import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import { NewFileUpload, NewFileUploadItem } from './newFileUploadModal';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  dragArea: {
    border: '1px solid #CBCBCB',
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  errorDragArea: {
    border: '2px dotted #D10A0A',
    width: '100%',
    minHeight: '120px',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
  },
  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  errorMeasage: {
    color: '#D10A0A',
  },
  uploadText: {
    fontWeight: 500,
  },
}));

interface IFileUploadAreaProps {
  items: NewFileUpload[];
  setItems: (items) => void;
  multiple?: boolean;
  fileType?: string;
  fileTypes?: string[];
  showError?: boolean;
  maxFiles?: number;
  showUploadButton?: boolean
}

export const NewFileUploadArea = observer(
  ({ items, setItems, multiple, fileType, showError, fileTypes, maxFiles, showUploadButton = false }: IFileUploadAreaProps) => {
    const classes = useStyles();
    const [error, setError] = useState<boolean>(false);
    const isCorrectFileType = (files: any[]) => {
      let isValid = true;
      if (fileType) {
        files.forEach(file => {
          if (file.type?.toLowerCase() !== fileType.toLowerCase()) {
            isValid = false;
            return;
          }
        })
      }

      if (fileTypes) {
        files.forEach(file => {
          const dotSplitFileName = file.name.split('.');
          if (!fileTypes.includes(dotSplitFileName[dotSplitFileName.length - 1])) {
            isValid = false;
            return;
          }
        });
      }

      return isValid;
    }

    const onDrop = (acceptedFiles: File[]) => {
      const newItems = acceptedFiles.map(file => ({
        name: file.name,
        file: file,
        type: file.name.split('.')[1]
      }));
      if (isCorrectFileType(newItems)) {
        setItems(prevItems => [...(multiple ? prevItems : []), ...newItems]);
        setError(false);
      } else {
        setError(true);
      }
    };

    const onRemove = (index: number) => {
      setItems(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    };

    const onRename = (index: number, newName: string) => {
      setItems(prevItems => {
        const items = [...prevItems];
        items[index].name = newName;
        return items;
      });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple,
      maxFiles: multiple ? maxFiles ? maxFiles : 0 : 1,
    });

    return (
      <>
        {((multiple && !maxFiles) || (multiple && maxFiles && items.length < maxFiles) || (!multiple && items.length === 0)) &&
          <Box {...getRootProps()} className={error || showError ? classes.errorDragArea : classes.dragArea}>
            <input {...getInputProps()} />
            <CloudUpload className={classes.icon} />
            {isDragActive ? (
              <p>Drop the file(s) here...</p>
            ) : (
              <>
                <p className={classes.uploadText}>Drag file to upload or <span style={{ textDecoration: 'underline' }}>browse file</span></p>
              </>
            )}
          </Box>
        }
        {error && <Typography variant="caption" className={classes.errorMeasage}>
          * The file you are trying to upload does not have the expected file type
        </Typography>}
        <Box>
          {items.map((item, i) => (
            <NewFileUploadItem
              key={item.file?.name ? item.file.name : item.name}
              name={item.name}
              file={item.file}
              onDrop={onDrop}
              disabled={item.disabled}
              showUploadButton={showUploadButton}
              onSaveName={newName => onRename(i, newName)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </Box>
      </>
    );
  }
);
