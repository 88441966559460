import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default function TabPanel(props) {
  const { children, value, index, px=3, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={3} px={px} >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}