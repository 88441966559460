import { GlobalStore } from '@roc/client-portal-shared/stores';
import {
  CreditBackgroundType,
  DateFormat,
  downloadDocument,
  formatDate,
  GENERIC_ERROR_MESSAGE,
  isNil,
  parseDate,
  PortalName,
  uniq,
} from '@roc/client-portal-shared/utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ApiResponse } from '@roc/feature-app-core';
import { Borrower } from '@roc/feature-types';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import {
  GeneralLoanTasksFilters,
  GeneralTasksFilters,
  GeneralTasksTab,
  GeneralTasksView,
  Task,
  TaskScope,
  TaskStatus,
} from '../types/generalTasksTypes';
import { GeneralTasksGridStore } from './generalTasksGridStore';
import { EditTaskStore } from './editTaskStore';
import { GeneralTasksService } from '../services/generalTasksService';
import { UserOption } from '@roc/feature-communication';
import { debounce } from '@roc/feature-utils';
import { cloneObject } from '../utils/generalTasksUtils';
import { isValid } from 'date-fns';
import { SupportTasksStore } from './supportTasksStore';

export class GeneralTasksStore {
  private globalStore: GlobalStore;
  private userStore: UserStore;
  private generalTasksService: GeneralTasksService;

  userInfo;
  editTaskStore: EditTaskStore;
  taskListGridStore: GridStore;
  supportTasksStore: SupportTasksStore;


  kanbanGridStores: Record<any, GridStore>;

  defaultFilters: Record<string, any> = {
    [GeneralTasksFilters.STATUS]: [
      { value: TaskStatus.OPEN }, { value: TaskStatus.IN_REVIEW }
    ],
    [GeneralTasksFilters.OPEN_SUBTASKS]: [],
    [GeneralTasksFilters.TASK_SCOPE]: [{ value: TaskScope.ASSIGNED_TO_ME }],
  };

  tab: GeneralTasksTab;
  view: GeneralTasksView;
  filters: Record<string, any>;
  assigneeOptions: UserOption[];
  watcherOptions: UserOption[];
  loanId: number;
  taskRoomExist: boolean;
  loanInformation: Record<number, any> = {};

  applyFiltersDebounced = debounce(this.applyFilters.bind(this));

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.generalTasksService = new GeneralTasksService();
    this.editTaskStore = new EditTaskStore(globalStore, userStore, this);
    this.taskListGridStore = new GeneralTasksGridStore(globalStore, this);
    this.kanbanGridStores = {};
    this.supportTasksStore = new SupportTasksStore(globalStore, this);

    makeObservable(this, {
      tab: observable,
      view: observable,
      filters: observable,
      defaultFilters: observable,
      assigneeOptions: observable,
      loanId: observable,
      taskRoomExist: observable,
      loanInformation: observable,
      reset: action,
      setTab: flow,
      setView: action,
      checkIfTaskRoomExist: action,
      createTaskChatroom: flow,
      setFilter: action,
      setLoanId: action,
      getFilterParams: action,
      resetFilters: flow,
      applyFilters: action,
      updateViews: flow,
      resetAndFetchOptions: action,
      fetchUserInfoAndAssigneeOptions: flow,
      fetchUserInfoAndWatchersOptions: flow,
      fetchLoanInformation: flow,
      fetchLoanDataForPage: flow,
    });
    this.reset();
  }

  reset() {
    this.editTaskStore.reset();
    this.tab = GeneralTasksTab.MY_TASKS;
    this.view = GeneralTasksView.LIST;
    this.loanInformation = {};
    this.filters = cloneObject(this.defaultFilters);
    this.taskListGridStore.reset();
    Object.values(this.kanbanGridStores).forEach(store => store.reset());
  }

  *setTab(tab: GeneralTasksTab) {
    this.tab = tab;
    if (GeneralTasksTab.MY_LOANS_TASKS === tab || GeneralTasksTab.ALL_LOANS_TASKS === tab) {
      yield this.userStore.getUserInfoAndEntitlements();
      this.taskListGridStore.reset();
      yield this.resetFilters();
    }
  }

  setView(view: GeneralTasksView) {
    this.view = view;
  }

  setFilter(filterName: GeneralTasksFilters | GeneralLoanTasksFilters, value) {
    this.filters[filterName] = value;
    this.applyFiltersDebounced();
  }

  setLoanId(loanId: number) {
    this.loanId = loanId;
  }

  *resetFilters() {
    this.filters = cloneObject(this.defaultFilters);
    yield this.applyFilters();
  }

  async applyFilters() {
    if (this.view === GeneralTasksView.LIST) {
      await this.taskListGridStore.fetchGridData();
    }
    if (this.view === GeneralTasksView.KANBAN) {
      Object.values(this.kanbanGridStores).forEach(gridStore =>
        gridStore.fetchGridData()
      );
    }
  }

  getFilterParams() {
    const getDropdownValues = options =>
      options?.length > 0 ? options.map(o => o.value).join(',') : undefined;

    const formattedDueDate = formatDate(
      this.filters[GeneralTasksFilters.DUE_DATE],
      DateFormat.MMDDYYYY
    );

    const filters = {
      title: this.filters[GeneralTasksFilters.TITLE],
      loanId: this.filters[GeneralTasksFilters.LOAN_ID],
      taskId: this.filters[GeneralTasksFilters.TASK_ID],
      dueDate: isValid(parseDate(formattedDueDate)) ? formattedDueDate : '',
      assigneeIds: (this.filters[GeneralTasksFilters.ASSIGNEE] || [])
        .map(opt => `id:${opt.value}`)
        .join(','),
      createdBy: this.filters[GeneralTasksFilters.CREATED_BY],
      taskName: this.filters[GeneralLoanTasksFilters.TASK_NAME],
      section: this.filters[GeneralLoanTasksFilters.SECTION],
    };
    const dropdownFilters = {
      status: getDropdownValues(this.filters[GeneralTasksFilters.STATUS]),
      priority: getDropdownValues(this.filters[GeneralTasksFilters.PRIORITY]),
      hasOpenSubtasks: getDropdownValues(
        this.filters[GeneralTasksFilters.OPEN_SUBTASKS]
      ),
      ownerId: getDropdownValues(this.filters[GeneralTasksFilters.OWNER]),
      taskType: getDropdownValues(this.filters[GeneralTasksFilters.TASK_TYPE]),
    };

    return { filters, dropdownFilters };
  }

  async resetAndFetchOptions() {
    await this.fetchUserInfoAndAssigneeOptions();
  }

  *fetchUserInfoAndAssigneeOptions(loanId?, loanTaskId?) {
    try {
      //TODO: Get rid of this setInternalUser code
      if (this.globalStore.isInternalPortal) {
        const userInfo = yield this.userStore.getUserInformation();
        this.userStore.setInternalUser(userInfo.user, null);
      }
      const response = yield this.generalTasksService.getAssigneOptions(loanId, loanTaskId);
      this.assigneeOptions = response.data.data;
      this.watcherOptions = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchUserInfoAndWatchersOptions() {
    try {
      const response = yield this.generalTasksService.getCurrentWatchersOptions(
        this.editTaskStore.task?.observers ?? []
      );
      
      const newWatchers = response.data.data;
      const existingUserIds = new Set(this.assigneeOptions.map(user => user.userId));
      const uniqueWatchers = newWatchers.filter(watcher => !existingUserIds.has(watcher.userId));
      this.watcherOptions = this.assigneeOptions.concat(uniqueWatchers);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *updateViews(savedTask?) {
    // Update views after saving a task
    if (this.view === GeneralTasksView.LIST) {
      this.applyFilters();
    }
    if (this.view === GeneralTasksView.KANBAN) {
      //TODO: Update kanban view after saving
    }
  }

  *fetchLoanInformation(loanIds) {
    try {
      const idsNotCached = uniq(loanIds).filter(
        (loanId: number) => !this.loanInformation[loanId]
      );
      if (idsNotCached.length > 0) {
        const response = yield this.generalTasksService.getLoanInformation(
          idsNotCached
        );
        const loansData = response.data.data;
        loansData.forEach(loanData => {
          this.loanInformation[loanData.loanId] = loanData;
        });
      }
    } catch (e) { }
  }

  *fetchLoanDataForPage() {
    const rows = this.taskListGridStore.gridData.data.rows ?? [];
    const loanIds = rows.map(row => row.loanId).filter(Boolean);
    yield this.fetchLoanInformation(loanIds);
  }

  async checkIfTaskRoomExist(
    taskId: number,
    onSuccess: () => void,
  ) {
    const responseTaskChat = await this.generalTasksService.doesTaskChatRoomExist(taskId);
    this.taskRoomExist = responseTaskChat.data.data
    if (responseTaskChat.data.data) {
      onSuccess();
    }
  }

  *createTaskChatroom(
    taskId: number,
    onSuccess: () => void,
  ) {
    try {
      yield this.editTaskStore.reloadTaskById(taskId)
      yield this.editTaskStore.createTaskChatRoom();
      const responseTaskChat = yield this.generalTasksService.doesTaskChatRoomExist(taskId);
      this.taskRoomExist = responseTaskChat.data.data
      onSuccess()
    } catch (error) {
      console.log("erro creating the chat")
    }
  }
}
