import { observable, makeObservable, computed, action } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { noAccessSources } from '../submitAppraisal/utils/constants';
import { Borrower } from '@roc/feature-types';
import { LOAN_FUNDER, parseAddress } from '@roc/feature-utils';
import { LoanSubType } from '@roc/feature-utils';

export class SubmitAppraisalBaseStore extends FormStore {
  globalStore: GlobalStore;
  isSubmitted = false;
  selectedLoanSubType: string;
  submittedAppraisalId: number;
  isClientPortal: boolean;

  constructor(appraisalInformation: object, globalStore: GlobalStore) {
    super({ ...appraisalInformation }, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      isSubmitted: observable,
      selectedLoanSubType: observable,
      isNoAccessAndPurchase: computed,
      isValidNoInteriorAccessComment: computed,
      isValidPropertyType: computed,
      isDefaultWholesaleLender: computed,
      isAddressSet: computed,
      setFormFieldValue: action,
      onChange: action,
      handlePropertyOwnershipChange: action,
      handleRenovationBudgetChange: action,
      handleRenovationSpentToDateChange: action,
      handlePropertySourcingChange: action,
      handleInteriorAccessAvailableChange: action,
      handleNoInteriorAccessCommentChange: action,
      handleSelectLoanSubType: action.bound,
      isClientPortal: observable,
    });
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );
    this.onFieldChange('address', address);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('zipCode', parsedAddress.zip);
    this.onFieldChange('latitude', parsedAddress.latitude);
    this.onFieldChange('longitude', parsedAddress.longitude);
    this.onFieldChange('county', parsedAddress.county);

    const addressInCorrectFormat =
      parsedAddress.street_number !== null &&
      parsedAddress.street !== null &&
      parsedAddress.city !== null &&
      parsedAddress.state !== null &&
      parsedAddress.zip !== null;
    this.onFieldChange('__isAddressInCorrectFormat__', addressInCorrectFormat);
  }

  get isNoAccessAndPurchase() {
    const { propertyOwnership, propertySourcing } = this.form.fields;
    return (
      propertyOwnership.value === 'Purchase' &&
      noAccessSources.includes(propertySourcing.value)
    );
  }

  get isValidNoInteriorAccessComment() {
    const {
      interiorAccessAvailable,
      noInteriorAccessComment,
    } = this.form.fields;

    if (this.selectedLoanSubType !== LoanSubType.FIX_AND_FLIP &&
      this.selectedLoanSubType !== LoanSubType.FIX_AND_FLIP_PRO) return true;

    return (
      interiorAccessAvailable.value === 'Y' ||
      this.isNoAccessAndPurchase ||
      !!noInteriorAccessComment.value
    );
  }

  get isValidPropertyType() {
    const { useCode } = this.form.fields;
    return !!useCode.value;
  }

  get isDefaultWholesaleLender() {
    const { wholesaleLender } = this.form.fields;
    return wholesaleLender.value === LOAN_FUNDER;
  }

  get isAddressSet() {
    const { latitude,  longitude} = this.form.fields;
    return !!latitude.value && !!longitude.value;
  }

  setFormFieldValue(field, value) {
    this.form.fields[field].value = value;
    this.form.fields[field].error = null;
  }

  onChange = (field, value) => {
    this.setFormFieldValue(field, value);
  };

  handlePropertyOwnershipChange() {
    const { propertyOwnership } = this.getFormValues();
    if (propertyOwnership === 'Purchase') {
      this.setFormFieldValue('purchaseDate', null);
      this.setFormFieldValue('sunkCost', '');
      this.setFormFieldValue('payoffAmount', '');
      this.setFormFieldValue('renovationRemaining', '');
    } else {
      this.setFormFieldValue('propertySourcing', '');
      this.setFormFieldValue('wholesaleAmount', '');
    }
    this.setFormFieldValue('purchasePrice', '');
    this.setFormFieldValue('totalRenovationBudget', '');
    this.setFormFieldValue('scopeOfWorkFile', null);
    this.setFormFieldValue('afterRepairValue', '');
    this.setFormFieldValue('exitStrategy', '');
    this.setFormFieldValue('inspectionBy', '');
    this.setFormFieldValue('interiorAccessAvailable', '');
    this.setFormFieldValue('noInteriorAccessComment', '');
    this.setFormFieldValue(
      '__isValidNoInteriorAccessComment__',
      this.isValidNoInteriorAccessComment
    );
  }

  handleRenovationBudgetChange() {
    const { sunkCost, totalRenovationBudget } = this.getFormValues();
    if (!sunkCost || totalRenovationBudget >= sunkCost) {
      if (totalRenovationBudget <= 1000000000000) {
        if (!totalRenovationBudget) {
          this.setFormFieldValue('afterRepairValue', '');
        }
        this.setFormFieldValue('renovationRemaining', totalRenovationBudget);
      }
    } else {
      if (!totalRenovationBudget) {
        this.setFormFieldValue('renovationRemaining', '');
        this.setFormFieldValue('sunkCost', '');
      }
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The total renovation budget should be greater than the amount already invested.',
      });
    }
  }

  handleRenovationSpentToDateChange() {
    const { sunkCost, totalRenovationBudget } = this.getFormValues();
    if (!totalRenovationBudget || sunkCost <= totalRenovationBudget) {
      const renovationRemaining = totalRenovationBudget - sunkCost;
      if (sunkCost <= 1000000000000) {
        this.setFormFieldValue('renovationRemaining', renovationRemaining);
      }
    } else {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'Amount already invested should be lower than the total renovation budget.',
      });
    }
  }

  handlePropertySourcingChange() {
    const {
      propertySourcing,
      interiorAccessAvailable,
      noInteriorAccessComment,
    } = this.getFormValues();
    if (propertySourcing !== 'Wholesaler') {
      this.setFormFieldValue('purchasePrice', '');
      this.setFormFieldValue('wholesaleAmount', '');
    } else if (propertySourcing === 'Wholesaler') {
      this.setFormFieldValue('purchasePrice', '');
    }
    if (
      interiorAccessAvailable === 'N' &&
      this.isNoAccessAndPurchase &&
      noInteriorAccessComment !== ''
    ) {
      this.setFormFieldValue('noInteriorAccessComment', '');
    }
    this.setFormFieldValue(
      '__isValidNoInteriorAccessComment__',
      this.isValidNoInteriorAccessComment
    );
  }

  handleInteriorAccessAvailableChange() {
    const {
      interiorAccessAvailable,
      inspectionBy,
      noInteriorAccessComment,
    } = this.getFormValues();
    if (interiorAccessAvailable === 'Y' && noInteriorAccessComment !== '') {
      this.setFormFieldValue('noInteriorAccessComment', '');
    } else if (interiorAccessAvailable === 'N' && inspectionBy !== '') {
      this.setFormFieldValue('inspectionBy', '');
    }
    this.setFormFieldValue(
      '__isValidNoInteriorAccessComment__',
      this.isValidNoInteriorAccessComment
    );
  }

  handleNoInteriorAccessCommentChange() {
    this.setFormFieldValue(
      '__isValidNoInteriorAccessComment__',
      this.isValidNoInteriorAccessComment
    );
  }

  handleBorrowerChange(borrowers: Borrower[]) {
    const borrower = borrowers[0] || null;
    this.onFieldChange('borrower', borrower);
  }

  handleSelectLoanSubType(loanSubType) {
    this.selectedLoanSubType = loanSubType;
    this.setFormFieldValue('selectedLoanSubType', loanSubType);
  }

  protected parseFormInformation(data) {
    return {
      inspectionBy: data.inspectionBy,
      noInteriorAccessComment: data.noInteriorAccessComment,
      comment: data.clientComments,
      propertyAppraisalOrder: {
        property: this.getProperty(data),
        interiorAccessAvailable: data.interiorAccessAvailable === 'Y',
        noInteriorAccessComment: data.noInteriorAccessComment,
        propertyOwnership: data.propertyOwnership,
        exitStrategy: data.exitStrategy,
        purchasePrice: data.purchasePrice,
        purchaseDate: data.purchaseDate,
        renovationBudget: data.totalRenovationBudget,
        renovationRemaining: data.renovationRemaining,
        renovationSpentToDate: data.sunkCost,
        payoffAmount: data.payoffAmount,
        afterRepairValue: data.afterRepairValue,
        propertySourcing: data.propertySourcing,
        wholesaleAmount: data.wholesaleAmount,
        originatorId: data.originatorId,
        loanType: this.isDefaultWholesaleLender ? this.selectedLoanSubType : data.loanType,
        borrower: this.isDefaultWholesaleLender ? this.getBorrower(data) : this.buildBorrower(data),
        loanExternalNumber: data.loanExternalNumber,
        transactionType: data.transactionType,
        occupancyType: data.occupancyType,
        wholesaleLender: data.wholesaleLender,
        appraisalType: data.appraisalType,
        appraisalType1: data.appraisalType1,
        appraisalType2: data.appraisalType2,
        appraisalType3: data.appraisalType3,
      },
      submitRushedAppraisal: data.submitRushedAppraisal === 'Y',
    };
  }

  protected getProperty(data) {
    return {
      address: data.address,
      streetName: data.streetName,
      streetNumber: data.streetNumber,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      latitude: data.latitude,
      longitude: data.longitude,
      propertyType: data.useCode,
      county: data.county,
    };
  }

  protected getBorrower(data) {
    return {
      firstName: data.borrower.firstName,
      lastName: data.borrower.lastName,
      cellPhone: data.borrower.cellPhone,
      emailAddress: data.borrower.emailAddress,
    };
  }

  protected buildBorrower(data) {
    return {
      firstName: data.fullName,
      cellPhone: data.cellPhone,
      emailAddress: data.emailAddress,
    };
  }

  protected getScopeOfWorkFile(data) {
    const file = data.scopeOfWorkFile && data.scopeOfWorkFile[0];
    if (!file) return null;
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], file.name, { type: file.type });
    return newFile;
  }

  reset() {
    this.isSubmitted = false;
    this.selectedLoanSubType = null;
    this.submittedAppraisalId = null;
    super.reset();
  }
}
