import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { computed, flow, makeObservable, override } from 'mobx';
import { BorrowerLoanSubmissionService } from '../../services/borrowerLoanSubmissionService';
import { isNil, segmentAnalytics, SegmentTrackerEvent } from '@roc/feature-utils';
import { LoanSubmissionStep } from '@roc/feature-loans';
import { BaseLoanTypeStore } from '../baseLoanTypeStore';
import { BridgeBorrowerInformationStore } from './bridgeBorrowerInformationStore';
import { BorrowerEntityInformationStore } from '../borrowerEntityInformationStore';
import { BridgePropertiesStore } from './bridgePropertiesStore';
import { getQuoteFromBorrowerLoanApplication } from 'libs/feature-loans/src/loanSubmission/stores/common/mapLoanToQuoteHelper';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';

export class PublicFixFlipStore extends BaseLoanTypeStore {
  private borrowerLoanSubmissionService: BorrowerLoanSubmissionService;
  private oneToolService: OneToolService;

  constructor(globalStore: GlobalStore) {
    super(globalStore);
    this.isPublic = true;
    this.borrowerLoanSubmissionService = new BorrowerLoanSubmissionService();
    this.oneToolService = new OneToolService();

    this.borrowerInformationStore = new BridgeBorrowerInformationStore(
      globalStore
    );
    this.borrowerEntityInformationStore = new BorrowerEntityInformationStore(
      globalStore,
      this.borrowerInformationStore
    );
    this.propertiesStore = new BridgePropertiesStore(globalStore);

    this.setDefaults();

    makeObservable(this, {
      submitLoan: override,
      submitPublicLoanApplication: flow,
      sendLoanSubmissionErrorEmail: flow,
      saveLoanQuoteForApplication: flow,
      publicLoanApplicationJson: computed,
      loanProperties: computed,
      borrowerEntity: computed,
    });
  }

  *submitLoan() {
    yield this.submitPublicLoanApplication();
  }

  *submitPublicLoanApplication() {
    try {
      this.newPublicLoanId = null;
      const publicLoanApplicationJson = this.publicLoanApplicationJson;
      console.log('publicLoanApplicationJson', publicLoanApplicationJson);
      const response: ApiResponse = yield this.borrowerLoanSubmissionService.submitPublicLoaApplication(
        publicLoanApplicationJson,
        this.globalStore.portalConfiguration.host,
        this.borrowerInformationStore.mainBorrower.emailAddress
      );
      if (response.data?.data?.success === false) {
      } else {
        this.newPublicLoanId = response.data?.data?.loanId;
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.PUBLIC_SUBMISSION,
          userName: this.borrowerInformationStore.mainBorrower.emailAddress,
        });
      }
    } catch (error) {
      console.log(error);
      this.sendLoanSubmissionErrorEmail(
        error?.error?.response?.data?.error?.message ||
        error?.error?.message ||
        error
      );
    } finally {
      this.setActiveStep(LoanSubmissionStep.SUCCESS);
      this.saved = true;
    }
    this.saveLoanQuoteForApplication(this.loanSubtype,
      this.borrowerEntityInformationStore.mainEntity,
      this.borrowerInformationStore.borrowers,
      this.propertiesStore.getProperties());
  }

  get publicLoanApplicationJson() {
    return {
      loanType: this.loanType,
      loanSubtype: this.loanSubtype,
      loanBorrowerInformation: {
        borrowerEntity: this.borrowerEntity,
        loanBorrowers: {
          rows: [...this.borrowerInformationStore.borrowers],
        },
      },
      propertiesMap: {
        rows: [...this.loanProperties],
      },
    };
  }

  get borrowerEntity() {
    const mainEntity = this.borrowerEntityInformationStore.mainEntity;
    return {
      ...mainEntity,
      company: {
        ...mainEntity,
        name: mainEntity.name,
        einNumber: mainEntity.ein,
        entityType: mainEntity.type,
        zipCode: mainEntity.zipcode,
      },
    };
  }

  get loanProperties() {
    return this.propertiesStore.getProperties().map(property => ({
      ...property,
      armsLength: property.armsLength === 'Y',
      anyDebt: property.armsLength === 'Y',
      describeRenovation: Array.isArray(property.describeRenovation) ? (property.describeRenovation || [])
            .map(item => item.value)
            .join(','): property.describeRenovation,
    }));
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.borrowerLoanSubmissionService.sendErrorEmail(
      'Public Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan',
      this.globalStore.portalConfiguration.id
    );
  }

  *saveLoanQuoteForApplication(loanSubtype, entity, borrowers, properties) {
    try {
      if (!isNil(this.newPublicLoanId)) {
        const quoteData = {
          ...getQuoteFromBorrowerLoanApplication(this.newPublicLoanId, loanSubtype, entity, borrowers, properties),
        };
        const response = yield this.oneToolService.savePublicQuote(quoteData, this.borrowerInformationStore.mainBorrower.emailAddress);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuote for public borrower application because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving public borrower application as loan quote');
      console.log(error);
    }
  }
}
