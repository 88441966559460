
import { observer } from 'mobx-react';
import LoanSummaryExpandableTable from './loanSummaryExpandableTable';
import {
  getValueOrDefaultDash
} from 'libs/feature-one-tool/src/quote/utils/utils';

export const KeyDatesBridgeV2 = observer(({ loanDetails }) => {

  return (
    <>
      <LoanSummaryExpandableTable
        title='Key Dates'
        data={[
          {
            left: 'Submission',
            right: getValueOrDefaultDash(loanDetails?.submissionDate),
          },
          {
            left: 'Exp. Closing',
            right: getValueOrDefaultDash(loanDetails?.expectedClosingDate),
          },
          {
            left: 'Agreed Date',
            right: getValueOrDefaultDash(loanDetails?.agreedDate),
          },
          {
            left: 'Closing Date',
            right: getValueOrDefaultDash(loanDetails?.closingDate),
          },
          {
            left: 'PayOff Date',
            right: getValueOrDefaultDash(loanDetails?.payoffDate),
          },
          {
            left: 'Maturity',
            right: getValueOrDefaultDash(loanDetails?.maturityDate),
          },
          {
            left: 'Next Due Date',
            right: getValueOrDefaultDash(loanDetails?.calculatedLoanData?.nextDueDate),
          }
        ]}
        hasTwoColumns={false}
      />
    </>
  );
});