import {
  makeStyles,
} from "@material-ui/core/styles";
import { FieldSetBox } from "../fieldSetBox";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { ComparablesTable } from "./comparablesTable";

const useStyles = makeStyles(() => ({
  page: {
    fontFamily: "Roboto, Arial, sans-serif",
    border: "1px solid lightgray",
    borderRadius: "5px",
    width: "1018px",
    height: "1440px",
    padding: "64px 40px 64px 40px",
  }
}));

interface Page2Props {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const Page2 = ({ internalValuationReviewStore }: Page2Props) => {
  const classes = useStyles();


  return (
    <div className={classes.page}>
      <main>
        <FieldSetBox title="Comparable">
          <ComparablesTable internalValuationReviewStore={internalValuationReviewStore} />
        </FieldSetBox>
      </main>
    </div>
  );
};