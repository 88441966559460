import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import {
  AppraisalDetailsForm,
  BorrowerForm,
  PropertyForm,
  PropertyFormCustomLender,
  PropertyFormHeader,
} from './components';

export const SubmitAppraisalForm = observer(({ store }) => {
  const { isValidPropertyType, isDefaultWholesaleLender, isAddressSet } = store;

  const createOrder = () => {
    store.submitAppraisal();
  };

  return (
    <Grid container>
      <Grid container item>
        <Grid item container xs={12} spacing={4}>
          <PropertyFormHeader submitAppraisalStore={store} />
          {isAddressSet && (
            <>
              {isDefaultWholesaleLender && (
                <>
                  <PropertyForm submitAppraisalStore={store} />
                  {isValidPropertyType && (
                    <>
                      <AppraisalDetailsForm submitAppraisalStore={store} />
                      <BorrowerForm submitAppraisalStore={store} />
                    </>
                  )}
                </>
              )}
              {!isDefaultWholesaleLender && <PropertyFormCustomLender submitAppraisalStore={store} />
              }
            </>
          )}
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={createOrder}
              testId="createOrder"
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
