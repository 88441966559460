import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getMultipartFile } from '../../utils/fileUtils';
import {
  CurrencyField,
  SelectField,
  RadioField,
  SubmitButton,
  TextField,
  NumberFormat,
  Slider,
  Paper,
  FieldLabel,
  NumberSliderWithInput,
  Button,
  InformationDialog,
  StandardDialog,
  createTextField,
  yesNoOptions
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { isNotBlank, OTHER, lenderUnderwritingFeeTooltip, InterestRateWithAdjustedFloorRateTooltip, LoanSubType, requiredLicenseStates, Roles, isNil, isFixAndFlip, isGroundUp, roleOptions } from '@roc/feature-utils';
import { Add, Build, ShowChart } from '@material-ui/icons';
import CompanyForm from '../../components/companyForm';
import { Box, InputAdornment, Link, List, ListItem } from '@material-ui/core';
import {
  PreferredClosingAttorney,
  PreferredInsurance,
  PreferredTitleCompany,
  RequestedClosingDate,
  SubmitAppraisalWithTamarisk,
  FastTrackLoan,
  FacoParticipants,
  LenderParticipants
} from '../../components/formComponents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { FileUpload } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { StateLicenseUpload } from '../../components/formComponents';
import { normalizeState } from '@roc/feature-utils';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';
import { nonReferralFeeStates } from '@roc/feature-utils';
import { PricingModelTable } from './pricingModelTable';
import { ErrorsAlert, WarningsAlert } from '../../components/alerts';
import { LeverageImprovement, LeverageException } from '../../components';
import ExperienceMatrix from '../../components/experienceMatrix';

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5.5),
      width: '95%',
    },
    sliderDisabled: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5.5),
      width: '95%',
      color: 'gray'
    },
    totalAmountCard: {
      marginTop: theme.spacing(4),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    disclaimerLabel: {
      fontSize: '0.75rem',
      paddingTop: '8px',
      color: 'rgba(0, 0, 0, 0.60)',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    list: {
      listStyleType: 'disc',
      listStylePosition: 'inside'
    },
    listItem: {
      display: 'list-item',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  })
);

const useCustomSliderStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5.5),
      width: '95%',
      '& .MuiSlider-valueLabel': {
        left: 'calc(-50% - 8px)',
        transform: 'scale(1) translateY(-14px) !important',
        '& > span': {
          width: '40px',
          height: '40px'
        }
      }
    },
  })
);

const percentageMarks = (top, min = 0) => [
  {
    value: min,
    label: min + '%',
  },
  {
    value: top,
    label: top + '%',
  },
];

const posibleDurations = [
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '18 months',
    value: 18,
  },
];

const accrualMethods = [
  {
    value: 'Full Boat',
    label: 'Full Boat',
  },
  {
    value: 'As disbursed',
    label: 'As disbursed',
  },
];

const interestReserveOptions = [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: '1 month',
  },
  {
    value: 2,
    label: '2 months',
  },
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 4,
    label: '4 months',
  },
  {
    value: 5,
    label: '5 months',
  },
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 7,
    label: '7 months',
  },
  {
    value: 8,
    label: '8 months',
  },
  {
    value: 9,
    label: '9 months',
  },
  {
    value: 10,
    label: '10 months',
  },
  {
    value: 11,
    label: '11 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 13,
    label: '13 months',
  },
  {
    value: 14,
    label: '14 months',
  },
  {
    value: 15,
    label: '15 months',
  },
  {
    value: 16,
    label: '16 months',
  },
  {
    value: 17,
    label: '17 months',
  },
  {
    value: 18,
    label: '18 months',
  },
];

export const rateOptions = [
  {
    value: 9.99,
    label: '9.99%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];
const mfBridgeRateOptions = [
  {
    value: 9.99,
    label: '9.99%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];

const pointFormatOptions = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

const participationOptions = [
  {
    value: 'Through life of loan',
    label: 'Through life of loan',
  },
  {
    value: 'With subsequent buy-out',
    label: 'With subsequent buy-out',
  },
  {
    value: 'No Participation',
    label: 'No Participation',
  },
];

const participationPercetangeOptions = [
  {
    value: 0.0,
    label: '0%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 20.0,
    label: '20%',
  },
  {
    value: 25.0,
    label: '25%',
  },
  {
    value: 30.0,
    label: '30%',
  },
  {
    value: 35.0,
    label: '35%',
  },
  {
    value: 40.0,
    label: '40%',
  },
  {
    value: 45.0,
    label: '45%',
  },
  {
    value: 49.0,
    label: '49%',
  },
];

const pointOptions = [
  {
    value: 0.0,
    label: '0',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
  {
    value: 0.75,
    label: '0.75',
  },
  {
    value: 1.0,
    label: '1',
  },
  {
    value: 1.25,
    label: '1.25',
  },
  {
    value: 1.5,
    label: '1.5',
  },
  {
    value: 1.75,
    label: '1.75',
  },
  {
    value: 2.0,
    label: '2',
  },
  {
    value: 2.25,
    label: '2.25',
  },
  {
    value: 2.5,
    label: '2.5',
  },
  {
    value: 2.75,
    label: '2.75',
  },
  {
    value: 3.0,
    label: '3',
  },
  {
    value: 3.25,
    label: '3.25',
  },
  {
    value: 3.5,
    label: '3.5',
  },
  {
    value: 3.75,
    label: '3.75',
  },
  {
    value: 4.0,
    label: '4',
  },
  {
    value: 4.25,
    label: '4.25',
  },
  {
    value: 4.5,
    label: '4.5',
  },
  {
    value: 4.75,
    label: '4.75',
  },
  {
    value: 5.0,
    label: '5',
  },
  {
    value: 5.25,
    label: '5.25',
  },
  {
    value: 5.5,
    label: '5.5',
  },
  {
    value: 5.75,
    label: '5.75',
  },
  {
    value: 6.0,
    label: '6',
  },
];

const InitialLoanAmount = observer(({ store, disabled }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      testId="initialLoanAmount"
      standaloneLabel
      required
      fullWidth
      label="Initial Loan Amount"
      variant="outlined"
      value={formFields.initialLoanAmount.value}
      errorText={formFields.initialLoanAmount.error}
      error={isNotBlank(formFields.initialLoanAmount.error)}
      onChange={(name, value) =>
        store.changeInitialLoanAmount('initialLoanAmount', Number(value))
      }
      decimalScale={0}
      disabled={disabled}
    />
  );
});


export const LtcCatchupDraw = observer(({ store, getNewRateFloor }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Add LTC Catchup Draw?"
      value={formFields.ltcCatchupDraw.value}
      errorText={formFields.ltcCatchupDraw.error}
      error={isNotBlank(formFields.ltcCatchupDraw.error)}
      name={'ltcCatchupDraw'}
      options={yesNoOptions}
      onChange={value => {
        store.onChange('ltcCatchupDraw', value);
        getNewRateFloor();
      }
      }
      row
      required
      testId="ltcCatchupDraw"
    />
  );
});

const InitialLoanToPurchase = observer(({ store, loanSubtype, disabled, getNewRateFloor, maxLTC, maxValueSliderInitialLoanAmount, financeIrEnabled }) => {
  const formFields = store.form.fields;
  const classes = useStyles();
  let max = maxLTC || 75;
  return (
    <Slider
      testId="initialLoanToPurchaseSlider"
      standaloneLabel
      label={loanSubtype === LoanSubType.GROUND_UP ? 'Initial Loan Amount/Purchase Price or Land Value (Lesser of)' : 'Initial Loan Amount/Purchase Price'}
      className={classes.slider}
      value={formFields.initialLoanToPurchase.value}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{Math.round(value)}%</div>}
      onChangeCommitted={(event, value) => {
        store.changeInitialLoanToPurchase('initialLoanToPurchase', value)
        getNewRateFloor();
      }}
      marks={percentageMarks(maxValueSliderInitialLoanAmount(formFields?.interestReserveFinanced?.value, max, loanSubtype, financeIrEnabled()))}
      step={1}
      min={0}
      max={maxValueSliderInitialLoanAmount(formFields?.interestReserveFinanced?.value, max, loanSubtype, financeIrEnabled())}
      disabled={disabled}
    />
  );
});

const ConstructionHoldback = observer(({ store, disabled }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      testId="constructionHoldback"
      standaloneLabel
      required
      fullWidth
      label="Construction Holdback"
      variant="outlined"
      value={formFields.constructionHoldback.value}
      onChange={(name, value) =>
        store.changeConstructionHoldback('constructionHoldback', Number(value))
      }
      decimalScale={0}
      disabled={disabled}
    />
  );
});

const ConstructionHoldbackPercentage = observer(({ store, getNewRateFloor, maxValuesSliderConstructionHoldBack, financeIrEnabled, loanSubtype, ...rest }) => {
  const formFields = store.form.fields;
  const classes = useStyles();
  return (
    <Slider
      testId="constructionHoldbackPercentageSlider"
      standaloneLabel
      label="Construction Holdback/Renovation Budget"
      className={classes.slider}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{Math.round(value)}%</div>}
      marks={percentageMarks(maxValuesSliderConstructionHoldBack(100, loanSubtype, financeIrEnabled()))}
      value={formFields.constructionHoldbackPercentage.value}
      onChangeCommitted={(event, value) => {
        store.changeConstructionHoldbackPercentage(
          'constructionHoldbackPercentage',
          value
        )
        getNewRateFloor();
      }}
      step={1}
      min={0}
      max={maxValuesSliderConstructionHoldBack(100, loanSubtype, financeIrEnabled())}
      {...rest}
    />
  );
});
const MaxLTFCSlider = observer(({ store, val, getNewRateFloor, maxValuesSliderConstructionHoldBack, financeIrEnabled, loanSubtype, ...rest }) => {
  // const formFields = store.form.fields;
  const classes = useStyles();
  return (
    <Slider
      testId="constructionHoldbackPercentageSlider"
      standaloneLabel
      label="Total Loan Amount/Total Cost"
      className={classes.sliderDisabled}
      valueLabelDisplay="on"
      disabled={true}
      valueLabelFormat={value => <div>{Math.round(value)}%</div>}
      marks={percentageMarks(maxValuesSliderConstructionHoldBack(100, loanSubtype, financeIrEnabled()))}
      value={val?.ltfc ?? 0}
      step={1}
      min={0}
      max={100}
      {...rest}
    />
  );
});
const MaxARLTVSlider = observer(({ store, getNewRateFloor, maxValuesSliderConstructionHoldBack, financeIrEnabled, loanSubtype, ...rest }) => {
  const classes = useStyles();
  return (
    <Slider
      testId="constructionHoldbackPercentageSlider"
      standaloneLabel
      label="Total Loan Amount/ARV"
      className={classes.sliderDisabled}
      disabled={true}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{Math.round(value)}%</div>}
      marks={percentageMarks(maxValuesSliderConstructionHoldBack(100, loanSubtype, financeIrEnabled()))}
      value={store?.rateAdjustmentsData?.arltv ?? 0}
      step={1}
      min={0}
      max={100}
      {...rest}
    />
  );
});

const InterestRate = observer(({ store, options, disabled, isMultifamilyBridge, loanSubtype, getNewRateFloor, financeIrEnabled }) => {
  const formFields = store.form.fields;

  if (isMultifamilyBridge) {
    return (
      <SelectField
        standaloneLabel
        label={'Interest Rate'}
        name={'rate'}
        value={formFields.rate.value}
        errorText={formFields.rate.error}
        error={isNotBlank(formFields.rate.error)}
        options={options}
        onChange={value => {
          store.onChange('rate', value)
          if (financeIrEnabled()) {
            getNewRateFloor()
          }
        }}
        variant="outlined"
        shrink
        required
        testId="rate"
        disabled={disabled}
      />
    );
  }
  const classes = useCustomSliderStyles();
  let min = 0;
  let max = 0;

  if (options?.length > 0) {
    min = options[0].value;
    max = options[options.length - 1].value;
  }

  return (
    <NumberSliderWithInput
      testId="interestRateSlider"
      standaloneLabel
      label={loanSubtype === LoanSubType.GROUND_UP ?
        <FieldLabel tooltip={InterestRateWithAdjustedFloorRateTooltip}>Interest Rate *</FieldLabel>
        : 'Interest Rate *'}
      className={classes.slider}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{value}%</div>}
      marks={min > 0 ? percentageMarks(max, min) : false}
      value={formFields.rate.value}
      onChangeCommitted={(event, value) => {
        store.onChange('rate', value)
        if (financeIrEnabled()) {
          getNewRateFloor()
        }
      }}
      step={0.01}
      min={min}
      max={max}
    />
  );
});

const Points = observer(({ store, disabled }) => {
  const formFields = store.form.fields;
  const classes = useCustomSliderStyles();
  let basePoints = store.globalStore.lenderInfo.bridgeRocBasePoints ?? 0;
  if (store?.fixFlipStore?.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
    basePoints = 0;
  }

  return (
    <NumberSliderWithInput
      testId="pointsSlider"
      standaloneLabel
      label="Points *"
      className={classes.slider}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{value}%</div>}
      marks={percentageMarks(5, basePoints)}
      value={formFields.points.value}
      onChangeCommitted={(event, value) => {
        store.changePointsPercentage('points', value, 'pointsNumber');
      }}
      step={0.25}
      min={store?.fixFlipStore?.floorPoints ?? 0}
      max={store?.fixFlipStore?.maxPoints ?? 5}
    />
  )
});

const BrokerPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  const { globalStore } = useStore();

  return (
    <>
      <RadioField
        standaloneLabel
        label={globalStore?.lenderInfo?.isInternal ? "On Hud Broker Fee" : "Broker Points"}
        value={formFields.brokerPointsFormat.value}
        errorText={formFields.brokerPointsFormat.error}
        error={isNotBlank(formFields.brokerPointsFormat.error)}
        name={'brokerPointsFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('brokerPointsFormat', value);
          store.changePointsPercentage(
            'brokerPoints',
            formFields.brokerPoints.value,
            'brokerPointsNumber'
          );
        }}
        fullWidth
        row
        required
        testId="brokerPointsFormat"
      />
      {formFields.brokerPointsFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="brokerPointsPercentage"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.brokerPoints.value}
          onChange={e =>
            store.changePointsPercentage(
              'brokerPoints',
              e.target.value,
              'brokerPointsNumber'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.brokerPoints.error)}
          helperText={formFields.brokerPoints.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.brokerPointsFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.brokerPointsNumber.value}
          errorText={formFields.brokerPoints.error}
          error={isNotBlank(formFields.brokerPoints.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'brokerPointsNumber',
              value,
              'brokerPoints'
            )
          }
          fullWidth
          required
          testId="brokerPointsNumber"
        />
      )}
    </>
  );
});


const ReferralFees = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <>
      <RadioField
        standaloneLabel
        label={"Off Hud Broker Fee"}
        value={formFields.referralFeesFormat.value}
        errorText={formFields.referralFeesFormat.error}
        error={isNotBlank(formFields.referralFeesFormat.error)}
        name={'referralFeesFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('referralFeesFormat', value);
          store.changePointsPercentage(
            'referralFee',
            formFields.referralFee.value,
            'referralFeesAmount'
          );
        }}
        fullWidth
        row
        required
        testId="referralFeesFormat"
      />
      {formFields.referralFeesFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="referralFee"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.referralFee.value}
          onChange={e =>
            store.changePointsPercentage(
              'referralFee',
              e.target.value,
              'referralFeesAmount'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.referralFee.error)}
          helperText={formFields.referralFee.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.referralFeesFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.referralFeesAmount.value}
          errorText={formFields.referralFee.error}
          error={isNotBlank(formFields.referralFee.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'referralFeesAmount',
              value,
              'referralFee'
            )
          }
          fullWidth
          required
          testId="referralFeesNumber"
        />
      )}
    </>
  );
});


const LenderUnderwritingFee = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Underwriting Fee"
      value={formFields.lenderUnderwritingFee.value}
      errorText={formFields.lenderUnderwritingFee.error}
      error={isNotBlank(formFields.lenderUnderwritingFee.error)}
      onChange={(name, value) => store.onChange('lenderUnderwritingFee', Number(value))}
      tooltip={lenderUnderwritingFeeTooltip}
      variant="outlined"
      fullWidth
      required
      testId="lenderUnderwritingFee"
    />
  );
});

const LenderProcessingFee = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Processing Fee"
      value={formFields.lenderProcessingFee.value}
      errorText={formFields.lenderProcessingFee.error}
      error={isNotBlank(formFields.lenderProcessingFee.error)}
      onChange={(name, value) => store.onChange('lenderProcessingFee', Number(value))}
      variant="outlined"
      fullWidth
      testId="lenderProcessingFee"
    />
  );
});

export const RocPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Capital Provider Points'}
      name={'rocPointsIn'}
      value={formFields.rocPointsIn.value}
      errorText={formFields.rocPointsIn.error}
      error={isNotBlank(formFields.rocPointsIn.error)}
      options={pointOptions}
      onChange={value => store.onChange('rocPointsIn', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="rocPointsIn"
    />
  );
});

const TermDuration = observer(({ store, disabled, getNewRateFloor }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Term (Duration)'}
      name={'termDuration'}
      value={formFields.duration.value}
      errorText={formFields.duration.error}
      error={isNotBlank(formFields.duration.error)}
      options={posibleDurations}
      onChange={value => {
        store.onChange('duration', value);
        console.log('changing Duration')
        getNewRateFloor();
      }}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="termDuration"
      disabled={disabled}
    />
  );
});

const InterestAccrualMethod = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Accrual Method'}
      name={'interestAccrualMethod'}
      value={formFields.interestAccrualMethod.value}
      errorText={formFields.interestAccrualMethod.error}
      error={isNotBlank(formFields.interestAccrualMethod.error)}
      options={accrualMethods}
      onChange={value => store.onChange('interestAccrualMethod', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestAccrualMethod"
    />
  );
});

const InterestReserve = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Reserve'}
      name={'interestReserveMonths'}
      value={formFields.interestReserveMonths.value}
      errorText={formFields.interestReserveMonths.error}
      error={isNotBlank(formFields.interestReserveMonths.error)}
      options={interestReserveOptions}
      onChange={value => { store.onChange('interestReserveMonths', value) }}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestReserveMonths"
    />
  );
});

export const InterestRevervedFinanced = observer(({ store, getNewRateFloor }) => {
  const formFields = store.form.fields;
  return (
    <RadioField
      standaloneLabel
      label="Add financed interest reserve?"
      value={formFields.interestReserveFinanced.value}
      errorText={formFields.interestReserveFinanced.error}
      error={isNotBlank(formFields.interestReserveFinanced.error)}
      name={'interestReserveFinanced'}
      options={yesNoOptions}
      onChange={value => {
        store.onChange('interestReserveFinanced', value)
        getNewRateFloor();
      }}
      row
      required
      testId="interestReserveFinanced"
    />
  );
});

const Participation = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Participation'}
      name={'participation'}
      value={formFields.participation.value}
      errorText={formFields.participation.error}
      error={isNotBlank(formFields.participation.error)}
      options={participationOptions}
      onChange={value => store.onChange('participation', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="participation"
      {...rest}
    />
  );
});
const ParticipationPercentage = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Participation Percentage'}
      name={'origLenderSubordination'}
      value={formFields.origLenderSubordination.value}
      errorText={formFields.origLenderSubordination.error}
      error={isNotBlank(formFields.origLenderSubordination.error)}
      options={participationPercetangeOptions}
      onChange={value => store.onChange('origLenderSubordination', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="origLenderSubordination"
      {...rest}
    />
  );
});
const UnderwritingMessage = ({ bulletPoints, bulletPointStyle, listItemStyle }) => {
  return <>
    <Typography>Please note that an underwriter pre-screen will be required in order to provide a quote on all loans with either of the following criteria:</Typography>
    <List className={bulletPointStyle}>
      {bulletPoints?.map((bulletPoint) => <ListItem className={listItemStyle}>{bulletPoint}</ListItem>)}
    </List>
    <Typography variant="body1">Please submit the loan at your requested terms and be sure to provide any and all additional information to support credit decision. Final terms are subject to underwriting and lender discretion.</Typography></>
}
const LoanEconomicsSection = observer(({ store, loanType, enabledFinanceIrAndCatchUp, hasMultipleProperties, bulletPointStyle, getInterestRateOptions, listItemStyle, hasPermitsInPlace, ...rest }) => {
  if (loanType == LoanSubType.GROUND_UP && enabledFinanceIrAndCatchUp && !hasMultipleProperties()) {
    return hasPermitsInPlace() ? (
      <>
        <Grid xs={12} container wrap="nowrap" style={{ marginBottom: '-60px' }}>
          <Grid item xs={12} md={4}>
            <InterestRevervedFinanced
              store={store}
              getNewRateFloor={() => {
                getInterestRateOptions();
              }}
            />
          </Grid>
        </Grid>
      </>
    ) : (
      <>
        <Grid xs={12} container wrap="nowrap" style={{ marginTop: 24 }}>
          <Grid item xs={12} md={4}>
            <InterestRevervedFinanced
              store={store}
              getNewRateFloor={() => {
                getInterestRateOptions();
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  return <>
    {
      !enabledFinanceIrAndCatchUp && <>
        <Typography variant="h4">Loan Economics</Typography>
        <br />
        <Typography variant="body1">
          Let's do some math here with the loan terms and pricing
        </Typography>
      </>
    }
  </>
});

function LoanInformation({ store, editMode = false, ...props }) {
  const { globalStore } = useBaseStore();
  const { lenderDetailsStore } = useStore();
  const {
    loanInformationStore,
    loanTerms,
    loanSubtype,
    isMultifamilyBridge,
    canSubmitAppraisalWithTamarisk,
    properties,
    brokersStore,
    stepErrors,
    stepWarnings,
    quoteGeneratorDetailsStore,
  } = store;
  const { form } = loanInformationStore;
  const classes = useStyles();
  const { isFacoOriginator } = loanInformationStore.loanParticipantsStore;
  const disabledField = store.isDealRoom;
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const { loanCommissionStore } = useLoanCommissionStore();

  const { quoteCalculationDetails: { bridgePricerResponseFields } } = quoteGeneratorDetailsStore;

  const [interestRateOptions, setInterestRateOptions] = useState([]);
  const [matrixLeverageOpen, setMatrixLeverageOpen] = useState(false);

  useEffect(() => {
    if (allowLoanPricing && !editMode) {
      getInterestRateOptions();
    }
  }, [loanSubtype]);

  useEffect(() => {
    if (!editMode) {
      if (allowLoanPricing) {
        getInterestRateOptions();
      }
      loanInformationStore.getBrokerVendorClosingSettings();
      loanInformationStore.makeInitialCalculations();
      loanInformationStore.getLenderAttorneys();
      setUploads(loanInformationStore.licenseUpload);
      loanInformationStore.resetBrokerPointsBasedOnHud(brokersStore.onTheHud);
    }
  }, []);

  useEffect(() => {
    if (uploads !== loanInformationStore.licenseUpload && uploads.length > 0) {
      loanInformationStore.setLicenseUpload(uploads);
      lenderDetailsStore.lenderDocumentsStore.uploadDocument(`State License - ${properties[0].state}`, getMultipartFile(uploads[0]));
    }
  }, [uploads]);

  useEffect(() => {
    if (!editMode) {
      const formFields = form?.fields || {};
      if(!isBrokerUser && !isBorrowerUser) {
        loanCommissionStore?.getBridgeCommission({
          loanAmount: formFields.amount.value,
          rocRate: formFields.rate.value,
          rocPointsIn: formFields.points.value,
          fees: formFields.lenderUnderwritingFee.value + formFields.lenderProcessingFee.value,
          rocPointsOut: 0,
          referralFee: formFields?.referralFee.value,
        });
      }
      if (loanSubtype === LoanSubType.GROUND_UP && !isBrokerUser && globalStore.lenderInfo?.isInternal) {
        store.getProfitInfo();
      }
    }
  }, [form.fields.amount.value, form.fields.rate.value, form.fields.points.value, form.fields.lenderUnderwritingFee.value, form.fields.lenderProcessingFee.value, form.fields.referralFee.value, form.fields.brokerPoints.value]);

  const calculateTotal = (properties, afterRepairValueKey) => {
    return properties.reduce((total, property) => {
      return total + (property[afterRepairValueKey] || 0);
    }, 0);
  }

  const getInterestRateOptions = async () => {
    const actualDate = new Date();
    try {
      if (loanSubtype) {
        const response = await store.getRateFloor(
          {
            "submissionDate": actualDate,
            "lenderName": "",
            "financedIr": form?.fields?.interestReserveFinanced?.value === 'Y',
            "permitsInPlace": hasPermitsInPlace(),
            "arv": calculateTotal(properties, 'afterRepairValue'),
            "purchasePrice": calculateTotal(properties, 'purchasePrice'),
            "constructionCost": calculateTotal(properties, 'totalRenovationBudget'),
            "interestExpense": loanInformationStore.calculateInterest(),
            "enableFinancedIr": isGroundUpLoan(),
            "propertyState": properties[0]?.state,
            "loanSubtype": loanSubtype
          });
        filterRateOptions(response);
        if (loanSubtype === LoanSubType.GROUND_UP && !isBrokerUser && globalStore.lenderInfo?.isInternal) {
          store.getProfitInfo();
        }
        store.fetchBorrowerPointsOptions();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const filterRateOptions = (rate) => {
    const floorRate = rate.data;
    const options = rateOptions.filter((o) => {
      return o.value >= parseFloat(floorRate);
    })

    const lowest = options.find((o =>
      o.value === parseFloat(floorRate)
    ));

    if (!lowest) {
      options.push({
        label: floorRate + "%",
        value: floorRate
      });
    }

    options.sort((a, b) => a.value - b.value);
    setInterestRateOptions(options);
    if (!isMultifamilyBridge) {
      if (options.length > 0) {
        const parsedFloorRate = parseFloat(floorRate);

        if (loanInformationStore.form.fields.rate.value < parsedFloorRate) {
          loanInformationStore.onChange('rate', parsedFloorRate);
        }
      }
    }
  }

  const isGroundUpLoan = () => {
    return (
      loanSubtype === LoanSubType.GROUND_UP
    );
  };

  const hasPermitsInPlace = () => {
    return properties.length === 1 && properties[0]?.permitsInPlace
  }

  const hasMultipleProperties = () => {
    return properties.length > 1
  }

  const maxValueSliderInitialLoanAmount = (financeIR, currentMax, type, financeIrEnabled) => {
    if (isGroundUp(type) && financeIrEnabled) {
      if (hasPermitsInPlace()) {
        if (financeIR === "Y") {
          return 85;
        }
        else {
          return 75;
        }
      }
      else {
        if (financeIR === "Y") {
          return 85;
        }
        return 60;
      }
    }
    else {
      return currentMax;
    }
  }
  const maxValuesSliderConstructionHoldBack = (currentMax, type, financeIrEnabled) => {
    if (isGroundUp(type) && financeIrEnabled) {
      return 100;
    }
    else {
      return currentMax;
    }
  }

  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const isBrokerUser = userStore.userInformation?.roles?.includes(Roles.BROKER);
  const isBorrowerUser = userStore.userInformation?.roles?.includes(roleOptions.BORROWER.value);

  const preferredAttorneyFieldDisabled = store?.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO;

  const displayLenderUnderwritingFeeField = store?.loanSubtype !== LoanSubType.FIX_AND_FLIP_PRO && globalStore.userFeatures?.allowLenderUnderwritingFee;

  let lenderYouMake = 0;

  if (loanSubtype === LoanSubType.GROUND_UP && isInternal) {
    const pricerAmount = bridgePricerResponseFields?.yourPoints ? ((bridgePricerResponseFields?.yourPoints / 100) * (store.loanTerms?.amount ?? 0)) : 0;
    lenderYouMake = store.getTotalLenderFees + pricerAmount;
  }

  const getLoanSubmissionBody = () => {
    return (
      <React.Fragment>
        <br />
        <br />

        {!editMode && <>
          <Grid container spacing={10}>
            {isBrokerUser ? (
              <Grid item xs={12} md={4}>
                <Typography variant="h4">Pricing</Typography>
                <br />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <BrokerPoints store={loanInformationStore} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              allowLoanPricing &&
              <Grid item xs={12} md={4}>
                <Typography variant="h4">Pricing</Typography>
                <br />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <InterestRate
                      store={loanInformationStore}
                      options={
                        isMultifamilyBridge ? mfBridgeRateOptions : interestRateOptions
                      }
                      isMultifamilyBridge={isMultifamilyBridge}
                      getNewRateFloor={getInterestRateOptions}
                      financeIrEnabled={isGroundUpLoan}
                      disabled={disabledField}
                      loanSubtype={loanSubtype}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Points store={loanInformationStore} disabled={disabledField} />
                  </Grid>
                  {brokersStore.onTheHud ? (
                    <Grid item xs={12}>
                      <BrokerPoints store={loanInformationStore} />
                    </Grid>) :
                    (<Grid item xs={12}>
                      <ReferralFees store={loanInformationStore} />
                    </Grid>)}
                  {displayLenderUnderwritingFeeField &&
                    <Grid item xs={12}>
                      <LenderUnderwritingFee store={loanInformationStore} />
                    </Grid>
                  }
                  {displayLenderUnderwritingFeeField &&
                    <Grid item xs={12}>
                      <LenderProcessingFee store={loanInformationStore} />
                    </Grid>
                  }
                  {isMultifamilyBridge && (
                    <Grid item xs={12}>
                      <RocPoints store={loanInformationStore} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {!isBrokerUser && allowLoanPricing && loanSubtype === LoanSubType.GROUND_UP && isInternal && (
              <>
                <Grid item xs={12} md={4}>
                  <br />
                  <Paper>
                    <PricingModelTable data={store.rateAdjustmentsData} />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </>
            )}
            <Grid item xs={12} md={4}>
              <Typography variant="h4">Interest Mechanics</Typography>
              <br />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TermDuration store={loanInformationStore} disabled={disabledField} getNewRateFloor={getInterestRateOptions} />
                </Grid>
                {!isFixAndFlip(loanSubtype) && (
                  <>
                    <Grid item xs={12}>
                      <InterestAccrualMethod store={loanInformationStore} />
                    </Grid>
                    <Grid item xs={12}>
                      <InterestReserve store={loanInformationStore} />
                    </Grid>
                  </>
                )}
                {!isBrokerUser && (
                  <Grid item xs={12}>
                    {createTextField({
                      store: loanInformationStore,
                      testId: 'projectDescription',
                      fieldName: 'projectDescription',
                      label: 'Project Description',
                      multiline: true,
                      inputProps: { maxLength: 500, rows: 3 }
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
              <Grid item xs={12} md={4}>
                <Card variant="outlined" className={classes.totalAmountCard}>
                  <CardContent>
                    <Typography align="center" variant="h6" color="primary">
                      Your Commission %
                    </Typography>
                    <br />
                    <Typography align="center" variant="h4">
                      {loanCommissionStore?.commissionData?.commissionPercentage
                        ? formatPercentage(loanCommissionStore.commissionData.commissionPercentage, 4)
                        : '%'}
                    </Typography>
                    <br />
                    <Typography align="center" variant="h6" color="primary">
                      Your Commission Amount
                    </Typography>
                    <br />
                    <Typography align="center" variant="h4">
                      {loanCommissionStore?.commissionData?.commissionAmount
                        ? formatCurrency(loanCommissionStore.commissionData.commissionAmount)
                        : '$'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {/* {!isBrokerUser && !isInternal && (
              <Grid item xs={12} md={4}>
                <Card variant="outlined" className={classes.totalAmountCard}>
                  <CardContent>
                    <Typography align="center" variant="h6" color="primary">
                      You Make %
                    </Typography>
                    <br />
                    <Typography align="center" variant="h4">
                      {store.loanTerms?.amount > 0 ? formatPercentage(lenderYouMake * 100 / store.loanTerms?.amount, 4) : '%'}
                    </Typography>
                    <br />
                    <Typography align="center" variant="h6" color="primary">
                      You Make $
                    </Typography>
                    <br />
                    <Typography align="center" variant="h4">
                      {store.loanTerms?.amount > 0 ? formatCurrency(lenderYouMake) : '$'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )} */}
          </Grid>

          <br />
          <br />
        </>
        }
        {allowLoanPricing &&
          <Grid container spacing={10}>
            <Grid item xs={12} md={5}>
              <Typography variant="h4">Vendor and Closing Options</Typography>
              <br />
              <Grid container spacing={2} alignItems="center">
                {!editMode && globalStore.userFeatures?.allowParticipation &&
                  <>
                    <Grid item xs={12}>
                      <Participation
                        store={loanInformationStore}
                        disabled={isMultifamilyBridge}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ParticipationPercentage
                        store={loanInformationStore}
                        disabled={isMultifamilyBridge}
                      />
                    </Grid>
                  </>
                }
                {!editMode && (canSubmitAppraisalWithTamarisk &&
                  !globalStore.userFeatures?.tamariskAppraisal) && (
                    <Grid item xs={12}>
                      <SubmitAppraisalWithTamarisk store={loanInformationStore} />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <PreferredClosingAttorney store={loanInformationStore} disabled={preferredAttorneyFieldDisabled} />
                </Grid>
                {!editMode && <Grid item xs={12}>
                  <RequestedClosingDate store={loanInformationStore} />
                </Grid>
                }
              </Grid>
            </Grid>
            {!editMode && <Grid item xs={12} md={5}>
              <Typography variant="h4" color="primary">
                &nbsp;
              </Typography>
              <br />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <PreferredInsurance store={loanInformationStore} />
                </Grid>
                {loanTerms.preferredInsurance === OTHER && (
                  <Grid item xs={12}>
                    <SubmitButton
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      startIcon={<Add />}
                      testId="addOtherInsurance"
                      onClick={() =>
                        loanInformationStore.setOtherInsuranceCompany()
                      }
                      submitDialogProps={{
                        title: 'Insurance',
                        body: (
                          <CompanyForm
                            store={loanInformationStore.otherInsuranceFormStore}
                          />
                        ),
                        okButtonText: 'Confirm',
                        submitValidation: () => {
                          loanInformationStore.otherInsuranceFormStore.runFormValidation();
                          return loanInformationStore.otherInsuranceFormStore.form
                            .meta.isValid;
                        },
                      }}
                    >
                      ADD INFO
                    </SubmitButton>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PreferredTitleCompany store={loanInformationStore} />
                </Grid>
                {loanTerms.preferredTitle === OTHER && (
                  <Grid item xs={12}>
                    <SubmitButton
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      startIcon={<Add />}
                      testId="addOtherTitle"
                      onClick={() => loanInformationStore.setOtherTitleCompany()}
                      submitDialogProps={{
                        title: 'Title',
                        body: (
                          <CompanyForm
                            store={loanInformationStore.otherTitleFormStore}
                          />
                        ),
                        okButtonText: 'Confirm',
                        submitValidation: () => {
                          loanInformationStore.otherTitleFormStore.runFormValidation();
                          return loanInformationStore.otherTitleFormStore.form.meta
                            .isValid;
                        },
                      }}
                    >
                      ADD INFO
                    </SubmitButton>
                  </Grid>
                )}
                {globalStore.userFeatures.fastTrack && isNil(store.editLoanJson.loanId) &&
                  (loanInformationStore.form.fields.preferredTitle.value ||
                    loanInformationStore.form.fields.preferredInsurance.value) && (
                    <Grid item xs={12}>
                      <FastTrackLoan
                        store={loanInformationStore}
                        sendPaymentEmail={() => store.sendFastTrackPayment()}
                        propertiesQuantity={store.properties.length}
                      />
                    </Grid>
                  )}
              </Grid>
            </Grid>
            }
          </Grid>
        }
        {!editMode && <>
          <Grid container spacing={10}>
            {isFacoOriginator ? (
              <FacoParticipants xs={12} md={5} store={loanInformationStore} />
            ) : (
              <LenderParticipants
                xs={12}
                md={5}
                store={loanInformationStore}
                originatorFieldName="loanOriginator"
              />
            )}
          </Grid>
          {/*globalStore.userFeatures?.showFreeAppraisalPromotion &&
            <Grid container spacing={10}>
              <Grid item xs={12} md={5}>
                <Typography variant="h4" style={{ marginBottom: '8px' }}>Promotions</Typography>
                <AppraisalPromotion
                  store={loanInformationStore} />
              </Grid>
            </Grid>
          */}
          <Grid container spacing={10} style={{ paddingTop: '15px' }}>
            <Grid item xs={12} md={5}>
              {globalStore.lenderInfo?.showTPOLicenseValidation && properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
                !globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) && (
                  <StateLicenseUpload store={loanInformationStore} setUploads={setUploads} property={properties[0]} />
                )}
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </>
        }
      </React.Fragment>
    );
  };

  return (
    <>
      <Grid container spacing={10} alignItems="center">
        <Grid item xs={12} style={{ paddingBottom: '0px' }}>
          {hasMultipleProperties() && isGroundUpLoan() && <Grid style={{ marginBottom: 24 }}>
            <UnderwritingMessage bulletPointStyle={classes.list} listItemStyle={classes.listItem} bulletPoints={['loans with multiple properties', '>$1,000,000 loan amount']} />
          </Grid>}
          {isFixAndFlip(loanSubtype) ? (
            <>
              <Typography variant="h4">Loan Terms</Typography>
              <br />
              <UnderwritingMessage bulletPoints={['<660 fico', '>$1,000,000 loan amount']} bulletPointStyle={classes.list} listItemStyle={classes.listItem} />
              <StandardDialog
                open={matrixLeverageOpen}
                title="How did we come up with this leverage?"
                handleClose={() => { setMatrixLeverageOpen(false) }}
                dialogContent={(
                  <ExperienceMatrix store={store} />
                )}
                maxWidth='lg'
                dialogActions={
                  <>
                    <Button
                      style={{ marginRight: '8px' }}
                      onClick={() => { setMatrixLeverageOpen(false) }}
                      color="primary"
                      variant="outlined"
                      testId="cancel"
                    >
                      Close
                    </Button>
                  </>
                }
              />
            </>
          ) : (
            <LoanEconomicsSection hasMultipleProperties={hasMultipleProperties} getInterestRateOptions={getInterestRateOptions} store={loanInformationStore} hasPermitsInPlace={hasPermitsInPlace} bulletPointStyle={classes.list} listItemStyle={classes.listItem} enabledFinanceIrAndCatchUp={isGroundUpLoan()} loanType={loanSubtype} />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {loanSubtype === LoanSubType.GROUND_UP && (
            <>
              <Typography variant="h4">Loan Terms</Typography>
              <br />
            </>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InitialLoanAmount store={loanInformationStore} disabled={disabledField} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InitialLoanToPurchase
                maxValueSliderInitialLoanAmount={maxValueSliderInitialLoanAmount}
                maxLTC={store.maxLTC} store={loanInformationStore}
                loanSubtype={loanSubtype}
                financeIrEnabled={isGroundUpLoan}
                disabled={disabledField}
                getNewRateFloor={() => { getInterestRateOptions() }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConstructionHoldback
                store={loanInformationStore}
                disabled={disabledField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConstructionHoldbackPercentage
                store={loanInformationStore}
                loanSubtype={loanSubtype}
                disabled={isMultifamilyBridge || disabledField}
                financeIrEnabled={isGroundUpLoan}
                maxValuesSliderConstructionHoldBack={maxValuesSliderConstructionHoldBack}
                getNewRateFloor={() => { getInterestRateOptions() }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {
            <>
              <Card variant="outlined" className={classes.totalAmountCard}>
                <CardContent>
                  <Typography align="center" variant="h6" color="primary">
                    Total Loan Amount
                  </Typography>

                  <Typography align="center" variant="h4">
                    {form.fields.amount.value
                      ? formatCurrency(form.fields.amount.value)
                      : '$'}
                  </Typography>
                </CardContent>
              </Card>
              {isGroundUpLoan() && !hasMultipleProperties() && <><Grid style={{ marginTop: '48px' }} item xs={12} md={12}>
                <MaxLTFCSlider
                  store={store}
                  val={store?.rateAdjustmentsData}
                  loanSubtype={loanSubtype}
                  financeIrEnabled={isGroundUpLoan}
                  maxValuesSliderConstructionHoldBack={maxValuesSliderConstructionHoldBack}
                  getNewRateFloor={() => { getInterestRateOptions() }}
                />
              </Grid>
                <Grid style={{ marginTop: '24px' }} item xs={12} md={12}>
                  <MaxARLTVSlider
                    store={store}
                    loanSubtype={loanSubtype}
                    financeIrEnabled={isGroundUpLoan}
                    maxValuesSliderConstructionHoldBack={maxValuesSliderConstructionHoldBack}
                    getNewRateFloor={() => { getInterestRateOptions() }}
                  />
                </Grid>
              </>}
            </>
          }
          {isFixAndFlip(loanSubtype) &&
            <Box marginTop={'24px'}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<Build />}
                onClick={() => {
                  setMatrixLeverageOpen(true)
                }}
                testId="adjust-leverage"
              >
                HOW DID WE COME UP WITH THIS LEVERAGE?
              </Button>
            </Box>
          }
          {isFixAndFlip(loanSubtype) && !isBrokerUser ? (
            <>
              <Box marginTop={'24px'}>
                <SubmitButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<ShowChart />}
                  onClick={() => {
                    loanInformationStore.requestExceptionLeverage();
                  }}
                  testId="adjust-leverage"
                  submitDialogProps={{
                    title: 'Leverage Request',
                    body: <LeverageException store={store} />,
                    okButtonText: 'Request Leverage',
                    submitValidation: () => {
                      if (!loanInformationStore.form.fields.exceptionLeverageReasonUnconfirmed.value) {
                        loanInformationStore.form.fields.exceptionLeverageReasonUnconfirmed.error = 'Required';
                        return false;
                      }
                      return true;
                    }
                  }}
                >
                  REQUEST LEVERAGE EXCEPTION
                </SubmitButton>
                <Typography className={classes.disclaimerLabel}>You are welcome to request higher leverage, please be aware that it is subject to underwriter discretion. These exceptions should have strong credit, great experience, and good profit. </Typography>
              </Box>
            </>
          ) : null}
        </Grid>
      </Grid>
      {getLoanSubmissionBody()}
      <Grid>
        <Box>
          <ErrorsAlert errors={stepErrors} />
        </Box>
        <Box marginTop={'16px'}>
          <WarningsAlert warnings={stepWarnings} />
        </Box>
      </Grid>
    </>
  );
}

export default observer(LoanInformation);
