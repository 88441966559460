import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { InsuranceInformationReview } from '@roc/feature-insurance-review';
import { InsuranceInformationInternal } from '../components/insuranceInformationInternal';

export const getInsuranceInformationInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    insuranceInformation: {
      path: `${basePath}/insurance-information`,
      url: `${baseUrl}/insurance-information`,
      documentTitle: 'Insurance Information',
    },
    insuranceInformationReview: {
      path: `${basePath}/insurance-information-review`,
      url: `${baseUrl}/insurance-information-review`,
      documentTitle: 'Insurance Information Review',
    },
  };
};

export const getInsuranceInformationInternalRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: string,
  loanDocumentId: string,
) => {
  const config = getInsuranceInformationInternalRoutesConfig(basePath, baseUrl);
  const {
    insuranceInformationStore,
    insuranceInformationReviewStore
  } = useRocInternalPortalStore();

  return {
    insuranceInformation: (
      <Route exact path={config.insuranceInformation.path}>
        <Page routeDefinition={config.insuranceInformation}>
          <InsuranceInformationInternal
            store={insuranceInformationStore}
            loanId={loanId}
            loanDocumentId={loanDocumentId}
          />
        </Page>
      </Route>
    ),
    insuranceInformationReview: (
      <Route exact path={config.insuranceInformationReview.path}>
        <Page routeDefinition={config.insuranceInformationReview}>
          <InsuranceInformationReview
            store={insuranceInformationReviewStore}
            loanId={loanId}
          />
        </Page >
      </Route >
    ),
  };
};
