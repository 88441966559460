import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { makeObservable } from 'mobx';
import { ExternalAgentLoanService } from '../services/externalAgentLoanService';

export class ExternalLoansDashboardStore {
  private globalStore: GlobalStore;
  private externalLoanService: ExternalAgentLoanService;

  foreclosureLoansGridStore: GridStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.externalLoanService = new ExternalAgentLoanService();

    this.foreclosureLoansGridStore = new GridStore(
      () => this.fetchforeclosureLoans(),
      null,
      50
    );

    makeObservable(this, {});
  }

  private async fetchforeclosureLoans() {
    try {

      const filters = {
        ...this.foreclosureLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.externalLoanService.getExternalAgentForeclosureloans(
        this.foreclosureLoansGridStore.gridData.meta.pageNumber,
        this.foreclosureLoansGridStore.gridData.meta.pageSize,
        this.foreclosureLoansGridStore.gridData.meta.sortDir,
        this.foreclosureLoansGridStore.gridData.meta.sortBy,
        filters,
        this.foreclosureLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching title foreclosure loans.',
      });
    }
  }
}
