
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore'
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import { useLoanStore } from '@roc/feature-loans';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { LoanSubType, LoanType, sanitizeTestId, insertIf } from '@roc/feature-utils';
import LoanSummaryExpandableTable from '../common/loanSummaryExpandableTable';

export const Commissions = observer(({ loanId }) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanCommissionStore } = useLoanCommissionStore();
  const [data, setData] = useState([])
  const { loanStore } = useLoanStore();
  const { loanSummary } = loanSummaryStore;
  const { commissionData } = loanCommissionStore;
  const { loanDetails } = loanStore;

  useEffect(() => {
    loanCommissionStore.resetStore();
    loanSummaryStore.fetchLoanSummary(loanId);
  }, [loanId]);

  useEffect(() => {
    if (loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE) {
      const totalFees = getTotalBridgeFees(loanDetails?.loanLenderDetails);
      loanCommissionStore.getBridgeCommission({
        loanAmount: loanSummary?.amount,
        rocRate: loanSummary?.rocRate,
        rocPointsIn: loanSummary?.rocPointsIn,
        fees: totalFees ?? 0,
        rocPointsOut: loanSummary?.rocPointsOut,
        referralFee: loanSummary?.referralFee,
      });
    }
    if (loanSummary?.loanType === LoanType.RESIDENTIAL_TERM) {
      const retailFees = getTotalTermFees(loanSummary?.loanRetailFees);
      loanCommissionStore.getTermCommission({
        loanAmount: loanSummary?.amount,
        rocPoints: loanSummary?.loanRetailFees?.retailPoints,
        fees: retailFees ?? 0,
        lenderPremium: loanSummary?.lenderPremium,
        totalPremium: loanSummary?.totalPremium,
        referralFee: loanSummary?.referralFee,
      });
    }
  }, [loanSummaryStore.loanSummary, loanStore.loanDetails]);

  const sum = (a, b) => a + b;

  const getTotalTermFees = fees => {
    if (fees) {
      const retailCustomFees = fees.retailCustomFees
        ? JSON.parse(fees.retailCustomFees)
        : [];
      const customFeeTotal = retailCustomFees
        .map(customFee => parseFloat(customFee.retailValue || 0))
        .reduce(sum, 0);
      return (
        customFeeTotal +
        parseFloat(loanSummary?.rocUnderwritingFees ?? 0) +
        parseFloat(loanSummary?.processingFee ?? 0) +
        parseFloat(fees.retailProcessingFee ?? 0) +
        parseFloat(fees.retailAdminFee ?? 0) +
        parseFloat(fees.retailUnderwritingFee ?? 0) +
        parseFloat(fees.retailCommitmentFee ?? 0) +
        parseFloat(fees.retailBuydownFee ?? 0)
      );
    }
  };

  const getTotalBridgeFees = fees => {
    if (fees) {
      return (
        parseFloat(loanSummary?.underwritingFee ?? 0) +
        parseFloat(loanSummary?.processingFee ?? 0) +
        parseFloat(fees.processingFee ?? 0) +
        parseFloat(fees.adminFee ?? 0) +
        parseFloat(fees.underwritingFee ?? 0) +
        parseFloat(fees.commitmentFee ?? 0) +
        parseFloat(fees.lenderBuydownFee ?? 0)
      );
    }
  };

  const isBridge = loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE;
  const isTerm = loanSummary?.loanType === LoanType.RESIDENTIAL_TERM;
  const isMultifamilyBridge =
    loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;

  useEffect(() => {
    if (isBridge) {
      setData([
        {
          left: 'Loan Amount',
          right: formatCurrency(loanSummary?.amount ?? 0)
        },
        {
          left: 'Rate',
          right: formatPercentage(loanSummary?.rocRate ?? 0)
        },
        {
          left: 'Points',
          right: formatPercentage(loanSummary?.rocPointsIn ?? 0)
        },
        {
          left: 'Total Fees',
          right: formatCurrency(
            getTotalBridgeFees(loanDetails?.loanLenderDetails) ?? 0)
        },
        {
          left: 'Your Commission %',
          right: formatPercentage(commissionData?.commissionPercentage ?? 0, 4)
        },
        ...insertIf(!isMultifamilyBridge, [{
          left: 'Your Commission Amount',
          right: formatCurrency(commissionData?.commissionAmount ?? 0)
        }]),
        ...insertIf(isMultifamilyBridge, [{
          left: 'Your Closing Commission Amount',
          right: formatCurrency(commissionData?.commissionAmount ?? 0)
        },
        {
          left: 'Your Payoff Commission Amount',
          right: formatCurrency(commissionData?.exitCommissionAmount ?? 0)
        }
        ])
      ])
    }
    if (isTerm) {
      setData([
        {
          left: 'Loan Amount',
          right: formatCurrency(loanSummary?.amount ?? 0)
        },
        {
          left: 'Yield Spread Premium',
          right: formatPercentage(loanSummary?.lenderPremium ?? 0)
        },
        {
          left: 'Add-on Origination Points',
          right: formatPercentage(loanSummary?.loanRetailFees?.retailPoints ?? 0)
        },
        {
          left: 'Rate Buydown Points',
          right: formatPercentage(loanSummary?.rocPointsIn ?? 0)
        },
        {
          left: 'Total Fees',
          right: formatCurrency(
            getTotalTermFees(loanSummary?.loanRetailFees) ?? 0
          )
        },
        {
          left: 'Your Commission %',
          right: formatPercentage(commissionData?.commissionPercentage ?? 0, 4)
        }, {
          left: 'Your Commission Amount',
          right: formatCurrency(commissionData?.commissionAmount ?? 0)
        }
      ])
    }
  }, [loanSummary, commissionData])

  return (
    <>
      <LoanSummaryExpandableTable
        title='Commissions'
        data={data}
        hasTwoColumns={false}
      />
    </>
  );
});