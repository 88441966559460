import { useEffect } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs, Paper, GridColumnProps } from '@roc/ui';
import { observer } from 'mobx-react';
import { ProtectiveAdvancesGrid } from '../protectiveAdvancesGrid';
import { CounterPartyExpensesTab } from '../../types/types';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';
import { formatCurrency, filterProps, noOpComparator } from '@roc/ui/utils';
import { ReceivablesToolbar } from './receivablesToolbar';
import { insertIf } from '@roc/feature-utils';
import { useDocumentStore } from '@roc/feature-documents';
import { CloudDownload } from '@material-ui/icons';

const TABS = [
  {
    value: CounterPartyExpensesTab.PENDING_RECEIVABLE,
    label: 'Pending',
  },
  {
    value: CounterPartyExpensesTab.ALL_RECEIVABLE,
    label: 'All',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
}));



export const ReceivablesDashboard = observer(() => {
  const classes = useStyles();
  const { receivablesDashboardStore } = useReceivablesDashboardStore();
  const { tab } = receivablesDashboardStore;
  const { documentStore } = useDocumentStore();

  useEffect(() => {
    receivablesDashboardStore.fetchEntityNames();
    return () => {
      receivablesDashboardStore.reset();
    };
  }, []);

  const actions = (record, handleMenuClose) => {

    return (
      <MenuItem
        disabled={!record.documentId}
        onClick={() => {
          const document = {
            loanTaskId: record.documentId,
            originalFileName: record.originalFileName,
          };
          documentStore.downloadDocumentOriginalNameById(document);
          handleMenuClose();
        }}
      >
        <ListItemIcon>
          <CloudDownload fontSize="small" />
        </ListItemIcon>
        <ListItemText>Download Invoice</ListItemText>
      </MenuItem>
    );
  }

  const RECEIVABLES_COLUMNS: GridColumnProps[] = [

    {
      field: 'loanId',
      headerName: 'Loan Id',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'entity',
      headerName: 'Investor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'vendorName',
      headerName: 'Vendor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'invoice',
      headerName: 'Invoice #',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'feeType',
      headerName: 'Type',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    ...insertIf(tab === CounterPartyExpensesTab.PENDING_RECEIVABLE, [{
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
      editable: true,
      cellEditor: 'dropdownGridEditor',
    },
    ]),
    ...insertIf(tab === CounterPartyExpensesTab.ALL_RECEIVABLE, [{
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    ]),
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'createdDate',
      headerName: 'Creation Date',
      minWidth: 125,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  return (
    <Layout title="Receivables" containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(TABS)}
          onTabChange={(e, i) => receivablesDashboardStore.setTab(TABS[i].value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <ProtectiveAdvancesGrid
              counterPartyExpenseStore={receivablesDashboardStore}
              columns={RECEIVABLES_COLUMNS}
              selectModeEnabled={tab === CounterPartyExpensesTab.PENDING_RECEIVABLE}
              toolbar={
                <ReceivablesToolbar
                  receivablesDashboardStore={receivablesDashboardStore}
                />}
              actions={actions}
            />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
