import { observer } from 'mobx-react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AgGridColumnProps, ConfirmationMenuItem, DataGrid, SelectMode, StandardDialog, SubmitMenuItem } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Box, Button, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Modal, useTheme, Popover, makeStyles } from '@material-ui/core';
import { GridStore, InfiniteScrollGrid } from '@roc/feature-app-core';
import { Check, Edit, ImportExport, LocalActivity, MoreVert, Note, NoteOutlined, SpeakerNotesOff, WorkOff, SupervisedUserCircle, Phone, RemoveCircle, Assignment, Sms, Email, Visibility } from '@material-ui/icons';
import { ConvertLeadToLenderModal } from '../../leadsDashboard/convertLeadToLenderModal';
import { useHistory } from 'react-router';
import { useLeadDetailsRoutes } from '../../../routes/loanRoutes';
import { DateFormat, formatDate } from '@roc/feature-utils';
import { useNewLeadStore } from '../../../hooks/useNewLeadStore';
import { Link } from 'react-router-dom';
import { ReasonToRemoveLeadFromQueue } from 'libs/client-portal-shared/src/app/modules/companyProfile/types/companyProfileTypes';
import { useBorrowerDetailsRoutes } from '../../../routes/borrowerDetailsRoutes';
import { useTPOContactDetailsRoutes } from '../../../routes/tpoContactDetailsRoutes';
import { formatPhoneNumber } from '@roc/ui/utils';
import { EditLeadActivityDialog } from '../../notesAndActivities/editLeadActivityDialog';
import { EditLeadStatusDialog } from '../../leadDetails/editLeadStatusDialog';
import { BORROWER_OBJECT_TYPE, BROKER_OBJECT_TYPE, TPO_OBJECT_TYPE, LEAD_OBJECT_TYPE, STANDARD_DASHBOARD_TYPE, OUTSIDE_CREDIT_INQUIRY, CHANGE_LEAD_OWNER, CHANGE_OWNER, CHANGE_RELATIONSHIP_MANAGER, ADD_ACTIVITY, ADD_VIEW_NOTES, CHANGE_STATUS, UNABLE_TO_CONTACT, LEAD_NOT_INTERESTED, CONTACTED, CONVERT_TO_LENDER, ADD_TASK, ADD_BROKER_RELATION, ADD_BROKER_AS_TPO } from '../constants/constants';
import { ChangeRelationshipManagerModal } from '../../newLead/changeRelationshipManagerModal';
import { BulkLeadChangeOwner } from '../../leadsDashboard/bulkLeadChangeOwner';
import { SendBulkMessagesModal } from 'apps/crm-portal/src/app/modules/ringCentral/sendBulkMessages';
import { SendBulkEmailsModal } from '../../leadsDashboard/sendBulkEmails';
import { ViewAndAddNotesDialog } from '../../notesAndActivities/viewAndAddNotesDialog';
import { LeadsDashboardStore } from '../../../stores/leadsDashboardStore';
import { EditLeadTaskDialog } from '../../notesAndActivities/editLeadTaskDialog';
import { AddBrokerRelation } from '../../leadsDashboard/addBrokerRelation';
import RecordDetails from './recordDetails';
import { AddBrokerAsTPO } from '../../leadsDashboard/addBrokerAsTPO';


export const isLeadDashboard = (leadsDashboardStore: LeadsDashboardStore) => {
  return leadsDashboardStore.currentDashboard?.objectType?.toUpperCase() === LEAD_OBJECT_TYPE || leadsDashboardStore.currentDashboard?.baseObject?.toUpperCase() === LEAD_OBJECT_TYPE;
}

// This component listens for changes on the field
// salesforceLeadStore.leadIds and updates the corresponding rows on the grid
const ListenForSelectedRow = observer(({ gridStore, params }) => {
  const { salesforceLeadStore } = useStore();
  const { newLeadStore } = useNewLeadStore();

  useEffect(() => {
    newLeadStore.setGlobalSearch(false);

    let selected = false;

    if (!isLeadDashboard(salesforceLeadStore.leadsDashboardStore)) {
      selected = salesforceLeadStore.borrowerIds.includes(params.node.data?.contactId);
    } else {
      selected = salesforceLeadStore.leadIds.includes(params.node.data?.leadId);
    }

    params.node.setSelected(selected);
  }, [
    gridStore.gridData.data.rows,
    salesforceLeadStore.leadsDashboardStore.currentDashboard,
    salesforceLeadStore.leadIds,
    salesforceLeadStore.borrowerIds,
    params.node
  ]);

  return null;
});

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '0px'
  },
}));

export const LeadsDashboardGrid = observer(({ toolbar, columns, gridStore, showMarkAsContacted }:
  { toolbar: ReactNode, columns: AgGridColumnProps[], gridStore: GridStore, showMarkAsContacted?: boolean }) => {
  const { palette } = useTheme();
  const { salesforceLeadStore, userStore, lenderDetailsStore, globalStore } = useStore();
  const { newLeadStore } = useNewLeadStore();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [anchorElForPhoneNumberMenu, setAnchorElForPhoneNumberMenu] = useState<HTMLElement>(null);
  const [anchorElForEmailMenu, setAnchorElForEmailMenu] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const phoneNumberMenuOpen = Boolean(anchorElForPhoneNumberMenu);
  const emailMenuOpen = Boolean(anchorElForEmailMenu);
  const [leadName, setLeadName] = useState({});
  const [record, setRecord] = useState<any>({});
  const { leadDetailsRoutesConfig } = useLeadDetailsRoutes();
  const { borrowerDetailsRoutesConfig } = useBorrowerDetailsRoutes();
  const { tpoContactDetailsRoutesConfig } = useTPOContactDetailsRoutes();
  const { phoneBurnerStore } = useStore();
  const { leadsDashboardStore, ringCentralStore, sendEmailsStore } = salesforceLeadStore;
  const { currentDashboardId, dashboardActions } = leadsDashboardStore;
  const [openLeadStatusDialog, setOpenLeadStatusDialog] = useState(false);
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const BORROWER_RECORD_TYPE = 'Borrower';
  const CONFLICTS_QUEUE = 'conflicts_queue'
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [openSendBulkMessagesModal, setOpenSendBulkMessagesModal] = useState(false);
  const [openSendBulkEmailModal, setOpenSendBulkEmailModal] = useState(false);
  const actionsOnDashboard = dashboardActions?.actions;
  const actionKeys = actionsOnDashboard ? Object.keys(actionsOnDashboard) : [];
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState(gridStore.gridData.data.rows);
  const currentDashboard = leadsDashboardStore.currentDashboard;
  const [anchorPOP, setAnchorPOP] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const classes = useStyles();

  function checkIfIsConflictsDashboard(currentDashboard) {
    return currentDashboard === CONFLICTS_QUEUE
  }

  function checkActionsForDashboard(actions, componentAction) {
    return actions.includes(componentAction);
  }

  useEffect(() => {
    ringCentralStore.getSmsCount();
  }, []);

  useEffect(() => {
    if (sendEmailsStore.emailsSentSuccessfuly) {
      const currentPage = leadsDashboardStore.leadsDashboardGridStore.gridData.meta.pageNumber;
      leadsDashboardStore.leadsDashboardGridStore.reset();
      leadsDashboardStore.leadsDashboardGridStore.setPageNumber(currentPage, true);
      sendEmailsStore.emailsSentSuccessfuly = false;
    }
  }, [sendEmailsStore.emailsSentSuccessfuly]);

  useEffect(() => {
    if (ringCentralStore.smsSentSuccessfuly) {
      const currentPage = leadsDashboardStore.leadsDashboardGridStore.gridData.meta.pageNumber;
      leadsDashboardStore.leadsDashboardGridStore.reset();
      leadsDashboardStore.leadsDashboardGridStore.setPageNumber(currentPage, true);
      ringCentralStore.smsSentSuccessfuly = false;
    }
  }, [ringCentralStore.smsSentSuccessfuly]);

  const updatedColumns = columns.map(column => {
    if (["id", "name", "fullName", "email", "phone"].includes(column.field)) {
      return { ...column, pinned: 'left', lockPinned: true };
    }
    return column;
  });

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEmailMenuClose = () => {
    sendEmailsStore.reset();
    setAnchorElForEmailMenu(null);
  };

  const handlePhoneNumberMenuClose = () => {
    ringCentralStore.reset();
    setAnchorElForPhoneNumberMenu(null);
  };

  const handleSendSMS = () => {
    switch ((leadsDashboardStore.currentDashboard?.objectType || leadsDashboardStore.currentDashboard?.baseObject)?.toUpperCase()) {
      case LEAD_OBJECT_TYPE:
        salesforceLeadStore.setRecordType('Lead');
        break;
      case TPO_OBJECT_TYPE:
        salesforceLeadStore.setRecordType('TPO');
        break;
      case BROKER_OBJECT_TYPE:
        salesforceLeadStore.setRecordType('Broker');
        break;
      case BORROWER_OBJECT_TYPE:
        salesforceLeadStore.setRecordType('Borrower');
        break;
    }
    setOpenSendBulkMessagesModal(true);
    handleMenuClose();
  };

  const handleSendEmail = () => {
    setOpenSendBulkEmailModal(true);
    handleMenuClose();
    setAnchorElForEmailMenu(null);
  };


  const setOpenChangeRM = async (lenderId) => {
    await lenderDetailsFormStore.loadDataToChangeRM(lenderId);
    newLeadStore.setOpenChangeRmModal(true);
  };

  const setOpenBulkLeadChangeOwnerBorrower = (borrowerId) => {
    salesforceLeadStore.setIsBorrower(true);
    salesforceLeadStore.setRecordType('Borrower');
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setLeadsIds(borrowerId, true);
    newLeadStore.setOpenBulkLeadChangeOwnerModal(true);
  };

  const setOpenBulkLeadChangeOwnerLead = (leadId) => {
    salesforceLeadStore.setIsBorrower(false);
    salesforceLeadStore.setRecordType('Lead');
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setLeadsIds(leadId, true);
    newLeadStore.setOpenBulkLeadChangeOwnerModal(true);
  };

  const setOpenBulkLeadChangeOwnerBroker = (leadId) => {
    salesforceLeadStore.setIsBorrower(false);
    salesforceLeadStore.setRecordType('Broker');
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setLeadsIds(leadId, true);
    newLeadStore.setOpenBulkLeadChangeOwnerModal(true);
  };


  const handleEditStatusClose = () => {
    setOpenLeadStatusDialog(false)
    gridStore.fetchGridData();
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    gridStore.setFilterModel(filterModel);
  };

  const getActiveDashboardValues = data => {
    if (data?.leadId) {
      return {
        id: data?.leadId,
        routeConfig: id => leadDetailsRoutesConfig.leadDetails(id),
      };
    } else if (data?.borrowerId) {
      return {
        id: data?.borrowerId,
        routeConfig: id => borrowerDetailsRoutesConfig.borrowerDetails(id),
      };
    }

    return {
      id: data?.companyContactId,
      routeConfig: id => tpoContactDetailsRoutesConfig.tpoContactDetails(id, data?.recordType),
    };
  };

  const redirectCellRenderer = (params, propertyName) => {
    const data = getActiveDashboardValues(params.node.data);
    return (
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Link style={{ color: palette.primary.dark }}
          to={data.routeConfig(data.id).url}
          target="_blank">
          <strong>{params.value}</strong>
        </Link>
      </div>
    )
  };

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => formatDate(value, DateFormat.MMDDYYYY) ?? null,
    dateWithoutTimeCellRenderer: ({ value }) => formatDate(value, DateFormat.MMDDYYYY) ?? null,
    firstNameCellRenderer: params => redirectCellRenderer(params, 'firstName'),
    lastNameCellRenderer: params => redirectCellRenderer(params, 'lastName'),
    companyCellRenderer: params => redirectCellRenderer(params, 'company'),
    actionCellRenderer: params => {
      return (
        <>
          <IconButton onClick={e => openMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>
          <ListenForSelectedRow gridStore={gridStore} params={params} />
        </>
      );
    },
    orderCellRenderer: params => {
      return <OrderId params={params} store={leadsDashboardStore} />
    },
    phoneCellRenderer: params => {
      const phoneNumber = formatPhoneNumber(params.value);

      return (
        <div onClick={e => openPhoneNumberMenu(e, params)}>
          <a style={{ textDecoration: 'underline', color: '#5c94de', cursor: 'pointer' }} target="_blank" >
            {phoneNumber}
          </a>
        </div>
      );
    },
    emailCellRenderer: params => {
      return (
        <div onClick={e => openSendGridMenu(e, params)}>
          <a style={{ textDecoration: 'underline', color: '#5c94de', cursor: 'pointer' }} target="_blank" >
            {params.value}
          </a>
        </div>
      );
    },
    primaryContactCellRenderer: params => {
      const displayText = params.value ? 'Yes' : 'No';

      return (
        <div >
          <span>
            {displayText}
          </span>
        </div>
      );
    },
    detailsCellRenderer: params => {
      const data = params.node.data;
      return (
        <div onClick={(e) => handleClick(e, data)}>
          <Visibility style={{ cursor: 'pointer' }} />
        </div>
      );
    },
  };

  const handleClick = (event, record) => {
    setAnchorPOP(event.currentTarget);
    setSelectedRecord(record);
  };

  const handleClose = () => {
    setAnchorPOP(null);
    setSelectedRecord(null);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setLeadName(record.firstName + ' ' + record.lastName)
    setAnchorEl(event.currentTarget);
  };

  const openPhoneNumberMenu = (event: React.MouseEvent<HTMLElement>, params) => {
    const objectType = leadsDashboardStore.currentDashboard?.objectType ?? leadsDashboardStore.currentDashboard?.baseObject?.toUpperCase()
    setRowIds(params, objectType);
    if (isLeadDashboard(leadsDashboardStore)) {
      ringCentralStore.addStandaloneRecord(params?.node.data.leadId, params?.node.data, leadsDashboardStore.currentDashboard?.objectType);
    } else {
      ringCentralStore.addStandaloneRecord(params?.node.data.contactId, params?.node.data, objectType);
    }
    setPhoneNumber(params.value);
    setAnchorElForPhoneNumberMenu(event.currentTarget);
  };

  const openSendGridMenu = (event: React.MouseEvent<HTMLElement>, params) => {
    const objectType = getDashboardObjectTypeOrBaseObjectUppercase();
    sendEmailsStore.addStandaloneRecord(params?.node.data, objectType);
    setAnchorElForEmailMenu(event.currentTarget);
  };

  function onRowSelected(params) {
    const currentDashboardType = getDashboardObjectTypeOrBaseObjectUppercase();
    setRowIds(params, currentDashboardType);
    updateRingCentralAndEmailsLists(currentDashboardType);
  }

  function setRowIds(params, currentDashboardType) {
    if (currentDashboardType === BORROWER_OBJECT_TYPE) {
      salesforceLeadStore.setBorrowerIds(params?.data.contactId, params?.node.selected);
    } else if (currentDashboardType === TPO_OBJECT_TYPE || currentDashboardType === BROKER_OBJECT_TYPE) {
      salesforceLeadStore.setCompanyContactsIds(params?.data.contactId, params?.node.selected);
    } else {
      salesforceLeadStore.setLeadsIds(params?.data.leadId, params?.node.selected)
    }
  }

  function getCurrentDashboardSelectedRow(dashboardType) {
    if (dashboardType === BORROWER_OBJECT_TYPE) {
      return salesforceLeadStore.selectedBorrowerRecords;
    } else if (dashboardType === TPO_OBJECT_TYPE || dashboardType === BROKER_OBJECT_TYPE) {
      return salesforceLeadStore.selectedCompanyContactRecords;
    } else {
      return salesforceLeadStore.selectedLeadRecords;
    }
  }

  function updateRingCentralAndEmailsLists(dashboardType) {
    const selectedRecords = getCurrentDashboardSelectedRow(dashboardType);
    ringCentralStore.setRecordsFromGrid(selectedRecords, dashboardType);
    sendEmailsStore.setBulkEmailsFromGrid(selectedRecords, dashboardType);
    phoneBurnerStore.setRecordsFromGrid(selectedRecords, dashboardType);
    editLeadTaskStore.setRecordsFromGrid(selectedRecords, dashboardType);
  }
  const { leadsStore } = salesforceLeadStore;
  const { leadDetailsStore } = leadsStore;

  const { editLeadNoteStore } = leadDetailsStore;
  const { editLeadActivityStore } = leadDetailsStore;
  const { editLeadTaskStore } = leadDetailsStore;

  const closeModal = () => {
    newLeadStore.setOpenChangeRmModal(false);
    newLeadStore.setOpenBulkLeadChangeOwnerModal(false);
    salesforceLeadStore.resetLeadsIds();
    salesforceLeadStore.setIsBorrower(false);
    salesforceLeadStore.setMoveFundedLoans(false);
    salesforceLeadStore.setMoveNonFundedLoans(false);
    salesforceLeadStore.setIsFromPossibleMatches(false);
    salesforceLeadStore.setRecordType('');
    setOpenSendBulkMessagesModal(false);
    setOpenSendBulkEmailModal(false);
    ringCentralStore.setMessage('');
    ringCentralStore.setLabel('');
    ringCentralStore.setTemplateValue('');
    ringCentralStore.setTemplateId('');
    ringCentralStore.setTemplateLabel('');
    ringCentralStore.sendBulkSMSFormStore.onFieldChange('message', '');
    ringCentralStore.resetLists();
    ringCentralStore.reset();
    setOpenSendBulkMessagesModal(false);
    handleMenuClose();
    handlePhoneNumberMenuClose();
    lenderDetailsStore.setAddBrokerRelationModalOpen(null, false);
    lenderDetailsStore.setAddBrokerAsTPOModalOpen(null, false);
    sendEmailsStore.handleModalClose();
  }


  const isBorrowerDashboard = () => {
    return leadsDashboardStore.currentDashboard?.objectType === BORROWER_OBJECT_TYPE || leadsDashboardStore.currentDashboard?.baseObject?.toUpperCase() === BORROWER_OBJECT_TYPE;
  }


  const shouldRenderAction = (typeOfDashboard, typeOfAction) => {
    const dashboardType = getDashBoardType();
    return actionKeys.includes(dashboardType) &&
      Object.keys(actionsOnDashboard?.[typeOfDashboard] ?? {}).includes(userStore.userInfo.role) &&
      checkActionsForDashboard(actionsOnDashboard?.[typeOfDashboard]?.[userStore.userInfo.role] ?? {}, typeOfAction)
  }

  const getDashBoardType = () => {
    const objectType = leadsDashboardStore?.currentDashboard?.dashboardType !== STANDARD_DASHBOARD_TYPE ? leadsDashboardStore?.currentDashboard?.baseObject : leadsDashboardStore?.currentDashboard?.objectType
    return objectType ? objectType.toUpperCase() : objectType;
  }

  function getDashboardObjectTypeOrBaseObjectUppercase() {
    const currentDashboard = leadsDashboardStore.currentDashboard;

    if (currentDashboard) {
      return currentDashboard.objectType?.toUpperCase() ?? currentDashboard.baseObject?.toUpperCase();
    }

    return undefined;
  }

  const open = Boolean(anchorPOP);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <DataGrid
        key={`${currentDashboardId} - ${updatedColumns.length}`}
        className={classes.container}
        selectMode={SelectMode.MULTIPLE}
        headerCheckboxSelection={true}
        onRowSelected={e => onRowSelected(e)}
        suppressRowClickSelection={true}
        frameworkComponents={frameworkComponents}
        columns={updatedColumns}
        toolbar={toolbar}
        rows={gridStore.gridData.data.rows}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={gridStore.isLoading}
        showFilters={gridStore.showFilters}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={gridStore.setPageNumber}
        rowHeight={45}
        checkBoxColumnPinned={true}
        enablePaginationSelectPage={true}
      />
      < Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorElForPhoneNumberMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={phoneNumberMenuOpen}
        onClose={handlePhoneNumberMenuClose}
      >
        <ListItem button onClick={handleSendSMS}>
          <ListItemIcon>
            <Sms fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send SMS" />
        </ListItem>
        <ListItem button component="a" href={`rcapp://r/call?number=${phoneNumber}`} onClick={handlePhoneNumberMenuClose}>
          <ListItemIcon>
            <Phone fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Call" />
        </ListItem>
      </Menu >
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorElForEmailMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={emailMenuOpen}
        onClose={handleEmailMenuClose}
      >
        <ListItem button onClick={handleSendEmail}>
          <ListItemIcon>
            <Email fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send Email" />
        </ListItem>
      </Menu>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {shouldRenderAction(LEAD_OBJECT_TYPE, CONVERT_TO_LENDER) &&
          < SubmitMenuItem
            testId={`change-loan-status-`}
            onClick={() => {
              salesforceLeadStore.convertLeadToLender(record);
              handleMenuClose();
            }}
            submitDialogProps={{
              title: `Convert Lead to Lender`,
              body: <ConvertLeadToLenderModal record={record} />,
              okButtonText: 'Submit',
              submitValidation: () => {
                salesforceLeadStore.convertLeadToLenderStore.runFormValidation();
                return salesforceLeadStore.convertLeadToLenderStore.form.meta
                  .isValid;
              },
            }}
          >
            <ListItemIcon>
              <ImportExport fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Convert Lead</ListItemText>
          </SubmitMenuItem>
        }

        {shouldRenderAction(LEAD_OBJECT_TYPE, CONTACTED) && (
          <ConfirmationMenuItem
            testId={`mark-lead-as-contacted`}
            onClick={async () => {
              await salesforceLeadStore.removeLeadFromQueue(
                record,
                ReasonToRemoveLeadFromQueue.CONTACTED
              );
              gridStore.fetchGridData();
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to mark ${leadName} as contacted?`,
            }}
          >
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Contacted</ListItemText>
          </ConfirmationMenuItem>
        )}

        {shouldRenderAction(LEAD_OBJECT_TYPE, LEAD_NOT_INTERESTED) && (
          <ConfirmationMenuItem
            testId={`mark-lead-as-not-interested`}
            onClick={async () => {
              await salesforceLeadStore.removeLeadFromQueue(
                record,
                ReasonToRemoveLeadFromQueue.LEAD_NOT_INTERESTED
              );
              gridStore.fetchGridData();
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to mark ${leadName} as lead not interested?`,
            }}
          >
            <ListItemIcon>
              <WorkOff fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Lead Not Interested</ListItemText>
          </ConfirmationMenuItem>
        )}

        {shouldRenderAction(LEAD_OBJECT_TYPE, UNABLE_TO_CONTACT) && (
          <ConfirmationMenuItem
            testId={`mark-lead-as-unable-to-contact`}
            onClick={async () => {
              await salesforceLeadStore.removeLeadFromQueue(
                record,
                ReasonToRemoveLeadFromQueue.UNABLE_TO_CONTACT
              );
              gridStore.fetchGridData();
              handleMenuClose();
            }}
            confirmDialogProps={{
              body: `Are you sure want to mark ${leadName} as unable to contact?`,
            }}
          >
            <ListItemIcon>
              <SpeakerNotesOff fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Unable to Contact</ListItemText>
          </ConfirmationMenuItem>
        )}

        {isLeadDashboard(leadsDashboardStore) && checkIfIsConflictsDashboard(leadsDashboardStore.currentDashboard?.apiName) && (
          <>
            <ConfirmationMenuItem
              testId={`mark-lead-as-contacted`}
              onClick={async () => {
                await salesforceLeadStore.removeLeadFromQueue(
                  record,
                  ReasonToRemoveLeadFromQueue.CONFLICT_RESOLVED
                );
                gridStore.fetchGridData();
                handleMenuClose();
              }}
              confirmDialogProps={{
                body: `Are you sure want to mark ${leadName} as resolved?`,
              }}
            >
              <ListItemIcon>
                <Check fontSize="small" />
              </ListItemIcon>
              <ListItemText onClick={handleMenuClose}>Mark as resolved</ListItemText>
            </ConfirmationMenuItem>
          </>
        )}

        {shouldRenderAction(LEAD_OBJECT_TYPE, CHANGE_STATUS) &&
          <MenuItem
            onClick={() => setOpenLeadStatusDialog(true)}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Change Status</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(LEAD_OBJECT_TYPE, ADD_VIEW_NOTES) &&
          <MenuItem
            onClick={() => editLeadNoteStore.openAddLeadNoteFromDashboard(record.leadId, getDashBoardType())}
          >
            <ListItemIcon>
              <NoteOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add/View Notes</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(LEAD_OBJECT_TYPE, ADD_ACTIVITY) &&
          <MenuItem
            onClick={() => editLeadActivityStore.openAddLeadActivityFromDashboard(record.leadId, getDashBoardType())}
          >
            <ListItemIcon>
              <LocalActivity fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Activity</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(LEAD_OBJECT_TYPE, ADD_TASK) &&
          <MenuItem
            onClick={() => editLeadTaskStore.openAddLeadTaskFromDashboard(record.leadId, getDashBoardType())}
          >
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Task</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(TPO_OBJECT_TYPE, CHANGE_RELATIONSHIP_MANAGER) &&
          <MenuItem
            onClick={() => setOpenChangeRM(record.lenderId)}
            style={{ padding: '8px' }}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`Change Relationship Manager`}
              style={{ cursor: 'pointer' }}
            />
          </MenuItem>
        }
        {shouldRenderAction(TPO_OBJECT_TYPE, ADD_VIEW_NOTES) &&
          <MenuItem
            onClick={() => editLeadNoteStore.openAddLeadNoteFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <NoteOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add/View Notes</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(TPO_OBJECT_TYPE, ADD_ACTIVITY) &&
          <MenuItem
            onClick={() => editLeadActivityStore.openAddLeadActivityFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <LocalActivity fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Activity</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(TPO_OBJECT_TYPE, ADD_TASK) &&
          <MenuItem
            onClick={() => editLeadTaskStore.openAddLeadTaskFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Task</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(BORROWER_OBJECT_TYPE, CHANGE_OWNER) &&
          <MenuItem
            onClick={() => setOpenBulkLeadChangeOwnerBorrower(record.borrowerId)}
            style={{ padding: '8px' }}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`Change Owner`}
              style={{ cursor: 'pointer' }}
            />
          </MenuItem>}

        {shouldRenderAction(LEAD_OBJECT_TYPE, CHANGE_LEAD_OWNER) &&
          <MenuItem
            onClick={() => setOpenBulkLeadChangeOwnerLead(record.leadId)}
            style={{ padding: '8px' }}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={`Change Lead Owner`}
              style={{ cursor: 'pointer' }}
            />
          </MenuItem>}

        {shouldRenderAction(BORROWER_OBJECT_TYPE, OUTSIDE_CREDIT_INQUIRY) &&
          <ConfirmationMenuItem
            testId={`mark-lead-removed-transunion-queue`}
            onClick={async () => {
              await salesforceLeadStore.removeFromQueue(
                record.borrowerId,
                leadsDashboardStore.currentDashboard.dashboardListId
              );
              gridStore.fetchGridData();
              handleMenuClose();
              console.log("id Borrower: ", record.borrowerId)
              console.log("id LIST: ", leadsDashboardStore.currentDashboard.dashboardListId)
            }}
            confirmDialogProps={{
              body: `Are you sure want to remove it from the list?`,
            }}
          >
            <ListItemIcon>
              <RemoveCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Remove From This List</ListItemText>
          </ConfirmationMenuItem>}
        {shouldRenderAction(BORROWER_OBJECT_TYPE, ADD_VIEW_NOTES) &&
          <MenuItem
            onClick={() => editLeadNoteStore.openAddLeadNoteFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <NoteOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add/View Notes</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(BORROWER_OBJECT_TYPE, ADD_ACTIVITY) &&
          <MenuItem
            onClick={() => editLeadActivityStore.openAddLeadActivityFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <LocalActivity fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Activity</ListItemText>
          </MenuItem>
        }

        {shouldRenderAction(BORROWER_OBJECT_TYPE, ADD_TASK) &&
          <MenuItem
            onClick={() => editLeadTaskStore.openAddLeadTaskFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Task</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(TPO_OBJECT_TYPE, ADD_BROKER_RELATION) &&
          <MenuItem
            onClick={() => lenderDetailsStore.setAddBrokerRelationModalOpen(record, true)}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Broker Relation</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(BROKER_OBJECT_TYPE, CHANGE_OWNER) &&
          <MenuItem
            onClick={() => setOpenBulkLeadChangeOwnerBroker(record.lenderId)}
          >
            <ListItemIcon>
              <SupervisedUserCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Change Owner</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(BROKER_OBJECT_TYPE, ADD_VIEW_NOTES) &&
          <MenuItem
            onClick={() => editLeadNoteStore.openAddLeadNoteFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <NoteOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add/View Notes</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(BROKER_OBJECT_TYPE, ADD_ACTIVITY) &&
          <MenuItem
            onClick={() => editLeadActivityStore.openAddLeadActivityFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <LocalActivity fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Activity</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(BROKER_OBJECT_TYPE, ADD_TASK) &&
          <MenuItem
            onClick={() => editLeadTaskStore.openAddLeadTaskFromDashboard(record.contactId, getDashBoardType())}
          >
            <ListItemIcon>
              <Assignment fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Task</ListItemText>
          </MenuItem>
        }
        {shouldRenderAction(BROKER_OBJECT_TYPE, ADD_BROKER_AS_TPO) &&
          <MenuItem
            onClick={() => lenderDetailsStore.setAddBrokerAsTPOModalOpen(record, true)}
          >
            <ListItemIcon>
              <ImportExport fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={handleMenuClose}>Add Broker As TPO</ListItemText>
          </MenuItem>
        }


      </Menu >
      <ViewAndAddNotesDialog leadId={record.leadId} contactId={record.contactId} leadName={leadName}
        objectType={leadsDashboardStore.currentDashboard?.objectType?.toUpperCase() ?? leadsDashboardStore.currentDashboard?.baseObject?.toUpperCase()} />
      <EditLeadActivityDialog editLeadActivityStore={editLeadActivityStore} />
      <EditLeadTaskDialog editLeadTaskStore={editLeadTaskStore} />
      {openLeadStatusDialog && (<EditLeadStatusDialog salesforceLeadStore={salesforceLeadStore} leadId={record.leadId} handleEdit={handleEditStatusClose} handleClose={() => setOpenLeadStatusDialog(false)} />)}
      <ChangeRelationshipManagerModal open={newLeadStore.openChangeRmModal} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
      <BulkLeadChangeOwner open={newLeadStore.openBulkLeadChangeOwnerModal} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} isFromGlobalSearch={false} />
      <AddBrokerRelation open={lenderDetailsStore.isAddBrokerModalOpen} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
      <AddBrokerAsTPO open={lenderDetailsStore.isAddBrokerAsTPOModalOpen} handleClose={closeModal} salesforceLeadStore={salesforceLeadStore} />
      <StandardDialog
        open={openSendBulkMessagesModal}
        title={'Send SMS'}
        maxWidth="lg"
        handleClose={closeModal}
        dialogContent={
          <SendBulkMessagesModal
            handleClose={closeModal}
            salesforceLeadStore={salesforceLeadStore}
            type={getDashboardObjectTypeOrBaseObjectUppercase()}
          />
        }
      />
      <StandardDialog
        open={openSendBulkEmailModal}
        title={"Send Bulk Emails"}
        maxWidth="xl"
        handleClose={closeModal}
        dialogContent={
          <SendBulkEmailsModal
            handleClose={closeModal}
            salesforceLeadStore={salesforceLeadStore}
            type={getDashboardObjectTypeOrBaseObjectUppercase()}
          />}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorPOP}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <RecordDetails record={selectedRecord} onClose={handleClose} />
      </Popover>
    </>
  );
});

export const OrderId = observer(({ params, store
}) => {

  const rowIndex = params.node.rowIndex;
  const pageSize = params.api.paginationGetPageSize();
  const currentPage = store.leadsDashboardGridStore.gridData.meta.pageNumber - 1;
  return (
    rowIndex + 1 + currentPage * pageSize
  );
},

);