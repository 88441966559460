import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { observer } from "mobx-react";
import { SubjectPropertyMarker, CompPropertyMarker } from "@roc/feature-comp-tool";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  markerDivMapView: {
    position: "absolute",
    bottom: "100%",
    marginLeft: "-10px",
  },
  marker: {
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '0px 5px',
    borderRadius: '4px',
  }
}));
const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: 12,
      padding: 5,
      textAlign: "center",
      border: "none",
    },
  })
)(TableCell);
export interface Marker {
  id: number;
  mapTitle: string;
  isSubjectProperty: boolean;
  lat: number;
  lng: number;
  description: string;
  address: string;
  proximity: string;
}
interface CompMapProps {
  markers: Marker[];
}

const mapOptions = {
  fullscreenControl: false,
  zoomControl: true,
};

export const CompMap = observer(({ markers }: CompMapProps) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);


  if (!markers || markers.length === 0) {
    return (<Typography variant="h6">
      Comparables Map not Available.
    </Typography>)
  }

  const handleApiLoaded = (map, maps) => {
    setMapInstance(map);
    setMapsApi(maps);
  };

  const subjectProperty = markers[0];
  const defaultCenter = { lat: subjectProperty.lat, lng: subjectProperty.lng };
  const subjectPropertyLat = subjectProperty.lat;
  const subjectPropertyLng = subjectProperty.lng;

  useEffect(() => {
    if (mapsApi && mapInstance) {
      const bounds = new mapsApi.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(new mapsApi.LatLng(marker.lat, marker.lng));
      });

      mapInstance.fitBounds(bounds);
    }
  }, [markers, mapInstance, mapsApi, subjectPropertyLat, subjectPropertyLng]);

  return (
    <GoogleMapReact
      defaultCenter={defaultCenter}
      defaultZoom={15}
      options={mapOptions}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
      {markers.map((marker) => (
        <Pin {...marker} />
      ))}
    </GoogleMapReact>
  );
});
interface MapLegendProps {
  markers: Marker[];
  liteMode?: boolean;
}
export const MapLegend = ({ markers, liteMode = false }: MapLegendProps) => {
  return (
    <TableContainer style={{ backgroundColor: "#FFFFFF", paddingInline: liteMode ? "30px" : "" }}>
      <Table>
        <TableBody>
          {markers.map((marker) => (
            <TableRow key={marker.id}>
              <StyledTableCell align="center" width="15%">
                <Pin {...marker} mapView={false} />
              </StyledTableCell>
              <StyledTableCell width={liteMode ? "85%" : "28%"}>
                {marker.description}
              </StyledTableCell>
              {!liteMode && (<StyledTableCell width="28%">{marker.address}</StyledTableCell>)}
              {!liteMode && (<StyledTableCell width="29%">{marker.proximity}</StyledTableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface PinProps {
  mapTitle?: string;
  isSubjectProperty: boolean;
  mapView?: boolean;
}
export const Pin = ({ mapTitle, isSubjectProperty, mapView = true }: PinProps) => {
  const classes = useStyles();

  return (<div className={mapView ? classes.markerDivMapView : ""}>
    <Typography variant="body2" >
      <span className={classes.marker} style={{ backgroundColor: isSubjectProperty ? "#285391" : "#717580" }}>
        {mapTitle}
      </span >
    </Typography>
    {isSubjectProperty ? <SubjectPropertyMarker /> : <CompPropertyMarker />}
  </div>);
}
