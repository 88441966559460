import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { TextField, CurrencyField, PercentageField, Button, DateField } from '@roc/ui';
import { observer } from 'mobx-react';
import { DealEconomicsStore } from '../../../../stores/documentForms/dealEconomicsStore';
import { useUserStore } from 'libs/feature-user-profile/src/userProfile/hooks/useUserStore';
import moment from 'moment';

export type DealEconomicsFormProps = {
  store: DealEconomicsStore;
};

export const DealEconomicsForm = observer((props: DealEconomicsFormProps) => {
  const { store } = props;
  const { userStore } = useUserStore();

  const calculateDealEconomics = () => {
    store.calculateDealEconomics();
  };

  const onFieldChange = (name, e) => {
    store.onFieldChange(name, e.target.value);
  };

  const onNumberFieldChange = (name, e) => {
    let val = e.target.value;
    store.onFieldChange(name, val ? Number(val) : val);
  };

  const onDateChange = (fieldName, value) => {
    store.onFieldChange(fieldName, moment(value).format("MM/DD/YYYY"));
  }

  const getfpiLabel = value => {
    return value ? 'Yes' : 'No';
  };
  const isNportal = userStore.isNportalUser;
  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <CurrencyField
                label="Purchase Price"
                standaloneLabel
                value={store.form.fields.purchasePrice.value}
                variant="outlined"
                testId="purchasePrice"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="Seller's Credit Amount"
                standaloneLabel
                value={store.form.fields.sellersCreditAmount.value}
                variant="outlined"
                testId="sellersCreditAmount"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="Value"
                standaloneLabel
                value={store.form.fields.value.value}
                variant="outlined"
                testId="value"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="Loan Amount"
                standaloneLabel
                value={store.form.fields.loanAmount.value}
                variant="outlined"
                testId="loanAmount"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="ARLTV"
                standaloneLabel
                value={store.form.fields.afterRepairLTV.value}
                variant="outlined"
                testId="afterRepairLTV"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="Renovation Budget"
                standaloneLabel
                value={store.form.fields.renovationBudget.value}
                variant="outlined"
                testId="renovationBudget"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Interest"
                standaloneLabel
                value={store.form.fields.interestRate.value}
                variant="outlined"
                testId="interestRate"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Origination Points"
                standaloneLabel
                value={store.form.fields.points.value}
                variant="outlined"
                testId="points"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Origination Broker Points"
                standaloneLabel
                value={store.form.fields.originationBrokerPoints.value}
                variant="outlined"
                testId="originationBrokerPoints"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Full Principal Interest"
                standaloneLabel
                value={getfpiLabel(
                  store.form.fields.fullPrincipalInterest.value
                )}
                variant="outlined"
                testId="fpi"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="Monthly Rent"
                standaloneLabel
                value={store.form.fields.rent.value}
                variant="outlined"
                testId="rent"
                fullWidth
                disabled
              />
            </Grid>
            {store.form.fields.useNewDealEconomicsCalculations.value && (
              <>
                <Grid item xs={12}>
                  <CurrencyField
                    label="Payoff Amount"
                    standaloneLabel
                    value={store.form.fields.payoffAmount.value}
                    variant="outlined"
                    testId="payoffAmount"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Purchase Date"
                    standaloneLabel
                    value={store.form.fields.purchaseDate.value}
                    variant="outlined"
                    testId="purchaseDate"
                    fullWidth
                    disabled
                  />
                </Grid>
                {store.form.fields.exitStrategy.value === 'Refinance' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Annual HOA"
                        standaloneLabel
                        value={store.form.fields.annualHOADues.value}
                        variant="outlined"
                        testId="rent"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Annual Insurance"
                        standaloneLabel
                        value={store.form.fields.annualInsurance.value}
                        variant="outlined"
                        testId="rent"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <TextField
                label="Time to flip (months)"
                standaloneLabel
                type="number"
                value={store.form.fields.timeToFlip.value}
                onChange={e => onNumberFieldChange('timeToFlip', e)}
                variant="outlined"
                testId="timeToFlip"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Mortgage Tax"
                standaloneLabel
                value={store.form.fields.mortgageTaxPercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('mortgageTaxPercent', value)
                }
                variant="outlined"
                testId="mortgageTaxPercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Transfer Tax"
                standaloneLabel
                value={store.form.fields.transferTaxPercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('transferTaxPercent', value)
                }
                variant="outlined"
                testId="transferTaxPercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Mansion Tax"
                standaloneLabel
                value={store.form.fields.mansionTaxPercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('mansionTaxPercent', value)
                }
                variant="outlined"
                testId="mansionTaxPercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Title Insurance"
                standaloneLabel
                value={store.form.fields.titleInsurancePercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('titleInsurancePercent', value)
                }
                variant="outlined"
                testId="titleInsurancePercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Sale Broker Fee/Real Estate Agent Fee"
                standaloneLabel
                value={store.form.fields.brokerFeePercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('brokerFeePercent', value)
                }
                variant="outlined"
                testId="brokerFeePercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Property Tax and Maintenance"
                standaloneLabel
                value={store.form.fields.propertyTaxAndMaintenancePercent.value}
                onChange={(name, value) =>
                  store.onFieldChange('propertyTaxAndMaintenancePercent', value)
                }
                variant="outlined"
                testId="propertyTaxAndMaintenancePercent"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyField
                label="NOI"
                standaloneLabel
                value={store.form.fields.noi.value}
                onChange={(name, value) => store.onFieldChange('noi', value)}
                variant="outlined"
                testId="noi"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentageField
                label="Exit Loan Rate"
                standaloneLabel
                value={store.form.fields.exitLoanRate.value}
                onChange={(name, value) =>
                  store.onFieldChange('exitLoanRate', value)
                }
                variant="outlined"
                testId="exitLoanRate"
                fullWidth
                disabled={!isNportal}
              />
            </Grid>
            {store.form.fields.useNewDealEconomicsCalculations.value && (
              <>
                <Grid item xs={12}>
                  <DateField
                    label="Loan Closing Date"
                    standaloneLabel
                    value={store.form.fields.loanClosingDate.value}
                    onChange={(date) => onDateChange('loanClosingDate', date)}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    testId="rent"
                    fullWidth
                    disabled={!isNportal}
                  />
                </Grid>
                {store.form.fields.exitStrategy.value === 'Refinance' && (
                  <Grid item xs={12}>
                    <TextField
                      label="Annual Taxes"
                      standaloneLabel
                      value={store.form.fields.annualTaxes.value}
                      variant="outlined"
                      testId="rent"
                      fullWidth
                      disabled
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" data-html2canvas-ignore>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={calculateDealEconomics}
            testId="calculateDealEconomics"
            fullWidth
          >
            Calculate
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default DealEconomicsForm;
