import { StandardDialog } from '@roc/ui';
import LoanClosingCondition from './loanClosingCondition';
import { useLoanClosingConditionStore } from '../../../hooks/useLoanClosingConditionStore';
import { Typography } from '@material-ui/core';
import { useEffect } from 'react';

export type ClosingConditionProps = {
  open?: boolean;
  onClose?: () => void;
  loanTaskId?: number;
  taskName: string;
};

export const LoanClosingConditionModal = (
  props: ClosingConditionProps
) => {
  const { open, onClose, loanTaskId } = props;
  return (
    <>
      <StandardDialog
        open={open}
        maxWidth="sm"
        handleClose={onClose}
        dialogContent={(
          <div style={{ margin: '-40px -32px -20px -32px' }}>
            <LoanClosingCondition handleClose={onClose} loanTaskId={loanTaskId} closingConditionAdmin={false} closingConditionCommittee={false} internal={false} />
          </div>
        )}
      />
    </>
  );
};
