import { Grid } from '@material-ui/core';
import { createTextField } from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';

export const CreateSuportTask = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const { isReadOnly, } = editTaskStore;
  const store = editTaskStore;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'title',
          fieldName: 'title',
          label: 'Description',
          disabled: isReadOnly,
        })}
      </Grid>
    </Grid>
  );
});