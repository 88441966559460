import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  IconButton,
  Box,
  Radio,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { CustomIconTooltip, PercentageField } from '@roc/ui';
import {
  AddCircleOutline,
  InfoOutlined,
  RemoveCircleOutline,
} from '@material-ui/icons';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { FixFlipGULeverageMatrixCard } from './fixFlipGULeverageMatrixCard';
import {
  fixAndFlipExperienceMatrixData,
  groundUpExperienceMatrixData,
  NO_INSTA_QUOTE_ALLOWED,
  stabilizedBridgeExperienceMatrixData,
} from '../../../utils/constants';
import { isStabilizedBridge } from '@roc/feature-utils';

const MANUAL_QUOTE_COLOR = 'lightgray';
const INSTA_QUOTE_COLOR = '#4CAF50';

const useLeverageCardStyles = makeStyles(theme => ({
  centerContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'left', // Horizontal centering of text
    alignItems: 'center',
    border: 0,
  },
  paper: ({ disabled, instaQuote }: any) => ({
    border: `2px solid ${instaQuote ? INSTA_QUOTE_COLOR : MANUAL_QUOTE_COLOR}`, // Green border
    padding: theme.spacing(2),
    borderRadius: '16px !important',
    opacity: disabled ? '0.5' : '1',
    pointerEvents: disabled ? 'none' : 'all',
  }),
  title: ({ instaQuote }: any) => ({
    color: instaQuote ? INSTA_QUOTE_COLOR : '#333333',
    fontWeight: 'bold',
  }),
  disabledWarningContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  disabledWarningLabel: {
    maxWidth: '60%',
    background: '#fff',
    border: '1px solid #999',
    borderRadius: '8px',
    padding: '8px',
  },
  rootContainer: {
    position: 'relative',
  },
}));

interface InstaLeverageQuoteCardProps {
  store: QuoteTypeStore;
  disabled?: boolean;
}

export const LeverageCard = observer(
  ({
    disabled = false,
    title,
    children,
    instaQuote,
    LeverageMatrixCard,
    quoteTypeStore,
    disabledWarning,
  }) => {
    const classes = useLeverageCardStyles({ disabled, instaQuote });

    return (
      <Box className={classes.rootContainer}>
        <Paper className={classes.paper} elevation={0}>
          <Grid container direction="row">
            <Grid item xs={3} className={classes.centerContainer}>
              <Typography className={classes.title} variant="h6">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                {children}
              </Grid>
            </Grid>
          </Grid>
          {LeverageMatrixCard && (
            <Grid container direction="row">
              <Grid item xs={12}>
                <LeverageMatrixCard
                  store={quoteTypeStore}
                  data={
                    isStabilizedBridge(quoteTypeStore.loanSubtype) ? 
                      stabilizedBridgeExperienceMatrixData : 
                      quoteTypeStore.loanSubtype === 'Groundup (1-4)'
                        ? groundUpExperienceMatrixData
                        : fixAndFlipExperienceMatrixData
                  }
                />
              </Grid>
            </Grid>
          )}
        </Paper>
        {disabledWarning && (
          <Box className={classes.disabledWarningContainer}>
            <Typography className={classes.disabledWarningLabel}>
              {NO_INSTA_QUOTE_ALLOWED}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);

const useLeverageCardRowStyles = makeStyles(theme => ({
  symbols: {
    color: '#BABEC5',
    fontWeight: 'bold',
  },
}));

export const LeverageCardRow = observer(
  ({ disabled = false, dividend, divisor, result }) => {
    const classes = useLeverageCardRowStyles();

    return (
      <Grid item container alignItems="center">
        <Grid item xs={3}>
          {dividend}
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.symbols} align="center" variant="h4">
            /
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {divisor}
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.symbols} align="center" variant="h4">
            =
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {result}
        </Grid>
      </Grid>
    );
  }
);

const useItemDisplayStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F7F7F7',
  },
  cardValue: ({ instaQuote }: any) => ({
    fontSize: '20px',
    fontWeight: 'bold',
    color: instaQuote ? INSTA_QUOTE_COLOR : 'rgba(107, 108, 113, 1)',
  }),
  cardLabel: {
    fontSize: '14px',
    color: '#939494',
    display: 'flex',
    textAlign: 'center',
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: '75vw',
  },
  infoIcon: {
    width: '16px',
    color: '#939494',
    marginLeft: '5px',
  },
}));

export const ItemDisplay = ({
  label,
  value,
  alignItems = 'center',
  showBackground = false,
  instaQuote = false,
  tooltip = null,
}) => {
  const classes = useItemDisplayStyles({ instaQuote });

  return (
    <Grid
      container
      alignItems={alignItems as any}
      justifyContent="center"
      direction="column"
      className={showBackground ? classes.container : undefined}
    >
      <Grid item>
        <Paper
          elevation={0}
          style={{ textAlign: 'center', backgroundColor: 'transparent' }}
        >
          <Typography variant="body2" className={classes.cardLabel}>
            {label}
            {tooltip && (
              <CustomIconTooltip
                title={tooltip}
                placement="top"
                icon={InfoOutlined}
                style={{ marginLeft: '5px' }}
              />
            )}
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.cardValue}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const usePercentagePickerStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardLabel: {
    fontSize: '14px',
    color: '#939494',
  },
  iconButton: {
    color: theme.palette.grey[500],
  },
  percentageField: ({ inputFontColor }: any) => ({
    '& .MuiInputBase-input': {
      textAlign: 'right',
      fontSize: '20px!important',
      fontWeight: 'bold !important',
      color: inputFontColor,
    },
    '& .MuiTypography-colorTextSecondary': {
      color: inputFontColor,
      fontWeight: 'bold',
    },
  }),
}));

export const PercentagePicker = observer(
  ({
    fieldName,
    label,
    value,
    onChange,
    instaQuote = false,
    min = 0,
    max = 100,
    allowIncrease = true,
    disabled = false,
  }) => {
    const inputFontColor = instaQuote ? '#4CAF50' : '#6B6C71';
    const classes = usePercentagePickerStyles({ inputFontColor });

    function handleMinusClick() {
      const newValue = Math.max(min, Math.min(max, value - 1));
      onChange(newValue);
    }

    function handlePlusClick() {
      const newValue = Math.max(min, Math.min(max, value + 1));
      onChange(newValue);
    }

    function handleBlur() {
      const newValue = Math.max(min, Math.min(max, value));
      onChange(newValue);
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body2" className={classes.cardLabel}>
          {label}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width={24} pr={1}>
            {value > min && (
              <IconButton
                className={classes.iconButton}
                onClick={handleMinusClick}
                disabled={disabled}
                style={{ padding: 0 }}
              >
                <RemoveCircleOutline />
              </IconButton>
            )}
          </Box>
          <Box width={80} textAlign={'right'}>
            <PercentageField
              testId={fieldName}
              value={value || 0}
              variant={'standard'}
              fullWidth={true}
              onChange={(name, value) => onChange(value)}
              disabled={disabled}
              inputClassname={classes.percentageField}
              allowNegative={false}
              //autoFocus={true}
              decimalScale={2}
              onBlur={handleBlur}
            //inputStyle={{ textAlign: 'right' }}
            />
          </Box>
          <Box width={24} pl={1}>
            {value < max && allowIncrease && (
              <IconButton
                className={classes.iconButton}
                onClick={handlePlusClick}
                disabled={disabled}
                style={{ padding: 0 }}
              >
                <AddCircleOutline />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
