import {
  bridgeLoanSubTypes,
  DateFormat,
  DescribedRennovation,
  formatDate,
  getLoanPurpose,
  insertIf,
  LoanSubType,
  LoanType,
  parseDate,
  termLoanSubTypes,
} from '@roc/feature-utils';
import { LoanApplicationBorrowerEntityBorrower } from './loanApplicationTypes';

export const mapLoanApplicationPayload = (
  loanSubType: LoanSubType,
  entity,
  borrowers,
  properties
) => {
  const yesNoToBoolean = val =>
    val === 'Y' ? true : val === 'N' ? false : null;
  return {
    loanType: getLoanType(loanSubType),
    loanSubtype: loanSubType,
    amount: 0,
    ...(loanSubType === LoanSubType.SINGLE_PROPERTY
      ? {
        loanPurpose: properties?.[0]?.loanPurpose,
        submissionDate: formatDate(new Date(), DateFormat.MMDDYYYY),
        annualInsurance: properties?.[0]?.annualInsurance,
        //loanLtv: properties?.[0]?.targetLTV,
        nsfrLoanFields: {
          loanToValueAtSubmission: properties?.[0]?.targetLTV,
        },
      }
      : {
        loanPurpose: getLoanPurpose(properties),
      }),
    propertiesMap: {
      rows: properties.map(property => {
        let rehabFields = {};

        if (loanSubType === LoanSubType.FIX_AND_FLIP_PRO) {
          property.squareFootageAdded = property?.describeRenovation !== DescribedRennovation.NO;
          rehabFields = {
            preRehabBedroomsCount: property.preRehabBedroomsCount,
            postRehabBedroomsCount: property.postRehabBedroomsCount,
            preRehabBathroomsCount: property.preRehabBathroomsCount,
            postRehabBathroomsCount: property.postRehabBathroomsCount,
            preRehabAproxSquareFootageAboveGrade: property.preRehabAproxSquareFootageAboveGrade,
            postRehabAproxSquareFootageAboveGrade: property.squareFootageAdded ? property.postRehabAproxSquareFootageAboveGrade : null,
            preRehabAproxSquareFootageBelowGrade: property.preRehabAproxSquareFootageBelowGrade,
            postRehabAproxSquareFootageBelowGrade: property.squareFootageAdded ? property.postRehabAproxSquareFootageBelowGrade : null
          }
        }

        let useCodeValue = property.useCode;

        if (LoanType.RESIDENTIAL_TERM === getLoanType(loanSubType)) {
          useCodeValue = property.propertyType;
        }

        return {
          propertyId: property.propertyId,
          appraisalId: property.appraisalId,
          address: property.address,
          streetNumber: property.streetNumber,
          streetName: property.streetName,
          city: property.city,
          state: property.state,
          zipCode: property.zipCode,
          latitude: property.latitude,
          longitude: property.longitude,
          ...(loanSubType === LoanSubType.GROUND_UP ? {
            parcel: property.parcel,
            numberOfLots: property.numberOfLots,
            isOriginalAddress: property.isOriginalAddress,
            originalAddress: property.originalAddress,
            permitsInPlace: property.permitsInPlace
          } : {}),
          useCode: useCodeValue,
          aptNumber: property.aptNumber,
          propertyOwnership: property.propertyOwnership,
          propertyDescription: property.propertyDescription,
          purchasePrice: property.purchasePrice,
          armsLength: yesNoToBoolean(property.armsLength),
          armsLengthComment: property.armsLengthComment,
          propertySourcing: property.propertySourcing,
          propertySourcingExplanation: property.propertySourcingExplanation,
          wholesaleAmount: property.wholesaleAmount,
          wholesalerPurchase: property.wholesalerPurchase,
          totalRenovationBudget: property.totalRenovationBudget,
          describeRenovation: Array.isArray(property.describeRenovation) ? (property.describeRenovation || [])
            .map(item => item.value)
            .join(','): property.describeRenovation,
          sunkCost: property.sunkCost,
          renovationRemaining: property.renovationRemaining,
          afterRepairValue: property.afterRepairValue,
          exitStrategy: property.exitStrategy,
          additionalComments: property.additionalComments,
          submitRushedAppraisal: property.submitRushedAppraisal,
          monthlyMarketRentProvidedAtOrigination:
            property.monthlyMarketRentProvidedAtOrigination,
          annualHOAProvidedAtOrigination: property.annualHOAProvidedAtOrigination,
          annualTaxesProvidedAtOrigination:
            property.annualTaxesProvidedAtOrigination,
          annualInsuranceProvidedAtOrigination:
            property.annualInsuranceProvidedAtOrigination,
          renovationBudget: property.totalRenovationBudget,
          anyDebt: yesNoToBoolean(property.anyDebt),
          refiAmount: property.refiAmount,
          purchaseDate: property.purchaseDate,
          asIsValue: property.asIsValue,
          orderId: property.orderId,
          sellerCredit: property.sellerCredit,
          sellerCreditAmount: property.sellerCreditAmount,
          fireDamage: property.fireDamage,
          squareFootageAdded: property.squareFootageAdded,
          wholesalerRelationship: property.wholesalerRelationship,

          //Rental
          commercialUnits: property.commercialUnits,
          commercialUnitsCurrentOnRent: property.commercialUnitsCurrentOnRent,
          county: property.propertyCounties?.[0],
          rentalPricerId: property.rentalPricerId,
          pricerType: property.pricerType,
          propertyType: property.propertyType,
          loanPurpose: property.loanPurpose,

          units: property.units,

          unitsOccupiedHave12MonthsLease: property.unitsOccupiedHave12MonthsLease,

          totalAnnualUtilities: property.totalAnnualUtilities,
          totalAnnualRepairsMaintenance: property.totalAnnualRepairsMaintenance,
          totalAnnualPropertyManagementFees:
            property.totalAnnualPropertyManagementFees,
          generalAdministrative: property.generalAdministrative,
          payroll: property.payroll,
          marketing: property.marketing,
          replacementReserves: property.replacementReserves,
          residentialUnits: property.residentialUnits,
          residentialUnitsSqFtArea: property.residentialUnitsSqFtArea,
          commercialUnitsSqFtArea: property.commercialUnitsSqFtArea,
          residentialIncome: property.residentialIncome,
          commercialIncome: property.commercialIncome,
          totalAnnualNOI: property.totalAnnualNOI,
          totalAnnualIncome: property.totalAnnualIncome,
          totalAnnualExpenses: property.totalAnnualExpenses,
          loanRecourse: property.loanRecourse,
          nsfrLoanPropertyFields: {
            propertyValuation: property.propertyValuation,
            annualTaxes: property.annualTaxes,
            annualInsurance: property.annualInsurance,
            annualHOA: property.annualHOA,
            acquisitionPrice: property.acquisitionPrice,
            acquisitionDate: property.acquisitionDate,
            capitalImprovements: property.capitalImprovements,
            totalDebtPayoff: property.totalDebtPayoff,
            monthlyGrossRent: property.monthlyGrossRent,
            noOfUnitLeased: property.unitsOccupied,
            annualGrossRent:
              property.monthlyGrossRent && property.monthlyGrossRent * 12,
            propertyAnnualInsurance: property.annualInsurance,
            annualTaxesProvidedAtOrigination: property.annualTaxes,
            annualInsuranceProvidedAtOrigination: property.annualInsurance,
            annualHoaProvidedAtOrigination: property.annualHOA,
            section8: property.section8,
          },
          internalValuationReviewRequired: property.internalValuationReviewRequired,
          internalValuationReviewRequiredReason: property.internalValuationReviewRequiredReason,
          ...rehabFields
        }
      }),
    },
    loanBorrowerInformation: {
      borrowerEntity: {
        borrowerEntityId: entity.borrowerEntityId,
        company: {
          name: entity.name,
          einNumber: entity.ein,
          entityType: entity.type,
          operatingAgreementDate: formatDate(
            entity.operatingAgreementDate,
            DateFormat.MMDDYYYY
          ),
          address: entity.address,
          city: entity.city,
          streetName: entity.streetName,
          state: entity.state,
          streetNumber: entity.streetNumber,
        },
      },
      loanBorrowers: {
        rows: borrowers.map(borrower => ({
          borrowerId: borrower.borrowerId,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          emailAddress: borrower.emailAddress,
          cellPhone: borrower.cellPhone,
          pctOwnership: borrower.pctOwnership,
          ficoProvidedAtOrigination: borrower.ficoProvidedAtOrigination,
          experienceProvidedAtOrigination:
            borrower.experienceProvidedAtOrigination,
          rentalExperience: borrower.rentalExperience, // TODO: Check if we should do something like experienceProvidedAtOrigination instead
          citizenshipStatus: borrower.citizenshipStatus,
          bankruptcy: borrower.bankruptcy,
          bankruptcyDate: borrower.bankruptcyDate,
          foreclosure: borrower.foreclosure,
          foreclosureDate: borrower.foreclosureDate,
        })),
      },
    },
  };
};

const getLoanType = (loanSubType: LoanSubType) => {
  if (bridgeLoanSubTypes.includes(loanSubType)) {
    return LoanType.RESIDENTIAL_BRIDGE;
  } else if (termLoanSubTypes.includes(loanSubType)) {
    return LoanType.RESIDENTIAL_TERM;
  }
  return null;
};

export const mapEntityResponse = (
  data
): LoanApplicationBorrowerEntityBorrower[] => {
  return data.map(item => ({
    borrowerEntity: {
      borrowerEntityId: item.borrowerEntityId,
      name: item.company.name,
      type: item.type,
      ein: item.ein,
      address: item.company.address,
      latitude: item.company.latitude,
      longitude: item.company.longitude,
      streetNumber: item.company.streetNumber,
      streetName: item.company.streetName,
      city: item.company.address,
      state: item.company.state,
      zipCode: item.company.zipCode,
      operatingAgreementDate: parseDate(item.operatingAgreementDate),
    },
    borrowers: item.borrowers.map(row => ({
      borrowerId: row.borrower.borrowerId,
      firstName: row.borrower.firstName,
      lastName: row.borrower.lastName,
      emailAddress: row.borrower.emailAddress,
      cellPhone: row.borrower.cellPhone,
      pctOwnership: row.pctOwnership,
    })),
  }));
};
