
export const SUBJECT_PROPERTY_SECTION = '- Subject Property';

export const APPRAISAL_REPORTS_LINE_ITEMS_TEXT = `To change the status of this line item, go to the Appraisal Review Report screen. At the bottom of the screen, you'll find options to either create revisions or complete the review.`;

export const SCOPE_OF_WORK_LINE_ITEM_TEXT = `To change the status of this line item, navigate to the Scope of Work form screen. At the top of the final step, you'll find options to either send revisions or mark the review as complete.`;

export const colors = {
  lapisBlue: "#1C3F78",
  desertStorm: "#F7F7F7",
  iron: "#D7D7D7",
  dodgerBlue: "#4285F4",
  mountainMist: "#939494",
  boxShadow: '0px 2px 6px rgba(0,0,0,0.16) !important',
  dune: "#333333",
  white: '#fff',
  soapstone: "#FCFCFC",
  mercury: '#E4E8EB',
  greenWhite: '#E8E8EA',
  lavenderPinocchio: '#E0E0E0',
  raisinBlack: "#222222",
  brightGray: "#eeeeee"
}