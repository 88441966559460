import {
  Badge,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MoreVert, Launch, MenuOpen, Delete } from '@material-ui/icons';
import {
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { DraftLoan } from '@roc/feature-types';
import {
  ConfirmationMenuItem, LinkColumn, MenuItem, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, TextColumn
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeByLoanSubType, useLoanSubmissionRoutes } from '../../loanSubmission';
import { useLoanStore } from '../hooks/useLoanStore';
import { LoanSubType, PORTAL_COLORS, isNil } from '@roc/feature-utils';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useOneToolRoutes } from '@roc/feature-one-tool';
const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

export const MyRequestLoansGrid = observer(({ columns }) => {
  const { loanStore } = useLoanStore();

  const { loanRoutesConfig } = useLoanRoutes();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { oneToolRoutesConfig } = useOneToolRoutes();
  const [loanId, setLoanId] = useState(0);
  const [currentRow, setCurrentRow] = useState<DraftLoan>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const { push } = useHistory();
  const isLoanApplication = true;

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    dealNameRenderer: params =>
      isNil(params.data?.loanApplicationId) || !params.data?.borrowerLoanApplication ? (
        <>
          {TextColumn(params)}
        </>
      ) : (
        <>
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loanApplications(params.data?.loanApplicationId).children.dashboard.url,
          })}
        </>
      ),
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN_REQUEST} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { id } = record;
    setLoanId(id);
    setCurrentRow(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={loanStore.myRequestLoansGridStore}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          testId={`continue-draft-loan-${loanId}`}
          onClick={() => {
            if (isNil(currentRow?.loanQuoteId) || currentRow?.loanSubType == LoanSubType.FIX_AND_FLIP_PRO) {
              loanStore.continueDraftLoan(currentRow);
              handleMenuClose();
              push(
                loanSubmissionRoutesConfig.submitloan.children[
                  routeByLoanSubType[currentRow?.loanSubType]
                ].url
              );
            }
            else {
              handleMenuClose();
              const route = oneToolRoutesConfig[routeByLoanSubType[currentRow?.loanSubType]];
              push(route(currentRow?.loanQuoteId).url);
            }
          }}
        >
          <ListItemIcon>
            <Launch fontSize="small" />
          </ListItemIcon>
          <ListItemText>Open Submission</ListItemText>
        </MenuItem>
        <ConfirmationMenuItem
          testId={`reject-loan-request-${loanId}`}
          onClick={()=>{
            loanStore.rejectLoanRequest(currentRow);
            handleMenuClose();
          }}
          confirmDialogProps={{
            body: `Are you sure you want to reject this loan request?`
          }}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reject loan request</ListItemText>
        </ConfirmationMenuItem>
        {currentRow?.borrowerLoanApplication &&
          <MenuItem
            testId={`loan-details-${currentRow?.loanApplicationId}`}
            onClick={() => {
              push(
                loanRoutesConfig.loanApplications(currentRow?.loanApplicationId.toString()).children.dashboard.url
              );
            }}
          >
            <ListItemIcon>
              <MenuOpen fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
        }

      </Menu>
    </>
  );
});
