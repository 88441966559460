import { action, flow, observable, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { DealEconomicsStore } from './dealEconomicsStore';
import { ExpectedClosingDateStore } from './expectedClosingDateStore';
import { RequestedClosingDateStore } from './requestedClosingDateStore';
import { LoanPaymentsStore } from './loanPaymentsStore';
import { GoodFaithDepositStore } from './goodFaithDepositStore';
import { TamariskPaymentStore } from './tamariskPaymentStore';
import { BorrowerClosingAgentStore } from './borrowerClosingAgentStore';
import { InsuranceProviderStore } from './insuranceProvider/insuranceProviderStore';
import { InsuranceApprovalStore } from './insuranceApproval/insuranceApprovalStore';
import { FundingTemplateStore } from './fundingTemplateStore';
import BorrowerSurveyStore from './borrowerSurveyStore';
import BorrowerEntityStore from './borrowerEntity/borrowerEntityStore';
import LenderFeesStore from './lenderFeesStore';
import TitleAgentStore from './titleAgent/titleAgentStore';
import FundingPreferenceStore from './fundingPreference/fundingPreferenceStore';
import ExpectedWireReleaseDateStore from './expectedWireReleaseDateStore';
import { AppraisalOrderDetailsStore } from './appraisalOrderDetailsStore';
import { Document } from '@roc/feature-types';
import {
  DocumentName,
  DocumentStatus,
  DOCUMENT_FORMS,
  SCOPE_OF_WORK_FORM,
  SCOPE_OF_WORK_FORM_V2,
  SCOPE_OF_WORK_LINK_V2,
  RESOLUTION_ITEM_FORM,
  RESOLUTION_LINE_ITEM_PREFIX,
  CLOSING_CONDITION,
  BANK_STATEMENT_PREFIX,
  LEASE_INFORMATION_UNIT_PREFIX,
  TAX_ESCROW,
  POST_DISASTER_INSPECTION,
  CREDIT_REPORT,
  TRIMERGE_CREDIT_REPORT,
  QUOTE_LOANS,
  BANK_STATEMENT_MONTH_PREFIX,
} from '../../constants';
import { isNil } from '@roc/feature-utils';
import {
  isAccepted,
  isFileAlreadyUploaded,
  isFormDocument,
  documentUploadNotAllowedOnly,
} from '../../utils/documents';
import TermSheetStore from './termSheetStore';
import { DocusignStore } from './docusignStore';
import { ScopeOfWorkV2Service } from '@roc/feature-sow-shared/services';
import {
  ScopeOfWorkV2FormBaseStore,
  ScopeOfWorkV2FormStore,
  ScopeOfWorkV2LinkStore,
} from '@roc/feature-sow-shared/stores/v2';
import { TitleCompanyBankInformationStore } from './titleCompanyBankInformation/titleCompanyBankInformationStore';
import LoanFastrackPaymentStore from './loanFastrackPaymentStore';
import LoanClosingConditionStore from '../loanClosingConditionStore';
import CdaAndAppraisalOrderDetailsStore from './cdaAndAppraisalOrderDetailsStore';
import { BorrowerSetupStore } from '@roc/feature-borrowers';
import { BankStatementFormStore } from './bankStatement/bankStatementFormStore';
import { LeaseInformationFormStore } from './leaseInformation/leaseInformationFormStore';
import { PostDisasterInspectionFormStore } from './postDisasterInspection/postDisasterInspectionFormStore';
import { PersonalIdentificationFormStore } from './personalIdentificationFormStore';
import { CmtFormStore } from './cmtFormStore';
import AppraisalRecertificationOrderDetailsStore from './appraisalRecertificationOrderDetailsStore';
import { PermitFormStore } from './permitsFormStore';

export class DocumentFormStore {
  private documentStore: DocumentStore;
  public loanStore: LoanStore;
  private globalStore: GlobalStore;
  dealEconomicsStore: DealEconomicsStore;
  expectedClosingDateStore: ExpectedClosingDateStore;
  requestedClosingDateStore: RequestedClosingDateStore;
  loanPaymentsStore: LoanPaymentsStore;
  cdaAndAppraisalOrderDetailsStore: CdaAndAppraisalOrderDetailsStore;
  appraisalRecertificationOrderDetailsStore: AppraisalRecertificationOrderDetailsStore;
  termSheetStore: TermSheetStore;
  goodFaithDepositStore: GoodFaithDepositStore;
  loanFastrackPaymentStore: LoanFastrackPaymentStore;
  tamariskPaymentStore: TamariskPaymentStore;
  borrowerClosingAgentStore: BorrowerClosingAgentStore;
  insuranceProviderStore: InsuranceProviderStore;
  borrowerSurveyStore: BorrowerSurveyStore;
  borrowerEntityStore: BorrowerEntityStore;
  lenderFeesStore: LenderFeesStore;
  titleAgentStore: TitleAgentStore;
  fundingPreferenceStore: FundingPreferenceStore;
  insuranceApprovalStore: InsuranceApprovalStore;
  fundingTemplateStore: FundingTemplateStore;
  appraisalOrderDetailsStore: AppraisalOrderDetailsStore;
  expectedWireReleaseDateStore: ExpectedWireReleaseDateStore;
  scopeOfWorkV2FormStore: ScopeOfWorkV2FormStore;
  scopeOfWorkV2LinkStore: ScopeOfWorkV2LinkStore;
  scopeOfWorkV2Service: ScopeOfWorkV2Service;
  docusignStore: DocusignStore;
  titleCompanyBankInformationStore: TitleCompanyBankInformationStore;
  loanClosingConditionStore: LoanClosingConditionStore;
  borrowerSetupStore: BorrowerSetupStore;
  bankStatementFormStore: BankStatementFormStore;
  leaseInformationFormStore: LeaseInformationFormStore;
  postDisasterInspectionFormStore: PostDisasterInspectionFormStore;
  personalIdentificationFormStore: PersonalIdentificationFormStore;
  cmtFormStore: CmtFormStore;
  permitFormStore: PermitFormStore;

  currentForm: string;

  constructor(
    documentStore: DocumentStore,
    loanStore: LoanStore,
    globalStore: GlobalStore
  ) {
    this.documentStore = documentStore;
    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.dealEconomicsStore = new DealEconomicsStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.expectedClosingDateStore = new ExpectedClosingDateStore(
      documentStore,
      loanStore,
      globalStore
    );
    this.requestedClosingDateStore = new RequestedClosingDateStore(
      documentStore,
      loanStore,
      globalStore
    );
    this.dealEconomicsStore = new DealEconomicsStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.termSheetStore = new TermSheetStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.loanPaymentsStore = new LoanPaymentsStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.cdaAndAppraisalOrderDetailsStore = new CdaAndAppraisalOrderDetailsStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.appraisalRecertificationOrderDetailsStore = new AppraisalRecertificationOrderDetailsStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.goodFaithDepositStore = new GoodFaithDepositStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.loanFastrackPaymentStore = new LoanFastrackPaymentStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.tamariskPaymentStore = new TamariskPaymentStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.borrowerSurveyStore = new BorrowerSurveyStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.borrowerEntityStore = new BorrowerEntityStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.lenderFeesStore = new LenderFeesStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.loanClosingConditionStore = new LoanClosingConditionStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.titleAgentStore = new TitleAgentStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.insuranceProviderStore = new InsuranceProviderStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.borrowerClosingAgentStore = new BorrowerClosingAgentStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.insuranceApprovalStore = new InsuranceApprovalStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.fundingTemplateStore = new FundingTemplateStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.expectedWireReleaseDateStore = new ExpectedWireReleaseDateStore(
      documentStore,
      loanStore,
      globalStore
    );
    this.appraisalOrderDetailsStore = new AppraisalOrderDetailsStore(
      globalStore
    );
    this.fundingPreferenceStore = new FundingPreferenceStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.docusignStore = new DocusignStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.titleCompanyBankInformationStore = new TitleCompanyBankInformationStore(
      loanStore,
      documentStore,
      globalStore
    );
    this.scopeOfWorkV2LinkStore = new ScopeOfWorkV2LinkStore(globalStore);
    this.scopeOfWorkV2FormStore = new ScopeOfWorkV2FormStore(globalStore);
    this.scopeOfWorkV2Service = new ScopeOfWorkV2Service();
    this.borrowerSetupStore = new BorrowerSetupStore(globalStore);
    this.bankStatementFormStore = new BankStatementFormStore(globalStore);
    this.leaseInformationFormStore = new LeaseInformationFormStore(globalStore);
    this.postDisasterInspectionFormStore = new PostDisasterInspectionFormStore(
      globalStore
    );
    this.personalIdentificationFormStore = new PersonalIdentificationFormStore(
      globalStore
    );
    this.cmtFormStore = new CmtFormStore(globalStore);
    this.permitFormStore = new PermitFormStore(globalStore);

    makeObservable(this, {
      setCurrentForm: action,
      closeCurrentForm: action,
      reset: action,
      currentForm: observable,
      openScopeOfWork: flow,
    });
    this.initialize();
  }

  initialize() {
    this.scopeOfWorkV2LinkStore.setOnSuccessLink(this.onScopeOfWorkLinked);
    this.scopeOfWorkV2FormStore.setOnSubmitScopeOfWork(
      this.onScopeOfWorkFormSubmitted
    );
    this.scopeOfWorkV2FormStore.setOnResetSuccefull(this.onResetSOW);
  }

  setCurrentForm(form: string) {
    this.currentForm = form;
  }

  closeCurrentForm() {
    this.setCurrentForm(null);
  }

  isFormDocument(document: Document) {
    const isForm = isFormDocument(
      document,
      this.loanStore?.userStore?.isNportalUser,
      this.loanStore?.loanDetails?.toDosFrameworkEnabled
    );
    if (document.taskName === DocumentName.PERSONAL_IDENTIFICATION) {
      return isForm && this.globalStore.isInternalPortal;
    } else if (document.taskName === DocumentName.SCOPE_OF_WORK) {
      return isForm && this.isScopeOfWorkFormEnabled(document);
    } else if (this.isAppraisalReportDocument(document.taskName)) {
      return (
        isForm && this.documentStore.isAppraisalDocumentUploadAllowed(document)
      );
    } else if (document.taskName === DocumentName.LOAN_TERMS) {
      return this.isQuoteLoanTerms(document) ? true : false;
    } else {
      return isForm;
    }
  }

  isAppraisalReportDocument(documentName: string) {
    return Object.values([
      DocumentName.APPRAISAL_REPORT,
      DocumentName.APPRAISAL_REPORT_ARV,
      DocumentName.APPRAISAL_REPORT_AS_IS,
      DocumentName.APPRAISAL_REPORT_AS_IS_RENT,
    ]).includes(documentName as DocumentName);
  }

  private isScopeOfWorkFormEnabled(document: Document) {
    const isScopeOfWorkFormEnabled = this.globalStore.userFeatures
      ?.underwritingEntitlements.isScopeOfWorkFormEnabled;
    return !!isScopeOfWorkFormEnabled;
  }

  private isQuoteLoanTerms(document: Document) {
    return (
      QUOTE_LOANS.includes(this.loanStore?.loanDetails?.loanSubType) &&
      document.taskName === DocumentName.LOAN_TERMS
    );
  }

  canOpenForm(document: Document, loanProcess: string) {
    const isForm = this.isFormDocument(document);
    if (!isForm) return false;

    const isUploadNotAllowed = this.isQuoteLoanTerms(document)
      ? false
      : this.documentUploadNotAllowedOnly(document, loanProcess);

    const documentMeetsOpenFormRules = this.doesDocumentMeetsOpenFormRules(
      document
    );

    return !isUploadNotAllowed && documentMeetsOpenFormRules;
  }

  private doesDocumentMeetsOpenFormRules(document: Document) {
    if (document.taskName === DocumentName.SCOPE_OF_WORK) {
      return this.isScopeOfWorkFormEnabled(document);
    } else if (document.taskName === DocumentName.LOAN_FUNDING_TEMPLATE) {
      return isAccepted(document);
    } else if (document.taskName === DocumentName.NOTE_ALLONGE) {
      return !isAccepted(document);
    } else if (document.taskName === DocumentName.ASSIGNMENT_AND_CONVEYANCE) {
      return !isAccepted(document);
    } else if (document.taskName === DocumentName.DRAW_REPORT) {
      return isAccepted(document);
    } else if (document.taskName === DocumentName.ACH_FORM) {
      return !isAccepted(document);
    } else if (this.isQuoteLoanTerms(document)) {
      return isAccepted(document);
    } else {
      return true;
    }
  }

  private canOpenTermSheetForm(document: Document) {
    return (
      document.status === DocumentStatus.MORE_INFO_NEEDED ||
      document.status === DocumentStatus.PENDING ||
      document.status === DocumentStatus.ACCEPTED
    );
  }

  openDocumentForm(document: Document) {
    if (
      document.taskName === DocumentName.SCOPE_OF_WORK ||
      document.taskName === DocumentName.DIGITAL_SCOPE_OF_WORK
    ) {
      this.openScopeOfWork(document);
    } else if (
      document.taskName &&
      document.taskName.includes(RESOLUTION_LINE_ITEM_PREFIX)
    ) {
      this.setCurrentForm(RESOLUTION_ITEM_FORM);

    } else if (document.taskName && document.taskName.includes(CLOSING_CONDITION)) {
      this.setCurrentForm(document.taskName)
    } else if (document.taskName &&
      (document.taskName.includes(BANK_STATEMENT_PREFIX)  || document.taskName.includes(BANK_STATEMENT_MONTH_PREFIX) || document.taskName.includes(LEASE_INFORMATION_UNIT_PREFIX) || document.taskName === TAX_ESCROW || document.taskName === POST_DISASTER_INSPECTION
        || document.taskName === CREDIT_REPORT || document.taskName === TRIMERGE_CREDIT_REPORT)) {
      this.setCurrentForm(document.taskName)
    } else {
      this.setCurrentForm(DOCUMENT_FORMS[document.taskName]);
    }
  }

  openTermSheetForm(document: Document) {
    const { loanPaymentsUrl } = this.loanStore.loanDetails;

    if (loanPaymentsUrl) {
      this.setCurrentForm(DOCUMENT_FORMS[document.taskName][0]);
    } else {
      this.setCurrentForm(DOCUMENT_FORMS[document.taskName][1]);
    }
  }

  *openScopeOfWork(document: Document) {
    try {
      const { data: response } = yield this.scopeOfWorkV2Service.getScopeOfWork(
        document.loanId,
        document.collateralId
      );
      const scopeOfWork = JSON.parse(response.data);
      if (!scopeOfWork) {
        this.setCurrentForm(SCOPE_OF_WORK_LINK_V2);
      } else {
        this.openScopeOfWorkForm(scopeOfWork);
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'An error occurred while fetching the Scope of Work information',
      });
    }
  }

  onScopeOfWorkLinked = scopeOfWork => {
    this.openScopeOfWorkForm(scopeOfWork);
    this.documentStore.refetchDocuments();
  };

  onResetSOW = () => {
    this.setCurrentForm(SCOPE_OF_WORK_LINK_V2);
    this.documentStore.refetchDocuments();
  };

  openScopeOfWorkForm(scopeOfWork) {
    if (!this.globalStore.isInternalPortal) {
      const params = {
        scopeOfWorkId: scopeOfWork.scopeOfWorkId,
        isInternal: true,
        version: scopeOfWork.version,
      };
      this.scopeOfWorkV2FormStore.setParams(params);
    }
    this.setCurrentForm(SCOPE_OF_WORK_FORM_V2);
  }

  onScopeOfWorkFormSubmitted = (submitted: boolean) => {
    if (isNil(submitted)) return;
    this.closeCurrentForm();
    this.documentStore.refetchDocuments();
  };

  onCloseNoteAllongeAndAssignment = () => {
    this.closeCurrentForm();
    this.documentStore.refetchDocuments();
  };

  reset() {
    this.currentForm = null;
    this.dealEconomicsStore.reset();
    this.termSheetStore.reset();
    this.loanPaymentsStore.reset();
    this.cdaAndAppraisalOrderDetailsStore.reset();
    this.goodFaithDepositStore.reset();
    this.tamariskPaymentStore.reset();
    this.insuranceProviderStore.reset();
    this.borrowerSurveyStore.reset();
    this.borrowerEntityStore.reset();
    this.lenderFeesStore.reset();
    this.titleAgentStore.reset();
    this.insuranceProviderStore.reset();
    this.fundingPreferenceStore.reset();
    this.insuranceApprovalStore.reset();
    this.fundingTemplateStore.reset();
    this.scopeOfWorkV2FormStore.reset();
    this.scopeOfWorkV2LinkStore.reset();
    this.loanClosingConditionStore.reset();
    this.borrowerSetupStore.reset();
    this.bankStatementFormStore.reset();
    this.leaseInformationFormStore.reset();
    this.postDisasterInspectionFormStore.reset();
  }

  private documentUploadNotAllowedOnly(
    document: Document,
    loanProcess: string
  ) {
    const entitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);
    return documentUploadNotAllowedOnly(
      entitlements?.isUploadNotAllowedOnly,
      document
    );
  }
}

export default DocumentFormStore;
