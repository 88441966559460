import { Service } from '@roc/client-portal-shared/services';
import { encodeParams } from '@roc/feature-sow-v2';

const baseUrl = '/nportal/rest/pub/scopeOfWork';
const baseUrlAuditLogs = '/nportal/rest/pub/scopeOfWorkAuditLogs';
const publicServiceUrl = '/api/v1/loan/public/scopeOfWork';

const url = {
  GET_SCOPE_OF_WORK_V2_SHARE_URL: `${baseUrl}/getScopeOfWorkShareUrlV2`,
  GET_SCOPE_OF_WORK_V2: `${baseUrl}/getScopeOfWorkV2`,
  GET_SCOPE_OF_WORK_V2_BY_LOAN_AND_PROPERTY: `${baseUrl}/getScopeOfWorkByLoanIdAndPropertyIdV2`,
  GET_NEW_SCOPE_OF_WORK_V2: `${baseUrl}/getNewScopeOfWorkV2`,
  SAVE_SCOPE_OF_WORK_V2: `${baseUrl}/saveScopeOfWorkV2`,
  SUBMIT_SCOPE_OF_WORK_V2: `${baseUrl}/submitScopeOfWorkV2`,
  SUBMIT_SCOPE_OF_WORK_PUBLIC: `${baseUrl}/submitScopeOfWorkFromPublicLink`,
  GET_LENDER_ID_BY_HOST: `${publicServiceUrl}/getLenderIdByHost`,
  GET_SCOPE_OF_WORK_CATEGORIES: `${baseUrl}/getScopeOfWorkCategories`,
  EXPORT_SCOPE_OF_WORK: `${baseUrl}/exportScopeOfWorkV2`,
  SAVE_SCOPE_OF_WORK_DATA_CONTENT_V2: `${baseUrl}/saveDataContent`,
  MARK_AS_IN_REVIEW: `${baseUrl}/markAsInReview`,
  MARK_AS_MORE_INFO_NEEDED: `${baseUrl}/markAsMoreInfoNeeded`,
  GET_SPLITTED_CATEGORIES: `${baseUrl}/getScopeOfWorkSplitedCategories`,
  SAVE_PDF: `${baseUrl}/savePdf`,
  SAVE_SOW_AUDIT_LOGS: `${baseUrlAuditLogs}/saveScopeOfWorkAuditLogs`,
  GET_SOW_AUDIT_LOG_MESSAGES: `${baseUrlAuditLogs}/getScopeOfWorkAuditLogsMessages`,
  GET_SOW_ERROR_MESSAGES: `${baseUrl}/getSowSubmissionErrors`

};

const addQueryParam = (params: string, key: string, value: string) => {
  if (!key || !value) {
    return params;
  }
  return `${params ? `${params}&` : ''}${key}=${value}`;
};

export class ScopeOfWorkV2Service extends Service {

  getSowSubmissionErrors(loanId: string, propertyId: any, data: any) {
    if (loanId && propertyId) {
      return this.post(`${url.GET_SOW_ERROR_MESSAGES}?loanId=${loanId}&propertyId=${propertyId}`, data)
    } else {
      return this.post(`${url.GET_SOW_ERROR_MESSAGES}`, data)
    }
  }

  saveSowAuditLogs(scopeOfWorkId: string, lenderId, data: any, isSubmit: boolean, userName: string) {
    const userNameParam = userName ? `&userName=${userName}` : '';
    return this.post(`${url.SAVE_SOW_AUDIT_LOGS}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}&isSubmit=${isSubmit}${userNameParam}`, data)
  }

  getSowAuditLogs(scopeOfWorkId: string, lenderId: any) {
    return this.get(`${url.GET_SOW_AUDIT_LOG_MESSAGES}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`)
  }

  savePdf(file, scopeOfWorkId, lenderId) {
    return this.post(`${url.SAVE_PDF}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`, file);
  }

  getSplittedCategories(scopeOfWorkId: string, lenderId: string) {
    return this.get(`${url.GET_SPLITTED_CATEGORIES}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`)
  }

  getScopeOfWorkShareUrl(lenderId: string, scopeOfWorkId: string) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = encodeParams(params);
    return this.get(
      url.GET_SCOPE_OF_WORK_V2_SHARE_URL,
      { params },
      {
        responseType: 'text',
      }
    );
  }

  getScopeOfWork(lenderId: string, scopeOfWorkId: string) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = encodeParams(params);
    return this.post(
      `${url.GET_SCOPE_OF_WORK_V2}${params ? `?params=${params}` : ''}`,
      null
    );
  }

  getScopeOfWorkByLoanIdAndPropertyId(loanId: string, propertyId: string) {
    let params = addQueryParam('', 'loanId', loanId);
    params = addQueryParam(params, 'propertyId', propertyId);
    params = encodeParams(params);
    return this.post(
      `${url.GET_SCOPE_OF_WORK_V2_BY_LOAN_AND_PROPERTY}${params ? `?params=${params}` : ''
      }`,
      null
    );
  }

  getNewScopeOfWork() {
    return this.get(url.GET_NEW_SCOPE_OF_WORK_V2);
  }

  getCategories() {
    return this.get(url.GET_SCOPE_OF_WORK_CATEGORIES);
  }

  saveScopeOfWork(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SAVE_SCOPE_OF_WORK_V2}?params=${params}`,
      data,
      {},
      options
    );
  }


  saveScopeOfWorkDataContent(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SAVE_SCOPE_OF_WORK_DATA_CONTENT_V2}?params=${params}`,
      data,
      {},
      options
    );
  }


  exportScopeOfWork(data: any) {
    return this.post(
      `${url.EXPORT_SCOPE_OF_WORK}`,
      data,
      null,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  submitScopeOfWork(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SUBMIT_SCOPE_OF_WORK_V2}?params=${params}`,
      data,
      {},
      options
    );
  }

  submitScopeOfWorkPublic(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SUBMIT_SCOPE_OF_WORK_PUBLIC}?params=${params}`,
      data,
      {},
      options
    );
  }

  getLenderIdByHost(
    host: string,
    silent: boolean = false
  ) {
    const options = { disableGlobalLoading: silent };
    return this.get(
      `${url.GET_LENDER_ID_BY_HOST}?host=${host}`,
      {},
      options
    );
  }

  markSOWAsInReview(
    reviewStatus: string,
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'reviewStatus', reviewStatus);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.MARK_AS_IN_REVIEW}?params=${params}`,
      data,
      {},
      options
    );
  };

  markSOWAsMoreInfoNeeded(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.MARK_AS_MORE_INFO_NEEDED}?params=${params}`,
      data,
      {},
      options
    );
  }

}
