import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Tab,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Link,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ConfirmDialog, ConfirmationButton, DialogState, FileUploadModal, PDFPreview, StandardDialog } from '@roc/ui';
import {
  TRACK_RECORD_USER_INPUTTED,
  TRACK_RECORD_REVIEW_TYPE,
  TRACK_RECORD_CAPITAL_PROVIDER,
} from 'libs/feature-utils/src/utils/constants';
import PropertyInformationDialog from './propertyInformationDialog';
import { formatDate, getStreetName, getStreetNumber, usdAmount } from './utils';
import { BorrowerInfoCardInternalStore } from 'apps/roc-internal-portal/src/app/modules/borrowerInfoCard/stores/borrowerInfoCardInternalStore';
import LinkEntityDialog from './linkEntityDialog';
import EntityModal, {
  BorrowerEntityModalState,
} from 'libs/feature-loans/src/loanSubmission/components/entityModal';
import { BORROWER_REVIEW_TYPES } from 'libs/feature-borrower-review/src/components/utils/backgroundUtils';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import TrackRecordTitleHistory from './trackRecordTitleHistory';
import { BorrowerBaseballCardStore } from '@roc/feature-borrowers';
import TrackRecordAuditLogs from './trackRecordAuditLogs';
import MatDataGrid from '../matDataGrid/matDataGrid';
import { insertIf } from '@roc/feature-utils';
import PublicTrackRecordDialog from './publicTrackRecordsDialog';
import CloseIcon from '@material-ui/icons/Close';
import BorrowerExperienceData from 'libs/feature-borrower-review/src/components/borrowerExperienceData';
import { VerifiedChip } from '../chip/verifiedChip';

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      marginBottom: '2rem',
    },
    verificationStatusCell: {
      textAlign: 'right',
    },

    nonEditableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
    editableInput: {
      padding: '2% 2% 0 2%',
      backgroundColor: 'transparent',
      border: 'solid lightgrey 1px',
      outline: 'none',
      margin: '20px',
      marginBottom: 0,
    },
    actionButton: {
      marginRight: '1rem',
      height: '40px',
      fontSize: '12px',
      padding: '5px',
    },
    experienceComments: {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      border: 'solid lightgrey 1px',
      '& textarea': {
        resize: 'none',
        width: '-webkit-fill-available',
      },
      fontFamily: 'Manrope, sans- serif'
    },
    paper: {
      borderRadius: theme.spacing(2),
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'color 0.3s ease',
    },
    textField: {
      backgroundColor: 'white',
      shrink: true,
      '& input.Mui-disabled': {
        color: 'black',
      },
    },
    table: {
      marginTop: '1.5em',
      marginBottom: '2em',
    },
    tab: {
      marginBottom: theme.spacing(2),
    },
    buttonsGrid: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      paddingTop: '10px',
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
    },
    addressWrapper: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      padding: '16px',
    },
    addressWrapperTab: {
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.grey[50],
      },
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '26px',
    },
  })
);

interface Props {
  hideTitle?: boolean;
  store: BorrowerInfoCardInternalStore | BorrowerBaseballCardStore;
  onClose?: () => void;
  isInternal?: boolean;
}

export const NewBorrowerTrackRecord = observer(({ hideTitle = false, store, onClose, isInternal = true }: Props) => {
  const {
    baseballCardDetails,
    entityStore,
    trackRercords
  } = store;

  const classes = useStyles();

  const [selectEntityOpen, setSelectEntityOpen] = useState(false);
  const [addEntityOpen, setAddEntityOpen] = useState(false);
  const [
    confirmCompleteReviewModalOpen,
    setConfirmCompleteReviewModalOpen,
  ] = useState(false);

  const [isExperienceEditable, setIsExperienceEditable] = useState(false);

  const [isAddressLinksEditable, setIsAddressLinksEditable] = useState(false);

  const [entityFilter, setEntityFilter] = React.useState('');
  const [propertyVerificationOthersReason, setPropertyVerificationOthersReason] = React.useState('');
  const [entityVerificationOthersReason, setEntityVerificationOthersReason] = React.useState('');
  const [propertyAdressFilter, setPropertyAdressFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [verificationStatusFilter, setVerificationStatusFilter] = useState('');
  const [isMultipleVerification, setIsMultipleVerification] = useState(false);

  const [uploadDocument, setUploadDocument] = useState(null);

  const [currentRowId, setCurrentRowId] = useState(null);

  const [currentRowType, setCurrentRowType] = useState(null);

  const [titleHistoryOpen, setTitleHistoryOpen] = useState(false);

  const [currentEntity, setCurrentEntity] = useState(null);

  const [currentProperty, setCurrentProperty] = useState(null);

  const [isPublicTrackRecordModalOpen, setIsPublicTrackRecordModalOpen] = useState(false);

  // Verify
  const [isVerifyEntityModalOpen, setIsVerifyEntityModalOpen] = useState(false);
  const [isVerifyPropertyModalOpen, setIsVerifyPropertyModalOpen] = useState(false);
  const [currentEntityVerificationSource, setCurrentEntityVerificationSource] = useState(null);
  const [currentPropertyVerificationSource, setCurrentPropertyVerificationSource] = useState(null);

  // Unverify
  const [isUnverifyEntityModalOpen, setIsUnverifyEntityModalOpen] = useState(false);
  const [isUnverifyPropertyModalOpen, setIsUnverifyPropertyModalOpen] = useState(false);
  const [propertyUnverificationReason, setPropertyUnverificationReason] = React.useState('');
  const [entityUnverificationReason, setEntityUnverificationReason] = React.useState('');

  const [linkKey, setLinkKey] = useState('');
  const [linkValue, setLinkValue] = useState('');

  const handleSaveLinks = async (property) => {
    let currentLinks = [];

    try {
      currentLinks = JSON.parse(property.links) || [];
    } catch { }

    if (linkKey && linkValue) {
      const newLink = { key: linkKey, value: linkValue };

      const links = [...currentLinks, newLink];

      await store.propertyFormStore.editPropertyInformation({ ...property, links: JSON.stringify(links) });

      property.links = JSON.stringify(links);

      setLinkKey('');
      setLinkValue('');
    }
  };

  const handleDeleteLink = async (property, link) => {
    const parsedLinks = JSON.parse(property.links);
    const currentLinks = [...parsedLinks];


    const newLinks = currentLinks.filter(linkItr => !(linkItr.key === link.key && linkItr.value === link.value));
    await store.propertyFormStore.editPropertyInformation({ ...property, links: JSON.stringify(newLinks) });

    property.links = JSON.stringify(newLinks);

  }

  const filteredTrackRecords = React.useMemo(() => {
    return trackRercords?.filter(record => {
      const entityMatch =
        !entityFilter ||
        (record.entityName &&
          record.entityName.toLowerCase().includes(entityFilter.toLowerCase()));

      const propertyAddressMatch =
        !propertyAdressFilter ||
        (record.purchaseAddress &&
          record.purchaseAddress.toLowerCase().includes(propertyAdressFilter.toLowerCase()));

      const cityMatch =
        !cityFilter ||
        (record.purchaseCity &&
          record.purchaseCity.toLowerCase().includes(cityFilter.toLowerCase()));

      const statusMatch =
        verificationStatusFilter === 'All' ||
        (verificationStatusFilter === 'Verified' ? record.isVerified : !record.isVerified);


      return entityMatch && propertyAddressMatch && cityMatch && statusMatch;
    });
  }, [trackRercords, entityFilter, propertyAdressFilter, cityFilter, verificationStatusFilter]);

  const handleEntityFilterChange = event => {
    setEntityFilter(event.target.value);
  };

  const handlePropertyVerificationReasonChange = event => {
    setPropertyVerificationOthersReason(event.target.value);
  };

  const handleEntityVerificationReasonChange = event => {
    setEntityVerificationOthersReason(event.target.value);
  };

  const handlePropertyUnverificationReasonChange = event => {
    setPropertyUnverificationReason(event.target.value);
  };

  const handleEntityUnverificationReasonChange = event => {
    setEntityUnverificationReason(event.target.value);
  };

  const handleCityFilterChange = (event) => {
    setCityFilter(event.target.value);
  };

  const handlePropertyAdressFilterChange = (event) => {
    setPropertyAdressFilter(event.target.value);
  };

  const onTabSelect = (e: any, index: number): void => {
    store.setSelectedTab(index);
  };

  const handleExperienceSave = () => {
    setIsExperienceEditable(false);
    handleSave();
  };

  const handleEditExperience = () => {
    setIsExperienceEditable(true);
  };


  useEffect(() => {
    setEntityFilter(''),
      setPropertyAdressFilter(''),
      setCityFilter(''),
      refreshData();
    if (store.selectedTab === 0) {
      setVerificationStatusFilter('Verified');
    } else {
      setVerificationStatusFilter('All');
    }
    store.selectedProperties = [];
    store.propertiesMatGridStore.setCurrentSelectedIndex(null);
    store.entitiesMatGridStore.setCurrentSelectedIndex(null);
  }, [store.selectedTab]);

  const handleCloseConfirmCompleteReviewModal = () => {
    setConfirmCompleteReviewModalOpen(false);
  };

  const handleConfirmationCompleteReviewAction = () => {
    store.submitReview(BORROWER_REVIEW_TYPES.BORROWER_TRACK_RECORD_REVIEW);
    handleCloseConfirmCompleteReviewModal();
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveEntity = async entity => {
    await store.removeEntity(entity.borrowerEntityBorrowerId);
    store.entitiesMatGridStore.setCurrentSelectedIndex(null);
    refreshEntities();
  };


  const handleUnverifyTrackRecord = async () => {
    await store.unverifyTrackRecord(currentProperty.verifiedTrackRecordsId, store.loanId, propertyUnverificationReason);
    refreshData();
    handleCloseUnverifyPropertyModal();
  };

  const handleSave = async () => {
    try {
      let request = {
        comment: store.baseballCardDetails?.experienceComment,
        rentalExp: store.baseballCardDetails?.rentalExp,
        bridgeExp: store.baseballCardDetails?.bridgeExp,
        rentalMfExperience: store.baseballCardDetails?.rentalMfExperience,
        groundupExperience: store.baseballCardDetails?.groundupExperience,
        totalPropertiesOwned: store.baseballCardDetails?.totalPropertiesOwned,
      };
      await store.saveExperienceComment(store.currentBorrower, request);
      store.showSuccessNotification('Experience saved successfully');
    } catch (error) {
      store.showErrorNotification('Error while saving experience');
    }
  };

  const handleDelete = async (property) => {
    await store.deleteTrackRecord(property.verifiedTrackRecordsId);
    refreshData();
  }


  const handleMultipleVerify = async () => {
    for (let property of store.selectedProperties) {
      property.purchaseDate = formatDate(property.purchaseDate);
      property.saleDate = formatDate(property.saleDate);
    }
    await store.verifyMultipleTrackRecords(store.selectedProperties, store.loanId, currentPropertyVerificationSource, propertyVerificationOthersReason);
    store.setSelectedTab(0);
    store.selectedProperties = [];
    setIsVerifyPropertyModalOpen(false);
    setCurrentPropertyVerificationSource(null);
    setPropertyVerificationOthersReason('');
    setCurrentProperty(null);
    setIsMultipleVerification(false);
  }

  const handleRemoveMultipleProperties = async () => {
    const ids = store.selectedProperties.reduce((acc, property) => {
      acc.push(property.verifiedTrackRecordsId);
      return acc;
    }, []);
    await store.deleteMultipleTrackRecords(ids);
    store.selectedProperties = [];
    store.propertiesMatGridStore.setSelectedRows([]);
    refreshData();
  }

  const handleMultipleRequestVerification = async () => {
    let resolutions = [];
    for (let property of store.selectedProperties) {
      property.purchaseDate = formatDate(property.purchaseDate);
      property.saleDate = formatDate(property.saleDate);
      property.isVerificationRequested = true;
      if (property.verifiedTrackRecordsId == null) {
        const response = await store.savePropertyInformation(property) as any;
        property.verifiedTrackRecordsId = response.data.verifiedTrackRecordsId;
      }
    }

    let trackRecords = [];
    for (let trackRecord of store.trackRercords) {
      if (trackRecord.isVerificationRequested == true) {
        trackRecords.push(trackRecord);
        resolutions.push(getResolutionItem(trackRecord));
      }
    }
    let request = {
      borrowerId: store.currentBorrower,
      letterOfExplanation: resolutions,
      trackRecords: trackRecords,
    };
    await store.requestTrackRecordVerification(request, store.currentBorrower, store.loanId, TRACK_RECORD_REVIEW_TYPE);
  }

  const handleRequestVerification = async property => {
    let resolutions = [];

    property.purchaseDate = formatDate(property.purchaseDate);
    property.saleDate = formatDate(property.saleDate);
    property.isVerificationRequested = true;
    if (property.verifiedTrackRecordsId == null) {
      const response = (await store.savePropertyInformation(property)) as any;
      property.verifiedTrackRecordsId = response.data.verifiedTrackRecordsId;
    }

    let trackRecords = [];
    for (let trackRecord of store.trackRercords) {
      if (trackRecord.isVerificationRequested == true) {
        trackRecords.push(trackRecord);
        resolutions.push(getResolutionItem(trackRecord));
      }
    }
    let request = {
      borrowerId: store.currentBorrower,
      letterOfExplanation: resolutions,
      trackRecords: trackRecords,
    };
    await store.requestTrackRecordVerification(
      request,
      store.currentBorrower,
      store.loanId,
      TRACK_RECORD_REVIEW_TYPE
    );
  };


  const openUploadDocument = async (id, type) => {
    setCurrentRowId(id);
    setCurrentRowType(type);
    setUploadDocument(true);
  }

  const handleUploadDocument = async (id, files, type) => {
    let currentId = id;
    if (type === 'property') {
      store.currentProperty.purchaseDate = formatDate(store.currentProperty.purchaseDate);
      store.currentProperty.saleDate = formatDate(store.currentProperty.saleDate);
      if (store.currentProperty.verifiedTrackRecordsId == null) {
        const response = (await store.savePropertyInformation(store.currentProperty)) as any;
        currentId = response.data.verifiedTrackRecordsId;
      }

    }

    store.createTaskWithFile(currentId, files[0], type);
  }

  const openTitleHistory = async (property) => {
    const data = new FormData();
    let lookupRequest = {
      address: property.fullAddress ? property.fullAddress : `${property.purchaseAddress}, ${property.purchaseCity}, ${property.purchaseState}, ${property.purchaseZipcode}`,
      streetNumber: property.streetNumber ? property.streetNumber : getStreetNumber(property.purchaseAddress),
      streetName: property.streetName ? property.streetName : getStreetName(property.purchaseAddress),
      city: property.purchaseCity,
      state: property.purchaseState,
      zipCode: property.purchaseZipcode,
    };
    data.append('form_information', JSON.stringify(lookupRequest));
    const response = await store.lookupProperty(data) as any;
    store.currentCollateral = property;
    store.collateralTitleHistory = response?.data.rows;
    store.titleHistoryMatGridStore.currentSelectedIndex = null;
    store.titleHistoryMatGridStore.setSelectedRows([]);
    setTitleHistoryOpen(true);
  }


  const getResolutionItem = property => {
    const fullAddress =
      (property.purchaseAddress ? property.purchaseAddress + ', ' : '') +
      (property.purchaseCity ? property.purchaseCity + ', ' : '') +
      (property.purchaseState ? property.purchaseState + ' ' : '') +
      (property.purchaseZipcode ? property.purchaseZipcode : '');

    const itemDescription =
      `Address: ${fullAddress}\n` +
      (property.entityName ? 'Entity name: ' + property.entityName : '') +
      (property.buyerName ? ' Buyer name: ' + property.buyerName : '');

    return {
      itemSection:
        TRACK_RECORD_REVIEW_TYPE + ' ' + property.verifiedTrackRecordsId,
      itemName: fullAddress.trim(),
      itemDescription: itemDescription,
    };
  };

  const refreshData = () => {
    if (store.selectedTab === 0) refreshTrackRecords();
    else if (store.selectedTab === 1) refreshEntities();
    else if (store.selectedTab === 2) refreshTrackRecords();
    store.propertiesMatGridStore.setCurrentSelectedIndex(null);
    store.entitiesMatGridStore.setCurrentSelectedIndex(null);
  }

  const refreshTrackRecords = async () => {
    store.trackRercords = []
    await store.getTrackRecords(store.currentBorrower, store.loanId);
  };


  const refreshEntities = async () => {
    store.baseballCardDetails.borrowingEntities = [];
    await store.fetchBaseballCardData(
      store.currentBorrower,
      store.loanId ? parseInt(store.loanId) : null
    );
  };

  const handleAddProperty = async () => {
    store.openPropertyInformationDialog(DialogState.ADD);
  };


  const handleLookupProperty = async () => {
    store.openPropertyInformationDialog(
      DialogState.ADD,
      undefined,
      undefined,
      true
    );
  };

  const handleEditProperty = async (property) => {
    store.currentRecordSourceType = property.recordSourceType;
    await store.openPropertyInformationDialog(DialogState.EDIT, property.verifiedTrackRecordsId, property.isVerified, false, property.verificationSource, property.links);
  }


  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
  };

  const handleSavedSuccess = () => {
    window.parent.postMessage('saved-success', '*');
  };

  const openLinkEntityModal = () => {
    setSelectEntityOpen(true);
    entityStore.setSelectedEntity(null);
  };

  const openAddEntityModal = () => {
    setAddEntityOpen(true);
    entityStore.setSelectedEntity(null);
    entityStore.reset();
  };

  const handleAddEntity = () => {
    entityStore.setSelectedEntity(entityStore.getEntity());
    entityStore.handleLinkEntity(store.currentBorrower, () => {
      setAddEntityOpen(false);
      store.baseballCardDetails.borrowingEntities = [];
      store.fetchBaseballCardData(
        store.currentBorrower,
        store.loanId ? parseInt(store.loanId) : null
      );
    });
  };

  const handleUpdateVerifyEntity = async () => {
    await store.updateVerifyEntity(
      currentEntity.borrowerEntityBorrowerId,
      currentEntity.entityVerified === 'Yes',
      currentEntityVerificationSource,
      store.loanId,
      entityVerificationOthersReason,
    );
    setIsVerifyEntityModalOpen(false);
    setCurrentEntityVerificationSource(null);
    setCurrentEntity(null);
    setEntityVerificationOthersReason('');
    refreshData();
  };

  const handleUpdateVerifyProperty = async () => {
    if (isMultipleVerification) {
      handleMultipleVerify()
    } else {
      currentProperty.purchaseDate = formatDate(currentProperty.purchaseDate);
      currentProperty.saleDate = formatDate(currentProperty.saleDate);

      await store.verifyTrackRecord(currentProperty, store.loanId, currentPropertyVerificationSource, propertyVerificationOthersReason);
      store.setSelectedTab(0)

      setIsVerifyPropertyModalOpen(false);
      setCurrentPropertyVerificationSource(null);
      setPropertyVerificationOthersReason('');
      setCurrentProperty(null);
      setIsMultipleVerification(false);
      store.setSelectedTab(0);
    }
  };

  const handleUpdateVerifyEntityWithParam = async () => {
    await store.updateVerifyEntity(
      currentEntity.borrowerEntityBorrowerId,
      currentEntity.entityVerified === 'Yes',
      entityUnverificationReason,
      store.loanId,
    );
    refreshData();
    handleCloseUnverifyEntityModal();
  };

  const handleEntityVerificationSourceChange = (event) => {
    const value = event.target.value;
    if (currentEntityVerificationSource === 'Other' && value != 'Other') {
      setEntityVerificationOthersReason('');
    }
    setCurrentEntityVerificationSource(value);
  }

  const handlePropertyVerificationSourceChange = (event) => {
    const value = event.target.value;
    if (currentPropertyVerificationSource === 'Other' && value != 'Other') {
      setPropertyVerificationOthersReason('');
    }
    setCurrentPropertyVerificationSource(value);
  }

  const handleVerificationStatusFilterChange = (event) => {
    setVerificationStatusFilter(event.target.value);
  }

  const handleOpenVerifyEntityModal = async entity => {
    setCurrentEntity(entity);
    setIsVerifyEntityModalOpen(true);
  };

  const handleOpenVerifyPropertyModal = async property => {
    setCurrentProperty(property);
    setIsVerifyPropertyModalOpen(true);
  };

  const handleOpenUnverifyEntityModal = entity => {
    setCurrentEntity(entity);
    setIsUnverifyEntityModalOpen(true);
  };

  const handleOpenUnverifyPropertyModal = property => {
    setCurrentProperty(property);
    setIsUnverifyPropertyModalOpen(true);
  };

  const handleOpenMultipleVerifyPropertyModal = () => {
    setIsMultipleVerification(true);
    setIsVerifyPropertyModalOpen(true);
  };

  const handleCloseVerifyPropertyModal = () => {
    setIsMultipleVerification(false);
    setIsVerifyPropertyModalOpen(false);
    setCurrentProperty(null);
    setCurrentPropertyVerificationSource(null);
    setPropertyVerificationOthersReason('');
  }

  const handleCloseVerifyEntityModal = () => {
    setIsVerifyEntityModalOpen(false);
    setCurrentEntity(null);
    setCurrentEntityVerificationSource(null);
    setEntityVerificationOthersReason('');
  }

  const handleCloseUnverifyPropertyModal = () => {
    setIsUnverifyPropertyModalOpen(false);
    setCurrentProperty(null);
    setPropertyUnverificationReason('');
  }

  const handleCloseUnverifyEntityModal = () => {
    setIsUnverifyEntityModalOpen(false);
    setCurrentEntity(null);
    setEntityUnverificationReason('');
  }

  const previewDocument = (id, name, fileExtension) => {
    store.downloadTaskFile(id, name, fileExtension);
  }

  const entitiesHeaders = ['Entity Name', 'Verification Source', <div style={{ textAlign: 'right' }}>Verification Status</div>,];

  const entitiesRows = baseballCardDetails?.borrowingEntities.reduce(
    (rows, entity) => {
      rows.push({
        id: entity.borrowerEntityBorrowerId,
        type: 'entity',
        cells: [
          entity.entityName,
          !entity.verificationSource || entity.verificationSource == "null" ? '-' : entity.verificationSource,
          <Chip
            label={entity.entityVerified !== 'No' ? 'Verified' : 'Not Verified'}
            style={{
              backgroundColor:
                entity.entityVerified !== 'No' ? 'green' : 'grey',
              color: 'white',
              float: 'right'

            }}
          />,
        ],
        expandableContent: (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '3rem' }}>
              <Typography variant="subtitle1">
                <strong>Documents</strong>
              </Typography>
              <Grid style={{ display: 'flex', flexDirection: "column" }}>
                {store.currentTasks && store.currentTasks.length > 0 ?
                  store.currentTasks.map(task => {
                    if (task.originalFileName) {
                      return (
                        <Link className={classes.link} key={task.taskId} onClick={() => previewDocument(task.taskId, task.originalFileName, task.fileExtension)}>{task.originalFileName}</Link>
                      )
                    }

                  })
                  : 'No Records'}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-end"
              style={{ display: 'flex', marginBottom: 10 }}
            >

              {isInternal && entity.entityVerified === 'No' && entity.isEntityPresentInLoan !== 'true' ? <ConfirmationButton
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleRemoveEntity(entity)}

                testId="remove-entity"
                confirmDialogProps={{
                  title: 'Confirmation',
                  body: 'Are you sure you want to remove this entity?',
                }}
              >
                Remove
              </ConfirmationButton> : ''}
              <Button
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                onClick={() => openUploadDocument(entity.borrowerEntityBorrowerId, 'entity')}
              >
                Upload Document
              </Button>
              {
                isInternal && entity.entityVerified === 'No' &&
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenVerifyEntityModal(entity)}
                >
                  Verify Entity
                </Button>

              }

              {
                isInternal && entity.entityVerified === 'Yes' &&
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenUnverifyEntityModal(entity)}
                >
                  Unverify Entity
                </Button>
              }


            </Grid>
          </Grid>
        ),
      });

      return rows;
    },
    []
  );

  const trackRecordsHeaders = [
    'Verification Requested',
    'Entity Name',
    'Buyer Name',
    'Property Address',
    'Purchase Date',
    'Purchase Amount',
    'Rehab Budget',
    'Seller Name',
    'Sale Date',
    'Sale Price',
    'Exit Strategy',
    <div style={{ textAlign: 'center' }}>Verification Status</div>
  ];

  const trackRecordsRows = filteredTrackRecords?.reduce((rows, property) => {
    rows.push({
      id: property.verifiedTrackRecordsId,
      object: property,
      type: 'property',
      cells: [
        property.isVerificationRequested ? 'Yes' : 'No',
        property.entityName ? property.entityName : '-',
        property.soldTo ? property.soldTo : '-',
        property.fullAddress ? property.fullAddress : `${property.purchaseAddress}, ${property.purchaseCity}, ${property.purchaseState}, ${property.purchaseZipcode}`,
        property.purchaseDate ? property.purchaseDate : '-',
        property.purchasePrice
          ? usdAmount({ value: property.purchasePrice })
          : '$0.00',
        property.rehabBudget
          ? usdAmount({ value: property.rehabBudget })
          : '$0.00',
        property.purchasedFrom ? property.purchasedFrom : '-',
        property.saleDate ? property.saleDate : '-',
        property.salePrice ? usdAmount({ value: property.salePrice }) : '$0.00',
        property.exitStrategy ? property.exitStrategy : '-',
        <VerifiedChip isVerified={property.isVerified} />
      ],
      expandableContent: (
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between' }}>
            <Grid>
              {isInternal &&
                <Grid style={{ marginRight: '4rem', marginBottom: '2rem' }}>
                  <Typography variant="subtitle1">
                    <strong>Address Links</strong>
                    {!isAddressLinksEditable && !baseballCardDetails?.lockedData ? (
                      <IconButton
                        style={{ padding: 2 }}
                        onClick={() => setIsAddressLinksEditable(true)}
                      >
                        <EditIcon style={{ color: 'grey' }} />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </Typography>
                  {isAddressLinksEditable &&
                    <Grid style={{ display: "flex", marginBottom: '5px', marginTop: '5px' }}>
                      <TextField

                        label="Name"
                        value={linkKey}
                        onChange={(e) => setLinkKey(e.target.value)}
                      />
                      <TextField
                        style={{ marginLeft: '4px' }}

                        label="Link"
                        value={linkValue}
                        onChange={(e) => setLinkValue(e.target.value)}
                      />
                    </Grid>}
                  <Grid style={{ display: 'flex', flexDirection: "column" }}>
                    <Link href={property.googleLink ? property.googleLink : `http://www.google.com/search?q=${property.purchaseAddress}, ${property.purchaseCity}, ${property.purchaseState}, ${property.purchaseZipcode}`} target="_blank">Google</Link>
                    <Link href={property.zillowLink ? property.zillowLink : `http://www.zillow.com/homes/${property.purchaseAddress}, ${property.purchaseCity}, ${property.purchaseState}, ${property.purchaseZipcode}`.replace(/ /g, "-") + "_rb/" + '|Zillow'} target="_blank">Zillow</Link>
                    <Link className={classes.link} onClick={() => openTitleHistory(property)}>Title History</Link>
                    {
                      (function () {
                        try {
                          return JSON.parse(property.links) || [];
                        } catch {
                          return [];
                        }
                      })().map((link, index) => (
                        <Grid key={index} style={{ display: 'flex', alignItems: 'center' }}>
                          <Link href={link.value} target="_blank" rel="noopener noreferrer">
                            {link.key}
                          </Link>
                          {isAddressLinksEditable && (
                            <IconButton style={{ padding: 0 }} onClick={() => handleDeleteLink(property, link)}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Grid>
                      ))
                    }
                  </Grid>

                  {isAddressLinksEditable ? (
                    <Grid
                      xs={12}
                      justifyContent="flex-end"
                      style={{ display: 'flex', padding: '1%' }}
                    >
                      <Button
                        onClick={() => {
                          setLinkKey('');
                          setLinkValue('');
                          setIsAddressLinksEditable(false)
                        }}
                        color="primary"
                        className={classes.actionButton}
                      >
                        Close
                      </Button>
                      <Button
                        style={{ margin: 0 }}
                        onClick={() => handleSaveLinks(property)}
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                      >
                        Save
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>}

              <Grid style={{ marginRight: '4rem' }}>
                <Typography variant="subtitle1">
                  <strong>Documents</strong>
                </Typography>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {store.currentTasks && store.currentTasks.length > 0 ?
                    store.currentTasks.map(task => {
                      if (task.originalFileName) {
                        return (
                          <Link className={classes.link} key={task.taskId} onClick={() => previewDocument(task.taskId, task.originalFileName, task.fileExtension)}>{task.originalFileName}</Link>
                        )
                      }

                    })
                    : 'No Records'}
                </Grid>
              </Grid>
            </Grid>


            <Grid >
              <Grid style={{ marginRight: '4rem', marginBottom: '64px' }}>
                <strong>Mortgage Amount: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.mortgageAmount
                    ? usdAmount({ value: property.mortgageAmount })
                    : '$0.00'}
                </Grid>
              </Grid>
              <Grid style={{ marginRight: '4rem' }}>
                <strong>Verification Source: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.verificationSource ?
                    property.verificationSource
                    : 'Not Selected'}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid style={{ marginRight: '4rem', marginBottom: '64px' }}>
                <strong>Source Type: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.recordSourceType ? property.recordSourceType : 'None'}
                </Grid>
              </Grid>
              <Grid style={{ marginRight: '4rem' }}>
                <strong>Lender Name: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.lenderName ?
                    property.lenderName
                    : 'None'}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid style={{ marginRight: '4rem', marginBottom: '64px' }}>
                <strong>Property Type: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.propertyType ?
                    property.propertyType
                    : 'None'}
                </Grid>
              </Grid>
              <Grid style={{ marginRight: '4rem' }}>
                <strong>Deal Type: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  {property.dealType ?
                    property.dealType
                    : 'None'}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid style={{ marginRight: '4rem', marginBottom: '50.25px' }}>
                <strong>Borrower Status: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  <VerifiedChip isVerified={property.isBorrowerVerified} />
                </Grid>
              </Grid>
              <Grid style={{ marginRight: '4rem' }}>
                <strong>Property Status: </strong>
                <Grid style={{ display: 'flex', flexDirection: "column" }}>
                  <VerifiedChip isVerified={property.isPropertyVerified} />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="flex-end"
            style={{ display: 'flex', marginBottom: 10 }}
          >
            {!property.isVerified && !property.verificationInProgress && !property.isVerificationRequested &&

              <ConfirmationButton
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleDelete(property)}

                testId="remove-property"
                confirmDialogProps={{
                  title: 'Confirmation',
                  body: 'Are you sure you want to remove this property?',
                }}
              >
                Remove
              </ConfirmationButton>
            }
            {
              ((isInternal && property.isVerified && property.recordSourceType && property.recordSourceType !== TRACK_RECORD_CAPITAL_PROVIDER) || (!property.isVerified && !property.verificationInProgress))
              &&
              <Button
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                onClick={() => handleEditProperty(property)}
              >
                Edit Property
              </Button>
            }
            {!property.verificationInProgress && (<Button
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              onClick={() => openUploadDocument(property.verifiedTrackRecordsId, 'property')}
            >
              Upload Document
            </Button>
            )}
            {
              isInternal && property.isVerified && property.recordSourceType && property.recordSourceType != TRACK_RECORD_CAPITAL_PROVIDER && !baseballCardDetails?.lockedData &&
              <Button
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleOpenUnverifyPropertyModal(property)}
              >
                Unverify Property
              </Button>
            }
            {isInternal && !property.isVerified && !property.verificationInProgress && !property.isVerificationRequested && (
              <ConfirmationButton
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleRequestVerification(property)}
                testId="confirm-unverification"
                confirmDialogProps={{
                  title: 'Request Verification',
                  body:
                    'Are you sure you want to request verification for this property ?',
                }}
              >
                Request Verification
              </ConfirmationButton>
            )}

            {isInternal && !property.isVerified && !property.verificationInProgress &&
              <Button
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={() => handleOpenVerifyPropertyModal(property)}
              >
                Verify Property
              </Button>

            }

          </Grid>
        </Grid>
      ),
    });

    return rows;
  }, []);

  const selectActions = [
    <ConfirmationButton
      className={classes.actionButton}
      variant="outlined"
      color="primary"
      disabled={store.selectedProperties.some((property) => property.isVerified || property.isVerificationRequested)}
      onClick={() => handleRemoveMultipleProperties()}
      testId="confirm-unverification"
      confirmDialogProps={{
        title: 'Remove Properties',
        body:
          'Are you sure you want to remove this properties ?',
      }}
    >
      Remove
    </ConfirmationButton>
    ,
    ...insertIf(isInternal, [
      <ConfirmationButton
        className={classes.actionButton}
        variant="outlined"
        color="primary"
        onClick={() => handleMultipleRequestVerification()}
        testId="confirm-unverification"
        confirmDialogProps={{
          title: 'Request Verification',
          body:
            'Are you sure you want to request verification for this properties ?',
        }}
      >
        Request Verification
      </ConfirmationButton>,

      <Button
        className={classes.actionButton}
        variant="outlined"
        color="primary"
        onClick={() => handleOpenMultipleVerifyPropertyModal()}
      >
        Verify Properties
      </Button>
    ])
  ]


  const disabledPropertyVerificationButton = !currentPropertyVerificationSource ||
    (currentPropertyVerificationSource === 'Other' && !propertyVerificationOthersReason);
  const disabledEntityVerificationButton = !currentEntityVerificationSource ||
    (currentEntityVerificationSource === 'Other' && !entityVerificationOthersReason);

  return (
    <Grid style={{ minHeight: '800px', width: '100%' }}>
      <PublicTrackRecordDialog
        store={store}
        isOpen={isPublicTrackRecordModalOpen}
        handleClose={() => {
          setIsPublicTrackRecordModalOpen(false)
          refreshData();
        }
        }
      />
      <StandardDialog
        open={isVerifyEntityModalOpen}
        maxWidth="sm"
        title={'Verify Entity'}
        handleClose={() => handleCloseVerifyEntityModal()}
        dialogContent={
          <Grid container direction='column'>
            <Grid  >
              <Typography variant='body1' style={{ marginBottom: '25px' }}>
                What is the verification source ?
                <FormControl style={{ marginLeft: '10px', position: 'relative', bottom: '3px', marginRight: '5px' }}>
                  <Select value={currentEntityVerificationSource} native onChange={handleEntityVerificationSourceChange}>
                    <option aria-label="None" value="" />
                    <option value={'Bizapedia'}>Bizapedia</option>
                    <option value={'Open Corporates'}>Open Corporates</option>
                    <option value={'Entity Docs'}>Entity Docs</option>
                    <option value={'Other'}>Other</option>
                  </Select>
                </FormControl>
              </Typography>
              {currentEntityVerificationSource === 'Other' && (
                <>
                  <Typography>Please specify:</Typography>
                  <FormControl style={{ width: '100%', marginBottom: '16px', paddingRight: '64px' }}>
                    <TextField
                      variant="outlined"
                      id="entityVerificationOthersReason"
                      value={entityVerificationOthersReason}
                      onChange={handleEntityVerificationReasonChange}
                    />
                  </FormControl>
                </>
              )}
              <Typography variant='body1'>
                Are you sure you want to mark this entity as verified ?
              </Typography>

            </Grid>
          </Grid>
        }
        dialogActions={
          <Grid container direction='row' justifyContent='flex-end'>
            <Button style={{ marginRight: '10px' }} onClick={() => handleCloseVerifyEntityModal()}>
              Cancel
            </Button>
            <Button disabled={disabledEntityVerificationButton} color='primary' variant='outlined' onClick={handleUpdateVerifyEntity}>
              Verify
            </Button>
          </Grid>
        }
      />
      <StandardDialog
        open={isVerifyPropertyModalOpen}
        maxWidth="sm"
        title={'Verify Property'}
        handleClose={() => handleCloseVerifyPropertyModal()}
        dialogContent={
          <Grid container direction='column'>
            <Grid  >
              <Typography variant='body1' style={{ marginBottom: '25px' }}>
                What is the verification source ?
                <FormControl style={{ marginLeft: '10px', position: 'relative', bottom: '3px', marginRight: '5px' }}>
                  <Select value={currentPropertyVerificationSource} native onChange={handlePropertyVerificationSourceChange}>
                    <option aria-label="None" value="" />
                    <option value={'RPR'}>RPR</option>
                    <option value={'Roc Title History'}>Roc Title History</option>
                    <option value={'HUD'}>HUD</option>
                    <option value={'Other'}>Other</option>
                  </Select>
                </FormControl>
              </Typography>
              {currentPropertyVerificationSource === 'Other' && (
                <>
                  <Typography>Please specify:</Typography>
                  <FormControl style={{ width: '100%', marginBottom: '16px', paddingRight: '64px' }}>
                    <TextField
                      variant="outlined"
                      id="propertyVerificationOthersReason"
                      value={propertyVerificationOthersReason}
                      onChange={handlePropertyVerificationReasonChange}
                    />
                  </FormControl>
                </>
              )}
              <Typography variant='body1'>
                Are you sure you want to mark this property as verified ?
              </Typography>

            </Grid>
          </Grid>
        }
        dialogActions={
          <Grid container direction='row' justifyContent='flex-end'>
            <Button style={{ marginRight: '10px' }} onClick={() => handleCloseVerifyPropertyModal()}>
              Cancel
            </Button>
            <Button disabled={disabledPropertyVerificationButton} color='primary' variant='outlined' onClick={handleUpdateVerifyProperty}>
              Verify
            </Button>
          </Grid>
        }

      />

      <StandardDialog
        open={isUnverifyEntityModalOpen}
        maxWidth="sm"
        title={'Unverify Entity'}
        handleClose={() => handleCloseUnverifyEntityModal()}
        dialogContent={
          <Grid container direction='column'>
            <Grid>
              <Typography>Please specify the reason:</Typography>
              <FormControl style={{ width: '100%', marginBottom: '16px', paddingRight: '64px' }}>
                <TextField
                  variant="outlined"
                  id="entityUnverificationReason"
                  value={entityUnverificationReason}
                  onChange={handleEntityUnverificationReasonChange}
                />
              </FormControl>
              <Typography variant='body1'>
                Are you sure you want to mark this entity as unverified ?
              </Typography>

            </Grid>
          </Grid>
        }
        dialogActions={
          <Grid container direction='row' justifyContent='flex-end'>
            <Button style={{ marginRight: '10px' }} onClick={() => handleCloseUnverifyEntityModal()}>
              Cancel
            </Button>
            <Button disabled={!entityUnverificationReason} color='primary' variant='outlined' onClick={handleUpdateVerifyEntityWithParam}>
              Unverify
            </Button>
          </Grid>
        }
      />
      <StandardDialog
        open={isUnverifyPropertyModalOpen}
        maxWidth="sm"
        title={'Unverify Property'}
        handleClose={() => handleCloseUnverifyPropertyModal()}
        dialogContent={
          <Grid container direction='column'>
            <Grid>
              <Typography>Please specify the reason:</Typography>
              <FormControl style={{ width: '100%', marginBottom: '16px', paddingRight: '64px' }}>
                <TextField
                  variant="outlined"
                  id="propertyUnverificationReason"
                  value={propertyUnverificationReason}
                  onChange={handlePropertyUnverificationReasonChange}
                />
              </FormControl>
              <Typography variant='body1'>
                Are you sure you want to mark this property as unverified ?
              </Typography>

            </Grid>
          </Grid>
        }
        dialogActions={
          <Grid container direction='row' justifyContent='flex-end'>
            <Button style={{ marginRight: '10px' }} onClick={() => handleCloseUnverifyPropertyModal()}>
              Cancel
            </Button>
            <Button disabled={!propertyUnverificationReason} color='primary' variant='outlined' onClick={handleUnverifyTrackRecord}>
              Unverify
            </Button>
          </Grid>
        }
      />

      <TrackRecordTitleHistory
        store={store.titleHistoryMatGridStore}
        property={store.currentCollateral}
        titleHistory={store.collateralTitleHistory}
        open={titleHistoryOpen}
        setModalOpen={setTitleHistoryOpen}
        handleClose={() => {
          store.collateralTitleHistory = null;
          setTitleHistoryOpen(false);
        }}
      />
      <PDFPreview
        open={store.isPdfPreviewModalOpen}
        title={store.pdfTitle}
        data={store.pdfData}
        headers={{}}
        fileName={store.pdfTitle}
        onClose={() => {
          store.pdfTitle = null;
          store.pdfData = null;
          store.isPdfPreviewModalOpen = false;
        }}
      />
      <PropertyInformationDialog
        store={store}
        recordSourceType={
          store.currentRecordSourceType === ''
            ? TRACK_RECORD_USER_INPUTTED
            : store.currentRecordSourceType
        }
      />
      <ConfirmDialog
        open={confirmCompleteReviewModalOpen}
        confirmButtonText={'Yes'}
        handleClose={handleCloseConfirmCompleteReviewModal}
        handleCancel={handleCloseConfirmCompleteReviewModal}
        handleConfirm={handleConfirmationCompleteReviewAction}
        title={'Complete Review'}
        body={`Are you sure you want to mark this review as completed?`}
      />
      <LinkEntityDialog
        borrowerId={store.currentBorrower}
        store={entityStore}
        open={selectEntityOpen}
        handleClose={() => {
          store.entitiesMatGridStore.setCurrentSelectedIndex(null);
        }}
        setModalOpen={setSelectEntityOpen}
        reloadData={refreshEntities}
      />
      <EntityModal
        modalState={BorrowerEntityModalState.ADD}
        open={addEntityOpen}
        handleClose={() => {
          setAddEntityOpen(false);
          store.entitiesMatGridStore.setCurrentSelectedIndex(null);
        }}
        store={store}
        handleAdd={handleAddEntity}
        handleEdit={() => { }}
      />
      <FileUploadModal
        open={!!uploadDocument}
        title={`Upload Document`}
        onClose={() => setUploadDocument(null)}
        onUpload={(files) => handleUploadDocument(currentRowId, files, currentRowType)}
      />
      <Grid item xs={12}>
        <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '20px' }}>
          {
            !hideTitle &&
            <Grid item xs={12} className={classes.header}>
              <Typography variant={'h4'}>
                {store && store.baseballCardDetails
                  ? `${store.baseballCardDetails.firstName} ${store.baseballCardDetails.lastName} - Track Record Review`
                  : 'Loading...'}
              </Typography>
            </Grid>
          }
          <Grid
            item
            xs={12}
            style={{ borderBottom: 'solid grey 1px', marginBottom: '3rem' }}
          >
            <Tabs
              value={store.selectedTab}
              onChange={onTabSelect}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Summary" />
              <Tab label="Entities" />
              <Tab label="Properties" />
            </Tabs>
          </Grid>
          {store.selectedTab === 0 &&
            <Grid spacing={3} container>
              <Grid item xs={12} style={{ marginBottom: '2%' }} className={classes.experienceComments}>
                <Grid item xs={12}
                  justifyContent="space-between"
                  style={{ display: 'flex', padding: '2% 2% 1% 2%', }}>
                  <Typography>
                    <strong>Borrower Experience</strong>
                  </Typography>
                  {isInternal && !isExperienceEditable && !baseballCardDetails?.lockedData ? (
                    <IconButton
                      style={{ padding: 2 }}
                      onClick={() => handleEditExperience()}
                    >
                      <EditIcon style={{ color: 'grey' }} />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid className={classes.addressWrapper} xs={12}>
                  <BorrowerExperienceData store={store} isInternal={isInternal} isExperienceEditable={isExperienceEditable} />
                </Grid>
                {isInternal &&
                  <Grid >
                    <Grid
                      xs={12}
                      justifyContent="space-between"
                      style={{ display: 'flex', padding: '2% 2% 1% 2%', }}
                    >
                      <Typography>
                        <strong>Experience Comments</strong>
                      </Typography>
                    </Grid>
                    <textarea
                      rows={5}
                      disabled={!isExperienceEditable}
                      onChange={e => store.setExperienceComment(e.target.value)}
                      className={
                        !isExperienceEditable
                          ? classes.nonEditableInput
                          : classes.editableInput
                      }
                      value={
                        baseballCardDetails
                          ? baseballCardDetails.experienceComment
                          : ''
                      }
                    />

                    {isExperienceEditable ? (
                      <Grid
                        xs={12}
                        justifyContent="flex-end"
                        style={{ display: 'flex', padding: '1%' }}
                      >
                        <Button
                          onClick={() => setIsExperienceEditable(false)}
                          color="primary"
                          className={classes.actionButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleExperienceSave()}
                          variant="outlined"
                          color="primary"
                          className={classes.actionButton}
                        >
                          Save
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                }
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <strong>Filters:</strong>

                <FormControl variant="outlined" style={{ marginLeft: 20, width: 200 }}>
                  <InputLabel>Verification Status</InputLabel>
                  <Select
                    native
                    value={verificationStatusFilter}
                    onChange={handleVerificationStatusFilterChange}
                    label="Verification Status"
                  >
                    <option value={'All'}>All</option>
                    <option value={'Verified'}>Verified</option>
                    <option value={'Not Verified'}>Not Verified</option>
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={12}>
                <MatDataGrid
                  store={store.propertiesMatGridStore}
                  isExpandable={false}
                  headers={trackRecordsHeaders}
                  rows={trackRercords ? trackRecordsRows : []}
                  enablePagination={true}
                />
              </Grid>

              {isInternal &&
                <Grid item xs={12}>
                  <Grid className={classes.addressWrapperTab}>
                    <TrackRecordAuditLogs store={store} />
                  </Grid>
                </Grid>
              }

              {isInternal && <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }} >
                <ConfirmationButton
                  variant="contained"
                  onClick={handleConfirmationCompleteReviewAction}
                  color="primary"
                  testId="confirmReview"
                  confirmDialogProps={{
                    title: 'Complete Review',
                    body: 'Are you sure you want to mark this review as completed?',
                  }}
                >
                  REVIEW COMPLETE
                </ConfirmationButton>
              </Grid>}

            </Grid>
          }
          {store.selectedTab === 1 && (
            <Grid spacing={3} container justifyContent='center'>
              <Grid item xs={8}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-end"
                    style={{ display: 'flex', paddingBottom: 0 }}
                  >
                    <Button
                      onClick={openAddEntityModal}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: '1rem' }}
                    >
                      Add Entity
                    </Button>
                    <Button
                      onClick={openLinkEntityModal}
                      variant="contained"
                      color="primary"
                    >
                      Link Entity
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <MatDataGrid
                      store={store.entitiesMatGridStore}
                      isExpandable={true}
                      onExpandToggle={(row, isSelected) => {
                        if (!isSelected) {
                          store.getTasks(row.id, row.type);
                        }
                      }}
                      headers={entitiesHeaders}
                      rows={baseballCardDetails ? entitiesRows : []}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {store.selectedTab === 2 && (
            <Grid container direction="row"
              justifyContent="center"
              alignItems="center" spacing={3}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <strong>Filters:</strong>
                <FormControl style={{ marginLeft: 20, width: 200 }}>
                  <TextField
                    variant="outlined"
                    label="Entity Name"
                    id="entityFilter"
                    value={entityFilter}
                    onChange={handleEntityFilterChange}
                  />
                </FormControl>
                <FormControl style={{ marginLeft: 20, width: 200 }}>

                  <TextField
                    variant="outlined"
                    label="Property Address"
                    id="propertyAdressFilter"
                    value={propertyAdressFilter}
                    onChange={handlePropertyAdressFilterChange}
                  />
                </FormControl>
                <FormControl style={{ marginLeft: 20, width: 200 }}>

                  <TextField
                    variant="outlined"
                    label="City"
                    id="cityFilter"
                    value={cityFilter}
                    onChange={handleCityFilterChange}
                  />
                </FormControl>

                <FormControl variant="outlined" style={{ marginLeft: 20, width: 200 }}>
                  <InputLabel>Verification Status</InputLabel>
                  <Select
                    native
                    value={verificationStatusFilter}
                    onChange={handleVerificationStatusFilterChange}
                    label="Verification Status"
                  >
                    <option value={'All'}>All</option>
                    <option value={'Verified'}>Verified</option>
                    <option value={'Not Verified'}>Not Verified</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                style={{ display: 'flex', paddingBottom: 0 }}
              >

                <Button
                  onClick={handleAddProperty}
                  variant="contained"
                  color="primary"

                >
                  Add Property

                </Button>

                {isInternal &&
                  <Button
                    onClick={handleLookupProperty}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '1rem' }}
                  >
                    Look Up Property from Title History
                  </Button>
                }

                {isInternal &&
                  <Button
                    onClick={() => { setIsPublicTrackRecordModalOpen(true) }}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '1rem' }}
                  >
                    Look Up Property from entities
                  </Button>
                }
              </Grid>

              <Grid item xs={12}>
                <MatDataGrid
                  store={store.propertiesMatGridStore}
                  isExpandable={true}
                  enableSelection={isInternal}
                  onExpandToggle={(row, isSelected) => {
                    store.currentTasks = [];
                    if (isSelected) {
                      store.currentProperty = null;
                      setIsAddressLinksEditable(false);
                    } else {

                      setIsAddressLinksEditable(false);
                      store.currentProperty = row.object;
                      store.getTasks(row.id, row.type)
                    }
                  }
                  }
                  onSelectedRowsChange={(rows) => { store.selectedProperties = rows.map((row) => row.object) }}
                  headers={trackRecordsHeaders}
                  rows={trackRercords ? trackRecordsRows : []}
                  enablePagination={true}
                  selectActions={selectActions}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default NewBorrowerTrackRecord;
