import { GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { GeneralTasksFilters, TaskScope, TaskStatus } from "../types/generalTasksTypes";
import { GeneralTasksStore } from "./generalTasksStore";
import { EditTaskStore } from "./editTaskStore";
import { TECH_SUPPORT_ID } from "../utils/generalTasksConstants";
import { GeneralTasksService } from "../services/generalTasksService";

export class SupportTasksStore {
  globalStore: GlobalStore;
  openModal: boolean = false;
  generalTasksStore: GeneralTasksStore;
  editTaskStore: EditTaskStore;
  private generalTaskService: GeneralTasksService;
  unreadCountMessages: number = 0;

  constructor(globalStore: GlobalStore, generalTasksStore: GeneralTasksStore) {
    this.globalStore = globalStore;
    this.generalTasksStore = generalTasksStore;
    this.editTaskStore = generalTasksStore.editTaskStore;
    this.generalTaskService = new GeneralTasksService();
    makeObservable(this, {
      openModal: observable,
      setModalOpen: action,
      saveSupportTask: flow,
      unreadCountMessages: observable,
      fetchUnreadMessagesCount: flow
    });
  }

  setDefaultFilters() {
    this.generalTasksStore.defaultFilters = {
      [GeneralTasksFilters.STATUS]: [
        { value: TaskStatus.OPEN }
      ],
      [GeneralTasksFilters.OPEN_SUBTASKS]: [],
      [GeneralTasksFilters.TASK_SCOPE]: [{ value: TaskScope.REPORTED_BY_ME }],
    };
  }

  setModalOpen(open: boolean) {
    this.openModal = open;
    this.setDefaultFilters();
  }

  *saveSupportTask() {
    this.editTaskStore.onFieldChange('assigneeId', TECH_SUPPORT_ID);
    yield this.editTaskStore.saveTaskWithMessage();
  }

  *fetchUnreadMessagesCount() {
    const response = yield this.generalTaskService.getUserTaskssUnreadCountMessages();
    this.unreadCountMessages = response.data.data;
  }

}