import { useDocumentStore } from '@roc/feature-documents';
import { ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';

export const DeleteModal = observer(
  ({ open, onClose, document, loanId, loanProcess }) => {
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;

    const handleDelete = async () => {
      await loanTodosStore.deleteDocument(document);
      loanTodosStore.fetchTodos(loanId, loanProcess);
      onClose();
    };

    const dialogContent = (
      <>Are you sure you want to delete "{document.taskName}"?</>
    );

    return (
      <ConfirmDialog
        open={open}
        title={'Delete To-do'}
        handleClose={onClose}
        handleCancel={onClose}
        handleConfirm={handleDelete}
        body={dialogContent}
      />
    );
  }
);
