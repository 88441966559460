import { observer } from 'mobx-react';

import {
  Box,
  Divider,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import {
  Button,
  DataGrid,
  GridColumnProps,
  IconButton,
  Paper,
  SelectMode,
  Toolbar,
} from '@roc/ui';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { CheckCircle, GetApp, RadioButtonUnchecked } from '@material-ui/icons';
import { ReactNode, useEffect, useState } from 'react';
import { InsuranceInformationBaseStore } from '../stores';
import { FinalDocumentType } from '../util';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '40vh',
    overflow: 'hidden',
  },
  content: {
    fontWeight: 'bold',
    fontSize: '15px',
  },
  label: {
    fontSize: '13px',
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataPoint: {
    marginTop: '5px',
    textAlign: 'center',
  },
  containerBox: {
    display: 'flex',
  },
}));

export type InsuranceInformationFinalDocumentSelectionProps = {
  store: InsuranceInformationBaseStore;
  onCancel?: () => void;
  onApprove?: () => void;
};

export const InsuranceInformationFinalDocumentSelection = observer(
  (props: InsuranceInformationFinalDocumentSelectionProps) => {
    const classes = useStyles();
    const { store, onCancel, onApprove } = props;

    const FINAL_DOCUMENT_SELECTION_COLUMNS: GridColumnProps[] = [
      {
        field: 'name',
        headerName: 'Document Name',
        minWidth: 250,
        cellStyle: {
          width: '100%',
        },
        comparator: noOpComparator,
        ...filterProps(),
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 80,
        cellRenderer: 'actionCellRenderer',
        comparator: noOpComparator,
        width: 80,
        pinned: 'right',
        lockPinned: true,
      },
    ];

    return (
      <div style={{ backgroundColor: '#FFF', width: '100%' }}>
        <Layout containerClass={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ margin: '0 0 30px 0' }}>
              <Box mb={1}>
                <Typography variant={'h4'}>Select Final Documents</Typography>
              </Box>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box mb={1}>Select the documents to create final versions</Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" height="100%">
                <Box flex={1}>
                  <Paper className={classes.paper}>
                    <InsuranceInformationFinalDocumentSelectionGrid
                      store={store}
                      columns={FINAL_DOCUMENT_SELECTION_COLUMNS}
                      toolbar={
                        <InsuranceInformationFinalDocumentSelectionToolbar
                          store={store}
                        />
                      }
                    />
                  </Paper>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  {store.finalBinder ? (
                    <CheckCircle />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </Grid>
                <Grid item xs={2}>
                  Final Binder Completed
                </Grid>
                {store.finalBinder && (
                  <>
                    <Grid item>
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          store.downloadFinalDocument(FinalDocumentType.BINDER);
                        }}
                      >
                        Final Binder.pdf
                      </Link>
                    </Grid>
                    <Grid item>
                      <GetApp
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          store.downloadFinalDocument(FinalDocumentType.BINDER)
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Grid item>
                  {store.finalInvoice ? (
                    <CheckCircle />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </Grid>
                <Grid item xs={2}>
                  Final Invoice Completed
                </Grid>
                {store.finalInvoice && (
                  <>
                    <Grid item>
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          store.downloadFinalDocument(
                            FinalDocumentType.INVOICE
                          );
                        }}
                      >
                        Final Invoice.pdf
                      </Link>
                    </Grid>
                    <Grid item>
                      <GetApp
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          store.downloadFinalDocument(FinalDocumentType.INVOICE)
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              justifyContent={'flex-end'}
            >
              <Grid item>
                <Button
                  onClick={() => {
                    onCancel && onCancel();
                    store.selectedRecords = [];
                  }}
                  testId="onCancel-final-documents"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    store.approveInsurance();
                    store.isApproved && onApprove && onApprove()
                  }}
                  testId="approve-final-documents"
                  variant="contained"
                  color="primary"
                  disabled={
                    !(store.finalBinder && store.finalInvoice) ||
                    store.isApproved
                  }
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </div>
    );
  }
);

export type InsuranceInformationFinalDocumentSelectionGridProps = {
  store: InsuranceInformationBaseStore;
  columns: GridColumnProps[];
  toolbar?: ReactNode;
};

export const InsuranceInformationFinalDocumentSelectionGrid = observer(
  ({
    store,
    columns,
    toolbar,
  }: InsuranceInformationFinalDocumentSelectionGridProps) => {
    const classes = useStyles();
    const { finalDocumentSelectionGridStore } = store;
    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
      finalDocumentSelectionGridStore.resetAndFetchGridData();
    }, []);

    const onRowSelected = params => {
      store.setSelectedRecords(params?.data, params?.node.selected);
    };

    const onGridReady = params => {
      setGridApi(params.api);
    };

    useEffect(() => {
      gridApi?.deselectAll();
    }, [store.finalInvoice, store.finalBinder]);

    const frameworkComponents = {
      actionCellRenderer: params => {
        return (
          <IconButton
            testId={'download'}
            onClick={e => {
              store.downloadInsuranceQuoteDocument(params.node.data);
            }}
          >
            <GetApp />
          </IconButton>
        );
      },
    };

    return (
      <Box>
        <Grid item className={classes.gridContainer}>
          <DataGrid
            columns={columns}
            rows={finalDocumentSelectionGridStore.gridData.data.rows}
            frameworkComponents={frameworkComponents}
            selectMode={SelectMode.MULTIPLE}
            headerCheckboxSelection={false}
            suppressRowClickSelection={true}
            toolbar={toolbar}
            key={'finalDocumentSelection'}
            isLoading={finalDocumentSelectionGridStore.isLoading}
            showFilters={false}
            showPagination={false}
            onRowSelected={onRowSelected}
            gridReady={onGridReady}
          />
        </Grid>
      </Box>
    );
  }
);

export type InsuranceInformationFinalDocumentSelectionToolbarProps = {
  store: InsuranceInformationBaseStore;
};

export const InsuranceInformationFinalDocumentSelectionToolbar = observer(
  ({ store }: InsuranceInformationFinalDocumentSelectionToolbarProps) => {
    return (
      <Toolbar>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              id="create-final-binder"
              onClick={() => {
                store.createFinalBinder();
              }}
              testId="create-final-binder"
              variant="contained"
              color="primary"
              disabled={!store.selectedRecords.length}
            >
              Create Final Binder
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="create-final-invoice"
              onClick={() => {
                store.createFinalInvoice();
              }}
              testId="create-final-invoice"
              variant="contained"
              color="primary"
              disabled={!store.selectedRecords.length}
            >
              Create Final Invoice
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
);
