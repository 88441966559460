import { observer } from 'mobx-react';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { formatCurrency } from '@roc/ui/utils';
import { insertIf, LoanSubType } from '@roc/feature-utils';
import {
  getValueOrDefaultDash,
} from '../../../utils/utils';
import { REFINANCE } from '@roc/feature-utils';
import { propertySourceValues } from '../../../utils/constants';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';

export const ProjectDetailsTable = observer(
  ({ store }: { store: PropertyFormStore }) => {
    const projectDetails: DetailsTableField[] = getProjectDetails(store);
    return <DetailsTable fields={projectDetails} />;
  }
);

export const getProjectDetails = (store: PropertyFormStore): DetailsTableField[] => {
  switch (store.loanSubType) {
    case LoanSubType.FIX_AND_FLIP:
      return getFixAndFlipProjectDetails(store);
    case LoanSubType.GROUND_UP:
      return getGroundUpProjectDetails(store);
    case LoanSubType.STABILIZED_BRIDGE:
      return getStabilizedBridgeProjectDetails(store);
    default: {
      return [];
    }
  }
}
function getFixAndFlipProjectDetails(store: PropertyFormStore): DetailsTableField[] {
  const property = store.getFormValues();
  const purchasePrice = formatCurrency(property.purchasePrice);
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;

  const projectDetails: DetailsTableField[] = [
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.wholesaler,
      [
        {
          label:
            'How much does the wholesaler have the property under contract for?',
          value: formatCurrency(property.wholesalerPropertyContractAmount),
          colSpan: 1,
        },
      ]
    ),
    { label: 'Purchase Price', value: purchasePrice, colSpan: 1 },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Current Market Value',
        value: formatCurrency(property.currentMarketValue),
        colSpan: 1,
      },
      {
        label: 'Construction/Reno Spend to Date',
        value: formatCurrency(property.constructionRenoSpendToDate),
        colSpan: 1,
      },
    ]),
    {
      label: isPropertyOwnedByBorrower ? 'Proposed Additional Construction Budget' : 'Proposed Construction Budget',
      value: property.renovationBudget ? formatCurrency(property.renovationBudget) : '-',
      colSpan: 1,
    },
    {
      label: 'Special project type?',
      value: getValueOrDefaultDash(property.describeRenovation),
      colSpan: 1,
    },
    {
      label: 'Market Value After Renovation Completion',
      value: property.marketValueAfterCompletion ? formatCurrency(property.marketValueAfterCompletion) : '-',
      colSpan: 1,
    },
  ];
  checkAndCorrectColSpanForFields(projectDetails);

  return projectDetails;
}

function getGroundUpProjectDetails(store: PropertyFormStore): DetailsTableField[] {
  const property = store.getFormValues();
  const purchasePrice = formatCurrency(property.purchasePrice);
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;

  const projectDetails: DetailsTableField[] = [
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.wholesaler,
      [
        {
          label:
            'How much does the wholesaler have the property under contract for?',
          value: formatCurrency(property.wholesalerPropertyContractAmount),
          colSpan: 1,
        },
      ]
    ),
    { label: 'Purchase Price', value: purchasePrice, colSpan: 1 },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Current Market Value',
        value: formatCurrency(property.currentMarketValue),
        colSpan: 1,
      },
      {
        label: 'Construction/Reno Spend to Date',
        value: formatCurrency(property.constructionRenoSpendToDate),
        colSpan: 1,
      },
    ]),
    {
      label: isPropertyOwnedByBorrower ? 'Proposed Additional Construction Budget' : 'Proposed Construction Budget',
      value: property.renovationBudget ? formatCurrency(property.renovationBudget) : '-',
      colSpan: 1,
    },
    {
      label: 'Market Value After Renovation Completion',
      value: property.marketValueAfterCompletion ? formatCurrency(property.marketValueAfterCompletion) : '-',
      colSpan: 1,
    },
  ];
  checkAndCorrectColSpanForFields(projectDetails);

  return projectDetails;
}

function getStabilizedBridgeProjectDetails(store: PropertyFormStore): DetailsTableField[] {
  const property = store.getFormValues();
  const purchasePrice = formatCurrency(property.purchasePrice);
  const isPropertyOwnedByBorrower = property.loanPurpose === REFINANCE;

  const projectDetails: DetailsTableField[] = [
    ...insertIf(
      !isPropertyOwnedByBorrower &&
      property.propertySourcing === propertySourceValues.wholesaler,
      [
        {
          label:
            'How much does the wholesaler have the property under contract for?',
          value: formatCurrency(property.wholesalerPropertyContractAmount),
          colSpan: 1,
        },
      ]
    ),
    { label: 'Purchase Price', value: purchasePrice, colSpan: 1 },
    {
      label: 'Current Market Value',
      value: formatCurrency(property.currentMarketValue),
      colSpan: 1,
    },
    {
      label: 'Monthly Market Rent',
      value: formatCurrency(property.monthlyMarketRent),
      colSpan: 1,
    },
    ...insertIf(isPropertyOwnedByBorrower, [
      {
        label: 'Construction/Reno Spend to Date',
        value: formatCurrency(property.constructionRenoSpendToDate),
        colSpan: 1,
      },
    ]),
    {
      label: 'Annual Taxes',
      value: formatCurrency(property.annualTaxes),
      colSpan: 1,
    },
    {
      label: 'Annual Insurance',
      value: formatCurrency(property.annualInsurance),
      colSpan: 1,
    },
  ];
  checkAndCorrectColSpanForFields(projectDetails);

  return projectDetails;
}

/**
 * Function that adds colspan to last row in case it will not complete the row, if
 * this is not done it could make the last field to not appear in the property details table
 *
 * @param propertyDetails
 */
const checkAndCorrectColSpanForFields = (projectFields) => {
  const totalColSpan = projectFields.reduce((sum, obj) => sum + (obj.colSpan || 0), 0);
  if (totalColSpan > 0 && totalColSpan % 2 !== 0) {
    projectFields[projectFields.length - 1].colSpan = 2;
  }
}
