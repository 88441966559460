import { Service } from "@roc/feature-app-core";

export const url = {
  GET_CREDS: '/api/v1/vendor/gmail/oauth/getCreds',
  OAUTH_CALLBACK: '/api/v1/vendor/gmail/oauth/token',
};
export class GmailService extends Service {

  exchangeAuthCodeForToken(req) {
      this.post(url.OAUTH_CALLBACK,req);
  }

  getCreds() {
      return this.get(url.GET_CREDS);
  }

}