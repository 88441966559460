import { action, computed, flow, makeObservable, observable } from 'mobx';
import { startOfToday, differenceInYears, format } from 'date-fns';
import EntityStore from './entityStore';
import BorrowerStore from './borrowerFormStore';
import PricerSummaryStore from './pricerSummaryStore';
import SinglePropertyPricerStore from '../singleProperty/pricerStore';
import RentalPortfolioPricerStore from '../rentalPortfolio/pricerStore';
import { DraftLoan, Entity, Property, PropertyDetailsSummary, PropertyTypeSummary } from '@roc/feature-types';
import { ApiResponse } from '@roc/feature-app-core';
import { LoanService } from '../../../loans/services/loanService';
import { Roles, normalizeState, generateUUID, LOAN_WITHOUT_LEAD_SOURCE_VALIDATION, segmentAnalytics, SegmentTrackerEvent, PURCHASE, LoanStatus } from '@roc/feature-utils';
import { requiredLicenseStates, prequalificationStatusProperties } from '@roc/feature-utils';

import {
  ELMSURE,
  formatDateIgnoreTimezone,
  getUniqueId,
  isEmptyObject,
  isNil,
  isNotBlank,
  Utility,
  parseDate,
  WIMBA,
  REQUEST_LOAN_TYPE_LOAN,
  OTHER
} from '@roc/feature-utils';
import LoanDetailsStore from './loanDetailsStore';
import primaryPropertyTemplate from '../templates/primaryPropertyTemplate';
import {
  borrowerExpDictionary,
  LoanSubType,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  PROPERTY_TYPES_RUSHED_APPRAISAL,
  sfrPPPMapping,
} from '@roc/feature-utils';
import { Borrower } from '@roc/feature-types';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import BorrowerFormStore from './borrowerFormStore';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { mapBooleanValue } from './pricerSummaryHelper';
import {
  getBorrowerEntity,
  getBorrowers,
  getLoanTermPricerDetails,
  getTermLoanDetails,
  getTermPricerDetails,
  getTermPortfolioPricerDetails,
  getPropertyDetails,
  getTermPricerDetailsFromExistingLoan,
  getBorrowerEntityForLoanApplication,
  getLoanTermPricerDetailsFromExistingLoan,
} from './convert-draft-loan-helper';
import { canSubmitAppraisalWithTamarisk } from '../../utils/appraisalUtils';
import { isBorrowerPersonGuarantor } from '@roc/feature-utils';
import { transactionRolesTerm } from '@roc/ui/formComponents';
import { BrokersStore } from '@roc/feature-brokers';
import { DscrCalculatorStore } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/stores/dscrCalculatorStore';
import { US_TREASURY_5_YR_INDEX } from 'libs/feature-loans/src/rentalPricer';
import { LoanSubmissionStep } from '../../utils/constants';
import { EditLoanSubmissionService } from '@roc/feature-edit-loans';
import { MarketingPromotionsStore } from '@roc/feature-sendgrid';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';
import { getQuoteFromLoanSubmitJson } from './mapLoanToQuoteHelper';

export class TermStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  brokersStore: BrokersStore;
  entityStore: EntityStore;
  borrowerFormStore: BorrowerFormStore;
  pricerStore: SinglePropertyPricerStore | RentalPortfolioPricerStore;
  pricerSummaryStore: PricerSummaryStore;
  loanDetailsStore: LoanDetailsStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanService: LoanService;
  marketingPromotionsStore: MarketingPromotionsStore;
  private editLoanSubmissionService: EditLoanSubmissionService;
  dscrCalculatorStore: DscrCalculatorStore;
  public draftLoanInfo: DraftLoan;
  public entity: Entity | Record<string, never>;
  public rawEntity: Entity | Record<string, never>;
  public selfBorrower: Borrower | Record<string, never>;
  public borrowers;
  public loanTerms;
  public properties: Property[];
  public loanType: string;
  public activeStep: number;
  public stepError: string;
  public allErrors: string[];
  public loanSubtype: LoanSubType;
  public saved: boolean;
  public requestSaved: boolean;
  public isNewEntity: boolean;
  public selectedEntities: any[];
  public savedLoanId: number;
  public allCounties;
  public hasFastTrack: boolean;
  public isFastTrackPaymentComplete: boolean;
  public isSmallPortfolio: boolean;
  public propertySummary: PropertyTypeSummary[];
  public totalEstimatedAsIsValue: number;
  public totalEstimatedAsIsValueProperties: number;
  public totalPurchasePrice: number;
  public propertiesDetails: Property[];
  public propertyDetailsSummary: PropertyDetailsSummary[];
  public propertyInformationErrors: String[];
  public disableSubmitButton: boolean;
  public areBorrowersPreQualified: boolean;
  public allWarnings: string[];
  public borrowersErrors: string[];
  public borrowersWarnings: string[];
  public editLoanJson: any;
  public loanApplication: any;
  private oneToolService: OneToolService;

  constructor(globalStore, userStore, brokersStore: BrokersStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.entityStore = new EntityStore(globalStore, this);
    this.borrowerFormStore = new BorrowerStore(globalStore);
    this.loanDetailsStore = new LoanDetailsStore(globalStore, this);
    this.pricerSummaryStore = new PricerSummaryStore(globalStore, this, userStore);
    this.editLoanSubmissionService = new EditLoanSubmissionService();
    this.brokersStore = brokersStore;
    this.dscrCalculatorStore = new DscrCalculatorStore(globalStore);
    this.selectBorrowersStore = new SelectBorrowersStore(
      globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      borrower => this.setNewSelectedBorrowerDefaults(borrower),
      () => new BorrowerStore(globalStore)
    );
    this.loanService = new LoanService();
    this.editLoanSubmissionService = new EditLoanSubmissionService();
    this.marketingPromotionsStore = new MarketingPromotionsStore(globalStore);
    this.oneToolService = new OneToolService();
    this.setDefaults();

    makeObservable(this, {
      entity: observable,
      selfBorrower: observable,
      borrowers: observable,
      loanTerms: observable,
      properties: observable,
      saved: observable,
      requestSaved: observable,
      savedLoanId: observable,
      activeStep: observable,
      stepError: observable,
      allCounties: observable,
      allErrors: observable,
      reset: action,
      loadStore: flow,
      setExistingEntity: action,
      setDefaultEntity: action,
      setEntity: action,
      setSelectedEntity: action,
      setIsNewEntity: action,
      moveToStep: action,
      goNextStep: action,
      goPrevStep: action,
      setBorrowers: action,
      setNewBorrowers: action,
      setLoanTerms: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      loanSubmitJson: computed,
      onLoanSave: flow,
      getDraftLoan: flow,
      onLoanSubmit: flow,
      fetchStateCounties: flow,
      log: flow,
      deleteEntity: action,
      setSaved: action,
      setDraftLoanId: action,
      getBoolean: action,
      getValues: action,
      getPropertyTypes: action,
      getPropertyTypesAsString: action,
      getNumberOfUnits: action,
      getPropertyDataPortfolio: action,
      onReprice: action,
      isValidBankruptcyDate: action,
      isValidForeclosureDate: action,
      canSubmitAppraisalWithTamarisk: computed,
      handleBorrowerInformationChange: action,
      getPrepaymentPenaltyDescription: action,
      sendFastTrackPayment: flow,
      setRushedAppraisalProperties: action,
      checkSmallPortfolio: flow,
      isSmallPortfolio: observable,
      propertySummary: observable,
      updatePropertySummary: flow,
      totalEstimatedAsIsValue: observable,
      totalEstimatedAsIsValueProperties: observable,
      propertiesDetails: observable,
      setPropertiesDetails: flow,
      updatePropertyDetails: action,
      propertyDetailsSummary: observable,
      deletePropertyDetails: action,
      checkPropertyErrors: flow,
      propertyInformationErrors: observable,
      draftLoanInfo: observable,
      isLoanRequest: computed,
      disableSubmitButton: observable,
      validateBorrowerErrors: flow,
      validateEntityErrors: flow,
      areBorrowersPreQualified: observable,
      getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans: flow,
      allWarnings: observable,
      borrowersErrors: observable,
      borrowersWarnings: observable,
      setBroker: action,
      editLoanJson: observable,
      getEditLoanJsonById: flow,
      onEditLoanSubmit: flow,
      propertyData: observable,
      loanApplication: observable,
      setLoanApplicationId: action,
      getLoanApplicationById: flow,
      rejectLoanApplication: flow,
      onLoanApplicationSave: flow,
      saveLoanQuoteForApplication: flow,
    });
  }

  private setDefaults() {
    this.entity = {};
    this.rawEntity = {};
    this.loanTerms = {};
    this.properties = [];
    this.loanType = 'Residential Term';
    this.activeStep = 0;
    this.stepError = '';
    this.allErrors = [];
    this.saved = false;
    this.requestSaved = false;
    this.savedLoanId = null;
    this.isNewEntity = false;
    this.allCounties = {};
    this.selectedEntities = [];
    this.draftLoanInfo = {
      borrowers: null,
      propertyAddress: null,
      loanType: null,
      loanSubType: null,
      amount: null,
      createdBy: null,
      createdDate: null,
      creationDate: null,
      draftLoanId: null,
      id: null,
      isActive: true,
      type: null,
      rejectRequestReason: null,
      status: null,
      loanApplicationId: null,
      borrowerLoanApplication: false,
      loanQuoteId: null,
    };
    this.hasFastTrack = false;
    this.isFastTrackPaymentComplete = false;
    this.isSmallPortfolio = false;
    this.propertiesDetails = [];
    this.propertyInformationErrors = [];
    this.propertySummary = [];
    this.propertyDetailsSummary = [];
    this.totalEstimatedAsIsValue = 0;
    this.totalEstimatedAsIsValueProperties = 0;
    this.disableSubmitButton = false;
    this.areBorrowersPreQualified = true;
    this.allWarnings = [];
    this.borrowersErrors = [];
    this.borrowersWarnings = [];
    this.editLoanJson = {};
    this.loanApplication = {
      loanApplicationId: null,
    }
  }

  *fetchStateCounties() {
    try {
      const response: ApiResponse = yield this.loanService.getStateCounties();
      this.allCounties = response.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting state counties.',
      });
    }
  }

  setIsNewEntity = isNewEntity => {
    this.isNewEntity = isNewEntity;
  };

  private calculateBorrowerPercentages = () => {
    return this.selectBorrowersStore.borrowers.reduce(
      (total, current) => total + Number(current.pctOwnership ?? 0),
      0
    );
  };

  borrowersHaveEmail = () => {
    return this.selectBorrowersStore.borrowers.length
      ? this.selectBorrowersStore.borrowers.findIndex(
        borrower => !borrower.emailAddress
      ) !== -1
      : false;
  };

  public checkBorrowersErrors = () => {
    this.borrowersErrors = this.selectBorrowersStore.borrowerAccordionStore.validate();
    if (this.borrowersHaveEmail()) {
      this.borrowersErrors.push('Please add email address to every borrower.');
    }
  };

  public checkBorrowersWarnings = () => {
    this.borrowersWarnings = [];
    if (this.selectBorrowersStore.borrowers.length > 0 && this.calculateBorrowerPercentages() !== 100) {
      this.borrowersWarnings.push(
        'Please ensure that "Percentage of Ownership" adds to a total of 100%. Click the pencil icon to edit your borrower(s) and adjust the ownership percentage slider accordingly.'
      );
    }
  }

  checkPoints() {
    const isBrokerUser = this.userStore.userInformation?.roles?.includes(Roles.BROKER);
    const brokerPoints = this.loanDetailsStore.form.fields.brokerPointsIn.value;
    console.log(brokerPoints)
    if (isBrokerUser && brokerPoints > 2) {
      return 'Broker points must not exceed 2%.'
    }
  }

  private checkAllErrors = () => {
    this.allErrors = [];

    const pointsError = this.checkPoints();
    if (pointsError)
      this.allErrors.push(pointsError);

    if (isEmptyObject(this.entity)) {
      this.allErrors.push('Please select an entity or create a new one.');
    }
    if (this.selectBorrowersStore.borrowers.length === 0) {
      this.allErrors.push('Please select a borrower or add a new one.');
    }
    if (this.isSmallPortfolio && !this.checkGridData()) {
      this.allErrors.push('Please add properties details.');
    }
    if (this.globalStore.lenderInfo?.showTPOLicenseValidation && requiredLicenseStates.includes(normalizeState(this.property.state)) &&
      !this.globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(this.property.state)) && this.loanDetailsStore.licenseUpload.length == 0) {
      this.allErrors.push('Please upload the corresponding state license.');
    }
  };

  private checkStepErrors = (currentStep: LoanSubmissionStep) => {
    this.checkAllErrors();
    switch (currentStep) {
      case LoanSubmissionStep.LOAN_PRICER: {
        if (this.pricerStore.checkIfRequiredFieldsEmpty()) {
          this.stepError = 'Please fill all the required fields';
          return true;
        } else if (this.pricerStore.checkForPricerStepErrors()) {
          return true;
        } else if (this.loanDetailsStore.form.fields.brokerPointsIn.value > 2) {
          this.stepError = 'Broker Points cannot be more than 2%';
          return true;
        } else {
          this.stepError = '';
          return false;
        }
      }
      case LoanSubmissionStep.LOAN_PRICER_SUMMARY: {
        if (this.pricerSummaryStore.checkIfRequiredFieldsEmpty()) {
          return true;
        } else {
          this.stepError = '';
          return false;
        }
      }
      case LoanSubmissionStep.ENTITY_INFORMATION: {
        if (isEmptyObject(this.entity)) {
          this.stepError = 'Please select an entity or create a new one.';
          return true;
        } else {
          this.stepError = '';
          return false;
        }
      }
      case LoanSubmissionStep.BORROWER_INFORMATION: {
        if (this.selectBorrowersStore.borrowers.length === 0) {
          this.stepError = 'Please select a borrower or add a new one.';
          return true;
        } else if (this.calculateBorrowerPercentages() > 100) {
          this.stepError = `Sum of borrowers' percent ownership cannot be greater than 100%. (Currently ${this.calculateBorrowerPercentages()} %)`;
          return true;
        } else if (this.calculateBorrowerPercentages() !== 100) {
          this.stepError = `Sum of borrowers' percent ownership should add up to 100 %. (Currently ${this.calculateBorrowerPercentages()} %)`;
          return true;
        } else {
          this.stepError = '';
          return false;
        }
      }
      case LoanSubmissionStep.PROPERTY_INFORMATION: {
        if (this.isSmallPortfolio) {
          if (!this.checkGridData()) {
            this.stepError = `You should fill all the required fields for each property`;
            return true;
          } else if (this.getTotalPurchasePrice() !== this.pricerStore.getProperty().acquisitionPrice) {
            this.stepError = `Total purchase price should match previously entered value: $${this.pricerStore.getProperty().acquisitionPrice}`;
            return true;
          } else if (this.totalEstimatedAsIsValue !== this.totalEstimatedAsIsValueProperties) {
            this.stepError = `Total estimated As Is value should match previously entered value: $${this.totalEstimatedAsIsValue}`;
            return true;
          } else if (!this.checkPropertiesDate()) {
            this.stepError = `Property purchase date can't be more recent than last purchase date previously entered: ${formatDateIgnoreTimezone(this.pricerStore.getAcquisitionDate())}`;
            return true;
          } else if (this.propertyInformationErrors.length !== 0) {
            this.stepError = `Please fix property type errors to continue`;
            return true;
          } else {
            this.stepError = '';
            return false;
          }
        }
        else {
          if (
            this.pricerStore.checkIfRequiredFieldsEmpty() ||
            this.pricerStore.checkIfRequiredPropertyFieldsEmpty()
          ) {
            this.stepError = 'Please fill all the required fields';
            return true;
          } else if (this.pricerStore.checkForPropertyStepErrors()) {
            return true;
          } else {
            this.stepError = '';
            return false;
          }
        }
      }
      case LoanSubmissionStep.LOAN_DETAILS: {
        this.stepError = this.checkPoints();
        console.log(this.stepError)
        if (this.stepError) {
          return true;
        }
        if (this.loanDetailsStore.checkIfRequiredFieldsEmpty()) {
          this.stepError = 'Please fill all the required fields';
          return true;
        } else if (this.loanDetailsStore.checkForStepErrors()) {
          return true;
        } else {
          this.stepError = '';
          return false;
        }
      }
      default: {
        this.stepError = '';
        return false;
      }
    }
  };

  private canGoBeyond(step) {
    if (!this.userStore.allowLoanPricing) {
      return true;
    } else if (!this.pricerSummaryStore.haveRatesBeenFetched && step > 1) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          'Please check the pricer summary before moving to the other steps',
      });
      return false;
    } else {
      return true;
    }
  }

  moveToStep = (step, currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.selectBorrowersStore.borrowerAccordionStore.saveBorrowersFromAccordion();
    }
    this.stepError = '';
    if (!this.checkStepErrors(currentStep) && this.canGoBeyond(step)) {
      this.activeStep = step;
    }
    if (this.stepError) {
      this.globalStore.notificationStore.showErrorNotification({
        message: this.stepError,
      });
    }
    if (this.activeStep == 0)
      this.pricerSummaryStore.lenderFeesFormStore.resetStore();
  };

  goPrevStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    this.checkStepErrors(currentStep);
    this.activeStep--;
    if (this.activeStep == 0)
      this.pricerSummaryStore.lenderFeesFormStore.resetStore();
  };

  goNextStep = (currentStep: LoanSubmissionStep) => {
    this.moveToStep(this.activeStep + 1, currentStep);
  };

  *loadStore(editLoanId = null) {
    const draftLoanId = this.draftLoanInfo.draftLoanId;
    const loanApplicationId = this.loanApplication.loanApplicationId;
    this.reset();
    this.fetchStateCounties();
    if (!isNil(editLoanId)) {
      this.getEditLoanJsonById(editLoanId);
    }
    else if (!isNil(loanApplicationId)) {
      this.getLoanApplicationById(loanApplicationId);
    }
    if (!isNil(draftLoanId)) {
      this.getDraftLoan(draftLoanId);
    }
    //yield this.loanDetailsStore.getLenderAttorneys();
    yield this.loanDetailsStore.getWimbaStates()

    if (this.loanDetailsStore.loanParticipantsStore.isFacoOriginator) {
      this.loanDetailsStore.loanOriginatorStore.fetchOriginators();
    } else {
      this.loanDetailsStore.loanParticipantsStore.fetchLoanParticipants();
    }
    if (this.globalStore.userFeatures.isBroker) {
      yield this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
      yield this.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(
        this.brokersStore.selectBrokersStore.brokers[0].brokerId
      );
    }
  }

  reset() {
    this.setDefaults();
    this.entityStore.resetStore();
    this.borrowerFormStore.reset();
    this.pricerSummaryStore.resetStore();
    this.pricerStore.reset();
    this.loanDetailsStore.resetStore();
    this.selectBorrowersStore.reset();
    this.brokersStore.reset();
    if (this.globalStore.userFeatures.isBroker) {
      this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
    }
    this.dscrCalculatorStore.reset();
    this.loanDetailsStore.loanParticipantsStore.reset();
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    const pricerFields = this.pricerStore.form.fields;
    return {
      ...borrower,
      borrowerExp: pricerFields.borrowerExp.value,
      ficoProvidedAtOrigination: pricerFields.ficoProvidedAtOrigination.value,
      personalGuarantor: isBorrowerPersonGuarantor(borrower.pctOwnership),
    };
  }

  setNewSelectedBorrowerDefaults(borrower: Borrower) {
    const pricerFields = this.pricerStore.form.fields;
    return {
      ...borrower,
      citizenshipStatus: pricerFields.citizenshipStatus.value,
    };
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  handleBorrowerInformationChange() {
    this.prepareBorrowers();
  }

  setSelectedEntity(entity, isSelected) {
    if (isSelected) {
      this.selectedEntities = [...this.selectedEntities, entity];
    } else {
      const index = this.selectedEntities.findIndex(
        selectedEntity =>
          selectedEntity.borrowerEntityId === entity.borrowerEntityId
      );
      this.selectedEntities.splice(index, 1);
    }
  }

  setExistingEntity() {
    const selectedEntity = { ...this.selectedEntities[0] };
    if (!selectedEntity.name) return;

    let initialBorrowers = [];
    const _borrowers = [];
    for (const option of selectedEntity.borrowers) {
      const borrower = this.setNewSelectedBorrowerDefaults(
        this.setBorrowerDefaults({
          ...option.borrower,
          borrowerId: option.borrower.borrowerId,
          pctOwnership: option.pctOwnership ?? 0,
          roleInTransaction: '',
          firstName: option.borrower.firstName,
          lastName: option.borrower.lastName,
          cellPhone: option.borrower.cellPhone,
          emailAddress: option.borrower.emailAddress,
        })
      );
      _borrowers.push(borrower);
    }

    this.rawEntity = { ...selectedEntity };

    this.setEntity(selectedEntity, [...initialBorrowers, ..._borrowers]);
  }

  setDefaultEntity() {
    const _borrowers = [];
    this.entity = {
      name: '',
      type: 'Limited Liability Company',
      borrowers: _borrowers,
      loanApplicationUuid: generateUUID()
    };
    this.setBorrowers(_borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  setEntity(entity, borrowers = []) {
    this.entity = {
      borrowerEntityId: entity.borrowerEntityId,
      name: entity.name,
      ein: entity.ein,
      type: entity.type,
      operatingAgreementDate: entity.operatingAgreementDate,
      address: entity.address,
      streetNumber: entity.streetNumber,
      streetName: entity.streetName,
      city: entity.city,
      state: entity.state,
      zipCode: entity.zipCode,
      loanApplicationUuid: entity.loanApplicationUuid ?? generateUUID(),
      borrowers,
    };
    this.setBorrowers(borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  deleteEntity = () => {
    this.entity = {};
    this.rawEntity = {};
    this.setBorrowers([]);
    this.isNewEntity = false;
    this.selectedEntities = [];
  };

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  setIsLoanBeingEdit(isLoanBeingEdit) {
    this.selectBorrowersStore.setIsLoanBeingEdit(isLoanBeingEdit);
  }

  setProperties(properties) {
    this.properties = properties;
  }

  setPropertiesDetails(properties) {
    if (this.propertiesDetails.length === 0) {
      properties.map(property => {
        for (let i = 0; i < property.numberOfProperties; i++) {
          const propertyNew = this.createPropertyCopy(property);
          this.propertiesDetails.push(propertyNew);
        }
      })
    }
  }

  setPropertySummary(propertySummary) {
    this.propertySummary = propertySummary;
  }

  setPropertyDetailsSummary(propertyDetailsSummary) {
    this.propertyDetailsSummary = propertyDetailsSummary;
  }

  addProperty = property => {
    this.properties.push(property);
    const { properties } = this.pricerStore.form.fields;
    properties.value = this.properties;
    this.updatePropertySummary();
    this.checkSmallPortfolio();
    if (this.isSmallPortfolio) {
      this.addPropertyDetails(property);
      this.updatePropertyDetailsSummary();
    }
  }

  addPropertyDetails = property => {
    let numberOfProperties = property.numberOfProperties;
    for (let i = 0; i < numberOfProperties; i++) {
      const propertyNew = this.createPropertyCopy(property)
      this.propertiesDetails.push(propertyNew);
    }
  }

  checkPropertyErrors = () => {
    this.propertyInformationErrors = [];
    const propertyTypes = [...new Set(this.propertiesDetails.map(x => x.useCode))];
    propertyTypes.forEach(propertyType => {
      if (!(this.getNumberOfPropertiesByType(propertyType) === this.propertiesDetails.filter(property => property.useCode === propertyType).length)) this.propertyInformationErrors.push("The number of " + propertyType + " properties doesn't match previously entered value");
    });
    // if (this.isSmallPortfolio && this.propertiesDetails.some(property => property.rural && this.pricerStore.form.fields.rural.value && property.rural != this.pricerStore.form.fields.rural.value)) {
    //   this.propertyInformationErrors.push(`The loan is priced as ${this.pricerStore.form.fields.rural.value == 'Y' ? 'rural' : 'urban'} and one or more properties are selected to be priced as ${this.pricerStore.form.fields.rural.value != 'Y' ? 'rural' : 'urban'}. Please reprice the loan accordingly.`);
    // }
  }

  private validateNumberOfPropertiesByType = (propertyType) => {
    if (this.getNumberOfPropertiesByType(propertyType) === this.propertiesDetails.filter(property => property.useCode === propertyType).length) {
      return true;
    } else {
      return false;
    }
  }

  getNumberOfPropertiesByType = (propertyType) => {
    return this.properties
      .filter(property => property.propertyType === propertyType)
      .map(x => x.numberOfProperties || 0)
      .reduce((sum, current) => sum + Number(current), 0);
  }

  updatePropertyDetails = updatedProperty => {
    this.propertiesDetails = this.propertiesDetails.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
  };

  updatePropertySummary = () => {
    this.getTotalEstimatedAsIsValue();
    this.setPropertySummary([{ propertyType: "Totals", numberOfProperties: this.getNumberOfProperties(), estimatedAsIsValue: this.totalEstimatedAsIsValue }]);
  }

  updatePropertyDetailsSummary = () => {
    this.getTotalEstimatedAsIsValueDetails();
    this.setPropertyDetailsSummary([{ useCode: "Totals", address: "", purchasePrice: this.getTotalPurchasePrice(), estimatedAsIsValue: this.totalEstimatedAsIsValueProperties }])
  }

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        if (property.propertyType != updatedProperty.propertyType) {
          this.addPropertyDetails(updatedProperty);
        } else if (property.numberOfProperties < updatedProperty.numberOfProperties) {
          let diference = updatedProperty.numberOfProperties - property.numberOfProperties;
          for (let i = 0; i < diference; i++) {
            const propertyNew = this.createPropertyCopy(updatedProperty);
            this.propertiesDetails.push(propertyNew);
          }
        }
        return updatedProperty;
      }
      return property;
    });
  };

  createPropertyCopy = property => {
    const propertyNew = {
      ...property,
      propertyId: getUniqueId(),
      loanApplicationUuid: generateUUID(),
      useCode: property.propertyType,
      address: property.address,
      numberOfProperties: 1,
      estimatedAsIsValue: 0,
      rural: null
    }
    return propertyNew;
  }

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
    this.checkSmallPortfolio();
    this.updatePropertySummary();
    this.checkPropertyErrors();
  };

  deletePropertyDetails = (propertyId: string, useCode: string) => {
    if (!this.validateNumberOfPropertiesByType(useCode)) {
      this.propertiesDetails = this.propertiesDetails.filter(x => x.propertyId !== propertyId);
      this.updatePropertyDetailsSummary();
      this.checkPropertyErrors();
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Number of ' + useCode + " properties matches previously entered value, try reprice loan to delete this property",
      });
    }

  }

  getPropertyTypes = () => {
    return this.properties.filter(x => x.propertyType).map(x => x.propertyType);
  };

  getPropertyTypesAsString = () => {
    return this.properties
      .filter(x => x.propertyType)
      .map(x => x.propertyType)
      .join(',');
  };

  getNumberOfProperties = () => {
    const { isSingleProperty } = this.pricerStore;
    if (isSingleProperty) {
      return 1;
    } else {
      return this.properties
        .map(x => x.numberOfProperties || 0)
        .reduce((sum, current) => sum + Number(current), 0);
    }
  };

  getTotalEstimatedAsIsValue = () => {
    this.totalEstimatedAsIsValue = this.properties
      .map(x => x.estimatedAsIsValue || 0)
      .reduce((sum, current) => sum + Number(current), 0);
  };

  getTotalEstimatedAsIsValueDetails = () => {
    this.totalEstimatedAsIsValueProperties = this.propertiesDetails
      .map(x => x.estimatedAsIsValue || 0)
      .reduce((sum, current) => sum + Number(current), 0);
  }

  getTotalPurchasePrice = () => {
    this.totalPurchasePrice = this.propertiesDetails
      .map(x => x.purchasePrice || 0)
      .reduce((sum, current) => sum + Number(current), 0);

    this.totalPurchasePrice = Number(this.totalPurchasePrice.toFixed(2));
    return this.totalPurchasePrice;
  }

  getPropertyCounties() {
    const { propertyCounties } = this.pricerStore.form.fields;

    return propertyCounties.value
      ? propertyCounties.value.map(x => x.value)
      : [];
  }

  getPropertyCountiesSelectedValues() {
    const { propertyCounties } = this.pricerStore.form.fields;
    return propertyCounties.value ?? [];
  }

  getPropertyDataPortfolio = () => {
    let propertyData = [];
    let mixedUseProperties = [];
    let multifamilyProperties = [];

    this.properties.map(p => {
      if (p.propertyType === MULTIFAMILY_5_PLUS) {
        multifamilyProperties = p.mfProperties.map(
          mfProperty => mfProperty.numberOfUnits
        );
      }
      if (p.propertyType === MIXED_USE) {
        p.muProperties.forEach(mp => {
          mixedUseProperties.push({
            ...mp,
            commercialUnitsCurrentOnRent:
              mp.commercialUnitsCurrentOnRent === 'Y',
          });
        });
      }
      propertyData.push({
        ...p,
        noOfProperties: p.numberOfProperties,
        propertyType: p.propertyType,
        mixedUseProperties,
        estimatedAsIsValue: p.estimatedAsIsValue,
        multifamilyProperties,
      });
    });

    return propertyData;
  };

  getNumberOfUnits = () => {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    if (isSingleProperty) {
      return formValues['propertyType'] == MIXED_USE
        ? Number(formValues['residentialUnits']) +
        Number(formValues['commercialUnits'])
        : formValues['units'];
    }

    return '';
  };

  getPrepaymentPenaltyDescription = () => {
    const pricerLoanTerms = this.pricerSummaryStore.getPricerLoanTerms();

    const description =
      sfrPPPMapping[pricerLoanTerms.prePaymentPenalty] &&
        sfrPPPMapping[pricerLoanTerms.prePaymentPenalty]['nValue']
        ? sfrPPPMapping[pricerLoanTerms.prePaymentPenalty]['nValue']
        : null;
    return description;
  };

  setLoanTerms = loanTerms => {
    this.loanTerms = { ...loanTerms };
  };

  setSaved = (value: boolean) => {
    this.saved = value;
  };

  setDraftLoanId = (value: number) => {
    this.draftLoanInfo.draftLoanId = value;
  };

  isRawEntityValue(field) {
    return this.rawEntity[field];
  }

  *log(data, additionalInfo) {
    try {
      yield this.loanService.log(data, additionalInfo);
    } catch (error) {
      console.log(error);
    }
  }

  get borrowerCreditRequests() {
    return this.selectBorrowersStore.borrowers
      .filter(borrower => borrower.creditBackgroundCheck)
      .map(borrower => borrower.emailAddress);
  }

  get asIsValue() {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    const propertyValueBasedOnLoanType = isSingleProperty ? formValues['propertyValuation'] : formValues['totalEstimatedAsIsValue'];
    const asIsValue = formValues['loanPurpose'] == PURCHASE ? Math.min(propertyValueBasedOnLoanType, formValues['acquisitionPrice']) : propertyValueBasedOnLoanType;
    return asIsValue;
  }

  get monthlyRent() {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    return isSingleProperty
      ? formValues['monthlyGrossRent']
      : formValues['totalGrossMonthlyRent'];
  }

  get annualTaxes() {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    return isSingleProperty
      ? formValues['annualTaxes']
      : formValues['totalGrossAnnualTaxes'];
  }

  get annualInsurance() {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    return isSingleProperty
      ? formValues['annualInsurance']
      : formValues['totalGrossAnnualInsurance'];
  }

  get annualHoa() {
    const { isSingleProperty } = this.pricerStore;
    const formValues = this.pricerStore.getFormValues();

    return isSingleProperty
      ? formValues['annualHOA']
      : formValues['totalAnnualHOADues'];
  }

  getRateType(rateType, amortization) {
    return amortization.value == "fullyAmortizing" ? rateType.value + " Fully Amortizing" : rateType.value;
  }

  get loanSaveJson() {
    const { borrowerEntity, borrowers, broker, ...rest } = this.loanSubmitJson;
    const { pricerBorrower } = this.pricerStore;

    console.log('loanSaveJson', this.pricerSummaryStore);

    return {
      ...rest,
      borrowerEntityObj: {
        ...borrowerEntity,
      },
      broker: broker,
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...borrowers],
        },
      },
      pricerBorrower: {
        ...pricerBorrower,
      },
      customQuotes: this.pricerSummaryStore.customQuotes,
    };
  }

  get loanSubmitJson() {
    const property = this.property;
    const propertiesMap = this.isSmallPortfolio ? this.propertySmallPortfolio : [property];
    return {
      borrowerEntity: {
        ...this.borrowerEntityObj,
      },
      loanBorrowerInformation: {
        loanBorrowers: {}
      },
      borrowers: [...this.borrowersRows],
      primaryProperty: property,
      primaryPropertyState: property.state,
      broker: this.brokersStore.selectBrokersStore.brokers[0],
      propertiesMap: {
        rows: propertiesMap,
      },
      ...this.loanDetails,
      emailsForCreditBackgroundCheckRequests: this.borrowerCreditRequests?.join(
        ','
      ),
      loanSubmittedByMailId: this.userStore.userInformation?.username,
      isSubmitted: true,
      loanSubtype: this.getLoanSubtypeBasedOnPropertyTypes(),
      numberOfPropertiesAtSubmission: this.getNumberOfProperties(),
      utility: Utility.LOAN_INFORMATION,
      freeAppraisalPromotion: this.loanDetails.freeAppraisalPromotion,
      loanType: this.loanType
    };
  }

  getLoanSubtypeBasedOnPropertyTypes = () => {
    const { isSingleProperty } = this.pricerStore;
    if (isSingleProperty) {
      const { propertyType } = this.pricerStore.getProperty();
      if (propertyType === MULTIFAMILY_5_PLUS) {
        return LoanSubType.SINGLE_PROPERTY_MULTIFAMILY;
      } else if (propertyType === MIXED_USE) {
        return LoanSubType.SINGLE_PROPERTY_MIXED_USE;
      } else {
        return LoanSubType.SINGLE_PROPERTY;
      }
    } else {
      const propertyTypes = this.getPropertyTypes();
      if (propertyTypes.includes(MIXED_USE)) {
        return LoanSubType.MIXED_USE_PORTFOLIO;
      } else if (propertyTypes.includes(MULTIFAMILY_5_PLUS)) {
        return LoanSubType.MULTIFAMILY_TERM_PORTFOLIO;
      } else {
        return LoanSubType.RENTAL_PORTFOLIO;
      }
    }
  };

  get borrowerEntityObj() {
    return {
      borrowerEntityId: this.entity.borrowerEntityId,
      name: this.entity.name,
      ein: this.entity.ein,
      type: this.entity.type,
      operatingAgreementDate: this.entity.operatingAgreementDate,
      address: this.entity.address,
      city: this.entity.city,
      zipCode: this.entity.zipCode,
      streetName: this.entity.streetName,
      state: this.entity.state,
      streetNumber: this.entity.streetNumber,
      loanApplicationUuid: this.entity.loanApplicationUuid
    };
  }

  get borrowersRows() {
    const { borrowerExp, ficoProvidedAtOrigination } = this.pricerStore;
    const defaultFormValues = this.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo.restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      const pctOwnership =
        borrower.pctOwnership ?? defaultFormValues.pctOwnership;

      return {
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: this.getValues(borrower.roleInTransaction),
        personalGuarantor: borrower.personalGuarantor,
        experience: borrower.experience,
        pctOwnership,
        citizenshipStatus: borrower.citizenshipStatus,
        hasSubstantialChanges: borrower.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        rentalExperience: borrowerExpDictionary[borrower.borrowerExp],
        borrowerExp,
        ficoProvidedOnOrigination: ficoProvidedAtOrigination, // Used by the pricer
        ficoProvidedAtOrigination, // Used for loan submission
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
        loanApplicationUuid: borrower.loanApplicationUuid,
        promoCode: borrower?.promoCode,
        promoCodeDate: borrower?.promoCodeDate,
        protectedBorrower: borrower?.protectedBorrower,
      };
    });
  }

  get property() {
    const propertyData = this.pricerStore.getProperty();
    const isoAcquisitionDate =
      propertyData.acquisitionDate &&
      new Date(propertyData.acquisitionDate).toISOString();
    const shortDateAcquisitionDate = propertyData.acquisitionDate
      ? format(new Date(propertyData.acquisitionDate), 'MM/dd/yyyy')
      : null;

    return {
      ...propertyData,
      acquisitionDate: isoAcquisitionDate,
      useCode: propertyData.propertyType,
      noOfUnitLeased: propertyData.unitsOccupied,
      unitsLeaseType: propertyData.unitsOccupiedHave12MonthsLease,
      rural: propertyData.rural,
      nsfrLoanPropertyFields: {
        ...primaryPropertyTemplate.nsfrLoanPropertyFields,
        section8: propertyData.section8,
        propertyValuation: propertyData.propertyValuation,
        annualTaxes: propertyData.annualTaxes,
        annualInsurance: propertyData.annualInsurance,
        annualHOA: propertyData.annualHOA,
        acquisitionPrice: propertyData.acquisitionPrice,
        acquisitionDate: shortDateAcquisitionDate,
        capitalImprovements: propertyData.capitalImprovements,
        totalDebtPayoff: propertyData.totalDebtPayoff,
        monthlyGrossRent: propertyData.monthlyGrossRent,
        noOfUnitLeased: propertyData.unitsOccupied,
        annualGrossRent:
          propertyData.monthlyGrossRent && propertyData.monthlyGrossRent * 12,
      },
      extraFields: {
        maximumAsIsValue: propertyData.maximumAsIsValue,
        minimumAsIsValue: propertyData.minimumAsIsValue,
        numberOfProperties: propertyData.numberOfProperties,
        propertyManagement: propertyData.propertyManagement,
        targetLTV: propertyData.targetLTV,
        totalAnnualExpenses: propertyData.totalAnnualExpenses,
        totalAnnualIncome: propertyData.totalAnnualIncome,
        totalAnnualNOI: propertyData.totalAnnualNOI,
        totalAnnualPropertyManagementFees:
          propertyData.totalAnnualPropertyManagementFees,
        totalAnnualRepairsMaintenance:
          propertyData.totalAnnualRepairsMaintenance,
        totalAnnualUtilities: propertyData.totalAnnualUtilities,
      },
    };
  }

  propertyData(property) {
    return {
      acquisitionDate: property.purchaseDate,
      acquisitionPrice: property.purchasePrice,
      capitalImprovements: property.capitalImprovements,
      state: property.state,
      city: property.city,
      streetNumber: property.streetNumber,
      streetName: property.streetName,
      zipCode: property.zipCode,
      propertyType: property.useCode,
      useCode: property.useCode,
      propertyValuation: property.estimatedAsIsValue,
      aptNumber: property.aptNumber,
      rural: property.rural === null ? null : property.rural == 'Y',
      section8: property.section8 == 'Y'
    }
  }

  get propertySmallPortfolio() {
    return this.propertiesDetails.map(
      property => {
        const propertyData = this.propertyData(property);
        const isoAcquisitionDate =
          propertyData.acquisitionDate &&
          new Date(propertyData.acquisitionDate).toISOString();
        const shortDateAcquisitionDate = propertyData.acquisitionDate
          ? format(new Date(propertyData.acquisitionDate), 'MM/dd/yyyy')
          : null;
        return {
          ...propertyData,
          acquisitionDate: isoAcquisitionDate,
          useCode: propertyData.useCode,
          nsfrLoanPropertyFields: {
            section8: propertyData.section8,
            propertyValuation: propertyData.propertyValuation,
            acquisitionPrice: propertyData.acquisitionPrice,
            acquisitionDate: shortDateAcquisitionDate,
            capitalImprovements: propertyData.capitalImprovements,
          },
        }
      }
    )
  }

  setInsuranceDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === ELMSURE || this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === null) {
      return { insuredThroughElmsure: true };
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany)
    }
  }

  setPreferredInsuranceBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === ELMSURE) {
      return ELMSURE;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setPreferredTitleBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return WIMBA;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setTitleDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return null;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore?.brokerVendorClosingSettings?.preferredTitleCompany)
    }
  }

  get loanDetails() {
    const { pricerFeesEnabled } = this.globalStore.userFeatures;
    const pricerLoanTerms = this.pricerSummaryStore.getPricerLoanTerms();
    const loanDetails = this.loanDetailsStore.getLoanDetails();
    const {
      isSingleProperty,
      borrowerExp,
      totalMixUseEstimatedAsIsValue,
      ficoProvidedAtOrigination,
    } = this.pricerStore;
    const { loanRecourse, loanPurpose } = this.property;
    const ppp = this.getPrepaymentPenaltyDescription();
    const isElmsure = loanDetails.preferredInsurance === ELMSURE;
    const isWimba = loanDetails.preferredTitleCompany === WIMBA;

    const { originationPointsTpo, originationPointsRoc } = this.pricerSummaryStore.lenderFeesFormStore.getFormValues();
    const rocPointsIn = pricerFeesEnabled ? originationPointsRoc : loanDetails.rocPointsIn;
    const lenderPoints = pricerFeesEnabled ? originationPointsTpo : loanDetails.lenderPoints;
    const loanLenderDetails = pricerFeesEnabled ? this.pricerSummaryStore.loanLenderDetails : undefined;

    return {
      amortization: pricerLoanTerms.amortizationLabel,
      loanTerm: pricerLoanTerms.loanTerm,
      amount: pricerLoanTerms.amount,
      monthlyPayment: pricerLoanTerms.monthlyPayment,
      rateChargedToBorrower: pricerLoanTerms.rateChargedToBorrower,
      totalPremium: pricerLoanTerms.totalPremium,
      lenderPremium: pricerLoanTerms.lenderPremium,
      outOfBox: pricerLoanTerms.outOfBox,
      oneTimeYieldSpreadPremiumAmount:
        pricerLoanTerms.oneTimeYieldSpreadPremiumAmount,
      investorId: pricerLoanTerms.investorId,
      boxes: pricerLoanTerms.boxes,
      loanToValue: pricerLoanTerms.loanToValue,
      amortizationLabel: pricerLoanTerms.amortizationLabel,
      prePaymentPenalty: pricerLoanTerms.prePaymentPenalty,
      oneTimeYieldSpreadPremium: pricerLoanTerms.oneTimeYieldSpreadPremium,
      rateType: pricerLoanTerms.rateType?.toUpperCase(),
      investorSalePrices: pricerLoanTerms.investorSalePrices,
      submissionInvestor: pricerLoanTerms.investorId,
      rocPointsIn,
      lenderPoints,
      brokerPointsIn: loanDetails.brokerPointsIn,
      rocUnderwritingFees: loanDetails.lenderUnderwritingFee,
      attorneyEmail: loanDetails.attorneyEmail,
      attorneyName: loanDetails.attorneyName,
      lenderAttorneySelectedName: loanDetails.attorneyName,
      preferredInsurance: this.globalStore.userFeatures.isBroker ? this.setPreferredInsuranceBasedOnBrokerSettings() : loanDetails.preferredInsurance,
      loanInsuranceData: this.globalStore.userFeatures.isBroker ? this.setInsuranceDataBasedOnBrokerSettings() :
        loanDetails.preferredInsurance === 'Elmsure'
          ? { insuredThroughElmsure: true, estimatedInsurance: this.property.annualInsurance }
          : { estimatedInsurance: this.property.annualInsurance },
      preferredTitle: this.globalStore.userFeatures.isBroker ? this.setPreferredTitleBasedOnBrokerSettings() :
        loanDetails.preferredTitleCompany && loanDetails.preferredTitleCompany,
      titleCompany: this.globalStore.userFeatures.isBroker ? this.setTitleDataBasedOnBrokerSettings() :
        loanDetails.preferredTitleCompany === 'Other'
          ? loanDetails.otherTitleDetails
          : {},
      lenderOriginatorId: this.getLenderOriginatorId(loanDetails.loanOriginator),
      requestedClosingDate: loanDetails.requestedClosingDate,
      referralFee: loanDetails.referralFee,
      referralFeePct: loanDetails.referralFee,
      submitAppraisalWithTamarisk: this.getSubmitAppraisalWithTamarisk(loanDetails.submitAppraisalWithTamarisk),
      gfdPayment: loanDetails.gfdPayment,
      fastTrack: loanDetails.fastTrack,
      fastTrackOptions: {
        ...loanDetails.fastTrackOptions,
        insuranceReview: !isElmsure,
        titleSearch: isWimba,
        insurance: isElmsure,
        titleAttorneySearch: !isWimba,
        properties: this.properties.length || 1,
        propertiesAddresses: this.properties?.map(property => property.address).join(', ')
      },
      rate: pricerLoanTerms.rateChargedToBorrower,
      loanPurpose: loanPurpose,
      purpose: mapBooleanValue(pricerLoanTerms.seekingCashOut),
      sfrLenderPointsIn: pricerFeesEnabled ? loanDetails.lenderPoints : loanDetails.sfrCalculatedLenderPointsIn,
      loanType: this.loanType,
      loanSubtype: this.loanSubtype,
      prepaymentDescription: ppp,
      recourseStructure: loanRecourse,
      pledgeOfShares: true,
      // originalAmortizationTerms: This is set on the BE
      duration: pricerLoanTerms.loanTerm * 12,
      rentalExperienceAtOrigination: borrowerExpDictionary[borrowerExp],
      ficoProvidedAtOrigination: ficoProvidedAtOrigination,
      exceptionMessage: pricerLoanTerms.exceptionMessage,
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      propertyInformation: !isSingleProperty
        ? JSON.stringify(this.getPropertyDataPortfolio())
        : '',
      loanToValueAtSubmission: pricerLoanTerms.loanToValue,
      loanLtv: pricerLoanTerms.loanToValue,
      extraFields: {
        amortization: pricerLoanTerms.amortization,
        borrowerExp: borrowerExp,
        loanTerm: pricerLoanTerms.loanTerm,
        monthlyPayment: pricerLoanTerms.monthlyPayment,
        oneTimeYieldSpreadPremium: pricerLoanTerms.oneTimeYieldSpreadPremium,
        oneTimeYieldSpreadPremiumAmount:
          pricerLoanTerms.oneTimeYieldSpreadPremiumAmount,
        prePaymentPenalty: pricerLoanTerms.prePaymentPenalty,
        rateType: pricerLoanTerms.rateType,
        seekingCashOut: pricerLoanTerms.seekingCashOut,
      },
      loanSubmittedByMailId: this.userStore.userInformation?.username,
      selectedBoxes: pricerLoanTerms.boxes,
      fullPrincipalInterest: false,
      loanPricingData: {
        useSpread: true,
        swapRateAtOrigination: pricerLoanTerms.swapRate,
        swapRate: pricerLoanTerms.swapRate,
        spreadRateAtOrigination: pricerLoanTerms.spreadRate,
        spreadRate: pricerLoanTerms.spreadRate,
        // swapIndex and swapIndexAtOrigination needs to be an integer
        swapIndex: pricerLoanTerms.swapIndex
          ? parseInt('' + pricerLoanTerms.swapIndex)
          : US_TREASURY_5_YR_INDEX,
        swapIndexAtOrigination: pricerLoanTerms.swapIndex
          ? parseInt('' + pricerLoanTerms.swapIndex)
          : US_TREASURY_5_YR_INDEX,
        programType: pricerLoanTerms.programType
      },
      loanLenderDetails,
      freeAppraisalPromotion: loanDetails.freeAppraisalPromotion,
      marketingPromotionId: loanDetails.marketingPromotionId
    };
  }

  isValidBankruptcyDate(bankruptcyDate) {
    return (
      bankruptcyDate &&
      differenceInYears(startOfToday(), new Date(bankruptcyDate)) >= 3
    );
  }

  isValidForeclosureDate(foreclosureDate) {
    return (
      foreclosureDate &&
      differenceInYears(startOfToday(), new Date(foreclosureDate)) >= 3
    );
  }

  getBoolean = (value: string) => {
    return value === 'Y';
  };

  getValues = (options: any[]) => {
    const values = options ? options.map(option => option.value) : [];
    return values.join(',');
  };

  *onLoanSave(type: string, showSuccessNotification = true) {
    try {
      const loan = this.loanSaveJson;
      const draftLoan = {
        content: JSON.stringify(loan),
        draftLoanId: this.draftLoanInfo.draftLoanId,
        createdBy: this.draftLoanInfo.createdBy,
        creationDate: this.draftLoanInfo.creationDate
          ? format(
            new Date(this.draftLoanInfo.creationDate),
            'MM/dd/yyyy HH:mm:ss.SSS'
          )
          : null,
        isActive: this.draftLoanInfo.isActive,
        type: type,
        rejectRequestReason: this.draftLoanInfo.rejectRequestReason,
        status: this.draftLoanInfo.status,
        brokerId: this.globalStore.userFeatures.isBroker ? this.brokersStore.selectBrokersStore.brokers[0].brokerId : null,
        lenderOriginatorId: loan.lenderOriginatorId
      };
      if (this.allErrors.length > 0 && type === REQUEST_LOAN_TYPE_LOAN) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(draftLoan, 'draft loan');
        const createDocuments = this.globalStore.userFeatures.uploadDuringSubmissionEnabled;
        const response: ApiResponse = yield this.loanService.saveDraftLoan(
          draftLoan,
          createDocuments
        );
        if (response.data) {
          this.draftLoanInfo.draftLoanId = response.data?.data?.id;
          if (showSuccessNotification) {
            this.globalStore.notificationStore.showSuccessNotification({
              message: 'Loan has been saved successfully.',
            });
          }
        }
        type == REQUEST_LOAN_TYPE_LOAN
          ? (this.requestSaved = true)
          : (this.requestSaved = false);
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan.',
      });
    }
  }

  *getDraftLoan(draftLoanId: number) {
    try {
      const response: ApiResponse = yield this.loanService.getDraftLoan(
        draftLoanId
      );
      const data = response?.data?.data;
      this.draftLoanInfo = data;
      const draftLoan = JSON.parse(data.content);
      this.mapDraftLoanToStore(draftLoan);
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'Pricing may have changed since you saved your loan, please review the pricing again.',
      });
      this.updatePropertySummary();
      this.updatePropertyDetailsSummary();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draft loan.',
      });
    }
  }

  mapDraftLoanToStore(draftLoan: any) {
    const { loanType, loanSubtype } = draftLoan;
    const isSingleProperty = loanSubtype.includes('Single Property');

    this.loanType = loanType;
    this.loanSubtype = isSingleProperty
      ? LoanSubType.SINGLE_PROPERTY
      : LoanSubType.RENTAL_PORTFOLIO;
    if (draftLoan.broker) {
      const { broker } = draftLoan;
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker,
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
    this.entity = getBorrowerEntity(draftLoan);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );

    const borrowers = getBorrowers(draftLoan, transactionRolesTerm);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

    const pricerDetails = isSingleProperty
      ? getTermPricerDetails(draftLoan)
      : getTermPortfolioPricerDetails(draftLoan);
    this.pricerStore.loadForm(pricerDetails);
    this.handleBorrowerInformationChange();

    if (!isSingleProperty) {
      const properties = [...pricerDetails.propertyTypeData].map(property => ({
        ...property,
        propertyId: getUniqueId(),
      }));
      this.setProperties(properties);
      this.checkSmallPortfolio();
      if (this.isSmallPortfolio) {
        this.propertiesDetails = getPropertyDetails(draftLoan).rows.map(property =>
        ({
          ...property,
          propertyId: getUniqueId(),
          purchasePrice: property.acquisitionPrice,
          purchaseDate: property.acquisitionDate,
          estimatedAsIsValue: property.propertyValuation,
          address: this.getAddress(property),
        }));
      }
      this.checkPropertyErrors();
    }
    this.pricerSummaryStore.loadForm(getLoanTermPricerDetails(draftLoan));
    draftLoan.marketingPromotionId = null;
    this.loanDetailsStore.initForm(getTermLoanDetails(draftLoan));
    this.pricerSummaryStore.lenderFeesFormStore.initForm(draftLoan);
    this.pricerSummaryStore.setDataFromDraftLoan(draftLoan);
  }

  getAddress(property) {
    if (property.state && property.streetNumber && property.zipCode && property.streetName && property.city) {
      return `${property.streetNumber} ${property.streetName}, ${property.city}, ${property.state} ${property.zipCode}`;
    }

  }

  onReprice() {
    this.activeStep = 0;
    this.stepError = '';
  }

  *onLoanSubmit() {
    try {
      const {
        borrowerEntity,
        borrowers,
        loanInsuranceData,
        titleCompany,
        isSubmitted,
        preferredTitle,
        emailsForCreditBackgroundCheckRequests,
        investorId,
        loanPricingData,
        lenderOriginatorId,
        fastTrack,
        fastTrackOptions,
        broker,
        freeAppraisalPromotion,
        ...rest
      } = this.loanSubmitJson;
      const existingData = this.isBorrowerLoanApplication ? this.loanApplication : {};
      const data = {
        ...existingData,
        formInformation: JSON.stringify({ ...existingData, ...rest }),
        borrowerEntity,
        borrowers,
        loanInsuranceData,
        titleCompany,
        isSubmitted,
        preferredTitle,
        loanPricingData,
        emailsForCreditBackgroundCheckRequests,
        draftLoanId: !this.loanSubmitJson.fastTrack ? this.draftLoanInfo.draftLoanId :
          this.loanDetailsStore.draftLoanId,
        investorId,
        lenderOriginatorId,
        fastTrack,
        fastTrackOptions,
        broker,
        loanLenderDetails: this.pricerSummaryStore.loanLenderDetails,
        freeAppraisalPromotion
      };
      this.disableSubmitButton = true;
      this.log(data, 'submit term loan');
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEvent.TERM_SUBMISSION,
        userName: this.userStore?.userInformation?.username,
      });
      const response: ApiResponse = yield this.loanService.submitTermLoan(data);
      const isInternal = this.globalStore.lenderInfo?.isInternal;
      const isBackOfficeProcessor = this.userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
      const isBackOfficeUser = this.userStore.isBackOfficeUser;

      if (response.data?.data?.success === false) {
        const additionalDescription =
          '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
        let errorMessage = response.data?.data?.responseMessage;
        this.sendOtherValidationEmailError(errorMessage);
      } else {
        this.savedLoanId = response.data?.data?.loanId;
        if (this.loanDetailsStore.form.fields.fastTrack.value === 'Y') {
          const fastTrackInfo = yield this.loanService.getLoanDetails(this.savedLoanId.toString());
          this.hasFastTrack = true;
          this.isFastTrackPaymentComplete = fastTrackInfo.data?.data?.fastrackPaymentComplete;
        }
        this.saved = true;
      }
    } catch (error) {
      this.saved = true;
      this.sendLoanSubmissionErrorEmail(error?.error?.response?.data?.error?.message || error?.error?.message || error);
      console.log(error);
    }
  }

  *onEditLoanSubmit(onSuccessCallback = () => { }) {
    try {
      const {
        borrowerEntity,
        borrowers,
        loanInsuranceData,
        titleCompany,
        isSubmitted,
        preferredTitle,
        emailsForCreditBackgroundCheckRequests,
        investorId,
        loanPricingData,
        lenderOriginatorId,
        fastTrack,
        fastTrackOptions,
        broker,
        freeAppraisalPromotion,
        ...rest
      } = this.loanSubmitJson;
      const data = {
        formInformation: JSON.stringify(rest),
        borrowerEntity,
        borrowers,
        loanInsuranceData,
        titleCompany,
        isSubmitted,
        preferredTitle,
        loanPricingData,
        emailsForCreditBackgroundCheckRequests,
        draftLoanId: !this.loanSubmitJson.fastTrack ? this.draftLoanInfo.draftLoanId :
          this.loanDetailsStore.draftLoanId,
        investorId,
        lenderOriginatorId,
        fastTrack,
        fastTrackOptions,
        broker,
        loanLenderDetails: this.pricerSummaryStore.loanLenderDetails,
        freeAppraisalPromotion,
      };
      const response: ApiResponse = yield this.editLoanSubmissionService.updateLoanSubmission(data, this.editLoanJson.loanId);


      if (response.data?.data?.success === false) {
        const additionalDescription =
          '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
      } else {
        this.savedLoanId = response.data?.data?.loanId;
        this.saved = true;
        onSuccessCallback();
      }
    } catch (error) {
      this.saved = true;
      console.log(error);
    }
  }

  getLenderOriginatorId(selectedLenderOriginatorId) {
    return this.globalStore.userFeatures?.isLenderOriginator ? this.userStore.userInformation?.userId : selectedLenderOriginatorId;
  }

  getSubmitAppraisalWithTamarisk(submitAppraisalWithTamarisk) {
    if (this.loanSubtype === LoanSubType.RENTAL_PORTFOLIO) return false;
    return this.globalStore.userFeatures?.tamariskAppraisal ? true : submitAppraisalWithTamarisk;
  }

  sendLoanSubmissionErrorEmail(error: string) {
    const loanSubType = this.getLoanSubtypeBasedOnPropertyTypes();
    this.loanService.sendErrorEmail(
      'Rental Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${loanSubType}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  get canSubmitAppraisalWithTamarisk() {
    return canSubmitAppraisalWithTamarisk(
      this.loanSubtype,
      this.globalStore.userFeatures
    ) && this.properties.every(prop => PROPERTY_TYPES_RUSHED_APPRAISAL.includes(prop.propertyType));
  }

  *sendFastTrackPayment() {
    yield this.loanDetailsStore.requestFastTrackPaymentEmail({
      loanId: this.loanDetailsStore.draftLoanId,
      borrowers: [...this.borrowersRows],
    });
  }

  setRushedAppraisalProperties(value) {
    this.properties = this.properties.map(prop => ({ ...prop, submitRushedAppraisal: value }));
  }

  checkSmallPortfolio() {
    this.isSmallPortfolio = (this.getNumberOfProperties() !== 0 && this.getNumberOfProperties() <= 20);
  }

  checkGridData() {
    var valid = true;
    if (this.propertiesDetails.length === 0) {
      valid = false;
    } else {
      for (var property in this.propertiesDetails) {
        if (this.propertiesDetails[property].address == null) valid = false;
      }
    }
    return valid;
  }

  checkPropertiesDate() {
    var valid = true;
    if (this.pricerStore.getLoanPurpose() === 'Purchase') {
      return valid;
    } else {
      for (var property in this.propertiesDetails) {
        const parseDatePricer = parseDate(this.pricerStore.getAcquisitionDate());
        if (parseDate(this.propertiesDetails[property].purchaseDate) > parseDatePricer) valid = false;
      }
      return valid;
    }
  }

  get isLoanRequest() {
    return this.draftLoanInfo?.type === REQUEST_LOAN_TYPE_LOAN;
  }

  *validateBorrowerErrors() {
    try {
      const response: ApiResponse = yield this.loanService.getValidateBorrowerLoanErrors({
        borrowerRows: [...this.borrowersRows],
        loanValues: {
          marketingPromotionId: this.loanDetails.marketingPromotionId,
          loanId: this.editLoanJson.loanId
        }
      });
      const validateErrors = response.data.data.errors;
      this.allErrors = [...this.allErrors, ...validateErrors];
    } catch (error) {
      console.log(error);
    }
  }

  *validateEntityErrors() {
    try {
      const data = this.loanSubmitJson;
      if (!this.entity.borrowerEntityId) {
        return;
      }
      const response: ApiResponse = yield this.loanService.getValidateEntityLoanErrors(
        this.entity.borrowerEntityId
      );
      const validateErrors = response.data.data.errors;
      this.allErrors = [...this.allErrors, ...validateErrors];
    } catch (error) {
      console.log(error);
    }
  }

  sendOtherValidationEmailError(errorMessage: any) {
    if (errorMessage !== LOAN_WITHOUT_LEAD_SOURCE_VALIDATION) {
      errorMessage += `. For ${this.globalStore.lenderInfo.companyName} with lender id ${this.globalStore.lenderInfo.lenderId}.`;
      this.sendLoanSubmissionErrorEmail(errorMessage);
    }
  }
  *getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans() {
    try {
      this.areBorrowersPreQualified = true;
      const response = yield this.loanService.getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans(this.loanSubmitJson);
      this.areBorrowersPreQualified = response?.data?.data;
      if (!this.areBorrowersPreQualified) {
        this.allWarnings.push('Your loan will be automatically assigned to our underwriting team once borrower credit and background are received.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  setBroker(broker, termLoanDetails) {
    if (!isNil(broker)) {
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker,
        entityName: broker?.company?.name,
        entityTaxNumber: broker?.company?.einNumber,
        brokerId: broker?.lenderId,
        companyId: broker?.company?.companyId,
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
  }

  *getEditLoanJsonById(editLoanId) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanSummary(
        editLoanId
      );
      const data = JSON.parse(response?.data?.data);
      this.editLoanJson = data;
      this.mapEditLoanJsonToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }

  mapEditLoanJsonToStore(editLoanJson: any) {
    const { loanType, loanSubtype } = editLoanJson;
    const isSingleProperty = loanSubtype.includes('Single Property');

    this.loanType = loanType;
    this.loanSubtype = isSingleProperty
      ? LoanSubType.SINGLE_PROPERTY
      : LoanSubType.RENTAL_PORTFOLIO;

    if (isSingleProperty) {
      this.entity = getBorrowerEntityForLoanApplication(editLoanJson);
      this.entityStore.setHasEntityInfo(
        isNotBlank(this.entity.name) || !isNil(this.entity.ein)
      );

      const borrowers = getBorrowers(editLoanJson, transactionRolesTerm);
      this.setBorrowers(borrowers.filter(b => b.borrowerId));
      this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));
      this.setIsLoanBeingEdit(!isNil(this.editLoanJson.loanId));

      const pricerDetails = getTermPricerDetailsFromExistingLoan(editLoanJson);
      this.pricerStore.loadForm(pricerDetails);

      this.handleBorrowerInformationChange();

      this.pricerSummaryStore.loadForm(getLoanTermPricerDetailsFromExistingLoan(editLoanJson));
      const termLoanDetails = getTermLoanDetails(editLoanJson)
      this.loanDetailsStore.initForm(termLoanDetails);
      this.loanDetailsStore.otherTitleFormStore.loadForm(termLoanDetails.otherTitleCompany);
      this.pricerSummaryStore.lenderFeesFormStore.initForm(editLoanJson);
      this.pricerSummaryStore.setDataFromDraftLoan(editLoanJson);
      this.setBroker(editLoanJson?.broker, termLoanDetails);
    }
  }

  get isBorrowerLoanApplication() {
    return this.loanApplication.loanApplicationId != null;
  }

  setLoanApplicationId = (value: number) => {
    this.loanApplication.loanApplicationId = value;
  };

  *getLoanApplicationById(loanApplicationId) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanApplicationById(
        loanApplicationId
      );
      this.reset();
      const data = response?.data?.data;
      this.loanApplication = data;
      this.mapLoanApplicationToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }

  mapLoanApplicationToStore(loanApplication: any) {
    const { loanType, loanSubtype, loanId } = loanApplication;
    const isSingleProperty = loanSubtype.includes('Single Property');

    this.loanType = loanType;
    this.loanSubtype = isSingleProperty
      ? LoanSubType.SINGLE_PROPERTY
      : LoanSubType.RENTAL_PORTFOLIO;

    if (isSingleProperty) {
      this.entity = getBorrowerEntityForLoanApplication(loanApplication);
      this.entityStore.setHasEntityInfo(
        isNotBlank(this.entity.name) || !isNil(this.entity.ein)
      );
      const borrowers = getBorrowers(loanApplication, transactionRolesTerm);
      this.setBorrowers(borrowers.filter(b => b.borrowerId));
      this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

      let pricerDetails = isSingleProperty
        ? getTermPricerDetailsFromExistingLoan(loanApplication)
        : getTermPortfolioPricerDetails(loanApplication);
      this.pricerStore.loadForm(pricerDetails);
      this.handleBorrowerInformationChange();
      this.pricerSummaryStore.reset();
      this.loanDetailsStore.reset();
      this.pricerSummaryStore.lenderFeesFormStore.reset();

      this.loanApplication = loanApplication;
      this.setLoanApplicationId(loanId);
    }
  }

  *rejectLoanApplication() {
    try {
      const data = {
        status: LoanStatus.APPLICATION_REJECTED,
        loanId: this.loanApplication.loanApplicationId
      }
      const response: ApiResponse = yield this.loanService.updateLoanApplicationStatus(
        data
      );
      if (response.data.data.success === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Term Loan Application status has been updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating term loan application status',
      });
    }
  }

  validateProtectedBorrowers() {
    try {
      const borrowers = this.loanSubmitJson.borrowers;

      if (borrowers != null && borrowers.length > 0) {
        borrowers.map(borrower => {
          if (borrower?.protectedBorrower) {
            this.allWarnings.push('The Borrower ' + borrower.fullName + ' currently has loans associated with an active TPO');
          }
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  get loanApplicationJson() {
    const loanApplication = this.loanSaveJson;

    return {
      ...loanApplication,
      loanBorrowerInformation: {
        ...loanApplication.loanBorrowerInformation,
        borrowerEntity: {
          ...loanApplication.borrowerEntityObj
        },
      },
      loanClosingData: {},
      broker: isNil(loanApplication?.broker) || !isNil(loanApplication?.broker?.company) ? loanApplication?.broker : {
        lenderId: loanApplication?.broker?.brokerId,
        companyId: loanApplication?.broker?.companyId,
        lenderName: loanApplication?.broker?.entityName,
        company: {
          companyId: loanApplication?.broker?.companyId,
          name: loanApplication?.broker?.entityName,
          einNumber: loanApplication?.broker?.entityTaxNumber,
          phone: loanApplication?.broker?.cellPhone,
          email: loanApplication?.broker?.emailAddress,
          contacts: [
            {
              contactId: loanApplication?.broker?.contactId,
              firstName: loanApplication?.broker?.firstName,
              lastName: loanApplication?.broker?.lastName,
              emailAddress: loanApplication?.broker?.emailAddress,
              workPhone: loanApplication?.broker?.cellPhone,
            }
          ]
        }
      }
    };
  }

  *onLoanApplicationSave() {
    try {
      const loan = this.loanApplicationJson;
      if (this.allErrors.length > 0) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(loan, 'loan request');
        const response: ApiResponse = yield this.loanService.submitTermLoanApplicationForBrokerOrLenderOriginator(
          loan
        );
        if (response.data) {
          this.savedLoanId = response.data?.data?.loanId;
        }
        this.requestSaved = true;
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan request.',
      });
    }
    this.saveLoanQuoteForApplication();
  }

  *saveLoanQuoteForApplication() {
    try {
      if (!isNil(this.savedLoanId)) {
        const quoteData = {
          ...getQuoteFromLoanSubmitJson(this.savedLoanId, this.loanSubmitJson),
          lenderId: this.globalStore.lenderInfo.lenderId,
        };
        const response = yield this.oneToolService.saveQuote(quoteData);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuoteForApplication because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving application as loan quote');
      console.log(error);
    }
  }
}
