import { GlobalStore } from '@roc/feature-app-core';
import { computed, flow, action, makeObservable, observable } from 'mobx';
import { DialogState } from '@roc/ui';
import { FormStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { MatGridStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { getPageFromObject } from '@roc/feature-utils';
import { PropertyFormStore } from './propertyFormStore';

const borrowerForm = {
  fields: {
    borrowerId: {
      value: 0,
      error: null,
      rule: 'required',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export abstract class BorrowerBaseballCardBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  gridStore: GridStore;
  verifiedPropertiesGrid: GridStore;
  unverifiedPropertiesGrid: GridStore;
  entitiesMatGridStore: MatGridStore;
  propertiesMatGridStore: MatGridStore;
  titleHistoryMatGridStore: MatGridStore;
  publicTrackRecordsMatGridStore: MatGridStore;
  dialogState: DialogState;
  propertyFormStore: PropertyFormStore;
  currentBorrower: any;
  baseballCardDetails: any;
  verifiedTrackRecord: any;
  unverifiedTrackRecord: any;
  trackRercords: any;
  reviewSection: string;
  borrowerReviewResolutions: any[];
  publicTrackRecord: any;
  publicPropertyDetails: any = [];
  loanId: any;
  selectedPublicTrackRecords: any[];
  selectedProperties: any[];
  lookUpSelectedProperties: any[];
  lookUpPropertiesOptions: any[];
  oldSelectedProperties: any[];
  selectedTab: number;
  currentRecordSourceType: string;
  historyData: any[] = [];
  isNewEntity: boolean;
  currentTasks: any[];
  pdfTitle: string;
  pdfData: any;
  isPdfPreviewModalOpen: boolean;
  currentCollateral: any;
  collateralTitleHistory: any;
  currentProperty: any;


  propertyInformationDialogState: DialogState;
  selectedVerifiedTrackRecordsId: number;
  selectedVerifiedTrackRecordsVerificationSource: string;
  selectedVerifiedTrackRecordsLinks: any;
  selectedTrackRecordIsVerified: boolean;
  doPropertyLookup: boolean;

  abstract getBaseballCardData(borrowerId: number, loanId?: number);
  abstract getPublicTrackRecord(borrowerId: number);
  abstract getVerifiedTrackRecord(borrowerId: number, loanId: string);
  abstract getUnverifiedTrackRecord(borrowerId: number);
  abstract getTasks(id: number, type: string);
  abstract getTrackHistoryAuditLogs();
  abstract getResolutionsReviewItems(borrowerId: number, section: string);

  constructor(globalStore: GlobalStore, propertyFormStore: PropertyFormStore) {
    super(borrowerForm, globalStore);
    this.isNewEntity = true;
    this.currentTasks = [];
    this.pdfTitle = '';
    this.pdfData = null;
    this.isPdfPreviewModalOpen = false;

    this.globalStore = globalStore;
    this.selectedProperties = [];
    this.selectedPublicTrackRecords = [];
    this.oldSelectedProperties = [];
    this.lookUpSelectedProperties = [];
    this.lookUpPropertiesOptions = [];
    this.selectedTab = 0;
    this.currentRecordSourceType = '';
    this.verifiedPropertiesGrid = new GridStore(
      () => this.fetchVerifiedPropertiesGridPage(),
      null,
      10
    );
    this.unverifiedPropertiesGrid = new GridStore(
      () => this.fetchUnverifiedPropertiesGridPage(),
      null,
      10
    );
    this.entitiesMatGridStore = new MatGridStore();
    this.propertiesMatGridStore = new MatGridStore();
    this.titleHistoryMatGridStore = new MatGridStore();
    this.publicTrackRecordsMatGridStore = new MatGridStore();
    this.historyData = [];
    this.propertyFormStore = propertyFormStore;
    makeObservable(this, {
      currentProperty: observable,
      currentTasks: observable,
      pdfTitle: observable,
      pdfData: observable,
      isPdfPreviewModalOpen: observable,
      currentCollateral: observable,
      collateralTitleHistory: observable,
      trackRercords: observable,
      reviewSection: observable,
      borrowerReviewResolutions: observable,
      dialogState: observable,
      selectedProperties: observable,
      selectedPublicTrackRecords: observable,
      oldSelectedProperties: observable,
      lookUpSelectedProperties: observable,
      lookUpPropertiesOptions: observable,
      selectedTab: observable,
      currentRecordSourceType: observable,
      showDialog: computed,
      resetDialogState: action,
      viewBaseballCard: action,
      baseballCardDetails: observable,
      currentBorrower: observable,
      verifiedTrackRecord: observable,
      unverifiedTrackRecord: observable,
      publicTrackRecord: observable,
      combinedUnverifiedTrackRecord: computed,
      propertyInformationDialogState: observable,
      selectedVerifiedTrackRecordsId: observable,
      selectedVerifiedTrackRecordsLinks: observable,
      selectedVerifiedTrackRecordsVerificationSource: observable,
      fetchBaseballCardData: action,
      fetchUnverifiedTrackRecord: action,
      fetchVerifiedTrackRecord: action,
      fetchPublicTrackRecord: action,
      getBaseballCardData: flow,
      getResolutionsReviewItems: flow,
      getVerifiedTrackRecord: flow,
      getUnverifiedTrackRecord: flow,
      getPublicTrackRecord: flow,
      getTrackHistoryAuditLogs: flow,
      openPropertyInformationDialog: action,
      closePropertyInformationDialog: action,
      setSelectedTab: action,
      historyData: observable
    });
  }

  public async fetchBaseballCardData(borrowerId: number, loanId?: number) {
    try {
      const response: ApiResponse = await this.getBaseballCardData(borrowerId, loanId);
      this.baseballCardDetails = response?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching borrower details',
      });
    }
  }

  public async getReviewResolutions(borrowerId: number, sectionName: string) {
    try {
      const response: ApiResponse = await this.getResolutionsReviewItems(borrowerId, sectionName);
      this.borrowerReviewResolutions = response?.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching resolutions',
      });
    }
  }

  public async fetchBaseballCardDataForExternalPortal(borrowerId: number, loanId?: number) {
    try {
      const response: ApiResponse = await this.getBaseballCardData(borrowerId, loanId);
      const responseObj = JSON.parse(response.data.data);
      responseObj.unverifiedExp = JSON.parse(responseObj.unverifiedExp);
      responseObj.verifiedExp = JSON.parse(responseObj.verifiedExp);
      this.baseballCardDetails = responseObj;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching borrower details',
      });
    }
  }

  public async getTrackRecords(borrowerId: number, loanId: string) {
    try {

      const verifiedTrackRecordresponse = await this.getVerifiedTrackRecord(borrowerId, loanId);
      const unverifiedResponse = await this.getUnverifiedTrackRecord(borrowerId);

      this.trackRercords = [
        ...verifiedTrackRecordresponse.data,
        ...unverifiedResponse.data,
      ];

    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching track record details',
      });
    }
  }

  public async getPublicTrackRecords() {
    try {

      const response: ApiResponse = await this.getPublicTrackRecord(this.currentBorrower);
      const publicTrackRecord = response?.data;
      this.publicTrackRecord = publicTrackRecord.map(record => ({
        temporaryId: Symbol(),
        ...record,
      }));

    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching track record details',
      });
    }
  }

  setExperience(value: number) {
    this.baseballCardDetails.bridgeExp = value;
  }

  setRentalExperience(value: number) {
    this.baseballCardDetails.rentalExp = value;
  }

  setRentalMfExperience(value: number) {
    this.baseballCardDetails.rentalMfExperience = value;
  }

  setGroundUpExperience(value: number) {
    this.baseballCardDetails.groundupExperience = value;
  }

  setTotalPropertiesOwned(value: number) {
    this.baseballCardDetails.totalPropertiesOwned = value;
  }

  public async fetchVerifiedTrackRecord(borrowerId: number, loanId: string) {
    try {
      const response: ApiResponse = await this.getVerifiedTrackRecord(
        borrowerId,
        loanId
      );
      this.verifiedTrackRecord = response?.data;
      this.verifiedPropertiesGrid.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching verified track record details',
      });
    }
  }

  public async fetchUnverifiedTrackRecord(borrowerId: number) {
    try {
      const response: ApiResponse = await this.getUnverifiedTrackRecord(
        borrowerId
      );
      this.unverifiedTrackRecord = response?.data;
      this.unverifiedPropertiesGrid.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching unverified track record details',
      });
    }
  }

  public async fetchPublicTrackRecord(borrowerId: number) {
    try {
      const response: ApiResponse = await this.getPublicTrackRecord(borrowerId);
      const publicTrackRecord = response?.data;
      this.publicTrackRecord = publicTrackRecord.map(record => ({
        temporaryId: Symbol(),
        ...record,
      }));
      this.unverifiedPropertiesGrid.resetAndFetchGridData();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching public track record details',
      });
    }
  }

  public async fetchVerifiedPropertiesGridPage() {
    const meta = this.verifiedPropertiesGrid.gridData.meta;
    const data = getPageFromObject(this.verifiedTrackRecord, meta);
    return new ApiResponse({ data }, null);
  }

  public async fetchUnverifiedPropertiesGridPage() {
    const meta = this.unverifiedPropertiesGrid.gridData.meta;
    const data = getPageFromObject(this.combinedUnverifiedTrackRecord, meta);
    return new ApiResponse({ data }, null);
  }

  public showSuccessNotification(message) {
    this.globalStore.notificationStore.showSuccessNotification({
      message: message,
    })
  }

  public showErrorNotification(message) {
    this.globalStore.notificationStore.showErrorNotification({
      message: message,
    });
  }

  get combinedUnverifiedTrackRecord() {
    return (
      this.unverifiedTrackRecord || []
    ).concat(this.publicTrackRecord || [])
  }

  resetDialogState() {
    this.dialogState = undefined;
    this.currentBorrower = undefined;
  }

  viewBaseballCard(record) {
    this.reset();
    this.dialogState = DialogState.EDIT;
    this.currentBorrower = record;
    this.loadForm(record);
  }

  get showDialog() {
    return this.dialogState != null;
  }

  openPropertyInformationDialog(mode: DialogState, id?: number, isVerified?: boolean, doPropertyLookup?: boolean, verificationSource?: string, links?: any) {
    this.propertyFormStore.reset();
    this.propertyInformationDialogState = mode;
    this.selectedVerifiedTrackRecordsId = id;
    this.selectedVerifiedTrackRecordsVerificationSource = verificationSource;
    this.selectedTrackRecordIsVerified = isVerified;
    this.selectedVerifiedTrackRecordsLinks = links
    this.doPropertyLookup = doPropertyLookup;
    this.titleHistoryMatGridStore.currentSelectedIndex = null;
    this.titleHistoryMatGridStore.setSelectedRows([]);
  }

  closePropertyInformationDialog() {
    this.selectedVerifiedTrackRecordsVerificationSource = null;
    this.propertyInformationDialogState = null;
    this.selectedVerifiedTrackRecordsId = null;
    this.selectedTrackRecordIsVerified = null;
    this.selectedVerifiedTrackRecordsLinks = null;
    this.doPropertyLookup = false;
    this.lookUpPropertiesOptions = [];
    this.lookUpSelectedProperties;
  }

  setOldSelectedProperty(property, isSelected) {
    if (isSelected) {
      this.oldSelectedProperties = [...this.oldSelectedProperties, property];
    } else {
      const id = property.verifiedTrackRecordsId ?? property.temporaryId
      this.oldSelectedProperties = this.oldSelectedProperties.filter(
        selectedProperty => id !== (selectedProperty.verifiedTrackRecordsId ?? selectedProperty.temporaryId)
      );
    }
  }

  setLookUpSelectedProperty(property, isSelected) {
    if (isSelected) {
      this.lookUpSelectedProperties = [...this.lookUpSelectedProperties, property];
    } else {
      const id = property.id;
      this.lookUpSelectedProperties = this.lookUpSelectedProperties.filter(
        selectedProperty => id !== selectedProperty.id
      );
    }
  }

  setExperienceComment(comment) {
    this.baseballCardDetails.experienceComment = comment;
  }

  setSelectedTab(value) {
    this.selectedTab = value;
  }


  *showAlertMessage(alert: string) {
    this.globalStore.notificationStore.showSuccessNotification({
      message: alert,
    });
  }

  async handleSaveProperty() {
    await this.getTrackRecords(this.currentBorrower, this.loanId);
  }
}
