import { observer } from "mobx-react";
import { LoanFundingPreferences } from "../loanFundingPreferences/loanFundingPreferences";
import TamariskPreferences from "libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/tamariskPreferences/tamariskPreferences";
import DesktopAppraisalPreferences from "libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/desktopAppraisalPreferences/desktopAppraisalPreferences";
import HighValuePreferences from "libs/feature-backoffice-users/src/backOfficeUsers/components/companyProfile/highValuePreferences/highValuePreferences";




export const Preferences = observer(({ companyProfileStore }) => {
  return (
    <>
      <LoanFundingPreferences companyProfileStore={companyProfileStore}/>
      <TamariskPreferences companyProfileStore={companyProfileStore}/>
      <DesktopAppraisalPreferences companyProfileStore={companyProfileStore} />
      <HighValuePreferences companyProfileStore={companyProfileStore}/>
    </>
  );
});
