import { StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { Document } from '@roc/feature-types';
import { makeStyles } from '@material-ui/core/styles';
import { InsuranceInformation } from './insuranceInformation';
import { InsuranceInformationBaseStore } from '@roc/feature-insurance-review';

export type InsuranceInformationDialogProps = {
  open?: boolean;
  onClose?: () => void;
  document: Document;
  store: InsuranceInformationBaseStore;
  loanId: string;
};

const useStyles = makeStyles(theme => ({
  dialogPadding: {
    '& .MuiDialogContent-root': {
      padding: '0 24px',
    },
    '& .MuiDialogTitle-root': {
      paddingBottom: 0
    }
  }
}));

export const InsuranceInformationDialog = observer(
  (props: InsuranceInformationDialogProps) => {
    const { open, onClose, store, loanId, document } = props;
    const classes = useStyles();
    return (
      <StandardDialog
        className={classes.dialogPadding}
        open={open}
        title="Insurance Information"
        maxWidth="lg"
        handleClose={onClose}
        dialogContent={
          <InsuranceInformation
            store={store}
            loanId={loanId}
            loanDocumentId={document?.loanTaskId?.toString()}
            showTitle={false}
          />}
      />
    );
  }
);
