import { Layout } from '@roc/feature-app-core';
import { EmbeddedFrameBoundaries, TrackRecordLineItem } from '@roc/ui';
import { BorrowerInfoCardCustomTheme } from './components/borrowerInfoCardCustomTheme';
import { BorrowerTrackRecordStore } from '@roc/feature-borrowers';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

interface BorrowerTrackRecordLineItemComponentProps {
  store: BorrowerTrackRecordStore;
  borrowerId?: string;
  loanTaskId?: string;
}

export const BorrowerTrackRecordLineItemComponent = ({ store, borrowerId, loanTaskId }: BorrowerTrackRecordLineItemComponentProps) => {

  if (!borrowerId || !loanTaskId) {
    return (<></>);
  }
  let parsedLoanTaskId;
  let parsedBorrowerId;
  try {
    parsedLoanTaskId = loanTaskId ? parseInt(loanTaskId) : null;
    parsedBorrowerId = borrowerId ? parseInt(borrowerId) : null;
  } catch { }

  if (!parsedBorrowerId || !parsedLoanTaskId) {
    return (<></>);
  }

  store.getDocument(loanTaskId);
  store.internal = true;

  return (
    <Layout>
      <BorrowerInfoCardCustomTheme>
        <EmbeddedFrameBoundaries>
          <TrackRecordLineItem
            store={store}
            borrowerId={parsedBorrowerId}
            onClose={() => { window.parent.postMessage('saved-success', '*'); }}
          />
        </EmbeddedFrameBoundaries>
      </BorrowerInfoCardCustomTheme>
    </Layout>
  );
};
