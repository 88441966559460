import React from 'react';
import {
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  Tooltip,
  Paper,
} from '@material-ui/core';

import { CheckCircle } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { StepTitle } from '@roc/client-portal-shared/components';
import clsx from 'clsx';


export interface StepperComponentProps {
  disableNavigation?: boolean;
  steps: Array<StepObj>;
  activeStep: number;
  handleStep?: (value: number) => void;
  className?: string;
  alternativeLabel?: boolean;
}

export interface StepObj {
  date: string;
  title: string;
  onBack?: () => void;
  onNext?: () => void;
  onSave?: () => void;
  onReprice?: () => void;
  onReject?: () => void;
  allowBack?: boolean;
  allowNext?: boolean;
  allowSave?: boolean;
  allowReprice?: boolean;
  completed?: boolean;
  nextButtonText?: string;
  saveButtonText?: string;
  allowReject?: boolean;
  disableNextButton?: boolean;
  disabledStep?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepLabel-label': {
          display: 'none',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
          display: 'block',
        },
      },
    },
    customIcon: {
      width: 28,
      height: 28,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
    },
    iconBackgroundGray: {
      backgroundColor: theme.palette.grey[500],
    },
    iconBackgroundPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    stepNumber: {
      fontSize: "14px",
    },
    stepNumberTwoDigits: {
      fontSize: "12px",
    },
    checkIcon: {
      color: theme.palette.primary.main,
      fontSize: 32,
    },
    tooltipContent: {
      padding: theme.spacing(1),
      backgroundColor: "#fff",
      boxShadow: theme.shadows[3],
      borderRadius: 4,
    },
    stepWarning: {
      color: theme.palette.warning.main,
    },
  })
);



const CustomStepIcon = ({ icon, completed, selected, elapsedTime, estimatedTimeline, totalElapsedTime, showTooltip, isREO }) => {
  const classes = useStyle();
  const iconContent = (
    completed ? (
      <CheckCircle className={classes.checkIcon} />
    ) : (
      <div className={`${classes.customIcon} ${(completed || selected) ? classes.iconBackgroundPrimary : classes.iconBackgroundGray}`} >
        <span className={icon >= 10 ? classes.stepNumberTwoDigits : classes.stepNumber}>{icon}</span>
      </div >
    )
  );

  return (
    showTooltip ? (
      <Tooltip
        title={
          <Paper className={classes.tooltipContent}>
            <Typography variant="body2"><strong>Elapsed Stage Time:</strong> {`${elapsedTime} ${elapsedTime > 1 ? "days" : "day"}`} </Typography>
            {estimatedTimeline ? (
              <Typography variant="body2"><strong>{isREO ? "REO Deed Recording Timeline:" : "Estimated Timeline to next stage:"}</strong> {`${estimatedTimeline} ${estimatedTimeline > 1 ? "days" : "day"}`}</Typography>
            ) : <></>}
            {totalElapsedTime ? (
              <Typography variant="body2"><strong>Total Elapsed Time:</strong> {`${totalElapsedTime} ${totalElapsedTime > 1 ? "days" : "day"}`} </Typography>
            ) : <></>}
          </Paper>
        }
        arrow
      >
        {iconContent}
      </Tooltip>
    ) : iconContent

  );
}


export const ForeclosureStepper: React.FC<StepperComponentProps> = ({
  disableNavigation,
  steps,
  activeStep,
  handleStep,
  className,
  alternativeLabel
}) => {
  const classes = useStyle();
  const stepsPerRow = steps.length > 14 ? Math.ceil(steps.length / 2) : steps.length;
  const firstRowSteps = steps.slice(0, stepsPerRow);
  const secondRowSteps = steps.slice(stepsPerRow);
  const PROPERTY_SOLD_AT_FORECLOSURE = "Property Sold at FC";
  const REO = "REO";

  const getElapsedDays = (stepDate) => {
    const date = new Date(stepDate);
    const currentDate = new Date();
    const milliseconds = currentDate.getTime() - date.getTime();
    return Math.floor(milliseconds / (1000 * 60 * 60 * 24))
  }

  const getStepRow = (activeStep, steps, sequence) => {

    const activeWarning = (step, index) => {
      return activeStep == index && step.milestone?.durationDays === null && step.title !== PROPERTY_SOLD_AT_FORECLOSURE;
    }

    return (
      <>
        <Stepper
          activeStep={activeStep}
          connector={<StepConnector />}
          className={clsx([classes.stepper, className])}
          alternativeLabel={alternativeLabel}
          nonLinear={true}
        >
          {steps.map((step, index) => {
            const isCompleted = step.completed && step.date && true;
            return (
              <Step key={`${step.title}-${index}`} completed={isCompleted}>s

                <StepButton
                  disabled={disableNavigation}
                  onClick={() => handleStep && handleStep(index)}
                >
                  <StepLabel StepIconComponent={() => <CustomStepIcon icon={sequence + index} completed={isCompleted} selected={step.selected} elapsedTime={getElapsedDays(step.date)} estimatedTimeline={step.milestone?.durationDays} totalElapsedTime={getElapsedDays(step.foreclosureCommencedDate)} showTooltip={activeStep == index && step.title !== PROPERTY_SOLD_AT_FORECLOSURE && step.milestone?.durationDays !== 0} isREO={step.title === REO} />}>

                    {step.date && (
                      <>
                        <Typography variant="subtitle1">{step.date}</Typography>
                      </>
                    )}
                    <StepTitle active={activeStep == index} >
                      <span className={activeWarning(step, index) && classes.stepWarning} >{step.title}</span>
                    </StepTitle>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </>
    )
  }

  return (
    <>
      {getStepRow(activeStep, firstRowSteps, 1)}
      {steps.length > 14 && getStepRow(activeStep - stepsPerRow, secondRowSteps, stepsPerRow + 1)}
    </>
  );
};
