import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { FieldSetBox } from "../fieldSetBox";
import { ReportField } from "./reportField";
import { ReportDate } from "./reportDate";
import roc360 from "../../assets/roc360.png";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { observer } from "mobx-react";
import { formatCurrencyField, formatDateField, formatNumberField, formatPercentageField, getFullAddress, getStringOrDefault } from "../../utils/utils";
import { getLabelForHighestAndBestUseValue, getLabelForLocationValue, getLabelForNeighborhoodZoningValue, getLabelForPropertyTypeValue } from "../../utils/constants";

const useStyles = makeStyles(() => ({
  page: {
    fontFamily: "Roboto, Arial, sans-serif",
    border: "1px solid lightgray",
    borderRadius: "5px",
    width: "1018px",
    height: "1440px",
    padding: "64px 40px 64px 40px",
  },
  header: {
    marginRight: "40px",
    marginBottom: "20px",
  },
  roc360Logo: {
    width: "232px",
    height: "114px",
  },
  title: {
    fontSize: "64px",
    paddingBottom: "20px",
  },
  valuationTitle: {
    fontSize: "inherit",
    color: "#1C3F78",
    lineHeight: "1",
    fontWeight: 100,
  },
  reportTitle: {
    fontSize: "inherit",
    color: "#2EC2C9",
    lineHeight: "1",
    fontWeight: "bolder",
  },
  imgPlaceHolder: {
    border: "1px solid #656565",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    width: "800px",
    height: "300px",
  },
  currentPropertyBox: {
    height: "114px",
  },
  subjectNeighborhoodBox: {
    height: "308px",
  },
  addressTypography: {
    color: "black",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "left",
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    height: "36px",
  },
  longTextTypography: {
    fontSize: "14px",
    padding: "16px",
    paddingTop: "10px",
    fontWeight: 400,
  },
}));

interface Page1Props {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const Page1 = observer(({ internalValuationReviewStore }: Page1Props) => {
  const classes = useStyles();

  const internalValuationData = internalValuationReviewStore.initialData;
  const internalValuationReview = internalValuationData.internalValuationReview;

  const address = getFullAddress(internalValuationReview);

  const propertyStatusInformation = internalValuationData.propertyStatusInformation;
  const neighborhoodHousingConditions = internalValuationData.neighborhoodHousingConditions;

  const recommendedImage = internalValuationData?.propertyImages?.find(image => image.recommendedPreview === "true")?.url;

  return (
    <div className={classes.page}>
      <header className={classes.header}>
        <Grid container justifyContent="center" alignItems="flex-end">
          <Grid item>
            <img src={roc360} alt="Roc 360 Logo" className={classes.roc360Logo} />
          </Grid>
          <Grid item xs>
            <Box
              display="flex"
              alignItems="end"
              justifyContent="space-between"
              width="100%"
              className={classes.title}
            >
              <Box display="flex">
                <Typography className={classes.valuationTitle}>
                  Valuation
                </Typography>
                <Typography className={classes.reportTitle}>Report</Typography>
              </Box>
              <ReportDate date={new Date()} />
            </Box>
          </Grid>
        </Grid>
      </header>
      <main>
        <Grid container spacing={2}>
          <Grid item>
            <FieldSetBox title="Subject Property">
              <Box display="flex" flexDirection="row">
                {recommendedImage && <div style={{
                  width: "800px",
                  height: "300px",
                  backgroundImage: `url(${recommendedImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border: "1px solid #656565"
                }}></div>}
                {!recommendedImage && <div className={classes.imgPlaceHolder}><p>No Image Available</p></div>}
                <Grid container>
                  <Grid item xs={12}>
                    <ReportField label="Address" removeRightBorder>
                      <Typography className={classes.addressTypography}>
                        {address}
                      </Typography>
                    </ReportField>
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Bedroom" value={formatNumberField(internalValuationReview.beds)} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="BathRoom" value={formatNumberField(internalValuationReview.baths)} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Parking" value={formatNumberField(internalValuationReview.parking)} removeRightBorder />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Living Area" value={formatNumberField(internalValuationReview.livingArea)} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Lot area" value={formatNumberField(internalValuationReview.landArea)} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField
                      label="Year Built"
                      value={formatNumberField(internalValuationReview.yearBuilt)}
                      removeRightBorder
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Property Type" value={getStringOrDefault(getLabelForPropertyTypeValue(internalValuationReview.propertyType))} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="Zoning" value={getStringOrDefault(internalValuationReview.subjectZoning)} />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField
                      label="County FIPS"
                      value={getStringOrDefault(internalValuationReview.countyFips)}
                      removeRightBorder
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReportField label="HOA fees" value={formatCurrencyField(internalValuationReview.hoaFees)} />
                  </Grid>
                  <Grid item xs={8}>
                    <ReportField
                      label="Parcel Number"
                      value={getStringOrDefault(internalValuationReview.parcelNo)}
                      removeRightBorder
                    />
                  </Grid>
                </Grid>
              </Box>
            </FieldSetBox>
          </Grid>
          <Grid item xs={12}>
            <FieldSetBox title="Current Property Status">
              <Grid container className={classes.currentPropertyBox}>
                <Grid item xs={4}>
                  <ReportField
                    label="Current Property Status"
                    value={getStringOrDefault(propertyStatusInformation.propertyStatus)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField label="Original List Date" value={formatDateField(propertyStatusInformation.originalListDate)} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Original List Price"
                    value={formatCurrencyField(propertyStatusInformation.originalListPrice)}
                    removeRightBorder
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField label="Sale Type" value={getStringOrDefault(propertyStatusInformation.saleType)} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField label="Last Sale Date" value={formatDateField(propertyStatusInformation.lastSaleDate)} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Last Sale Price"
                    value={formatCurrencyField(propertyStatusInformation.lastSalePrice)}
                    removeRightBorder
                  />
                </Grid>
              </Grid>
            </FieldSetBox>
          </Grid>
          <Grid item xs={12}>
            <FieldSetBox title="Subject Neighborhood Information & Housing Conditions">
              <Grid container className={classes.subjectNeighborhoodBox}>
                <Grid item xs={4}>
                  <ReportField label="Location" value={getStringOrDefault(getLabelForLocationValue(internalValuationReview.location))} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Property Price Trend: Monthly Median Price"
                    value={formatCurrencyField(neighborhoodHousingConditions.propertyPriceTrendMonthlyMedianPrice)}
                    trend={{
                      percentage: neighborhoodHousingConditions.yoyMedianPricePercentage,
                      description: "vs Median Price 1 Year ago",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="REO/Short/Distressed Trhend: Monthly Listings"
                    value={formatNumberField(neighborhoodHousingConditions.reoShortDistressedTrendMonthlyListings)}
                    trend={{
                      percentage: neighborhoodHousingConditions.yoyReoListingsPercentage,
                      description: "vs REO Listings 1 Year Ago",
                    }}
                    removeRightBorder
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label='Number of "Comparable" Sales Past 12MO.'
                    value={formatNumberField(neighborhoodHousingConditions.compsPast12Mo)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label='"Comparable" Sale Price Range'
                    value={`${formatCurrencyField(neighborhoodHousingConditions.comparableSalePriceFrom)} - ${formatCurrencyField(neighborhoodHousingConditions.comparableSalePriceTo)}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Neighborhood Price Range"
                    value={`${formatCurrencyField(neighborhoodHousingConditions.neighborhoodPriceFrom)} - ${formatCurrencyField(neighborhoodHousingConditions.neighborhoodPriceTo)}`}
                    removeRightBorder
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="DOM Trend"
                    value={formatNumberField(neighborhoodHousingConditions.domTrend)}
                    trend={{
                      percentage: neighborhoodHousingConditions.yoyDomPercentage,
                      description: "vs DOM 1 Year Ago",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Supply Trend Months of Supply"
                    value={formatNumberField(neighborhoodHousingConditions.supplyTrendMonthsOfSupply)}
                    trend={{
                      percentage: neighborhoodHousingConditions.yoyMonthsOfSupplyPercentage,
                      description: "vs Months of Supply 1 Year Ago",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Demand Trend % of Sales Within 2 Weeks"
                    value={formatPercentageField(neighborhoodHousingConditions.demandTrendPercentSalesWithin2Weeks)}
                    trend={{
                      percentage: neighborhoodHousingConditions.yoyWithin2WeeksPercentage,
                      description: "vs % of Sales within 2 Weeks 1 Year Ago",
                    }}
                    removeRightBorder
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Highest and Best Use"
                    value={getStringOrDefault(getLabelForHighestAndBestUseValue(internalValuationReview.highestAndBestUse))}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReportField label="Adverse Factors" value={internalValuationReview.hasAdverseFactors ? "Yes" : "No"} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Neighborhood Zoning"
                    value={getStringOrDefault(getLabelForNeighborhoodZoningValue(internalValuationReview.neighborhoodZoning))}
                    removeRightBorder
                  />
                </Grid>
              </Grid>
            </FieldSetBox>
          </Grid>
          <Grid item xs={12}>
            <FieldSetBox title="Adverse External Factor and Neighborhood">
              <ReportField label="Comments" removeRightBorder>
                <Typography className={classes.longTextTypography}>
                  {getStringOrDefault(internalValuationReview.adverseFactorsComments)}
                </Typography>
              </ReportField>
            </FieldSetBox>
          </Grid>
          {internalValuationReviewStore.hasScopeOfWork && <Grid item xs={12}>
            <FieldSetBox title="Scope of Work">
              <Grid container>
                <Grid item xs={4}>
                  <ReportField label="Renovation Budget" value={formatCurrencyField(internalValuationReviewStore.renovationBudget)} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField label="CAP EX" value={formatCurrencyField(internalValuationReviewStore.completedRenovation)} />
                </Grid>
                <Grid item xs={4}>
                  <ReportField
                    label="Total Renovation Budget"
                    value={formatCurrencyField(internalValuationReviewStore.totalRenovationBudget)}
                    removeRightBorder
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReportField label="Project Summary" removeRightBorder>
                    <Typography className={classes.longTextTypography}>
                      {internalValuationReviewStore.projectSummary}
                    </Typography>
                  </ReportField>
                </Grid>
              </Grid>
            </FieldSetBox>
          </Grid>}
        </Grid>
      </main>
    </div>
  );
});