import { GlobalStore } from '@roc/client-portal-shared/stores';
import { FormStore, UserStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE,
  insertIf,
} from '@roc/feature-utils';
import { DialogState } from '@roc/ui';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { GeneralTasksService } from '../services/generalTasksService';
import {
  Assignee,
  GeneralTaskAttachment,
  GeneralTaskComment,
  PopulateTaskFormField,
  GeneralTaskType,
  Task,
  TaskStatus,
} from '../types/generalTasksTypes';
import { appraisalTaskTypes, lineItemsTaskTypes, taskTypeOptions } from '../utils/generalTasksConstants';
import { createEmptyTask } from '../utils/generalTasksUtils';
import { GeneralTasksAttachmentStore } from './generalTasksAttachmentStore';
import { GeneralTasksCommentStore } from './generalTasksCommentStore';
import { GeneralTasksStore } from './generalTasksStore';
import { UserOption } from '@roc/feature-communication';

const form = {
  fields: {
    title: {
      value: '',
      error: null,
      rule: 'required',
    },
    description: {
      value: '',
      error: null,
      rule: '',
    },
    status: {
      value: TaskStatus.OPEN,
      error: null,
      rule: 'required',
    },
    priority: {
      value: '',
      error: null,
      rule: '',
    },
    dueDate: {
      value: '',
      error: null,
      rule: '',
    },
    assigneeId: {
      value: null,
      error: null,
      rule: '',
    },
    watchIds: {
      value: null,
      error: null,
      rule: '',
    },
    loanId: {
      value: '',
      error: null,
      rule: '',
    },
    taskType: {
      value: '',
      error: null,
      rule: ''
    },
    parentTaskId: {
      value: null,
    },
    ownerName: {
      value: null,
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class EditTaskStore extends FormStore {
  private globalStore: GlobalStore;
  private generalTasksStore: GeneralTasksStore;
  private generalTaskService: GeneralTasksService;

  commentStore: GeneralTasksCommentStore;
  attachmentStore: GeneralTasksAttachmentStore;
  task: Task;
  dialogState: DialogState;
  comments: GeneralTaskComment[];
  attachments: GeneralTaskAttachment[];

  canEscalateTask: boolean;
  hasConversation: boolean;
  isTamariskAppraisal: boolean;
  allowedEscalationRoles: string[];

  constructor(
    globalStore: GlobalStore,
    userStore: UserStore,
    generalTasksStore: GeneralTasksStore
  ) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.generalTasksStore = generalTasksStore;
    this.generalTaskService = new GeneralTasksService();

    this.commentStore = new GeneralTasksCommentStore(
      globalStore,
      userStore,
      this
    );
    this.attachmentStore = new GeneralTasksAttachmentStore(globalStore, this);

    makeObservable(this, {
      task: observable,
      hasConversation: observable,
      dialogState: observable,
      comments: observable,
      attachments: observable,
      reset: override,
      closeDialog: action,
      setAttachments: action,
      setComments: action,
      saveTaskWithMessage: flow,
      loadTask: flow,
      openAddTask: flow,
      openEditTask: flow,
      openAddSubtask: flow,
      saveTask: flow,
      isReadOnly: computed,
      isReadOnlyForAppraisalTasks: computed,
      isAppraisalTaskType: computed,
      isSubTask: computed,
      createTaskChatRoom: flow,
      escalateTaskToManager: flow,
      moveTaskToRoc: flow,
      moveTaskToCapitalProvider: flow,
      markTaskAsCompleted: flow,
      moveTaskToCMTSpecialist: flow,
      putTaskToTpo: flow,
      getAllowedEscalationRoles: flow,
      setCanEscalate: flow,
      canEscalateTask: observable,
      allowedEscalationRoles: observable,
      isTamariskAppraisal: observable,
      mapFormValuesToTask: flow,
      reloadTaskById: flow,
      markTaskAsWaitingOnExternal: flow,
      setIsTamariskAppraisal: flow,
    });
  }

  reset() {
    super.reset();
    this.commentStore.reset();
    this.attachmentStore.reset();
    this.task = null;
    this.dialogState = null;
    this.comments = [];
    this.attachments = [];
  }

  *openAddTask(parentTask: Task = null, populateTaskFormData: PopulateTaskFormField = {}, callback?: () => void) {
    this.reset();
    this.initializeNewTask(parentTask);

    yield this.loadForm({
      loanId: this.determineNewTaskLoanId(parentTask),
      parentTaskId: parentTask?.taskDetails?.taskId,
      ...populateTaskFormData,
      dueDate: null,
    });

    this.dialogState = DialogState.ADD;
    if (callback) callback();
  }

  initializeNewTask(parentTask: Task) {
    this.task = createEmptyTask();
    this.task.parentTask = parentTask?.taskDetails ?? null;
    this.hasConversation = false;
  }

  determineNewTaskLoanId(parentTask: Task) {
    if (this.isSubTask && parentTask) {
      return parentTask.attributes.loanId;
    }
    // If the task is created from the loan view loanId will be the current loan
    return this.generalTasksStore.loanId;
  }

  *reloadTaskById(taskId: number) {
    const response = yield this.generalTaskService.getTaskById(taskId);
    const task = response.data.data;
    if (task.attributes.taskType === GeneralTaskType.APPRAISAL_REPORT_REVIEW) {
      yield this.setIsTamariskAppraisal(task.attributes.loanId, task.attributes.propertyId)
    }
    yield this.loadTask(task);
  }

  *openEditTask(taskId) {
    this.reset();
    yield this.reloadTaskById(taskId);
    const responseTaskChat = yield this.generalTaskService.doesTaskChatRoomExist(taskId);
    this.hasConversation = responseTaskChat.data.data;
    yield this.generalTasksStore.fetchUserInfoAndAssigneeOptions(this.task?.attributes?.loanId, this.task?.attributes?.loanTaskId);
    yield this.generalTasksStore.fetchUserInfoAndWatchersOptions();
    this.allowedEscalationRoles = yield this.getAllowedEscalationRoles();
    yield this.setCanEscalate();
    this.dialogState = DialogState.EDIT;
  }

  *createTaskChatRoom() {
    try {
      if (lineItemsTaskTypes.includes(this.task.attributes.taskType as GeneralTaskType)) {
        yield this.generalTaskService.createLineItemTaskChatRoom(this.task);
      }
      yield this.generalTaskService.createTaskChatRoom(this.task);
      const responseTaskChat = yield this.generalTaskService.doesTaskChatRoomExist(this.task.taskDetails.taskId);
      this.hasConversation = responseTaskChat.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }

  }

  *openAddSubtask() {
    yield this.openAddTask(this.task);
  }

  *loadTask(task: Task) {
    this.task = task;
    this.loadForm({
      title: this.task.taskDetails.title,
      description: this.task.taskDetails.description,
      status: this.task.taskDetails.status,
      priority: this.task.taskDetails.priority,
      dueDate: this.task.taskDetails.dueDate,
      assigneeId: this.task.assignees?.[0]?.assigneeId,
      loanId: this.task.attributes.loanId,
      taskType: this.task.attributes.taskType,
      watchIds: this.task.observers,
      parentTaskId: this.task.taskDetails.parentTaskId,
      ownerName: this.task.taskDetails.ownerFirstName + ' ' + this.task.taskDetails.ownerLastName,
    });
    this.comments = this.task.comments;
    this.attachments = this.task.attachments;
  }

  *saveTask() {
    if (this.runFormValidationWithMessage()) {
      const values = this.getFormValues();
      const task: Task = {
        taskDetails: {
          ...this.task.taskDetails,
          title: values.title,
          description: values.description,
          status: values.status || null,
          priority: values.priority || null,
          parentTaskId: values.parentTaskId,
          dueDate: formatDate(values.dueDate, DateFormat.MMDDYYYY),
        },
        attributes: {
          ...this.task.attributes,
          loanId: values.loanId,
          taskType: values.taskType ? values.taskType : null
        },
        assignees: [
          ...insertIf(values.assigneeId, [
            {
              ...((this.task.assignees?.[0] || {}) as Assignee),
              assigneeId: values.assigneeId,
            },
          ]),
        ],
        observers: values.watchIds
      };
      const response = yield this.generalTaskService.saveTask(task);
      const savedTask = response.data.data;
      this.task = savedTask;
      yield this.generalTasksStore.updateViews();
      return true;
    }
  }

  *saveTaskWithMessage() {
    try {
      const success = yield this.saveTask();
      if (success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Task saved successfully!',
        });
        this.closeDialog();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  setComments(comments) {
    this.comments = comments;
  }

  setAttachments(attachments) {
    this.attachments = attachments;
  }

  setHasConversation(hasConversation) {
    this.hasConversation = hasConversation;
  }

  *deleteTask(taskId) {
    //yield this.generalTaskService.
  }

  closeDialog() {
    this.dialogState = null;
    this.generalTasksStore.resetAndFetchOptions();
  }

  get isEscalationTask() {
    return this.form.fields.taskType.value == GeneralTaskType.ESCALATION;
  }

  get isReadOnly() {
    const taskType = this.task?.attributes?.taskType;
    const isSystemTask = this.task?.taskDetails?.isSystemTask;
    const isLegacyTaskType = taskTypeOptions.some(opt => opt.value === taskType && opt.legacy);
    return isLegacyTaskType || isSystemTask;
  }

  get isReadOnlyForAppraisalTasks() {
    return (this.globalStore.isCrmPortal || this.globalStore.isLenderPortal)
      && appraisalTaskTypes.includes(this.task?.attributes?.taskType as GeneralTaskType);
  }

  get isAppraisalTaskType() {
    return appraisalTaskTypes.includes(this.task?.attributes?.taskType as GeneralTaskType);
  }

  get isSubTask() {
    return !!this.task?.parentTask;
  }

  *getAllowedEscalationRoles() {
    const response = yield this.generalTaskService.getAllowedEscalationRoles();
    const allowedEscalationRoles: string[] = response.data.data;
    return allowedEscalationRoles;
  }

  *setCanEscalate() {
    if (!this.generalTasksStore.assigneeOptions) return false;
    const userOption: UserOption[] = this.generalTasksStore.assigneeOptions.filter(assignee => assignee.userId === Number(this.form.fields.assigneeId.value));
    this.canEscalateTask = this.allowedEscalationRoles.includes(userOption?.[0]?.role);
  }

  *escalateTaskToManager() {
    try {
      const response = yield this.generalTaskService.escalateTaskToManager(this.task.taskDetails.taskId);

      const savedTask = response.data.data;
      this.task = savedTask;
      yield this.loadTask(this.task);
      yield this.generalTasksStore.updateViews();

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Task escalated successfully!',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *markTaskAsCompleted(onFinish: () => void) {
    try {
      yield this.generalTaskService.markTaskAsCompleted(this.task.taskDetails.taskId);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *markTaskAsWaitingOnExternal(onFinish: () => void) {
    try {
      yield this.generalTaskService.markTaskAsWaitingOnExternal(this.task.attributes.loanId, this.task.attributes.propertyId, this.task.attributes.taskType);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *moveTaskToCapitalProvider(onFinish: () => void) {
    try {
      yield this.generalTaskService.moveTaskToCapitalProvider(this.task.attributes.loanTaskId);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *moveTaskToRoc(onFinish: () => void) {
    try {
      yield this.generalTaskService.moveTaskToRoc(this.task.taskDetails.taskId);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *moveTaskToCMTSpecialist(onFinish: () => void) {
    try {
      yield this.generalTaskService.moveTaskToCMTSpecialist(this.task.taskDetails.taskId);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *putTaskToTpo(onFinish: () => void) {
    try {
      yield this.generalTaskService.putTaskToTpo(this.task.taskDetails.taskId);
      yield this.generalTasksStore.updateViews();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    } finally {
      onFinish();
    }
  }

  *mapFormValuesToTask(values) {
    const task: Task = {
      taskDetails: {
        ...this.task.taskDetails,
        title: values.title,
        description: values.description,
        status: values.status || null,
        priority: values.priority || null,
        parentTaskId: values.parentTaskId,
        dueDate: formatDate(values.dueDate, DateFormat.MMDDYYYY),
      },
      attributes: {
        ...this.task.attributes,
        loanId: values.loanId,
        taskType: values.taskType ? values.taskType : null
      },
      assignees: [
        ...insertIf(values.assigneeId, [
          {
            ...((this.task.assignees?.[0] || {}) as Assignee),
            assigneeId: values.assigneeId,
          },
        ]),
      ],
      observers: values.watchIds
    };

    return task;
  }

  *setIsTamariskAppraisal(loanId: number, propertyAppraisalId: number) {
    const response = yield this.generalTaskService.getIsTamariskAppraisal(loanId, propertyAppraisalId);
    this.isTamariskAppraisal = response.data.data
  }
}
