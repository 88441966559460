import { Service } from "@roc/feature-app-core";

const url = {
  GET_VALUATION_REVIEW_DATA: '/api/v1/property/internalValuationReview/getInternalValuationReviewByLoanIdAndPropertyId',
  INTERNAL_VALUATION_REVIEW: '/api/v1/property/internalValuationReview',
  UPDATE_PROPERTY_COMPS: "/api/v1/property/propertyComp/updatePropertyComps"
};

export class InternalValuationReviewService extends Service {
  url;
  constructor() {
    super();
    this.url = url;
  }

  getInternalValuationReviewData(loanId: number, propertyId: number) {
    return this.get(this.url.GET_VALUATION_REVIEW_DATA,
      { loanId, propertyId }
    );
  }

  saveInternalValuationReview(payload, disableGlobalLoading = false) {
    if (!payload.internalValuationReviewId) {
      return this.post(this.url.INTERNAL_VALUATION_REVIEW,
        payload, null, { disableGlobalLoading }
      );
    }

    return this.put(this.url.INTERNAL_VALUATION_REVIEW,
      payload, null, null, { disableGlobalLoading }
    );
  }

  updatePropertyComps(payload, disableGlobalLoading = false) {
    return this.put(this.url.UPDATE_PROPERTY_COMPS,
      payload, null, null, { disableGlobalLoading }
    );
  }
}