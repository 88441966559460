/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DirtyableFormStore, GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";

const form = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    county: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    beds: {
      value: '',
      error: null,
      rule: '',
    },
    baths: {
      value: '',
      error: null,
      rule: '',
    },
    parking: {
      value: '',
      error: null,
      rule: '',
    },
    livingArea: {
      value: '',
      error: null,
      rule: '',
    },
    propertyType: {
      value: '',
      error: null,
      rule: '',
    },
    subjectZoning: {
      value: '',
      error: null,
      rule: '',
    },
    yearBuilt: {
      value: '',
      error: null,
      rule: '',
    },
    landArea: {
      value: '',
      error: null,
      rule: '',
    },
    hoaFees: {
      value: '',
      error: null,
      rule: '',
    },
    parcelNo: {
      value: '',
      error: null,
      rule: '',
    },
    propertyImages: {
      value: [],
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PropertyInformationFormStore extends DirtyableFormStore {
  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.reset();

    makeObservable(this, {
    });
  }
}
