import { useCallback, useRef, useState, useMemo } from 'react';
import { Add, CloudDownload } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Document } from '@roc/feature-types';
import { StatusChip, StatusType, TableCard } from '@roc/ui';
import { FileUpload, FileUploadModal } from '@roc/ui';
import { formatDate, insertIf } from '@roc/feature-utils';
import { DocumentName } from './components/documentName';
import { DocumentActions } from './components/documentActions';
import { DocumentHistoryDialog } from '../documentHistory/documentHistoryDialog';

const columns = [
  {
    name: 'Document Name',
    width: '35%',
  },
  {
    name: 'Filename',
    width: '30%',
  },
  {
    name: 'Update Date',
    style: { width: 250 },
  },
  {
    name: 'Status',
    style: { width: 120 },
  },
  {
    name: 'Actions',
    style: { width: 120 },
  },
];

const getRows = (documents: Document[], actions, showDocumentStatus: boolean) =>
  documents.map(doc => [
    {
      value: doc.taskName,
      component: () => (
        <DocumentName
          document={doc}
          onDownload={actions.onDownload}
          onOpenForm={actions.onOpenForm}
        />
      ),
    },
    {
      value: doc.originalFileName || '',
    },
    {
      value: formatDate(doc.lastUpdateDate),
    },
    ...insertIf(showDocumentStatus,
      [{
        value: doc.status,
        component: () => (
          <StatusChip
            label={doc.status?.toUpperCase() || 'PENDING'}
            statusType={StatusType.DOCUMENT}
            variant="filled"
          />
        ),
      }]),
    {
      value: 'menu',
      component: () => (
        <DocumentActions
          document={doc}
          onDownload={actions.onDownload}
          onUpload={actions.onUpload}
          onPreview={actions.onPreview}
          onOpenForm={actions.onOpenForm}
          onOpenHistory={actions.onHistoryClick}
          onFillForm={actions.onFillForm}
          onShareLink={actions.onShareLink}
          onViewForm={actions.onViewForm}
          onRequestMoreInformation={actions.onRequestMoreInformation}
        />
      ),
    },
  ]);

const useStyles = makeStyles(() => ({
  helperTextRemaining: {
    fontSize: 14,
    color: '#d94452',
  },
  helperTextCompleted: {
    fontSize: 14,
    color: '#48ad25',
  },
}));

const getPendingDocs = (documents: Document[]) =>
  documents?.filter(doc => doc.status === 'Pending' || !doc.status)?.length;

const getCardTitle = (title: string) => {
  const sectionParts = title.split(' - ');
  return sectionParts.length > 1 ? (
    <>
      <span>{sectionParts[1]}</span>
      <br />
      <Typography component="span" color="textSecondary">
        {sectionParts[0]}
      </Typography>
    </>
  ) : (
    title
  );
};

interface IDocumentsTable {
  title: string;
  documents: Document[];
  unreadComments?: number;
  allowCommunication?: boolean;
  twilioEnabled?: boolean;
  allowUpload?: boolean;
  allowSectionDownload?: boolean;
  onSectionUpload: (files: FileUpload[]) => void;
  onSectionDownload: () => void;
  onDocumentUpload: (file: File, docName: Document) => void;
  onDownload: (file: Document) => void;
  onPreview: (file: Document) => void;
  onOpenForm: (file: Document) => void;
  onOpenCommunication?: () => void;
  onFillForm?: (document: Document) => void;
  onShareLink?: (document: Document) => void;
  onViewForm?: (document: Document) => void;
  showDocumentStatus?: boolean;
  onRequestMoreInformation?: (document: Document) => void;
}

export const DocumentsTable = ({
  title,
  documents,
  unreadComments = 0,
  allowCommunication,
  twilioEnabled,
  allowUpload,
  allowSectionDownload,
  onSectionUpload,
  onSectionDownload,
  onDocumentUpload,
  onDownload,
  onPreview,
  onOpenForm,
  onOpenCommunication,
  onFillForm,
  onViewForm,
  onShareLink,
  onRequestMoreInformation,
  showDocumentStatus = true
}: IDocumentsTable) => {
  const [activeDoc, setActiveDoc] = useState(null);
  const inputRef = useRef(null);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [isHistoryOpen, setHistoryOpen] = useState(false);

  const onUpload = useCallback(
    doc => {
      inputRef.current.click();
      setActiveDoc(doc);
    },
    [inputRef]
  );

  const closeFileUploadModal = () => {
    setUploadOpen(false);
  };

  const onHistoryClick = useCallback(doc => {
    setActiveDoc(doc);
    setHistoryOpen(true);
  }, []);

  const closeHistoryModal = () => {
    setHistoryOpen(false);
  };

  const getFilteredColumns = () => {
    if (!showDocumentStatus) {
      return columns.filter(column => column.name != 'Status')
    }
    return columns;
  }

  const classes = useStyles();
  const rows = useMemo(
    () => getRows(documents, {
      onUpload,
      onDownload,
      onPreview,
      onOpenForm,
      onHistoryClick,
      onFillForm,
      onShareLink,
      onViewForm,
      onRequestMoreInformation
    }, showDocumentStatus),
    [
      documents,
      onUpload,
      onDownload,
      onPreview,
      onOpenForm,
      onHistoryClick,
      onFillForm,
      onShareLink,
      onViewForm,
      onRequestMoreInformation
    ]
  );

  const pendingDocs = getPendingDocs(documents);

  const helperText =
    rows.length > 0 && showDocumentStatus ? `${pendingDocs} Pending out of ${rows.length}` : '';

  return (
    <Box mb={2}>
      <input
        accept="*"
        type="file"
        onChange={event => onDocumentUpload(event.target.files[0], activeDoc)}
        hidden
        ref={inputRef}
      />
      <FileUploadModal
        title={`ADD NEW DOCUMENTS: ${title}`}
        open={isUploadOpen}
        multiple
        onClose={closeFileUploadModal}
        onUpload={files => onSectionUpload(files)}
      />
      <DocumentHistoryDialog
        open={isHistoryOpen}
        onClose={closeHistoryModal}
        document={activeDoc}
      />
      <TableCard
        title={getCardTitle(title)}
        options={[
          allowUpload && {
            name: 'Add New Documents',
            action: () => setUploadOpen(true),
            icon: Add,
          },
          allowSectionDownload && {
            name: 'Download All Documents',
            action: () => onSectionDownload(),
            icon: CloudDownload,
          },
        ].filter(Boolean)}
        optionsTitle="Section Actions"
        helperText={helperText}
        rows={rows}
        columns={getFilteredColumns()}
        emptyText="No Records."
        helperTextClass={
          pendingDocs === 0
            ? classes.helperTextCompleted
            : classes.helperTextRemaining
        }
      />
    </Box>
  );
};

export default DocumentsTable;
