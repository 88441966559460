import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GoogleMapReact from 'google-map-react';
import { ComparableProperty } from '../../types/propertyTypes';
import { Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { ReactComponent as PropertyMarker } from '../../images/SubjectPropertyMarker.svg'
import { ReactComponent as CompMarker } from '../../images/CompPropertyMarker.svg'
import { Button } from '@roc/ui';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { PropertyCheckBox } from './propertyCheckBox';
import { formatAbbreviatedCurrency } from '../../utils/formatter';
import { useStore } from '@roc/client-portal-shared/hooks';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marker: {
      backgroundColor: '#285391',
      color: '#ffffff',
      fontWeight: 'bold',
      padding: '0px 5px',
      borderRadius: '4px',
    },
    compMarker: {
      backgroundColor: '#717580',
      color: '#ffffff',
      fontWeight: 'bold',
      padding: '0px 5px',
      borderRadius: '4px',
    },
    button: {
      backgroundColor: 'white',
      minWidth: '50px',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      textAlign: 'center',
      position: 'absolute',
      top: '4%',
      left: '96%',
      transform: 'translate(-50%, -50%)',
      paddingRight: '2px'
    },
    markerDiv: {
      position: "absolute",
      bottom: "100%", // Positioning relative to bottom instead of top
      marginLeft: "-10px", // Half of the width (80px / 2) to center it
    },
    card: {
      position: 'absolute',
      zIndex: 9999
    }
  })
);

export const CompPropertyMarker = ({ lat, lng, text, compToolStore, property }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classes.markerDiv}
    >
      <Typography variant="body2">
        <span className={classes.compMarker}>
          {text}{compToolStore.rentalComps && <span style={{ fontSize: '12px' }}>/mo</span>}
        </span>
      </Typography>
      <CompMarker />
      <div className={classes.card}>
        {hover && (
          <PropertyCheckBox property={property} />
        )}
      </div>

    </div>
  );
};

const SubjectPropertyMarker = ({ lat, lng, text, compToolStore }) => {
  const classes = useStyles();
  return (
    <div className={classes.markerDiv}>
      <Typography variant="body2" >
        <span className={classes.marker}>
          {text}{compToolStore.rentalComps && <span style={{ fontSize: '12px' }}>/mo</span>}
        </span >
      </Typography>
      <PropertyMarker />
    </div>
  );
};

const mapOptions = {
  fullscreenControl: false,
  zoomControl: true,
};

export interface GoogleMapProps {
  mapStyles?: any;
  markers: ComparableProperty[]
}

export const PropertiesMap: React.FC<GoogleMapProps> = ({
  mapStyles,
  markers
}) => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { subjectProperty } = compToolStore;
  const propertyPrice = compToolStore.rentalComps ? subjectProperty.data.rent : subjectProperty.data.price;

  const [mapInstance, setMapInstance] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);

  const handleApiLoaded = (map, maps) => {
    setMapInstance(map);
    setMapsApi(maps);
  };

  useEffect(() => {
    if (mapsApi && mapInstance) {
      const bounds = new mapsApi.LatLngBounds();
      bounds.extend(new mapsApi.LatLng(subjectProperty.data.lat, subjectProperty.data.lng));

      markers.forEach((marker) => {
        bounds.extend(new mapsApi.LatLng(marker.data.lat, marker.data.lng));
      });

      mapInstance.fitBounds(bounds);
    }
  }, [markers, mapInstance, mapsApi]);

  return (
    <Box className={mapStyles} display="flex">
      <GoogleMapReact
        defaultCenter={{ lat: subjectProperty.data.lat, lng: subjectProperty.data.lng }}
        defaultZoom={15}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        <SubjectPropertyMarker
          lat={subjectProperty.data.lat}
          lng={subjectProperty.data.lng}
          text={formatAbbreviatedCurrency(propertyPrice)}
          compToolStore={compToolStore}
        />
        {markers?.map((marker) => (
          <CompPropertyMarker
            key={marker.data.lat + marker.data.lng}
            lat={marker.data.lat}
            lng={marker.data.lng}
            text={formatAbbreviatedCurrency(compToolStore.rentalComps ? marker.data.rent : marker.data.price)}
            property={marker}
            compToolStore={compToolStore}
          />
        ))}
      </GoogleMapReact>
      {compToolStore.propertiesMapExtended ? (
        <Button
          variant="outlined"
          color="primary"
          testId="expandExitButton"
          className={classes.button}
          onClick={() => {
            compToolStore.setPropertiesMapExtended(false);
          }}
          startIcon={<FullscreenExit />}
        />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          testId="expandButton"
          className={classes.button}
          onClick={() => {
            compToolStore.setPropertiesMapExtended(true);
          }}
          startIcon={<Fullscreen />}
        />
      )}
    </Box>
  );
};
