import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import { InternalValuationReviewStore } from "../stores/internalValuationReviewStore";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const useStyles = makeStyles(() => ({
  subjectPropertyInfoCard: {
    height: "500px",
    padding: "10px",
  },
  iconBox: {
    backgroundColor: "#E4E8EB",
    borderRadius: "100%",
    width: "2.5rem",
    height: "2.5rem",
  },
  button: {
    minWidth: "180px"
  }
}));

interface SubjectPropertyInformationCardHeaderProps {
  internalValuationReviewStore: InternalValuationReviewStore;
  generateReport: () => void;
}

export default function SubjectPropertyInformationCardHeader({ internalValuationReviewStore, generateReport }: SubjectPropertyInformationCardHeaderProps) {
  const classes = useStyles();

  const saveHandler = () => {
    internalValuationReviewStore.saveInternalValuationReview();
  }

  const generateReportHandler = () => {
    generateReport();
  }

  const downloadAppraisalReportHandler = () => {
    internalValuationReviewStore.downloadAppraisalReport();
  }

  const markAsCompleteHandler = () => {
    internalValuationReviewStore.markAsComplete();
  }

  const readOnly = !!internalValuationReviewStore.readOnly;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.iconBox}
        >
          <BusinessCenterOutlinedIcon />
        </Box>
        <Typography variant="h5" style={{ marginLeft: "1rem" }}>
          Subject Property Information
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" gridGap="1rem">
        <Button className={classes.button} variant="contained" color="primary" onClick={markAsCompleteHandler} disabled={readOnly}>
          Mark as Complete
        </Button>
        <Button className={classes.button} variant="contained" color="primary" onClick={downloadAppraisalReportHandler}>
          Appraisal Report
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={generateReportHandler}
          disabled={readOnly}
        >
          Generate Report
        </Button>
        <Button disabled={readOnly} variant="outlined" color="primary" startIcon={<SaveOutlinedIcon />} onClick={saveHandler}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
