import { FormStore } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import {
  formatStringToMMDDYYYYDate,
  generateUUID,
  parseAddress,
  PURCHASE,
  REFINANCE,
} from '@roc/feature-utils';
import { PropertySource, propertySourceValues } from '../../utils/constants';
import { PropertiesStore } from './propertiesStore';
import { PropertyFormStore } from './propertyFormStore';
import primaryPropertyTemplate from 'libs/feature-loans/src/loanSubmission/stores/templates/primaryPropertyTemplate';

const REQUIRED_MESSAGE = 'This field is required';

const propertyInformationForm = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The address is required.',
    },
    __isAddressInCorrectFormat__: {
      value: true,
      error: null,
      rule: 'accepted',
      message: 'Invalid Address Format.',
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Street Name is required.',
    },
    city: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The City is required.',
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The State is required.',
    },
    county: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The Zip Code is required.',
    },
    latitude: {
      value: 0,
      error: null,
      rule: '',
    },
    longitude: {
      value: 0,
      error: null,
      rule: '',
    },
    aptNumber: {
      value: '',
      error: null,
      rule: '',
    },
    propertyType: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The property type is required.',
    },
    loanPurpose: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    purchaseDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: [
        {
          required_if_one_of: ['loanPurpose', REFINANCE],
        },
      ],
      format: 'MM/dd/yyyy',
      message: 'Purchase Date is required.',
    },
    propertySourcing: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    sellerCredit: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    sellerCreditAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['sellerCredit', true],
        },
      ],
      message: 'The Seller Credit Amount is required.',
    },
    borrowerKnowOwnerOfRecord: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    armsLengthComment: {
      value: '',
      error: null,
      rule: [
        {
          required_if: ['borrowerKnowOwnerOfRecord', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    outstandingLoanOnTheProperty: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', REFINANCE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['outstandingLoanOnTheProperty', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    loanEverBeenInDefault: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['outstandingLoanOnTheProperty', true],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    //GU
    permitsInPlace: {
      value: false,
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //STB
    hasBeenRecentlyRenovated: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //STB
    soonToBeListedForSale: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //STB
    currentlyOccupied: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    //STB
    monthlyMarketRent: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    //STB
    annualInsurance: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    //STB
    holdingCosts: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', REFINANCE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    purchasePrice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    currentMarketValue: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    constructionRenoSpendToDate: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', REFINANCE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    squareFootageExpansion: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: ['propertyOwnership', PURCHASE],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    wholesalerPropertyContractAmount: {
      value: '',
      error: null,
      rule: [
        {
          required_if_one_of: [
            'propertySourcing',
            propertySourceValues.wholesaler,
          ],
        },
      ],
      message: REQUIRED_MESSAGE,
    },
    propertySourcingExplanation: {
      value: '',
      error: null,
      rule: [
        { required_if_one_of: ['propertySourcing', PropertySource.OTHER] },
      ],
      message: REQUIRED_MESSAGE,
    },
    //Supplemental Information
    hasNewAddress: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: [{ required_if_true: 'isPreflight' }],
      message: REQUIRED_MESSAGE,
    },
    //Flags
    isAppraisalAvailable: { value: false },
    isPreflight: { value: false },
    //Term Sheet Fields
    releaseAmount: { value: null },
    propertyLoanAllocatedAmount: { value: null },
  },
  meta: {
    key: null,
    editMode: false,
    isValid: false,
    error: null,
  },
};

export class StabilizedBridgePropertiesStore extends PropertiesStore {
  constructor(globalStore, loanSubType) {
    super(globalStore, loanSubType);
  }

  createPropertyForm() {
    return new PropertyFormStore(propertyInformationForm, this.globalStore, this.loanSubType);
  }

  getProperties() {
    return this.propertyFormStores.map(form => {
      const property = form.getFormValues();

      return {
        ...form.getProperty(),
        nsfrLoanPropertyFields: {
          ...primaryPropertyTemplate.nsfrLoanPropertyFields,
          propertyValuation: property.currentMarketValue,
          annualTaxes: property.annualTaxes,
          annualInsurance: property.annualInsurance,
          annualInsuranceFromSubmission: property.annualInsurance,
          annualHOA: 0,
          acquisitionPrice: property.purchasePrice,
          acquisitionDate: formatStringToMMDDYYYYDate(property.purchaseDate),
          capitalImprovements: property.constructionRenoSpendToDate,
          totalDebtPayoff: property.payoffAmount,
          monthlyGrossRent: property.monthlyMarketRent,
          monthlyMarketRent: property.monthlyMarketRent,
          noOfUnitLeased: property.units,
          annualGrossRent:
            property.monthlyMarketRent && property.monthlyMarketRent * 12,
        }
      }
    });
  }
}
