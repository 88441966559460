import { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload, MoreVert, Replay } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { DocumentName, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  Button,
  DropdownMenu,
  Paper,
  DataGrid as PayoffGrid
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { APPROVED_STAGE, RETRACTED_STAGE } from '../constants/payoffs';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { PayoffDetails } from './payoffDetails';
import { PayoffLoanInformationNew } from './payoffLoanInformation';
import { PayoffRequestDialog } from './payoffRequestDialog';
import { PayoffRetractDialog } from './payoffRetractDialog';

const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  alert: {
    textAlign: 'center',
  },
  alertContainer: {
    paddingTop: 20,
  },
}));

export const PayoffDashboardNew = observer(({ loanId }: { loanId: string }) => {
  const {
    payoffRequestStore,
    payoffRequestStore: { loanPayoffs, currentPayoff, disableCreatePayoff },
  } = usePayoffRequestStore();
  const { documentStore } = useDocumentStore();
  const {
    loanStore: { loanDetails },
  } = useLoanStore();
  const classes = useStyles();
  const [
    isNewPayoffRequestModalOpen,
    setIsNewPayoffRequestModalOpen,
  ] = useState(false);
  const [
    isNewPayoffRetractModalOpen,
    setIsNewPayoffRetractModalOpen,
  ] = useState(false);
  const { globalStore } = useBaseStore();
  useEffect(() => {
    payoffRequestStore.getActivePayoffDataByLoanId(loanId);
    payoffRequestStore.getPayoffsByLoanId(loanId);
  }, [loanId, payoffRequestStore]);

  useEffect(() => {
    if (currentPayoff) {
      payoffRequestStore.getPayoffsByLoanId(loanId);
    }
  }, [currentPayoff, payoffRequestStore, loanId]);

  const showActivePayoff = () => {
    if (payoffRequestStore.currentPayoff)
      return <PayoffDetails loanId={loanId} />;
  };

  const downloadFinalPayoffReport = () => {
    const loanDocumentJson = {
      loanId: loanId,
      taskName: DocumentName.FINAL_PAYOFF_REPORT,
    };
    documentStore.downloadDocumentByName(loanDocumentJson);

  };

  const openRetractPayoff = (payoff) => {
    if (payoff.payoffStatus !== RETRACTED_STAGE)
      setIsNewPayoffRetractModalOpen(true);
  };

  const handleCreatePayoffRequest = () => {
    if (!payoffRequestStore.currentPayoff) {
      setIsNewPayoffRequestModalOpen(true);
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'There is an active payoff for this loan.',
      });
    }
  };

  const payoffColumns: AgGridColumnProps[] = [
    {
      field: 'goodThroughDate',
      headerName: 'Good Through Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'payoffStatus',
      headerName: 'Status',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedByFullName',
      headerName: 'Requested By',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedDate',
      headerName: 'Requested Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 50,
      cellRenderer: 'actionCellRenderer',
    },
  ];

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    actionCellRenderer: params => {
      const data = params.node.data;
      return (
        <>
          <DropdownMenu options={getActions(data)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
  };

  const getActions = (data) => {
    const actionList = [];
    if (data?.payoffStatus !== RETRACTED_STAGE) {
      actionList.push(
        {
          icon: Replay,
          name: 'Reject',
          action: () => openRetractPayoff(data),
        }
      );
      if (data?.payoffStatus === APPROVED_STAGE) {
        actionList.push(
          {
            icon: CloudDownload,
            name: 'Download Final Report',
            action: () => downloadFinalPayoffReport(),
          }
        );
      }
    }
    return actionList;
  };

  return (
    <>
      <Grid item container direction="row" spacing={3} xs={12} style={{
        margin: 0
      }}>
        {showActivePayoff()}
        <PayoffLoanInformationNew />
      </Grid>
      <Grid item xs={12} className={classes.gridContainer}>
        <Paper style={{
          paddingBottom: 16
        }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                </Grid>
                <Grid item>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      testId="createPayoffRequest"
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={disableCreatePayoff}
                      onClick={handleCreatePayoffRequest}
                    >
                      Request Payoff
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PayoffGrid
                columns={payoffColumns}
                rows={loanPayoffs}
                frameworkComponents={frameworkComponents}
                domLayout="autoHeight"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <PayoffRequestDialog
        loanId={loanId}
        open={isNewPayoffRequestModalOpen}
        onClose={() => {
          setIsNewPayoffRequestModalOpen(false);
        }}
      />
      <PayoffRetractDialog
        loanId={loanId}
        open={isNewPayoffRetractModalOpen}
        onClose={() => {
          setIsNewPayoffRetractModalOpen(false);
        }}
      />

    </>
  );
});
