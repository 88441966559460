import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { BorrowerSummaryCard } from '../cards/borrowerSummaryCard';
import { LoanSummaryTodosTasksCard } from '../cards/loanSummaryTodosTasksCard';
import { LoanDetailsCardStabilizedBridge } from './loanDetailsCardStabilizedBridgeV2';
import { LoanKeyDates } from '../cards/loanKeyDates';
import { useBaseStore } from '@roc/feature-app-core';
import UpdateLogCard from '../cards/updateLogCard';
import { LoanOriginatorCard } from '../cards/loanOriginatorCard';
import { useRouteMatch } from 'react-router';
import { useLoanStore } from '@roc/feature-loans';

export const LoanSummaryStabilizedBridge = observer(({ }) => {
  const {
    showOriginatorsCard,
    allowLoanPricing,
    isTaskFrameworkEnabled,
    isLenderOriginator
  } = useLoanFlags();
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;
  const isServicerPortal = useRouteMatch('/servicer-portal');

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <LoanKeyDates />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <LoanDetailsCardStabilizedBridge />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <LoanSummaryTodosTasksCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={'flex'} flexDirection={'column'}>
              {!isServicerPortal && !isLenderOriginator && <Box flexGrow={1} style={{ marginBottom: 20 }}>
                <LoanOriginatorCard readOnly={!allowLoanPricing} />
              </Box>}
              <Box flexGrow={1} pt={showOriginatorsCard ? 2 : 0}>
                <BorrowerSummaryCard />
              </Box>
              {isTaskFrameworkEnabled && allowLoanPricing && <Box pt={2} flexGrow={1}>
                <UpdateLogCard loanId={loanStore.loanDetails.loanId}/>
              </Box>}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
