import { observer } from 'mobx-react';
import {
  StandardDialog, Button
} from '@roc/ui';
import { Grid } from '@material-ui/core';
import { Document } from '@roc/feature-types';
import { AppraisalReportForm } from './appraisalReportForm';



interface AppraisalReportDialogProps {
  open: boolean;
  onClose: () => void;
  loanTaskId: any;
  document: Document;
}

export const AppraisalReportDialog = (
  props: AppraisalReportDialogProps
) => {
  const { open, onClose, loanTaskId, document } = props;
  return (
    <StandardDialog
      open={open}
      title="Appraisal Report"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={<div style={{ marginTop: '-40px' }}><AppraisalReportForm open={true} handleClose={onClose} loanTaskId={loanTaskId} /></div>}
    />
  );
}

export default AppraisalReportDialog;
