import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { EditTaskDialog } from './editTask/editTaskDialog';
import { DialogState } from '@roc/ui';

export const SingleTaskView = observer(({ taskId, onClose }) => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const [dialogState, setDialogState] = useState<DialogState | null>(null);

  useEffect(() => {
    if (taskId && dialogState === null) {
      editTaskStore.openEditTask(taskId);
      setDialogState(DialogState.EDIT)
      window.parent.postMessage({ action: 'modal-ready' }, '*');
    }
  }, [taskId]);

  useEffect(() => {
    if (dialogState === DialogState.EDIT && editTaskStore.dialogState === null) {
      setDialogState(null);
      onClose();
    }
  }, [editTaskStore.dialogState]);

  if (!taskId) {
    return <></>;
  }
  return (
    <EditTaskDialog />
  );
});
