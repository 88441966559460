import { Layout } from '@roc/client-portal-shared/components';
import { Greeting } from '@roc/feature-app-core';
import { PortalBannerMessages } from 'libs/client-portal-shared/src/app/modules/dashboard/components/portalBannerMessages';
import { useExternalAgentRoutes } from '../hooks/useExternalAgentRoutes';

export const ExternalAgentHome = () => {
  const {
    titleAgentLoanRoutesConfig,
  } = useExternalAgentRoutes();

  return (
    <Layout>
      <PortalBannerMessages />
      <Greeting
        text={''}
        buttonLabel="GO TO FLORECLOSURE LOANS"
        buttonLink={titleAgentLoanRoutesConfig.loansHome.url}
      />
    </Layout>
  );
}