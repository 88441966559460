import { Box, TableCell, TableRow, Typography, Card, Grid } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowRight, KeyboardArrowDown, InsertDriveFileOutlined, DeleteOutline } from "@material-ui/icons";
import { DateFormat, formatDate, isNil } from "@roc/feature-utils";
import { IconButton } from "@roc/ui";
import { formatCurrency } from "@roc/ui/utils";
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import { StatusColumn } from "./statusColumn";
import { ActionsColumn } from "./actionsColumn";

const useStyles = makeStyles((theme: Theme) => ({
  noBorderCell: {
    border: 0,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  documentCard: {
    borderRadius: 0,
    padding: '8px',
    display: 'flex',
    backgroundColor: '#F5F5F5',
    height: '100%'
  },
  documentCardContainer: {
    padding: '8px',
  },
  documentName: {
    padding: '8px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  headerRow: {
    '& td': {
      padding: '8px'
    },
  },
  documentIconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cardIcon: {
    cursor: 'pointer'
  }
}));

export const InsuranceQuotesTableRow = observer(({ quote, index, store, expandAll = undefined, allowActions = false }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles({});

  useEffect(() => {
    if (!isNil(expandAll)) {
      setOpen(expandAll);
    }
  }, [expandAll])

  return (
    <Fragment key={quote?.insuranceQuoteDetailId}>
      <TableRow className={classes.headerRow}>
        <TableCell>
          <Box
            display={'flex'}
            alignItems={'center'}
            px={1}
            py={1}
            ml={0}
            onClick={() => setOpen(!open)}
            style={{
              cursor: 'pointer',
            }}
          >
            <Box component={'span'}>
              <IconButton
                testId={`expand-${quote?.insuranceQuoteDetailId}`}
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            </Box>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>{quote?.quoteShowId ?? `QUOTE-${index + 1}`}</Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <ul>
              {quote?.propertyCoverage && <li>Property</li>}
              {quote?.generalLiabilityCoverage && <li>Liability</li>}
              {quote?.floodCoverage && <li>Flood</li>}
              {!quote?.propertyCoverage && !quote?.generalLiabilityCoverage && !quote?.floodCoverage && '-'}
            </ul>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>
              {!isNil(quote?.insurancePremium) ? formatCurrency(quote?.insurancePremium) : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>
              {!isNil(quote?.paidAlready) ? formatCurrency(quote?.paidAlready) : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>
              {!isNil(quote?.remainingAmount) ? formatCurrency(quote?.remainingAmount) : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>
              {!isNil(quote?.insuranceEffectiveDate) ? formatDate(quote?.insuranceEffectiveDate, DateFormat.MMDDYYYY) : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <Typography>
              {!isNil(quote?.insuranceExpiryDate) ? formatDate(quote?.insuranceExpiryDate, DateFormat.MMDDYYYY) : '-'}
            </Typography>
          </Box>
        </TableCell>
        <TableCell >
          <Box>
            <StatusColumn
              showDropdown={false}
              status={quote?.status ?? 'In Review'}
            />
          </Box>
        </TableCell>
        {allowActions && <TableCell >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
            px={0}
            py={1}
            ml={0}
          >
            <ActionsColumn store={store} quote={quote} index={index} />
          </Box>
        </TableCell>}
      </TableRow>
      {open &&
        <>
          <TableRow>
            <TableCell className={classes.noBorderCell} />
            <TableCell className={classes.noBorderCell} colSpan={3}>
              <Typography className={classes.label}>
                Insurance Agent Name
              </Typography>
              <Typography>
                {quote?.insuranceAgentName ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Insurance Agent Email
              </Typography>
              <Typography>
                {quote?.insuranceAgentEmail ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Insurance Agent Phone
              </Typography>
              <Typography>
                {quote?.insuranceAgentPhone ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Policy Number
              </Typography>
              <Typography>
                {quote?.policyNumber ?? '-'}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noBorderCell} />
            <TableCell className={classes.noBorderCell} colSpan={4}>
              <Typography className={classes.label}>
                Insurance Agent Address
              </Typography>
              <Typography>
                {quote?.insuranceAgentAddress ?? '-'}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noBorderCell} />
            <TableCell className={classes.noBorderCell} colSpan={3}>
              <Typography className={classes.label}>
                Payment Type
              </Typography>
              <Typography>
                {quote?.paymentType ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Carrier
              </Typography>
              <Typography>
                {quote?.carrier ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Carrier NAIC
              </Typography>
              <Typography>
                {quote?.carrierNaic ?? '-'}
              </Typography>
            </TableCell>
            <TableCell className={classes.noBorderCell} colSpan={2}>
              <Typography className={classes.label}>
                Coverage Amount
              </Typography>
              <Typography>
                {!isNil(quote?.coverageAmount) ? formatCurrency(quote?.coverageAmount) : '-'}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noBorderCell} />
            <TableCell colSpan={9} className={classes.noBorderCell}>
              <Typography className={classes.label}>
                Documents
              </Typography>
              {quote?.documents?.length > 0 ?
                <Grid container spacing={2} className={classes.documentCardContainer}>
                  {quote?.documents?.map(document => (
                    <Grid item xs={3}>
                      <Card className={classes.documentCard}>
                        <Grid container spacing={2}>
                          <Grid item xs={2} className={classes.documentIconContainer}>
                            <InsertDriveFileOutlined className={classes.cardIcon} onClick={() => store.downloadInsuranceQuoteDocument(document)} />
                          </Grid>
                          <Grid item xs={8} className={classes.documentName}>
                            <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>{document?.name}</Typography>
                          </Grid>
                          {(quote?.status != 'Approved' || !quote?.selectedQuote) && allowActions && <Grid item xs={2} className={classes.documentIconContainer}>
                            <DeleteOutline className={classes.cardIcon} onClick={() => store.deleteQuoteDocument(document?.insuranceQuoteDocumentId)} />
                          </Grid>}
                        </Grid>
                      </Card>
                    </Grid>))}
                </Grid>
                :
                <p>No insurance documents have been added yet</p>
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell colSpan={9}>
              <Typography className={classes.label}>
                Properties
              </Typography>
              <ul>
                {quote?.properties?.length > 0 ?
                  store?.propertiesOptions?.map(propertyOption => {
                    if (quote?.properties?.includes(propertyOption?.value)) {
                      return (<li>{propertyOption?.label}</li>);
                    }
                    else {
                      return null;
                    }
                  })
                  :
                  <li>No properties have been selected yet</li>
                }
              </ul>
            </TableCell>
          </TableRow>
        </>
      }
    </Fragment>
  )
});