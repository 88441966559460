import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  value: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  label: {
    fontSize: "0.75rem",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: "#939494",
  },
}));
export const ItemDisplay = ({
  label,
  value = "-",
  children,
}: {
  label: string;
  value?: string;
  children?: any;
}) => {
  const classes = useStyles();

  return (
    <Box>
      {children && children}
      {!children && <Typography className={classes.value}>{value}</Typography>}
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};
