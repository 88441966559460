import { Document } from '@roc/feature-types';
import { Button, PrintableAreaRef, StandardDialog } from '@roc/ui';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useEffect, useRef } from 'react';
import { TermSheetHtml } from './termSheetHtml';
import { TermSheet } from './termSheet';
import PrintIcon from '@material-ui/icons/Print';
import { useDocumentStore } from '../../../../hooks/useDocumentStore';
import { observer } from 'mobx-react';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { useOneToolStore } from 'libs/feature-one-tool/src/quote/hooks/useOneToolStore';
import { DownloadableQuote, DownloadableTermSheet } from 'libs/feature-one-tool/src/quote/steps/summary/components/downloadableQuote';
import { QUOTE_LOANS } from 'libs/feature-documents/src/documents/constants';

const DOCUMENT_NAME = 'Term Sheet.pdf';

export type TermSheetDialogProps = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const TermSheetDialogExternal = observer(
  (props: TermSheetDialogProps) => {
    const { open, onClose, loan, document } = props;
    const termSheetRef = useRef<PrintableAreaRef>(null);
    const { documentStore } = useDocumentStore();
    const { termSheetStore } = documentStore.documentFormStore;
    const { termSheetData } = termSheetStore;
    const renderNewTermSheet =
      loan?.loanType !== LoanType.RESIDENTIAL_TERM &&
      loan?.loanSubType !== LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;

    useEffect(() => {
      if (open && renderNewTermSheet) {
        if (loan.loanId) {
          termSheetStore.fetchTermSheetData(loan.loanId);
        } else {
          termSheetStore.fetchTermSheetDataForBridgeSubmission(loan);
        }
      }
    }, [open]);

    const downloadTermSheet = () => {
      termSheetRef.current.downloadAsPdf(DOCUMENT_NAME);
    };

    const printTermSheet = async () => {
      const url = await termSheetRef.current.getPdfBlobUrl();
      const printWindow = window.open(url.toString(), '_blank');
      printWindow?.print();
    };

    const dialogActions = (
      <>
        <Button
          style={{ marginRight: '8px' }}
          testId="print-pdf"
          color="default"
          variant="contained"
          onClick={printTermSheet}
          startIcon={<PrintIcon />}
        >
          Print
        </Button>
        <Button
          testId="download-pdf"
          color="primary"
          variant="contained"
          startIcon={<GetAppIcon />}
          onClick={downloadTermSheet}
        >
          Download
        </Button>
      </>
    );

    return (
      <StandardDialog
        open={open}
        title="Term Sheet"
        maxWidth="md"
        handleClose={onClose}
        dialogActions={renderNewTermSheet && dialogActions}
        dialogContent={
          renderNewTermSheet ? (
            termSheetData &&
            (QUOTE_LOANS.includes(termSheetData?.loanProgram) ? (
              <DownloadableTermSheet ref={termSheetRef} termSheetData={termSheetData} loan={loan} />
            ) : (
              <TermSheetHtml ref={termSheetRef} data={termSheetData} />
            ))
          ) : (
            <TermSheet
              document={document}
              downloadTermSheet={() => {
                termSheetStore.downloadTermSheet(document);
              }}
            />
          )
        }
      />
    );
  }
);
