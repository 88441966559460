/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { InternalValuationReviewStore } from "./stores/internalValuationReviewStore";
import { observer } from "mobx-react";
import { useEffect } from 'react';
import { autorun } from "mobx";
import { InternalValuationReview } from "./internalValuationReview";

interface InternalValuationReviewIframeProps {
  internalValuationReviewStore: InternalValuationReviewStore;
  loanId: number;
  propertyId: number;
  readOnly?: boolean;
}

//This component is used as a wrapper to post this message when used as iframe from nportal
export const InternalValuationReviewIframe = observer(({ loanId, propertyId, readOnly = false, internalValuationReviewStore }: InternalValuationReviewIframeProps) => {
  useEffect(() => {
    const disposer = autorun(() => {
      window.parent.postMessage(
        { type: "IS_FORM_DIRTY", data: internalValuationReviewStore.isDirty },
        "*"
      );
    });

    return () => disposer();
  }, []);


  return <InternalValuationReview loanId={loanId} propertyId={propertyId} readOnly={readOnly} internalValuationReviewStore={internalValuationReviewStore} />
});
