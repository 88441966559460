import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { CloudDownload, Create, InsertDriveFile, MoreVert } from '@material-ui/icons';
import { useDocumentStore } from '@roc/feature-documents';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  DataGrid,
  MenuItem,
  StandardDialog
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useDrawsStore } from '../../hooks';
import SOWRevisionRequestDialog from './../components/SOWRevisionRequestDialog';

const noOpComparator = () => 0;

const sowColumns: AgGridColumnProps[] = [
  {
    field: 'taskName',
    headerName: 'Document Name',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'collateralAddress',
    headerName: 'Property Address',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'lastUpdateDate',
    headerName: 'Update Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

export const SOWGrid = observer(
  ({ scopeOfWorkList }: { scopeOfWorkList: any[] }) => {
    const { scopeOfWorkRevisionStore } = useDrawsStore();
    const { documentStore } = useDocumentStore();
    const { scopeOfWorkV2Store } = useScopeOfWorkV2Store();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [sowMenuOpen, setSowMenuOpen] = useState(false);
    const [openSOWRevisionRequestDialog, setOpenSOWRevisionRequestDialog] = useState(false);

    const sowFrameworkComponents = {
      dateCellRenderer: ({ value }) => formatDate(value, 'MM/dd/yyyy') ?? null,
      actionsCellRenderer: params => {
        return (
          <IconButton onClick={e => openSowMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      },
    };

    const openSowMenu = (event: React.MouseEvent<HTMLElement>, record: any) => {
      setSelectedDocument(record);
      setAnchorEl(event.currentTarget);
      setSowMenuOpen(true);
    };

    const handleSowMenuClose = () => {
      setSelectedDocument(null);
      setAnchorEl(null);
      setSowMenuOpen(false);
    };

    const startRevision = (record: any) => {
      setOpenSOWRevisionRequestDialog(true);
    };

    if (!scopeOfWorkList) {
      return <></>;
    };

    return (
      <>
        <DataGrid
          columns={sowColumns}
          rows={scopeOfWorkList}
          frameworkComponents={sowFrameworkComponents}
          domLayout="autoHeight"
        />
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={sowMenuOpen}
          onClose={handleSowMenuClose}
        >
          <div>
            {selectedDocument && !selectedDocument.dropboxPath &&
              <MenuItem
                testId={`view-sow-document`}
                onClick={() => scopeOfWorkV2Store.openScopeOfWork(selectedDocument)}
              >
                <ListItemIcon>
                  <InsertDriveFile fontSize="small" />
                </ListItemIcon>
                <ListItemText>View Document</ListItemText>
              </MenuItem>
            }
            {selectedDocument && selectedDocument.dropboxPath &&
              <MenuItem
                testId={`download-sow-document`}
                onClick={() => documentStore.downloadDocument(selectedDocument)}
              >
                <ListItemIcon>
                  <CloudDownload fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download Document</ListItemText>
              </MenuItem>
            }
          </div>
          <div>
            <MenuItem
              testId={`start-revision-request`}
              onClick={() => startRevision(selectedDocument)}
            >
              <ListItemIcon>
                <Create fontSize="small" />
              </ListItemIcon>
              <ListItemText>Revise Scope of Work</ListItemText>
            </MenuItem>
          </div>
        </Menu>
        <StandardDialog
          open={openSOWRevisionRequestDialog}
          title="SOW Revision Request"
          maxWidth="sm"
          handleClose={() => setOpenSOWRevisionRequestDialog(false)}
          dialogContent={
            <SOWRevisionRequestDialog
              onClose={() => setOpenSOWRevisionRequestDialog(false)}
              onContinue={() => {
                scopeOfWorkRevisionStore.submitSOWRevision(selectedDocument);
                setOpenSOWRevisionRequestDialog(false);
              }}
            />
          }
        />
      </>
    );
  }
);