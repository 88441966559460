import { GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { InsuranceInformationReviewInternalService } from "../services/insuranceInformationReviewInternalService";
import { FileUpload } from "@roc/ui";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";
import { downloadDocument } from "@roc/feature-utils";

export class FloodZoneStore {
  private globalStore: GlobalStore;
  public selectedProperty: any;
  public floodCertFiles: FileUpload[];
  private insuranceInformationReviewService: InsuranceInformationReviewInternalService;
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.insuranceInformationReviewService = new InsuranceInformationReviewInternalService();
    this.floodCertFiles = [];
    makeObservable(this, {
      setProperty: action,
      saveFloodExceptionToProperty: flow,
      setFloodOverrideReason: action,
      selectedProperty: observable,
      saveFloodCert: flow,
      floodCertFiles: observable,
      downloadFloodCert: flow
    })
  }

  setProperty(property: any) {
    this.selectedProperty = property;
    this.floodCertFiles = [];
  }

  setFloodZone(floodZone: any) {
    this.selectedProperty.floodZone = floodZone;
  }

  setFloodCertFiles(floodCertFile: FileUpload[]) {
    this.floodCertFiles = floodCertFile;
  }

  setFloodOverrideReason(floodOverrideReason: string) {
    this.selectedProperty.floodOverrideReason = floodOverrideReason;
  }

  *saveFloodExceptionToProperty(callback: () => void) {
    try {
      if (!this.selectedProperty.floodOverrideReason) {
        this.globalStore.notificationStore.showWarningNotification({
          message: 'Please fill the required information',
        });
        return;
      }
      const data = new FormData();
      data.append('form_information', JSON.stringify(this.selectedProperty));
      yield this.insuranceInformationReviewService.saveLoanProperty(data);
      callback();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving flood exception',
      });
    }
  }

  *saveFloodCert(callback: () => void) {
    try {
      if (!this.selectedProperty.floodZone || this.floodCertFiles.length == 0) {
        this.globalStore.notificationStore.showWarningNotification({
          message: 'Please fill the required information',
        });
        return;
      }
      const data = new FormData();

      const blob = this.floodCertFiles[0].file.slice(0, this.floodCertFiles[0].file.size, this.floodCertFiles[0].file.type);
      const file = new File([blob], this.floodCertFiles[0].name, { type: this.floodCertFiles[0].file.type });
      data.append('file', file);

      data.append('loanId', this.selectedProperty.loanId);
      data.append('propertyId', this.selectedProperty.propertyId);
      data.append('status', this.selectedProperty.floodZone);

      yield this.insuranceInformationReviewService.saveFloodCert(data);
      callback();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving flood exception',
      });
    }
  }

  *downloadFloodCert(property: any) {
    try {
      const response = yield this.insuranceInformationReviewService.downloadFloodCert(property.propertyId);
      downloadDocument(response.data, response.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}