import { SubmitButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { ElmsureForm } from '@roc/feature-insurance-review';
import { useEffect } from 'react';

export const RequestElmsureQuote = observer(({ loanStore }) => {
  const { insuranceInformationStore } = useStore();

  useEffect(() => {
    insuranceInformationStore.init(loanStore.loanDetails?.loanId, null);
  }, []);

  return (
    <SubmitButton
      size="small"
      variant="contained"
      color="primary"
      testId="requestElmsureQuote"
      disabled={insuranceInformationStore.quotes.some(quote => quote.quoteShowId.includes('Elmsure'))}
      onClick={() => insuranceInformationStore.requestQuoteFromElmsure()}
      submitDialogProps={{
        title: `Request Elmsure Quote`,
        submitValidation: () => insuranceInformationStore.validateElmsureQuoteSubmission(),
        body: <ElmsureForm store={insuranceInformationStore} />,
        okButtonText: 'Submit',
        cancelButtonText: 'Close',
      }}
    >
      REQUEST ELMSURE QUOTE
    </SubmitButton>
  );
});
