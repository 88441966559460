import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { FilterType, MyChatContainer, SelectConversation, useLoanCommunicationStore, useTechSupportCommunicationStore } from '@roc/feature-communication';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';

const useStyles = makeStyles((theme: Theme) => ({
  chatContainer: {
    flex: 1,
  },
  container: {
    marginTop: '15px',
  },
}));

export const SupportConversations = observer(() => {
  const classes = useStyles();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { generalTasksStore } = useGeneralTasksStore();
  const { supportTasksStore } = generalTasksStore;

  useEffect(() => {
    supportTasksStore.fetchUnreadMessagesCount();
  }, [loanCommunicationStore.currentConversationSid]);

  return (
    <>
      <Grid item xs={12} md={12} className={classes.container}>
        <Box display="flex" height={'75vh'} >
          <MyChatContainer
            communicationStore={loanCommunicationStore}
            allowNewConversations={false}
            allowJoinConversation={true}
            showEmailAddress={false}
            showSnippet={false}
            className={classes.chatContainer}
            allowRemoveParticipants={true}
            allowManageParticipants={true}
            filterType={FilterType.TASK_SUPPORT}
            allowAddInternalParticipants={true}
            onConversationSelect={conversationId => {
              loanCommunicationStore?.setCurrentConversationId(conversationId)
            }
            }
          />
          <SelectConversation communicationStore={loanCommunicationStore} />
        </Box>
      </Grid>
    </>
  );
}
);
