import { filterProps } from '@roc/ui/utils';
import { GridColumnProps } from '@roc/ui';

export const pendingQuoteDashboardColumns: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    cellRenderer: 'loanIdCellRenderer',
    width: 90,
    ...filterProps(),
  },
  {
    field: 'lenderName',
    headerName: 'TPO',
    width: 90,
    ...filterProps(),
  },
  {
    field: 'insuranceReviewer',
    headerName: 'Insurance Reviewer',
    minWidth: 200,
    ...filterProps(),
  },
  {
    field: 'uploadedDate',
    headerName: 'Uploaded Date',
    minWidth: 125,
    ...filterProps(),
  },
  {
    field: 'hoursInReview',
    headerName: 'Hours Ellapsed',
    maxWidth: 150,
    ...filterProps(),
  }
];

export const resolutionDashboardColumns: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    cellRenderer: 'loanIdCellRenderer',
    width: 90,
    ...filterProps(),
  },
  {
    field: 'quoteShowId',
    headerName: 'Quote',
    minWidth: 125,
    ...filterProps(),
  },
  {
    field: 'resolutionName',
    headerName: 'Resolution',
    minWidth: 300,
    ...filterProps(),
  },
  {
    field: 'lenderName',
    headerName: 'TPO',
    width: 90,
    ...filterProps(),
  },
  {
    field: 'uploadedDate',
    headerName: 'Uploaded Date',
    minWidth: 125,
    ...filterProps(),
  },
  {
    field: 'hoursInReview',
    headerName: 'Hours Ellapsed',
    maxWidth: 150,
    ...filterProps(),
  }
];
export enum QUOTE_STATUS {
  APPROVED = 'Approved',
  MORE_INFO_NEEDED = 'More Info Needed'
}
