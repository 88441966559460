import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useStore } from '@roc/client-portal-shared/hooks';
import GmailIcon from './gmailIcon';
import {
  Box,
  Typography,
  Chip} from '@material-ui/core';
import { Paper, Button } from '@roc/ui';
import { CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: '10px',
    minWidth: '150px',
  },
  notAuthtenticatedButton: {
    margin: '10px',
    minWidth: '150px',
    backgroundColor: 'red',
    '&:hover': {
    backgroundColor: 'red',
  },
  },
}));

const GmailOauthCard =  observer( () => {
  const { userStore , gmailStore } = useStore();
  const classes = useStyles();

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose',
    onSuccess: async (authCodeResponse) => {
      gmailStore.exchangeAuthCodeForToken({
        token: authCodeResponse.code,
        userId: userStore.userInformation.userId,
      })
    },
    onError: () => console.error('Login Failed'),
  });

  return (
      <>
      <Paper className={classes.paper} style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
        <Box display="flex" alignItems="center" mb={2}>
          <GmailIcon width='15%'/>
          <Box flexGrow={1} ml={2}>
            <Typography variant="h6">
              {'Gmail Connection'}
            </Typography>
            <Typography variant="body2">
              {'Connect your Gmail account to send emails from Portal'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Box mr={2}>
          {
            (userStore.userInformation.connectedWithGmail || gmailStore.success) ? (
              <Chip
               icon={<CheckCircle style={{
                          color: 'white'
                        }} />}
                label="CONNECTED"
                style={{
                  backgroundColor: '#008f39',
                  letterSpacing: 2,
                  color: '#FFFFFF',
                  fontWeight: 'bold'
                }}
              />
            ) : (
              <Chip
                label="NOT CONNECTED"
                style={{
                  backgroundColor: '#b3b3b3',
                  letterSpacing: 2,
                  color: '#777',
                  fontWeight: 'bold'
                }}
              />
            )
          }
          </Box>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            testId="Authenticate_With_Gmail"
            onClick={() => login()}
          >
            Connect Gmail
          </Button>
        </Box>
      </Paper>
    </>
  );
});

export const GmailOauth = observer(() => {
  const { gmailStore } = useStore();

  useEffect(() => {

  }, [gmailStore]);

  return (
        <GoogleOAuthProvider clientId={gmailStore.gmailCreds?.clientId}>
          <GmailOauthCard />
        </GoogleOAuthProvider>
  );
});
