import { REFERRAL_INFORMATION } from "@roc/feature-documents";

const RATE_TYPE_5 = '5/6 ARM';

export const PortalContextPath = {
  nPortal: 'nportal',
  lenderPortal: 'lender-portal',
  borrowerPortal: 'borrower-portal',
  attorneyPortal: 'attorney-portal',
  closingReviewerPortal: 'closing-reviewer-portal',
  insuranceReviewerPortal: 'insurance-reviewer-portal',
  salesforcePortal: 'salesforce-portal',
  salesforceUi: 'salesforce-ui',
  elmsureWeb: 'elmsure-web',
  brokerPortal: 'broker-portal',
  auctionPortal: 'auction-portal',
  rocInternalPortal: 'internal-portal',
  assetManagementPortal: 'asset-management-portal',
  messagePortal: 'message-portal',
  servicerPortal: 'servicer-portal',
  vendorPortal: 'vendor-portal',
  mobileUi: 'mobile-ui',
  chatUi: 'chat-ui',
  adminPortal: 'admin-portal',
  crmPortal: 'crm-portal',
};

export const noneSelected = {
  label: '-- Select --',
  value: 'NONE',
};

export const stateMapping = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'Washington D.C.',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];

export const statesAbbreviation = [
  '',
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const borrowerStatesAbbreviation = [
  '',
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'PR',
];

export const stateNormalizerJson = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'Washington D.C.': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const requiredLicenseStates = ['AZ', 'NV'];

export const nonReferralFeeStates = ['AZ', 'ID', 'MN', 'NV', 'OR', 'UT'];

export enum StateNames {
  NEVADA = 'NV'
}

export const statesNames = [
  '--select--',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Washington D.C.',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
export const borrowerStatesNames = [
  '--select--',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Washington D.C.',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
];
export const notLicensedinState = {
  states: ['OR', 'UT', 'MN', 'ND', 'SD', 'VT'],
  message:
    'Capital provider not currently licensed in OR, UT, MN, ND, SD, or VT',
};
export const notLicensedSfr = {
  states: ['OR', 'UT', 'MN', 'ND', 'SD', 'VT'],
  message:
    'Capital provider not currently licensed in OR, UT, MN, ND, SD, or VT',
};

export const AllowedWimba = [
  'NY',
  'NJ',
  'FL',
  'DC',
  'IL',
  'IN',
  'MD',
  'PA',
  'VA',
  'CO',
  'DE',
  'GA',
  'NH',
  'NC',
  'MA',
  'TN',
  'DE',
  'CT',
];
export const borrowerExpDictionary = {
  '0': 0,
  '1-2': 1,
  '3-4': 2,
  '5-9': 3,
  '10-49': 4,
  '50+': 5,
};

export const borrowerExpMapping = {
  '0': '0',
  '1': '1-2',
  '2': '3-4',
  '3': '5-9',
  '4': '10-49',
  '5': '50+',
};

export const BRIDGE = 'bridge';
export const TERM = 'term';
export const UNDERWRITING = 'underwriting';
export const CLOSING = 'closing';
export const DRAWS = 'draws';
export const EXTENSIONS = 'Extensions';
export const SERVICING = 'servicing';
export const TRADES = 'trades';
export const SINGLE_FAMILY = 'Single Family';
export const CONDOMINIUM = 'Condominium';
export const MIXED_USE = 'Mixed Use';
export const MULTIFAMILY_5_PLUS = '5Plus Unit Multifamily';
export const PURCHASE = 'Purchase';
export const REFINANCE = 'Refinance';
export const RATE_TERM_REFINANCE = 'Refi';
export const CASHOUT_REFINACE = 'Cash Out Refi';
export const PROPOSAL = 'Proposal';
export const DUPLEX = 'Duplex';
export const TRIPLEX = 'Triplex';
export const QUADRUPLEX = 'Quadruplex';
export const TOWNHOME = 'Townhome';
export const PLANNEDUNIT = 'Planned Unit Development';

export const GENERIC_ERROR_MESSAGE =
  'Oops, something went wrong. Please try again later.';

export const statePropertyTypeRestriction = {
  states: ['FL', 'Florida'],
  propertyTypes: [CONDOMINIUM],
  message:
    'Please be aware that due to recent market changes, we are not lending on condos in Florida at this time. Please speak to your relationship manager if you have any questions.',
};

export const loanPurpose = [
  { value: 'Purchase', label: 'Purchase' },
  { value: REFINANCE, label: REFINANCE },
];

export const loanRecourse = [
  { value: 'Full Recourse', label: 'Full Recourse' },
  { value: 'Non Recourse', label: 'Non Recourse' },
];
export const FULL_RECOURSE = 'Full Recourse';
export const NON_RECOURSE = 'Non Recourse';

export const propertyStates = [
  {
    label: 'Alabama',
    value: 'Alabama',
    id: 'AL',
  },
  {
    label: 'Alaska',
    value: 'Alaska',
    id: 'AK',
  },
  {
    label: 'Arizona',
    value: 'Arizona',
    id: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
    id: 'AR',
  },
  {
    label: 'California',
    value: 'California',
    id: 'CA',
  },
  {
    label: 'Colorado',
    value: 'Colorado',
    id: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
    id: 'CT',
  },
  {
    label: 'Delaware',
    value: 'Delaware',
    id: 'DE',
  },
  {
    label: 'Washington D.C.',
    value: 'Washington D.C.',
    id: 'DC',
  },
  {
    label: 'Florida',
    value: 'Florida',
    id: 'FL',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    id: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
    id: 'HI',
  },
  {
    label: 'Idaho',
    value: 'Idaho',
    id: 'ID',
  },
  {
    label: 'Illinois',
    value: 'Illinois',
    id: 'IL',
  },
  {
    label: 'Indiana',
    value: 'Indiana',
    id: 'IN',
  },
  {
    label: 'Iowa',
    value: 'Iowa',
    id: 'IA',
  },
  {
    label: 'Kansas',
    value: 'Kansas',
    id: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
    id: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
    id: 'LA',
  },
  {
    label: 'Maine',
    value: 'Maine',
    id: 'ME',
  },
  {
    label: 'Maryland',
    value: 'Maryland',
    id: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
    id: 'MA',
  },
  {
    label: 'Michigan',
    value: 'Michigan',
    id: 'MI',
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
    id: 'MS',
  },
  {
    label: 'Missouri',
    value: 'Missouri',
    id: 'MO',
  },
  {
    label: 'Montana',
    value: 'Montana',
    id: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
    id: 'NE',
  },
  {
    label: 'Nevada',
    value: 'Nevada',
    id: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
    id: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
    id: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
    id: 'NM',
  },
  {
    label: 'New York',
    value: 'New York',
    id: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
    id: 'NC',
  },
  {
    label: 'Ohio',
    value: 'Ohio',
    id: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
    id: 'OK',
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
    id: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',
    id: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
    id: 'SC',
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
    id: 'TN',
  },
  {
    label: 'Texas',
    value: 'Texas',
    id: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
    id: 'UT',
  },
  {
    label: 'Virginia',
    value: 'Virginia',
    id: 'VA',
  },
  {
    label: 'Washington',
    value: 'Washington',
    id: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
    id: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
    id: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
    id: 'WY',
  },
];
export const sfrPortfolioPropertyManagement = [
  { value: 'Self Managed', label: 'Self Managed' },
  { value: 'Third Party Management', label: 'Third Party Management' },
];
export const tapeFilePath =
  '/tech/Non Editable Documents/Portfolio Loan Submission Template.xlsx';

export const wimbaFilePath = '/tech/Non Editable Documents/Wimba AfBD Form.pdf';

export const trackRecordTemplateFilePath =
  '/tech/Non Editable Documents/Track Record Template.xlsx';

export const ELMSURE = 'Elmsure';
export const OTHER = 'Other';
export const NONE = 'None';

export const insuranceOptions = [
  { label: ELMSURE, value: ELMSURE },
  { label: OTHER, value: OTHER },
];

export const WIMBA = 'Wimba';
export const titleOptionsAllowedWimba = [
  { value: WIMBA, label: WIMBA },
  { value: OTHER, label: OTHER },
  { value: '', label: NONE },
];

export const titleOptionsQuote = [
  { value: NONE, label: NONE },
  { value: OTHER, label: OTHER },
];

export const titleOptions = [
  { value: '', label: NONE },
  { value: OTHER, label: OTHER },
];

export const UNKNOWN = 'Unknown';

export enum LoanType {
  RESIDENTIAL_BRIDGE = 'Residential Bridge',
  RESIDENTIAL_TERM = 'Residential Term',
}

export enum LoanSubType {
  FIX_AND_FLIP = 'Fix and Flip (1-4)',
  FIX_AND_FLIP_PRO = 'Fix and Flip Pro (1-4)',
  GROUND_UP = 'Groundup (1-4)',
  MULTIFAMILY_BRIDGE_5PLUS = 'Multifamily Bridge (5+)',
  SINGLE_PROPERTY = 'Single Property Rental',
  SINGLE_PROPERTY_MULTIFAMILY = 'Single Property Multifamily Term',
  SINGLE_PROPERTY_MIXED_USE = 'Single Property Mixed Use',
  RENTAL_PORTFOLIO = 'Rental Portfolios',
  MULTIFAMILY_TERM_PORTFOLIO = 'Multifamily Term Portfolios',
  MIXED_USE_PORTFOLIO = 'Mixed Use Portfolios',
  STABILIZED_BRIDGE = 'Stabilized Bridge',
}

export const bridgeLoanSubTypes = [
  LoanSubType.FIX_AND_FLIP,
  LoanSubType.FIX_AND_FLIP_PRO,
  LoanSubType.GROUND_UP,
  LoanSubType.MULTIFAMILY_BRIDGE_5PLUS,
  LoanSubType.STABILIZED_BRIDGE,
];

export const termLoanSubTypes = [
  LoanSubType.SINGLE_PROPERTY,
  LoanSubType.RENTAL_PORTFOLIO,
];

export const multifamilyLoanSubtypes = [
  LoanSubType.MULTIFAMILY_BRIDGE_5PLUS,
  LoanSubType.SINGLE_PROPERTY_MULTIFAMILY,
  LoanSubType.MULTIFAMILY_TERM_PORTFOLIO,
];

//TODO: remove this, use util
export const propertyTypes = [
  { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
  { value: 'Duplex', label: 'Duplex' },
  { value: 'Triplex', label: 'Triplex' },
  { value: 'Quadruplex', label: 'Quadruplex' },
  { value: 'Condominium', label: 'Warrantable Condominium' },
  { value: 'Townhome', label: 'Townhome' },
  { value: 'Planned Unit Development', label: 'Planned Unit Development' },
  { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
  { value: MIXED_USE, label: MIXED_USE },
];

export const PREPAYMENT_STARTING_5 = '5-4-3-2-1%';
export const PREPAYMENT_STARTING_4 = '4-3-2-1%';
export const PREPAYMENT_STARTING_3 = '3-2-1%';
export const PREPAYMENT_3_0_0 = '3-0-0%';
export const PREPAYMENT_1_0_0 = '1-0-0%';

export const PPP_4_3_2_1 = '4-3-2-1';
export const PPP_5_5_5_5_5_0 = '5-5-5-5-5-0';
export const PPP_5_5_5_5_0 = '5-5-5-5-0';
export const PPP_5_4_3_2_1 = '5-4-3-2-1';
export const PPP_3_2_1 = '3-2-1';
export const PPP_YIELD_MAINTENANCE = 'Yield Maintenance';
export const PPP_3_2_0 = '3-2-0';
export const PPP_3_0_0 = '3-0-0';
export const PPP_1_0_0 = '1-0-0';
export const PPP_0_NO_PREPAY = '0';

export const PPP_3_YEAR_LABEL = '3 year (3 - 2 - 1)';
export const PPP_4_YEAR_LABEL = '4 year (4 - 3 - 2 - 1)';
export const PPP_5_YEAR_LABEL = '5 year (5 - 4 - 3 - 2 - 1)';
export const PPP_5_5_5_5_5_0_LABEL = '5 year (5 - 5 - 5 - 5 - 5 - 0)';
export const PPP_5_5_5_5_0_LABEL = '5 year (5 - 5 - 5 - 5 - 0)';
export const PPP_5_4_3_2_1_LABEL = '5 year (5 - 4 - 3 - 2 - 1)';
export const PPP_3_2_0_LABEL = '2 year (3 - 2 - 0)';
export const PPP_3_0_0_LABEL = '1 year (3 - 0 - 0)';
export const PPP_1_0_0_LABEL = '1 year (1 - 0 - 0)';
export const PPP_0_NO_PREPAY_LABEL = '0 year (No prepay penalty)';

export const PPP_3_YEAR_NPORTAL = '3-2-1%';
export const PPP_4_YEAR_NPORTAL = '4-3-2-1%';
export const PPP_5_YEAR_NPORTAL = '5-4-3-2-1%';

export const prePaymentPenaltyOptionsLoanData = [
  { value: PREPAYMENT_STARTING_3, label: PPP_3_YEAR_LABEL },
  { value: PREPAYMENT_STARTING_4, label: PPP_4_YEAR_LABEL },
  { value: PREPAYMENT_STARTING_5, label: PPP_5_YEAR_LABEL },
  { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  { value: PREPAYMENT_3_0_0, label: PPP_3_0_0_LABEL },
  { value: PREPAYMENT_1_0_0, label: PPP_1_0_0_LABEL },
];

export const sfrPPPMapping = {
  [PPP_3_2_1]: {
    label: PPP_3_YEAR_LABEL,
    value: PPP_3_2_1,
    nValue: PPP_3_YEAR_NPORTAL,
  },
  [PPP_4_3_2_1]: {
    label: PPP_4_YEAR_LABEL,
    value: PPP_4_3_2_1,
    nValue: PPP_4_YEAR_NPORTAL,
  },
  [PPP_5_4_3_2_1]: {
    label: PPP_5_YEAR_LABEL,
    value: PPP_5_4_3_2_1,
    nValue: PPP_5_YEAR_NPORTAL,
  },
  [PPP_5_5_5_5_5_0]: {
    label: PPP_5_5_5_5_5_0,
    value: PPP_5_5_5_5_5_0,
    nValue: PPP_5_5_5_5_5_0 + '%',
  },
  [PPP_5_5_5_5_0]: {
    label: PPP_5_5_5_5_0,
    value: PPP_5_5_5_5_0,
    nValue: PPP_5_5_5_5_0 + '%',
  },
  [PPP_YIELD_MAINTENANCE]: {
    label: PPP_YIELD_MAINTENANCE,
    value: PPP_YIELD_MAINTENANCE,
    nValue: PPP_YIELD_MAINTENANCE,
  },
  [PPP_3_2_0]: {
    label: PPP_3_2_0_LABEL,
    value: PPP_3_2_0,
    nValue: PPP_3_2_0 + '%',
  },
  [PPP_3_0_0]: {
    label: PPP_3_0_0_LABEL,
    value: PPP_3_0_0,
    nValue: PPP_3_0_0 + '%',
  },
  [PPP_1_0_0]: {
    label: PPP_1_0_0_LABEL,
    value: PPP_1_0_0,
    nValue: PPP_1_0_0 + '%',
  },
  [PPP_0_NO_PREPAY]: {
    label: PPP_0_NO_PREPAY_LABEL,
    value: PPP_0_NO_PREPAY,
    nValue: PPP_0_NO_PREPAY + '%',
  }
};

export const prePaymentPenaltyOptionsSingleProperty = [
  { value: PPP_3_2_1, label: PPP_3_YEAR_LABEL },
  { value: PPP_4_3_2_1, label: PPP_4_YEAR_LABEL },
  { value: PPP_5_4_3_2_1, label: PPP_5_YEAR_LABEL },
  { value: PPP_5_5_5_5_5_0, label: PPP_5_5_5_5_5_0_LABEL },
  { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  { value: PPP_3_0_0, label: PPP_3_0_0_LABEL },
  { value: PPP_1_0_0, label: PPP_1_0_0_LABEL },
  { value: PPP_0_NO_PREPAY, label: PPP_0_NO_PREPAY_LABEL },
];

export const prePaymentPenaltyOptionsPortfolio = {
  '5': [
    { value: PPP_5_4_3_2_1, label: PPP_5_4_3_2_1_LABEL },
    { value: PPP_5_5_5_5_0, label: PPP_5_5_5_5_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  ],
  '10': [
    { value: PPP_5_4_3_2_1, label: PPP_5_4_3_2_1_LABEL },
    { value: PPP_5_5_5_5_5_0, label: PPP_5_5_5_5_5_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  ],
  '30': [
    { value: PPP_3_2_1, label: PPP_3_YEAR_LABEL },
    { value: PPP_4_3_2_1, label: PPP_4_YEAR_LABEL },
    { value: PPP_5_4_3_2_1, label: PPP_5_YEAR_LABEL },
    { value: PPP_5_5_5_5_5_0, label: PPP_5_5_5_5_5_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
    { value: PPP_3_0_0, label: PPP_3_0_0_LABEL },
    { value: PPP_1_0_0, label: PPP_1_0_0_LABEL },
    { value: PPP_0_NO_PREPAY, label: PPP_0_NO_PREPAY_LABEL },
  ],
};

export const prePaymentPenaltyOptionsMultifamilyMixedUse = {
  '5': [
    { value: PPP_5_4_3_2_1, label: PPP_5_4_3_2_1_LABEL },
    { value: PPP_5_5_5_5_0, label: PPP_5_5_5_5_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  ],
  '10': [
    { value: PPP_5_4_3_2_1, label: PPP_5_4_3_2_1_LABEL },
    { value: PPP_5_5_5_5_5_0, label: PPP_5_5_5_5_5_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
  ],
  '30': [
    { value: PPP_5_4_3_2_1, label: PPP_5_YEAR_LABEL },
    { value: PPP_4_3_2_1, label: PPP_4_YEAR_LABEL },
    { value: PPP_3_2_1, label: PPP_3_YEAR_LABEL },
    { value: PPP_1_0_0, label: PPP_1_0_0_LABEL },
    { value: PPP_YIELD_MAINTENANCE, label: PPP_YIELD_MAINTENANCE },
    { value: PPP_3_0_0, label: PPP_3_0_0_LABEL },
  ],
};

export const prePaymentPenaltyOptions0To1Prepay = [
  { value: PPP_1_0_0, label: PPP_1_0_0_LABEL },
  { value: PPP_0_NO_PREPAY, label: PPP_0_NO_PREPAY_LABEL },
];

export const prePaymentPenaltyOptionsNoPrepayOnly = [
  { value: PPP_0_NO_PREPAY, label: PPP_0_NO_PREPAY_LABEL },
];

export const BROKER_POINTS_OPTIONS = [
  { value: 0, label: '0%' },
  { value: 0.25, label: '0.25%' },
  { value: 0.5, label: '0.50%' },
  { value: 0.75, label: '0.75%' },
  { value: 1, label: '1%' },
  { value: 1.25, label: '1.25%' },
  { value: 1.5, label: '1.50%' },
  { value: 1.75, label: '1.75%' },
  { value: 2, label: '2%' },
];

export const LOAN_TERM_5 = 5;
export const LOAN_TERM_10 = 10;
export const LOAN_TERM_30 = 30;
export const LOAN_TERM_5_LABEL = '5 year Term';
export const LOAN_TERM_10_LABEL = '10 year Term';
export const LOAN_TERM_30_LABEL = '30 year Term';
export const RATE_TYPE_5_FRM = '5 Yr FRM';

export const sfrLoanTermTypes = [
  { value: LOAN_TERM_5, label: LOAN_TERM_5_LABEL },
  { value: LOAN_TERM_10, label: LOAN_TERM_10_LABEL },
  { value: LOAN_TERM_30, label: LOAN_TERM_30_LABEL },
];

export const loanTermRateTermMapping = {
  [LOAN_TERM_5]: {
    default: RATE_TYPE_5_FRM,
  },
  [LOAN_TERM_10]: {
    default: RATE_TYPE_5,
  },
  [LOAN_TERM_30]: {
    default: RATE_TYPE_5,
  },
};

export const YES = 'Yes';
export const NO = 'No';

export const TRUE = 'true';
export const FALSE = 'false';

export enum Occupancy {
  TENANT_OCCUPIED = 'Tenant Occupied',
  VACANT = 'Vacant',
}

export enum Foundation {
  CONCRETE_SLAB = 'Concrete Slab',
  CRAWL_SPACE = 'Crawl Space',
  FULL_BASEMENT = 'Full Basement',
  PARTIAL_BASEMENT = 'Partial Basement',
  NO_BASEMENT = 'No Basement',
}

export const occupancyOptions = [
  {
    label: Occupancy.TENANT_OCCUPIED,
    value: Occupancy.TENANT_OCCUPIED,
  },
  {
    label: Occupancy.VACANT,
    value: Occupancy.VACANT,
  },
];

export const foundationOptions = [
  {
    label: Foundation.CONCRETE_SLAB,
    value: Foundation.CONCRETE_SLAB,
  },
  {
    label: Foundation.CRAWL_SPACE,
    value: Foundation.CRAWL_SPACE,
  },
  {
    label: Foundation.FULL_BASEMENT,
    value: Foundation.FULL_BASEMENT,
  },
  {
    label: Foundation.PARTIAL_BASEMENT,
    value: Foundation.PARTIAL_BASEMENT,
  },
  {
    label: Foundation.NO_BASEMENT,
    value: Foundation.NO_BASEMENT,
  },
];

export const yesNoOptions = [
  {
    label: YES,
    value: YES,
  },
  {
    label: NO,
    value: NO,
  },
];

export const yesNoOptionsBoolean = [
  {
    label: YES,
    value: true,
  },
  {
    label: NO,
    value: false,
  },
];

export const PROPERTY_TYPES_RUSHED_APPRAISAL = [
  'Single Family',
  'Duplex',
  'Triplex',
  'Quadruplex',
  'Condominium',
];

export const STATUS_QUOTE_PAID_BY_BORROWER = 'Quote Paid By Borrower';
export const STATUS_QUOTE_ACCEPTED_BY_BORROWER = 'Quote Accepted By Borrower';
export const STATUS_QUOTE_BOUND = 'Quote is Bound';

export const citizenshipStatusOptions = [
  { label: 'US Citizen', value: 'US Citizen' },
  {
    label: 'US Permanent Resident (Green Card Holder)',
    value: 'US Permanent Resident (Green Card Holder)',
  },
  {
    label: 'US Resident with Valid Visa and Credit Score',
    value: 'US Resident with Valid Visa',
  },
  { label: 'Foreign National with Valid Visa and Credit Score', value: 'Foreign National' },
];

export const fundingPreferenceOptions = [
  {
    value: 'Loan Funder LLC',
    label: 'Loan Funder LLC',
  },
  {
    value: 'SPL with MERS as nominee',
    label: 'Lender with MERS as nominee',
  },
  {
    value: 'Loan Funder LLC with MERS as nominee',
    label: 'Loan Funder LLC with MERS as nominee',
  },
  {
    value: 'DBA',
    label: 'DBA',
  },
];

export const fundingPreferenceOptionsForLender = [
  {
    value: 'Loan Funder LLC',
    label: 'Capital Provider',
  },
  {
    value: 'SPL with MERS as nominee',
    label: 'You with MERS as nominee',
  },
  {
    value: 'Loan Funder LLC with MERS as nominee',
    label: 'Capital Provider with MERS as nominee',
  },
  {
    value: 'DBA',
    label: 'DBA',
  },
];

export const interestStartMechanismOptions = [
  {
    value: 'Closing Date',
    label: 'Closing Date',
  },
  {
    value: 'Wire Release Date',
    label: 'Wire Release Date',
  },
];

export const lenderUnderwritingFeeTooltip =
  'Loan Amount <$500k, minimum underwriting fee = $595' +
  '\nLoan Amount >= $500k & <$1mm, minimum underwriting fee = $895' +
  '\nLoan Amount >= $1mm & <$2mm, minimum underwriting fee = $1,295' +
  '\nLoan Amount >= $2mm & <$3mm, minimum underwriting fee = $1,995' +
  '\nLoan Amount >= $3mm & <$4mm, minimum underwriting fee = $3,495' +
  '\nLoan Amount >= $4mm & <$5mm, minimum underwriting fee = $4,495';

export const InterestRateWithAdjustedFloorRateTooltip = 'The adjusted floor rate offered to borrower can be increased here.'

export enum DocumentName {
  DEAL_ECONOMICS = 'Deal Economics',
  REQUESTED_CLOSING_DATE = 'Requested Closing Date',
  EXPECTED_CLOSING_DATE = 'Expected Closing Date',
  DUE_DILIGENCE_PAYMENT = 'Due Diligence Payment',
  TERM_SHEET = 'Term Sheet',
  BORROWER_SURVEY_QUESTIONNAIRE = 'Borrower Survey Questionnaire',
  BORROWER_BACKGROUND_REVIEW = 'Borrower Background Review',
  BORROWER_CREDIT_REVIEW = 'Borrower Credit Review',
  BORROWER_ENTITY = 'Borrower Entity Information',
  LENDER_FEES = 'Lender Fees',
  TITLE_AGENT = 'Title Provider Information',
  INSURANCE_PROVIDER = 'Insurance Provider Information',
  EXPECTED_WIRE_RELEASE_DATE = 'Expected Wire Release Date',
  INSURANCE_APPROVAL = 'Insurance Approval',
  LOAN_FUNDING_TEMPLATE = 'Loan Funding Template',
  BORROWER_CLOSING_AGENT = 'Borrower Closing Agent Information',
  SCOPE_OF_WORK = 'Scope of Work',
  DIGITAL_SCOPE_OF_WORK = 'Digital SOW',
  DEAL_SUMMARY = 'Deal Summary',
  LOAN_TERMS = 'Loan Terms',
  NOTE_ALLONGE = 'Note Allonge',
  ASSIGNMENT_AND_CONVEYANCE = 'Assignment and Conveyance',
  FUNDING_PREFERENCE = 'Loan Closing Data',
  POINT_OF_CONTACT = 'Point of Contact',
  PROPERTY_PHOTOS = 'Property Photos',
  DRAW_REQUEST_FORM = 'Draw Request Form',
  REVISED_SOW = 'Revised SOW',
  REVISED_SOW_FORM = 'Revised SOW Form',
  DRAW_REPORT = 'Draw Report',
  IDENTITY_VERIFICATION = 'Identity Verification',
}

export enum DocumentSection {
  INSURANCE = 10,
}

export enum DocumentSectionNames {
  GENERATED_CLOSING_DOCUMENTS = 'Generated Closing Documents',
  BOARDING = 'Boarding',
  SCANNED_CLOSING_DOCS = 'Scanned Closing Docs',
  RECORDED_CLOSING_DOCS = 'Recorded Closing Docs',
  PHYSICAL_ORIGINAL_RECORDED_CLOSING_DOCS = 'Physical Original Recorded Closing Docs',
  E_FILE_ONLY_RECORDED_CLOSING_DOCS = 'E-file Only Recorded Closing Docs',
  POST_CLOSING = 'Post Closing',
}

export const lenderRoleOptions = [
  {
    label: 'Processor',
    value: 'lender',
  },
  {
    label: 'Originator',
    value: 'lender_originator',
  },
  {
    label: 'Lead',
    value: 'lender_full_access',
  },
  {
    label: 'Admin',
    value: 'lender_full_access_admin',
  },
];

export const internalenderRoleOptions = [
  {
    label: 'Processor',
    value: 'lender',
  },
  {
    label: 'Originator',
    value: 'lender_originator',
  },
  {
    label: 'Lead',
    value: 'lender_full_access',
  },
  {
    label: 'Admin',
    value: 'lender_full_access_admin',
  },
  {
    label: 'Back Office Originator',
    value: 'backOfficeOriginator',
  },
];

export const LENDER_OWNER_COMPANY_ROLE = 'Owner/Principal';
export const lenderCompanyrolesOptions = [
  {
    label: LENDER_OWNER_COMPANY_ROLE,
    value: LENDER_OWNER_COMPANY_ROLE,
  },
  {
    label: 'Employee',
    value: 'Employee',
  },
];

export enum LoanProcess {
  CLOSING = 'closing',
  DRAWS = 'draws',
  SERVICING = 'servicing',
  UNDERWRITING = 'underwriting',
}

export const usStates = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'BC',
  'SK',
  'ON',
].map(state => ({
  label: state,
  value: state,
}));

export const SUBMITTED_FROM_DEAL_ROOM = 'deal room';
export const PROPOSAL_REQUEST_TYPE = 'Proposal Request';

export const exitStrategiesStabilizedBridge = [
  { value: 'Sale', label: 'Sale' },
  { value: 'Rent and Refinance', label: 'Rent and Refinance' },
];

export const exitStrategies = [
  { value: 'Refinance', label: 'Refinance' },
  { value: 'Sale', label: 'Sale' },
];

export enum Roles {
  LENDER = 'lender',
  ORIGINATOR = 'lender_originator',
  BACK_OFFICE_PROCESSOR = 'backOfficeProcessor',
  BACK_OFFICE_ORIGINATOR = 'backOfficeOriginator',
  BACK_OFFICE_LEAD = 'backOfficeLead',
  ADMIN = 'lender_full_access_admin',
  LENDER_LEAD = 'lender_full_access',
  DECISION_COMMITTEE = 'decisionCommittee',
  UNDERWRITER_COMMITTEE = 'underwriterCommittee',
  LIMITED_RELATIONSHIP_MANAGER = 'limitedRelationshipManager',
  MARKETING = 'marketing',
  BROKER = 'broker',
  APPRAISAL_TAMARISK_LEAD = 'appraisalTamariskLead',
  APPRAISAL_TAMARISK_ASSISTANCE = 'appraisalTamariskAssistance',
  APPRAISAL_TAMARISK_SUPPORT = 'appraisalTamariskSupport',
  DRAW_ADMIN_SPECIALIST = 'drawAdminSpecialist',
  DRAW_REVIEW_SPECIALIST = 'drawReviewSpecialist',
  DRAW_MANAGER = 'drawsManager',
  LOAN_COORDINATOR = 'loanCoordinator'
}

export enum SupportRoles {
  TECH_ADMIN = 'techAdmin',
  TECH = 'tech',
  CLOSING_MANAGER = 'closingManager',
  CLOSING_SPECIALIST = 'closingSpecialist',
  APPRAISAL_SPECIALIST_LEAD = 'appraisalSpecialistLead',
  APPRAISAL_SPECIALIST = 'appraisalSpecialist'
}

export const APPRAISAL_EDIT_CONFIG_PERMISSION_OLD_VERSIONS = [
  SupportRoles.TECH_ADMIN,
  SupportRoles.TECH,
];

export const APPRAISAL_EDIT_CONFIG_PERMISSION_VERSION_3 = [
  SupportRoles.TECH_ADMIN,
  SupportRoles.TECH,
];

export const APPRAISAL_REPORT_VERSION_3 = 3;

export const roleOptions = {
  LENDER_ADMIN: {
    label: 'Admin',
    value: 'lender_full_access_admin',
  },
  LENDER: {
    label: 'Processor',
    value: Roles.LENDER,
  },
  ORIGINATOR: {
    label: 'Originator',
    value: Roles.ORIGINATOR,
  },
  BORROWER: {
    label: 'Borrower',
    value: 'borrower',
  },
  BROKER: {
    label: 'Broker',
    value: 'broker',
  },
  ATTORNEY: {
    label: 'Attorney',
    value: 'closingAttorney',
  },
  INSURANCE_REVIEWER: {
    label: 'Insurance Reviewer',
    value: 'insurance_reviewer',
  },
  CLOSING_REVIEWER: {
    label: 'Closing Reviewer',
    value: 'closing_reviewer',
  },
  LENDER_LEAD: {
    label: 'Lender Lead',
    value: 'lender_full_access',
  },
  BACK_OFFICE_PROCESSOR: {
    label: 'Back Office Processor',
    value: 'backOfficeProcessor',
  },
  BACK_OFFICE_ORIGINATOR: {
    label: 'Back Office Originator',
    value: 'backOfficeOriginator',
  },
};

export enum Utility {
  LOAN_INFORMATION = 'Loan',
  PROPOSAL_INFORMATION = 'Proposal',
}

export const DRAFT_LOAN_TYPE_LOAN = 'Draft Loan';
export const REQUEST_LOAN_TYPE_LOAN = 'Loan Request';
export const REJECTED_STATE = 'Rejected';
export const COMPLETED_STATE = 'Completed';

export const originatorSubLabel =
  "(Note: If 'None' is selected no originators will see this submission)";

export const TRACK_RECORD_REVIEW_TYPE = 'Borrower Track Record Review';
export const BORROWER_BACKGROUND_REVIEW_TYPE = 'Borrower Background Review';
export const BORROWER_CREDIT_REVIEW_TYPE = 'Borrower Credit Review';
export const TRACK_RECORD_MLS_DATA = 'MLS Data';
export const TRACK_RECORD_USER_INPUTTED = 'User Inputted';
export const TRACK_RECORD_BORROWER_INPUTTED = 'Borrower Inputted';
export const TRACK_RECORD_CAPITAL_PROVIDER = 'Capital Provider';
export const TRACK_RECORD_TEMPLATE = 'Track Record Template';

export const TASK_BACKGROUND_TEMPLATE_ID = 7;
export const TASK_CREDIT_TEMPLATE_ID = 6;
export const TASK_TRACK_RECORD_TEMPLATE_ID = 8;

export enum CreditBackgroundType {
  CRAF = 'CRAF',
  ID_VERIFICATION = 'ID_VERIFICATION',
  BG_ID_VERIFICATION_WITHOUT_NOTIFICATION = 'BG_ID_VERIFICATION_WITHOUT_NOTIFICATION',
}

export const DURATION = [
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 18,
    label: '18 months',
  },
];

export const borrowerEntityTypeOptions = [
  {
    value: 'Limited Liability Company',
    label: 'Limited Liability Company',
  },
  {
    value: 'Corporation',
    label: 'Corporation',
  },
];

export enum PropertyOwnership {
  PURCHASED = 'Purchase',
  OWNED_BY_BORROWER = 'Owned By Borrower',
}

export const estimatedFICOOptions = [
  { label: 'Please select', value: '' },
  { label: '> 740', value: 741 },
  { label: '700 - 740', value: 740 },
  { label: '660 - 699', value: 699 },
  { label: '620 - 659', value: 659 },
  { label: '< 620', value: 619 },
];

export const estimatedGUExperienceOptions = [
  { label: 'Please select', value: '' },
  { label: 'Experience Score 3 (1-2 Ground Up Construction)', value: 3 },
  { label: 'Experience Score 4 (3-4 Ground Up Construction)', value: 4 },
  { label: 'Experience Score 5 (5-19 Ground Up Construction)', value: 5 },
  { label: 'Experienced Developer (20+ Ground Up Construction)', value: 6 },
];

export const estimatedFFExperienceOptions = [
  { label: 'Please select', value: '' },
  { label: 'Experience Score 1 (No Experience)', value: 1 },
  { label: 'Experience Score 2 (1-2 Investment Properties)', value: 2 },
  { label: 'Experience Score 3 (3-4 Investment Properties)', value: 3 },
  { label: 'Experience Score 4 (5-9 Investment Properties)', value: 4 },
  { label: 'Experience Score 5 (10+ Investment Properties)', value: 5 },
];

export const ffExperienceOptions = [
  { label: '1-2 Investment Properties', value: 2 },
  { label: '3-4 Investment Properties', value: 3 },
  { label: '5-9 Investment Properties', value: 4 },
  { label: '10+ Investment Properties', value: 5 },
];

export const renoDescriptionOptions = [
  { label: 'Please select', value: '' },
  { label: 'Light', value: 'Light' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'Heavy', value: 'Heavy' },
  { label: 'Extensive', value: 'Extensive' },
];


export const TOOLTIP_TRACK_RECORD_MESSAGE =
  "To verify the addresses provided in the borrower's track record, please upload proof of property ownership.If an entity purchased a property provided in the track record, share documentation that the borrower is a member of the entity.Acceptable Forms of Documentation: Signed HUD with Borrower's Name, Entity Documents (Articles of Incorporation, EIN, Operating Agreement, Partnership Agreement), Borrower listed as Member in Online databases";
export const TOOLTIP_BACKGROUND_REVIEW_MESSAGE =
  'We found an item flagged in the background report that will need to be addressed with a Letter of Explanation (LOE) and documentation of dismissal/satisfaction if applicable. Please reach out to your loan coordinator or underwriter with any questions';
export const TOOLTIP_CREDIT_REVIEW_MESSAGE =
  'We found an item flagged in the credit report that will need to be addressed with a Letter of Explanation (LOE) and documentation of dismissal/satisfaction if applicable. Please reach out to your loan coordinator or underwriter with any questions';

export enum EditLoanRequestType {
  LOAN_TERM = 'LOAN_TERM',
  PROPERTY = 'PROPERTY',
  BORROWER = 'BORROWER',
}

export const CDA = 'cda';
export const ARR = 'arr';

export const FACO_PROFILE_NAME = 'faco sales user';
export const ROC_PROFILE_NAME = 'relationship manager';
export const CIVIC_PROFILE_NAME = 'civic sales user';

export const LOAN_WITHOUT_LEAD_SOURCE_VALIDATION =
  'One borrower does not have lead source';

export const INTEREST_ACCRUAL_TOOLTIP = `<$100K Loan Amount: Interest charged on total loan amount ("Full Boat")
  >= $100K Loan Amount: Interest charged on unpaid principal balance ("As Disbursed")`;

export enum BusinessUnit {
  FACO = 'FACO',
  CIVIC = 'CIVIC',
  ROC = 'ROC'
}

export const isFixAndFlip = (loanSubType: any) => {
  return LoanSubType.FIX_AND_FLIP === loanSubType || LoanSubType.FIX_AND_FLIP_PRO === loanSubType;
}
export const isGroundUp = (loanSubtype: String) => {
  return LoanSubType.GROUND_UP === loanSubtype;
}

export const isStabilizedBridge = (loanSubtype: String) => {
  return LoanSubType.STABILIZED_BRIDGE === loanSubtype;
}

export const getLoanPurpose = (properties: any[]) => {
  let loanPurpose = 'Purchase and Rehab';
  properties?.forEach(property => {
    if (property?.propertyOwnership == PropertyOwnership.OWNED_BY_BORROWER) {
      loanPurpose = 'Refi';
    }
  });
  return loanPurpose;
};

export const bankInformationPropertyStates = [
  { label: 'Alabama', value: 'Alabama', id: 'AL' },
  { label: 'Alaska', value: 'Alaska', id: 'AK' },
  { label: 'Arizona', value: 'Arizona', id: 'AZ' },
  { label: 'Arkansas', value: 'Arkansas', id: 'AR' },
  { label: 'California', value: 'California', id: 'CA' },
  { label: 'Colorado', value: 'Colorado', id: 'CO' },
  { label: 'Connecticut', value: 'Connecticut', id: 'CT' },
  { label: 'Delaware', value: 'Delaware', id: 'DE' },
  { label: 'Florida', value: 'Florida', id: 'FL' },
  { label: 'Georgia', value: 'Georgia', id: 'GA' },
  { label: 'Hawaii', value: 'Hawaii', id: 'HI' },
  { label: 'Idaho', value: 'Idaho', id: 'ID' },
  { label: 'Illinois', value: 'Illinois', id: 'IL' },
  { label: 'Indiana', value: 'Indiana', id: 'IN' },
  { label: 'Iowa', value: 'Iowa', id: 'IA' },
  { label: 'Kansas', value: 'Kansas', id: 'KS' },
  { label: 'Kentucky', value: 'Kentucky', id: 'KY' },
  { label: 'Louisiana', value: 'Louisiana', id: 'LA' },
  { label: 'Maine', value: 'Maine', id: 'ME' },
  { label: 'Maryland', value: 'Maryland', id: 'MD' },
  { label: 'Massachusetts', value: 'Massachusetts', id: 'MA' },
  { label: 'Michigan', value: 'Michigan', id: 'MI' },
  { label: 'Minnesota', value: 'Minnesota', id: 'MN' },
  { label: 'Mississippi', value: 'Mississippi', id: 'MS' },
  { label: 'Missouri', value: 'Missouri', id: 'MO' },
  { label: 'Montana', value: 'Montana', id: 'MT' },
  { label: 'Nebraska', value: 'Nebraska', id: 'NE' },
  { label: 'Nevada', value: 'Nevada', id: 'NV' },
  { label: 'New Hampshire', value: 'New Hampshire', id: 'NH' },
  { label: 'New Jersey', value: 'New Jersey', id: 'NJ' },
  { label: 'New Mexico', value: 'New Mexico', id: 'NM' },
  { label: 'New York', value: 'New York', id: 'NY' },
  { label: 'North Carolina', value: 'North Carolina', id: 'NC' },
  { label: 'North Dakota', value: 'North Dakota', id: 'ND' },
  { label: 'Ohio', value: 'Ohio', id: 'OH' },
  { label: 'Oklahoma', value: 'Oklahoma', id: 'OK' },
  { label: 'Oregon', value: 'Oregon', id: 'OR' },
  { label: 'Pennsylvania', value: 'Pennsylvania', id: 'PA' },
  { label: 'Rhode Island', value: 'Rhode Island', id: 'RI' },
  { label: 'South Carolina', value: 'South Carolina', id: 'SC' },
  { label: 'South Dakota', value: 'South Dakota', id: 'SD' },
  { label: 'Tennessee', value: 'Tennessee', id: 'TN' },
  { label: 'Texas', value: 'Texas', id: 'TX' },
  { label: 'Utah', value: 'Utah', id: 'UT' },
  { label: 'Vermont', value: 'Vermont', id: 'VT' },
  { label: 'Virginia', value: 'Virginia', id: 'VA' },
  { label: 'Washington', value: 'Washington', id: 'WA' },
  { label: 'West Virginia', value: 'West Virginia', id: 'WV' },
  { label: 'Wisconsin', value: 'Wisconsin', id: 'WI' },
  { label: 'Wyoming', value: 'Wyoming', id: 'WY' },
  { label: 'Washington D.C.', value: 'Washington D.C.', id: 'DC' },
];


export const READ_EARNINGS_PERMISSION_LIST = [
  Roles.ADMIN,
  Roles.BACK_OFFICE_LEAD,
  Roles.BACK_OFFICE_ORIGINATOR,
];

export const LOAN_FUNDER = 'Loan Funder LLC';

export enum DescribedRennovation {
  HORIZONTAL = 'Expanding the square footage (Horizontally)',
  VERTICAL = 'Expanding the square footage (Vertically)',
  VERTICAL_AND_HORIZONTAL = 'Expanding the square footage (Horizontally & Vertically)',
  UNIT_COUNT = 'Changing the unit count',
  CONDO_CONVERSION = 'Converting to Condominiums',
  ADU_CONVERSION = 'Adding or converting to an ADU (Accessory Dwelling Unit)',
  FIRE_DAMAGE = 'Repairing fire damage',
  NO = 'No'
}

export const describedRennovationOptions = [
  { label: 'No', value: DescribedRennovation.NO, },
  { label: 'Expanding the square footage (Horizontally)', value: DescribedRennovation.HORIZONTAL },
  { label: 'Expanding the square footage (Vertically)', value: DescribedRennovation.VERTICAL },
  {
    label: 'Expanding the square footage (Horizontally & Vertically)',
    value: DescribedRennovation.VERTICAL_AND_HORIZONTAL,
  },
  { label: 'Changing the unit count', value: DescribedRennovation.UNIT_COUNT },
  { label: 'Converting to Condominiums', value: DescribedRennovation.CONDO_CONVERSION },
  {
    label: 'Adding or converting to an ADU (Accessory Dwelling Unit)',
    value: DescribedRennovation.ADU_CONVERSION
  },
  { label: 'Repairing fire damage', value: DescribedRennovation.FIRE_DAMAGE }
];

export const NEW_CONSTRUCTION = "New Construction";
