import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useLoanStore } from '@roc/feature-loans';
import { isFundedLoan, LoanType } from '@roc/feature-utils';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '32px',
  },
  label: {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '26.6px',
    color: '#939494',
    letterSpacing: '1.5px'
  },
}));


export const LoanKeyDates = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();

  const loanId = loanStore.loanDetails?.loanId;

  useEffect(() => {
    loanStore.getCalendlyPreferences(loanId);
  }, []);
  return (
    <CustomCard
      paperNoShadow
      cardContentProps={{
        style: {
          overflow: 'auto',
          padding: 16,
        }
      }}
    >
      <Box minWidth={1100}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3} justifyContent='center'>
            <Typography className={classes.value}>{loanStore?.cogsExpirationDate ?? "-"}</Typography>
            <Box display="flex" justifyContent="center">
              <Typography className={classes.label}>
                COGS EXPIRATION DATE
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.value}>{loanStore?.creditExpirationDate ?? "-"}</Typography>
            <Box display="flex" justifyContent="center">
              <Typography className={classes.label}>
                CREDIT EXPIRATION DATE
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.value}>{loanStore?.appraisalExpirationDate ?? "-"}</Typography>
            <Box display="flex" justifyContent="center">
              <Typography className={classes.label}>
                APPRAISAL EXPIRATION DATE
              </Typography>
            </Box>
          </Grid>
          {isFundedLoan(loanStore.loanDetails?.status) ?
            <Grid item xs={3}>
              <Typography className={classes.value}>
                {loanStore?.loanDetails?.closingDate ?? '-'}
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.label}>
                  CLOSING DATE
                </Typography>
              </Box>
            </Grid>
            :
            <Grid item xs={3}>
              <Typography className={classes.value}>
                {loanStore?.loanDetails?.expectedClosingDate ?? '-'}
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.label}>
                  EXPECTED CLOSING DATE
                </Typography>
              </Box>
            </Grid>}
        </Grid>
      </Box>
    </CustomCard>
  );
});
