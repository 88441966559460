import { Box, Grid } from '@material-ui/core';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { BrokerCard } from '../cards/brokerCard';
import { CommissionsCard } from '../cards/commissionsCard';
import { BorrowerSummaryCard } from '../cards/borrowerSummaryCard';
import { InsuranceCard } from '../cards/insuranceCard';
import { KeyDatesCardTerm } from '../cards/keyDatesCard';
import { LoanContactsCard } from '../cards/loanContactsCard';
import { LoanSummaryTodosCard } from '../cards/loanSummaryTodosCard';
import { OriginatorsCard } from '../cards/originatorsCard';
import { TermPropertiesGridCard } from '../cards/propertiesGridCard';
import { TermsCard } from '../cards/termsCard';
import { LoanKeyDates } from '../cards/loanKeyDates';
import { LoanDetailsPaper } from '../loanDetailsPaper';
import { LoanStatusStepper } from '../loanDetailsStepper';
import { GeneralStatsCardTerm } from './generalStatsCardTerm';
import { LoanDetailsCardTerm } from './loanDetailsCardTermV2';
import { LoanTermsCardTerm } from './loanTermsCardTerm';
import { PointsAndFeesCardTerm } from './pointsAndFeesCardTerm';
import { SubmissionVsUnderwritingValuesCardTerm } from './submissionVsUnderwritingValuesCardTerm';
import { PricingAndRateCard } from '../cards/pricingAndRateCard';
import { EstimatedClosingCosts } from '../cards/estimatedClosingCosts';
import { useBaseStore } from '@roc/feature-app-core';
import { useSubmissionVsUnderwritingStore } from '../../hooks/useSubmissionVsUnderwritingStore';
import { BorrowerLiquidityCard } from '../cards/borrowerLiquidityCard';
import { LoanSubType } from '@roc/feature-utils';
import { LoanSummaryTodosTasksCard } from '../cards/loanSummaryTodosTasksCard';
import UpdateLogCard from '../cards/updateLogCard';
import { LoanOriginatorCard } from '../cards/loanOriginatorCard';
import { useRouteMatch } from 'react-router';

export const LoanSummaryTerm = observer(() => {
  const {
    showOriginatorsCard,
    isLenderOriginator,
    allowLoanPricing,
    isTaskFrameworkEnabled
  } = useLoanFlags();
  const { globalStore } = useBaseStore();
  const { submissionVsUnderwritingStore } = useSubmissionVsUnderwritingStore();

  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const showBorrowerLiquidityCard = loanDetails.loanSubType === LoanSubType.SINGLE_PROPERTY;
  const isServicerPortal = useRouteMatch('/servicer-portal');

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <LoanKeyDates />
      </Grid>
      <Grid item xs={12}>
        <LoanDetailsCardTerm />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <LoanSummaryTodosTasksCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={'flex'} flexDirection={'column'}>
              {!isServicerPortal && !isLenderOriginator && <Box flexGrow={1} style={{ marginBottom: 20 }}>
                <LoanOriginatorCard readOnly={!allowLoanPricing} />
              </Box>}
              <Box flexGrow={1} pt={showOriginatorsCard ? 2 : 0}>
                <BorrowerSummaryCard />
              </Box>
              {isTaskFrameworkEnabled && allowLoanPricing && <Box pt={2} flexGrow={1}>
                <UpdateLogCard loanId={loanDetails.loanId}/>
              </Box>}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
