import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import CalendarIcon from "../assets/calendarIcon";
import { useLoanStore } from '@roc/feature-loans';
import { observer } from "mobx-react";
import { PopupModal } from 'react-calendly';
//
const useStyles = makeStyles((theme) => ({
  blueBg: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    borderRadius: "14px",
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    height: 'auto',
    '@media (min-width: 992px)': {
      width: "420px",
    },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    padding: "8px 16px",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    wordBreak: "break-word",
  },
  contactContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '134px',
    textOverflow: 'ellipsis'
  },
  leftBox: {
    justifyContent: "flex-end",
    borderRight: "2px solid white",
  },
  rightBox: {
    justifyContent: "flex-start",
  },
  icon: {
    marginRight: "24px",
  },
  role: {
    color: "#E4E8EB",
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    whiteSpace: "nowrap",
    '@media (max-width: 992px)': {
      width: '118px',
    },
  },
  name: {
    color: "#fff",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0.15px',
    textOverflow: "ellipsis",
    whiteSpace: "normal", // Allow the name to wrap if needed
  },
  clickableBox: {
    cursor: "pointer",
  }
}));

export const ContactsCard = observer(() => {
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [calendlyUrl, setCalendlyUrl] = useState('');

  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;

  const loanId = loanStore.loanDetails?.loanId;
  const calendlyPreferences = loanStore.calendlyPreferences;
  const prefill = calendlyPreferences.calendlyPrefill;
  const showCalendly = calendlyPreferences.showCalendly;
  const loanUnderwriter = loanDetails?.loanUnderwriter;
  const loanCoordinator = loanDetails?.loanCoordinator;
  const classes = useStyles();

  const handleContactClick = (type: string) => {
    if (type === 'loanCoordinator') {
      setCalendlyUrl(calendlyPreferences.calendlyUsers[loanCoordinator?.userId]?.calendlyUrl);
    } else {
      setCalendlyUrl(calendlyPreferences.calendlyUsers[loanUnderwriter?.userId]?.calendlyUrl);
    }
    setIsCalendlyModalOpen(true);
  };

  useEffect(() => {
    loanStore.getCalendlyPreferences(loanId);
  }, []);

  return (
    <>
      <Box className={classes.blueBg}>
        <Box
          className={`${classes.box} ${classes.leftBox} ${calendlyPreferences?.calendlyUsers[loanCoordinator?.userId]?.calendlyUrl ? classes.clickableBox : ''}`}
          onClick={() => handleContactClick('loanCoordinator')}
        >
          <Box className={classes.icon}>
            <CalendarIcon />
          </Box>
          <Box className={classes.contactContainer}>
            <Typography className={classes.name}>{loanCoordinator?.name}</Typography>
            <Typography className={classes.role}>Loan Coordinator</Typography>
          </Box>
        </Box>

        <Box
          className={`${classes.box} ${classes.rightBox} ${calendlyPreferences?.calendlyUsers[loanUnderwriter?.userId]?.calendlyUrl ? classes.clickableBox : ''}`}
          onClick={() => handleContactClick('underwritter')}
        >
          <Box className={classes.icon}>
            <CalendarIcon />
          </Box>
          <Box className={classes.contactContainer}>
            <Typography className={classes.name}>{loanUnderwriter?.name}</Typography>
            <Typography className={classes.role}>Underwriter</Typography>
          </Box>
        </Box>
      </Box>

      {calendlyUrl && <CalendlyWidget isCalendlyModalOpen={isCalendlyModalOpen} setIsCalendlyModalOpen={setIsCalendlyModalOpen} calendlyUrl={calendlyUrl} prefill={prefill} showCalendly={showCalendly} />}
    </>
  );
});

const CalendlyWidget = observer(({ isCalendlyModalOpen, setIsCalendlyModalOpen, calendlyUrl, prefill, showCalendly }) => {
  return (
    <>
      {showCalendly && calendlyUrl &&
        <PopupModal
          url={`https://calendly.com/${calendlyUrl}`}
          prefill={prefill}
          onModalClose={() => setIsCalendlyModalOpen(false)}
          open={isCalendlyModalOpen}
          rootElement={document.getElementById('root')}
        />
      }
    </>
  );
});
