import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { InsuranceAgentFinder } from '../components/insuranceAgentFinder';

export const getInsuranceAgentRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    insuranceAgentDashboard: {
      path: `${basePath}/backoffice/insurance-agents`,
      url: `${baseUrl}/backoffice/insurance-agents`,
    },
  };
};

export const getInsuranceAgentRoutes = (basePath: string, baseUrl: string) => {
  const config = getInsuranceAgentRoutesConfig(basePath, baseUrl);

  return {
    insuranceAgentDashboard: (
      <Route exact path={config.insuranceAgentDashboard.path}>
        <Page routeDefinition={config.insuranceAgentDashboard}>
          <InsuranceAgentFinder />
        </Page>
      </Route>
    ),
  };
};
