import { action, computed, flow, makeObservable, observable } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { EditLoansService } from '../services/editLoansService';
import {
  EditLoanRequestType,
  LoanStatus,
  LoanSubType,
  prequalificationStatusProperties,
  renovationDescriptionsByLoanSubtype,
} from '@roc/feature-utils';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { Borrower } from '@roc/feature-types';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { Property } from '@roc/feature-types';
import { borrowerDefaults } from '@roc/feature-utils';
import { PropertyStore } from '@roc/feature-loans';
import LoanInformationStore from 'libs/feature-loans/src/loanSubmission/stores/fixFlip/loanInformationStore';
import { getSelectFieldValue, mapPrincipalInterest } from 'libs/feature-loans/src/loanSubmission/stores/common/convert-draft-loan-helper';
import { getBoolean, getValues } from '@roc/ui/utils';
import { BorrowerFormStore } from 'libs/feature-loans/src/loanSubmission/stores/fixFlip/borrowerFormStore';
import { LoanService } from '@roc/feature-loans';

export class EditLoansStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  propertyStore: PropertyStore;
  borrowerFormStore: BorrowerFormStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanInformationStore: LoanInformationStore;
  public existingLoan: any;
  public loanTerms: any;
  public properties: Property[];
  public loanSubtype: LoanSubType;
  private editLoanService: EditLoansService;
  public allErrors: string[];
  public borrowersErrors: string[];
  public borrowersWarnings: string[];
  private loanService: LoanService;

  constructor(globalStore, userStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.propertyStore = new PropertyStore(this.globalStore, x =>
      this.handleRenovationBudgetChange(x)
    );
    this.borrowerFormStore = new BorrowerFormStore(this.globalStore);
    this.loanInformationStore = new LoanInformationStore(
      this.globalStore,
      this
    );
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      undefined,
      () => new BorrowerFormStore(globalStore)
    );
    this.loanService = new LoanService();
    this.editLoanService = new EditLoansService();
    this.setDefaults();

    makeObservable(this, {
      existingLoan: observable,
      loanTerms: observable,
      properties: observable,
      loanSubtype: observable,
      allErrors: observable,
      reset: action,
      loadStore: action,
      loadLoanProperties: action,
      getExistingProperties: action,
      loadLoanTerms: action,
      getExistingLoanTerms: action,
      loadLoanBorrowers: action,
      getExistingBorrowers: action,
      changeStatusToPreSubmission: flow,
      recalculateLoanStatus: flow,
      setBorrowers: action,
      setNewBorrowers: action,
      setLoanTerms: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      handleRenovationBudgetChange: action,
      loanTermsJson: computed,
      borrowersRows: computed,
      propertiesRows: computed,
      updateLoanTerms: flow,
      updateLoanBorrowers: flow,
      updateLoanProperties: flow,
      validateBridgeSubmission: flow,
      borrowersErrors: observable,
      borrowersWarnings: observable,
    });
  }

  private setDefaults() {
    this.loanTerms = {};
    this.properties = [];
    this.existingLoan = {};
    this.allErrors = [];
    this.borrowersErrors = [];
    this.borrowersWarnings = [];
  }

  reset() {
    this.setDefaults();
    this.borrowerFormStore.reset();
    this.propertyStore.reset();
    this.loanInformationStore.resetLoanInformation();
    this.selectBorrowersStore.reset();
  }

  loadStore(existingLoan) {
    this.reset();
    this.existingLoan = existingLoan;
    this.loanSubtype = existingLoan.loanSubType;
    this.loadLoanTerms(existingLoan);
    this.loadLoanBorrowers(existingLoan);
    this.loadLoanProperties(existingLoan);
  }

  loadLoanProperties(existingLoan) {
    this.properties = this.getExistingProperties(existingLoan.properties, existingLoan.loanSubType);
  }

  getExistingProperties = (properties, loanSubtype) => {
    const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
      loanSubtype
    );

    return properties.map(property => {
      return {
        propertyId: property.propertyId,
        address: property.address,
        streetNumber: property.streetNumber,
        streetName: property.streetName,
        city: property.city,
        state: property.state,
        zipCode: property.zipCode,
        latitude: property.latitude,
        longitude: property.longitude,
        useCode: property.propertyType,
        aptNumber: property.aptNumber,
        propertyOwnership: property.propertyOwnership,
        propertyDescription: property.propertyDescription,
        purchasePrice: property.purchasePrice,
        armsLength: property.armsLength ? 'Y' : 'N',
        armsLengthComment: property.armsLengthComment,
        propertySourcing: property.propertySourcing,
        propertySourcingExplanation: property.propertySourcingExplanation,
        wholesaleAmount: property.wholesaleAmount,
        wholesalerPurchase: property.wholesalerPurchase,
        anyDebt: property.anyDebt ? 'Y' : 'N',
        refiAmount: property.refiAmount,
        purchaseDate: property.purchaseDate,
        totalRenovationBudget: property.renovationBudget + property.sunkCost,
        describeRenovation: property.describeRenovation,
        sunkCost: property.sunkCost,
        renovationRemaining: property.renovationBudget,
        renovationBudget: property.renovationBudget,
        afterRepairValue: property.afterRepairValue,
        exitStrategy: property.exitStrategy,
        additionalComments: property.additionalComments,
        monthlyMarketRentProvidedAtOrigination: property.monthlyMarketRentProvidedAtOrigination,
        annualHOAProvidedAtOrigination: property.annualHOAProvidedAtOrigination,
        annualTaxesProvidedAtOrigination: property.annualTaxesProvidedAtOrigination,
        annualInsuranceProvidedAtOrigination: property.annualInsuranceProvidedAtOrigination,
        asIsValue: property.asIsValue,
      };
    });
  };

  loadLoanTerms(existingLoan) {
    this.properties = this.getExistingProperties(existingLoan.properties, existingLoan.loanSubType);
    this.loanTerms = this.getExistingLoanTerms(existingLoan);
    this.loanInformationStore.loadForm(this.loanTerms);
    this.loanInformationStore.makeInitialCalculations();
    this.loanInformationStore.getLenderAttorneys();
  }

  getExistingLoanTerms = (existingLoan) => {
    return {
      loanId: existingLoan.loanId,
      rate: existingLoan.rate,
      points: existingLoan.pointsIn,
      brokerPoints: existingLoan.brokerPointsIn,
      lenderUnderwritingFee: existingLoan.underwritingFee,
      lenderProcessingFee: existingLoan.processingFee,
      externalLenderUnderwritingFee: existingLoan.externalLenderUnderwritingFee,
      externalLenderProcessingFee: existingLoan.externalLenderProcessingFee,
      interestAccrualMethod: mapPrincipalInterest[existingLoan.fullPrincipalInterest] ?? 'As disbursed',
      interestReserveMonths: existingLoan.interestReserveMonths,
      duration: existingLoan.duration,
      amount: existingLoan.amount,
      initialLoanAmount: existingLoan.initialLoanAmount,
      initialLoanToPurchase: existingLoan.initialLoanToPurchase,
      constructionHoldback: existingLoan.constructionHoldback,
      constructionHoldbackPercentage: existingLoan.constructionHoldbackToRenovationBudgetPercent,
      preferredClosingAttorney: existingLoan.attorneyEmail
    };
  };

  loadLoanBorrowers(existingLoan) {
    this.setBorrowers(this.getExistingBorrowers(existingLoan.borrowers));
  }

  getExistingBorrowers = (borrowers) => {
    return borrowers.map(borrower => ({
      borrowerId: borrower.borrowerId,
      firstName: borrower.firstName,
      lastName: borrower.lastName,
      emailAddress: borrower.emailAddress,
      cellPhone: borrower.cellPhone,
      personalGuarantor: borrower.personalGuarantor,
      pctOwnership: borrower.pctOwnership,
      authSignatory: borrower.authSignatory,
      experience: borrower.experience,
      creditBackgroundCheck: borrower.runCreditOnCreation,
      citizenshipStatus: borrower.citizenshipStatus,
      preQualificationStatus: borrower.preQualificationStatus,
    }));
  };

  *changeStatusToPreSubmission(loanId, onSuccessCallback = () => { }) {
    try {
      const response: ApiResponse = yield this.editLoanService.changeLoanStatus(loanId, {
        'loanStatus': LoanStatus.PENDING_SUBMISSION,
        'statusChangeReason': 'Moved to pending submission'
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan status updated succesfully.'
      });
      onSuccessCallback();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error ocurred while updating the loan status. Please reach out to tech team on this.'
      });
    }
  }

  *recalculateLoanStatus(onSuccessCallback = () => { }) {
    try {
      const response: ApiResponse = yield this.editLoanService.recalculateLoanStatus(this.existingLoan?.loanId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan status recalculated succesfully.'
      });
      onSuccessCallback();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error ocurred while recalculating the loan status. Please reach out to tech team on this.'
      });
    }
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    return {
      ...borrower,
      personalGuarantor:
        borrower.personalGuarantor ?? borrowerDefaults.personalGuarantor,
    };
  }

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  setLoanTerms = loanTerms => {
    this.loanTerms = { ...loanTerms };
  };

  addProperty = property => {
    this.properties.push(property);
  };

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
  };

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
  };

  handleRenovationBudgetChange = (value: number) => {
    const {
      purchasePrice,
    } = this.propertyStore.purchaseInformationStore.getFormValues();
    if (value > purchasePrice / 2) {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The Renovation budget should not be greater than 50% of the Purchase price.',
      });
    }
  };

  get loanTermsJson() {
    return {
      loanId: this.existingLoan?.loanId,
      loanSubtype: this.loanSubtype,
      fullPrincipalInterest: this.loanTerms.fullPrincipalInterest,
      initialLoanAmount: this.loanTerms.initialLoanAmount,
      initialLoanToPurchase: this.loanTerms.initialLoanToPurchase,
      constructionHoldback: this.loanTerms.constructionHoldback,
      constructionHoldbackPercentage: this.loanTerms.constructionHoldbackPercentage,
      rate: this.loanTerms.rate,
      rocRate: this.loanTerms.rate,
      points: this.loanTerms.points,
      pointsIn: this.loanTerms.points,
      brokerPoints: this.loanTerms.brokerPoints,
      brokerPointsIn: this.loanTerms.brokerPoints,
      interestReserveMonths: this.loanTerms.interestReserveMonths,
      duration: this.loanTerms.duration,
      amount: this.loanTerms.amount,
      sellAs: this.loanTerms.sellAs,
      underwritingFee: this.loanTerms.lenderUnderwritingFee,
      processingFee: this.loanTerms.lenderProcessingFee,
      externalLenderUnderwritingFee: this.loanTerms.externalLenderUnderwritingFee,
      externalLenderProcessingFee: this.loanTerms.externalLenderProcessingFee,
      lenderAttorneySelectedName: this.loanInformationStore.getPreferredAttorney()?.label,
    };
  }

  get borrowersRows() {
    const defaultFormValues = this.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo
      .restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        ...borrower,
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: getValues(borrower.roleInTransaction),
        personalGuarantor: borrower.personalGuarantor,
        experience: borrower.experience ?? defaultFormValues.experience,
        pctOwnership: borrower.pctOwnership ?? defaultFormValues.pctOwnership,
        citizenshipStatus:
          borrower.citizenshipStatus || defaultFormValues.citizenshipStatus,
        hasSubstantialChanges:
          borrower.hasSubstantialChanges ??
          defaultFormValues.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        OwnershipNotKnown:
          borrower.ownershipNotKnown ?? defaultFormValues.ownershipNotKnown, // Compatiblity with eportal
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
        socialSecurityNumber: borrower.ssn,
        preQualificationStatus: borrower.preQualificationStatus
      };
    });
  }

  get propertiesRows() {
    return this.properties.map(property => ({
      ...property,
      propertyId: property.propertyId,
      address: property.address,
      streetNumber: property.streetNumber,
      streetName: property.streetName,
      aptNumber: property.aptNumber,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      latitude: property.latitude,
      longitude: property.longitude,
      exitStrategy: property.exitStrategy,
      propertyOwnership: property.propertyOwnership,
      propertySourcing: property.propertySourcing,
      purchasePrice: property.purchasePrice,
      purchaseDate: property.purchaseDate, //TODO: Need formatting as mm/dd/yyyy ??
      totalRenovationBudget: property.totalRenovationBudget,
      renovationBudget: Math.max(
        0,
        property.totalRenovationBudget - property.sunkCost
      ),
      describeRenovation: property.describeRenovation,
      useCode: property.useCode,
      armsLength: getBoolean(property.armsLength),
      armsLengthComment: property.armsLengthComment,
      anyDebt: getBoolean(property.anyDebt),
      asIsValue: property.asIsValue,
      afterRepairValue: property.afterRepairValue,
      refiAmount: property.refiAmount,
      propertySourcingExplanation: property.propertySourcingExplanation,
      additionalComments: property.additionalComments,
      propertyAppraisalId: property.appraisalId,
      submitRushedAppraisal: property.submitRushedAppraisal,
      wholesaleAmount:
        property.wholesaleAmount &&
        property.purchasePrice &&
        property.purchasePrice - property.wholesaleAmount,
      wholesalerPurchase: property.wholesalerPurchase,
      sunkCost: property.sunkCost || 0,
    }));
  }

  *updateLoanTerms(onSuccessCallback = () => { }) {
    try {
      const data = this.loanTermsJson;
      const response: ApiResponse = yield this.editLoanService.updateLoan(EditLoanRequestType.LOAN_TERM, data);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Loan terms updated succesfully.'
      });
      onSuccessCallback();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error ocurred while updating the loan terms. Please reach out to tech team on this.'
      });
    }
  }

  *updateLoanBorrowers(onSuccessCallback = () => { }) {
    try {
      this.checkBorrowersErrors();
      if (this.borrowersErrors.length === 0) {
        const data = this.loanSubmitJson;
        const response: ApiResponse = yield this.editLoanService.updateLoan(EditLoanRequestType.BORROWER, data);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan borrowers updated succesfully.'
        });
        onSuccessCallback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error ocurred while updating the loan borrowers. Please reach out to tech team on this.'
      });
    }
  }

  *updateLoanProperties(onSuccessCallback = () => { }) {
    try {
      this.applyEditPropertyValidations();
      if (this.allErrors.length === 0) {
        const data = this.loanSubmitJson;
        const response: ApiResponse = yield this.editLoanService.updateLoan(EditLoanRequestType.PROPERTY, data);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan properties updated succesfully.'
        });
        onSuccessCallback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error ocurred while updating the loan properties. Please reach out to tech team on this.'
      });
    }
  }

  get loanSubmitJson() {
    return {
      ...this.existingLoan,
      ...this.loanTermsJson,
      borrowers: [...this.borrowersRows],
      properties: [...this.propertiesRows],
    };
  }

  private checkBorrowersErrors = () => {
    this.borrowersErrors = [];
    if (this.borrowersRows.length === 0) {
      this.borrowersErrors.push('Please select at least one borrower');
    }
  }

  public checkBorrowersWarnings = () => {
    this.borrowersWarnings = [];
    if (this.selectBorrowersStore.borrowers.length > 0 && this.calculateBorrowerPercentages() !== 100) {
      this.borrowersWarnings.push(
        'Please ensure that "Percentage of Ownership" adds to a total of 100%. Click the pencil icon to edit your borrower(s) and adjust the ownership percentage slider accordingly.'
      );
    }
  }

  private calculateBorrowerPercentages = () => {
    return this.selectBorrowersStore.borrowers.reduce(
      (total, current) => total + current.pctOwnership ?? 0,
      0
    );
  };

  private applyEditPropertyValidations = () => {
    this.allErrors = [];
    if (this.propertiesRows.length === 0) {
      this.allErrors.push('Please add at least one property')
    }
  }

  *validateBridgeSubmission() {
    try {
      const data = this.loanSubmitJson;
      const response: ApiResponse = yield this.loanService.getValidateBridgeLoanErrors(
        data, true
      );
      if (!response.data.data.isValid) {
        const validateErrors = response.data.data.errors;
        this.allErrors = [...this.allErrors, ...validateErrors];
      }
    } catch (error) {
      console.log(error);
    }
  };

}