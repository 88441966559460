import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import { colors } from '../utils/todoConstants';
import { observer } from 'mobx-react';
import { CustomCard } from './customCard';

export const useLoanDetailsInfoItemStyles = makeStyles((theme: Theme) => ({
  loanDetailsCardItem: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  loanDetailsCardLabel: {
    fontSize: '14px',
    color: '#555',
  },
  overflowElipsis: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  tabs: {
    borderBottom: `1px solid #eee`,
  },
  scroller: {
    '& .MuiTabs-flexContainer': {
      overflowX: 'auto',
      overflowY: 'hidden',
    }
  },
  button: {
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: '4px 10px',
    backgroundColor: colors.white,
    fontSize: 15,
    marginLeft: '2.5px',
    marginRight: '2.5px',
    '&:disabled': {
      border: 'none'
    },
    '&:hover': {
      backgroundColor: colors.white,
    }
  },
  amountHighligher: {
    backgroundColor: lighten(theme.palette.primary.light, 0.9),
    borderRadius: '14px',
    padding: '12px',
  },
  amountHighligterTerms: {
    paddingRight: '2px',
    width: 'fit-content',
    paddingBottom: 0
  },
  loanSummaryBottomSection: {
    display: 'flex',
    marginTop: '3px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  loanSummaryLabelStyle: {
    color: "#939494",
    fontSize: 10,
    textTransform: 'uppercase'
  },
  containerStyle: {
    marginRight: 0
  },
  tab: {
    color: colors.raisinBlack,
    fontWeight: 500,
    lineHeight: "24px",
    borderTopLeftRadius: "8px",
    marginBottom: '-1px',
    width: "calc(25% - 4px)",
    borderTopRightRadius: "8px",
    border: "1px solid #eee",
    margin: "1px",
    opacity: 1,
  },
  loanSummaryTabsOverride: {
    width: 'auto!important'
  },
  activeTab: {
    backgroundColor: colors.desertStorm,
    fontWeight: 'bold'
  },
  indicator: {
    backgroundColor: "transparent",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
  },
  subtitle: {
    fontSize: "10px",
    fontWeight: 400,
    color: colors.dune,
  },
  mobileFix: {
    '@media (max-width: 992px)': {
      overflowX: 'scroll',
    },
  }
}));

export const LoanDetailsInfoItem = observer(({ value, secondValue, secondValueStyle, valueComponent, label, labelComponent, valueStyle, labelStyle, secondaryLabel, secondaryLabelComponent, secondaryLabelStyle, boxStyle, thirdValue, thirdLabel, thirdValueStyle, py = 1 }: {
  value?: any;
  secondValue?: any;
  secondValueStyle?: any;
  valueComponent?: any;
  valueStyle?: any;
  label?: any;
  labelComponent?: any;
  labelStyle?: any;
  py?: any
  secondaryLabel?: any;
  secondaryLabelComponent?: any;
  secondaryLabelStyle?: any;
  boxStyle?: any;
  thirdValue?: any;
  thirdLabel?: any;
  thirdValueStyle?: any;
}) => {
  const classes = useLoanDetailsInfoItemStyles();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}
        py={py}
        pr={1}
        style={boxStyle}
      >
        <Box width={'100%'}>
          {
            valueComponent ?
              valueComponent :
              secondValue ?
                <Box display="flex" flexDirection="row" height={'100%'} alignItems={'center'}>
                  <Box>
                    <Typography className={classes.loanDetailsCardItem} style={valueStyle}>
                      {value ? value : '-'}
                    </Typography>
                  </Box>
                  <Box mx={2} mt={0.5} mb={1} alignSelf={'normal'} borderLeft={'2px solid #bbb'}></Box>
                  <Box>
                    <Typography style={secondValueStyle}>
                      {secondValue}
                    </Typography>
                  </Box>
                </Box>
                :
                <Typography className={classes.loanDetailsCardItem} style={valueStyle}>
                  {value ? value : '-'}
                </Typography>
          }
          {
            labelComponent ?
              labelComponent :
              <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {label}
              </Typography>
          }
          {
            secondaryLabelComponent ?
              secondaryLabelComponent :
              <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {secondaryLabel}
              </Typography>
          }
          {thirdValue &&
            <>
              <Typography className={classes.loanDetailsCardItem} style={thirdValueStyle ? thirdValueStyle : valueStyle}>
                {thirdValue}
              </Typography>
              {thirdLabel && <Typography className={classes.loanDetailsCardLabel} style={labelStyle}>
                {thirdLabel}
              </Typography>}
            </>

          }
        </Box>
      </Box>
    </>
  );
});

export const LoanDetailsCustomCard = observer(
  ({ cardContentProps = {}, contentStyle = {}, children }) => {
    return (
      <CustomCard title={undefined} cardContentProps={cardContentProps}>
        <Box px={1} pb={.25} style={contentStyle}>
          <Grid container spacing={0}>
            {children}
          </Grid>
        </Box>
      </CustomCard>
    );
  }
);
