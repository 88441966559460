import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Layout } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import {
  LoanTabs,
  MyLoansCards,
  MyLoansGrid,
  MyPipelineLoansGrid,
  MyDraftLoansGrid,
  useLoanStore,
  MyRequestLoansGrid,
  MyAgreedLoansGrid,
  MyAppraisalToDosGrid,
  MyQuoteLoansGrid
} from '@roc/feature-loans';
import {
  myPipelineLoansColumns,
  myDraftLoansColumns,
  myRequestLoansColumns,
  myPendingApprovalLoansColumns,
  myAgreedLoansColumns,
  myAppraisalToDosColumns,
  myQuoteLoansColumns,
} from './loanColumns';
import { UnreadMessages } from './unreadMessages';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Roles, insertIf } from '@roc/feature-utils';
import { makeStyles } from '@material-ui/core/styles';
import { useLenderPortalStore } from '../../hooks';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { useParams } from 'react-router';


const BACKUP_LOANS_TAB = 'BACKUP LOANS';

const useStyles = makeStyles({
  buttonIcon: {
    fontSize: 'inherit',
    marginRight: 0,
  },
});

const renderTabContent = (globalStore, loanStore) => {
  const { userStore } = useUserStore();
  switch (loanStore.selectedTabIndex) {
    case 0:
      return globalStore.isListView ? (
        <MyPipelineLoansGrid columns={myPipelineLoansColumns} />
      ) : null;
    case 1:
      return globalStore.isListView ? (
        <MyAgreedLoansGrid columns={myAgreedLoansColumns} />
      ) : null;
    case 2:
      return globalStore.isListView ? <MyLoansGrid /> : <MyLoansCards />;
    case 3:
      return globalStore.isListView ? (
        <MyAppraisalToDosGrid columns={myAppraisalToDosColumns} />
      ) : null;
    case 4:
      return globalStore.isListView ? (
        userStore.isJrOriginator ?
          <MyDraftLoansGrid columns={myDraftLoansColumns} /> :
          <MyQuoteLoansGrid columns={myQuoteLoansColumns} />
      ) : null;
    case 5:
      return globalStore.isListView ? (
        <MyRequestLoansGrid columns={userStore.allowLoanPricing ?
          myRequestLoansColumns : myPendingApprovalLoansColumns} />
      ) : null;
    case 6:
      return globalStore.isListView ? <MyLoansGrid isForBackUpLoans={true} /> : <MyLoansCards isForBackUpLoans={true} />;
    default:
      console.error('Invalid tab index');
      break;
  }
};

export const LoansHome = observer(() => {
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const { userStore } = useUserStore();
  const { selectedTabIndex } = loanStore;
  const [totalCountLoanRequests, setTotalCountLoanRequests] = useState(0);
  const classes = useStyles();

  const TABS = [
    {
      label: 'PIPELINE',
    },
    {
      label: 'AGREED LOANS',
    },
    {
      label: 'MY LOANS',
    },
    {
      label: 'MY TASKS',
    },
    ...insertIf(userStore.isJrOriginator, [
      {
        label: 'DRAFT LOANS',
      },
    ]),
    ...insertIf(!userStore.isJrOriginator, [
      {
        label: 'LOAN QUOTES',
      },
    ]),
    ...insertIf(!globalStore.userFeatures?.isLenderOriginator || !userStore.allowLoanPricing, [
      {
        label: 'LOAN REQUESTS',
        showBadge: !globalStore.userFeatures?.isLenderOriginator && totalCountLoanRequests > 0 ? totalCountLoanRequests : undefined
      },
    ]),
    ...insertIf(globalStore.userFeatures?.showBackUpLoans, [
      {
        label: BACKUP_LOANS_TAB
      },
    ]),
  ];

  useEffect(() => {
    const isComingBackFromLoanDetails = globalStore.lastHistoryAction === 'POP';
    if (!isComingBackFromLoanDetails) {
      loanStore.setSelectedTabIndex(0);
    }
    totalLoanRequestCount();
  }, []);

  useEffect(() => {
    if (BACKUP_LOANS_TAB === TABS[selectedTabIndex]?.label) {
      fetchToggleState();
      fetchIsBackgroundTaskBeingProcessed();
    }
  }, [selectedTabIndex])

  const showSwitchView = () => {
    switch (selectedTabIndex) {
      case 1:
        return true;
      default:
        return false;
    }
  };

  const totalLoanRequestCount = async () => {
    const response = await loanStore.getLoanRequestsTotalCount();
    let totalCount = Number(response);
    totalCount = totalCount ? totalCount : 0;
    setTotalCountLoanRequests(totalCount);
  }

  const fetchToggleState = async () => {
    loanStore.fetchConversationsToggleStatus(userStore?.userInformation?.userId);
  };

  const fetchIsBackgroundTaskBeingProcessed = async () => {
    loanStore.fetchIsBackgroundTaskBeingProcessed(userStore?.userInformation?.userId);
  }

  const handleConversationsButtonChange = async () => {
    loanStore.updateUserBackUpConversationToggle(userStore?.userInformation?.userId);
    loanStore.setConversationsToggle(!loanStore.conversationsToggle);
    loanStore.setIsBackgroundTaskBeingProcessed(!loanStore.isBackgroundTaskBeingProcessed);
  };

  const refresh = async () => {
    fetchIsBackgroundTaskBeingProcessed();
    loanStore.myBackUpLoansGridStore.refresh();
  };

  const showBackUpConversationsButton = loanStore.selectedTabIndex === 5 && loanStore.myBackUpLoansGridStore.gridData.data.rows.length > 0;

  return (
    <Layout>
      <Grid
        container
        direction="column"
        spacing={2}
        style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
      >
        <Grid item>
          <UnreadMessages />
        </Grid>
        <Grid
          item
          style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
        >
          <LoanTabs tabs={TABS} showSwitchView={showSwitchView} totalLoanRequests={totalCountLoanRequests} />
          {showBackUpConversationsButton && loanStore.conversationsToggle && !loanStore.isBackgroundTaskBeingProcessed &&
            <Alert severity="info">
              <AlertTitle><b>You have currently turned ON the setting to join all backup loans conversations.</b></AlertTitle>
              <Box>
                <Button color="primary" size="small" variant="outlined" onClick={() => handleConversationsButtonChange()}>
                  Remove me from all backup conversations
                </Button>
              </Box>
            </Alert>
          }
          {showBackUpConversationsButton && !loanStore.conversationsToggle && !loanStore.isBackgroundTaskBeingProcessed &&
            <Alert severity="info">
              <AlertTitle><b>You have currently turned OFF the setting to join all backup loans conversations.</b></AlertTitle>
              <Box>
                <Button color="primary" size="small" variant="outlined" onClick={() => handleConversationsButtonChange()}>
                  Add me to all backup conversations
                </Button>
              </Box>
            </Alert>
          }
          {showBackUpConversationsButton && loanStore.isBackgroundTaskBeingProcessed && loanStore.conversationsToggle &&
            <Alert severity="warning">
              <AlertTitle><b>Your request to join all backup conversations is currently being processed.</b></AlertTitle>
              <p>This may take few mins to complete. Click below to check the latest status of your request.</p>
              <Box mt={1}>
                <Button color="primary" size="small" variant="outlined" startIcon={<RefreshIcon className={classes.buttonIcon} />} onClick={() => refresh()}>
                  Check Status
                </Button>
              </Box>
            </Alert>
          }
          {showBackUpConversationsButton && loanStore.isBackgroundTaskBeingProcessed && !loanStore.conversationsToggle &&
            <Alert severity="warning">
              <AlertTitle><b>Your request to leave all backup conversations is currently being processed.</b></AlertTitle>
              <p>This may take few mins to complete. Click below to check the latest status of your request.</p>
              <Box mt={1}>
                <Button color="primary" size="small" variant="outlined" startIcon={<RefreshIcon className={classes.buttonIcon} />} onClick={() => refresh()}>
                  Check Status
                </Button>
              </Box>
            </Alert>
          }
          {renderTabContent(globalStore, loanStore)}
        </Grid>
      </Grid>
    </Layout>
  );
});
