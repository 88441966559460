import { formatDateIgnoreTimezone } from "@roc/feature-utils";
import { formatCurrency, formatNumberWithCommas, formatPercentage } from "@roc/ui/utils";
import moment from "moment";

export function formatDateField(value) {
  const date = moment(value, "YYYY-MM-DD", true);
  if (date.isValid()) {
    return formatDateIgnoreTimezone(date.toDate());
  } else {
    return "-";
  }
}

export function formatCurrencyField(value) {
  if (isNaN(value) || !value) {
    return "-";
  }
  return formatCurrency(value, 0);
}

export function formatPercentageField(value) {
  if (isNaN(value) || !value) {
    return "-";
  }
  return formatPercentage(value);
}

export function formatNumberField(value, minDecimals = 0, maxDecimals = 0) {
  let res = value;
  if (res && typeof res === 'string') {
    res = Number(res.replace(/,/g, ''));
  }

  if (isNaN(res) || !res) {
    return "-";
  }
  return formatNumberWithCommas(res, minDecimals, maxDecimals);
}

export function convertStringNumberToNumber(value) {
  if (value && typeof value === 'string') {
    return Number(value.replace(/,/g, ''));
  }
  return value;
}

export function getStringOrDefault(value) {
  if (value) {
    return value;
  }
  return "-";
}

export function getFullAddress({ address, city, state, zipCode }) {
  return `${address}, ${city}, ${state} ${zipCode}`;
}