import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { useStore } from '@roc/client-portal-shared/hooks';
import { useUserStore } from '@roc/feature-app-core';
import { BorrowerLoanFastTrackPaymentDialog, LOAN_FASTRACK } from '@roc/feature-loan-fasttrack';
import { useLoanStore } from '@roc/feature-loans';
import {
  ACH_FORM,
  APPRAISAL_DETAILS,
  BORROWER_BACKGROUND_REVIEW,
  BORROWER_CREDIT_REVIEW,
  BORROWER_ENTITY,
  BORROWER_SETUP,
  LOAN_FAST_TRACK_PAYMENT,
  RESOLUTION_ITEM_FORM,
  SCOPE_OF_WORK_FORM_V2,
  SCOPE_OF_WORK_LINK_V2,
  TRACK_RECORD,
  TRACK_RECORD_REVIEW
} from '../../constants';
import { useDocumentStore } from '../../hooks/useDocumentStore';
import { BorrowerBackgroundReviewDialog } from './borrowerBackgroundReview/borrowerBackgroundReviewDialog';
import { NewBorrowerBaseballCardDialog } from './borrowerBaseballCard/newBorrowerBaseballCardDialog';
import { TrackRecordLineItemDialog } from './borrowerBaseballCard/trackRecordLineItemDialog';
import { BorrowerCreditReviewDialog } from './borrowerCreditReview/borrowerCreditReviewDialog';
import BorrowerEntityDialog from './borrowerEntity/borrowerEntityDialog';
import ResolutionItem from './resolutionItem/resolutionItem';
import { AchConsentFormDialog } from '@roc/feature-plaid';
import { TamariskAppraisalOrderPaymentContainerDialog } from '@roc/feature-tamarisk-payments';
import { BorrowerScopeOfWorkV2LinkDialog, ScopeOfWorkV2FormDialog } from '@roc/feature-sow-v2';
import { BorrowerSetupToDoDialog } from '@roc/feature-borrowers';
import { LoanProcess } from '@roc/feature-utils';

export type BorrowerDocumentFormsProps = {
  loanId: string;
};

export const BorrowerDocumentForms = observer((props: BorrowerDocumentFormsProps) => {
  const { loanId } = props;
  const { loanStore } = useLoanStore();
  const {
    documentStore: { documentFormStore, currentDocument },
  } = useDocumentStore();
  const { globalStore } = useStore();

  const { documentStore } = useDocumentStore();
  const closeCurrentForm = () => {
    documentFormStore.closeCurrentForm();
  };

  const closeNoteAllongeAndAssignment = () => {
    documentFormStore.onCloseNoteAllongeAndAssignment();
  }

  const plaidShareBaseURL = window.location.href.split('lender-portal');
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const currentForm = documentFormStore.currentForm;
  const isClosingConditionForm = currentForm && currentForm.includes('Closing Condition - ');

  useEffect(() => {
    return () => {
      documentFormStore.reset();
    };
  }, [documentFormStore]);
  return (
    <>
      <BorrowerBackgroundReviewDialog
        open={documentFormStore.currentForm === BORROWER_BACKGROUND_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <BorrowerCreditReviewDialog
        open={documentFormStore.currentForm === BORROWER_CREDIT_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <NewBorrowerBaseballCardDialog
        open={documentFormStore.currentForm === TRACK_RECORD_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <TrackRecordLineItemDialog
        open={documentFormStore.currentForm === TRACK_RECORD}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <ResolutionItem
        open={documentFormStore.currentForm === RESOLUTION_ITEM_FORM}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
      />
      <BorrowerEntityDialog
        open={documentFormStore.currentForm === BORROWER_ENTITY}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <BorrowerLoanFastTrackPaymentDialog
        open={documentFormStore.currentForm === LOAN_FAST_TRACK_PAYMENT}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <AchConsentFormDialog
        open={documentFormStore.currentForm === ACH_FORM}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <TamariskAppraisalOrderPaymentContainerDialog
        open={documentFormStore.currentForm === APPRAISAL_DETAILS}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <BorrowerScopeOfWorkV2LinkDialog
        open={documentFormStore.currentForm === SCOPE_OF_WORK_LINK_V2}
        onClose={closeCurrentForm}
        loanId={currentDocument?.loanId}
        document={currentDocument}
      />
      <ScopeOfWorkV2FormDialog
        open={documentFormStore.currentForm === SCOPE_OF_WORK_FORM_V2}
        onClose={closeCurrentForm}
        store={documentFormStore.scopeOfWorkV2FormStore}
      />
      <BorrowerSetupToDoDialog
        open={documentFormStore.currentForm === BORROWER_SETUP}
        borrowerId={currentDocument?.borrowerId}
        loan={loanStore.loanDetails}
        handleClose={closeCurrentForm}
        refreshLoan={() => {
          loanStore.refreshLoanDetails();
          documentStore.refetchDocuments();
        }}
      />
      {/* Add additional forms in here */}
    </>
  );
});
