import { Box } from '@material-ui/core';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import {
  CLOSING,
  flatten,
  isFundedLoan,
  LoanProcess,
  LoanStatus,
} from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { TodoStatus } from '../../utils/loanDetailsTypes';
import { CustomCard } from '../customCard';
import { TitleWithTodoCount, TodoCard, TodoCardCompponent } from './todoCard';
import { colorByStatus } from '../todoStatusChip';
import { isPendingTodoStatus } from '../../utils/todoUtils';
import { Button } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useHistory } from 'react-router';

export const LoanSummaryTodosCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { loanDetails } = loanStore;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanId = loanDetails.loanId;

  const showUnderwritingTodos = [
    LoanStatus.PENDING_SUBMISSION,
    LoanStatus.PENDING,
    LoanStatus.IN_UNDERWRITING,
  ].includes(loanDetails?.status);

  const showClosingTodos = [
    LoanStatus.PENDING_DECISION,
    LoanStatus.AGREED,
  ].includes(loanDetails?.status);

  useEffect(() => {
    if (showUnderwritingTodos) {
      documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    } else if (showClosingTodos) {
      documentStore.fetchProcessDocuments(loanId, CLOSING);
    }
  }, [loanId]);

  const todos =
    documentStore.loanTodosStore.todosByLoanProcess[LoanProcess.UNDERWRITING] ?? [];

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    }
  }, [loanId, currentForm]);

  let pendingTodos = [];
  if (showUnderwritingTodos) {
    pendingTodos =
      documentStore.loanTodosStore.todosByLoanProcess[
      LoanProcess.UNDERWRITING
      ] ?? [];
  } else if (showClosingTodos) {
    let documents = documentStore.closingDocuments;
    pendingTodos = flatten(Object.values(documents)).map(doc =>
      documentStore.loanTodosStore.mapDocumentToTodo(doc, LoanProcess.CLOSING)
    );
  }
  pendingTodos = pendingTodos.filter(t => isPendingTodoStatus(t.status));

  const hideTodosCard =
    [
      LoanStatus.PAID_OFF,
      LoanStatus.PAID_OFF_BUYOUT,
      LoanStatus.DECLINED,
      LoanStatus.RETRACTED,
      LoanStatus.ON_HOLD,
    ].includes(loanDetails?.status) || pendingTodos.length == 0;

  return (
    <>
      {!hideTodosCard && (
        <CustomCard title={<TitleWithTodoCount count={pendingTodos.length} />}
          cardHeaderProps={{
            action: (
              <Box pt={1}>
                <Button testId={'seeAllTodos'} variant='contained' color='primary' unsetTextTransfrom unsetLetterSpacing onClick={() => {
                  if (showUnderwritingTodos) {
                    push(loanRoutesConfig.loans(loanId).children.underwriting.url)
                  } else {
                    push(loanRoutesConfig.loans(loanId).children.closing.url)
                  }
                }}>
                  View All To-dos
                </Button>
              </Box>
            ),
          }}
          cardContentProps={{
            style: {
              overflow: 'auto'
            }
          }}>
          <Box
            style={{
              maxHeight: '350px',
              position: 'relative',
              overflowY: 'auto',
              overflowX: 'hidden',
              minWidth: '1000px',
            }}
          >
            <TodoCardCompponent
              todos={pendingTodos}
              loanProcess={
                showUnderwritingTodos
                  ? LoanProcess.UNDERWRITING
                  : LoanProcess.CLOSING
              }
              allowStatusChange={false}
              showSectionActions={false}
              stickyHeader={true}
            />
          </Box>
        </CustomCard>
      )}
      <DocumentForms loanId={loanDetails.loanId} />
    </>
  );
});
