import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { isStabilizedBridge, LoanSubType } from '@roc/feature-utils';
import { insertIf, PURCHASE, REFINANCE } from '@roc/feature-utils';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { GlobalStore } from '@roc/feature-app-core';
import { sum } from '../../../utils/utils';

export const calculateThirdPartyCosts = loanAmount => {
  if (!loanAmount || loanAmount < 0) return 0;

  const THRESHOLDS = [200000, 500000];
  const RATES = [0.04, 0.03, 0.02];

  if (loanAmount <= THRESHOLDS[0]) {
    return loanAmount * RATES[0];
  } else if (loanAmount <= THRESHOLDS[1]) {
    return loanAmount * RATES[1];
  } else {
    return loanAmount * RATES[2];
  }
};

export const generateLoanDetailsTableData = (
  outputs,
  thirdPartyCosts = 0,
  loanPurpose,
  loanSubType
) => {
  return {
    categories: [
      {
        name: 'Purchase Price or Payoff Amount',
        fees: [
          ...insertIf(PURCHASE === loanPurpose, [
            {
              name: 'Acquisition Price',
              amount: formatCurrency(outputs?.purchasePrice),
            },
          ]),
          ...insertIf(REFINANCE === loanPurpose, [
            {
              name: 'Existing Debt Payoff',
              amount: formatCurrency(outputs?.existingDebtPayoff),
            },
          ]),
          {
            name: isStabilizedBridge(loanSubType)
              ? 'Loan Amount'
              : 'Initial Loan Amount',
            amount: formatCurrency(outputs?.initialLoanAmount),
          },
        ],
        total: formatCurrency(outputs?.totalPurchasePriceOrPayoffAmount),
      },
      {
        name: 'Reserves & Escrows',
        fees: [
          {
            name: `Interest Reserve (${outputs?.interestReserveFinanced ? 'Financed' : 'Minimum prepaid'
              })`,
            amount: formatCurrency(outputs?.interestReserve),
          },
        ],
        total: formatCurrency(outputs?.interestReserve),
      },
      {
        name: 'Lender Fees',
        fees: (outputs?.lenderFees ?? []).map(fee => ({
          name: fee.feeName,
          amount: formatCurrency(fee.feeAmount),
        })),
        total: formatCurrency(outputs?.totalLenderFees),
      },
      {
        name: 'Estimated Third Party Fees',
        fees: (outputs?.thirdPartyFees ?? []).map(fee => ({
          name: fee.feeName,
          amount: formatCurrency(fee.feeAmount),
        })),
        total: formatCurrency(outputs?.totalThirdPartyFees),
      },
    ],
    total: formatCurrency(outputs?.estimatedBorrowerCashToClose),
  };
};

export const mapDownloadableQuoteData = (
  store: QuoteTypeStore,
  globalStore: GlobalStore
) => {
  const properties: any[] = store.propertiesStore.getProperties();
  const broker = store.brokerStore.getBroker();
  const brokerFee = store.loanEconomicsStore.outputs?.thirdPartyFees?.find?.(
    fee => fee.feeName == 'Broker Origination Fees'
  );

  const fundedRetailLoanBorrower = store.borrowersStore
    .getBorrowers()
    .some(borrower => (borrower.fundedRetailLoans ?? 0) > 0);
  return {
    loanSubtype: store.loanSubtype,
    loanPurpose: store.propertiesStore.getProperties()?.[0]?.loanPurpose,
    properties: store.propertiesStore.getProperties(),
    borrowers: store.borrowersStore.getBorrowers(),
    entity: store.entityStore.getEntity(),

    ltv: store.loanEconomicsStore.calculatedFields.calculatedLTV,
    arltv: store.loanEconomicsStore.calculatedFields.afterRepairLTV,
    ltfc: store.loanEconomicsStore.calculatedFields.ltfc,

    brokerName: broker.hasBroker ? broker?.companyName?.trim?.() : null,
    brokerFee: broker.hasBroker
      ? `${formatCurrency(brokerFee?.feeAmount)} (${formatPercentage(
        brokerFee?.feePoints
      )})`
      : null,

    interestPaymentType: store.loanEconomicsStore.interestType,
    showFreeAppraisalNotification:
      !fundedRetailLoanBorrower &&
      !store.brokerStore.brokerFormStore.getValue('hasBroker') &&
      globalStore?.lenderInfo?.isInternal,

    outputs: store.loanEconomicsStore.outputs,
    economics: store.loanEconomicsStore.getLoanEconomics(),
  };
};

export const mapDownloadableTermSheetData = (
  termSheetData: any,
  globalStore: GlobalStore,
  loan: any,
) => {
  const data = termSheetData;
  const loanSubtype = data.loanProgram;
  // Load Borrowers
  const borrowers = data.borrowers.map((borrower, index) => {
    const [firstName = '', lastName = ''] = (borrower.fullName || '')
      .trim()
      .split(' ');

    return {
      firstName,
      lastName,
      ficoScore: borrower.ficoScore || '',
      percentageOfOwnership: borrower.percentageOfOwnership || 0,
      experience: borrower.experienceScore,
    };
  });

  const mapLoanPurpose = {
    Refi: REFINANCE,
    'Purchase and Rehab': PURCHASE,
  };

  // Load Property
  const properties = data.properties.map((property, index) => {
    return {
      ...property,
      currentMarketValue: property.asIsValue,
      constructionRenoSpendToDate: property.completedRenoToDate,
      marketValueAfterCompletion: property.afterRepairValue,
      loanPurpose: mapLoanPurpose[data.loanPurpose] || data.loanPurpose,
    };
  });

  // Load Economics
  const totalPurchasePrice = properties.reduce((sum, property) => {
    return sum + (property?.purchasePrice || 0);
  }, 0);

  //Entity
  const entityName = data.borrowerEntity ?? '';
  // outputs
  //this.loanEconomicsStore.interestReserveFinanced = data.interestReserveFinanced;

  let interestType;
  if (loanSubtype === LoanSubType.STABILIZED_BRIDGE) {
    interestType = 'Interest Only';
  } else {
    interestType =
      data.fullBoatInterest === 'No' ? 'As disbursed' : 'Full Boat';
  }

  const outputs = {
    interestReserveFinanced: data.interestReserveFinanced,
    ltcCatchupDrawAmount: data.ltcCatchupDrawAmount,
    totalPurchasePriceOrPayoffAmount: data.totalPurchasePriceOrPayoffAmount,
    totalLoanAmount: data.totalLoanAmount,
    initialLoanAmount: data.initialLoanAmount,
    constructionHoldback: data.constructionHoldback,
    interestReserve: data.interestReserve,
    interestReserveMonths: data.interestReserveMonths,
    existingDebtPayoff: data.existingDebtPayoff,
    purchasePrice: totalPurchasePrice,
    lenderFees: [
      {
        feeName: 'Origination Fees',
        feeAmount: data.lenderOriginationFees,
      },
      {
        feeName: 'Underwriting Fee',
        feeAmount: data.underwritingFee,
      },
      {
        feeName: 'Processing Fee',
        feeAmount: data.processingFee,
      },
      {
        feeName: 'Buy Down Fee',
        feeAmount: data.buyDownFee,
      },
      {
        feeName: 'Admin Fee',
        feeAmount: data.adminFee,
      },
      {
        feeName: 'Commitment Fee',
        feeAmount: data.commitmentFee,
      },
    ].filter(fee => fee.feeAmount !== 0),
    totalLenderFees: sum([
      data.lenderOriginationFees,
      data.underwritingFee,
      data.processingFee,
      data.buyDownFee,
      data.adminFee,
      data.commitmentFee,
    ]),
    thirdPartyFees: [
      {
        feeName: 'Broker Origination Fees',
        feeAmount: data.brokerOriginationFees,
      },
      {
        feeName: 'Servicing Setup Fee',
        feeAmount: data.servicingSetupFee,
      },
      {
        feeName: 'Legal Review Fee',
        feeAmount: data.legalReviewFee,
      },
      {
        feeName: 'Corelogix Fee',
        feeAmount: data.corelogixFee,
      },
      {
        feeName: 'Title, Insurance and Other Estimated Costs',
        feeAmount: data.thirdPartyCosts,
      },
    ],
    totalThirdPartyFees:
      data.legalReviewFee +
      data.servicingSetupFee +
      data.brokerOriginationFees +
      data.thirdPartyCosts +
      data.corelogixFee,

    estimatedBorrowerCashToClose:
      data.legalReviewFee +
      data.interestReserve +
      data.servicingSetupFee +
      data.brokerOriginationFees +
      data.thirdPartyCosts +
      data.lenderOriginationFees +
      data.underwritingFee +
      data.totalPurchasePriceOrPayoffAmount +
      data.corelogixFee +
      data.adminFee +
      data.buyDownFee +
      data.commitmentFee +
      data.processingFee,
  };

  // Economics
  const economics = {
    interestRate: data.interestRate,
    loanTermMonths: data.duration,
    loanToCost: data.initialLTC,
    manualQuoteLoantoCost: data.initialLTC,
    enableManualQuote: true,
  };

  const fundedRetailLoanBorrower = borrowers.some(
    borrower => (borrower.fundedRetailLoans ?? 0) > 0
  );
  const hasBroker = !!(data.brokerName || '').trim();

  return {
    loanSubtype: data.loanProgram,
    loanPurpose: properties?.[0]?.loanPurpose,
    properties: properties,
    borrowers: borrowers,
    entity: {
      entityName,
    },

    ltv: data.ltv,
    arltv: data.arltv,
    ltfc: data.ltfc,

    brokerName: data.brokerName,
    brokerFee: data.brokerOriginationFees,

    interestPaymentType: interestType,
    showFreeAppraisalNotification:
      loan?.freeAppraisalPromotion,

    outputs: outputs,
    economics: economics,
  };
};
