import {
  Badge,
  Box,
  Chip,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import {
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { bridgeLoanSubTypes, PORTAL_COLORS } from '@roc/feature-utils';
import {
  LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, StatusChip,
  StatusType, TextColumn, FastForwardIcon
} from '@roc/ui';
import { GridColumnProps } from '@roc/client-portal-shared/components';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanPriority } from '@roc/feature-loans';
import { formatCurrency } from '@roc/ui/utils';
import { useUserStore } from '@roc/feature-app-core';
import { BackOfficeLoansStore } from '../../stores/backOfficeLoansStore';


const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};

const noOpComparator = () => 0;
const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

const getUnreadMessagesCount = unreadMessages => {
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  if (unreadMessages && allowLoanPricing) {
    return unreadMessages > 99 ? '99+' : unreadMessages;
  }
  return undefined;
};

export interface MyAgreedLoansBackOfficeGridProps {
  store: BackOfficeLoansStore;
}

export const MyAgreedLoansBackOfficeGrid = observer(({ store }: MyAgreedLoansBackOfficeGridProps) => {
  const { loanRoutesConfig } = useLoanRoutes();
  const [loanId, setLoanId] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [showMarkAsHighPriority, setShowMarkAsHighPriority] = useState(true);
  const [showChangePriority, setShowChangePriority] = useState(false);
  const [
    selectPriorityValue,
    setSelectPriorityValue,
  ] = useState<LoanPriority>();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params =>
      store.isLoanSubtypeSupported(params.node.data?.loanSubType) ? (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {LinkColumn({
            ...params,
            url: loanRoutesConfig.loans(params.value).children.dashboard.url,
          })}
        </StyledBadge>
      ) : (
        <StyledBadge
          badgeContent={getUnreadMessagesCount(
            params.node.data?.unreadMessages
          )}
        >
          {TextColumn(params)}
        </StyledBadge>
      ),
    pendingDocumentsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (
        <Box pt={1} display="flex" justifyContent="center">
          <Chip
            size="small"
            label={value}
            style={{
              backgroundColor: PORTAL_COLORS.pendingDocuments,
              color: 'white',
              fontWeight: 700,
            }}
          />
        </Box>
      );
    },
    fastTrackCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      return (<FastForwardIcon value={value} />)
    },
    insuranceApprovedCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      const backgroundColor = value === 'Pending' ? '#d6828a' : '#89C053';
      return (<Chip
        size="small"
        label={value}
        style={{
          backgroundColor: backgroundColor,
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    closingDocsCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      const backgroundColor = value === 'Pending' ? '#d6828a' : '#89C053';
      return (<Chip
        size="small"
        label={value}
        style={{
          backgroundColor: backgroundColor,
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    hudStatusCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      const backgroundColor = value === 'Pending' ? '#d6828a' : '#89C053';
      return (<Chip
        size="small"
        label={value}
        style={{
          backgroundColor: backgroundColor,
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    loanApprovalCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      const backgroundColor = value === 'Pending' ? '#d6828a' : '#89C053';
      return (<Chip
        size="small"
        label={value}
        style={{
          backgroundColor: backgroundColor,
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    wireRequestedCellRenderer: ({ value }) => {
      if (!value) {
        return null;
      }
      const backgroundColor = value === "Yes" ? '#89C053' : '#d6828a';
      return (<Chip
        size="small"
        label={value}
        style={{
          backgroundColor: backgroundColor,
          color: 'white',
          fontWeight: 700,
        }}
      />)
    },
    pendingClosingConditionsCellRenderer: ({ value }) => {
      const backgroundColor = value === 'Pending'
        ? '#d6828a'
        : value === 'Being Reviewed'
          ? '#F5B945'
          : '#89C053';
      return (
        <Chip
          size="small"
          label={value}
          style={{
            backgroundColor: backgroundColor,
            color: 'white',
            fontWeight: 700,
          }}
        />
      );
    },
    dealNameCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip label={value} size="small" statusType={StatusType.LOAN} />
      ) : null,
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const {
      dealid,
      priority,
      loanSubType,
      keyDocumentsCompletedForSFRLoans,
    } = record;

    const isBridgeLoan = bridgeLoanSubTypes.includes(loanSubType);

    setLoanId(dealid);

    setSelectPriorityValue(priority);
    if (isBridgeLoan) {
      setShowChangePriority(true);
      setShowMarkAsHighPriority(false);
    } else {
      setShowChangePriority(false);
      setShowMarkAsHighPriority(
        priority?.toLowerCase() === 'low' && keyDocumentsCompletedForSFRLoans
      );
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const checkLoanPricingFlag = (columns) => {
    if (!allowLoanPricing) {
      return columns.filter(c => c.field != "interestEconomics" && c.field != "keyRatios");
    } else {
      return columns;
    }
  }

  const columns: GridColumnProps[] = [
    {
      field: 'dealid',
      headerName: 'Loan Id',
      minWidth: 100,
      cellRenderer: 'loanIdCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      pinned: 'left',
      lockPinned: true,
    },
    {
      field: 'dealName',
      headerName: 'Deal Name',
      minWidth: 300,
      cellRenderer: 'dealNameCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'loanSubType',
      headerName: 'Loan Type',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'attorney',
      headerName: 'Attorney',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      valueFormatter: usdAmount,
      comparator: noOpComparator,
    },
    {
      field: 'pendingClosingConditions',
      headerName: 'Closing Conditions',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: 'pendingClosingConditionsCellRenderer',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'expectedClosingDate',
      headerName: 'Expected Closing Date',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'expectedWireReleaseDate',
      headerName: 'Expected Wire Release Date',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'fastrackPaymentComplete',
      headerName: 'Fast Tracked Loan',
      minWidth: 125,
      cellRenderer: 'fastTrackCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'insuranceApprovedStatus',
      headerName: 'Insurance Approved Status',
      minWidth: 125,
      cellRenderer: 'insuranceApprovedCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'closingDocsStatus',
      headerName: 'Closing Docs Status',
      cellRenderer: 'closingDocsCellRenderer',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'hudStatus',
      headerName: 'HUD Status',
      cellRenderer: 'hudStatusCellRenderer',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'finalLoanApprovalStatus',
      headerName: 'Loan Approval Status',
      minWidth: 300,
      cellRenderer: 'loanApprovalCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'wireRequested',
      headerName: 'Wire Requested',
      minWidth: 300,
      cellRenderer: 'wireRequestedCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
  ];

  return (
    <>
      <InfiniteScrollGrid
        columns={checkLoanPricingFlag(columns)}
        frameworkComponents={frameworkComponents}
        store={store.myAgreedLoansGridStore}
      />
    </>
  );
});