import { GlobalStore, GridStore, ApiResponse } from "@roc/feature-app-core";
import { InsuranceDashboardInternalService } from "../services/insuranceDashboardService";
import { action, makeObservable, observable, computed } from "mobx";
import { InsuranceDashboardTab } from "../components/insuranceDashboard";
import { resolutionDashboardColumns, pendingQuoteDashboardColumns } from "../utils";


export class InsuranceDashboardStore {
  private globalStore: GlobalStore;
  protected insuranceDashboardService: InsuranceDashboardInternalService;
  public insuranceDashoboardGridStore: GridStore;
  public selectedTab: InsuranceDashboardTab = InsuranceDashboardTab.MY_PENDING_QUOTES;
  public selectedRecords: number[];
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.insuranceDashboardService = new InsuranceDashboardInternalService();
    this.insuranceDashoboardGridStore = new GridStore(() => this.fetchRecords());
    makeObservable(this, {
      selectedTab: observable,
      setSelectedTab: action,
      columns: computed,
      isQuoteDashboard: computed,
      setSelectedRecords: action
    });
  }

  setSelectedTab(tab: InsuranceDashboardTab) {
    this.selectedTab = tab;
  }

  setSelectedRecords(record: number, selected: boolean): boolean {
    if (selected) {
      this.selectedRecords.push(record);
      return true;
    }
    if (record != null && !selected) {
      this.selectedRecords.filter(r => r != record);
    }
    return false;
  }

  async fetchRecords() {
    try {
      const response: ApiResponse = await this.insuranceDashboardService.getInsuranceDashbboard(
        this.getEnumKey(),
        this.insuranceDashoboardGridStore.gridData.meta.pageNumber,
        this.insuranceDashoboardGridStore.gridData.meta.pageSize,
        this.insuranceDashoboardGridStore.gridData.meta.sortDir,
        this.insuranceDashoboardGridStore.gridData.meta.sortBy,
        this.insuranceDashoboardGridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching insurance records',
      });
    }
  }

  getEnumKey() {
    return Object.keys(InsuranceDashboardTab).find(key => InsuranceDashboardTab[key] == this.selectedTab);
  }

  get columns() {
    if (this.isQuoteDashboard) {
      return pendingQuoteDashboardColumns;
    } else {
      return resolutionDashboardColumns;
    }
  }

  get isQuoteDashboard() {
    return [InsuranceDashboardTab.ALL_PENDING_QUOTES, InsuranceDashboardTab.MY_PENDING_QUOTES].includes(this.selectedTab);
  }
}