import { Grid, Menu, ListItemText, ListItemIcon, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, Clear, PlaylistAddCheck } from '@material-ui/icons';
import { Button, StandardDialog, ConfirmationMenuItem, TextField, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useBaseStore } from '@roc/feature-app-core';
import { OrderTaxCertificateModal } from './orderTaxCertificateModal';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';

const useStyles = makeStyles(theme => ({

}));


export const TaxCertificateToolbar = observer(() => {
  const classes = useStyles();
  const [showOrderCertificateModal, setShowOrderCertificateModal] = useState(false)
  const { taxCertificateStore } = useTaxCertificateStore();
  const { taxCertificateGridStore } = taxCertificateStore;

  const createTaxCertificate = (loanId) => {
    taxCertificateStore.createTaxCertificate(loanId);
    setShowOrderCertificateModal(false)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              testId="create-order-bpo"
              onClick={() => {
                setShowOrderCertificateModal(true);
              }}
            >
              Order Certificate
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
      <OrderTaxCertificateModal
        showOrderCertificateModal={showOrderCertificateModal}
        closeOrderModal={() => setShowOrderCertificateModal(false)}
        handleOrderConfirm={createTaxCertificate}
      />
    </>


  );
});
