import { Grid } from '@material-ui/core';
import { StandardDialog, Button } from '@roc/ui';
import CreditReportForm, { } from 'libs/feature-borrower-review/src/components/utils/creditReportForm'

export type CreditReportProps = {
  open?: boolean;
  onClose?: () => void;
  loanTaskId?: number;
};

export const CreditReport = (
  props: CreditReportProps
) => {
  const { open, onClose, loanTaskId } = props;

  return (
    <StandardDialog
      open={open}
      title="Credit Report - Upload"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={(
        <div style={{ margin: '-40px -32px 0px -32px' }}>
          <CreditReportForm open={true} handleClose={onClose} loanTaskId={loanTaskId} internal={false} />
        </div>
      )}
    />
  );
};