import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ExpandMore, Person } from '@material-ui/icons';
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import { EditBorrowersForm, useLoanStore } from '@roc/feature-loans';
import { LoanSubType, LoanType, sanitizeTestId } from '@roc/feature-utils';
import { StandardDialog, SubmitDialog, TestDataAttribute } from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';
import BorrowerEntityOverview from 'libs/feature-loans/src/loans/loanDetails/components/borrowerEntityOverview';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { CustomCard, CustomCardDividers, CustomCardRow } from '../customCard';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  borrowerText2: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
  },
}));

export const CommissionsCard = observer(() => {
  const classes = useStyles();
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanCommissionStore } = useLoanCommissionStore();
  const { loanStore } = useLoanStore();
  const { loanId } = useParams<{ loanId: string }>();
  const { loanSummary } = loanSummaryStore;
  const { commissionData } = loanCommissionStore;
  const { loanDetails } = loanStore;

  useEffect(() => {
    loanCommissionStore.resetStore();
    loanSummaryStore.fetchLoanSummary(loanId);
  }, [loanId]);

  useEffect(() => {
      loanCommissionStore.getLoanCommission(loanId);
  }, [loanSummaryStore.loanSummary, loanStore.loanDetails]);

  const sum = (a, b) => a + b;

  const getTotalTermFees = fees => {
    if (fees) {
      const retailCustomFees = fees.retailCustomFees
        ? JSON.parse(fees.retailCustomFees)
        : [];
      const customFeeTotal = retailCustomFees
        .map(customFee => parseFloat(customFee.retailValue || 0))
        .reduce(sum, 0);
      return (
        customFeeTotal +
        parseFloat(fees.retailProcessingFee ?? 0) +
        parseFloat(fees.retailAdminFee ?? 0) +
        parseFloat(fees.retailUnderwritingFee ?? 0) +
        parseFloat(fees.retailCommitmentFee ?? 0) +
        parseFloat(fees.retailBuydownFee ?? 0)
      );
    }
  };

  const getTotalBridgeFees = fees => {
    if (fees) {
      return (
        parseFloat(loanSummary?.underwritingFee ?? 0) +
        parseFloat(loanSummary?.processingFee ?? 0) +
        parseFloat(fees.processingFee ?? 0) +
        parseFloat(fees.adminFee ?? 0) +
        parseFloat(fees.underwritingFee ?? 0) +
        parseFloat(fees.commitmentFee ?? 0) +
        parseFloat(fees.lenderBuydownFee ?? 0)
      );
    }
  };

  const isBridge = loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE;
  const isTerm = loanSummary?.loanType === LoanType.RESIDENTIAL_TERM;
  const isMultifamilyBridge =
    loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;

  return (
    <CustomCard title="Commissions" cardContentProps={{
      style: {
        padding: 0
      }
    }}>
      <Grid container>
        {isBridge && (
          <>
            <Grid item xs={12}>
              <Box>
                <List>
                  {listItem('Loan Amount', formatCurrency(loanSummary?.amount ?? 0))}
                  {divider}
                  {listItem('Rate', formatPercentage(loanSummary?.rocRate ?? 0))}
                  {divider}
                  {listItem('Points', formatPercentage(loanSummary?.rocPointsIn ?? 0))}
                  {divider}
                  {listItem('Total Fees', formatCurrency(
                    getTotalBridgeFees(loanDetails?.loanLenderDetails) ?? 0
                  ))}
                  {divider}
                  {listItem(
                    'Your Commission %',
                    formatPercentage(commissionData?.commissionPercentage ?? 0, 4),
                    true
                  )}
                  {divider}
                  {!isMultifamilyBridge ? (
                    <>
                      {listItem('Your Commission Amount', formatCurrency(commissionData?.commissionAmount ?? 0), true)}
                    </>
                  ) : (
                    <>
                      {listItem('Your Closing Commission Amount', formatCurrency(commissionData?.entryCommissionAmount ?? 0), true)}
                      {divider}
                      {listItem('Your Payoff Commission Amount', formatCurrency(commissionData?.exitCommissionAmount ?? 0), true)}
                    </>
                  )}
                </List>
              </Box>
            </Grid>
          </>
        )}
        {isTerm && (
          <>
            <Grid item xs={12}>
              <Box>
                <List>
                  {listItem('Loan Amount', formatCurrency(loanSummary?.amount ?? 0))}
                  {divider}
                  {listItem('Yield Spread Premium', formatPercentage(loanSummary?.lenderPremium ?? 0))}
                  {divider}
                  {listItem('Add-on Origination Points', formatPercentage(loanSummary?.loanRetailFees?.retailPoints ?? 0))}
                  {divider}
                  {listItem('Rate Buydown Points', formatPercentage(loanSummary?.rocPointsIn ?? 0))}
                  {divider}
                  {listItem('Total Fees', formatCurrency(
                    getTotalTermFees(loanSummary?.loanRetailFees) ?? 0
                  ))}
                  {divider}
                  {listItem(
                    'Your Commission %',
                    formatPercentage(commissionData?.commissionPercentage ?? 0,4),
                    true
                  )}
                  {divider}
                  {listItem('Your Commission Amount', formatCurrency(commissionData?.commissionAmount ?? 0), true)}
                </List>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </CustomCard>
  );
});

const listItem = (label, value, bold = false, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1} mr={1}>
            {bold ? <strong>{label}</strong> : label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{bold ? <strong>{value}</strong> : value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;