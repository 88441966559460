import {
  DateFormat,
  DescribedRennovation,
  formatDate,
  insertIf,
  isEmptyString,
  isNotBlank,
  LoanSubType,
  NEW_CONSTRUCTION,
  PURCHASE,
  REFINANCE,
} from '@roc/feature-utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { PropertySource, SquareFootageExpansion } from './constants';

export function getValueOrDefaultDash(value): string {
  return value ? value.toString() : '-';
}

export function getBooleanDisplayValue(value): string {
  return value ? 'Yes' : 'No';
}

export const sum = values =>
  values.reduce((total, value) => total + (Number(value) || 0), 0);

export const parseDate = date => {
  return date ? formatDate(date, DateFormat.MMDDYYYY) : '-';
};

export const LTC_CATCHUP_PERCENTAGE = 15;

export const calculateLtcCatchupAmount = purchasePrice => {
  return LTC_CATCHUP_PERCENTAGE / 100 * purchasePrice;
};
export const mapTermSheetData = fields => {
  const formatCurrencyValue = value => {
    if (value === null || value === undefined) {
      return '-';
    }
    return formatCurrency(value, 2);
  };

  const formatPercentageValue = value => {
    if (value === null || value === undefined) {
      return '-';
    }
    return formatPercentage(value, 2);
  };
};

export const mapPropertyFields = (values, loanSubType): any => {
  const ltcCatchupDrawAmount = calculateLtcCatchupAmount(
    values.purchasePrice ?? 0
  );
  const isPurchase = values.loanPurpose === PURCHASE;
  const isRefinance = values.loanPurpose === REFINANCE;
  const isWholesale = PropertySource.WHOLESALER == values.propertySourcing;
  const exitStrategyRefinance = values.exitStrategy === REFINANCE;
  const describeRenovation = loanSubType === LoanSubType.GROUND_UP 
    ? NEW_CONSTRUCTION
    : values.describeRenovation;
  const isAddingSquareFootage = DescribedRennovation.NO != describeRenovation;
  const data = {
    units: values.units,
    propertyId: values.propertyId,
    address: values.address,
    streetNumber: values.streetNumber,
    streetName: values.streetName,
    aptNumber: values.aptNumber,
    city: values.city,
    state: values.state,
    county: values.county,
    zipCode: values.zipCode,
    latitude: values.latitude,
    longitude: values.longitude,
    propertyType: values.propertyType,
    loanPurpose: values.loanPurpose,
    payoffAmount: values.payoffAmount,
    subdividedIntoLots: values.subdividedIntoLots,
    numberOfLots: values.numberOfLots,
    loanEverBeenInDefault: values.loanEverBeenInDefault,
    permitsInPlace: isAddingSquareFootage ? values.permitsInPlace : undefined,
    ltcCatchupDraw: values.ltcCatchupDraw,
    ltcCatchupDrawAmount: values.ltcCatchupDraw ? ltcCatchupDrawAmount : 0,
    hasBeenRecentlyRenovated: values.hasBeenRecentlyRenovated,
    soonToBeListedForSale: values.soonToBeListedForSale,
    currentlyOccupied: values.currentlyOccupied,
    annualTaxes: values.annualTaxes,
    monthlyMarketRent: values.monthlyMarketRent,
    annualInsurance: values.annualInsurance,
    holdingCosts: values.holdingCosts,
    currentMarketValue: values.currentMarketValue,
    renovationBudget: values.renovationBudget,
    marketValueAfterCompletion: values.marketValueAfterCompletion,
    squareFootageExpansion: values.squareFootageExpansion,
    isAppraisalAvailable: values.isAppraisalAvailable,
    purchasePrice: values.purchasePrice,
    wholesalerPropertyContractAmount: isWholesale
      ? values.wholesalerPropertyContractAmount
      : undefined,
    propertySourcingExplanation:
      PropertySource.OTHER == values.propertySourcing
        ? values.propertySourcingExplanation
        : undefined,

    ...(isPurchase
      ? {
        propertySourcing: values.propertySourcing,
        sellerCredit: values.sellerCredit,
        sellerCreditAmount: values.sellerCredit
          ? values.sellerCreditAmount
          : undefined,
        borrowerKnowOwnerOfRecord: values.borrowerKnowOwnerOfRecord,
        armsLengthComment: values.armsLengthComment,
        constructionRenoSpendToDate: values.constructionRenoSpendToDate,
      }
      : {}),
    ...(isRefinance
      ? {
        purchaseDate: formatDate(values.purchaseDate, DateFormat.MMDDYYYY),
        outstandingLoanOnTheProperty: values.outstandingLoanOnTheProperty,
        loanEverBeenInDefault: values.outstandingLoanOnTheProperty
          ? values.loanEverBeenInDefault
          : undefined,
        constructionRenoSpendToDate: values.constructionRenoSpendToDate,
      }
      : {}),
    condoConversion: describeRenovation ? describeRenovation == DescribedRennovation.CONDO_CONVERSION : values.condoConversion,
    fireDamage: describeRenovation ? describeRenovation == DescribedRennovation.FIRE_DAMAGE : values.fireDamage,
    squareFootageAdded: isAddingSquareFootage,
    hasNewAddress: values.hasNewAddress,
    exitStrategy: values.exitStrategy,
    newAddress: values.newAddress,
    ...(exitStrategyRefinance ? {
      monthlyMarketRentProvidedAtOrigination: values.monthlyMarketRentProvidedAtOrigination,
      annualHOAProvidedAtOrigination: values.annualHOAProvidedAtOrigination,
      annualTaxesProvidedAtOrigination: values.annualTaxesProvidedAtOrigination,
      annualInsuranceProvidedAtOrigination: values.annualInsuranceProvidedAtOrigination,
    } : {}),
    releaseAmount: values.releaseAmount, // Term Sheet
    propertyLoanAllocatedAmount: values.propertyLoanAllocatedAmount, // Term Sheet
    describeRenovation: describeRenovation,
  };

  return Object.keys(data).reduce((obj, key) => {
    const hasValue =
      data[key] !== null && data[key] !== '' && data[key] !== undefined;
    if (hasValue) {
      obj[key] = data[key];
    }
    return obj;
  }, {});
};

export const buildAddress = (property) => {
  const address = property.address || '';
  const city = property.city || '';
  const state = property.state || '';
  const zipCode = property.zipCode || '';

  const fullAddress = `${address}, ${city}, ${state} ${zipCode}`.trim();

  return fullAddress;
}
export const formatPercentageValue = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  return formatPercentage(value, 2);
};