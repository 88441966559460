import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { formatDateField } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: "#595959",
    fontSize: "16px",
  },
  fieldValue: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

interface ReportDateProps {
  date: Date;
}

export const ReportDate = ({ date }: ReportDateProps) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <Typography className={classes.label}>Date</Typography>
      <Typography className={classes.fieldValue}>{formatDateField(date)}</Typography>
    </Box>
  );
};