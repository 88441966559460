import { Box, Grid } from '@material-ui/core';
import { CreateAppraisalForm, useLoanStore } from '@roc/feature-loans';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';

type Props = {
  buttonStyle?: any
}

export const CreateAppraisalOrderButton = observer(({ buttonStyle }: Props) => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;

  const [openCreateAppraisalModal, setOpenCreateAppraisalModal] = useState(
    false
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          className={buttonStyle}
          onClick={() => setOpenCreateAppraisalModal(true)}
          color="primary"
          testId="create_appraisal_order"
        >
          Create Appraisal Order
        </Button>
      </Box>

      <StandardDialog
        open={openCreateAppraisalModal}
        title="Create Appraisal Order"
        maxWidth="md"
        handleClose={() => setOpenCreateAppraisalModal(false)}
        dialogContent={
          <CreateAppraisalForm
            onConfirm={(gfdPayment: boolean) => {
              setOpenCreateAppraisalModal(false);
              loanStore.submitLoanAppraisalOrder(loanId, gfdPayment);
            }}
            onClose={() => setOpenCreateAppraisalModal(false)}
          />
        }
      />
    </>
  );
});
