import { ApiResponse, GlobalStore, UserStore } from '@roc/feature-app-core';
import { action, computed, flow, makeObservable, observable, runInAction } from 'mobx';
import { CreateNewConversationStore } from './createNewConversationStore';
import { ManageParticipantsStore } from './manageParticipantsStore';

import { groupBy, isElectronApp, isLocalHost, uniq, extractContent, isNil } from '@roc/feature-utils';
import {
  Client, Conversation, DetailedDeliveryReceipt, Message, Paginator, Participant
} from "@twilio/conversations";
import { getAuthorName, getTypingMessage, handlePromiseRejection } from '../helper';
import { ICommunicationService } from '../services/iCommunicationService';
import {
  ChatRoomType,
  ConversationAttribute, ConversationChatRoom, ConversationChatRoomType, ConversationChatRoomStatus, ConversationData, ConversationGroupMap, ConversationMentioned, ConversationMessageDefaultTag, ConversationsPromiseType, ConversationStarred, ConversationsType, ConversationTags, ConversationTwilioIdentityType, ConversationWriteAccess, Filter, MessageAttribute, MessagesType, ParticipantAttribute, ParticipantType, PromiseHolderType, TwilioIdentityClientType, TypingDataType, UnreadMessagesCountType, ChatRoomStatus, RequireParticipant, MessagesPaginatorType, TwilioReplyMessageType, ConversationMessage, FetchConversationMessageRequest, ConversationGroup, MessagesGroupedByDateType, PrivateChatRoomResponse, MessageStatus, MessageDeliveryReceipts, MessageDeliveryParticipantData,
  ConversationHasBorrower,
  ConversationLastMentionedDate,
  ChatAppNotificationType,
  SupportedEmojis,
} from './../types/communicationTypes';
import { compareDesc } from 'date-fns';
import { StartPrivateConversationStore } from './startPrivateConversationStore';

const ConversationSortOrder = {
  "UNDERWRITING": 1,
  "CLOSING": 2,
  "INSURANCE": 3
};

export abstract class CommunicationStore {
  globalStore: GlobalStore;
  public userStore: UserStore;
  createNewConversationStore: CreateNewConversationStore;
  startPrivateConversationStore: StartPrivateConversationStore;
  manageParticipantsStore: ManageParticipantsStore;
  communicationService: ICommunicationService;
  conversations: ConversationsType = {};
  conversationTwilioIdentity: ConversationTwilioIdentityType = {};
  conversationChatRoom: ConversationChatRoom = {};
  conversationHasBorrower: ConversationHasBorrower = {};
  conversationChatRoomType: ConversationChatRoomType = {};
  conversationChatRoomStatus: ConversationChatRoomStatus = {};
  conversationGroupMap: ConversationGroupMap = {};
  conversationStarred: ConversationStarred = {};
  conversationMentioned: ConversationMentioned = {};
  conversationLastMentionedDate: ConversationLastMentionedDate = {};
  requiredParticipant: RequireParticipant = {};
  conversationWriteAccess: ConversationWriteAccess = {};
  twilioIdentityClients: TwilioIdentityClientType = {};
  messages: MessagesType = {};
  twilioReplyMessages: TwilioReplyMessageType = {};
  messagesPaginator: MessagesPaginatorType = {};
  unreadMessagesCount: UnreadMessagesCountType = {};
  unreadTechSupportMessagesCount: UnreadMessagesCountType = {};
  participants: ParticipantType = {};
  typingData: TypingDataType = {};
  conversationMessageDefaultTag: ConversationMessageDefaultTag = {};
  conversationTags: ConversationTags = {};
  conversationTagsSelected: ConversationTags = {};
  currentConversation: Conversation;
  currentConversationSid: string;
  showTitleCard: boolean = false;
  showNoConversationsMessage: boolean = false;
  loading: boolean = false;
  isInternal: boolean = false;
  internalPortalUserInfo: { userId: number, userName: string };
  messageSnippets: Array<any> = [];
  preserveUnreadCount: boolean = false;
  errorMessageSent: boolean = false;
  shouldSortConversationsByLastMessageDate: boolean = false;
  subscribeToAllUpdates: boolean = false;
  showMessagePreview: boolean = false;
  loadAllMessagesChatRoomTypes: ChatRoomType[] = [];
  filters: Filter[] = [];
  checkForNewTwilioIdentityIntervalId: any;
  showReloadConversationSnackBar: boolean = false;
  loadingCurrentConversation: boolean = false;
  query: string;
  conversationGroup: ConversationGroup;
  loadingMoreResults: boolean;
  totalUserConversationsUnreadCount: number;
  taskId: number;
  updateLogConversationId: string;
  updateLogMessages: any;

  constructor(
    globalStore,
    userStore,
    communicationService,
    isInternal = false,
    shouldSortConversationsByLastMessageDate = false,
    subscribeToAllUpdates = false,
    showMessagePreview = true,
    loadAllMessagesChatRoomTypes = [],
  ) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.createNewConversationStore = new CreateNewConversationStore(this.globalStore, this, communicationService);
    this.startPrivateConversationStore = new StartPrivateConversationStore(this.globalStore, this, communicationService);
    this.manageParticipantsStore = new ManageParticipantsStore(this.globalStore, this, communicationService);
    this.communicationService = communicationService;
    this.isInternal = isInternal;
    // if loading from an iframe, get userId and userName from querystring
    if (this.isInternal) {
      this.internalPortalUserInfo = this.getInternalPortalUserInfo();
    }
    this.shouldSortConversationsByLastMessageDate = shouldSortConversationsByLastMessageDate;
    this.subscribeToAllUpdates = subscribeToAllUpdates;
    this.showMessagePreview = showMessagePreview;
    this.loadAllMessagesChatRoomTypes = loadAllMessagesChatRoomTypes;
    makeObservable(this, {
      conversations: observable,
      conversationTwilioIdentity: observable,
      conversationChatRoom: observable,
      conversationHasBorrower: observable,
      conversationChatRoomType: observable,
      conversationChatRoomStatus: observable,
      conversationGroupMap: observable,
      conversationStarred: observable,
      conversationMentioned: observable,
      conversationLastMentionedDate: observable,
      requiredParticipant: observable,
      conversationWriteAccess: observable,
      twilioIdentityClients: observable,
      messages: observable,
      twilioReplyMessages: observable,
      messagesPaginator: observable,
      unreadMessagesCount: observable,
      unreadTechSupportMessagesCount: observable,
      participants: observable,
      typingData: observable,
      conversationMessageDefaultTag: observable,
      conversationTags: observable,
      conversationTagsSelected: observable,
      currentConversation: observable,
      currentConversationSid: observable,
      showTitleCard: observable,
      showNoConversationsMessage: observable,
      loading: observable,
      isInternal: observable,
      internalPortalUserInfo: observable,
      messageSnippets: observable,
      preserveUnreadCount: observable,
      errorMessageSent: observable,
      filters: observable,
      showReloadConversationSnackBar: observable,
      loadingCurrentConversation: observable,
      totalUserConversationsUnreadCount: observable,
      reset: action,
      typingMessage: computed,
      isCurrentUser: action,
      doesCurrentUserLikedTheMessage: action,
      loadOneConversation: action,
      fetchUserConversations: action,
      doesUserHasAnyConversations: action,
      loadMyConversations: action,
      rephraseMessage: action,
      joinConversationBychatRoomId: flow,
      joinConversationByconversationSid: flow,
      setShowTitleCard: flow,
      clearCurrentConversation: flow,
      setCurrentConversationId: flow,
      setCurrentConversation: flow,
      getConversationDisplayName: action,
      loadCurrentConversationPreviousPageMessages: flow,
      loadCurrentConversationPreviousPageMessagesRecursivelyTillMessageFound: action,
      getName: action,
      getNameInCamelCase: action,
      getloggedInParticpant: action,
      sendMessage: flow,
      likeMessage: flow,
      undoLikeMessage: flow,
      updateConversationName: action,
      totalUnreadMessagesCount: computed,
      doesUserHaveUnreadMessages: computed,
      getOrCreatePrivateChatRoom: action,
      createNewConversation: flow,
      unarchiveConversation: flow,
      resetAndFetchData: flow,
      removeConversation: action,
      postAddParticipant: flow,
      isConversationReadOnly: action,
      sendErrorEmail: action,
      getAllMessageSnippets: flow,
      getMessageDeliveryReceipts: action,
      removeMentionMarker: flow,
      currentChatRoomId: computed,
      currentChatRoomType: computed,
      currentConversationGroup: computed,
      userId: computed,
      userName: computed,
      currentConversationTags: computed,
      currentConversationTagsSelected: computed,
      currentConversationReadOnly: computed,
      currentConversationFullReadOnly: computed,
      currentConversationIsArchived: computed,
      currentConversationIsPrivateRoom: computed,
      currentConversationIsLoanConversation: computed,
      filtersKey: computed,
      chatAppNotification: computed,
      chatAppNotificationType: computed,
      setCurrentConversationTagsSelected: flow,
      setFilter: flow,
      setFilters: flow,
      setShowReloadConversationSnackBar: action,
      isSupportConversation: computed,
      isProposalTopicConversation: computed,
      isTaskConversation: computed,
      query: observable,
      conversationGroup: observable,
      saveSearchFilter: action,
      searchQuery: computed,
      currentConversationMessagesGroupedByDate: computed,
      searchConversationGroup: computed,
      setLoadingMoreResults: action,
      loadingMoreResults: observable,
      loadAllPreviousMessages: flow,
      isBorrowerRoom: action,
      getTotalUserConversationsUnreadCount: flow,
      shouldAutoLoadAllPreviousMessages: computed,
      _sortConversationsByLastMessageDate: action,
      updateLogConversationId: observable,
      updateLogMessages: observable,
      taskId: observable,
      setTaskId: flow,
    });
  }

  reset(withoutFilter = false) {
    Object.keys(this.twilioIdentityClients).forEach(identity => {
      this.twilioIdentityClients[identity]?.removeAllListeners();
      this.twilioIdentityClients[identity]?.shutdown();
      delete this.twilioIdentityClients[identity];
    });
    Object.keys(this.conversations).forEach(sid => {
      this.conversations[sid]?.removeAllListeners();
    });
    this.showNoConversationsMessage = false;
    this.conversations = {};
    this.conversationTwilioIdentity = {};
    this.conversationChatRoom = {};
    this.conversationHasBorrower = {};
    this.conversationChatRoomType = {};
    this.conversationChatRoomStatus = {};
    this.conversationGroupMap = {};
    this.conversationStarred = {};
    this.conversationMentioned = {};
    this.conversationLastMentionedDate = {};
    this.requiredParticipant = {};
    this.conversationWriteAccess = {};
    this.twilioIdentityClients = {};
    this.messages = {};
    this.twilioReplyMessages = {};
    this.messagesPaginator = {};
    this.unreadMessagesCount = this.preserveUnreadCount ? this.unreadMessagesCount : {};
    this.participants = {};
    this.typingData = {};
    this.conversationMessageDefaultTag = {};
    this.conversationTags = {};
    this.conversationTagsSelected = {};
    this.currentConversation = undefined;
    this.currentConversationSid = undefined;
    this.loading = false
    this.manageParticipantsStore.reset();
    this.errorMessageSent = false;
    this.updateLogConversationId = undefined;
    this.updateLogMessages = [];
    if (!withoutFilter) {
      this.filters = [];
    }
  }

  get typingMessage() {
    return this.currentConversationSid ? getTypingMessage(this.typingData[this.currentConversationSid]) : undefined;
  }

  isCurrentUser(identity: string) {
    const loggedInParticipant = this.getloggedInParticpant(this.participants[this.currentConversationSid]);
    return loggedInParticipant?.identity == identity;
  }

  doesCurrentUserLikedTheMessage(messageLikes: string[]) {
    const loggedInParticipant = this.getloggedInParticpant(this.participants[this.currentConversationSid]);
    return loggedInParticipant?.sid && messageLikes.includes(loggedInParticipant.sid);
  }

  async rephraseMessage(message: string) {
    try {
      const newMessage: ApiResponse = await this.communicationService.rephraseMessage(message)
      return newMessage?.data?.data?.text
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timed out');
        return message
      } else {
        console.error(error.message);
        return message
      }
    }
  }

  async loadOneConversation(conversationSid: string, callback: (error: boolean) => void) {
    try {
      let response: ApiResponse = await this.communicationService.getMyConversation({
        userId: this.userId,
        conversationSid,
      });
      const conversationData: ConversationData = response?.data?.data?.result ?? [];
      this._processConversations([conversationData], undefined, () => {
        callback(false);
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: "Error fetching conversation " + conversationSid
      });
      callback(true);
    }
  }

  async fetchUserConversations(
    filters: Filter[]
  ) {
    try {
      let response: ApiResponse = await this.communicationService.getMyConversations({
        userId: this.userId,
        start: 0,
        count: 5,
        filters
      });
      const conversationsData: ConversationData[] = response?.data?.data?.result?.rows ?? [];
      const totalCount: number = response?.data?.data?.result?.totalCount;
      return {
        conversationsData,
        totalCount
      };
    } catch (e) {
      return {};
    }
  }

  async doesUserHasAnyConversations(
    filters: Filter[]
  ) {
    const result = await this.fetchUserConversations(filters);
    return result?.totalCount > 0;
  }

  async loadMyConversations(
    start: number,
    pageSize: number,
    onSuccess: (totalCount: number, rows: ConversationData[]) => void,
    onError: () => void = () => { },
    silentRefreshMyConversations: boolean = false,
  ) {
    try {
      // Get My Conversations
      let response: ApiResponse = await this.communicationService.getMyConversations({
        userId: this.userId,
        start: start,
        count: pageSize,
        filters: this.filters
      });
      const conversationsData: ConversationData[] = response?.data?.data?.result?.rows ?? [];
      const totalCount: number = response?.data?.data?.result?.totalCount;
      this._processConversations(conversationsData, totalCount, () => {
        if (conversationsData?.length == 0) {
          this.showNoConversationsMessage = true;
        }
        onSuccess(totalCount, conversationsData);
      }, silentRefreshMyConversations);
    } catch (e) {
      if (!silentRefreshMyConversations) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error fetching my conversations',
        });
      }
      onError();
    }
  }

  private async _loadAllMessagesFromConversation(
    client: Client,
    conversationSid: string,
    promiseHolder: PromiseHolderType<Conversation>,
  ) {
    try {
      const conv = await client.getConversationBySid(conversationSid);
      await this._loadAllMessages(conv);
      promiseHolder.resolve(conv);
    } catch (e) {
      console.error("Unable to fetch conversation from Twilio : " + conversationSid);
      promiseHolder?.resolve(null);
    }
  }

  async _loadAllMessages(conv) {
    let messagesPaginator = await conv.getMessages(30);
    let allMessages = messagesPaginator.items;

    while (messagesPaginator.hasPrevPage) {
      messagesPaginator = await messagesPaginator.prevPage();
      allMessages = [...messagesPaginator.items, ...allMessages];
    }

    this._addMessages(conv.sid, allMessages);
  }

  protected _processOneConversationAndLoadAllMessages(
    conversation: ConversationData,
    onComplete: () => void,
  ) {
    if (!conversation) {
      if (this.query?.length > 0) {
        this._setConversations([]);
        onComplete();
        return;
      }
      onComplete();
      return;
    }

    this.conversationTwilioIdentity[conversation.conversationSid] = conversation.twilioIdentity;
    this.conversationChatRoom[conversation.conversationSid] = conversation.chatRoomId;
    this.conversationChatRoomType[conversation.conversationSid] = conversation.chatRoomType;
    this.conversationChatRoomStatus[conversation.conversationSid] = conversation.status;
    this.conversationGroupMap[conversation.conversationSid] = conversation.conversationGroup;
    this.conversationStarred[conversation.conversationSid] = conversation.isStarred;
    this.conversationMentioned[conversation.conversationSid] = conversation.hasMentions;
    this.conversationLastMentionedDate[conversation.conversationSid] = conversation.lastMentionedDate;
    this.conversationWriteAccess[conversation.conversationSid] = this._checkForWriteAccess(conversation.twilioIdentity);
    this.requiredParticipant[conversation.conversationSid] = conversation.requiredParticipant;

    const promiseHolder: PromiseHolderType<Conversation> = this._createPromise();
    const conversationPromiseType: ConversationsPromiseType = {
      [conversation.conversationSid]: promiseHolder,
    };

    if (this.twilioIdentityClients[conversation.twilioIdentity]) {
      this._loadAllMessagesFromConversation(
        this.twilioIdentityClients[conversation.twilioIdentity],
        conversation.conversationSid,
        promiseHolder
      );
    } else {
      this.setupTwilioClient(conversation.twilioIdentity, [conversation], conversationPromiseType);
    }

    promiseHolder.promise.then(conversationResult => {
      if (conversationResult) {
        console.log("Conversation Loaded");
        this._loadAllMessages(conversationResult);
      }
      onComplete();
    });
  }

  protected _processConversations(
    conversations: ConversationData[],
    totalcount: number,
    onComplete: () => void,
    silentRefreshMyConversations: boolean = false,
  ) {
    // if there are no conversations, then simply return
    if (conversations?.length == 0) {
      if (this.query?.length > 0) {
        this._setConversations([]);
        onComplete();
        return;
      }
      onComplete();
      return;
    }

    // format the response as needed by the UI
    const allConversationPromises: Promise<Conversation>[] = [];
    const conversationPromiseType: ConversationsPromiseType = {};
    conversations.forEach((x) => {
      this.conversationTwilioIdentity[x.conversationSid] = x.twilioIdentity;
      this.conversationChatRoom[x.conversationSid] = x.chatRoomId;
      this.conversationChatRoomType[x.conversationSid] = x.chatRoomType;
      this.conversationChatRoomStatus[x.conversationSid] = x.status;
      this.conversationGroupMap[x.conversationSid] = x.conversationGroup;
      this.conversationStarred[x.conversationSid] = x.isStarred;
      this.conversationMentioned[x.conversationSid] = x.hasMentions;
      this.conversationLastMentionedDate[x.conversationSid] = x.lastMentionedDate;
      this.conversationWriteAccess[x.conversationSid] = this._checkForWriteAccess(x.twilioIdentity);
      this.requiredParticipant[x.conversationSid] = x.requiredParticipant;

      const p: PromiseHolderType<Conversation> = this._createPromise();
      allConversationPromises.push(p.promise);
      conversationPromiseType[x.conversationSid] = p;
    });

    const map = groupBy(conversations, c => c.twilioIdentity);
    Object.keys(map).forEach(y => {
      if (this.twilioIdentityClients[y]) {
        map[y].forEach(c => {
          this._loadConversation(
            this.twilioIdentityClients[y],
            c.conversationSid,
            conversationPromiseType[c.conversationSid]
          );
        });
      } else {
        this.setupTwilioClient(y, map[y], conversationPromiseType);
      }
    });

    Promise.all(allConversationPromises).then(x => {
      console.log("All Conversations Loaded - " + x?.length);
      this._setConversations(x.filter(y => y ? true : false), silentRefreshMyConversations);
      onComplete();
    });
  }

  *joinConversationBychatRoomId(chatRoomId: number, loanId: number, conversationSid: string, successMessage: string) {
    // add participant
    yield this.manageParticipantsStore.addParticipant(chatRoomId, this.userName, loanId, successMessage);
    // make changes to the conversation after adding participant
    yield this.postAddParticipant(conversationSid);
  }

  *joinConversationByconversationSid(conversationSid: string, successMessage: string) {
    yield this.joinConversationBychatRoomId(this.conversationChatRoom[conversationSid], null, conversationSid, successMessage);
  }

  *setFilter(filter: Filter) {
    yield this.setFilters([filter]);
  }

  *setFilters(filters: Filter[]) {
    this.filters = filters;
  }

  *setShowTitleCard(flag) {
    this.showTitleCard = flag;
  }

  *clearCurrentConversation() {
    delete this.twilioReplyMessages[this.currentConversationSid];
    this.currentConversationSid = undefined;
    this.currentConversation = undefined;
    this.updateLogConversationId = undefined;
  }

  *setCurrentConversationId(
    conversationId: string,
  ) {
    this.currentConversationSid = conversationId;
  }

  *setCurrentConversation(
    conversation: Conversation,
  ) {
    this.loadingCurrentConversation = true;
    this.currentConversation = conversation;
    this.currentConversationSid = (conversation.sid);

    // update participants
    this._updateParticipants(this.currentConversation);

    // Get last page messages
    let pageSize = 30;
    let messagesPaginator: Paginator<Message> = yield conversation.getMessages(pageSize);
    let messages: Message[] = messagesPaginator.items;
    if (messagesPaginator.hasPrevPage) {
      this.messagesPaginator = Object.assign({}, this.messagesPaginator, {
        [conversation.sid]: messagesPaginator,
      });
    }

    // Should load all previous messages too in this conversation?
    // This is needed for all `Tags` to populate and work properly
    if (this.shouldAutoLoadAllPreviousMessages) {
      yield this.loadAllPreviousMessages(messages);
    }
    else {
      yield this._populateTwilioReplyMessages(conversation.sid, messages);
      this.messages[conversation.sid] = messages;
    }

    yield this._markAllMessagesAsRead();
    this.loadingCurrentConversation = false;
  }

  getConversationDisplayName(
    conversation: Conversation,
  ) {
    const conversationAttributes: ConversationAttribute = conversation?.attributes;
    if (conversationAttributes?.chatRoomType == ChatRoomType.PRIVATE_ROOM
      && this.userStore?.userInformation?.fullName) {
      let result = conversation.friendlyName;
      result = result.replace(`${this?.userStore?.userInformation?.fullName}, `, '');
      result = result.replace(`, ${this?.userStore?.userInformation?.fullName}`, '');
      return result;
    }
    return conversation?.friendlyName;
  }

  private async _markAllMessagesAsRead() {
    // update unread messages count (twilio)
    this.setUnreadMessagesCount(this.currentConversationSid, 0);
    const lastMessage =
      this.messages[this.currentConversationSid].length &&
      this.messages[this.currentConversationSid][this.messages[this.currentConversationSid].length - 1];
    if (lastMessage && lastMessage.index !== -1) {
      await this.currentConversation.updateLastReadMessageIndex(lastMessage.index);
    }
  }

  *loadCurrentConversationPreviousPageMessages() {
    // Get previous page messages by paginating backwards
    const _messagePaginator = this.messagesPaginator[this.currentConversationSid];
    if (_messagePaginator?.hasPrevPage) {
      const _newMessagePaginator: Paginator<Message> = yield _messagePaginator?.prevPage();
      yield this._populateTwilioReplyMessages(this.currentConversationSid, _newMessagePaginator.items);
      this._addMessages(this.currentConversationSid, _newMessagePaginator.items);
      if (_newMessagePaginator.hasPrevPage) {
        this.messagesPaginator[this.currentConversationSid] = _newMessagePaginator;
      }
      else {
        delete this.messagesPaginator[this.currentConversationSid];
      }
    }
    else {
      delete this.messagesPaginator[this.currentConversationSid];
    }
  }

  *loadAllPreviousMessages(messagesToAdd = []) {
    let messagesPaginator = this.messagesPaginator[this.currentConversationSid];
    let count = 0;
    const MAX_PREVIOUS_PAGES = 50;
    let _messagesToAdd = messagesToAdd;
    while (messagesPaginator?.hasPrevPage && count < MAX_PREVIOUS_PAGES) {
      messagesPaginator = yield messagesPaginator?.prevPage();
      _messagesToAdd = [...messagesPaginator.items, ..._messagesToAdd];
      if (messagesPaginator.hasPrevPage) {
        this.messagesPaginator[this.currentConversationSid] = messagesPaginator;
      } else {
        delete this.messagesPaginator[this.currentConversationSid];
      }
      count = count + 1;
    }
    if (_messagesToAdd.length) {
      yield this._populateTwilioReplyMessages(
        this.currentConversationSid,
        _messagesToAdd
      );
      this._addMessages(this.currentConversationSid, _messagesToAdd);
    }
  }

  async loadCurrentConversationPreviousPageMessagesRecursivelyTillMessageFound(messageSidToFind: string) {
    // Recursively fetch previous page messages until the message is found
    let messagesPaginator = this.messagesPaginator[this.currentConversationSid];
    let messages: Message[] = [];
    if (messagesPaginator?.hasPrevPage) {
      while (messagesPaginator?.hasPrevPage) {
        messagesPaginator = await messagesPaginator?.prevPage();
        const messageToFind = messagesPaginator.items.find(m => m.sid == messageSidToFind);
        messages = [...messagesPaginator.items, ...messages];
        if (messageToFind) {
          if (messages.length) {
            if (messagesPaginator.hasPrevPage) {
              this.messagesPaginator[this.currentConversationSid] = messagesPaginator;
            }
            else {
              delete this.messagesPaginator[this.currentConversationSid];
            }
            await this._populateTwilioReplyMessages(this.currentConversationSid, messages);
            this._addMessages(this.currentConversationSid, messages);
          }
          break;
        }
      }
    }
  }

  *setCurrentConversationTagsSelected(newValues: string[]) {
    this.conversationTagsSelected[this.currentConversationSid] = new Set(newValues);
  }

  getParticipantUserId(participant: Participant) {
    const { userId } = participant.attributes as ParticipantAttribute;
    return userId;
  }

  getName(participant: Participant) {
    const { firstName, lastName, isSystemUser } = participant.attributes as ParticipantAttribute;
    const displayName = isSystemUser ? "SYSTEM" : this.getNameInCamelCase(firstName, lastName);
    return displayName;
  }

  getNameInCamelCase(firstName: string, lastName: string) {
    firstName = firstName.charAt(0).toUpperCase() + firstName.substring(1, firstName.length).toLowerCase();
    lastName = lastName?.charAt(0).toUpperCase() ?? '';
    return firstName + " " + lastName;
  }

  getloggedInParticpant(participants: Participant[]) {
    const loggedInParticipant = participants.find(x => {
      return x.identity == this.conversationTwilioIdentity[this.currentConversationSid];
    });
    return loggedInParticipant;
  }

  *sendMessage(message: String, attachments: File[], replyToMessage: Message, mentionsArr: string[], callBack: () => void) {
    const _messages = this.messages[this.currentConversationSid];
    const messagesToSend = [];
    const messagesData = [];
    const currentDate: Date = new Date();
    const loggedInParticipant = this.getloggedInParticpant(this.participants[this.currentConversationSid]);

    let messageAttributes: MessageAttribute = {
      authorName: this.getName(loggedInParticipant),
      mentions: mentionsArr
    };

    if (this.conversationMessageDefaultTag[this.currentConversationSid]) {
      messageAttributes.tags = [this.conversationMessageDefaultTag[this.currentConversationSid]];
    }

    if (replyToMessage?.sid) {
      messageAttributes.replyMessageSid = replyToMessage.sid;
    }

    if (message) {
      const newMessage: Message = Object.assign({}, _messages[_messages.length], {
        ...(_messages[_messages.length] as Message),
        author: loggedInParticipant.identity,
        attributes: messageAttributes,
        body: message,
        dateCreated: currentDate,
        index: -1,
        participantSid: loggedInParticipant.sid,
        sid: this.currentConversationSid,
        aggregatedDeliveryReceipt: null,
      }) as Message;
      messagesToSend.push(newMessage);
      messagesData.push(message);
    }

    this.loading = true;
    /*if (!attachments.length) {
      this._addMessages(this.currentConversationSid, messagesToSend);
    }*/

    yield handlePromiseRejection(async () => {
      const indexes = [];
      for (const msg of messagesData) {
        const builder = await this.currentConversation.prepareMessage();
        builder.setBody(msg);
        builder.setAttributes(messageAttributes);
        for (let file of attachments) {
          builder.addMedia({
            contentType: file.type,
            filename: file.name,
            media: file,
          });
        }
        const index = await builder.build().send();
        indexes.push(index);
      }
      this.loading = false;
      await this.currentConversation.updateLastReadMessageIndex(Math.max(...indexes));
      callBack();
    }, () => {
      this.loading = false;
      this.removeMessages(this.currentConversationSid, messagesToSend);
      this.globalStore.notificationStore.showErrorNotification({ message: "Unable to send message" });
    });
  }

  *likeMessage(message: Message, likedBy: string) {
    const currentMessageAttributes = message.attributes as MessageAttribute;
    const emoji = currentMessageAttributes?.emoji ?? {};
    const currentLikes = emoji?.[SupportedEmojis.Likes] ?? [];
    const updatedLikes = Array.from(new Set([...currentLikes, likedBy]));
    const newAttributes = {
      ...currentMessageAttributes,
      emoji: {
        ...emoji,
        [SupportedEmojis.Likes]: updatedLikes
      }
    };
    yield message.updateAttributes(newAttributes);
  }

  *undoLikeMessage(message: Message, removeLikedBy: string) {
    const currentMessageAttributes = message.attributes as MessageAttribute;
    const emoji = currentMessageAttributes?.emoji ?? {};
    const currentLikes = emoji?.[SupportedEmojis.Likes] ?? [];
    const updatedLikes = currentLikes.filter((like) => like !== removeLikedBy);
    const newAttributes: MessageAttribute = {
      ...currentMessageAttributes,
      emoji: {
        ...emoji,
        [SupportedEmojis.Likes]: updatedLikes
      }
    };
    return message.updateAttributes(newAttributes);
  }

  // overridden method
  abstract setConversationMessageDefaultTag(conversationAttributes: ConversationAttribute, conversationInfo);

  // overridden method
  abstract resetUnreadCount(id: number);

  // overridden method
  abstract getParticipantsAllowedByLoanId(id, disableGlobalLoading: boolean);

  // overridden method
  abstract getParticipantsAllowedByChatRoomId(id, disableGlobalLoading: boolean);

  // overridden method
  abstract updateConversationStarredStatus(isStarred: boolean, chatRoomId: number, conversationSid: string, callBack?: () => void);

  // overridden method
  async updateConversationName(conversationName: string, chatRoomId: number, loanId?: number) {
    try {
      const response: ApiResponse = await this.communicationService.updateConversationName({
        chatRoomId,
        loanId,
        conversationName
      });
      return response.data?.data as boolean;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error updating conversation name',
      });
    }
  }

  get totalUnreadMessagesCount() {
    return Object.values(this.unreadMessagesCount).reduce((p, n) => p + n, 0)
  }

  get doesUserHaveUnreadMessages() {
    // this.totalUserConversationsUnreadCount check is for borrower-portal
    if (this.totalUserConversationsUnreadCount > 0) {
      return true;
    }
    if (this.totalUnreadMessagesCount > 0) {
      return true;
    }
    return false;
  }

  async getOrCreatePrivateChatRoom(userIds: number[]) {
    const response: ApiResponse = await this.communicationService.getOrCreatePrivateChatRoom(userIds);
    return response.data?.data as PrivateChatRoomResponse;
  }

  *createNewConversation(loanId: number, name: string, callback?: (conversationSid?: string) => void) {
    const nameExist: ApiResponse = yield this.communicationService.verifyChatRoomName(name, loanId, this.userId);
    if (nameExist?.data?.data?.exist) {
      this.globalStore.notificationStore.showErrorNotification({
        message: nameExist?.data?.data?.error,
      });
    } else {
      try {
        const response: ApiResponse = yield this.communicationService.addNewConversation(name, loanId);
        if (response?.data?.data) {
          const { data } = response;
          const { conversationSid, chatRoomId } = data.data;
          yield this.joinConversationBychatRoomId(chatRoomId, loanId, conversationSid, 'Conversation created successfully!');
          callback && callback(conversationSid);
          this.manageParticipantsStore.openDialog();
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Error while creating a new Conversation',
          });
          callback && callback();
        }
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while creating a new Conversation',
        });
      }
    }
  }

  async createNewUserConversation(loanId: number, userIds: number[]) {
    try {
      const participantAttributes = this.getloggedInParticpant(this.participants[this.currentConversationSid]).attributes as ParticipantAttribute;
      const dateCreated = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }) + ' EST';
      const conversationName = `${participantAttributes.firstName}'s room [created: ${dateCreated}]`;
      const response: ApiResponse = await this.communicationService.addNewUserConversation(loanId, userIds, conversationName);
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error creating a new Conversation',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating a new Conversation',
      });
    }
  }

  removeConversation(conversationSid: string) {
    if (conversationSid == this.currentConversationSid) {
      this.currentConversation = undefined;
      this.currentConversationSid = undefined;
      this.manageParticipantsStore.closeDialog();
    }
    if (this.conversations[conversationSid]) {
      this.conversations[conversationSid]?.removeAllListeners();
    }
    delete this.conversations[conversationSid];
    delete this.conversationTwilioIdentity[conversationSid];
    delete this.conversationChatRoom[conversationSid];
    delete this.conversationHasBorrower[conversationSid];
    delete this.conversationChatRoomType[conversationSid];
    delete this.conversationChatRoomStatus[conversationSid];
    delete this.conversationGroupMap[conversationSid];
    delete this.conversationStarred[conversationSid];
    delete this.conversationMentioned[conversationSid];
    delete this.conversationLastMentionedDate[conversationSid];
    delete this.requiredParticipant[conversationSid];
    delete this.conversationWriteAccess[conversationSid];
    delete this.messages[conversationSid];
    delete this.twilioReplyMessages[conversationSid];
    delete this.messagesPaginator[conversationSid];
    delete this.unreadMessagesCount[conversationSid];
    delete this.participants[conversationSid];
    delete this.typingData[conversationSid];
    delete this.conversationMessageDefaultTag[conversationSid];
    delete this.conversationTags[conversationSid];
    delete this.conversationTagsSelected[conversationSid];
  }

  *getTotalUserConversationsUnreadCount() {
    const response = yield this.communicationService.getTotalUnreadCount(this.userId);
    this.totalUserConversationsUnreadCount = response.data.data;
  }

  *unarchiveConversation() {
    try {
      yield this.communicationService.unarchiveConversation(this.conversationChatRoom[this.currentConversationSid]);
      this.resetAndFetchData();
      this.globalStore.notificationStore.showInfoNotification({
        message: 'Conversation was reopened succesfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while reopening conversation',
      });
    }
  }

  *resetAndFetchData() {
    this.loadMyConversations(1, 100, (totalCount) => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private async getToken(twilioIdentity): Promise<string> {
    const response: ApiResponse = await this.communicationService.getToken(twilioIdentity);
    return response.data.data;
  }

  private async setupTwilioClient(twilioIdentity, conversationsData: ConversationData[], conversationPromiseType: ConversationsPromiseType) {
    const accessToken = await this.getToken(twilioIdentity);
    this.initializeTwilioClient(twilioIdentity, accessToken)
      .then(client => {
        conversationsData.forEach(c => {
          if (this.updateLogConversationId === c.conversationSid) {
            this._loadAllMessagesFromConversation(
              client,
              c.conversationSid,
              conversationPromiseType[c.conversationSid]
            )
          } else {
            this._loadConversation(
              client,
              c.conversationSid,
              conversationPromiseType[c.conversationSid]
            );
          }
        });
      });
  }

  private getInternalPortalUserInfo() {
    const params = new URLSearchParams(location.search);
    return {
      userId: parseInt(params.get('userId')),
      userName: params.get('userName'),
    };
  }

  *postAddParticipant(conversationSid: string) {
    if (this.conversations[conversationSid]) {
      this.conversations[conversationSid]?.removeAllListeners();
    }
    yield this.loadOneConversation(conversationSid, () => { })
  }

  private interceptTwilioRequests(client: Client) {
    const socketClient = (client as any).options.twilsockClient;
    socketClient._get = socketClient.get;
    socketClient.get = async function (url) {
      const isConversationsEndpoint = /\/Services\/.+\/Users\/.+\/Conversations(?!\/)/.test(
        url
      );
      if (isConversationsEndpoint) {
        const response = {
          body: {
            conversations: [],
            meta: {
              next_token: null
            }
          }
        };
        return response;
      } else {
        return this._get(url);
      }
    };
  }

  private _checkIfTheClientShouldProcessSuppliedEvent(client: Client, conversation: Conversation) {
    // 1. if the conversation is not processed by any clients earlier, then proceed.
    // 2. if the conversation is already processed by some client, then check if the identities match and then process.
    // (for internal users there could be two clients (real user and system user) trying to process the same conversation)
    if (!this.conversationTwilioIdentity[conversation.sid] || this.conversationTwilioIdentity[conversation.sid] == client.user?.identity) {
      return true;
    }
    return false;
  }

  private initializeTwilioClient(twilioIdentity, accessToken) {
    const promiseHolder: PromiseHolderType<Client> = this._createPromise();
    const client = new Client(accessToken, {
      typingIndicatorTimeoutOverride: 1
    });
    this.interceptTwilioRequests(client);

    client.on('stateChanged', (state) => {
      if (state === 'initialized') {
        if (this.twilioIdentityClients[twilioIdentity]) {
          this.twilioIdentityClients[twilioIdentity].removeAllListeners();
          this.twilioIdentityClients[twilioIdentity].shutdown();
          delete this.twilioIdentityClients[twilioIdentity];
        }
        this.twilioIdentityClients[twilioIdentity] = client;
        promiseHolder.resolve(client);
      }
    });

    client.on("conversationAdded", async (conversation: Conversation) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, conversation)) {
        return;
      }
      conversation.on("typingStarted", (participant) => {
        if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, conversation)) {
          return;
        }
        handlePromiseRejection(() => {
          this.setTypingIndicator(participant, conversation.sid, this.startTyping.bind(this));
        }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
      });
      conversation.on("typingEnded", (participant) => {
        if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, conversation)) {
          return;
        }
        handlePromiseRejection(() => {
          this.setTypingIndicator(participant, conversation.sid, this.endTyping.bind(this));
        }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
      });
      handlePromiseRejection(() => {
        // for now, new conversations will automatically show up only when subscribeToAllUpdates is true
        // maybe with some logic, we can start listening to new conversations even if subscribeToAllUpdates is false
        /**
         * this.conversationTwilioIdentity[conversation.sid] = twilioIdentity;
         * const conversationAttributes = conversation.attributes as ConversationAttribute;
         * conversationAttributes.loanId == loanId
        */
        //skip if not subscribeToAllUpdates
        if (!this.subscribeToAllUpdates) {
          return;
        }
        //skip if some filter is applied
        if (this.filters?.length > 0) {
          return;
        }
        //skip if conversation is already picked up and processed
        if (this.conversationTwilioIdentity[conversation.sid]) {
          return;
        }
        console.log("Event: Conversation Added : " + conversation.sid);
        // had to delay this for 2 secs, for the participant to get added to the cache.
        setTimeout(() => {
          this.loadOneConversation(conversation.sid, () => { });
          /*this.flashTaskBarIcon();
          const attributes = (conversation.attributes as ConversationAttribute);
          const loanIdText = attributes.loanId ? `Loan# ${attributes.loanId} - ` : '';
          this.sendNotification({
            title: `${loanIdText}${this.getConversationDisplayName(conversation)}`,
            body: `You are added to the conversation`,
            conversationSid: conversation.sid
          });*/
        }, 2000);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("conversationUpdated", ({ conversation, updateReasons }) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        //skip if not subscribeToAllUpdates and coversation is not in the scope
        if (!this.subscribeToAllUpdates && !this.conversations[conversation.sid]) {
          return;
        }
        //skip if some filter is applied and coversation is not in the scope
        if (this.filters?.length > 0 && !this.conversations[conversation.sid]) {
          return;
        }
        console.log("Event: Conversation Updated : " + conversation.sid);
        // had to delay this for 1 sec, for the unread count to refresh in twilio end.
        setTimeout(() => {
          // if the conversation is current
          if (this.currentConversationSid == conversation.sid) {
            this._loadCurrentConversationUpdates(conversation);
          }
          //if the conversation is already processed, then simply refresh the data
          else if (this.conversations[conversation.sid]) {
            this._loadConversation(client, conversation.sid, undefined);
            this.flashTaskBarIcon();
          }
          // load conversation from backend
          else {
            this.loadOneConversation(conversation.sid, () => { });
            this.flashTaskBarIcon();
          }
        }, 1000);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("conversationRemoved", (conversation: Conversation) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, conversation)) {
        return;
      }
      console.log("Event: Conversation Removed : " + conversation.sid);

      if (isElectronApp() || this.requiredParticipant[conversation.sid]) {
        this.removeConversation(conversation.sid);
      } else {
        this.loadOneConversation(conversation.sid, () => { });
      }
    });

    client.on("messageAdded", (message: Message) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, message.conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        // send notification only if it satisfies both the conditions below
        // 1. the message is received from someone else and
        // 2. the client is "logged in user's client" (sometimes it process sytem user client)
        if (client.user?.identity?.startsWith(`${this.userId}_`)
          && !message.author?.startsWith(`${this.userId}_`)) {
          this.flashTaskBarIcon();
          const attributes = (message.conversation.attributes as ConversationAttribute);
          const messageAttributes = (message.attributes as MessageAttribute);
          const loanIdText = attributes.loanId ? `Loan# ${attributes.loanId} - ` : '';
          this.sendNotification({
            title: `${loanIdText}${this.getConversationDisplayName(message.conversation)}`,
            roomType: attributes.chatRoomType,
            from: `${getAuthorName(message)}`,
            body: extractContent(message.body)?.substring(0, 500),
            conversationSid: message.conversation.sid,
            hasMentions: messageAttributes?.mentions?.length > 0
          });
        }
        // skip if showMessagePreview is true (and) the message coversation is not in scope
        if (this.showMessagePreview && !this.conversations[message.conversation.sid]) {
          return;
        }
        // skip if showMessagePreview is false (and) the message coversation is not current
        if (!this.showMessagePreview && this.currentConversationSid !== message.conversation.sid) {
          return;
        }
        console.log("Event: Message Added : " + message.conversation.sid);
        this._addMessage(message);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("messageUpdated", ({ message }) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, message.conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        // skip if showMessagePreview is true (and) the message coversation is not in scope
        if (this.showMessagePreview && !this.conversations[message.conversation.sid]) {
          return;
        }
        // skip if showMessagePreview is false (and) the message coversation is not current
        if (!this.showMessagePreview && this.currentConversationSid !== message.conversation.sid) {
          return;
        }
        console.log("Event: Message Updated : " + message.conversation.sid);
        this.updateMessage(message.conversation.sid, message);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("messageRemoved", (message) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, message.conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        // skip if showMessagePreview is true (and) the message coversation is not in scope
        if (this.showMessagePreview && !this.conversations[message.conversation.sid]) {
          return;
        }
        // skip if showMessagePreview is false (and) the message coversation is not current
        if (!this.showMessagePreview && this.currentConversationSid !== message.conversation.sid) {
          return;
        }
        console.log("Event: Message Removed : " + message.conversation.sid);
        this.removeMessages(message.conversation.sid, [message]);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("participantJoined", (participant) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, participant.conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        // skip if the participant coversation is not current
        if (this.currentConversationSid !== participant.conversation.sid) {
          return;
        }
        console.log("Event: Participant Joined : " + participant.conversation.sid);
        this._updateParticipants(participant.conversation);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    /*client.on("participantUpdated", ({ participant, updateReasons }: { participant: Participant, updateReasons: ParticipantUpdateReason[] }) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, participant.conversation)) {
        return;
      }
      console.log("Event: Participant Updated : " + participant.conversation.sid);
      handlePromiseRejection(() => {
        // skip if the participant coversation is not current
        if (this.currentConversationSid !== participant.conversation.sid) {
          return;
        }
        this.updateParticipants(participant.conversation);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });*/

    client.on("participantLeft", (participant) => {
      if (!this._checkIfTheClientShouldProcessSuppliedEvent(client, participant.conversation)) {
        return;
      }
      handlePromiseRejection(() => {
        // skip if the participant coversation is not current
        if (this.currentConversationSid !== participant.conversation.sid) {
          return;
        }
        console.log("Event: Participant Left : " + participant.conversation.sid);
        this._updateParticipants(participant.conversation);
      }, (message) => this.globalStore.notificationStore.showErrorNotification({ message }));
    });

    client.on("tokenAboutToExpire", async () => {
      console.log("Event: Token About to Expire");
      await this.fetchNewTokenAndUpdate(client, twilioIdentity);
    });

    client.on("tokenExpired", async () => {
      console.log("Event: Token Expired");
      await this.fetchNewTokenAndUpdate(client, twilioIdentity);
    });

    client.on('connectionError', ({ message }) => {
      console.log("Event: Connection Error");
      if (!this.errorMessageSent) {
        this.errorMessageSent = true;
        const twilsockUrl = (<any>client).options?.transport?.config?.url;
        //this.sendErrorEmail(message, twilsockUrl);
      }
    });

    return promiseHolder.promise;
  }

  setShowReloadConversationSnackBar(flag) {
    this.showReloadConversationSnackBar = flag;
  }

  private async _loadConversation(
    client: Client,
    conversationSid: string,
    promiseHolder: PromiseHolderType<Conversation>,
  ) {
    try {
      const conv = await client.getConversationBySid(conversationSid);
      if (conv.status === "joined") {
        await this._loadUnreadMessagesCount(conv);
        await this._loadPreviewMessage(conv);
        promiseHolder ? promiseHolder.resolve(conv) : this._setConversations([conv]);
      } else {
        console.error("Error loading conversation ", conversationSid, this.getConversationDisplayName(conv), conv.status);
        promiseHolder && promiseHolder.reject("Error loading conversation");
      }
    } catch (e) {
      console.error("Unable to fetch conversation from Twilio : " + conversationSid);
      promiseHolder?.resolve(null);
    }
  }

  private async _loadCurrentConversationUpdates(
    conv: Conversation
  ) {
    this.conversations[this.currentConversationSid] = conv;
    this.currentConversation = conv;
    await this._loadUnreadMessagesCount(conv);
    await this._loadPreviewMessage(conv);
    this.sortConversations([conv]);
    await this._markAllMessagesAsRead();
  }

  private async _loadUnreadMessagesCount(
    conversation: Conversation
  ) {
    let count = await conversation.getUnreadMessagesCount();
    if (count == null) {
      count = await conversation.getMessagesCount();
    }
    this.setUnreadMessagesCount(conversation.sid, count ?? 0);
  }

  private async _loadPreviewMessage(conv) {
    if (this.showMessagePreview) {
      const messagesPaginator: Paginator<Message> = await conv.getMessages(1);
      this._addMessages(conv.sid, messagesPaginator.items);
    }
  }

  private async _addMessage(
    message: Message,
  ) {
    if (this.currentConversationSid !== message.conversation.sid) {
      this._loadUnreadMessagesCount(message.conversation);
    }
    await this._populateTwilioReplyMessages(message.conversation.sid, [message]);
    this._addMessages(message.conversation.sid, [message]);
  }

  private _addMessages(sid, messagesToAdd) {
    //get existing messages for the convo
    const existingMessages = this.messages[sid] ?? [];

    const filteredExistingMessages = existingMessages.filter(
      (message: Message) => {
        const messageAttributes = message.attributes as MessageAttribute;
        return !messagesToAdd.find(
          (value) => {
            return (
              value.body === message.body &&
              value.attributes?.authorName === messageAttributes?.authorName &&
              value.media?.filename === message.media?.filename &&
              value.media?.size === message.media?.size &&
              (message.index === -1 || value.index === message.index)
            );
          }
        );
      }
    );

    //add new messages to exisiting, ignore duplicates
    const messagesUnique = [...filteredExistingMessages, ...messagesToAdd];

    //populate conversation tags
    let conversationTags = this.conversationTags[sid] ?? new Set();
    messagesUnique.forEach(message => {
      const messageAttributes = message.attributes as MessageAttribute;
      if (messageAttributes?.tags?.length > 0)
        conversationTags = new Set([...conversationTags, ...messageAttributes.tags]);
    });
    this.conversationTags[sid] = conversationTags;

    const sortedMessages = messagesUnique.sort(
      (a, b) => a.dateCreated.getTime() - b.dateCreated.getTime()
    );

    //overwrite the channelSid messages
    this.messages = Object.assign({}, this.messages, {
      [sid]: sortedMessages,
    }) as MessagesType;

    if (this.updateLogConversationId === sid) {
      this.updateLogMessages = sortedMessages.reverse();
    }
  }

  private async _populateTwilioReplyMessages(conversationSid: string, messages: Message[]) {
    if (this.currentConversationSid != conversationSid) {
      return;
    }
    const requestPayload: FetchConversationMessageRequest = {
      conversationSid,
      messageSidList: messages
        .filter(m => (m?.attributes as any)?.replyMessageSid ? true : false)
        .map(m => (m?.attributes as any)?.replyMessageSid)
    }
    if (requestPayload.messageSidList.length) {
      try {
        await this.communicationService.getReplyToMessages(requestPayload)
          .then(response => {
            const replyToMessages = response.data.data as ConversationMessage[];
            this.twilioReplyMessages[conversationSid] = this.twilioReplyMessages[conversationSid] ?? {};
            replyToMessages.forEach(r => {
              this.twilioReplyMessages[conversationSid][r.sid] = r;
            });
          });
      } catch (e) {
        // do nothing
      }
    }
  }

  private updateMessage(conversationSid, updatedMessage: Message) {
    const existingMessages = this.messages[conversationSid] ?? [];
    if (existingMessages.length > 0) {
      const newMessages = existingMessages.map(x => {
        return (x.sid == updatedMessage.sid ? updatedMessage : x)
      });
      this.messages = Object.assign({}, this.messages, {
        [conversationSid]: newMessages,
      }) as MessagesType;
    }
  }

  private removeMessages(sid, messagesToRemove) {

    //get existing messages for the convo
    const existingMessages = this.messages[sid] ?? [];

    const messages = existingMessages.filter(
      ({ index }) =>
        !messagesToRemove.find(
          ({ index: messageIndex }) => messageIndex === index
        )
    );

    //overwrite the channelSid messages
    this.messages = Object.assign({}, this.messages, {
      [sid]: messages,
    }) as MessagesType;
  }

  isConversationReadOnly(conversationSid) {
    return !this.conversationWriteAccess[conversationSid];
  }

  private async _updateParticipants(conversation: Conversation) {
    const participants = await conversation.getParticipants();
    this._checkIfThereIsABorrower(conversation.sid, participants);
    this.participants = Object.assign({}, this.participants, { [conversation.sid]: participants }) as ParticipantType;
    this._sortParticipants(conversation.sid);
  }

  private _checkIfThereIsABorrower(conversationSid: string, participants: Participant[]) {
    let anyParticipantWithBorrowerRole = false;
    participants?.forEach(y => {
      const attributes = y.attributes as ParticipantAttribute;
      if (attributes.role == 'borrower') {
        anyParticipantWithBorrowerRole = true;
      }
    });
    this.conversationHasBorrower[conversationSid] = anyParticipantWithBorrowerRole;
  }

  private async setUnreadMessagesCount(sid, unreadCount) {
    this.unreadTechSupportMessagesCount = Object.assign({}, this.unreadTechSupportMessagesCount, { [sid]: unreadCount }) as UnreadMessagesCountType;
    this.unreadMessagesCount = Object.assign({}, this.unreadMessagesCount, { [sid]: unreadCount }) as UnreadMessagesCountType;
  }

  private setTypingIndicator(
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) {
    const { identity } = participant;
    const participantName = this.getName(participant);
    // skip the current participant typing
    // if (this.twilioIdentityClients[identity] != undefined) {
    if (this.userId == this.getParticipantUserId(participant)) {
      return;
    }
    callback(sid, participantName);
  }

  private startTyping(channelSid, participantName) {
    const existedUsers = this.typingData[channelSid] ?? [];
    existedUsers.push(participantName);
    this.typingData = Object.assign({}, this.typingData, {
      [channelSid]: uniq(existedUsers),
    });
  }

  private endTyping(channelSid, participantName) {
    const filteredUsers = (this.typingData[channelSid] ?? []).filter(
      user => user !== participantName
    );
    this.typingData = Object.assign({}, this.typingData, {
      [channelSid]: filteredUsers,
    });
  }

  private async _setConversations(conversations: Conversation[], silentRefreshMyConversations: boolean = false,) {
    this.sortConversations(conversations);
    // if the conversation is already selected, then load more details
    const _currConversation = conversations.find(
      c => this.currentConversationSid == c.sid
    );
    if (_currConversation && !silentRefreshMyConversations) {
      this.setCurrentConversation(_currConversation);
    }
  }

  private _sortParticipants(cSid) {
    this.participants[cSid].sort((a, b) =>
      this.getName(b) > this.getName(a) ? -1 : 1
    );
  }

  protected sortConversations(conversations: Conversation[]) {
    const temp: ConversationsType = {};
    if (
      this.query?.length > 0 &&
      !this.loadingMoreResults &&
      (this.conversationGroup === ConversationGroup.TECH_SUPPORT ||
        this.conversationGroup === ConversationGroup.CLOSING_SUPPORT ||
        this.conversationGroup === ConversationGroup.SALES_SUPPORT ||
        this.conversationGroup === ConversationGroup.PRIVATE_MESSAGES)
    ) {
      Object.keys(this.conversations).forEach(key => {
        if (
          this.conversations[key].attributes.chatRoomType !== this._getChatRoomType(this.conversationGroup)
        ) {
          temp[key] = this.conversations[key];
        }
      });
    } else {
      Object.assign(temp, this.conversations);
    }

    conversations.forEach(c => {
      temp[c.sid] = c;
    });

    const conversationsObj = temp;

    this.shouldSortConversationsByLastMessageDate
      ? this._sortConversationsByLastMessageDate(conversationsObj)
      : this._sortConversationsByRoomName(conversationsObj);
  }

  private _sortConversationsByRoomName(conversationsObj: ConversationsType) {
    this.conversations = Object.keys(conversationsObj)
      .map(cSid => {
        const name = this.getConversationDisplayName(conversationsObj[cSid]).toUpperCase();
        return {
          cSid,
          conversation: conversationsObj[cSid],
          order: ConversationSortOrder[name] ?? Number.MAX_SAFE_INTEGER
        }
      })
      .sort((a, b) => a.order - b.order)
      .reduce((obj, { cSid, conversation }) => {
        obj[cSid] = conversation;
        return obj;
      }, {});
  }

  _sortConversationsByLastMessageDate(conversationsObj: ConversationsType) {
    this.conversations = Object.keys(conversationsObj)
      .map(cSid => {
        const lastMessageDate = conversationsObj[cSid].lastMessage?.dateCreated;
        const conversationCreatedDate = conversationsObj[cSid].dateCreated;
        return {
          cSid,
          conversation: conversationsObj[cSid],
          conversationUpdatedDate: lastMessageDate ? lastMessageDate : conversationCreatedDate
        }
      })
      .sort((a, b) => compareDesc(a.conversationUpdatedDate, b.conversationUpdatedDate))
      .reduce((obj, { cSid, conversation }) => {
        obj[cSid] = conversation;
        return obj;
      }, {});
  }

  *getAllMessageSnippets() {
    try {
      const response: ApiResponse = yield this.communicationService.getAllMessageSnippets();
      const data = response.data.data;
      this.messageSnippets = data.length ? data : [];
    }
    catch (error) {
      console.log("Error getting message snippets");
    }
  }

  *removeMentionMarker(conversationSid) {
    this.conversationMentioned[conversationSid] = false;
  }

  async getMessageDeliveryReceipts(
    message: Message
  ): Promise<MessageDeliveryReceipts> {
    if (message.index === -1) {
      return undefined;
    }

    const participantSidDeliveryStatusMap: Record<string, MessageStatus> = {};
    this.participants[this.currentConversationSid].forEach((participant) => {
      if (
        participant.identity == this.conversationTwilioIdentity[this.currentConversationSid] ||
        participant.type !== "chat"
      ) {
        return;
      }
      if (
        !isNil(participant.lastReadMessageIndex) &&
        participant.lastReadMessageIndex >= message.index
      ) {
        participantSidDeliveryStatusMap[participant.sid] = MessageStatus.Read;
      } else if (participant.lastReadMessageIndex !== -1) {
        participantSidDeliveryStatusMap[participant.sid] = MessageStatus.Delivered;
      }
    });
    if (message.aggregatedDeliveryReceipt) {
      const receipts: DetailedDeliveryReceipt[] = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
      receipts.forEach((receipt) => {
        if (receipt.status === "read") {
          participantSidDeliveryStatusMap[receipt.participantSid] = MessageStatus.Read;
        }
        if (receipt.status === "delivered") {
          participantSidDeliveryStatusMap[receipt.participantSid] = MessageStatus.Delivered;
        }
        if (receipt.status === "failed" || receipt.status === "undelivered") {
          participantSidDeliveryStatusMap[receipt.participantSid] = MessageStatus.Failed;
        }
        if (receipt.status === "sent" || receipt.status === "queued") {
          participantSidDeliveryStatusMap[receipt.participantSid] = MessageStatus.Sending;
        }
      });
    }
    let deliveryStatusParticipantSids: MessageDeliveryReceipts | {} = {};
    this.participants[this.currentConversationSid]
      .filter(x => {
        // remove loggedInParticipant
        return x.identity != this.conversationTwilioIdentity[this.currentConversationSid];
      })
      .forEach((p) => {
        const status = participantSidDeliveryStatusMap[p.sid] ?? MessageStatus.None;
        const x: MessageDeliveryParticipantData = {
          participantSid: p.sid,
          name: this.getName(p),
          status
        }
        if (!deliveryStatusParticipantSids[status]) {
          deliveryStatusParticipantSids[status] = [];
        }
        deliveryStatusParticipantSids[status].push(x);
      });
    return deliveryStatusParticipantSids as MessageDeliveryReceipts;
  }

  get currentChatRoomId() {
    return this.conversationChatRoom[this.currentConversationSid];
  }

  get currentChatRoomType() {
    return this.conversationChatRoomType[this.currentConversationSid];
  }

  get currentConversationGroup() {
    return this.conversationGroupMap[this.currentConversationSid];
  }

  get currentConversationMessagesGroupedByDate(): MessagesGroupedByDateType {
    if (!this.messages[this.currentConversationSid]) {
      return {};
    }
    var x = {};
    this.messages[this.currentConversationSid].forEach(m => {
      const temp = x[m.dateCreated.toLocaleDateString()] ?? [];
      x[m.dateCreated.toLocaleDateString()] = [...temp, m];
    })
    return x;
  }

  get userId() {
    return this.isInternal
      ? this.internalPortalUserInfo.userId
      : this.userStore.userInformation.userId;
  }

  get userName() {
    return this.isInternal
      ? this.internalPortalUserInfo.userName
      : this.userStore.userInformation.username;
  }

  get chatAppNotification() {
    return this.userStore.userInformation?.userPreference?.chatAppNotification;
  }

  get chatAppNotificationType() {
    return this.userStore.userInformation?.userPreference?.chatAppNotificationType;
  }

  private _checkForWriteAccess(twilioIdentity) {
    return twilioIdentity.startsWith(this.userId + '_');
  }

  private _createPromise<T>() {
    let promiseHolder: PromiseHolderType<T> = {};
    const p = new Promise<T>((resolve, reject) => {
      promiseHolder.resolve = resolve;
      promiseHolder.reject = reject;
    });
    promiseHolder.promise = p;
    return promiseHolder;
  }

  sendErrorEmail(message, twilsockUrl) {
    this.userStore.sendErrorEmail(
      `Error while connecting to twilio`,
      `The user was unable to connect to twilio`,
      `Twilio Message: ${message} <br/> Twilio Socket URL: ${twilsockUrl} <br/> `
    );
  }

  get currentConversationTags() {
    if (!this.conversationTags[this.currentConversationSid]) {
      return [];
    }
    return [...this.conversationTags[this.currentConversationSid].values()];
  }

  get currentConversationTagsSelected() {
    if (!this.conversationTagsSelected[this.currentConversationSid]) {
      return [];
    }
    return [...this.conversationTagsSelected[this.currentConversationSid].values()];
  }

  get currentConversationReadOnly() {
    return !this.conversationWriteAccess[this.currentConversationSid];
  }

  get currentConversationFullReadOnly() {
    if (this.conversationChatRoomType[this.currentConversationSid] === ChatRoomType.LOAN_SYSTEM_NOTIFICATIONS) {
      return true;
    }
    return false;
  }

  get currentConversationIsArchived() {
    return this.conversationChatRoomStatus[this.currentConversationSid] === ChatRoomStatus.ARCHIVED;
  }

  get currentConversationIsPrivateRoom() {
    return this.conversationChatRoomType[this.currentConversationSid] === ChatRoomType.PRIVATE_ROOM;
  }

  get currentConversationIsLoanConversation() {
    const attributes = (this.currentConversation?.attributes as ConversationAttribute);
    return attributes?.loanId ? true : false;
  }

  get filtersKey() {
    return this.filters?.map(x => x.type.toString() + ':' + x.value.toString())
      .sort()
      .reduce((a, b) => a + b, '');
  }

  protected flashTaskBarIcon() {
    if (isElectronApp() && (window as any).require) {
      const { ipcRenderer } = (window as any).require('electron');
      ipcRenderer?.send('flash-taskbar-icon', 'ping');
    }
  }

  protected sendNotification(data: {
    title: string,
    roomType: ChatRoomType,
    from?: string,
    body: string,
    conversationSid: string,
    hasMentions?: boolean,
  }) {
    if (isElectronApp()
      && (window as any).require
      && (this.chatAppNotificationType == ChatAppNotificationType.all || this.chatAppNotificationType == ChatAppNotificationType.mentions && data.hasMentions)) {
      const { ipcRenderer } = (window as any).require('electron');
      ipcRenderer?.send('send-notification', data);
    }
  }

  private async fetchNewTokenAndUpdate(client: Client, twilioIdentity: string) {
    const newToken: string = await this.getToken(twilioIdentity);
    await client.updateToken(newToken);
  }

  get isSupportConversation() {
    return (
      this.conversationChatRoomType[this.currentConversationSid] ==
      ChatRoomType.TECH_SUPPORT ||
      this.conversationChatRoomType[this.currentConversationSid] ==
      ChatRoomType.SALES_SUPPORT ||
      this.conversationChatRoomType[this.currentConversationSid] ==
      ChatRoomType.CLOSING_SUPPORT
    );
  }

  get isProposalTopicConversation() {
    return (
      this.conversationChatRoomType[this.currentConversationSid] ==
      ChatRoomType.DEAL_ROOM_TOPIC_ROOM
    );
  }

  get isTaskConversation() {
    return (
      this.conversationChatRoomType[this.currentConversationSid] ==
      ChatRoomType.TASKS
    );
  }

  *setTaskId(taskId: number) {
    this.taskId = taskId;
  }

  saveSearchFilter(query: string, conversationGroup: ConversationGroup) {
    this.query = query;
    this.conversationGroup = conversationGroup;
  }

  get searchQuery() {
    return this.query;
  }

  get searchConversationGroup() {
    return this.conversationGroup;
  }

  private _getChatRoomType(conversationGroup: ConversationGroup): ChatRoomType {
    if (conversationGroup === ConversationGroup.TECH_SUPPORT) {
      return ChatRoomType.TECH_SUPPORT;
    }
    else if (conversationGroup === ConversationGroup.CLOSING_SUPPORT) {
      return ChatRoomType.CLOSING_SUPPORT;
    }
    else if (conversationGroup === ConversationGroup.SALES_SUPPORT) {
      return ChatRoomType.SALES_SUPPORT;
    }
    else if (conversationGroup === ConversationGroup.PRIVATE_MESSAGES) {
      return ChatRoomType.PRIVATE_ROOM;
    }
    else {
      throw new Error("ChatRoomType not mapped! Fix it!");
    }
  }

  setLoadingMoreResults(state: boolean) {
    this.loadingMoreResults = state;
  }

  isBorrowerRoom(conversation: Conversation) {
    return conversation?.sid && this.conversationHasBorrower[conversation.sid] == true;
  }

  get shouldAutoLoadAllPreviousMessages() {
    return this.currentChatRoomType && this.loadAllMessagesChatRoomTypes.includes(this.currentChatRoomType);
  }

}
