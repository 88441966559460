import { observer } from 'mobx-react';
import { Box, Grid, Typography } from '@material-ui/core';

import { PropertyAddressField } from './propertyFields/propertyAddressField';
import { PropertyAccordionItem } from './propertyAccordion/propertyAccordionItem';
import { makeStyles } from '@material-ui/core/styles';
import { BridgePropertiesStore } from '../../stores/bridge/bridgePropertiesStore';
import { LoanSubType } from '@roc/feature-utils';
import { Button, TextField } from '@roc/ui';
import { PropertyForm } from './propertyForm';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

interface Props {
  propertiesStore: BridgePropertiesStore;
  loanSubtype: LoanSubType;
}

export const EditPropertyInformation = observer(
  ({ propertiesStore, loanSubtype }: Props) => {
    const classes = useStyles();
    const propertyIndex = propertiesStore.editPropertyActiveIndex;
    const propertyForm =
      propertiesStore.propertiesForm[propertiesStore.editPropertyActiveIndex];
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Property Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.body}>
            Please provide the property information.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Property #{propertyIndex + 1}</Typography>
            <Button
              testId="remove-property"
              variant="outlined"
              color="primary"
              onClick={() => propertiesStore.removeProperty(propertyForm)}
            >
              Remove Property
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PropertyForm
            loanSubtype={loanSubtype}
            propertyStore={propertyForm}
            propertiesStore={propertiesStore}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            testId="add-property"
            onClick={() => propertiesStore.handleAddProperty()}
            variant="contained"
            color="primary"
            fullWidth
          >
            Add another property
          </Button>
        </Grid>
      </>
    );
  }
);
