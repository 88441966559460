import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout, Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { AppraisalCdaOrderDetails } from 'libs/feature-documents/src/documents/components/documentForms/appraisalOrderAndCDADetails/loanPayments/appraisalCdaOrderDetails';

export const getAppraisalCdaRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanTaskId: any
) => {
  return {
    appraisalCda: {
      path: `${basePath}/appraisal-cda`,
      url: `${baseUrl}/appraisal-cda`,
      documentTitle: 'appraisal-cda',
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: '0px 0px',
    padding: '0px 0px'
  },
}));

export const getAppraisalCdaRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanTaskId: any,
  isTamariskLender: any
) => {
  const config = getAppraisalCdaRoutesConfig(basePath, baseUrl, loanId, loanTaskId);
  const classes = useStyles();

  return {
    appraisalCda: (
      <Route exact path={config.appraisalCda.path}>
        <Page routeDefinition={config.appraisalCda}>
          <Box pl={3} pr={3} style={{ background: 'white', width: '100%' }}>
            <Layout title="Appraisal Order Details" minHeight={100} containerClass={classes.container}>
              <EmbeddedFrameBoundaries>
                <AppraisalCdaOrderDetails
                  loanId={loanId}
                  loanTaskId={loanTaskId}
                  isInternal={true}
                  isTamariskLender={isTamariskLender}
                />
              </EmbeddedFrameBoundaries>
            </Layout>
          </Box>
        </Page>
      </Route>
    ),
  };
}