import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { action, flow, makeObservable, observable } from 'mobx';
import { LeaseInformationService } from '../../../services/leaseInformation/leaseInformationService';
import { formatDate, getDocumentAsBlob } from '@roc/feature-utils';

export const TERM_LEASE = 'Term Lease';
const MONTH_TO_MONTH = 'Month-to-Month';

const leaseInformationForm = {
  fields: {
    vacant: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    tenantName: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    leaseType: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    monthlyRent: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    startDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: '',
      format: 'MM/dd/yyyy',
      message: '',
    },
    marketRent: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    endDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: '',
      format: 'MM/dd/yyyy',
      message: '',
    },
    unitNumber: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    sectionEightFourTwo: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class LeaseInformationFormStore extends FormStore {

  leaseInformationFile: File;
  document: Document;
  leaseTypes: Array<any>;
  leaseInfo: any;

  globalStore: GlobalStore;
  leaseInformationService: LeaseInformationService;

  constructor(globalStore) {
    super({ ...leaseInformationForm }, globalStore);

    this.globalStore = globalStore;
    this.leaseInformationService = new LeaseInformationService();

    makeObservable(this, {
      leaseInformationFile: observable,
      setLeaseInformationFile: action,
      fetchLeaseInformation: flow,
      submitLeaseInformation: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.setLeaseInformationFile(null);
    this.leaseTypes = [];
    this.leaseInfo = {};
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  setLeaseInformationFile(file: File) {
    this.leaseInformationFile = file;
  }

  *fetchLeaseInformation(loan, document, downloadExistingFile) {
    try {

      this.reset();
      this.document = document;

      if (downloadExistingFile) {
        const response = yield downloadExistingFile(document);
        const fileResult = getDocumentAsBlob(response?.data, response?.headers, response?.data?.type);
        this.leaseInformationFile = new File([fileResult.blob], fileResult.name);
      }

      this.leaseTypes = [
        {
          label: "Please Select",
          value: ""
        },
        {
          label: TERM_LEASE,
          value: TERM_LEASE
        },
        {
          label: MONTH_TO_MONTH,
          value: MONTH_TO_MONTH
        }
      ];
      const response = yield this.leaseInformationService.getLeaseInformation(this.document?.loanTaskId);
      if (response?.data?.data?.leaseId) {
        this.leaseInfo = response?.data?.data;
      }
      else {
        this.leaseInfo = {
          loanId: loan?.loanId,
          documentId: this.document?.loanTaskId,
          propertyId: this.document?.collateralId,
          vacant: true,
        };
      }
      this.loadForm(this.leaseInfo);
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving details for this lease.',
      });
    }
  }

  *submitLeaseInformation(onClose) {
    try {
      const formValues = this.getFormValues();
      this.runFormValidationWithMessage();
      if (this.form.meta.isValid) {
        const data = {
          ...this.leaseInfo,
          ...formValues,
          startDate: formValues.startDate ? formatDate(formValues.startDate, 'MM/dd/yyyy') : null,
          endDate: formValues.endDate ? formatDate(formValues.endDate, 'MM/dd/yyyy') : null,
        }
        yield this.leaseInformationService.updateLeaseInformation(data);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Lease details updated successfully.',
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the details for this lease.',
      });
    }
  }

}