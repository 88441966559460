import { Box, Chip, Popover, Typography } from "@material-ui/core";
import { Close, ThumbUp } from "@material-ui/icons";
import { IconButton } from "@roc/ui";
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CommunicationStore } from '../stores';
import { ParticipantAttribute } from "../types/communicationTypes";

export const LikesChip = observer(({
  communicationStore,
  likes,
}: {
  communicationStore: CommunicationStore,
  likes: string[]
}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [likedUsers, setLikedUsers] = useState<string[]>([]);
  const { participants, currentConversationSid } = communicationStore;

  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    const _participants = participants[currentConversationSid];
    const result = [];
    likes.forEach(l => {
      const participant = _participants.find(x => x.sid == l);
      if (participant) {
        const participantAttributes = participant?.attributes as ParticipantAttribute;
        const name = participantAttributes.firstName + " " + participantAttributes.lastName?.charAt(0);
        result.push(name);
      }
    });
    setLikedUsers(result);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setLikedUsers([]);
  };

  return (
    <Box>
      <Box mt={1} mb={0.5} mx={0} display={'inline-block'} onMouseEnter={(e) => openPopover(e)}>
        <Chip
          icon={<ThumbUp style={{
            color: 'orange',
            width: 18,
            height: 18,
          }} />}
          label={likes.length}
          clickable
          style={{
            background: '#fff',
            fontSize: 14,
            height: 24,
            borderRadius: 18,
            border: '1px solid #ddd',
          }}
          onClick={(e) => openPopover(e)}
        />
      </Box>
      <Popover
        id={'likes-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        disableRestoreFocus
      >
        <Box p={2}>
          <Box display="flex" alignItems={'center'}>
            <Box flexGrow={1} width={200}>
              <Typography variant="h6" gutterBottom style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <strong>Likes</strong>
                <ThumbUp style={{
                  color: 'orange',
                  marginLeft: 8
                }} />
              </Typography>
            </Box>
            <Box>
              <IconButton
                testId="close"
                aria-label="close"
                onClick={closePopover}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          {
            likedUsers.map(x => {
              return (
                <div>{x}</div>
              )
            })
          }
        </Box>
      </Popover>
    </Box>
  )
});