import React from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { LoanStatus } from '@roc/feature-utils';
import { over } from "stompjs";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 4,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(LinearProgress);


export const stepsByLoanStatus = {
  [LoanStatus.PENDING_SUBMISSION]: [
    { title: 'Pending Submission', percentage: 50, active: true },
    { title: 'Underwriting', percentage: 0 },
    { title: 'Committee', percentage: 0 },
    { title: 'Approved', percentage: 0 },
    { title: 'Funded', percentage: 0 },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.PENDING]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Pending To-dos', percentage: 50, active: true },
    { title: 'Underwriting', percentage: 0 },
    { title: 'Committee', percentage: 0 },
    { title: 'Approved', percentage: 0 },
    { title: 'Funded', percentage: 0 },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.IN_UNDERWRITING]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Pending To-dos', percentage: 100 },
    { title: 'Underwriting', percentage: 50, active: true },
    { title: 'Committee', percentage: 0 },
    { title: 'Approved', percentage: 0 },
    { title: 'Funded', percentage: 0 },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.PENDING_DECISION]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 50, active: true },
    { title: 'Approved', percentage: 0 },
    { title: 'Funded', percentage: 0 },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.AGREED]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 100 },
    { title: 'Approved', percentage: 100 },
    { title: 'Funded', percentage: 50, active: true },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.FUNDED]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 100 },
    { title: 'Approved', percentage: 100 },
    { title: 'Funded', percentage: 100 },
    { title: 'Paid Off', percentage: 50, active: true },
  ],
  [LoanStatus.PAID_OFF]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 100 },
    { title: 'Approved', percentage: 100 },
    { title: 'Funded', percentage: 100 },
    { title: 'Paid Off', percentage: 100, active: false },
  ],
  [LoanStatus.PAID_OFF_BUYOUT]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 100 },
    { title: 'Approved', percentage: 100 },
    { title: 'Funded', percentage: 100 },
    { title: 'Paid Off', percentage: 100, active: false },
  ],
  [LoanStatus.DECLINED]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Declined', percentage: 100, active: false },
  ],
  [LoanStatus.RETRACTED]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Retracted', percentage: 100, active: false },
  ],
  [LoanStatus.ON_HOLD]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Hold', percentage: 100 },
    { title: 'Retracted', percentage: 0, active: true },
  ],
  [LoanStatus.APPLICATION_SUBMITTED]: [
    { title: 'Application Submitted', percentage: 100 },
    { title: 'Pending To-dos', percentage: 50, active: true },
    { title: 'Underwriting', percentage: 0 },
    { title: 'Committee', percentage: 0 },
    { title: 'Approved', percentage: 0 },
    { title: 'Funded', percentage: 0 },
    { title: 'Paid Off', percentage: 0 },
  ],
  [LoanStatus.APPLICATION_REJECTED]: [
    { title: 'Submission', percentage: 100 },
    { title: 'Underwriting', percentage: 100 },
    { title: 'Committee', percentage: 100 },
    { title: 'Declined', percentage: 100, active: false },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    marginBottom: '24px',
    '@media (max-width: 992px)': {
      width: 'max-content',
    },
  },
  progressContainer: {
    display: "flex",
    flexDirection: "row",
  },
  box: {
    width: "100%",
    marginRight: "5px",
  },

  progressStage: {
    color: "#6B6C71",
    fontSize: "12px",
  },
  progressStatus: {
    color: theme.palette.primary.main,
    fontSize: "10px",
    textTransform: "uppercase",
  },
  firstLine: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastLine: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
}));

export default function CustomizedProgressBars({ loanDetails }) {
  const classes = useStyles();
  const loanStatus = loanDetails?.status;
  return (<>
    {stepsByLoanStatus[loanStatus] && <div className={classes.root}>
      {stepsByLoanStatus[loanStatus].map((data, i) => (
        <Box className={classes.box}>
          <Typography className={classes.progressStatus}>
            {data?.active && 'Pending'}
            {data?.percentage === 100 && 'Completed'}
            {(data?.percentage === 0 || !data?.percentage) && <span style={{ color: 'white' }}>Not started</span>}
          </Typography>
          <BorderLinearProgress variant="determinate" value={data.percentage} className={`${i === 0 && classes.firstLine} ${i === stepsByLoanStatus[loanStatus].length - 1 && classes.lastLine}`} />
          <Typography className={classes.progressStage}>
            {data.title}
          </Typography>
        </Box>
      ))}
    </div>}
  </>
  );
}
