import { Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import UpdateLogCard from './components/cards/updateLogCard';
import { useParams } from 'react-router';

export const LoanSystemNotificationInternal = observer(() => {
	const { loanId } = useParams<{ loanId: string }>();
	
  return (
    <Layout maxWidth="lg">
      <Grid container spacing={2} style={{ width: '100%'}}>
        <UpdateLogCard loanId={loanId} fullHeight={true}/>
      </Grid>
    </Layout>
  );
});
