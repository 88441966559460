import ReactECharts from "echarts-for-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { formatPercentageField } from "../../utils/utils";
import { ChartUnavailable } from "./chartUnavailable";

const chartTitle = "Percentage of Listing Accepting Offers";

function transformData(chartData) {
  try {
    if (!chartData) {
      return null;
    }
    const rawData = chartData.values;

    const mappedValues = rawData.map(entry => ({
      value: Math.round(entry.value * 100),
      date: new Date(entry.year, entry.month - 1)
    })).sort((a, b) => a.date - b.date);

    return {
      title: {
        text: chartTitle,
        left: "center",
        textStyle: {
          fontSize: 20,
          fontWeight: "bold",
          color: "#656565",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        name: "Month of List Date",
        nameLocation: "center",
        nameTextStyle: {
          fontSize: 14,
          fontWeight: 'bold',
          padding: [10, 0, 0, 0]
        },
        boundaryGap: false,
        data: mappedValues.map((d) => `${d.date.toLocaleString('en-US', { month: "short", year: "numeric" })}`),
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 100,
        axisLabel: {
          formatter: formatPercentageField
        },
      },
      series: [
        {
          name: "Acceptance Rate",
          type: "line",
          data: mappedValues.map((d) => d.value),
          itemStyle: {
            color: "#2b5797",
          },
          lineStyle: {
            width: 2,
          },
          symbolSize: 6,
          markLine: {
            data: [
              {
                yAxis: Math.round(mappedValues.reduce((sum, v) => sum + v.value, 0) / mappedValues.length),
                name: "Avg",
                label: {
                  formatter: `Avg: ${Math.round(mappedValues.reduce((sum, v) => sum + v.value, 0) / mappedValues.length)}%`,
                },
                lineStyle: {
                  type: "dashed",
                  color: "#888",
                },
              },
            ],
          },
        },
      ],
      grid: {
        left: "3%",
        right: "6%",
        bottom: "6%",
        containLabel: true,
      },
    };
  } catch (err) {
    console.log(err);
    return null;
  }
}

interface PercentageOfListingAcceptingOffersChartProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const PercentageOfListingAcceptingOffersChart = ({ internalValuationReviewStore }: PercentageOfListingAcceptingOffersChartProps) => {
  const chartData = internalValuationReviewStore.initialData.charts?.percentage_of_listing_accepting_offers;
  const option = transformData(chartData);

  if (!option) {
    return <ChartUnavailable chartName={chartTitle} />
  }

  return (
    <ReactECharts option={option} style={{ height: "461px", width: "100%" }} />
  );
};