import { DataGrid, GridColumnProps, SelectMode, StatusChip, StatusType } from '@roc/ui';

import { observer } from 'mobx-react';
import { DateFormat, formatDate } from '@roc/feature-utils';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { ReactNode, useEffect } from 'react';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';
import { filterProps, noOpComparator } from '@roc/ui/utils';

interface TaxCertificateGridInterface {
  toolbar?: ReactNode;
}


const columns: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    width: 90,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
    width: 90,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'createdDate',
    headerName: 'Creation Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
];

const rowsTest = [
  {
    orderId: 77,
    orderStatus: "Pending",
    createdDate: "10/31/2024",
    loanId: 62884
  },
  {
    orderId: 72,
    orderStatus: "Pending",
    createdDate: "10/29/2024",
    loanId: 60797
  },
  {
    orderId: 71,
    orderStatus: "Pending",
    createdDate: "10/29/2024",
    loanId: 60859
  }
]

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '65vh',
    overflow: 'hidden',
  },
}));

export const TaxCertificateGrid = observer(({ toolbar }: TaxCertificateGridInterface) => {
  const classes = useStyles();
  const { taxCertificateStore } = useTaxCertificateStore();
  const { taxCertificateGridStore } = taxCertificateStore;

  useEffect(() => {
    taxCertificateGridStore.resetAndFetchGridData();
  }, []);

  const frameworkComponents = {
    statusCellRenderer: ({ value }) =>
      value ? (<StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />) : null,
    dateCellRenderer: ({ value }) => formatDate(value, DateFormat.MMDDYYYY) ?? null,
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    taxCertificateGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    taxCertificateGridStore.setFilterModel(filterModel);
  };

  return (
    <Box>
      <Grid item className={classes.gridContainer}>
        <DataGrid
          columns={columns}
          rows={taxCertificateGridStore.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          headerCheckboxSelection={true}
          suppressRowClickSelection={true}
          toolbar={toolbar}
          key={"taxes"}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={taxCertificateGridStore.isLoading}
          showFilters={taxCertificateGridStore.showFilters}
          sortModel={taxCertificateGridStore.sortModel}
          filterModel={taxCertificateGridStore.filterModel}
          paginationData={taxCertificateGridStore.gridData.meta}
          setPageNumber={taxCertificateGridStore.setPageNumber}
        />
      </Grid>
    </Box>
  );
});