import { FormStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { FileUpload } from '@roc/ui';
import { GlobalStore } from '@roc/feature-app-core';
import { SpecialServicingService } from '../services/specialServicingService';
import { ProtectiveAdvanceModalType } from '../types/types';

const REQUIRED_FIELD = 'This field is required.';

const form = {
  fields: {
    invoice: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    amount: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    typeOfFee: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    vendor: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_FIELD
    },
    internalInvoice: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};


export class ProtectiveAdvanceFormStore extends FormStore {
  globalStore: GlobalStore;
  specialServicingService: SpecialServicingService;
  type: ProtectiveAdvanceModalType = ProtectiveAdvanceModalType.CREATE;
  constructor(globalStore: GlobalStore, specialServicingService: SpecialServicingService) {
    super({ ...form }, globalStore);
    this.specialServicingService = specialServicingService;
    this.globalStore = globalStore;

    makeObservable(this, {
      uploadProtectiveAdvance: flow,
      type: observable,
      setType: action,
    });
  }

  setType(type: ProtectiveAdvanceModalType) {
    this.type = type;
  }

  *uploadProtectiveAdvance(
    payableId: number,
    loanId: string,
    fileUpload: FileUpload,
  ) {
    try {
      var newFile;
      if (fileUpload) {
        const file = fileUpload.file;
        const blob = file.slice(0, file.size, file.type);
        newFile = new File([blob], fileUpload.name, { type: file.type });
      }

      yield this.specialServicingService.saveProtectiveAdvance(
        payableId,
        loanId,
        this.form.fields.invoice.value.replace(/\s+/g, ''),
        this.form.fields.amount.value,
        this.form.fields.typeOfFee.value,
        this.form.fields.vendor.value,
        this.form.fields.internalInvoice.value,
        newFile
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Protective advance was uploaded successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading protective advance.',
      });
    }
  };

  reset = () => {
    super.reset();
  };

}