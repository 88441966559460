import { Box, Card } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TitleDivider } from "../titleDivider";
import { observer } from "mobx-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { MonthlySupplyOfActiveListingChart } from "../charts/monthlySupplyOfActiveListingChart";
import { PercentageOfActiveListingChart } from "../charts/percentageOfActiveListingChart";
import { PercentageOfListingAcceptingOffersChart } from "../charts/percentageOfListingAcceptingOffersChart";
import { PercentageOfListingInContractChart } from "../charts/percentageOfListingInContractChart";
import { SalesTrendsChart } from "../charts/salesTrendsChart";
import { AverageMonthlyPriceChart } from "../charts/averageMonthlyPriceChart";
import { YearOverYearSalePriceTrendChart } from "../charts/yearOverYearSalePriceTrendChart";

const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    backgroundColor: "#FFFFFF",
    padding: "2rem",
  },
  chartCard: {
    padding: "1rem"
  }
}));

interface LiquidityReportTabProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const LiquidityReportTab = observer(({ internalValuationReviewStore }: LiquidityReportTabProps) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      className={classes.tabContent}
    >
      <TitleDivider title="Liquidity Report" />
      <Box display="flex" flexDirection="column" gridGap="1rem">
        <Card elevation={2} className={classes.chartCard}>
          <MonthlySupplyOfActiveListingChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <PercentageOfActiveListingChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <PercentageOfListingAcceptingOffersChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <PercentageOfListingInContractChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <SalesTrendsChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <AverageMonthlyPriceChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
        <Card elevation={2} className={classes.chartCard}>
          <YearOverYearSalePriceTrendChart internalValuationReviewStore={internalValuationReviewStore} />
        </Card>
      </Box>
    </Box>
  );
});
