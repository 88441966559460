import { Box, Typography } from "@material-ui/core";

interface AverageMonthlyPriceChartProps {
  chartName: string;
}

export const ChartUnavailable = ({ chartName }: AverageMonthlyPriceChartProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        height: "300px",
      }}
    >
      <Typography variant="h6">
        {chartName} Not
        Available
      </Typography>
    </Box>
  );
};