import { flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { GmailService } from '../services/gmailService';

export class GmailStore {
  private globalStore: GlobalStore;
  private gmailService: GmailService;
  public gmailCreds: any;
  public success: boolean = false;


  constructor(globalStore) {
    this.globalStore = globalStore;
    this.gmailService = new GmailService();

    makeObservable(this, {
      gmailCreds: observable,
      exchangeAuthCodeForToken: flow,
      getCreds: flow,
      success: observable,
    });

    this.initializeCreds();
  }

  private async initializeCreds() {
    try {
      await this.getCreds();
    } catch (error) {
      console.error("Failed to initialize credentials:", error);
    }
  }

  async getCreds() {
    const response = await this.gmailService.getCreds();
    this.gmailCreds = response?.data;
  }


  async exchangeAuthCodeForToken(req) {
    try {
      this.gmailService.exchangeAuthCodeForToken(req);
      this.success = true;
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Connected Succesfully',
      });
    } catch (error) {
      this.success = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error occurred, please contact the teach team',
      });
      throw error;
    }
  }


}
