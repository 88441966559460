import {
  Avatar as ChatUiAvatar, ChatContainer,
  ConversationHeader, InputToolbox, Message as ChatUiMessage, MessageInput, MessageList, TypingIndicator, Sidebar
} from "@chatscope/chat-ui-kit-react";
import { Badge, Box, IconButton, Link, ListItemIcon, ListItemText, Menu, Typography, useMediaQuery, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AccountCircleOutlined, FilterList, Group, MoreVert, ExitToApp, Warning, ThumbUp, Archive, Unarchive, Edit, Save, Cancel, Star } from "@material-ui/icons";
import { Skeleton, ToggleButton } from "@material-ui/lab";
import { Button, ConfirmDialog, HtmlMessageContent, MenuItem, Tooltip, ConfirmationButton, CircularLoader, createTextField, TextField, BorrowerIndicatorIcon } from "@roc/ui";
import { Message, Participant } from "@twilio/conversations";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CommunicationStore } from "../stores";
import { ChatRoomType, ChatRoomStatus, ConversationAttribute, ParticipantAttribute, ParticipantDetails, ParticipantType, MessageAttribute, SupportedEmojis } from "../types/communicationTypes";
import { filterByTagsSelected, getAuthorName, getRoleInfoForAuthor, getIcon, getMessageTime, isInternal, cleanHtmlText, getUserFromAllowedParticipants, checkIfExistingRating } from "./../helper";
import { AttachmentPreview } from "./attachments/attachmentPreview";
import { CustomMessageInputContainer } from "./customMessageInput";
import { FilterTagsContainer } from "./filterTagsContainer";
import { MessageHoverMenu } from "./hoverMenu";
import { JoinToolBox } from "./joinToolBox";
import { ManageParticipantsDialog } from "./manageParticipantsDialog";
import { ReplyButton } from "./replyButton";
import { ReplyToMessage } from "./replyToMessage";
import { TechSupportCommunicationStore } from "../stores/techSupportCommunicationStore";
import { ArchivedChatInfoBox } from "./archivedChatInfoBox";
import { useUserStore } from "@roc/feature-app-core";
import { isEmptyObject, isNil } from "@roc/feature-utils";
import { DisplayNameWithOnlineStatus } from "@roc/feature-app-core";
import { ReadReceiptButton } from "./readReceiptButton";
import Rating from '@material-ui/lab/Rating';
import { CreateTaskDialog } from 'libs/feature-general-tasks/src/generalTasks/components/editTask/createTaskDialog';
import { BestPractices } from "./bestPractices";
import Drawer from '@material-ui/core/Drawer';
import { LikesChip } from "./likesChip";
import { FullReadOnlyChatInfoBox } from "./fullReadOnlyChatInfoBox";

const SLIM_HEADER_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  conversationAvatar: {
    background: '#EEE',
    padding: 4,
  },
  slimHeader: {
    '& .cs-conversation-header': {
      padding: 0,
      alignItems: 'center',
      height: SLIM_HEADER_HEIGHT,
      '& .cs-conversation-header__avatar': {
        margin: '0 8px'
      },
      '& .search-container': {
        top: SLIM_HEADER_HEIGHT
      }
    }
  },
  conversationHeader: {
    position: 'relative',
    '& .search-container': {
      position: 'absolute',
      left: '0',
      top: 68,
      minHeight: 60,
      width: '100%',
      padding: 8,
      background: '#fff',
      borderBottom: '1px solid #ccc'
    }
  },
  messageList: {
    position: 'relative',
    '& .scrollbar-container': {
      padding: 0,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'auto !important',
      '& >div:first-child': {
        paddingBottom: 24,
        marginBottom: '0px !important',
      },
      '& .ps__rail-y': {
        display: 'none',
      },
    },
    [theme.breakpoints.down(769)]: {
      '& .scrollbar-container': {
        padding: 0,
        overflow: 'auto !important',
        '& .ps__rail-y': {
          display: 'none',
        },
      },
    }
  },
  message: {
    padding: '16px 16px 0 16px',
    '& .cs-message__content-wrapper': {
      position: 'relative',
    },
    '& .cs-message__avatar': {
      justifyContent: 'flex-start',
    },
    '& .cs-message__content': {
      padding: 0,
      borderRadius: '8px !important',
      '& ul': {
        paddingLeft: 24,
      }
    },
    '&.cs-message--outgoing': {
      marginLeft: '100px !important',
    },
    '&.cs-message--incoming': {
      marginRight: '100px !important',
    },
    '&.cs-message--outgoing .cs-message__content': {
      color: '#f1f1f1',
      backgroundColor: '#666',
    },
    '&.cs-message--incoming .cs-message__content': {
      color: 'black',
      backgroundColor: '#eee',
    },
    '& .cs-message__footer': {
      alignItems: 'flex-end',
      '& .cs-message__sent-time': {
        fontSize: 12,
        paddingLeft: 80
      },
    },
    '& .wysiwyg-mention': {
      color: '#ffffff',
      fontWeight: '700'
    },
  },
  loadingContentMessageList: {
    position: 'absolute',
    marginTop: 24,
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  emptyContentMessageList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    fontSize: '24px',
    letterSpacing: '0.5px',
    fontWeight: 'bold',
    color: '#bbb',
  },
  huddleTooltipPopper: {
    '& .MuiTooltip-tooltip': {
      fontSize: 16,
      padding: 8
    }
  },
  bestPracticesToggle: {
    '&.MuiToggleButton-root': {
      color: '#000000',
    },
  }
}
));

const useDialogContentStyles = makeStyles(theme => ({
  root: {
    '& .dialog-content-box': {
      display: 'flex',
      alignItems: 'center',
    },
  }
}));




export const ChatSection = observer(({
  as,
  showLoading,
  communicationStore,
  showSnippet = false,
  allowJoinConversation = false,
  allowLeaveConversation = false,
  onBackClick,
  showEmailAddress,
  allowManageParticipants,
  allowAddInternalParticipants,
  allowAddParticipants,
  allowRemoveParticipants,
  onDownloadConversation,
  onUnarchiveConversation,
  slimChatHeader = true,
  onHuddle,
  onUserNewConversation,
  onOpenAddTask,
  containerClass,
}: {
  as: any,
  showLoading: boolean,
  communicationStore: CommunicationStore,
  showSnippet: boolean,
  allowJoinConversation: boolean,
  allowLeaveConversation: boolean,
  onBackClick?: () => void,
  showEmailAddress?: boolean,
  allowManageParticipants?: boolean,
  allowAddInternalParticipants?: boolean,
  allowAddParticipants?: boolean,
  allowRemoveParticipants?: boolean,
  onDownloadConversation?: (...any) => any,
  onUnarchiveConversation?: (...any) => any,
  slimChatHeader?: boolean,
  onHuddle?: (inviteUserIds: number[]) => void,
  onUserNewConversation?: (inviteUserId: number) => void,
  onOpenAddTask?: (userId: number) => void,
  containerClass?: string,
}) => {
  const classes = useStyles();
  const dialogContentclasses = useDialogContentStyles();
  const msgListRef = useRef(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expandMessageInputArea, setExpandMessageInputArea] = React.useState<boolean>(false);
  const [selected, setSelected] = useState(false);
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));
  const [bestPractices, setBestPractices] = useState(false);
  const [replyToMessage, setReplyToMessage] = useState<Message>(null);
  const [loanId, setLoanId] = useState<number>();
  const [chatRoomType, setChatRoomType] = useState<ChatRoomType>();
  const [companyId, setCompanyId] = useState<number>();
  const [userId, setUserId] = useState<number>();
  const [mentionsList, setMentionsList] = useState([]);
  const [uiUnreadMessagesCount, setUiUnreadMessagesCount] = useState(0);
  const [uiUnreadMessagesCountClearIntervalId, setUiUnreadMessagesCountClearIntervalId] = useState<any>();
  const [scrollToValue, setScrollToValue] = useState<number>();
  const { userStore } = useUserStore();
  const {
    showNoConversationsMessage,
    conversations,
    participants,
    messages,
    currentConversationMessagesGroupedByDate,
    twilioReplyMessages,
    messagesPaginator,
    currentConversationSid,
    typingMessage,
    currentConversationTags,
    currentConversationTagsSelected,
    manageParticipantsStore,
    currentConversationReadOnly,
    currentConversationIsArchived,
    unreadMessagesCount,
    currentChatRoomId,
    loadingCurrentConversation,
    currentConversationFullReadOnly,
  } = communicationStore;
  const { userIdParticipantDetailsMap, userIdCalendlyLinkMap, internalParticipants } = manageParticipantsStore;
  const sidRef = useRef("");
  sidRef.current = currentConversationSid;
  const ratings = userStore.ratings;

  interface Attributes {
    firstName: string;
    lastName: string;
    userId: number;
  }

  const menuOpen = Boolean(anchorEl);

  const isInternalUser = useMemo(() => { return userStore.userInformation?.isInternal; }, [userStore.userInformation]);

  useEffect(() => {
    if (messages[currentConversationSid]?.length && !loadingCurrentConversation) {
      scrollToPosition(scrollToValue);
    }
  }, [messages[currentConversationSid]?.length, loadingCurrentConversation])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openedConversation = useMemo(() => {
    return conversations[currentConversationSid];
  }, [currentConversationSid, conversations[currentConversationSid]]);

  const hasParticipantsFetched = useMemo(() => {
    return participants[currentConversationSid] != undefined;
  }, [currentConversationSid, participants[currentConversationSid]]);

  const isConversationOpen = Boolean(openedConversation);

  const noMessages = useMemo(
    () => messages[currentConversationSid]?.length == 0,
    [currentConversationSid, conversations[currentConversationSid], messages[currentConversationSid]]
  );

  useEffect(() => {
    return () => {
      communicationStore.clearCurrentConversation();
    }
  }, []);

  useEffect(() => {
    if (openedConversation && !currentConversationReadOnly && !currentConversationFullReadOnly) {
      setUiUnreadMessagesCount(unreadMessagesCount[currentConversationSid]);
      clearTimeout(uiUnreadMessagesCountClearIntervalId);
      const timeoutId = setTimeout(() => {
        setUiUnreadMessagesCount(0);
      }, 60 * 1000);
      setUiUnreadMessagesCountClearIntervalId(timeoutId);
    }
  }, [openedConversation]);

  useEffect(() => {
    if (currentConversationFullReadOnly && openedConversation) {
      const conversationAttributes = openedConversation.attributes as ConversationAttribute;
      const loanId = conversationAttributes?.loanId;
      communicationStore.resetUnreadCount(loanId);
    }
    if (openedConversation && (currentConversationReadOnly || currentConversationFullReadOnly)) {
      const conversationAttributes = openedConversation.attributes as ConversationAttribute;
      const loanId = conversationAttributes?.loanId;
      fetchParticipants(loanId, currentChatRoomId);
    }
    if (openedConversation && !currentConversationReadOnly && !currentConversationFullReadOnly) {
      setScrollToValue(undefined);
      setReplyToMessage(null);
      const conversationAttributes = openedConversation.attributes as ConversationAttribute;
      const companyId = conversationAttributes?.companyId ? conversationAttributes?.companyId : userStore?.userInformation?.companyId ?? userStore?.globalStore?.selectedCompanyId;
      const loanId = conversationAttributes?.loanId;
      const proposalTopicId = conversationAttributes?.proposalTopicId;
      setLoanId(loanId);
      setChatRoomType(conversationAttributes?.chatRoomType);
      setCompanyId(companyId)
      if (communicationStore.isSupportConversation) {
        communicationStore.resetUnreadCount(companyId);
      } else if (communicationStore.isProposalTopicConversation) {
        communicationStore.resetUnreadCount(proposalTopicId);
      } else if (communicationStore.isTaskConversation) {
        communicationStore.setTaskId(conversationAttributes.taskId)
        communicationStore.resetUnreadCount(loanId);
      } else {
        communicationStore.resetUnreadCount(loanId);
      }
      setUserId(conversationAttributes?.userId)
      communicationStore.removeMentionMarker(currentConversationSid);
      communicationStore.setConversationMessageDefaultTag(conversationAttributes, {
        conversationSid: openedConversation.sid,
        chatRoomType: conversationAttributes.chatRoomType
      });
      fetchParticipants(loanId, currentChatRoomId);
      fetchExistingRating();
    }
  }, [openedConversation]);

  async function fetchParticipants(loanId, currentChatRoomId) {
    const mentionsLists = [];

    if (isNil(loanId)) {
      await manageParticipantsStore.getParticipantsAllowedByChatRoomId(currentChatRoomId, true);
    } else {
      await manageParticipantsStore.getParticipantsAllowedByLoanId(loanId, true);
    }

    let participants = communicationStore.manageParticipantsStore.internalParticipants;
    let hereParticipant = {
      firstName: 'here',
      lastName: '',
      assignedToLoan: null,
      roleDisplayName: null,
      roleDisplayColor: null
    }
    participants.push(hereParticipant);
    for (const key in participants) {
      const p = participants[key];
      mentionsLists.push({
        text: (
          <div style={{
            display: 'grid',
            gridTemplateColumns: '180px auto',
            justifyItems: 'start',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'space-between',
          }}>
            <span style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              {p.roleDisplayName != null && p.assignedToLoan &&
                <Star style={{
                  fontSize: 16,
                  marginRight: 4,
                  color: '#FF9800',
                }} />
              }
              <span>{`${p.firstName?.trim()} ${p.lastName?.charAt(0)} `}</span>
            </span>
            {p.roleDisplayName != null &&
              <Chip
                variant="default"
                size="small"
                label={p.assignedToLoan ? p.roleDisplayName + " - Assigned" : p.roleDisplayName}
                style={{
                  height: 18,
                  backgroundColor: p.assignedToLoan ? '#4CAF50' : p.roleDisplayColor,
                  color: p.roleDisplayColor ? theme.palette.getContrastText(p.roleDisplayColor) : 'black',
                  fontWeight: 'bold'
                }}
              />
            }
          </div>
        ),
        value: p.assignedToLoan ? `${p.firstName?.trim()} ${p.lastName?.charAt(0)} | ${p.roleDisplayName}` : `${p.firstName?.trim()} ${p.lastName?.charAt(0)}`,
        hasRole: p.roleDisplayName != null,
        role: p.roleDisplayName || "",
        name: `${p.firstName?.trim()} ${p.lastName?.charAt(0)}`,
        assignedToLoan: p.assignedToLoan,
        url: "javascript:void(0)"
      });
    }
    mentionsLists.sort((a, b) => {
      if (a.assignedToLoan !== b.assignedToLoan) {
        return a.assignedToLoan ? -1 : 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    setMentionsList(mentionsLists);
  }

  useEffect(() => {
    if (currentChatRoomId != undefined) {
      manageParticipantsStore.getParticipantsDetails(currentChatRoomId, true);
    }
  }, [currentChatRoomId]);

  const getParticipantsText = () => {
    if (!participants[currentConversationSid]) {
      return `Loading...`;
    }
    else if (participants[currentConversationSid].length) {
      const text = `${participants[currentConversationSid].length} ${participants[currentConversationSid].length > 1 ? " participants" : " participant"}`;
      return (
        (allowManageParticipants && !currentConversationReadOnly && !currentConversationFullReadOnly) ?
          <Box component={'span'}>
            <Link href="#" onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              manageParticipantsStore.openDialog();
            }} style={{
              color: theme.palette.secondary.dark
            }}>
              {text}
            </Link>
          </Box> : text
      )
    } else {
      return `No Participants`;
    }
  }

  const createMentionsArr = (mentions) => {
    let participants = communicationStore.participants;
    const keys = participants[currentConversationSid] && Object.keys(participants[currentConversationSid]);
    let mentionsArr = [];
    let uniqueMentions = [...new Set(mentions)];
    if (!uniqueMentions.includes("here ")) {
      for (let i in uniqueMentions) {
        let plainNameMention = (uniqueMentions[i] as string).split('|')[0].trim();
        for (let participant in keys) {
          const p = participants[currentConversationSid][participant].attributes as Attributes;
          const name = p.firstName + " " + p.lastName?.charAt(0);
          if (name === plainNameMention) {
            mentionsArr.push(participants[currentConversationSid][participant].sid);
          }
        }
      }
    } else {
      const loggedInUser = communicationStore.getloggedInParticpant(communicationStore.participants[communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
      const loggedInUserName = loggedInUser.firstName + " " + loggedInUser.lastName?.charAt(0);
      for (let participant in keys) {
        const p = participants[currentConversationSid][participant].attributes as Attributes;
        const name = p.firstName + " " + p.lastName?.charAt(0);
        if (name !== loggedInUserName) {
          mentionsArr.push(participants[currentConversationSid][participant].sid);
        }
      }
    }
    return mentionsArr
  }

  const onMessageSend = async (message, attachments, mentions) => {
    let mentionsArr = createMentionsArr(mentions);
    communicationStore.sendMessage(message, attachments, replyToMessage, mentionsArr, () => {
      setUiUnreadMessagesCount(0);
      setReplyToMessage(null);
      setScrollToValue(undefined);
    });
  };

  const toggleExpand = () => {
    setExpandMessageInputArea(!expandMessageInputArea);
  };

  const scrollToPosition = (scrollToValue = undefined) => {
    setTimeout(() => {
      const messagesContainerEl: any = document.getElementById(`message-list-container-${currentConversationSid}`);
      let scrollTop = messagesContainerEl.scrollHeight;
      if (scrollToValue) {
        scrollTop = Math.max(0, (messagesContainerEl.scrollHeight - scrollToValue));
      } else {
        // simply scroll to bottom
        scrollTop = messagesContainerEl.scrollHeight;
      }
      messagesContainerEl.scrollTo({
        behavior: 'instant',
        top: scrollTop
      });
    }, 100);
  };

  const isChatRoomActive = () => {
    return communicationStore.conversationChatRoomStatus[currentConversationSid] !== ChatRoomStatus.ARCHIVED;
  }

  const fetchExistingRating = async () => {
    try {
      const loggedInUser = communicationStore.getloggedInParticpant(communicationStore.participants[communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
      await userStore.fetchAllRatingsByChatRoomIdAndUserId(currentChatRoomId, loggedInUser.userId);
    } catch (error) {
      console.error('Error fetching existing ratings for this chat:', error);
    }
  };

  const unreadMessageStartSid = useMemo(
    () => {
      if (messages[currentConversationSid]?.length && uiUnreadMessagesCount > 0) {
        const unreadMessageStartIndex = (Math.max(messages[currentConversationSid].length - uiUnreadMessagesCount, 0));
        return messages[currentConversationSid][unreadMessageStartIndex].sid;
      }
      return null;
    },
    [uiUnreadMessagesCount, messages[currentConversationSid]]
  );

  const scrollAndHighlightMessage = (messagesContainerEl, replyMessageId) => {
    const replyMessageEl: any = messagesContainerEl.querySelector(`#container-${replyMessageId}`);
    if (replyMessageEl) {
      const replyMessageContentEl: any = replyMessageEl.querySelector(".cs-message__content");
      messagesContainerEl.scrollTo({
        behavior: 'instant',
        top: replyMessageEl.offsetTop
      });
      replyMessageContentEl.style.outline = '#f00 auto 1px';
      setTimeout(() => {
        replyMessageContentEl.style.outline = 'none';
      }, 2000);
    }
  };

  return (
    <>
      <CreateTaskDialog />
      <ChatContainer className={clsx(slimChatHeader ? classes.slimHeader : null, containerClass)}>
        {
          isConversationOpen ?
            <ConversationHeader className={classes.conversationHeader}>
              {
                onBackClick && <ConversationHeader.Back style={{
                  display: isTabletSize ? 'inherit' : 'none'
                }} onClick={onBackClick} />
              }
              <ChatUiAvatar src={getIcon(openedConversation)} className={classes.conversationAvatar} />
              <ConversationHeader.Content>
                <CustomConversationHeaderContent
                  loanId={loanId}
                  chatRoomType={chatRoomType}
                  displayName={communicationStore.getConversationDisplayName(openedConversation) ?? currentConversationSid}
                  communicationStore={communicationStore}
                  hasParticipantsFetched={hasParticipantsFetched} />
                <div className="cs-conversation-header__info">{getParticipantsText()}</div>
                {
                  selected && currentConversationTags.length > 0 && <div className="search-container">
                    <FilterTagsContainer
                      data={currentConversationTags}
                      selectedData={currentConversationTagsSelected}
                      onChange={(newValues) => {
                        communicationStore.setCurrentConversationTagsSelected(newValues);
                      }}
                    />
                  </div>
                }
              </ConversationHeader.Content>
              <ConversationHeader.Actions>
                {isInternalUser && <ToggleButton
                  style={{
                    border: 'none',
                  }}
                  className={classes.bestPracticesToggle}
                  value="check"
                  selected={bestPractices}
                  onChange={() => {
                    setBestPractices(!bestPractices);
                  }}>
                  <Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <ThumbUp fontSize="small" style={{ marginRight: '2px' }} />
                    <Typography style={{ fontSize: '14px', margin: 'auto 3px' }} display="inline">Best</Typography>
                    <Typography style={{ fontSize: '14px' }} display="inline">Practices</Typography>
                  </Box>
                </ToggleButton>}
                {isConversationOpen && isChatRoomActive() && !communicationStore.isSupportConversation && !currentConversationReadOnly && allowLeaveConversation && !currentConversationFullReadOnly &&
                  <ConfirmationButton style={{ backgroundColor: 'transparent' }}
                    testId="leave"
                    fullWidth
                    onClick={() => manageParticipantsStore.leaveConversation(participants, currentConversationSid)}
                    endIcon={<ExitToApp />}
                    title="Leave"
                    confirmDialogProps={{
                      title: `Leave ${communicationStore.getConversationDisplayName(openedConversation) ?? currentConversationSid} conversation`,
                      dialogContentclasses: dialogContentclasses,
                      body: (
                        <>
                          <Warning
                            color="error"
                            fontSize="large"
                            style={{ marginRight: '16px' }}
                          />
                          <span>
                            <strong>{`Are you sure you want to leave the ${communicationStore.getConversationDisplayName(openedConversation) ?? currentConversationSid} conversation?`}</strong>{`  You can join this conversation again at any time.`}
                          </span>
                        </>
                      ),
                      confirmButtonText: 'Leave',
                    }}
                  >
                    Leave
                  </ConfirmationButton>
                }
                {
                  currentConversationTags.length > 0 &&
                  <ToggleButton
                    style={{
                      border: 'none'
                    }}
                    value="check"
                    selected={selected}
                    onChange={() => {
                      setSelected(!selected);
                    }}>
                    <Badge color="error" variant="dot" invisible={currentConversationTagsSelected?.length == 0}>
                      <FilterList />
                    </Badge>
                  </ToggleButton>
                }
                {isChatRoomActive() && (
                  <IconButton onClick={handleMenuOpen}>
                    <MoreVert />
                  </IconButton>
                )}
              </ConversationHeader.Actions>
            </ConversationHeader> :
            <ConversationHeader className={classes.conversationHeader} style={{
              display: 'none'
            }}>
              <ConversationHeader.Content />
            </ConversationHeader>
        }
        {
          expandMessageInputArea ? null :
            <MessageList
              className={classes.messageList}
              style={{
                marginTop: (selected && currentConversationTags.length > 0) ? 60 : 0
              }}
              autoScrollToBottom={false}
              ref={msgListRef}
              typingIndicator={typingMessage ? <TypingIndicator content={typingMessage} /> : undefined}>
              {
                loadingCurrentConversation ?
                  <MessageList.Content className={classes.loadingContentMessageList}>
                    <CircularLoader position="absolute" thickness={5} color="primary" size={36} />
                  </MessageList.Content> : null
              }
              {
                isConversationOpen && !loadingCurrentConversation && hasParticipantsFetched && messages[currentConversationSid] &&
                <MessageList.Content style={{
                  width: '100%',
                  marginBottom: 24,
                  display: 'flex'
                }}>
                  <Box width={isInternalUser && bestPractices && !isTabletSize ? '60%' : '100%'} height={'100%'} style={{ overflow: 'auto' }} position={'absolute'} left={0} id={`message-list-container-${currentConversationSid}`} className="chat-messages-container">
                    {Object.keys(currentConversationMessagesGroupedByDate).map((date, topIndex) => {
                      const _messages = currentConversationMessagesGroupedByDate[date];
                      return (
                        <>
                          {
                            (currentConversationTagsSelected?.length == 0
                              && topIndex == 0
                              && !messagesPaginator[currentConversationSid]) ?
                              <ConversationHeadingMessage
                                as={ChatUiMessage}
                                participantsText={getParticipantsText()} /> : null
                          }
                          {
                            (currentConversationTagsSelected?.length == 0
                              && topIndex == 0
                              && messagesPaginator[currentConversationSid]) ?
                              <LoadPreviousMessages
                                as={ChatUiMessage}
                                onClick={() => {
                                  const messagesScrollContainerEl: any = document.getElementById(`message-list-container-${currentConversationSid}`);
                                  setScrollToValue(messagesScrollContainerEl.scrollHeight);
                                  communicationStore.loadCurrentConversationPreviousPageMessages()
                                }} /> : null
                          }
                          <MessageWrapper as={ChatUiMessage} date={date}>
                            {
                              _messages?.filter((m, i) => filterByTagsSelected(m, currentConversationTagsSelected))
                                .map((m, i) => {
                                  const isCurrentUser = communicationStore.isCurrentUser(m.author);
                                  const hasMedia = m.attachedMedia?.length > 0;
                                  const emoji = (m.attributes as MessageAttribute)?.emoji as Record<SupportedEmojis, string[]>;
                                  const likes = emoji?.[SupportedEmojis.Likes] ?? [];
                                  const doesCurrentUserLikedTheMessage = communicationStore.doesCurrentUserLikedTheMessage(likes);
                                  return (
                                    <>
                                      {
                                        (currentConversationTagsSelected?.length == 0
                                          && m.sid == unreadMessageStartSid) ?
                                          <NewMessageIndicator as={ChatUiMessage} count={uiUnreadMessagesCount} /> : null
                                      }
                                      <ChatUiMessage
                                        key={m.sid}
                                        model={{
                                          direction: isCurrentUser
                                            ? 'outgoing'
                                            : 'incoming',
                                          position: 'single',
                                        }}
                                        className={classes.message}
                                        id={`container-${m.sid}`}
                                        data-message-sid={`container-${m.sid}`}
                                      >
                                        {!isCurrentUser && (
                                          <ChatUiAvatar status="available">
                                            <AccountCircleOutlined
                                              style={{
                                                width: '100%',
                                                height: '100%',
                                                color: '#777',
                                              }}
                                            />
                                          </ChatUiAvatar>
                                        )}
                                        {!isCurrentUser && (
                                          <CustomMessageHeader
                                            as={ChatUiMessage.Header}
                                            messageSid={m.sid}
                                            name={getAuthorName(m)}
                                            participants={participants[currentConversationSid]}
                                            allowParticipants={internalParticipants}
                                            participantSid={(m.participantSid)}
                                            onHuddle={onHuddle}
                                            onUserNewConversation={onUserNewConversation}
                                            onOpenAddTask={onOpenAddTask}
                                            roles={userIdParticipantDetailsMap}
                                            communicationStore={communicationStore}
                                            loanId={loanId}
                                            chatRoomType={chatRoomType}
                                            ratings={ratings}
                                            calendlyLinks={userIdCalendlyLinkMap}
                                            messageBody={cleanHtmlText(m.body)}
                                            messageCreatedDate={m.dateCreated}
                                            author={m.author}
                                          />
                                        )}
                                        <ChatUiMessage.CustomContent>
                                          <>
                                            <MessageHoverMenu firstMessage={i == 0} key={m.sid} message={m} onReplyClick={() => setReplyToMessage(m)} doesCurrentUserLikedTheMessage={doesCurrentUserLikedTheMessage} onLike={() => {
                                              const loggedInParticipantSid = communicationStore.getloggedInParticpant(communicationStore.participants[communicationStore.currentConversationSid])?.sid;
                                              if (loggedInParticipantSid) {
                                                if (doesCurrentUserLikedTheMessage) {
                                                  communicationStore.undoLikeMessage(m, loggedInParticipantSid);
                                                } else {
                                                  communicationStore.likeMessage(m, loggedInParticipantSid);
                                                }
                                              }
                                            }}>
                                              {hasMedia && (
                                                <>
                                                  {m.attachedMedia.map(media => (
                                                    <AttachmentPreview key={media.sid} media={media} />
                                                  ))}
                                                </>
                                              )}
                                              {
                                                (m.attributes as any)?.replyMessageSid &&
                                                <Box mb={1}>
                                                  <ReplyToMessage
                                                    message={(() => {
                                                      const replyMessageId = (m.attributes as any)?.replyMessageSid;
                                                      const currentTwilioReplyMessages = twilioReplyMessages[currentConversationSid] ?? {};
                                                      const replyToMessage = currentTwilioReplyMessages[replyMessageId] ?? undefined;
                                                      return replyToMessage;
                                                    })()}
                                                    onClick={(replyMessageId) => {
                                                      const messagesContainerEl: any = document.getElementById(`message-list-container-${currentConversationSid}`);
                                                      const replyMessageEl: any = messagesContainerEl.querySelector(`#container-${replyMessageId}`);
                                                      if (replyMessageEl) {
                                                        scrollAndHighlightMessage(messagesContainerEl, replyMessageId);
                                                      }
                                                      else {
                                                        communicationStore.loadCurrentConversationPreviousPageMessagesRecursivelyTillMessageFound(replyMessageId)
                                                          .then(() => {
                                                            setTimeout(() => {
                                                              scrollAndHighlightMessage(messagesContainerEl, replyMessageId);
                                                            }, 1000);
                                                          });
                                                      }
                                                    }}
                                                    style={{
                                                      zoom: 0.8,
                                                      borderRadius: 8,
                                                    }} />
                                                </Box>
                                              }
                                              <HtmlMessageContent id={m.sid} html={m.body} />
                                              {
                                                likes.length > 0 &&
                                                <LikesChip
                                                  communicationStore={communicationStore}
                                                  likes={likes}
                                                />
                                              }
                                            </MessageHoverMenu>
                                            {isChatRoomActive() && (
                                              <ReplyButton isCurrentUserMessage={isCurrentUser} onClick={() => setReplyToMessage(m)} />
                                            )}
                                            {
                                              isCurrentUser && <ReadReceiptButton communicationStore={communicationStore} message={m} />
                                            }
                                          </>
                                        </ChatUiMessage.CustomContent>
                                        <ChatUiMessage.Footer
                                          sentTime={getMessageTime(m)}
                                        />
                                      </ChatUiMessage>
                                    </>
                                  );
                                })
                            }
                          </MessageWrapper>
                        </>
                      );
                    })
                    }

                  </Box>
                  {!isTabletSize && isInternalUser && bestPractices &&
                    <Box width={'40%'} height={'100%'} style={{ overflow: 'auto', borderLeft: '1px solid #ccc' }} position={'absolute'} right={0} className="best-practices">
                      <BestPractices handleClose={() => setBestPractices(false)} />
                    </Box>}
                  {isTabletSize &&
                    <Drawer open={isInternalUser && bestPractices} variant={'temporary'} anchor={'right'} onClose={() => setBestPractices(false)}
                      ModalProps={{ keepMounted: true }} PaperProps={{
                        style: {
                          width: '80%'
                        }
                      }}>
                      <BestPractices handleClose={() => setBestPractices(false)} />
                    </Drawer>}
                </MessageList.Content>
              }
              {
                !isConversationOpen && !loadingCurrentConversation && showNoConversationsMessage ?
                  <MessageList.Content className={classes.emptyContentMessageList}>
                    No Conversations.
                  </MessageList.Content> : null
              }
              {
                !isConversationOpen && !loadingCurrentConversation && !showNoConversationsMessage &&
                (
                  !(communicationStore instanceof TechSupportCommunicationStore)
                    ? <MessageList.Content className={classes.emptyContentMessageList}>
                      {
                        showLoading ? <CircularLoader position="absolute" thickness={5} color="primary" size={36} /> :
                          'Select a conversation on the left to get started.'
                      }
                    </MessageList.Content>
                    : communicationStore instanceof TechSupportCommunicationStore
                      ? <MessageList.Content className={classes.emptyContentMessageList}>
                        Loading...
                      </MessageList.Content>
                      : null
                )
              }
              {
                isConversationOpen && !loadingCurrentConversation && noMessages ?
                  <MessageList.Content style={{ display: 'flex' }}>
                    <Box width={isInternalUser && bestPractices ? '60%' : '100%'} height={'100%'} position={'absolute'} left={0}
                      className={classes.emptyContentMessageList}>
                      No Messages.
                    </Box>
                    {!isTabletSize && isInternalUser && bestPractices &&
                      <Box width={'40%'} style={{ overflow: 'auto', borderLeft: '1px solid #ccc' }} height={'100%'} position={'absolute'} right={0} className="best-practices">
                        <BestPractices handleClose={() => setBestPractices(false)} />
                      </Box>}
                    {isTabletSize &&
                      <Drawer open={isInternalUser && bestPractices} variant={'temporary'} anchor={'right'} onClose={() => setBestPractices(false)}
                        ModalProps={{ keepMounted: true }} PaperProps={{
                          style: {
                            width: '80%'
                          }
                        }}>
                        <BestPractices handleClose={() => setBestPractices(false)} />
                      </Drawer>}
                  </MessageList.Content> : null
              }
            </MessageList>
        }
        {
          isConversationOpen && !currentConversationReadOnly && !currentConversationFullReadOnly && isChatRoomActive() ?
            <CustomMessageInputContainer
              as={MessageInput}
              roles={userIdParticipantDetailsMap}
              chatRoomType={chatRoomType}
              replyToMessage={replyToMessage}
              onReplyClear={() => setReplyToMessage(null)}
              sendMessage={onMessageSend}
              communicationStore={communicationStore}
              mentionsList={mentionsList}
              onChange={() => {
                openedConversation.typing();
              }}
              messageSnippets={communicationStore.messageSnippets}
              expandMessageInputArea={expandMessageInputArea}
              toggleExpand={toggleExpand}
              loading={communicationStore.loading}
              showSnippet={showSnippet}
              participants={participants}
              currentConversationSid={currentConversationSid}
              isTabletSize={isTabletSize} /> : null

        }
        {
          isConversationOpen && currentConversationReadOnly && allowJoinConversation ?
            <JoinToolBox
              as={InputToolbox}
              communicationStore={communicationStore} /> : null
        }
        {
          isConversationOpen && currentConversationIsArchived ?
            <ArchivedChatInfoBox as={InputToolbox} communicationStore={communicationStore} /> : null
        }

        {
          isConversationOpen && currentConversationFullReadOnly ?
            <FullReadOnlyChatInfoBox as={InputToolbox} communicationStore={communicationStore} /> : null
        }
      </ChatContainer>


      <ManageParticipantsDialog
        communicationStore={communicationStore}
        allowAddParticipants={allowAddParticipants}
        allowAddInternalParticipants={allowAddInternalParticipants}
        allowRemoveParticipants={allowRemoveParticipants}
        showEmailAddress={showEmailAddress}
        loanId={loanId}
      />

      <Menu
        id="menu-conversations"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {allowManageParticipants && !currentConversationReadOnly && !currentConversationFullReadOnly && (
          <MenuItem testId="manage-participants" onClick={() => {
            handleMenuClose();
            manageParticipantsStore.openDialog();
          }}>
            <ListItemIcon>
              <Group fontSize="small" />
            </ListItemIcon>
            <ListItemText>Manage Participants</ListItemText>
          </MenuItem>
        )}
        {
          onDownloadConversation &&
          <MenuItem testId="manage-participants" onClick={() => {
            handleMenuClose();
            onDownloadConversation();
          }}>
            <ListItemIcon>
              <Group fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download Conversation</ListItemText>
          </MenuItem>
        }
      </Menu>
    </>
  )
});

const CustomConversationHeaderContent = observer((
  {
    loanId,
    chatRoomType,
    displayName,
    communicationStore,
    hasParticipantsFetched,
  }:
    {
      loanId: number;
      chatRoomType: ChatRoomType;
      displayName: string;
      communicationStore: CommunicationStore;
      hasParticipantsFetched: boolean;
    }) => {
  const [state, setState] = useState<"NORMAL" | "EDIT">("NORMAL");
  const [isBorrowerRoom, setIsBorrowerRoom] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [newDisplayName, setNewDisplayName] = useState<string>('');
  const isBorrowerUser = communicationStore.globalStore.isBorrowerUser;

  useEffect(() => {
    if (hasParticipantsFetched) {
      setIsBorrowerRoom(communicationStore.isBorrowerRoom(communicationStore.currentConversation));
    }
  }, [hasParticipantsFetched]);

  useEffect(() => {
    setName(displayName);
  }, [displayName]);
  return (
    <div className="cs-conversation-header__user-name" style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <Box minWidth={0} overflow={'hidden'} textOverflow={'ellipsis'} maxWidth={"100%"}>
        {
          state == "EDIT" ?
            <TextField style={{ marginRight: '20px', height: 24 }}
              autoFocus
              testId="conversationName"
              size='small'
              variant="outlined"
              label=""
              type="email"
              value={newDisplayName}
              onChange={e => setNewDisplayName(e.target.value)}
              inputProps={{
                style: {
                  padding: '2px 8px'
                },
              }}
              InputProps={{
                style: {
                  marginTop: '2px'
                },
              }}
              fullWidth
            />
            : <>
              {
                isBorrowerRoom && !isBorrowerUser && <BorrowerIndicatorIcon />
              }
              {name}
            </>
        }
      </Box>
      {
        chatRoomType == ChatRoomType.ADHOC &&
        <Box>
          {
            state == "EDIT" ?
              <>
                <Tooltip title="Save" placement="top" arrow>
                  <IconButton color="primary" onClick={() => {
                    if (newDisplayName && newDisplayName.trim()) {
                      if (newDisplayName != name) {
                        communicationStore.updateConversationName(newDisplayName, communicationStore.currentChatRoomId, loanId)
                          .then((result) => {
                            if (result) {
                              setName(newDisplayName);
                            }
                          })
                          .finally(() => {
                            setState("NORMAL");
                          });
                      } else {
                        setState("NORMAL");
                      }
                    }
                  }} size="small">

                    <Save fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel" placement="top" arrow>
                  <IconButton color="default" onClick={() => {
                    setNewDisplayName('');
                    setState("NORMAL");
                  }} size="small">
                    <Cancel fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
              :
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton color="primary" onClick={() => {
                  setState("EDIT");
                  setNewDisplayName(displayName);
                }}
                  size="small"
                  style={{
                    padding: 2,
                    marginLeft: 4,
                    width: 24,
                    height: 24,
                  }}>
                  <Edit style={{
                    fontSize: 16
                  }} />
                </IconButton>
              </Tooltip>
          }
        </Box>
      }
    </div>
  )
});

const UserRating = ({ messageSid, senderId, receiverId, loanId, chatRoomId, ratings, messageBody, messageCreatedDate, setHaveRating }) => {
  const { userStore } = useUserStore();
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [temporalRating, setTemporalRating] = useState(0);
  const [comment, setComment] = useState('');
  const [ratingSubmitted, setRatingSubmitted] = useState(false);

  const handleRatingClick = (newRating) => {
    setTemporalRating(newRating);
    setCommentBoxOpen(true);
  };

  const handleCommentSubmit = async () => {
    setRating(temporalRating);
    const userRatingRequest = {
      loanId: loanId,
      chatRoomId: chatRoomId,
      senderUserId: senderId,
      receiverUserId: receiverId,
      comment: comment,
      rating: temporalRating,
      messageBody: messageBody,
      messageCreatedDate: messageCreatedDate,
    };
    await userStore.submitUserRating(userRatingRequest);
    await userStore.fetchAllRatingsByChatRoomIdAndUserId(chatRoomId, senderId);
    setCommentBoxOpen(false);
    setComment('');
    setRatingSubmitted(true);
    setHaveRating(true)
  };

  const closeCommentDialog = () => {
    setRating(0);
    setTemporalRating(0);
    setCommentBoxOpen(false);
    setComment('');
  };

  useEffect(() => {
    const existingRating = ratings ? ratings.find(r => r.receiverUserId === receiverId) : false;
    if (existingRating) {
      setRating(existingRating.rating);
      setComment(existingRating.comment);
      setHaveRating(true);
      setRatingSubmitted(true);
    }
  }, [ratings, receiverId]);

  return (
    <>

      <Typography style={{
        fontWeight: 'bold',
        color: 'black',
        fontSize: 15
      }}>
        <Box display="flex" alignItems="center">
          <span>How am I doing? Rate me anonymously:</span>
          <Rating
            name={messageSid}
            value={rating}
            onChange={(event, newValue) => {
              handleRatingClick(newValue);
            }}
            disabled={ratingSubmitted}
            precision={1}
            size="medium"
          />
        </Box>
      </Typography>


      <Dialog open={commentBoxOpen} onClose={() => setCommentBoxOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>How am I doing?</DialogTitle>
        <DialogContent>
          <TextField
            testId="comment"
            standaloneLabel
            variant="outlined"
            value={comment}
            onChange={e => setComment(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCommentDialog} color="primary" variant="outlined" testId="cancel">
            Cancel
          </Button>
          <Button onClick={handleCommentSubmit} color="primary" variant="contained" testId="submit">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CustomMessageHeader = observer(({ as, messageSid, name, participants, allowParticipants, participantSid, onHuddle, onUserNewConversation, onOpenAddTask, roles, communicationStore, loanId, chatRoomType, ratings, calendlyLinks, messageBody, messageCreatedDate, author }: {
  as: any,
  messageSid: string,
  name: string,
  participants: Participant[],
  allowParticipants: Record<number, ParticipantDetails>,
  participantSid: string,
  onHuddle: (inviteUserIds: number[]) => void,
  onUserNewConversation: (inviteUserId: number) => void,
  onOpenAddTask: (userId: number) => void,
  roles: Record<number, ParticipantDetails>,
  communicationStore: CommunicationStore,
  loanId: number,
  chatRoomType: ChatRoomType,
  ratings: any;
  calendlyLinks: Record<number, string>,
  messageBody: string,
  messageCreatedDate: Date,
  author: string
}) => {
  let isInternalUser = null;
  let roleName = null;
  let hasRole = null;
  let roleColor = null;
  let isActive = true;
  const classes = useStyles();
  const theme = useTheme();
  const userFromAllowedParticipants = author ? getUserFromAllowedParticipants(Number(author.split('_')[0]), allowParticipants) : undefined;
  const participant = participants?.find(p => {
    if (p.sid == participantSid) {
      return true;
    } else {
      return false;
    }
  });
  const userId = participant ? (participant.attributes as ParticipantAttribute).userId : userFromAllowedParticipants;
  const hideHuddle = (participant?.attributes as ParticipantAttribute)?.isSystemUser ?? true;
  const [haveRating, setHaveRating] = useState();
  if (ratings) {
    checkIfExistingRating(ratings, userId)
  }

  if (participant) {
    isInternalUser = userId !== undefined ? isInternal(userId.toString(), roles) : null;
    roleName = userId !== undefined ? getRoleInfoForAuthor(userId.toString(), roles).role : null;
    hasRole = userId !== undefined ? getRoleInfoForAuthor(userId.toString(), roles).hasRole : null;
    roleColor = userId !== undefined ? getRoleInfoForAuthor(userId.toString(), roles).roleColor : null;
    isActive = userId !== undefined ? getRoleInfoForAuthor(userId.toString(), roles).isActive : true;
  } else {
    isInternalUser = (userId !== undefined && userId !== null) ? isInternal(userId.toString(), allowParticipants) : null;
    roleName = (userId !== undefined && userId !== null) ? getRoleInfoForAuthor(userId.toString(), allowParticipants).role : null;
    hasRole = (userId !== undefined && userId !== null) ? getRoleInfoForAuthor(userId.toString(), allowParticipants).hasRole : null;
    roleColor = (userId !== undefined && userId !== null) ? getRoleInfoForAuthor(userId.toString(), allowParticipants).roleColor : null;
    isActive = (userId !== undefined && userId !== null) ? getRoleInfoForAuthor(userId.toString(), allowParticipants).isActive : true;
  }
  const loggedInUser = communicationStore.getloggedInParticpant(communicationStore.participants[communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
  const isNportalUser = loggedInUser?.userId !== undefined ? isInternal(loggedInUser.userId.toString(), roles) : null;
  const participantCalendlyLink = calendlyLinks[userId] || null;

  return (
    <Box display={'flex'} alignItems='center' bgcolor={(!haveRating && chatRoomType === ChatRoomType.UNDERWRITING && !isNportalUser && isInternalUser) ? '#fff9c4' : ''}>
      <Box mr={1}>
        <Typography style={{
          fontWeight: 'bold',
          color: '#666',
          fontSize: 14
        }}>
          <Box display="flex" alignItems="center" gridGap="5px">
            <DisplayNameWithOnlineStatus
              userId={userId}
              displayName={name}
              isActive={isActive}
              allowHuddle={!hideHuddle}
              onHuddle={onHuddle}
              onUserNewConversation={onUserNewConversation}
              onOpenAddTask={onOpenAddTask}
              showHoverPopup={true}
              participantCalendlyLink={participantCalendlyLink}
              roleName={roleName}
              isInternalUser={isInternalUser} />
            {hasRole && isActive &&
              <Chip variant="default"
                size="small"
                label={roleName}
                style={{
                  height: 18,
                  margin: 4,
                  backgroundColor: roleColor,
                  color: roleColor ? theme.palette.getContrastText(roleColor) : 'black',
                  fontWeight: 'bold'
                }} />
            }
            {userId != undefined && chatRoomType === ChatRoomType.UNDERWRITING && !isNportalUser && isInternalUser &&
              <UserRating
                key={userId}
                messageSid={messageSid}
                senderId={loggedInUser?.userId}
                receiverId={userId}
                loanId={loanId}
                chatRoomId={communicationStore.currentChatRoomId}
                ratings={ratings}
                messageBody={messageBody}
                messageCreatedDate={messageCreatedDate}
                setHaveRating={setHaveRating}
              />
            }
          </Box>
        </Typography>
      </Box>
    </Box>
  );
});

const ConversationHeadingMessage = observer(({ as, participantsText }: {
  as: any,
  participantsText: any,
}) => {
  return (
    <Box pt={3} px={1} mx={2} fontSize={20} color={'#a5a5a5'}>
      <Typography variant={'h6'}>
        This is the very beginning of the conversation.
      </Typography>
      <Typography variant={'h6'}>
        <span>This conversation has </span>
        {participantsText}
        <span>.</span>
      </Typography>
    </Box>
  );
});

const LoadPreviousMessages = observer(({ as, onClick }: {
  as: any,
  onClick: any
}) => {
  return (
    <Box width="100%"
      display={'flex'}
      justifyContent={'center'}>
      <Button testId="loanPreviousMessages" variant="text" color="secondary" onClick={onClick} style={{
        marginTop: 8,
        textDecoration: 'underline',
        textTransform: 'capitalize',
        letterSpacing: 'normal',
      }}>
        Load Previous Messages
      </Button>
    </Box>
  );
});

const NewMessageIndicator = observer(({ as, count }: {
  as: any,
  count: number
}) => {
  return (
    <Box position="relative" width="100%">
      <Box position="absolute" width="100%" display={'flex'} fontSize={12} fontWeight="bold" color={"#ff0000"}>
        <Box flexGrow={1} alignItems='center' display={'flex'}>
          <Box width={'100%'} height={'1px'} bgcolor={'#ff0000'}>
          </Box>
        </Box>
        <Box mx={2}>
          New
        </Box>
        <Box flexGrow={1} alignItems='center' display={'flex'}>
          <Box width={'100%'} height={'1px'} bgcolor={'#ff0000'}>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

const MessageWrapper = observer(({ as, date, children }: {
  as: any,
  date: any,
  children: any,
}) => {
  return (
    <>
      {children}
    </>
  );
  /* const _date = new Date(date);
  const formattedDate = isToday(_date) ? 'Today' : format(_date, 'EEEE, MMMM do');
  return (
    <Box position="relative" width="100%" my={2}>
      <Box position="absolute" top={24} width="100%" display={'flex'}>
        <Box flexGrow={1} alignItems='center' display={'flex'}>
          <Box width={'100%'} height={'1px'} bgcolor={'#175689'}>
          </Box>
        </Box>
      </Box>
      <Box position="sticky" top={0} width="100%" display={'flex'} justifyContent={'center'} zIndex={1}>
        <Box mt={1}>
          <Chip
            variant="outlined"
            label={formattedDate}
            style={{
              color: '#175689',
              borderColor: '#175689',
              background: '#fff',
              fontWeight: 'bold'
            }}
          />
        </Box>
      </Box>
      {children}
    </Box>
  );*/
});
