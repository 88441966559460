import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Paper, PaperBorderType } from '@roc/ui';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    border: '1px solid #d5d5d5 !important',
    boxShadow: '0px 2px 6px rgba(0,0,0,0.16) !important',
    '&.MuiPaper-rounded': {
      borderRadius: theme.spacing(2),
    },
  },
  paperNoShadow: {
    overflow: 'hidden',
    boxShadow: 'none !important',
    border: '1px solid #d5d5d5 !important',
    '&.MuiPaper-rounded': {
      borderRadius: theme.spacing(2),
    },
  }
}));

export const LoanDetailsPaper = observer(({ style, noShadow, children }: {
  style?: CSSProperties,
  noShadow?: boolean,
  children: any;
}) => {
  const classes = useStyles();
  return <Paper borderType={PaperBorderType.SOLID_BORDER} className={classes.paperNoShadow} style={style}>{children}</Paper>;
});
