import {
  CheckCircleOutlineOutlined,
  CloudUploadOutlined,
  EditOutlined,
  MoreVertOutlined,
  HighlightOff,
  DeleteOutline
} from '@material-ui/icons';
import {
  DropdownMenu,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { InsuranceInformationBaseStore } from '../stores';

export const ActionsColumn = observer(({ quote, index, store }: { quote: any; index: number; store: InsuranceInformationBaseStore }) => {

  const getDocumentActions = () => {
    const actionList = [];
    if (!quote?.quoteShowId.includes('Elmsure')) {
      actionList.push({
        icon: EditOutlined,
        name: 'Edit Quote',
        action: () => store.handleOpenSave(index, quote?.selectedQuote),
      });
      actionList.push({
        icon: CloudUploadOutlined,
        name: 'Upload Document',
        action: () => store.handleUpload(index, quote?.selectedQuote),
      });
    }
    return actionList;
  };


  const getInternalDocumentActions = () => {
    const actionList = [];
    if (!quote?.quoteShowId.includes('Elmsure')) {
      actionList.push({
        icon: EditOutlined,
        name: 'Edit Quote',
        action: () => store.handleOpenSave(index, quote?.selectedQuote),
      });
      actionList.push({
        icon: CloudUploadOutlined,
        name: 'Upload Document',
        action: () => store.handleUpload(index, quote?.selectedQuote),
      });
    }
    if (!quote?.selectedQuote) {
      if (quote?.status == 'More Info Needed' || quote?.status == 'In Review') {
        if (store.insuranceQuoteFormStore.isInternal) {
          actionList.push({
            icon: DeleteOutline,
            name: 'Delete Quote',
            action: () => store.deleteQuote(quote?.insuranceQuoteDetailId),
          });
        }
      }
      if (quote?.status == 'Approved') {
        actionList.push({
          icon: CheckCircleOutlineOutlined,
          name: 'Select Quote as Final Choice',
          action: () => store.handleSelectAsFinalChoice(index, true),
        });
      }
    } else {
      actionList.push({
        icon: HighlightOff,
        name: 'Remove from Final Choice',
        action: () => store.handleSelectAsFinalChoice(index, false),
      });
    }

    return actionList;
  };

  const documentActions = useMemo(store.insuranceQuoteFormStore.isInternal ? getInternalDocumentActions : getDocumentActions, [
    quote,
  ]);

  return documentActions.length ? (
    <>
      <DropdownMenu options={documentActions}>
        <MoreVertOutlined />
      </DropdownMenu>
    </>
  ) : null;
});
