import { Box, makeStyles } from "@material-ui/core";
import { ReactNode } from "react";
const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #656565",
  },
  title: {
    color: "#ffffff",
    backgroundColor: "#656565",
    height: "24px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    fontSize: "12px",
  },
}));
interface FieldSetBoxProps {
  title: string;
  children: ReactNode;
  className?: string;
}
export const FieldSetBox = ({ title, children, className = "" }: FieldSetBoxProps) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box className={classes.title}>{title}</Box>
      {children}
    </Box>
  );
};