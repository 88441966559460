import { GlobalStore } from '@roc/feature-app-core';
import { observable, action, makeObservable, flow, computed } from 'mobx';
import { BackgroundSectionDetails, ItemDetails, loeDetails, QuickAnalysisFlags, AutomatedEvents } from '../types/backgroundReviewForm';
import { BackgroundReviewFormBaseStore } from './backgroundReviewFormBaseStore';
import { BorrowerInternalService } from '../services/borrowerInternalService';
import { BorrowerService } from '../services/borrowerService';
import { BorrowerBaseService } from '../services/borrowerBaseService';
import { ARREST_RECORD, BANKRUPTCY_RECORD, BORROWER_REVIEW_TYPES, COLLECTION_ACCOUNTS_RECORD, CRIMINAL_RECORD, DOCKET_RECORD, FORECLOSURE_RECORD, GLOBAL_SANCTION_RECORD, LAWSUIT_RECORD, LIEN_JUDGEMENT_RECORD, MORTGAGE_ACCOUNTS_RECORD, OTHER_ACCOUNTS_RECORD, OTHER_SSN_RECORD, PUBLIC_RECORD, QUICK_ANALYSIS_FLAGS, QUICK_CHECKS, TRADELINE_DATA_RECORD, TRADELINE_SUMMARY_RECORD, CHARGE_OFF_ACCOUNTS_RECORD, SSN_ADDRESS_FRAUD_RECORD, EXCLUDED_PARTY_RECORD, OTHER_NAMES_FOR_SSN_RECORD, EVENT_ACTION, ESCALATED_KEYWORDS_FLAGS } from '../components/utils/backgroundUtils';
import { MORTGAGE_HISTORY_RECORD } from '../components/utils/backgroundUtils';
import { formatDate, isNil } from '@roc/feature-utils';
import { PublicRecordItemFormStore } from './creditReview/publicRecordItemFormStore';


const TRADELINE_TOTAL_ROW = 'Total';

const sum = (arrayOfNumbers) => {
  return arrayOfNumbers.reduce((total, item) => total + parseFloat(item), 0)
}

interface InitOpenData {
  borrowerId?: string,
  borrowerName?: string,
  borrowerJson?: any,
  backgroundDate?: string,
  creditDate?: string,
  equifax?: number,
  transunion?: number,
  experian?: number,
  birthdayDate?: string,
  ssn?: string,
  isAddressFound?: boolean,
  isSellersNameFound?: boolean,
  isWholesalerNameFound?: boolean,
  creditComment?: string,
  assets?: number,
  liabilities?: number,
  netWorth?: number,
  annualIncome?: number,
  quickChecks?: [QuickAnalysisFlags],
  quickAnalysisFlags?: [QuickAnalysisFlags],
  escalatedKeywordFlags?: [QuickAnalysisFlags]
  backgroundSectionDetails?: BackgroundSectionDetails,
  letterOfExplanation?: [loeDetails],
  experience?: number,
  rentalExperience?: number,
  rentalMfExperience?: number,
  totalPropertiesOwned?: number,
  backgroundComments?: string,
  lockedData?: boolean,
  updateHistoryAuditLogs?: any[];
  foreclosure?: string;
  bankruptcy?: string;
  criminalRecord?: boolean;
  financialCrimes?: boolean;
  foreclosureDate?: string;
  bankruptcyDate?: string;
}
export abstract class BackgroundItemBaseStore {
  private globalStore: GlobalStore;
  private backgroundReviewFormBaseStore: BackgroundReviewFormBaseStore;
  currentBorrower: any;
  loanId: any;
  isInternal: boolean;
  readOnly: boolean;
  loan: any;

  public borrowerService: BorrowerBaseService;

  publicRecordItemFormStore: PublicRecordItemFormStore;

  isOpen: boolean;

  borrowerId: string;
  borrowerName: string;
  borrowerJson: any;
  backgroundDate: string;
  borrowerAddress: string;
  creditDate: string;
  equifax: number;
  transunion: number;
  experian: number;
  birthdayDate: string;
  ssn: string;
  isAddressFound: boolean;
  isSellersNameFound: boolean;
  isWholesalerNameFound: boolean;
  creditComment: string;
  assets?: number;
  liabilities?: number;
  netWorth?: number;
  annualIncome?: number;
  quickChecks: QuickAnalysisFlags[];
  quickAnalysisFlags: QuickAnalysisFlags[];
  escalatedKeywordFlags: QuickAnalysisFlags[];
  backgroundSectionDetails: BackgroundSectionDetails;
  letterOfExplanation: loeDetails[];
  quickChecksCounter: number;
  escalatedKeywordCounter: number;
  quickAnalysisFlagsCounter: number;
  itemsMap: {};
  experience: number;
  rentalExperience: number;
  foreclosure: string;
  bankruptcy: string;
  criminalRecord: boolean;
  financialCrimes: boolean;
  foreclosureDate: string;
  bankruptcyDate: string;
  rentalMfExperience: number;
  totalPropertiesOwned: number;
  backgroundComments: string;
  lockedData: boolean;
  updateHistoryAuditLogs: any[] = [];
  automatedEvents: AutomatedEvents[];
  reviewType: string;

  abstract getBackgroundReviewData(reviewType?: string);

  constructor(
    globalStore: GlobalStore,
    backgroundReviewFormBaseStore: BackgroundReviewFormBaseStore
  ) {
    this.globalStore = globalStore;
    this.backgroundReviewFormBaseStore = backgroundReviewFormBaseStore;
    this.quickChecksCounter = 0;
    this.publicRecordItemFormStore = new PublicRecordItemFormStore(globalStore, this);
    this.readOnly = false;
    makeObservable(this, {
      borrowerId: observable,
      borrowerName: observable,
      borrowerJson: observable,
      backgroundDate: observable,
      creditDate: observable,
      equifax: observable,
      transunion: observable,
      experian: observable,
      birthdayDate: observable,
      ssn: observable,
      isAddressFound: observable,
      isSellersNameFound: observable,
      isWholesalerNameFound: observable,
      creditComment: observable,
      assets: observable,
      liabilities: observable,
      netWorth: observable,
      annualIncome: observable,
      quickChecks: observable,
      quickAnalysisFlags: observable,
      escalatedKeywordFlags: observable,
      backgroundSectionDetails: observable,
      letterOfExplanation: observable,
      isOpen: observable,
      experience: observable,
      rentalExperience: observable,
      rentalMfExperience: observable,
      totalPropertiesOwned: observable,
      backgroundComments: observable,
      lockedData: observable,
      showLegacyFormLink: computed,
      showLegacyFormLinkFromBorrowerForInternal: computed,
      openModal: action,
      getBackgroundReviewData: flow,
      currentBorrower: observable,
      loanId: observable,
      isInternal: observable,
      saveData: flow,
      generateResolutions: flow,
      submitReview: flow,
      setCreditComment: action,
      setEquifax: action,
      setTransunion: action,
      setExperian: action,
      setBirthdayDate: action,
      setSsn: action,
      setExperience: action,
      setRentalExperience: action,
      setRentalMfExperience: action,
      setRentalExperienceConstruction: action,
      setAssets: action,
      setLiabilities: action,
      setNetWorth: action,
      setAnnualIncome: action,
      setBackgroundComments: action,
      tradelineDataRows: computed,
      tradelineTotalRow: computed,
      updateHistoryAuditLogs: observable,
      setCriminalRecord: action,
      setFinancialCrimes: action,
      foreclosureDate: observable,
      bankruptcyDate: observable,
      criminalRecord: observable,
      financialCrimes: observable,
      foreclosure: observable,
      bankruptcy: observable,
      generateLOE: flow,
      automatedEvents: observable,
      reviewType: observable,
    })

    this.initData();
    this.initItemsMap();
  }

  openModal(initData: InitOpenData = {}) {
    this.isOpen = true;
    this.borrowerId = initData.borrowerId;
    this.borrowerName = initData.borrowerName;
    this.borrowerJson = initData.borrowerJson;
    this.backgroundDate = initData.backgroundDate;
    this.creditDate = initData.creditDate;
    this.equifax = initData.equifax;
    this.transunion = initData.transunion;
    this.experian = initData.experian;
    this.birthdayDate = initData.birthdayDate;
    this.ssn = initData.ssn;
    this.isAddressFound = initData.isAddressFound;
    this.isSellersNameFound = initData.isSellersNameFound;
    this.isWholesalerNameFound = initData.isWholesalerNameFound;
    this.creditComment = initData.creditComment;
    this.assets = initData.assets;
    this.liabilities = initData.liabilities;
    this.netWorth = initData.netWorth;
    this.annualIncome = initData.annualIncome;
    this.quickChecks = initData.quickChecks;
    this.quickAnalysisFlags = initData.quickAnalysisFlags;
    this.escalatedKeywordFlags = initData.escalatedKeywordFlags;
    this.backgroundSectionDetails = initData.backgroundSectionDetails;
    this.letterOfExplanation = initData.letterOfExplanation;
    this.experience = initData.experience;
    this.rentalExperience = initData.rentalExperience;
    this.foreclosure = initData.foreclosure;
    this.rentalMfExperience = initData.rentalMfExperience;
    this.totalPropertiesOwned = initData.totalPropertiesOwned;
    this.backgroundComments = initData.backgroundComments;
    this.lockedData = initData.lockedData;
    this.updateHistoryAuditLogs = initData.updateHistoryAuditLogs;
    this.bankruptcy = initData.bankruptcy;
    this.foreclosureDate = initData.foreclosureDate;
    this.bankruptcyDate = initData.bankruptcyDate;
    this.criminalRecord = initData.criminalRecord;
    this.financialCrimes = initData.financialCrimes;
  }

  // getBackgroundReviewData = flow(function* (this: BackgroundItemBaseStore, reviewType?: string) {
  //   this.initData();
  //   this.quickChecks = [];
  //   this.letterOfExplanation = [];
  //   this.quickAnalysisFlagsCounter = 0;
  //   const response = yield this.borrowerService.getBorrowerDataCollection(Number(this.currentBorrower), this.loanId, reviewType);
  //   let borrowerData;
  //   if (!this.isInternal) {
  //    borrowerData = JSON.parse(response.data.data);
  //   borrowerData = borrowerData.data;
  //   } else {
  //    borrowerData = response.data.data;
  //   }
  //   this.borrowerName = borrowerData.borrowerName;
  //   this.borrowerJson = borrowerData;
  //   this.backgroundDate = borrowerData.backgroundDate;
  //   this.creditDate = borrowerData.creditDate;
  //   this.birthdayDate = borrowerData.birthdayDate || borrowerData.birthdate;
  //   this.ssn = borrowerData.ssn;
  //   this.equifax = borrowerData.equifax;
  //   this.transunion = borrowerData.transunion;
  //   this.experian = borrowerData.experian;
  //   this.creditComment = borrowerData.creditComment;
  //   this.assets = borrowerData.assets;
  //   this.liabilities = borrowerData.liabilities;
  //   this.netWorth = borrowerData.netWorth;
  //   this.annualIncome = borrowerData.annualIncome;
  //   this.experience = borrowerData.experience;
  //   this.rentalExperience = borrowerData.rentalExperience;
  //   this.rentalMfExperience = borrowerData.rentalMfExperience;
  //   this.totalPropertiesOwned = borrowerData.totalPropertiesOwned;
  //   this.backgroundComments = borrowerData.comment;
  //   this.lockedData = borrowerData.lockedData;
  //   this.quickChecks = borrowerData.quickChecks || [];
  //   this.backgroundSectionDetails.backgroundSection.arrestRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[ARREST_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.bankruptcyRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[BANKRUPTCY_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.criminalRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[CRIMINAL_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.docketRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[DOCKET_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.foreclosureRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[FORECLOSURE_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.globalSanction =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[GLOBAL_SANCTION_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.lawsuitRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[LAWSUIT_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.lienJudgmentRecord =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[LIEN_JUDGEMENT_RECORD].name] || [];
  //   this.backgroundSectionDetails.backgroundSection.otherSSNAssociated =
  //     borrowerData.backgroundSectionDetails?.backgroundSection[this.itemsMap[OTHER_SSN_RECORD].name] || [];
  //   this.quickAnalysisFlags = borrowerData.quickAnalysisFlags ? borrowerData.quickAnalysisFlags : [];

  //   // Credit
  //   this.backgroundSectionDetails.backgroundSection.tradelineSummary = borrowerData[TRADELINE_SUMMARY_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.tradelinesData = borrowerData[TRADELINE_DATA_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.mortgageHistory = borrowerData[MORTGAGE_HISTORY_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.otherLateTradelines = borrowerData[OTHER_ACCOUNTS_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.mortgageLateTradelines = borrowerData[MORTGAGE_ACCOUNTS_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.collectionTradelines = borrowerData[COLLECTION_ACCOUNTS_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.chargeOffTradelines = borrowerData[CHARGE_OFF_ACCOUNTS_RECORD] || [];
  //   this.backgroundSectionDetails.backgroundSection.publicRecords = borrowerData[PUBLIC_RECORD] || [];

  //   if (reviewType === BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW) {
  //     this.letterOfExplanation = borrowerData.letterOfExplanationList || [];
  //   } else {
  //     this.validateLetterOfExplanation();
  //   }

  // });

  initData() {
    if (!this.backgroundSectionDetails) {
      this.backgroundSectionDetails = {
        backgroundSection: {
          arrestRecord: [],
          bankruptcyRecord: [],
          criminalRecord: [],
          docketRecord: [],
          foreclosureRecord: [],
          lawsuitRecord: [],
          lienJudgmentRecord: [],
          otherSSNAssociated: [],
          ssnAddressFraudRecord: [],
          excludedPartyRecord: [],
          otherNamesRecord: [],
          globalSanction: [],
          tradelineSummary: [],
          tradelinesData: [],
          mortgageLateTradelines: [],
          otherLateTradelines: [],
          mortgageHistory: [],
          collectionTradelines: [],
          chargeOffTradelines: [],
          publicRecords: []
        },
        backgroundSectionCounter: {
          arrestRecord: 0,
          bankruptcyRecord: 0,
          criminalRecord: 0,
          docketRecord: 0,
          foreclosureRecord: 0,
          lawsuitRecord: 0,
          lienJudgmentRecord: 0,
          otherSSNAssociated: 0,
          ssnAddressFraudRecord: 0,
          excludedPartyRecord: 0,
          otherNamesRecord: 0,
          globalSanction: 0,
        }
      };
    }
  }

  // QUICK CHECKS.
  updateQuickCheckItem(index: number, record: string) {
    if (QUICK_CHECKS === record) {
      if (this.quickChecks[index]) {
        const itemName = this.quickChecks[index].flagItem;
        const flag = this.quickChecks[index].flagCheck;
        if (flag === true || isNil(flag)) {
          this.quickChecks[index].flagCheck = flag === false ? null : flag === true ? null : true;
          this.updateLOEQuickCheck(index);
          const actionPerfomed = this.quickChecks[index].flagCheck === true ? EVENT_ACTION.ITEM_FLAGGED : EVENT_ACTION.ITEAM_UNFLAGGED;
          this.setAutomatedEvent(actionPerfomed, itemName, record);
        }
      }
    } else if (ESCALATED_KEYWORDS_FLAGS === record) {
      if (this.escalatedKeywordFlags[index]) {
        const itemName = this.escalatedKeywordFlags[index].flagItem;
        const flag = this.escalatedKeywordFlags[index].flagCheck;
        if (flag === true || isNil(flag)) {
          this.escalatedKeywordFlags[index].flagCheck = flag === false ? null : flag == true ? null : true;
          this.updateLOEKeyword(index);
          const actionPerfomed = this.escalatedKeywordFlags[index].flagCheck === true ? EVENT_ACTION.ITEM_FLAGGED : EVENT_ACTION.ITEAM_UNFLAGGED;
          this.setAutomatedEvent(actionPerfomed, itemName, record);
        }
      }
    }
    else {
      if (this.quickAnalysisFlags[index]) {
        this.quickAnalysisFlags[index].flagCheck = !this.quickAnalysisFlags[index].flagCheck;
        const itemName = this.quickAnalysisFlags[index].flagItem;
        const actionPerfomed = this.quickAnalysisFlags[index].flagCheck === true ? EVENT_ACTION.ITEM_FLAGGED : EVENT_ACTION.ITEAM_UNFLAGGED;
        this.setAutomatedEvent(actionPerfomed, itemName, record);
      }
      //this.updateLetterOfExplanation(this.quickAnalysisFlags[index], record);
    }
  };

  addQuickCheckItem(itemName: string, isItemFlagged: boolean, record?: string, check?: string) {
    if (QUICK_CHECKS === record) {
      this.quickChecks.push({
        flagItem: itemName,
        flagCheck: isItemFlagged,
        check
      });
      this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, itemName, record);
      this.updateLOEQuickCheck(this.quickChecks.length - 1);
    } else if (ESCALATED_KEYWORDS_FLAGS === record) {
      this.escalatedKeywordFlags.push({
        flagItem: itemName,
        flagCheck: isItemFlagged,
        check
      });
      this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, itemName, record);
      this.updateLOEKeyword(this.escalatedKeywordFlags.length - 1);
    }
    else {
      this.quickAnalysisFlags.push({
        flagItem: itemName,
        flagCheck: isItemFlagged
      });
      this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, itemName, record);
    }
  };

  removeQuickCheckItem(index: number, record: string) {
    if (QUICK_CHECKS == record) {
      if (this.quickChecks[index]) {
        const itemName = this.quickChecks[index]?.flagItem;
        this.updateLOEListAfterRemoval(this.quickChecks[index], QUICK_CHECKS);
        this.quickChecks.splice(index, 1);
        this.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, itemName, record);
      }
    } else if (ESCALATED_KEYWORDS_FLAGS == record) {
      if (this.escalatedKeywordFlags[index]) {
        const itemName = this.escalatedKeywordFlags[index]?.flagItem;
        this.updateLOEListAfterRemoval(this.escalatedKeywordFlags[index], ESCALATED_KEYWORDS_FLAGS);
        this.escalatedKeywordFlags.splice(index, 1);
        this.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, itemName, record);
      }
    }
    else {
      if (this.quickAnalysisFlags[index]) {
        const itemName = this.quickAnalysisFlags[index]?.flagItem;
        this.quickAnalysisFlags.splice(index, 1);
        this.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, itemName, record);
      }
    }
  };

  editQuickCheckItem(itemName: string, isItemFlagged: boolean, index: number, record: string, check?: string) {
    if (QUICK_CHECKS === record) {
      this.quickChecks[index] = {
        flagItem: itemName,
        flagCheck: isItemFlagged,
        check
      }
      this.updateLOEQuickCheck(index);
      this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, itemName, record);
    } else if (ESCALATED_KEYWORDS_FLAGS === record) {
      this.escalatedKeywordFlags[index] = {
        flagItem: itemName,
        flagCheck: isItemFlagged,
        check
      }
      this.updateLOEQuickCheck(index);
      this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, itemName, record);
    }
    else {
      this.quickAnalysisFlags[index] = {
        flagItem: itemName,
        flagCheck: isItemFlagged
      }
    }
  };

  validateLetterOfExplanation() {
    this.quickChecks.forEach(q => {
      if (q.flagCheck) {
        this.quickChecksCounter++;
        this.letterOfExplanation.push({
          itemSection: "Quick Checks" + (this.quickChecksCounter > 9 ? " #" + this.quickChecksCounter : " #0" + this.quickChecksCounter),
          itemName: q.flagItem,
          itemDescription: "Check: " + q.check,
        });
      }
    });

    this.quickAnalysisFlags.forEach(q => {
      if (q.flagCheck) {
        this.quickAnalysisFlagsCounter++;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[QUICK_ANALYSIS_FLAGS].name + (this.quickAnalysisFlagsCounter > 9 ? " #" + this.quickAnalysisFlagsCounter : " #0" + this.quickAnalysisFlagsCounter),
          itemName: q.flagItem,
          itemDescription: ""
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[ARREST_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[ARREST_RECORD].counter = this.itemsMap[ARREST_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[ARREST_RECORD].name + (this.itemsMap[ARREST_RECORD].counter > 9 ? " #" + this.itemsMap[ARREST_RECORD].counter : " #0" + this.itemsMap[ARREST_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[BANKRUPTCY_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[BANKRUPTCY_RECORD].counter = this.itemsMap[BANKRUPTCY_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[BANKRUPTCY_RECORD].name + (this.itemsMap[BANKRUPTCY_RECORD].counter > 9 ? " #" + this.itemsMap[BANKRUPTCY_RECORD].counter : " #0" + this.itemsMap[BANKRUPTCY_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[CRIMINAL_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[CRIMINAL_RECORD].counter = this.itemsMap[CRIMINAL_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[CRIMINAL_RECORD].name + (this.itemsMap[CRIMINAL_RECORD].counter > 9 ? " #" + this.itemsMap[CRIMINAL_RECORD].counter : " #0" + this.itemsMap[CRIMINAL_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[DOCKET_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[DOCKET_RECORD].counter = this.itemsMap[DOCKET_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[DOCKET_RECORD].name + (this.itemsMap[DOCKET_RECORD].counter > 9 ? " #" + this.itemsMap[DOCKET_RECORD].counter : " #0" + this.itemsMap[DOCKET_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[FORECLOSURE_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[FORECLOSURE_RECORD].counter = this.itemsMap[FORECLOSURE_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[FORECLOSURE_RECORD].name + (this.itemsMap[FORECLOSURE_RECORD].counter > 9 ? " #" + this.itemsMap[FORECLOSURE_RECORD].counter : " #0" + this.itemsMap[FORECLOSURE_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[GLOBAL_SANCTION_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[GLOBAL_SANCTION_RECORD].counter = this.itemsMap[GLOBAL_SANCTION_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[GLOBAL_SANCTION_RECORD].name + (this.itemsMap[GLOBAL_SANCTION_RECORD].counter > 9 ? " #" + this.itemsMap[GLOBAL_SANCTION_RECORD].counter : " #0" + this.itemsMap[GLOBAL_SANCTION_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[LAWSUIT_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[LAWSUIT_RECORD].counter = this.itemsMap[LAWSUIT_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[LAWSUIT_RECORD].name + (this.itemsMap[LAWSUIT_RECORD].counter > 9 ? " #" + this.itemsMap[LAWSUIT_RECORD].counter : " #0" + this.itemsMap[LAWSUIT_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });
    this.backgroundSectionDetails.backgroundSection[LIEN_JUDGEMENT_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[LIEN_JUDGEMENT_RECORD].counter = this.itemsMap[LIEN_JUDGEMENT_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[LIEN_JUDGEMENT_RECORD].name + (this.itemsMap[LIEN_JUDGEMENT_RECORD].counter > 9 ? " #" + this.itemsMap[LIEN_JUDGEMENT_RECORD].counter : " #0" + this.itemsMap[LIEN_JUDGEMENT_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[OTHER_SSN_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[OTHER_SSN_RECORD].counter = this.itemsMap[OTHER_SSN_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[OTHER_SSN_RECORD].name + (this.itemsMap[OTHER_SSN_RECORD].counter > 9 ? " #" + this.itemsMap[OTHER_SSN_RECORD].counter : " #0" + this.itemsMap[OTHER_SSN_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });

    this.backgroundSectionDetails.backgroundSection[SSN_ADDRESS_FRAUD_RECORD].forEach(q => {
      if (q.loefFlag) {
        this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].counter = this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].counter + 1;
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].name + (this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].counter > 9 ? " #" + this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].counter : " #0" + this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].counter),
          itemName: q.item,
          itemDescription: q.itemDescription
        });
      }
    });
  }

  updateLetterOfExplanation(quickCheck: QuickAnalysisFlags, record: string) {
    if (this.letterOfExplanation) {
      const itemIndex = this.letterOfExplanation.findIndex(l => l.itemName === quickCheck.flagItem);
      if (quickCheck.flagCheck && itemIndex === -1) {
        if (QUICK_CHECKS === record) {
          this.quickChecksCounter++;
        } else if (ESCALATED_KEYWORDS_FLAGS === record) {
          this.escalatedKeywordCounter++;
        }
        else {
          this.quickAnalysisFlagsCounter++;
        }
        const nextSectionNumber = this.findNextAvailableSectionNumber(record);
        this.letterOfExplanation.push({
          itemSection: this.itemsMap[record].name + (nextSectionNumber > 9 ? " #" + nextSectionNumber : " #0" + nextSectionNumber),
          itemName: quickCheck.flagItem,
          itemDescription: QUICK_CHECKS === record ? "Check : " + quickCheck.check : ""
        })
      }
      else if (!quickCheck.flagCheck && itemIndex !== -1) {
        const removedSectionNumber = parseInt(this.letterOfExplanation[itemIndex].itemSection.split('#')[1]);
        this.letterOfExplanation.splice(itemIndex, 1);
        if (QUICK_CHECKS === record) {
          if (removedSectionNumber === this.quickChecksCounter) {
            this.quickChecksCounter--;
          }
        } else if (ESCALATED_KEYWORDS_FLAGS === record) {
          if (removedSectionNumber === this.escalatedKeywordCounter) {
            this.escalatedKeywordCounter--;
          }
        } else {
          if (removedSectionNumber === this.quickAnalysisFlagsCounter) {
            this.quickAnalysisFlagsCounter--;
          }
        }
        this.reorderLetterOfExplanation(record);
      }

      this.letterOfExplanation.sort((a, b) => {
        if (a.itemSection < b.itemSection) return -1;
        if (a.itemSection > b.itemSection) return 1;
        return 0;
      });
    }
  }

  getIndexForCreditLetterOfExplanation(recordItem: any, record: string) {
    return this.letterOfExplanation.findIndex(l => l.itemName === recordItem.item && l.itemSection.includes(this.itemsMap[record].section));
  }

  isCreditReviewData(record: string) {
    switch (record) {
      case TRADELINE_DATA_RECORD:
      case MORTGAGE_ACCOUNTS_RECORD:
      case OTHER_ACCOUNTS_RECORD:
      case COLLECTION_ACCOUNTS_RECORD:
      case CHARGE_OFF_ACCOUNTS_RECORD:
      case MORTGAGE_HISTORY_RECORD:
      case TRADELINE_SUMMARY_RECORD:
        return true;
        break;
    }
    return false;
  }

  updateLetterOfExplanationForRecordItems(recordItem: any) {
    const loe = recordItem.letterOfExplanation;
    let itemIndex = this.letterOfExplanation.findIndex(l => l.itemName === loe.itemName);
    if (loe.status === true) {
      this.letterOfExplanation.push({
        itemSection: loe.itemSection,
        itemName: loe.itemName,
        itemDescription: loe.itemDescription
      })
    } else if (isNil(loe.status) && itemIndex !== -1) {
      this.letterOfExplanation.splice(itemIndex, 1);
    }
    this.letterOfExplanation.sort((a, b) => {
      if (a.itemSection < b.itemSection) return -1;
      if (a.itemSection > b.itemSection) return 1;
      return 0;
    });
  };

  findNextAvailableSectionNumber(record: string): number {
    const usedSectionNumbers = this.letterOfExplanation.filter(l => l.itemSection.includes(this.itemsMap[record].name))
      .map(l => parseInt(l.itemSection.split('#')[1])).sort((a, b) => a - b);
    let nextSectionNumber = 1;
    for (let num of usedSectionNumbers) {
      if (num === nextSectionNumber) {
        nextSectionNumber++;
      } else {
        break;
      }
    }
    return nextSectionNumber;
  }

  findNextAvailableSectionNumberForRecord(record: string): number {
    const usedSectionNumbers = this.letterOfExplanation.filter(l => l.itemSection.includes(this.itemsMap[record].name))
      .map(l => parseInt(l.itemSection.split('#')[1])).sort((a, b) => a - b);
    let nextSectionNumber = 1;
    for (let num of usedSectionNumbers) {
      if (num === nextSectionNumber) {
        nextSectionNumber++;
      } else {
        break;
      }
    }
    return nextSectionNumber;
  }

  reorderLetterOfExplanation(record?: string) {
    if (![QUICK_CHECKS, QUICK_ANALYSIS_FLAGS, ESCALATED_KEYWORDS_FLAGS].includes(record)) {
      const relatedItems = this.letterOfExplanation.filter(l => l.itemSection.includes(this.itemsMap[record].name));
      relatedItems.sort((a, b) => {
        const aSectionNumber = parseInt(a.itemSection.split('#')[1]);
        const bSectionNumber = parseInt(b.itemSection.split('#')[1]);
        return aSectionNumber - bSectionNumber;
      });

      for (let i = 0; i < relatedItems.length; i++) {
        const currentItem = relatedItems[i];
        const currentSectionNumber = parseInt(currentItem.itemSection.split('#')[1]);
        if (currentSectionNumber !== (i + 1)) {
          currentItem.itemSection = this.itemsMap[record].name + ((i + 1) > 9 ? " #" + (i + 1) : " #0" + (i + 1));
        }
      }
    } else {
      const relatedItems = this.letterOfExplanation.filter(l => l.itemSection.includes(this.itemsMap[record].name));
      relatedItems.sort((a, b) => {
        const aSectionNumber = parseInt(a.itemSection.split('#')[1]);
        const bSectionNumber = parseInt(b.itemSection.split('#')[1]);
        return aSectionNumber - bSectionNumber;
      });

      for (let i = 0; i < relatedItems.length; i++) {
        const currentItem = relatedItems[i];
        const currentSectionNumber = parseInt(currentItem.itemSection.split('#')[1]);
        if (currentSectionNumber !== (i + 1)) {
          currentItem.itemSection = this.itemsMap[record].name + " #" + ((i + 1) > 9 ? " #" + (i + 1) : " #0" + (i + 1));
        }
      }
    }

    this.letterOfExplanation.sort((a, b) => {
      if (a.itemSection < b.itemSection) return -1;
      if (a.itemSection > b.itemSection) return 1;
      return 0;
    });
  }

  generateJSON(reviewType?: string) {
    let json = {};
    if (reviewType === BORROWER_REVIEW_TYPES.BORROWER_CREDIT_REVIEW) {
      json = {
        ...this.borrowerJson,
        borrowerId: this.borrowerId,
        borrowerName: this.borrowerName,
        birthdate: formatDate(this.birthdayDate, 'MM/dd/yyyy'),
        creditDate: formatDate(this.creditDate, 'MM/dd/yyyy'),
        ssn: this.ssn,
        equifax: this.equifax,
        transunion: this.transunion,
        experian: this.experian,
        tradelinesData: [
          ...this.tradelineDataRows,
          this.tradelineTotalRow
        ],
        mortgageHistory: this.backgroundSectionDetails.backgroundSection.mortgageHistory,
        otherLateTradelines: this.backgroundSectionDetails.backgroundSection.otherLateTradelines,
        mortgageLateTradelines: this.backgroundSectionDetails.backgroundSection.mortgageLateTradelines,
        collectionTradelines: this.backgroundSectionDetails.backgroundSection.collectionTradelines,
        chargeOffTradelines: this.backgroundSectionDetails.backgroundSection.chargeOffTradelines,
        // updateHistoryAuditLogs: [],
        tradelineSummary: this.backgroundSectionDetails.backgroundSection.tradelineSummary,
        // isAddressFound: false
        quickChecks: this.quickChecks,
        letterOfExplanationList: this.letterOfExplanation,
        creditComment: this.creditComment,
        publicRecords: this.backgroundSectionDetails.backgroundSection.publicRecords,
        assets: this.assets,
        liabilities: this.liabilities,
        netWorth: this.netWorth,
        annualIncome: this.annualIncome,
      }
    } else {
      json = {
        ...this.borrowerJson,
        borrowerId: this.borrowerId,
        borrowerName: this.borrowerName,
        backgroundDate: formatDate(this.backgroundDate, 'MM/dd/yyyy'),
        birthdayDate: formatDate(this.birthdayDate, 'MM/dd/yyyy'),
        ssn: this.ssn,
        experience: this.experience,
        rentalExperience: this.rentalExperience,
        foreclosure: this.foreclosure,
        criminalRecord: this.criminalRecord,
        financialCrimes: this.financialCrimes,
        bankruptcy: this.bankruptcy,
        foreclosureDate: formatDate(this.foreclosureDate, 'MM/dd/yyyy'),
        bankruptcyDate: formatDate(this.bankruptcyDate, 'MM/dd/yyyy'),
        rentalMfExperience: this.rentalMfExperience,
        totalPropertiesOwned: this.totalPropertiesOwned,
        comment: this.backgroundComments,
        isAddressFound: this.isAddressFound,
        isSellersNameFound: this.isSellersNameFound,
        quickChecks: this.quickChecks,
        quickAnalysisFlags: this.quickAnalysisFlags,
        escalatedKeywordFlags: this.escalatedKeywordFlags,
        backgroundSectionDetails: {
          backgroundSection: {
            [this.itemsMap[ARREST_RECORD].name]: this.backgroundSectionDetails.backgroundSection.arrestRecord,
            [this.itemsMap[BANKRUPTCY_RECORD].name]: this.backgroundSectionDetails.backgroundSection.bankruptcyRecord,
            [this.itemsMap[CRIMINAL_RECORD].name]: this.backgroundSectionDetails.backgroundSection.criminalRecord,
            [this.itemsMap[DOCKET_RECORD].name]: this.backgroundSectionDetails.backgroundSection.docketRecord,
            [this.itemsMap[FORECLOSURE_RECORD].name]: this.backgroundSectionDetails.backgroundSection.foreclosureRecord,
            [this.itemsMap[GLOBAL_SANCTION_RECORD].name]: this.backgroundSectionDetails.backgroundSection.globalSanction,
            [this.itemsMap[LAWSUIT_RECORD].name]: this.backgroundSectionDetails.backgroundSection.lawsuitRecord,
            [this.itemsMap[LIEN_JUDGEMENT_RECORD].name]: this.backgroundSectionDetails.backgroundSection.lienJudgmentRecord,
            [this.itemsMap[OTHER_SSN_RECORD].name]: this.backgroundSectionDetails.backgroundSection.otherSSNAssociated,
            [this.itemsMap[SSN_ADDRESS_FRAUD_RECORD].name]: this.backgroundSectionDetails.backgroundSection.ssnAddressFraudRecord,
            [this.itemsMap[EXCLUDED_PARTY_RECORD].name]: this.backgroundSectionDetails.backgroundSection.excludedPartyRecord,
            [this.itemsMap[OTHER_NAMES_FOR_SSN_RECORD].name]: this.backgroundSectionDetails.backgroundSection.otherNamesRecord,
          },
          backgroundSectionCounter: this.backgroundSectionDetails.backgroundSectionCounter
        },
        letterOfExplanation: this.letterOfExplanation
      }
    }

    return json;
  }

  // RECORDS Items.
  updateRecordItem(index: number, record: string) {
    if (this.isInternal) {
      if (this.backgroundSectionDetails.backgroundSection[record][index]) {
        const itemName = this.backgroundSectionDetails.backgroundSection[record][index].item;
        const flag = this.backgroundSectionDetails.backgroundSection[record][index].loefFlag;
        if (flag === true || isNil(flag)) {
          this.backgroundSectionDetails.backgroundSection[record][index].loefFlag = flag === false ? null : flag === true ? null : true;
          this.updateLetterOfExplanationStatus(index, record);
          const actionPerfomed = this.backgroundSectionDetails.backgroundSection[record][index].loefFlag === true ? EVENT_ACTION.ITEM_FLAGGED : EVENT_ACTION.ITEAM_UNFLAGGED;
          this.setAutomatedEvent(actionPerfomed, itemName, record);
        }
      }
    }
  };

  addRecordItem(
    item: string,
    itemDescription: string,
    resolution: string,
    isResolutionRequired: boolean,
    loefFlag: boolean, record: string,
    count?: string) {
    if (!this.backgroundSectionDetails.backgroundSection[record]) {
      this.backgroundSectionDetails.backgroundSection[record] = [];
    }
    this.backgroundSectionDetails.backgroundSection[record].push({
      item: item,
      itemDescription: itemDescription,
      resolution: resolution,
      isResolutionRequired: isResolutionRequired,
      loefFlag: loefFlag,
      count
    });
    this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, item, record);
    const index = this.backgroundSectionDetails.backgroundSection[record].length - 1;
    this.updateLetterOfExplanationStatus(index, record);
  };

  removeRecordItem(index: number, record?: string) {
    if (this.backgroundSectionDetails.backgroundSection[record][index]) {
      const itemName = this.backgroundSectionDetails.backgroundSection[record][index].item
      const lineItem = this.backgroundSectionDetails.backgroundSection[record][index];
      this.updateLOEListAfterRemoval(lineItem, record);
      this.backgroundSectionDetails.backgroundSection[record].splice(index, 1);
      this.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, itemName, record);
    }
  };

  updateLOEListAfterRemoval(lineItem: any, record: string) {
    if (lineItem.letterOfExplanation) {
      lineItem.letterOfExplanation.status = false;
      this.updateLetterOfExplanationForRecordItems(lineItem);
    } else {
      const loeIndex = this.letterOfExplanation.findIndex(l => l.itemName === lineItem.item);
      if (loeIndex !== -1) {
        this.letterOfExplanation.splice(loeIndex, 1);
        this.reorderLetterOfExplanation(record);
      }
    }
  };

  editRecordItem(item: string,
    itemDescription: string,
    resolution: string,
    isResolutionRequired: boolean,
    loefFlag: boolean,
    record: string,
    index: number,
    count?: string) {
    this.backgroundSectionDetails.backgroundSection[record][index] = {
      item: item,
      itemDescription: itemDescription,
      resolution: resolution,
      isResolutionRequired: isResolutionRequired,
      loefFlag: loefFlag,
      count
    }
    this.updateLetterOfExplanationStatus(index, record);
    this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, item, record);
  };

  addTDRecordItem(
    item: string,
    outstandingCount: number,
    balanceAmount: number,
    paidCount: number,
    highCreditAmount: number,
    pastDue: number,
    record: string,) {
    this.backgroundSectionDetails.backgroundSection[record].push({
      item,
      outstandingCount,
      balanceAmount,
      paidCount,
      highCreditAmount,
      pastDue
    });
    this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, item, record);
    const index = this.backgroundSectionDetails.backgroundSection[record].length - 1;
    this.updateLetterOfExplanationStatus(index, record);
  };

  removeTDRecordItem(index: number, record?: string) {
    if (this.backgroundSectionDetails.backgroundSection[record][index]) {
      const itemName = this.backgroundSectionDetails.backgroundSection[record][index].item
      this.updateLOEListAfterRemoval(this.backgroundSectionDetails.backgroundSection[record][index], record);
      this.backgroundSectionDetails.backgroundSection[record].splice(index, 1);
      this.setAutomatedEvent(EVENT_ACTION.ITEM_REMOVED, itemName, record);
    }
  };

  editTDRecordItem(item: string,
    outstandingCount: number,
    balanceAmount: number,
    paidCount: number,
    highCreditAmount: number,
    pastDue: number,
    record: string,
    index: number,) {

    this.backgroundSectionDetails.backgroundSection[record][index] = {
      item,
      outstandingCount,
      balanceAmount,
      paidCount,
      highCreditAmount,
      pastDue
    }
    this.updateLetterOfExplanationStatus(index, record);
    this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, item, record);
  };

  addLHRecordItem(
    item: string,
    count: number,
    mostRecentDate: Date,
    mostAgedDate: Date,
    accountType: string,
    loefFlag: boolean,
    record: string) {
    this.backgroundSectionDetails.backgroundSection[record].push({
      item,
      count,
      mostRecentDate,
      mostAgedDate,
      accountType,
      loefFlag: loefFlag,
    });
    this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, item, record);
    const index = this.backgroundSectionDetails.backgroundSection[record].length - 1;
    this.updateLetterOfExplanationStatus(index, record);
  };

  removeLHRecordItem(index: number, record?: string) {
    if (this.backgroundSectionDetails.backgroundSection[record][index]) {
      this.updateLOEListAfterRemoval(this.backgroundSectionDetails.backgroundSection[record][index], record);
      this.backgroundSectionDetails.backgroundSection[record].splice(index, 1);
    }
  };

  editLHRecordItem(item: string,
    count: number,
    mostRecentDate: Date,
    mostAgedDate: Date,
    accountType: string,
    loefFlag: boolean,
    record: string,
    index: number,) {
    this.backgroundSectionDetails.backgroundSection[record][index] = {
      item,
      count,
      mostRecentDate,
      mostAgedDate,
      accountType,
      loefFlag: loefFlag,
    }
    this.updateLetterOfExplanationStatus(index, record);
    this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, item, record);
  };

  addCARecordItem(
    item: string,
    creditComment: string,
    balanceAmount: number,
    highCreditAmount: number,
    pastDue: number,
    accountType: string,
    record: string) {
    this.backgroundSectionDetails.backgroundSection[record].push({
      item,
      creditComment,
      balanceAmount,
      highCreditAmount,
      pastDue,
      accountType,
    });
    this.setAutomatedEvent(EVENT_ACTION.ITEM_ADDED, item, record);
    const index = this.backgroundSectionDetails.backgroundSection[record].length - 1;
    this.updateLetterOfExplanationStatus(index, record);
  };

  removeCARecordItem(index: number, record?: string) {
    if (this.backgroundSectionDetails.backgroundSection[record][index]) {
      this.updateLOEListAfterRemoval(this.backgroundSectionDetails.backgroundSection[record][index], record);
      this.backgroundSectionDetails.backgroundSection[record].splice(index, 1);
    }
  };

  editCARecordItem(item: string,
    creditComment: string,
    balanceAmount: number,
    highCreditAmount: number,
    pastDue: number,
    accountType: string,
    record: string,
    index: number,) {

    this.backgroundSectionDetails.backgroundSection[record][index] = {
      item,
      creditComment,
      balanceAmount,
      highCreditAmount,
      pastDue,
      accountType,
    }
    this.updateLetterOfExplanationStatus(index, record);
    this.setAutomatedEvent(EVENT_ACTION.ITEM_UPDATED, item, record);
  };

  initItemsMap() {
    this.itemsMap = {
      arrestRecord: {
        name: 'Arrest Record',
        counter: 0
      },
      bankruptcyRecord: {
        name: 'Bankruptcy',
        counter: 0
      },
      criminalRecord: {
        name: 'Criminal Record',
        counter: 0
      },
      docketRecord: {
        name: 'Docket Record',
        counter: 0
      },
      foreclosureRecord: {
        name: 'Foreclosure Record',
        counter: 0
      },
      lawsuitRecord: {
        name: 'Lawsuit Record',
        counter: 0
      },
      lienJudgmentRecord: {
        name: 'Lien Judgment Record',
        counter: 0
      },
      otherSSNAssociated: {
        name: 'Other SSNs Associated Record',
        counter: 0
      },
      ssnAddressFraudRecord: {
        name: 'SNN Address Fraud Record',
        counter: 0
      },
      excludedPartyRecord: {
        name: 'Excluded Party Record',
        counter: 0
      },
      otherNamesRecord: {
        name: 'Other Names For SSN Section',
        counter: 0
      },
      globalSanction: {
        name: 'Global Sanctions',
        counter: 0
      },
      quickAnalysisFlags: {
        name: 'Quick Analysis Flags',
        counter: 0
      },
      quickChecks: {
        name: 'Quick Checks',
        counter: 0
      },
      escalatedKeywordFlags: {
        name: 'Escalated Keyword',
        counter: 0,
        hideDescription: true,
      },
      tradelineSummary: {
        name: 'Tradeline Summary',
        counter: 0,
        hideDescription: true,
        showCheck: true,
        section: 'Tradeline Summary'
      },
      tradelinesData: {
        name: 'Tradeline Data',
        counter: 0,
        section: 'Tradeline Data'
      },
      mortgageLateTradelines: {
        name: 'Mortgage Account',
        counter: 0,
        section: 'Mortgage Account'
      },
      otherLateTradelines: {
        name: 'Other Accounts',
        counter: 0,
        section: 'Other Accounts'
      },
      mortgageHistory: {
        name: 'Mortgage History',
        counter: 0,
        hideDescription: true,
        section: 'Mortgage History'
      },
      collectionTradelines: {
        name: 'Collection Account',
        counter: 0,
        section: 'Collection Account'
      },
      chargeOffTradelines: {
        name: 'Charge Off Account',
        counter: 0,
        section: 'Charge Off Account'
      },
      publicRecords: {
        name: 'Public Record',
        counter: 0,
        section: 'Public Record'
      }
    }
  }

  resetDialogState() {
    this.currentBorrower = undefined;
  }

  initializeService() {
    this.borrowerService = this.isInternal ? new BorrowerInternalService() : new BorrowerService();
  }

  *saveData(reviewType?: string, callback?: () => void) {
    try {
      yield this.borrowerService.saveBorrowerDataCollection(this.currentBorrower, this.loanId, this.generateJSON(reviewType), reviewType);
      yield this.borrowerService.saveAutomatedEvents(this.automatedEvents)
      if (this.isInternal) {
        window.parent.postMessage('saved-success', '*');
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Data saved successfully!',
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  *generateResolutions(reviewType?: string) {
    try {
      yield this.borrowerService.saveBorrowerDataCollectionAndCreateResolutions(this.currentBorrower, this.loanId, this.generateJSON(reviewType), reviewType);
      yield this.borrowerService.saveAutomatedEvents(this.automatedEvents)
      if (this.isInternal) {
        window.parent.postMessage('saved-success', '*');
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Data saved successfully!',
      });
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  get showLegacyFormLink() {
    return this.globalStore.queryParams.showLegacyFormLink === 'true';
  }

  get showLegacyFormLinkFromBorrowerForInternal() {
    return (this.isInternal && this.getCurrentBorrowerFromLoan()?.portalProjectsCompleted > 0);
  }

  getCurrentBorrowerFromLoan() {
    return this.loan?.borrowers?.find(b => b.borrowerId === this.currentBorrower);
  }

  setCreditComment(comment: string) {
    this.creditComment = comment;
  }

  setEquifax(value: number) {
    this.equifax = value;
  }

  setTransunion(value: number) {
    this.transunion = value;
  }

  setExperian(value: number) {
    this.experian = value;
  }

  setBirthdayDate(value) {
    this.birthdayDate = value;
  }

  setCreditDate(value) {
    this.creditDate = value;
  }

  setBackgroundDate(value) {
    this.backgroundDate = value;
  }

  setSsn(value) {
    this.ssn = value;
  }

  setExperience(value: number) {
    this.experience = value;
  }


  setRentalExperience(value: number) {
    this.rentalExperience = value;
  }

  setRentalMfExperience(value: number) {
    this.rentalMfExperience = value;
  }

  setRentalExperienceConstruction(value: number) {
    this.totalPropertiesOwned = value;
  }

  setBackgroundComments(comment: string) {
    this.backgroundComments = comment;
  }

  setAssets(value: number) {
    this.assets = value;
    this.setNetWorth(this.assets - this.liabilities);
  }

  setLiabilities(value: number) {
    this.liabilities = value;
    this.setNetWorth(this.assets - this.liabilities);
  }

  setNetWorth(value: number) {
    this.netWorth = value;
  }

  setAnnualIncome(value: number) {
    this.annualIncome = value;
  }

  setCriminalRecord(value: string) {
    this.criminalRecord = value === 'true';
  }

  setFinancialCrimes(value: string) {
    this.financialCrimes = value === 'true';
  }

  setForeclosureDate(value: string) {
    this.foreclosureDate = value;
  }

  setBankruptcyDate(value: string) {
    this.bankruptcyDate = value;
  }


  get tradelineDataRows() {
    return (this.backgroundSectionDetails.backgroundSection?.tradelinesData || []).filter(r => r.item !== TRADELINE_TOTAL_ROW);
  }

  get tradelineTotalRow() {
    const rows = this.tradelineDataRows;
    return {
      item: TRADELINE_TOTAL_ROW,
      creditComment: null,
      outstandingCount: sum(rows.map(x => x.outstandingCount)),
      paidCount: sum(rows.map(x => x.paidCount)),
      balanceAmount: sum(rows.map(x => x.balanceAmount)),
      highCreditAmount: sum(rows.map(x => x.highCreditAmount)),
      payments: sum(rows.map(x => x.payments)),
      pastDue: sum(rows.map(x => x.pastDue)),
      count: sum(rows.map(x => x.count)),
    }
  }

  *submitReview(taskName: any, callback?: () => void) {
    try {
      yield this.borrowerService.makeReviewCompleted(this.loanId, this.currentBorrower, taskName);
      yield this.borrowerService.saveAutomatedEvents(this.automatedEvents)
      if (this.isInternal) {
        window.parent.postMessage('saved-success', '*');
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Data saved successfully!',
      });
      if (callback) {
        callback();
      }
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error saving data',
      });
    }
  }

  updateLetterOfExplanationStatus = async (index: number, record: string) => {
    const lineItem = this.backgroundSectionDetails.backgroundSection[record][index];
    if (isNil(lineItem.letterOfExplanation)) {
      const data = await this.generateLOE(this.currentBorrower, this.itemsMap[record].name, lineItem);
      if (data === null) return;
      lineItem.letterOfExplanation = data;
    }
    lineItem.letterOfExplanation.status = lineItem.loefFlag;
    this.updateLetterOfExplanationForRecordItems(this.backgroundSectionDetails.backgroundSection[record][index]);
  };

  updateLOEQuickCheck = async (index: number) => {
    const lineItem = this.quickChecks[index];
    if (isNil(lineItem.letterOfExplanation)) {
      const data = await this.generateLOE(this.currentBorrower, 'Quick Checks', lineItem);
      if (data === null) return;
      lineItem.letterOfExplanation = data;
    }
    lineItem.letterOfExplanation.status = lineItem.flagCheck;
    this.updateLetterOfExplanationForRecordItems(lineItem);
  };

  updateLOEKeyword = async (index: number) => {
    const lineItem = this.escalatedKeywordFlags[index];
    if (isNil(lineItem.letterOfExplanation)) {
      const data = await this.generateLOE(this.currentBorrower, 'Escalated Keyword', lineItem);
      if (data === null) return;
      lineItem.letterOfExplanation = data;
    }
    lineItem.letterOfExplanation.status = lineItem.flagCheck;
    this.updateLetterOfExplanationForRecordItems(lineItem);
  };

  *generateLOE(borrowerId: number, section: string, lineItem: any) {
    try {
      const response = yield this.borrowerService.getBorrowerLOE(borrowerId, section, lineItem);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  setAutomatedEvent(actionPerfomed: string, item: string, sectionName: string) {
    this.automatedEvents.push({
      borrowerId: this.currentBorrower,
      reviewType: this.reviewType,
      actionPerformed: actionPerfomed,
      itemName: item,
      sectionName: sectionName
    })
  }
}