import { computed, makeObservable, observable } from 'mobx';
import { GlobalStore } from './globalStore';
import FormStore from './formStore';


export class DirtyableFormStore extends FormStore {
  initialValues: any;

  constructor(form: any, globalStore: GlobalStore) {
    super(form, globalStore);
    this.initialValues = null;
    makeObservable(this, {
      initialValues: observable,
      isDirty: computed,
    });
  }

  loadForm = (obj: any) => {
    super.loadForm(obj);
    setTimeout(() => {
      // Wait for input fields to set the values
      // numberFormatFields convert number to string because of thousand separator after first render
      this.initialValues = this.getFlattenedValues();
    }, 500);
  };

  reset() {
    super.reset();
    this.initialValues = null;
  }

  get isDirty() {
    if (!this.form || !this.initialValues) return false;
    const flattenedValues = this.getFlattenedValues();
    return JSON.stringify(flattenedValues) !== JSON.stringify(this.initialValues);
  }
}

export default DirtyableFormStore;