import { Grid, Menu, ListItemText, ListItemIcon, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, CloudDownload, Send } from '@material-ui/icons';
import { Button, Toolbar, ConfirmationMenuItem } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useBaseStore } from '@roc/feature-app-core';
import { TamariskAppraisalBulkAction } from '../types/types';
import { TamariskAppraisalStore } from '../store/tamariskAppraisalStore';

const useStyles = makeStyles(theme => ({
  content: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  label: {
    fontSize: '13px',
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataPoint: {
    marginTop: '5px',
    textAlign: 'center',
  },
  containerBox: {
    display: 'flex',
  },
}));

interface TamariskAppraisalDashboardToolbarProps {
  tamariskAppraisalStore: TamariskAppraisalStore;
}

export const TamariskAppraisalDashboardToolbar = observer(({ tamariskAppraisalStore } : TamariskAppraisalDashboardToolbarProps) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
  const { globalStore } = useBaseStore();

  const [open, setOpen] = useState(true);

  const closeMenu = () => {
    setShowBulkActionsMenu(false);
  };

  const performActions = async (action: TamariskAppraisalBulkAction) => {
    if (tamariskAppraisalStore.selectedRecords.length > 0) {
      await tamariskAppraisalStore.performBulkAction(action);
      tamariskAppraisalStore.getCurrentGridStore().resetAndFetchGridData();
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'Please select an item from the table.',
      });
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar>
            <Box className={classes.containerBox} >
              <Box style={{ textAlign: 'center' }}>
                <div ref={anchorRef} style={{ display: 'inline-block' }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={() => { setShowBulkActionsMenu(true) }}
                    testId="open_menu"
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={ tamariskAppraisalStore.selectedRecords.length < 2 }
                  >
                    Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorRef.current}
                    open={showBulkActionsMenu}
                    onClose={closeMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                  >
                    <ConfirmationMenuItem
                      testId={`sendRevisionBulk`}
                      onClick={async () => {
                        performActions(TamariskAppraisalBulkAction.SEND);
                      }}
                      confirmDialogProps={{
                        body: `Are you sure you want to send the selected appraisals for revision?`,
                      }}
                    >
                      <ListItemIcon>
                        <Send fontSize="small" />
                      </ListItemIcon>
                      <ListItemText onClick={closeMenu}>Send for revision</ListItemText>
                    </ConfirmationMenuItem>
                    <ConfirmationMenuItem
                      testId={`downloadBulk`}
                      onClick={async () => {
                        performActions(TamariskAppraisalBulkAction.DOWNLOAD);
                      }}
                      confirmDialogProps={{
                        body: `Are you sure you want to download the selected appraisals' revision?`,
                      }}
                    >
                      <ListItemIcon>
                        <CloudDownload fontSize="small" />
                      </ListItemIcon>
                      <ListItemText onClick={closeMenu}>Download revision</ListItemText>
                    </ConfirmationMenuItem>
                  </Menu>
                </div>
              </Box>
            </Box>
        </Toolbar>
      </Grid>
    </Grid>
  );
});
