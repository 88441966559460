import { Button, Box, Typography, IconButton } from "@material-ui/core";
import { lighten, useTheme } from '@material-ui/core/styles';
import { Unarchive, HelpOutline } from "@material-ui/icons";
import { InputToolbox } from "@chatscope/chat-ui-kit-react";
import { observer } from "mobx-react-lite";
import { ChatRoomType } from "../types/communicationTypes";
import { ConfirmationButton } from "@roc/ui";
import { CommunicationStore } from "../stores";

export const FullReadOnlyChatInfoBox = observer(({ communicationStore }:
  {
    as: any,
    communicationStore: CommunicationStore,
  }) => {

  const theme = useTheme();

  const getChatRoomType = () => {
    return communicationStore.conversationChatRoomType[communicationStore.currentConversationSid];
  };

  let message;

  switch (getChatRoomType()) {
    case ChatRoomType.LOAN_SYSTEM_NOTIFICATIONS:
      message = (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
          This is a read-only chat and will display system-generated messages about the loan.
        </Typography>
      );
      break;
    default:
      message = null;
  }

  return (
    <InputToolbox style={{
      justifyContent: "center",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}>
      <Box p={1} borderTop={'1px solid #eee'} width="100%" bgcolor={lighten(theme.palette.primary.main, 0.9)}
        display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {message}
      </Box>
    </InputToolbox>
  )
});
