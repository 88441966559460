import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TodoTaskCard } from '../components/cards/todoTaskCard';
import { useEffect } from 'react';
import { useLoanStore } from '@roc/feature-loans';
import { LoanProcess } from '@roc/feature-utils';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '@media (max-width: 992px)': {
      marginLeft: '-6px',
    },
  },
}));
export const UnderwritingTasksTab = observer(() => {
  const { documentStore } = useDocumentStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanProcess = LoanProcess.UNDERWRITING;
  const classes = useStyles();

  const todos =
    documentStore.loanTodosStore.todosByLoanProcess[loanProcess] ?? [];

  useEffect(() => {
    documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
  }, [loanId]);

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, loanProcess);
    }
  }, [loanId, currentForm]);

  return (
    <>
      <Grid item xs={12} className={classes.container}>
        <TodoTaskCard
          loanProcess={loanProcess}
          showFilter
          showSectionActions={true}
          showFileName={true}
          showDownloadAll
          todos={todos}
          showActions={true}
        />
      </Grid>
      <DocumentForms loanId={loanDetails.loanId} />
    </>
  );
});
