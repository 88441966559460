import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import { Button, Card, FileUploadModal, Paper, PaperBorderType, PDFPreview } from "@roc/ui";
import { observer } from "mobx-react";
import { InsuranceQuotesTableRow } from "./insuranceQuotesTableRow";
import { useState } from "react";
import { InsuranceInformationBaseStore } from "../stores";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";


const useStyles = makeStyles((theme: Theme) => ({
  noBorderCell: {
    border: 0,
  },
  table: {
    '& th': {
      borderBottom: '1px solid #eee'
    }
  },
  headerRow: {
    '& th': {
      verticalAlign: 'text-top',
      padding: '8px'
    }
  }
}));

export type InsuranceQuotesTableProps = {
  title: string;
  quotes: any;
  emptyMessage: string;
  store: InsuranceInformationBaseStore;
  showCollapseExpandAll?: boolean;
  allowActions?: boolean;
};

export const InsuranceQuotesTable = observer((
  { title, quotes, emptyMessage, store, showCollapseExpandAll = true, allowActions = false }: InsuranceQuotesTableProps) => {
  const [expandAll, setExpandAll] = useState(false);

  const classes = useStyles({});

  return (
    <Paper borderType={PaperBorderType.SOLID_BORDER}>
      <Card
        title={title}
        cardHeaderProps={{
          action: (
            <>
              {!!showCollapseExpandAll && (<Box pt={1} display="flex">
                <Box pr={1}>
                  <Tooltip title="Expand all quotes" placement='bottom'>
                    <div>
                      <Button testId="expandAll" onClick={() => {
                        setExpandAll(true);
                      }} variant="outlined" style={{
                        letterSpacing: 'normal',
                        textTransform: 'none',
                      }}>
                        <Box height={'31px'} display="flex" alignItems="center">
                          <UnfoldMore />&nbsp;&nbsp;Expand All
                        </Box>
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
                <Box pr={1}>
                  <Tooltip title="Collapse all quotes" placement='bottom'>
                    <div>
                      <Button testId="collapseAll" onClick={() => {
                        setExpandAll(false);
                      }} variant="outlined" style={{
                        letterSpacing: 'normal',
                        textTransform: 'none',
                      }}>
                        <Box height={'31px'} display="flex" alignItems="center">
                          <UnfoldLess />&nbsp;&nbsp;Collapse All
                        </Box>
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
              </Box>
              )}
            </>
          ),
        }}
        cardContentProps={{
          style: {
            overflow: 'auto',
            padding: '0',
            borderTop: '1px solid #E0E0E0FF'
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell></TableCell>
              <TableCell>Quote</TableCell>
              <TableCell>Coverage</TableCell>
              <TableCell>Insurance Premium</TableCell>
              <TableCell>Paid Already</TableCell>
              <TableCell>Remaining</TableCell>
              <TableCell>Effective Date</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Status</TableCell>
              {allowActions && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.length == 0 ?
              <TableRow>
                <TableCell colSpan={10} className={classes.noBorderCell}>
                  <Box>
                    {emptyMessage}
                  </Box>
                </TableCell>
              </TableRow>
              :
              quotes?.map((quote, index) => (
                <InsuranceQuotesTableRow store={store} quote={quote} index={index} expandAll={expandAll} allowActions={allowActions} />
              ))
            }
          </TableBody>
        </Table>
      </Card>
      <FileUploadModal
        open={store.uploadDocuments}
        title={`Upload Document(s)`}
        onClose={() => store.uploadDocuments = false}
        onUpload={(documents) => store.uploadInsuranceQuoteDocuments(documents)}
        multiple
      />
    </Paper>
  )
});
