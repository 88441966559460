import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { isNil, isNotBlank, parseAddress } from '@roc/feature-utils';
import {
  CALLBACK_TYPE,
  DISABLE_STATUS_LIST,
} from '@roc/feature-sow-shared/constants/scopeOfWorkV2Form';
import {
  Address,
  CategoryDB,
  ScopeOfWorkAuditLog,
  ScopeOfWorkAuditLogMessesObj,
  ScopeOfWorkErrorWarning,
  ScopeOfWorkV2,
  ScopeOfWorkV2Data,
  ScopeOfWorkV2FormParams,
} from '@roc/feature-sow-shared/types';
import CategoryStore from './categoryStore';
import ConfirmPopupStore from './confirmPopupStore';
import SubcategoryItemizedDetailsStore from './subcategoryItemizedDetailsStore';
import { downloadDocument } from '@roc/feature-utils';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import ScopeOfWorkV2Store from '../scopeOfWorkV2Store';
import { ACCEPTED, NO_ACTION } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';

const alreadyOwnDefaultValue = true;

const scopeOfWorkFormFields = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: 'The address is required.',
    },
    streetNumber: {
      value: null,
      error: null,
      rule: '',
      message: 'The street number is required.',
    },
    streetName: {
      value: null,
      error: null,
      rule: 'required',
      message: 'The street name is required.',
    },
    alreadyOwn: {
      value: alreadyOwnDefaultValue,
      error: null,
      rule: '',
    },
    anyImprovements: {
      value: null,
      error: null,
      rule: '',
    },
    city: {
      value: null,
      error: null,
      rule: '',
    },
    state: {
      value: null,
      error: null,
      rule: '',
    },
    zipCode: {
      value: null,
      error: null,
      rule: '',
    },
    originatorId: {
      value: null,
      error: null,
      rule: '',
    },
    projectDescription: {
      value: '',
      error: null,
      rule: '',
    },
    describeRenovation: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Special project type value is required.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ScopeOfWorkV2FormBaseStore extends FormStore {
  protected globalStore: GlobalStore;
  private scopeOfWorkStoreV2: ScopeOfWorkV2Store;
  loanParticipantsStore: LoanParticipantsStore;
  subcategoryItemizedDetailsStore: SubcategoryItemizedDetailsStore;
  categoryStore: CategoryStore;
  confirmPopupStore: ConfirmPopupStore;
  scopeOfWork: ScopeOfWorkV2;
  scopeOfWorkData: ScopeOfWorkV2Data;
  userStore: UserStore;
  isDisabled: boolean;
  isPublicLink: boolean;
  ltcCatchupAmount?: number;
  readOnlyCapex: boolean = false;
  allowReject: boolean;
  allowApprove: boolean;
  isDownloading: boolean;
  params: ScopeOfWorkV2FormParams;
  scopeOfWorkShareUrl: string;
  publicScopeOfWorkShareUrl: string;
  submittedScopeOfWorkId: number;
  public activeStep: number;
  public showSaveButton: boolean;
  renderScopeOfWorkV2Link: boolean;
  renderScopeOfWorkV2LinkFromUW: boolean;
  username: string;
  splitedCategoriesObj: CategoryDB[];
  scopeOfWorkScreenshotIds: string[];
  scopeOfWorkAuditLogs: ScopeOfWorkAuditLog[];
  scopeOfWorkAuditLogsMessages: ScopeOfWorkAuditLogMessesObj[];
  isInternalPortal = false;
  showLoadingMask = false;
  isSubmitForReviewOpen: boolean;
  isSubmitForRevisionOpen: boolean;
  isReviewCompleteOpen: boolean;
  submissionErrors: ScopeOfWorkErrorWarning[];
  submissionWarnings: ScopeOfWorkErrorWarning[];

  protected getScopeOfWorkShareUrl: (
    params: ScopeOfWorkV2FormParams
  ) => Promise<string>;
  protected getPublicScopeOfWorkShareUrl: (
    params: ScopeOfWorkV2FormParams
  ) => Promise<string>;
  protected getNewScopeOfWork: () => Promise<ScopeOfWorkV2>;
  protected getScopeOfWork: (
    params: ScopeOfWorkV2FormParams
  ) => Promise<ScopeOfWorkV2>;
  protected getScopeOfWorkByLoanIdAndPropertyId: (
    params: ScopeOfWorkV2FormParams
  ) => Promise<ScopeOfWorkV2>;
  protected getScopeOfWorkCategories: () => Promise<CategoryDB[]>;
  protected saveScopeOfWork: (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent?: boolean
  ) => Promise<boolean>;
  protected saveScopeOfWorkDataContent: (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent?: boolean
  ) => Promise<boolean>;
  protected getSowSubmissionErrors: (
    loanId: any,
    propertyId: any,
    data: any
  ) => void;
  protected submitScopeOfWork: (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent?: boolean
  ) => Promise<any>;
  protected afterCall: (
    type: CALLBACK_TYPE,
    responseData: any,
    isInternal: boolean
  ) => void;
  protected copyScopeOfWorkShareUrl: (
    shareUrl: string,
    isInternal: boolean
  ) => void;
  protected copyPublicScopeOfWorkShareUrl: (
    shareUrl: string,
    isInternal: boolean
  ) => void;
  protected exportScopeOfWork: (data: any) => Promise<any>;
  protected removeScopeOfWork: (data: any) => Promise<any>;
  protected markSOWAsInReview: (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent?: boolean
  ) => Promise<boolean>;
  protected markSOWAsInReviewComplete: (
    params: ScopeOfWorkV2FormParams,
  ) => Promise<boolean>;
  markSOWAsInRevision: (
    params: ScopeOfWorkV2FormParams,
  ) => Promise<boolean>;
  protected markSOWAsMoreInfoNeeded: (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent?: boolean
  ) => Promise<boolean>;
  protected markSOWAsApproved: (
    id: string,
    silent?: boolean
  ) => Promise<boolean>;

  protected splitCategories: (
    scopeOfWorkId: string,
    lenderId: string
  ) => Promise<CategoryDB[]>;

  protected saveScopeOfWorkAuditLogs: (
    scopeOfWorkId: any,
    lenderId: any,
    data: any,
    isSubmit: boolean,
    userName: string
  ) => void;

  protected getScopeOfWorkAuditLogs: (
    scopeOfWorkId: any,
    lenderId: any
  ) => void;

  onResetSuccesfull: () => void;

  constructor(
    globalStore,
    formFields?: any,
    userStore?: UserStore,
  ) {
    super(formFields !== undefined ? { ...formFields } : { ...scopeOfWorkFormFields }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.subcategoryItemizedDetailsStore = new SubcategoryItemizedDetailsStore(
      globalStore,
      this
    );
    this.categoryStore = new CategoryStore(globalStore, this);
    this.confirmPopupStore = new ConfirmPopupStore();
    this.ltcCatchupAmount;
    makeObservable(this, {
      setParams: action,
      setIsDisabled: action,
      setIsPublicLink: action,
      setIsDownloading: action,
      setAddress: action,
      params: observable,
      isDisabled: observable,
      isPublicLink: observable,
      isDownloading: observable,
      submissionErrors: observable,
      fetchInitialData: flow,
      fetchScopeOfWorkShareUrl: flow,
      fetchPublicScopeOfWorkShareUrl: flow,
      loadParticipants: flow,
      scopeOfWorkShareUrl: observable,
      publicScopeOfWorkShareUrl: observable,
      showSaveButton: observable,
      handleCopyUrl: action,
      fetchScopeOfWork: flow,
      saveScopeOfWorkData: flow,
      saveAuditLogs: flow,
      getSowAuditLogs: flow,
      exportScopeOfWorkData: flow,
      resetScopeOfWork: flow,
      submitScopeOfWorkData: action,
      autoSaveScopeOfWork: flow,
      setScopeOfWorkId: action,
      scopeOfWorkData: observable,
      isStandaloneScopeOfWorkParams: computed,
      isExistingScopeOfWorkParams: computed,
      showShare: computed,
      showResetSow: computed,
      addressDisabled: computed,
      addressError: computed,
      initParams: action,
      setReviewStatus: action,
      initScopeOfWork: action,
      reset: override,
      activeStep: observable,
      moveToStep: flow,
      goNextStep: action,
      goPrevStep: action,
      getAddress: action,
      submittedScopeOfWorkId: observable,
      showSuccessDialog: computed,
      closeSuccessDialog: action,
      showSelectOriginator: computed,
      scopeOfWork: observable,
      handleMarkAsInReview: flow,
      handleMarkAsReviewComplete: flow,
      handleMarkAsInRevision: flow,
      handleMarkAsMoreInfoNeeded: flow,
      handleMarkAsApproved: flow,
      saveSOWDataContent: flow,
      getSubmissionErrors: flow,
      manageExeptions: flow,
      renderScopeOfWorkV2Link: observable,
      setRenderScopeOfWorkV2Link: flow,
      renderScopeOfWorkV2LinkFromUW: observable,
      setRenderScopeOfWorkV2LinkFromUW: flow,
      username: observable,
      handleSplitedCategories: flow,
      splitedCategoriesObj: observable,
      scopeOfWorkScreenshotIds: observable,
      setScopeOfWorkScreenshotIds: flow,
      showScreenshotGrid: flow,
      hideScreenshotGrid: flow,
      savePdf: flow,
      savePdfOnDropbox: flow,
      savePdfFile: flow,
      showLoadingMask: observable,
      setOpenSubmitReviewDialog: action,
      setOpenSubmitRevisionDialog: action,
      setOpenCompleteReviewDialog: action,
      isSubmitForReviewOpen: observable,
      isSubmitForRevisionOpen: observable,
      isReviewCompleteOpen: observable,
      showInternalActions: computed,
    });
    this.setDefaults();
  }

  *savePdfFile(response) {
    if (response?.success) {
      yield this.handleSplitedCategories();
      yield this.savePdf(response);
    }
    this.afterCall(CALLBACK_TYPE.SUBMIT, response, this.params.isInternal);
  }
  savePdfOnDropbox() { }

  *savePdf(response) {
    try {
      yield new Promise(
        (resolve) => {
          setTimeout(async () => {
            await this.savePdfOnDropbox()
            return resolve(true);
          }, 500);
        })
    } catch (error) {
      console.log("Error occurred on save pdf ", error);
    }
  }

  showScreenshotGrid() {
    const parentTag = document.getElementById('screenshotParent');
    parentTag.setAttribute('overflow', 'auto');
    parentTag.setAttribute('height', '100%');
  }

  hideScreenshotGrid() {
    const parentTag = document.getElementById('screenshotParent');
    parentTag.setAttribute('overflow', 'hidden');
    parentTag.setAttribute('height', '0');
  }

  *handleSplitedCategories() {
    const response = yield this.splitCategories(
      this.params.scopeOfWorkId,
      this.params.lenderId
    );
    this.splitedCategoriesObj = response;
  }

  setScopeOfWorkScreenshotIds(scopeOfWorkScreenshotIds) {
    this.scopeOfWorkScreenshotIds = scopeOfWorkScreenshotIds;
  }

  setUsername(value) {
    this.username = value;
  }

  setOnResetSuccefull(onSuccessReset) {
    this.onResetSuccesfull = onSuccessReset;
  }

  setRenderScopeOfWorkV2Link(value) {
    this.renderScopeOfWorkV2Link = value;
  }

  setRenderScopeOfWorkV2LinkFromUW(value) {
    this.renderScopeOfWorkV2LinkFromUW = value;
  }

  setDefaults() {
    this.scopeOfWork = null;
    this.scopeOfWorkData = null;
    this.isDisabled = true;
    this.isPublicLink = false;
    this.isDownloading = false;
    this.params = null;
    this.scopeOfWorkShareUrl = null;
    this.publicScopeOfWorkShareUrl = isNil(this.publicScopeOfWorkShareUrl) ? null : this.publicScopeOfWorkShareUrl;
    this.activeStep = 0;
    this.scopeOfWorkAuditLogs = [];
    this.submissionErrors = [];
  }

  setIsInternalPortal(isInternalPortal) {
    this.isInternalPortal = isInternalPortal;
  }

  setLtcCatchupAmount(amount) {
    this.ltcCatchupAmount = amount;
  }

  setGetScopeOfWorkShareUrl(getScopeOfWorkShareUrl) {
    this.getScopeOfWorkShareUrl = getScopeOfWorkShareUrl;
  }

  setGetPublicScopeOfWorkShareUrl(getPublicScopeOfWorkShareUrl) {
    this.getPublicScopeOfWorkShareUrl = getPublicScopeOfWorkShareUrl;
  }

  setGetNewScopeOfWork(getNewScopeOfWork) {
    this.getNewScopeOfWork = getNewScopeOfWork;
  }

  setGetScopeOfWork(getScopeOfWork) {
    this.getScopeOfWork = getScopeOfWork;
  }

  setGetScopeOfWorkByLoanIdAndPropertyId(getScopeOfWorkByLoanIdAndPropertyId) {
    this.getScopeOfWorkByLoanIdAndPropertyId = getScopeOfWorkByLoanIdAndPropertyId;
  }

  setGetScopeOfWorkCategories(getScopeOfWorkCategories) {
    this.getScopeOfWorkCategories = getScopeOfWorkCategories;
  }

  setSaveScopeOfWork(saveScopeOfWork) {
    this.saveScopeOfWork = saveScopeOfWork;
  }

  setSaveScopeOfWorkDataContent(saveScopeOfWorkDataContent) {
    this.saveScopeOfWorkDataContent = saveScopeOfWorkDataContent;
  }

  setExportScopeOfWork(exportScopeOfWork) {
    this.exportScopeOfWork = exportScopeOfWork;
  }

  setRemoveScopeOfWork(removeScopeOfWork) {
    this.removeScopeOfWork = removeScopeOfWork
  }

  setSubmitScopeOfWork(submitScopeOfWork) {
    this.submitScopeOfWork = submitScopeOfWork;
  }

  setAfterCall(afterCall) {
    this.afterCall = afterCall;
  }

  setCopyScopeOfWorkShareUrl(copyScopeOfWorkShareUrl) {
    this.copyScopeOfWorkShareUrl = copyScopeOfWorkShareUrl;
  }

  setCopyPublicScopeOfWorkShareUrl(copyPublicScopeOfWorkShareUrl) {
    this.copyPublicScopeOfWorkShareUrl = copyPublicScopeOfWorkShareUrl;
  }

  setMarkSOWAsInReview(markSOWAsInReview) {
    this.markSOWAsInReview = markSOWAsInReview;
  }

  setMarkSOWAsInReviewComplete(markSOWAsInReviewComplete) {
    this.markSOWAsInReviewComplete = markSOWAsInReviewComplete;
  }

  setMarkSOWAsMoreInfoNeeded(markSOWAsMoreInfoNeeded) {
    this.markSOWAsMoreInfoNeeded = markSOWAsMoreInfoNeeded;
  }

  setMarkSOWAsApproved(markSOWAsApproved) {
    this.markSOWAsApproved = markSOWAsApproved;
  }

  setParams(params: ScopeOfWorkV2FormParams) {
    this.params = params;
    this.fetchInitialData();
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setIsPublicLink(isPublicLink: boolean) {
    this.isPublicLink = isPublicLink;
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setAddress(address: string, addressDetails) {
    const parsedAddress = parseAddress(address, addressDetails);

    this.changeField('address', address);
    this.changeField('streetNumber', parsedAddress.street_number);
    this.changeField('streetName', parsedAddress.street);
    this.changeField('city', parsedAddress.city);
    this.changeField('state', parsedAddress.state);
    this.changeField('zipCode', parsedAddress.zip);
  }

  getAddress() {
    return this.form.fields.address.value;
  }

  changeField(field: string, value: any) {
    this.form.fields[field].value = value;
    this.form.fields[field].error = null;
  }

  *fetchInitialData() {
    yield this.fetchScopeOfWork();
    if (!this.showShare) return;

    this.fetchScopeOfWorkShareUrl();
    if (!this.isInternalPortal) this.fetchPublicScopeOfWorkShareUrl();
  }

  *fetchScopeOfWorkShareUrl() {
    try {
      const url = yield this.getScopeOfWorkShareUrl(this.params);
      this.scopeOfWorkShareUrl = url;
    } catch (error) {
      this.afterCall(CALLBACK_TYPE.GET_SHARE_URL, null, this.params.isInternal);
    }
  }

  *fetchPublicScopeOfWorkShareUrl() {
    try {
      const url = yield this.getPublicScopeOfWorkShareUrl(this.params);
      this.publicScopeOfWorkShareUrl = url;
    } catch (error) {
      this.afterCall(CALLBACK_TYPE.GET_SHARE_URL, null, this.params.isInternal);
    }
  }

  handleCopyUrl() {
    this.copyScopeOfWorkShareUrl(
      this.scopeOfWorkShareUrl,
      this.params.isInternal
    );

    this.copyPublicScopeOfWorkShareUrl(
      this.scopeOfWorkShareUrl,
      this.params.isInternal
    );
  }

  *handleMarkAsInReview(status: string, reason: string) {
    yield this.markSOWAsInReview(
      { ...this.params, reviewStatus: status, reviewReason: reason },
      this.parseDataToSave()
    );
    this.setReviewStatus(status);
    this.setOpenSubmitReviewDialog(false);
  };

  *handleMarkAsReviewComplete(status: string) {
    yield this.markSOWAsInReviewComplete(
      { ...this.params, reviewStatus: status },
    );
    this.setReviewStatus(status);
    this.setOpenCompleteReviewDialog(false);
  }

  *handleMarkAsInRevision(status: string) {
    yield this.markSOWAsInRevision(
      { ...this.params, reviewStatus: status },
    );
    this.setReviewStatus(status);
    this.setOpenSubmitRevisionDialog(false);
  }

  *handleMarkAsApproved() {
    yield this.markSOWAsApproved(
      this.params.scopeOfWorkId,
    );
    this.setReviewStatus(NO_ACTION);
  };

  *handleMarkAsMoreInfoNeeded() {
    yield this.markSOWAsMoreInfoNeeded(
      this.params,
      this.parseDataToSave()
    );
  }

  *fetchScopeOfWork() {
    try {
      let response: ScopeOfWorkV2;

      if (this.isStandaloneScopeOfWorkParams) {
        response = yield this.getScopeOfWork(this.params);
      } else if (this.isExistingScopeOfWorkParams) {
        response = yield this.getScopeOfWorkByLoanIdAndPropertyId(this.params);
      } else {
        response = yield this.getNewScopeOfWork();
      }

      const categoriesResponse = yield this.getScopeOfWorkCategories();

      if (!response || !categoriesResponse) return;
      this.initParams(response);
      this.initScopeOfWork(response, categoriesResponse);
      yield this.loadParticipants();
      yield this.handleSplitedCategories();

      const logsResponse = yield this.getSowAuditLogs()
      this.scopeOfWorkAuditLogsMessages = logsResponse;
    } catch (error) {
      this.afterCall(CALLBACK_TYPE.GET, null, this.params.isInternal);
    }
  }

  *loadParticipants() {
    if (this.showSelectOriginator) {
      yield this.loanParticipantsStore.fetchLoanParticipants();
    }
    if (!this.params?.scopeOfWorkId) {
      if (this.globalStore?.userFeatures?.isLenderOriginator) {
        this.onFieldChange(
          'originatorId',
          this.userStore?.userInformation?.userId
        );
      }
    }
  }

  initParams(scopeOfWork: ScopeOfWorkV2) {
    this.params = {
      ...this.params,
      lenderId: scopeOfWork.lenderId ?? this.params.lenderId,
      scopeOfWorkId: scopeOfWork.scopeOfWorkId ?? this.params.scopeOfWorkId,
      isReviewAllowed: scopeOfWork.reviewAllowed,
      isApprovalAllowed: scopeOfWork.approvalAllowed,
      reviewStatus: scopeOfWork.reviewStatus ? scopeOfWork.reviewStatus : NO_ACTION
    };
  }

  setReviewStatus(status: string) {
    this.params = {
      ...this.params,
      reviewStatus: status
    };
  };

  initScopeOfWork(
    scopeOfWork: ScopeOfWorkV2,
    categoriesResponse: CategoryDB[]
  ) {
    const { dataContent } = scopeOfWork;
    this.scopeOfWork = scopeOfWork;
    this.isDisabled = DISABLE_STATUS_LIST.includes(scopeOfWork.status);
    this.scopeOfWorkData = scopeOfWork.dataContent;
    this.categoryStore.loadCategories(categoriesResponse);

    const propertyFields = dataContent.property || dataContent.propertyAddress;
    this.loadForm({
      ...propertyFields,
      alreadyOwn: propertyFields.address
        ? dataContent.alreadyOwn
        : alreadyOwnDefaultValue,
      anyImprovements: propertyFields.address
        ? dataContent.anyImprovements
        : null,
      originatorId: this.scopeOfWorkData.originatorId,
      projectDescription: this.scopeOfWorkData.projectDescription,
      describeRenovation: this.scopeOfWorkData.describeRenovation
    });

    this.showSaveButton = this.scopeOfWork?.assigned;
  }

  *saveAuditLogs(callback: () => void, isSubmit) {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        yield this.saveScopeOfWorkAuditLogs(this.scopeOfWork.scopeOfWorkId, this.scopeOfWork.lenderId, this.scopeOfWorkAuditLogs, isSubmit, this.params.username)
      } catch (error) {

      } finally {
        callback();
        this.scopeOfWorkAuditLogs = [];
      }
    }
  }

  *getSowAuditLogs() {
    try {
      return yield this.getScopeOfWorkAuditLogs(this.scopeOfWork.scopeOfWorkId, this.scopeOfWork.lenderId)
    } catch (error) {
      return null;
    }
  }

  *saveScopeOfWorkData() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const saved = yield this.saveScopeOfWork(
          this.params,
          this.parseDataToSave()
        );
        this.afterCall(CALLBACK_TYPE.SAVE, saved, this.params.isInternal);
      } catch (error) {
        this.afterCall(CALLBACK_TYPE.SAVE, null, this.params.isInternal);
      }
    }
  }

  manageExeptions() {
    this.setShowLoadingMask(false);
    this.confirmPopupStore.closeModal();
    const responseData = { responseMessage: 'There were issues while submitting the scope of work. Please review the error messages below and make the necessary corrections before resubmitting.' }
    this.afterCall(CALLBACK_TYPE.SUBMIT, responseData, this.params.isInternal);
  }

  *getSubmissionErrors() {
    try {
      const warningsAndErrors = yield this.getSowSubmissionErrors(
        this.scopeOfWork.loanId,
        this.scopeOfWork.propertyId,
        this.parseDataToSave()
      );
      this.submissionErrors = warningsAndErrors.errors;
      this.submissionWarnings = warningsAndErrors.warnings;
    } catch (error) {
      console.log(error)
    }
  }


  *saveSOWDataContent() {
    try {
      yield this.saveScopeOfWorkDataContent(
        this.params,
        this.parseDataToSave()
      );
    } catch (error) {
      console.log(error)
    }
  }

  async submitScopeOfWorkData() {
    this.runFormValidationWithMessage();
    if (this.form.meta.isValid) {
      try {
        const response = await this.submitScopeOfWork(
          this.params,
          this.parseDataToSave(),
          true
        );
        if (this.scopeOfWork.assigned && response.success) {
          this.isDisabled = true;
        }
        this.submittedScopeOfWorkId = response?.scopeOfWorkId;
        this.scopeOfWorkShareUrl = response?.scopeOfWorkShareUrl ?? this.scopeOfWorkShareUrl;
        return response;
      } catch (error) {
        this.afterCall(CALLBACK_TYPE.SUBMIT, null, this.params.isInternal);
      }
    }
    return null;
  }

  setScopeOfWorkId(sowId) {
    this.scopeOfWork.scopeOfWorkId = sowId;
    this.params.scopeOfWorkId = sowId;
  }

  *autoSaveScopeOfWork() {
    try {
      const isStandalone = !this.scopeOfWork?.assigned;
      const silent = true; // Do not show the global loading
      if (isStandalone) {
        const response = yield this.submitScopeOfWork(
          this.params,
          this.parseDataToSave(),
          silent
        );
        if (!this.params.scopeOfWorkId) {
          this.setScopeOfWorkId(response.scopeOfWorkId);
          this.scopeOfWorkStoreV2.gridStore.fetchGridData();
        }
      } else {
        yield this.saveScopeOfWork(this.params, this.parseDataToSave(), silent);
      }
    } catch (e) { }
  }

  *exportScopeOfWorkData() {
    try {
      const response = yield this.exportScopeOfWork(this.parseDataToSave());
      downloadDocument(response?.data, response?.headers, 'download');
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while exporting excel document.',
      });
    }
  }


  *resetScopeOfWork() {
    try {
      const response = yield this.removeScopeOfWork(this.params.scopeOfWorkId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Scope of work has been reset successfully.'
      });
      if (this.isInternalPortal) {
        this.setRenderScopeOfWorkV2Link(true);
      }
      if (!this.onResetSuccesfull) return;
      this.onResetSuccesfull()
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while reseting scope of work',
      });
    }
  }

  parseDataToSave() {
    return {
      ...this.scopeOfWorkData,
      ltcCatchupAmount: this.categoryStore.sortedCategoriesNotEmpty.some(cat => cat.name === 'LTC Catchup') ? this.ltcCatchupAmount : 0,
      alreadyOwn: this.form.fields.alreadyOwn.value,
      anyImprovements: this.form.fields.anyImprovements.value,
      totalCost: this.categoryStore.totalCost,
      totalCapex: this.categoryStore.totalCapex,
      originatorId: this.form.fields.originatorId.value,
      propertyAddress: this.parsePropertyAddressToSave(),
      categoryMap: this.categoryStore.parseDataToSave(),
      projectDescription: this.form.fields.projectDescription.value,
      describeRenovation: this.form.fields.describeRenovation.value,
      borrowerFirstName: this.form.fields?.borrowerFirstName?.value,
      borrowerLastName: this.form.fields?.borrowerLastName?.value,
      borrowerEmailAddress: this.form.fields?.borrowerEmailAddress?.value,
    };
  }

  parsePropertyAddressToSave(): Address {
    return {
      ...this.scopeOfWorkData.propertyAddress,
      address: this.form.fields.address.value,
      streetNumber: this.form.fields.streetNumber.value,
      streetName: this.form.fields.streetName.value,
      city: this.form.fields.city.value,
      state: this.form.fields.state.value,
      zipCode: this.form.fields.zipCode.value,
    };
  }

  get isExistingScopeOfWorkParams() {
    return !!(this.params.loanId && this.params.propertyId);
  }

  get isStandaloneScopeOfWorkParams() {
    return !!this.params.scopeOfWorkId;
  }

  get showShare() {
    return (
      !isNil(this.params?.isInternal) &&
      (this.scopeOfWork?.assigned || this.scopeOfWork?.standalone)
    );
  }

  get showResetSow() {
    if (this.scopeOfWork?.assigned) {
      return (this.scopeOfWork?.status !== ACCEPTED && (this.renderScopeOfWorkV2LinkFromUW || (!isNil(this.params?.isInternal) && !this.isInternalPortal)));
    } else if (this.scopeOfWork?.scopeOfWorkId) {
      return (this.renderScopeOfWorkV2LinkFromUW || (!isNil(this.params?.isInternal) && !this.isInternalPortal));
    }
  }

  get addressDisabled() {
    return (
      this.isDisabled || isNil(this.params.isInternal) || this.scopeOfWork.assigned
    );
  }

  get addressError() {
    return (
      this.form.fields.address.error ||
      [
        this.form.fields.streetNumber.error,
        this.form.fields.streetName.error,
        this.form.fields.city.error,
        this.form.fields.state.error,
      ]
        .filter(isNotBlank)
        .join(' ')
    );
  }

  get getDescriptionError() {
    return this.form.fields.describeRenovation.error;
  }

  get getBorrowerFirstNameError() {
    return this.form.fields.borrowerFirstName.error
  }

  get getBorrowerLastNameError() {
    return this.form.fields.borrowerFirstName.error
  }

  get getBorrowerEmailError() {
    return this.form.fields.borrowerFirstName.error
  }

  get showSuccessDialog() {
    return !!this.submittedScopeOfWorkId && !this.showLoadingMask;
  }

  get showSelectOriginator() {
    return !this.params?.scopeOfWorkId //Only if new SOW is being submitted
      && !this.params?.isInternal
  }

  closeSuccessDialog() {
    this.submittedScopeOfWorkId = null;
  }

  *moveToStep(step) {
    this.activeStep = step;
    this.submissionErrors = [];
  }

  goPrevStep() {
    this.moveToStep(this.activeStep - 1);
  }

  goNextStep() {
    this.moveToStep(this.activeStep + 1);
  }

  onReject() { }

  onApprove() { }

  getSubCategoryStyle(categoryId, subCategoryName): string {
    return '';
  }
  getItemStyle(categoryId, subCategoryName, itemName): string {
    return '';
  }

  reset() {
    this.categoryStore.reset();
    super.reset();
    this.setDefaults();
  }

  setShowLoadingMask(value) {
    this.showLoadingMask = value;
  }

  setOpenSubmitReviewDialog(value: boolean) {
    this.isSubmitForReviewOpen = value;
  }

  setOpenSubmitRevisionDialog(value: boolean) {
    this.isSubmitForRevisionOpen = value;
  }

  setOpenCompleteReviewDialog(value: boolean) {
    this.isReviewCompleteOpen = value;
  }

  get showInternalActions() {
    return this.isInternalPortal;
  }
}

export default ScopeOfWorkV2FormBaseStore;
