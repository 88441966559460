import * as Validator from 'validatorjs';
import { isNotBlank } from './utils';

Validator.registerImplicit(
  'required_if_contains',
  function (val) {
    const [fieldName, searchStr] = this.getParameters() ?? [];

    const fieldValue = this.validator._objectPath(
      this.validator.input,
      fieldName
    );

    if (fieldValue.includes(searchStr)) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  ''
);

Validator.registerImplicit(
  'required_if_and',
  function (val) {
    const parameters = this.getParameters();

    let result = true;

    for (let i = 0; i < parameters.length - 1; i += 2) {
      const fieldName = parameters[i];
      const searchStr = [...parameters[i + 1]];

      const fieldValue = this.validator._objectPath(
        this.validator.input,
        fieldName
      );

      result = result && searchStr.includes(fieldValue);
    }

    if (result) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  ''
);

Validator.registerImplicit(
  'required_if_one_of',
  function (val) {
    const [fieldName, ...values] = this.getParameters() ?? [];

    const fieldValue = this.validator._objectPath(
      this.validator.input,
      fieldName
    );

    if (values.some(value => value == fieldValue)) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  ''
);

Validator.registerImplicit(
  'required_if_true',
  function (val) {
    const fieldNames = this.getParameters() ?? [];

    const fieldValues = fieldNames.map(fieldName =>
      this.validator._objectPath(this.validator.input, fieldName)
    );

    if (fieldValues.every(value => value === true)) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  ''
);

Validator.registerImplicit(
  'valid_address',
  function (val) {
    const addressFields = this.getParameters() ?? [];

    const fieldValues = addressFields.map(fieldName =>
      this.validator._objectPath(this.validator.input, fieldName)
    );

    return fieldValues.every(value => isNotBlank(value));
  },
  'Invalid address'
);

Validator.registerImplicit(
  'address',
  function (val) {
    const isValid = val?.address && val?.city && val?.state && val?.zip;
    return isValid;
  },
  'Please fill all address-related fields'
);

Validator.registerImplicit('address_state_not_in', function (val) {
  const invalidValues = this.getParameters() ?? [];
  const state = val?.state || '';
  return !invalidValues.includes(state);
});

/**
 * This validator would take in rules and process them one by one
*/
Validator.registerImplicit(
  'combined_validation',
  function (val) {
    const context = this;
    const nestedRules = this.getParameters(); // Get the array of nested rules
    return validateAllNested(nestedRules, val, context);
  },
  ''
);

function validateAllNested(nestedRules, value, context) {
  for (const rule of nestedRules) {
    const ruleName = Object.keys(rule)[0];
    const ruleParams = rule[ruleName];

    if (context.validator.getRule(ruleName).validate(value, ruleParams, context.attribute, null)) {
      return true;
    }
  }
  return false;
}
