import { Button, DialogState } from '@roc/ui';
import React, { ReactNode } from 'react';
import { Box, Drawer, Typography } from '@material-ui/core';
import { IconButton} from "@roc/ui";

import { Close } from '@material-ui/icons';

interface EditableDrawerProps {
  open: boolean;
  title: string;
  maxWidth: number;
  addDialogTitlePrefix?: string;
  editDialogTitlePrefix?: string;
  addButtonText?: string;
  editButtonText?: string;
  content: ReactNode;
  state: DialogState;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  handleAdd?: React.MouseEventHandler<HTMLButtonElement>;
  handleEdit?: React.MouseEventHandler<HTMLButtonElement>;
  disabledButton?: boolean;
  additionalActions?: ReactNode[];
  showSaveChangesButton?: boolean;
  showCancelButton?: boolean;
  removeContentBox?: boolean;
}

export const EditableDrawer = ({
  open,
  title,
  addDialogTitlePrefix = 'Add',
  editDialogTitlePrefix = 'Edit',
  handleClose,
  content,
  state,
  handleAdd = () => { },
  handleEdit = () => { },
  disabledButton = false,
  addButtonText = 'Add',
  editButtonText = 'Apply Changes',
  additionalActions,
  showSaveChangesButton = true,
  showCancelButton = true,
  maxWidth, 
  removeContentBox = false,
  ...props
}: EditableDrawerProps) => {
  return (
    <Drawer
      anchor='right'
      open={open}
      title={
        state == DialogState.ADD
          ? `${addDialogTitlePrefix} ${title}`
          : `${editDialogTitlePrefix} ${title}`
      }
      onClose={handleClose}
    >
      <Box style={{
        width: maxWidth,
        padding: '20px'
      }}>
        <Box>
          <Typography style={{
            fontSize: '2.125rem',
            fontFamily: 'Roboto Condensed, sans-serif',
            fontWeight: 400,
            lineHeight: '1.235'
          }}>
            <span>
              { state == DialogState.ADD
                ? `${addDialogTitlePrefix} ${title}`
                : `${editDialogTitlePrefix} ${title}`}
            </span>
            <IconButton
              testId="close-drawer-details"
              color="default"
              onClick={handleClose}
              style={{
                padding: 4,
                float: 'right'
              }}
            >
              <Close />
            </IconButton>
          </Typography>
        </Box>
        {content}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} padding={'20px 20px 0 20px'}>
          {showCancelButton && 
            <Button
              style={{ marginRight: '8px' }}
              onClick={handleClose}
              color="primary"
              variant="outlined"
              testId="cancel"
            >
              Cancel
            </Button>
          }
          {additionalActions && additionalActions.map((action, index) => (
            <span key={index}>
              {action}
            </span>
          ))}
          {showSaveChangesButton && 
            <Button
              onClick={e => {
                {
                  state == DialogState.ADD ? handleAdd(e) : handleEdit(e);
                }
              }}
              color="primary"
              variant="contained"
              testId="save"
              disabled={disabledButton}
            >
              {state == DialogState.ADD ? addButtonText : editButtonText}
            </Button>
          } 
        </Box>
      </Box>
    </Drawer>
  );
};
