import { ApiResponse, FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable } from "mobx";
import { PermitService } from "../../services/permitService";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";

const form = {
  fields: {
    documentType: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PermitFormStore extends FormStore {
  private globalStore: GlobalStore;
  private permitService: PermitService;

  constructor(globalStore: GlobalStore){
    super(form, globalStore);
    this.globalStore = globalStore;
    this.permitService = new PermitService();

    makeObservable(this,{
      resetStore: action,
      savePermitDocumentType: flow,
      getPermitDocumentType: flow,
    })
  }

  resetStore(){
    this.reset();
  }

  *getPermitDocumentType(loanId, propertyId){
    try{
      const response: ApiResponse = yield this.permitService.getPermitsDocumentType(
        loanId,
        propertyId
      );
      const documentType = response.data.reponseMessage;
      this.loadForm({ documentType });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *savePermitDocumentType(loanId, propertyId) {
    try {
      const { documentType } = this.getFormValues();
      const data = {loanId: loanId, propertyId: propertyId, documentType: documentType};
      this.permitService.savePermitDocumentType(JSON.stringify(data));
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}