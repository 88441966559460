import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ColumnContainer,
  CondoConversion,
  FieldContainerNoSpacing,
  FireDamage,
  IsSellerCredit,
  SellerCreditAmount,
  SquareFootageAdded,
  WholesalerRelationship,
} from '@roc/ui/formComponents';
import {
  PropertyAddressDetails,
  PropertyType,
  Unit,
  PropertyOwnership,
  PurchasePrice,
  ArmsLength,
  ArmsLengthDescribe,
  PropertySource,
  PropertySourceOther,
  WholesalerPropertyPaid,
  WholesalerPropertySource,
  WholesalerPropertySourceOther,
  AnyDebt,
  DebtAmount,
  PurchaseDate,
  RenovationBudget,
  DescribeRenovation,
  RefinanceRenovationBudget,
  RefinanceInvestedCapitalImprovements,
  RefinanceRenovationAmountRemaining,
  AsIsValue,
  AfterRepairValue,
  ExitStrategy,
  AdditionalComments,
  SubmitRushAppraisal,
} from '@roc/ui/formComponents';
import { CurrencyField, GridRowSelectedEvent } from '@roc/ui';
import {
  CONDOMINIUM,
  isNotBlank,
  propertyTypesByLoanSubtype,
  renovationDescriptionsByLoanSubtype,
} from '@roc/feature-utils';
import { LoanSubType } from '@roc/feature-utils';
import { useBaseStore } from '@roc/feature-app-core';
import { PropertyFormStore } from '../store/propertyFormStore';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { Grid, GridSpacing } from '@material-ui/core';

export const PropertyForm = observer(
  ({ store }: { store: PropertyFormStore }) => {
    const { newLoanApplicationStore } = useNewLoanApplicationStore();
    const { propertyInformationStore, isPurchase } = store;

    return (
      <Grid container spacing={2}>
        <PropertyInformation
          propertyStore={store}
          loanSubtype={newLoanApplicationStore.loanType}
          disabled={false}
        />
        {isPurchase ? (
          <>
            <PurchaseInformation propertyStore={store} disabled={false} />
            <RenovationDetails
              propertyStore={store}
              disabled={false}
              loanSubtype={newLoanApplicationStore.loanType}
            />
            <OtherDetails
              propertyStore={store}
              disabled={false}
              loanSubtype={newLoanApplicationStore.loanType}
            />
          </>
        ) : (
          <>
            <Refinance_PurchaseInformation
              propertyStore={store}
              disabled={false}
            />
            <Refinance_RenovationDetails
              propertyStore={store}
              disabled={false}
              loanSubtype={newLoanApplicationStore.loanType}
            />
            <Refinance_OtherDetails
              propertyStore={store}
              disabled={false}
              loanSubtype={newLoanApplicationStore.loanType}
            />
          </>
        )}
      </Grid>
    );
  }
);

const PropertyInformation = observer(
  ({ propertyStore, loanSubtype, disabled }) => {
    const { propertyInformationStore, appraisalId } = propertyStore;
    const isCondo =
      propertyInformationStore.form.fields.useCode.value === CONDOMINIUM;
    const propertyTypeOptions = propertyTypesByLoanSubtype(loanSubtype);
    propertyInformationStore.onFieldChange('loanSubType', loanSubtype);
    const disabledField = disabled;
    return (
      <>
        <FieldContainer>
          <PropertyAddressDetails
            store={propertyInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
        <FieldContainer>
          <PropertyType
            store={propertyInformationStore}
            propertyTypeOptions={propertyTypeOptions}
            disabled={appraisalId}
          />
        </FieldContainer>
        <FieldContainer>
          <PropertyOwnership
            store={propertyInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      </>
    );
  }
);

const PurchaseInformation = observer(({ propertyStore, disabled }) => {
  const { purchaseInformationStore, appraisalId } = propertyStore;
  const isWholesalerProperty =
    purchaseInformationStore.form.fields.propertySourcing.value ===
    'Wholesaler';
  const isArmsLengthNo =
    purchaseInformationStore.form.fields.armsLength.value === 'N';
  const isPropertySourceOther =
    purchaseInformationStore.form.fields.propertySourcing.value === 'Other';
  const isWholesalerPropertySourceOther =
    purchaseInformationStore.form.fields.wholesalerPurchase.value === 'Other';
  const disabledField = disabled;
  const isSellerCredit = purchaseInformationStore.form.fields.sellerCredit.value === true;

  return (
    <>
      <FieldContainer>
        <PurchasePrice
          store={purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      <FieldContainer>
        <ArmsLength store={purchaseInformationStore} disabled={appraisalId} />
      </FieldContainer>
      {isArmsLengthNo && (
        <FieldContainer>
          <ArmsLengthDescribe
            store={purchaseInformationStore}
            disabled={appraisalId}
            showError
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PropertySource
          store={purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {isPropertySourceOther && (
        <FieldContainer>
          <PropertySourceOther
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertyPaid
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertySource
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && isWholesalerPropertySourceOther && (
        <FieldContainer>
          <WholesalerPropertySourceOther
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerRelationship
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <IsSellerCredit
          store={purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {isSellerCredit && (
        <FieldContainer>
          <SellerCreditAmount
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
    </>
  );
});

const Refinance_PurchaseInformation = observer(
  ({ propertyStore, disabled }) => {
    const { refinance_purchaseInformationStore } = propertyStore;
    const isAnyDebt =
      refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';
    const disabledField = disabled;
    return (
      <>
        <FieldContainer>
          <AnyDebt
            store={refinance_purchaseInformationStore}
            disabled={disabledField}
            showError
          />
        </FieldContainer>
        {isAnyDebt && (
          <FieldContainer>
            <DebtAmount
              store={refinance_purchaseInformationStore}
              disabled={disabledField}
            />
          </FieldContainer>
        )}
        <FieldContainer>
          <PurchaseDate
            store={refinance_purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
        <FieldContainer>
          <PurchasePrice
            store={refinance_purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      </>
    );
  }
);

const RenovationDetails = observer(
  ({ propertyStore, loanSubtype, disabled }) => {
    const { renovationDetailsStore, appraisalId } = propertyStore;
    const totalRenovationBudget =
      renovationDetailsStore.form.fields.totalRenovationBudget.value;
    const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
      loanSubtype
    );
    renovationDetailsStore.onFieldChange('loanSubType', loanSubtype);
    const disabledField = disabled;
    return (
      <>
        <FieldContainer>
          <RenovationBudget
            store={renovationDetailsStore}
            disabled={disabledField}
            onChange={() =>
              renovationDetailsStore.handleRenovationBudgetChange(loanSubtype)
            }
          />
        </FieldContainer>
        {totalRenovationBudget > 0 && !(LoanSubType.GROUND_UP == loanSubtype) && (
          <FieldContainer>
            <DescribeRenovation
              store={renovationDetailsStore}
              disabled={disabledField}
              renovationDescriptionOptions={renovationDescriptionOptions}
              showError
            />
          </FieldContainer>
        )}
        {/* TODO: Check what to do about this field */}
        {/* {loanSubtype === LoanSubType.FIX_AND_FLIP && (
        <FieldContainer>
          <CondoConversion store={loanInformationStore} />
        </FieldContainer>
      )} */}
        {/* <FieldContainer>
          <FireDamage store={renovationDetailsStore} />
        </FieldContainer>
        <FieldContainer>
          <SquareFootageAdded store={renovationDetailsStore} />
        </FieldContainer> */}
      </>
    );
  }
);

const Refinance_RenovationDetails = observer(
  ({ propertyStore, loanSubtype, disabled }) => {
    const { refinance_renovationDetailsStore, appraisalId } = propertyStore;
    const amountRemaining =
      refinance_renovationDetailsStore.form.fields.renovationRemaining.value >
      0;
    const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
      loanSubtype
    );
    refinance_renovationDetailsStore.onFieldChange('loanSubType', loanSubtype);
    const disabledField = disabled;
    return (
      <>
        <FieldContainer>
          <RefinanceRenovationBudget
            store={refinance_renovationDetailsStore}
            onChange={() =>
              refinance_renovationDetailsStore.handleRenovationBudgetChange()
            }
            disabled={disabledField}
          />
        </FieldContainer>
        <FieldContainer>
          <RefinanceInvestedCapitalImprovements
            store={refinance_renovationDetailsStore}
            onChange={() =>
              refinance_renovationDetailsStore.handleRenovationSpentToDateChange(
                loanSubtype
              )
            }
            disabled={appraisalId}
          />
        </FieldContainer>
        <FieldContainer>
          <RefinanceRenovationAmountRemaining
            store={refinance_renovationDetailsStore}
          />
        </FieldContainer>
        {amountRemaining && !(LoanSubType.GROUND_UP == loanSubtype) && (
          <FieldContainer>
            <DescribeRenovation
              store={refinance_renovationDetailsStore}
              disabled={disabledField}
              renovationDescriptionOptions={renovationDescriptionOptions}
              showError
            />
          </FieldContainer>
        )}
        {/* <FieldContainer>
          <FireDamage store={refinance_renovationDetailsStore} />
        </FieldContainer>
        <FieldContainer>
          <SquareFootageAdded store={refinance_renovationDetailsStore} />
        </FieldContainer> */}
      </>
    );
  }
);

const OtherDetails = observer(({ propertyStore, disabled, loanSubtype }) => {
  const { otherDetailsStore, appraisalId } = propertyStore;
  const disabledField = disabled;

  return (
    <>
      <FieldContainer>
        <AfterRepairValue store={otherDetailsStore} disabled={disabledField} />
      </FieldContainer>
      <FieldContainer>
        <ExitStrategy store={otherDetailsStore} disabled={disabledField} />
      </FieldContainer>
      {otherDetailsStore.form.fields.exitStrategy.value === 'Refinance' ? (
        <>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <MonthlyMarketRent store={otherDetailsStore} />
              <AnnualTaxes store={otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <AnnualInsurance store={otherDetailsStore} />
              <AnnualHoa store={otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
        </>
      ) : null}
      {(LoanSubType.GROUND_UP == loanSubtype) && (
        <FieldContainer>
          <AdditionalComments store={otherDetailsStore} disabled={appraisalId} />
        </FieldContainer>
      )}
    </>
  );
});

const Refinance_OtherDetails = observer(({ propertyStore, disabled, loanSubtype }) => {
  const {
    refinance_renovationDetailsStore,
    refinance_otherDetailsStore,
    appraisalId,
  } = propertyStore;
  const amountRemaining =
    refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;
  const disabledField = disabled;

  return (
    <>
      <FieldContainer>
        <AsIsValue
          store={refinance_otherDetailsStore}
          disabled={disabledField}
          showError
        />
      </FieldContainer>
      {amountRemaining && (
        <FieldContainer>
          <AfterRepairValue
            store={refinance_otherDetailsStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <ExitStrategy
          store={refinance_otherDetailsStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      {refinance_otherDetailsStore.form.fields.exitStrategy.value ===
        'Refinance' ? (
        <>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <MonthlyMarketRent store={refinance_otherDetailsStore} />
              <AnnualTaxes store={refinance_otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <AnnualInsurance store={refinance_otherDetailsStore} />
              <AnnualHoa store={refinance_otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
        </>
      ) : null}
      {(LoanSubType.GROUND_UP == loanSubtype) && (
        <FieldContainer>
          <AdditionalComments
            store={refinance_otherDetailsStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
    </>
  );
});

const MonthlyMarketRent = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Monthly Market Rent"
      value={form.fields.monthlyMarketRentProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange(
          'monthlyMarketRentProvidedAtOrigination',
          Number(value)
        )
      }
      fullWidth
      testId="monthlyMarketRentProvidedAtOrigination"
      required
      error={isNotBlank(
        form.fields.monthlyMarketRentProvidedAtOrigination.error
      )}
      errorText={form.fields.monthlyMarketRentProvidedAtOrigination.error}
    />
  );
});

const AnnualTaxes = observer(({ store }) => {
  const { form } = store;

  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Taxes"
      value={form.fields.annualTaxesProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange('annualTaxesProvidedAtOrigination', Number(value))
      }
      fullWidth
      testId="annualTaxesProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualTaxesProvidedAtOrigination.error)}
      errorText={form.fields.annualTaxesProvidedAtOrigination.error}
    />
  );
});

const AnnualInsurance = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual Insurance"
      value={form.fields.annualInsuranceProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange(
          'annualInsuranceProvidedAtOrigination',
          Number(value)
        )
      }
      fullWidth
      testId="annualInsuranceProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualInsuranceProvidedAtOrigination.error)}
      errorText={form.fields.annualInsuranceProvidedAtOrigination.error}
    />
  );
});

const AnnualHoa = observer(({ store }) => {
  const { form } = store;
  return (
    <CurrencyField
      variant="outlined"
      standaloneLabel
      label="Annual HOA Dues"
      value={form.fields.annualHOAProvidedAtOrigination.value}
      onChange={(name, value) =>
        store.onFieldChange('annualHOAProvidedAtOrigination', Number(value))
      }
      fullWidth
      testId="annualHOAProvidedAtOrigination"
      required
      error={isNotBlank(form.fields.annualHOAProvidedAtOrigination.error)}
      errorText={form.fields.annualHOAProvidedAtOrigination.error}
    />
  );
});



const FieldContainer = ({ children, spacing }: { children: ReactNode, spacing?: GridSpacing }) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-around"
      style={{ margin: 0 }}
    >
      {Array.isArray(children) ? (
        children.map((c, i) => (
          <Grid key={`carousel-grid-item-${i}`} item xs={12}>
            {c}
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};