import { GridColumnProps, LinkColumn } from '@roc/ui';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { useExternalAgentRoutes } from '@roc/feature-title-agent';
import { useExternalAgentLoansStore } from '../../hooks/useExternalAgentLoansStore';

export const foreclosureLoansColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 600,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'lender',
    headerName: 'Lender',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 100,
    cellRenderer: 'currencyCellRenderer',
    comparator: noOpComparator,
  },
];

export const ExternalForeclosureLoansGrid = observer(() => {
  const { titleAgentLoanRoutesConfig } = useExternalAgentRoutes();
  const { externalLoansDashboardStore: titleLoansDashboardStore } = useExternalAgentLoansStore();

  const frameworkComponents = {
    loanIdCellRenderer: params => {
      return LinkColumn({
        ...params,
        url: titleAgentLoanRoutesConfig.loans(params.value).children.foreclosureInvoice.url,
      });
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
  };
  return (
    <>
      <InfiniteScrollGrid
        columns={foreclosureLoansColumns}
        store={titleLoansDashboardStore.foreclosureLoansGridStore}
        frameworkComponents={frameworkComponents}
      />
    </>
  );
});