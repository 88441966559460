import { Grid } from '@material-ui/core';
import { Button, StandardDialog, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';

interface IOrderTaxCertificateModalProps {
  showOrderCertificateModal: boolean;
  handleOrderConfirm: (loanId) => void;
  closeOrderModal: () => void;
}

export const OrderTaxCertificateModal = observer(({ showOrderCertificateModal, closeOrderModal, handleOrderConfirm }: IOrderTaxCertificateModalProps) => {
  const [loanId, seLoandId] = useState('')

  const getContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            testId="orderCertificateTxt"
            label="Loan Id"
            variant='outlined'
            value={loanId}
            onChange={(e) => seLoandId(e.target.value)}
            style={{
              width: '100%'
            }}
            type='number'
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <StandardDialog
      open={showOrderCertificateModal}
      title={"Order Certificate"}
      maxWidth="sm"
      handleClose={closeOrderModal}
      dialogContent={
        getContent()
      }
      dialogActions={(
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={closeOrderModal}
            color="primary"
            variant="outlined"
            testId="cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleOrderConfirm(loanId);
              seLoandId('');
            }}
            color="primary"
            variant="contained"
            testId="confirm"
            disabled={!loanId}
          >
            Order
          </Button>
        </>
      )}
    />
  );

});
