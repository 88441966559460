import ReactECharts from "echarts-for-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { formatCurrencyField } from "../../utils/utils";
import { ChartUnavailable } from "./chartUnavailable";

const chartTitle = "Year over Year Sale Price Trend";

function fillMissingMonths(data) {
  // Extract the year from the first date in the data (assuming all dates are within the same year)
  const year = data.length > 0 ? data[0].date.getFullYear() : new Date().getFullYear();

  // Create an array to store the filled data
  const filledData = [];

  // Iterate through all months (0 to 11 for a full year)
  for (let month = 0; month < 12; month++) {
    // Find the entry for the current month
    const monthData = data.find(item => item.date.getMonth() === month);

    // If data for the month exists, push it, otherwise push a new object with null value
    if (monthData) {
      filledData.push(monthData);
    } else {
      filledData.push({ date: new Date(year, month), value: null });
    }
  }

  return filledData;
}

function transformData(chartData) {
  try {
    if (!chartData) {
      return null;
    }

    const sortedYears = Object.keys(chartData.years)
      .map(Number)
      .sort((a, b) => b - a)

    const lastThreeYears = sortedYears.slice(0, 3);

    const filteredObject = lastThreeYears.reduce((acc, year) => {
      acc[year] = chartData.years[year];
      return acc;
    }, {});

    const colors = ["#92bfce", "#a2acb0", "#4d7fa2"];
    const tiers = Object.keys(filteredObject);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const option = {
      title: {
        text: chartTitle,
        left: "center",
        textStyle: {
          fontSize: 20,
          fontWeight: "bold",
          color: "#656565",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        top: "8%",
        left: "center",
        data: tiers,
      },
      grid: {
        left: "3%",
        right: "6%",
        bottom: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: months,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatCurrencyField
        }
      },
      series: [],
    };



    option.xAxis.data = months;

    Object.keys(filteredObject).forEach(
      (tier, index) => {


        const mappedValues = filteredObject[tier].values.map(entry => ({
          value: Math.round(entry.value * 100) / 100,
          date: new Date(entry.year, entry.month - 1)
        }));

        const finalValues = fillMissingMonths(mappedValues).sort((a, b) => a.date - b.date);

        option.series.push({
          name: tiers[index],
          type: "line",
          symbol: "none",
          data: finalValues.map((entry) => entry.value),
          color: colors[index % colors.length],
        });
      }
    );

    return option;
  } catch (err) {
    console.log(err);
    return null;
  }

}

interface YearOverYearSalePriceTrendChartProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const YearOverYearSalePriceTrendChart = ({ internalValuationReviewStore }: YearOverYearSalePriceTrendChartProps) => {
  const chartData = internalValuationReviewStore.initialData.charts?.yoy_sale_price_trends;
  const option = transformData(chartData);

  if (!option) {
    return <ChartUnavailable chartName={chartTitle} />
  }

  return (
    <ReactECharts option={option} style={{ height: "461px", width: "100%" }} />
  );
};