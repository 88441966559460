import { action, computed, flow, makeObservable, observable } from 'mobx';
import { startOfToday, differenceInYears, parse, format } from 'date-fns';
import PropertyStore from './propertyStore';
import BorrowerFormStore from './borrowerFormStore';
import { ApiResponse } from '@roc/feature-app-core';
import { LoanService } from '../../../loans/services/loanService';
import {
  ELMSURE,
  generateUUID,
  isEmptyObject,
  isNil,
  isNotBlank,
  LOAN_WITHOUT_LEAD_SOURCE_VALIDATION,
  LoanSubType,
  notLicensedinState,
  REQUEST_LOAN_TYPE_LOAN,
  segmentAnalytics,
  Utility,
  WIMBA,
  Roles,
  SegmentTrackerEvent,
  OTHER
} from '@roc/feature-utils';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import LoanInformationStore from './loanInformationStore';
import { Borrower, MFProperty } from '@roc/feature-types';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { DraftLoan, Entity, Property } from '@roc/feature-types';
import EntityStore from '../common/entityStore';
import {
  getBridgeLoanTerms,
  getBorrowerEntity,
  getBorrowers,
  getBridgeProperties,
} from '../common/convert-draft-loan-helper';
import { canSubmitAppraisalWithTamarisk } from '../../utils/appraisalUtils';
import { borrowerDefaults } from '@roc/feature-utils';
import { transactionRoles } from '@roc/ui/formComponents';
import { BrokersStore } from '@roc/feature-brokers';
import primaryPropertyTemplate from '../templates/primaryPropertyTemplate';
import { LoanSubmissionStep } from '../../utils/constants';
import { requiredLicenseStates } from '@roc/feature-utils';
import { normalizeState } from '@roc/feature-utils';

export class MultiFamilyStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  propertyStore: PropertyStore;
  brokersStore: BrokersStore;
  entityStore: EntityStore;
  borrowerFormStore: BorrowerFormStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanInformationStore: LoanInformationStore;
  public entity: Entity | Record<string, never>;
  public rawEntity: Entity | Record<string, never>;
  public selfBorrower: Borrower | Record<string, never>;
  public loanTerms;
  public pricingModelValues;
  public properties: MFProperty[];
  public loanType: string;
  public activeStep: number;
  public stepError: string;
  public allErrors: string[];
  public allWarnings: string[];
  public allOutOfBoxWarnings: string[];
  public loanSubtype: LoanSubType;
  public saved: boolean;
  public requestSaved: boolean;
  public savedLoanId: number;
  public isNewEntity: boolean;
  public selectedEntities: any[];
  private loanService: LoanService;
  public draftLoanInfo: DraftLoan;
  public isDealRoom: boolean;
  public hasFastTrack: boolean;
  public isFastTrackPaymentComplete: boolean;
  public disableSubmitButton: boolean;

  constructor(globalStore, userStore, brokersStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.brokersStore = brokersStore;
    this.propertyStore = new PropertyStore(this.globalStore, x =>
      this.handleRenovationBudgeChanged(x)
    );
    this.entityStore = new EntityStore(this.globalStore, this);
    this.borrowerFormStore = new BorrowerFormStore(this.globalStore);
    this.loanInformationStore = new LoanInformationStore(
      this.globalStore,
      this
    );
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      undefined,
      () => new BorrowerFormStore(this.globalStore)
    );
    this.loanService = new LoanService();
    this.setDefaults();

    makeObservable(this, {
      entity: observable,
      selfBorrower: observable,
      loanTerms: observable,
      properties: observable,
      saved: observable,
      requestSaved: observable,
      savedLoanId: observable,
      activeStep: observable,
      stepError: observable,
      allErrors: observable,
      allOutOfBoxWarnings: observable,
      pricingModelValues: observable,
      reset: action,
      loadStore: flow,
      setExistingEntity: action,
      setDefaultEntity: action,
      setEntity: action,
      setSelectedEntity: action,
      setIsNewEntity: action,
      moveToStep: action,
      goNextStep: action,
      goPrevStep: action,
      setBorrowers: action,
      setNewBorrowers: action,
      setLoanTerms: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      propertiesRows: computed,
      canSubmitAppraisalWithTamarisk: computed,
      onLoanSave: flow,
      getDraftLoan: flow,
      onLoanSubmit: flow,
      log: flow,
      isRawEntityValue: action,
      setDraftLoanId: action,
      validateOutOfBoxLoan: flow,
      getPricingModel: flow,
      sendFastTrackPayment: flow,
      setRushedAppraisalProperties: action,
      draftLoanInfo: observable,
      isLoanRequest: computed,
      disableSubmitButton: observable,
      validateBorrowerErrors: flow,
      validateEntityErrors: flow,
      isInternal: computed,
    });
  }

  private setDefaults() {
    this.entity = {};
    this.rawEntity = {};
    this.loanTerms = {};
    this.properties = [];
    this.loanType = 'Residential Bridge';
    this.loanSubtype = LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;
    this.activeStep = 0;
    this.stepError = '';
    this.allErrors = [];
    this.allWarnings = [];
    this.allOutOfBoxWarnings = [];
    this.saved = false;
    this.requestSaved = false;
    this.savedLoanId = null;
    this.isNewEntity = false;
    this.selectedEntities = [];
    this.draftLoanInfo = {
      borrowers: null,
      propertyAddress: null,
      loanType: null,
      loanSubType: null,
      amount: null,
      createdBy: null,
      createdDate: null,
      creationDate: null,
      draftLoanId: null,
      id: null,
      isActive: true,
      type: null,
      rejectRequestReason: null,
      status: null,
      loanApplicationId: null,
      borrowerLoanApplication: false,
      loanQuoteId: null,
    };
    this.isDealRoom = false;
    this.hasFastTrack = false;
    this.isFastTrackPaymentComplete = false;
    this.disableSubmitButton = false;
  }

  setIsNewEntity = isNewEntity => {
    this.isNewEntity = isNewEntity;
  };

  private calculateBorrowerPercentages = () => {
    return this.selectBorrowersStore.borrowers.reduce(
      (total, current) => total + current.pctOwnership ?? 0,
      0
    );
  };

  public checkAllErrors = () => {
    this.allErrors = [];

    const pointsError = this.checkPoints();
    if (pointsError) this.allErrors.push('Broker points cannot exceed total lender points.');

    if (isEmptyObject(this.entity)) {
      this.allErrors.push('Please select an entity or create a new one.');
    }
    if (this.selectBorrowersStore.borrowers.length === 0) {
      this.allErrors.push('Please select a borrower or add a new one.');
    }
    if (this.properties.length === 0) {
      this.allErrors.push('Please add a new property.');
    }
    if (this.loanTerms.amount === 0) {
      this.allErrors.push('Total loan amount must be greater than 0.');
    }
    if (
      this.properties.some(p =>
        notLicensedinState.states.includes(p.state?.toUpperCase())
      )
    ) {
      this.allErrors.push(notLicensedinState.message);
    }
    if (!this.loanInformationStore.otherTitleFormStore.form.meta.isValid && this.loanTerms.preferredTitle === 'Other') {
      this.allErrors.push('Please fill out all the info for Preferred Title Company.');
    }
    if (this.globalStore.lenderInfo?.showTPOLicenseValidation && this.properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
      !this.globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) &&
      this.loanInformationStore.licenseUpload.length == 0) {
      this.allErrors.push('Please upload the corresponding state license.');
    }

    //Multifamily Bridge
    if (this.isMultifamilyBridge) {
      if (this.loanTerms.amount < 500000) {
        this.allErrors.push(
          'Total loan amount for multi family Bridge loan must be greater than or equal to $500,000.'
        );
      }
      if (this.loanTerms.initialLoanToPurchase > 80) {
        this.allErrors.push(
          'Total loan amount for multi family Bridge loan must not be greater than 80% of purchase price'
        );
      }
      if (this.properties.findIndex(property => property.useCode === '5Plus Unit Multifamily') > -1 && this.properties.findIndex(property => property.units < 5) > -1) {
        this.allErrors.push(
          'Unit Count for 5+ Unit Multifamily Bridge loan must be 5+'
        );
      }
    }
    //Multifamily Bridge

    const allowLenderUnderwritingFee = this.globalStore.userFeatures?.allowLenderUnderwritingFee;
    if (allowLenderUnderwritingFee && this.userStore.allowLoanPricing) {
      const error = this.checkLenderUnderwritingFeeValidations();
      if (error) {
        this.allErrors.push(error);
      }
    }

    this.allWarnings = [];
    if (this.calculateBorrowerPercentages() !== 100) {
      this.allWarnings.push(
        'The total percent ownership for all borrowers should add up to 100. If you are not sure, you can submit the loan but provide this as soon as possible.'
      );
    }
    if (this.entity.operatingAgreementDate) {
      const diff = differenceInYears(
        startOfToday(),
        parse(this.entity.operatingAgreementDate, 'MM/dd/yyyy', new Date())
      );
      if (diff > 1) {
        this.allWarnings.push(
          'Certificate of Good Standing is needed within 90 days of closing. These can be ordered on the applicable county/state website'
        );
      }
    }
    if (this.properties.some(p => p.state !== this.entity.state)) {
      this.allWarnings.push(
        'The borrower must obtain a foreign entity status in the state of the collateral. These can be ordered on the applicable county/state website'
      );
    }

    if (this.isMultifamilyBridge && this.isConstructionHoldbackAndLTFC()) {
      this.allWarnings.push(
        'The loan is lopsided (Initial loan amount < Construction Holdback) and over 93% of cost. The initial loan to cost is too high.'
      );
    }
    this.propertiesRows.some(p => {
      if (!p.tertiarySubmarket) {
        this.allErrors.push(
          `For Property ${p.streetNumber + ' ' + p.streetName + ' ' + p.state
          } is ineligible since it is located in a tertiary submarket`
        );
      }
    });
  }

  private checkStepErrors = (currentStep) => {
    this.checkAllErrors();
    switch (currentStep) {
      case LoanSubmissionStep.LOAN_DETAILS: {
        this.loanInformationStore.runFormValidation();
        if (!this.loanInformationStore.form.meta.isValid) {
          return true;
        }

        this.stepError = this.checkPoints();
        if (this.stepError) {
          return true;
        }


        const allowLenderUnderwritingFee = this.globalStore.userFeatures?.allowLenderUnderwritingFee;
        if (allowLenderUnderwritingFee && this.userStore.allowLoanPricing) {
          this.stepError = this.checkLenderUnderwritingFeeValidations();
          return !!this.stepError;
        }

        else {
          this.stepError = '';
          return false;
        }
      }
      default: {
        this.stepError = '';
        return false;
      }
    }
  };

  checkLenderUnderwritingFeeValidations() {
    if (this.loanTerms.amount < 500000 && this.loanTerms.lenderUnderwritingFee < 595) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $595.';
    }
    if (this.loanTerms.amount >= 500000 && this.loanTerms.amount < 1000000 && this.loanTerms.lenderUnderwritingFee < 895) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $895.';
    }
    if (this.loanTerms.amount >= 1000000 && this.loanTerms.amount < 2000000 && this.loanTerms.lenderUnderwritingFee < 1295) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,295.';
    }
    if (this.loanTerms.amount >= 2000000 && this.loanTerms.amount < 3000000 && this.loanTerms.lenderUnderwritingFee < 1995) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,995.';
    }
    if (this.loanTerms.amount >= 3000000 && this.loanTerms.amount < 4000000 && this.loanTerms.lenderUnderwritingFee < 3495) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $3,495.';
    }
    if (this.loanTerms.amount >= 4000000 && this.loanTerms.amount < 5000000 && this.loanTerms.lenderUnderwritingFee < 4495) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $4,495.';
    }
    return '';
  }

  checkPoints() {
    const isBrokerUser = this.userStore.userInformation?.roles?.includes(Roles.BROKER);
    const brokerPoints = this.loanTerms.brokerPoints;
    const lenderPoints = this.loanTerms.points;

    if (isBrokerUser && brokerPoints > 2) {
      return 'Broker points must not exceed 2%.'
    } else {
      return brokerPoints > lenderPoints
        ? 'Broker points cannot exceed total lender points.'
        : '';
    }
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  *validateEntityErrors() {
    try {
      const data = this.loanSubmitJson;
      if (!this.entity.borrowerEntityId) {
        return;
      }
      const response: ApiResponse = yield this.loanService.getValidateEntityLoanErrors(
        this.entity.borrowerEntityId
      );
      const validateErrors = response.data.data.errors;
      this.allErrors = [...this.allErrors, ...validateErrors];
    } catch (error) {
      console.log(error);
    }
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    return {
      ...borrower,
      personalGuarantor:
        borrower.personalGuarantor ?? null,
    };
  }

  moveToStep = (step, currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.selectBorrowersStore.borrowerAccordionStore.saveBorrowersFromAccordion();
    }
    this.checkStepErrors(currentStep);
    this.activeStep = step;
  };

  goPrevStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    this.checkStepErrors(currentStep);
    this.activeStep--;
  };

  goNextStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    if (!this.checkStepErrors(currentStep)) {
      this.moveToStep(this.activeStep, currentStep);
    }
    if (this.stepError) {
      this.globalStore.notificationStore.showErrorNotification({
        message: this.stepError,
      });
    }
  };

  *loadStore() {
    if (isNil(this.draftLoanInfo.draftLoanId)) {
      this.reset();
    } else {
      this.getDraftLoan();
    }
    //this.loanInformationStore.getLenderAttorneys();
    this.loanInformationStore.getWimbaStates();
    this.loanInformationStore.getAllTertiaryMarketZipCode();

    if (this.loanInformationStore.loanParticipantsStore.isFacoOriginator) {
      this.loanInformationStore.getLoanOriginators();
    } else {
      this.loanInformationStore.loanParticipantsStore.fetchLoanParticipants();
    }
    if (this.globalStore.userFeatures.isBroker) {
      yield this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
      yield this.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(
        this.brokersStore.selectBrokersStore.brokers[0].brokerId
      );
    }
  }

  reset() {
    this.setDefaults();
    this.entityStore.resetStore();
    this.borrowerFormStore.reset();
    this.propertyStore.reset();
    this.loanInformationStore.resetLoanInformation();
    this.brokersStore.reset();
    this.selectBorrowersStore.reset();
    if (this.globalStore.userFeatures.isBroker) {
      this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
    }
    this.loanInformationStore.loanParticipantsStore.reset();
  }

  setLoanType(loanSubType: LoanSubType) {
    this.loanSubtype = loanSubType;
    this.loanInformationStore.resetLoanInformation();
  }

  setSelectedEntity(entity, isSelected) {
    if (isSelected) {
      this.selectedEntities = [...this.selectedEntities, entity];
    } else {
      const index = this.selectedEntities.findIndex(
        selectedEntity =>
          selectedEntity.borrowerEntityId === entity.borrowerEntityId
      );
      this.selectedEntities.splice(index, 1);
    }
  }

  setExistingEntity() {
    const selectedEntity = { ...this.selectedEntities[0] };
    if (!selectedEntity.name) return;

    let initialBorrowers = [];
    const _borrowers = [];
    for (const option of selectedEntity.borrowers) {
      const borrower = this.setBorrowerDefaults({
        ...option.borrower,
        borrowerId: option.borrower.borrowerId,
        pctOwnership: option.pctOwnership ?? 0,
        roleInTransaction: '',
        firstName: option.borrower.firstName,
        lastName: option.borrower.lastName,
        cellPhone: option.borrower.cellPhone,
        emailAddress: option.borrower.emailAddress,
      });
      _borrowers.push(borrower);
    }

    this.rawEntity = { ...selectedEntity };

    this.setEntity(selectedEntity, [...initialBorrowers, ..._borrowers]);
  }

  setDefaultEntity() {
    const _borrowers = [];
    this.entity = {
      name: '',
      type: 'Limited Liability Company',
      borrowers: _borrowers,
      loanApplicationUuid: generateUUID()
    };
    this.setBorrowers(_borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  setEntity(entity, borrowers = []) {
    this.entity = {
      borrowerEntityId: entity.borrowerEntityId,
      name: entity.name,
      ein: entity.ein,
      type: entity.type,
      operatingAgreementDate: entity.operatingAgreementDate,
      address: entity.address,
      streetNumber: entity.streetNumber,
      streetName: entity.streetName,
      city: entity.city,
      state: entity.state,
      zipCode: entity.zipCode,
      loanApplicationUuid: entity.loanApplicationUuid ?? generateUUID(),
      borrowers,
    };
    this.setBorrowers(borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  deleteEntity = () => {
    this.entity = {};
    this.rawEntity = {};
    this.setBorrowers([]);
    this.isNewEntity = false;
    this.selectedEntities = [];
  };

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  addProperty = property => {
    this.properties.push(property);
    this.loanInformationStore.setDefaultPreferredTitle();
    this.checkStepErrors(LoanSubmissionStep.PROPERTY_INFORMATION);
  };

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
    this.loanInformationStore.setDefaultPreferredTitle();
  };

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
    if (!this.properties.length) {
      this.loanInformationStore.reset();
    }
    this.loanInformationStore.setDefaultPreferredTitle();
  };

  setLoanTerms = loanTerms => {
    this.loanTerms = { ...loanTerms };
  };

  setSaved = (value: boolean) => {
    this.saved = value;
  };

  setDraftLoanId = (value: number) => {
    this.draftLoanInfo.draftLoanId = value;
  };

  isConstructionHoldbackAndLTFC = () => {
    return (
      this.loanTerms.constructionHoldback > this.loanTerms.initialLoanAmount &&
      this.getPropertiesLTFC() > 93
    );
  };

  getPropertiesLTFC = () => {
    this.loanInformationStore.makeInitialCalculations();
    const futureCost =
      this.loanTerms.totalPurchasePrice + this.loanTerms.totalRenovationBudget;
    return futureCost ? (this.loanTerms.amount / futureCost) * 100 : 0;
  };

  get prepaymentPenalty() {
    switch (this.loanTerms.duration) {
      case 12:
        return 9;
      case 18:
        return 12;
      case 24:
        return 18
      default:
        return 3;
    }
  };

  *log(data, additionalInfo) {
    try {
      yield this.loanService.log(data, additionalInfo);
    } catch (error) {
      console.log(error);
    }
  }

  get loanSaveJson() {
    const {
      borrowerEntity,
      borrowers,
      properties,
      exceptionMessage,
      ...rest
    } = this.loanSubmitJson;

    return {
      ...rest,
      exceptionMessage: exceptionMessage?.length > 0 ? exceptionMessage.join(',') : '',
      borrowerEntityObj: {
        ...borrowerEntity,
      },
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...borrowers],
        },
      },
      propertiesMap: {
        rows: [...properties],
      },
    };
  }
  setInsuranceDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === ELMSURE || this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === null) {
      return { insuredThroughElmsure: true };
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany)
    }
  }

  setPreferredInsuranceBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === ELMSURE) {
      return ELMSURE;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setPreferredTitleBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return WIMBA;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setTitleDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return null;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore?.brokerVendorClosingSettings?.preferredTitleCompany)
    }
  }

  get loanSubmitJson() {
    const isElmsure = this.loanTerms.preferredInsurance === ELMSURE;
    const isWimba = this.loanTerms.preferredTitle === WIMBA;
    return {
      loanType: this.loanType,
      broker: this.brokersStore.selectBrokersStore.brokers[0],
      loanSubtype: this.loanSubtype,
      loanPurpose: this.loanPurposeValue,
      lenderParticipation: this.loanTerms.lenderParticipation,
      pledgeOfShares: true,
      submitAppraisalWithTamarisk: false,
      fullPrincipalInterest: this.loanTerms.fullPrincipalInterest,
      initialLoanAmount: this.loanTerms.initialLoanAmount,
      initialLoanToPurchase: this.loanTerms.initialLoanToPurchase,
      constructionHoldback: this.loanTerms.constructionHoldback,
      constructionHoldbackPercentage: this.loanTerms
        .constructionHoldbackPercentage,
      rate: this.loanTerms.rate,
      rocRate: this.loanTerms.rate,
      interestMethod: 'Actual/360',
      points: this.loanTerms.points,
      pointsIn: this.loanTerms.points,
      pointsOut: this.loanTerms.exitFees,
      rocPointsIn: this.loanTerms.rocPointsIn,
      rocPointsOut: this.loanTerms.capitalProviderExitFees,
      brokerPoints: this.loanTerms.brokerPoints,
      brokerPointsIn: this.loanTerms.brokerPoints,
      lenderOriginatorId: this.getLenderOriginatorId(this.loanTerms.loanOriginator),
      brokerPointsOut: null,
      interestReserveMonths: this.loanTerms.interestReserveMonths,
      duration: this.loanTerms.duration,
      prepayPenaltyMonths: this.prepaymentPenalty,
      origLenderSubordination: this.loanTerms.origLenderSubordination,
      attorneyEmail: this.globalStore.userFeatures.isBroker ? this.loanInformationStore.getPreferredAttorney()
        ?.value : this.loanTerms.preferredClosingAttorney,
      lenderAttorneySelectedName: this.loanInformationStore.getPreferredAttorney()
        ?.label,
      preferredInsurance: this.globalStore.userFeatures.isBroker ? this.setPreferredInsuranceBasedOnBrokerSettings() : this.loanTerms.preferredInsurance,
      requestedClosingDate: this.loanTerms.requestedClosingDate,
      amount: this.loanTerms.amount,
      sellAs: this.loanTerms.sellAs,
      referralFee: this.loanTerms.referralFee,
      loanInsuranceData:
        this.globalStore.userFeatures.isBroker ? this.setInsuranceDataBasedOnBrokerSettings() :
          this.loanTerms.preferredInsurance === 'Elmsure'
            ? { insuredThroughElmsure: true }
            : {
              ...this.loanTerms.otherInsuranceCompany,
            },
      preferredTitle: this.globalStore.userFeatures.isBroker ? this.setPreferredTitleBasedOnBrokerSettings() : this.loanTerms.preferredTitle
        ? this.loanTerms.preferredTitle
        : null,
      titleCompany: this.globalStore.userFeatures.isBroker ? this.setTitleDataBasedOnBrokerSettings() :
        !this.loanTerms.preferredTitle
          ? null
          : this.loanTerms.preferredTitle !== WIMBA
            ? {
              ...this.loanTerms.otherTitleCompany,
            }
            : null,
      emailsForCreditBackgroundCheckRequests: this.borrowerCreditRequests?.join(
        ','
      ),
      loanSubmittedByMailId: this.userStore.userInformation?.username,
      isSubmitted: true,
      borrowerEntity: this.borrowerEntityObj,
      borrowers: [...this.borrowersRows],
      properties: [...this.propertiesRows],
      draftLoanId: !(this.loanTerms.fastTrack === 'Y') ? this.draftLoanInfo.draftLoanId :
        this.loanInformationStore.draftLoanId,
      underwritingFee: this.loanTerms.lenderUnderwritingFee,
      outOfBox: this.allOutOfBoxWarnings.length > 0,
      exceptionMessage: this.allOutOfBoxWarnings,
      pricingModel: this.pricingModelValues,
      utility: Utility.LOAN_INFORMATION,
      fastTrack: this.loanTerms.fastTrack === 'Y',
      fastTrackOptions: {
        ...this.loanTerms.fastTrackOptions,
        insuranceReview: !isElmsure,
        titleSearch: isWimba,
        insurance: isElmsure,
        titleAttorneySearch: !isWimba,
        properties: this.properties.length,
        propertiesAddresses: this.properties?.map(property => property.address).join(', ')
      },
      freeAppraisalPromotion: this.loanTerms.freeAppraisalPromotion === 'Y',
    };
  }

  get loanPurposeValue() {
    const purchaseIdx = this.properties.findIndex(property => property.propertyOwnership === 'Purchase');
    if (purchaseIdx > -1) {
      return 'Purchase';
    }
    const cashOutIdx = this.properties.findIndex(property => property.cashOut === 'Y');
    if (cashOutIdx > -1) {
      return 'Cash Out Refi';
    }
    return 'Refi';
  }

  getLenderOriginatorId(selectedLenderOriginatorId) {
    return this.globalStore.userFeatures?.isLenderOriginator ? this.userStore.userInformation?.userId : selectedLenderOriginatorId;
  }

  get borrowerEntityObj() {
    return {
      borrowerEntityId: this.entity.borrowerEntityId,
      name: this.entity.name,
      ein: this.entity.ein,
      type: this.entity.type,
      operatingAgreementDate: this.entity.operatingAgreementDate,
      address: this.entity.address,
      city: this.entity.city,
      zipCode: this.entity.zipCode,
      streetName: this.entity.streetName,
      state: this.entity.state,
      streetNumber: this.entity.streetNumber,
      loanApplicationUuid: this.entity.loanApplicationUuid
    };
  }

  get borrowersRows() {
    const defaultFormValues = this.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo.restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: this.getValues(borrower.roleInTransaction),
        personalGuarantor: borrower.personalGuarantor,
        experience: borrower.experience ?? defaultFormValues.experience,
        pctOwnership:
          borrower.pctOwnership ?? defaultFormValues.pctOwnership,
        citizenshipStatus:
          borrower.citizenshipStatus || defaultFormValues.citizenshipStatus,
        hasSubstantialChanges:
          borrower.hasSubstantialChanges ??
          defaultFormValues.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        OwnershipNotKnown:
          borrower.ownershipNotKnown ?? defaultFormValues.ownershipNotKnown, // Compatiblity with eportal
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
        ficoProvidedAtOrigination: defaultFormValues.borrowerCreditScore,
        liquidity: defaultFormValues.liquidity,
        loanApplicationUuid: borrower.loanApplicationUuid,
        promoCode: borrower?.promoCode,
        promoCodeDate: borrower?.promoCodeDate
      };
    });
  }

  get propertiesRows() {
    return this.properties.map(property => ({
      propertyId: property.appraisalId ? property.propertyId : null,
      address: property.address,
      streetNumber: property.streetNumber,
      streetName: property.streetName,
      aptNumber: property.aptNumber,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      latitude: property.latitude,
      longitude: property.longitude,
      exitStrategy: property.exitStrategy,
      propertyOwnership: property.propertyOwnership,
      propertySourcing: property.propertySourcing,
      purchasePrice: property.purchasePrice,
      purchaseDate: property.purchaseDate, //TODO: Need formatting as mm/dd/yyyy ??
      totalRenovationBudget: property.totalRenovationBudget,
      renovationBudget: Math.max(
        0,
        property.totalRenovationBudget - property.sunkCost
      ),
      describeRenovation: this.getValues(property.describeRenovation),
      useCode: property.useCode,
      armsLength: this.getBoolean(property.armsLength),
      armsLengthComment: property.armsLengthComment,
      anyDebt: this.getBoolean(property.anyDebt),
      asIsValue: property.asIsValue,
      afterRepairValue: property.afterRepairValue,
      refiAmount: property.refiAmount,
      propertySourcingExplanation: property.propertySourcingExplanation,
      additionalComments: property.additionalComments,
      redFlagComments: property.redFlagComments,
      propertyAppraisalId: property.appraisalId,
      submitRushedAppraisal: property.submitRushedAppraisal,
      wholesaleAmount:
        property.wholesaleAmount &&
        property.purchasePrice &&
        property.purchasePrice - property.wholesaleAmount,
      wholesalerPurchase: property.wholesalerPurchase,
      sunkCost: property.sunkCost || 0,
      units: property.units,
      residentialUnits: property.residentialUnits,
      commercialUnits: property.commercialUnits,
      residentialUnitsSqFtArea: property.residentialUnitsSqFtArea,
      commercialUnitsSqFtArea: property.commercialUnitsSqFtArea,
      residentialIncome: property.residentialIncome,
      commercialIncome: property.commercialIncome,
      monthlyGrossRent: property.monthlyGrossRent,
      annualTaxes: property.annualTaxes,
      annualInsurance: property.annualInsurance,
      annualHOA: property.annualHOA,
      annualUtilities: property.totalAnnualUtilities,
      totalAnnualRepairsMaintenance: property.totalAnnualRepairsMaintenance,
      totalAnnualPropertyManagementFees: property.totalAnnualPropertyManagementFees,
      generalAdministrative: property.generalAdministrative,
      payroll: property.payroll,
      marketing: property.marketing,
      replacementReserves: property.replacementReserves,
      cashOut: property.cashOut,
      tertiarySubmarket: this.getTertiaryMarketForProperty(property.zipCode),
      currentOccupancy: property.currentOccupancy,
      nsfrLoanPropertyFields: {
        ...primaryPropertyTemplate.nsfrLoanPropertyFields,
        annualTaxes: property.annualTaxes,
        annualInsurance: property.annualInsurance,
        annualHOA: property.annualHOA,
        annualUtilities: property.totalAnnualUtilities,
        monthlyGrossRent: property.monthlyGrossRent,
        annualRepairsAndMaintenance: property.totalAnnualRepairsMaintenance,
        annualAdministrativeExpense: property.generalAdministrative,
        annualPropertyManagementFee: property.totalAnnualPropertyManagementFees,
        annualPayrollExpense: property.payroll,
        annualMarketingExpense: property.marketing,
        annualReplacementReserve: property.replacementReserves,
        annualGrossRent:
          property.monthlyGrossRent && property.monthlyGrossRent * 12,
      },
      loanApplicationUuid: property.loanApplicationUuid
    }));
  }

  get borrowerCreditRequests() {
    return this.selectBorrowersStore.borrowers
      .filter(borrower => borrower.creditBackgroundCheck)
      .map(borrower => borrower.emailAddress);
  }

  get isMultifamilyBridge() {
    return this.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;
  }

  isRawEntityValue(field) {
    return this.rawEntity[field];
  }

  getBoolean = (value: string) => {
    return value === 'Y';
  };

  getTertiaryMarketForProperty = (value: string) => {
    return this.loanInformationStore.tertiaryMarketZipCodes.includes(value);
  };

  getValues = (options: any[]) => {
    const values = options ? options.map(option => option.value) : [];
    return values.join(',');
  };

  handleRenovationBudgeChanged = (value: number) => {
    if (!this.isMultifamilyBridge) return;

    const {
      purchasePrice,
    } = this.propertyStore.purchaseInformationStore.getFormValues();
    if (value > purchasePrice / 2) {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The Renovation budget should not be greater than 50% of the Purchase price.',
      });
    }
  };

  *onLoanSave(type: string, showSuccessNotification = true) {
    try {
      const loan = this.loanSaveJson;
      const draftLoan = {
        content: JSON.stringify(loan),
        draftLoanId: this.draftLoanInfo.draftLoanId,
        createdBy: this.draftLoanInfo.createdBy,
        creationDate: this.draftLoanInfo.creationDate
          ? format(
            new Date(this.draftLoanInfo.creationDate),
            'MM/dd/yyyy HH:mm:ss.SSS'
          )
          : null,
        isActive: this.draftLoanInfo.isActive,
        type: type,
        rejectRequestReason: this.draftLoanInfo.rejectRequestReason,
        status: this.draftLoanInfo.status,
        brokerId: this.globalStore.userFeatures.isBroker ? this.brokersStore.selectBrokersStore.brokers[0].brokerId : null,
        lenderOriginatorId: loan.lenderOriginatorId,
      };
      if (this.allErrors.length > 0 && type === REQUEST_LOAN_TYPE_LOAN) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(draftLoan, 'draft loan');
        const createDocuments = this.globalStore.userFeatures.uploadDuringSubmissionEnabled;
        const response: ApiResponse = yield this.loanService.saveDraftLoan(
          draftLoan,
          createDocuments
        );
        if (response.data) {
          this.draftLoanInfo.draftLoanId = response.data?.data?.id;
          if (showSuccessNotification) {
            this.globalStore.notificationStore.showSuccessNotification({
              message: 'Loan has been saved successfully.',
            });
          }
        }
        type == REQUEST_LOAN_TYPE_LOAN
          ? (this.requestSaved = true)
          : (this.requestSaved = false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan.',
      });
    }
  }

  *getDraftLoan() {
    try {
      const response: ApiResponse = yield this.loanService.getDraftLoan(
        this.draftLoanInfo.draftLoanId
      );
      this.reset();
      const data = response?.data?.data;
      this.draftLoanInfo = data;
      const draftLoan = JSON.parse(data.content);
      this.mapDraftLoanToStore(draftLoan);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draft loan.',
      });
    }
  }

  mapDraftLoanToStore(draftLoan: any) {
    const { loanType, loanSubtype } = draftLoan;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntity(draftLoan);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(draftLoan, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

    this.properties = getBridgeProperties(draftLoan);
    this.loanTerms = getBridgeLoanTerms(draftLoan);

    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(
      this.loanTerms.otherInsuranceCompany
    );
    this.loanInformationStore.otherTitleFormStore.loadForm(
      this.loanTerms.otherTitleCompany
    );
    if (draftLoan.broker) {
      const { broker } = draftLoan;
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker,
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
  }

  *onLoanSubmit() {
    try {
      const data = this.loanSubmitJson;
      this.disableSubmitButton = true;
      const isInternal = this.globalStore.lenderInfo?.isInternal;
      this.log(data, 'submit bridge loan');
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEvent.MFB_SUBMISSION,
        userName: this.userStore?.userInformation?.username
      });
      const response: ApiResponse = yield this.loanService.submitBridgeLoan(
        data
      );

      if (response.data?.data?.success === false) {
        const additionalDescription =
          '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
        let errorMessage = response.data?.data?.responseMessage;
        this.sendOtherValidationEmailError(errorMessage);
      } else {
        this.savedLoanId = response.data?.data?.loanId;
        if (this.loanInformationStore.form.fields.fastTrack.value === 'Y') {
          const fastTrackInfo = yield this.loanService.getLoanDetails(this.savedLoanId.toString());
          this.hasFastTrack = true;
          this.isFastTrackPaymentComplete = fastTrackInfo.data?.data?.fastrackPaymentComplete;
        }
        this.saved = true;
      }
    } catch (error) {
      this.saved = true;
      this.sendLoanSubmissionErrorEmail(error?.error?.response?.data?.error?.message || error?.error?.message || error);
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return canSubmitAppraisalWithTamarisk(
      this.loanSubtype,
      this.globalStore.userFeatures
    );
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.loanService.sendErrorEmail(
      'Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  *validateOutOfBoxLoan() {
    try {
      const data = this.loanSubmitJson;
      const response: ApiResponse = yield this.loanService.getOutOfBoxWarnings(
        data
      );
      const validateOutOfLoanWarnings = response.data.data.errors;
      const additionalWarnings = response.data.data.warnings;
      this.allOutOfBoxWarnings = validateOutOfLoanWarnings;
      this.allWarnings = [...this.allWarnings, ...additionalWarnings];
      this.getPricingModel()
    } catch (error) {
      this.log('Error Retrieving Out of Box Warnings', error);
    }
  }

  *getPricingModel() {
    try {
      const data = this.loanSubmitJson;
      const response: ApiResponse = yield this.loanService.getMultifamilyPricingModel(
        data
      );
      this.pricingModelValues = response.data;
      this.loanTerms = ({ ...this.loanTerms, rate: this.pricingModelValues.rate, rocRate: this.pricingModelValues.rate }
      )
    } catch (error) {
      this.log('Error Retrieving Pricing Model', error);
    }
  }

  *sendFastTrackPayment() {
    yield this.loanInformationStore.requestFastTrackPaymentEmail({
      loanId: this.loanInformationStore.draftLoanId,
      borrowers: [...this.borrowersRows],
    });
  }

  setRushedAppraisalProperties(value) {
    this.properties = this.properties.map(prop => ({ ...prop, submitRushedAppraisal: value }));
  }

  get isLoanRequest() {
    return this.draftLoanInfo?.type === REQUEST_LOAN_TYPE_LOAN;
  }

  *validateBorrowerErrors() {
    try {
      if (this.borrowersRows.length > 0) {
        const response: ApiResponse = yield this.loanService.getValidateBorrowerLoanErrors(
          [...this.borrowersRows]
        );
        const validateErrors = response.data.data.errors;
        this.allErrors = [...this.allErrors, ...validateErrors];
      }
    } catch (error) {
      console.log(error);
    }
  }

  sendOtherValidationEmailError(errorMessage: any) {
    if (errorMessage !== LOAN_WITHOUT_LEAD_SOURCE_VALIDATION) {
      errorMessage += `. For ${this.globalStore.lenderInfo.companyName} with lender id ${this.globalStore.lenderInfo.lenderId}.`;
      this.sendLoanSubmissionErrorEmail(errorMessage);
    }
  }

  get isInternal() {
    return this.globalStore.lenderInfo?.isInternal;
  }
}
