import { action, flow, makeObservable, observable } from 'mobx';
import { GlobalStore } from '../../../stores';
import { ExternalLenderStore } from '../../companyProfile/stores/externalLenderStore';
import { tabIndex } from '../utils/constants';
import { LenderContactsStore } from './lenderContactsStore';
import { LenderDetailsFormStore } from './lenderDetailsFormStore';
import { LenderDocumentsStore } from './lenderDocumentsStore';
import { segmentAnalytics } from '@roc/feature-utils';

export class LenderDetailsStore {
  protected globalStore: GlobalStore;

  salesforceLenderStore: ExternalLenderStore;
  lenderDetailsFormStore: LenderDetailsFormStore;
  lenderContactsStore: LenderContactsStore;
  lenderDocumentsStore: LenderDocumentsStore;
  selectedBrokerProspect: number;
  isAddBrokerModalOpen: boolean;
  isAddBrokerAsTPOModalOpen: boolean;
  selectedBrokerOwner: any;

  selectedTabIndex = 0;
  RESTRICTED_STATUSES = ['Approved', 'Committee Decision'];
  constructor(globalStore, salesforceLenderStore) {
    this.globalStore = globalStore;
    this.salesforceLenderStore = salesforceLenderStore;

    this.lenderDetailsFormStore = new LenderDetailsFormStore(
      globalStore,
      salesforceLenderStore
    );
    this.lenderContactsStore = new LenderContactsStore(globalStore);
    this.lenderDocumentsStore = new LenderDocumentsStore(
      globalStore,
      this.lenderDetailsFormStore
    );

    this.globalStore = globalStore;

    makeObservable(this, {
      selectedTabIndex: observable,
      setSelectedTabIndex: flow,
      previousTab: flow,
      nextTab: flow,
      initializeForm: flow,
      initializeLenderOnboardingForm: flow,
      submitForm: flow,
      validateAndUpdateLender: flow,
      updateEntityInformation: flow,
      updateRMLenderInformation: flow,
      validateRMorPercentageChangedForCommissions: action,
      updateCommissions: flow,
      validationsForSavingLender: flow,
      saveTamariskInformation: flow,
      isAddBrokerModalOpen: observable,
      setAddBrokerRelationModalOpen: action,
      addLenderBrokerRelation: flow,
      selectedBrokerOwner: observable,
      isAddBrokerAsTPOModalOpen: observable,
      setAddBrokerAsTPOModalOpen: action,
      addBrokerAsTPO: flow,
      saveHighValueClientValue:flow
    });
  }

  *setSelectedTabIndex(index) {
    yield this.validateAndUpdateLender(index, false);
  }

  *validateAndUpdateLender(nextTab, isSubmitting = false) {
    try {
      const currentTab = this.selectedTabIndex;

      if (currentTab == tabIndex.TAB_COMPANY_DETAILS) {
        this.lenderDetailsFormStore.runFormValidationWithMessage();
        if (!this.lenderDetailsFormStore.form.meta.isValid) {
          return;
        }
      }
      yield this.lenderDetailsFormStore.saveSalesforceLenderChanges(
        isSubmitting
      );

      if (isSubmitting) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Lender details submitted.',
        });
      }
      if (nextTab !== null) {
        this.selectedTabIndex = nextTab;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating lender details.',
      });
    }
  }

  validateRMorPercentageChangedForCommissions() {
    const changed = this.lenderDetailsFormStore.validateRMorPercentageChanged();
    return changed;
  }

  *updateCommissions() {
    try {
      yield this.lenderDetailsFormStore.updateCommissions();
    } catch (e) {
      console.log('error', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating Loans Commissions details.',
      });
    }
  }

  *updateEntityInformation() {
    try {
      const validation = yield this.validationsForSavingLender();
      if (validation) {
        yield this.lenderDetailsFormStore.saveSalesforceLenderChanges(false);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Entity details saved.',
        });
      }
    } catch (e) {
      console.log('error', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating entity details.',
      });
    }
  }

  *updateRMLenderInformation() {
    try {

      yield this.lenderDetailsFormStore.saveSalesforceLenderChanges(false);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Entity details saved.',
      });
    } catch (e) {
      const { message } = e?.error?.response?.data?.error;
      this.globalStore.notificationStore.showErrorNotification({
        message:
          message ||
          'An error occurred when trying to assign a new owner for this record',
      });
    }
  }

  *previousTab() {
    yield this.setSelectedTabIndex(this.selectedTabIndex - 1);
  }

  *nextTab() {
    yield this.setSelectedTabIndex(this.selectedTabIndex + 1);
  }

  *initializeForm() {
    yield Promise.all([
      this.lenderDetailsFormStore.loadData(),
      this.lenderDetailsFormStore.getLenderBankInformation(),
      this.lenderDocumentsStore.loadData(),
    ]);
  }

  *initializeLenderOnboardingForm() {
    yield Promise.all([
      this.lenderDetailsFormStore.loadData()
    ]);
  }


  *submitForm() {
    yield this.validateAndUpdateLender(null, true);
  }

  *validationsForSavingLender() {
    try {

      if (this.portalValidation()) {

        const isDuplicateAlias = yield this.lenderDetailsFormStore.isDuplicateLenderAlias();
        if (isDuplicateAlias?.data?.data) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'This Alias is not valid, please choose a different one',
          });
          return false;
        }

        if (!(this.salesforceLenderStore.salesForceLender?.lenderName.length <= 10)) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Alias must be less or equal to 10 characters',
          });
          return false;
        }
      }

      return true;
    } catch (e) {
      console.log('error', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while validating lender.',
      });
      return false;
    }
  }

  portalValidation() {
    if (this.globalStore.isInternalPortal) {
      return this.RESTRICTED_STATUSES.includes(this.salesforceLenderStore.salesForceLender?.status);
    } else {
      return this.RESTRICTED_STATUSES.includes(this.salesforceLenderStore.salesForceLender?.status) && this.globalStore?.userFeatures?.backOfficeUser;
    }
  }

  saveTamariskInformation() {
    const lender = this.lenderDetailsFormStore.salesforceLenderStore.salesForceLender;

    const preference = {
      lenderId: lender.lenderId,
      tamariskSendInvoiceEmail: this.lenderDetailsFormStore.form.fields.tamariskSendInvoiceEmailModal.value,
      tamariskDefaultOption: this.lenderDetailsFormStore.form.fields.tamariskDefaultOptionModal.value,
      tamariskAppraisal: this.lenderDetailsFormStore.form.fields.tamariskAppraisalModal.value,
    }
    if (this.salesforceLenderStore.updateTamariskPreferences(preference)) {
      this.lenderDetailsFormStore.setTamariskValuesFromModal(preference)
    }
  }

  saveDesktopAppraisalPreferences() {
    const lender = this.lenderDetailsFormStore.salesforceLenderStore.salesForceLender;
    this.salesforceLenderStore.updateDesktopAppraisalPreferences(this.lenderDetailsFormStore.form.fields.desktopAppraisalPreference.value, lender.lenderId);
  }

  setAddBrokerRelationModalOpen(record: any, isOpen: boolean) {
    this.selectedBrokerProspect = record;
    this.isAddBrokerModalOpen = isOpen;

  }

  setAddBrokerAsTPOModalOpen(record: any, isOpen: boolean) {
    this.selectedBrokerProspect = record;
    this.isAddBrokerAsTPOModalOpen = isOpen;

  }

  *addLenderBrokerRelation(callback: () => void) {
    if (this.selectedBrokerOwner) {
      yield this.salesforceLenderStore.addLenderBrokerRelation(this.selectedBrokerOwner, this.selectedBrokerProspect);
      this.selectedBrokerOwner = null;
      callback();
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Select an owner for the broker relation'
      });
    }
  }

  *addBrokerAsTPO(callback: () => void) {
    if (this.selectedBrokerOwner) {
      yield this.salesforceLenderStore.turnBrokerIntoTPO(this.selectedBrokerOwner, this.selectedBrokerProspect);
      this.selectedBrokerOwner = null;
      callback();
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Select a relationship manager for the TPO'
      });
    }
  }

    *saveHighValueClientValue() {
    try {
        yield this.lenderDetailsFormStore.saveHighValueClientTPO();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'High Value Client details saved.',
        });
    } catch (e) {
      console.log('error', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating High Value Client details.',
      });
    }
  }
}
