import { Button, ConfirmDialog, FileUpload, FileUploadArea, IconButton, NewFileUploadArea, StandardDialog, VerticalBarLoader } from '@roc/ui';
import { observer } from 'mobx-react';
import { routeByLoanSubType, useLoanStore, useLoanSubmissionRoutes } from '@roc/feature-loans';
import { DraftLoan, Document as DocumentType } from '@roc/feature-types';
import { useHistory } from 'react-router';
import { LoanProcess, LoanSubType, UNDERWRITING } from '@roc/feature-utils';
import { TodoItem } from '../utils/loanDetailsTypes';
import { Box, CircularProgress, createStyles, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { DocumentName, restrictedAppraisalDocuments, useDocumentStore } from '@roc/feature-documents';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useGeneralTasksStore } from '@roc/feature-general-tasks';
import { ArrowDownward, ChatBubble, Delete, ArrowForward, GetApp, InsertDriveFile, Error, Forum, ForumOutlined } from '@material-ui/icons';
import { BannerMessages } from 'libs/feature-loans/src/loans/components/bannerMessages';
import { ChatSection, FilterType, MyChatContainer, SelectConversation, SimpleListSkeleton, useLoanCommunicationStore } from '@roc/feature-communication';
import { ChatContainer } from '@chatscope/chat-ui-kit-react';



interface Props {
  todo: TodoItem;
  expand: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    document: {
      width: 'fit-content',
      margin: '0 auto',
    },
    page: {
      margin: theme.spacing(2),
      boxShadow: '2px 2px 4px #888',
      width: 'fit-content',
    },
    chatContainer: {
      flex: 1,
      height: ''
    },
    createChatButton: {
      letterSpacing: 'normal',
      textTransform: 'none',
      fontWeight: 'normal',
      fontSize: '16px',
      backgroundColor: '#4285F4',
      width: '307px',
      height: '64px',
      padding: '8px 16px',
      boxShadow: '0px 4px 4px 0px #00000040',
      '&:hover': {
        backgroundColor: '#357AE8',
      },
    },
    createChatButtonContainer: {
      height: '75vh',
      border: '1px solid #CBCBCB',
      borderRadius: '10px',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    circleIconContainer: {
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
    }
  })
);

export const LineItemChat = observer(({
  todo, expand
}: Props) => {
  const classes = useStyles();
  const { generalTasksStore } = useGeneralTasksStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { documentStore } = useDocumentStore();
  const [scale, setScale] = useState(1.0);
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const [numPages, setNumPages] = useState(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showDocumentLoading, setShowDocumentLoading] = useState<boolean>(false);
  const [isPdfFile, setIsPdfFile] = useState<boolean>(false);
  const [hasClosingCondition, setHasClosingCondition] = useState<boolean>(documentStore.loanTodosStore.checkIfHasClosingCondition(todo.todoId));


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    syncUploads(todo.document)
    checkIfPdf()
    if (todo.document.canDownloadFile) {
      documentStore.previewDocumentV2(todo.document);
    }
    return () => {
      loanCommunicationStore.reset();
    };
  }, [])

  useEffect(() => {
    if (uploads[0] && uploads[0].file != null) {
      setShowDocumentLoading(true);
      documentStore.uploadDocument(uploads[0].file, todo.document, UNDERWRITING, () => {
        documentStore.previewDocumentV2(todo.document);
        setShowDocumentLoading(false);
        checkIfPdf();
      });
    }
  }, [uploads])

  useEffect(() => {
    if (!expand) {
      generalTasksStore.checkIfTaskRoomExist(todo.document.taskId, () => loadChat())
    }
  }, [expand])

  const loadChat = () => {
    setShowLoading(true)
    loanCommunicationStore.reset();
    loanCommunicationStore.setFilter({
      type: FilterType.TASK_ID,
      value: todo.document.taskId
    });
    if (loanCommunicationStore.isInternal) {
      loanCommunicationStore.getAllMessageSnippets();
    }
    loanCommunicationStore.loadMyConversations(1, 100, (totalCount, rows) => {
      setShowLoading(false);
      if (rows[0]) {
        loanCommunicationStore.setCurrentConversation(loanCommunicationStore.conversations[rows[0].conversationSid])
      }
    }, () => {
      setShowLoading(false);
    });
  }

  const createChat = (taskId) => {
    generalTasksStore.createTaskChatroom(taskId, () => {
      loadChat()
    });
  }

  const checkIfPdf = () => {
    if (uploads[0]) {
      if (uploads[0].name.split('.')[1] === 'pdf') {
        setIsPdfFile(true)
      } else {
        setIsPdfFile(false)
      }
    } else {
      if (todo.document.docType === 'pdf') {
        setIsPdfFile(true)
      } else {
        setIsPdfFile(false)
      }
    }
  }

  const syncUploads = (document: DocumentType) => {
    if (document.canDownloadFile) {
      const newItems = {
        name: document.originalFileName,
        file: null,
        type: document.docType
      };
      setUploads([newItems]);
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems="stretch" style={{ paddingTop: '0px' }}>
        {(!todo.document.isForm && !restrictedAppraisalDocuments.includes(todo.document.taskName)) && (
          <Grid item xs={expand ? 12 : 6} style={{ padding: 16, transition: 'all 0.3s ease' }}>
            {documentStore.documentPreviewV2 && uploads[0] &&
              <>
                {isPdfFile ?
                  <Box style={{
                    height: hasClosingCondition ? '55vh' : '67.9vh',
                    overflowY: 'auto',
                    border: '1px solid #CBCBCB',
                    borderRadius: '10px',
                    padding: '8px',
                    display: showDocumentLoading ? 'flex' : 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'all 0.5s ease',
                  }}>
                    {showDocumentLoading ? <CircularProgress size={32} /> :
                      <Document
                        file={documentStore.documentPreviewV2.data}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className={classes.document}
                      >
                        {numPages &&
                          [...Array(numPages)].map((x, i) => {
                            return (
                              <Page
                                className={classes.page}
                                pageNumber={i + 1}
                                scale={scale}
                              />
                            );
                          })}
                      </Document>
                    }
                  </Box>
                  :
                  <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" style={{
                    height: hasClosingCondition ? '55vh' : '67.9vh',
                    overflowY: 'auto',
                    border: '1px solid #CBCBCB',
                    borderRadius: '10px',
                    padding: '8px',
                    transition: 'all 0.5s ease',
                  }}>
                    <Error style={{ marginBottom: '5px', fontSize: '40px' }}></Error>
                    <Typography variant="h6" align='center'>
                      We are currently unable to show a preview of the document
                    </Typography>
                  </Box>
                }
              </>
            }
            <Box style={{
              marginTop: uploads[0] ? 16 : 0,
              transition: 'all 0.5s ease',
            }}>
              <NewFileUploadArea
                items={uploads}
                setItems={(items) => setUploads(items)}
                showUploadButton={todo.document.canUploadFile}
                multiple={false} />
            </Box>

            {hasClosingCondition &&
              <BannerMessages
                isInlineItem={true}
                messages={[
                  {
                    title: 'This line item must be completed for the loan to be funded.',
                    content: "Your loan will not be funded until this item is resolved.",
                  },
                ]}
              />
            }
          </Grid>

        )}

        {!expand &&
          <Grid item xs={(todo.document.isForm || restrictedAppraisalDocuments.includes(todo.document.taskName)) ? 12 : 6} style={{ padding: 16 }}>
            {generalTasksStore.taskRoomExist ?
              <Box height={'75vh'} style={{
                border: '1px solid #CBCBCB',
                borderRadius: '10px',
                padding: '8px',
              }}>
                <ChatSection
                  as={ChatContainer}
                  communicationStore={loanCommunicationStore}
                  allowJoinConversation={true}
                  allowLeaveConversation={true}
                  showSnippet={false}
                  showEmailAddress={false}
                  allowManageParticipants={true}
                  allowAddInternalParticipants={true}
                  allowAddParticipants={true}
                  allowRemoveParticipants={true}
                  onDownloadConversation={undefined}
                  onUnarchiveConversation={() => { }}
                  showLoading={showLoading}
                />
              </Box>
              :
              <Box className={classes.createChatButtonContainer}>
                <Button
                  testId="downloadAll"
                  onClick={() => createChat(todo.document.taskId)}
                  variant="contained"
                  className={classes.createChatButton}
                >
                  <Box display="flex" alignItems="center">
                    <Box className={classes.circleIconContainer}>
                      <ForumOutlined style={{ color: '#4285F4' }}></ForumOutlined>
                    </Box>
                    <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '8px', color: '#FFFFFF' }}>
                      Start Conversation
                    </Typography>
                  </Box>
                </Button>
                <Typography variant="body1" style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'normal' }}>
                  Contact us with any questions about this document.
                </Typography>
              </Box>
            }
          </Grid>
        }
      </Grid>
    </>
  );
});
