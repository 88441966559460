import { action, observable, flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore, } from '@roc/feature-app-core';
import { TaxService } from '../services/taxService';

export class TaxCertificateStore {
  globalStore: GlobalStore;
  service: TaxService;
  taxCertificateGridStore: GridStore;


  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.service = new TaxService();
    this.taxCertificateGridStore = new GridStore(() => this.fetchTaxCertificateDashboard(),
      null,
      50);
    makeObservable(this, {
      createTaxCertificate: flow,

    });
  };

  private async fetchTaxCertificateDashboard() {
    try {
      const filters = {
        ...this.taxCertificateGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.service.getTaxCertificateDashboard(
        this.taxCertificateGridStore.gridData.meta.pageNumber,
        this.taxCertificateGridStore.gridData.meta.pageSize,
        this.taxCertificateGridStore.gridData.meta.sortDir,
        this.taxCertificateGridStore.gridData.meta.sortBy,
        filters,
        this.taxCertificateGridStore.gridData.meta.dropdownFilters

      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching tax dashboard.',
      });
    }
  };

  *createTaxCertificate(loanId) {
    try {
      const response: ApiResponse = yield this.service.createTaxCertificate(loanId);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Order created successfully.'
        });
        this.taxCertificateGridStore.resetAndFetchGridData();
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.error?.message
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating tax certificate.',
      });
    }
  };

}
