import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropertyInformationAccordion } from './propertyInformationAccordion';
import { observer } from 'mobx-react';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import React, { useState } from 'react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { OneToolCardBorder } from '../../common/oneToolCard';

export interface PropertyInformationFormProps {
  store: PropertyFormStore;
  onDeleteClick: (propertyKey: string) => void;
  removeButtonDisabled?: boolean;
  showActions: boolean;
  quoteTypeStore: QuoteTypeStore;
}

interface PropertyInformationCardProps {
  store: QuoteTypeStore;
  propertyFormStore: PropertyFormStore;
  showActions?: boolean;
  PropertyInformationForm: React.FC<PropertyInformationFormProps>;
}

export const PropertyInformationCard = observer(
  ({
    store,
    propertyFormStore,
    showActions = true,
    PropertyInformationForm,
  }: PropertyInformationCardProps) => {
    const editMode = propertyFormStore.form.meta.editMode;
    const removeButtonDisabled =
      store.propertiesStore.propertyFormStores.length <= 1;

    const [expanded, setExpanded] = useState(false);

    const handleDelete = propertyKey => {
      store.propertiesStore.removeProperty(propertyKey);
    };

    const handleAcordionDelete = propertyKey => {
      store.propertiesStore.removePropertyInAccordion(propertyKey);
    };

    return (
      <OneToolCardBorder>
        {editMode && (
          <PropertyInformationForm
            store={propertyFormStore}
            quoteTypeStore={store}
            onDeleteClick={handleDelete}
            showActions={showActions}
            removeButtonDisabled={removeButtonDisabled}
          />
        )}
        {!editMode && (
          <PropertyInformationAccordion
            store={propertyFormStore}
            onDeleteClick={handleAcordionDelete}
            showActions={showActions}
            removeButtonDisabled={removeButtonDisabled}
            expanded={expanded}
            onExpand={expanded => setExpanded(expanded)}
            onEdit={() => propertyFormStore.setEditMode(true)}
          />
        )}
      </OneToolCardBorder>
    );
  }
);
