import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { createCurrencyField, createMultiSelectField, createNumberFormatField, createSelectField, createTextField } from "@roc/ui";
import { LiquidityReportStore } from "../stores";
import { stateOptions } from "libs/client-portal-shared/src/app/modules/bridgePricer/utils/bridgePricerConstants";

interface LiquidityReportField {
  store: LiquidityReportStore;
}

const field = component => {
  const WrappedComponent = observer(component);
  return props => (
    <Grid item xs={12}>
      <WrappedComponent {...props} />
    </Grid>
  );
};

const propertyTypeOptions = [
  {
    value: 'single family',
    label: 'Single Family',
  },
  {
    value: 'multi family',
    label:'Multi Family',
  },
  {
    value:'apartment',
    label:'Apartment',
  }
];

export const Email = field(({ store }: LiquidityReportField) => {
  return createTextField({
    store,
    label: 'Email',
    fieldName: 'email',
    testId: 'email',
    placeholder: 'Enter your email'
  });
});

export const Recipients = field(({ store }: LiquidityReportField) => {
  return createTextField({
    store,
    label: 'Recipients',
    fieldName: 'recipients',
    testId: 'recipients',
    placeholder: 'Other recipients (comma separated emails)'
  });
});

export const LiquidityId = field(({ store }: LiquidityReportField) => {
  return createTextField({
    store,
    label: 'Liquidity ID',
    fieldName: 'liquidity_id',
    testId: 'liquidity_id',
    placeholder: 'Identifier: loan ID, deal name, etc'
  });
});

export const Arv = field(({ store }: LiquidityReportField) => {
  return createCurrencyField({
    store,
    label: 'Arv (After Repair Value)',
    fieldName: 'arv',
    testId: 'arv'
  });
});

export const City = field(({ store }: LiquidityReportField) => {
  return createTextField({
    store,
    label: 'City',
    fieldName: 'city',
    testId: 'city'
  });
});

export const State = field(({ store }: LiquidityReportField) => {
  return createSelectField({
    store,
    label: 'State',
    fieldName: 'state',
    testId: 'state',
    options: stateOptions
  });
});

export const Zipcode = field(({ store }: LiquidityReportField) => {
  return createTextField({
    store,
    label: 'Zip Code(s)',
    fieldName: 'zipcodes',
    testId: 'zipcodes',
    placeholder: 'Comma separated zip codes'
  });
});

export const PropertyTypes = field(({ store }: LiquidityReportField) => {
  return createSelectField({
    store,
    label: 'Property Type',
    fieldName: 'property_type',
    testId: 'property_types',
    options: propertyTypeOptions,
  });
});

export const SqtFootage = field(({ store }: LiquidityReportField) => {
  return createNumberFormatField({
    store,
    label: 'Post renovation square footage',
    fieldName: 'sqftage',
    testId: 'sqftage'
  });
});