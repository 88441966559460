import { ButtonGroup, Checkbox, Chip, ClickAwayListener, FormControlLabel, Grid, InputAdornment, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { Slider as MuiSlider } from '@material-ui/core';
import { Theme, createStyles, lighten, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { CheckBox, CheckBoxOutlineBlank, Delete, Info, Edit, ReportProblem } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { EditText } from 'react-edit-text';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Paper } from '@material-ui/core';
import {
  BROKER_POINTS_OPTIONS,
  MULTIFAMILY_5_PLUS,
  PPP_0_NO_PREPAY,
  PURCHASE,
  Roles,
  insertIf,
  isNil,
  isNotBlank,
  loanTermRateTermMapping,
  prePaymentPenaltyOptionsSingleProperty
} from '@roc/feature-utils';
import {
  Button,
  RadioField,
  SelectField,
  Slider,
  IconButton,
  Tooltip,
  NumberFormat,
  TextField,
  CurrencyField,
  PercentageField,
  ConfirmDialog
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  amortizationOptions,
  amortizationTypeTooltip,
} from '../../rentalPricer/utils/constants';
import { RentalPortfolioStore, SinglePropertyStore, TermStore } from './../stores';

import { Alert, AlertTitle } from '@material-ui/lab';
import { useHeaderHeight, useUserStore } from '@roc/feature-app-core';
import { DownloadableQuotesDialog } from '../../rentalPricer/pricerSummary/components/downloadableQuoteDialog';
import { DSCR_EXPANDED, rateTypeToLoanTerm } from '../stores/common/pricerSummaryHelper';


import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionActions from '@material-ui/core/AccordionActions';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PointsFeesAllocationTable } from './pricerSummaryPointFeesAllocation';
import clsx from 'clsx';
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';
import { ClosingFeesTable } from './pricerSummaryClosingFeesTable';
import { CustomQuote, RateScenario } from '@roc/feature-types';
import { PricerRateSlider } from './pricerRateSlider';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useLoanSummaryStore } from '@roc/feature-loan-summary';

const useAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    chooseScenario: {
      '& .MuiFormControlLabel-root': {
        paddingRight: '8px',
        marginLeft: 0,
        marginTop: 8,
        '&:hover': {
          background: '#e4f3f7'
        },
        '&.isSelected': {
          background: '#bae2ef',
          '&:hover': {
            background: '#bae2ef'
          }
        },
      },
    },
    selectField: {
      '& .MuiSelect-root': {
        padding: '8px 32px 8px 8px'
      }
    },
    percentField: {
      '& .MuiInputBase-input': {
        padding: '8px 32px 8px 8px'
      }
    },
    editTextContainer: {
      '&>div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '& input': {
        textAlign: 'center'
      }
    },
    removeQuoteButton: {
      color: '#00BCD4',
      marginTop: 4,
      textTransform: 'unset',
      '& .MuiButton-startIcon': {
        marginRight: 4,
      }
    },
    youMakeContainer: {
      position: 'absolute',
      right: -1,
      top: -50,
      zIndex: 1
    },
    youMakeContainerBorder: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: -1,
      height: 51,
      border: '1px solid #e0e0e0',
      borderBottomWidth: 0,
      width: '100%',
      background: '#fff',
      '&.expanded': {
        boxShadow: '0px 0px 16px 0px #757373'
      }
    },
    youMakeContent: {
      padding: '8px 16px',
      margin: 1,
      background: '#d1f9d1',
    },
    validationError: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    borderBottomWidth: 0,
    marginBottom: '16px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '16px 0',
      borderBottomWidth: 1,
      boxShadow: '0px 0px 16px 0px #757373'
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    padding: '4px 16px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottomWidth: 0,
      background: '#fbfbfb'
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionActions = withStyles({
  root: {
    backgroundColor: '#fbfbfb',
  },
})(MuiAccordionActions);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: '#fbfbfb',
  },
})(MuiAccordionDetails);

const CustomSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  }
})(MuiSlider);


const options = [
  { label: 'On the HUD', value: true },
  { label: 'Off the HUD', value: false }
];

const pointFormatOptions = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

interface DetailedAccordionProps {
  store: SinglePropertyStore | RentalPortfolioStore;
  setShowLoanRatesDialog: (value: boolean) => void;
  onNext?: () => void;
}

const DetailedAccordion = observer(({ store, setShowLoanRatesDialog, onNext = () => { } }: DetailedAccordionProps) => {
  const accordionClasses = useAccordionStyles();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const { pricerSummaryStore, pricerStore, userStore } = store;
  const { isBroker } = pricerSummaryStore;
  const { propertyType, propertyValuation, totalEstimatedAsIsValue, loanPurpose, acquisitionPrice } = pricerStore.form.fields;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const propertyValueBasedOnLoanType = pricerStore.isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
  const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
  const allowLoanPricing = userStore.allowLoanPricing;

  const [editRateMode, setEditRateMode] = React.useState(false);
  const [editFeesMode, setEditFeesMode] = React.useState(false);
  const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = React.useState(false);
  const [validationErrorOpen, setValidationErrorOpen] = React.useState([]);
  const { brokersStore, loanStore, termStore, fixFlipStore, stabilizedBridgeStore } = useStore();
  const {loanSummaryStore} = useLoanSummaryStore();
  const [editLoanJson, setEditLoanJson] = useState(null)
  const [isConfirmNoPrepaySubmitOpen, setIsConfirmNoPrepaySubmitOpen] = React.useState(false);

  useEffect(() => {
    const referralFee = store.loanDetailsStore?.form?.fields?.referralFee?.value;
    if(referralFee && referralFee > 0 ){
      brokersStore.setOnTheHud(false);
    }
  }, []);


  const handleConfirmSubmit = (quoteIndex, showNoPrepayPopup) => {
    setIsConfirmSubmitOpen(false);
    if(showNoPrepayPopup) {
      setIsConfirmNoPrepaySubmitOpen(true);
    } else {
      handleSubmitQuote(quoteIndex);
    }
  };

  const handleConfirmNoPrepaySubmit = (quoteIndex) => {
    setIsConfirmNoPrepaySubmitOpen(false);
    handleSubmitQuote(quoteIndex);
  };

  const setRateTypeOptionsBasedOnProgramType = () => {
    return pricerSummaryStore.rateTypeOptionsStandard;
  }

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOnChangeAmortization = value => {
    pricerSummaryStore.onFieldChange('amortization', value);
    pricerSummaryStore.fetchRatesAndLoanTermsV2();
  };

  const handleChangePrePaymentPenalty = value => {
    pricerSummaryStore.onFieldChange('prePaymentPenalty', value);
    pricerSummaryStore.fetchRatesAndLoanTermsV2();
  };

  const handleChangeLoanTerm = value => {
    pricerSummaryStore.setLoanTerm(rateTypeToLoanTerm[value]);
    pricerSummaryStore.onFieldChange('rateType', value);
    pricerSummaryStore.onFieldChange('prePaymentPenalty', pricerSummaryStore.defaultPrepay);
    pricerSummaryStore.fetchRatesAndLoanTermsV2();
  };

  const handleChangeYourLoanTerm = (name, value) => {
    pricerSummaryStore.onFieldChange(name, value);
    pricerSummaryStore.fetchRatesAndLoanTermsV2();
  };

  const handleChangeCommittedRateSlider = (cRate, quoteIndex, value) => {
    pricerSummaryStore.adjustRatesAndLoanTermsForCustomQuote(cRate, quoteIndex, value);
  }

  const handleSubmitQuote = (quoteIndex) => {
    pricerSummaryStore.selectCustomQuote(quoteIndex);
    onNext();
  }

  const handleOpenValidationError = (e, id, open) => {
    setValidationErrorOpen(validationErrorOpen => {
      const index = validationErrorOpen.findIndex(error => error.id === id);
      const newList = [...validationErrorOpen];
      if (index > -1) {
        newList.splice(index, 1);
      }
      newList.push({ id: id, open: !open, anchorEl: e.target })
      return newList;
    });
  }

  const handleCloseValidationError = value => {
    setValidationErrorOpen([]);
  }

  const resetReferralFeeAndBrokerPoints = () =>{
    store.loanDetailsStore.onFieldChange('brokerPointsIn', 0);
    store.loanDetailsStore.changePointsPercentage(
      'referralFee',
      0,
      'referralFeesAmount',
      ((pricerSummaryStore.form.fields.loanToValue?.value * asIsValue / 100) || 0));
  }

  const handleHudChange = (value) => {
    console.log(loanStore);
    if (value.toLowerCase() === 'true') {
      brokersStore.setOnTheHud(true);
      resetReferralFeeAndBrokerPoints();
    } else {
      brokersStore.setOnTheHud(false);
      resetReferralFeeAndBrokerPoints();
    }
  }

  const ReferralFees = observer(({ store }) => {
    const formFields = store.loanDetailsStore.form.fields;
    useEffect(() => {
      brokersStore.setIsTermLoan(true);
    }, [])

    return (
      <Grid item xs={6} style={{ alignSelf: 'flex-start' }}>
        <Box mb={2} mr={4} mt={4}>
          <RadioField
            standaloneLabel
            label="Referral Fee"
            value={formFields.referralFeesFormat.value}
            errorText={formFields.referralFeesFormat.error}
            error={isNotBlank(formFields.referralFeesFormat.error)}
            name={'referralFeesFormat'}
            options={pointFormatOptions}
            onChange={value => {
              store.loanDetailsStore.onChange('referralFeesFormat', value);
              store.loanDetailsStore.changePointsPercentage(
                'referralFee',
                formFields.referralFee.value,
                'referralFeesAmount',
                ((pricerSummaryStore.form.fields.loanToValue?.value * asIsValue / 100) || 0)
              );
            }}
            fullWidth
            row
            required
            testId="referralFeesFormat"
          />
          {formFields.referralFeesFormat.value === pointFormatOptions[0].value && (
            <NumberFormat
              testId="referralFee"
              required
              customInput={TextField}
              variant="outlined"
              value={formFields.referralFee.value}
              onChange={e =>
                store.loanDetailsStore.changePointsPercentage(
                  'referralFee',
                  e.target.value,
                  'referralFeesAmount',
                  ((pricerSummaryStore.form.fields.loanToValue?.value * asIsValue / 100) || 0)
                )
              }
              decimalScale={2}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={isNotBlank(formFields.referralFee.error)}
              helperText={formFields.referralFee.error}
              fullWidth
              standaloneLabel
            />
          )}
          {formFields.referralFeesFormat.value === pointFormatOptions[1].value && (
            <CurrencyField
              variant="outlined"
              standaloneLabel
              value={formFields.referralFeesAmount.value}
              errorText={formFields.referralFee.error}
              error={isNotBlank(formFields.referralFee.error)}
              onChange={(name, value) =>
                store.loanDetailsStore.changePointsAmount(
                  'referralFeesAmount',
                  value,
                  'referralFee',
                  ((pricerSummaryStore.form.fields.loanToValue?.value * asIsValue / 100) || 0)
                )
              }
              fullWidth
              required
              testId="referralFeesNumber"
            />
          )}
        </Box>
      </Grid>
    );
  });

  const ratesScenarios = pricerSummaryStore.ratesScenarios as RateScenario[];
  const customQuotes = pricerSummaryStore.customQuotes as CustomQuote[];
  const formFields = pricerSummaryStore.form.fields;
  return (
    <div className={accordionClasses.root}>

      <Box width="100%" mb={3} p={2} style={{
        background: lighten(theme.palette.primary.main, .95)
      }}>
        <Box mb={2} p={3} width="100%" style={{
          background: '#fff',
          border: '1px solid #eeee',
          borderRadius: 8,
        }}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography gutterBottom style={{
                  fontWeight: 'bold',
                  fontSize: 20
                }}>
                  Loan Terms
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box mb={2} mr={4}>
                <SelectField
                  disabled={false}
                  standaloneLabel
                  label={'Amortization Type'}
                  name={'amortization'}
                  value={formFields.amortization.value}
                  tooltip={amortizationTypeTooltip}
                  options={amortizationOptions}
                  onChange={handleOnChangeAmortization}
                  variant="outlined"
                  testId="amortization"
                  className={accordionClasses.selectField}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box mb={2} mr={4}>
                <SelectField
                  disabled={false}
                  standaloneLabel
                  label={'Rate Type'}
                  name={'rateType'}
                  value={formFields.rateType.value}
                  options={setRateTypeOptionsBasedOnProgramType()}
                  onChange={handleChangeLoanTerm}
                  variant="outlined"
                  testId="rateType"
                  className={accordionClasses.selectField}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box mb={2} mr={4}>
                <SelectField
                  disabled={false}
                  standaloneLabel
                  label={'Pre-Payment Penalty'}
                  name={'prePaymentPenalty'}
                  value={formFields.prePaymentPenalty.value}
                  options={pricerSummaryStore.prepaymentPenaltyOptions}
                  onChange={handleChangePrePaymentPenalty}
                  variant="outlined"
                  testId="prePaymentPenalty"
                  className={accordionClasses.selectField}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box>
                <Slider
                  testId="loanToValueSlider"
                  standaloneLabel
                  label="Loan To Value"
                  disabled={false}
                  onChangeCommitted={(event, value) =>
                    handleChangeYourLoanTerm('loanToValue', value)
                  }
                  className={classes.slider}
                  value={formFields.loanToValue.value}
                  valueLabelDisplay="on"
                  marks={[
                    {
                      value: 0,
                      label: `0%`,
                    },
                    {
                      value: 80,
                      label: `80%`,
                    },
                  ]}
                  step={5}
                  min={0}
                  max={80}
                />
              </Box>
            </Grid>
            <Grid container spacing={4} alignItems="flex-start">
              {(!brokersStore.onTheHud && pricerSummaryStore.isInternalLender) ? (
                <ReferralFees store={store} />
              ) :
                (
                  <Grid item xs={6} style={{ alignSelf: 'flex-start' }}>
                    <Box mb={2} mr={4} mt={4}>
                      <SelectField
                        disabled={false}
                        standaloneLabel
                        label={'Broker Points'}
                        name={'brokerPointsIn'}
                        value={store.loanDetailsStore.form.fields.brokerPointsIn.value}
                        options={BROKER_POINTS_OPTIONS}
                        onChange={value => store.loanDetailsStore.onFieldChange('brokerPointsIn', value)}
                        variant="outlined"
                        fullWidth
                        testId="brokerPointsIn"
                        subLabel="Enter the points for the third party broker"
                        className={accordionClasses.selectField}
                      />
                    </Box>
                  </Grid>
                )
              }
              {pricerSummaryStore.isInternalLender &&
                <Grid item xs={6} style={{ alignSelf: 'flex-start' }}>
                  <Box mt={4}>
                    <RadioField
                      standaloneLabel
                      label="How do you want the broker to be paid ?"
                      value={brokersStore.onTheHud}
                      options={options}
                      onChange={value => handleHudChange(value)}
                      required={true}
                      fullWidth
                      testId="hud"
                      row
                    />
                    <Typography className={classes.noteText}>
                      Note: Brokers paid on the HUD will have a separate broker line item and brokers paid off the HUD will have their fee rolled into origination points.
                    </Typography>
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
        </Box>
        <Box my={2} pl={3}>
          <Typography display="inline" gutterBottom style={{
            fontWeight: 'bold',
            fontSize: 20
          }}>Loan Amount: </Typography>
          <Typography display="inline" style={{
            padding: '8',
            fontSize: 20,
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
          }}>
            {formatCurrency((pricerSummaryStore.form.fields.loanToValue?.value * asIsValue / 100) || 0)}
          </Typography>
        </Box>
        <Box my={2} pl={3}>
          <Typography gutterBottom style={{
            fontWeight: 'bold',
            fontSize: 20
          }}>
            Loan Rates
          </Typography>
        </Box>
        <Box mt={2} pl={3} display={'flex'}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={accordionClasses.secondaryHeading} style={{
                    padding: 0
                  }} width={'25%'}>Scenario</TableCell>
                  <TableCell align="left" className={accordionClasses.secondaryHeading} style={{
                    padding: '8px'
                  }} width={'20%'}>Rate</TableCell>
                  <TableCell align="left" className={accordionClasses.secondaryHeading} style={{
                    padding: '8px 0'
                  }} width={'20%'}>
                    <Box display={'flex'} alignItems={'center'}>
                      {isBroker ? 'Buydown ' : ''}Points/Fees
                      {' '}
                      <Tooltip title="Points/Fees you must allocate to get the respective rate" placement='top'>
                        <Info fontSize='small' style={{
                          color: '#555',
                          marginLeft: 4,
                        }} />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {!isBackOfficeProcessor && allowLoanPricing && (
                    <TableCell align="left" className={accordionClasses.secondaryHeading} style={{
                      padding: '8px 0'
                    }} width={'20%'}>
                      You Make
                    </TableCell>
                  )}
                  <TableCell align="left" className={accordionClasses.secondaryHeading} style={{
                    padding: '8px 0'
                  }} width={'15%'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  ratesScenarios.map((scenario, i) => {
                    const rateAvailable = !isNil(scenario.rate);
                    const id = String(scenario.buydown);
                    return (
                      <TableRow key={scenario.buydownKey}>
                        <TableCell component="th" scope="row" align="left" style={{
                          padding: 0,
                          fontSize: 16,
                        }}>
                          <>
                            {i === 0 && (
                              <div>
                                <div>Max Buydown</div>
                                {!isBroker && (<div style={{ fontSize: 12 }}>(to Capital Provider)</div>)}
                              </div>
                            )}
                            {i === ratesScenarios.length - 1 && (
                              <div>
                                {isBroker && (
                                  <div>No Buydown</div>
                                )}
                                {!isBroker && (
                                  <>
                                    <div>No Points/Fees</div>
                                    <div style={{ fontSize: 12 }}>(to Capital Provider)</div>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        </TableCell>
                        <TableCell component="th" scope="row" align="left" style={{
                          padding: '8',
                          fontSize: 24,
                          fontWeight: 'bold',
                          color: theme.palette.primary.dark,
                        }}>
                          {rateAvailable ? formatPercentage(scenario.rate) : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{
                          padding: '8px 0',
                          fontSize: 16,
                          fontWeight: 'bold',
                        }}>
                          <span>{rateAvailable ? formatCurrency(scenario.pointsAndFees) : 'N/A'}</span>
                          <div style={{
                            display: 'block',
                            fontSize: 10,
                            color: '#777',
                          }}>{rateAvailable ? `${formatPercentage(scenario.pointsAndFeesPercent)} of Loan Amount` : null}</div>
                        </TableCell>
                        {!isBackOfficeProcessor && allowLoanPricing && (
                          !isBroker ? (
                            <TableCell align="left" style={{
                              padding: '8px 0',
                              fontSize: 24,
                              fontWeight: 'bold',
                              color: theme.palette.primary.dark,
                            }}>
                              <span>{rateAvailable ? formatCurrency(pricerSummaryStore.isInternalLender ? scenario.commissions?.commissionAmount || 0 : scenario.youMakeForWholesale || 0) : 'N/A'}</span>
                              <div style={{
                                display: 'block',
                                fontSize: 10,
                              }}>{rateAvailable ? `${formatPercentage(pricerSummaryStore.isInternalLender ? scenario.commissions?.commissionPercentage || 0 : scenario.youMakeForWholesalePercent || 0)} of Loan Amount` : null}</div>
                            </TableCell>
                          ) : (
                            <TableCell align="left" style={{
                              padding: '8px 0',
                              fontSize: 24,
                              fontWeight: 'bold',
                              color: theme.palette.primary.dark,
                            }}>
                              <span>{rateAvailable ? formatCurrency(scenario.youMakeForBroker) : 'N/A'}</span>
                              <div style={{
                                display: 'block',
                                fontSize: 10,
                              }}>{rateAvailable ? `${formatPercentage(scenario.youMakeForBrokerPercent)} of Loan Amount` : null}</div>
                            </TableCell>
                          )
                        )}
                        {(scenario.validationError && scenario.validationError != '') ? (
                          <TableCell align={'right'} style={{
                            padding: '8px 16px'
                          }}>
                            <Box my={1} >
                              <IconButton
                                size='small'
                                testId='validationErrorButton'
                                style={{ color: 'orange' }}
                                onClick={e => handleOpenValidationError(e, id, validationErrorOpen.findIndex(x => x.id === id) > -1 && validationErrorOpen.find(x => x.id === id).open)}
                              >
                                <ReportProblem fontSize="medium" />
                              </IconButton>
                              <Popper
                                open={validationErrorOpen.findIndex(x => x.id === id) > -1 && validationErrorOpen.find(x => x.id === id).open}
                                anchorEl={validationErrorOpen.findIndex(x => x.id === id) > -1 ?
                                  validationErrorOpen.find(x => x.id === id).anchorEl : undefined}
                                id={id}
                              >
                                <ClickAwayListener onClickAway={handleCloseValidationError}>
                                  <div className={accordionClasses.validationError} style={{ maxWidth: '500px' }}>{scenario.validationError}</div>
                                </ClickAwayListener>
                              </Popper>
                            </Box>

                          </TableCell>
                        ) : (
                          <TableCell align={'right'} style={{
                            padding: '8px 16px'
                          }}>
                            <Box my={1}>
                              {rateAvailable ? (
                                <Button
                                  size='small'
                                  variant="contained"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    pricerSummaryStore.addCustomQuote(scenario);
                                    setExpanded(`panel${customQuotes.length}`);
                                    setTimeout(() => {
                                      (window as any).scrollTo(0, document.body.scrollHeight);
                                    }, 100);
                                  }}
                                  testId="createCustomQuote"
                                >
                                  Customize
                                </Button>
                              ) : null}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
                }
                {pricerSummaryStore.isTargetRateSettingAvailable && (
                  <TableRow key='targetRate'>
                    <TableCell component="th" scope="row" align="left" style={{
                      padding: 0,
                      fontSize: 16,
                      borderTop: "2px solid lightgrey",
                    }}>
                      <div>Or, enter your target interest rate</div>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left" style={{
                      padding: '8',
                      fontSize: 24,
                      fontWeight: 'bold',
                      color: theme.palette.primary.dark,
                      borderTop: "2px solid lightgrey",
                    }}>
                      <PercentageField
                        testId='targetRateInput'
                        value={pricerSummaryStore.targetRateScenario.rate}
                        inputClassname={classes.targetRateField}
                        style={{
                          maxWidth: '100px',
                          margin: '0px 0px',
                          backgroundColor: 'white',
                        }}
                        variant={'outlined'}
                        decimalScale={2}
                        allowNegative={false}
                        onBlur={() => {
                          if (Number(pricerSummaryStore.targetRateScenario.rate) < pricerSummaryStore.minTargetRate) {
                            pricerSummaryStore.setTargetRate(pricerSummaryStore.minTargetRate);
                          } else if (Number(pricerSummaryStore.targetRateScenario.rate) > pricerSummaryStore.maxTargetRate) {
                            pricerSummaryStore.setTargetRate(pricerSummaryStore.maxTargetRate);
                          }
                          pricerSummaryStore.targetRateScenario.minRate = pricerSummaryStore.targetRateScenario.rate;
                          pricerSummaryStore.getBuydownForTargetedRate(pricerSummaryStore.targetRateScenario, pricerSummaryStore.targetRateScenario.rate);
                        }}
                        onChange={(_, value) => {
                          pricerSummaryStore.setTargetRate(value);
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{
                      padding: '8px 0',
                      fontSize: 16,
                      fontWeight: 'bold',
                      borderTop: "2px solid lightgrey",
                    }}>
                      <span>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? formatCurrency(pricerSummaryStore.targetRateScenario.pointsAndFees || 0) : '-'}</span>
                      <div style={{
                        display: 'block',
                        fontSize: 10,
                        color: '#777',
                      }}>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? `${formatPercentage(pricerSummaryStore.targetRateScenario.pointsAndFeesPercent || 0)} of Loan Amount` : null}</div>
                    </TableCell>
                    {!isBackOfficeProcessor && allowLoanPricing && (
                      !isBroker ? (
                        <TableCell align="left" style={{
                          padding: '8px 0',
                          fontSize: 24,
                          fontWeight: 'bold',
                          color: theme.palette.primary.dark,
                          borderTop: "2px solid lightgrey",
                        }}>
                          <span>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? formatCurrency(pricerSummaryStore.isInternalLender ? pricerSummaryStore.targetRateScenario.commissions?.commissionAmount || 0 : pricerSummaryStore.targetRateScenario.youMakeForWholesale || 0) : '-'}</span>
                          <div style={{
                            display: 'block',
                            fontSize: 10,
                          }}>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? `${formatPercentage(pricerSummaryStore.isInternalLender ? pricerSummaryStore.targetRateScenario.commissions?.commissionPercentage || 0 : pricerSummaryStore.targetRateScenario.youMakeForWholesalePercent || 0)} of Loan Amount` : null}</div>
                        </TableCell>
                      ) : (
                        <TableCell align="left" style={{
                          padding: '8px 0',
                          fontSize: 24,
                          fontWeight: 'bold',
                          color: theme.palette.primary.dark,
                          borderTop: "2px solid lightgrey",
                        }}>
                          <span>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? formatCurrency(pricerSummaryStore.targetRateScenario.youMakeForBroker) : '-'}</span>
                          <div style={{
                            display: 'block',
                            fontSize: 10,
                          }}>{!isNil(pricerSummaryStore.targetRateScenario.rate) ? `${formatPercentage(pricerSummaryStore.targetRateScenario.youMakeForBrokerPercent)} of Loan Amount` : null}</div>
                        </TableCell>
                      )
                    )}
                    <TableCell align={'right'} style={{
                      padding: '8px 16px',
                      borderTop: "2px solid lightgrey",
                    }}>
                      <Box my={1}>
                        <Button
                          size='small'
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            pricerSummaryStore.addCustomQuote(pricerSummaryStore.targetRateScenario);
                            setExpanded(`panel${customQuotes.length}`);
                            setTimeout(() => {
                              (window as any).scrollTo(0, document.body.scrollHeight);
                            }, 100);
                          }}
                          testId="createCustomQuote"
                          disabled={isNil(pricerSummaryStore.targetRateScenario.rate)}
                        >
                          Customize
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {
        customQuotes.length > 0 &&
        <>
          <Box mt={6} textAlign={'center'}>
            <Typography variant="h4">
              Custom Quotes
            </Typography>
          </Box>
        </>
      }
      {
        customQuotes.map((cRate, quoteIndex) => {
          const panelName = `panel${quoteIndex}`;
          const isExpanded = expanded === panelName;
          const rateAvailable = !isNil(cRate.rate);
          const goodToSubmitQuote = pricerSummaryStore.determineIfGoodToSubmitQuote(cRate);
          const missingPointToRoc = pricerSummaryStore.determineIfPointToRocMissing(cRate);
          return (
            <Box mt={8}>
              <Accordion expanded={isExpanded} onChange={handleChange(panelName)} key={panelName}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id={`${panelName}-header`}
                >
                  {!isBackOfficeProcessor && allowLoanPricing && (
                    <Box className={accordionClasses.youMakeContainer}>
                      <Box className={clsx(accordionClasses.youMakeContainerBorder, isExpanded ? 'expanded' : '')} />
                      <Box className={accordionClasses.youMakeContent}>
                        <Typography className={accordionClasses.secondaryHeading} style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <span style={{
                            marginRight: 8
                          }}>
                            You Make:
                          </span>
                          <div style={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            color: 'black',
                          }}>
                            <div>
                              {rateAvailable ?
                                (isBroker ?
                                  formatCurrency(cRate.youMakeForBroker) :
                                  formatCurrency(pricerSummaryStore.isInternalLender ? cRate.commissions?.commissionAmount || 0 : cRate.youMakeForWholesale)) : '-'}
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 'bold',
                            }}>
                              {rateAvailable ? `${isBroker ?
                                formatPercentage(cRate.youMakeForBrokerPercent) :
                                formatPercentage(pricerSummaryStore.isInternalLender ? cRate.commissions?.commissionPercentage || 0 : cRate.youMakeForWholesalePercent)} of Loan Amount` : null}
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box width="100%" mx={1} my={3}>
                    <Box display="flex">
                      <div style={{
                        flexBasis: '25%',
                        textAlign: 'left',
                        marginRight: '8px',
                      }}>
                        <Typography className={accordionClasses.heading} style={{
                          fontWeight: "bold"
                        }}>Custom Quote #{quoteIndex + 1}</Typography>
                        <Tooltip title="You can select up to 3 quotes to compare side by side and download" placement='top'>
                          <ButtonGroup color="primary" size="small" style={{
                            margin: '8px 0'
                          }}>
                            <Button variant='outlined' testId='compareCheckBox' disabled={!goodToSubmitQuote} onClick={(e) => {
                              e.stopPropagation();
                              const newCRate = Object.assign({}, { ...cRate });
                              const compareVaule = newCRate.compare;
                              const newCompareValue = !compareVaule;
                              newCRate.compare = newCompareValue;
                              pricerSummaryStore.editCompareCount(newCRate, quoteIndex, false);
                            }}>
                              {
                                cRate.compare && goodToSubmitQuote ? <CheckBox /> : <CheckBoxOutlineBlank />
                              }
                            </Button>
                            <Button variant='outlined' testId="compareButton" disabled={!goodToSubmitQuote} onClick={(e) => {
                              e.stopPropagation();
                              if (!cRate.compare) {
                                const newCRate = Object.assign({}, { ...cRate });
                                const compareVaule = newCRate.compare;
                                const newCompareValue = !compareVaule;
                                newCRate.compare = newCompareValue;
                                pricerSummaryStore.editCompareCount(newCRate, quoteIndex, false);
                              }
                              setShowLoanRatesDialog(true)
                            }} style={{
                              textTransform: 'unset'
                            }}>
                              Compare / Download
                            </Button>
                          </ButtonGroup>
                        </Tooltip>
                        <Box>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              pricerSummaryStore.removeCustomQuote(quoteIndex);
                            }}
                            testId="removeQuote"
                            startIcon={<Delete fontSize='small' />}
                            className={accordionClasses.removeQuoteButton}
                          >
                            Remove Quote
                          </Button>
                        </Box>
                      </div>
                      <div style={{
                        flexBasis: '20%',
                        marginRight: '8px',
                      }}>
                        <Box mb={2.5}>
                          <Typography className={accordionClasses.secondaryHeading}>
                            Loan To Value
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}>{formatPercentage(cRate.loanToValue)}</span>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={accordionClasses.secondaryHeading}>
                            Pre-Payment Penalty
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}>
                              {cRate.prePaymentPenalty}
                            </span>
                          </Typography>
                        </Box>
                      </div>
                      <div style={{
                        flexBasis: '20%',
                      }}>
                        {propertyType?.value !== MULTIFAMILY_5_PLUS && (
                          <Box mb={2.5}>
                            <Typography className={accordionClasses.secondaryHeading}>
                              PITIA DSCR
                            </Typography>
                            <Typography>
                              <span style={{
                                fontSize: 16,
                                fontWeight: 'bold'
                              }}>{rateAvailable ? cRate.dscr : '-'}</span>
                            </Typography>
                            <Typography className={accordionClasses.secondaryHeading}>
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          <Typography className={accordionClasses.secondaryHeading}>
                            Rate Type
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}>{cRate.rateType}</span>
                          </Typography>
                          <Typography className={accordionClasses.secondaryHeading}>
                          </Typography>
                        </Box>
                      </div>
                      <div style={{
                        flexBasis: '20%',
                        marginRight: '8px',
                      }}>
                        <Box mb={2.5}>
                          <Typography className={accordionClasses.secondaryHeading}>
                            Est. Mo. Payment
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}>
                              {rateAvailable ? formatCurrency(cRate.pricerLoanTerms?.monthlyPaymentWithoutExpenses) : '-'}
                            </span>
                            <span>/mo</span>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={accordionClasses.secondaryHeading}>
                            <Box display={'flex'} alignItems={'center'}>
                              Points/Fees
                              {' '}
                              <Tooltip title="Points/Fees you must allocate to get this rate" placement='top'>
                                <Info fontSize='small' style={{
                                  color: '#555',
                                  marginLeft: 4,
                                }} />
                              </Tooltip>
                            </Box>
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 16,
                              fontWeight: 'bold'
                            }}>{rateAvailable ? formatCurrency(cRate.pointsAndFees) : '-'}</span>
                            <div style={{
                              display: 'block',
                              fontSize: 10,
                              color: '#777',
                            }}>{rateAvailable ? `${formatPercentage(cRate.pointsAndFeesPercent)} of Loan Amount` : null}</div>
                          </Typography>
                          <Typography className={accordionClasses.secondaryHeading}>
                          </Typography>
                        </Box>
                      </div>
                      <div style={{
                        flexBasis: '15%',
                      }}>
                        <Box mb={2}>
                          <Typography className={accordionClasses.secondaryHeading}>
                            Rate
                          </Typography>
                          <Typography>
                            <span style={{
                              fontSize: 24,
                              fontWeight: 'bold',
                              color: theme.palette.primary.dark,
                            }}>{rateAvailable ? formatPercentage(cRate.rate) : 'N/A'}</span>
                            <span style={{
                              marginLeft: 4
                            }}></span>
                          </Typography>
                          <Typography className={accordionClasses.secondaryHeading}>
                          </Typography>
                        </Box>
                        <Box>
                          {rateAvailable && goodToSubmitQuote ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                setTimeout(() => {
                                  (window as any).scrollTo(0, document.body.scrollHeight);
                                }, 100);
                                if (!isBroker && !isBackOfficeProcessor && allowLoanPricing &&
                                  ((!pricerSummaryStore.isInternalLender && cRate.youMakeForWholesale == 0) || (pricerSummaryStore.isInternalLender && cRate.commissions?.commissionAmount == 0))) {
                                  setIsConfirmSubmitOpen(true);
                                } else if(cRate.prePaymentPenalty === PPP_0_NO_PREPAY) {
                                  setIsConfirmNoPrepaySubmitOpen(true);
                                } else {
                                  handleSubmitQuote(quoteIndex);
                                }
                              }}
                              testId="continueSubmission"
                            >
                              Submit
                            </Button>
                          ) : null}
                        </Box>
                        <ConfirmDialog
                          open={isConfirmSubmitOpen}
                          body={`Your expected earnings for this loan are $0. Are you sure you want to proceed?`}
                          handleCancel={() => setIsConfirmSubmitOpen(false)}
                          handleClose={() => setIsConfirmSubmitOpen(false)}
                          handleConfirm={() => { handleConfirmSubmit(quoteIndex, cRate.prePaymentPenalty === PPP_0_NO_PREPAY) }}
                          confirmButtonText={'Yes'}
                          cancelButtonText={'No'}
                        />
                        <ConfirmDialog
                          open={isConfirmNoPrepaySubmitOpen}
                          body={`After submission, the capital provider must add one point to loans without a prepayment penalty. In the next release, we will update the pricer page to accurately reflect this change. As you build your quote, please make sure you know at least one point will be going to capital provider.`}
                          handleCancel={() => setIsConfirmNoPrepaySubmitOpen(false)}
                          handleClose={() => setIsConfirmNoPrepaySubmitOpen(false)}
                          handleConfirm={() => { handleConfirmNoPrepaySubmit(quoteIndex) }}
                          confirmButtonText={'Yes'}
                          cancelButtonText={'No'}
                        />
                      </div>
                    </Box>
                    {
                      (rateAvailable && cRate.pointsAndFees !== cRate.totalAllocatedFees) ?
                        <Box mt={2} display={'flex'} marginLeft={'25%'}>
                          <Alert severity="warning" style={{
                            padding: '0 8px',
                            width: '100%',
                          }}>
                            {cRate.totalAllocatedFees < cRate.pointsAndFees ?
                              'You still need to allocate some fees to get the selected lowest rate.' :
                              'You have allocated more than the necessary fees.'}
                          </Alert>
                        </Box> : null
                    }
                    {
                      (cRate.prePaymentPenalty === PPP_0_NO_PREPAY) ?
                        <Box mt={2} display={'flex'} marginLeft={'25%'}>
                          <Alert severity="warning" style={{
                            padding: '0 8px',
                            width: '100%',
                          }}>
                              {'After submission, the capital provider must add one point to loans without a prepayment penalty. In the next release, we will update the pricer page to accurately reflect this change. As you build your quote, please make sure you know at least one point will be going to capital provider.'}
                          </Alert>
                        </Box> : null
                    }
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={accordionClasses.details}>
                  <Grid container style={{
                    padding: '16px',
                    justifyContent: 'space-between'
                  }}>
                    <Grid container spacing={4} item xs={12} md={4} style={{
                      flexDirection: "column"
                    }}>
                      <Box width="100%" style={{
                        background: '#fff',
                        border: '1px solid #eeee',
                        borderRadius: 8,
                        padding: 32,
                      }}>
                        <Grid item xs={12}>
                          <Box mb={2}>
                            <Typography display="inline" gutterBottom style={{
                              fontWeight: 'bold',
                              fontSize: 20
                            }}>Loan Amount: </Typography>
                            <Typography display="inline" style={{
                              padding: '8',
                              fontSize: 20,
                              fontWeight: 'bold',
                              color: theme.palette.primary.dark,
                            }}>
                              {formatCurrency(cRate.pricerLoanTerms.amount || 0)}
                            </Typography>
                          </Box>
                          <Box mb={2}>
                            <Typography gutterBottom style={{
                              fontWeight: 'bold',
                              fontSize: 20
                            }}>
                              Loan Terms
                            </Typography>
                          </Box>
                          <Box mb={2}>
                            <SelectField
                              disabled={false}
                              standaloneLabel
                              label={'Amortization Type'}
                              name={'amortization'}
                              value={cRate.amortization}
                              tooltip={amortizationTypeTooltip}
                              options={amortizationOptions}
                              onChange={value => {
                                cRate.amortization = value;
                                pricerSummaryStore.editCustomQuote(cRate, quoteIndex, true);
                              }}
                              variant="outlined"
                              testId="amortization"
                              className={accordionClasses.selectField}
                            />
                          </Box>
                          <Box mb={2}>
                            <SelectField
                              disabled={false}
                              standaloneLabel
                              label={'Rate Type'}
                              name={'rateType'}
                              value={cRate.rateType}
                              options={setRateTypeOptionsBasedOnProgramType()}
                              onChange={value => {
                                cRate.rateType = value;
                                pricerSummaryStore.editCustomQuote(cRate, quoteIndex, true);
                              }}
                              variant="outlined"
                              testId="rateType"
                              className={accordionClasses.selectField}
                            />
                          </Box>
                          <Box mb={2}>
                            <SelectField
                              disabled={false}
                              standaloneLabel
                              label={'Pre-Payment Penalty'}
                              name={'prePaymentPenalty'}
                              value={cRate.prePaymentPenalty}
                              options={pricerSummaryStore.prepaymentPenaltyOptions}
                              onChange={value => {
                                cRate.prePaymentPenalty = value;
                                pricerSummaryStore.editCustomQuote(cRate, quoteIndex, true);
                              }}
                              variant="outlined"
                              testId="prePaymentPenalty"
                              className={accordionClasses.selectField}
                            />
                          </Box>
                          <Box mb={2}>
                            <Slider
                              testId="loanToValueSlider"
                              standaloneLabel
                              label="Loan To Value"
                              disabled={false}
                              onChangeCommitted={(event, value) => {
                                cRate.loanToValue = value as number;
                                pricerSummaryStore.editCustomQuote(cRate, quoteIndex, true);
                              }}
                              className={classes.slider}
                              value={cRate.loanToValue}
                              valueLabelDisplay="on"
                              marks={[
                                {
                                  value: 0,
                                  label: `0%`,
                                },
                                {
                                  value: 80,
                                  label: `80%`,
                                },
                              ]}
                              step={5}
                              min={0}
                              max={80}
                            />
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid container spacing={4} item xs={12} md={8} style={{
                      flexDirection: "column",
                    }}>
                      <Box width="100%" style={{
                        background: lighten(theme.palette.primary.main, .95),
                        border: `1px solid ${lighten(theme.palette.primary.main, .8)}`,
                        borderRadius: 8,
                        padding: 16,
                        marginBottom: 16
                      }}>
                        <Grid item xs={12} style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                          <Box textAlign={'center'} mb={2} className={accordionClasses.editTextContainer}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <Box textAlign={'center'} ml={2} display={'flex'} flexDirection={'column'}>
                              <Typography>
                                Rate:
                              </Typography>
                              <Box textAlign={'center'} flexDirection={'row'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}>
                                {/*
                                  !editRateMode && rateAvailable && (cRate.rate != cRate.minRate) &&
                                  <IconButton size='small' testId='reduceRate' style={{
                                    border: '1px solid',
                                    height: 30,
                                    margin: '0 8px',
                                    borderRadius: 20,
                                  }}>
                                    <ExpandMore fontSize="small" onClick={(event) => {
                                      const currentRate = cRate.rate;
                                      const newCurrentRate = Math.max(cRate.minRate, Number((currentRate - 0.05).toFixed(4)));
                                      cRate.rate = newCurrentRate;
                                      pricerSummaryStore.adjustRatesAndLoanTermsForCustomQuote(cRate, quoteIndex, newCurrentRate);
                                    }} />
                                  </IconButton>
                                  */}
                                {rateAvailable ? (
                                  <Box className={accordionClasses.editTextContainer}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      {editRateMode ? (
                                        <NumberFormat
                                          testId='editRateInput'
                                          value={cRate.rate}
                                          style={{
                                            fontSize: '32px',
                                            fontWeight: 'bold',
                                            maxWidth: '100px',
                                            margin: '0px 8px',
                                          }}
                                          autoFocus
                                          onBlur={() => {
                                            setEditRateMode(false);
                                            let newRate = cRate.rate;
                                            if (newRate < cRate.minRate) {
                                              newRate = cRate.minRate;
                                            }
                                            if (newRate > cRate.maxRate) {
                                              newRate = cRate.maxRate;
                                            }
                                            // should we change the rate too?
                                            cRate.rate = newRate;
                                            pricerSummaryStore.adjustRatesAndLoanTermsForCustomQuote(cRate, quoteIndex, newRate);
                                          }}
                                          onChange={({ target }) => {
                                            let newRate = Number(target.value);
                                            cRate.rate = newRate;
                                            pricerSummaryStore.editCustomQuote(cRate, quoteIndex, false, false);
                                          }}
                                          min={cRate.minRate}
                                        />
                                      ) : (
                                        <>
                                          <Box style={{
                                            fontSize: '32px',
                                            fontWeight: 'bold',
                                            maxWidth: '100px',
                                            margin: '0px 8px',
                                          }}>
                                            {formatPercentage(cRate.rate)}
                                          </Box>
                                          <IconButton size='small' testId='reduceRate' >
                                            <Edit fontSize="small" style={{ color: 'black' }} onClick={(event) => {
                                              setEditRateMode(true);
                                            }} />
                                          </IconButton>
                                        </>
                                      )}
                                    </Box>
                                  </Box>) : 'N/A'}
                                {/*
                                  !editRateMode && rateAvailable && (cRate.rate != cRate.maxRate) &&
                                  <IconButton size='small' testId='increaseRate' style={{
                                    border: '1px solid',
                                    height: 30,
                                    margin: '0 8px',
                                    borderRadius: 20,
                                  }}>
                                    <ExpandLess fontSize="small" onClick={(event) => {
                                      const currentRate = cRate.rate;
                                      const newCurrentRate = Math.min(cRate.maxRate, Number((currentRate + 0.05).toFixed(4)));
                                      cRate.rate = newCurrentRate;
                                      pricerSummaryStore.adjustRatesAndLoanTermsForCustomQuote(cRate, quoteIndex, newCurrentRate);
                                    }} />
                                  </IconButton>
                                  */}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        {rateAvailable &&
                          <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }} >
                            <Grid item xs={6} style={{ display: 'inline', alignItems: 'center', justifyContent: 'center' }}>
                              <Box style={{ display: 'inline', alignItems: 'center', justifyContent: 'center' }}>
                                <PricerRateSlider
                                  rate={cRate.rate}
                                  minRate={cRate.minRate}
                                  maxRate={cRate.maxRate}
                                  onChange={(value) => handleChangeCommittedRateSlider(cRate, quoteIndex, value)}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        }
                        <Grid item xs={12}>
                          <Alert severity="info" style={{ marginBottom: '8px' }}>
                            <AlertTitle>Info</AlertTitle>
                            <ul>
                              {isBroker ? (
                                <>
                                  <li>Points and fees that go to the "Buydown" are needed in order to get the selected rate scenario.</li>
                                  <li>Points and fees that are in the "Non-Buydown" don't impact the rate.</li>
                                </>
                              ) : (
                                <>
                                  <li>Points and fees that go to the Capital Provider buy down the rate. To get the rate listed, you need to allocate the minimum points/fees to Capital Provider.</li>
                                  <li>Points and fees that go to the Lender don't impact the rate.</li>
                                </>
                              )}
                            </ul>
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <PointsFeesAllocationTable
                              handleAdd={(section) => pricerSummaryStore.addCustomQuoteFeeChange(quoteIndex, section)}
                              handleDelete={(i) => pricerSummaryStore.deleteCustomQuoteFeeChange(quoteIndex, i)}
                              handleChange={(i, k, v) => pricerSummaryStore.handleCustomQuoteFeeChange(quoteIndex, i, k, v)}
                              handleBlur={(i, k) => pricerSummaryStore.handleCustomQuoteFeeChangeOnBlur(quoteIndex, i, k)}
                              customQuote={cRate}
                              pricerSummaryStore={pricerSummaryStore}
                              quoteIndex={quoteIndex}
                              isInternal={pricerSummaryStore.isInternalLender}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={2}>
                            <ClosingFeesTable
                              pricerSummaryStore={pricerSummaryStore}
                            />
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })
      }
    </div>
  );
});

interface PricerSummaryTermProps {
  store: SinglePropertyStore | RentalPortfolioStore;
  onNext?: () => void,
}

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '16px',
      width: '90%',
    },
    errorContainer: {
      color: theme.palette.error.main,
    },
    selectedRate: {
      color: theme.palette.primary.dark,
    },
    targetRateField: {
      '& input': {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
      },
      '& .MuiInputAdornment-root p': {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
      },
      '& .MuiInputBase-root': {
        maxHeight: '52px',
      },
    },
    noteText: {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
    },
  })
);

const listItem = (label, value, value2 = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          <Box flexGrow={1}>{label}</Box>
          <span />
          {!value2 && (
            <Typography variant="body1">
              <strong>{value}</strong>
            </Typography>
          )}
          {value2 && (
            <Grid spacing={2} style={{ textAlign: 'right' }}>
              <Typography variant="body1">
                <strong>{value}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>{value2}</strong>
              </Typography>
            </Grid>
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

const LoanRatesErrors = observer(({ store }) => {
  const classes = useStyles();
  const { serverExceptions } = store.pricerSummaryStore;

  return (
    <Grid className={classes.errorContainer}>
      <Typography variant="body1">
        <strong>
          Currently Rates are not avaliable for the given loan scenario, Please
          proceed and Submit Loan Application. Our underwriting team will get
          back to you.
        </strong>
      </Typography>
      <br />
      <Typography variant="body1">
        {serverExceptions &&
          serverExceptions.length > 0 &&
          serverExceptions.map(exception => (
            <li key={exception}>
              <strong>{exception}</strong>
            </li>
          ))}
      </Typography>
    </Grid>
  );
});

export const RateProgramType = observer(({ store }) => {
  const classes = useStyles();
  const { pricerLoanTerms, form } = store.pricerSummaryStore;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;

  const handleChangeYourLoanTerm = (name, value) => {
    store.pricerSummaryStore.onFieldChange(name, value);
    store.pricerSummaryStore.setMinsAndMaxs();
    store.pricerSummaryStore.fetchRatesAndLoanTerms();
    store.pricerSummaryStore.setErrorMessages();
  };

  const setRateTypeOptionsBasedOnProgramType = () => {
    if (form.fields.programType.value === DSCR_EXPANDED) {
      return store.pricerSummaryStore.rateTypeOptions;
    }
    else {
      return store.pricerSummaryStore.rateTypeOptionsStandard;
    }
  }

  return (
    <Grid container
      xs={12}
      md={12}
      lg={12} direction="row">
      <Grid item xs={12} md={6} lg={6} style={{ paddingRight: '10px' }}>
        <SelectField
          disabled={false}
          standaloneLabel
          label={'Rate Type'}
          name={'rateType'}
          value={form.fields.rateType.value}
          options={setRateTypeOptionsBasedOnProgramType()}
          onChange={value => handleChangeYourLoanTerm('rateType', value)}
          variant="outlined"
          fullWidth
          testId="rateType"
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} style={{ paddingLeft: '10px' }}>
        <SelectField
          disabled={false}
          standaloneLabel
          label={'Program Type'}
          name={'programType'}
          value={form.fields.programType.value}
          options={store.pricerSummaryStore.programTypeOptions}
          onChange={value => handleChangeYourLoanTerm('programType', value)}
          variant="outlined"
          fullWidth
          testId="programType"
        />
      </Grid>

      <br />
    </Grid>
  );
})

const LoanTermsResult = observer(({ store }: { store: TermStore }) => {
  const classes = useStyles();
  const { pricerLoanTerms, form } = store.pricerSummaryStore;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const { dscrCalculatorStore } = store;
  return (
    <Grid
      container
      xs={12}
      md={6}
      lg={6}
      style={{
        width: '100%',
        border: 'solid 3px',
        margin: '32px 0px 32px 0px',
        borderColor: '#f3bc14',
        borderRadius: '8px',
        padding: '15px',
      }}
    >
      <Paper variant="elevation" elevation={0} style={{ width: '100%' }}>
        <Typography variant="h6">
          <strong>Loan Pricer Summary Results</strong>
        </Typography>
        <br />
        <List>
          {listItem('Loan Amount', `${formatCurrency(pricerLoanTerms.amount)}`)}
          {divider}
          {listItem(
            'Spread Charged To borrower',
            `${pricerLoanTerms.spreadRate}%`
          )}
          {divider}
          {listItem(
            'Monthly Payment Including Expenses',
            `${formatCurrency(pricerLoanTerms.monthlyPayment, 3)}`
          )}
          {divider}
          {allowLoanPricing &&
            listItem(
              'Yield Spread Premium',
              `${form.fields.oneTimeYieldSpreadPremium.value}% | ${formatCurrency(
                pricerLoanTerms.oneTimeYieldSpreadPremiumAmount
              )}`
            )}
          {divider}
          {(dscrCalculatorStore.calculatedValues?.fullyAmortizingDscrInIOPeriod) ?
            <>
              {listItem(
                'Fully Amortizing PITIA DSCR',
                dscrCalculatorStore.calculatedValues?.fullyAmortizingDscrInIOPeriod)}
            </>
            :
            <>
              {listItem(
                'NCF Fully Amortizing DSCR',
                dscrCalculatorStore.calculatedValues?.ncfFullyAmortizingDscr
              )}
            </>
          }
        </List>
      </Paper>
    </Grid>
  );
});

const PricerSummaryTermNew = ({ store, onNext }: PricerSummaryTermProps) => {
  const classes = useStyles();

  const { pricerSummaryStore, pricerStore, globalStore } = store;
  const {
    form,
    overRideRate,
    currentMinLtv,
    currentMaxLtv,
    rateTermLTV,
    showRateNotFoundErrorMsg,
    loanStandardRatesRows,
  } = pricerSummaryStore;
  const { maxYsp } = pricerSummaryStore.pricerLoanTerms;
  const { isSingleProperty } = pricerStore;
  const { anyDebt, totalDebtPayoff, targetLTV } = pricerStore.form.fields;
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const [showLoanRatesDialog, setShowLoanRatesDialog] = useState(false);
  const { pricerFeesEnabled } = globalStore.userFeatures;
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { loanToValue } = pricerSummaryStore.form.fields;

  async function fetchDSCRRates() {
    await pricerSummaryStore.fetchDSCRRates();
  }

  useEffect(() => {
    fetchDSCRRates();
  }, [pricerSummaryStore.rateForDSCR])

  useEffect(() => {
    if (!loanToValue.value) {
      pricerSummaryStore.setLoanToValue(targetLTV.value);
    }
    pricerSummaryStore.setPrePaymentPenaltyToDefault();
    pricerSummaryStore.fetchRatesAndLoanTermsV2();
    pricerSummaryStore.fetchRatesForCustomQuotes();
  }, []);

  const buildLoanToValueMarks = () => {
    return [
      {
        value: currentMinLtv,
        label: `${currentMinLtv}%`,
      },
      ...insertIf(currentMinLtv !== currentMaxLtv, [
        {
          value: currentMaxLtv,
          label: `${currentMaxLtv}%`,
        }
      ]),
    ];
  };

  const buildYieldSpreadPremiumMarks = () => {
    return [
      {
        value: 0,
        label: '0%',
      },
      {
        value: maxYsp,
        label: `${maxYsp}%`,
      },
    ];
  };

  const buildBuydownOptions = () => {
    let buydownList = [];
    const maxValue = ((pricerSummaryStore.maxAllowedBuydown > 0) ? pricerSummaryStore.maxAllowedBuydown * 100 : pricerSummaryStore.maxAllowedBuydownStandard * 100);
    const maxValueRatio = pricerSummaryStore.buydownRatio ? pricerSummaryStore.buydownRatio : pricerSummaryStore.buydownRatioStandard;
    for (
      let buydown = 0;
      buydown <= maxValue;
      buydown++
    ) {
      let buydownPoints = (
        buydown /
        maxValueRatio /
        100
      ).toFixed(3);
      let buydownDecimal = buydown / 100;
      buydownList.push({
        value: buydownDecimal,
        label: `${buydownDecimal}% (${buydownPoints} pts)`,
      });
    }
    return buydownList;
  };

  const showRatesTable = (!showRateNotFoundErrorMsg && !overRideRate) ||
    loanStandardRatesRows;

  return (
    <Grid container style={{
      maxWidth: 1200,
      margin: 'auto'
    }}>
      <Grid item xs={12} style={{
        textAlign: 'center',
        marginBottom: 32
      }}>
        <Typography variant="h4">Loan Pricer Summary</Typography>
        <Typography variant="body1"></Typography>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={'left'} my={1}>
          <Typography gutterBottom>
            <Alert icon={<Info fontSize="medium" />} severity="info" style={{
              padding: 8
            }}>
              <Box component={'span'} fontSize={16}>
                Click "Customize" to create a custom quote by modifying the loan terms, fees etc., and "Submit"
                <br /><br />
                Just a heads-up: when you're personalizing your quote, you can only increase the interest rate from your current choice. To get the lowest rates available, select to customize the maximum buydown option
              </Box>
            </Alert>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{}}>
        <DetailedAccordion store={store} setShowLoanRatesDialog={setShowLoanRatesDialog} onNext={onNext} />
      </Grid>
      <DownloadableQuotesDialog
        store={store}
        open={showLoanRatesDialog}
        rateBuydownOptions={buildBuydownOptions()}
        onClose={() => setShowLoanRatesDialog(false)} />
    </Grid>
  );
};

export default observer(PricerSummaryTermNew);
