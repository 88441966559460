import { Service } from '@roc/feature-app-core';

export abstract class AppraisalReviewBaseService extends Service {

  abstract getLoanDetails(loanId: number);
  abstract getAutomatedAppraisalReviewReport(propertyId: number, loanId: number);
  abstract saveReview(propertyId: number, loanId: number, data: any);
  abstract downloadRevisionListPdf(propertyId: number, data: any);
  abstract approveReport(propertyId: number, loanId: number);
  abstract requestAppraisal1004D(propertyId: number, loanId: number);
  abstract createResolutionPerItem(data: any);
  abstract requestRevision(data: any);
  abstract getAllAppraiserFirmNames();
  abstract getAutomatedManualFlag(propertyId: number);
  abstract getReportEditableFlag(propertyId: number);
  abstract updateReportEditableFlag(propertyId: number, editable: boolean);
  abstract updateUwAppraisalReviewStatus(loanId: number, propertyId: number, status: string);
  abstract getAppraisalReviewItemNames();
  abstract getAutomatedAppraisalReviewPhotos(propertyId: number, loanId: number);
  abstract getAppraisalReviewVersion(propertyId: number, loanId: number);
  abstract getAutomatedManualFlagByLoanId(loanId: number);
}