import { observable, flow, makeObservable, action, computed } from 'mobx';
import { FormStore, UserStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { LoanSubmissionService } from '../../services/loanSubmissionService';
import { TermStore } from './termStore';
import { CustomQuote, PricerLoanTerms, RateScenario } from '@roc/feature-types';
import { v4 as uuidv4 } from 'uuid';
import * as constants from './pricerSummaryHelper';
import { CONDOMINIUM, DUPLEX, LoanSubType, PPP_0_NO_PREPAY, PPP_1_0_0, PURCHASE, SINGLE_FAMILY, TOWNHOME, downloadDocument, isNil, isNotBlank, prePaymentPenaltyOptions0To1Prepay, prePaymentPenaltyOptionsNoPrepayOnly } from '@roc/feature-utils';
import {
  FULL_RECOURSE,
  PPP_3_2_1,
  PPP_5_4_3_2_1,
  prePaymentPenaltyOptionsPortfolio,
  prePaymentPenaltyOptionsSingleProperty,
  prePaymentPenaltyOptionsMultifamilyMixedUse,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
} from '@roc/feature-utils';
import { GlobalStore } from '@roc/feature-app-core';
import { mapLoanPurpose } from './pricerSummaryHelper';
import { DscrCalculatorService } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/services/dscrCalculatorService';
import ta from 'date-fns/locale/ta';
import { getLoanSubtype } from 'libs/client-portal-shared/src/app/modules/dscrCalculator/utils/dscrCalculatorUtils';
import { LenderFeesFormStore } from './lenderFeesFormStore';
import { LoanCommissionStore } from '@roc/feature-loan-summary';
import { DEFAULT_FEE_LEGAL_REVIEW, DEFAULT_FEE_RETAIL_SERVICING_SETUP } from '../../utils/constants';

type FeesRow = {
  amount: number,
  category: string,
  key: string,
  name: string,
  percent: number,
  rowId: string | null
}
const pricerSummaryForm = {
  fields: {
    amortization: {
      value: constants.FULLY_AMORTIZING,
      error: null,
      rule: '',
    },
    prePaymentPenalty: {
      // Pre-Payment penalty default value is set at runtime because
      // the default value for Single Property and Portfolio are not equal
      value: '',
      error: null,
      rule: '',
    },
    oneTimeYieldSpreadPremium: {
      value: 0,
      error: null,
      rule: '',
    },
    rateType: {
      value: constants.RATE_TYPE_5,
      error: null,
      rule: '',
    },
    loanToValue: {
      value: '',
      error: null,
      rule: '',
    },
    loanTerm: {
      value: 30,
      error: null,
      rule: '',
    },
    seekingCashOut: {
      value: '',
      error: null,
      rule: '',
    },
    rateBuydown: {
      value: 0,
      error: null,
      rule: '',
    },
    programType: {
      value: constants.DSCR_EXPANDED,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export const mapLtvToValue = ltv => {
  switch (ltv) {
    case '0-5':
      return 5;
    case '6-10':
      return 10;
    case '11-15':
      return 15;
    case '16-20':
      return 20;
    case '21-25':
      return 25;
    case '26-30':
      return 30;
    case '31-35':
      return 35;
    case '36-40':
      return 40;
    case '41-45':
      return 45;
    case '46-50':
      return 50;
    case '50-55':
      return 55;
    case '55-60':
      return 60;
    case '61-65':
      return 65;
    case '66-70':
      return 70;
    case '71-75':
      return 75;
    case '76-80':
      return 80;
    case '81-85':
      return 85;
  }
};

const mapValueToLtv = loanToValue => {
  switch (loanToValue) {
    case 5:
      return '0-5';
    case 10:
      return '6-10';
    case 15:
      return '11-15';
    case 20:
      return '16-20';
    case 25:
      return '21-25';
    case 30:
      return '26-30';
    case 35:
      return '31-35';
    case 40:
      return '36-40';
    case 45:
      return '41-45';
    case 50:
      return '46-50';
    case 55:
      return '50-55';
    case 60:
      return '55-60';
    case 65:
      return '61-65';
    case 70:
      return '66-70';
    case 75:
      return '71-75';
    case 80:
      return '76-80';
    case 85:
      return '81-85';
  }
};

const singlePropertyExpense = pricerStore => {
  const {
    annualTaxes,
    annualInsurance,
    annualHOA,
    monthlyGrossRent,
    units,
    totalAnnualUtilities,
    totalAnnualRepairsMaintenance,
    generalAdministrative,
    payroll,
    marketing,
    replacementReserves,
    totalAnnualPropertyManagementFees,
    isPropertyLeased,
    loanPurpose,
  } = pricerStore.form.fields;
  return {
    grossAnnualTaxes: annualTaxes.value,
    grossAnnualInsurance: annualInsurance.value,
    annualHoaDues: annualHOA.value,
    numberOfUnits: units.value,
    monthlyRent:
      isPropertyLeased.value === 'Y' || loanPurpose.value != PURCHASE
        ? monthlyGrossRent.value
        : Number(monthlyGrossRent.value) * 0.9,
    generalAdministrative: generalAdministrative.value,
    payroll: payroll.value,
    marketing: marketing.value,
    replacementReserves: replacementReserves.value,
    totalAnnualUtilities: totalAnnualUtilities.value,
    totalAnnualRepairsMaintenance: totalAnnualRepairsMaintenance.value,
    totalAnnualPropertyManagementFees: totalAnnualPropertyManagementFees.value,
  };
};

export const portfolioExpense = pricerStore => {
  const {
    totalGrossAnnualTaxes,
    totalGrossAnnualInsurance,
    totalAnnualHOADues,
    totalAnnualExpenses,
    totalAnnualUtilities,
    totalAnnualRepairsMaintenance,
    totalAnnualPropertyManagementFees,
    totalGrossMonthlyRent,
  } = pricerStore.form.fields;
  return {
    grossAnnualTaxes: totalGrossAnnualTaxes.value,
    grossAnnualInsurance: totalGrossAnnualInsurance.value,
    annualHoaDues: totalAnnualHOADues.value,
    // This field was removed from rental portfolio.
    // sending a value is required for calculation reasons
    totalOtherAnnualExpenses: '',
    totalAnnualUtilities: totalAnnualUtilities.value,
    totalAnnualRepairsMaintenance: totalAnnualRepairsMaintenance.value,
    totalAnnualPropertyManagementFees: totalAnnualPropertyManagementFees.value,
    monthlyRent: totalGrossMonthlyRent.value,
    generalAdministrative: '',
    payroll: '',
    marketing: '',
    replacementReserves: '',
  };
};

const FEE_AMOUNT_KEYS = [
  'rocUnderwritingFee',
  'rocCommitmentFee',
  'rocProcessingFee',
  'rocAdminFee',
  'rocBuydownFee',
  'retailUnderwritingFee',
  'retailCommitmentFee',
  'retailProcessingFee',
  'retailAdminFee',
  'retailBuydownFee',
  'underwritingFee',
  'commitmentFee',
  'processingFee',
  'adminFee',
  'lenderBuydownFee',
];

const FEE_PERCENT_KEYS = [
  'originationPointsRoc',
  'originationPointsTpo',
  'originationPointsRetail',
  'buydownPointsRoc',
  'buydownPointsTpo',
  'buydownPointsRetail',
];

export class PricerSummaryStore extends FormStore {
  private globalStore: GlobalStore;
  private loanSubmissionService: LoanSubmissionService;
  public dscrCalculatorService: DscrCalculatorService;
  private termStore: TermStore;
  public pricerLoanTerms: PricerLoanTerms;
  public rateTypeColumns: any;
  public rateTypeOptions: any;
  public rateTypeOptionsStandard: any;
  public programTypeOptions: any;
  public maxInvestorLtvBands: [];
  public maxInvestorLtvBandsStandard: [];
  public currentInvestorLtvBands: [];
  public currentMinLtv: number;
  public currentMaxLtv: number;
  public investorMinLtv: number;
  public loanRatesRows: any;
  public loanStandardRatesRows: any;
  public minRate: number;
  public maxRate: number;
  public serverExceptions: string[];
  public exceptions: string[];
  public swapRates: any;
  public exceptionCounter: number;
  public showRateNotFoundErrorMsg: boolean;
  public warningExceptions: string[];
  public isBroker: boolean = false;
  public haveRatesBeenFetched: boolean = false;
  public maxAllowedBuydown: number;
  public buydownRatio: number;
  public maxAllowedBuydownStandard: number;
  public buydownRatioStandard: number;
  private userStore: UserStore;
  public lowestLtvRates: any;
  public rateForDSCR: number;
  public loanRatesPdfData: any;
  public pricingOptions: any[] = [];
  public selectedPricingOption;
  public lenderFeesFormStore: LenderFeesFormStore;
  public ratesScenariosResponse: any;
  public ratesScenarios: RateScenario[];
  public customQuotes: CustomQuote[];
  public loanCommissionStore: LoanCommissionStore;
  public compareCustomQuoteCount: number;
  public targetRateScenario: RateScenario;
  public minTargetRate: number;
  public maxTargetRate: number;
  public isTargetRateSettingAvailable: boolean = false;
  public defaultPrepay: string = PPP_5_4_3_2_1;

  constructor(globalStore, termStore: TermStore, userStore: UserStore) {
    super({ ...pricerSummaryForm }, globalStore);
    this.globalStore = globalStore;
    this.loanSubmissionService = new LoanSubmissionService();
    this.dscrCalculatorService = new DscrCalculatorService();
    this.lenderFeesFormStore = new LenderFeesFormStore(globalStore, this);
    this.loanCommissionStore = new LoanCommissionStore(globalStore, userStore);
    this.termStore = termStore;
    this.userStore = userStore;
    this.setDefaults();

    makeObservable(this, {
      rateTypeColumns: observable,
      pricerLoanTerms: observable,
      rateTypeOptions: observable,
      rateTypeOptionsStandard: observable,
      programTypeOptions: observable,
      serverExceptions: observable,
      exceptionCounter: observable,
      warningExceptions: observable,
      loanRatesPdfData: observable,
      pricingOptions: observable,
      selectedPricingOption: observable,
      fetchRatesAndLoanTerms: flow,
      fetchLoanTermsBasedonYsp: flow,
      fetchSfrPricerLoanTermsValues: flow,
      downloadLoanRates: flow,
      downloadLoanFaqs: flow,
      fetchLoanRatesPdfData: flow,
      setMinsAndMaxs: flow,
      setMaxYsp: action,
      overRideRate: computed,
      showRateNotFoundErrorMsg: observable,
      prepaymentPenaltyOptions: computed,
      setLoanTerm: action,
      resetStore: action,
      setPrePaymentPenaltyToDefault: action,
      isPricerSummaryAvailable: computed,
      isBroker: observable,
      haveRatesBeenFetched: observable,
      updateRatesTable: flow,
      currentInvestorLtvBands: observable,
      maxAllowedBuydown: observable,
      buydownRatio: observable,
      fetchDSCRRates: flow,
      fetchRateForDSCR: flow,
      addToQuote: flow,
      removeFromQuote: flow,
      selectQuoteOption: flow,
      getUnderwritingProcessingFees: flow,
      loanLenderDetails: computed,
      unselectQuoteOption: flow,
      getDefaultFees: flow,
      setRates: flow,
      setBaseRateChargedToBorrower: flow,
      targetRateBuydown: computed,
      fetchRatesAndLoanTermsV2: flow,
      getDefaultFeesRequest: action,
      setRatesScenarios: flow,
      setValidationErrors: flow,
      ratesScenariosResponse: observable,
      ratesScenarios: observable,
      calculateRate: action,
      customQuotes: observable,
      editCustomQuote: action,
      removeCustomQuote: action,
      getSfrPricerLoanTermsRequestBodyV2: action,
      fetchSfrPricerLoanTermsValuesV2: flow,
      fetchLoanTermsBasedonYsV2: flow,
      fetchRatesAndLoanTermsForCustomQuote: flow,
      createCustomQuoteFeesInitialValues: action,
      addCustomQuoteFeeChange: action,
      deleteCustomQuoteFeeChange: action,
      handleCustomQuoteFeeChange: action,
      getCustomQuoteFeeKey: action,
      compareCustomQuoteCount: observable,
      editCompareCount: action,
      getDownloadRatesJsonV2: action,
      createPricingOptionsFromCustomQuote: action,
      setDataFromDraftLoan: action,
      fetchRatesForCustomQuotes: flow,
      handleCustomQuoteFeeChangeOnBlur: action,
      calculateCommissionsForCustomQuote: flow,
      createPricingOption: action,
      selectCustomQuote: action,
      adjustRatesAndLoanTermsForCustomQuote: flow,
      fetchDSCRRatesV2: flow,
      addCustomQuote: flow,
      selectRateScenario: flow,
      getFeesBodyForCommissions: action,
      fetchBrokerLoanTermsYspV2: flow,
      handleRateBuydownChange: action,
      handleRateBuydownSubmit: action,
      closingLegalReviewFee: computed,
      getAndSetTargetRateScenario: action,
      getBuydownForTargetedRate: flow,
      targetRateScenario: observable,
      isTargetRateSettingAvailable: observable,
    });
  }

  setTargetRate(rate) {
    this.targetRateScenario.rate = rate;
  }

  resetStore(isBroker = false) {
    this.isBroker = isBroker || this.globalStore.userFeatures.isBroker;
    this.setDefaults();
    this.termStore.dscrCalculatorStore.reset();
    this.pricingOptions = [];
    this.selectedPricingOption = null;
    this.lenderFeesFormStore.resetStore();
    this.reset();
  }

  private setDefaults() {
    this.rateTypeColumns = [];
    this.rateTypeOptions = [...constants.rateTypeOptions];
    this.rateTypeOptionsStandard = [...constants.rateTypeOptions];
    this.programTypeOptions = [...constants.programTypeOptions];
    this.loanRatesRows = [];
    this.loanStandardRatesRows = [];
    this.serverExceptions = [];
    this.exceptions = [];
    this.swapRates = [];
    this.exceptionCounter = 0;
    this.pricerLoanTerms = {
      paymentChoice: constants.SHARE_POINTS,
      loanTerm: 30,
      chargeBorrower: constants.RATES,
      amount: 0,
      monthlyPayment: 0,
      monthlyPaymentWithoutExpenses: 0,
      rateChargedToBorrower: 0,
      swapRate: 0,
      spreadRate: 0,
      maxYsp: 1,
      totalPremium: 0,
      lenderPremium: 0,
      outOfBox: false,
      oneTimeYieldSpreadPremiumAmount: 0,
      overRideRates: {},
      totalPoints: 0,
      programType: '',
    };
    this.showRateNotFoundErrorMsg = false;
    this.warningExceptions = [];
    this.haveRatesBeenFetched = false;
    this.swapRates = {};
    this.maxInvestorLtvBands = [];
    this.maxInvestorLtvBandsStandard = [];
    this.currentInvestorLtvBands = [];
    this.buydownRatio = 0;
    this.maxAllowedBuydown = 0;
    this.lowestLtvRates = {};
    this.rateForDSCR = null;
    this.ratesScenariosResponse = null;
    this.ratesScenarios = [];
    this.customQuotes = [];
    this.compareCustomQuoteCount = 0;
    this.targetRateScenario = null;
    this.minTargetRate = 0;
    this.maxTargetRate = 0;
    this.isTargetRateSettingAvailable = false;
    this.defaultPrepay = PPP_5_4_3_2_1;
  }

  setDataFromDraftLoan(draftLoan) {
    let customQ = [];
    if (draftLoan.customQuotes) {
      customQ = draftLoan.customQuotes.map((customQuote: { feesRows: FeesRow[] }) => {
        if (customQuote.feesRows.length > 0) {
          for (const fee of customQuote.feesRows) {
            if (!fee.rowId) {
              fee.rowId = uuidv4();
            }
          }
        }
        return customQuote;
      });
    }
    this.customQuotes = customQ;
  }

  *fetchRatesAndLoanTerms() {
    try {
      const userId = this.termStore.userStore?.userInformation?.userId;

      this.clearServerExceptions();

      this.haveRatesBeenFetched = true;

      const underwritingProcessingFees = yield this.getUnderwritingProcessingFees();
      const request = this.getRequest();
      const response: ApiResponse = yield this.loanSubmissionService.getRates({
        ...request,
        userId: userId,
        isBroker: this.isBroker,
        ...underwritingProcessingFees,
      });

      const {
        ltvRates,
        standardLtvRates,
        maxYspBasedonAmortizationType,
        overRideRatesBasedonAmortizationType,
        exceptions,
        allInvestorRates,
        allInvestorStandardRates,
        swapRates,
        maxInvestorLtvBands,
        maxInvestorLtvBandsStandard,
        maxBuydownAndRatio,
        maxBuydownAndRatioStandard,
        lowestLtvRates,
      } = response.data;

      this.exceptions = exceptions;
      this.swapRates = swapRates;
      this.maxInvestorLtvBands =
        maxInvestorLtvBands[this.form.fields.amortization.value];
      this.maxInvestorLtvBandsStandard =
        maxInvestorLtvBandsStandard[this.form.fields.amortization.value];
      this.currentInvestorLtvBands = this.maxInvestorLtvBands;
      this.pricerLoanTerms = {
        ...this.pricerLoanTerms,
        ...{
          loanTerms: ltvRates,
          standardLoanTerms: standardLtvRates,
          allInvestorRates,
          allInvestorStandardRates,
          maxYspBasedonAmortizationType,
          maxYsp:
            maxYspBasedonAmortizationType[this.form.fields.amortization.value],
          overRideRates: overRideRatesBasedonAmortizationType,
        },
      };
      this.buydownRatio = maxBuydownAndRatio['buydownRatio'];
      this.maxAllowedBuydown = maxBuydownAndRatio['maxAllowedBuydown'];
      this.buydownRatioStandard =
        maxBuydownAndRatioStandard['buydownRatioStandard'];
      this.maxAllowedBuydownStandard =
        maxBuydownAndRatioStandard['maxAllowedBuydownStandard'];
      this.lowestLtvRates = lowestLtvRates || {};

      this.validateProgramTypeOptions();

      this.setColumns();
      this.setRows();
      this.setStandardRows();
      yield this.setMinsAndMaxs();
      this.setErrorMessages();

    } catch (error) {
      console.log('Error setting rates', error);
      this.showRateNotFoundErrorMsg = true;
    }

    const oneTimeYieldSpreadPremium = this.form.fields.oneTimeYieldSpreadPremium
      .value;
    if (oneTimeYieldSpreadPremium > 0 || this.isBroker) {
      this.fetchLoanTermsBasedonYsp(oneTimeYieldSpreadPremium);
    }
  }

  *fetchRatesAndLoanTermsV2() {
    try {
      const userId = this.termStore.userStore?.userInformation?.userId;
      const {
        rateType,
        amortization,
        loanToValue,
      } = this.form.fields;
      this.clearServerExceptions();

      this.haveRatesBeenFetched = true;

      const ratesRequest = this.getRequest();
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2((ratesRequest.asIsValue * (ratesRequest.loanToValue / 100), 0));
      const request = {
        sfrFormData: {
          ...ratesRequest,
          userId: userId,
          isBroker: this.isBroker,
          currentBuydown: 0,
          buydownPoints: 0,
          ...underwritingProcessingFees,
        },
        buydownRequestData: this.getDefaultFeesRequest(),
      };
      const response: ApiResponse = yield this.loanSubmissionService.getRatesScenarios(request);

      this.setRatesScenarios(response.data);

      const ltvRange = constants.ltvMapper[loanToValue.value];
      let rateResults = [];
      const buydowns = Object.keys(response.data.scenarios).map(buydown => buydown);
      for (let key of buydowns) {
        rateResults.push(response.data.scenarios[key].ltvRates[rateType.value][amortization.value]?.baseRates[ltvRange]);
      };

      let selectedLtvRatesForValidation = {};
      for (let key of buydowns) {
        const parsedBuydown = Number(key);
        selectedLtvRatesForValidation[parsedBuydown] = response.data.scenarios[key].ltvRates[rateType.value][amortization.value]?.baseRates[ltvRange];
      };
      const errorValidationRequest = {
        sfrFormData: {
          ...request.sfrFormData,
          loanToValue: loanToValue.value,
        },
        selectedLtvRates: selectedLtvRatesForValidation,
      }
      const validationErrorResponse: ApiResponse = yield this.loanSubmissionService.getRateValidationErrors(errorValidationRequest);
      this.setValidationErrors(validationErrorResponse.data);
    } catch (error) {
      console.log('Error setting rates', error);
      this.showRateNotFoundErrorMsg = true;
      this.ratesScenariosResponse = null;
      this.ratesScenarios = [];
    }
  }

  *setRatesScenarios(data: any) {
    this.ratesScenariosResponse = data.scenarios;
    this.lenderFeesFormStore.setInitialDefaultFees({ data: data.defaultFees });

    const buydowns = Object.keys(data.scenarios).map(buydown => buydown).sort((a, b) => (Number(b) - Number(a)));
    const {
      rateType,
      amortization,
      prePaymentPenalty,
    } = this.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const { originationPointsRetail, originationPointsRoc } = this.lenderFeesFormStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    let maxFees = 0;
    let youMakeForWholesale = 0;
    let youMakeForWholesalePercent = 0;
    let youMakeForBroker = 0;
    let youMakeForBrokerPercent = 0;

    // check rates here
    let loanToValue = this.form.fields.loanToValue.value;

    let checkDone = false;
    while (!checkDone && loanToValue > 0) {
      const ltvRange = constants.ltvMapper[loanToValue];
      if (ltvRange || loanToValue >= 80) {
        checkDone = true;
        this.onFieldChange('loanToValue', loanToValue);
      } else {
        loanToValue += 1;
      }
    }

    const loanAmount = asIsValue * (loanToValue / 100);
    if (this.isBroker) {
      const brokerPoints = this.termStore.loanDetailsStore.form.fields.brokerPointsIn.value || 0;

      youMakeForBroker = brokerPoints ? loanAmount * (brokerPoints / 100) : 0;
      youMakeForBrokerPercent = brokerPoints;
    } else if (!this.isInternalLender) {
      youMakeForWholesale = this.createCustomQuoteFeesInitialValues(this.lenderFeesFormStore.defaultFees, loanAmount).reduce((sum, item) => sum + item.amount, 0);
      youMakeForWholesalePercent = (youMakeForWholesale * 100) / loanAmount;
    }

    let rates = [];

    // Using normal for-loop to avoid yield operator problems
    for (let i = 0; i < buydowns.length; i++) {
      const buydown = buydowns[i];
      const scenario = data.scenarios[buydown];
      const parsedBuydown = Number(buydown);
      const amortizationData = scenario.ltvRates[rateType.value][amortization.value];
      const ltvRange = constants.ltvMapper[loanToValue];
      const rateObj = amortizationData.baseRates[ltvRange];
      const rate = rateObj && rateObj?.errorMessage.length === 0 ? this.calculateRate(rateObj) : null;
      const maxRate = rate ? this.calculateMaxRate(rateObj.swapRate, amortizationData.maxRates[ltvRange]) : 0;
      const pointsAndFees = Math.round((loanAmount * (parsedBuydown / 100)) * 100) / 100;

      const rateScenario: RateScenario = {
        fullScenario: scenario,
        buydownKey: buydown,
        buydown: parsedBuydown,
        amortizationData,
        amortization: amortization.value,
        loanToValue: loanToValue,
        prePaymentPenalty: prePaymentPenalty.value,
        rateType: rateType.value,
        rate,
        baseRate: rateObj,
        pointsAndFees,
        pointsAndFeesPercent: parsedBuydown,
        pricerLoanTerms: {
          loanTerms: scenario.ltvRates,
          allInvestorRates: scenario.allInvestorRates,
          maxYspBasedonAmortizationType: scenario.maxYspBasedonAmortizationType,
          maxYsp: scenario.maxYspBasedonAmortizationType[amortization],
          overRideRates: scenario.overRideRatesBasedonAmortizationType,
        },
        commissions: {},
        minRate: rate,
        maxRate,
        yieldSpread: this.isBroker ? data.defaultFees.yieldSpreadMultiplier : 0,
        maxYieldSpread: scenario.maxYspBasedonAmortizationType[amortization.value],
        maxPointsAndFees: pointsAndFees,
        youMakeForWholesale: this.isInternalLender ? 0 : youMakeForWholesale,
        youMakeForWholesalePercent: this.isInternalLender ? 0 : youMakeForWholesalePercent,
        originationPointsRetail: originationPointsRetail.value,
        originationPointsRetailAmount: originationPointsRetail.value ? Number(originationPointsRetail.value) * loanAmount / 100 : 0,
        originationPointsRoc: originationPointsRoc.value,
        youMakeForBroker,
        youMakeForBrokerPercent,
        validationError: '',
      };
      // max buydown scenario
      if (i === 0) {
        maxFees = pointsAndFees;
      }

      if (!isNil(rateScenario.rate)) {
        const pricerLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(rateScenario, 0);
        if (!this.isBroker) {
          youMakeForWholesale = this.createCustomQuoteFeesInitialValues(this.lenderFeesFormStore.defaultFees, loanAmount).reduce((sum, item) => sum + item.amount, 0);
          const fees = youMakeForWholesale - rateScenario.originationPointsRetailAmount;
          const commissions = yield this.loanCommissionStore.getTermCommissionAPI({
            loanAmount,
            rocPoints: rateScenario.originationPointsRetail ?? 0,
            fees: fees || 0,
            lenderPremium: 0,
            totalPremium: pricerLoanTerms.totalPremium ?? 0,
            referralFee: this.termStore.loanDetailsStore.form.fields.referralFee.value || 0,
          });

          rateScenario.commissions = commissions.data?.data;
        }

        rateScenario.pricerLoanTerms = {
          ...rateScenario.pricerLoanTerms,
          ...pricerLoanTerms
        };
      }
      rates.push(rateScenario);
    }
    if (this.isBroker && rates[0].yieldSpread > 0) {
      const quoteList = rates.map((rate) => {
        return {
          ...rate,
          compare: false,
          fees: {},
          feesRows: [],
          totalAllocatedFees: 0,
          totalNonRocFees: 0,
        };
      });

      const yieldScenarios = yield this.fetchBrokerLoanTermsYspV2(quoteList);
      if (yieldScenarios) {
        rates = yieldScenarios;
      }
    }
    this.ratesScenarios = rates.map(rate => ({ ...rate, maxPointsAndFees: maxFees }));
    this.getAndSetTargetRateScenario();
  }

  *setValidationErrors(data: any) {
    this.ratesScenarios.forEach((scenario) => {
      const buydown = scenario.buydown;
      scenario.validationError = data[buydown];
    });
  }

  getAndSetTargetRateScenario() {
    const availableScenarios = this.ratesScenarios.filter((scenario) => !isNil(scenario.rate));
    if (availableScenarios.length < 4) {
      this.isTargetRateSettingAvailable = false;
      return;
    }
    const lowestBuydownScenario = availableScenarios.reduce((lowestScenario, currentScenario) => lowestScenario.buydown < currentScenario.buydown ? lowestScenario : currentScenario, availableScenarios[0]);
    this.targetRateScenario = {
      ...lowestBuydownScenario,
      rate: null,
    };
    if (this.isBroker) {
      const ltvBand = mapValueToLtv(lowestBuydownScenario.loanToValue);
      this.targetRateScenario.baseRate = lowestBuydownScenario.amortizationData.baseRates[ltvBand];
    }
    const rates = availableScenarios.map(scenario => scenario.rate);
    this.minTargetRate = Math.min(...rates);
    this.maxTargetRate = Math.max(...rates);
    this.isTargetRateSettingAvailable = true;
  }

  updateTargetRateScenario(scenario) {
    this.targetRateScenario = scenario;
  }

  calculateRate(data) {
    return Number((data.swapRate + data.spreadRate).toFixed(4));
  }

  calculateMaxRate(swapRate, data) {
    return Number((swapRate + data.spreadRate).toFixed(4));
  }

  *selectRateScenario(rateObj: RateScenario, callback = () => { }) {
    this.customQuotes = [];
    yield this.addCustomQuote(rateObj);
    this.selectCustomQuote(0);
    callback();
  }

  *addCustomQuote(rateObj: RateScenario) {
    const {
      rateType,
      loanToValue,
      amortization,
      prePaymentPenalty
    } = this.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
     const loanAmount = asIsValue * (loanToValue.value / 100);
    const dscr = yield this.fetchDSCRRatesV2(rateObj);

    const customQuote: CustomQuote = {
      ...rateObj,
      amortization: amortization.value,
      loanToValue: loanToValue.value,
      prePaymentPenalty: prePaymentPenalty.value,
      rateType: rateType.value,
      compare: false,
      fees: {
        ...this.lenderFeesFormStore.defaultFees,
        originationPointsTpo: this.lenderFeesFormStore.defaultFees?.originationPointsTpo || 0,
        originationPointsRoc: 0,
        originationPointsRetail: 0,
        lenderPointsShare: 0,
        rocPoints: 0,
        retailPoints: 0,
        lenderBuydownFee: 0,
        retailBuydownFee: 0,
        rocBuydownFee: 0,
      },
      feesRows: this.createCustomQuoteFeesInitialValues(this.lenderFeesFormStore.defaultFees, loanAmount),
      totalAllocatedFees: 0,
      totalNonRocFees: 0,
      dscr,
    };

    this.customQuotes.push(customQuote);
    this.adjustRatesAndLoanTermsForCustomQuote(customQuote, this.customQuotes.length - 1, customQuote.rate);
    this.calculateTotalFeeForCustomQuote(this.customQuotes.length - 1, loanAmount);
  }

  createCustomQuoteFeesInitialValues(defaultFees, loanAmount) {
    const {
      underwritingFee,
      commitmentFee,
      processingFee,
      adminFee,
      originationPointsTpo,
      brokerOriginationPoints,
      retailPoints,
      lenderBuydownFee,
    } = defaultFees;
    const rows = [];

    if (this.isBroker) {
      if (brokerOriginationPoints) {
        rows.push({
          category: 'Lender',
          name: 'Origination Points',
          amount: loanAmount * (brokerOriginationPoints / 100),
          percent: brokerOriginationPoints,
          key: 'brokerOriginationPoints'
        });
      }
    } else if (!this.isInternalLender) {
      if (originationPointsTpo) {
        rows.push({
          category: 'Lender',
          name: 'Origination Points',
          amount: loanAmount * (originationPointsTpo / 100),
          percent: originationPointsTpo,
          key: 'originationPointsTpo'
        });
      }
    } else {
      if (retailPoints) {
        rows.push({
          category: 'Lender',
          name: 'Origination Points',
          amount: loanAmount * (retailPoints / 100),
          percent: retailPoints,
          key: 'originationPointsRetail'
        });
      }
    }

    const isInternal = this.isInternalLender;
    if (underwritingFee) {
      rows.push({
        category: 'Lender',
        name: 'Underwriting Fee',
        amount: underwritingFee,
        percent: (underwritingFee * 100) / loanAmount,
        key: isInternal ? 'retailUnderwritingFee' : 'underwritingFee'
      });
    }
    if (commitmentFee) {
      rows.push({
        category: 'Lender',
        name: 'Commitment Fee',
        amount: commitmentFee,
        percent: (commitmentFee * 100) / loanAmount,
        key: isInternal ? 'retailCommitmentFee' : 'commitmentFee'
      });
    }
    if (processingFee) {
      rows.push({
        category: 'Lender',
        name: 'Processing Fee',
        amount: processingFee,
        percent: (processingFee * 100) / loanAmount,
        key: isInternal ? 'retailProcessingFee' : 'processingFee'
      });
    }
    if (adminFee) {
      rows.push({
        category: 'Lender',
        name: 'Admin Fee',
        amount: adminFee,
        percent: (adminFee * 100) / loanAmount,
        key: isInternal ? 'retailAdminFee' : 'adminFee'
      });
    }
    if (lenderBuydownFee) {
      rows.push({
        category: 'Lender',
        name: 'Buydown Fee',
        amount: lenderBuydownFee,
        percent: (lenderBuydownFee * 100) / loanAmount,
        key: isInternal ? 'retailBuydownFee' : 'lenderBuydownFee'
      });
    }

    return rows;
  }

  editCustomQuote(quote, index, loanTermsChanged, recalculate = true) {
    this.customQuotes[index] = quote;
    if (recalculate) {
      this.fetchRatesAndLoanTermsForCustomQuote(quote, index, loanTermsChanged);
    }
  }

  editCompareCount(quote, index, recalculate = true) {
    const { compare } = quote;
    if (this.compareCustomQuoteCount >= 3 && compare) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'You have already added the maximum number of options',
      });
    } else {
      this.compareCustomQuoteCount = this.compareCustomQuoteCount + (compare ? 1 : -1);
      this.editCustomQuote(quote, index, false, recalculate);
    }
  }

  removeCustomQuote(index) {
    const newQuotes = this.customQuotes.slice();

    newQuotes.splice(index, 1);
    this.customQuotes = newQuotes;

    this.compareCustomQuoteCount = this.customQuotes.filter(quote => quote.compare).length;
  }

  *fetchRatesForCustomQuotes() {
    const quotesCopy = this.customQuotes.slice();

    // Using normal for-loop to avoid yield operator problems
    for (let index = 0; index < quotesCopy.length; index++) {
      const quote = quotesCopy[index];
      yield this.fetchRatesAndLoanTermsForCustomQuote(quote, index, true);
    }
  }

  *fetchRatesAndLoanTermsForCustomQuote(customQuote: CustomQuote, index: number, loanTermsChanged: boolean) {
    try {
      const userId = this.termStore.userStore?.userInformation?.userId;
      const {
        amortization,
        prePaymentPenalty,
        loanToValue,
        rateType,
        buydown,
        pointsAndFees,
        rate,
        yieldSpread,
        pricerLoanTerms,
      } = customQuote;
      const yieldSpreadPremium = !isNil(rate) && !loanTermsChanged ? yieldSpread : 0;
      const { paymentChoice, chargeBorrower, loanTerm } = pricerLoanTerms;

      const ratesRequest = this.getRequest();
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(pointsAndFees);
      const request = {
        ...ratesRequest,
        userId: userId,
        isBroker: this.isBroker,
        ...underwritingProcessingFees,
        paymentChoice,
        chargeBorrower,
        loanTerm,
        rateType: rateType,
        oneTimeYieldSpreadPremium: yieldSpreadPremium,
        prePaymentPenalty: prePaymentPenalty,
        currentBuydown: 0,
        buydownPoints: 0,
        loanToValue,
        amortization,
      };
      const response: ApiResponse = yield this.loanSubmissionService.getRatesSingleQuote(request);

      const { isSingleProperty } = this.termStore.pricerStore;
      const {
        propertyValuation,
        loanPurpose,
        acquisitionPrice,
      } = this.termStore.pricerStore.form.fields;
      const {
        totalEstimatedAsIsValue,
      } = this.termStore.pricerStore.form.fields;
      const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
      const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;      // check rates here
      let newLoanToValue = loanToValue;

      let checkDone = false;
      while (!checkDone && newLoanToValue > 0) {
        const scenario = response.data;
        const amortizationData = scenario.ltvRates[rateType][amortization];
        const ltvRange = constants.ltvMapper[newLoanToValue];
        const rateObj = amortizationData.baseRates[ltvRange];
        const rate = rateObj && rateObj?.errorMessage.length === 0 ? 1 : null;
        if (rate) {
          checkDone = true;
        } else {
          newLoanToValue -= 5;
        }
      }
      while (!checkDone && newLoanToValue <= 80) {
        const scenario = response.data;
        const amortizationData = scenario.ltvRates[rateType][amortization];
        const ltvRange = constants.ltvMapper[newLoanToValue];
        const rateObj = amortizationData.baseRates[ltvRange];
        const rate = rateObj && rateObj?.errorMessage.length === 0 ? 1 : null;
        if (rate) {
          checkDone = true;
        } else {
          newLoanToValue += 5;
        }
      }

      const loanAmount = asIsValue * (newLoanToValue / 100);
      const scenario = response.data;
      const amortizationData = scenario.ltvRates[rateType][amortization];
      const ltvRange = constants.ltvMapper[newLoanToValue];
      const rateObj = amortizationData.baseRates[ltvRange];
      const newRate = rateObj.errorMessage.length === 0 ? this.calculateRate(rateObj) : null;
      const newMaxRate = newRate ? this.calculateMaxRate(rateObj.swapRate, amortizationData.maxRates[ltvRange]) : 0;

      const newQuote: CustomQuote = {
        ...customQuote,
        fullScenario: scenario,
        loanToValue: newLoanToValue,
        amortizationData,
        rate: newRate,
        minRate: loanTermsChanged ? newRate : customQuote.minRate,
        baseRate: loanTermsChanged ? rateObj : customQuote.baseRate,
        maxRate: newMaxRate,
        yieldSpread: newRate ? yieldSpreadPremium : 0,
        maxYieldSpread: newRate ? scenario.maxYspBasedonAmortizationType[amortization] : 0,
        pricerLoanTerms: {
          loanTerms: scenario.ltvRates,
          allInvestorRates: scenario.allInvestorRates,
          maxYspBasedonAmortizationType: scenario.maxYspBasedonAmortizationType,
          maxYsp: scenario.maxYspBasedonAmortizationType[amortization],
          overRideRates: scenario.overRideRatesBasedonAmortizationType,
        },
      }

      if (!isNil(newQuote.rate)) {
        const newLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(newQuote, yieldSpreadPremium);
        if (!this.isBroker) {
          const commissions = yield this.loanCommissionStore.getTermCommissionAPI({
            loanAmount,
            rocPoints: newQuote.originationPointsRetail,
            fees: newQuote.totalNonRocFees - (newQuote.originationPointsRetailAmount > 0 ? newQuote.originationPointsRetailAmount : 0),
            lenderPremium: newQuote.yieldSpread,
            totalPremium: newLoanTerms.totalPremium ?? 0,
            referralFee: this.termStore.loanDetailsStore.form.fields.referralFee.value || 0,
          });

          newQuote.commissions = commissions.data?.data;
        }
        newQuote.pricerLoanTerms = {
          ...newQuote.pricerLoanTerms,
          ...newLoanTerms,
        };
        const dscr = yield this.fetchDSCRRatesV2(customQuote);
        newQuote.dscr = dscr;
      }

      if (yieldSpreadPremium > 0 || this.isBroker) {
        this.fetchLoanTermsBasedonYsV2(newQuote, index);
      } else {
        this.customQuotes[index] = newQuote;
      }
    } catch (error) {
      console.log('Error setting rates for custom quote', error);
    }
  }

  *adjustRatesAndLoanTermsForCustomQuote(customQuote: CustomQuote, quoteIndex: number, targetRate: number) {
    try {
      const userId = this.termStore.userStore?.userInformation?.userId;
      const {
        amortization,
        prePaymentPenalty,
        loanToValue,
        rateType,
        buydown,
        rate,
        yieldSpread,
        pricerLoanTerms,
        amortizationData,
        pointsAndFees,
      } = customQuote;
      const yieldSpreadPremium = !isNil(rate) ? yieldSpread : 0;
      const { paymentChoice, chargeBorrower, loanTerm, loanTerms } = pricerLoanTerms;
      const ltvBand = mapValueToLtv(loanToValue);
      const selectedLtvRate = loanTerms[rateType][amortization].baseRates[ltvBand];

      const ratesRequest = this.getRequest();
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(pointsAndFees);
      const request = {
        sfrFormData: {
          ...ratesRequest,
          userId: userId,
          isBroker: this.isBroker,
          ...underwritingProcessingFees,
          paymentChoice,
          chargeBorrower,
          loanTerm,
          rateType: rateType,
          oneTimeYieldSpreadPremium: yieldSpreadPremium,
          prePaymentPenalty: prePaymentPenalty,
          currentBuydown: 0,
          buydownPoints: 0,
          loanToValue,
          amortization,
        },
        targetRate,
        ltvBand,
        selectedLtvRate,
      };

      if (this.isBroker) {
        const response: ApiResponse = yield this.loanSubmissionService.adjustRatesSingleQuote(request);
        const adjustmentData = response.data;

        const { isSingleProperty } = this.termStore.pricerStore;
        const {
          propertyValuation,
          loanPurpose,
          acquisitionPrice,
        } = this.termStore.pricerStore.form.fields;
        const {
          totalEstimatedAsIsValue,
        } = this.termStore.pricerStore.form.fields;
        const { originationPointsRetail } = this.lenderFeesFormStore.form.fields;
        const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
        const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
         const loanAmount = asIsValue * (loanToValue / 100);

        loanTerms[rateType][amortization].baseRates[ltvBand] = adjustmentData.selectedLtvRate;
        loanTerms[rateType][amortization].yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
        amortizationData.baseRates[ltvBand] = adjustmentData.selectedLtvRate;
        amortizationData.yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
        const newBuydown = adjustmentData.feeAllocationAmount * 100 / loanAmount;
        const newQuote: CustomQuote = {
          ...customQuote,
          amortizationData,
          rate: targetRate,
          buydown: newBuydown,
          pointsAndFees: adjustmentData.feeAllocationAmount,
          pointsAndFeesPercent: newBuydown,
          pricerLoanTerms: {
            ...pricerLoanTerms,
            loanTerms,
          },
        }

        if (!isNil(newQuote.rate)) {
          const newLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(newQuote, yieldSpreadPremium);
          newQuote.pricerLoanTerms = {
            ...newQuote.pricerLoanTerms,
            ...newLoanTerms,
          };

          const newDscr = yield this.fetchDSCRRatesV2(newQuote);
          newQuote.dscr = newDscr;
        }
        this.customQuotes[quoteIndex] = newQuote;
      } else {
        const response: ApiResponse = yield this.loanSubmissionService.calculateYieldSpreadForRateChange({
          ...request,
          selectedLtvRate: customQuote.baseRate
        });
        const adjustmentData = response.data;

        const { isSingleProperty } = this.termStore.pricerStore;
        const {
          propertyValuation,
          loanPurpose,
          acquisitionPrice,
        } = this.termStore.pricerStore.form.fields;
        const {
          totalEstimatedAsIsValue,
        } = this.termStore.pricerStore.form.fields;
        const { originationPointsRetail } = this.lenderFeesFormStore.form.fields;
        const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
        const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
         const loanAmount = asIsValue * (loanToValue / 100);
        let youMakeForWholesale = 0;
        let youMakeForWholesalePercent = 0;

        if (!this.isInternalLender) {
          const yieldSpreadBasedOnLoanAmount = adjustmentData.yieldSpread > 0 ?
            loanAmount * (adjustmentData.yieldSpread / 100)
            : 0;
          youMakeForWholesale = customQuote.totalNonRocFees + (yieldSpreadBasedOnLoanAmount * customQuote.fees.yieldSpreadMultiplier);
          youMakeForWholesalePercent = youMakeForWholesale * 100 / loanAmount;
        }

        loanTerms[rateType][amortization].baseRates[ltvBand] = adjustmentData.selectedLtvRate;
        loanTerms[rateType][amortization].yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
        amortizationData.baseRates[ltvBand] = adjustmentData.selectedLtvRate;
        amortizationData.yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
        const newQuote: CustomQuote = {
          ...customQuote,
          amortizationData,
          rate: targetRate,
          maxRate: adjustmentData.maxRate,
          yieldSpread: adjustmentData.yieldSpread,
          youMakeForWholesale,
          youMakeForWholesalePercent,
          pricerLoanTerms: {
            ...pricerLoanTerms,
            loanTerms,
          },
        }

        if (!isNil(newQuote.rate)) {
          const newLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(newQuote, adjustmentData.yieldSpread);
          const commissions = yield this.loanCommissionStore.getTermCommissionAPI({
            loanAmount,
            rocPoints: newQuote.fees.originationPointsRetail,
            fees: newQuote.totalNonRocFees - (newQuote.originationPointsRetailAmount > 0 ? newQuote.originationPointsRetailAmount : 0),
            lenderPremium: newQuote.yieldSpread,
            totalPremium: newLoanTerms.totalPremium ?? 0,
            referralFee: this.termStore.loanDetailsStore.form.fields.referralFee.value || 0,
          });

          newQuote.commissions = commissions.data?.data;
          newQuote.pricerLoanTerms = {
            ...newQuote.pricerLoanTerms,
            ...newLoanTerms,
          };

          const newDscr = yield this.fetchDSCRRatesV2(newQuote);
          newQuote.dscr = newDscr;
        }
        this.customQuotes[quoteIndex] = newQuote;
      }
    } catch (error) {
      console.log('Error setting rates for custom quote', error);
    }
  }

  *getBuydownForTargetedRate(currentScenario: RateScenario, targetRate: number) {
    try {
      const userId = this.termStore.userStore?.userInformation?.userId;
      const {
        amortization,
        prePaymentPenalty,
        loanToValue,
        rateType,
        rate,
        yieldSpread,
        pricerLoanTerms,
        amortizationData,
      } = currentScenario;
      const yieldSpreadPremium = !isNil(rate) ? yieldSpread : 0;
      const { paymentChoice, chargeBorrower, loanTerm, loanTerms } = pricerLoanTerms;
      const ltvBand = mapValueToLtv(loanToValue);

      const ratesRequest = this.getRequest();
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(0);
      const request = {
        sfrFormData: {
          ...ratesRequest,
          userId: userId,
          isBroker: this.isBroker,
          ...underwritingProcessingFees,
          paymentChoice,
          chargeBorrower,
          loanTerm,
          rateType: rateType,
          oneTimeYieldSpreadPremium: yieldSpreadPremium,
          prePaymentPenalty: prePaymentPenalty,
          currentBuydown: 0,
          buydownPoints: 0,
          loanToValue,
          amortization,
        },
        targetRate,
        ltvBand,
        selectedLtvRate: currentScenario.baseRate,
      };
      const response: ApiResponse = yield this.loanSubmissionService.adjustRatesSingleQuote(request);
      const adjustmentData = response.data;

      const { isSingleProperty } = this.termStore.pricerStore;
      const {
        propertyValuation,
        loanPurpose,
        acquisitionPrice,
      } = this.termStore.pricerStore.form.fields;
      const {
        totalEstimatedAsIsValue,
      } = this.termStore.pricerStore.form.fields;
      const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
      const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
      const loanAmount = asIsValue * (loanToValue / 100);

      loanTerms[rateType][amortization].baseRates[ltvBand] = adjustmentData.selectedLtvRate;
      loanTerms[rateType][amortization].yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
      amortizationData.baseRates[ltvBand] = adjustmentData.selectedLtvRate;
      amortizationData.yieldSpreadPremiumRates[ltvBand] = adjustmentData.selectedLtvRate;
      const newBuydown = adjustmentData.feeAllocationAmount * 100 / loanAmount;
      this.targetRateScenario.buydown = newBuydown;
      this.targetRateScenario.pointsAndFees = Math.round(adjustmentData.feeAllocationAmount * 100) / 100;
      this.targetRateScenario.pointsAndFeesPercent = newBuydown;
    } catch (error) {
      console.log('Error getting buydown for targeted rate', error);
    }
  }

  addCustomQuoteFeeChange(quoteIndex: number, category: string) {
    const customIdForRow = uuidv4();
    this.customQuotes[quoteIndex].feesRows.push({ category, name: '', amount: 0, percent: 0, key: '', rowId: customIdForRow });
  };

  deleteCustomQuoteFeeChange(quoteIndex: number, feeIndex: number) {
    const newFeesRows = this.customQuotes[quoteIndex].feesRows.slice();
    const rowToDelete = newFeesRows[feeIndex];

    newFeesRows.splice(feeIndex, 1);
    this.customQuotes[quoteIndex].feesRows = newFeesRows;
    if (rowToDelete.key) {
      this.customQuotes[quoteIndex].fees[rowToDelete.key] = 0;
      if (rowToDelete.key === 'originationPointsRetail') {
        this.customQuotes[quoteIndex].originationPointsRetail = 0;
        this.customQuotes[quoteIndex].originationPointsRetailAmount = 0;
      }
      if(rowToDelete.key === 'originationPointsRoc') {
        this.customQuotes[quoteIndex].originationPointsRoc = 0;
      }
    }

    const customQuote = this.customQuotes[quoteIndex];

    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const loanAmount = asIsValue * (customQuote.loanToValue / 100);

    this.calculateTotalFeeForCustomQuote(quoteIndex, loanAmount);
    this.calculateCommissionsForCustomQuote(customQuote, quoteIndex, loanAmount);

  };

  handleCustomQuoteFeeChange(quoteIndex: number, feeObj: any, propertyKey: string, value: any) {

    if (feeObj.key) {
      this.customQuotes[quoteIndex].fees[feeObj.key] = 0;
    }

    feeObj[propertyKey] = value;

    if (feeObj.name && feeObj.category) {
      feeObj.key = this.getCustomQuoteFeeKey(feeObj.name, feeObj.category);
    } else {
      feeObj.key = '';
    }

    if (feeObj.amount && FEE_AMOUNT_KEYS.includes(feeObj.key)) {
      this.customQuotes[quoteIndex].fees[feeObj.key] = feeObj.amount;
    }
    if (feeObj.percent && FEE_PERCENT_KEYS.includes(feeObj.key)) {
      this.customQuotes[quoteIndex].fees[feeObj.key] = feeObj.percent;
    }
    if (feeObj.key === 'originationPointsRetail') {
      this.customQuotes[quoteIndex].originationPointsRetail = feeObj.percent;
      this.customQuotes[quoteIndex].originationPointsRetailAmount = feeObj.amount;
    }
    if(feeObj.key === 'originationPointsRoc') {
        this.customQuotes[quoteIndex].originationPointsRoc = feeObj.percent;
      }
    const feeIndex = this.customQuotes[quoteIndex].feesRows.findIndex(fee => fee.rowId === feeObj.rowId);
    this.customQuotes[quoteIndex].feesRows[feeIndex] = feeObj;
    this.calculateTotalFeeForCustomQuote(quoteIndex);
  };

  handleCustomQuoteFeeChangeOnBlur(quoteIndex: number, feeIndex: number, propertyKey: string) {
    const feeObj = this.customQuotes[quoteIndex].feesRows[feeIndex];
    const customQuote = this.customQuotes[quoteIndex];

    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const loanAmount = asIsValue * (customQuote.loanToValue / 100);

    if (propertyKey === 'amount') {
      feeObj.percent = Number(((feeObj.amount * 100) / loanAmount).toFixed(4));
    } else {
      feeObj.amount = Number((loanAmount * feeObj.percent) / 100);
    }
    if (feeObj.key === 'originationPointsRetail') {
      this.customQuotes[quoteIndex].originationPointsRetail = feeObj.percent;
      this.customQuotes[quoteIndex].originationPointsRetailAmount = feeObj.amount;
    }
    if(feeObj.key === 'originationPointsRoc') {
        this.customQuotes[quoteIndex].originationPointsRoc = feeObj.percent;
      }

    this.customQuotes[quoteIndex].feesRows[feeIndex] = feeObj;
    this.calculateTotalFeeForCustomQuote(quoteIndex, loanAmount);
    if (customQuote.rate && feeObj.category === constants.CUSTOM_QUOTE_FEE_CATEGORIES.LENDER) {
      this.calculateCommissionsForCustomQuote(customQuote, quoteIndex, loanAmount);
    }
  };

  handleRateBuydownChange(newValue: number, customQuote: CustomQuote, quoteIndex: number, propertyKey: string) {
    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const loanAmount = asIsValue * (customQuote.loanToValue / 100);
    const parsedValue = Number(newValue) || 0;

    if (parsedValue === 0) {
      customQuote.pointsAndFeesPercent = parsedValue;
      customQuote.buydown = parsedValue;
      customQuote.pointsAndFees = parsedValue;
    } else if (propertyKey === 'amount') {
      customQuote.pointsAndFees = parsedValue > customQuote.maxPointsAndFees ? customQuote.maxPointsAndFees : parsedValue;
    } else {
      customQuote.pointsAndFeesPercent = parsedValue;
      const calculatedFeeAmount = Number((loanAmount * customQuote.pointsAndFeesPercent / 100).toFixed(2));
      if (calculatedFeeAmount > customQuote.maxPointsAndFees) {
        customQuote.pointsAndFees = customQuote.maxPointsAndFees;
        customQuote.pointsAndFeesPercent = Number((customQuote.pointsAndFees * 100 / loanAmount).toFixed(2));
      }
    }
  }

  handleRateBuydownSubmit(quoteIndex: number) {
    const customQuote = this.customQuotes[quoteIndex];
    const { isSingleProperty } = this.termStore.pricerStore;
    const {
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const {
      totalEstimatedAsIsValue,
    } = this.termStore.pricerStore.form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const loanAmount = asIsValue * (customQuote.loanToValue / 100);

    this.calculateTotalFeeForCustomQuote(quoteIndex, loanAmount);
    if (customQuote.rate) {
      this.calculateCommissionsForCustomQuote(customQuote, quoteIndex, loanAmount);
    }
  };

  determineIfGoodToSubmitQuote(quote: CustomQuote) {
    const remainingBuydown = quote.pointsAndFees - quote.totalAllocatedFees;
    const areAllFieldsNamed = quote.feesRows.map(fee => isNotBlank(fee.name) && isNotBlank(fee.key)).reduce((acc, isNamed) => { return acc && isNamed }, true);
    return remainingBuydown === 0 && areAllFieldsNamed;
  }

  determineIfPointToRocMissing(quote: CustomQuote) {
    if(quote.prePaymentPenalty !== PPP_0_NO_PREPAY) return false;
    if(quote.originationPointsRoc < 1) return true;
    else return false;
  }

  *calculateCommissionsForCustomQuote(quote: CustomQuote, quoteIndex: number, loanAmount: number) {
    try {
      if (!this.isBroker) {
        const commissions = yield this.loanCommissionStore.getTermCommissionAPI({
          loanAmount,
          rocPoints: quote.originationPointsRetail,
          fees: quote.totalNonRocFees - (quote.originationPointsRetailAmount > 0 ? quote.originationPointsRetailAmount : 0),
          lenderPremium: quote.yieldSpread,
          totalPremium: quote.pricerLoanTerms.totalPremium ?? 0,
          referralFee: this.termStore.loanDetailsStore.form.fields.referralFee.value || 0,
        });

        quote.commissions = commissions.data?.data;
        this.customQuotes[quoteIndex] = quote;
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  getCustomQuoteFeeKey(name: string, category: string) {
    if (category === constants.CUSTOM_QUOTE_FEE_CATEGORIES.ROC) {
      if (name === 'Origination Points') {
        return 'originationPointsRoc';
      }
      if (name === 'Underwriting Fee') {
        return 'rocUnderwritingFee';
      }
      if (name === 'Processing Fee') {
        return 'rocProcessingFee';
      }
      if (name === 'Commitment Fee') {
        return 'rocCommitmentFee';
      }
      if (name === 'Admin Fee') {
        return 'rocAdminFee';
      }
      if (name === 'Buydown Fee') {
        return 'rocBuydownFee';
      }
    } else if (category === constants.CUSTOM_QUOTE_FEE_CATEGORIES.LENDER) {
      if (this.isInternalLender) {
        if (name === 'Origination Points') {
          return 'originationPointsRetail';
        }
        if (name === 'Underwriting Fee') {
          return 'retailUnderwritingFee';
        }
        if (name === 'Processing Fee') {
          return 'retailProcessingFee';
        }
        if (name === 'Commitment Fee') {
          return 'retailCommitmentFee';
        }
        if (name === 'Admin Fee') {
          return 'retailAdminFee';
        }
        if (name === 'Buydown Fee') {
          return 'retailBuydownFee';
        }
      } else {
        if (name === 'Origination Points') {
          return 'originationPointsTpo';
        }
        if (name === 'Underwriting Fee') {
          return 'underwritingFee';
        }
        if (name === 'Processing Fee') {
          return 'processingFee';
        }
        if (name === 'Commitment Fee') {
          return 'commitmentFee';
        }
        if (name === 'Admin Fee') {
          return 'adminFee';
        }
        if (name === 'Buydown Fee') {
          return 'lenderBuydownFee';
        }
      }
    }
  }

  calculateTotalFeeForCustomQuote(quoteIndex: number, loanAmount?: number) {
    const feesRows = this.customQuotes[quoteIndex].feesRows;
    let totalAllocated = 0;
    let totalNonRoc = 0;

    feesRows.forEach(feeRow => {
      if (feeRow.category === constants.CUSTOM_QUOTE_FEE_CATEGORIES.ROC) {
        totalAllocated += Number(feeRow.amount);
      } else if (feeRow.category === constants.CUSTOM_QUOTE_FEE_CATEGORIES.LENDER) {
        totalNonRoc += Number(feeRow.amount);
      }
    })

    const customQuote = this.customQuotes[quoteIndex];
    customQuote.totalAllocatedFees = Math.round(totalAllocated * 100) / 100;
    customQuote.totalNonRocFees = totalNonRoc;

    if (loanAmount && !this.isInternalLender) {
      const yieldSpreadBasedOnLoanAmount = customQuote.yieldSpread > 0 ?
        loanAmount * (customQuote.yieldSpread / 100)
        : 0;
      customQuote.youMakeForWholesale = customQuote.totalNonRocFees + (yieldSpreadBasedOnLoanAmount * customQuote.fees.yieldSpreadMultiplier);
      customQuote.youMakeForWholesalePercent = customQuote.youMakeForWholesale * 100 / loanAmount;
    }
    this.customQuotes[quoteIndex] = customQuote;
  }

  *getUnderwritingProcessingFees() {
    if (this.globalStore.userFeatures.pricerFeesEnabled) {
      if (!this.lenderFeesFormStore.defaultFees) {
        yield this.getDefaultFees();
        return {
          pricerFeesEnabled: this.globalStore.userFeatures.pricerFeesEnabled,
          underwritingProcessingFees: this.lenderFeesFormStore.underwritingProcessingFees,
          buydownPoints: this.lenderFeesFormStore.form.fields?.originationPointsRoc?.value
        };
      }
      return {
        pricerFeesEnabled: this.globalStore.userFeatures.pricerFeesEnabled,
        underwritingProcessingFees: this.lenderFeesFormStore
          .underwritingProcessingFees,
        buydownPoints: this.lenderFeesFormStore.form.fields?.originationPointsRoc?.value
      };
    } else {
      return {};
    }
  }

  getUnderwritingProcessingFeesV2(pointsAndFees: number) {
    return {
      pricerFeesEnabled: true,
      underwritingProcessingFees: pointsAndFees ? [pointsAndFees] : [],
      buydownPoints: 0
    };
  }

  *getDefaultFees() {
    const data = this.getDefaultFeesRequest();
    yield this.lenderFeesFormStore.fetchDefaultFees(data);
  }

  getDefaultFeesRequest() {
    const { loanSubtype, pricerStore } = this.termStore;
    const { getNumberOfUnits, getPropertyTypes } = this.termStore;
    const { propertyType, totalEstimatedAsIsValue, propertyValuation, targetLTV, loanPurpose, acquisitionPrice, } = pricerStore.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    return {
      loanSubtype: loanSubtype,
      asIsValue: asIsValue,
      propertyType: propertyType?.value,
      propertyTypes: isSingleProperty
        ? null
        : getPropertyTypes(),
      noOfUnits: getNumberOfUnits(),
    };
  }

  validateProgramTypeOptions() {
    if (!this.buydownRatio) {
      this.programTypeOptions = [constants.programTypeOptions[1]];
    } else if (!this.buydownRatioStandard) {
      this.programTypeOptions = [constants.programTypeOptions[0]];
    } else this.programTypeOptions = [...constants.programTypeOptions];
  }

  *updateRatesTable() {
    this.setColumns();
    this.setRows();
    this.setStandardRows();

    const loanToValue = this.form.fields.loanToValue.value;
    if (loanToValue < this.currentMinLtv || loanToValue > this.currentMaxLtv) {
      this.onFieldChange('loanToValue', this.currentMinLtv);
    }

    this.setMinAndMaxRates();
    const rate = this.getRate(constants.RATES);
    this.pricerLoanTerms = {
      ...this.pricerLoanTerms,
      swapRate: rate.swapRate,
      spreadRate: rate.spreadRate,
    };

    this.setErrorMessages();
  }

  get isPricerSummaryAvailable() {
    return this.rateTypeColumns.length > 0;
  }

  get targetRateBuydown() {
    return ((this.lenderFeesFormStore.targetMargin ?? 0) * (this.pricerLoanTerms.amount ?? 0) / 100).toFixed(2);
  }

  *fetchLoanTermsBasedonYsp(oneTimeYieldSpreadPremium) {
    const {
      loanTerm,
      maxYsp,
      allInvestorRates,
      allInvestorStandardRates,
    } = this.pricerLoanTerms;
    const {
      propertyType,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
    } = this.termStore.pricerStore.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const sfrProperty = isSingleProperty
      ? null
      : this.termStore.getPropertyDataPortfolio();

    if (maxYsp > 0) {
      try {
        const underwritingProcessingFees = yield this.getUnderwritingProcessingFees();
        const data = {
          rates: this.pricerLoanTerms.loanTerms,
          standardRates: this.pricerLoanTerms.standardLoanTerms,
          yieldSpread: oneTimeYieldSpreadPremium,
          loanSubtype: this.termStore.loanSubtype,
          loanTerm: loanTerm,
          rateType: this.termStore.getRateType(
            this.form.fields.rateType,
            this.form.fields.amortization
          ),
          propertyType: propertyType?.value,
          propertyTypes: isSingleProperty
            ? null
            : this.termStore.getPropertyTypes(),
          userId: this.termStore.userStore.userInformation.userId,
          numberOfProperties: this.termStore.getNumberOfProperties(),
          noOfUnits: this.termStore.getNumberOfUnits(),
          asIsValue: this.termStore.asIsValue,
          allInvestorRates: allInvestorRates,
          allInvestorStandardRates: allInvestorStandardRates,
          sfrProperty,
          isBroker: this.isBroker,
          monthlyRent: this.termStore.monthlyRent,
          grossAnnualTaxes: this.termStore.annualTaxes,
          grossAnnualInsurance: this.termStore.annualInsurance,
          annualHOADues: this.termStore.annualHoa,
          totalAnnualPropertyManagementFees:
            totalAnnualPropertyManagementFees?.value,
          generalAdministrative: generalAdministrative?.value,
          payroll: payroll?.value,
          marketing: marketing?.value,
          replacementReserves: replacementReserves?.value,
          totalAnnualUtilities: totalAnnualUtilities?.value,
          totalAnnualRepaisMaintenance: totalAnnualRepairsMaintenance?.value,
          currentBuydown: this.form.fields.rateBuydown.value,
          loanToValue: this.form.fields.loanToValue.value,
          amortization: this.form.fields.amortization.value,
          prePaymentPenalty: this.form.fields.prePaymentPenalty.value,
          ...underwritingProcessingFees,
        };

        const response: ApiResponse = yield this.loanSubmissionService.getRatesBasedOnYsp(
          data
        );

        this.pricerLoanTerms.loanTerms = response.data.rates;
        this.pricerLoanTerms.standardLoanTerms = response.data.standardRates;
        this.maxInvestorLtvBands =
          response.data.maxInvestorLtvBands[
          this.form.fields.amortization.value
          ];
        this.maxInvestorLtvBandsStandard =
          response.data.maxInvestorLtvBandsStandard[
          this.form.fields.amortization.value
          ];
        this.currentInvestorLtvBands = this.maxInvestorLtvBands;
        this.lowestLtvRates = response.data.lowestLtvRates || {};
        this.setColumns();
        this.setRows();
        this.setStandardRows();
        this.setMinsAndMaxs();
      } catch (error) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while fetching loan rates.',
        });
      }
    }
    this.setErrorMessages();
  }

  *fetchLoanTermsBasedonYsV2(customQuote: CustomQuote, quoteIndex: number) {
    const {
      propertyType,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
    } = this.termStore.pricerStore.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const sfrProperty = isSingleProperty
      ? null
      : this.termStore.getPropertyDataPortfolio();

    try {
      const { amortization, buydown, loanToValue, fullScenario, prePaymentPenalty, rateType, pointsAndFees } = customQuote;
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(pointsAndFees);
      const data = {
        rates: fullScenario.ltvRates,
        standardRates: null,
        yieldSpread: Number(customQuote.yieldSpread.toFixed(4)),
        loanSubtype: this.termStore.loanSubtype,
        loanTerm: constants.rateTypeToLoanTerm[rateType],
        rateType: this.termStore.getRateType(
          rateType,
          amortization
        ),
        propertyType: propertyType?.value,
        propertyTypes: isSingleProperty
          ? null
          : this.termStore.getPropertyTypes(),
        userId: this.termStore.userStore.userInformation.userId,
        numberOfProperties: this.termStore.getNumberOfProperties(),
        noOfUnits: this.termStore.getNumberOfUnits(),
        asIsValue: this.termStore.asIsValue,
        allInvestorRates: fullScenario.allInvestorRates,
        allInvestorStandardRates: null,
        sfrProperty,
        isBroker: this.isBroker,
        monthlyRent: this.termStore.monthlyRent,
        grossAnnualTaxes: this.termStore.annualTaxes,
        grossAnnualInsurance: this.termStore.annualInsurance,
        annualHOADues: this.termStore.annualHoa,
        totalAnnualPropertyManagementFees:
          totalAnnualPropertyManagementFees?.value,
        generalAdministrative: generalAdministrative?.value,
        payroll: payroll?.value,
        marketing: marketing?.value,
        replacementReserves: replacementReserves?.value,
        totalAnnualUtilities: totalAnnualUtilities?.value,
        totalAnnualRepaisMaintenance: totalAnnualRepairsMaintenance?.value,
        currentBuydown: 0,
        buydownPoints: 0,
        loanToValue: loanToValue,
        amortization: amortization,
        prePaymentPenalty: prePaymentPenalty,
        ...underwritingProcessingFees,
      };

      const response: ApiResponse = yield this.loanSubmissionService.getRatesBasedOnYsp(
        data
      );

      const scenario = response.data;
      const amortizationData = scenario.rates[rateType][amortization];
      const ltvRange = constants.ltvMapper[loanToValue];
      const rateObj = amortizationData.baseRates[ltvRange];
      const newRate = rateObj.errorMessage.length === 0 ? this.calculateRate(rateObj) : null;

      const newQuote: CustomQuote = {
        ...customQuote,
        rate: newRate,
        pricerLoanTerms: {
          ...customQuote.pricerLoanTerms,
          loanTerms: scenario.rates,
        },
      }

      if (!isNil(newQuote.rate)) {
        const newLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(newQuote, customQuote.yieldSpread);

        newQuote.pricerLoanTerms = {
          ...newQuote.pricerLoanTerms,
          ...newLoanTerms,
        };
      }
      if (!isNil(quoteIndex)) {
        this.customQuotes[quoteIndex] = newQuote;
      } else {
        return newQuote;
      }
    } catch (error) {
      if (!isNil(quoteIndex)) {
        this.customQuotes[quoteIndex] = customQuote;
      }
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan rates.',
      });
    }
  }

  *fetchBrokerLoanTermsYspV2(quoteList: any) {
    const {
      propertyType,
      totalAnnualPropertyManagementFees,
      generalAdministrative,
      payroll,
      marketing,
      replacementReserves,
      totalAnnualUtilities,
      totalAnnualRepairsMaintenance,
    } = this.termStore.pricerStore.form.fields;
    const { isSingleProperty } = this.termStore.pricerStore;
    const sfrProperty = isSingleProperty
      ? null
      : this.termStore.getPropertyDataPortfolio();
    const request = {};

    try {
      for (let index = 0; index < quoteList.length; index++) {
        const customQuote = quoteList[index];
        const { amortization, loanToValue, fullScenario, prePaymentPenalty, rateType, pointsAndFees } = customQuote;
        const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(pointsAndFees);
        const data = {
          rates: fullScenario.ltvRates,
          standardRates: null,
          yieldSpread: Number(customQuote.yieldSpread.toFixed(4)),
          loanSubtype: this.termStore.loanSubtype,
          loanTerm: constants.rateTypeToLoanTerm[rateType],
          rateType: this.termStore.getRateType(
            rateType,
            amortization
          ),
          propertyType: propertyType?.value,
          propertyTypes: isSingleProperty
            ? null
            : this.termStore.getPropertyTypes(),
          userId: this.termStore.userStore.userInformation.userId,
          numberOfProperties: this.termStore.getNumberOfProperties(),
          noOfUnits: this.termStore.getNumberOfUnits(),
          asIsValue: this.termStore.asIsValue,
          allInvestorRates: fullScenario.allInvestorRates,
          allInvestorStandardRates: null,
          sfrProperty,
          isBroker: this.isBroker,
          monthlyRent: this.termStore.monthlyRent,
          grossAnnualTaxes: this.termStore.annualTaxes,
          grossAnnualInsurance: this.termStore.annualInsurance,
          annualHOADues: this.termStore.annualHoa,
          totalAnnualPropertyManagementFees:
            totalAnnualPropertyManagementFees?.value,
          generalAdministrative: generalAdministrative?.value,
          payroll: payroll?.value,
          marketing: marketing?.value,
          replacementReserves: replacementReserves?.value,
          totalAnnualUtilities: totalAnnualUtilities?.value,
          totalAnnualRepaisMaintenance: totalAnnualRepairsMaintenance?.value,
          currentBuydown: 0,
          buydownPoints: 0,
          loanToValue: loanToValue,
          amortization: amortization,
          prePaymentPenalty: prePaymentPenalty,
          ...underwritingProcessingFees,
        };
        request[customQuote.buydown] = data;
      }
      const response: ApiResponse = yield this.loanSubmissionService.getYieldSpreadPremiumScenarios({ scenarios: request });

      const yieldScenarios = response.data.scenarios;
      const buydowns = Object.keys(yieldScenarios).map(buydown => buydown).sort((a, b) => (Number(b) - Number(a)));
      const yieldSpreadQuotes = [];
      for (let i = 0; i < buydowns.length; i++) {
        const buydown = buydowns[i];
        const yieldScenario = yieldScenarios[buydown];
        const matchingQuoteFromList = quoteList.find((quote) => quote.buydown === Number(buydown));
        const quoteIndex = quoteList.findIndex((quote) => quote.buydown === Number(buydown));
        const { amortization, loanToValue, rateType } = matchingQuoteFromList;
        const amortizationData = yieldScenario.rates[rateType][amortization];
        const ltvRange = constants.ltvMapper[loanToValue];
        const rateObj = amortizationData.baseRates[ltvRange];
        const newRate = rateObj.errorMessage.length === 0 ? this.calculateRate(rateObj) : null;

        const newQuote: CustomQuote = {
          ...matchingQuoteFromList,
          rate: newRate,
          amortizationData,
          pricerLoanTerms: {
            ...matchingQuoteFromList.pricerLoanTerms,
            loanTerms: yieldScenario.rates,
          },
        }

        if (!isNil(newQuote.rate)) {
          const newLoanTerms = yield this.fetchSfrPricerLoanTermsValuesV2(newQuote, matchingQuoteFromList.yieldSpread);

          newQuote.pricerLoanTerms = {
            ...newQuote.pricerLoanTerms,
            ...newLoanTerms,
          };
        }
        yieldSpreadQuotes.push(newQuote);
      }
      const listOfNewRates = yieldSpreadQuotes.map(quote => quote.rate);
      const maxRate = Math.max(...listOfNewRates);
      yieldSpreadQuotes.map(quote => {
        quote.maxRate = maxRate;
        quote.minRate = quote.rate;
      });

      return yieldSpreadQuotes;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan rates.',
      });
    }
  }

  *fetchSfrPricerLoanTermsValues(rateChargedToBorrower) {
    if (!rateChargedToBorrower || rateChargedToBorrower <= 0) {
      this.clearLoanTermsFields();
      return;
    }

    try {
      const requestBody = this.getSfrPricerLoanTermsRequestBody(
        rateChargedToBorrower
      );
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFees();
      const response: ApiResponse = yield this.loanSubmissionService.postPricerLoanTerms(
        {
          ...requestBody,
          ...underwritingProcessingFees,
          isBroker: this.isBroker,
        }
      );

      const {
        loanAmount,
        monthlyPayment,
        monthlyPaymentWithoutExpenses,
        totalPremium,
        lenderPremium,
        yieldSpreadPremiumAmount,
        numberOfUnits,
        minRocPoints,
        boxes,
        investorSalePrices,
      } = response.data;

      const {
        loanPurpose,
        acquisitionPrice,
        totalDebtPayoff,
      } = this.termStore.pricerStore.form.fields;

      //TODO: Figure out if this is needed
      //this.pricerStore.setNumberOfUnits = numberOfUnits;

      const rate = this.getRate(constants.RATES);

      this.pricerLoanTerms = {
        ...this.pricerLoanTerms,
        ...{
          amount: loanAmount,
          loanPurpose: loanPurpose,
          acquisitionPrice: acquisitionPrice,
          totalDebtPayoff: totalDebtPayoff,
          monthlyPayment: monthlyPayment?.toFixed(3), //TODO: Use util here
          monthlyPaymentWithoutExpenses: monthlyPaymentWithoutExpenses?.toFixed(3),
          rateChargedToBorrower,
          swapRate: rate.swapRate,
          spreadRate: rate.spreadRate,
          totalPremium,
          lenderPremium,
          outOfBox: false,
          oneTimeYieldSpreadPremiumAmount: yieldSpreadPremiumAmount,
          investorId: rate?.investorId,
          minRocPoints: minRocPoints,
          boxes: boxes,
          investorSalePrices: investorSalePrices,
        },
      };
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan terms.',
      });
    }
  }

  *fetchSfrPricerLoanTermsValuesV2(rateScenario: RateScenario, oneTimeYieldSpreadPremium: number) {
    const rateChargedToBorrower = rateScenario.rate;

    try {
      const requestBody = this.getSfrPricerLoanTermsRequestBodyV2(rateScenario, oneTimeYieldSpreadPremium);
      const underwritingProcessingFees = yield this.getUnderwritingProcessingFeesV2(rateScenario.pointsAndFees);
      const response: ApiResponse = yield this.loanSubmissionService.postPricerLoanTerms(
        {
          ...requestBody,
          currentBuydown: 0,
          buydownPoints: 0,
          ...underwritingProcessingFees,
          isBroker: this.isBroker,
        }
      );

      const {
        loanAmount,
        monthlyPayment,
        monthlyPaymentWithoutExpenses,
        totalPremium,
        lenderPremium,
        yieldSpreadPremiumAmount,
        numberOfUnits,
        minRocPoints,
        boxes,
        investorSalePrices,
      } = response.data;

      const {
        loanPurpose,
        acquisitionPrice,
        totalDebtPayoff,
      } = this.termStore.pricerStore.form.fields;

      //TODO: Figure out if this is needed
      //this.pricerStore.setNumberOfUnits = numberOfUnits;

      const rate = rateScenario.pricerLoanTerms?.loanTerms?.[rateScenario.rateType]?.[rateScenario.amortization]?.yieldSpreadPremiumRates[constants.ltvMapper[rateScenario.loanToValue]];

      const loanTerms = {
        ...this.pricerLoanTerms,
        ...{
          amount: loanAmount,
          loanPurpose: loanPurpose,
          acquisitionPrice: acquisitionPrice,
          totalDebtPayoff: totalDebtPayoff,
          monthlyPayment: Number(monthlyPayment?.toFixed(3)), //TODO: Use util here
          monthlyPaymentWithoutExpenses: Number(monthlyPaymentWithoutExpenses?.toFixed(3)),
          rateChargedToBorrower,
          swapRate: rate.swapRate,
          spreadRate: rate.spreadRate,
          totalPremium,
          lenderPremium,
          outOfBox: false,
          oneTimeYieldSpreadPremiumAmount: yieldSpreadPremiumAmount,
          investorId: rate?.investorId,
          minRocPoints: minRocPoints,
          boxes: boxes,
          investorSalePrices: investorSalePrices,
        },
      };

      return loanTerms;
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan terms.',
      });
    }
  }

  *fetchDSCRRates() {
    if (this.rateForDSCR) {
      const { dscrCalculatorStore } = this.termStore;
      const { isSingleProperty } = this.termStore.pricerStore;
      const expenses = isSingleProperty
        ? singlePropertyExpense(this.termStore.pricerStore)
        : portfolioExpense(this.termStore.pricerStore);
      const {
        targetLTV,
        propertyValuation,
        annualHOA,
        totalAnnualRepairsMaintenance,
        totalAnnualPropertyManagementFees,
        replacementReserves,
        annualTaxes,
        annualInsurance,
        totalEstimatedAsIsValue,
        totalAnnualHOADues,
        totalGrossAnnualTaxes,
        totalGrossAnnualInsurance,
        loanPurpose,
        acquisitionPrice,
      } = this.termStore.pricerStore.form.fields;

      if (this.termStore.loanSubtype === LoanSubType.SINGLE_PROPERTY) {
        // FOR LOAN AMOUNT
        dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
          loanPurpose.value == PURCHASE ? Math.min(propertyValuation.value, acquisitionPrice.value) : propertyValuation.value;
        dscrCalculatorStore.form.fields.requestedLTV.value = targetLTV.value;
      } else {
        dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
          loanPurpose.value == PURCHASE ? Math.min(totalEstimatedAsIsValue.value, acquisitionPrice.value) : totalEstimatedAsIsValue.value;
        dscrCalculatorStore.form.fields.requestedLTV.value = targetLTV.value;
      }
      const numberOfUnits = this.termStore.getNumberOfUnits();
      const loan = {
        loanSubType: this.termStore.loanSubtype,
        unitCount: numberOfUnits,
        amount: dscrCalculatorStore.loanAmount,
      };

      const calculatorType = getLoanSubtype(loan);
      dscrCalculatorStore.form.fields.loanSubtype.value = calculatorType;

      yield dscrCalculatorStore.fetchDefaultValues();
      if (this.termStore.loanSubtype === LoanSubType.SINGLE_PROPERTY) {
        // FOR LOAN AMOUNT
        dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
          loanPurpose.value == PURCHASE ? Math.min(propertyValuation.value, acquisitionPrice.value) : propertyValuation.value;
        dscrCalculatorStore.form.fields.requestedLTV.value = targetLTV.value;

        // FOR AnnualQualifying Rent
        dscrCalculatorStore.form.fields.inPlaceMonthlyRent.value =
          expenses.monthlyRent;
        dscrCalculatorStore.form.fields.monthlyMarketRent.value =
          expenses.monthlyRent;

        dscrCalculatorStore.form.fields.rate.value = this.rateForDSCR;
        dscrCalculatorStore.form.fields.annualTaxes.value = annualTaxes.value;
        dscrCalculatorStore.form.fields.annualHOAFees.value = annualHOA.value;
        dscrCalculatorStore.form.fields.annualInsurance.value =
          annualInsurance.value;
        dscrCalculatorStore.form.fields.interestOnlyPeriod.value = 0;
      } else {
        // FOR LOAN AMOUNT
        dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
          loanPurpose.value == PURCHASE ? Math.min(totalEstimatedAsIsValue.value, acquisitionPrice.value) : totalEstimatedAsIsValue.value;
        dscrCalculatorStore.form.fields.requestedLTV.value = targetLTV.value;

        dscrCalculatorStore.form.fields.rate.value = this.rateForDSCR;
        dscrCalculatorStore.form.fields.annualHOAFees.value =
          totalAnnualHOADues.value;
        dscrCalculatorStore.form.fields.annualTaxes.value =
          totalGrossAnnualTaxes.value;
        dscrCalculatorStore.form.fields.annualInsurance.value =
          totalGrossAnnualInsurance.value;

        dscrCalculatorStore.form.fields.monthlyMarketRent.value =
          expenses.monthlyRent;
        dscrCalculatorStore.form.fields.interestOnlyPeriod.value = 0;

        dscrCalculatorStore.form.fields.propertyManagementFee.value =
          totalAnnualPropertyManagementFees.value;
        dscrCalculatorStore.form.fields.replacementReserves.value =
          replacementReserves.value;
        dscrCalculatorStore.form.fields.repairsAndMaintenance.value =
          totalAnnualRepairsMaintenance.value;
      }
      yield dscrCalculatorStore.calculateFullyAmortizingPeriod();
      yield dscrCalculatorStore.calculateDefaultsBasedOnGPR();
      yield dscrCalculatorStore.calculateGrossAnnualIncome();
      yield dscrCalculatorStore.calculateLoanAmount();
      yield dscrCalculatorStore.fetchCalculatedValues();
    }
  }

  *fetchRateForDSCR(value) {
    let rate = value.spreadRate + value.swapRate;
    this.rateForDSCR = rate;
  }

  *fetchDSCRRatesV2(rateScenario: RateScenario) {
    const { dscrCalculatorStore } = this.termStore;
    const { isSingleProperty } = this.termStore.pricerStore;
    const expenses = isSingleProperty
      ? singlePropertyExpense(this.termStore.pricerStore)
      : portfolioExpense(this.termStore.pricerStore);
    const {
      propertyValuation,
      annualHOA,
      totalAnnualRepairsMaintenance,
      totalAnnualPropertyManagementFees,
      replacementReserves,
      annualTaxes,
      annualInsurance,
      totalEstimatedAsIsValue,
      totalAnnualHOADues,
      totalGrossAnnualTaxes,
      totalGrossAnnualInsurance,
      loanPurpose,
      acquisitionPrice,
    } = this.termStore.pricerStore.form.fields;
    const isFullyAmortizing = rateScenario.amortization === 'fullyAmortizing';

    if (this.termStore.loanSubtype === LoanSubType.SINGLE_PROPERTY) {
      // FOR LOAN AMOUNT
      dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
        loanPurpose.value == PURCHASE ? Math.min(propertyValuation.value, acquisitionPrice.value) : propertyValuation.value;
      dscrCalculatorStore.form.fields.requestedLTV.value = rateScenario.loanToValue;
    } else {
      dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
        loanPurpose.value == PURCHASE ? Math.min(totalEstimatedAsIsValue.value, acquisitionPrice.value) : totalEstimatedAsIsValue.value;
      dscrCalculatorStore.form.fields.requestedLTV.value = rateScenario.loanToValue;
    }
    const numberOfUnits = this.termStore.getNumberOfUnits();
    const loan = {
      loanSubType: this.termStore.loanSubtype,
      unitCount: numberOfUnits,
      amount: dscrCalculatorStore.loanAmount,
    };

    const calculatorType = getLoanSubtype(loan);
    dscrCalculatorStore.form.fields.loanSubtype.value = calculatorType;

    yield dscrCalculatorStore.fetchDefaultValues();

    dscrCalculatorStore.form.fields.interestOnlyPeriod.value = isFullyAmortizing ? 0 : 120;
    if (this.termStore.loanSubtype === LoanSubType.SINGLE_PROPERTY) {
      // FOR LOAN AMOUNT
      dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
        loanPurpose.value == PURCHASE ? Math.min(propertyValuation.value, acquisitionPrice.value) : propertyValuation.value;
      dscrCalculatorStore.form.fields.requestedLTV.value = rateScenario.loanToValue;

      // FOR AnnualQualifying Rent
      dscrCalculatorStore.form.fields.inPlaceMonthlyRent.value =
        expenses.monthlyRent;
      dscrCalculatorStore.form.fields.monthlyMarketRent.value =
        expenses.monthlyRent;

      dscrCalculatorStore.form.fields.rate.value = rateScenario.rate;
      dscrCalculatorStore.form.fields.annualTaxes.value = annualTaxes.value;
      dscrCalculatorStore.form.fields.annualHOAFees.value = annualHOA.value;
      dscrCalculatorStore.form.fields.annualInsurance.value =
        annualInsurance.value;
    } else {
      // FOR LOAN AMOUNT
      dscrCalculatorStore.form.fields.estimatedAsIsValue.value =
        loanPurpose.value == PURCHASE ? Math.min(totalEstimatedAsIsValue.value, acquisitionPrice.value) : totalEstimatedAsIsValue.value;
      dscrCalculatorStore.form.fields.requestedLTV.value = rateScenario.loanToValue;

      dscrCalculatorStore.form.fields.rate.value = rateScenario.rate;
      dscrCalculatorStore.form.fields.annualHOAFees.value =
        totalAnnualHOADues.value;
      dscrCalculatorStore.form.fields.annualTaxes.value =
        totalGrossAnnualTaxes.value;
      dscrCalculatorStore.form.fields.annualInsurance.value =
        totalGrossAnnualInsurance.value;

      dscrCalculatorStore.form.fields.monthlyMarketRent.value =
        expenses.monthlyRent;

      dscrCalculatorStore.form.fields.propertyManagementFee.value =
        totalAnnualPropertyManagementFees.value;
      dscrCalculatorStore.form.fields.replacementReserves.value =
        replacementReserves.value;
      dscrCalculatorStore.form.fields.repairsAndMaintenance.value =
        totalAnnualRepairsMaintenance.value;
    }
    yield dscrCalculatorStore.calculateFullyAmortizingPeriod();
    yield dscrCalculatorStore.calculateDefaultsBasedOnGPR();
    yield dscrCalculatorStore.calculateGrossAnnualIncome();
    yield dscrCalculatorStore.calculateLoanAmount();
    yield dscrCalculatorStore.fetchCalculatedValues();

    if (isFullyAmortizing) {
      return dscrCalculatorStore.calculatedValues?.fullyAmortizingDscrInIOPeriod;
    }
    return dscrCalculatorStore.calculatedValues?.pitiaDscrInIOPeriod;
  }

  *downloadLoanRates() {
    try {
      const request = this.getRequest();

      const downloadRequest = { ...request, ...this.getDownloadRatesJson() };

      const response = yield this.loanSubmissionService.downloadRates(
        downloadRequest
      );

      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        'loan-rates.pdf'
      );
    } catch (error) {
      console.log('downloadLoanRates', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading loan rates.',
      });
    }
  }

  *fetchLoanRatesPdfData() {
    try {
      const request = this.getRequest();

      const response = yield this.loanSubmissionService.getLoanRatesPdfData({
        ...request,
        ...this.getDownloadRatesJsonV2(),
      });
      this.loanRatesPdfData = response.data;
    } catch (error) {
      console.log('fetchLoanRatesPdfData', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading loan rates.',
      });
    }
  }

  *downloadLoanFaqs() {
    try {
      const response = yield this.loanSubmissionService.downloadFaq();

      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        'loan-rates-faqs.pdf'
      );
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading loan rates faq.',
      });
    }
  }

  setPrePaymentPenaltyToDefault() {
    const { prePaymentPenalty } = this.form.fields;
    if (!prePaymentPenalty.value || prePaymentPenalty.value != this.defaultPrepay) {
      this.onFieldChange('prePaymentPenalty', this.defaultPrepay);
    }
  }

  get overRideRate() {
    const { amortization } = this.form.fields;
    const { overRideRates } = this.pricerLoanTerms;

    return this.exceptionCounter > 0 &&
      this.exceptions &&
      Object.keys(this.exceptions).length > 0
      ? true
      : !!overRideRates[amortization.value];
  }

  get prepaymentPenaltyOptions() {
    const { getPropertyTypes, properties } = this.termStore;
    const { isSingleProperty } = this.termStore.pricerStore;
    const { loanToValue } = this.form.fields;
    const {
      propertyType,
      propertyTypes,
      state,
      propertyStates,
      propertyValuation,
      loanPurpose,
      acquisitionPrice,
      totalEstimatedAsIsValue,
     } = this.termStore.pricerStore.form.fields;
    let val = propertyType?.value;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const loanAmount = asIsValue * (loanToValue.value / 100);
    const selectedState = isSingleProperty ? state.value : propertyStates.value;
    const isRestrictedPropertyType = isSingleProperty ? val &&
      (val === SINGLE_FAMILY ||
          val === DUPLEX ||
          val === TOWNHOME ||
          val === CONDOMINIUM) :
      getPropertyTypes().some(propertyType => {
          return (
            propertyType === SINGLE_FAMILY ||
            propertyType === DUPLEX ||
            propertyType === TOWNHOME ||
            propertyType === CONDOMINIUM
          );
    });
    const numberOfUnits = properties.reduce((count, p) => count + (p.numberOfProperties * Number(constants.getUnitsByPropertyType(p.propertyType))), 0);

    this.defaultPrepay = PPP_5_4_3_2_1;
    if(isRestrictedPropertyType && selectedState) {
      if(selectedState === 'OH' || selectedState === 'Ohio') {
        if(isSingleProperty || numberOfUnits <= 2) {
          if(loanAmount < 111000) {
            this.defaultPrepay = PPP_0_NO_PREPAY;
            this.onFieldChange('prePaymentPenalty', this.defaultPrepay);
            return prePaymentPenaltyOptionsNoPrepayOnly;
          } else {
            this.defaultPrepay = PPP_1_0_0;
            this.onFieldChange('prePaymentPenalty', this.defaultPrepay);
            return prePaymentPenaltyOptions0To1Prepay;
          }
        }
      }
      if((selectedState === 'PA' || selectedState === 'Pennsylvania') && loanAmount < 320000) {
        this.defaultPrepay = PPP_0_NO_PREPAY;
        this.onFieldChange('prePaymentPenalty', this.defaultPrepay);
        return prePaymentPenaltyOptionsNoPrepayOnly;
      }
    }

    if (val === MULTIFAMILY_5_PLUS || val === MIXED_USE) {
      return prePaymentPenaltyOptionsMultifamilyMixedUse[
        this.pricerLoanTerms.loanTerm
      ];
    }

    if (isSingleProperty) {
      return prePaymentPenaltyOptionsSingleProperty;
    }

    return prePaymentPenaltyOptionsPortfolio[this.pricerLoanTerms.loanTerm];
  }

  get rateTermLTV() {
    const { isSingleProperty, form } = this.termStore.pricerStore;
    const { loanPurpose, totalDebtPayoff, propertyValuation } = form.fields;
    const amount = isSingleProperty ? propertyValuation : totalDebtPayoff;
    let result = 0;

    if (
      loanPurpose.value === constants.RATE_TERM_REFINANCE &&
      !isNaN(totalDebtPayoff) &&
      !isNaN(amount)
    ) {
      result =
        ((Number(totalDebtPayoff) + Number(totalDebtPayoff * 0.03)) * 100) /
        amount;
    }

    return result;
  }

  checkIfRequiredFieldsEmpty = () => {
    const { amount, monthlyPayment } = this.pricerLoanTerms;
    let errorMsg = '';

    const rateChargedToBorrower =
      Number(this.getRate(constants.RATES)?.swapRate?.toFixed(4)) +
      Number(this.getRate(constants.RATES)?.spreadRate?.toFixed(4)) || 0;

    if (!this.userStore.allowLoanPricing) {
      return false;
    } else {
      if (this.overRideRate) {
        return false;
      }

      if (!amount) {
        errorMsg = 'Loan amount cannot be 0';
        this.termStore.stepError = errorMsg;
        return true;
      }
      if (!monthlyPayment) {
        errorMsg = 'Monthly payment cannot be 0';
        this.termStore.stepError = errorMsg;
        return true;
      }
      if (!rateChargedToBorrower) {
        errorMsg = 'Rate Charged to Borrower cannot be 0';
        this.termStore.stepError = errorMsg;
        return true;
      }
      if (!this.selectedPricingOption) {
        errorMsg =
          'Please select one of the quote options to continue with loan submission';
        this.termStore.stepError = errorMsg;
        return true;
      }
    }
    return false;
  };

  clearServerExceptions() {
    this.serverExceptions = [];
    this.exceptions = [];
    this.showRateNotFoundErrorMsg = false;
    this.warningExceptions = [];
    this.exceptionCounter = 0;
  }

  clearLoanTermsFields() {
    if (this.overRideRate) {
      const {
        propertyValuation,
        targetLTV,
        totalEstimatedAsIsValue,
        loanPurpose,
        acquisitionPrice,
      } = this.termStore.pricerStore.form.fields;
      const { isSingleProperty } = this.termStore.pricerStore;
      const minLTVvalue = this.currentMinLtv;
      const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
      const asIsValueResult = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
      const targetLTVResult = targetLTV.value;
      const amount =
        !isNaN(targetLTVResult) && !isNaN(targetLTVResult)
          ? (targetLTVResult * asIsValueResult) / 100
          : 0;
      this.pricerLoanTerms = {
        ...this.pricerLoanTerms,
        ...{
          oneTimeYieldSpreadPremiumAmount: 0,
          amount: amount,
          monthlyPayment: 1,
          monthlyPaymentWithoutExpenses: 1,
          rateChargedToBorrower: 0,
          swapRate: 0,
          spreadRate: 0,
          loanToValue: minLTVvalue,
          totalPremium: 0,
          lenderPremium: 0,
          outOfBox: true,
          boxes: '',
          rateBuydown: 0,
        },
      };
      this.setLoanToValue(minLTVvalue);
      this.setErrorMessages();
      this.lenderFeesFormStore.resetStore();
    } else {
      const rate =
        Number(this.getRate(constants.RATES)?.swapRate?.toFixed(4)) +
        Number(this.getRate(constants.RATES)?.spreadRate?.toFixed(4)) || 0;
      this.pricerLoanTerms = {
        ...this.pricerLoanTerms,
        ...{
          oneTimeYieldSpreadPremiumAmount: 0,
          amount: 0,
          monthlyPayment: 0,
          monthlyPaymentWithoutExpenses: 0,
          rateChargedToBorrower: rate,
          swapRate: Number(this.getRate(constants.RATES)?.swapRate?.toFixed(4)),
          spreadRate: Number(
            this.getRate(constants.RATES)?.spreadRate?.toFixed(4)
          ),
          outOfBox: false,
          rateBuydown: 0,
        },
      };
      this.lenderFeesFormStore.resetStore();
    }
  }

  setMaxYsp(amortization) {
    const { maxYspBasedonAmortizationType } = this.pricerLoanTerms;

    this.pricerLoanTerms = {
      ...this.pricerLoanTerms,
      maxYsp: maxYspBasedonAmortizationType[amortization],
    };
  }

  setColumns() {
    const { paymentChoice } = this.pricerLoanTerms;
    const columns = [...constants.rateColumns];

    if (paymentChoice !== constants.SHARE_POINTS) {
      columns.splice(1, 1);
    }

    this.rateTypeColumns = columns;
  }

  setRows() {
    const { loanTerms } = this.pricerLoanTerms;
    const newFullyAmortizingRows = [];
    const newPartialInterestRows = [];
    const newFullyAmortizingRateTypeOptions = [];
    const newPartialInterestRateTypeOptions = [];
    const newFullyAmortizingProgramTypeOptions = [];
    const newPartialInterestProgramTypeOptions = [];

    let chargeBorrowerLabel = '';
    Object.keys(loanTerms).map(rateType => {
      //Iterate over rate types (5/6 ARM, 7/6 ARM, 10/6 ARM, 30 Yr FRM)
      loanTerms[rateType] &&
        Object.keys(loanTerms[rateType]).map(amortization => {
          //Iterate over amortization types (fullyAmortizing, partialInterest)
          loanTerms[rateType] &&
            Object.keys(loanTerms[rateType][amortization]).map(
              chargeBorrower => {
                //Iterate over charge for borrower (baseRates, maxRates [max will apply if paymentChoice == sharePoints])
                chargeBorrowerLabel = 'Rate';
                if (
                  chargeBorrower === constants.RATES &&
                  Object.keys(loanTerms[rateType][amortization][chargeBorrower])
                    .length > 0
                ) {
                  const ltvRates =
                    loanTerms[rateType][amortization][chargeBorrower];
                  const selectedLtv = mapValueToLtv(
                    this.pricerLoanTerms.loanToValue
                      ? this.pricerLoanTerms.loanToValue
                      : 5
                  );
                  if (amortization === constants.FULLY_AMORTIZING) {
                    newFullyAmortizingRows.push({
                      programType: ltvRates[selectedLtv]['programType'],
                      rateType,
                      chargeBorrower: chargeBorrowerLabel,
                      ...constants.defaultRow,
                      ...ltvRates,
                    });
                    if (
                      Object.keys(ltvRates).length > 0 &&
                      !this.isNotApplicableRow(ltvRates)
                    ) {
                      newFullyAmortizingRateTypeOptions.push(rateType);
                    }
                  } else {
                    newPartialInterestRows.push({
                      rateType,
                      chargeBorrower: chargeBorrowerLabel,
                      ...constants.defaultRow,
                      ...ltvRates,
                    });
                    if (
                      Object.keys(ltvRates).length > 0 &&
                      !this.isNotApplicableRow(ltvRates)
                    ) {
                      newPartialInterestRateTypeOptions.push(rateType);
                    }
                  }
                }
              }
            );
        });
    });

    const newFullyAmortizingOptions = this.getOptions(
      newFullyAmortizingRateTypeOptions
    );
    const newPartialInterestOptions = this.getOptions(
      newPartialInterestRateTypeOptions
    );
    const dropdownOptions = this.getRateTypesOptionsDependingOnAmortizationType(
      newFullyAmortizingOptions,
      newPartialInterestOptions
    );

    this.rateTypeOptions = dropdownOptions;
    this.loanRatesRows = this.getRowsDependingOnAmortizationType(
      newFullyAmortizingRows,
      newPartialInterestRows
    );
  }

  setStandardRows() {
    const { standardLoanTerms } = this.pricerLoanTerms;
    const newFullyAmortizingRows = [];
    const newPartialInterestRows = [];
    const newFullyAmortizingRateTypeOptions = [];
    const newPartialInterestRateTypeOptions = [];
    const newFullyAmortizingProgramTypeOptions = [];
    const newPartialInterestProgramTypeOptions = [];

    let chargeBorrowerLabel = '';
    Object.keys(standardLoanTerms).map(rateType => {
      //Iterate over rate types (5/6 ARM, 7/6 ARM, 10/6 ARM, 30 Yr FRM)
      standardLoanTerms[rateType] &&
        Object.keys(standardLoanTerms[rateType]).map(amortization => {
          //Iterate over amortization types (fullyAmortizing, partialInterest)
          standardLoanTerms[rateType] &&
            Object.keys(standardLoanTerms[rateType][amortization]).map(
              chargeBorrower => {
                //Iterate over charge for borrower (baseRates, maxRates [max will apply if paymentChoice == sharePoints])
                chargeBorrowerLabel = 'Rate';
                if (
                  chargeBorrower === constants.RATES &&
                  Object.keys(
                    standardLoanTerms[rateType][amortization][chargeBorrower]
                  ).length > 0
                ) {
                  const ltvRates =
                    standardLoanTerms[rateType][amortization][chargeBorrower];
                  const selectedLtv = mapValueToLtv(
                    this.pricerLoanTerms.loanToValue
                      ? this.pricerLoanTerms.loanToValue
                      : 5
                  );
                  if (amortization === constants.FULLY_AMORTIZING) {
                    newFullyAmortizingRows.push({
                      programType: ltvRates[selectedLtv]['programType'],
                      rateType,
                      chargeBorrower: chargeBorrowerLabel,
                      ...constants.defaultRow,
                      ...ltvRates,
                    });
                    if (
                      Object.keys(ltvRates).length > 0 &&
                      !this.isNotApplicableRow(ltvRates)
                    ) {
                      newFullyAmortizingRateTypeOptions.push(rateType);
                    }
                  } else {
                    newPartialInterestRows.push({
                      programType: ltvRates[selectedLtv]['programType'],
                      rateType,
                      chargeBorrower: chargeBorrowerLabel,
                      ...constants.defaultRow,
                      ...ltvRates,
                    });
                    if (
                      Object.keys(ltvRates).length > 0 &&
                      !this.isNotApplicableRow(ltvRates)
                    ) {
                      newPartialInterestRateTypeOptions.push(rateType);
                    }
                  }
                }
              }
            );
        });
    });

    const newFullyAmortizingOptions = this.getOptions(
      newFullyAmortizingRateTypeOptions
    );
    const newPartialInterestOptions = this.getOptions(
      newPartialInterestRateTypeOptions
    );
    const dropdownOptions = this.getRateTypesOptionsDependingOnAmortizationType(
      newFullyAmortizingOptions,
      newPartialInterestOptions
    );

    if (!this.rateTypeOptions || this.rateTypeOptions.length === 0) {
      this.onFieldChange('programType', constants.DSCR_STANDARD);
      this.rateTypeOptions = dropdownOptions;
    }

    this.rateTypeOptionsStandard = dropdownOptions;

    this.loanStandardRatesRows = this.getRowsDependingOnAmortizationType(
      newFullyAmortizingRows,
      newPartialInterestRows
    );
  }

  isNotApplicableRow(ltvRates) {
    let isNotApplicableRow = true;
    Object.keys(ltvRates).forEach(ltv => {
      if (ltvRates[ltv] && ltvRates[ltv].spreadRate && ltvRates[ltv].swapRate) {
        isNotApplicableRow = false;
        return isNotApplicableRow;
      }
    });
    return isNotApplicableRow;
  }

  setMinAndMaxLtv() {
    const currentLtvRates = this.getLtvRates(this.pricerLoanTerms.loanTerms);
    const currentStandardLtvRates = this.getLtvRates(
      this.pricerLoanTerms.standardLoanTerms
    );
    const ltvRateValues =
      currentLtvRates &&
      currentLtvRates.length > 0 &&
      currentLtvRates.map(ltv => mapLtvToValue(ltv));
    const standardLtvRateValues =
      currentStandardLtvRates &&
      currentStandardLtvRates.length > 0 &&
      currentStandardLtvRates.map(ltv => mapLtvToValue(ltv));

    if (
      ltvRateValues &&
      ltvRateValues.length > 0 &&
      standardLtvRateValues &&
      standardLtvRateValues.length > 0
    )
      this.currentMinLtv = Math.min(...ltvRateValues, ...standardLtvRateValues);
    else if (ltvRateValues && ltvRateValues.length > 0)
      this.currentMinLtv = Math.min(...ltvRateValues);
    else if (standardLtvRateValues && standardLtvRateValues.length > 0)
      this.currentMinLtv = Math.min(...standardLtvRateValues);
    else this.currentMinLtv = 50;

    if (
      ltvRateValues &&
      ltvRateValues.length > 0 &&
      standardLtvRateValues &&
      standardLtvRateValues.length > 0
    )
      this.currentMaxLtv = Math.max(...ltvRateValues, ...standardLtvRateValues);
    else if (ltvRateValues && ltvRateValues.length > 0)
      this.currentMaxLtv = Math.max(...ltvRateValues);
    else if (standardLtvRateValues && standardLtvRateValues.length > 0)
      this.currentMaxLtv = Math.max(...standardLtvRateValues);
    else this.currentMaxLtv = 50;
  }

  setMinInvestorLtv() {
    const ltvnumbers = this.maxInvestorLtvBands.map(ltvband =>
      Number(
        Object.keys(constants.ltvMapper).find(
          key => constants.ltvMapper[key] === ltvband
        )
      )
    );
    const standardLtvNumbers = this.maxInvestorLtvBandsStandard.map(ltvband =>
      Number(
        Object.keys(constants.ltvMapper).find(
          key => constants.ltvMapper[key] === ltvband
        )
      )
    );

    if (ltvnumbers?.length != 0 && standardLtvNumbers?.length != 0)
      this.investorMinLtv = Math.min(...ltvnumbers, ...standardLtvNumbers);
    else if (ltvnumbers?.length != 0)
      this.investorMinLtv = Math.min(...ltvnumbers);
    else if (standardLtvNumbers?.length != 0)
      this.investorMinLtv = Math.min(...standardLtvNumbers);
    else this.investorMinLtv = 50;
  }

  setLoanToValue = value => {
    this.onFieldChange('loanToValue', value);
  };

  setLoanTerm = value => {
    this.onFieldChange('loanTerm', value);
    this.pricerLoanTerms.loanTerm = value;
  };

  getLtvRates = loanTerms => {
    const { amortization, rateType } = this.form.fields;
    const { chargeBorrower } = this.pricerLoanTerms;
    const ltvRates =
      loanTerms[rateType.value][amortization.value][chargeBorrower];
    return Object.keys(ltvRates).filter(
      ltv =>
        ltvRates[ltv] && !!ltvRates[ltv].swapRate && !!ltvRates[ltv].spreadRate
    );
  };

  setMinAndMaxRates() {
    const { paymentChoice } = this.pricerLoanTerms;

    let min = 0;
    let max = 20;
    if (paymentChoice === constants.SHARE_POINTS) {
      min =
        Number(this.getRate('baseRates')?.swapRate?.toFixed(4)) +
        Number(this.getRate('baseRates')?.spreadRate?.toFixed(4));
      max =
        Number(this.getRate('maxRates')?.swapRate?.toFixed(4)) +
        Number(this.getRate('maxRates')?.spreadRate?.toFixed(4));
    }
    this.minRate = !isNaN(min) ? min : 0;
    this.maxRate = !isNaN(max) ? max : 0;
  }

  getRate = chargeBorrower => {
    const {
      amortization,
      rateType,
      loanToValue,
      programType,
    } = this.form.fields;
    let { loanTerms, standardLoanTerms } = this.pricerLoanTerms;

    if (!loanTerms) {
      return null;
    }
    if (!standardLoanTerms) {
      standardLoanTerms = loanTerms;
    }

    const expandedRate = (
      loanTerms[rateType.value] &&
      loanTerms[rateType.value][amortization.value] &&
      loanTerms[rateType.value][amortization.value][chargeBorrower] &&
      loanTerms[rateType.value][amortization.value][chargeBorrower][
      mapValueToLtv(loanToValue.value)
      ]
    );
    const standardRate = (
      standardLoanTerms[rateType.value] &&
      standardLoanTerms[rateType.value][amortization.value] &&
      standardLoanTerms[rateType.value][amortization.value][chargeBorrower] &&
      standardLoanTerms[rateType.value][amortization.value][chargeBorrower][
      mapValueToLtv(loanToValue.value)
      ]
    );

    if (programType.value === constants.DSCR_EXPANDED && expandedRate && (expandedRate.spreadRate || expandedRate.swapRate)) {
      return expandedRate;
    }

    if (programType.value === constants.DSCR_STANDARD && standardRate && (standardRate.spreadRate || standardRate.swapRate)) {
      return standardRate;
    }

    if (expandedRate && (expandedRate.spreadRate || expandedRate.swapRate)) {
      return expandedRate;
    }

    return standardRate;
  };

  getAllRatesForLtv = chargeBorrower => {
    const {
      amortization,
      rateType,
      loanToValue,
      programType,
    } = this.form.fields;
    const { allInvestorRates, allInvestorStandardRates } = this.pricerLoanTerms;

    let rates = [];
    if (programType.value === constants.DSCR_EXPANDED) {
      rates =
        allInvestorRates[rateType.value] &&
        allInvestorRates[rateType.value][amortization.value];
    } else {
      rates =
        allInvestorStandardRates[rateType.value] &&
        allInvestorStandardRates[rateType.value][amortization.value];
    }
    return rates.map(
      rate => rate?.[chargeBorrower]?.[mapValueToLtv(loanToValue.value)]
    );
  };

  *setMinsAndMaxs() {
    const { loanToValue } = this.form.fields;
    this.setMinAndMaxLtv();
    this.setMinInvestorLtv();

    let newLoanToValue = loanToValue.value;
    if (
      loanToValue.value < this.currentMinLtv ||
      loanToValue.value > this.currentMaxLtv
    ) {
      newLoanToValue = this.investorMinLtv;
      this.onFieldChange('loanToValue', newLoanToValue);
    }
    this.setCurrentInvestorLtvBands(newLoanToValue);
    yield this.setRates();
  }

  setCurrentInvestorLtvBands(loanToValue) {
    let newCurrentInvestorLtvBands;

    if (loanToValue === this.currentMaxLtv) {
      newCurrentInvestorLtvBands = this.getLtvAndNextBands(loanToValue - 10);
    } else if (loanToValue + 10 > this.currentMaxLtv) {
      newCurrentInvestorLtvBands = this.getLtvAndNextBands(loanToValue - 5);
    } else {
      newCurrentInvestorLtvBands = this.getLtvAndNextBands(loanToValue);
    }

    if (loanToValue < this.currentMinLtv) {
      this.onFieldChange('loanToValue', this.currentMinLtv);
    }
    this.currentInvestorLtvBands = newCurrentInvestorLtvBands;
  }

  getLtvAndNextBands(loanToValue: number) {
    return [
      mapValueToLtv(loanToValue),
      mapValueToLtv(loanToValue + 5),
      mapValueToLtv(loanToValue + 10),
    ];
  }

  *setRates() {
    this.setMinAndMaxRates();
    yield this.setBaseRateChargedToBorrower();
  }

  *setBaseRateChargedToBorrower() {
    const rateChargedToBorrower =
      Number(this.getRate(constants.RATES)?.swapRate?.toFixed(4)) +
      Number(this.getRate(constants.RATES)?.spreadRate?.toFixed(4)) || 0;
    yield this.fetchSfrPricerLoanTermsValues(rateChargedToBorrower);
  }

  setErrorMessages() {
    const { amortization } = this.form.fields;
    const { loanTerms, overRideRates } = this.pricerLoanTerms;
    const warningMsgs = [];
    this.serverExceptions = [];

    Object.keys(loanTerms).map(rateType => {
      loanTerms[rateType] &&
        Object.keys(
          loanTerms[rateType][amortization.value][constants.RATES]
        ).map(ltv => {
          const errorMsg =
            loanTerms[rateType][amortization.value][constants.RATES]?.[ltv]?.[
            'errorMessage'
            ];
          const warningMessage =
            loanTerms[rateType][amortization.value][constants.RATES]?.[ltv]?.[
            'warningMessage'
            ];

          if (
            errorMsg &&
            errorMsg.length > 0 &&
            this.currentInvestorLtvBands.some(band => band === ltv)
          ) {
            this.serverExceptions.push(errorMsg[0]);
            this.exceptionCounter += 1;
          }
          if (warningMessage && warningMessage.length > 0) {
            warningMsgs.push(warningMessage[0]);
          }
        });
    });
    const overRideRate = overRideRates[amortization.value]
      ? overRideRates[amortization.value]
      : false;
    const checkedExceptions =
      this.exceptions &&
        this.exceptions != null &&
        Array.isArray(this.exceptions)
        ? this.exceptions
        : [];
    const combinedExceptions = this.removeDuplicateFromArray([
      ...checkedExceptions,
      ...this.serverExceptions,
    ]);
    if (overRideRate || (checkedExceptions && checkedExceptions.length > 0)) {
      this.exceptions = combinedExceptions;
    }
    if (combinedExceptions && combinedExceptions.length > 0) {
      this.serverExceptions = combinedExceptions;
      this.exceptionCounter += 1;
    }
    const warningExceptions = this.removeDuplicateFromArray(warningMsgs);
    this.warningExceptions = warningExceptions;
    return combinedExceptions;
  }

  removeDuplicateFromArray = arr => {
    const uniqueChars = [];
    arr.forEach(c => {
      if (!uniqueChars.includes(c)) {
        uniqueChars.push(c);
      }
    });
    return uniqueChars;
  };

  getOptions(currentOptions) {
    const newOptions = [];
    const options = [...new Set(currentOptions)];
    options.map(option => {
      newOptions.push({
        value: option,
        label: option,
      });
    });
    return newOptions.sort(
      (a, b) =>
        constants.sfrRateTypesDisplayOrder[a.value] -
        constants.sfrRateTypesDisplayOrder[b.value]
    );
  }

  getRateTypesOptionsDependingOnAmortizationType(
    newFullyAmortizingRateTypeOptions,
    newPartialInterestRateTypeOptions
  ) {
    let rateTypeOptions = [];
    this.form.fields.amortization.value === constants.FULLY_AMORTIZING
      ? (rateTypeOptions = newFullyAmortizingRateTypeOptions)
      : (rateTypeOptions = newPartialInterestRateTypeOptions);

    return rateTypeOptions;
  }

  getRowsDependingOnAmortizationType(fullyAmortizingRows, partialInterestRows) {
    let newRows = [];
    newRows =
      this.form.fields.amortization.value === constants.FULLY_AMORTIZING
        ? fullyAmortizingRows
        : partialInterestRows;
    return newRows.sort(
      (a, b) =>
        constants.sfrRateTypesDisplayOrder[a.rateType] -
        constants.sfrRateTypesDisplayOrder[b.rateType]
    );
  }

  getSfrPricerLoanTermsRequestBody(rateChargedToBorrower) {
    const {
      loanToValue,
      rateType,
      oneTimeYieldSpreadPremium,
      prePaymentPenalty,
      amortization,
      seekingCashOut,
      rateBuydown,
    } = this.form.fields;
    const { paymentChoice, loanTerm } = this.pricerLoanTerms;
    const {
      pricerStore,
      loanSubtype,
      getPropertyTypes,
      getNumberOfUnits,
    } = this.termStore;
    const {
      form,
      isSingleProperty,
      totalMixUseEstimatedAsIsValue,
      residentialUnits,
      commercialUnits,
    } = pricerStore;
    const {
      propertyType,
      loanPurpose,
      propertyValuation,
      acquisitionPrice,
      units,
      anyDebt,
      totalAnnualExpenses,
      totalEstimatedAsIsValue,
      acquisitionDate,
      capitalImprovements,
    } = form.fields;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const formattedDate = acquisitionDate && new Date(acquisitionDate.value);

    const requestBody = {
      ltvBand: mapValueToLtv(loanToValue.value),
      asIsValue: asIsValue,
      finalRate: rateChargedToBorrower,
      selectedLtvRate: this.getRate(constants.BASE_RATES),
      ratesForSelectedLtv: this.getAllRatesForLtv(constants.BASE_RATES),
      amortization: amortization.value,
      rateTermType:
        amortization.value === constants.FULLY_AMORTIZING
          ? `${rateType.value} ${constants.FULLY_AMORTIZING_LABEL}`
          : rateType.value,
      typeOfRate:
        oneTimeYieldSpreadPremium.value === 0
          ? constants.BASE_RATES
          : constants.MAX_RATES,
      yieldSpreadPremium: oneTimeYieldSpreadPremium.value,
      totalAnnualExpenses: totalAnnualExpenses.value,
      loanPurpose: mapLoanPurpose(
        loanPurpose.value,
        seekingCashOut.value,
        anyDebt.value
      ),
      purchasePrice: acquisitionPrice.value,
      paymentChoice,
      prePaymentPenalty: prePaymentPenalty.value,
      loanSubtype,
      loanTerm,
      numberOfUnits: getNumberOfUnits(),
      residentialUnits: residentialUnits,
      commercialUnits: commercialUnits,
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      userId: this.termStore.userStore.userInformation?.userId,
      sfrProperty: !isSingleProperty
        ? this.termStore.getPropertyDataPortfolio()
        : null,
      numberOfProperties: !isSingleProperty
        ? this.termStore.getNumberOfProperties()
        : 1,
      rateBuydown: rateBuydown.value,
      purchaseDate: formattedDate ? formattedDate.toISOString() : null,
      capitalImprovements: capitalImprovements ? capitalImprovements.value : null,
    };

    return !isSingleProperty
      ? {
        ...requestBody,
        propertyTypes: getPropertyTypes(),
        ...portfolioExpense(pricerStore),
      }
      : {
        ...requestBody,
        propertyType: propertyType.value,
        ...singlePropertyExpense(pricerStore),
      };
  }

  getSfrPricerLoanTermsRequestBodyV2(rateScenario: RateScenario, oneTimeYieldSpreadPremium: number) {
    const {
      rate: rateChargedToBorrower,
      loanToValue,
      rateType,
      amortization,
      prePaymentPenalty,
      pricerLoanTerms,
    } = rateScenario;
    const paymentChoice = pricerLoanTerms?.paymentChoice || constants.SHARE_POINTS;
    const {
      // loanToValue,
      // rateType,
      // oneTimeYieldSpreadPremium,
      // prePaymentPenalty,
      // amortization,
      seekingCashOut,
      rateBuydown,
    } = this.form.fields;
    const {
      pricerStore,
      loanSubtype,
      getPropertyTypes,
      getNumberOfUnits,
    } = this.termStore;
    const {
      form,
      isSingleProperty,
      totalMixUseEstimatedAsIsValue,
      residentialUnits,
      commercialUnits,
    } = pricerStore;
    const {
      propertyType,
      loanPurpose,
      propertyValuation,
      acquisitionPrice,
      units,
      anyDebt,
      totalAnnualExpenses,
      totalEstimatedAsIsValue,
      acquisitionDate,
      capitalImprovements,
    } = form.fields;

    const selectedLtvRate = rateScenario.pricerLoanTerms?.loanTerms?.[rateType]?.[amortization]?.baseRates[constants.ltvMapper[rateScenario.loanToValue]];

    let ratesForSelectedLtv = rateScenario.fullScenario.allInvestorRates[rateType][amortization];
    ratesForSelectedLtv = ratesForSelectedLtv.map(
      rate => rate?.baseRates?.[mapValueToLtv(loanToValue)]
    );
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const asIsValue = loanPurpose.value == PURCHASE ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value) : propertyValueBasedOnLoanType;
    const formattedDate = acquisitionDate && new Date(acquisitionDate.value);

    const requestBody = {
      ltvBand: mapValueToLtv(loanToValue),
      asIsValue: asIsValue,
      finalRate: rateChargedToBorrower,
      selectedLtvRate,
      ratesForSelectedLtv,
      amortization: amortization,
      rateTermType:
        amortization === constants.FULLY_AMORTIZING
          ? `${rateType} ${constants.FULLY_AMORTIZING_LABEL}`
          : rateType,
      typeOfRate:
        oneTimeYieldSpreadPremium === 0
          ? constants.BASE_RATES
          : constants.MAX_RATES,
      yieldSpreadPremium: oneTimeYieldSpreadPremium,
      totalAnnualExpenses: totalAnnualExpenses.value,
      loanPurpose: mapLoanPurpose(
        loanPurpose.value,
        seekingCashOut.value,
        anyDebt.value
      ),
      purchasePrice: acquisitionPrice.value,
      paymentChoice,
      prePaymentPenalty: prePaymentPenalty,
      loanSubtype,
      loanTerm: constants.rateTypeToLoanTerm[rateType],
      numberOfUnits: getNumberOfUnits(),
      residentialUnits: residentialUnits,
      commercialUnits: commercialUnits,
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      userId: this.termStore.userStore.userInformation?.userId,
      sfrProperty: !isSingleProperty
        ? this.termStore.getPropertyDataPortfolio()
        : null,
      numberOfProperties: !isSingleProperty
        ? this.termStore.getNumberOfProperties()
        : 1,
      rateBuydown: rateBuydown.value,
      purchaseDate: formattedDate ? formattedDate.toISOString() : null,
      capitalImprovements: capitalImprovements ? capitalImprovements.value : null,
    };

    return !isSingleProperty
      ? {
        ...requestBody,
        propertyTypes: getPropertyTypes(),
        ...portfolioExpense(pricerStore),
      }
      : {
        ...requestBody,
        propertyType: propertyType.value,
        ...singlePropertyExpense(pricerStore),
      };
  }

  getRequest() {
    const { isSingleProperty } = this.termStore.pricerStore;
    return isSingleProperty
      ? {
        ...this.createSinglePropertyRequest(),
        ...singlePropertyExpense(this.termStore.pricerStore),
      }
      : {
        ...this.createPortfolioRequest(),
        ...portfolioExpense(this.termStore.pricerStore),
      };
  }

  createSinglePropertyRequest() {
    const {
      prePaymentPenalty,
      oneTimeYieldSpreadPremium,
      rateType,
      seekingCashOut,
      rateBuydown,
      loanToValue,
      amortization,
    } = this.form.fields;
    const { pricerStore, loanSubtype, userStore } = this.termStore;
    const userId = userStore.userInformation?.userId;
    const { getNumberOfUnits } = this.termStore;
    const { foreignNational } = pricerStore;
    const { paymentChoice, chargeBorrower, loanTerm } = this.pricerLoanTerms;
    const {
      propertyType,
      loanPurpose,
      residentialUnits,
      commercialUnits,
      residentialUnitsSqFtArea,
      commercialUnitsSqFtArea,
      residentialIncome,
      commercialIncome,
      propertyValuation,
      acquisitionPrice,
      acquisitionDate,
      anyDebt,
      monthlyGrossRent,
      units,
      capitalImprovements,
      commercialUnitsCurrentOnRent,
      propertyCounty,
      state,
      ficoProvidedAtOrigination,
      borrowerExp,
      bankruptcyDate,
      foreclosureDate,
      zipCode,
    } = pricerStore.form.fields;
    const formattedDate =
      acquisitionDate.value && new Date(acquisitionDate.value);
    const formattedBankruptcyDate =
      bankruptcyDate.value && new Date(bankruptcyDate.value);
    const formattedForeclosureDate =
      foreclosureDate.value && new Date(foreclosureDate.value);

    return {
      paymentChoice,
      chargeBorrower,
      loanTerm,
      propertyType: propertyType.value,
      asIsValue: loanPurpose.value == PURCHASE ? Math.min(propertyValuation.value, acquisitionPrice.value) : propertyValuation.value,
      monthlyRent: monthlyGrossRent.value,
      loanPurpose: mapLoanPurpose(
        loanPurpose.value,
        seekingCashOut.value,
        anyDebt.value
      ),
      purchasePrice: acquisitionPrice.value,
      purchaseDate: formattedDate ? formattedDate.toISOString() : null,
      capitalImprovements: capitalImprovements.value,
      creditScore: ficoProvidedAtOrigination.value,
      borrowerExp: borrowerExp.value,
      foreignNationalFlag: foreignNational,
      rateType: rateType.value,
      oneTimeYieldSpreadPremium: oneTimeYieldSpreadPremium.value,
      prePaymentPenalty: prePaymentPenalty.value,
      loanSubtype: loanSubtype,
      numberOfUnits: getNumberOfUnits(),
      commercialUnits: commercialUnits.value,
      residentialUnits: residentialUnits.value,
      commercialArea: commercialUnitsSqFtArea.value,
      residentialArea: residentialUnitsSqFtArea.value,
      commercialIncome: commercialIncome.value,
      residentialIncome: residentialIncome.value,
      bankruptcyDate: formattedBankruptcyDate,
      foreclosureDate: formattedForeclosureDate,
      state: state.value,
      propertyCounties: [propertyCounty.value],
      commercialUnitsCurrentOnRent: commercialUnitsCurrentOnRent.value,
      userId: userId,
      currentBuydown: rateBuydown.value,
      buydownFee:
        this.buydownRatio != 0 ? rateBuydown.value / this.buydownRatio : 0,
      loanToValue: loanToValue.value,
      amortization: amortization.value,
      zipCode: zipCode.value,
      hasSection8: pricerStore.getValue('section8') == 'Y',
      isRuralFromOriginator: pricerStore.getValue('rural') == null ? null : pricerStore.getValue('rural') == 'Y',
      anyDebt: pricerStore.getValue('anyDebt') == 'Y',
    };
  }

  createPortfolioRequest() {
    const {
      prePaymentPenalty,
      oneTimeYieldSpreadPremium,
      rateType,
      seekingCashOut,
      rateBuydown,
      loanToValue,
      amortization,
    } = this.form.fields;
    const {
      loanTerm,
      calculateRates,
      chargeBorrower,
      paymentChoice,
      totalPoints,
    } = this.pricerLoanTerms;
    const {
      pricerStore,
      loanSubtype,
      getPropertyTypes,
      getNumberOfProperties,
      getNumberOfUnits,
    } = this.termStore;
    const {
      foreignNational,
      totalMixUseEstimatedAsIsValue,
      residentialUnits,
      commercialUnits,
    } = pricerStore;
    const {
      loanPurpose,
      acquisitionPrice,
      anyDebt,
      totalEstimatedAsIsValue,
      totalGrossMonthlyRent,
      acquisitionDate,
      capitalImprovements,
      propertyManagement,
      propertyStates,
      minimumAsIsValue,
      ficoProvidedAtOrigination,
      borrowerExp,
      bankruptcyDate,
      foreclosureDate,
      loanRecourse,
    } = pricerStore.form.fields;
    const formattedDate =
      acquisitionDate.value && new Date(acquisitionDate.value);
    const formattedBankruptcyDate =
      bankruptcyDate.value && new Date(bankruptcyDate.value);
    const formattedForeclosureDate =
      foreclosureDate.value && new Date(foreclosureDate.value);

    return {
      calculateRates,
      chargeBorrower,
      paymentChoice,
      totalPoints,
      propertyTypes: getPropertyTypes(),
      asIsValue: loanPurpose.value == PURCHASE ? Math.min(totalEstimatedAsIsValue.value, acquisitionPrice.value) : totalEstimatedAsIsValue.value,
      monthlyRent: totalGrossMonthlyRent.value,
      loanPurpose: mapLoanPurpose(
        loanPurpose.value,
        seekingCashOut.value,
        anyDebt.value
      ),
      purchasePrice: acquisitionPrice.value,
      purchaseDate: formattedDate ? formattedDate.toISOString() : null,
      capitalImprovements: capitalImprovements.value,
      creditScore: ficoProvidedAtOrigination.value,
      borrowerExp: borrowerExp.value,
      foreignNationalFlag: foreignNational,
      rateType: rateType.value,
      oneTimeYieldSpreadPremium: oneTimeYieldSpreadPremium.value,
      prePaymentPenalty: prePaymentPenalty.value,
      propertyManagement: propertyManagement.value,
      propertyStates: [propertyStates.value],
      propertyCounties: this.termStore.getPropertyCounties(),
      numberOfProperties: getNumberOfProperties(),
      numberOfUnits: getNumberOfUnits(),
      loanSubtype: loanSubtype,
      loanTerm,
      geoTier: 'GeoTierStandard',
      fullRecourse: loanRecourse.value === FULL_RECOURSE,
      cashManagement: false,
      portfolio: '',
      disfavourMarket: '',
      bankruptcyDate: formattedBankruptcyDate,
      foreclosureDate: formattedForeclosureDate,
      commercialUnits: commercialUnits,
      residentialUnits: residentialUnits,
      minimumAsIsValue: minimumAsIsValue.value,
      commercialUnitsCurrentOnRent: '',
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      sfrProperty: this.termStore.getPropertyDataPortfolio(),
      currentBuydown: rateBuydown.value,
      buydownFee:
        this.buydownRatio != 0 ? rateBuydown.value / this.buydownRatio : 0,
      loanToValue: loanToValue.value,
      amortization: amortization.value,
      hasSection8: pricerStore.getValue('section8') == 'Y',
      isRuralFromOriginator: pricerStore.getValue('rural') == null ? null : pricerStore.getValue('rural') == 'Y',
      anyDebt: pricerStore.getValue('anyDebt') == 'Y',
    };
  }

  getDownloadRatesJson() {
    const {
      amortization,
      rateType,
      prePaymentPenalty,
      seekingCashOut,
      rateBuydown,
    } = this.form.fields;
    const {
      loanTerm,
      rateChargedToBorrower,
      paymentChoice,
      loanTerms,
      standardLoanTerms,
    } = this.pricerLoanTerms;
    const {
      propertyType,
      loanPurpose,
      propertyValuation,
      acquisitionPrice,
      acquisitionDate,
      anyDebt,
      totalDebtPayoff,
      monthlyGrossRent,
      capitalImprovements,
      address,
      propertyStates,
      totalGrossMonthlyRent,
      totalAnnualExpenses,
      totalEstimatedAsIsValue,
      ficoProvidedAtOrigination,
      borrowerExp,
      loanRecourse,
    } = this.termStore.pricerStore.form.fields;
    const {
      isSingleProperty,
      foreignNational,
      totalMixUseEstimatedAsIsValue,
    } = this.termStore.pricerStore;
    const numberOfUnits = this.termStore.getNumberOfUnits();
    const numberOfProperties = this.termStore.getNumberOfProperties();
    const addressValue = !isSingleProperty
      ? `${[propertyStates.value].join(
        ', '
      )} ${numberOfProperties} Property Portfolio`
      : address.value;
    const monthlyGrossRentValue = !isSingleProperty
      ? totalGrossMonthlyRent.value
      : monthlyGrossRent.value;
    const propertyValueBasedOnLoanType = !isSingleProperty
      ? totalEstimatedAsIsValue.value
      : propertyValuation.value;
    const propertyValuationValue = loanPurpose.value == PURCHASE
      ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value)
      : propertyValueBasedOnLoanType;
    const propertyTypeValue = !isSingleProperty
      ? this.termStore.getPropertyTypesAsString()
      : propertyType.value;
    const fullRecourse = !isSingleProperty
      ? loanRecourse.value === FULL_RECOURSE
        ? 'Full Recourse with pledge of equity'
        : 'Non Recourse with Bad Boy Carveouts and pledge of equity'
      : null;
    const cashManagement = 'No';
    const formattedDate =
      acquisitionDate.value && new Date(acquisitionDate.value);

    //download rates for currently selected ltv bands instead of max bands
    const maxInvestorLtvBands = this.currentInvestorLtvBands;

    const formatRatesForRate = (loanTerms, rateType) => ({
      ltv5:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_0_TO_5
        ],
      ltv10:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_6_TO_10
        ],
      ltv15:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_11_TO_15
        ],
      ltv20:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_16_TO_20
        ],
      ltv25:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_21_TO_25
        ],
      ltv30:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_26_TO_30
        ],
      ltv35:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_31_TO_35
        ],
      ltv40:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_36_TO_40
        ],
      ltv45:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_41_TO_45
        ],
      ltv50:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_46_TO_50
        ],
      ltv55:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_50_TO_55
        ],
      ltv60:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_55_TO_60
        ],
      ltv65:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_61_TO_65
        ],
      ltv70:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_66_TO_70
        ],
      ltv75:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_71_TO_75
        ],
      ltv80:
        loanTerms[rateType][amortization.value][constants.RATES][
        constants.FROM_76_TO_80
        ],
    });

    const formatRatesForPdf = () =>
      Object.keys(loanTerms).reduce((acc, key) => {
        const rateType = constants.sfrRateTypesPdf[key];
        acc[rateType] = formatRatesForRate(loanTerms, key);
        return acc;
      }, {});

    const formatStandardRatesForPdf = () =>
      Object.keys(standardLoanTerms).reduce((acc, key) => {
        const rateType = constants.sfrRateTypesPdf[key];
        acc[rateType] = formatRatesForRate(standardLoanTerms, key);
        return acc;
      }, {});

    const ratesJson = {
      address: addressValue,
      propertyValuation: propertyValuationValue,
      propertyType: propertyTypeValue,
      annualGrossRent: monthlyGrossRentValue * 12,
      totalAnnualExpenses: totalAnnualExpenses.value,
      acquisitionDate: formattedDate ? formattedDate.toDateString() : null,
      acquisitionPrice: acquisitionPrice.value,
      capitalImprovements: capitalImprovements.value,
      rateType:
        amortization === constants.FULLY_AMORTIZING
          ? `${rateType.value} ${constants.FULLY_AMORTIZING_LABEL}`
          : rateType.value,
      ficoProvidedOnOrigination: ficoProvidedAtOrigination.value,
      loanPurpose:
        constants.loanPurposeDic[
        mapLoanPurpose(loanPurpose.value, seekingCashOut.value, anyDebt.value)
        ],
      totalDebtPayoff: totalDebtPayoff.value,
      amortization: amortization.value,
      citizenshipStatus: foreignNational,
      rateChargedToBorrower,
      maxInvestorLtvBands,
      displayMaxRates:
        paymentChoice === constants.NO_ROC_POINTS ? null : paymentChoice,
      rates: formatRatesForPdf(),
      standardRates: formatStandardRatesForPdf(),
      prePaymentPenalty: prePaymentPenalty.value,
      loanSubtype: this.termStore.loanSubtype,
      loanTerm,
      numberOfUnits,
      numberOfProperties,
      fullRecourse,
      cashManagement,
      monthlyRent: monthlyGrossRentValue,
      borrowerExp: borrowerExp.value,
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      rateBuydown: rateBuydown.value,
    };
    const expenses = isSingleProperty
      ? singlePropertyExpense(this.termStore.pricerStore)
      : portfolioExpense(this.termStore.pricerStore);
    return {
      ...ratesJson,
      ...expenses,
    };
  }

  getDownloadRatesJsonV2() {
    const quotes = this.customQuotes.filter(quote => quote.compare);
    if (quotes.length === 0) {
      return {};
    }
    const {
      seekingCashOut,
    } = this.form.fields;
    const {
      amortization,
      rateType,
      prePaymentPenalty,
      buydown: rateBuydown,
      pricerLoanTerms,
    } = quotes[0];
    const {
      loanTerm,
      rateChargedToBorrower,
      paymentChoice,
      loanTerms,
      standardLoanTerms,
    } = pricerLoanTerms;
    const {
      propertyType,
      loanPurpose,
      propertyValuation,
      acquisitionPrice,
      acquisitionDate,
      anyDebt,
      totalDebtPayoff,
      monthlyGrossRent,
      capitalImprovements,
      address,
      propertyStates,
      totalGrossMonthlyRent,
      totalAnnualExpenses,
      totalEstimatedAsIsValue,
      ficoProvidedAtOrigination,
      borrowerExp,
      loanRecourse,
    } = this.termStore.pricerStore.form.fields;
    const {
      isSingleProperty,
      foreignNational,
      totalMixUseEstimatedAsIsValue,
    } = this.termStore.pricerStore;
    const numberOfUnits = this.termStore.getNumberOfUnits();
    const numberOfProperties = this.termStore.getNumberOfProperties();
    const addressValue = !isSingleProperty
      ? `${[propertyStates.value].join(
        ', '
      )} ${numberOfProperties} Property Portfolio`
      : address.value;
    const monthlyGrossRentValue = !isSingleProperty
      ? totalGrossMonthlyRent.value
      : monthlyGrossRent.value;
    const propertyValueBasedOnLoanType = isSingleProperty ? propertyValuation.value : totalEstimatedAsIsValue.value;
    const propertyValuationValue = loanPurpose.value == PURCHASE
      ? Math.min(propertyValueBasedOnLoanType, acquisitionPrice.value)
      : propertyValueBasedOnLoanType;
    const propertyTypeValue = !isSingleProperty
      ? this.termStore.getPropertyTypesAsString()
      : propertyType.value;
    const fullRecourse = !isSingleProperty
      ? loanRecourse.value === FULL_RECOURSE
        ? 'Full Recourse with pledge of equity'
        : 'Non Recourse with Bad Boy Carveouts and pledge of equity'
      : null;
    const cashManagement = 'No';
    const formattedDate =
      acquisitionDate.value && new Date(acquisitionDate.value);

    //download rates for currently selected ltv bands instead of max bands
    const maxInvestorLtvBands = this.currentInvestorLtvBands;

    const formatRatesForRate = (loanTerms, rateType) => ({
      ltv5:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_0_TO_5
        ],
      ltv10:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_6_TO_10
        ],
      ltv15:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_11_TO_15
        ],
      ltv20:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_16_TO_20
        ],
      ltv25:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_21_TO_25
        ],
      ltv30:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_26_TO_30
        ],
      ltv35:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_31_TO_35
        ],
      ltv40:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_36_TO_40
        ],
      ltv45:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_41_TO_45
        ],
      ltv50:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_46_TO_50
        ],
      ltv55:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_50_TO_55
        ],
      ltv60:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_55_TO_60
        ],
      ltv65:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_61_TO_65
        ],
      ltv70:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_66_TO_70
        ],
      ltv75:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_71_TO_75
        ],
      ltv80:
        loanTerms[rateType][amortization][constants.RATES][
        constants.FROM_76_TO_80
        ],
    });

    const formatRatesForPdf = () =>
      Object.keys(loanTerms).reduce((acc, key) => {
        const rateType = constants.sfrRateTypesPdf[key];
        acc[rateType] = formatRatesForRate(loanTerms, key);
        return acc;
      }, {});

    const ratesJson = {
      address: addressValue,
      propertyValuation: propertyValuationValue,
      propertyType: propertyTypeValue,
      annualGrossRent: monthlyGrossRentValue * 12,
      totalAnnualExpenses: totalAnnualExpenses.value,
      acquisitionDate: formattedDate ? formattedDate.toDateString() : null,
      acquisitionPrice: acquisitionPrice.value,
      capitalImprovements: capitalImprovements.value,
      rateType:
        amortization === constants.FULLY_AMORTIZING
          ? `${rateType} ${constants.FULLY_AMORTIZING_LABEL}`
          : rateType,
      ficoProvidedOnOrigination: ficoProvidedAtOrigination.value,
      loanPurpose:
        constants.loanPurposeDic[
        mapLoanPurpose(loanPurpose.value, seekingCashOut.value, anyDebt.value)
        ],
      totalDebtPayoff: totalDebtPayoff.value,
      amortization: amortization,
      citizenshipStatus: foreignNational,
      rateChargedToBorrower,
      maxInvestorLtvBands,
      displayMaxRates:
        paymentChoice === constants.NO_ROC_POINTS ? null : paymentChoice,
      rates: formatRatesForPdf(),
      standardRates: {},
      prePaymentPenalty: prePaymentPenalty,
      loanSubtype: this.termStore.loanSubtype,
      loanTerm,
      numberOfUnits,
      numberOfProperties,
      fullRecourse,
      cashManagement,
      monthlyRent: monthlyGrossRentValue,
      borrowerExp: borrowerExp.value,
      totalMixUseEstimatedAsIsValue: totalMixUseEstimatedAsIsValue,
      rateBuydown: rateBuydown,
    };
    const expenses = isSingleProperty
      ? singlePropertyExpense(this.termStore.pricerStore)
      : portfolioExpense(this.termStore.pricerStore);

    this.createPricingOptionsFromCustomQuote();
    return {
      ...ratesJson,
      ...expenses,
    };
  }

  getPricerLoanTerms(): PricerLoanTerms {
    const {
      prePaymentPenalty,
      oneTimeYieldSpreadPremium,
      rateType,
      programType,
      loanToValue,
      amortization,
      seekingCashOut,
      rateBuydown,
    } = this.form.fields;
    let outOfBox = this.pricerLoanTerms.outOfBox;
    let rateChargedToBorrower = this.pricerLoanTerms.rateChargedToBorrower;
    let exceptionMessage = null;

    if (Array.isArray(this.exceptions) && this.exceptions.length) {
      outOfBox = true;
      rateChargedToBorrower = 0;
      exceptionMessage = this.exceptions.join(',');
    }

    const rate = this.getRate(constants.RATES);

    return {
      amortization: amortization.value,
      loanTerm: this.pricerLoanTerms.loanTerm,
      amount: this.pricerLoanTerms.amount,
      monthlyPayment: this.pricerLoanTerms.monthlyPayment,
      monthlyPaymentWithoutExpenses: this.pricerLoanTerms.monthlyPaymentWithoutExpenses,
      rateChargedToBorrower: rateChargedToBorrower,
      totalPremium: this.pricerLoanTerms.totalPremium,
      lenderPremium: this.pricerLoanTerms.lenderPremium,
      outOfBox: outOfBox,
      oneTimeYieldSpreadPremiumAmount: this.pricerLoanTerms
        .oneTimeYieldSpreadPremiumAmount,
      investorId: this.pricerLoanTerms.investorId,
      boxes: this.pricerLoanTerms.boxes,
      loanToValue: loanToValue.value,
      amortizationLabel: constants.mapAmortization(
        this.form.fields.amortization.value
      ),
      prePaymentPenalty: prePaymentPenalty.value,
      oneTimeYieldSpreadPremium: oneTimeYieldSpreadPremium.value,
      rateType: rateType.value,
      exceptions: this.exceptions,
      exceptionMessage,
      investorSalePrices: JSON.stringify(
        this.pricerLoanTerms.investorSalePrices
      ),
      seekingCashOut: seekingCashOut.value,
      swapRate: rate && rate.swapRate ? rate.swapRate.toFixed(4) : 0,
      swapIndex: rate && rate.swapIndex ? rate.swapIndex.toFixed(3) : 0,
      spreadRate: rate && rate.spreadRate ? rate.spreadRate.toFixed(4) : 0,
      rateBuydown: rateBuydown.value,
      programType: programType.value,
    };
  }

  *addToQuote(rateRow, ltvBand) {
    try {
      if (this.pricingOptions.length < 3) {
        const programType = rateRow.programType || rateRow[ltvBand].programType;
        const rateType = rateRow.rateType;

        this.onFieldChange('rateType', rateRow.rateType);
        this.onFieldChange('programType', programType);
        this.onFieldChange('loanToValue', mapLtvToValue(ltvBand));
        yield this.setMinsAndMaxs();
        yield this.fetchRatesAndLoanTerms();
        yield this.setErrorMessages();
        this.selectedPricingOption = null;

        const formValues = this.getFormValues();
        const lenderFeesFormValues = this.lenderFeesFormStore.getFormValues();

        const {
          totalOriginationPoints,
          totalProcessingFees,
          totalAdminFees,
          totalUnderwritingFees,
          totalCommitmentFees,
          totalBuydownFees,
          totalBuydownPoints,
          valuationAndApplicationFees,
          underwritingAndProcessingFees,
          feeSubtotalWithoutPoints,
        } = this.lenderFeesFormStore;

        const option = {
          key: Symbol(),
          rateRow,
          ltvBand,
          rateType,
          formValues,
          programType,
          lenderFeesFormValues,
          totalOriginationPoints,
          totalProcessingFees,
          totalAdminFees,
          totalUnderwritingFees,
          totalCommitmentFees,
          totalBuydownFees,
          totalBuydownPoints,
          valuationAndApplicationFees,
          underwritingAndProcessingFees,
          feeSubtotalWithoutPoints,
          pricerLoanTerms: this.pricerLoanTerms,
        };

        this.pricingOptions = this.pricingOptions.concat(option);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Option added to the quote',
        });
      } else {
        this.globalStore.notificationStore.showWarningNotification({
          message: 'You have already added the maximum number of options',
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while adding option to the quote',
      });
    }
  }

  *removeFromQuote(option) {
    this.pricingOptions = this.pricingOptions.filter(
      item => item.key !== option.key
    );
  }

  *selectQuoteOption(option) {
    try {
      this.loadForm(option.formValues);
      this.lenderFeesFormStore.loadForm(option.lenderFeesFormValues);
      this.onFieldChange('rateType', option.rateType);
      this.onFieldChange('programType', option.programType);
      this.onFieldChange('loanToValue', mapLtvToValue(option.ltvBand));
      this.termStore.loanDetailsStore.onFieldChange('lenderPoints', option.lenderFeesFormValues.originationPointsTpo);
      yield this.setMinsAndMaxs();
      yield this.fetchRatesAndLoanTerms();
      yield this.setErrorMessages();
      this.selectedPricingOption = option;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while selecting option',
      });
    }
  }

  get loanLenderDetails() {
    return this.lenderFeesFormStore.loanLenderDetails;
  }

  *unselectQuoteOption() {
    this.selectedPricingOption = null;
  }

  createPricingOption(quote) {
    const {
      rateType,
      loanToValue,
      amortization,
      prePaymentPenalty,
      amortizationData,
      fees,
      yieldSpread,
      // loanTerm,
      buydown,
    } = quote;
    const ltvBand = mapValueToLtv(loanToValue);
    this.selectedPricingOption = null;

    const formValues = this.getFormValues();
    const programType = amortizationData.baseRates[ltvBand].programType;

    const totalOriginationPoints = (fees.originationPointsRoc || 0) + (fees.originationPointsTpo || 0) + (fees.originationPointsRetail || 0) + (fees.brokerOriginationPoints || 0) + (+this.termStore.loanDetailsStore.form.fields.referralFee.value || 0);

    const totalProcessingFees = (fees.processingFee || 0) +
      (fees.rocProcessingFee || 0) +
      (fees.retailProcessingFee || 0);

    const totalAdminFees = (fees.adminFee || 0) +
      (fees.rocAdminFee || 0) +
      (fees.retailAdminFee || 0);

    const totalUnderwritingFees = (fees.underwritingFee || 0) +
      (fees.rocUnderwritingFee || 0) +
      (fees.retailUnderwritingFee || 0);

    const totalCommitmentFees = (fees.commitmentFee || 0) +
      (fees.rocCommitmentFee || 0) +
      (fees.retailCommitmentFee || 0);

    const totalBuydownFees = (fees.lenderBuydownFee || 0) +
      (fees.rocBuydownFee || 0) +
      (fees.retailBuydownFee || 0);

    const totalBuydownPoints = (fees.buydownPointsTpo || 0) +
      (fees.buydownPointsRoc || 0) +
      (fees.buydownPointsRetail || 0);

    const valuationAndApplicationFees = (fees.adminFee || 0) +
      (fees.rocAdminFee || 0) +
      (fees.retailAdminFee || 0) +
      (fees.commitmentFee || 0) +
      (fees.rocCommitmentFee || 0) +
      (fees.retailCommitmentFee || 0) +
      (fees.lenderBuydownFee || 0) +
      (fees.rocBuydownFee || 0) +
      (fees.retailBuydownFee || 0);

    const underwritingAndProcessingFees = (fees.underwritingFee || 0) +
      (fees.rocUnderwritingFee || 0) +
      (fees.retailUnderwritingFee || 0) +
      (fees.processingFee || 0) +
      (fees.rocProcessingFee || 0) +
      (fees.retailProcessingFee || 0);

    const feeSubtotalWithoutPoints = quote.totalAllocatedFees + quote.totalNonRocFees;

    const lenderFeesFormValues = {
      totalOriginationPoints,
      totalProcessingFees,
      totalAdminFees,
      totalUnderwritingFees,
      totalCommitmentFees,
      totalBuydownFees,
      totalBuydownPoints,
      valuationAndApplicationFees,
      underwritingAndProcessingFees,
      feeSubtotalWithoutPoints,
      ...fees,
    };

    const option = {
      key: Symbol(),
      rateRow: {
        ...amortizationData.baseRates,
        rateType,
      },
      ltvBand,
      rateType,
      formValues: {
        ...formValues,
        rateType,
        loanToValue,
        amortization,
        prePaymentPenalty,
        oneTimeYieldSpreadPremium: yieldSpread,
        programType,
        rateBuydown: buydown,
        loanTerm: constants.rateTypeToLoanTerm[rateType], // change to have it in quote
      },
      programType,
      lenderFeesFormValues,
      totalOriginationPoints,
      totalProcessingFees,
      totalAdminFees,
      totalUnderwritingFees,
      totalCommitmentFees,
      totalBuydownFees,
      totalBuydownPoints,
      valuationAndApplicationFees,
      underwritingAndProcessingFees,
      feeSubtotalWithoutPoints,
      pricerLoanTerms: quote.pricerLoanTerms,
      commissions: quote.commissions,
      closingLegalReviewFee: this.closingLegalReviewFee,
    };

    return option;
  }

  createPricingOptionsFromCustomQuote() {
    try {
      this.pricingOptions = [];
      this.customQuotes.forEach(quote => {
        if (quote.compare && (quote.pointsAndFees - quote.totalAllocatedFees === 0)) {
          const option = this.createPricingOption(quote);

          this.pricingOptions = this.pricingOptions.concat(option);
        }
      })
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while adding option to the quote',
      });
    }
  }

  selectCustomQuote(quoteIndex: number) {
    const quote = this.customQuotes[quoteIndex];
    const pricingOption = this.createPricingOption(quote);
    this.loadForm(pricingOption.formValues);
    this.lenderFeesFormStore.loadForm(quote.fees);
    this.selectedPricingOption = pricingOption;
    if (!this.isBroker) {
      this.loanCommissionStore.setCommissions(quote.commissions);
    }
    this.pricerLoanTerms = {
      ...this.pricerLoanTerms,
      ...quote.pricerLoanTerms,
    }
    this.termStore.loanDetailsStore.onFieldChange("lenderPoints", quote.fees.originationPointsTpo || quote.fees.originationPointsRetail || 0);
  }

  get isInternalLender() {
    return this.globalStore.lenderInfo?.isInternal;
  }

  getFeesBodyForCommissions(customQuote: CustomQuote) {
    let fees = 0;
    if (this.isInternalLender) {
      fees += customQuote.totalNonRocFees;
    }
    return fees;
  }

  get closingLegalReviewFee() {
    return DEFAULT_FEE_LEGAL_REVIEW + (this.termStore.getNumberOfProperties() - 1) * 250;
  }
}

export default PricerSummaryStore;
