import ReactECharts from "echarts-for-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { ChartUnavailable } from "./chartUnavailable";

const chartTitle = "Historical Short vs. Long Term Sale Trends";

function transformData(chartData) {
  try {
    if (!chartData) {
      return null;
    }

    const colors = ["#999", "#D95F02", "#E69F00"];
    const tiers = ["Monthly Sales", "3mo Moving Avg. Monthly Sales", "12mo Moving Avg. Sales"];

    const option = {
      title: {
        text: chartTitle,
        left: "center",
        textStyle: {
          fontSize: 20,
          fontWeight: "bold",
          color: "#656565",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        top: "8%",
        left: "center",
        data: tiers,
      },
      grid: {
        left: "3%",
        right: "6%",
        bottom: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
        name: "Month of List Date",
        nameLocation: "center",
        nameTextStyle: {
          fontSize: 14,
          fontWeight: 'bold',
          padding: [10, 0, 0, 0]
        },
      },
      yAxis: {
        type: "value",
      },
      series: [],
    };

    const monthDates = chartData["monthly_sales"].values.map((d) => new Date(d.year, d.month - 1)).sort((a, b) => a - b);
    const months = monthDates.map((d) => new Intl.DateTimeFormat("en-US", { month: "short", year: "numeric" }).format(d));

    option.xAxis.data = months;

    Object.keys(chartData).forEach(
      (tier, index) => {
        const mappedValues = chartData[tier].values.map(entry => ({
          value: Math.round(entry.value * 100) / 100,
          date: new Date(entry.year, entry.month - 1)
        })).sort((a, b) => a.date - b.date);


        option.series.push({
          name: tiers[index],
          type: "line",
          smooth: true,
          symbol: "none",
          data: mappedValues.map((entry) => entry.value),
          color: colors[index % colors.length],
        });
      }
    );

    return option;
  } catch (err) {
    console.log(err);
    return null;
  }
}

interface SalesTrendsChartProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const SalesTrendsChart = ({ internalValuationReviewStore }: SalesTrendsChartProps) => {
  const chartData = internalValuationReviewStore.initialData.charts?.historical_short_long_sale_trends;
  const option = transformData(chartData);

  if (!option) {
    return <ChartUnavailable chartName={chartTitle} />
  }

  return (
    <ReactECharts option={option} style={{ height: "461px", width: "100%" }} />
  );
};