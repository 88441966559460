import { Grid, makeStyles, Typography } from "@material-ui/core";
import { createMultiSelectField, createCheckboxField } from "@roc/ui";
import { InsuranceInformationBaseStore } from "../stores";
import { observer } from "mobx-react";


const useStyles = makeStyles(() => ({
  boldLabel: {
    '& .MuiTypography-body1': {
      fontWeight: 'bold'
    }
  },
}));
export const ElmsureForm = observer(({ store }: { store: InsuranceInformationBaseStore }) => {
  const classes = useStyles();
  return (<Grid container spacing={2}>
    <Grid item xs={12} className={classes.boldLabel}>
      {createMultiSelectField({
        label: 'Properties',
        fieldName: 'properties',
        testId: 'properties',
        store: store.insuranceQuoteFormStore,
        options: store?.propertiesOptions,
      })}
    </Grid>
    <Grid item xs={12}>
      <Typography>
        <b>Insurance Coverage *</b>
      </Typography>
    </Grid>
    <Grid item xs={2}>
      {createCheckboxField({
        label: 'Property',
        fieldName: 'propertyCoverage',
        store: store.insuranceQuoteFormStore,
      })}
    </Grid>
    <Grid item xs={3}>
      {createCheckboxField({
        label: 'General Liability',
        fieldName: 'generalLiabilityCoverage',
        store: store.insuranceQuoteFormStore,
      })}
    </Grid>
    <Grid item xs={2}>
      {createCheckboxField({
        label: 'Flood',
        fieldName: 'floodCoverage',
        store: store.insuranceQuoteFormStore,
      })}
    </Grid>
  </Grid>)
});