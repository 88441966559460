import { useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessRounded, CheckCircleRounded, CloudDownload, DashboardRounded, EventRounded, MoreVert, Replay, UpdateRounded, WarningRounded } from '@material-ui/icons';
import { useBaseStore } from '@roc/feature-app-core';
import { DocumentName, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatDate } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  Button,
  DropdownMenu,
  Paper,
  DataGrid,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { APPROVED_STAGE, RETRACTED_STAGE } from '../constants/payoffs';
import { usePayoffRequestStore } from '../hooks/usePayoffRequestStore';
import { PayoffDetails } from './payoffDetails';
import { PayoffLoanInformationNew } from './payoffLoanInformation';
import { PayoffRequestDialog } from './payoffRequestDialog';
import { PayoffRetractDialog } from './payoffRetractDialog';
import { BorrowerPortalCard } from '@roc/feature-borrower-portal-core';
import { Alert } from '@material-ui/lab';
import { PayoffContactInformationDetails } from './payoffContactInformation';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';

const noOpComparator = () => 0;

function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
}

function isLoanDelinquent(currentDate: Date, nextDueDate: Date) {
  const dueDatePlus1Month = new Date(nextDueDate);
  dueDatePlus1Month.setMonth(nextDueDate.getMonth() + 1);
  if (currentDate <= dueDatePlus1Month) {
    return null;
  } else if (currentDate > dueDatePlus1Month) {
    return 'Yes';
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 24,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40,
  },
  alert: {
    textAlign: 'center',
  },
  alertContainer: {
    paddingTop: 20,
  },
}));

export const PayoffDashboardV2 = observer(({ loanId }: { loanId: string }) => {
  const {
    payoffRequestStore,
    payoffRequestStore: { loanPayoffs, currentPayoff, disableCreatePayoff },
  } = usePayoffRequestStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { documentStore } = useDocumentStore();
  const {
    loanStore,
    loanStore: { loanDetails },
  } = useLoanStore();
  const classes = useStyles();
  const [
    isNewPayoffRequestModalOpen,
    setIsNewPayoffRequestModalOpen,
  ] = useState(false);
  const [
    isNewPayoffRetractModalOpen,
    setIsNewPayoffRetractModalOpen,
  ] = useState(false);
  const { globalStore } = useBaseStore();
  const nextDueDate = loanStore?.loanDetails?.calculatedLoanData.nextDueDate
    ? new Date(loanStore?.loanDetails?.calculatedLoanData.nextDueDate)
    : new Date();
  const currentNyDate = convertTZ(new Date(), 'America/New_York');
  const isDelinquent = isLoanDelinquent(currentNyDate, nextDueDate);
  const [loading, setLoading] = useState(true);

  const showPayoffForm = ['SLS', 'Keybank'].includes(loanStore?.loanDetails?.servicer);
  const showPayoffContact = ['Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(loanStore?.loanDetails?.servicer);
  const { breadCrumbsStore } = globalStore;
  const { breadCrumbItems } = breadCrumbsStore;

  useEffect(() => {
    breadCrumbsStore.setBreadCrumbItems([
      {
        link: true,
        title: 'Dashboard',
        url: globalStore.routeBaseUrl,
        icon: <DashboardRounded />,
      },
      {
        link: true,
        title: `Loan# ${loanId}`,
        url: loanRoutesConfig.loans(loanId).url,
      },
      {
        link: false,
        title: 'Payoffs',
      },
    ]);
  }, []);

  useEffect(() => {
    payoffRequestStore.getActivePayoffDataByLoanId(loanId);
    payoffRequestStore.getPayoffsByLoanId(loanId, () => {
      setLoading(false);
    });
  }, [loanId, payoffRequestStore]);

  useEffect(() => {
    if (currentPayoff) {
      payoffRequestStore.getPayoffsByLoanId(loanId, () => {
        setLoading(false);
      });
    }
  }, [currentPayoff, payoffRequestStore, loanId]);

  const showActivePayoff = () => {
    if (payoffRequestStore.currentPayoff)
      return (
        <Box mb={3}>
          <PayoffDetails loanId={loanId} />
        </Box>
      );
  };

  const downloadFinalPayoffReport = () => {
    const loanDocumentJson = {
      loanId: loanId,
      taskName: DocumentName.FINAL_PAYOFF_REPORT,
    };
    documentStore.downloadDocumentByName(loanDocumentJson);

  };

  const openRetractPayoff = (payoff) => {
    if (payoff.payoffStatus !== RETRACTED_STAGE)
      setIsNewPayoffRetractModalOpen(true);
  };

  const handleCreatePayoffRequest = () => {
    if (!payoffRequestStore.currentPayoff) {
      setIsNewPayoffRequestModalOpen(true);
    } else {
      globalStore.notificationStore.showErrorNotification({
        message: 'There is an active payoff for this loan.',
      });
    }
  };

  const payoffColumns: AgGridColumnProps[] = [
    {
      field: 'payoffNumber',
      headerName: 'Payoff #',
      minWidth: 100,
      maxWidth: 200,
      cellRenderer: 'payoffNumberCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'payoffStatus',
      headerName: 'Status',
      minWidth: 230,
      maxWidth: 300,
      cellRenderer: 'statusCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedDate',
      headerName: 'Requested Date',
      minWidth: 100,
      maxWidth: 160,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'goodThroughDate',
      headerName: 'Good Through Date',
      minWidth: 100,
      maxWidth: 180,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'requestedByFullName',
      headerName: 'Requested By',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: 'actionCellRenderer',
    },
  ];

  const frameworkComponents = {
    payoffNumberCellRenderer: params => {
      if (!params.value) {
        return '';
      }
      const value = `${loanId} - Payoff ${params.value}`;
      return value;
    },
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    actionCellRenderer: params => {
      const data = params.node.data;
      return (
        <>
          <DropdownMenu options={getActions(data)}>
            <MoreVert />
          </DropdownMenu>
        </>
      );
    },
    statusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip
          statusType={StatusType.PAYOFFS}
          label={value}
          size='small'
          variant={(value == 'Approved') ? 'filled' : 'outlined'} />
      ) : null,
  };

  const getActions = (data) => {
    const actionList = [];
    if (data?.payoffStatus !== RETRACTED_STAGE) {
      actionList.push(
        {
          icon: Replay,
          name: 'Reject',
          action: () => openRetractPayoff(data),
        }
      );
      if (data?.payoffStatus === APPROVED_STAGE) {
        actionList.push(
          {
            icon: CloudDownload,
            name: 'Download Final Report',
            action: () => downloadFinalPayoffReport(),
          }
        );
      }
    }
    return actionList;
  };

  const renderCard = (
    icon,
    value1,
    value2
  ) => {
    return (
      <BorrowerPortalCard noPaddingCardContent>
        <Box p={3} width={'100%'} height={'100%'} textAlign={'center'}>
          <Box mb={.5}>
            {icon}
          </Box>
          <Typography variant='h4' gutterBottom color='primary'>
            {value1}
          </Typography>
          <Typography variant='body2'>
            {value2}
          </Typography>
        </Box>
      </BorrowerPortalCard>
    )
  }

  return (
    <>
      {
        showPayoffForm &&
        <>
          {showActivePayoff()}
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                {renderCard(<BusinessRounded />, loanStore?.loanDetails?.servicer ?? '-', 'Servicer')}
              </Grid>
              <Grid item xs={12} md={4}>
                {renderCard(<EventRounded />, loanStore?.loanDetails?.maturityDate ?? '-', 'Maturity Date')}
              </Grid>
              <Grid item xs={12} md={4}>
                {renderCard((
                  isDelinquent ? <WarningRounded style={{
                    color: 'red'
                  }
                  } /> : <CheckCircleRounded style={{
                    color: 'green'
                  }} />), isDelinquent ? 'No' : 'Yes', 'Is Current')}
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <BorrowerPortalCard
              noPaddingCardContent
              cardTitle={'Payoffs'}
              cardHeaderAction={
                <Button
                  testId="createPayoffRequest"
                  variant="contained"
                  color="primary"
                  disabled={disableCreatePayoff}
                  onClick={handleCreatePayoffRequest}
                >
                  Request Payoff
                </Button>
              }>
              <Box mt={2}>
                {
                  !loading && loanPayoffs?.length == 0 &&
                  <Box m={1}>
                    <Alert severity="info">
                      There is no payoff requested for this loan.
                    </Alert>
                  </Box>
                }
                {
                  !loading && loanPayoffs?.length > 0 &&
                  <DataGrid
                    columns={payoffColumns}
                    rows={loanPayoffs}
                    frameworkComponents={frameworkComponents}
                    domLayout="autoHeight"
                  />
                }
              </Box>
            </BorrowerPortalCard>
          </Box>
          <PayoffRequestDialog
            loanId={loanId}
            open={isNewPayoffRequestModalOpen}
            onClose={() => {
              setIsNewPayoffRequestModalOpen(false);
            }}
          />
          <PayoffRetractDialog
            loanId={loanId}
            open={isNewPayoffRetractModalOpen}
            onClose={() => {
              setIsNewPayoffRetractModalOpen(false);
            }}
          />
        </>
      }
      {
        showPayoffContact &&
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {renderCard(<BusinessRounded />, loanStore?.loanDetails?.servicer ?? '-', 'Servicer')}
            </Grid>
            <Grid item xs={12} md={8}>
              <BorrowerPortalCard cardRootStyle={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}>
                <PayoffContactInformationDetails />
              </BorrowerPortalCard>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  );
});
