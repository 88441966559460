import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { PropertyInformationFormStore } from "../stores/propertyInformationFormStore";
import { useState } from "react";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
    imgContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      width: "100%",
      minHeight: "300px",
    },
    img: {
      height: "370px",
      maxWidth: "100%",
      display: "block",
      width: "100%",
      objectFit: "cover",
    },
  })
);

interface PicturesCarouselProps {
  propertyInformationFormStore: PropertyInformationFormStore;
}

export const PicturesCarousel = observer(({ propertyInformationFormStore }: PicturesCarouselProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const images = propertyInformationFormStore.form.fields.propertyImages.value.map(img => img.url) || [];


  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const activeImage = images[activeStep];

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer}>
        {activeImage ? (
          <img className={classes.img} src={activeImage} alt="Carousel" />
        ) : (
          <p>No Image Available</p>
        )}
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || maxSteps === 0}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0 || maxSteps === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  );
});
