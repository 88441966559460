import { action, makeObservable } from 'mobx';
import { GlobalStore } from '@roc/feature-app-core';
import { FormStore } from '@roc/feature-app-core';
import { InsuranceInformationBaseStore } from './insuranceInformationBaseStore';

const form = {
  fields: {
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ResendQuoteStore extends FormStore {
  globalStore: GlobalStore;
  insuranceInformationStore: InsuranceInformationBaseStore;
  isInternal: boolean;

  constructor(globalStore, insuranceInformationStore: InsuranceInformationBaseStore, isInternal: boolean) {
    super({ ...form }, globalStore);
    this.insuranceInformationStore = insuranceInformationStore;
    this.isInternal = isInternal;
    makeObservable(this, {
      setDefaultBorrowerEmailAddress: action,
    });
  }

  setDefaultBorrowerEmailAddress() {
    const email = this.isInternal ? this.insuranceInformationStore.loanDetails?.loanBorrowerInformation?.loanBorrowers?.rows[0]?.emailAddress :
      this.insuranceInformationStore.loanDetails?.borrowers[0]?.emailAddress;
    this.onFieldChange('emailAddress', email);
  }
}

export default ResendQuoteStore;
