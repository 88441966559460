import { useMiscellaneousRoutes } from '@roc/feature-app-core';
import {
  getBorrowersRoutes,
  getBorrowersRoutesConfig,
} from '@roc/feature-borrowers';
import {
  getDocumentLibraryRoutes,
  getDocumentLibraryRoutesConfig,
} from '@roc/feature-document-library';
import {
  useLoanSubmissionRoutes,
  useRentalPricerRoutes,
} from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { getDrawsRoutes, getDrawsRoutesConfig } from '@roc/feature-draws';
import {
  getBackgroundReviewRoutes,
  getBackgroundReviewRoutesConfig
} from '@roc/feature-borrower-review';
import {
  getAppraisalsRoutes,
  getAppraisalsRoutesConfig,
} from '@roc/feature-appraisals';
import {
  getCompanyProfileRoutes,
  getCompanyProfileRoutesConfig,
  getVerifyBankInformationCallBackRoutesConfig,
  getVerifyBankInformationCallBackRoutes
} from '../modules/companyProfile/routes/companyProfileRoutes';
import {
  getCreditBackgroundRoutes,
  getCreditBackgroundRoutesConfig,
} from '../modules/creditBackground/routes/creditBackgroundRoutes';
import {
  getDashboardRoutes,
  getDashboardRoutesConfig,
} from '../modules/dashboard';
import {
  getDscrCalculatorRoutes,
  getDscrCalculatorRoutesConfig,
} from '../modules/dscrCalculator';
import {
  getCalculatorSelectionRoutes,
  getCalculatorSelectionRoutesConfig
} from '../modules/calculatorSelectionRoutes'
import {
  getFixFlipSizerRoutes,
  getFixFlipSizerRoutesConfig,
} from '../modules/fixFlipSizer/routes/fixFlixSizerRoutes';
import {
  getScopeOfWorkRevisionRoutes,
  getScopeOfWorkRevisionRoutesConfig,
} from '@roc/feature-draws';
import {
  getClientInternalPricerRoutes,
  getClientInternalPricerRoutesConfig,
} from '../modules/internalPricer';
import {
  getLenderDetailsRoutes,
  getLenderDetailsRoutesConfig,
} from '../modules/lenderDetails/routes/lenderDetailsRoutes';
import {
  getLiquidityCalculatorRoutes,
  getLiquidityCalculatorRoutesConfig,
} from '../modules/liquidityCalculator';
import {
  getMultifamilyLiquidityCalculatorRoutes,
  getMultifamilyLiquidityCalculatorRoutesConfig,
} from '../modules/multifamilyLiquidityCalculator'
import {
  getClientBridgePricerRoutes,
  getClientBridgePricerRoutesConfig,
} from '../modules/bridgePricer';
import {
  getMessagesRoutes,
  getMessagesRoutesConfig,
} from '../modules/messages';

import {
  getRocUniversityRoutes,
  getRocUniversityRoutesConfig,
} from '../modules/rocUniversity/routes/rocUniversityRoutes';

import {
  getSigmaDashboardsRoutes,
  getSigmaDashboardsRoutesConfig,
} from '../modules/sigmaDashboards/routes/sigmaDashboardsRoutes';


import {
  getProposalsRoutes,
  getProposalsRoutesConfig
} from '@roc/feature-proposals';

import {
  getStabilizedBridgeAnalyzerRoutes,
  getStabilizedBridgeAnalyzerRoutesConfig,
} from '../modules/stabilizedBridgeAnalyzer';

import {
  getManageBrokersRoutes,
  getManageBrokerRoutesConfig
} from '@roc/feature-brokers';

import {
  getLenderExtensionsRoutes,
  getLenderExtensionsRoutesConfig
}
  from '@roc/feature-extensions';

import {
  getPayoffsRoutes,
  getPayoffsRoutesConfig
} from '@roc/feature-payoffs';

import {
  getBridgeProfitAndDscrCalculatorRoutes,
  getBridgeProfitAndDscrCalculatorRoutesConfig,
} from '@roc/feature-bridge-profit-and-dscr-calculator';

import {
  getDrawDqDataRoutes,
  getDrawDqDataRoutesConfig
} from '@roc/feature-draw-dq-data';

import { useStore } from './useStore';
import { useDocumentLibraryRoutes } from '@roc/feature-document-library-contentful';
import { getScopeOfWorkRoutesConfigV2, getScopeOfWorkRoutesV2 } from '@roc/feature-sow-v2';
import { getCommunicationRoutesConfig } from '@roc/feature-communication';
import { getQuoteGeneratorRoutes, getQuoteGeneratorRoutesConfig } from '@roc/feature-quote-generator';
import { getMarketingSetupRoutes, getMarketingSetupRoutesConfig, getEmailsRoutes, getEmailsRoutesConfig, getEmailSettingsRoutesConfig, getEmailSettings, getCorporateEmailsRoutes, getCorporateEmailsRoutesConfig } from '@roc/feature-sendgrid';
import { getCreateListRoutes, getCreateListRoutesConfig } from 'apps/crm-portal/src/app/modules/createList/routes/createListRoutes';
import {
  getLoanVolumeReportRoutes,
  getLoanVolumeReportRoutesConfig,
} from '../modules/loanVolumeReport/routes/loanVolumeReportRoutes';
import { getLenderRoutes, getLenderRoutesConfig, getLeadDetailsRoutes, getLeadDetailsRoutesConfig, getImportLeadsConfig, getImportLeadsRoutes, getManageTemplatesConfig, getManageTemplatesRoutes, getLeadsDashboardRoutes, getLeadsDashboardRoutesConfig, getNewLeadRoutes, getNewLeadRoutesConfig, getPossibleMatchesConfig, gettPossibleMatchesRoutes, getCRMTaskRoutes, getCRMTaskRoutesConfig, getDelinquentLoansRoutes, getDelinquentLoansRoutesConfig } from 'libs/feature-backoffice-users/src/backOfficeUsers/routes/loanRoutes';
import { getCompToolRoutes, getCompToolRoutesConfig } from '@roc/feature-comp-tool';
import { getBorrowerDetailsRoutes, getBorrowerDetailsRoutesConfig } from 'libs/feature-backoffice-users/src/backOfficeUsers/routes/borrowerDetailsRoutes';
import { getTPOContactDetailsRoutes, getTPOContactDetailsRoutesConfig } from 'libs/feature-backoffice-users/src/backOfficeUsers/routes/tpoContactDetailsRoutes';
import { useEditLoanSubmissionRoutes } from '@roc/feature-edit-loans';
import { getLiquidityReportRoutes, getLiquidityReportRoutesConfig } from 'libs/feature-liquidity-report/src';
import { useOneToolRoutes } from '@roc/feature-one-tool';
import { getInsuranceAgentRoutes, getInsuranceAgentRoutesConfig } from '@roc/feature-insurance-agents';
import { getGeneralTasksRoutes, getGeneralTasksRoutesConfig } from '@roc/feature-general-tasks';


export const useRoutes = () => {
  const { globalStore } = useStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  const { borrowerId } = globalStore.queryParams;
  const { loanId } = globalStore.queryParams;
  const {
    miscellaneousRoutes,
    miscellaneousRoutesConfig,
  } = useMiscellaneousRoutes();

  const {
    loanSubmissionRoutes,
    loanSubmissionRoutesConfig,
  } = useLoanSubmissionRoutes();

  const {
    editLoanSubmissionRoutes,
    editLoanSubmissionRoutesConfig,
  } = useEditLoanSubmissionRoutes();

  const { loanRoutesConfig } = useLoanRoutes();

  const {
    rentalPricerRoutes,
    rentalPricerRoutesConfig,
  } = useRentalPricerRoutes();

  const {
    documentLibraryRoutes: documentLibraryContentfulRoutes,
    documentLibraryRoutesConfig: documentLibraryContentfulRoutesConfig
  } = useDocumentLibraryRoutes();

  const {
    oneToolRoutes,
    oneToolRoutesConfig
  } = useOneToolRoutes();

  return {
    miscellaneousRoutes,
    miscellaneousRoutesConfig,
    dashboardRoutes: getDashboardRoutes(routeBasePath, routeBaseUrl),
    dashboardRoutesConfig: getDashboardRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    loanRoutesConfig,
    loanSubmissionRoutes,
    loanSubmissionRoutesConfig,
    editLoanSubmissionRoutes,
    editLoanSubmissionRoutesConfig,
    messagesRoutes: getMessagesRoutes(routeBasePath, routeBaseUrl),
    messagesRoutesConfig: getMessagesRoutesConfig(routeBasePath, routeBaseUrl),
    companyProfileRoutesConfig: getCompanyProfileRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    companyProfileRoutes: getCompanyProfileRoutes(routeBasePath, routeBaseUrl),
    verifyBankInformationCallBackRoutes: getVerifyBankInformationCallBackRoutes(routeBasePath, routeBaseUrl),
    verifyBankInformationCallBackRoutesConfig: getVerifyBankInformationCallBackRoutesConfig(routeBasePath, routeBaseUrl),
    lenderRoutesConfig: getLenderRoutesConfig(routeBasePath, routeBaseUrl),
    lenderRoutes: getLenderRoutes(routeBasePath, routeBaseUrl),
    lenderDetailsRoutes: getLenderDetailsRoutes(routeBasePath, routeBaseUrl),
    lenderDetailsRoutesConfig: getLenderDetailsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    borrowersRoutes: getBorrowersRoutes(routeBasePath, routeBaseUrl),
    borrowersRoutesConfig: getBorrowersRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    appraisalsRoutes: getAppraisalsRoutes(routeBasePath, routeBaseUrl),
    appraisalsRoutesConfig: getAppraisalsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    creditBackgroundRoutes: getCreditBackgroundRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    creditBackgroundRoutesConfig: getCreditBackgroundRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    documentLibraryRoutes: getDocumentLibraryRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    documentLibraryRoutesConfig: getDocumentLibraryRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    documentLibraryContentfulRoutes,
    documentLibraryContentfulRoutesConfig,
    fixFlipSizerRoutes: getFixFlipSizerRoutes(routeBasePath, routeBaseUrl),
    fixFlipSizerRoutesConfig: getFixFlipSizerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    scopeOfWorkRoutesV2: getScopeOfWorkRoutesV2(routeBasePath, routeBaseUrl),
    scopeOfWorkRoutesConfigV2: getScopeOfWorkRoutesConfigV2(
      routeBasePath,
      routeBaseUrl
    ),
    scopeOfWorkRevisionRoutes: getScopeOfWorkRevisionRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    scopeOfWorkRevisionRoutesConfig: getScopeOfWorkRevisionRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    drawsRoutes: getDrawsRoutes(routeBasePath, routeBaseUrl),
    drawsRoutesConfig: getDrawsRoutesConfig(routeBasePath, routeBaseUrl),
    rentalPricerRoutes,
    rentalPricerRoutesConfig,
    dscrCalculatorRoutes: getDscrCalculatorRoutes(routeBasePath, routeBaseUrl),
    dscrCalculatorRoutesConfig: getDscrCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    calculatorSelectionRoutes: getCalculatorSelectionRoutes(routeBasePath, routeBaseUrl),
    calculatorSelectionRoutesConfig: getCalculatorSelectionRoutesConfig(routeBasePath, routeBaseUrl),
    liquidityCalculatorRoutes: getLiquidityCalculatorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    liquidityCalculatorRoutesConfig: getLiquidityCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    multifamilyLiquidityCalculatorRoutes: getMultifamilyLiquidityCalculatorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    multifamilyLiquidityCalculatorRoutesConfig: getMultifamilyLiquidityCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    internalPricerRoutes: getClientInternalPricerRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    internalPricerRoutesConfig: getClientInternalPricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    bridgePricerRoutes: getClientBridgePricerRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    bridgePricerRoutesConfig: getClientBridgePricerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    backgroundReviewRoutes: getBackgroundReviewRoutes(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanId
    ),
    backgroundReviewRoutesConfig: getBackgroundReviewRoutesConfig(
      routeBasePath,
      routeBaseUrl,
      borrowerId,
      loanId
    ),
    rocUniversityRoutes: getRocUniversityRoutes(routeBasePath, routeBaseUrl),
    rocUniversityRoutesConfig: getRocUniversityRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    compToolRoutes: getCompToolRoutes(routeBasePath, routeBaseUrl),
    compToolRoutesConfig: getCompToolRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    sigmaDashboardsRoutes: getSigmaDashboardsRoutes(routeBasePath, routeBaseUrl),
    sigmaDashboardsRoutesConfig: getSigmaDashboardsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    proposalsRoutes: getProposalsRoutes(routeBasePath, routeBaseUrl),
    proposalsRoutesConfig: getProposalsRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    stabilizedBridgeAnalyzerRoutes: getStabilizedBridgeAnalyzerRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    stabilizedBridgeAnalyzerRoutesConfig: getStabilizedBridgeAnalyzerRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    manageBrokersRoutes: getManageBrokersRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    manageBrokersRoutesConfig:
      getManageBrokerRoutesConfig(
        routeBasePath,
        routeBaseUrl
      ),
    lenderExtensionsRoutes:
      getLenderExtensionsRoutes(
        routeBasePath,
        routeBaseUrl
      ),
    lenderExtensionsRoutesConfig:
      getLenderExtensionsRoutesConfig(
        routeBasePath,
        routeBaseUrl
      ),
    payoffRoutes:
      getPayoffsRoutes(
        routeBasePath,
        routeBaseUrl
      ),
    payoffRoutesConfig:
      getPayoffsRoutesConfig(
        routeBasePath,
        routeBaseUrl
      ),
    bridgeProfitAndDscrCalculatorRoutes: getBridgeProfitAndDscrCalculatorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    bridgeProfitAndDscrCalculatorRoutesConfig: getBridgeProfitAndDscrCalculatorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    communicationRoutesConfig: getCommunicationRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    drawDqDataRoutes: getDrawDqDataRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    drawDqDataRoutesConfig: getDrawDqDataRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    quoteGeneratorRoutes: getQuoteGeneratorRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    quoteGeneratorRoutesConfig: getQuoteGeneratorRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),

    emailsRoutes: getEmailsRoutes(routeBasePath, routeBaseUrl),
    emailRoutesConfig: getEmailsRoutesConfig(routeBasePath, routeBaseUrl),
    emailSettingsRoutes: getEmailSettings(routeBasePath, routeBaseUrl),
    emailSettingsRoutesConfig: getEmailSettingsRoutesConfig(routeBasePath, routeBaseUrl),
    marketingSetupRoutes: getMarketingSetupRoutes(routeBasePath, routeBaseUrl),
    marketingSetupRoutesConfig: getMarketingSetupRoutesConfig(routeBasePath, routeBaseUrl),
    createListRoutes: getCreateListRoutes(routeBasePath, routeBaseUrl),
    createListRoutesConfig: getCreateListRoutesConfig(routeBasePath, routeBaseUrl),
    corporateEmailsRoutesConfig: getCorporateEmailsRoutesConfig(routeBasePath, routeBaseUrl),
    corporateEmailsRoutes: getCorporateEmailsRoutes(routeBasePath, routeBaseUrl),
    loanVolumeReportRoutes: getLoanVolumeReportRoutes(routeBasePath, routeBaseUrl),
    loanVolumeReportRoutesConfig: getLoanVolumeReportRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    possibleMatchesConfig: getPossibleMatchesConfig(routeBasePath, routeBaseUrl),
    possibleMatchesRoutes: gettPossibleMatchesRoutes(routeBasePath, routeBaseUrl),
    newLeadRoutes: getNewLeadRoutes(routeBasePath, routeBaseUrl),
    newLeadRoutesConfig: getNewLeadRoutesConfig(routeBasePath, routeBaseUrl),
    leadsDashboardRoutesConfig: getLeadsDashboardRoutesConfig(routeBasePath, routeBaseUrl),
    leadsDashboardRoutes: getLeadsDashboardRoutes(routeBasePath, routeBaseUrl),
    leadDetailsRoutesConfig: getLeadDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    leadDetailsRoutes: getLeadDetailsRoutes(routeBasePath, routeBaseUrl),
    importLeadsRoutes: getImportLeadsRoutes(routeBasePath, routeBaseUrl),
    importLeadsConfig: getImportLeadsConfig(routeBasePath, routeBaseUrl),
    manageTemplatesRoutes: getManageTemplatesRoutes(routeBasePath, routeBaseUrl),
    manageTemplatesConfig: getManageTemplatesConfig(routeBasePath, routeBaseUrl),
    borrowerDetailsRoutes: getBorrowerDetailsRoutes(routeBasePath, routeBaseUrl),
    borrowerDetailsRoutesConfig: getBorrowerDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    tpoContactDetailsRoutesConfig: getTPOContactDetailsRoutesConfig(routeBasePath, routeBaseUrl),
    tpoContactDetailsRoutes: getTPOContactDetailsRoutes(routeBasePath, routeBaseUrl),
    crmTaskDashboardRoutesConfig: getCRMTaskRoutesConfig(routeBasePath, routeBaseUrl),
    crmTaskDashboardRoutes: getCRMTaskRoutes(routeBasePath, routeBaseUrl),
    delinquentLoansDashboardRoutesConfig: getDelinquentLoansRoutesConfig(routeBasePath, routeBaseUrl),
    delinquentLoansDashboardRoutes: getDelinquentLoansRoutes(routeBasePath, routeBaseUrl),
    liquidityReportRoutesConfig: getLiquidityReportRoutesConfig(routeBasePath, routeBaseUrl),
    liquidityReportRoutes: getLiquidityReportRoutes(routeBasePath, routeBaseUrl),
    oneToolRoutes,
    oneToolRoutesConfig,
    generalTasksRoutesConfig: getGeneralTasksRoutesConfig(routeBasePath, routeBaseUrl),
    generalTasksRoutes: getGeneralTasksRoutes(routeBasePath, routeBaseUrl),
    insuranceAgentRoutes: getInsuranceAgentRoutes(routeBasePath, routeBaseUrl),
    insuranceAgentRoutesConfig: getInsuranceAgentRoutesConfig(routeBasePath, routeBaseUrl),
  };
};
