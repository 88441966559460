import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Page,
  useBaseStore,
  useMiscellaneousRoutes,
  useUserStore,
} from '@roc/feature-app-core';
import {
  ConversationAttribute,
  MyChatContainer,
  SelectConversation,
  useCommunicationRoutesConfig,
  useLoanCommunicationStore,
} from '@roc/feature-communication';
import {
  AllMessages,
  Documents,
  Overview,
  PendingDocuments,
  useDocumentCommunicationStore,
  useDocumentStore,
} from '@roc/feature-documents';
import {
  DrawDetails,
  DrawRequest,
  LoanDrawsDashboard,
  ReviseScopeOfWork,
} from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import {
  CLOSING,
  getDocumentSection,
  getDrawId,
  getExtensionId,
  LoanStatus,
  LoanType,
  SERVICING,
  UNDERWRITING,
} from '@roc/feature-utils';
import { DrawerMenu, IconButton, NotFound, PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { useLoanStore } from '../hooks/useLoanStore';
import BorrowerEntityOverview from './components/borrowerEntityOverview';
import { LoanDetailsLayout } from './components/loanDetailsLayout';
import { LoanDetailsMenu } from './components/loanDetailsMenu';
import Properties from './components/properties';
import { ExtensionDetails } from './modules/extensionDetails';
import { LoanContacts } from './modules/loanContacts/loanContacts';
import { ExtensionsDashboard } from './modules/extensionsDashboard';
import PayoffRequest from './payoffs/payoffRequest';
import { PayoffRequestV2 } from './payoffs/payoffRequestV2';
import { EstimatedClosingCosts } from './components/estimatedClosingCosts';
import { BannerMessages } from '../components/bannerMessages';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import {
  LoanSummaryCommissions,
  LoanSummaryExtended,
  LoanSummaryDecisionTab,
} from '@roc/feature-loan-summary';
import { MenuOpen } from '@material-ui/icons';
import {
  EditLoanBorrowers,
  EditLoanProperties,
  EditLoanTerms,
} from '@roc/feature-edit-loans';
import { LoanForeclosureRequest } from './foreclosure/components/loanForeclosureRequest';
import { LoanDetailsV2 } from './loanDetailsV2';
import { LoanDetailsV1 } from './loanDetailsV1';
import { NewUiBannerMessages } from '../components/newUiBannerMessages';
import { useGeneralTasksStore } from 'libs/feature-general-tasks/src/generalTasks/hooks/useGeneralTasksStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    chatBox: {
      overflow: 'auto',
      [theme.breakpoints.down(769)]: {
        margin: '8px 0 0 0',
      },
    },
  })
);

const fastTrackElegibleLoanStatuses = [
  LoanStatus.UNKNOWN,
  LoanStatus.PENDING,
  LoanStatus.TERM_SHEET_OUT,
  LoanStatus.IN_UNDERWRITING,
  LoanStatus.PRESCREEN,
  LoanStatus.PENDING_DECISION,
  LoanStatus.PENDING_TERM_SHEET,
];

interface LoanDetailsProps {
  hideEditBorrowers?: boolean;
  renderLoanSummary: (props) => React.ReactNode;
}

export const LoanDetails = observer((props: LoanDetailsProps) => {
  const { globalStore } = useBaseStore();
  const classes = useStyles();
  const { renderLoanSummary } = props;
  const { loanId } = useParams<{ loanId: string }>();
  const { pathname } = useLocation();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { documentCommunicationStore } = useDocumentCommunicationStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const {
    currentConversationSid,
    currentConversation,
    currentConversationIsLoanConversation,
  } = loanCommunicationStore;
  const { push } = useHistory();
  const {
    miscellaneousRoutes,
    miscellaneousRoutesConfig,
  } = useMiscellaneousRoutes();
  const { loanRoutesConfig } = useLoanRoutes();
  const twilioCommunicationEnabled =
    loanStore.loanDetails?.twilioCommunicationEnabled;
  const isNewSummaryScreenDesign = loanStore.loanDetails?.toDosFrameworkEnabled;
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));
  const isBridgeLoan =
    loanStore.loanDetails?.loanType == LoanType.RESIDENTIAL_BRIDGE;

  const { communicationRoutesConfig } = useCommunicationRoutesConfig();

  const documentSection = getDocumentSection(pathname);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);
  const [isLoanSummary, setIsLoanSummary] = useState(false);

  const [readyToShowLoanDetails, setReadyToShowLoanDetails] = useState(false);
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  const toggleDrawerMenu = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    loanStore.resetLoanDetails();
    documentStore.reset();
    documentStore.loanTodosStore.reset();
    return () => {
      loanStore.resetLoanDetails();
      documentStore.reset();
      documentStore.loanTodosStore.reset();
    };
  }, [loanId]);

  useEffect(() => {
    if (!loanStore.isLoanDetailsSuccess) {
      push(miscellaneousRoutesConfig.notFound.url);
    }
  }, [loanStore.isLoanDetailsSuccess, documentStore]);

  useEffect(() => {
    if (
      !loanStore.loanDetails?.loanId ||
      documentCommunicationStore.isDrawerOpen
    )
      return;
    loanCommunicationStore.fetchUnreadMessagesCount(loanId);
    if (!loanStore.loanDetails?.twilioCommunicationEnabled) {
      documentStore.fetchUnreadComments(loanId);
    }
  }, [
    documentCommunicationStore.isDrawerOpen,
    loanStore.loanDetails?.loanId,
    documentStore,
  ]);

  useEffect(() => {
    if (!loanStore.loanDetails?.loanId || globalStore.userFeatures?.isServicer)
      return;
    documentStore.updateIsProposal(false);
    documentStore.fetchSections();
    documentStore.fetchDocuments(loanId);
    loanStore.fetchPreviousDraws(loanId);
    if (
      loanStore.loanDetails?.fastTrack &&
      !loanStore.loanDetails?.fastrackPaymentComplete
    ) {
      loanStore.fetchLoanFastTrackPaymentUrl(loanId);
    }
  }, [loanStore.loanDetails?.loanId, documentStore]);

  useEffect(() => {
    setReadyToShowLoanDetails(false);
    loanStore.fetchLoanDetails(loanId, () => {
      setReadyToShowLoanDetails(true);
    });
  }, [
    loanStore,
    loanStore.submitAppraisalSuccess,
    loanStore.borrowerUpdatedCounter,
    loanId,
  ]);

  useEffect(() => {
    loanStore.fetchLoanExpirationDates(Number(loanId));
    loanStore.fetchLoanPrescreenData(Number(loanId));
    loanStore.fetchExceptionsAndCommentsExternal(
      loanId,
      'Underwriter Comments'
    );
  }, []);

  useEffect(() => {
    loanStore.fetchLoanClosingConditions(Number(loanId));
  }, [loanId]);

  useEffect(() => {
    setDrawerOpen(false);
    setMenuVisible(!pathname.includes('/loan-summary'));
    setIsLoanSummary(pathname.includes('/loan-summary'));
  }, [pathname]);

  const onUserNewConversation = currentConversationIsLoanConversation
    ? async inviteUserId => {
      const attributes = currentConversation?.attributes as ConversationAttribute;
      const response = await loanCommunicationStore.createNewUserConversation(
        attributes.loanId,
        [loanCommunicationStore.userId, inviteUserId]
      );
      if (currentConversationSid != response?.conversationSid) {
        window.location.href = communicationRoutesConfig
          .conversations(`${attributes.loanId}`)
          .children.conversation(response.conversationSid).url;
      }
    }
    : undefined;

  const onOpenAddTask = currentConversationIsLoanConversation ?
    async (assigneeId) => {
      const attributes = currentConversation?.attributes as ConversationAttribute;
      generalTasksStore.setLoanId(attributes.loanId);
      editTaskStore.openAddTask(null, { assigneeId }, null);
    }
    : undefined;

  const renderDrawDetails = () => {
    const drawId = getDrawId(pathname);
    if (true && drawId) {
      return (
        <Route
          exact
          path={
            loanRoutesConfig.loans(loanId).children.drawDetails(drawId).path
          }
        >
          <Page
            routeDefinition={loanRoutesConfig
              .loans(loanId)
              .children.drawDetails(drawId)}
          >
            <DrawDetails
              loanId={loanId}
              drawId={drawId}
              adminSpecialist={
                loanStore.loanDetails?.drawAdminSpecialistFullName
              }
              reviewSpecialist={
                loanStore.loanDetails?.drawReviewSpecialistFullName
              }
            />
          </Page>
        </Route>
      );
    } else {
      return null;
    }
  };

  const renderPayoffScreen = (servicer, version) => {
    const showPayoffModule = [
      'SLS',
      'Keybank',
      'Fay Servicing',
      'Planet Home Lending',
      'Planet Portfolio',
    ].includes(servicer);
    return showPayoffModule ? (
      <PayoffRequestV2 loanId={loanId} showTitle={version == 'v2' ? false : true} style={{
        padding: version == 'v2' ? 0 : 16
      }} />
    ) : (
      <PayoffRequest />
    );
  };
  const renderExtensionDetails = () => {
    const extensionId = getExtensionId(pathname);
    if (true && extensionId) {
      return (
        <Route
          exact
          path={
            loanRoutesConfig
              .loans(loanId)
              .children.extensionDetails(extensionId).path
          }
        >
          <Page
            routeDefinition={loanRoutesConfig
              .loans(loanId)
              .children.extensionDetails(extensionId)}
          >
            <ExtensionDetails loanId={loanId} extensionId={extensionId} />
          </Page>
        </Route>
      );
    } else {
      return null;
    }
  };

  const renderFastTrackPaymentReminder = () => {
    return loanStore.loanDetails?.fastTrack &&
      !loanStore.loanDetails?.fastrackPaymentComplete &&
      loanStore.loanDetails?.status &&
      fastTrackElegibleLoanStatuses.includes(loanStore.loanDetails?.status) &&
      loanStore.loanFastTrackUrl ? (
      <BannerMessages
        newDesignForSummaryScreen={isNewSummaryScreenDesign}
        messages={[
          {
            title: 'Fast Track Payment is Still Pending',
            content: `Payment is required in order to initiate Fast Track process. <a id="fastTrackPaymentLink" href="${loanStore.loanFastTrackUrl}" target="_blank"/>Click here</a> to pay now for the fast track fees. `,
          },
        ]}
      />
    ) : null;
  };


  const renderRepeatPropertyWarning = () => {
    const repeatedPropertyAddresses = loanStore.loanDetails?.properties
      ?.filter((property) => property.repeatedProperty)
      .map((property) => property.address);
    const { userStore } = useUserStore();
    let crmUser = userStore.isBackOfficeUser;
    if (crmUser) {
      if ((!repeatedPropertyAddresses || repeatedPropertyAddresses.length === 0)) {
        return null;
      }

      let messageContent = '';

      if (repeatedPropertyAddresses.length >= 1) {
        messageContent = 'There is another loan in the system reflecting this address. Please reach out to your RM for more information.';
      }

      return (
        <BannerMessages
          newDesignForSummaryScreen={isNewSummaryScreenDesign}
          messages={[
            {
              title: 'Duplicate Address Alert ',
              content: messageContent,
            },
          ]}
        />
      );
    }
    return null;
  };


  const renderLoanClosingCondition = () => {
    if (loanStore.loanClosingConditions != undefined) {
      const conditionsToDisplay = loanStore.loanClosingConditions.filter(
        entry =>
          entry.documentStatus === 'Being Reviewed'
            ? entry.status === false
            : true
      );
      return conditionsToDisplay.length > 0 &&
        globalStore.userFeatures?.closingConditionBannerAllowed ? (
        !loanStore.loanDetails.toDosFrameworkEnabled ? (
          <BannerMessages
            newDesignForSummaryScreen={isNewSummaryScreenDesign}
            messages={[
              {
                title:
                  'Attention: Closing conditions can be found under Closing in Loan Terms section',
                content: `
           <ul>
            ${conditionsToDisplay
                    .map(
                      entry => `
            <li key=${entry.loanClosingConditionId}>
            <strong>${entry.conditionName}${entry.conditionName == 'Other'
                          ? `: ${entry?.conditionDescription
                            ?.replace('<p>', '')
                            ?.replace('</p>', '')}`
                          : ''
                        }</strong>
              </li>`
                    )
                    .join('')}
          </ul>
              `,
              },
            ]}
          />
        ) :
          <NewUiBannerMessages
            newDesignForSummaryScreen={isNewSummaryScreenDesign}
            messages={[
              {
                title:
                  'Attention: Closing conditions can be found under Closing in Loan Terms section',
                content: `
           <ul>
            ${conditionsToDisplay
                    .map(
                      entry => `
            <li key=${entry.loanClosingConditionId}>
            <strong>${entry.conditionName}${entry.conditionName == 'Other'
                          ? `: ${entry?.conditionDescription
                            ?.replace('<p>', '')
                            ?.replace('</p>', '')}`
                          : ''
                        }</strong>
              </li>`
                    )
                    .join('')}
          </ul>
              `,
              },
            ]}
          />
      ) : null;
    }
  };

  if (!readyToShowLoanDetails || !loanStore.loanDetails) {
    return <></>;
  }

  if (loanStore.loanDetails.toDosFrameworkEnabled) {
    return (
      <LoanDetailsV2
        renderLoanSummary={props.renderLoanSummary}
        hideEditBorrowers={props.hideEditBorrowers}
        isLoanSummary={isLoanSummary as boolean}
        onUserNewConversation={onUserNewConversation}
        renderFastTrackPaymentReminder={renderFastTrackPaymentReminder}
        renderRepeatPropertyWarning={renderRepeatPropertyWarning}
        renderLoanClosingCondition={renderLoanClosingCondition}
        renderDrawDetails={renderDrawDetails}
        renderExtensionDetails={renderExtensionDetails}
        renderPayoffScreen={(servicer) => renderPayoffScreen(servicer, 'v2')}
        onOpenAddTask={onOpenAddTask}
      />
    );
  } else {
    return (
      <LoanDetailsV1
        renderLoanSummary={props.renderLoanSummary}
        hideEditBorrowers={props.hideEditBorrowers}
        isLoanSummary={isLoanSummary as boolean}
        onUserNewConversation={onUserNewConversation}
        renderFastTrackPaymentReminder={renderFastTrackPaymentReminder}
        renderLoanClosingCondition={renderLoanClosingCondition}
        renderDrawDetails={renderDrawDetails}
        renderExtensionDetails={renderExtensionDetails}
        renderRepeatPropertyWarning={renderRepeatPropertyWarning}
        renderPayoffScreen={(servicer) => renderPayoffScreen(servicer, 'v1')}
        onOpenAddTask={onOpenAddTask}
      />
    );
  }
});
