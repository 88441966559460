import { useState } from 'react';
import { Box, Grid, Typography, ListItemText, Menu, MenuItem, IconButton, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '@roc/feature-utils';
import { MoreVert, GetApp } from '@material-ui/icons';
import {
  AgGridColumnProps,
  Button,
  Paper,
  DataGrid as InvoiceGrid,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLoanStore } from '@roc/feature-loans-shared';
import { formatCurrency } from '@roc/ui/utils';
import { useDocumentStore } from '@roc/feature-documents';
import { useExternalAgentLoansStore } from '../../hooks/useExternalAgentLoansStore'
import { ForeclosureInvoiceModal } from '@roc/feature-loans';
import { TITLE_FEE } from '../../utils/constans';


const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  title: {
    marginBottom: 24,
  },
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 12,
  },
  paperTitle: {
    padding: 16,
  },
}));

const invoiceColumns: AgGridColumnProps[] = [
  {
    field: 'invoice',
    headerName: 'Invoice #',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 100,
    cellRenderer: 'currencyCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'receivedDate',
    headerName: 'Requested Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'documentStatus',
    headerName: 'Status',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 100,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 100,
    pinned: 'right',
    lockPinned: true,
  },
];

export const LoanExternalAgentDetails = observer(({ loanId }: { loanId: string }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [record, setRecord] = useState<any>({});
  const menuOpen = Boolean(anchorEl);
  const { foreclosureInvoiceStore } = useExternalAgentLoansStore();
  const { foreclosureRequestStore } = foreclosureInvoiceStore;
  const { foreclosureInvoiceFormStore } = foreclosureRequestStore;

  useEffect(() => {
    console.log("LoanExternalAgentDetails useEffect called");
    foreclosureRequestStore.getInvoicesByLoanId(loanId);
  }, [loanId, foreclosureInvoiceStore]);

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    actionCellRenderer: params => {
      return (
        <>
          <IconButton onClick={e => openMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>

        </>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.dealName}>
          <Typography variant="h6" color="textSecondary">
            {loanStore?.loanDetails.dealName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" className={classes.title}>
            Foreclosure Invoice
          </Typography>
        </Grid>

        <Grid item >
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">INVOICES</Typography>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        testId="addInvoice"
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={loanId == null}
                        onClick={() => setIsFileUploadModalOpen(true)}
                      >
                        Add Expenses
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <InvoiceGrid
                columns={invoiceColumns}
                rows={foreclosureRequestStore.invoiceList}
                frameworkComponents={frameworkComponents}
                domLayout="autoHeight"
              />
              <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    const document = {
                      loanTaskId: record.documentId,
                      originalFileName: record.originalFileName,
                    };
                    documentStore.downloadDocumentOriginalNameById(document);
                    handleMenuClose();
                  }}
                  style={{ padding: '8px' }}
                >
                  <ListItemIcon>
                    <GetApp fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Download Document`}
                    style={{ cursor: 'pointer' }}
                  />
                </MenuItem>
              </Menu>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ForeclosureInvoiceModal
        loanId={loanId}
        open={isFileUploadModalOpen}
        store={foreclosureInvoiceFormStore}
        onClose={() => setIsFileUploadModalOpen(false)}
        onUpload={() => foreclosureRequestStore.getInvoicesByLoanId(loanId)}
      />
    </>
  );
});