import { Document } from '@roc/feature-types';
import { DOCUMENT_FORMS, DocumentStatus, RESOLUTION_LINE_ITEM_PREFIX, CLOSING_CONDITION, BANK_STATEMENT_PREFIX, LEASE_INFORMATION_UNIT_PREFIX, TAX_ESCROW, POST_DISASTER_INSPECTION, TRIMERGE_CREDIT_REPORT, CREDIT_REPORT, BANK_STATEMENT_MONTH_PREFIX } from '../constants';

export const isAccepted = (document: Document) => {
  return document && document.status === DocumentStatus.ACCEPTED;
};

export const isAcceptedOrDelivered = (document: Document) => {
  return (
    document &&
    (document.status === DocumentStatus.ACCEPTED ||
      document.status === DocumentStatus.REPORTS_DELIVERED)
  );
};

export const isFileAlreadyUploaded = (document: Document) => {
  return document && !!document.dropboxPath;
};

export const isFormDocument = (document: Document, isNportalUser: boolean, toDosFrameworkEnabled: boolean) => {
  return document && !!DOCUMENT_FORMS[document.taskName] || (document.taskName && document.taskName.includes(RESOLUTION_LINE_ITEM_PREFIX)) || (document.taskName && document.taskName.includes(CLOSING_CONDITION)) || (isNportalUser && document.taskName && (document.taskName.includes(BANK_STATEMENT_PREFIX) || document.taskName.includes(BANK_STATEMENT_MONTH_PREFIX) || document.taskName.includes(LEASE_INFORMATION_UNIT_PREFIX) || document.taskName === TAX_ESCROW || document.taskName === POST_DISASTER_INSPECTION))
    || (!toDosFrameworkEnabled && (document.taskName === TRIMERGE_CREDIT_REPORT || document.taskName === CREDIT_REPORT));
};

export const documentIsUploadAllowedOnly = (
  isUploadAllowedOnly: string[],
  document: Document
) => {
  return (
    isUploadAllowedOnly && isUploadAllowedOnly.includes(document.taskName)
  );
};

export const documentUploadNotAllowedOnly = (
  isUploadNotAllowedOnly: string[],
  document: Document
) => {
  return (
    isUploadNotAllowedOnly &&
    isUploadNotAllowedOnly.includes(document.taskName)
  );
};

export const documentUploadAllowedForSection = (
  isUploadAllowedForSection: string[],
  document: Document
) => {
  return (
    isUploadAllowedForSection && isUploadAllowedForSection.includes(document.sectionName)
  );
};
