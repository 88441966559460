/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DirtyableFormStore, GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";

const form = {
  fields: {
    propertyCompId: {
      value: '',
      error: null,
      rule: '',
    },
    subjectPropertyId: {
      value: '',
      error: null,
      rule: '',
    },
    comparePropertyId: {
      value: '',
      error: null,
      rule: '',
    },
    address: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    mlsNumber: {
      value: '',
      error: null,
      rule: '',
    },
    proximity: {
      value: '',
      error: null,
      rule: '',
    },
    originalListDate: {
      value: '',
      error: null,
      rule: '',
    },
    originalListPrice: {
      value: '',
      error: null,
      rule: '',
    },
    saleDate: {
      value: '',
      error: null,
      rule: '',
    },
    salePrice: {
      value: '',
      error: null,
      rule: '',
    },
    domTrend: {
      value: '',
      error: null,
      rule: '',
    },
    propertyType: {
      value: '',
      error: null,
      rule: '',
    },
    saleType: {
      value: '',
      error: null,
      rule: '',
    },
    lotSize: {
      value: '',
      error: null,
      rule: '',
    },
    yearBuilt: {
      value: '',
      error: null,
      rule: '',
    },
    beds: {
      value: '',
      error: null,
      rule: '',
    },
    baths: {
      value: '',
      error: null,
      rule: '',
    },
    pricePerSqFt: {
      value: '',
      error: null,
      rule: '',
    },
    parkingType: {
      value: '',
      error: null,
      rule: '',
    },
    amenities: {
      value: '',
      error: null,
      rule: '',
    },
    hasPool: {
      value: '',
      error: null,
      rule: '',
    },
    mlsComments: {
      value: '',
      error: null,
      rule: '',
    },
    bestComparable: {
      value: '',
      error: null,
      rule: '',
    },
    noOfUnits: {
      value: '',
      error: null,
      rule: '',
    },
    view: {
      value: '',
      error: null,
      rule: '',
    },
    comparability: {
      value: '',
      error: null,
      rule: '',
    },
    analystComments: {
      value: '',
      error: null,
      rule: '',
    },
    latitude: {
      value: '',
      error: null,
      rule: '',
    },
    longitude: {
      value: '',
      error: null,
      rule: '',
    },
    sequence: {
      value: '',
      error: null,
      rule: '',
    },

  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PropertyCompFormStore extends DirtyableFormStore {
  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.reset();

    makeObservable(this, {
    })
  }
}
