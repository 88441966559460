import { Box } from '@material-ui/core';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { EditTaskDialog } from './editTask/editTaskDialog';
import { useEffect } from 'react';
import { generalTaskStatusOptions } from '../utils/generalTasksConstants';
type ButtonProps = {
  loanId: any,
  buttonStyle?: any
}
export const AddNewTaskButton = observer(({ loanId, buttonStyle }: ButtonProps) => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  useEffect(() => {
    generalTasksStore.setLoanId(loanId);
    generalTasksStore.fetchUserInfoAndAssigneeOptions(loanId);
  }, [loanId])

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          className={buttonStyle}
          onClick={() => editTaskStore.openAddTask()}
          color="primary"
          testId="create_task">
          Create task
        </Button>
      </Box>
      <EditTaskDialog />
    </>
  );
});
