import { ApiResponse, Service } from '@roc/feature-app-core';
import { Conversation, Participant } from '@twilio/conversations';
import { AddParticipantRequest, ChatRoomType, ConversationGroup, FetchConversationMessageRequest, Filter, ResetUnreadCountRequest, UpdateConversationNameRequest, UpdateConversationStarredStatusRequest } from '../types/communicationTypes';

export abstract class ICommunicationService extends Service {
  abstract getToken(identity: string);
  abstract getAllTokens(userId: number);

  abstract getLoanUpdateLogsChatRoom(loanId: number);

  abstract getMyConversations(requestPayload: {
    userId: number,
    start: number,
    count: number,
    filters: Filter[]
  });

  abstract getMyConversationsGrouped(requestPayload: {
    userId: number,
    start: number,
    count: number,
    conversationGroup?: ConversationGroup,
    query?: string
  });

  abstract getMyConversation(requestPayload: {
    userId: number,
    conversationSid: string,
  });

  abstract getReplyToMessages(fetchConversationMessageRequest: FetchConversationMessageRequest): Promise<ApiResponse>;

  abstract unarchiveConversation(chatRoomId: number);

  abstract getAppraisalConversations(appraisalId: number, userId: number);

  abstract getParticipantsAllowedByLoanId(loanId: number, disableGlobalLoading?: boolean);

  abstract getParticipantsAllowedByChatroomId(chatroomId: number, disableGlobalLoading?: boolean);

  abstract addParticipant(addParticipantRequest: AddParticipantRequest);

  abstract removeParticipant(conversationSid, participantSid);

  abstract getOrCreatePrivateChatRoom(userIds: number[]);

  abstract addNewConversation(conversationName: string, loanId: number);

  abstract addNewUserConversation(loanId: number, userIds: number[], conversationName: string);

  abstract getUnreadCountByLoanId(loanId: string, userId: number);

  abstract getTotalUnreadCount(userId: number);

  abstract getConversationMessageDefaultTag(id: number, conversationInfoList: Array<any>);

  abstract resetUnreadCount(resetUnreadCountRequest: ResetUnreadCountRequest);

  abstract updateConversationName(updateConversationName: UpdateConversationNameRequest);

  abstract updateConversationStarredStatus(updateConversationStarredStatus: UpdateConversationStarredStatusRequest);

  abstract getAllMessageSnippets();

  abstract verifyChatRoomName(conversationName: string, loanId: number, userId: number);

  abstract rephraseMessage(text: string);

  abstract createTechSupportChat(companyId);

  abstract getUnreadCountByUserId(companyId: number);

  abstract leaveConversation(conversationSid, participantSid);

  abstract getParticipantsDetails(conversationSid, disableGlobalLoading?: boolean);

  async getConversationParticipants(
    conversation: Conversation
  ): Promise<Participant[]> {
    return await conversation.getParticipants();
  }

}
