export const wholesaleLenderNames = [
  {label: "5th Street Capital", value: "5th Street Capital"},
  {label: "A&D Mortgage LLC", value: "A&D Mortgage LLC"},
  {label: "A&S Capital LLC", value: "A&S Capital LLC"},
  {label: "ABL RPC Residential Credit Acquisition LLC", value: "ABL RPC Residential Credit Acquisition LLC"},
  {label: "Accolend LLC", value: "Accolend LLC"},
  {label: "ACI Fund2 LLC", value: "ACI Fund2 LLC"},
  {label: "Acra Lending", value: "Acra Lending"},
  {label: "AD BPL Funding LLC, ISAOA/ATIMA", value: "AD BPL Funding LLC, ISAOA/ATIMA"},
  {label: "Advisors Mortgage Group LLC", value: "Advisors Mortgage Group LLC"},
  {label: "All Credit Considered Mortgage, Inc.", value: "All Credit Considered Mortgage, Inc."},
  {label: "Allison Lending LLC", value: "Allison Lending LLC"},
  {label: "Alpha Realty Advisors", value: "Alpha Realty Advisors"},
  {label: "Altair Debt Solutions LLC - ISAOA/ATIMA", value: "Altair Debt Solutions LLC - ISAOA/ATIMA"},
  {label: "Alternative Lending Group", value: "Alternative Lending Group"},
  {label: "American Heritage Lending LLC", value: "American Heritage Lending LLC"},
  {label: "Ameritrust Mortgage Corporation", value: "Ameritrust Mortgage Corporation"},
  {label: "Amped-Up Inc", value: "Amped-Up Inc"},
  {label: "Anchor Loans LP", value: "Anchor Loans LP"},
  {label: "Arbor Agency Lending LLC", value: "Arbor Agency Lending LLC"},
  {label: "ARC Home LLC", value: "ARC Home LLC"},
  {label: "Archwest Brokerage, Inc.", value: "Archwest Brokerage, Inc."},
  {label: "Archwest Capital", value: "Archwest Capital"},
  {label: "Archwest Funding, LLC", value: "Archwest Funding, LLC"},
  {label: "Arion Fund LLC", value: "Arion Fund LLC"},
  {label: "Asset Based Lending", value: "Asset Based Lending"},
  {label: "Atlantic Union, Inc.", value: "Atlantic Union, Inc."},
  {label: "Backfilp", value: "Backfilp"},
  {label: "Battalion Lending LLC", value: "Battalion Lending LLC"},
  {label: "Bayport Funding LLC", value: "Bayport Funding LLC"},
  {label: "Black Label Capital", value: "Black Label Capital"},
  {label: "Bleecker Family Trust", value: "Bleecker Family Trust"},
  {label: "Blue Gate Capital", value: "Blue Gate Capital"},
  {label: "Blue Gate Capital LLC/Loan Funder LLC", value: "Blue Gate Capital LLC/Loan Funder LLC"},
  {label: "BPL Mortgage Trust, LLC", value: "BPL Mortgage Trust, LLC"},
  {label: "BPL Mortgage, LLC ISAOA/ATIMA C/O Fay Servic", value: "BPL Mortgage, LLC ISAOA/ATIMA C/O Fay Servic"},
  {label: "Brickell Funding Corp", value: "Brickell Funding Corp"},
  {label: "Bridge Gap Capital, Inc", value: "Bridge Gap Capital, Inc"},
  {label: "BridgeWell Capital LLC", value: "BridgeWell Capital LLC"},
  {label: "Brighton Bank", value: "Brighton Bank"},
  {label: "Broad X Funding III LLc", value: "Broad X Funding III LLc"},
  {label: "Broadview  Funding.", value: "Broadview  Funding."},
  {label: "Broadview Capital LLC", value: "Broadview Capital LLC"},
  {label: "Broadview Funding", value: "Broadview Funding"},
  {label: "Brooklyn Funding Group, Ltd.  ISAOA-ATIMA", value: "Brooklyn Funding Group, Ltd.  ISAOA-ATIMA"},
  {label: "Builder Banc", value: "Builder Banc"},
  {label: "CALCAP Lending LLC", value: "CALCAP Lending LLC"},
  {label: "California Prime Fund LLC", value: "California Prime Fund LLC"},
  {label: "Canton Capital Management, LLC", value: "Canton Capital Management, LLC"},
  {label: "CapGuide Funding LLC ISAOA/ATIMA", value: "CapGuide Funding LLC ISAOA/ATIMA"},
  {label: "CAPITAL FUNDING FINANCIAL LLC ISAOA/ATIMA", value: "CAPITAL FUNDING FINANCIAL LLC ISAOA/ATIMA"},
  {label: "CAPITAL PRIME LENDING", value: "CAPITAL PRIME LENDING"},
  {label: "Capstone Financial DBA CTP Funding, LLC", value: "Capstone Financial DBA CTP Funding, LLC"},
  {label: "Carlyle Capital", value: "Carlyle Capital"},
  {label: "Carstensz Capital Partners, LLC", value: "Carstensz Capital Partners, LLC"},
  {label: "Castle Commercial Capital LLC", value: "Castle Commercial Capital LLC"},
  {label: "Castle Mortgage Corporation", value: "Castle Mortgage Corporation"},
  {label: "Castling Capital", value: "Castling Capital"},
  {label: "Catalyst Funding, LLC", value: "Catalyst Funding, LLC"},
  {label: "CatNY Holdings, LLC", value: "CatNY Holdings, LLC"},
  {label: "Center Street Lending", value: "Center Street Lending"},
  {label: "Central Lending LLC", value: "Central Lending LLC"},
  {label: "CEO COUNCIL, LLC ISAOA/ATIMA", value: "CEO COUNCIL, LLC ISAOA/ATIMA"},
  {label: "Chambliss Mortgage Capital", value: "Chambliss Mortgage Capital"},
  {label: "Change Lending  LLC", value: "Change Lending  LLC"},
  {label: "Churchill Funding I ISAOA/ATIMA", value: "Churchill Funding I ISAOA/ATIMA"},
  {label: "Citadel Servicing Corporation", value: "Citadel Servicing Corporation"},
  {label: "Civic Financial Services", value: "Civic Financial Services"},
  {label: "Collins Funding LLC", value: "Collins Funding LLC"},
  {label: "Commercial Lender  LLC", value: "Commercial Lender  LLC"},
  {label: "Commercial Lender LLC", value: "Commercial Lender LLC"},
  {label: "Commercial Lender, LLC", value: "Commercial Lender, LLC"},
  {label: "Commercial Lender, LLC ISAOA/ATIMA", value: "Commercial Lender, LLC ISAOA/ATIMA"},
  {label: "Commercial Lender. LLC", value: "Commercial Lender. LLC"},
  {label: "Commercial Loan Funding LLC", value: "Commercial Loan Funding LLC"},
  {label: "Constructive Mortgage, LLC", value: "Constructive Mortgage, LLC"},
  {label: "Conventus Holdings Corp", value: "Conventus Holdings Corp"},
  {label: "Conventus LLC", value: "Conventus LLC"},
  {label: "CoreVest American Finance Lender LLC", value: "CoreVest American Finance Lender LLC"},
  {label: "Corporate Capital Solutions", value: "Corporate Capital Solutions"},
  {label: "CRE Capital LLC", value: "CRE Capital LLC"},
  {label: "Creative Hard Money LLC", value: "Creative Hard Money LLC"},
  {label: "DB Premier Asset Partners LLC", value: "DB Premier Asset Partners LLC"},
  {label: "Diversified Income Strategy Fund", value: "Diversified Income Strategy Fund"},
  {label: "Dominion Financial Services, LLC", value: "Dominion Financial Services, LLC"},
  {label: "Draper and Kramer Mortgage Corp", value: "Draper and Kramer Mortgage Corp"},
  {label: "Dream Capital LLC", value: "Dream Capital LLC"},
  {label: "East Coast Capital Corp. ISAOA/ATIMA", value: "East Coast Capital Corp. ISAOA/ATIMA"},
  {label: "Easy Street Capital Investments, LLC.", value: "Easy Street Capital Investments, LLC."},
  {label: "ECF FUND I LLC ISAOA/ATIMA", value: "ECF FUND I LLC ISAOA/ATIMA"},
  {label: "Ellevana Capital LLC", value: "Ellevana Capital LLC"},
  {label: "Emporium TPO", value: "Emporium TPO"},
  {label: "Encore SFR Finance, LLC", value: "Encore SFR Finance, LLC"},
  {label: "Envision funded LLC", value: "Envision funded LLC"},
  {label: "Envision Funding", value: "Envision Funding"},
  {label: "Equity Lending Solutions", value: "Equity Lending Solutions"},
  {label: "Excelerate Capital", value: "Excelerate Capital"},
  {label: "Executive Capital Group, Inc", value: "Executive Capital Group, Inc"},
  {label: "Express Capital Financing", value: "Express Capital Financing"},
  {label: "Fahey Bank", value: "Fahey Bank"},
  {label: "Fairmount Funding, LLC", value: "Fairmount Funding, LLC"},
  {label: "Finance Of America Mortgage LLC", value: "Finance Of America Mortgage LLC"},
  {label: "Finance of America Mortgage LLC, ISAOA", value: "Finance of America Mortgage LLC, ISAOA"},
  {label: "First Equity Funding, LLC", value: "First Equity Funding, LLC"},
  {label: "First Loans Cap", value: "First Loans Cap"},
  {label: "First Trinity Mortgage Company, LLC", value: "First Trinity Mortgage Company, LLC"},
  {label: "FIRSTLOANS INC", value: "FIRSTLOANS INC"},
  {label: "Fix and Flip Loan ISAOA ATIMA", value: "Fix and Flip Loan ISAOA ATIMA"},
  {label: "Flatiron Realty Capital", value: "Flatiron Realty Capital"},
  {label: "Flip That House INC", value: "Flip That House INC"},
  {label: "FLIPX", value: "FLIPX"},
  {label: "FM Home Loans, LLC", value: "FM Home Loans, LLC"},
  {label: "Fortress Financial Partners LLC", value: "Fortress Financial Partners LLC"},
  {label: "Fortress Square Capital", value: "Fortress Square Capital"},
  {label: "Four Corner Capital", value: "Four Corner Capital"},
  {label: "FTLQ Credit I LLC", value: "FTLQ Credit I LLC"},
  {label: "Fuente Funding", value: "Fuente Funding"},
  {label: "Fund Me Capital LLC", value: "Fund Me Capital LLC"},
  {label: "Funding Door LLC", value: "Funding Door LLC"},
  {label: "Gauntlet Funding LLC ISAOA/ATIMA", value: "Gauntlet Funding LLC ISAOA/ATIMA"},
  {label: "Gauntlet Funding/Loan Funder", value: "Gauntlet Funding/Loan Funder"},
  {label: "Gemach Mei Hadas", value: "Gemach Mei Hadas"},
  {label: "Global Integrity Finance", value: "Global Integrity Finance"},
  {label: "Global Point Financial, LLC", value: "Global Point Financial, LLC"},
  {label: "Gps Capital Fund LLC", value: "Gps Capital Fund LLC"},
  {label: "Gps Capital LLC ISAOA/ATIMA", value: "Gps Capital LLC ISAOA/ATIMA"},
  {label: "Groundfloor Credit 1, LLC ISAOA, ATIMA", value: "Groundfloor Credit 1, LLC ISAOA, ATIMA"},
  {label: "Guardian Financial, LLC", value: "Guardian Financial, LLC"},
  {label: "Gudz Solutions LLC", value: "Gudz Solutions LLC"},
  {label: "Hanover Community Bank as Successor to Savoy Bank", value: "Hanover Community Bank as Successor to Savoy Bank"},
  {label: "Hard Money USA", value: "Hard Money USA"},
  {label: "Hardmoneyman.com LLC", value: "Hardmoneyman.com LLC"},
  {label: "Hardmoneyusacorp", value: "Hardmoneyusacorp"},
  {label: "Heritage Bank", value: "Heritage Bank"},
  {label: "HIB Funding LLC", value: "HIB Funding LLC"},
  {label: "HIghline Lending LLC", value: "HIghline Lending LLC"},
  {label: "HMAC dba Unite Mortgage", value: "HMAC dba Unite Mortgage"},
  {label: "HML Investments", value: "HML Investments"},
  {label: "Home Point Financial Corporation  NMLS #7706", value: "Home Point Financial Corporation  NMLS #7706"},
  {label: "Hometown Equity Mortgage, LLC dba meMortgage", value: "Hometown Equity Mortgage, LLC dba meMortgage"},
  {label: "Hometown Equity Mortgage, LLC dba theLender", value: "Hometown Equity Mortgage, LLC dba theLender"},
  {label: "Homexpress Mortgage Corp", value: "Homexpress Mortgage Corp"},
  {label: "HomeXpress Mortgage Corp.", value: "HomeXpress Mortgage Corp."},
  {label: "HouseMax Funding Fund I, LLC ISAOA", value: "HouseMax Funding Fund I, LLC ISAOA"},
  {label: "Hudson Group Realty Network INC", value: "Hudson Group Realty Network INC"},
  {label: "Ice Lender Holdings LLC", value: "Ice Lender Holdings LLC"},
  {label: "IceCap Group", value: "IceCap Group"},
  {label: "IFP Fund I LLC ISAOA/ATIMA", value: "IFP Fund I LLC ISAOA/ATIMA"},
  {label: "IFP Fund I, LLC", value: "IFP Fund I, LLC"},
  {label: "IFP Fund I, LLC ISAOA/ATIMA", value: "IFP Fund I, LLC ISAOA/ATIMA"},
  {label: "IL Lending LLC", value: "IL Lending LLC"},
  {label: "In The Know Realty, Co.", value: "In The Know Realty, Co."},
  {label: "Infinity Lending LLC", value: "Infinity Lending LLC"},
  {label: "Insula Capital Group llc", value: "Insula Capital Group llc"},
  {label: "Intercapital Funding Inc", value: "Intercapital Funding Inc"},
  {label: "Investment Banker Network", value: "Investment Banker Network"},
  {label: "Iron Fund", value: "Iron Fund"},
  {label: "Iska Mortgage LLC", value: "Iska Mortgage LLC"},
  {label: "Jacobs Partners Corp", value: "Jacobs Partners Corp"},
  {label: "Jask Capital LLC dba Lend Some Money", value: "Jask Capital LLC dba Lend Some Money"},
  {label: "Jet Direct Funding Corp", value: "Jet Direct Funding Corp"},
  {label: "JET MORTGAGE", value: "JET MORTGAGE"},
  {label: "JFQ Lending, LLC.", value: "JFQ Lending, LLC."},
  {label: "Jocic Capital, LLC", value: "Jocic Capital, LLC"},
  {label: "Kastle Mortgage Finance", value: "Kastle Mortgage Finance"},
  {label: "Kiavi Funding, Inc", value: "Kiavi Funding, Inc"},
  {label: "Lafayette Federal Credit Union", value: "Lafayette Federal Credit Union"},
  {label: "Lender, C/O Superior Loan Servicing ISAOA/ATIMA", value: "Lender, C/O Superior Loan Servicing ISAOA/ATIMA"},
  {label: "Lendevent Realty Capital LLC", value: "Lendevent Realty Capital LLC"},
  {label: "LendSure Mortgage Corp", value: "LendSure Mortgage Corp"},
  {label: "LendVent Realty Capital LLC", value: "LendVent Realty Capital LLC"},
  {label: "LendVent Realty Capital LLC,", value: "LendVent Realty Capital LLC,"},
  {label: "Lev Cap LLC", value: "Lev Cap LLC"},
  {label: "Lima One Capital", value: "Lima One Capital"},
  {label: "Loan Aggregator, LLC.", value: "Loan Aggregator, LLC."},
  {label: "Loan Funder LLC", value: "Loan Funder LLC"},
  {label: "Loan Funder, c/o Superior Loan Servicing, ISAOA/ATIMA", value: "Loan Funder, c/o Superior Loan Servicing, ISAOA/ATIMA"},
  {label: "Loan Funder, LLC, CO Superior Loan Servicing, ISAOA/ATIMA", value: "Loan Funder, LLC, CO Superior Loan Servicing, ISAOA/ATIMA"},
  {label: "Loan Funding Group II LLC, ISAOA/ATIMA", value: "Loan Funding Group II LLC, ISAOA/ATIMA"},
  {label: "Loan Lender LLC", value: "Loan Lender LLC"},
  {label: "Logan Finance Corporation, ISAOA", value: "Logan Finance Corporation, ISAOA"},
  {label: "Longhorn Investments, LLC", value: "Longhorn Investments, LLC"},
  {label: "M&M Private Lending Group, LLC", value: "M&M Private Lending Group, LLC"},
  {label: "Malve Fund LLC", value: "Malve Fund LLC"},
  {label: "MBS FUNDING INC ISAOA / ATIMA", value: "MBS FUNDING INC ISAOA / ATIMA"},
  {label: "MCG Venture LLC", value: "MCG Venture LLC"},
  {label: "MCM Capital Solutions Inc, LLC ISAOA / ATIMA", value: "MCM Capital Solutions Inc, LLC ISAOA / ATIMA"},
  {label: "Merchants Mortgage & Trust Corporation, LLC ISAOA", value: "Merchants Mortgage & Trust Corporation, LLC ISAOA"},
  {label: "Mid Hudson Capital", value: "Mid Hudson Capital"},
  {label: "Milo Credit LLC", value: "Milo Credit LLC"},
  {label: "MINO Lending Solutions", value: "MINO Lending Solutions"},
  {label: "Mioym Commercial Capital", value: "Mioym Commercial Capital"},
  {label: "Mission Property Funding/Loan Funder LLC", value: "Mission Property Funding/Loan Funder LLC"},
  {label: "MJ Doubletrees LLC", value: "MJ Doubletrees LLC"},
  {label: "MoFin Lending Corporation", value: "MoFin Lending Corporation"},
  {label: "Mortgage Funding 01 ISAOA/ATIMA", value: "Mortgage Funding 01 ISAOA/ATIMA"},
  {label: "Mortgage Funding 02 LLC ATIMA/ISAOA", value: "Mortgage Funding 02 LLC ATIMA/ISAOA"},
  {label: "Mortgage Funding 02 LLC ISAOA/ATIMA", value: "Mortgage Funding 02 LLC ISAOA/ATIMA"},
  {label: "Mutual Omaha Mortgage INC", value: "Mutual Omaha Mortgage INC"},
  {label: "Myers Investment Group LLC", value: "Myers Investment Group LLC"},
  {label: "National Loan Funding", value: "National Loan Funding"},
  {label: "NDL FUNDING", value: "NDL FUNDING"},
  {label: "New Republic Funding, LLC ISAOA ATIMA", value: "New Republic Funding, LLC ISAOA ATIMA"},
  {label: "New Silver Lending LLC", value: "New Silver Lending LLC"},
  {label: "Nextres, LLC ISAOA/ATIMA", value: "Nextres, LLC ISAOA/ATIMA"},
  {label: "No Limit Capital", value: "No Limit Capital"},
  {label: "Northfield Bank", value: "Northfield Bank"},
  {label: "Northpointe Bank", value: "Northpointe Bank"},
  {label: "Nuvida Funding, LLC", value: "Nuvida Funding, LLC"},
  {label: "Nvestor Funding", value: "Nvestor Funding"},
  {label: "Oaktree Funding Corporation, ISAOA", value: "Oaktree Funding Corporation, ISAOA"},
  {label: "OfferMarket Capital LLC", value: "OfferMarket Capital LLC"},
  {label: "Onyx Equity Partners Corp", value: "Onyx Equity Partners Corp"},
  {label: "Optimus Capital, Inc.", value: "Optimus Capital, Inc."},
  {label: "Option Funding Inc", value: "Option Funding Inc"},
  {label: "Park Place Finance", value: "Park Place Finance"},
  {label: "Park Place Finance, LLC", value: "Park Place Finance, LLC"},
  {label: "Patch of Land", value: "Patch of Land"},
  {label: "Pimlico Refinance, LLC", value: "Pimlico Refinance, LLC"},
  {label: "Private Moni Lenders LLC", value: "Private Moni Lenders LLC"},
  {label: "Project Private Capital", value: "Project Private Capital"},
  {label: "Quentin Realty Capital LLC", value: "Quentin Realty Capital LLC"},
  {label: "Quontic Bank", value: "Quontic Bank"},
  {label: "Quontic Bank ISAOA/ATIMA", value: "Quontic Bank ISAOA/ATIMA"},
  {label: "R.E Lending LLC ISAOA ATIMA", value: "R.E Lending LLC ISAOA ATIMA"},
  {label: "Rapid Excelisior Funding", value: "Rapid Excelisior Funding"},
  {label: "RBI Mortgages ISAOA/ ATIMA", value: "RBI Mortgages ISAOA/ ATIMA"},
  {label: "RCN Capital", value: "RCN Capital"},
  {label: "RCS Funding Group LLC", value: "RCS Funding Group LLC"},
  {label: "RD W SPV LLC", value: "RD W SPV LLC"},
  {label: "Real Estate Funder", value: "Real Estate Funder"},
  {label: "Realty Capital Finance", value: "Realty Capital Finance"},
  {label: "Realty Closing Solution LLC", value: "Realty Closing Solution LLC"},
  {label: "REF Group LLC", value: "REF Group LLC"},
  {label: "Reliable Holdings Manager, LLC DBA Lendz Financial ISAOA/ATIMA", value: "Reliable Holdings Manager, LLC DBA Lendz Financial ISAOA/ATIMA"},
  {label: "Renovo Financial", value: "Renovo Financial"},
  {label: "Reward Services, Inc", value: "Reward Services, Inc"},
  {label: "RJ :LIVING LLC", value: "RJ :LIVING LLC"},
  {label: "Rock East Funding/Loan Funder LLC", value: "Rock East Funding/Loan Funder LLC"},
  {label: "Rock East Group/Loan Funder LLC", value: "Rock East Group/Loan Funder LLC"},
  {label: "Rock North Funding", value: "Rock North Funding"},
  {label: "Rock Solid Funding", value: "Rock Solid Funding"},
  {label: "Roger Anderson Family Trust", value: "Roger Anderson Family Trust"},
  {label: "Sachem Capital", value: "Sachem Capital"},
  {label: "Sachem Capital Corp. ISAOA/ATIMA", value: "Sachem Capital Corp. ISAOA/ATIMA"},
  {label: "Sauer Capital", value: "Sauer Capital"},
  {label: "Savi Ranch Financial", value: "Savi Ranch Financial"},
  {label: "ShareStates", value: "ShareStates"},
  {label: "Sharestates Investments, LLC ISAOA / ATIMA", value: "Sharestates Investments, LLC ISAOA / ATIMA"},
  {label: "Simplending Financial LLC", value: "Simplending Financial LLC"},
  {label: "Solid Oak Financing", value: "Solid Oak Financing"},
  {label: "Spreo Capital Partners", value: "Spreo Capital Partners"},
  {label: "Stormfield Capital Funding I", value: "Stormfield Capital Funding I"},
  {label: "Stormfield Capital Funding I, LLC ISAOA / ATIMA", value: "Stormfield Capital Funding I, LLC ISAOA / ATIMA"},
  {label: "SuccessCap,LLC", value: "SuccessCap,LLC"},
  {label: "Swifte Capital", value: "Swifte Capital"},
  {label: "TBD", value: "TBD"},
  {label: "TCB Consults", value: "TCB Consults"},
  {label: "TCMAX LLC", value: "TCMAX LLC"},
  {label: "Temple View Capital Funding, LP", value: "Temple View Capital Funding, LP"},
  {label: "The Citizens National Bank Of Bluffton", value: "The Citizens National Bank Of Bluffton"},
  {label: "The Lender", value: "The Lender"},
  {label: "Tidal Loans LLC", value: "Tidal Loans LLC"},
  {label: "Tower Fund Capital", value: "Tower Fund Capital"},
  {label: "Trident Realty Investments, LLC", value: "Trident Realty Investments, LLC"},
  {label: "True Point Lending", value: "True Point Lending"},
  {label: "Truly Investor Capital", value: "Truly Investor Capital"},
  {label: "United Lending LLC", value: "United Lending LLC"},
  {label: "United Wholesale Mortgage, LLC ISAOA/ATIMA", value: "United Wholesale Mortgage, LLC ISAOA/ATIMA"},
  {label: "Universal Commercial Capital", value: "Universal Commercial Capital"},
  {label: "Upfront Dollars LLC", value: "Upfront Dollars LLC"},
  {label: "US Capital Advisors", value: "US Capital Advisors"},
  {label: "Verus Residential Loanco", value: "Verus Residential Loanco"},
  {label: "VIS Capital Venture, LLC", value: "VIS Capital Venture, LLC"},
  {label: "Visio Lending", value: "Visio Lending"},
  {label: "Viva Capital Group LLC", value: "Viva Capital Group LLC"},
  {label: "Voro Capital", value: "Voro Capital"},
  {label: "Wall Street Mortgage Bankers Ltd", value: "Wall Street Mortgage Bankers Ltd"},
  {label: "We Lend, LLC", value: "We Lend, LLC"},
  {label: "XPRESS LOANS 911 LLC ISAOA ATIMA", value: "XPRESS LOANS 911 LLC ISAOA ATIMA"},
  {label: "Yellow Jacket Ventures, LLC", value: "Yellow Jacket Ventures, LLC"},
  {label: "Zinc Financial", value: "Zinc Financial"},
  {label: "Zohar Funding", value: "Zohar Funding"},
];

export const propertyTypeOptions = [
  { value: 'Co-Operative (Co-Op)', label: 'Co-Operative (Co-Op)' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Commercial Mixed se', label: 'Commercial Mixed se' },
  { value: 'Commercial Multi Family', label: 'Commercial Multi Family' },
  { value: 'Condominium', label: 'Condominium' },
  { value: 'Mobile Home', label: 'Mobile Home' },
  { value: 'Multi-Unit Property', label: 'Multi-Unit Property' },
  { value: 'Single Property', label: 'Single Property' }
];

export const occupancyTypeOptions = [
  { value: 'Vacant', label: 'Vacant' },
  { value: 'Tenant', label: 'Tenant' },
  { value: 'Owner Occupied', label: 'Owner Occupied' }
];

export const transactionTypeOptions = [
  { value: 'Acquisition', label: 'Acquisition' },
  { value: 'Construction', label: 'Construction' },
  { value: 'HUD Real Estate Owned (REO) Property', label: 'HUD Real Estate Owned (REO) Property' },
  { value: 'Investment', label: 'Investment' },
  { value: 'Other', label: 'Other' },
  { value: 'Pre-Foreclosure', label: 'Pre-Foreclosure' },
  { value: 'Purchase', label: 'Purchase' },
  { value: 'Re-Finance', label: 'Re-Finance' },
];

export const loanTypeOptions = [
  { value: 'Conventional', label: 'Conventional' },
  { value: 'FHA', label: 'FHA' },
];

export const appraisalTypeOptions = [
  {value:"1004 1 Family", label:"1004 1 Family"},
  {value:"1004 1 Family FHA", label:"1004 1 Family FHA"},
  {value:"1004 ARV - AIV (ARV Value and AS-IS Value)", label:"1004 ARV - AIV (ARV Value and AS-IS Value)"},
  {value:"1004 ARV - AIV with 1007 (ARV Value and AS-IS Valu", label:"1004 ARV - AIV with 1007 (ARV Value and AS-IS Valu"},
  {value:"1004 ARV (After Repair Value only)", label:"1004 ARV (After Repair Value only)"},
  {value:"1004 ARV with 1007 (After Repair Value only)", label:"1004 ARV with 1007 (After Repair Value only)"},
  {value:"1004 Desktop-70D", label:"1004 Desktop-70D"},
  {value:"1004 with 1007 Rental property (AS-IS with rent)", label:"1004 with 1007 Rental property (AS-IS with rent)"},
  {value:"1004C", label:"1004C"},
  {value:"2055 - As is", label:"2055 - As is"},
  {value:"2055 ARV - AIV (ARV Value and AS-IS Value)", label:"2055 ARV - AIV (ARV Value and AS-IS Value)"},
  {value:"2055 ARV - AIV with 1007 (ARV Value and AS-IS Valu", label:"2055 ARV - AIV with 1007 (ARV Value and AS-IS Valu"},
  {value:"2055 ARV (After Repair Value only)", label:"2055 ARV (After Repair Value only)"},
  {value:"2055 ARV with 1007 (After Repair Value only)", label:"2055 ARV with 1007 (After Repair Value only)"},
  {value:"2055 with 1007 Rental property (AS-IS value with r", label:"2055 with 1007 Rental property (AS-IS value with r"},
  {value:"Uniform Residential Appraisal Report Hybrid (1004 Hybrid/70H)", label:"Uniform Residential Appraisal Report Hybrid (1004 Hybrid/70H)"},
  {value:"Desktop 1004 ARV", label:"Desktop 1004 ARV"},
  {value:"Desktop 1004 ARV - AIV with 1007 (ARV & As is)", label:"Desktop 1004 ARV - AIV with 1007 (ARV & As is)"},
  {value:"Desktop 1004 ARV with 1007 (After Repair Value)", label:"Desktop 1004 ARV with 1007 (After Repair Value)"},
  {value:"1025 ARV - AIV (ARV Value and AS-IS Value)", label:"1025 ARV - AIV (ARV Value and AS-IS Value)"},
  {value:"1025 ARV (After Repair Value only)", label:"1025 ARV (After Repair Value only)"},
  {value:"1025 Rental property (AS-IS value with rent)", label:"1025 Rental property (AS-IS value with rent)"},
  {value:"1073 ARV - AIV (ARV Value and AS-IS Value)", label:"1073 ARV - AIV (ARV Value and AS-IS Value)"},
  {value:"1073 ARV - AIV with 1007 (ARV Value and AS-IS Valu", label:"1073 ARV - AIV with 1007 (ARV Value and AS-IS Valu"},
  {value:"1073 Condo ARV (After Repair Value only)", label:"1073 Condo ARV (After Repair Value only)"},
  {value:"1073 Condo ARV with 1007 (After Repair Value only)", label:"1073 Condo ARV with 1007 (After Repair Value only)"},
  {value:"1073 Condo FHA", label:"1073 Condo FHA"},
  {value:"1073 Condo with 1007 Rental property (ASIS value)", label:"1073 Condo with 1007 Rental property (ASIS value)"},
  {value:"1075 ARV - AIV & 1007 (ARV Value and AS-IS Value)", label:"1075 ARV - AIV & 1007 (ARV Value and AS-IS Value)"},
  {value:"1075 ARV - AIV (ARV Value and AS-IS Value)", label:"1075 ARV - AIV (ARV Value and AS-IS Value)"},
  {value:"1075 ARV (After Repair Value only)", label:"1075 ARV (After Repair Value only)"},
  {value:"1075 AS-IS Value", label:"1075 AS-IS Value"},
  {value:"1075 Condo ARV with 1007 (After Repair Value only)", label:"1075 Condo ARV with 1007 (After Repair Value only)"},
  {value:"1075 Condo with 1007 Rental property (ASIS value)", label:"1075 Condo with 1007 Rental property (ASIS value)"},
  {value:"2090 Co-op", label:"2090 Co-op"},
  {value:"2090 Co-op FHA", label:"2090 Co-op FHA"},
  {value:"70B - Manufactured Home", label:"70B - Manufactured Home"},
  {value:"Budget Review", label:"Budget Review"},
  {value:"Commercial Multi-family AIV - ARV", label:"Commercial Multi-family AIV - ARV"},
  {value:"Commercial Multi-family ARV", label:"Commercial Multi-family ARV"},
  {value:"Commercial Multi-family AS-IS", label:"Commercial Multi-family AS-IS"},
  {value:"Office/Retail", label:"Office/Retail"},
  {value:"Industrial", label:"Industrial"},
  {value:"Mixed use AIV - ARV", label:"Mixed use AIV - ARV"},
  {value:"Mixed use ARV", label:"Mixed use ARV"},
  {value:"Mixed use AS-IS", label:"Mixed use AS-IS"},
  {value:"1004D Appraisal Update", label:"1004D Appraisal Update"},
  {value:"1004D Final Inspection", label:"1004D Final Inspection"},
  {value:"1007 Form", label:"1007 Form"},
  {value:"1007-216", label:"1007-216"},
  {value:"1033 Desk Review", label:"1033 Desk Review"},
  {value:"2000 Field Review", label:"2000 Field Review"},
  {value:"216 Form", label:"216 Form"},
  {value:"ESA", label:"ESA"},
  {value:"HRDR", label:"HRDR"},
  {value:"Land Value", label:"Land Value"},
  {value:"PCA w/Budget Review", label:"PCA w/Budget Review"},
  {value:"PCA/PCR", label:"PCA/PCR"},
  {value:"Phase II", label:"Phase II"},
  {value:"Radon Testing", label:"Radon Testing"},
  {value:"Seismic", label:"Seismic"},
  {value:"STR 1007 form", label:"STR 1007 form"},
];
