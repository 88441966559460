import { Box, Container, Grid } from '@material-ui/core';
import { Layout } from '@roc/feature-app-core';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import { TaskStatus } from '../types/generalTasksTypes';
import { Swimlane } from './swimlane';

export const KanbanView = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  return (
    <Grid container spacing={2} style={{ height: '100%' }}>
      <Grid item xs={4}>
        <Swimlane
          title="Open"
          gridStore={generalTasksStore.kanbanGridStores[TaskStatus.OPEN]}
        />
      </Grid>
      <Grid item xs={4}>
        <Swimlane
          title="Closed"
          gridStore={generalTasksStore.kanbanGridStores[TaskStatus.CLOSED]}
        />
      </Grid>
    </Grid>
  );
});
