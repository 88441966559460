import { GlobalStore, GridStore,ApiResponse } from '@roc/feature-app-core';
import { BackOfficeLoansService } from '../services/backOfficeLoansService';
import { action, makeObservable, observable } from 'mobx';
import { Grid } from 'ag-grid-community';
import { StdioNull } from 'child_process';



export class BackOfficeLoansStore {
  public globalStore: GlobalStore;
  private backOfficeLoansService: BackOfficeLoansService;

  pipelineBackOfficeLoansGridStore: GridStore;
  primaryBackOfficeLoansGridStore: GridStore;
  secondaryBackOfficeLoansGridStore: GridStore;
  pipelineBackOfficeLoansByContactId: GridStore;
  myLoansGridStore: GridStore;
  appraisalTasksBackOfficeLoansGridStore: GridStore;
  myAgreedLoansGridStore: GridStore;

  lenderIdForLoanVolumeDashboard: number;
  borrowerForLoanVolumeDashboard: number;

  tabActionSelection: any;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.backOfficeLoansService = new BackOfficeLoansService();

    this.pipelineBackOfficeLoansGridStore = new GridStore(() => this.fetchPipelineBackOfficeLoans(), null, 50);
    this.primaryBackOfficeLoansGridStore = new GridStore(() => this.fetchPrimaryBackOfficeLoans(), null, 50);
    this.secondaryBackOfficeLoansGridStore = new GridStore(() => this.fetchSecondaryBackOfficeLoans(), null, 50);
    this.pipelineBackOfficeLoansByContactId = new GridStore(() => this.fetchPipelineBackOfficeLoansByContactId(this.lenderIdForLoanVolumeDashboard, this.borrowerForLoanVolumeDashboard), null, 50);
    this.myLoansGridStore = new GridStore(() => this.fetchMyLoans(), null, 50);
    this.appraisalTasksBackOfficeLoansGridStore = new GridStore(() => this.fetchAppraisalTasksBackOfficeLoans(), null, 50);
    this.myAgreedLoansGridStore = new GridStore(() => this.fetchMyAgreedLoans(),null,50)
    this.tabActionSelection = null;
    makeObservable(this, {
      tabActionSelection: observable,
      setTabActionSelection: action,
    })
  }

    isLoanSubtypeSupported(subtype) {
    return true;
  }

  private async fetchPipelineBackOfficeLoans() {
    try {
      const response = await this.backOfficeLoansService.getCompaniesLoansForBackOfficeRole(
        "ALL",
        this.pipelineBackOfficeLoansGridStore.gridData.meta.pageNumber,
        this.pipelineBackOfficeLoansGridStore.gridData.meta.pageSize,
        this.pipelineBackOfficeLoansGridStore.gridData.meta.sortDir,
        this.pipelineBackOfficeLoansGridStore.gridData.meta.sortBy,
        this.pipelineBackOfficeLoansGridStore.gridData.meta.filters,
        this.pipelineBackOfficeLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };

  private async fetchMyAgreedLoans() {
    const externalStatus = this.myAgreedLoansGridStore.gridData.meta.dropdownFilters
      ?.status;
    const gridFilters = this.myAgreedLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      loanSubType: undefined,
      loanSubtype: gridFilters.loanSubType,
    };
    try {
      const response: ApiResponse = await this.backOfficeLoansService.getMyAgreedLoans(
        this.myAgreedLoansGridStore.gridData.meta.pageNumber,
        this.myAgreedLoansGridStore.gridData.meta.pageSize,
        this.myAgreedLoansGridStore.gridData.meta.sortDir,
        this.myAgreedLoansGridStore.gridData.meta.sortBy,
        filters,
        externalStatus,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }

  private async fetchAppraisalTasksBackOfficeLoans() {
    try {
      const response = await this.backOfficeLoansService.getAppraisalTasksForBackOfficeRole(
        "ALL",
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.pageNumber,
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.pageSize,
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.sortDir,
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.sortBy,
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.filters,
        this.appraisalTasksBackOfficeLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };


  private async fetchPipelineBackOfficeLoansByContactId(lenderId, borrowerId) {
    try {
      if (lenderId) {
        const response = await this.backOfficeLoansService.getCompaniesLoansForBackOfficeRoleByLenderId(
          "ALL",
          this.pipelineBackOfficeLoansByContactId.gridData.meta.pageNumber,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.pageSize,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.sortDir,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.sortBy,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.filters,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.dropdownFilters,
          lenderId
        );
        return response;
      }
      if (borrowerId) {
        const response = await this.backOfficeLoansService.getCompaniesLoansForBackOfficeRoleByBorrowerId(
          "ALL",
          this.pipelineBackOfficeLoansByContactId.gridData.meta.pageNumber,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.pageSize,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.sortDir,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.sortBy,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.filters,
          this.pipelineBackOfficeLoansByContactId.gridData.meta.dropdownFilters,
          borrowerId
        );
        return response;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };

  private async fetchPrimaryBackOfficeLoans() {
    try {
      const response = await this.backOfficeLoansService.getCompaniesLoansForBackOfficeRole(
        "PRIMARY",
        this.primaryBackOfficeLoansGridStore.gridData.meta.pageNumber,
        this.primaryBackOfficeLoansGridStore.gridData.meta.pageSize,
        this.primaryBackOfficeLoansGridStore.gridData.meta.sortDir,
        this.primaryBackOfficeLoansGridStore.gridData.meta.sortBy,
        this.primaryBackOfficeLoansGridStore.gridData.meta.filters,
        this.primaryBackOfficeLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };

  private async fetchSecondaryBackOfficeLoans() {
    try {
      const response = await this.backOfficeLoansService.getCompaniesLoansForBackOfficeRole(
        "SECONDARY",
        this.secondaryBackOfficeLoansGridStore.gridData.meta.pageNumber,
        this.secondaryBackOfficeLoansGridStore.gridData.meta.pageSize,
        this.secondaryBackOfficeLoansGridStore.gridData.meta.sortDir,
        this.secondaryBackOfficeLoansGridStore.gridData.meta.sortBy,
        this.secondaryBackOfficeLoansGridStore.gridData.meta.filters,
        this.secondaryBackOfficeLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };

  private async fetchMyLoans() {
    try {
      const response = await this.backOfficeLoansService.getMyLoans(
        this.myLoansGridStore.gridData.meta.pageNumber,
        this.myLoansGridStore.gridData.meta.pageSize,
        this.myLoansGridStore.gridData.meta.sortDir,
        this.myLoansGridStore.gridData.meta.sortBy,
        this.myLoansGridStore.gridData.meta.filters,
        this.myLoansGridStore.gridData.meta.dropdownFilters,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while fetching the loans.`,
      });
    }
  };

  setTabActionSelection(value) {
    this.tabActionSelection = value;
  }

}
