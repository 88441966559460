import { useContext } from 'react';
import { StoreContext } from '@roc/feature-app-core';
import { ExternalLoansDashboardStore } from '../stores/externalLoansDashboardStore';
import { ForeclosureInvoiceStore } from '../stores/foreclosureInvoiceStore';

export const useExternalAgentLoansStore = () =>
  useContext<{
    foreclosureInvoiceStore: ForeclosureInvoiceStore;
    externalLoansDashboardStore: ExternalLoansDashboardStore;
  }>(StoreContext);
