import { observer } from "mobx-react";
import { SubjectProperty } from "./subjectProperty";
import { Button, Paper } from "@roc/ui";
import { Box, Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import { ConstructionFeasibilityReview } from "./constructionFeasibilityReview";
import { ScopeOfWorkSummary } from "./scopeOfWorkSummary";
import { GeneralProjectInformation } from "./generalProjectInformation";
import { ConstructionDataSummary } from "./constructionDataSummary";
import { useEffect, useState } from "react";
import { useDocumentStore } from "@roc/feature-documents";
import { Document } from '@roc/feature-types';
import { UwReasonDialog } from "./components/uwReasonDialog";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    background: theme.palette.grey[300],
    width: '100%',
    padding: theme.spacing(2),
  },
  sectionName: {
    fontSize: '22px',
    color: theme.palette.grey[500],
  },
  item: {
    marginBottom: '10px',
  },
  button: {
    marginLeft: '5px',
  },
}))

export type ConstructionFeasibilityToDoProps = {
  loanId?: any;
  onClose: () => void;
  document: Document;
  propertyId?: any;
  underwritingReview?: boolean;
};

export const ConstructionFeasibilityToDo = observer((props: ConstructionFeasibilityToDoProps) => {
  const { loanId, onClose, document, propertyId, underwritingReview } = props;
  const { documentStore: { documentFormStore }, } = useDocumentStore();
  const { cmtFormStore } = documentFormStore;
  const collateralId = propertyId ?? document.collateralId;
  const [uwReasonModal, setUwReasonModal] = useState(null);

  useEffect(() => {
    cmtFormStore.resetStore();
    cmtFormStore.getCMTData(collateralId, loanId);
  }, [])

  const classes = useStyles();

  const onSave = () => {
    cmtFormStore.saveCMTData(loanId, collateralId);
  }

  const onReviewComplete = () => {
    cmtFormStore.revisionComplete(collateralId, loanId);
    cmtFormStore.saveCMTData(loanId, collateralId);
  }

  const onReviewCompleteWithComments = () => {
    cmtFormStore.revisionCompleteWithComments(collateralId, loanId);
    cmtFormStore.saveCMTData(loanId, collateralId);
  }

  const onRequestRevision = () => {
    cmtFormStore.requestRevision(collateralId, loanId);
    cmtFormStore.saveCMTData(loanId, collateralId);
  }

  const updateSowUnderwrittingStatus = (status) => {
    setUwReasonModal(status);
  }

  const handleCloseUwReasonModal = () => {
    setUwReasonModal(null);
  }

  const handleConfirmUwReasonModal = (message?: string) => {
    cmtFormStore.updateSowUWStatus(collateralId, loanId, uwReasonModal, message, handleCloseUwReasonModal);
  }


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.item}>
          <SubjectProperty store={cmtFormStore} />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <SectionName title="SUMMARY" />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <ConstructionFeasibilityReview store={cmtFormStore} />
        </Grid>
        {/* <Grid item xs={12} className={classes.item}>
          <ScopeOfWorkSummary />
        </Grid> */}
        <Grid item xs={12} className={classes.item}>
          <SectionName title="CMT ANALYSIS" />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <GeneralProjectInformation store={cmtFormStore} />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <ConstructionDataSummary store={cmtFormStore} />
        </Grid>
        {underwritingReview ? (
          <>
            <UwReasonDialog
              open={!!uwReasonModal}
              onClose={handleCloseUwReasonModal}
              onConfirm={handleConfirmUwReasonModal}
              reasonRequired={uwReasonModal === 'Rejected'}
              confirmBtnText={uwReasonModal === 'Rejected' ? 'Reject' : 'Approve'}
            />
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button testId="close" variant="outlined"
                  color="primary" onClick={onClose} className={classes.button}>Close</Button>
                {!cmtFormStore?.cmtReportDetails?.taskFrameworkEnabled &&
                  <>
                    <Button
                      testId="review" variant="outlined"
                      onClick={() => updateSowUnderwrittingStatus('Accepted')}
                      color="primary" className={classes.button}>
                        Approve
                    </Button>
                    <Button
                      testId="request" variant="outlined" color="primary"
                      onClick={() => updateSowUnderwrittingStatus('Rejected')}
                      className={classes.button}>
                        Reject
                    </Button>
                  </>
                }
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {!cmtFormStore?.cmtReportDetails?.taskFrameworkEnabled &&
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button testId="request" variant="outlined" color="primary" onClick={onRequestRevision} className={classes.button}>Request Revision</Button>

                  <Button testId="generate" variant="outlined" color="primary" onClick={onReviewCompleteWithComments}
                    className={classes.button}>Review Completed with Comments</Button>

                  <Button testId="review" variant="outlined" onClick={onReviewComplete}
                    color="primary" className={classes.button}>Review Approved</Button>
                </Box>
              </Grid>
            }
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button testId="cancel" variant="outlined"
                  color="primary" onClick={onClose} className={classes.button}>Cancel</Button>

                <Button testId="save" variant="contained"
                  color="primary" onClick={onSave} className={classes.button}>Save</Button>
              </Box>
            </Grid>
          </>
        )}

      </Grid>
    </>
  )
})

const SectionName = observer(({ title }) => {

  const classes = useStyles();

  return (
    <Paper className={classes.paper} square>
      <Grid item xs={12}>
        <Typography align="center" className={classes.sectionName}>
          {title}
        </Typography>
      </Grid>
    </Paper>
  )
})