import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Checkbox, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { BorrowerBaseballCardBaseStore } from 'libs/feature-borrowers/src/borrowers/stores/borrowerBaseballCardBaseStore';
import { observer } from 'mobx-react';
import {
  BuyerName,
  EntityName,
  ExitStrategy,
  PropertyType,
  LenderName,
  MortgageAmount,
  PropertyAddress,
  PropertyCity,
  PropertyState,
  PurchaseAmount,
  PurchaseDate,
  RehabBudgetAmount,
  SaleAmount,
  SaleDate,
  SellerName,
  StreetName,
  StreetNumber,
  UnitNumber,
  ZipCode,
  DealType,
  AddressLine2,
  YearBuilt,
} from './propertyFormFields';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { EDIT_PROPERTY, usdAmount } from './utils';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { DataGrid, GridColumnProps } from '../dataGrid';
import MatDataGrid from '../matDataGrid/matDataGrid';
import { ConfirmationButton, StandardDialog } from '@roc/ui';
import MergeProperties from './mergeProperties';
import { FieldLabel } from '@roc/ui';
import { AutocompleteAddressField } from '@roc/ui';
import { TextField as ApartmentField } from '@roc/ui';

export interface Props {
  property: any;
  open: boolean;
  setModalOpen: (val: boolean) => void;
  handleClose: () => void;
  titleHistory: any;
}

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      marginTop: 32,
    },
    actionButton: {
      marginRight: '1rem',
      height: '40px',
      fontSize: '12px',
      padding: '5px',
    },
  })
);


export type PropertyInformationFormProps = {
  open?: boolean;
  onClose?: () => void;
  loan?: any;
  store: BorrowerBaseballCardBaseStore;
  postClosingAction?: () => void;
  verifiedTrackRecordsId?: any;
  title: string;
  disabled?: boolean;
  isVerified?: boolean;
  verificationSource?: string;
  links?: any;
  recordSourceType?: string;
  doPropertyLookup?: boolean;
};

export const PropertyInformationForm = observer(
  (props: PropertyInformationFormProps) => {
    const {
      open,
      onClose,
      loan,
      store,
      postClosingAction,
      verifiedTrackRecordsId,
      title,
      disabled,
      isVerified,
      verificationSource,
      links,
      recordSourceType,
      doPropertyLookup,
    } = props;
    const classes = useStyles();

    const [trackRecordsId, setTrackRecordsId] = useState();

    useEffect(() => {
      if (verifiedTrackRecordsId) {
        setTrackRecordsId(verifiedTrackRecordsId);
        loadData();
      }
    }, [verifiedTrackRecordsId]);
    const loadData = async () => {
      let data = [];
      data = await store.trackRercords.filter(
        v => v.verifiedTrackRecordsId == verifiedTrackRecordsId
      );
      await store.propertyFormStore.fillForm(data[0]);
    };

    const handleClose = () => {
      store.closePropertyInformationDialog();
    };

    const handleSave = async () => {
      if (title === EDIT_PROPERTY) {
        await store.propertyFormStore.editPropertyInformation(
          store.propertyFormStore.generatePropertyInformationJSON(
            store.currentBorrower,
            trackRecordsId,
            isVerified,
            recordSourceType,
            verificationSource,
            links
          )
        );
      } else {
        await store.propertyFormStore.savePropertyInformation(
          store.propertyFormStore.generatePropertyInformationJSON(
            store.currentBorrower
          )
        );
      }
      store.propertiesMatGridStore.setCurrentSelectedIndex(null);
      await store.handleSaveProperty();
      store.closePropertyInformationDialog();
      store.propertyFormStore.resetForm();
      postClosingAction();
    };

    const handlePropertyChange = async () => {
      const formValues = store.propertyFormStore.getFormValues();
      const data = new FormData();
      let lookupRequest = {
        address: formValues.purchaseAddress,
        streetNumber: formValues.streetNumber,
        streetName: formValues.streetName,
        aptNumber: formValues.aptNumber,
        city: formValues.purchaseCity,
        state: formValues.purchaseState,
        zipCode: formValues.purchaseZipcode,
      };
      data.append('form_information', JSON.stringify(lookupRequest));
      const response = await store.propertyFormStore.lookupProperty(data) as any;

      store.lookUpPropertiesOptions = response?.data.rows;
    }
    const handleLookup = async () => {
      try {

        const addRequest = [];
        store.lookUpSelectedProperties.map(row => {
          addRequest.push(store.propertyFormStore.generateTitleLookupJSON(
            store.currentBorrower,
            row
          ))
        })
        await store.propertyFormStore.saveMultiplePropertyInformation(addRequest);
        await store.handleSaveProperty();
      } catch (error) {
        store.showErrorNotification('Error while looking up title history',);
      }
      store.closePropertyInformationDialog();
      store.propertyFormStore.resetForm();
    }
    const [mergeRecordsValues, setMergedRecordsValues] = useState([]);
    const [editedRecords, setEditedRecords] = useState([])
    const saveMergedRecords = async () => {
      try {
        const addRequest = [];
        editedRecords.map(row => {
          addRequest.push(store.propertyFormStore.generateTitleLookupForMergedRecordsJSON(
            store.currentBorrower,
            row
          ))
        })
        await store.propertyFormStore.saveMultiplePropertyInformation(addRequest);
        await store.handleSaveProperty();
        setOpenMergeModal(false);
        store.closePropertyInformationDialog();
        store.propertyFormStore.resetForm();
      } catch (error) {
        console.log(error)
        store.showErrorNotification('Error while saving merged records');
      }
    }

    const titleHistoryColumns = [
      'Action Date',
      'Purchase Date',
      'Sell Date',
      'Recording Date',
      'Property Type',
      'Seller',
      'Buyer',
      'Lender',
      'Loan Amount',
      'Seller Price',
      'Interest Rate'
    ];

    const titleHistoryRows = store.lookUpPropertiesOptions.reduce((rows, titleHistory) => {
      rows.push(
        {
          id: titleHistory.id,
          object: titleHistory,
          type: 'property',
          cells: [
            titleHistory.actionDate ? titleHistory.actionDate : '-',
            titleHistory.purchaseDate ? titleHistory.purchaseDate : '-',
            titleHistory.sellDate ? titleHistory.sellDate : '-',
            titleHistory.recordingDate ? titleHistory.recordingDate : '-',
            titleHistory.propertyType ? titleHistory.propertyType : '-',
            titleHistory.seller ? titleHistory.seller : '-',
            titleHistory.buyer ? titleHistory.buyer : '-',
            titleHistory.lender ? titleHistory.lender : '-',
            titleHistory.loanAmount ? usdAmount({ value: titleHistory.loanAmount })
              : '$0.00',
            titleHistory.sellPrice ? usdAmount({ value: titleHistory.sellPrice })
              : '$0.00',
            titleHistory.interestRate ? titleHistory.interestRate : '-',
          ],
          expandableContent: (
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '3rem', display: 'flex', justifyContent: 'space-between' }}>
                <Grid>

                  <Grid >
                    <Grid style={{ marginRight: '4rem', marginBottom: '4rem' }}>
                      <strong>Description: </strong>
                      <Grid style={{ display: 'flex', flexDirection: "column" }}>
                        {titleHistory.description
                        }
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          )
        }
      )
      return rows;
    }, [])

    const [openMergeModal, setOpenMergeModal] = useState(false);
    const generateMergedRecord = (properties) => {
      const mergedRecord = {
        id: properties[0].id,
        propertyId: properties[0].propertyId,
        actionDate: properties[0].actionDate,
        sellDate: properties[0].recordingDate,
        recordingDate: properties[0].recordingDate,
        description: properties[0].description,
        propertyType: properties[0].propertyType,
        seller: properties[0].seller,
        buyer: properties[0].buyer,
        lender: properties[0].lender,
        loanAmount: properties[0].loanAmount,
        interestRate: properties[0].interestRate,
        sellPrice: properties[0].sellPrice,
        purchasePrice: properties.length === 2 ? properties[1].sellPrice : properties[2].sellPrice,
        purchaseDate: properties.length === 2 ? properties[1].recordingDate : properties[2].recordingDate,
      };

      if (properties.length === 2 && (properties[1].sellPrice === null || properties[1].recordingDate === null)) {
        mergedRecord.purchasePrice = properties[0].sellPrice;
        mergedRecord.recordingDate = properties[0].recordingDate;
      }

      return mergedRecord;
    };

    const mergeRecords = () => {
      const selectedProperties = store.lookUpSelectedProperties;

      if (selectedProperties.length === 2 || selectedProperties.length === 3) {
        selectedProperties.sort((a, b) => {
          if (a.recordingDate === null && b.recordingDate === null) {
            return 0; // Both have null recordingDate, no change in order.
          } else if (a.recordingDate === null) {
            return 1; // a has null recordingDate, so b should come before it.
          } else if (b.recordingDate === null) {
            return -1; // b has null recordingDate, so a should come before it.
          } else {
            // Compare by recordingDate when both are not null.
            const dateA = new Date(a.recordingDate);
            const dateB = new Date(b.recordingDate);

            return dateB.getTime() - dateA.getTime();
          }
        });
        const mergedRecord = generateMergedRecord(selectedProperties);

        setMergedRecordsValues([mergedRecord]);
        setEditedRecords([mergedRecord]);
        setOpenMergeModal(true);
      }
    };


    const handleChange = (index, field, value) => {
      const updatedRecords = [...editedRecords];
      updatedRecords[index][field] = value;
      setEditedRecords(updatedRecords);
    };
    const handleCloseTest = () => {
      setMergedRecordsValues([]);
      setEditedRecords([]);
      setOpenMergeModal(false);
    }
    const defaultTooltip =
      'Please enter the address in the below format: \nStreetNumber Street Name, City, State Abbreviation, Zipcode\n\nFor e.g.\n12345 Happy Avenue, New York City, NY 10001';
    const selectActions = [
      <>
        <Button className={classes.actionButton}
          onClick={() => mergeRecords()}
          color='primary'
          variant='outlined'
          disabled={store.lookUpSelectedProperties.length > 3}>
          Merge Records
        </Button>
        <StandardDialog
          open={openMergeModal}
          title="Preview merge values"
          maxWidth="lg"
          handleClose={() => handleCloseTest()}
          dialogContent={
            <MergeProperties
              mergedRecordsValues={mergeRecordsValues}
              handleChange={handleChange} />
          }
          dialogActions={
            <><Button variant='outlined' color='primary' onClick={() => handleCloseTest()} style={{ marginRight: '5px' }}>
              Cancel
            </Button><Button variant='outlined' color='primary' onClick={() => saveMergedRecords()}>
                Merge
              </Button></>
          }
        />
      </>
    ]
    return (
      <>
        {!doPropertyLookup &&
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Provide the information for the <strong>verified property</strong>{' '}
                purchased by the borrower.
              </Typography>
            </Grid>
            {/* First column */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EntityName store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <PropertyAddress store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <AddressLine2 store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <PropertyCity store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <PropertyState store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <ZipCode store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <SaleDate store={store.propertyFormStore} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <SaleAmount store={store.propertyFormStore} />
                </Grid>
                <Grid item xs={12}>
                  <ExitStrategy store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <DealType store={store.propertyFormStore} disabled={disabled} />
                </Grid>
              </Grid>
            </Grid>

            {/* Second column */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BuyerName store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <MortgageAmount store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <LenderName store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <SellerName store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <PurchaseAmount store={store.propertyFormStore} />
                </Grid>
                <Grid item xs={12}>
                  <PurchaseDate store={store.propertyFormStore} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <RehabBudgetAmount store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '3px' }}>
                  <YearBuilt store={store.propertyFormStore} disabled={disabled} />
                </Grid>
                <Grid item xs={12}>
                  <PropertyType store={store.propertyFormStore} disabled={disabled} />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        }
        {doPropertyLookup &&
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Do an address lookup for the property to add title history to properties table.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <AutocompleteAddressField
                  value={store.propertyFormStore.form.fields.purchaseAddress.value}
                  name="purchaseAddress"
                  label="Enter property address"
                  onChange={event => {
                    store.propertyFormStore.handleAddressChange(event);
                  }}
                  fullWidth
                  required
                  testId="purchaseAddress"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ApartmentField
                  label={"Enter an Apt./Unit #"}
                  value={store.propertyFormStore.form.fields.aptNumber.value}
                  onChange={(e) => store.propertyFormStore.handleChangeAptNumber(e.target.value)}
                  fullWidth={true}
                  standaloneLabel={true}
                  testId={"aptField"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PropertyCity store={store.propertyFormStore} disabled={disabled} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PropertyState store={store.propertyFormStore} disabled={disabled} />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: '3px' }}>
                <ZipCode store={store.propertyFormStore} disabled={disabled} />
              </Grid>
            </Grid>
            <Grid item xs={12} spacing={2} style={{ marginTop: '20px' }}>
              <Grid style={{ marginBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handlePropertyChange}>
                  Get Title History
                </Button>
              </Grid>

              <MatDataGrid
                store={store.titleHistoryMatGridStore}
                isExpandable={true}
                enableSelection={true}
                onSelectedRowsChange={(rows) => { store.lookUpSelectedProperties = rows.map((row) => row.object) }}
                headers={titleHistoryColumns}
                rows={store.lookUpPropertiesOptions ? titleHistoryRows : []}
                selectActions={selectActions}
              />

            </Grid>
          </Grid>

        }
        <Grid item xs={12} className={classes.buttonContainer}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={6} md={2}>
              <Button
                onClick={handleClose}
                color="primary"
                size="large"
                variant="outlined"
                fullWidth
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              {!doPropertyLookup &&
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleSave}
                  disabled={!store.propertyFormStore.form.meta.isValid}
                >
                  SAVE
                </Button>
              }
              {doPropertyLookup &&
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleLookup}
                  disabled={store.lookUpSelectedProperties.length === 0}
                >
                  ADD
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

);

export default PropertyInformationForm;
