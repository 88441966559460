import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
  statementText: {
    fontSize: "10px",
    color: "#656565",
    textTransform: "uppercase",
    whiteSpace: "pre-line",
    letterSpacing: 1,
    padding: "15px",
  },
}));
export const StatementOfLimitingConditions = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.statementText}>
      Statement of Limiting Conditions{"\n"}
      {"\n"}
      <u>Intended Use:</u>{"\n"}
      The intended use of this report is for the client to make a sound
      real estate investment decision in the property that is the subject of the
      analysis in this report.{"\n"}
      {"\n"}
      <u>Intended User:</u>{"\n"}
      The intended user of this report is the ROC client. There shall be no other intended users.{"\n"}
      {"\n"}
      <u>Type and Definition of Market Value:</u>{"\n"}
      Market value means the most probable price which a property should
      bring in a competitive and open market under all conditions requisite to a
      fair sale, the buyer and seller each acting prudently and knowledgeably,
      and assuming the price is not affected by undue stimulus. Implicit in this
      definition is the consummation of a sale as of a specified date and the
      passing of title from seller to buyer under conditions whereby:{"\n"}
      {"\n"}
      1. Buyer and seller are typically motivated;{"\n"}
      2. Both parties
      are well informed or well advised, and acting in what they consider their
      own best interests;{"\n"}
      3. A reasonable time is allowed for exposure in
      the open market;{"\n"}
      4. Payment is made in terms of cash in U.S. dollars or in terms of
      financial arrangements comparable thereto; and{"\n"}
      5. The price
      represents the normal consideration for the property sold unaffected by
      special or creative financing or sales concessions granted by anyone
      associated with the sale.
    </Typography>
  );
};