import { Service } from '@roc/feature-app-core';
import { CancelTokenSource } from 'axios';

const url = {
  REQUEST_RESOLUTION: '/nportal/rest/priv/insurance/v2/requestResolution',
  SAVE_INSURANCE_INFORMATION_REVIEW: '/nportal/rest/priv/insurance/v2/saveInsuranceQuoteData',
  GET_INSURANCE_QUOTES: '/nportal/rest/priv/insurance/v2/getQuotesByLoanId',
  GET_INSURANCE_QUOTE_DATA_BY_ID: '/nportal/rest/priv/insurance/v2/getInsuranceQuoteDataByQuoteId',
  CREATE_RESOLUTIONS: '/nportal/rest/priv/insurance/v2/createResolutions',
  CHANGE_QUOTE_STATUS: '/nportal/rest/priv/insurance/v2/changeQuoteStatus',
  SAVE_LOAN_PROPERTY: '/nportal/rest/priv/property/saveLoanProperty',
  SAVE_FLOOD_CERT: '/nportal/rest/priv/insurance/v2/saveFloodCert',
  DOWNLOAD_FLOODCERT: '/nportal/rest/priv/insurance/v2/downloadFloodCert'
};

export class InsuranceInformationReviewInternalService extends Service {
  getInsuranceReviewData(quoteId: string) {
    return this.get(`${url.GET_INSURANCE_QUOTE_DATA_BY_ID}?quoteId=${quoteId}`);
  }

  getInsuranceQuotes(loanId) {
    return this.get(`${url.GET_INSURANCE_QUOTES}?loanId=${loanId}`);
  }

  requestResolution(id: number, data: any[]) {
    return this.post(`${url.REQUEST_RESOLUTION}?insuranceQuoteDataId=${id}`, data);
  }

  saveData(id: number, data: any) {
    return this.post(`${url.SAVE_INSURANCE_INFORMATION_REVIEW}?insuranceQuoteDataId=${id}`, data);
  }

  changeQuoteStatus(quoteId: number, status: string) {
    return this.get(`${url.CHANGE_QUOTE_STATUS}?insuranceDetailQuoteId=${quoteId}&status=${status}`);
  }

  createResolutions(data: any) {
    return this.post(url.CREATE_RESOLUTIONS, data);
  }
  saveLoanProperty(loanProperty: FormData) {
    return this.post(url.SAVE_LOAN_PROPERTY, loanProperty);
  }

  saveFloodCert(data: FormData) {
    return this.post(url.SAVE_FLOOD_CERT, data);
  }

  downloadFloodCert(propertyId: number) {
    return this.get(url.DOWNLOAD_FLOODCERT, { propertyId }, {
      responseType: 'arraybuffer',
    });
  }

}