import { Service } from '@roc/feature-app-core';

const url = {
  GET_TAX_CERTIFICATE_DASHBOARD: '/api/v1/loan/loanPropertyTax/getTaxDashboard',
  CREATE_TAX_CERTIFICATE: '/api/v1/vendor/lereta/order/createOrder',

};

export class TaxService extends Service {

  getTaxCertificateDashboard(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_TAX_CERTIFICATE_DASHBOARD,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  createTaxCertificate(loanId) {
    return this.post(
      `${url.CREATE_TAX_CERTIFICATE}?loanId=${loanId}`, null,
    );
  }

}
