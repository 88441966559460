import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { GeneralTasksComponent } from './components/generalTasksComponent';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { GeneralTasksTab } from './types/generalTasksTypes';
import { LoansTasksComponent } from './components/loansTasksComponent';

const TABS = [
  {
    value: GeneralTasksTab.MY_TASKS,
    label: 'My Tasks',
  },
  {
    value: GeneralTasksTab.ALL_TASKS,
    label: 'All Tasks',
  },
  {
    value: GeneralTasksTab.ALL_LOANS_TASKS,
    label: 'All Loans Tasks',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
}));

export const GeneralTasks = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { tab } = generalTasksStore;
  const classes = useStyles();

  useEffect(() => {
    generalTasksStore.resetAndFetchOptions();
  }, []);

  return (
    <Layout title="Tasks" containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(TABS)}
          onTabChange={(e, i) => generalTasksStore.setTab(TABS[i].value)}
        />
      </Box>
      {tab == GeneralTasksTab.ALL_LOANS_TASKS ?
        <LoansTasksComponent />
        :
        <GeneralTasksComponent />
      }
    </Layout>
  );
});
