import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableContainer, Paper } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    "& tr:last-child th": {
      fontWeight: "bold",
    },
    "& tr:last-child td": {
      fontWeight: "bold",
      background: "#dffdff",
    },
    maxWidth: "100%",
    overflowX: "auto"
  },
});

const PaidOffInterestAllocationTable = ({ loanData, protectiveAdvances }) => {
  const classes = useStyles();
  const columnKeys = loanData?.length ? Object.keys(loanData[0]) : [];
  return (
    <TableContainer component={Paper} style={{ width: columnKeys.length === 2 ? "50%" : "100%" }}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {columnKeys.map((key, index) => (
              <TableCell align={index === 0 ? "left" : "right"} style={{ minWidth: '160px', width: '160px' }} key={index}>
                {key === "name" ? "Description" : key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loanData?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columnKeys.map((key, colIndex) => (
                <TableCell align={colIndex === 0 ? "left" : "right"} key={colIndex}>{row[key] ?? "-"}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaidOffInterestAllocationTable;
