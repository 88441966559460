/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  createStyles,
} from "@material-ui/core";
import { formatCurrency } from "@roc/ui/utils";
import { observer } from "mobx-react";

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: "0.9rem",
      padding: 10,
      border: "1px solid black",
    },
    head: {
      backgroundColor: "#f0f0f0",
      fontWeight: "bold",
      border: "1px solid black",
      textAlign: "center",
      fontSize: "0.7rem",
      padding: 10,
      letterSpacing: "1px"
    },
  })
)(TableCell);

interface TitleHistoryTableProps {
  propertyActivities: any[]
}

export const TitleHistoryTable = observer(({ propertyActivities }: TitleHistoryTableProps) => {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>DATE</StyledTableCell>
            <StyledTableCell>DESCRIPTION</StyledTableCell>
            <StyledTableCell>SELLER</StyledTableCell>
            <StyledTableCell>BUYER</StyledTableCell>
            <StyledTableCell>LENDER</StyledTableCell>
            <StyledTableCell>PRICE</StyledTableCell>
            <StyledTableCell>LOAN AMOUNT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(!propertyActivities || propertyActivities.length === 0) ? (
            <TableRow>
              <StyledTableCell colSpan={7} align="center">
                No records available
              </StyledTableCell>
            </TableRow>
          ) : (propertyActivities.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell>{
                row.actionDate ? `${row.actionDate[1].toString().padStart(2, '0')}/${row.actionDate[2].toString().padStart(2, '0')}/${row.actionDate[0]}`
                  : 'No Date'
              }</StyledTableCell>
              <StyledTableCell align="center">{row.description}</StyledTableCell>
              <StyledTableCell align="center">{row.seller}</StyledTableCell>
              <StyledTableCell align="center">{row.buyer}</StyledTableCell>
              <StyledTableCell align="center">{row.lender}</StyledTableCell>
              <StyledTableCell align="right">{formatCurrency(row.sellPrice, 0)}</StyledTableCell>
              <StyledTableCell align="right">{formatCurrency(row.loanAmount, 0)}</StyledTableCell>
            </TableRow>)))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
});
