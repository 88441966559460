import { Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  DocumentName,
  DocumentStatus,
  LoanAction,
  LoanActionForms,
  useLoanActionsStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { CLOSING, formatDate } from '@roc/feature-utils';
import { FabActionsButton, FabActionsMenuItem } from '@roc/ui';

import { observer } from 'mobx-react';

export const ClosingReviewerLoanActions = observer(props => {
  const { loanActionsStore } = useLoanActionsStore();
  const { loanStore } = useLoanStore();

  const setAction = action => {
    loanActionsStore.setCurrentAction(action);
  };

  const document = loanActionsStore.findDocumentByName(CLOSING, DocumentName.ADDITIONAL_CLOSING_REVIEW);
  const showAdditionalClosingReviewApprovalDate = !!document?.lastAcceptedDate && document?.status === DocumentStatus.ACCEPTED;

  if (loanStore.loanDetails?.toDosFrameworkEnabled && !(props.version === 'v2' || props.version === 'v3')) {
    return null;
  }

  return (
    <>
      {loanActionsStore?.showActions && (
        <FabActionsButton version={props.version}>
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Approve Additional Closing Review'}
            onClick={() => setAction(LoanAction.APPROVE_ADDITIONAL_CLOSING)}
            rightColumnValue={showAdditionalClosingReviewApprovalDate ?
              `Approved ${formatDate(document.lastAcceptedDate, 'MMMM d, yyyy')}` : ''}

          />
        </FabActionsButton>
      )}
      <LoanActionForms />
    </>
  );
});
