import { observer } from 'mobx-react';
import {
  FinalDocumentType,
  InsuranceInformationBaseStore,
} from '@roc/feature-insurance-review';
import { Card, Paper, PaperBorderType } from '@roc/ui';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& th': {
      borderBottom: '1px solid #eee',
    },
  },
  headerRow: {
    '& th': {
      verticalAlign: 'text-top',
      padding: '8px',
    },
    '& th:first-child': {
      paddingLeft: '16px',
    },
  },
  action: {
    cursor: 'pointer',
  },
}));

export type InsuranceInformationFinalDocumentsSummaryProps = {
  store: InsuranceInformationBaseStore;
};

export const InsuranceInformationFinalDocumentsSummary = observer(
  ({ store }: InsuranceInformationFinalDocumentsSummaryProps) => {
    const classes = useStyles({});

    return (
      <Paper borderType={PaperBorderType.SOLID_BORDER}>
        <Card
          title={'Final Documents'}
          cardContentProps={{
            style: {
              overflow: 'auto',
              padding: '0',
              borderTop: '1px solid #E0E0E0FF',
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>Document Name</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {store.finalBinder && (
                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <Typography>Final Binder.pdf</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <GetApp
                        className={classes.action}
                        onClick={() =>
                          store.downloadFinalDocument(FinalDocumentType.BINDER)
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {store.finalInvoice && (
                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <Typography>Final Invoice.pdf</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <GetApp
                        className={classes.action}
                        onClick={() =>
                          store.downloadFinalDocument(FinalDocumentType.INVOICE)
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {store.finalFloodCertificate && (
                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <Typography>Flood Certificate.pdf</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <GetApp
                        className={classes.action}
                        onClick={() =>
                          store.downloadFinalDocument(FinalDocumentType.FLOOD)
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </Paper>
    );
  }
);
