import {
  Button,
  createSelectField,
  FileUpload,
  FileUploadModal,
} from '@roc/ui';
import { useDocumentStore } from '@roc/feature-documents';
import { Document } from '@roc/feature-types';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert } from '@material-ui/lab';
import { UNDERWRITING } from '@roc/feature-utils';

type Props = {
  document: Document;
};

const documentTypeOptions = [
  { label: 'Please Select', value: '' },
  { label: 'Permits',value: 'Permits'},
  { label: 'Architect letter', value: 'Architect letter' },
];

export const PermitForm = observer((props: Props) => {
  const { documentStore } = useDocumentStore();
  const [isUploadOpen, setUploadOpen] = useState(false);
  const { documentFormStore } = documentStore;
  const { permitFormStore } = documentFormStore;
  const store = permitFormStore;
  const document = props.document;

  const canDownload = !!document.dropboxPath;

  const message = !canDownload
    ? `${document.taskName} has not been uploaded`
    : `${document.taskName} was last uploaded at ${document.lastUpdateDate}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createSelectField({
          store,
          fieldName: 'documentType',
          testId: 'documentType',
          label: 'Document Type',
          options: documentTypeOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">{message}</Alert>
      </Grid>
      <Grid item xs={12}>
        <Button
          style={{ marginRight: '8px' }}
          onClick={() => setUploadOpen(true)}
          color="primary"
          variant="outlined"
          testId="upload"
        >
          Upload
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disabled={!canDownload}
          onClick={() => documentStore.downloadDocument(document)}
          testId="download"
        >
          Download
        </Button>
      </Grid>
      <FileUploadModal
        title={`Upload: ${document.taskName}`}
        open={isUploadOpen}
        onClose={() => setUploadOpen(false)}
        onUpload={(uploads: FileUpload[]) =>
          documentStore.uploadDocument(uploads[0].file, document, UNDERWRITING)
        }
      />
    </Grid>
  );
});
