import {
  GeneralTasksFilters,
  GeneralTaskType,
  Task,
  TaskPriority,
  TaskScope,
  TaskStatus,
} from '../types/generalTasksTypes';

export const generalTaskStatusOptions = [
  { label: 'Open', value: TaskStatus.OPEN },
  { label: 'More Info Needed', value: TaskStatus.MORE_INFO_NEEDED },
  { label: 'In Review', value: TaskStatus.IN_REVIEW },
  { label: 'Completed', value: TaskStatus.COMPLETED },
  { label: 'Invalid', value: TaskStatus.INVALID },
  { label: 'Paused', value: TaskStatus.PAUSED },
  { label: 'Not Applicable', value: TaskStatus.NOT_APPLICABLE },
];

export const generalTaskPriorityOptions = [
  { label: 'Low', value: TaskPriority.LOW },
  { label: 'Medium', value: TaskPriority.MEDIUM },
  { label: 'High', value: TaskPriority.HIGH },
  { label: 'Critical', value: TaskPriority.CRITICAL },
  { label: 'Very Critical', value: TaskPriority.VERY_CRITICAL },
];

export const moveToRocCategories = [
  "APPRAISAL_REPORT_REVIEW",
  "CMT_REVIEW",
  "APPRAISAL_DOCUMENT_COLLECTION",
  "APPRAISAL_TAMARISK_REVIEW"
];

export const hasOpenSubtasksOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const taskTypeOptions = [
  { label: 'Credit Review', value: GeneralTaskType.CREDIT_REVIEW },
  { label: 'Background Review', value: GeneralTaskType.BACKGROUND_REVIEW },
  { label: 'Track Record Review', value: GeneralTaskType.TRACK_RECORD_REVIEW },
  { label: 'Appraisal Report & Related', value: GeneralTaskType.APPRAISAL_REPORT_AND_RELATED },
  { label: 'Appraisal Risk Review', value: GeneralTaskType.APPRAISAL_RISK_REVIEW },
  { label: 'Collateral Docs', value: GeneralTaskType.COLLATERAL_DOCS },
  { label: 'SOW Review', value: GeneralTaskType.SOW_REVIEW },
  { label: 'Entity Section', value: GeneralTaskType.ENTITY_SECTION },
  { label: 'Feedback', value: GeneralTaskType.FEEDBACK },
  { label: 'Tech Support', value: GeneralTaskType.TECH_SUPPORT },
  { label: 'External Tech Support', value: GeneralTaskType.EXTERNAL_TECH_SUPPORT },
  // Manual Tasks
  { label: 'Closing Assistance', value: GeneralTaskType.CLOSING_ASSISTANCE },
  { label: 'Payoff Request', value: GeneralTaskType.PAYOFF_REQUEST },
  { label: 'Draw Request', value: GeneralTaskType.DRAW_REQUEST },
  { label: 'Loan Extensions', value: GeneralTaskType.LOAN_EXTENSIONS },
  { label: 'Others / Adhoc', value: GeneralTaskType.OTHERS },
  { label: 'Closing Conditions', value: GeneralTaskType.CLOSING_CONDITIONS },
  { label: 'Loan Clarifications', value: GeneralTaskType.LOAN_CLARIFICATIONS },
  { label: 'Additional Requirement', value: GeneralTaskType.ADDITIONAL_REQUIREMENT },
  { label: 'Insurance', value: GeneralTaskType.INSURANCE },
  { label: 'Appraisal Report Review', value: GeneralTaskType.APPRAISAL_REPORT_REVIEW },
  { label: 'CMT Review', value: GeneralTaskType.CMT_REVIEW },
  { label: 'Appraisal Document Collection', value: GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION },
  // Legacy Tasks
  { label: 'Appraisal Review - Legacy', value: GeneralTaskType.APPRAISAL_REVIEW, legacy: true },
  { label: 'Subject Property - Legacy', value: GeneralTaskType.SUBJECT_PROPERTY, legacy: true },
  { label: 'Track Record - Legacy', value: GeneralTaskType.TRACK_RECORD, legacy: true },
  // SOS Escalation
  { label: 'Escalation', value: GeneralTaskType.ESCALATION },
  //Servicing
  { label: 'Servicing', value: GeneralTaskType.SERVICING },
  //Valuation
  { label: 'Valuation', value: GeneralTaskType.VALUATION },
  //Underwriting
  { label: 'Underwriting', value: GeneralTaskType.UNDERWRITING },
  

  { label: 'Prescreen', value: GeneralTaskType.PRESCREEN },
  { label: 'Title', value: GeneralTaskType.TITLE },
  { label: 'Borrower Closing Agent', value: GeneralTaskType.BORROWER_CLOSING_AGENT },
  { label: 'Loan Terms', value: GeneralTaskType.LOAN_TERMS },
  { label: 'Corporate Documents', value: GeneralTaskType.CORPORATE_DOCUMENTS },
  { label: 'Closing Documents', value: GeneralTaskType.CLOSING_DOCUMENTS },
  { label: 'Loan Related Documents', value: GeneralTaskType.LOAN_RELATED_DOCUMENTS },
  { label: 'Signed Closing Documents', value: GeneralTaskType.SIGNED_CLOSING_DOCUMENTS },
  { label: 'Committee', value: GeneralTaskType.COMMITTEE },
  { label: 'Appraisal Order', value: GeneralTaskType.APPRAISAL_ORDER },
  { label: 'Generated Closing Documents', value: GeneralTaskType.GENERATED_CLOSING_DOCUMENTS },
  { label: 'Second Appraisal Order', value: GeneralTaskType.SECOND_APPRAISAL_ORDER },
  { label: 'Boarding', value: GeneralTaskType.BOARDING },
  { label: 'Scanned Closing Docs', value: GeneralTaskType.SCANNED_CLOSING_DOCS },
  { label: 'Physical Original Recorded Closing Docs', value: GeneralTaskType.PHYSICAL_ORIGINAL_RECORDED_CLOSING_DOCS },
  { label: 'Draw Information', value: GeneralTaskType.DRAW_INFORMATION },
  { label: 'Appraisal and SOW', value: GeneralTaskType.APPRAISAL_AND_SOW },
  { label: 'Inspection', value: GeneralTaskType.INSPECTION },
  { label: 'Wire', value: GeneralTaskType.WIRE },
  { label: 'Pending Insurance', value: GeneralTaskType.PENDING_INSURANCE },
  { label: 'Internal', value: GeneralTaskType.INTERNAL },
  { label: 'Post Closing', value: GeneralTaskType.POST_CLOSING },
  { label: 'E-File Only Recorded Closing Docs', value: GeneralTaskType.E_FILE_ONLY_RECORDED_CLOSING_DOCS },
  { label: 'Maturity', value: GeneralTaskType.MATURITY },
  { label: 'Delinquency Management', value: GeneralTaskType.DELINQUENCY_MANAGEMENT },
  { label: 'Internal Payoff Request', value: GeneralTaskType.INTERNAL_PAYOFF_REQUEST },
  { label: 'Payoff Details', value: GeneralTaskType.PAYOFF_DETAILS },
  { label: 'Extension Documents', value: GeneralTaskType.EXTENSION_DOCUMENTS },
  { label: 'Protective Advances', value: GeneralTaskType.PROTECTIVE_ADVANCES },
  { label: 'Internal Documents', value: GeneralTaskType.INTERNAL_DOCUMENTS },
  { label: 'Generated CEMA Documents', value: GeneralTaskType.GENERATED_CEMA_DOCUMENTS },
  { label: 'Borrower Review', value: GeneralTaskType.BORROWER_REVIEW },
  { label: 'Trailing Documents', value: GeneralTaskType.TRAILING_DOCUMENTS },
  { label: 'Foreclosure Milestones', value: GeneralTaskType.FORECLOSURE_MILESTONES },
  { label: 'Subject Property', value: GeneralTaskType.SUBJECT_PROPERTY },
];

export const reportedByMeOptions = [
  { label: 'Reported by Me', value: TaskScope.REPORTED_BY_ME },
  { label: 'Assigned to Me', value: TaskScope.ASSIGNED_TO_ME },
  { label: 'Watched by Me', value: TaskScope.WATCHED_BY_ME }
];

export const appraisalTaskTypes = [
  GeneralTaskType.APPRAISAL_REPORT_REVIEW,
  GeneralTaskType.CMT_REVIEW,
  GeneralTaskType.APPRAISAL_DOCUMENT_COLLECTION
];

export const lineItemsTaskTypes = [
  GeneralTaskType.PRESCREEN,
  GeneralTaskType.TITLE,
  GeneralTaskType.BORROWER_CLOSING_AGENT,
  GeneralTaskType.LOAN_TERMS,
  GeneralTaskType.CORPORATE_DOCUMENTS,
  GeneralTaskType.CLOSING_DOCUMENTS,
  GeneralTaskType.LOAN_RELATED_DOCUMENTS,
  GeneralTaskType.SIGNED_CLOSING_DOCUMENTS,
  GeneralTaskType.COMMITTEE,
  GeneralTaskType.APPRAISAL_ORDER,
  GeneralTaskType.GENERATED_CLOSING_DOCUMENTS,
  GeneralTaskType.SECOND_APPRAISAL_ORDER,
  GeneralTaskType.BOARDING,
  GeneralTaskType.SCANNED_CLOSING_DOCS,
  GeneralTaskType.PHYSICAL_ORIGINAL_RECORDED_CLOSING_DOCS,
  GeneralTaskType.DRAW_INFORMATION,
  GeneralTaskType.APPRAISAL_AND_SOW,
  GeneralTaskType.INSPECTION,
  GeneralTaskType.WIRE,
  GeneralTaskType.PENDING_INSURANCE,
  GeneralTaskType.INTERNAL,
  GeneralTaskType.POST_CLOSING,
  GeneralTaskType.E_FILE_ONLY_RECORDED_CLOSING_DOCS,
  GeneralTaskType.MATURITY,
  GeneralTaskType.DELINQUENCY_MANAGEMENT,
  GeneralTaskType.INTERNAL_PAYOFF_REQUEST,
  GeneralTaskType.PAYOFF_DETAILS,
  GeneralTaskType.EXTENSION_DOCUMENTS,
  GeneralTaskType.PROTECTIVE_ADVANCES,
  GeneralTaskType.INTERNAL_DOCUMENTS,
  GeneralTaskType.GENERATED_CEMA_DOCUMENTS,
  GeneralTaskType.BORROWER_REVIEW,
  GeneralTaskType.TRAILING_DOCUMENTS,
  GeneralTaskType.FORECLOSURE_MILESTONES,
  GeneralTaskType.ENTITY_SECTION,
  GeneralTaskType.SUBJECT_PROPERTY
];

export const TECH_SUPPORT_ID = 10070;