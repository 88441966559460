import { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  useLoanStore,
} from '@roc/feature-loans';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { LoansGrid } from './components/loansGrid';
import { useServicerLoansStore } from '../hooks/useServicerLoansStore';

const TABS = [
  {
    label: 'NEW REQUEST',
  },
  {
    label: 'ROC',
  },
  {
    label: 'REVISIONS',
  },
  {
    label: 'APPROVED',
  },
  {
    label: 'RETRACTED/EXPIRED',
  },
  {
    label: 'FORECLOSURE'
  }
];

const renderTabContent = (globalStore, loanStore, servicerLoansDashboardStore) => {
  switch (loanStore.selectedTabIndex) {
    case 0:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.myNewRequestLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    case 1:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.rocLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    case 2:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.myRevisionLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    case 3:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.myApprovedLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    case 4:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.myRetractedExpiredLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    case 5:
      return globalStore.isListView ? (
        <LoansGrid gridStore={servicerLoansDashboardStore.myForeclosureLoansGridStore} store={servicerLoansDashboardStore} tabs={TABS} />
      ) : null;
    default:
      console.error('Invalid tab index');
      break;
  }
};

export const LoansHome = observer(() => {
  const { globalStore } = useBaseStore();
  const { loanStore } = useLoanStore();
  const {
    servicerLoansDashboardStore,
  } = useServicerLoansStore();
  const { selectedTabIndex } = loanStore;
  useEffect(() => {
    const isComingBackFromLoanDetails = globalStore.lastHistoryAction === 'POP';
    if (!isComingBackFromLoanDetails) {
      loanStore.setSelectedTabIndex(0);
    }
  }, []);

  return (
    <Layout>
      {renderTabContent(globalStore, loanStore, servicerLoansDashboardStore)}
    </Layout>
  );
});
