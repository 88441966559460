import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  AnnualHOAProvidedAtOriginationValue,
  AnnualInsuranceProvidedAtOriginationValue,
  AnnualTaxesProvidedAtOriginationValue,
  ExitStrategy,
  HasThePropertySufferedFireDamage,
  IsThePropertyBeingConvertedToCondominiums,
  IsThereANewAddressForThisProperty,
  IsThereSquareFootageBeingAdded,
  MonthlyMarketRentProvidedAtOriginationValue,
  NewAddressForThisProperty,
} from '../../propertyInformation/components/propertyInformationFields';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';
import { TitleDivider } from '../../../components/titleDivider';
import { PropertyDetailsTable } from '../../propertyInformation/components/propertyDetailsTable';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { HomeWorkOutlined } from '@material-ui/icons';
import { ItemDisplay } from '../../../components/itemDisplay';
import { getCompletePropertyAddress } from '@roc/ui/utils';
import { FORM_MAX_WIDTH } from '../../../utils/constants';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { isStabilizedBridge, LoanSubType, REFINANCE } from '@roc/feature-utils';
import { ProjectDetailsTable } from '../../propertyInformation/components/projectDetailsTable';

export const PreflightPropertyForm = observer(
  ({
    store,
    propertyFormStore,
  }: {
    store: QuoteTypeStore;
    propertyFormStore: PropertyFormStore;
  }) => {
    let completeAddress = getCompletePropertyAddress(
      propertyFormStore.getFormValues()
    );
    if (
      completeAddress === '' ||
      completeAddress.replace(/,/g, '').trim() === ''
    ) {
      completeAddress = '-';
    }

    const isGroundUp = store.loanSubtype === LoanSubType.GROUND_UP;
    const isFixAndFlip = store.loanSubtype === LoanSubType.FIX_AND_FLIP;
    const isStabilizedBridge = store.loanSubtype === LoanSubType.STABILIZED_BRIDGE;
    const isRefinance = propertyFormStore.form.fields.exitStrategy.value === REFINANCE;

    const hasNewAddress = propertyFormStore.form.fields.hasNewAddress.value;
    return (
      <Box maxWidth={FORM_MAX_WIDTH} marginLeft="auto" marginRight="auto">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                item
                container
                spacing={2}
                xs={8}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item alignItems="center" style={{ height: '100%' }}>
                  <RoundedBackgroundIcon
                    Icon={HomeWorkOutlined}
                    iconColor="#6D6D6D"
                  />
                </Grid>
                <Grid item>
                  <ItemDisplay
                    label="Property Address"
                    value={completeAddress}
                    alignItems="flex-start"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TitleDivider title="Property Details" />
          </Grid>
          <Grid item xs={12}>
            <PropertyDetailsTable store={propertyFormStore} />
          </Grid>
          <Grid item xs={12}>
            <TitleDivider title="Project Details" />
          </Grid>
          <Grid item xs={12}>
            <ProjectDetailsTable store={propertyFormStore} />
          </Grid>
          {/* {!isStabilizedBridge && <Grid item xs={12} sm={6}>
            <IsThePropertyBeingConvertedToCondominiums
              store={propertyFormStore}
            />
          </Grid>}
          {!isStabilizedBridge && <Grid item xs={12} sm={6}>
            <HasThePropertySufferedFireDamage store={propertyFormStore} />
          </Grid>} */}
          {isGroundUp && (
            <Grid item xs={12} sm={6}>
              <IsThereANewAddressForThisProperty store={propertyFormStore} />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ExitStrategy store={propertyFormStore} />
          </Grid>
          {((isGroundUp || isFixAndFlip) && isRefinance) && (
            <>
              <Grid item xs={12} sm={6}>
                <MonthlyMarketRentProvidedAtOriginationValue store={propertyFormStore} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AnnualTaxesProvidedAtOriginationValue store={propertyFormStore} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AnnualInsuranceProvidedAtOriginationValue store={propertyFormStore} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AnnualHOAProvidedAtOriginationValue store={propertyFormStore} />
              </Grid>
            </>
          )}
          {isGroundUp && !!hasNewAddress && (
            <Grid item xs={12}>
              <NewAddressForThisProperty store={propertyFormStore} />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
);
