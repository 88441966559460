import { UserFeatures } from "@roc/feature-app-core";
import { ChatRoomType } from "@roc/feature-communication";


export const getDefaultAllowedChatroom = (userFeatures: UserFeatures) => {
  if (userFeatures.isBorrower) {
    return defaultBorrowerAllowedChatRoomTypes
  } else if (userFeatures.isClosingAttorney) {
    return defaultAttorneyAllowedChatRoomTypes
  }
  else {
    return defaultAllowedChatRoomTypes;
  }
}


export const defaultAllowedChatRoomTypes = [
  ChatRoomType.UNDERWRITING,
  ChatRoomType.CLOSING,
  ChatRoomType.INSURANCE,
  ChatRoomType.EXTENSIONS,
  ChatRoomType.PAYOFF,
  ChatRoomType.DRAWS,
  ChatRoomType.DEAL_ROOM,
  ChatRoomType.TAMARISK_APPRAISAL,
  ChatRoomType.TECH_SUPPORT,
  ChatRoomType.INITIAL_PAYMENTS,
  ChatRoomType.SALES_SUPPORT,
  ChatRoomType.ADHOC,
  ChatRoomType.NEW_LOAN_REQUEST,
  ChatRoomType.LOAN_SYSTEM_NOTIFICATIONS
];

export const defaultBorrowerAllowedChatRoomTypes = [
  ChatRoomType.EXTENSIONS,
  ChatRoomType.PAYOFF,
  ChatRoomType.DRAWS,
  ChatRoomType.INITIAL_PAYMENTS,
  ChatRoomType.TECH_SUPPORT,
  ChatRoomType.ADHOC,
  ChatRoomType.NEW_LOAN_REQUEST
];

export const defaultAttorneyAllowedChatRoomTypes = [
  ChatRoomType.CLOSING,
  ChatRoomType.TECH_SUPPORT,
  ChatRoomType.CLOSING_SUPPORT,
  ChatRoomType.ADHOC,
  ChatRoomType.FORECLOSURE
];