import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails, Typography, Card, Tabs, Tab, Box, TableBody, Table, TableHead, TableCell, TableRow } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaidOffInterestAllocationTable from "./paidOffInterestAllocationTable";
import { DateField } from "@roc/ui";
import { observer } from "mobx-react";
import { InterestAllocationInternalStore } from "apps/roc-internal-portal/src/app/modules/InterestAllocation/stores/interestAllocationInternalStore";
import { useParams } from "react-router";
import { formatCurrency } from "@roc/ui/utils";
import { format } from "date-fns";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
  },
  cardStyle: {
    minHeight: "80%",
  },
  typographyStyle: {
    color: "#777"
  },
  typographyMargin: {
    marginLeft: "10px"
  },
  details: {
    padding: 0,
    paddingTop: theme.spacing(2),
    display: 'block',
  },
}));

const Accordion = withStyles({
  root: { border: "1px solid rgba(0, 0, 0, .125)", boxShadow: "none" },
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: { backgroundColor: "rgba(0, 0, 0, .03)", borderBottom: "1px solid rgba(0, 0, 0, .125)" },
})(MuiAccordionSummary);
const AccordionDetails = withStyles({ root: { padding: "8px 16px" } })(MuiAccordionDetails);

interface LoanData {
  title: string;
  servicerId: string;
  payString: string;
  investor: string;
  tpo: string;
  data: { [key: string]: string }[];
  protectiveAdvances?: { description: string; amount: string }[];
}

export type Props = {
  store: InterestAllocationInternalStore;
};

export const PaidOffInterestAllocationAccordion = observer(({ store }: Props) => {
  const { date } = useParams<{ date?: string }>();
  const initialPayoffDate = date ? new Date(`${date}T00:00:00`) : new Date();
  const [payoffDate, setPayoffDate] = useState<Date | null>(initialPayoffDate);
  const [loanData, setLoanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();

  const handleDateChange = (date: Date | null) => setPayoffDate(date);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newIndex: number) => setTabIndex(newIndex);

  useEffect(() => {
    if (!payoffDate) return;
    setLoading(true);
    const fetchData = async () => {
      try {
        const dateStr = payoffDate ? format(payoffDate, "yyyy-MM-dd") : "";
        if (tabIndex === 0) {
          await store.getPayoffDefaultInterest(dateStr);
        } else {
          await store.getForeclosureDefaultInterest(dateStr);
        }
      } catch {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [payoffDate, tabIndex, store]);

  useEffect(() => {
    const processLoanData = () => {
      const allocationList = tabIndex === 0 ? store.defaultInterestAllocation : store.foreclosureInterestAllocationList;
      if (!allocationList) return;


      const processedData = allocationList.map((item) => {
        // Default Interest Allocation
        if (tabIndex == 0) {
          const investor = item.investorName;

          const dynamicData = [
            {
              name: "Principal",
              Total: formatCurrency(item.principal),
              LoanServ: formatCurrency(0),
              ...(investor.toLowerCase().includes("athene") ? {
                "Funder-ATH": formatCurrency(item.principalFunder),
                "Loan Funder": formatCurrency(0),
              } : {
                LoanFunder: formatCurrency(item.principalFunder),
              }),
              TPO: formatCurrency(item.principalTpo),
              [investor]: formatCurrency(item.principalInv),
            },
            {
              name: "Extensions",
              Total: formatCurrency(item.extensions),
              LoanServ: formatCurrency(0),
              ...(investor.toLowerCase().includes("athene") ? {
                "Funder-ATH": formatCurrency(0),
                "Loan Funder": formatCurrency(item.extensions),
              } : {
                LoanFunder: formatCurrency(item.extensions),
              }),
              TPO: formatCurrency(0),
              [investor]: formatCurrency(0),
            },
            {
              name: "Note Rate Interest",
              Total: formatCurrency(item.interestNoteRate),
              LoanServ: formatCurrency(item.loanServicerNoteRate),
              ...(investor.toLowerCase().includes("athene") ? {
                "Funder-ATH": formatCurrency(item.funderNoteRate),
                "Loan Funder": formatCurrency(0),
              } : {
                LoanFunder: formatCurrency(item.funderNoteRate),
              }),
              TPO: formatCurrency(item.tpoNoteRate),
              [investor]: formatCurrency(item.investorNoteRate),
            },
            {
              name: "Default Rate Interest",
              Total: formatCurrency(item.interestDefaultRate),
              LoanServ: formatCurrency(item.loanServicerDefaultRate),
              ...(investor.toLowerCase().includes("athene") ? {
                "Funder-ATH": formatCurrency(item.funderDefaultRate),
                "Loan Funder": formatCurrency(0),
              } : {
                LoanFunder: formatCurrency(item.funderDefaultRate),
              }),
              TPO: formatCurrency(item.tpoDefaultRate),
              [investor]: formatCurrency(item.investorDefaultRate),
            },
            {
              name: "Total Interest",
              Total: formatCurrency(item.totalInterest),
              LoanServ: formatCurrency(item.totalServicerInterest),
              ...(investor.toLowerCase().includes("athene") ? {
                "Funder-ATH": formatCurrency(item.totalFunderInterest),
                "Loan Funder": formatCurrency(item.extensions),
              } : {
                LoanFunder: formatCurrency(item.totalFunderInterest),
              }),
              TPO: formatCurrency(item.totalTpoInterest),
              [investor]: formatCurrency(item.totalInvestorInterest),
            }
          ];


          const protectiveAdvances = Object.entries(item?.protectiveAdvances || {}).map(([key, value]) => ({
            "Protective Advance": key,
            Amount: formatCurrency(value),
          }));

          return {
            title: `[${item.loanId}] - ${item.dealName}`,
            servicerId: item.servicerId,
            payString: item.paystring,
            investor: item.investorName,
            tpo: item.tpoName,
            data: dynamicData,
            protectiveAdvances: protectiveAdvances.length > 0 ? protectiveAdvances : undefined,
          };
        };
        // Foreclosure Interest Allocation
        if (tabIndex === 1) {
          const investorNameKey = item.investorName;
          const dynamicData = item.foreclosureInterestAllocation.map(foreclosureItem => ({
            name: foreclosureItem.description,
            "Amount": formatCurrency(foreclosureItem.amount),
            "Loan Serv": formatCurrency(foreclosureItem.loanServicer),
            "Loan Funder": formatCurrency(foreclosureItem.funder),
            "TPO": formatCurrency(foreclosureItem.tpo),
            [investorNameKey]: formatCurrency(foreclosureItem.investor),
            "Loan UPB": formatCurrency(foreclosureItem.loanUPB),
            "Principal Loss": formatCurrency(foreclosureItem.principalLoss),
            "Interest Due": formatCurrency(foreclosureItem.interestDue),
            "Interest Loss": formatCurrency(foreclosureItem.interestLoss),
            "Protective Advances": formatCurrency(foreclosureItem.protectiveAdvance),
            "Protective Advances Loss": formatCurrency(foreclosureItem.protectiveAdvanceLoss),
          }));

          return {
            title: `[${item.loanId}] - ${item.dealName}`,
            servicerId: item.servicerId,
            payString: item.paystring,
            investor: item.investorName,
            tpo: item.tpoName,
            data: dynamicData,
            protectiveAdvances: null,
          };
        }

        return {} as LoanData;
      });

      setLoanData(processedData);
    };

    processLoanData();
  }, [store.defaultInterestAllocation, store.foreclosureInterestAllocationList, tabIndex]);

  return (
    <Box width="100%" paddingLeft="10%" paddingRight="10%" paddingTop="35px">
      <Typography variant="h4" align="center" gutterBottom>
        Interest Allocation
      </Typography>

      <Box display="flex" justifyContent="flex-end" width="100%">
        <DateField
          label="Payoff Date"
          value={payoffDate}
          onChange={date => handleDateChange(date)}
          format="MM/dd/yyyy"
          inputVariant="outlined"
          testId=""
          style={{
            marginBottom: "20px",
            width: "200px",
            float: "right",
          }}
        />
      </Box>

      {loading && <Typography align="center">Loading...</Typography>}
      {error && <Typography align="center" color="error">{error}</Typography>}


      <Card className={classes.cardStyle}>
        <Tabs className={classes.tabsContainer} value={tabIndex} onChange={handleTabChange} indicatorColor="primary">
          <Tab label="Default Allocation" />
          <Tab label="REO Allocation" />
        </Tabs>
        {!loading && !error && loanData.length > 0 ? (
          <Box padding={2}>
            {loanData.map((loan, index) => (
              <Accordion key={index} square defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{loan.title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Box marginLeft={0} marginTop={1} display="flex" >
                    <Typography className={classes.typographyStyle} variant="h6">
                      Servicer Id: <span style={{ color: "black" }}>{loan.servicerId}</span>
                    </Typography>
                    <Typography className={`${classes.typographyStyle} ${classes.typographyMargin}`} variant="h6">
                      Pay String (MBA): <span style={{ color: "black" }}>{loan.payString}</span>
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <PaidOffInterestAllocationTable loanData={loan.data} protectiveAdvances={false} />
                  </Box>
                  {Array.isArray(loan?.protectiveAdvances) && loan.protectiveAdvances.length > 1 && (
                    <Box mt={4} marginBottom={2}>
                      <PaidOffInterestAllocationTable loanData={loan.protectiveAdvances} protectiveAdvances={true} />
                    </Box>
                  )}

                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          <Typography align="center">No data available for the selected date.</Typography>
        )}
      </Card>

    </Box>
  );
});
