import { AccordionSummary, Box, Typography } from '@material-ui/core';
import { ArrowDropDown, House } from '@material-ui/icons';
import { isNumber } from '@roc/feature-utils';
import { Button, PropertyMap } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { emptyMapPlaceholder } from '../assets';
import { useNewLoanApplicationStore } from '../hooks/useNewLoanApplicationStore';
import { LoanApplicationProperty } from '../utils/loanApplicationTypes';
import {
  LoanApplicationAccordion,
  LoanApplicationCard,
  LoanApplicationCardActions,
  LoanApplicationCardDividers,
  LoanApplicationCardField,
} from './loanApplicationComponents';

const _formatCurrency = value =>
  isNumber(value) ? formatCurrency(value) : null;

export const PropertiesCard = observer(({ editable = true }) => {
  const { newLoanApplicationStore } = useNewLoanApplicationStore();
  const { propertyStore } = newLoanApplicationStore;
  const { properties } = propertyStore;
  const [accordionIndex, setAccordionIndex] = useState();

  const toggleAccordion = i => {
    setAccordionIndex(i === accordionIndex ? null : i);
  };

  return (
    <LoanApplicationCard icon={<House />} title={'Properties'}>
      <Box pt={2}>
        <LoanApplicationCardDividers px={2}>
          {properties.map((property, i) => (
            <Box mx={-2}>
              <LoanApplicationAccordion
                expanded={accordionIndex === i}
                onChange={() => toggleAccordion(i)}
                summary={property.address}
              >
                <Box flex={1}>
                  <PropertyItem property={property} />
                </Box>
              </LoanApplicationAccordion>
            </Box>
          ))}
          {properties.length === 0 && <PropertyPlaceholder />}
          {editable && (
            <Box px={2} py={2}>
              <LoanApplicationCardActions>
                <Button
                  color="primary"
                  testId={'editEntity'}
                  variant="outlined"
                  style={{ marginRight: '8px' }}
                  onClick={() => propertyStore.openAddProperty()}
                >
                  ADD PROPERTY
                </Button>
                <Button
                  color="primary"
                  testId={'editEntity'}
                  variant="outlined"
                  disabled={properties.length === 0}
                  onClick={() => propertyStore.openEditProperties()}
                >
                  EDIT PROPERTY(S)
                </Button>
              </LoanApplicationCardActions>
            </Box>
          )}
        </LoanApplicationCardDividers>
      </Box>
    </LoanApplicationCard>
  );
});

const PropertyPlaceholder = () => {
  const emptyProperty = {} as LoanApplicationProperty;
  return <PropertyItem property={emptyProperty} />;
};

const PropertyItem = observer(
  ({ property }: { property: LoanApplicationProperty }) => {
    return (
      <LoanApplicationCardDividers py={2}>
        {property.latitude === undefined ? (
          <img
            alt="placeholder"
            src={emptyMapPlaceholder}
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <PropertyMap
            latitude={property.latitude}
            longitude={property.longitude}
            address={property.address}
          />
        )}
        {/* <LoanApplicationCardField label="Loan Purpose" value={property.loanPurpose} /> */}
        <LoanApplicationCardField
          label="Purchase Price"
          value={
            !property.purchasePrice
              ? '-'
              : _formatCurrency(property.purchasePrice)
          }
        />
        <LoanApplicationCardField
          label="Favorable ARM"
          value={
            !property.armsLength
              ? '-'
              : property.armsLength === 'Y'
                ? 'Yes'
                : 'No'
          }
        />
        <LoanApplicationCardField
          label="Property Source"
          value={!property.propertySourcing ? '-' : property.propertySourcing}
        />
        <LoanApplicationCardField
          label="Renovation Budget"
          value={
            !property.totalRenovationBudget
              ? '-'
              : _formatCurrency(property.totalRenovationBudget)
          }
        />
        <LoanApplicationCardField
          label="Desired Renovation"
          value={
            Array.isArray(property.describeRenovation)
              ? (property.describeRenovation || []).length === 0
                ? '-'
                : (property.describeRenovation || [])
                    .map(item => item.value)
                    .join(', ')
              : property.describeRenovation
          }
        />
        {/* <LoanApplicationCardField
          label="Being converted to condominiums"
          value={property.}
        /> */}
        <LoanApplicationCardField
          label="After Renovation Value"
          value={
            !property.afterRepairValue
              ? '-'
              : _formatCurrency(property.afterRepairValue)
          }
        />
        <LoanApplicationCardField
          label="Exit Strategy"
          value={!property.exitStrategy ? '-' : property.exitStrategy}
        />
      </LoanApplicationCardDividers>
    );
  }
);
