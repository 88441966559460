/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DirtyableFormStore, GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";
import { REQUIRED_ERROR_MESSAGE } from "../utils/constants";

const form = {
  fields: {
    location: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_ERROR_MESSAGE
    },
    neighborhoodZoning: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_ERROR_MESSAGE
    },
    highestAndBestUse: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_ERROR_MESSAGE
    },
    hasAdverseFactors: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_ERROR_MESSAGE
    },
    comparableSalePriceFrom: {
      value: '',
      error: null,
      rule: '',
    },
    comparableSalePriceTo: {
      value: '',
      error: null,
      rule: '',
    },
    neighborhoodPriceFrom: {
      value: '',
      error: null,
      rule: '',
    },
    neighborhoodPriceTo: {
      value: '',
      error: null,
      rule: '',
    },
    adverseFactorsComments: {
      value: '',
      error: null,
      rule: '',
    },
    propertyPriceTrendMonthlyMedianPrice: {
      value: '',
      error: null,
      rule: '',
    },
    yoyMedianPricePercentage: {
      value: '',
      error: null,
      rule: '',
    },
    reoShortDistressedTrendMonthlyListings: {
      value: '',
      error: null,
      rule: '',
    },
    yoyReoListingsPercentage: {
      value: '',
      error: null,
      rule: '',
    },
    compsPast12Mo: {
      value: '',
      error: null,
      rule: '',
    },
    domTrend: {
      value: '',
      error: null,
      rule: '',
    },
    yoyDomPercentage: {
      value: '',
      error: null,
      rule: '',
    },
    supplyTrendMonthsOfSupply: {
      value: '',
      error: null,
      rule: '',
    },
    yoyMonthsOfSupplyPercentage: {
      value: '',
      error: null,
      rule: '',
    },
    demandTrendPercentSalesWithin2Weeks: {
      value: '',
      error: null,
      rule: '',
    },
    yoyWithin2WeeksPercentage: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class NeighborhoodFormStore extends DirtyableFormStore {
  globalStore: GlobalStore;


  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.reset();

    makeObservable(this, {
    })
  }
}
