import { observer } from 'mobx-react-lite';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import {
  Grid, Button, Theme, Chip
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NO_ACTION, SUBMITTED_FOR_REVIEW, CLARIFICATION_REQUESTED, SUBMITTED_FOR_REVIEW_MANUAL } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%'
    },
    statusChip: {
      width: '100%',
      marginTop: '3px',
    },
    reviewButton: {
      width: '100%',
      backgroundColor: '#FFC107',
      color: '#FFF',
      '&:hover': {
        background: '#B78103',
      },
    },
    revisionButton: {
      width: '100%',
      backgroundColor: '#d94352',
      color: '#FFF',
      '&:hover': {
        background: '#983D47',
      },
    },
    completedButton: {
      width: '100%',
      backgroundColor: '#48ad25',
      color: '#FFF',
      '&:hover': {
        background: '#387722',
      },
    }
  })
);

const reviewStatusMapping = {
  [SUBMITTED_FOR_REVIEW_MANUAL]: SUBMITTED_FOR_REVIEW,
  [CLARIFICATION_REQUESTED]: CLARIFICATION_REQUESTED,
  [SUBMITTED_FOR_REVIEW]: SUBMITTED_FOR_REVIEW,
  [NO_ACTION]: NO_ACTION
};

interface Props {
  store: ScopeOfWorkV2FormBaseStore;
}

export const InternalReviewButtons = observer((props: Props) => {
  const classes = useStyles();
  const { store } = props;

  const showSubmitForReviewButton = (store.params?.reviewStatus === NO_ACTION || store.params?.reviewStatus === CLARIFICATION_REQUESTED);

  return (
    <>
      {(store.showInternalActions && store?.activeStep === 3) &&
        <Grid item container xs={12} md={4} justifyContent='center'>
          <Chip
            className={classes.statusChip}
            label={reviewStatusMapping[store.params?.reviewStatus] ?? NO_ACTION}
            variant="outlined"
            style={{ marginLeft: '12px' }} />
        </Grid>
      }
      {store?.activeStep === 3 && store.showInternalActions &&
        <Grid container xs={12} spacing={2} style={{ marginTop: '5px', marginBottom: '3px' }}>
          {showSubmitForReviewButton &&
            <Grid item container xs={12} md={4} justifyContent='center'>
              <Button
                variant="contained"
                className={classes.reviewButton}
                onClick={() => store.setOpenSubmitReviewDialog(true)}
              >
                Submit for CMT Review
              </Button>
            </Grid>
          }
          <Grid item container xs={12} md={showSubmitForReviewButton ? 4 : 6} justifyContent='center'>
            <Button
              variant="contained"
              className={classes.revisionButton}
              onClick={() => store.setOpenSubmitRevisionDialog(true)}
            >
              Submit for TPO Revision
            </Button>
          </Grid>
          <Grid item container xs={12} md={showSubmitForReviewButton ? 4 : 6} justifyContent='center'>
            <Button
              variant="contained"
              className={classes.completedButton}
              onClick={() => store.setOpenCompleteReviewDialog(true)}
            >
              Complete Review
            </Button>
          </Grid>
        </Grid>
      }
    </>
  );
});
