import ReactECharts from "echarts-for-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { ChartUnavailable } from "./chartUnavailable";

const chartTitle = "Percentage of Active Listing w/ Price Cuts, Past Year, and Price Tier";

function transformData(chartData) {
  try {
    if (!chartData) {
      return null;
    }
    const colors = ["#3B82F6", "#22C55E", "#FACC15", "#EF4444", "#38BDF8"]

    const option = {
      title: {
        text: chartTitle,
        left: "center",
        textStyle: {
          fontSize: 20,
          fontWeight: "bold",
          color: "#656565",
        },
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        top: "8%",
        left: "center",
        data: Object.keys(chartData.max_percentile_line),
      },
      grid: {
        left: "3%",
        right: "6%",
        bottom: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [],
    };

    const monthDates = chartData.max_percentile_line["0 - 25%"].values.map((d) => new Date(d.year, d.month - 1)).sort((a, b) => a - b);
    const months = monthDates.map((d) => new Intl.DateTimeFormat("en-US", { month: "short", year: "numeric" }).format(d));

    option.xAxis.data = months;

    Object.keys(chartData.max_percentile_line).forEach(
      (tier, index) => {
        const mappedValues = chartData.max_percentile_line[tier].values.map(entry => ({
          value: entry.value,
          date: new Date(entry.year, entry.month - 1)
        })).sort((a, b) => a.date - b.date);


        option.series.push({
          name: tier,
          type: "line",
          data: mappedValues.map((entry) => Math.round(entry.value * 100) / 100),
          color: colors[index % colors.length],
        });
      }
    );

    return option;
  } catch (err) {
    console.log(err);
    return null;
  }
}

interface PercentageOfActiveListingChartProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const PercentageOfActiveListingChart = ({ internalValuationReviewStore }: PercentageOfActiveListingChartProps) => {
  const chartData = internalValuationReviewStore.initialData.charts?.percentage_of_active_listings;
  const option = transformData(chartData);

  if (!option) {
    return <ChartUnavailable chartName={chartTitle} />
  }

  return (
    <ReactECharts option={option} style={{ height: "461px", width: "100%" }} />
  );
};