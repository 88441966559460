import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { useRocInternalPortalStore } from '../../../hooks';
import { BorrowerInfoCardComponent } from '../borrowerInfoCardComponent';
import { BorrowerTrackRecordComponent } from '../borrowerTrackRecordComponent';
import { BorrowerUWReviewComponent } from '../borrowerUWReviewComponent';
import { BorrowerTrackRecordLineItemComponent } from '../borrowerTrackRecordLineItemComponent';

export const getBorrowerBaseballCardRoutesConfig = (basePath: string, baseUrl: string, borrowerId: string) => {
  return {
    borrowerBaseballCard: {
      path: `${basePath}/borrower-info-card`,
      url: `${baseUrl}/borrower-info-card`,
      documentTitle: 'View Borrower Baseball Card',
    },
  };
};

export const getBorrowerBaseballCardRoutes = (basePath: string, baseUrl: string, borrowerId: string) => {
  const { borrowerBaseballCardStore } = useRocInternalPortalStore();
  const config = getBorrowerBaseballCardRoutesConfig(basePath, baseUrl, borrowerId);

  return {
    borrowerBaseballCard: (
      <Route exact path={config.borrowerBaseballCard.path}>
        <Page routeDefinition={config.borrowerBaseballCard}>
          {BorrowerInfoCardComponent(borrowerBaseballCardStore, borrowerId)}
        </Page>
      </Route>
    ),
  };
};

export const getBorrowerTrackRecordRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowerTrackRecord: {
      path: `${basePath}/borrower-track-record`,
      url: `${baseUrl}/borrower-track-record`,
      documentTitle: 'View Borrower Track Reccord',
    },
  };
};

export const getBorrowerTrackRecordRoutes = (basePath: string, baseUrl: string, borrowerId: string, loanId) => {
  const { borrowerBaseballCardStore } = useRocInternalPortalStore();
  const config = getBorrowerTrackRecordRoutesConfig(basePath, baseUrl);

  return {
    borrowerTrackRecord: (
      <Route exact path={config.borrowerTrackRecord.path}>
        <Page routeDefinition={config.borrowerTrackRecord}>
          {BorrowerTrackRecordComponent(borrowerBaseballCardStore, borrowerId, loanId)}
        </Page>
      </Route>
    ),
  };
};

export const getBorrowerTrackRecordLineItemRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowerTrackRecord: {
      path: `${basePath}/borrower-track-record-form`,
      url: `${baseUrl}/borrower-track-record-form`,
      documentTitle: 'View Borrower Track Reccord',
    },
  };
};

export const getBorrowerTrackRecordLineItemRoutes = (basePath: string, baseUrl: string, borrowerId: string, loanTaskId) => {
  const { borrowerTrackRecordStore } = useRocInternalPortalStore();
  const config = getBorrowerTrackRecordLineItemRoutesConfig(basePath, baseUrl);

  return {
    borrowerTrackRecord: (
      <Route exact path={config.borrowerTrackRecord.path}>
        <Page routeDefinition={config.borrowerTrackRecord}>
          <BorrowerTrackRecordLineItemComponent
            store={borrowerTrackRecordStore}
            borrowerId={borrowerId}
            loanTaskId={loanTaskId}
          />
        </Page>
      </Route>
    ),
  };
};

export const getBorrowerUWReviewRoutesConfig = (basePath: string, baseUrl: string) => {
  return {
    borrowerUWReview: {
      path: `${basePath}/borrower-uw-review`,
      url: `${baseUrl}/borrower-uw-review`,
      documentTitle: 'Borrower Underwriter Review',
    },
  };
};

export const getBorrowerUWReviewRoutes = (basePath: string, baseUrl: string, borrowerId: string, reviewSection: string, loanId: string) => {
  const { borrowerBaseballCardStore } = useRocInternalPortalStore();
  const config = getBorrowerUWReviewRoutesConfig(basePath, baseUrl);

  return {
    borrowerUWReview: (
      <Route exact path={config.borrowerUWReview.path}>
        <Page routeDefinition={config.borrowerUWReview}>
          {BorrowerUWReviewComponent(borrowerBaseballCardStore, borrowerId, loanId, reviewSection)}
        </Page>
      </Route>
    ),
  };
};
