import { makeStyles, Theme } from '@material-ui/core/styles';
import { CardContent, Typography, Box, Card } from '@material-ui/core';
import { getTimeElapsed } from '@roc/feature-utils';
import classnames from 'classnames';
import { useLoanCommunicationStore } from '@roc/feature-communication';
import { useLoanStore } from '@roc/feature-loans';
import { useEffect, useState } from 'react';
import { CircularLoader, HtmlContainer } from '@roc/ui';
import { CustomCard } from '../customCard';
import { colors } from '../../utils/todoConstants';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    alignSelf: 'stretch',
    color: colors.dune,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '143%',
    fontWeight: 400,
    letterSpacing: '0.25px',

    '& *:not(ul):not(ol)': { 
      margin: '0 !important',
      padding: '0 !important',
    },

    '& ul, & ol': {
      paddingLeft: '25px',
      marginTop: '8px',
      marginBottom: '8px',
    },

    '& li': {
      marginBottom: '4px',
    }
  },
  timelapse: {
    alignSelf: 'stretch',
    color: colors.mountainMist,
    fontSize: '10px',
    fontStyle: 'normal',
    lineHeight: '266%',
    fontWeight: 400,
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  },
  cardBody: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  listItem: {
    display: 'flex',
    padding: '0px 16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.mercury}`,
  },
  listItemGrayColor: {
    background: colors.soapstone,
  }
}));

const mapUpdateLogsData = (logs) => {
  return logs.map((log, index) => ({
    id: log.sid || index,
    time: new Date(log.dateCreated).getTime(),
    message: log.body,
  }));
}

const UpdateLogCard = ({ loanId, fullHeight = false }) => {
  const classname = useStyles();
  const { loanCommunicationStore } = useLoanCommunicationStore();

  const [conversationSid, setConversationSid] = useState<string | null>(null);
  const [messages, setMessages] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    setShowLoading(true);
    loanCommunicationStore.getLoanUpdateLogsChatRoom(
      loanId,
      () => {
        const newConversationSid = loanCommunicationStore.updateLogConversationId;
        if (newConversationSid && newConversationSid !== conversationSid) {
          setConversationSid(newConversationSid);
        }
        setShowLoading(false);
      }
    );
  }, [loanId]);

  useEffect(() => {
    if (!conversationSid && showLoading) return;

    const logs = loanCommunicationStore.updateLogMessages || [];
    const formattedLogs = mapUpdateLogsData(logs);

    setMessages(formattedLogs);
    setShowLoading(false);
  }, [conversationSid, loanCommunicationStore.updateLogMessages, showLoading]);

  return (
    <CustomCard
      paperNoShadow
      paperStyle={{
        height: '100%',
        width: fullHeight ? '100%' : 'auto',
      }}
      title="Update Log"
    >
      {showLoading ? <CircularLoader position="relative" thickness={5} color="primary" size={24} /> :
        <Box className={classname.cardBody} style={{ maxHeight: fullHeight ? '100%' : '500px'}}>
          {messages?.length > 0 ? messages.map((update, index) => (
            <Box key={update.id} className={classnames(classname.listItem, {
              [classname.listItemGrayColor]: index % 2 === 0
            })}>
              <div className={classname.message}>
                <div dangerouslySetInnerHTML={{ __html: update.message }}></div>
              </div>
              <Typography className={classname.timelapse}>
                {getTimeElapsed(update.time)}
              </Typography>
            </Box>
          )) :
            <Typography className={classname.message}>
              No logs recorded for this loan.
            </Typography>
          }
        </Box>}
    </CustomCard>
  );
};

export default UpdateLogCard;
