import { useRoutes } from '@roc/client-portal-shared/hooks';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import {
  isFundedLoan,
  isFundedOrAgreedLoan,
  isPreagreedLoanStatus,
  LoanStatus,
  LoanSubType,
  LoanType,
  Roles,
  SERVICING,
} from '@roc/feature-utils';

export const useLoanFlags = () => {
  const { loanStore } = useLoanStore();
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const { documentStore } = useDocumentStore();

  const status = loanDetails?.status;
  const loanType = loanDetails?.loanType;
  const loanSubType = loanDetails?.loanSubType;
  const fullySubmitted = loanDetails?.fullySubmitted;
  const isTaskFrameworkEnabled = !!loanDetails?.taskFrameworkEnabled;

  const { isClosingAttorney } = globalStore.userFeatures;
  const isFundedOrPaidoffOrAgreedLoan = isFundedOrAgreedLoan(status);
  const showLoanExpirationDates = !isFundedLoan(status);
  const allowLoanPricing = userStore.allowLoanPricing;

  const showCreateApprasialOrderButton =
    !!loanStore.loanDetails?.enableAppraisalCreation &&
    !loanStore.loanDetails?.submitAppraisalWithTamarisk &&
    !loanStore.loanDetails?.arrEnabled;

  const showEditBorrowers = false;

  const showEditLoanButton =
    userStore.isBackOfficeUser && isPreagreedLoanStatus(status) && fullySubmitted;

  const showEditContacts =
    !globalStore.userFeatures?.isLenderOriginator &&
    loanDetails?.status !== LoanStatus.PAID_OFF && fullySubmitted;

  const showOriginatorsCard = globalStore.userFeatures?.addFacoOriginators;

  const showEditLenderOriginators = isPreagreedLoanStatus(status);

  const showResendInsuranceButton =
    globalStore.userFeatures?.resendInsuranceQuote;

  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(
    Roles.BACK_OFFICE_PROCESSOR
  );
  const isLenderOriginator = globalStore.userFeatures?.isLenderOriginator;
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const showDecisionComments = globalStore.userFeatures.showDecisionComments;
  const isBackOfficeOriginator = userStore.userInformation?.roles?.includes(
    Roles.BACK_OFFICE_ORIGINATOR
  );

  const showDecisionCommentsTab =
    showDecisionComments && isBackOfficeOriginator;

  const showCrmLoanSummaryView = globalStore.userFeatures?.showLoanSummary;

  const showCommissionsCard =
    isBackOfficeUser && !isBackOfficeProcessor && isInternal;

  const isLoanSubmissionEditable =
    loanStore.loanDetails?.status === LoanStatus.PENDING_SUBMISSION ||
    loanStore.loanDetails?.status === LoanStatus.PENDING;

  const showEditSubmissionButtonForLoanType =
    (loanType === LoanType.RESIDENTIAL_TERM &&
      loanSubType === LoanSubType.SINGLE_PROPERTY) ||
    (loanType === LoanType.RESIDENTIAL_BRIDGE &&
      loanSubType !== LoanSubType.MULTIFAMILY_BRIDGE_5PLUS);

  const showEditSubmissionButton =
    allowLoanPricing &&
    fullySubmitted &&
    isLoanSubmissionEditable &&
    showEditSubmissionButtonForLoanType &&
    (userStore.isLenderUser || userStore.isSalesUser);

  const isExternalAgent = globalStore.userFeatures?.isExternalAgent;

  const showDelinquency =
    loanDetails?.showDelinquency &&
    (globalStore.userFeatures.showForeclosureRequest ||
      isExternalAgent);

  const isVendor =
    globalStore.userFeatures.isClosingAttorney ||
    globalStore.userFeatures.isInsuranceReviewer ||
    globalStore.userFeatures.isClosingReviewer;

  const isServicer = globalStore.userFeatures?.isServicer;

  const hideDrawsTab = isVendor || isServicer || isExternalAgent;

  const hideExtensionsTab = isVendor || isServicer || isExternalAgent;

  const hideLoanSumaryTab = isExternalAgent || isServicer;

  const hideUnderwritingTab = isExternalAgent || isServicer;

  const hideClosingTab = isExternalAgent || isServicer;

  const hidePayoffsTab = isVendor || isExternalAgent;

  const showContactsTab = isClosingAttorney;

  const servicingSections = documentStore.displaySections[SERVICING] || [];

  const showPostClosing = !globalStore.userFeatures.isInsuranceReviewer &&
    !isClosingAttorney && !globalStore.userFeatures.isClosingReviewer && servicingSections.length > 0;

  return {
    showCreateApprasialOrderButton,
    showEditLoanButton,
    showEditBorrowers,
    showEditContacts,
    showOriginatorsCard,
    showEditLenderOriginators,
    showResendInsuranceButton,
    showDecisionCommentsTab,
    showCrmLoanSummaryView,
    showCommissionsCard,
    showEditSubmissionButton,
    allowLoanPricing,
    showDelinquency,
    hideDrawsTab,
    hideExtensionsTab,
    hidePayoffsTab,
    showContactsTab,
    isExternalAgent,
    hideLoanSumaryTab,
    hideUnderwritingTab,
    hideClosingTab,
    showPostClosing,
    isClosingAttorney,
    isTaskFrameworkEnabled,
    isLenderOriginator
  };
};
