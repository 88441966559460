/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Box, Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InternalValuationReviewTabs } from "./components/tabs/internalValuationReviewTabs";
import { SubjectPropertyInformationCard } from "./components/subjectPropertyInformationCard";
import { InternalValuationReviewStore } from "./stores/internalValuationReviewStore";
import { observer } from "mobx-react";
import { InternalValuationReport } from "./components/internalValuationReport/internalValuationReport";
import { useEffect, useRef, useState } from 'react';
import { PrintableAreaRef, VerticalBarLoader } from "@roc/ui";
import { useDocumentStore } from "@roc/feature-documents";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    backgroundColor: "transparent"
  },
  backgroundContainer: {
    position: "absolute",
    height: "1300px",
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #ffffff, #BABEC5)",
    zIndex: -1
  },
  hiddenDiv: {
    position: "absolute",
    height: 0,
    left: "-9999px"
  },
}));

interface InternalValuationReviewProps {
  internalValuationReviewStore: InternalValuationReviewStore;
  loanId: number;
  propertyId: number;
  readOnly?: boolean;
}

export const InternalValuationReview = observer(({ loanId, propertyId, readOnly = false, internalValuationReviewStore }: InternalValuationReviewProps) => {
  const classes = useStyles();
  const { documentStore } = useDocumentStore();

  const internalValuationReportRef = useRef<PrintableAreaRef>(null);
  const [reportOpen, setReportOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    internalValuationReviewStore.setDefaults();
    internalValuationReviewStore.loadInternalValuationReviewData(loanId, propertyId, readOnly);
  }, [loanId, propertyId, readOnly, internalValuationReviewStore]);

  const generateReportHandler = async () => {
    try {
      setIsLoading(true);
      setReportOpen(true);

      await new Promise((resolve) => setTimeout(resolve, 600));
      if (!internalValuationReportRef.current) {
        return;
      }
      const pdfBlob = await internalValuationReportRef.current.getPdfBlobUrl({
        allowTaint: true,
        useCORS: true,
      });
      const response = await fetch(pdfBlob.toString());
      const blob = await response.blob();

      const reportFile = new File([blob], "internalValuationReport.pdf", { type: "application/pdf", lastModified: Date.now() });

      const uploadResponse: any = await internalValuationReviewStore.uploadDocument(reportFile);

      if (uploadResponse?.data?.data) {
        const loanTask = JSON.parse(uploadResponse?.data?.data);
        documentStore.downloadDocument(loanTask);
      }
    } catch (err) {
      internalValuationReviewStore.globalStore.notificationStore.showErrorNotification({
        message: "There was an error generating the report.",
      });
      console.log(err);
    }
    setReportOpen(false);
    setIsLoading(false);
  };

  return (
    <Card className={classes.root} elevation={0}>
      <Box className={classes.backgroundContainer} />
      <Grid container direction="column" spacing={2}>
        <Grid item style={{ paddingInline: "1.5rem" }}>
          <SubjectPropertyInformationCard internalValuationReviewStore={internalValuationReviewStore} generateReport={generateReportHandler} />
        </Grid>
        <Grid item>
          <InternalValuationReviewTabs internalValuationReviewStore={internalValuationReviewStore} />
        </Grid>
      </Grid>
      {reportOpen && (<div className={classes.hiddenDiv}>
        <InternalValuationReport internalValuationReviewStore={internalValuationReviewStore} ref={internalValuationReportRef} />
      </div>)}
      {isLoading && <VerticalBarLoader />}
    </Card>
  );
});
