import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { MonthlySupplyOfActiveListingChart } from "../charts/monthlySupplyOfActiveListingChart";
import { PercentageOfActiveListingChart } from "../charts/percentageOfActiveListingChart";

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    fontFamily: "Roboto, Arial, sans-serif",
    border: "1px solid lightgray",
    borderRadius: "5px",
    width: "1018px",
    height: "1440px",
    padding: "64px 40px 64px 40px",
  },
  title: {
    fontSize: "64px",
    fontWeight: "bolder",
    color: "#2EC2C9",
    marginBottom: "20px",
    marginLeft: "24px",
  },
}));

interface Page4Props {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const Page4 = ({ internalValuationReviewStore }: Page4Props) => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography className={classes.title}>Appendix A</Typography>
        <Typography variant="h3">Liquidity Report</Typography>
      </Box>
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              padding: "20px",
            }}
          >
            <MonthlySupplyOfActiveListingChart internalValuationReviewStore={internalValuationReviewStore} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              padding: "20px",
            }}
          >
            <PercentageOfActiveListingChart internalValuationReviewStore={internalValuationReviewStore} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};