import { Backdrop, Box, Button, Fade, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useBaseStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { InternalValuationReview } from '@roc/feature-valuation-review-v2';
import { StandardDialog } from '@roc/ui';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';

type InternalValuationReportDialogProps = {
  open: boolean;
  onClose: () => void;
  document: Document;
};

const useStyles = makeStyles((theme) => ({
  customDialogContent: {
    padding: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    textAlign: "center",
    width: 400,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  h6Typography: {
    fontSize: "24px",
  },
  body2Typography: {
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
}));

export const InternalValuationReportDialog = observer(
  ({ open, onClose, document }: InternalValuationReportDialogProps) => {

    const classes = useStyles();
    const { globalStore } = useBaseStore();
    const obj = useRocInternalPortalStore();
    const { internalValuationReviewStore } = obj;
    const isDirty = useRef(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    useEffect(() => {
      const disposer = autorun(() => {
        isDirty.current = internalValuationReviewStore.isDirty;
      });

      return () => disposer();
    }, [internalValuationReviewStore]);

    if (!document) {
      return null;
    }

    const dialogContent = (
      <Box display="flex" overflow="visible" zIndex={0}>
        <InternalValuationReview internalValuationReviewStore={internalValuationReviewStore} loanId={document.loanId} propertyId={document.collateralId} readOnly={!globalStore.isInternalPortal} />
      </Box>
    );

    const onCloseModalHandler = () => {
      if (isDirty.current) {
        setIsConfirmOpen(true);
      } else {
        onClose();
      }
    }

    const onConfirmCloseHandler = () => {
      setIsConfirmOpen(false);
      onClose();
    }

    return (
      <>
        <StandardDialog
          maxWidth={'lg'}
          open={open}
          title="Internal Valuation Report"
          handleClose={onCloseModalHandler}
          dialogContent={dialogContent}
          removeContentBox
          overflowDialog
          dialogContentclasses={{ root: classes.customDialogContent }}
        />
        <ConfirmModal open={isConfirmOpen} onClose={() => setIsConfirmOpen(false)} onConfirm={onConfirmCloseHandler} />
      </>
    );
  }
);



const ConfirmModal = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <Typography variant="h6" className={classes.h6Typography}>
            Unsaved Changes
          </Typography>
          <Typography variant="body2" className={classes.body2Typography}>
            You have unsaved changes. If you close this form, your changes will be lost.
          </Typography>
          <div className={classes.buttonContainer}>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              Discard Changes
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};