import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { makeObservable, observable, action } from 'mobx';
import { ServicerLoanService } from '../services/servicerLoanService';
import { downloadDocument } from '@roc/feature-utils';

export class servicerLoansDashboardStore {
  private globalStore: GlobalStore;
  private servicerLoanService: ServicerLoanService;

  myNewRequestLoansGridStore: GridStore;
  rocLoansGridStore: GridStore;
  myRevisionLoansGridStore: GridStore;
  myApprovedLoansGridStore: GridStore;
  myRetractedExpiredLoansGridStore: GridStore;
  myForeclosureLoansGridStore: GridStore;
  statuses: string
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.servicerLoanService = new ServicerLoanService();

    this.myNewRequestLoansGridStore = new GridStore(
      () => this.fetchMyNewRequestLoans(),
      null,
      50
    );
    this.rocLoansGridStore = new GridStore(
      () => this.fetchRocLoans(),
      null,
      50
    );
    this.myRevisionLoansGridStore = new GridStore(
      () => this.fetchMyRevisionLoans(),
      null,
      50
    );
    this.myApprovedLoansGridStore = new GridStore(
      () => this.fetchMyApprovedLoans(),
      null,
      50
    );
    this.myRetractedExpiredLoansGridStore = new GridStore(
      () => this.fetchMyRetractedExpiredkLoans(),
      null,
      50
    );
    this.myForeclosureLoansGridStore = new GridStore(
      () => this.fetchMyForeclosureLoans(),
      null,
      50
    )
    makeObservable(this, {
      statuses: observable,
      setStatuses: action
    });
  }

  setStatuses(statuses: string) {
    this.statuses = statuses;
  }

  private async fetchMyNewRequestLoans() {
    try {
      this.setStatuses('Forwarded to Servicer');
      const filters = {
        ...this.myNewRequestLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Forwarded to Servicer',
        this.myNewRequestLoansGridStore.gridData.meta.pageNumber,
        this.myNewRequestLoansGridStore.gridData.meta.pageSize,
        this.myNewRequestLoansGridStore.gridData.meta.sortDir,
        this.myNewRequestLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myNewRequestLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my new requests.',
      });
    }
  }

  private async fetchRocLoans() {
    try {
      this.setStatuses('Approval and Forwarding Pending');
      const filters = {
        ...this.rocLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Approval and Forwarding Pending',
        this.rocLoansGridStore.gridData.meta.pageNumber,
        this.rocLoansGridStore.gridData.meta.pageSize,
        this.rocLoansGridStore.gridData.meta.sortDir,
        this.rocLoansGridStore.gridData.meta.sortBy,
        filters,
        this.rocLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my funded loans.',
      });
    }
  }

  private async fetchMyRevisionLoans() {
    try {
      this.setStatuses('In Revision');
      const filters = {
        ...this.myRevisionLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'In Revision',
        this.myRevisionLoansGridStore.gridData.meta.pageNumber,
        this.myRevisionLoansGridStore.gridData.meta.pageSize,
        this.myRevisionLoansGridStore.gridData.meta.sortDir,
        this.myRevisionLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myRevisionLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my priority list loans.',
      });
    }
  }

  private async fetchMyApprovedLoans() {
    try {
      this.setStatuses('Approved');
      const filters = {
        ...this.myApprovedLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Approved',
        this.myApprovedLoansGridStore.gridData.meta.pageNumber,
        this.myApprovedLoansGridStore.gridData.meta.pageSize,
        this.myApprovedLoansGridStore.gridData.meta.sortDir,
        this.myApprovedLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myApprovedLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my priority list loans.',
      });
    }
  }

  private async fetchMyRetractedExpiredkLoans() {
    try {
      this.setStatuses('Retracted,Expired');
      const filters = {
        ...this.myRetractedExpiredLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'Retracted,Expired',
        this.myRetractedExpiredLoansGridStore.gridData.meta.pageNumber,
        this.myRetractedExpiredLoansGridStore.gridData.meta.pageSize,
        this.myRetractedExpiredLoansGridStore.gridData.meta.sortDir,
        this.myRetractedExpiredLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myRetractedExpiredLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my fast track loans.',
      });
    }
  }


  private async fetchMyForeclosureLoans() {
    try {
      this.setStatuses('In Foreclosure');
      const filters = {
        ...this.myForeclosureLoansGridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffLoans(
        'In Foreclosure',
        this.myForeclosureLoansGridStore.gridData.meta.pageNumber,
        this.myForeclosureLoansGridStore.gridData.meta.pageSize,
        this.myForeclosureLoansGridStore.gridData.meta.sortDir,
        this.myForeclosureLoansGridStore.gridData.meta.sortBy,
        filters,
        this.myForeclosureLoansGridStore.gridData.meta.dropdownFilters
      );
      return {
        ...response,
        data: {
          ...response.data,
          data: JSON.parse(response.data?.data || '{}')
        }
      };
    } catch (error) {
      console.log('error', error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my foreclosure list loans.',
      });
    }
  }

  private async exportLoansToExcel(
    gridStore: GridStore,
    tabName: string
  ) {
    try {
      const filters = {
        ...gridStore.gridData.meta.filters,
      };
      const response: ApiResponse = await this.servicerLoanService.getPayoffExcelFile(
        this.statuses,
        gridStore.gridData.meta.pageNumber,
        gridStore.gridData.meta.pageSize,
        gridStore.gridData.meta.sortDir,
        gridStore.gridData.meta.sortBy,
        filters,
        gridStore.gridData.meta.dropdownFilters,

      );
      downloadDocument(response.data, response.headers, 'download', `${tabName}_PAYOFFS_REPORT.xlsx`);
    } catch (error) {
      console.log('Error', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while exporting loans`,
      });
    }
  }

}
