import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { useStore } from '@roc/client-portal-shared/hooks';
import { ExternalForeclosureLoansGrid } from './components/externalForeclosureLoansGrid'
import { ExternalAgentTabs } from './components/externalAgentTabs'

const TABS = [
  {
    label: 'FORECLOSURE'
  }
];

export const LoansHome = observer(() => {
  const { loanStore } = useStore();
  const { globalStore } = useBaseStore();

  useEffect(() => {
    const isComingBackFromLoanDetails = globalStore.lastHistoryAction === 'POP';
    if (!isComingBackFromLoanDetails) {
      loanStore.setSelectedTabIndex(0);
    }
  }, []);

  return (
    <Layout>
      <ExternalAgentTabs tabs={TABS} />
      {loanStore.selectedTabIndex === 0 && <ExternalForeclosureLoansGrid />}
    </Layout>
  );
});
