import {
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MoreVert, NavigateNext } from '@material-ui/icons';
import {
  GridStore,
  InfiniteScrollGrid
} from '@roc/feature-app-core';
import { PORTAL_COLORS } from '@roc/feature-utils';
import {
  LinkColumn, SelectFilterComponent,
  SelectFloatingFilterComponent, MenuItem,
  TextColumnWithOnClick,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { GridColumnProps } from '@roc/client-portal-shared/components';
import { useLoanStore } from '@roc/feature-loans';
import { useRoutes } from '../../hooks/useRoutes';
import { SingleTaskView } from '@roc/feature-general-tasks';
import { Alert } from '@material-ui/lab';

const noOpComparator = () => 0;

export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -12,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.unreadMessages,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

export interface MyAppraisalTasksBackOfficeGridProps {
  store: GridStore;
  openMenu?: (event: React.MouseEvent<HTMLElement>, record: any) => void;
}

export const MyAppraisalTasksBackOfficeGrid = observer((props: MyAppraisalTasksBackOfficeGridProps) => {
  const { loanStore } = useLoanStore();
  const { loanRoutesConfig } = useRoutes();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [currentRow, setCurrentRow] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    loanIdCellRenderer: params => {
      return (
        <StyledBadge>
          {
            TextColumnWithOnClick({
              ...params,
              onClick: () => setTaskId(params?.node?.data?.taskId)
            })
          }
        </StyledBadge>
      )
    },
    actionCellRenderer: params => {
      return (
        <IconButton onClick={e => openMenu(e, params.node.data)}>
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const getUrl = (row) => {
    const loanId = row?.loanId;
    return loanRoutesConfig.loans(loanId).children.dashboard.url;
  }

  useEffect(() => {
    props.store.resetAndFetchGridData();
  }, [loanStore.selectedTabIndex]);

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setCurrentRow(record);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const columns: GridColumnProps[] = [
    {
      field: 'loanId',
      headerName: 'Loan Id',
      maxWidth: 110,
      cellRenderer: 'loanIdCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      pinned: 'left',
      lockPinned: true,
    },
    {
      field: 'lenderName',
      headerName: 'Lender Name',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'section',
      headerName: 'Section',
      minWidth: 125,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'startDate',
      headerName: 'Requested Date',
      minWidth: 150,
      comparator: noOpComparator,
      filter: 'agDateColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'daysInQueue',
      headerName: 'Hours in queue',
      minWidth: 125,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true
    },
  ];

  const handleCloseTaskModal = () => {
    setTaskId(null);
    handleMenuClose();
    props.store.resetAndFetchGridData();
  }

  return (
    <>
      <Box>
        <Alert severity="info">
          Click on the loan ID or the three dots under 'Action' to open the task. From the Task Interface, you can update the task status to 'Pending on Borrower' or move it back to the 'Capital Provider'.
        </Alert>
      </Box>
      <InfiniteScrollGrid
        columns={columns}
        frameworkComponents={frameworkComponents}
        store={props.store}
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          testId='task-item'
          onClick={() => {
            setTaskId(currentRow?.taskId);
          }}>
          <ListItemIcon>
            <NavigateNext />
          </ListItemIcon>
          <ListItemText>Task</ListItemText>
        </MenuItem>
      </Menu>
      <SingleTaskView taskId={taskId} onClose={handleCloseTaskModal} />
    </>
  );
});