import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, CloudUpload, Delete, Description } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import { FileUpload } from '@roc/ui';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';

enum Color {
  WARNING = '#EF6C00',
  WARNING_BACKGROUND = '#FFF4E5',
  WARNING_TEXT = '#663C00',
}

const useStyles = makeStyles(() => ({
  dragArea: {
    border: '2px dashed #EF6C00',
    width: '100%',
    minHeight: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 8,
  },
  errorDragArea: {
    border: '2px dashed #D10A0A',
    width: '100%',
    minHeight: '120px',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 8,
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#EF6C00',
  },
  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  errorMeasage: {
    color: '#D10A0A',
  },
}));

const getMultipartFile = (fileUpload: FileUpload) => {
  const blob = fileUpload.file.slice(
    0,
    fileUpload.file.size,
    fileUpload.file.type
  );
  return new File([blob], fileUpload.name, { type: fileUpload.file.type });
};

export const StateLicenseUpload = observer(
  ({
    quoteTypeStore,
    propertyFormStore,
  }: {
    quoteTypeStore: QuoteTypeStore;
    propertyFormStore: PropertyFormStore;
  }) => {
    const { propertiesStore } = quoteTypeStore;
    const state = propertyFormStore.form.fields.state.value;
    const [uploads, setUploads] = useState([]);

    useEffect(() => {
      if (uploads?.[0]) {
        quoteTypeStore.propertiesStore.uploadStateLicense(
          getMultipartFile(uploads[0]),
          state
        );
      }
    }, [uploads]);

    const license = propertiesStore?.uploadedLicenses[state];
    return (
      <_StateLicenseUpload
        items={license ? [{ name: license, file: new File([], license) }] : []}
        setItems={setUploads}
      />
    );
  }
);

interface IFileUploadAreaProps {
  items: FileUpload[];
  setItems: (items) => void;
  multiple?: boolean;
  fileType?: string;
  fileTypes?: string[];
  showError?: boolean;
  maxFiles?: number;
}

export const _StateLicenseUpload = observer(
  ({
    items,
    setItems,
    multiple,
    fileType,
    showError,
    fileTypes,
    maxFiles,
  }: IFileUploadAreaProps) => {
    const classes = useStyles();
    const [error, setError] = useState<boolean>(false);
    const isCorrectFileType = (files: any[]) => {
      let isValid = true;
      if (fileType) {
        files.forEach(file => {
          if (file.type?.toLowerCase() !== fileType.toLowerCase()) {
            isValid = false;
            return;
          }
        });
      }

      if (fileTypes) {
        files.forEach(file => {
          const dotSplitFileName = file.name.split('.');
          if (
            !fileTypes.includes(dotSplitFileName[dotSplitFileName.length - 1])
          ) {
            isValid = false;
            return;
          }
        });
      }

      return isValid;
    };

    const onDrop = (acceptedFiles: File[]) => {
      const newItems = acceptedFiles.map(file => ({
        name: file.name,
        file: file,
        type: file.name.split('.')[1],
      }));
      if (isCorrectFileType(newItems)) {
        setItems(prevItems => [...(multiple ? prevItems : []), ...newItems]);
        setError(false);
      } else {
        setError(true);
      }
    };

    const onRemove = (index: number) => {
      setItems(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    };

    const onRename = (index: number, newName: string) => {
      setItems(prevItems => {
        const items = [...prevItems];
        items[index].name = newName;
        return items;
      });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple,
      maxFiles: multiple ? (maxFiles ? maxFiles : 0) : 1,
    });

    return (
      <>
        {((multiple && !maxFiles) ||
          (multiple && maxFiles && items.length < maxFiles) ||
          (!multiple && items.length === 0)) && (
          <Box
            {...getRootProps()}
            className={
              error || showError ? classes.errorDragArea : classes.dragArea
            }
          >
            <input {...getInputProps()} />
            <CloudUpload className={classes.icon} />
            {isDragActive ? (
              <p>Drop the file(s) here...</p>
            ) : (
              <>
                <p>Drop files here to upload</p>
                <p>or click here</p>
              </>
            )}
          </Box>
        )}
        {error && (
          <Typography variant="caption" className={classes.errorMeasage}>
            * The file you are trying to upload does not have the expected file
            type
          </Typography>
        )}
        <Box>
          {items.map((item, i) => (
            <FileUploadItem
              key={item.file.name}
              name={item.name}
              file={item.file}
              disabled={item.disabled}
              onSaveName={newName => onRename(i, newName)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </Box>
      </>
    );
  }
);

const useItemStyles = makeStyles(theme => ({
  button: {
    minWidth: '10vw',
  },
  top: {
    marginBottom: 10,
  },
  icon: {
    color: Color.WARNING,
    minWidth: '20px',
    minHeight: '20px',
  },
  fileContainer: {
    backgroundColor: Color.WARNING_BACKGROUND,
    color: Color.WARNING_TEXT,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    borderRadius: 5,
  },
  fileName: {
    marginLeft: theme.spacing(1),
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  removeButton: {
    minWidth: 0,
    color: 'inherit',
  },
}));

const FileUploadItem = props => {
  const classes = useItemStyles();

  return (
    <Box className={classes.fileContainer}>
      <Description className={classes.icon} />
      <div className={classes.fileName}>{props.name}</div>
      <Button onClick={props.onRemove} className={classes.removeButton}>
        <Delete />
      </Button>
    </Box>
  );
};
