import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import HouseIcon from "@material-ui/icons/House";
import { FieldSetBox } from "../fieldSetBox";
import { ReportField } from "./reportField";
import { StatementOfLimitingConditions } from "./stateOfLimitingConditions";
import { CompMap, MapLegend } from "../mapComponents";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { formatCurrencyField, getStringOrDefault } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  page: {
    fontFamily: "Roboto, Arial, sans-serif",
    border: "1px solid lightgray",
    borderRadius: "5px",
    width: "1018px",
    height: "1440px",
    padding: "64px 40px 64px 40px",
  },
  hiddenStyle: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    height: "450px",
    width: "100%",
  },
  mapContainer: {
    height: "450px",
    width: "100%",
  },
  longTextTypography: {
    fontSize: "14px",
    padding: "16px",
    paddingTop: "10px",
    fontWeight: 400,
  },
}));

interface Page3Props {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const Page3 = ({ internalValuationReviewStore }: Page3Props) => {
  const classes = useStyles();
  const [reloadMap, setReloadMap] = useState(true);

  const markers = internalValuationReviewStore.getPropertyCompsMarkers();

  const valuationValues: any = internalValuationReviewStore.valuationFormStore.getFlattenedValues();

  useEffect(() => {
    const moveMapToRenderIt = async () => {
      setReloadMap(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setReloadMap(false);
    };
    moveMapToRenderIt();
  }, []);
  return (
    <div className={classes.page}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FieldSetBox title="Comparable">
            <Box
              className={reloadMap ? classes.hiddenStyle : classes.mapContainer}
            >
              <CompMap
                markers={markers}
              />
            </Box>
          </FieldSetBox>
        </Grid>
        <Grid item>
          <FieldSetBox title="Legend">
            <MapLegend markers={markers} />
          </FieldSetBox>
        </Grid>
        <Grid item>
          <FieldSetBox title="Value Reconciliation">
            <Grid container>
              <Grid item xs={4}>
                <ReportField label="Borrower Value" value={formatCurrencyField(valuationValues.borrowerValue)} />
              </Grid>
              <Grid item xs={4}>
                <ReportField
                  label="As-Repaired Value"
                  value={formatCurrencyField(valuationValues.asRepairedValue)}
                />
              </Grid>
              <Grid item xs={4}>
                <ReportField
                  label="As-Is Value"
                  value={formatCurrencyField(valuationValues.asIsValue)}
                  removeRightBorder
                />
              </Grid>
              <Grid item xs={12}>
                <ReportField
                  label="Value Reconciliation Comments"
                  removeRightBorder
                >
                  <Typography className={classes.longTextTypography}>
                    {getStringOrDefault(valuationValues.valueReconciliationComments)}
                  </Typography>
                </ReportField>
              </Grid>
            </Grid>
          </FieldSetBox>
        </Grid>
        <Grid item xs={12}>
          <StatementOfLimitingConditions />
        </Grid>
      </Grid>
    </div>
  );
};
const rows = [
  {
    id: 1,
    lat: 40.7128,
    lng: -74.0062,
    color: "#49EAF2",
    textColor: "#1C3F78",
    content: <HouseIcon />,
    mapTitle: "Subject",
    description: "Subject Property",
    address: "7838 Anthony Ln; Plano TX 75024",
    proximity: "-",
  },
  {
    id: 2,
    lat: 40.7125,
    lng: -74.006,
    color: "#A7A6AB",
    textColor: "#1C3F78",
    content: 1,
    mapTitle: "C1",
    description: "Comparable Property #1",
    address: "7838 Anthony Ln; Plano TX 75024",
    proximity: "0.2 Miles Away",
  },
  {
    id: 3,
    lat: 40.7126,
    lng: -74.005,
    color: "#A7A6AB",
    textColor: "#1C3F78",
    content: 2,
    mapTitle: "C2",
    description: "Comparable Property #2",
    address: "7838 Anthony Ln; Plano TX 75024",
    proximity: "0.7 Miles Away",
  },
  {
    id: 4,
    lat: 40.712,
    lng: -74.005,
    color: "#A7A6AB",
    textColor: "#1C3F78",
    content: 3,
    mapTitle: "C3",
    description: "Comparable Property #3",
    address: "7838 Anthony Ln; Plano TX 75024",
    proximity: "0.4 Miles Away",
  },
  {
    id: 5,
    lat: 40.712,
    lng: -74.00613,
    color: "lightgray",
    textColor: "#1C3F78",
    content: 0,
    mapTitle: "",
    description: "Other Comparable Properties",
    address: "-",
    proximity: "-",
  },
];