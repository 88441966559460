export interface MailingListSubscription {
  email: string;
  loanStatus: boolean;
  dailyReport: boolean;
  extensions: boolean;
  participationAgreement: boolean;
  wireConfirm: boolean;
}

export interface LenderFees {
  servicingSetupFees: number;
  servicingFees: number;
  valuationReviewFees: number;
  legalReviewFees: number;
  processingFees: number;
  underWritingFees: number;
  adminFees: number;
  wireFees: number;
  commitmentFees: number;
  appraisal: number;
  custodianFees: number;
  constructionDrawFees: number;
  defaultTermPoints?: number;
}

//export interface FixAndFlipPricerLink {
//fixAndFlipPricerLink: string;
//}

export interface LenderSubscriptionDetails {
  subscribeToUnderwritingEmails: boolean;
  subscribeToInsuranceEmails: boolean;
  subscribeToClosingEmails: boolean;
  subscribeToPendingDocumentsEmails: boolean;
  notifyInsuranceAgent: boolean;
  notifyBorrowerClosingAgent: boolean;
  borrowersParticipateInHomeDepotProgram: boolean;
  sendDrawBoardedNotification: boolean;
  fullEmailSynchronization: boolean;
  loanStatusMailingList: string;
  dailyReportMailingList: string;
  extensionsMailingList: string;
  wireConfirmMailingList: string;
}


export interface NewLender {
  lenderId: number;
  companyId: number;
  lenderName: string;
  adminFee: number;
  appraisal: number;
  automaticBorrowerPortalInvite: string | null;
  borrowerPortalEnabled: boolean;
  commitmentFee: number;
  company: {
    companyId: number;
    name: string;
    phone: string;
    email: string;
    address: string;
    aptNumber: string | null;
    contacts: { fullName: string | null }[];
    city: string;
    state: string;
    streetNumber: string;
    streetName: string;
    zipCode: string;
    secondaryPhone: string;
    einNumber: string | null;
    additionalPostClosingEmailAddresses: string;
    entityType: string;
    operatingAgreement: string | null;
    operatingAgreementDate: string | null;
    latitude: number | null;
    longitude: number | null;
  };
  lenderTradeName: string;
  constructionDrawFee: number;
  custodianFee: number;
  dailyReportEmails: string;
  extensions: string;
  isInternal: boolean;
  legalReviewFee: number;
  loanStatusEmails: string;
  marketProducts: boolean;
  notifyBorrowerClosingAgent: boolean;
  notifyInsuranceAgent: boolean;
  operatingState: string;
  participationAgreementEmails: string;
  processingFee: number;
  products: string;
  rocAddressAtClosing: boolean;
  sendDrawNotification: boolean;
  borrowersParticipateHomeDepotProgram: boolean;
  servicingFee: number;
  servicingSetupFee: number;
  sfLenderId: string;
  status: string;
  taxId: string;
  underwritingFee: number;
  valuationReviewFee: number;
  wireConfirmEmails: string;
  wireFee: number;
  website: string;
  pledgeOfShares: boolean;
  inherited: boolean;
  nmlsId: string;
  companyDba: string;
  fundingDba: string;
  linkedinCompanyPage: string;
  primaryRelationshipManagerEmail: string;
  secondaryRelationshipManagerEmail: string;
  primaryRelationshipManagerName: string;
  secondaryRelationshipManagerName: string;
  primaryCommission: number;
  secondaryCommission: number;
  createdDate: string;
  lastUpdatedDate: string;
  preferredInsuranceReviewer: string;
  defaultTermPoints?: number;
  tamariskAppraisal: boolean;
  tamariskDefaultOption: boolean;
  tamariskSendInvoiceEmail: boolean;
  highValueClient:boolean;
}

export interface GroupEmailSubscription {
  companyId?: number;
  underwriting?: boolean;
  closing?: boolean;
  insurance?: boolean;
  pendingDocsEmail?: boolean;
  borrowersParticipateInHomeDepotProgram?: boolean;
  sendDrawBoardedNotification?: boolean;
}

export interface UserNotificationInfo {
  userUnreadNotificationId?: number;
  userId?: number;
  notificationEightAm?: boolean;
  notificationOnePm?: boolean;
  notificationSixPm?: boolean;
  emailSyncEnabled?: boolean;
  currentNotification?: boolean;
}

export interface LenderContact {
  Id: string;
  AccountId: string;
  RecordTypeId: string;
  Name: string;
  FirstName: string;
  MiddleName?: null;
  LastName: string;
  Email: string;
  Phone: string;
  Lender_Id__c: string;
  Lender_Principal_Id__c: string;
  Job_Function__c: string;
  Role__c: string;
  Ownership__c: number;
}

export interface LenderDetail {
  lenderId: number;
  lenderName: string;
  alias: string;
  primaryRelationshipManagerName: string;
  primaryCommission: number;
  secondaryRelationshipManagerName: string;
  secondaryCommission: number;
}

export enum ReasonToRemoveLeadFromQueue {
  CONTACTED = 'Contacted',
  LEAD_NOT_INTERESTED = 'Lead Not Interested',
  UNABLE_TO_CONTACT = 'Unable to Contact',
  CONFLICT_RESOLVED = 'Conflict resolved',
}