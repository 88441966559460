import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import {
  ItemDisplay,
  LeverageCard,
  LeverageCardRow,
  PercentagePicker,
} from './leverageComponents';
import { LeverageCardProps } from '../leverageStep';

export const FixFlipLeverageCard = observer(
  ({ store, disabled, instaQuote = false, showEmptyValues = false, LeverageMatrixCard, quoteTypeStore }: LeverageCardProps) => {
    const calculated = store.calculatedFields;
    const ranges = store.ranges;

    return (
      <LeverageCard
        title={
          instaQuote ? 'Insta-Leverage Quote' : 'Request Leverage'
        }
        disabled={disabled}
        instaQuote={instaQuote}
        LeverageMatrixCard={LeverageMatrixCard}
        quoteTypeStore={quoteTypeStore}
        disabledWarning={showEmptyValues}
      >
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label={instaQuote ? 'Initial Loan Amount' : 'Initial Advance'}
              value={showEmptyValues ? '-' : formatCurrency(calculated.initialAdvance)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label={quoteTypeStore.isMidConstructionRefinance() ? "Cost Spent to Date" : 'Purchase Price'}
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalPurchasePrice)}
            />
          }
          result={
            <PercentagePicker
              fieldName={'loanToCostPercentage'}
              label="Loan to Cost"
              disabled={disabled}
              instaQuote={instaQuote}
              value={showEmptyValues ? 0 : store.form.fields.loanToCost.value}
              min={ranges?.minLoanToCost}
              max={ranges?.maxLoanToCost}
              onChange={value => store.onFieldChange('loanToCost', value)}
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Construction Holdback"
              value={showEmptyValues ? '-' : formatCurrency(calculated.constructionHoldback)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="Proposed Construction Budget"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalProposedConstructionBudget)}
            />
          }
          result={
            <PercentagePicker
              label="Construction Reserve"
              fieldName="constructionReserve"
              disabled={disabled}
              instaQuote={instaQuote}
              value={showEmptyValues ? 0 : store.form.fields.constructionReserve.value}
              min={ranges?.minConstructionReserve}
              max={ranges?.maxConstructionReserve}
              onChange={value =>
                store.onFieldChange('constructionReserve', value)
              }
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Total Loan Amount"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalLoanAmount)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="After Repair Value"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalAfterRepairValue)}
            />
          }
          result={
            <ItemDisplay
              label="ARLTV"
              value={showEmptyValues ? '-' : formatPercentage(calculated.afterRepairLTV)}
              instaQuote={instaQuote}
            />
          }
        />
        <LeverageCardRow
          dividend={
            <ItemDisplay
              label="Total Loan Amount"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalLoanAmount)}
              instaQuote={instaQuote}
            />
          }
          divisor={
            <ItemDisplay
              label="Cost Basis"
              value={showEmptyValues ? '-' : formatCurrency(calculated.totalPurchasePrice + calculated.totalProposedConstructionBudget)}
            />
          }
          result={
            <ItemDisplay
              label="LTFC"
              value={showEmptyValues ? '-' : formatPercentage(calculated.ltfc)}
              instaQuote={instaQuote}
            />
          }
        />
      </LeverageCard>
    );
  }
);
