import { Box, Snackbar, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useBaseStore } from "./hooks";
import { isElectronApp, isMobileApp } from "@roc/feature-utils";
import { StandardDialog } from "@roc/ui";
import { Warning as WarningIcon } from '@material-ui/icons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      display: 'flex'
    },
    label: {
      marginLeft: '8px',
    },
    warningIcon: {
      color: '#FB836F',
      fontSize: '42px',
    },
  }),
);

export const MaintenanceWindowWorker = observer(() => {

  const classes = useStyles();
  const { workerStore, globalStore } = useBaseStore();
  const { showMaintenanceWindow } = workerStore;

  useEffect(() => {
    if (window.Worker && !isMobileApp() && !isElectronApp()) {
      const myWorker = new Worker("worker.js");
      console.log('Main: WebWorker Initialized');

      myWorker.onmessage = function (event) {
        if (event.data.eventType === 'MAINTENANCE_WINDOW_OPEN') {
          workerStore.setShowMaintenanceWindow(event.data.maintenanceWindowOpen);
        }
      }

      if (!isMobileApp() && !isElectronApp()) {
        console.log('Main: Configure EventType: MAINTENANCE_WINDOW_CHECK');
        myWorker.postMessage({
          eventType: 'MAINTENANCE_WINDOW_CHECK',
        });
      }

      return () => {
        myWorker.terminate();
      }

    }
    else {
      console.log('Main: Your browser doesn\'t support web workers.');
    }
  }, []);

  const renderContent = () => {
    return (
      <>Sorry for the inconvenience but we're performing some maintenance at the moment. We'll be back online shortly!</>
    );
  }

  return (
    <StandardDialog
      open={showMaintenanceWindow}
      dialogContent={renderContent()}
      title={(
        <Box className={classes.titleWrapper}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="h4" className={classes.label} >We'll be back soon!</Typography>
        </Box>
      )}
      dialogActions={[]}
    />
  );
});
