import { ApiResponse, GlobalStore, UserService } from '@roc/feature-app-core';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { AppraisalCdaService } from '../services/appraisalCdaService';


export class InternalAppraisalCdaStore {
  globalStore: GlobalStore;
  loanDetails: any = undefined;
  document: any = undefined;
  appraisalCdaService: AppraisalCdaService;

  constructor(globalStore: GlobalStore) {
    this.appraisalCdaService = new AppraisalCdaService();
    this.globalStore = globalStore;
    makeObservable(this, {
      loanDetails: observable,
      document: observable,
      refreshLoanDetails: flow,
      fetchLoanDetails: flow,
    });
  }

  *refreshLoanDetails(loanId: number, loanTaskId: number) {
    yield this.fetchLoanDetails(loanId, loanTaskId);
  }

  *fetchLoanDetails(loanId, loanTaskId, successCallback = () => { }) {
    try {
      const response: ApiResponse = yield this.appraisalCdaService.getLoanDetails(
        loanId
      ).then(response => {
        this.loanDetails = response?.data;
      });

      if (this.loanDetails?.loanBorrowerInformation?.loanBorrowers?.rows) {
        this.loanDetails.borrowers = this.processBorrowers(
          this.loanDetails.loanBorrowerInformation.loanBorrowers.rows
        );
      }

      const documentResponse: ApiResponse = yield this.appraisalCdaService.getDocumentDetails(
        loanTaskId
      ).then(response => {
        this.document = response?.data;
      });

      successCallback();
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan details.',
      });
    }
  }

  processBorrowers(borrowers) {
    return borrowers.map(borrower => {
      const roles = borrower.roleInTransaction
        ? borrower.roleInTransaction.split(',')
        : borrower.roleInTransaction;

      return {
        ...borrower,
        roleInTransaction:
          roles &&
          roles.map(role => {
            return { label: role };
          }),
      };
    });
  }
}