import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { EntityFormStore } from './entityFormStore';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE,
  parseAddress,
} from '@roc/feature-utils';

export class EntityStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  public entityFormStore: EntityFormStore;
  public entityDropdownData: any[];
  public borrowers: any[];
  public borrowersFormsStoreIds: string[];
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.oneToolService = new OneToolService();
    this.entityFormStore = new EntityFormStore(globalStore);
    this.entityDropdownData = [];
    this.borrowers = [];
    this.borrowersFormsStoreIds = [];
    makeObservable(this, {
      reset: action,
      entityDropdownData: observable,
      borrowers: observable,
      getEntityDropdownData: flow,
      fetchEntityById: flow,
      borrowersFormsStoreIds: observable,
      loadEntity: action,
      getEntity: action,
    });
  }

  *getEntityDropdownData() {
    try {
      const response = yield this.oneToolService.getEntityDropdownData();
      this.entityDropdownData = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchEntityById(entityId: number, onCallback?) {
    try {
      const response = yield this.oneToolService.getEntityById(entityId);
      const entity = response?.data?.data;
      const parsedAddress = parseAddress(entity?.entityAddress, undefined);

      this.loadEntity({
        ...entity,
        address: {
          ...parsedAddress,
          address: (parsedAddress.street_number || '') + `${parsedAddress.street ? ' ' + parsedAddress.street : ''}`,
        }
      });

      this.borrowers = Array.from(new Set(response.data.data.borrowers));
      onCallback();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  loadEntity(entity) {
    this.entityFormStore.loadForm({
      entityId: entity?.entityId ?? '',
      entityName: entity?.entityName ?? '',
      entityEin: entity?.entityEin ?? '',
      entityType: entity?.entityType ?? '',
      dateOfIncorporation: entity?.dateOfIncorporation ?? null,
      address: entity?.address ?? null,
      hasEntity: entity?.hasEntity ?? true,
    });
  }

  reset() {
    this.entityFormStore.reset();
  }

  setHasEntity(value) {
    this.entityFormStore.reset();
    this.entityFormStore.onFieldChange('hasEntity', value);
  }

  getEntity() {
    const values = this.entityFormStore.getFormValues();
    return {
      ...values,
      dateOfIncorporation: formatDate(
        values.dateOfIncorporation,
        DateFormat.MMDDYYYY
      ),
    };
  }

  validateEntity() {
    if (this.entityFormStore.form.fields.hasEntity.value) {
      this.entityFormStore.runFormValidationWithMessage();
      return this.entityFormStore.form.meta.isValid;
    } else {
      return true;
    }
  }
}
