import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout, useMiscellaneousRoutes } from '@roc/feature-app-core';
import { routeByLoanSubType } from '@roc/feature-loans';
import { isNil } from '@roc/feature-utils';
import { StepObj } from '@roc/ui';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { ReactNode, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useOneToolRoutes } from '../hooks/useOneToolRoutes';
import { QuoteTypeStore } from '../stores/quoteTypeStore';
import { QuoteStep, quoteSteps } from '../utils/constants';
import { OneToolQuoteStepsContainer } from './oneToolQuoteStepsContainer';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5, 7.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.5),
    },
  },
}));

interface Props {
  store: QuoteTypeStore;
  title: string;
  children: Array<JSX.Element>;
  preflightStep: any;
  successStep: any;
}

interface Step extends StepObj {
  info?: string;
  size?: number;
}

export const OneToolQuoteStepper = observer(
  ({ store, title, children, preflightStep, successStep }: Props) => {
    const classes = useStyles();
    const { quoteId } = useParams<{ quoteId: string }>();
    const { push } = useHistory();
    const { oneToolRoutesConfig } = useOneToolRoutes();
    const lastCompletedStepIndex = quoteSteps.indexOf(store.lastCompletedStep);

    const { miscellaneousRoutesConfig } = useMiscellaneousRoutes();

    useEffect(() => {
      store.reset();
      if (quoteId) {
        store.loadQuote(parseInt(quoteId));
      } else {
        store.createNewQuote();
      }
    }, []);

    useEffect(() => {
      if (!store.isLoanQuoteDetailsSuccess) {
        push(miscellaneousRoutesConfig.notFound.url);
      }
    }, [store.isLoanQuoteDetailsSuccess]);

    useEffect(() => {
      if (
        !quoteId &&
        store.quoteId &&
        store.step != QuoteStep.BORROWER_INFORMATION
      ) {
        const route =
          oneToolRoutesConfig[routeByLoanSubType[store.loanSubtype]];
        push(route(store.quoteId).url);
      }
    }, [quoteId, store.quoteId]);

    if (isNil(store.step)) {
      return null;
    }

    const quoteData = store.getQuoteData();

    const borrowerInfo =
      lastCompletedStepIndex >=
      quoteSteps.indexOf(QuoteStep.BORROWER_INFORMATION)
        ? quoteData.borrowers
            .map(b => [b.firstName, b.lastName].join(' '))
            .join(', ')
        : '';

    const propertyInfo =
      lastCompletedStepIndex >=
      quoteSteps.indexOf(QuoteStep.PROPERTY_INFORMATION)
        ? quoteData.properties.map((p: any) => p.address).join(', ')
        : '';

    const leverageInfo =
      lastCompletedStepIndex >= quoteSteps.indexOf(QuoteStep.LEVERAGE)
        ? store.loanEconomicsStore.calculateKeyRatios()
        : '';

    const loanEconomicsInfo =
      lastCompletedStepIndex >= quoteSteps.indexOf(QuoteStep.LOAN_ECONOMICS)
        ? formatCurrency(store.loanEconomicsStore.outputs?.lenderTotalEarnings)
        : '';

    const steps: Step[] = [
      {
        title: 'Borrower Information',
        icon: null,
        onNext: () => store.goToStep(QuoteStep.PROPERTY_INFORMATION),
        allowBack: false,
        allowNext: true,
        nextButtonText: 'SAVE & CONTINUE',
        info: borrowerInfo,
        size: 271,
      },
      {
        title: 'Property Information',
        icon: null,
        onBack: () => store.goToStep(QuoteStep.BORROWER_INFORMATION),
        onNext: () => store.goToStep(QuoteStep.LEVERAGE),
        allowBack: true,
        allowNext: true,
        nextButtonText: 'SAVE & CONTINUE',
        info: propertyInfo,
        size: 400,
      },
      {
        title: 'Leverage',
        icon: null,
        onBack: () => store.goToStep(QuoteStep.PROPERTY_INFORMATION),
        onNext: () => store.goToStep(QuoteStep.LOAN_ECONOMICS),
        allowBack: true,
        allowNext: true,
        disableNextButton: !store.loanEconomicsStore.calculatedFields
          .totalLoanAmount,
        nextButtonText: 'SAVE & CONTINUE',
        info: leverageInfo,
        size: 130,
      },
      {
        title: 'Loan Economics',
        icon: null,
        onBack: () => store.goToStep(QuoteStep.LEVERAGE),
        onNext: () => store.goToStep(QuoteStep.SUMMARY),
        allowBack: true,
        allowNext: true,
        nextButtonText: 'SAVE & CONTINUE',
        info: loanEconomicsInfo,
        size: 130,
      },
      {
        title: 'Summary',
        icon: null,

        onBack: () => store.goToStep(QuoteStep.LOAN_ECONOMICS),
        onNext: () => {
          if (store.validateCurrentStep()) {
            store.summaryStore.loanCommentsFormStore.openDialog();
          }
        },
        allowBack: true,
        allowNext: true,
        nextButtonText: store.loanEconomicsStore.enableManualQuote
          ? 'SAVE & CONTINUE'
          : 'ACCEPT QUOTE',
        size: 115,
      },
    ];

    if (store.step === QuoteStep.PREFLIGHT) {
      return preflightStep;
    }
    if (store.step === QuoteStep.SUCCESS) {
      return successStep;
    }

    return (
      <Layout maxWidth={'lg'}>
        <Typography
          align="left"
          variant="h2"
          style={{
            color: '#5E5E5E',
            marginBottom: '38px',
            fontSize: '48px',
          }}
        >
          {title}
        </Typography>
        <Paper className={classes.paper} elevation={0}>
          <OneToolQuoteStepsContainer
            activeStep={quoteSteps.indexOf(store.step)}
            disableNavigation={true}
            steps={steps}
            handleStep={index => store.goToStep(quoteSteps[index])}
          >
            {children}
          </OneToolQuoteStepsContainer>
        </Paper>
      </Layout>
    );
  }
);
