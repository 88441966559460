import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';

import { ApiResponse } from '@roc/feature-app-core';
import { DocumentService } from '../services/documentService';
import {
  Document,
  DocumentSection,
  LoanDocument,
  UnreadDocumentsComments,
} from '@roc/feature-types';
import {
  downloadDocument,
  EXTENSIONS,
  insertIf,
  removeFileExtension,
  PROPOSAL,
  isNil,
  GENERIC_ERROR_MESSAGE,
  TRADES
} from '@roc/feature-utils';
import {
  CLOSING,
  UNDERWRITING,
  DRAWS,
  ELMSURE,
  UNKNOWN,
  SERVICING,
} from '@roc/feature-utils';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentFormStore } from './documentForms/documentFormStore';
import { capitalize } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import {
  isFileAlreadyUploaded,
  isAccepted,
  isAcceptedOrDelivered,
  documentIsUploadAllowedOnly,
  documentUploadNotAllowedOnly,
  documentUploadAllowedForSection,
} from '../utils/documents';
import {
  DocumentStatus,
  DocumentName,
  DocumentSection as DocumentSectionEnum,
  DocumentSectionNames,
  APPRAISAL_ORDER_DETAILS,
  APPRAISAL_DETAILS,
  TRIMERGE_CREDIT_REPORT,
  CREDIT_REPORT,
  restrictedAppraisalDocuments,
} from '../constants';
import { LoanStore } from '@roc/feature-loans';
import { LoanTodoStore } from 'libs/feature-loans-shared/src/stores/loanTodoStore';

const OTHER = 'Other';
const PRESCREEN = 'Prescreen';
const COMMITTEE = 'Committee';

export interface ProcessDocuments {
  [key: string]: Document[];
}
export interface Documents {
  closing: ProcessDocuments;
  underwriting: ProcessDocuments;
  draws: ProcessDocuments;
  servicing: ProcessDocuments;
  Extensions: ProcessDocuments;
  trades: ProcessDocuments;
}

const mappedSections = {
  'BACKGROUND REVIEW': 'Background & Credit Review',
  'CREDIT REVIEW': 'Background & Credit Review',
};

export abstract class DocumentBaseStore {
  abstract getLoanDocuments(loanId: string, loanProcess: string);
  abstract getLoanAcceptedDocuments(loanId: string, loanProcess: string);
  abstract getLoanPendingDocuments(loanId: string, loanProcess: string);
  abstract getLoanDrawDocuments(loanId: string, loanProcess: string);
  abstract getLoanExtensionDocuments(loanId: string, loanProcess: string);
  abstract getPropertyLoanDocument(loanId: number, taskName: string, collateralId: number);

  loanStore: LoanStore;
  globalStore: GlobalStore;
  documentFormStore: DocumentFormStore;
  loanTodosStore: LoanTodoStore;
  protected documentService: DocumentService;
  public status: string;
  documentSections: DocumentSection[] = [];
  underwritingDocuments = {};
  closingDocuments = {};
  drawDocuments = {};
  extensionDocuments = {};
  insuranceExtensionDocumentId: number;
  servicingDocuments = {};
  tradeDocuments = {};
  drawComments: UnreadDocumentsComments = null;
  pendingDocuments = {};
  underwritingUnreadComments: UnreadDocumentsComments = null;
  closingUnreadComments: UnreadDocumentsComments = null;
  servincingUnreadComments: UnreadDocumentsComments = null;
  currentDocument: Document;
  documentHistory: any;
  documentPreview: any = null;
  documentPreviewV2: any = null;
  openDrawRequestModal: boolean = false;
  openSOWRevisionInProgressDialog: boolean = false;
  openSOWRevisionRequestDialog: boolean = false;
  openShareLinkModal: boolean = false;
  openSharePropertyPicturesLinkModal: boolean = false;
  isProposal: boolean = false;
  openDrawReviewModal: boolean = false;
  public openSharePlaidLinkModal: boolean = false;
  public hideRelationShipManager: boolean = false;
  public openEstimateFundingTemplate: boolean = false;

  constructor(loanStore: LoanStore, globalStore: GlobalStore) {
    this.loanStore = loanStore;
    this.globalStore = globalStore;
    this.documentFormStore = new DocumentFormStore(
      this,
      loanStore,
      globalStore
    );
    this.loanTodosStore = new LoanTodoStore(this.globalStore, this);
    this.documentService = new DocumentService();
    makeObservable(this, {
      getLoanDocuments: flow,
      getLoanPendingDocuments: flow,
      getLoanAcceptedDocuments: flow,
      getLoanDrawDocuments: flow,
      getLoanExtensionDocuments: flow,
      fetchSections: flow,
      fetchUnreadComments: action,
      fetchProcessUnreadComments: flow,
      setProcessUnreadComments: action,
      fetchDocuments: action,
      refetchDocuments: action,
      fetchDrawDocuments: flow,
      fetchExtensionDocuments: flow,
      fetchProcessDocuments: flow,
      fetchProcessDocumentsAccepted: flow,
      setProcessDocuments: action,
      reset: action,
      resetDocuments: action,
      resetSections: action,
      resetUnreadComments: action,
      closeDocumentPreview: action,
      closeDocumentPreviewV2: action,
      simulateDelete: action,
      uploadSectionDocuments: flow,
      uploadDocument: flow,
      downloadDocument: flow,
      previewDocument: flow,
      previewDocumentV2: flow,
      fetchDocumentFile: flow,
      downloadLoanTermsDocument: flow,
      downloadDealSummaryDocument: flow,
      downloadStandardDocument: flow,
      downloadDocumentByName: flow,
      downloadRateLockAgreementDocument: flow,
      documentSections: observable,
      sections: computed,
      drawComments: observable,
      underwritingUnreadComments: observable,
      closingUnreadComments: observable,
      unreadComments: computed,
      totalUnreadComments: computed,
      underwritingDocuments: observable,
      closingDocuments: observable,
      drawDocuments: observable,
      extensionDocuments: observable,
      insuranceExtensionDocumentId: observable,
      servicingDocuments: observable,
      tradeDocuments: observable,
      documentPreview: observable,
      documentPreviewV2: observable,
      documents: computed,
      displaySections: computed,
      allowCommunication: computed,
      isCommunicationAllowed: action,
      isUploadAllowed: action,
      showDownloadAll: action,
      setCurrentDocument: action,
      currentDocument: observable,
      getDocumentHistory: flow,
      resetDocumentHistory: action,
      documentHistory: observable,
      downloadHistoryDocument: flow,
      fetchPendingDocuments: flow,
      pendingDocuments: observable,
      uploadSectionPendingDocuments: action,
      uploadPendingDocument: action,
      flattenedDocuments: computed,
      openDrawRequestModal: observable,
      openSOWRevisionInProgressDialog: observable,
      openSOWRevisionRequestDialog: observable,
      openShareLinkModal: observable,
      openSharePropertyPicturesLinkModal: observable,
      onShareLink: action,
      onCancelRequest: action,
      showServicing: computed,
      downloadAllFromSection: flow,
      requestMoreInformation: flow,
      openSharePlaidLinkModal: observable,
      hideRelationShipManager: observable,
      fetchProposalsDocuments: flow,
      updateIsProposal: action,
      openDrawReviewModal: observable,
      openEstimateFundingTemplate: observable,
      setOpenEstimateFundingTemplate: action,
      downloadDocumentOriginalNameById: flow,
      downloadBorrowerPastLoanDocuments: flow,
      uploadDocumentsBatch: flow,
    });
  }

  updateIsProposal(isProposal: boolean) {
    this.isProposal = isProposal
  }

  setOpenEstimateFundingTemplate(openEstimateFundingTemplate: boolean) {
    this.openEstimateFundingTemplate = openEstimateFundingTemplate;
  }

  *fetchSections() {
    try {
      const response: ApiResponse = yield this.documentService.getDocumentSections();
      const { data } = response;
      this.documentSections = data && JSON.parse(data?.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  get sections() {
    return {
      [UNDERWRITING]: this.getProcessSections(UNDERWRITING),
      [CLOSING]: this.getProcessSections(CLOSING),
      [DRAWS]: this.getProcessSections(DRAWS),
      [EXTENSIONS]: this.getProcessSections(EXTENSIONS),
      [SERVICING]: this.getProcessSections(SERVICING),
      [TRADES]: this.getProcessSections(TRADES),
    };
  }

  getProcessSections(loanProcess: string) {
    return this.documentSections.filter(
      section => section.loanProcess.toLowerCase() === loanProcess
    );
  }

  fetchUnreadComments(loanId: string) {
    if (
      !this.allowCommunication ||
      this.loanStore.loanDetails?.twilioCommunicationEnabled
    ) {
      return;
    }
    this.fetchProcessUnreadComments(loanId, UNDERWRITING);
    this.fetchProcessUnreadComments(loanId, CLOSING);
    this.fetchProcessUnreadComments(loanId, DRAWS);
    this.fetchProcessUnreadComments(loanId, SERVICING);
  }

  *fetchProcessUnreadComments(loanId: string, loanProcess: string) {
    const processEntitlements = this.loanStore.getLoanProcessEntitlements(
      loanProcess
    );
    if (!processEntitlements?.isCommunicationAllowed) return;
    try {
      const response: ApiResponse = yield this.documentService.getUnreadDocumentComments(
        loanId,
        loanProcess
      );
      this.setProcessUnreadComments(response.data.data, loanProcess);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting unread comments.',
      });
    }
  }

  fetchUnreadDrawComments(loanId: string, drawId: string) {
    if (!this.allowCommunication) return;
    this.fetchProcessUnreadDrawComments(loanId, drawId, DRAWS);
  }

  *fetchProcessUnreadDrawComments(loanId: string, drawId, loanProcess: string) {
    try {
      const response: ApiResponse = yield this.documentService.getUnreadDrawComments(
        loanId,
        drawId
      );
      this.setProcessUnreadComments(response.data.data, loanProcess);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting unread comments.',
      });
    }
  }

  setProcessUnreadComments(data: any, loanProcess: string) {
    switch (loanProcess) {
      case UNDERWRITING:
        this.underwritingUnreadComments = data;
        break;
      case CLOSING:
        this.closingUnreadComments = data;
        break;
      case DRAWS:
        this.drawComments = data;
        break;
      case SERVICING:
        this.servincingUnreadComments = data;
        break;
      default:
        break;
    }
  }

  get unreadComments() {
    return {
      [UNDERWRITING]: this.underwritingUnreadComments,
      [CLOSING]: this.closingUnreadComments,
      [DRAWS]: this.drawComments,
      [SERVICING]: this.servincingUnreadComments,
    };
  }

  get totalUnreadComments() {
    return [UNDERWRITING, CLOSING, DRAWS, SERVICING]
      .map(process => this.unreadComments[process]?.totalUnread || 0)
      .reduce((p, n) => p + n);
  }

  fetchDocuments(loanId: string) {
    this.fetchProcessDocuments(loanId, UNDERWRITING);
    this.fetchProcessDocuments(loanId, CLOSING);
    this.fetchProcessDocuments(loanId, SERVICING);
  }

  refetchDocuments() {
    const { loanId } = this.loanStore.loanDetails;
    this.fetchProcessDocuments(loanId, UNDERWRITING);
    this.fetchProcessDocuments(loanId, CLOSING);
    this.fetchProcessDocuments(loanId, SERVICING);
  }

  *fetchDrawDocuments(loanId: string, drawId: string) {
    try {
      const response: ApiResponse = yield this.getLoanDrawDocuments(
        loanId,
        drawId
      );
      const { data } = response;
      this.setProcessDocuments(data && JSON.parse(data?.data), DRAWS);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draw documents.',
      });
    }
  }
  *fetchExtensionDocuments(loanId: string, extensionId: string) {
    try {
      const response: ApiResponse = yield this.getLoanExtensionDocuments(
        loanId,
        extensionId
      );
      const { data } = response;
      this.setProcessDocuments(data && JSON.parse(data?.data), EXTENSIONS);
      const obj = JSON.parse(data?.data);
      for (var key in obj) {
        for (var key1 in obj[key]) {
          if (obj[key][key1].taskName === 'Insurance Document') {
            this.insuranceExtensionDocumentId = obj[key][key1].loanTaskId;
          }
        }
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting extension documents.',
      });
    }
  }

  *fetchProposalsDocuments(id?: string) {
    try {
      let loanId = id;
      if (isNil(loanId)) {
        loanId = this.loanStore.loanDetails?.loanId;
      }
      if (!isNil(loanId)) {
        const response: ApiResponse = yield this.getProposalDocuments(
          loanId
        );
        const { data } = response;
        if (data) {
          const documents = JSON.parse(data.data);
          this.setProcessDocuments(documents, UNDERWRITING);
        }
      }
    } catch (err) {
      console.log(err);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting Proposal documents.',
      });
    }
  }

  getProposalDocuments = (loanId) => {
    return this.documentService.getProposalDocuments(loanId);
  }

  *fetchProcessDocuments(loanId: string, loanProcess: string) {
    try {
      const response: ApiResponse = yield this.getLoanDocuments(
        loanId,
        loanProcess
      );
      const { data } = response;
      if (data) {
        const documents = JSON.parse(data.data);
        this.setProcessDocuments(documents, loanProcess);
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting documents.',
      });
    }
  }

  *fetchProcessDocumentsAccepted(loanId: string, loanProcess: string) {
    try {
      const response: ApiResponse = yield this.getLoanAcceptedDocuments(
        loanId,
        loanProcess
      );
      const { data } = response;
      if (data) {
        const documents = JSON.parse(data.data);
        this.setProcessDocuments(documents, loanProcess);
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting documents HERE.',
      });
    }
  }

  setProcessDocuments(data: any, loanProcess: string) {
    switch (loanProcess) {
      case UNDERWRITING:
        this.underwritingDocuments = data;
        break;
      case CLOSING:
        this.closingDocuments = data;
        break;
      case DRAWS:
        this.drawDocuments = data;
        break;
      case EXTENSIONS:
        this.extensionDocuments = data;
        break;
      case SERVICING:
        this.servicingDocuments = data;
        break;
      case TRADES:
        this.tradeDocuments = data;
        break;
      default:
        break;
    }
  }

  get documents(): Documents {
    return {
      [UNDERWRITING]: this.underwritingDocuments,
      [CLOSING]: this.closingDocuments,
      [DRAWS]: this.drawDocuments,
      [EXTENSIONS]: this.extensionDocuments,
      [SERVICING]: this.servicingDocuments,
      [TRADES]: this.tradeDocuments,
    };
  }

  get displaySections() {
    return {
      [UNDERWRITING]: this.getDisplaySectionsByProcess(UNDERWRITING),
      [CLOSING]: [
        this.getOverviewSection(),
        ...this.getDisplaySectionsByProcess(CLOSING),
      ],
      [DRAWS]: this.getDisplaySectionsByProcess(DRAWS),
      [EXTENSIONS]: this.getDisplaySectionsByProcess(EXTENSIONS),
      [SERVICING]: this.getDisplaySectionsByProcess(SERVICING),
      [TRADES]: this.getDisplaySectionsByProcess(TRADES),
    };
  }

  getDisplaySectionsByProcess(loanProcess: string) {
    const sections = this.sections[loanProcess];
    const documents = this.documents[loanProcess];
    const unreadComments = this.unreadComments[loanProcess];

    const displaySections = this.getDisplaySectionsFromDocuments(
      documents,
      unreadComments
    );

    const { isClosingAttorney } = this.globalStore.userFeatures;
    //TODO: implement not visible sections
    let notVisibleSections: string[] = [
      ...insertIf(!isClosingAttorney, [
        DocumentSectionNames.GENERATED_CLOSING_DOCUMENTS,
      ]),
      DocumentSectionNames.BOARDING,
      DocumentSectionNames.SCANNED_CLOSING_DOCS,
      DocumentSectionNames.RECORDED_CLOSING_DOCS,
      DocumentSectionNames.PHYSICAL_ORIGINAL_RECORDED_CLOSING_DOCS,
      DocumentSectionNames.E_FILE_ONLY_RECORDED_CLOSING_DOCS,
      DocumentSectionNames.DELINQUENCY_MANAGEMENT,
      DocumentSectionNames.TRAILING_DOCUMENTS,
    ];

    const newDisplaySections = Object.keys(displaySections).filter(
      displaySection => !notVisibleSections.includes(displaySection)
    );

    return newDisplaySections.map(displaySection => ({
      key: displaySection.toUpperCase(),
      name: displaySection,
      ...displaySections[displaySection],
    }));
  }

  private getDisplaySectionsFromDocuments(documents, unreadComments) {
    return Object.keys(documents).reduce((previousValue, sectionName) => {
      const displaySectionName = this.getDisplaySectionName(sectionName);
      const displaySection = previousValue[displaySectionName] || {};
      const sections = displaySection.sections || [];
      const section = this.getSectionFromDocuments(
        sectionName,
        documents[sectionName],
        unreadComments
      );

      sections.push(section);
      displaySection.pendingDocuments =
        (displaySection.pendingDocuments || 0) + section.pendingDocuments;
      displaySection.unreadComments =
        (displaySection.unreadComments || 0) + section.unreadComments;
      displaySection.sections = sections;
      previousValue[displaySectionName] = displaySection;

      return previousValue;
    }, {});
  }

  private getSectionByName(sections, unreadComments, sectionName) {
    const documentSection = sections.find(
      section => section.sectionName === sectionName
    );
    if (!documentSection) return null;

    const sectionUnreadComments = this.getUnreadComments(
      unreadComments,
      documentSection.sectionId
    );
    const sectionPendingDocuments = 0;

    return {
      pendingDocuments: sectionPendingDocuments,
      unreadComments: sectionUnreadComments,
      sections: [
        {
          ...documentSection,
          pendingDocuments: sectionPendingDocuments,
          unreadComments: sectionUnreadComments,
        },
      ],
    };
  }

  private getOverviewSection() {
    return {
      key: 'overview',
      name: 'Overview',
      pendingDocuments: 0,
      unreadComments: 0,
      sections: [],
    };
  }

  private getDisplaySectionName(name: string) {
    let sectionName = name;
    if (sectionName.includes("Extension Documents -")) {
      sectionName = "Extension Documents";
      return capitalize(sectionName);
    } else {
      const sectionParts = name.split(' - ');
      if (sectionParts.length > 1) {
        sectionName = sectionParts[1];
      }
      if (mappedSections[sectionName.toUpperCase()]) {
        sectionName = mappedSections[sectionName.toUpperCase()];
      }
      return capitalize(sectionName);
    }
  }

  private getSectionFromDocuments(
    sectionName: string,
    documents: Document[],
    unreadComments
  ) {
    const loanId = this.getDocumentProperty('loanId', documents[0]);
    const sectionId = this.getDocumentProperty('sectionId', documents[0]);
    const borrowerId = this.getDocumentProperty('borrowerId', documents[0]);
    const collateralId = this.getDocumentProperty('collateralId', documents[0]);
    const drawId = this.getDocumentProperty('drawId', documents[0]);
    const extensionId = this.getDocumentProperty('extensionId', documents[0]);
    return {
      loanId,
      sectionId,
      sectionName,
      pendingDocuments: this.getPendingDocuments(documents),
      unreadComments: this.getUnreadComments(
        unreadComments,
        sectionId,
        borrowerId,
        collateralId
      ),
      borrowerId,
      collateralId,
      drawId,
      extensionId,
    };
  }

  private getDocumentProperty(property: string, document: Document) {
    return document ? document[property] : null;
  }

  private getPendingDocuments(documents: Document[]) {
    return documents.filter((doc: Document) => doc?.status === 'Pending')
      .length;
  }

  private getUnreadComments(
    unreadComments,
    sectionId,
    borrowerId = null,
    collateralId = null
  ) {
    const unreadCommentsKey = this.getUnreadCommentsKey(
      sectionId,
      borrowerId,
      collateralId
    );
    return unreadComments?.unreadCountBySection[unreadCommentsKey] || 0;
  }

  private getUnreadCommentsKey = (sectionId, borrowerId, collateralId) => {
    let key = `${sectionId}`;
    if (borrowerId) {
      key = `${sectionId} - ${borrowerId}`;
    } else if (collateralId) {
      key = `${sectionId} - ${collateralId}`;
    }
    return key;
  };

  get allowCommunication() {
    return this.globalStore.userFeatures?.allowCommunication;
  }

  isCommunicationAllowed(loanProcess) {
    const processEntitlements = this.loanStore.getLoanProcessEntitlements(
      loanProcess
    );
    return (
      this.allowCommunication && processEntitlements?.isCommunicationAllowed && !this.isProposal
    );
  }

  isUploadAllowed(section, loanProcess) {
    switch (loanProcess) {
      case TRADES: {
        const processEntitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);
        return processEntitlements.isUploadAllowedForSection?.includes(section);
      }
      default:
        return (
          !this.globalStore.userFeatures.isInsuranceReviewer &&
          !this.globalStore.userFeatures.isClosingReviewer &&
          !this.globalStore.userFeatures.isClosingAttorney &&
          (!this.isProposal || (this.isProposal && section === OTHER))
        );
    }
  }

  showDownloadAll(sectionName) {
    return (
      this.globalStore.isInternalPortal ||
      (this.globalStore.userFeatures.isClosingAttorney &&
        sectionName === DocumentSectionNames.GENERATED_CLOSING_DOCUMENTS)
    );
  }

  onOpenForm(document: Document) {
    this.setCurrentDocument(document);
    this.documentFormStore.openDocumentForm(document);
  }

  uploadSectionPendingDocuments(
    fileUploads: FileUpload[],
    loanId: string,
    sectionId: number,
    sectionName: string,
    loanProcess: string
  ) {
    this.uploadSectionDocuments(
      fileUploads,
      loanId,
      sectionId,
      sectionName,
      loanProcess,
      () => this.isProposal ? this.fetchProposalsDocuments(loanId) : this.fetchPendingDocuments(loanProcess, loanId)
    );
  }

  *uploadSectionDocuments(
    fileUploads: FileUpload[],
    loanId: string,
    sectionId: number,
    sectionName: string,
    loanProcess: string,
    onSuccess?: () => void,
    drawId?: string
  ) {
    try {
      const document = this.getDocumentFromName(loanProcess, sectionName);
      const loanDocuments = this.mapFilesToLoanDocs(
        fileUploads,
        loanId,
        sectionId,
        drawId,
        document
      );

      const responses = yield this.uploadDocumentsBatch(fileUploads, loanDocuments);

      if (drawId) {
        this.fetchDrawDocuments(loanId, drawId);
      } else if (this.isProposal) {
        this.fetchProposalsDocuments();
      } else {
        this.fetchProcessDocuments(loanId, loanProcess);
      }

      if (onSuccess) onSuccess();
      return responses;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading documents.',
      });
    }
  }

  *uploadDocumentsBatch(
    fileUploads: FileUpload[],
    loanDocuments: LoanDocument[]
  ) {
    const addDocumentResponse: ApiResponse = yield this.documentService.addDocument(
      loanDocuments
    );

    const calls = [];
    const fileUploadsQueue = [...fileUploads];
    while (fileUploadsQueue.length) {
      const fileUploadsChunk = fileUploadsQueue.splice(0, 5);
      const formData = new FormData();
      for (let i = 0; i < fileUploadsChunk.length; i++) {
        const file = fileUploadsChunk[i].file;
        const blob = file.slice(0, file.size, file.type);
        const newFile = new File([blob], fileUploadsChunk[i].name, {
          type: file.type,
        });
        formData.append('file', newFile);
      }
      formData.append('loanDocuments', addDocumentResponse.data.data);
      calls.push(this.documentService.uploadSectionDocument(formData));
    }
    return yield Promise.all(calls);
  }

  uploadPendingDocument(file: File, docRef: Document, loanProcess: string, loanId: string) {
    if (this.isProposal === false) {
      this.uploadDocument(file, docRef, loanProcess, () =>
        this.fetchPendingDocuments(loanProcess, loanId)
      )
    } else {
      this.uploadDocument(file, docRef, loanProcess, () =>
        this.fetchProposalsDocuments(loanId)
      );
    }

  }

  *uploadDocument(
    file: File,
    docRef: Document,
    loanProcess: string,
    onSuccess?: () => void,
    drawId?: string
  ) {
    try {
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], file.name, { type: file.type });
      const formData = new FormData();
      formData.append('file', newFile);
      if (this.isProposal) {
        yield this.documentService.uploadProposalDocument(
          formData,
          docRef.loanTaskId
        );
      } else {
        yield this.documentService.uploadDocument(
          formData,
          docRef.loanTaskId
        );
      }

      if (drawId) {
        this.fetchDrawDocuments(docRef.loanId.toString(), drawId);
      } else if (this.isProposal) {
        this.fetchProposalsDocuments(docRef.loanId.toString());
      }
      else {
        this.fetchProcessDocuments(docRef.loanId.toString(), loanProcess);
      }

      const { taskName } = docRef;
      this.globalStore.notificationStore.showSuccessNotification({
        message: `${taskName} uploaded successfully.`,
      });

      if (!onSuccess) return;
      onSuccess();
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading document.',
      });
    }
  }

  *previewDocument(document: Document) {
    try {
      const response = yield this.fetchDocumentFile(document);
      this.documentPreview = {
        title: document.taskName,
        data: response.data,
        headers: response.headers,
      };
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *previewDocumentV2(document: Document) {
    try {
      const response = yield this.fetchDocumentFile(document);
      this.documentPreviewV2 = {
        title: document.taskName,
        data: response.data,
        headers: response.headers,
      };
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  simulateDelete() {
    this.documentPreviewV2 = null;
  }

  closeDocumentPreview() {
    this.documentPreview = null;
  }

  closeDocumentPreviewV2() {
    this.documentPreviewV2 = null;
  }

  *downloadDocumentOriginalNameById(document: any) {
    try {
      const response = yield this.documentService.downloadDocument(document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *downloadDocument(document: Document) {
    try {
      const response = yield this.fetchDocumentFile(document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *fetchDocumentFile(document: Document) {
    if (
      document.taskName === DocumentName.LOAN_TERMS ||
      document.taskName === DocumentName.TERM_SHEET
    ) {
      return yield this.downloadLoanTermsDocument(document);
    } else if (document.taskName === DocumentName.DEAL_SUMMARY) {
      return yield this.downloadDealSummaryDocument(document);
    } else if (document.taskName === DocumentName.RATE_LOCK_EXTENSION) {
      return yield this.downloadRateLockAgreementDocument(document);
    } else {
      return yield this.downloadStandardDocument(document);
    }
  }

  *downloadLoanTermsDocument(document: Document) {
    return this.documentService.downloadTermSheet(document.loanId);
  }

  *downloadDealSummaryDocument(document: Document) {
    return this.documentService.downloadDealSummary(document.loanId);
  }

  *downloadStandardDocument(document: Document) {
    return this.documentService.downloadDocument(document);
  }

  *downloadDocumentByName(documentData) {
    try {
      const response = yield this.documentService.downloadDocumentByName(documentData);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        response?.data?.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }


  *downloadRateLockAgreementDocument(document: Document) {
    return this.documentService.downloadRateLockAgreement(document.loanId);
  }

  reset() {
    this.resetDocuments();
    this.resetSections();
    this.resetUnreadComments();
  }

  resetDocuments() {
    this.underwritingDocuments = {};
    this.closingDocuments = {};
  }

  resetDrawDocuments() {
    this.drawDocuments = {};
  }

  resetExtensionsDocuments() {
    this.extensionDocuments = {};
  }

  resetDrawComments() {
    this.drawComments = null;
  }

  resetSections() {
    this.documentSections = [];
  }

  resetUnreadComments() {
    this.underwritingUnreadComments = null;
    this.closingUnreadComments = null;
    this.drawComments = null;
  }

  getDocumentFromName(loanProcess: string, sectionName: string) {
    return this.documents[loanProcess][sectionName]?.find(section =>
      sectionName.includes(section.sectionName)
    );
  }

  mapFilesToLoanDocs(
    fileUploads: FileUpload[],
    loanId: string,
    sectionId: number,
    drawId: string,
    document: Document
  ) {
    return fileUploads.map(fileUpload => ({
      borrowerId: document?.borrowerId || null,
      collateralId: document?.collateralId || null,
      customDropboxPath: null,
      taskName: removeFileExtension(fileUpload.name),
      isMovedToDropbox: false,
      loanTaskId: null,
      loanId: document?.loanId || parseInt(loanId),
      requiredForLoanApproval: false,
      sectionId: document?.sectionId || sectionId,
      drawId: document?.drawId || (drawId !== null ? parseInt(drawId) : null),
    }));
  }

  setCurrentDocument(document: Document) {
    this.currentDocument = document;
  }

  *getDocumentHistory(document: Document) {
    try {
      const response: ApiResponse = yield this.documentService.getDocumentHistory(
        document.loanTaskId
      );

      const { data } = response;
      this.setDocumentHistory(data?.data);
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error loading file history.',
      });
    }
  }

  setDocumentHistory(data: any) {
    this.documentHistory = data;
  }

  resetDocumentHistory() {
    this.documentHistory = [];
  }

  *downloadHistoryDocument(document: any) {
    try {
      const response = yield this.documentService.downloadDocumentHistory(
        document
      );
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *fetchPendingDocuments(loanProcess: string, loanId: string) {
    try {
      const response: ApiResponse = yield this.getLoanPendingDocuments(
        loanId,
        loanProcess
      );
      const { data } = response;
      if (data) {
        const documents = JSON.parse(data.data);
        this.pendingDocuments = documents;
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting pending documents.',
      });
    }
  }

  get flattenedDocuments() {
    return {
      [UNDERWRITING]: this.getFlattenedDocuments(this.underwritingDocuments),
      [CLOSING]: this.getFlattenedDocuments(this.closingDocuments),
    };
  }

  private getFlattenedDocuments(documents: ProcessDocuments) {
    const flattenedDocuments = Object.values(documents).reduce(
      (total: Document[], current: Document[]) => {
        return [...total, ...current];
      },
      []
    );
    return flattenedDocuments;
  }

  formatDocument(document: Document, loanProcess: string): Document {
    return {
      ...document,
      ...this.getFormDocumentValues(document, loanProcess),
      ...this.getFileDocumentValues(document, loanProcess),
      documentNameSecondLine: this.getDocumentNameSecondLine(document),
      canRequestMoreInformation: this.getCanRequestMoreInfo(document)
    };
  }

  get showServicing(): boolean {
    const loanDetails = this.loanStore.loanDetails;
    const servicingSections = this.displaySections[SERVICING];

    return (
      servicingSections?.length > 0 ||
      loanDetails?.showExtensions ||
      loanDetails?.showPayoffs
    );
  }

  private getDocumentNameSecondLine(document: Document): string {
    if (DocumentName.TITLE_AGENT === document.taskName) {
      return this.loanStore?.loanDetails?.titleCompany?.name ?? UNKNOWN;
    }

    if (DocumentName.INSURANCE_PROVIDER === document.taskName) {
      return this.loanStore?.loanDetails?.loanInsurance?.insuredThroughElmsure
        ? ELMSURE
        : this.loanStore?.loanDetails?.loanInsurance?.selectedCompany?.company?.name ?? UNKNOWN;
    }

    if (DocumentName.BORROWER_CLOSING_AGENT === document.taskName) {
      return this.loanStore?.loanDetails?.loanClosingData?.borrowerClosingAgencyBO?.name ?? UNKNOWN;
    }

    return null;
  }

  private getFormDocumentValues(document: Document, loanProcess: string) {
    const isForm = this.documentFormStore.isFormDocument(document);
    const canOpenForm = this.documentFormStore.canOpenForm(
      document,
      loanProcess
    );
    return {
      isForm,
      canOpenForm,
    };
  }

  private getCanRequestMoreInfo(document: Document) {
    if (document.taskName === DocumentName.SCOPE_OF_WORK && document.status !== DocumentStatus.ACCEPTED && document.status !== DocumentStatus.MORE_INFO_NEEDED) {
      return true;
    } else {
      return false;
    }
  }

  private getFileDocumentValues(document: Document, loanProcess: string) {
    return {
      canUploadFile: this.canUploadFile(document, loanProcess),
      canDownloadFile: this.canDownloadFile(document),
    };
  }

  private canUploadFile(document: Document, loanProcess: string) {
    const isForm = this.documentFormStore.isFormDocument(document);
    if (isForm) return false;

    const isAppraisalDocumentUploadAllowed = this.isAppraisalDocumentUploadAllowed(document);

    const entitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);

    const isUploadAllowed =
      !!document &&
      (entitlements?.isUploadAllowed ||
        this.documentIsUploadAllowedOnly(document, loanProcess) ||
        this.documentUploadAllowedForSection(document, loanProcess)
      );

    const isUploadNotAllowed = this.documentUploadNotAllowedOnly(
      document,
      loanProcess
    );

    const documentMeetsUploadRules = this.doesDocumentMeetsUploadRules(
      document
    );

    return isUploadAllowed &&
      !isUploadNotAllowed &&
      documentMeetsUploadRules &&
      isAppraisalDocumentUploadAllowed;
  }

  private doesDocumentMeetsUploadRules(document: Document) {
    const documentIsAcceptedOrDelivered = isAcceptedOrDelivered(document);
    if (document.sectionId === DocumentSectionEnum.INSURANCE) {
      return (
        !documentIsAcceptedOrDelivered && !this.isInsuranceApprovalAccepted()
      );
    } else {
      return !documentIsAcceptedOrDelivered;
    }
  }

  private canDownloadFile(document: Document) {
    if (
      document.taskName === DocumentName.DEAL_SUMMARY ||
      document.taskName === DocumentName.LOAN_TERMS
    ) {
      return isAccepted(document);
    } else {
      return isFileAlreadyUploaded(document);
    }
  }

  private isInsuranceApprovalAccepted() {
    const underwritingDocuments = this.flattenedDocuments[CLOSING];
    const insuranceApprovalDocument = underwritingDocuments.find(
      document => document.taskName === DocumentName.INSURANCE_APPROVAL
    );
    return isAccepted(insuranceApprovalDocument);
  }

  isAppraisalDocumentUploadAllowed(document: Document) {
    const tamariskAppraisal = this.globalStore.userFeatures?.tamariskAppraisal;
    const includesDocument = restrictedAppraisalDocuments.includes(document.taskName);
    return (tamariskAppraisal && includesDocument ? false : true);
  }

  private documentIsUploadAllowedOnly(document: Document, loanProcess: string) {
    const entitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);
    return documentIsUploadAllowedOnly(
      entitlements?.isUploadAllowedOnly,
      document
    );
  }

  private documentUploadNotAllowedOnly(
    document: Document,
    loanProcess: string
  ) {
    const entitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);
    return documentUploadNotAllowedOnly(
      entitlements?.isUploadNotAllowedOnly,
      document
    );
  }

  private documentUploadAllowedForSection(document: Document, loanProcess: string) {
    const entitlements = this.loanStore.getLoanProcessEntitlements(loanProcess);
    return documentUploadAllowedForSection(entitlements?.isUploadAllowedForSection, document);
  }

  onShareLink(document) {
    switch (document.taskName) {
      case DocumentName.DRAW_REQUEST_FORM:
        this.openShareLinkModal = true;
        break;
      case DocumentName.PROPERTY_PHOTOS:
        this.openSharePropertyPicturesLinkModal = true;
        break;
      case DocumentName.PLAID_BANKS:
        this.openSharePlaidLinkModal = true;
        break;
    }
  }

  onCancelRequest(document) {
    console.log('Get Link', document.taskName);
    // TODO: ADD CANCEL REQUEST API CALL
  }

  *downloadAllFromSection(section) {
    const { loanId } = this.loanStore.loanDetails;
    const response = yield this.documentService.downloadAllFromSection(
      loanId,
      section.sectionId
    );
    downloadDocument(response?.data, response?.headers, 'download');
  }

  *requestMoreInformation(document) {
    try {
      const response = yield this.documentService.updateDocument({
        ...document,
        status: DocumentStatus.MORE_INFO_NEEDED,
      });
      yield this.loanStore.refreshLoanDetails();
      yield this.refetchDocuments();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Document updated successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error making the request.',
      });
    }
  }

  *downloadBorrowerPastLoanDocuments(document: Document) {
    try {
      const response = yield this.documentService.downloadBorrowerPastLoanDocuments(document?.borrowerId);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error while downloading Borrower Past Loan Documents.',
      });
    }
  }

  getDisplayDocumentName(title: string) {
    if (this.globalStore?.userFeatures?.isBorrower && title === APPRAISAL_DETAILS) {
      return APPRAISAL_ORDER_DETAILS;
    }
    if (title === TRIMERGE_CREDIT_REPORT) {
      return CREDIT_REPORT;
    }
    return title;
  }

}

export class DocumentStore extends DocumentBaseStore {
  constructor(loanStore: LoanStore, globalStore: GlobalStore) {
    super(loanStore, globalStore);
    makeObservable(this, {
      getLoanDocuments: override,
      getLoanAcceptedDocuments: override,
      getLoanPendingDocuments: override,
      getLoanDrawDocuments: override,
      getLoanExtensionDocuments: override,
    });
  }

  *getLoanDocuments(loanId: string, loanProcess: string) {
    return this.documentService.getDocuments(loanId, loanProcess);
  }

  *getLoanAcceptedDocuments(loanId: string, loanProcess: string) {
    return this.documentService.getAcceptedDocuments(loanId, loanProcess)
  }

  *getLoanPendingDocuments(loanId: string, loanProcess: string) {
    return this.documentService.getPendingDocuments(loanId, loanProcess);
  }

  *getLoanDrawDocuments(loanId: string, drawId: string) {
    return this.documentService.getDrawDocuments(loanId, drawId);
  }

  *getLoanExtensionDocuments(loanId: string, extensionId: string) {
    return this.documentService.getExtensionDocuments(loanId, extensionId);
  }

  async getPropertyLoanDocument(loanId: number, taskName: string, collateralId: number) {
    try {
      const response = await this.documentService.getPropertyDocument(loanId, taskName, collateralId);

      if (response?.data?.status === 'OK' && response?.data?.data) {
        return await JSON.parse(response?.data?.data);
      }
      return null;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error fetching the Property Document.',
      });
    }
  }
}
