import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { action, flow, makeObservable, observable } from 'mobx';
import { BankStatementService } from '../../../services/documentForms/bankStatement/bankStatementService';
import { formatDate, getDocumentAsBlob } from '@roc/feature-utils';

const PERSONAL_NAME = 'Personal Name';
const BORROWING_ENTITY = 'Borrowing Entity';
export const OTHER = 'Other';

const bankStatementForm = {
  fields: {
    asOfDate: {
      type: 'DATE',
      value: null,
      error: null,
      rule: 'required',
      format: 'MM/dd/yyyy',
      message: 'This field is required.',
    },
    accountHolder: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    amount: {
      value: '',
      error: null,
      rule: '',
      message: '',
    },
    otherAccountHolderName: {
      value: '',
      error: null,
      rule: [{ required_if: ['accountHolder', OTHER] }],
      message: 'Please specify Other account holder.',
    },
    lastFourDigits: {
      value: '',
      error: null,
      rule: '',
      message: 'This field must be 4 digits.',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BankStatementFormStore extends FormStore {

  bankStatementFile: File;
  document: Document;
  holderList: Array<any>;
  bankInfo: any;

  globalStore: GlobalStore;
  bankStatementService: BankStatementService;

  constructor(globalStore) {
    super({ ...bankStatementForm }, globalStore);

    this.globalStore = globalStore;
    this.bankStatementService = new BankStatementService();

    makeObservable(this, {
      bankStatementFile: observable,
      setBankStatementFile: action,
      fetchBankStatement: flow,
      submitBankStatement: flow,
    });
    this.setDefaults();
  }

  private setDefaults() {
    this.setBankStatementFile(null);
    this.holderList = [];
    this.bankInfo = {};
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  setBankStatementFile(file: File) {
    this.bankStatementFile = file;
  }

  *fetchBankStatement(loan, document, downloadExistingFile) {
    try {
      this.reset();
      this.document = document;

      if (downloadExistingFile) {
        const response = yield downloadExistingFile(document);
        const fileResult = getDocumentAsBlob(response?.data, response?.headers, response?.data?.type);
        this.bankStatementFile = new File([fileResult.blob], fileResult.name);
      }

      this.holderList = [
        {
          label: "Please Select",
          value: "",
        },
        {
          label: this.document.borrowerName,
          value: PERSONAL_NAME,
        },
        {
          label: loan?.borrowerEntity?.company?.name ? loan?.borrowerEntity?.company?.name : BORROWING_ENTITY,
          value: BORROWING_ENTITY,
        },
        {
          label: OTHER,
          value: OTHER,
        }
      ];

      const response = yield this.bankStatementService.getBankStatementData(loan?.loanId, this.document?.loanTaskId);
      if (response?.data?.data?.bankStatementId) {
        this.bankInfo = response?.data?.data;
      }
      else {
        this.bankInfo = {
          loanId: loan?.loanId,
          documentId: this.document?.loanTaskId,
          borrowerId: this.document?.borrowerId,
        };
      }
      this.loadForm(this.bankInfo);
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving the bank statement data.',
      });
    }
  }

  *submitBankStatement(onClose) {
    try {
      const formValues = this.getFormValues();
      this.runFormValidationWithMessage();
      if (formValues.lastFourDigits.trim().length != 4) {
        this.globalStore.notificationStore.showWarningNotification({
          message: 'Last 4 of Account # requires exactly 4 digits.',
        });
      }
      if (this.form.meta.isValid) {
        const data = {
          ...this.bankInfo,
          ...formValues,
          asOfDate: formatDate(formValues.asOfDate, 'MM/dd/yyyy'),
          otherAccountHolderName: formValues.accountHolder === OTHER ? formValues.otherAccountHolderName : '',
        }
        yield this.bankStatementService.updateBankStatementData(data);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Bank Statement updated successfully.',
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving bank statement.',
      });
    }
  }

}