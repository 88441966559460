import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Document } from '@roc/feature-types';
import { Help } from '@material-ui/icons';
import { Tooltip } from '@roc/ui';

const useDocumentNameStyles = makeStyles((theme: Theme) => ({
  clickable: {
    cursor: 'Pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tooltip: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    maxWidth: 'none',
  },
  helpIcon: {
    height: '18px',
    width: '18px',
    marginLeft: theme.spacing(0.5),
  },
  tooltipStyle: {
    '& li': {
      marginLeft: '25px',
    },
  },
}));

export type DocumentNameProps = {
  document: Document;
  onOpenForm: (document: Document) => void;
  onDownload: (document: Document) => void;
};

export const DocumentName = (props: DocumentNameProps) => {
  const { document, onOpenForm, onDownload } = props;
  const classes = useDocumentNameStyles();

  const isForm = document.isForm && document.canOpenForm;
  const isFile = document.canDownloadFile;

  const isClickable = isForm || isFile;

  const className = isClickable ? classes.clickable : null;

  const handleClick = () => {
    if (isClickable) {
      if (isForm) {
        onOpenForm(document);
      } else {
        onDownload(document);
      }
    }
  };

  return (
    <>
      <Typography className={className} onClick={handleClick}>
        {document.taskName}
        {document.taskInformation && (
          <Tooltip
            placement="right"
            title={
              <div
                className={classes.tooltipStyle}
                dangerouslySetInnerHTML={{
                  __html: document.taskInformation,
                }}
              />
            }
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <Help className={classes.helpIcon} color="primary" />
          </Tooltip>
        )}
      </Typography>
      {document.documentNameSecondLine && (
        <Typography component="span" color="textSecondary">
          ({document.documentNameSecondLine})
        </Typography>
      )}
    </>
  );
};

export default DocumentName;
