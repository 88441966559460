import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DateField, SelectField, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRevenueRecognitionStore } from '../hooks/useRevenueRecognitionStore';
import { REVENUE_RECOGNITION_MODULES } from '../utils/constants';
import { ReveneuRecognitionModules } from '../types/types';
import { SearchableDateAutocomplete } from './searchableDateAutocomplete';

const useStyles = makeStyles(theme => ({
  entityLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDownModuleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDownModule: {
    width: '400px',
    alignItems: 'center',
    display: 'flex'
  },
  item: {
    marginRight: '16px'
  },
  autoComplete: {
    width: '200px',
    marginRight: '16px'
  }
}));


export const RevenueRecognitionToolbar = observer(({ }) => {
  const classes = useStyles();
  const { revenueRecognitionStore } = useRevenueRecognitionStore();

  const getToolbarOptions = (moduleType: ReveneuRecognitionModules) => {
    return <SearchablesDatesToobarOption />
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar left={
          <Box className={classes.dropDownModuleBox}>
            <Box className={`${classes.dropDownModule} ${classes.item}`}>
              <Typography className={classes.item}>{'Module'}: </Typography>
              <SelectField
                multiple={false}
                testId={'typeOfFee'}
                fullWidth
                standaloneLabel
                variant="outlined"
                options={REVENUE_RECOGNITION_MODULES}
                onChange={value => {
                  revenueRecognitionStore.setSelectedModule(value);
                  //revenueRecognitionStore.resetAndFetchGridData();
                }}
                value={revenueRecognitionStore.selectedModule.value}
              />
            </Box>
            {getToolbarOptions(revenueRecognitionStore.selectedModule.value)}
          </Box>
        }>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                id="basic-button"
                onClick={() => {
                  revenueRecognitionStore.exportDashboardData();
                }}
                testId="open_menu"
                variant="contained"
                size="small"
                color="primary"
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
    </Grid>
  );
});

export const DatesToobarOption = () => {
  const classes = useStyles();
  const { revenueRecognitionStore } = useRevenueRecognitionStore();
  return (
    <>
      <DateField
        className={classes.item}
        testId="fromDate"
        views={["year", "month"]}
        format="MM/yyyy"
        label='From:'
        value={revenueRecognitionStore.params[0]}
        onChange={(date) => { revenueRecognitionStore.setParam(0, date) }}
      />
      {/* <DateField
        className={classes.item}
        testId="toDate"
        views={["year", "month"]}
        format="MM/yyyy"
        label='To:'
        value={revenueRecognitionStore.params[1]}
        onChange={(date) => { revenueRecognitionStore.setParam(1, date) }}
      /> */}
    </>
  );
}

export const SearchablesDatesToobarOption = observer(({ }) => {
  const classes = useStyles();
  const { revenueRecognitionStore } = useRevenueRecognitionStore();
  const [fromValueDate, setFromValueDate] = useState('');
  const [toValueDate, setToValueDate] = useState('');
  const { months, revenueRecognitionGridStore } = revenueRecognitionStore;

  useEffect(() => {
    revenueRecognitionStore.getMonths();
  }, [])

  useEffect(() => {
    validateFromDate();
  }, [months])

  const validateFromDate = () => {
    setFromValueDate(months.filter(month => month.value === revenueRecognitionStore.params[0])[0]?.label);
  }

  const validateToDate = () => {
    revenueRecognitionStore.setParam(1, null);
    revenueRecognitionGridStore.resetAndFetchGridData();
  }

  const onChangeDate = (updateInputValue, indexParam: number, value: string) => {
    updateInputValue();
    revenueRecognitionStore.setParam(indexParam, value);
    //revenueRecognitionGridStore.resetAndFetchGridData();
  }

  return (
    <>
      <Typography className={classes.item}>{'From'}: </Typography>
      <SearchableDateAutocomplete
        placeHolder='Pick a date'
        options={months}
        value={fromValueDate}
        onChange={(newValue) => {
          setFromValueDate(newValue);
        }}
        onSelect={(option: any) => {
          onChangeDate(() => setFromValueDate(option.label), 0, option.value);
        }}
        className={classes.autoComplete}
        onBlur={() => {
          if (fromValueDate === '') {
            validateFromDate();
          }
        }}
      />
      {/* <Typography className={classes.item}>{'To'}: </Typography>
      <SearchableDateAutocomplete
        placeHolder='Pick a date'
        options={months}
        value={toValueDate}
        onChange={(newValue) => {
          setToValueDate(newValue);
        }}
        onSelect={(option: any) => {
          onChangeDate(() => setToValueDate(option.label), 1, option.value);
        }}
        className={classes.autoComplete}
        onBlur={() => {
          if (toValueDate === '') {
            validateToDate();
          }
        }}
      /> */}

    </>
  );
})