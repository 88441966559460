import { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from '@roc/feature-utils';
import { DataGrid, GridColumnProps, SelectMode } from '@roc/ui';
import { observer } from 'mobx-react';
import { MoreVert } from '@material-ui/icons';
import { GridStore } from '@roc/feature-app-core';
import { TamariskAppraisalStore } from '../store/tamariskAppraisalStore';
import { TamariskAppraisalDashboardTab } from '../types/types';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '65vh',
    overflow: 'hidden',
  },
}));

interface TamariskAppraisalGridInterface {
  tamariskAppraisalStore: TamariskAppraisalStore;
  columns: GridColumnProps[];
  toolbar?: ReactNode;
  actions: (record, handleMenuClose) => ReactNode;
  loanId?: any;
}

export const TamariskAppraisalDashboardGrid = observer(
  ({
    tamariskAppraisalStore,
    columns,
    toolbar,
    actions,
    loanId,
  }: TamariskAppraisalGridInterface) => {
    const classes = useStyles();
    const {
      tab
    } = tamariskAppraisalStore;

    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [record, setRecord] = useState<any>({});
    const [gridApi, setGridApi] = useState(null);
    const [currentGridStore, setCurrentGridStore] = useState<GridStore>(tamariskAppraisalStore.myTamariskAppraisalGridStore);

    useEffect(() => {
      onTabChange();
    }, [tab]);

    useEffect(() => {
      currentGridStore.resetAndFetchGridData();

      if (tamariskAppraisalStore.tab !== TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL) {
        tamariskAppraisalStore.clearSelectedRecords();
      }
    }, [currentGridStore]);

    useEffect(() => {
        tamariskAppraisalStore.clearSelectedRecords();
    }, [currentGridStore.gridData?.meta?.pageNumber]);

    useEffect(() => {
      gridApi?.deselectAll();
    }, [currentGridStore.gridData.data.rows]);

    const onGridReady = params => {
      setGridApi(params.api);
    };

    const onTabChange = () => {
      setCurrentGridStore(tamariskAppraisalStore.getCurrentGridStore());
    };

    const frameworkComponents = {
      dateCellRenderer: ({ value }) => {
        return value ? formatDate(value, 'MM/dd/yyyy') : '';
      },
      actionCellRenderer: params => {
        return (
          <IconButton
              onClick={e => {
                openMenu(e, params.node.data);
              }}
            >
              <MoreVert color="primary" />
            </IconButton>
        );
      }
    };

    const onSortChanged = params => {
      const sortModel = params.api.getSortModel();
      currentGridStore.setSortModel(sortModel);
    };

    const onFilterChanged = params => {
      const filterModel = params.api.getFilterModel();
      currentGridStore.setFilterModel(filterModel);
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
      setRecord(record);
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const onRowSelected = params => {
      const isSelected = tamariskAppraisalStore.setSelectedRecords(
        params?.data,
        params?.node.selected
      );
      params.node.setSelected(isSelected);
    };

    return (
      <Box>
        <Grid item className={classes.gridContainer}>
          <DataGrid
            columns={columns}
            rows={currentGridStore.gridData.data.rows}
            frameworkComponents={frameworkComponents}
            selectMode={tamariskAppraisalStore.tab !== TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL && SelectMode.MULTIPLE}
            headerCheckboxSelection={true}
            suppressRowClickSelection={true}
            toolbar={tamariskAppraisalStore.tab !== TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL && toolbar}
            key={tab}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            isLoading={currentGridStore.isLoading}
            showFilters={currentGridStore.showFilters}
            sortModel={currentGridStore.sortModel}
            filterModel={currentGridStore.filterModel}
            paginationData={currentGridStore.gridData.meta}
            setPageNumber={currentGridStore.setPageNumber}
            onRowSelected={onRowSelected}
            gridReady={onGridReady}
          />
        </Grid>
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          {actions(record, handleMenuClose)}
        </Menu>
      </Box>
    );
  }
);
