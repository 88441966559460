import ReactECharts from "echarts-for-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { ChartUnavailable } from "./chartUnavailable";

const chartTitle = "Monthly Supply of Active Listing";

function transformData(chartData) {
  try {
    if (!chartData) {
      return null;
    }
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const colors = ["#3B82F6", "#22C55E", "#FACC15", "#EF4444", "#38BDF8", "#A83287"];
    const years = Object.keys(chartData.years).sort();
    const seriesData = [];

    years.forEach((year, index) => {
      const yearData = chartData.years[year].values;
      const monthValues = new Array(12).fill(null);

      yearData.forEach(({ month, value }) => {
        monthValues[month - 1] = value;
      });

      seriesData.push({
        name: year,
        type: "bar",
        data: monthValues,
        color: colors[index % colors.length]
      });
    });

    const option = {
      title: {
        text: chartTitle,
        left: "center",
        textStyle: {
          fontSize: 20,
          fontWeight: "bold",
          color: "#656565",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
      },
      legend: {
        top: "8%",
        left: "center",
        data: years,
      },
      grid: {
        left: "3%",
        right: "6%",
        bottom: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: months,
      },
      yAxis: {
        type: "value",
      },
      series: seriesData,
    };

    return option;
  } catch (err) {
    console.log(err);
    return null;
  }
}

interface MonthlySupplyOfActiveListingChartProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const MonthlySupplyOfActiveListingChart = ({ internalValuationReviewStore }: MonthlySupplyOfActiveListingChartProps) => {

  const chartData = internalValuationReviewStore.initialData.charts?.months_of_active_listing_supply;
  const option = transformData(chartData);

  if (!option) {
    return <ChartUnavailable chartName={chartTitle} />
  }

  return (
    <ReactECharts option={option} style={{ height: "461px", width: "100%" }} />
  );
};