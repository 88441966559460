import {
  Box,
  Chip,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  LinearProgress,
  Badge,
  useTheme
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { LoanProcess, uniq } from '@roc/feature-utils';
import {
  Button,
  CopyText,
  IconButton,
  PrintableArea,
  PrintableAreaRef,
  StandardDialog,
  Tooltip,
  TooltipOnOverflow,
} from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { CustomCard } from '../customCard';
import { TodoItem } from '../../utils/loanDetailsTypes';
import { DownloadTodoButton, TodoActions, TodoTitleLink } from './todoActions';
import { Add, ExpandMore, InsertDriveFile, PhotoCamera, Remove, FileCopyOutlined } from '@material-ui/icons';
import { restrictedAppraisalDocuments, useDocumentStore } from '@roc/feature-documents';
import { StatusFilterField } from '../common/statusFilterField';
import { SectionActions } from './sectionActions';
import { isPendingTodoStatus } from '../../utils/todoUtils';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Alert } from '@material-ui/lab';
import { colors } from '../../utils/todoConstants';
import { ReactComponent as ChatBubble } from '../../../icons/chatBubble.svg';
import { ReactComponent as ChatBubbleRead } from '../../../icons/chatBubbleRead.svg';
import { ReactComponent as ClosingConditionIcon } from '../../../icons/closingConditionIcon.svg';
import classname from 'classnames';
import { useLoanStore } from '@roc/feature-loans';
import { LineItemChat } from '../lineItemChat';
import { TodoStatusChip } from '../todoStatusChip';
import { todoStatusMapping } from '../../utils/loanDetailsConstants';

interface StylesProps {
  depth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  closingConditionTooltip: {
    '& .tooltip': {
      maxWidth: '257px',
    },
  },
  category: {
    backgroundColor: colors.greenWhite,
    padding: '0px 10px',
    borderRadius: '6px 6px 6px 6px',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    minHeight: '35px !important',
    cursor: 'pointer',
    userSelect: 'text',
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '35px !important',
    },
    '&.Mui-expanded': {
      borderRadius: '6px 6px 0px 0px',
    },
  },
  borderLinearProgress: {
    height: '18px',
    borderRadius: 20,
    [`& .MuiLinearProgress-root`]: {
      backgroundColor: colors.lavenderPinocchio,
    },
    [`& .MuiLinearProgress-bar`]: {
      borderRadius: 20,
      backgroundColor: colors.dodgerBlue,
    },
  },
  accordion: {
    marginBottom: '20px',
    '&.Mui-expanded': {
      margin: 0,
      marginBottom: '20px',
    },
    '&::before': {
      display: 'none',
    },
  },
  categoryTypography: {
    lineHeight: 2.5,
    fontWeight: 700,
    paddingRight: '8px'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: props => `8px 0px 8px ${props.depth * 34 + 44}px`,
    paddingRight: '0px',
    borderBottom: `1px solid ${colors.mercury}`,
    gap: '10px',
    backgroundColor: colors.soapstone,
    position: 'relative',
  },
  closingConditionIcon: {
    display: 'flex',
    position: 'absolute',
    left: '15px'
  },
  itemHasIcon: {
    padding: '16px 34px',
    paddingRight: '0px',
  },
  subItem: {
    paddingLeft: (props: StylesProps) => `${props.depth * 20}px`,
  },
  icon: {
    color: '#555',
    marginLeft: '-6px',
    cursor: 'pointer',
  },
  chip: {
    borderColor: 'black',
  },
  overflowElipsis: {
    maxWidth: '300px',
    textWrap: 'nowrap',
    fontSize: '1rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  underlined: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  todoTitle: {
    display: 'inline-block',
    flexShrink: 1,
  },
  filename: {
    paddingLeft: theme.spacing(2),
    color: '#86b8ff',
    display: 'inline-block',
  },
  headerText: {
    color: "#313131",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  statusChip: {
    width: '170px',
    justifyContent: 'center',
    fontSize: '1rem'
  },
  smallOptionsDropdown: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem'
    },
    '& .MuiTypography-root': {
      fontSize: '0.8rem',
    },
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  unreadCountBadge: {
    '& .MuiBadge-badge': {
      top: '11px',
      right: '-2px',
      border: '2px solid',
      fontSize: '0.7rem',
    },
  },
  copyText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    padding: '0 4px',
    '&:hover': {
      background: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 16,
      '&::after': {
        display: 'inherit',
      },
    },
    '&::after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
}));

interface Props {
  todos: TodoItem[];
  loanProcess: LoanProcess;
  showFilter?: boolean;
  showSectionActions?: boolean;
  showFileName?: boolean;
  showDownloadAll?: boolean;
  showDownloadTodo?: boolean;
  showActions?: boolean;
}

export const TodoTaskCard = observer(
  ({
    todos,
    loanProcess,
    showFilter = false,
    showSectionActions = false,
    showFileName = false,
    showDownloadAll = false,
    showDownloadTodo = false,
    showActions = false,
  }: Props) => {
    const printableAreaRef = useRef<PrintableAreaRef>(null);
    const [showOnlyPending, setShowOnlyPending] = useState(false);
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;

    const filteredTodos = showOnlyPending
      ? todos.filter(todo => isPendingTodoStatus(todo.status))
      : todos;

    const download = () => {
      printableAreaRef.current.downloadAsPdf(
        `Loan ${todos[0]?.document?.loanId} To-dos.pdf`,
        {
          singlePage: true,
        }
      );
    };

    const pendingTodosWithSubtasks = filteredTodos;

    return (
      <CustomCard
        paperNoShadow
        paperStyle={{ padding: '0px 16px 24px 16px' }}
        title={'To-Dos'}
        cardHeaderProps={{
          action: (
            <Box pt={1} display="flex">
              {showDownloadAll && (
                <Box pr={2}>
                  <Tooltip title="Take screenshot" placement="bottom">
                    <div>
                      <Button
                        testId="downloadAll"
                        onClick={download}
                        variant="outlined"
                        style={{
                          letterSpacing: 'normal',
                          textTransform: 'none',
                          fontWeight: 'normal',
                          fontSize: '16px',
                        }}
                      >
                        <Box height={'31px'} display="flex" alignItems="center">
                          <PhotoCamera />
                          &nbsp;&nbsp;Take Screenshot
                        </Box>
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
              )}
              {showFilter && (
                <StatusFilterField
                  value={showOnlyPending}
                  onChange={val => setShowOnlyPending(val as boolean)}
                />
              )}
            </Box>
          ),
        }}
        cardContentProps={{
          style: {
            overflow: 'auto',
          },
        }}
      >
        <PrintableArea ref={printableAreaRef}>
          <Box minWidth={1000}>
            <TaskTodoCardCompponent
              todos={pendingTodosWithSubtasks}
              loanProcess={loanProcess}
              showSectionActions={showSectionActions}
              showFileName={showFileName}
              showDownloadTodo={showDownloadTodo}
              showStatusColumn={true}
              showActions={showActions}
            />
          </Box>
        </PrintableArea>
      </CustomCard>
    );
  }
);

export const TaskTodoCardCompponent = observer(
  ({
    todos,
    loanProcess,
    showSectionActions = false,
    showFileName = false,
    showDownloadTodo = false,
    showDateColumn = false,
    showStatusColumn = false,
    showAssigneeColumn = false,
    showActions = false,
    expandAll = 0,
    collapseAll = 0,
    isLoading = false,
    isSummaryView = false,
    noRecordsMessageComponent = <Alert severity="info">No Records.</Alert>,
  }) => {
    const { documentStore } = useDocumentStore();
    const { loanTodosStore } = documentStore;
    const { loanStore } = useLoanStore();
    const { loanDetails } = loanStore;
    const loanId = loanDetails?.loanId;
    const classes = useStyles({});

    useEffect(() => {
      loanTodosStore.fetchClosingConditions(loanId);
    }, [loanId]);
    return !isLoading && todos.length == 0 ? (
      <Box mx={2} mb={1}>
        {noRecordsMessageComponent}
      </Box>
    ) : (
      <TaskTodoList
        todos={todos}
        classes={classes}
        showDateColumn={showDateColumn}
        showStatusColumn={showStatusColumn}
        showAssigneeColumn={showAssigneeColumn}
        loanProcess={loanProcess}
        showFileName={showFileName}
        showDownloadTodo={showDownloadTodo}
        showSectionActions={showSectionActions}
        expandAll={expandAll}
        collapseAll={collapseAll}
        showActions={showActions}
        isSummaryView={isSummaryView}
      />
    );
  }
);

const countTodos = (todos: TodoItem[]): number => {
  return todos.length;
};

interface TaskTodoListProps {
  todos: TodoItem[];
  loanProcess: LoanProcess;
  showFileName?: boolean;
  showDownloadTodo?: boolean;
  showSectionActions?: boolean;
  expandAll?: number;
  collapseAll?: number;
  classes?: any;
  showDateColumn?: boolean;
  showStatusColumn?: boolean;
  showAssigneeColumn?: boolean;
  showActions?: boolean;
  isSummaryView: boolean;
}

const TaskTodoList: React.FC<TaskTodoListProps> = ({
  todos,
  loanProcess,
  showFileName,
  showDownloadTodo,
  showDateColumn = false,
  showStatusColumn = false,
  showAssigneeColumn = false,
  showSectionActions,
  showActions,
  classes,
  expandAll,
  collapseAll,
  isSummaryView
}) => {
  const categories = uniq(todos.map(todo => todo.category)) as string[];
  const { globalStore } = useBaseStore();
  return (
    <Box>
      {(showDateColumn || showStatusColumn || showAssigneeColumn) && <Box style={{ margin: 'auto 26px auto auto' }} display='flex' justifyContent='right'>
        <Box display="flex" justifyContent="right" style={{ minWidth: globalStore.isInternalPortal ? '700px' : '630px' }}>
          {showAssigneeColumn && (
            <Box style={{ width: '30%', textAlign: 'center' }}>
              <Typography variant="caption" className={classes.headerText}>Assignee</Typography>
            </Box>
          )}
          {showStatusColumn && (
            <Box style={{ width: '30%', textAlign: 'center' }}>
              <Typography variant="caption" className={classes.headerText}>Status</Typography>
            </Box>
          )}
          {showDateColumn && (
            <Box style={{ width: '30%', textAlign: 'center' }}>
              <Typography variant="caption" className={classes.headerText}>Last Update Date</Typography>
            </Box>
          )}
          <Box style={{ width: '10%' }} display='flex' justifyContent='right'>
            {showStatusColumn && showDateColumn && (
              <Typography variant="caption" className={classes.headerText} style={{ paddingRight: '10px' }}>Actions</Typography>
            )}
          </Box>
        </Box>
      </Box>}
      {categories.map(category => {
        const categoryTodos = todos.filter(todo => todo.category === category);
        return (
          <TaskAccordion
            key={category}
            showDateColumn={showDateColumn}
            showStatusColumn={showStatusColumn}
            showAssigneeColumn={showAssigneeColumn}
            category={category}
            todos={categoryTodos}
            loanProcess={loanProcess}
            showFileName={showFileName}
            showDownloadTodo={showDownloadTodo}
            showSectionActions={showSectionActions}
            expandAll={expandAll}
            collapseAll={collapseAll}
            showActions={showActions}
            isSummaryView={isSummaryView}
          />
        );
      })}
    </Box>
  );
};

interface TaskAccordionProps {
  category: string;
  todos: any[];
  loanProcess: any;
  showFileName: boolean;
  showDownloadTodo: boolean;
  showSectionActions: boolean;
  isOpen?: boolean;
  expandAll?: number;
  collapseAll?: number;
  showDateColumn?: boolean;
  showStatusColumn?: boolean;
  showAssigneeColumn?: boolean;
  showActions?: boolean;
  isSummaryView: boolean;
}

export const TaskAccordion: React.FC<TaskAccordionProps> = ({
  category,
  todos,
  loanProcess,
  showFileName,
  showDownloadTodo,
  showSectionActions,
  isOpen = true,
  showDateColumn,
  showStatusColumn,
  showAssigneeColumn,
  showActions,
  expandAll,
  collapseAll,
  isSummaryView
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(isOpen);
  const [showCopySectionName, setShowCopySectionName] = useState(false);

  useEffect(() => {
    if (expandAll) {
      setOpen(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setOpen(false);
    }
  }, [collapseAll]);

  return (
    <Accordion elevation={0} expanded={open} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={classes.category}
        onMouseEnter={() => {
          if (!showCopySectionName) {
            setShowCopySectionName(true)
          }
        }}
        onMouseLeave={() => {
          if (showCopySectionName) {
            setShowCopySectionName(false)
          }
        }}
        onClick={() => setOpen(prev => !prev)}
      >
        <Typography className={classes.categoryTypography}>{category}</Typography>
        {showCopySectionName && (
          <CopyText propStyles={classes} tooltipText='Copy section name' valueToCopy={category}>
            <FileCopyOutlined />
          </CopyText>
        )}
        <Box style={{ margin: 'auto 0 auto auto' }}>
          {showSectionActions && (
            <SectionActions
              loanProcess={loanProcess}
              sectionName={category}
              sectionId={todos[0]?.document.sectionId}
              loanId={todos[0]?.document.loanId}
              drawId={todos[0]?.document.drawId}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column', padding: '0' }}>
        {todos.map(doc => (
          <TaskTodoItem
            key={doc.todoId}
            todo={doc}
            loanProcess={loanProcess}
            showDateColumn={showDateColumn}
            showStatusColumn={showStatusColumn}
            showAssigneeColumn={showAssigneeColumn}
            depth={0}
            showFileName={showFileName}
            showDownloadTodo={showDownloadTodo}
            expandAll={expandAll}
            collapseAll={collapseAll}
            showActions={showActions}
            isSummaryView={isSummaryView}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

interface TaskTodoItemProps {
  todo: TodoItem;
  loanProcess: LoanProcess;
  depth: number;
  showFileName?: boolean;
  showDownloadTodo?: boolean;
  expandAll?: number;
  collapseAll?: number;
  showDateColumn?: boolean;
  showStatusColumn?: boolean;
  showAssigneeColumn?: boolean;
  showActions?: boolean;
  isSummaryView: boolean;
}

const TaskTodoItem: React.FC<TaskTodoItemProps> = ({
  todo,
  loanProcess,
  depth,
  showFileName,
  showDownloadTodo,
  showDateColumn,
  showStatusColumn,
  showAssigneeColumn,
  showActions,
  expandAll,
  collapseAll,
  isSummaryView
}) => {
  const [open, setOpen] = useState(true);
  const [expand, setExpand] = useState(false);
  const [openLineItemChat, setOpenLineItemChat] = useState(false);
  const { globalStore } = useBaseStore();
  const { documentStore } = useDocumentStore();
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const classes = useStyles({ depth });
  const theme = useTheme();

  const info = todo.document?.documentNameSecondLine || null;
  const title = documentStore.getDisplayDocumentName(todo.title);
  const filename = todo.document?.originalFileName;
  const tooltip = todo.document?.taskInformation;
  const hasSubTodos = todo.subTodos?.length > 0;
  const loanId = todo.document?.loanId;

  const shouldShowLineItemChatOptions = allowLoanPricing && !userStore.isJrBroker && !userStore.isJrOriginator;

  enum TaskStatusColors {
    'Pending' = 'rgb(217, 68, 82)',
    'Being Reviewed' = 'rgb(221, 167, 0)',
    'More Info Needed' = 'rgb(217, 68, 82)',
    'Accepted' = 'rgb(72, 173, 37)',
    'Not Applicable' = 'rgba(0, 0, 0, 0.54)',
  }

  const statusCircle = (status: string) => {
    const color = TaskStatusColors[status];
    return <div style={{ height: 16, width: 16, borderRadius: '50%', backgroundColor: color, marginRight: 5 }}>
    </div>

  }

  useEffect(() => {
    if (expandAll) {
      setOpen(true);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setOpen(false);
    }
  }, [collapseAll]);

  return (
    <>
      <Box>
        <Box
          className={classname(classes.item, {
            [classes.itemHasIcon]: hasSubTodos,
          })}
        >
          {hasSubTodos ? (
            <IconButton size="small" className={classes.icon} testId={''} onClick={() => setOpen(!open)}>
              {open ? <Remove /> : <Add />}
            </IconButton>
          ) : null}
          <Box style={{ maxWidth: '40%' }} display="flex" flexWrap="nowrap" alignItems="center">
            <TooltipOnOverflow
              title={title}
              placement="bottom"
              spanClass={classes.todoTitle}
            >
              {(todo?.document?.isForm || todo.document.taskId == null || !shouldShowLineItemChatOptions) ?
                <Typography className={classes.overflowElipsis}>
                  <TodoTitleLink document={todo.document} loanProcess={loanProcess}>
                    {title}
                    {info ? ` - ${info}` : null}
                  </TodoTitleLink>
                </Typography> :
                (!restrictedAppraisalDocuments.includes(todo.document.taskName) ?
                  <Typography className={`${classes.overflowElipsis} ${classes.underlined}`} onClick={() => setOpenLineItemChat(true)} >
                    {title}
                    {info ? ` - ${info}` : null}
                  </Typography> :
                  <Typography className={todo.document.canDownloadFile ? `${classes.overflowElipsis}  ${classes.underlined}` : `${classes.overflowElipsis}`} onClick={todo.document.canDownloadFile ? () => documentStore.previewDocument(todo.document) : null}>
                    {title}
                    {info ? ` - ${info}` : null}
                  </Typography>
                )}
            </TooltipOnOverflow>
            {!!tooltip && (
              <Tooltip
                style={{ color: '#777' }}
                title={<Box dangerouslySetInnerHTML={{ __html: tooltip }} />}
              />
            )}
            {showFileName && !!filename && (
              <TooltipOnOverflow
                title={filename}
                placement="bottom"
                spanClass={classes.filename}
              >
                <Typography className={classes.overflowElipsis}>
                  {filename}
                </Typography>
              </TooltipOnOverflow>
            )}
            {showDownloadTodo && <DownloadTodoButton document={todo.document} />}
          </Box>
          {shouldShowLineItemChatOptions &&
            todo.document.taskId != null &&
            <Box>
              <IconButton testId="downloadAll"
                onClick={() => setOpenLineItemChat(true)}
                style={{
                  letterSpacing: 'normal',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  padding: '6px',
                  marginLeft: '-8px',
                }}>
                <Badge className={classes.unreadCountBadge} color="error" badgeContent={todo?.document?.unreadCount}>
                  {!!(todo?.document?.unreadCount) ? (
                    <ChatBubble fill={theme.palette.error.main} />
                  ) : (
                    <ChatBubble />
                  )}
                </Badge>
              </IconButton>
              { }
            </Box>
          }
          {globalStore.isInternalPortal ? (
            <Box style={{ margin: 'auto 26px auto auto', alignItems: 'center' }} display="flex" justifyContent="right">
              <Box display='flex' style={{ minWidth: '700px' }} justifyContent='right'>
                {showAssigneeColumn && (
                  <Box style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="caption" style={{ color: '#555', fontSize: '1rem' }}>{todo.document?.taskAssignee}</Typography>
                  </Box>
                )}
                {showStatusColumn && <Box style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <TodoStatusChip
                    showDropdown={true}
                    status={todo.status}
                    onStatusChange={status => {
                      documentStore.loanTodosStore.updateTodoStatus(
                        loanId,
                        loanProcess,
                        todo.document,
                        todoStatusMapping[status]
                      );
                    }}
                    size='small'
                    statusClassname={classes.statusChip}
                    dropdownClassname={classes.smallOptionsDropdown}
                  />
                </Box>}
                {showDateColumn && <Box style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="caption" style={{ color: '#555', fontSize: '1rem' }}>{todo.lastUpdateDate}</Typography>
                </Box>}
                {todo.document ? (
                  <Box style={{ width: '10%' }} display='flex' justifyContent='right'><TodoActions document={todo.document} loanProcess={loanProcess} /></Box>
                ) : (
                  <Box style={{ width: '10%' }} display='flex' justifyContent='right'>
                    <IconButton
                      testId={`todo-${todo.todoId}-more`}
                      onClick={() => { }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box style={{ margin: 'auto 26px auto auto', alignItems: 'center' }} flex={1} display="flex" justifyContent="right">
              <Box display='flex' style={!isSummaryView ? { minWidth: '500px' } : {}} justifyContent='right'>
                {showStatusColumn && (
                  <Box style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TodoStatusChip
                      showDropdown={false}
                      status={todo.status}
                      onStatusChange={status => { }}
                      size='small'
                      statusClassname={classes.statusChip}
                    />
                  </Box>
                )}
                {showActions &&
                  <>
                    {todo.document ? (
                      <Box style={{ width: '10%' }} display='flex' justifyContent='right'><TodoActions document={todo.document} loanProcess={loanProcess} /></Box>
                    ) : (
                      <Box style={{ width: '10%' }} display='flex' justifyContent='right'>
                        <IconButton
                          testId={`todo-${todo.todoId}-more`}
                          onClick={() => { }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </Box>
                    )}
                  </>
                }
              </Box>
            </Box>
          )}
        </Box>
        {open && todo.subTodos && (
          <Box border={0} className={classes.subItem}>
            {todo.subTodos.map(sub => (
              <TaskTodoItem
                key={sub.todoId}
                todo={sub}
                loanProcess={loanProcess}
                depth={depth + 1}
                showFileName={showFileName}
                showDownloadTodo={showDownloadTodo}
                showActions={showActions}
                isSummaryView={isSummaryView}
              />
            ))}
          </Box>
        )}
      </Box>
      <StandardDialog
        title={todo.title}
        open={openLineItemChat}
        handleClose={() => {
          documentStore.closeDocumentPreviewV2();
          setOpenLineItemChat(false);
          documentStore.loanTodosStore.fetchTodos(todo.document.loanId, LoanProcess.UNDERWRITING)
        }}
        handleExpand={todo.document.isForm ? null : () => {
          setExpand(prevState => !prevState);
        }}
        maxWidth={todo.document.isForm ? "lg" : "xl"}
        //subTitle={"Here you’ll write the instructions for what needs to be done with this document."}
        removeContentBox={true}
        dialogLogo={<InsertDriveFile />}
        dialogContent={
          <LineItemChat todo={todo} expand={expand} />
        }
      />
    </>
  );
};
