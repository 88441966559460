import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useExtractLoanProcessFromUrl } from '../../documents/hooks/useExtractLoanProcessFromUrl';
import { ApproveAdditionalClosingReviewModal } from '../actions/approveAdditionalClosingReviewModal';
import { ApproveEntityInformationModal } from '../actions/approveEntityInformationModal';
import { ApproveTitleInformationModal } from '../actions/approveTitleInformationModal';
import { CreateInSnapdocsModal } from '../actions/createInSnapdocsModal';
import { GenerateClosingDocumentsModal } from '../actions/generateClosingDocumentsModal';
import { MarkClosingDocumentsSentForSigningModal } from '../actions/markClosingDocumentsSentForSigningModal';
import { MarkFundedWireCompletedModal } from '../actions/markFundedWireCompletedModal';
import { MarkTitleCompanyContactedModal } from '../actions/markTitleCompanyContactedModal';
import { RejectInsuranceModal } from '../actions/rejectInsuranceModal';
import { RequestClosingWireModal } from '../actions/requestClosingWireModal';
import { UpdateFedexTrackingNumberModal } from '../actions/updateFedexTrackingNumberModal';
import { UploadClosingDocumentsModal } from '../actions/uploadClosingDocumentsModal';
import { UploadDocsToSnapdocsModal } from '../actions/uploadDocsToSnapdocsModal';
import { UploadHUDModal } from '../actions/uploadHUDModal';
import { UploadSignedClosingDocumentsModal } from '../actions/uploadSignedClosingDocumentsModal';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';
import { LoanAction } from '../utils/constants';
import { ResendFundingTemplateModal } from '../actions/resendFundingTemplateModal';

export const LoanActionForms = observer(() => {
  const { loanActionsStore } = useLoanActionsStore();
  const loanProcess = useExtractLoanProcessFromUrl();
  const { currentAction } = loanActionsStore;

  useEffect(() => {
    loanActionsStore.setLoanProcess(loanProcess);
  }, [loanProcess]);

  const closeModal = () => {
    loanActionsStore.setCurrentAction(null);
  };

  return (
    <>
      <ApproveAdditionalClosingReviewModal
        open={currentAction === LoanAction.APPROVE_ADDITIONAL_CLOSING}
        handleClose={closeModal}
      />
      <RejectInsuranceModal
        open={currentAction === LoanAction.REJECT_INSURANCE}
        handleClose={closeModal}
      />
      <MarkFundedWireCompletedModal
        open={currentAction === LoanAction.MARK_FUNDED_WIRE_COMPLETED}
        handleClose={closeModal}
      />
      <RequestClosingWireModal
        open={currentAction === LoanAction.REQUEST_CLOSING_WIRE}
        handleClose={closeModal}
      />
      <GenerateClosingDocumentsModal
        open={currentAction === LoanAction.GENERATE_CLOSING_DOCUMENTS}
        handleClose={closeModal}
      />
      <ResendFundingTemplateModal
        open={currentAction === LoanAction.RESEND_FUNDING_TEMPLATE}
        handleClose={closeModal}
      />
      <UpdateFedexTrackingNumberModal
        open={currentAction === LoanAction.UPDATE_FEDEX_TRACKING_NUMBER}
        handleClose={closeModal}
      />
      <CreateInSnapdocsModal
        open={currentAction === LoanAction.CREATE_IN_SNAPDOCS}
        handleClose={closeModal}
      />
      <ApproveEntityInformationModal
        open={currentAction === LoanAction.APPROVE_ENTITY_INFORMATION}
        handleClose={closeModal}
      />
      <ApproveTitleInformationModal
        open={currentAction === LoanAction.APPROVE_TITLE_INFORMATION}
        handleClose={closeModal}
      />
      <UploadHUDModal
        open={currentAction === LoanAction.UPLOAD_HUD}
        handleClose={closeModal}
      />
      <UploadDocsToSnapdocsModal
        open={currentAction === LoanAction.UPLOAD_DOCS_TO_SNAPDOCS}
        handleClose={closeModal}
      />
      <UploadClosingDocumentsModal
        open={currentAction === LoanAction.UPLOAD_CLOSING_DOCUMENTS}
        handleClose={closeModal}
      />
      <UploadSignedClosingDocumentsModal
        open={currentAction === LoanAction.UPLOAD_SIGNED_CLOSING_DOCUMENTS}
        handleClose={closeModal}
      />
      <MarkTitleCompanyContactedModal
        open={currentAction === LoanAction.MARK_TITLE_COMPANY_CONTACTED}
        handleClose={closeModal}
      />
      <MarkClosingDocumentsSentForSigningModal
        open={currentAction === LoanAction.MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING}
        handleClose={closeModal}
      />
    </>
  );
});
