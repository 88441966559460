import { GridColumnProps, SelectOption } from "@roc/ui";
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { ReveneuRecognitionModules } from "../types/types";

export const REVENUE_RECOGNITION_MODULES: Array<SelectOption> = [
  { label: 'Data Module 1', value: ReveneuRecognitionModules.DATA_MODULE },
  { label: 'Monthly Interest Module', value: ReveneuRecognitionModules.MONTHLY_INTEREST_MODULE },
  // { label: 'Monthly DQ Interest Management', value: ReveneuRecognitionModules.MONTHLY_DQ_INTEREST_MANAGEMENT },
  { label: 'Monthly Trade Module', value: ReveneuRecognitionModules.MONTHLY_TRADE_MODULE },
  { label: 'Data Module 2', value: ReveneuRecognitionModules.DATA_MODULE_2 },
  { label: 'Monthly Points Data & Amortization', value: ReveneuRecognitionModules.MONTHLY_POINTS_DATA_N_AMORTIZATION },
  { label: 'Monthly Underwriting Fees Data & Amortization', value: ReveneuRecognitionModules.MONTHLY_UW_DATA_N_AMORTIZATION },
  { label: 'Monthly Servicing fee on RTL Loans bps', value: ReveneuRecognitionModules.MONTHLY_SERVICING_FEE_ON_RTL_LOANS_BPS },
  { label: 'Monthly Default Interest & Special Servicing Fees', value: ReveneuRecognitionModules.MONTHLY_DEFAULT_INTEREST_N_SPECIAL_SERVICING_FEES },
  { label: 'Monthly Mapping of Term Loan Sale on Gain/Loss', value: ReveneuRecognitionModules.MONTHLY_MAPPING_OF_TERM_LOAN_SALE_ON_GAIN_LOSS },
  { label: 'Monthly Mapping of Late Penalties', value: ReveneuRecognitionModules.MONTHLY_MAPPING_OF_LATE_PENALTIES },
  { label: 'Monthly Extension Mapping & Amortization', value: ReveneuRecognitionModules.MONTHLY_EXTENSION_MAPPING_AND_AMORTIZATION },
  { label: 'Monthly Loan Balances Data & Portfolio Management', value: ReveneuRecognitionModules.MONTHLY_PORTFOLIO_MANAGEMENT },
];

export const MONTHLY_TRADE_MODULE_COLUMNS: GridColumnProps[] = [

  {
    field: 'month',
    headerName: 'Month',
    minWidth: 75,
    comparator: noOpComparator,
  },
  {
    field: 'loanId',
    headerName: 'Loan Id',
    width: 75,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    minWidth: 125,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'creditLineIn',
    headerName: 'Credit-Line In',
    minWidth: 125,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'creditLineOut',
    headerName: 'Credit-Line Out',
    minWidth: 125,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'repurchaseDate',
    headerName: 'Repurchase Date',
    minWidth: 150,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'entityName',
    headerName: 'Entity Name',
    minWidth: 160,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'creditLineName',
    headerName: 'Credit-Line Name',
    minWidth: 160,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'sellAs',
    headerName: 'Sell As',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
];