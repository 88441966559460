import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, makeObservable, observable } from 'mobx';
import { PropertyFormStore } from '../propertiesFormStore';
import { PublicLoanProperty } from '../../utils/publicLoanTypes';
import { isEmptyObject } from '@roc/feature-utils';
import { PropertyInformationSteps } from '../../utils/constants';
import { BasePropertiesStore } from '../basePropertiesStore';

export class BridgePropertiesStore extends BasePropertiesStore {
  private globalStore: GlobalStore;

  addPropertyAddresForm: PropertyFormStore;
  propertiesForm: PropertyFormStore[] = [];

  properties: PublicLoanProperty[] = [];

  accordionIndex: number = -1;

  propertyInformationActiveStep: PropertyInformationSteps;
  editPropertyActiveIndex: number;
  propertiesCount: number;

  constructor(globalStore) {
    super();
    this.globalStore = globalStore;
    this.addPropertyAddresForm = new PropertyFormStore(globalStore);
    this.reset();
    makeObservable(this, {
      properties: observable,
      reset: action,
      saveAddProperty: action,
      removeProperty: action,
      propertiesForm: observable,
      accordionIndex: observable,
      setAccordionIndex: action,
      propertyInformationActiveStep: observable,
      editPropertyActiveIndex: observable,
      propertiesCount: observable,
      setPropertiesCount: action,
      propertiesSubLables: computed,
      updatePropertyForms: action,
      handlePropertySubLabelClick: action,
      handlePropertyInformationBack: action,
      getPropertyInformationActiveStep: action,
      handleAddProperty: action,
      getProperties: action,
    });
  }

  setAccordionIndex(index) {
    this.accordionIndex = index;
  }

  setPropertiesCount(value) {
    this.propertiesCount = value;
  }

  reset() {
    this.addPropertyAddresForm.reset();
    this.properties = [];
    this.propertiesForm = [];
    this.accordionIndex = -1;
    this.propertyInformationActiveStep =
      PropertyInformationSteps.EDIT_PROPERTY_INFORMATION;
    this.editPropertyActiveIndex = 0;
    this.propertiesCount = 1;
    this.updatePropertyForms();
  }

  get propertiesSubLables() {
    const propertiesLabels = this.properties
      .filter(property => !isEmptyObject(property))
      .map(property => property.address);
    return propertiesLabels;
  }

  addProperty(formFields) {
    const addressInCorrectFormat =
      formFields.streetNumber !== null &&
      formFields.streetName !== null &&
      formFields.city !== null &&
      formFields.state !== null &&
      formFields.zipCode !== null;
    const newAddress = {
      address: formFields.address.value,
      streetNumber: formFields.streetNumber.value,
      streetName: formFields.streetName.value,
      city: formFields.city.value,
      state: formFields.state.value,
      zipCode: formFields.zipCode.value,
      latitude: formFields.latitude.value,
      longitude: formFields.longitude.value,
      __isAddressInCorrectFormat__: addressInCorrectFormat,
    };
    const newPropertyStore = new PropertyFormStore(this.globalStore);
    newPropertyStore.saveNewAddress(newAddress);

    this.propertiesForm = this.propertiesForm.concat(newPropertyStore);
    this.addPropertyAddresForm.reset();
  }

  updatePropertyForms() {
    const forms = [];
    for (let i = 0; i < this.propertiesCount; i++) {
      const form =
        this.propertiesForm[i] ?? new PropertyFormStore(this.globalStore);
      forms.push(form);
    }
    this.propertiesForm = forms;
  }

  saveAddProperty(newPropertyForm) {
    try {
      if (newPropertyForm.runAllFormValidationsWithMessage()) {
        if (
          this.propertiesForm.find(property => property === newPropertyForm)
        ) {
          this.propertiesForm = this.propertiesForm.map(property => {
            if (property === newPropertyForm) {
              return newPropertyForm;
            }
            return property;
          });

          this.properties = this.propertiesForm.map(property =>
            property.getProperty()
          );
        } else {
          const property = newPropertyForm.getProperty();
          this.properties = this.properties.concat([property]);
        }
        this.setAccordionIndex(-1);
      }
    } catch (e) {
      console.log(e);
    }
  }

  removeProperty(formStore: PropertyFormStore) {
    this.properties = this.properties.filter(
      x => x.address != formStore.getProperty().address
    );
    this.propertiesForm = this.propertiesForm.filter(x => x != formStore);

    if (this.propertiesForm.length > 0) {
      if (this.editPropertyActiveIndex > 0) {
        this.editPropertyActiveIndex--;
      }
    } else {
      this.propertyInformationActiveStep =
        PropertyInformationSteps.PROPERTY_INFORMATION;
    }
  }

  handlePropertyInformationNextStep = () => {
    switch (this.propertyInformationActiveStep) {
      case PropertyInformationSteps.PROPERTY_INFORMATION:
        this.updatePropertyForms();
        this.propertyInformationActiveStep =
          PropertyInformationSteps.EDIT_PROPERTY_INFORMATION;
        this.editPropertyActiveIndex = 0;
        break;
      case PropertyInformationSteps.EDIT_PROPERTY_INFORMATION:
        const form = this.propertiesForm[this.editPropertyActiveIndex];
        if (form.runAllFormValidations()) {
          this.saveAddProperty(form);
          if (this.editPropertyActiveIndex < this.propertiesForm.length - 1) {
            window.scrollTo(0, 0);
            this.editPropertyActiveIndex++;
          } else {
            this.propertyInformationActiveStep =
              PropertyInformationSteps.PROPERTY_INFORMATION_COMPLETED;
          }
        } else {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please fill the property(ies) fields',
          });
        }
    }
  };

  handlePropertyInformationBack = () => {
    if (this.editPropertyActiveIndex > 0) {
      window.scrollTo(0, 0);
      this.editPropertyActiveIndex--;
    }
  };

  handlePropertySubLabelClick = index => {
    this.propertyInformationActiveStep =
      PropertyInformationSteps.EDIT_PROPERTY_INFORMATION;
    this.editPropertyActiveIndex = index;
  };

  handleAddProperty = () => {
    const form = this.propertiesForm[this.editPropertyActiveIndex];
    if (form.runAllFormValidations()) {
      this.saveAddProperty(form);

      this.propertiesCount++;
      this.updatePropertyForms();
      this.editPropertyActiveIndex = this.propertiesForm.length - 1;
    }
  };

  getPropertyInformationActiveStep() {
    return this.propertyInformationActiveStep;
  }

  setPropertyInformationActiveStep(value) {
    this.propertyInformationActiveStep = value;
  }

  getProperties() {
    return this.properties;
  }
}
