import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import {
  GENERIC_ERROR_MESSAGE,
  isEmptyString,
  LoanStatus,
} from '@roc/feature-utils';
import { BorrowerLoansService } from '@roc/feature-borrower-loans';
import { GeneralTasksService } from '../services/generalTasksService';
import {
  GeneralTasksFilters,
  GeneralTasksTab,
  TaskStatus,
} from '../types/generalTasksTypes';
import { GeneralTasksStore } from './generalTasksStore';

export class GeneralTasksGridStore extends GridStore {
  globalStore: GlobalStore;
  generalTasksStore: GeneralTasksStore;
  generalTasksService: GeneralTasksService;

  constructor(globalStore: GlobalStore, generalTasksStore: GeneralTasksStore, status?: TaskStatus) {
    super(() => this.fetchGeneralTasksByStatus(status));
    this.globalStore = globalStore;
    this.generalTasksStore = generalTasksStore;
    this.generalTasksService = new GeneralTasksService();
  }

  fetchGeneralTasksByStatus = async status => {
    try {
      const loanId = this.generalTasksStore.loanId;
      switch (this.generalTasksStore.tab) {
        case GeneralTasksTab.ALL_TASKS:
          return await this.fetchAllTasksGrid(loanId, status);
        case GeneralTasksTab.MY_TASKS:
          return await this.fetchMyTasksGrid(loanId, status);
        case GeneralTasksTab.MY_LOANS_TASKS:
          return await this.fetchMyLoansTasksGrid(loanId, status);
        case GeneralTasksTab.ALL_LOANS_TASKS:
          return await this.fetchAllLoansTasksGrid(loanId, status);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  };

  fetchAllTasksGrid = async (loanId, status) => {
    const { filters, dropdownFilters } = this.generalTasksStore.getFilterParams();
    return this.generalTasksService.getAllTasksGrid(
      loanId,
      status,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortDir,
      this.gridData.meta.sortBy,
      filters,
      dropdownFilters
    );
  };

  fetchMyTasksGrid = async (loanId, status) => {
    const taskScope = this.generalTasksStore.filters[
      GeneralTasksFilters.TASK_SCOPE
    ];

    const taskScopeOptions = taskScope?.length > 0 ? taskScope.map(o => o.value).join(',') : undefined;

    const { filters, dropdownFilters } = this.generalTasksStore.getFilterParams();
    return this.generalTasksService.getMyTasksGrid(
      loanId,
      status,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortDir,
      this.gridData.meta.sortBy,
      filters,
      dropdownFilters,
      null, taskScopeOptions
    )
  }

  fetchMyLoansTasksGrid = async (loanId, status) => {
    const taskScope = this.generalTasksStore.filters[
      GeneralTasksFilters.TASK_SCOPE
    ];

    const taskScopeOptions = taskScope?.length > 0 ? taskScope.map(o => o.value).join(',') : undefined;

    const { filters, dropdownFilters } = this.generalTasksStore.getFilterParams();
    return this.generalTasksService.getMyLoansTasksGrid(
      loanId,
      status,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortDir,
      this.gridData.meta.sortBy,
      filters,
      dropdownFilters,
      taskScopeOptions
    )
  }

  fetchAllLoansTasksGrid = async (loanId, status) => {
    const { filters, dropdownFilters } = this.generalTasksStore.getFilterParams();
    return this.generalTasksService.getAllLoansTasksGrid(
      loanId,
      status,
      this.gridData.meta.pageNumber,
      this.gridData.meta.pageSize,
      this.gridData.meta.sortDir,
      this.gridData.meta.sortBy,
      filters,
      dropdownFilters
    )
  }
}
