import { Service } from '@roc/feature-app-core';
import { FinalDocumentType } from '../util';

const url = {
  GET_AVAILABLE_QUOTES_BY_LOAN_ID: '/api/v1/loan/insurance/v2/getAvailableQuotesByLoanId',
  SAVE_INSURANCE_QUOTE_DETAIL: '/api/v1/loan/insurance/v2/saveInsuranceQuoteDetail',
  GET_PROPERTIES_MISSING_QUOTES: '/api/v1/loan/insurance/v2/getPropertiesMissingQuotes',
  GET_SELECTED_QUOTES_BY_LOAN_ID: '/api/v1/loan/insurance/v2/getSelectedQuotesByLoanId',
  UPLOAD_INSURANCE_QUOTE_DOCUMENTS: '/api/v1/loan/insurance/v2/uploadInsuranceQuoteDocuments',
  DOWNLOAD_INSURANCE_QUOTE_DOCUMENT: '/api/v1/loan/insurance/v2/downloadInsuranceQuoteDocument',
  REQUEST_ELMSURE_QUOTE: '/api/v1/loan/insurance/v2/requestElmsureQuote',
  DELETE_QUOTE_DOCUMENT: '/api/v1/loan/insurance/v2/deleteQuoteDocument',
  GET_LOAN_BY_ID: '/api/v1/loan/getLoan',
  RESEND_QUOTE: '/api/v1/loan/resendQuote',
  GET_FINAL_DOCUMENTS: '/api/v1/loan/insurance/v2/getFinalDocuments',
  CREATE_FINAL_DOCUMENT: '/api/v1/loan/insurance/v2/createFinalDocument',
  DOWNLOAD_FINAL_DOCUMENT: '/api/v1/loan/insurance/v2/downloadFinalDocument',
};

export class InsuranceInformationService extends Service {

  getAvailableQuotesByLoanId(loanId) {
    return this.get(url.GET_AVAILABLE_QUOTES_BY_LOAN_ID, { loanId });
  }

  getSelectedQuotesByLoanId(loanId) {
    return this.get(url.GET_SELECTED_QUOTES_BY_LOAN_ID, { loanId });
  }

  saveInsuranceQuoteDetail(data: any) {
    return this.post(url.SAVE_INSURANCE_QUOTE_DETAIL, data);
  }

  getPropertiesMissingQuotes(loanId) {
    return this.get(url.GET_PROPERTIES_MISSING_QUOTES, { loanId });
  }

  uploadInsuranceQuoteDocuments(body: any) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.post(url.UPLOAD_INSURANCE_QUOTE_DOCUMENTS, body, config);
  }

  downloadInsuranceQuoteDocument(insuranceQuoteDocumentId) {
    return this.get(url.DOWNLOAD_INSURANCE_QUOTE_DOCUMENT, { insuranceQuoteDocumentId }, {
      responseType: 'arraybuffer',
    }).then(response => new Blob([response.data]));
  }

  getLoanDetails(id: string) {
    return this.get(url.GET_LOAN_BY_ID, { id });
  }

  requestElmsureQuote(loanId: string, data: any) {
    return this.post(url.REQUEST_ELMSURE_QUOTE, { ...data, loanId });
  }

  deleteQuoteDocument(quoteDocumentId: number) {
    return this.delete(url.DELETE_QUOTE_DOCUMENT, { quoteDocumentId });
  }

  resendQuote(loanId: string, borrowerEmail: string) {
    return this.post(`${url.RESEND_QUOTE}?loanId=${loanId}&borrowerEmailId=${borrowerEmail}`, {});
  }

  getFinalDocuments = loanId => {
    return this.get(url.GET_FINAL_DOCUMENTS, { loanId });
  }

  createFinalDocument = (loanTaskId: string, type: FinalDocumentType, insuranceQuoteDocumentIds: number[]) => {
    return this.post(url.CREATE_FINAL_DOCUMENT, { loanTaskId, insuranceQuoteDocumentIds, type });
  }

  downloadFinalDocument = (type: FinalDocumentType, loanId: string) => {
    return this.get(url.DOWNLOAD_FINAL_DOCUMENT, { loanId, type },{
      responseType: 'arraybuffer',
    }).then(response => new Blob([response.data]));
  }
}
