import { action, flow, makeObservable, observable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { InsuranceQuoteFormStore } from './insuranceQuoteFormStore';
import { isNil } from '@roc/feature-utils';
import ResendQuoteStore from './resendQuoteStore';
import { FinalDocumentType } from '../util';
import _ from 'lodash';

export abstract class InsuranceInformationBaseStore {
  protected globalStore: GlobalStore;
  insuranceQuoteFormStore: InsuranceQuoteFormStore;
  resendElmsureQuoteStore: ResendQuoteStore;
  finalDocumentSelectionGridStore: GridStore;

  isSaveInsuranceQuoteActive: boolean;
  quotes: any[];
  finalChoiceQuotes: any[];
  propertiesMissingQuotes: string[];
  propertiesOptions: any[];
  currentQuote: any;
  isPdfPreviewModalOpen: boolean;
  pdfTitle: string;
  pdfData: any;
  uploadDocuments: boolean;
  loanId: string;
  loanDocumentId: string;
  loanDetails: any;
  resendElmsureQuoteModal: boolean;
  selectedRecords: any[];
  finalBinder: string;
  finalInvoice: string;
  finalFloodCertificate: string;
  isApproved: boolean;

  abstract getSelectedQuotesByLoanId();
  abstract getAvailableQuotesByLoanId();
  abstract getPropertiesMissingQuotes();
  abstract uploadInsuranceQuoteDocuments(documents);
  abstract saveInsuranceQuoteDetails(quote, formSave?: boolean);
  abstract downloadInsuranceQuoteDocument(currentDocument);
  abstract getLoanDetails(loanId);
  abstract getPropertiesOptions();
  abstract validateQuote(quote: any);
  abstract requestQuoteFromElmsure();
  abstract deleteQuoteDocument(quoteDocumentId: number);
  abstract deleteQuote(quoteId: number);
  abstract approveInsurance();
  abstract resendElmsureQuote();
  abstract getFinalDocuments();
  abstract createFinalDocument(type: FinalDocumentType, insuranceQuoteDocumentIds: number[]);
  abstract downloadFinalDocument(type: FinalDocumentType);

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.finalDocumentSelectionGridStore = new GridStore(() =>
      this.initGrid()
    );

    this.setDefaults();
    makeObservable(this, {
      isSaveInsuranceQuoteActive: observable,
      quotes: observable,
      finalChoiceQuotes: observable,
      propertiesMissingQuotes: observable,
      propertiesOptions: observable,
      currentQuote: observable,
      isPdfPreviewModalOpen: observable,
      pdfTitle: observable,
      pdfData: observable,
      uploadDocuments: observable,
      setDefaults: action,
      reset: action,
      setQuotes: action,
      setFinalChoiceQuotes: action,
      getPropertiesOptions: action,
      handleOpenSave: action,
      handleCloseSave: action,
      handleUpload: action,
      handleSelectAsFinalChoice: flow,
      init: flow,
      getAvailableQuotesByLoanId: flow,
      getSelectedQuotesByLoanId: flow,
      saveInsuranceQuoteDetails: flow,
      getPropertiesMissingQuotes: flow,
      uploadInsuranceQuoteDocuments: flow,
      downloadInsuranceQuoteDocument: flow,
      getLoanDetails: flow,
      requestQuoteFromElmsure: flow,
      validateElmsureQuoteSubmission: action,
      deleteQuoteDocument: flow,
      deleteQuote: flow,
      approveInsurance: flow,
      handleResendElmsureModal: action,
      resendElmsureQuoteModal: observable,
      resendElmsureQuote: flow,
      initGrid: action,
      selectedRecords: observable,
      setSelectedRecords: action,
      createFinalDocument: flow,
      getFinalDocuments: flow,
      downloadFinalDocument: flow,
      createFinalBinder: flow,
      createFinalInvoice: flow,
      loadFinalDocuments: action,
      finalInvoice: observable,
      finalBinder: observable,
      finalFloodCertificate: observable,
      isApproved: observable,
    });
  }

  setDefaults() {
    this.isSaveInsuranceQuoteActive = false;
    this.setQuotes([]);
    this.setFinalChoiceQuotes([]);
    this.propertiesMissingQuotes = [];
    this.propertiesOptions = [];
    this.currentQuote = {};
    this.selectedRecords = [];
    this.finalInvoice = '';
    this.finalBinder = '';
    this.isApproved = false;
  }

  reset() {
    this.setDefaults();
    this.insuranceQuoteFormStore.reset();
    this.finalDocumentSelectionGridStore.reset();
  }

  setQuotes(quotes) {
    this.quotes = quotes;
  }

  setFinalChoiceQuotes(finalChoiceQuotes) {
    this.finalChoiceQuotes = finalChoiceQuotes;
  }

  setSelectedRecords(record, selected: boolean) {
    if (selected) {
      this.selectedRecords.push(record);
    }
    if (record != null && !selected) {
      this.selectedRecords = this.selectedRecords.filter(
        item =>
          item.insuranceQuoteDocumentId !== record.insuranceQuoteDocumentId
      );
    }
  }

  *init(loanId: string, loanDocumentId: string) {
    this.reset();
    this.loanId = loanId;
    this.loanDocumentId = loanDocumentId;
    yield this.getLoanDetails(this.loanId);
    yield this.getAvailableQuotesByLoanId();
    yield this.getSelectedQuotesByLoanId();
    yield this.getPropertiesMissingQuotes();
    this.getPropertiesOptions();
    this.resendElmsureQuoteStore.setDefaultBorrowerEmailAddress();
  }

  handleOpenSave(index = undefined, selectedQuote = false) {
    this.insuranceQuoteFormStore.reset();
    this.getPropertiesOptions();
    if (isNil(index)) {
      this.currentQuote = {};
    } else {
      this.currentQuote = selectedQuote
        ? this.finalChoiceQuotes[index]
        : this.quotes[index];
    }
    this.opentInsuranceModal(this.currentQuote);
  }

  opentInsuranceModal(quote) {
    this.currentQuote = quote;
    this.insuranceQuoteFormStore.loadForm(quote);
    this.isSaveInsuranceQuoteActive = true;
  }

  handleResendElmsureModal(isOpen: boolean) {
    this.resendElmsureQuoteModal = isOpen;
  }

  handleCloseSave() {
    this.isSaveInsuranceQuoteActive = false;
  }

  *handleSelectAsFinalChoice(index, selected: boolean) {
    this.currentQuote = selected
      ? this.quotes[index]
      : this.finalChoiceQuotes[index];
    this.currentQuote.selectedQuote = selected;
    yield this.saveInsuranceQuoteDetails(this.currentQuote);
  }

  handleUpload(index: number, isSelected: boolean) {
    this.currentQuote = isSelected ? this.finalChoiceQuotes[index] : this.quotes[index];
    this.uploadDocuments = true;
  }

  validateAndSaveQuote(quote: any) {
    if (this.validateQuote(quote)) {
      this.saveInsuranceQuoteDetails(quote, true);
    }
  }

  validateElmsureQuoteSubmission(): boolean {
    if (
      this.insuranceQuoteFormStore.getValue('properties').length > 0 &&
      (this.insuranceQuoteFormStore.getValue('generalLiabilityCoverage') ||
        this.insuranceQuoteFormStore.getValue('propertyCoverage') ||
        this.insuranceQuoteFormStore.getValue('floodCoverage'))
    ) {
      return true;
    }
    this.globalStore.notificationStore.showWarningNotification({
      message: 'Please fill the required information',
    });
    return false;
  }

  async initGrid() {
    const rows = _.flatten(this.finalChoiceQuotes.map(quote => quote.documents));
    return new ApiResponse({ data: { rows: rows } }, {});
  }

  async loadFinalDocuments() {
    const { data } = await this.getFinalDocuments();
    this.finalBinder = data.finalBinderPath;
    this.finalInvoice = data.finalInvoicePath;
    this.finalFloodCertificate = data.finalFloodCertificatePath;
  }

  async createFinalBinder() {
    const { data } = await this.createFinalDocument(FinalDocumentType.BINDER, this.selectedRecords.map(record => record.insuranceQuoteDocumentId));
    this.finalBinder = data.finalBinderPath;
  }

  async createFinalInvoice() {
    const { data } = await this.createFinalDocument(FinalDocumentType.INVOICE, this.selectedRecords.map(record => record.insuranceQuoteDocumentId));
    this.finalInvoice = data.finalInvoicePath;
  }
}
