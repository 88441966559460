import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import {
  Tabs
} from '@roc/ui';
import { observer } from 'mobx-react';
import { DataGrid } from '@roc/ui';
import { useRocInternalPortalStore } from 'apps/roc-internal-portal/src/app/hooks';
import { useEffect } from 'react';

export enum InsuranceDashboardTab {
  MY_PENDING_QUOTES,
  ALL_PENDING_QUOTES,
  SENT_FOR_REVISION
}

const TABS = [
  {
    value: InsuranceDashboardTab.MY_PENDING_QUOTES,
    label: 'My Pending Quotes',
  },
  {
    value: InsuranceDashboardTab.ALL_PENDING_QUOTES,
    label: 'All Pending Quotes',
  },
  {
    value: InsuranceDashboardTab.SENT_FOR_REVISION,
    label: 'Sent for Revision',
  }
];

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'white',
    borderRight: '1px solid #dddddd',
    borderLeft: '1px solid #dddddd',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  grid: {
    height: '70vh'
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold'
  }
}));

export const InsuranceDashboard = observer(() => {
  const classes = useStyles();
  const { insuranceDashboardStore } = useRocInternalPortalStore();
  const { insuranceDashoboardGridStore } = insuranceDashboardStore;

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    insuranceDashoboardGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    insuranceDashoboardGridStore.setFilterModel(filterModel);
  };

  useEffect(() => {
    insuranceDashoboardGridStore.resetAndFetchGridData();
  }, [insuranceDashboardStore.selectedTab]);

  const onRowSelected = params => {
    const isSelected = insuranceDashboardStore.setSelectedRecords(
      params?.data?.taskId,
      params?.node.selected
    );
    params.node.setSelected(isSelected);
  };

  const frameworkComponents = {
    loanIdCellRenderer: ({ value }) => <Link className={classes.link} onClick={() => window.parent.postMessage({ loanId: value }, '*')}>{value}</Link>
  };

  return (
    <Layout
      title="Insurance Dashboard"
    >
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            selectedTab={insuranceDashboardStore.selectedTab}
            tabs={TABS}
            onTabChange={(e, i) => insuranceDashboardStore.setSelectedTab(i)}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            className={classes.grid}
            columns={insuranceDashboardStore.columns}
            rows={insuranceDashoboardGridStore.gridData.data.rows}
            frameworkComponents={frameworkComponents}
            key={insuranceDashboardStore.selectedTab}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            isLoading={insuranceDashoboardGridStore.isLoading}
            showFilters
            showPagination
            paginationData={insuranceDashoboardGridStore.gridData.meta}
            setPageNumber={insuranceDashoboardGridStore.setPageNumber}
            onRowSelected={onRowSelected}
          />
        </Grid>
      </Grid>
    </Layout>
  );
});
