import { Button, StandardDialog, TextField } from '@roc/ui';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useEffect, useState } from 'react';
import { SUBMITTED_FOR_REVIEW_MANUAL } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';

export const SubmitReviewDialog = observer((props) => {
  const { store } = props;
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription('');
  }, [store.isSubmitForReviewOpen])

  return (
    <StandardDialog
      open={store.isSubmitForReviewOpen}
      title="Submit to Construction Management Team for Review"
      maxWidth="md"
      handleClose={() => store.setOpenSubmitReviewDialog(false)}
      dialogContent={
        <Typography>Are you sure you want to submit this scope of work to Construction Management team for review?</Typography>
      }
      dialogActions={
        <>
          <Button
            testId="submitReviewCancel"
            style={{ marginRight: '8px' }}
            onClick={() => store.setOpenSubmitReviewDialog(false)}
            color="primary"
            variant="outlined"
          >
            CANCEL
          </Button>
          <Button
            testId="submitReviewConfirm"
            onClick={() => store.handleMarkAsInReview(SUBMITTED_FOR_REVIEW_MANUAL, description)}
            color="primary"
            variant="contained"
          >
            CONFIRM
          </Button>
        </>
      }
    />
  );
});

export default SubmitReviewDialog;