import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { formatDate } from '@roc/feature-utils';
import { FileUpload } from '@roc/ui';
import { action, computed, makeObservable, observable } from 'mobx';
import { InsuranceInformationBaseStore } from './insuranceInformationBaseStore';

const form = {
  fields: {
    insuranceQuoteDetailId: {
      value: '',
      error: null,
      rule: '',
    },
    insuranceAgentName: {
      value: '',
      error: null,
      rule: 'required',
    },
    insuranceAgentPhone: {
      value: '',
      error: null,
      rule: 'required|phone',
    },
    insuranceAgentEmail: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    insuranceAgentAddress: {
      value: '',
      error: null,
      rule: [{ required_if: ['__isInternal__', true] }],
    },
    policyNumber: {
      value: '',
      error: null,
      rule: '',
    },
    insuranceEffectiveDate: {
      value: null,
      error: null,
      rule: '',
    },
    insuranceExpiryDate: {
      value: null,
      error: null,
      rule: '',
    },
    carrier: {
      value: '',
      error: null,
      rule: '',
    },
    carrierNaic: {
      value: '',
      error: null,
      rule: '',
    },
    coverageAmount: {
      value: null,
      error: null,
      rule: ''
    },
    insurancePremium: {
      value: null,
      error: null,
      rule: 'required',
    },
    paidAlready: {
      value: null,
      error: null,
      rule: 'required',
    },
    remainingAmount: {
      value: null,
      error: null,
      rule: '',
    },
    properties: {
      value: [],
      error: null,
      rule: 'required',
    },
    __isInternal__: {
      value: null,
      error: null,
      rule: '',
    },
    propertyCoverage: {
      value: false,
      error: null,
      rule: '',
    },
    generalLiabilityCoverage: {
      value: false,
      error: null,
      rule: '',
    },
    floodCoverage: {
      value: false,
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class InsuranceQuoteFormStore extends FormStore {
  documents: FileUpload[];
  isInternal: boolean;
  insuranceInformationStore: InsuranceInformationBaseStore;

  constructor(isInternal: boolean, insuranceInformationStore: InsuranceInformationBaseStore, globalStore: GlobalStore) {
    super(form, globalStore);
    this.isInternal = isInternal;
    this.insuranceInformationStore = insuranceInformationStore;
    makeObservable(this, {
      documents: observable,
      setDocuments: action,
      paymentType: computed,
      getInsuranceQuote: action,
      isInternal: observable,
      setPortalToForm: action
    });
    this.setDefaults();
  }

  reset() {
    super.reset();
    this.setDefaults();
  }

  private setDefaults() {
    this.setDocuments([]);
  }

  setDocuments(files: FileUpload[]) {
    this.documents = files;
  }

  onFieldChange(name, value) {

    super.onFieldChange(name, value);

    if (name == 'insurancePremium' || name == 'paidAlready') {
      const remainingAmount = this.form.fields.insurancePremium.value - this.form.fields.paidAlready.value;
      super.onFieldChange('remainingAmount', remainingAmount);
    }
  }

  get paymentType() {
    return this.form.fields.remainingAmount.value == 0 ? 'Paid Already' : 'Paid Through HUD';
  }

  getInsuranceQuote() {
    const formValues = this.getFormValues();
    const quote = {
      ...formValues,
      properties: this.getValue('properties').includes('All') ? this.insuranceInformationStore.propertiesOptions
        .map(option => option.value).filter(value => value != 'All') : this.getValue('properties'),
      paymentType: this.paymentType,
      insuranceEffectiveDate: formatDate(formValues.insuranceEffectiveDate, 'MM/dd/yyyy'),
      insuranceExpiryDate: formatDate(formValues.insuranceExpiryDate, 'MM/dd/yyyy'),
      documents: this.documents,
    }
    return quote;
  }

  setPortalToForm() {
    this.onFieldChange('__isInternal__', this.isInternal);
  }


  getRequestElmsureQuoteValues() {
    return {
      propertyCoverage: this.getValue('propertyCoverage'),
      generalLiabilityCoverage: this.getValue('generalLiabilityCoverage'),
      floodCoverage: this.getValue('floodCoverage'),
      properties: this.getValue('properties'),
    }
  }

}