import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import {
  GeneralTasksFilters,
  GeneralTasksTab,
} from '../types/generalTasksTypes';
import {
  generalTaskPriorityOptions,
  generalTaskStatusOptions,
  hasOpenSubtasksOptions,
  reportedByMeOptions,
  taskTypeOptions,
} from '../utils/generalTasksConstants';
import {
  DateFilter,
  MultiselectFilter,
  RadioFilter,
  SelectFilter,
  TextFilter,
  UserFilter,
} from './generalTasksToolbarFilterComponents';

const useStyles = makeStyles(theme => ({
  container: {
    paddingtTop: theme.spacing(2),
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
}));

export const GeneralTasksToolbarFilters = observer(() => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(true);
  const { generalTasksStore } = useGeneralTasksStore();
  const { view, filters, assigneeOptions, supportTasksStore } = generalTasksStore;

  let selectedFilters = [
    GeneralTasksFilters.TASK_SCOPE,
    GeneralTasksFilters.STATUS,
    GeneralTasksFilters.PRIORITY,
    GeneralTasksFilters.TITLE,
    GeneralTasksFilters.TASK_TYPE,
    GeneralTasksFilters.LOAN_ID,
    GeneralTasksFilters.TASK_ID,
    GeneralTasksFilters.DUE_DATE,
    GeneralTasksFilters.ASSIGNEE,
    // GeneralTasksFilters.OWNER,
    GeneralTasksFilters.OPEN_SUBTASKS,
  ];

  let hiddenFilters = [];

  if (generalTasksStore.tab === GeneralTasksTab.MY_TASKS) {
    hiddenFilters.push(GeneralTasksFilters.ASSIGNEE);
  }
  if (generalTasksStore.tab === GeneralTasksTab.ALL_TASKS) {
    hiddenFilters.push(GeneralTasksFilters.OPEN_SUBTASKS);
    hiddenFilters.push(GeneralTasksFilters.TASK_SCOPE);
  }
  if (generalTasksStore.loanId) {
    hiddenFilters.push(GeneralTasksFilters.LOAN_ID);
  }

  if (supportTasksStore.openModal) {
    hiddenFilters.push(GeneralTasksFilters.OPEN_SUBTASKS);
    hiddenFilters.push(GeneralTasksFilters.TASK_SCOPE);
    hiddenFilters.push(GeneralTasksFilters.DUE_DATE);
    hiddenFilters.push(GeneralTasksFilters.ASSIGNEE);
    hiddenFilters.push(GeneralTasksFilters.PRIORITY);
    hiddenFilters.push(GeneralTasksFilters.LOAN_ID);
    hiddenFilters.push(GeneralTasksFilters.TASK_TYPE);
  }

  selectedFilters = selectedFilters.filter(
    item => !hiddenFilters.includes(item)
  );

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <Button
          testId="show-filters"
          size="small"
          style={{ minWidth: 'auto' }}
          onClick={() => setShowFilters(val => !val)}
        >
          {showFilters ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
        <Typography variant="h6"> Filters</Typography>
        <Button
          testId="reset-filters"
          variant="text"
          size="small"
          color="primary"
          style={{ marginLeft: '16px' }}
          onClick={() => generalTasksStore.resetFilters()}
        >
          Reset Filters
        </Button>
      </Box>
      <Collapse in={showFilters}>
        <Box pt={2}>
          <Grid container spacing={2}>
            {selectedFilters.map(filterName => {
              const value = filters?.[filterName];
              const filterProps = {
                value,
                onChange: options => {
                  generalTasksStore.setFilter(filterName, options);
                },
              };
              return (
                <Grid item key={filterName}>
                  {GeneralTasksFilters.STATUS === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label={'Status'}
                      options={generalTaskStatusOptions}
                    />
                  )}
                  {GeneralTasksFilters.PRIORITY === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label={'Priority'}
                      options={generalTaskPriorityOptions}
                    />
                  )}
                  {GeneralTasksFilters.ASSIGNEE === filterName && (
                    <UserFilter
                      {...filterProps}
                      label={'Assignees'}
                      options={(assigneeOptions ?? []).map(assignee => ({
                        ...assignee,
                        label: `${assignee.firstName} ${assignee.lastName}`,
                        value: assignee.userId,
                      }))}
                    />
                  )}
                  {GeneralTasksFilters.TITLE === filterName && (
                    <TextFilter {...filterProps} label={'Title'} />
                  )}
                  {GeneralTasksFilters.DUE_DATE === filterName && (
                    <DateFilter {...filterProps} label={'Due Date'} />
                  )}
                  {GeneralTasksFilters.LOAN_ID === filterName && (
                    <TextFilter
                      {...filterProps}
                      label={'Loan Id'}
                      maxWidth={'100px'}
                    />
                  )}
                  {GeneralTasksFilters.TASK_ID === filterName && (
                    <TextFilter
                      {...filterProps}
                      label={'Task Id'}
                      maxWidth={'100px'}
                    />
                  )}
                  {GeneralTasksFilters.OWNER === filterName && (
                    <UserFilter
                      {...filterProps}
                      label={'Reporter'}
                      options={(assigneeOptions ?? []).map(assignee => ({
                        ...assignee,
                        label: `${assignee.firstName} ${assignee.lastName}`,
                        value: assignee.userId,
                      }))}
                    />
                  )}
                  {GeneralTasksFilters.OPEN_SUBTASKS === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label="Has Open Subtasks"
                      options={hasOpenSubtasksOptions}
                    />
                  )}
                  {GeneralTasksFilters.TASK_TYPE === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label="Category"
                      options={taskTypeOptions}
                      minWidth={'250px'}
                    />
                  )}
                  {GeneralTasksFilters.TASK_SCOPE === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label="Tasks"
                      options={reportedByMeOptions}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
});
