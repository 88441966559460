import { makeStyles } from '@material-ui/core/styles';
import { useInternalAppraisalStore } from '@roc/feature-appraisals';
import { useDocumentStore } from 'libs/feature-documents/src/documents/hooks/useDocumentStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AppraisalOrderDetails } from './appraisalOrderDetails';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  instruction: {
    fontWeight: 600,
  },
  paymentDetails: {
    fontWeight: 800,
    marginTop: '20px',
    fontStyle: 'italic',
  },
  tabsContainer: {
    border: 'none !important',
  },
  container: {
    paddingTop: 16,
  },
  closeButtonContainer: {
    paddingTop: 16,
  },
  button: {
    width: '100%',
  }
});

export type AppraisalOrderDetailsProps = {
  onUpdate?: () => void;
  onSubmitLoanPayments?: () => void;
  onClick?: () => void;
  isInternal?: boolean;
  loanId?: number;
  loanTaskId?: number;
  isTamariskLender?: any;
};

export const AppraisalCdaOrderDetails = observer(
  (props: AppraisalOrderDetailsProps) => {

    const { isInternal, isTamariskLender } = props;
    const { documentStore } = useDocumentStore();
    const { documentFormStore } = documentStore;
    const { cdaAndAppraisalOrderDetailsStore } = documentFormStore;
    const { internalAppraisalCdaStore } = useInternalAppraisalStore();
    cdaAndAppraisalOrderDetailsStore.setIsInternal(isInternal);

    useEffect(() => {
      if (props.isInternal) {
        loadLoanData();
        cdaAndAppraisalOrderDetailsStore.setIsInternal(isInternal);
      }
    }, [props.isInternal]);

    const loadLoanData = () => {
      if (isInternal) {
        internalAppraisalCdaStore.refreshLoanDetails(props.loanId, props.loanTaskId);
      }
    }

    if (internalAppraisalCdaStore.loanDetails && internalAppraisalCdaStore.document) {
      return (
        <Grid container>
          <AppraisalOrderDetails
            loan={internalAppraisalCdaStore.loanDetails}
            document={internalAppraisalCdaStore.document}
            onSubmitLoanPayments={() => { }}
            isInternal={true}
            isTamariskLender={isTamariskLender == 'true'}
          />
        </Grid>
      );
    } else {
      return <Grid container>Loading...</Grid>
    }
  }
)