import React, { useRef, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { Button } from '@roc/ui';
import { Menu, MenuItem } from "@material-ui/core"
import { ScreenshotGrid } from '@roc/ui';
import { useDocumentStore } from './../../../../../hooks/useDocumentStore';


export type FundingTemplateContainer = {
  children: JSX.Element;
};

export const FundingTemplateContainer = (props: FundingTemplateContainer) => {
  const { children } = props;
  const screenshotContainerRef = useRef(null);
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { fundingTemplateStore } = documentFormStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const downloadFundingTemplate = () => {
    screenshotContainerRef.current.download();
  };

  const downloadEstimateFundingTemplate = () => {
    fundingTemplateStore.downloadEstimatedFundingTemplate();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
        {documentStore.openEstimateFundingTemplate ? (
          <>
          <Button
            onClick={handleClick}
            color="secondary"
            variant="contained"
            testId="downloadFundingTemplateButton"
          >
            Download
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={() => { downloadEstimateFundingTemplate(); handleClose(); }}>
              Download as Excel
           </MenuItem>
            <MenuItem onClick={() => { downloadFundingTemplate(); handleClose(); }}>
              Download as PDF
            </MenuItem>
          </Menu>
          </>
      ):
      (
          <Button
            onClick={downloadFundingTemplate}
            color="secondary"
            variant="contained"
            testId="downloadFundingTemplateButton"
          >
            Download
          </Button>
      )
      }
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ScreenshotGrid
          fileName="Funding_Template.pdf"
          ref={screenshotContainerRef}
          container
          spacing={5}
        >
          <Grid item xs={12}>
            {children}
          </Grid>
        </ScreenshotGrid>
      </Grid>
    </Grid>
  );
};

export default FundingTemplateContainer;
