import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TitleDivider } from "../titleDivider";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { observer } from "mobx-react";
import { ReportHistoryTable } from "../reportHistoryTable";

const useStyles = makeStyles(() => ({
  tabContent: {
    backgroundColor: "#FFFFFF",
    padding: "2rem",
  },
  saveButton: {
    width: "15rem",
  },
}));

interface ReportHistoryTabProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ReportHistoryTab = observer(({ internalValuationReviewStore }: ReportHistoryTabProps) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      className={classes.tabContent}
    >
      <TitleDivider title="Report History" />
      <ReportHistoryTable internalValuationReviewStore={internalValuationReviewStore} />
    </Box>
  );
});
