import {
  Box,
  Divider,
  Drawer,
  Fab,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuList,
  Typography,
} from '@material-ui/core';
import { TouchApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Button } from '../button/button';
import { Tooltip } from '@roc/ui'

const useFabActionsButtonStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    zIndex: 99,
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    padding: theme.spacing(0, 6),
  },
  fabV2: {
    color: '#fff',
    transition: 'all .7s',
    position: 'fixed',
    bottom: '120px',
    right: '30px',
    width: 72,
    height: 72,
    '@media (min-width:1200px) and (max-width:1600px)': {
      position: 'sticky',
      marginLeft: 'auto',
      marginRight: '-80px',
      marginTop: '-80px'
    },
    '@media (min-width:1600px)': {
      bottom: '30px',
      position: 'sticky',
      marginLeft: 'auto',
      marginRight: '-160px',
      marginTop: '-55px'
    },
    '&:hover': {
      backgroundColor: '#0056c9'
    }
  },
  fabV3: {
    color: '#fff',
    transition: 'all .7s',
    position: 'fixed',
    bottom: '120px',
    backgroundColor: 'rgb(66, 133, 244)',
    right: '38px',
    width: 56,
    height: 56,
    '@media (min-width:1200px) and (max-width:1600px)': {
      position: 'sticky',
      marginLeft: 'auto',
      marginRight: '-80px',
      marginTop: '-80px'
    },
    '@media (min-width:1200px)': {
      position: 'sticky',
      marginLeft: 'auto',
      marginRight: '-80px',
      marginTop: '-80px'
    },
  }
}));

interface FabActionsButtonProps {
  name?: string;
  onOpen?: () => void;
  version?: 'v1' | 'v2' | 'v3';
}

export const FabActionsButton = observer(({
  name = 'Take Action',
  children,
  onOpen = undefined,
  version = 'v1'
}: PropsWithChildren<FabActionsButtonProps>) => {
  const classes = useFabActionsButtonStyles();
  const [open, setOpen] = useState(false);
  const fabText = useMemo(() => {
    if (version === 'v1') return (<Typography style={{ paddingLeft: 8, textTransform: 'uppercase' }}>{name}</Typography>);
    return null
  }, [version, name])

  function handleOpen() {
    setOpen(true);
    onOpen?.();
  }

  function handleClose() {
    setOpen(false);
  }

  function getStylesByVersion(version: string) {
    if (version === 'v1') {
      return classes.fab
    }
    if (version === 'v2') {
      return classes.fabV2
    }
    if (version === 'v3') {
      return classes.fabV3
    }
  }

  return (
    <>
      <Tooltip title={name}>
        <Fab
          variant={version === 'v1' ? 'extended' : 'circular'}
          color="primary"
          className={getStylesByVersion(version)}
          onClick={handleOpen}
        >
          {
            version === 'v1' ? <TouchApp /> : <TouchApp fontSize={'large'} />
          }
          {fabText}
        </Fab>
      </Tooltip>
      <Drawer
        anchor={'right'}
        open={open}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
        onClose={handleClose}
      >
        <MenuList>
          <ListItem>
            <ListItemText>
              <Typography variant="h4">{name}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                testId={'close'}
                variant="outlined"
                onClick={handleClose}
              >
                Close
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </MenuList>
        <Box padding={2}>
          <Grid container spacing={2} onClickCapture={handleClose}>
            {children}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
});

const useFabActionsMenuItemStyles = makeStyles(() => ({
  actionButton: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
}));

interface FabActionsMenuItemProps {
  icon: ReactElement;
  label: string;
  rightColumnValue?: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const FabActionsMenuItem = ({
  icon,
  label,
  rightColumnValue = null,
  onClick,
  disabled = false,
  tooltip = undefined
}: FabActionsMenuItemProps) => {
  const classes = useFabActionsMenuItemStyles();
  return (
    <>
      <Grid item xs={rightColumnValue ? 9 : 12}>
        <Tooltip title={tooltip} placement={'left'}>
          <Box>
            <Button
              testId={label}
              onClick={onClick}
              variant={'contained'}
              color={'primary'}
              fullWidth
              disabled={disabled}
              className={classes.actionButton}
            >
              {icon}
              <Box px={1}>{label}</Box>
            </Button>
          </Box>
        </Tooltip>
      </Grid>
      {rightColumnValue && (
        <Grid item xs={3}>
          <Typography>{rightColumnValue ? rightColumnValue : ''}</Typography>
        </Grid>
      )}
    </>
  );
};
