/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, makeObservable } from "mobx";
import { PropertyInformationFormStore } from "./propertyInformationFormStore";
import { PropertyStatusInformationStore } from "./propertyStatusInformationStore";

const form = {
  fields: {

  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class SubjectPropertyInformationStore extends FormStore {
  globalStore: GlobalStore;
  propertyInformationFormStore: PropertyInformationFormStore;
  propertyStatusInformationStore: PropertyStatusInformationStore;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.propertyInformationFormStore = new PropertyInformationFormStore(globalStore);
    this.propertyStatusInformationStore = new PropertyStatusInformationStore(globalStore);
    this.reset();

    makeObservable(this, {
      loadStore: action,
    })
  }

  loadStore(internalValuationReviewData: any) {
    this.propertyInformationFormStore.loadForm({ ...internalValuationReviewData?.internalValuationReview, propertyImages: internalValuationReviewData.propertyImages });
    this.propertyStatusInformationStore.loadForm({
      ...internalValuationReviewData?.propertyStatusInformation,
      googleLink: internalValuationReviewData.googleLink,
      googleMapsLink: internalValuationReviewData.googleMapsLink,
      zillowLink: internalValuationReviewData.zillowLink
    });
  }

  reset() {
    super.reset();
    this.propertyInformationFormStore.reset();
    this.propertyStatusInformationStore.reset();
  }
}
