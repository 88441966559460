import { Box, Grid } from '@material-ui/core';
import { isNil } from '@roc/feature-utils';
import { DialogState, EditableDialog, Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { useState, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { AttachmentPreview } from './attachmentPreview';
import { AddAttachmentDialog } from './addAttachmentDialog';
import { SummaryTab } from './editTaskBody/summaryTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapperInternal: {
      overflow: 'auto',
      flexGrow: 1,
    },
    container: {
      width: '100%',
    },
    warningButton: {
      color: '#ff0000',
      borderColor: '#ff0000',
      marginRight: '8px',
    },
  }),
);

export const CreateTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;
  const store = editTaskStore;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setSelectedTabIndex(0);
    generalTasksStore.fetchUserInfoAndAssigneeOptions(store.form.fields.loanId.value);
  }, [store.dialogState]);

  const getTitle = () => {
    const parentTaskId = store.form.fields.parentTaskId.value;

    if (store.dialogState === DialogState.ADD && parentTaskId) {
      return 'Subtask';
    } else {
      return `Task`;
    }
  };

  const TABS = [
    {
      label: 'SUMMARY'
    }
  ];

  const onTabChange = (e, index) => {
    setSelectedTabIndex(index);
  };

  const dialogContent = (
    <Box my={1} className={classes.container}>
      <Grid container spacing={4} className={classes.container} style={{
        paddingTop: 0
      }}>
        <Grid item xs={12} md={12}>
          <Tabs
            tabs={TABS}
            selectedTab={selectedTabIndex}
            onTabChange={onTabChange}
          />
        </Grid>
        {selectedTabIndex === 0 && <SummaryTab/>}
        <AttachmentPreview />
        <AddAttachmentDialog />
      </Grid>
    </Box>
  );

  return (
    editTaskStore.dialogState === DialogState.ADD && 
      <EditableDialog
        title={getTitle()}
        maxWidth={'lg'}
        open={!isNil(store.dialogState)}
        dialogState={store.dialogState}
        handleAdd={() => store.saveTaskWithMessage()}
        handleEdit={() => store.saveTaskWithMessage()}
        handleClose={() => store.closeDialog()}
        editButtonText={'Save'}
        addButtonText={'Create'}
        disabledButton={false}
        dialogContent={dialogContent}
        removeContentBox
      />
  );
});
