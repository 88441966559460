import { Service } from "@roc/feature-app-core";

const url = {
  GET_DOCUMENT_TYPE: '/nportal/rest/priv/property/getDocumentType',
  SAVE_DOCUMENT_TYPE: '/nportal/rest/priv/property/saveDocumentType',
};

export class PermitService extends Service{
  getPermitsDocumentType(loanId, propertyId) {
    return this.get(url.GET_DOCUMENT_TYPE,{loanId,propertyId})
  }

  savePermitDocumentType(documentTypeData){
    return this.put(url.SAVE_DOCUMENT_TYPE,documentTypeData);
  }
}