import { configure } from 'mobx';
import {
  UserStore,
  AuthenticationStore,
  LoginStore,
  EnvironmentVariables,
  WhiteLabelStore,
  SearchBarStore
} from '@roc/feature-app-core';
import { DashboardStore } from './../modules/dashboard/stores/dashboardStore';
import { PfsStore } from './../modules/dashboard/stores/pfsStore';
import {
  DrawStore,
  PointOfContactStore,
  ScopeOfWorkRevisionStore,
  DrawReviewReportStore,
  DrawRequestStoreExternal,
} from '@roc/feature-draws';
import {
  DocumentStore,
  DocumentCommunicationStore,
} from '@roc/feature-documents';
import {
  LoanStore,
  ExtensionRequestStore,
  PayoffRequestStore,
  FixFlipStore,
  SinglePropertyStore,
  RentalPortfolioStore,
  MultiFamilyStore,
  StabilizedBridgeStore,
  ForeclosureRequestStore,
  ForeclosureSummaryStore
} from '@roc/feature-loans';
import {
  AppraisalsStore,
  FindAppraiserStore,
  SubmitAppraisalStore,
} from '@roc/feature-appraisals';
import { BorrowerDetailsVerificationStore, BorrowerTaskStore, BorrowerTrackRecordStore, BorrowersStore, TpoAlreadyHaveInformationStore } from '@roc/feature-borrowers';
import { CreditBackgroundStore } from '../modules/creditBackground/stores/creditBackgroundStore';
import { LiquidityVerificationStore } from '../modules/creditBackground/stores/liquidityVerificationStore';
import { DocumentLibraryStore } from '@roc/feature-document-library';
import { CompanyProfileStore } from '../modules/companyProfile/stores/companyProfileStore';
import { CurrentNotificationStore } from '../modules/companyProfile/stores/currentNotificationStore';
import { LenderDetailsStore } from '../modules/lenderDetails/stores/lenderDetailsStore';
import { ExternalLenderStore } from '../modules/companyProfile/stores/externalLenderStore';
import FixFlipSizerStore from '../modules/fixFlipSizer/stores/fixFlipSizerStore';
import { ScopeOfWorkV2Store } from '@roc/feature-sow-shared/stores/v2';
import { RentalPricerStore } from '@roc/feature-loans';
import { BrokersStore } from '@roc/feature-brokers';
import { FindBorrowersStore } from '@roc/feature-borrowers';
import { FindLendersStore } from '../modules/lenders/stores';
import { DscrCalculatorStore } from '../modules/dscrCalculator/stores/dscrCalculatorStore';
import { LiquidityCalculatorStore } from '../modules/liquidityCalculator/stores/liquidityCalculatorStore';
import { InternalPricerStore } from '../modules/internalPricer/stores/internalPricerStore';
import { ClientInternalPricerStore } from '../modules/internalPricer/stores/clientInternalPricerStore';
import { TermStore } from '@roc/feature-loans';
import { RocUniversityStore } from '../modules/rocUniversity/stores/rocUniversityStore';
import { ProspectPropertiesStore } from '@roc/feature-prospect-properties';
import { BridgePricerStore } from '../modules/bridgePricer/stores/bridgePricerStore';
import { BaseStore } from '@roc/feature-app-core';
import { ClientBridgePricerStore } from '../modules/bridgePricer/stores/clientBridgePricerStore';
import { PlaidStore } from '@roc/feature-plaid';
import { ProposalsStore } from '@roc/feature-proposals';
import { ProposalTopicsStore } from '@roc/feature-proposals';
import { OriginatorsStore } from 'libs/feature-originator/src/originators/stores/originatorStore';
import { StabilizedBridgeAnalyzerStore } from '../modules/stabilizedBridgeAnalyzer/stores/stabilizedBridgeAnalyzerStore';

import { CompanyDropdownStore } from '@roc/feature-app-core';
import { multifamilyLiquidityCalculatorStore } from '../modules/multifamilyLiquidityCalculator';
import { PayoffNewRequestStore } from '@roc/feature-servicer-loans';
import { ResolutionStore } from 'libs/feature-borrower-review/src/stores/resolutionStore';
import { ExtensionsNewRequestStore } from '@roc/feature-extensions';
import { BridgeProfitAndDscrCalculatorStore } from '@roc/feature-bridge-profit-and-dscr-calculator';
import { CreditReportStore } from 'libs/feature-borrower-review/src/stores/creditReportStore';
import { EscrowDepositCalculatorStore } from 'apps/roc-internal-portal/src/app/modules/escrowDepositCalculator/stores/escrowDepositCalculatorStore';
import { SigmaDashboardsStore } from '../modules/sigmaDashboards/stores/sigmaDashboardsStore';
import { QuoteGeneratorStore } from '@roc/feature-quote-generator';
import { TradeStore } from 'libs/feature-browse-trades/src/stores/tradeStore';
import { AssetManagementDocumentStore } from '@roc/feature-asset-management-documents';
import { LoanClosingConditionStore } from 'libs/feature-documents/src/documents/stores/loanClosingConditionStore';
import {
  InsuranceInformationBaseStore, InsuranceInformationReviewBaseStore, InsuranceInformationStore,
  InsuranceReviewStore, InsuranceInformationReviewExternalStore
} from '@roc/feature-insurance-review';
import { InternalAppraisalCdaStore } from 'libs/feature-appraisals/src/appraisals/stores/internalAppraisalCdaStore';
import { AchFormStore } from 'libs/feature-plaid/src/stores/achFormStore';
import { ConcentrationReportsStore } from 'libs/feature-browse-trades/src/stores/concentrationReportsStore';
import { FundingAmountStore } from 'libs/feature-browse-trades/src/stores/fundingAmountStore';
import { TitleCompanyBankInformationStore } from 'libs/feature-documents/src/documents/stores/documentForms/titleCompanyBankInformation/titleCompanyBankInformationStore';
import { ManageUsersBackUpStore, ManageUsersStore } from '@roc/feature-manage-users';
import { AppraisalReportFormStore } from 'libs/feature-documents/src/documents/stores/documentForms/appraisalReportFormStore';
import { RingCentralStore } from '../modules/companyProfile/stores/ringCentralStore';
import { PhoneBurnerStore } from '../modules/companyProfile/stores/phoneBurnerStore';
import { ExternalLeadStore } from '../modules/companyProfile/stores/externalLeadStore';
import { LoanVolumeReportStore } from '../modules/loanVolumeReport/stores/loanVolumeReportStore';
import { ContactDetailsStore } from '@roc/feature-backoffice-users';
import { GeneralTasksStore } from '@roc/feature-general-tasks';
import { LinkedInStore } from '../modules/companyProfile/stores/linkedInStore';
import { CRMTaskStore } from '@roc/feature-general-tasks';
import { ReferralFeeExternalStore } from '@roc/feature-referral';
import { ForeclosureInvoiceStore, ExternalLoansDashboardStore } from '@roc/feature-title-agent'

import { BorrowerSetupToDoStore } from 'libs/feature-borrowers/src/borrowerSetupToDo';
import { SubmissionVsUnderwritingStore } from '@roc/feature-loan-details';
import { LiquidityReportStore } from 'libs/feature-liquidity-report/src/stores';
import { CompToolStore } from '@roc/feature-comp-tool';
import { ValuationReviewInternalStore } from 'apps/roc-internal-portal/src/app/modules/valuationReview/stores/valuationReviewInternalStore';
import { OneToolStore } from '@roc/feature-one-tool';
import { NewLeadStore } from 'libs/feature-backoffice-users/src/backOfficeUsers/stores/newLeadStore';
import { GmailStore } from '../modules/companyProfile/stores/gmailStore';

import { DocumentLibraryStore as ContentfulDocumentLibraryStore } from '@roc/feature-document-library-contentful';
import { InsuranceAgentExternalStore } from '@roc/feature-insurance-agents';
import { InsuranceAgentBaseStore } from 'libs/feature-insurance-agents/src/stores/insuranceAgentBaseStore';
import { LoanCommissionStore, LoanSummaryStore } from '@roc/feature-loan-summary';
import { InternalValuationReviewStore } from '@roc/feature-valuation-review-v2';

export abstract class ClientPortalBaseStore extends BaseStore {
  loginStore: LoginStore;
  whiteLabelStore: WhiteLabelStore;
  companyDropdownStore: CompanyDropdownStore
  userStore: UserStore;
  dashboardStore: DashboardStore;
  authenticationStore: AuthenticationStore;
  loanStore: LoanStore;
  fixFlipStore: FixFlipStore;
  multiFamilyStore: MultiFamilyStore;
  singlePropertyStore: SinglePropertyStore;
  rentalPortfolioStore: RentalPortfolioStore;
  pfsStore: PfsStore;
  documentStore: DocumentStore;
  drawStore: DrawStore;
  extensionRequestStore: ExtensionRequestStore;
  payoffRequestStore: PayoffRequestStore;
  drawRequestStore: DrawRequestStoreExternal;
  payoffNewRequestStore: PayoffNewRequestStore;
  pointOfContactStore: PointOfContactStore;
  borrowersStore: BorrowersStore;
  appraisalsStore: AppraisalsStore;
  internalAppraisalCdaStore: InternalAppraisalCdaStore;
  findAppraiserStore: FindAppraiserStore;
  findBorrowersStore: FindBorrowersStore;
  findLendersStore: FindLendersStore;
  submitAppraisalStore: SubmitAppraisalStore;
  documentCommunicationStore: DocumentCommunicationStore;
  creditBackgroundStore: CreditBackgroundStore;
  liquidityVerificationStore: LiquidityVerificationStore;
  documentLibraryStore: DocumentLibraryStore;
  salesforceLenderStore: ExternalLenderStore;
  salesforceLeadStore: ExternalLeadStore;
  companyProfileStore: CompanyProfileStore;
  lenderDetailsStore: LenderDetailsStore;
  fixFlipSizerStore: FixFlipSizerStore;
  currentNotificationStore: CurrentNotificationStore;
  scopeOfWorkV2Store: ScopeOfWorkV2Store;
  scopeOfWorkRevisionStore: ScopeOfWorkRevisionStore;
  rentalPricerStore: RentalPricerStore;
  dscrCalculatorStore: DscrCalculatorStore;
  brokersStore: BrokersStore;
  newLeadStore: NewLeadStore;
  liquidityCalculatorStore: LiquidityCalculatorStore;
  multifamilyLiquidityCalculatorStore: multifamilyLiquidityCalculatorStore;
  internalPricerStore: InternalPricerStore;
  clientInternalPricerStore: ClientInternalPricerStore;
  termStore: TermStore;
  rocUniversityStore: RocUniversityStore;
  sigmaDashboardsStore: SigmaDashboardsStore;
  prospectPropertiesStore: ProspectPropertiesStore;
  bridgePricerStore: BridgePricerStore;
  clientBridgePricerStore: ClientBridgePricerStore;
  stabilizedBridgeStore: StabilizedBridgeStore;
  plaidStore: PlaidStore;
  proposalsStore: ProposalsStore;
  proposalTopicStore: ProposalTopicsStore;
  originatorStore: OriginatorsStore;
  stabilizedBridgeAnalyzerStore: StabilizedBridgeAnalyzerStore;
  resolutionStore: ResolutionStore;
  appraisalReportFormStore: AppraisalReportFormStore;
  creditReportStore: CreditReportStore;
  borrowerTaskStore: BorrowerTaskStore;
  extensionsNewRequestStore: ExtensionsNewRequestStore;
  bridgeProfitAndDscrCalculatorStore: BridgeProfitAndDscrCalculatorStore;
  escrowDepositCalculatorStore: EscrowDepositCalculatorStore;
  drawReviewReportStore: DrawReviewReportStore;
  searchBarStore: SearchBarStore;
  quoteGeneratorStore: QuoteGeneratorStore;
  tradeStore: TradeStore;
  assetManagementDocumentStore: AssetManagementDocumentStore;
  concentrationReportsStore: ConcentrationReportsStore;
  fundingAmountStore: FundingAmountStore;
  loanClosingConditionStore: LoanClosingConditionStore;
  insuranceReviewStore: InsuranceReviewStore;
  titleCompanyBankInformationStore: TitleCompanyBankInformationStore;
  achFormStore: AchFormStore;
  manageUsersStore: ManageUsersStore;
  ringCentralStore: RingCentralStore;
  phoneBurnerStore: PhoneBurnerStore;
  manageUsersBackUpStore: ManageUsersBackUpStore;
  loanVolumeReportStore: LoanVolumeReportStore;
  borrowerDetailsVerificationStore: BorrowerDetailsVerificationStore;
  contactDetailsStore: ContactDetailsStore;
  linkedInStore: LinkedInStore;
  foreclosureRequestStore: ForeclosureRequestStore;
  foreclosureSummaryStore: ForeclosureSummaryStore;
  tpoAlreadyHaveInformationStore: TpoAlreadyHaveInformationStore;
  generalTasksStore: GeneralTasksStore;
  crmTaskStore: CRMTaskStore;
  borrowerTrackRecordStore: BorrowerTrackRecordStore;
  referralFeeStore: ReferralFeeExternalStore;
  borrowerSetupToDoStore: BorrowerSetupToDoStore;
  foreclosureInvoiceStore: ForeclosureInvoiceStore;
  externalLoansDashboardStore: ExternalLoansDashboardStore;
  liquidityReportStore: LiquidityReportStore;
  submissionVsUnderwritingStore: SubmissionVsUnderwritingStore;
  compToolStore: CompToolStore;
  valuationReviewReportStore: ValuationReviewInternalStore;
  oneToolStore: OneToolStore;
  gmailStore: GmailStore;
  documentLibraryStoreNew: ContentfulDocumentLibraryStore;
  insuranceAgentStore: InsuranceAgentBaseStore;
  loanSummaryStore: LoanSummaryStore;
  loanCommissionStore: LoanCommissionStore;
  internalValuationReviewStore: InternalValuationReviewStore;
  insuranceInformationStore: InsuranceInformationBaseStore;
  insuranceInformationReviewStore: InsuranceInformationReviewBaseStore;

  constructor(routeBasePath: string, routeBaseUrl: string, environmentVariables: EnvironmentVariables) {
    super(routeBasePath, routeBaseUrl, environmentVariables);
    this.loginStore = new LoginStore(this.globalStore);
    this.whiteLabelStore = new WhiteLabelStore(
      this.globalStore,
      this.environmentStore
    );
    this.userStore = new UserStore(this.globalStore, this.whiteLabelStore);
    this.salesforceLenderStore = new ExternalLenderStore(this.globalStore, this.userStore);
    this.companyProfileStore = new CompanyProfileStore(
      this.globalStore,
      this.salesforceLenderStore
    );
    this.companyDropdownStore = new CompanyDropdownStore(
      this.globalStore,
      this.userStore,
      this.whiteLabelStore,
      this.companyProfileStore
    );
    this.authenticationStore = new AuthenticationStore(
      this.globalStore,
      this.environmentStore
    );
    this.documentLibraryStore = new DocumentLibraryStore(this.globalStore);
    this.brokersStore = new BrokersStore(this.globalStore);
    this.plaidStore = new PlaidStore(this.globalStore);
    this.originatorStore = new OriginatorsStore(this.globalStore, this.userStore);

    this.fixFlipStore = new FixFlipStore(
      this.globalStore,
      this.userStore,
      this.brokersStore
    );
    this.multiFamilyStore = new MultiFamilyStore(
      this.globalStore,
      this.userStore,
      this.brokersStore
    );
    this.singlePropertyStore = new SinglePropertyStore(
      this.globalStore,
      this.userStore,
      this.brokersStore
    );
    this.stabilizedBridgeStore = new StabilizedBridgeStore(
      this.globalStore,
      this.userStore,
      this.brokersStore
    );
    this.rentalPortfolioStore = new RentalPortfolioStore(
      this.globalStore,
      this.userStore,
      this.documentLibraryStore,
      this.brokersStore
    );
    this.loanStore = new LoanStore(
      this.globalStore,
      this.documentLibraryStore,
      this.fixFlipStore,
      this.multiFamilyStore,
      this.singlePropertyStore,
      this.rentalPortfolioStore,
      this.stabilizedBridgeStore,
      this.userStore,
      this.companyDropdownStore
    );
    this.documentStore = new DocumentStore(this.loanStore, this.globalStore);
    this.pfsStore = new PfsStore(this.globalStore, this.userStore);
    this.extensionRequestStore = new ExtensionRequestStore(
      this.globalStore,
      this.loanStore
    );
    this.payoffRequestStore = new PayoffRequestStore(
      this.globalStore,
      this.loanStore
    );
    this.pointOfContactStore = new PointOfContactStore(
      this.globalStore,
      this.loanStore
    );
    this.borrowersStore = new BorrowersStore(this.globalStore, this.userStore);
    this.appraisalsStore = new AppraisalsStore(this.globalStore);
    this.internalAppraisalCdaStore = new InternalAppraisalCdaStore(this.globalStore);
    this.findAppraiserStore = new FindAppraiserStore(this.globalStore);
    this.findLendersStore = new FindLendersStore(this.globalStore);
    this.findBorrowersStore = new FindBorrowersStore(this.globalStore);
    this.submitAppraisalStore = new SubmitAppraisalStore(this.globalStore, this.userStore);
    this.documentCommunicationStore = new DocumentCommunicationStore(
      this.globalStore,
      this.documentStore
    );
    this.creditBackgroundStore = new CreditBackgroundStore(this.globalStore, this.userStore);
    this.liquidityVerificationStore = new LiquidityVerificationStore(this.globalStore, this.userStore);
    this.lenderDetailsStore = new LenderDetailsStore(
      this.globalStore,
      this.salesforceLenderStore
    );
    this.drawStore = new DrawStore(this.globalStore);
    this.drawRequestStore = new DrawRequestStoreExternal(
      this.globalStore,
      this.pointOfContactStore,
      this.drawStore,
      this.loanStore
    );

    this.drawReviewReportStore = new DrawReviewReportStore(this.globalStore);
    this.payoffNewRequestStore = new PayoffNewRequestStore(
      this.globalStore,
      this.loanStore
    );
    this.fixFlipSizerStore = new FixFlipSizerStore(this.globalStore, this.userStore);
    this.currentNotificationStore = new CurrentNotificationStore(
      this.globalStore
    );
    this.scopeOfWorkV2Store = new ScopeOfWorkV2Store(
      this.globalStore,
      this.userStore
    );
    this.scopeOfWorkRevisionStore = new ScopeOfWorkRevisionStore(
      this.globalStore,
      this.loanStore,
      this.drawStore,
    );

    this.rentalPricerStore = new RentalPricerStore(this.globalStore, this.userStore, this.singlePropertyStore, this.rentalPortfolioStore);
    this.dscrCalculatorStore = new DscrCalculatorStore(this.globalStore);
    this.liquidityCalculatorStore = new LiquidityCalculatorStore(
      this.globalStore
    );
    this.multifamilyLiquidityCalculatorStore = new multifamilyLiquidityCalculatorStore(
      this.globalStore
    );
    this.internalPricerStore = new InternalPricerStore(this.globalStore);
    this.clientInternalPricerStore = new ClientInternalPricerStore(
      this.globalStore
    );
    this.rocUniversityStore = new RocUniversityStore(this.globalStore);
    this.prospectPropertiesStore = new ProspectPropertiesStore(
      this.globalStore
    );
    this.sigmaDashboardsStore = new SigmaDashboardsStore(this.globalStore);
    this.bridgePricerStore = new BridgePricerStore(this.globalStore);
    this.clientBridgePricerStore = new ClientBridgePricerStore(
      this.globalStore
    );
    this.proposalsStore = new ProposalsStore(
      this.globalStore,
      this.userStore
    );
    this.proposalTopicStore = new ProposalTopicsStore(
      this.globalStore,
      this.userStore
    )
    this.stabilizedBridgeAnalyzerStore = new StabilizedBridgeAnalyzerStore(
      this.globalStore
    );
    this.resolutionStore = new ResolutionStore(
      this.globalStore,
      this.documentStore,
    );
    this.appraisalReportFormStore = new AppraisalReportFormStore(
      this.globalStore,
      this.documentStore,
    );
    this.creditReportStore = new CreditReportStore(
      this.globalStore,
      this.documentStore,
    );
    this.borrowerTaskStore = new BorrowerTaskStore(
      this.globalStore,
      this.documentStore
    );
    this.extensionsNewRequestStore = new ExtensionsNewRequestStore(this.globalStore, this.loanStore);
    this.bridgeProfitAndDscrCalculatorStore = new BridgeProfitAndDscrCalculatorStore(this.globalStore);
    this.escrowDepositCalculatorStore = new EscrowDepositCalculatorStore(
      this.globalStore
    );
    this.searchBarStore = new SearchBarStore(
      this.globalStore,
      this.companyDropdownStore
    )
    this.quoteGeneratorStore = new QuoteGeneratorStore(this.globalStore, this.userStore);
    this.loanClosingConditionStore = new LoanClosingConditionStore(this.loanStore, this.documentStore, this.globalStore);
    this.insuranceReviewStore = new InsuranceReviewStore(this.globalStore);
    this.tradeStore = new TradeStore(this.globalStore);
    this.assetManagementDocumentStore = new AssetManagementDocumentStore(this.globalStore);
    this.achFormStore = new AchFormStore(this.globalStore);
    this.concentrationReportsStore = new ConcentrationReportsStore(this.globalStore);
    this.fundingAmountStore = new FundingAmountStore(this.globalStore);
    this.titleCompanyBankInformationStore = new TitleCompanyBankInformationStore(this.loanStore, this.documentStore, this.globalStore);
    this.manageUsersStore = new ManageUsersStore(this.globalStore);
    this.ringCentralStore = new RingCentralStore(this.globalStore);
    this.phoneBurnerStore = new PhoneBurnerStore(this.globalStore, this.userStore, this.ringCentralStore);
    this.manageUsersBackUpStore = new ManageUsersBackUpStore(this.globalStore);
    this.newLeadStore = new NewLeadStore(this.globalStore, this.userStore);
    this.loanVolumeReportStore = new LoanVolumeReportStore(this.globalStore);
    this.salesforceLeadStore = new ExternalLeadStore(this.globalStore, this.newLeadStore, this.userStore);
    this.borrowerDetailsVerificationStore = new BorrowerDetailsVerificationStore(this.globalStore);
    this.contactDetailsStore = new ContactDetailsStore(this.globalStore);
    this.linkedInStore = new LinkedInStore(this.globalStore);
    this.foreclosureRequestStore = new ForeclosureRequestStore(this.globalStore);
    this.foreclosureSummaryStore = new ForeclosureSummaryStore(this.globalStore);
    this.tpoAlreadyHaveInformationStore = new TpoAlreadyHaveInformationStore(this.globalStore);
    this.generalTasksStore = new GeneralTasksStore(this.globalStore, this.userStore);
    this.crmTaskStore = new CRMTaskStore(this.globalStore);
    this.borrowerTrackRecordStore = new BorrowerTrackRecordStore(this.globalStore, this.documentStore);
    this.referralFeeStore = new ReferralFeeExternalStore(this.globalStore);
    this.borrowerSetupToDoStore = new BorrowerSetupToDoStore(this.globalStore);
    this.foreclosureInvoiceStore = new ForeclosureInvoiceStore(this.globalStore);
    this.externalLoansDashboardStore = new ExternalLoansDashboardStore(this.globalStore);
    this.liquidityReportStore = new LiquidityReportStore(this.globalStore);
    this.submissionVsUnderwritingStore = new SubmissionVsUnderwritingStore(this.globalStore, this.loanStore);
    this.oneToolStore = new OneToolStore(this.globalStore, this.userStore);
    this.compToolStore = new CompToolStore(this.globalStore, this.userStore);
    this.valuationReviewReportStore = new ValuationReviewInternalStore(this.globalStore);
    this.gmailStore = new GmailStore(this.globalStore);
    this.documentLibraryStoreNew = new ContentfulDocumentLibraryStore(this.globalStore, this.environmentStore);
    this.insuranceAgentStore = new InsuranceAgentExternalStore(this.globalStore);
    this.loanSummaryStore = new LoanSummaryStore(this.globalStore);
    this.loanCommissionStore = new LoanCommissionStore(
      this.globalStore,
      this.userStore
    );
    this.internalValuationReviewStore = new InternalValuationReviewStore(this.globalStore);
    this.insuranceInformationStore = new InsuranceInformationStore(this.globalStore);
    this.insuranceInformationReviewStore = new InsuranceInformationReviewExternalStore(this.globalStore, this.insuranceInformationStore);
  }
}

configure({
  enforceActions: 'never',
});
