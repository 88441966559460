import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Button, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { GeneralTasksToolbar } from '../../components/generalTasksToolbar';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { TaskListView } from '../taskListView';
import { EditSupportTaskDialog } from './editSupportTaskDialog';
import { useEffect } from 'react';
import { isNil } from '@roc/feature-utils';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    marginTop: '15px',
  },
  container: {
    width: '100%',
  },
}))

export const SupportTasksComponent = observer(() => {
  const classes = useStyles();
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, supportTasksStore, taskListGridStore } = generalTasksStore;

  useEffect(() => {
    if (isNil(editTaskStore.dialogState)) {
      supportTasksStore.fetchUnreadMessagesCount();
      taskListGridStore.resetAndFetchGridData();
    }
  }, [editTaskStore.dialogState, supportTasksStore.openModal]);

  return (

    <>
      <Grid container className={classes.mainContainer} xs={12} md={12}>
        <Box className={classes.container} display="flex" flexDirection="column" height={'80vh'} >
          <Box pb={2}>
            <Paper className={classes.paper}>
              <GeneralTasksToolbar />
            </Paper>
          </Box>
          <Box pb={2} pl={2} >
            <Typography variant="body1">
              Please select new task and give the information needed to solve your issue!
            </Typography>
          </Box>
          <Box flex={1}>
            <Paper className={classes.paper}>
              <Box pb={2} textAlign={"right"}>
                <Button
                  testId="add-task"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => editTaskStore.openAddTask()}
                >
                  <Add />
                  New Task
                </Button>
              </Box>
              <TaskListView />
            </Paper>
          </Box>
        </Box>
        <EditSupportTaskDialog />
      </Grid>
    </>


  );
});
