import { Box, Link, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, CloudUpload, CloudUploadOutlined, Delete, Description } from '@material-ui/icons';
import { Button, ConfirmDialog, IconButton, StandardDialog, TextField } from '@roc/ui';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const EXTENSION_REGEX = /.[^.]+$/;

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '10vw',
  },
  top: {
    marginBottom: 10,
  },
  dragArea: {
    border: '1px solid #CBCBCB',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileContainer: {
    border: '1px solid #CBCBCB',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
  },

  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 800,
    lineHeight: '21.98px',
    letterSpacing: '0.1px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#6B6C71',
  },
  close: {
    cursor: 'pointer',
    fontSize: '14px'
  },
  fileIcon: {
    fontSize: '20px'
  }
}));

export interface NewFileUpload {
  name: string;
  file: File;
  disabled?: boolean;
}

interface IFileUploadModalProps {
  title: string;
  open: boolean;
  top?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  showUploadButton?: boolean;
  showSimulateDelete?: boolean;
  onClose: () => void;
  onUpload: (uploads: NewFileUpload[]) => void;
}

interface FileUploadItemProps {
  name: string;
  file: File;
  disabled?: boolean;
  showUploadButton?: boolean;
  showSimulateDeleteButton?: boolean
  onDrop?: (acceptedFiles: File[]) => void;
  onSaveName: (name: string) => void;
  onRemove: () => void;
}

export const NewFileUploadItem = (props: FileUploadItemProps) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [newName, setNewName] = useState(() =>
    props.name?.replace(EXTENSION_REGEX, '')
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    multiple: false,
    maxFiles: 1,
  });

  const onRemove = () => {
    props.onRemove();
  };

  return (
    <>
      <Box className={classes.fileContainer}>
        <Description className={classes.fileIcon} />
        <div className={classes.fileName}>{props.name}</div>
        {props.showSimulateDeleteButton && !props.disabled &&
          <Tooltip title="Delete File">
            <IconButton
              testId="deleteFile"
              size="small"
              color="primary"
              onClick={() => {
                setConfirmationModalOpen(true);
              }}
            >
              <Delete style={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
        }
        {props.showUploadButton && !props.disabled &&
          <Tooltip title="Upload File">
            <>
              <input
                id="file-input"
                type="file"
                {...getInputProps()}
                style={{ display: 'none' }}
              />
              <IconButton
                testId="uploadFile"
                size="small"
                color="primary"
                onClick={() => document.getElementById('file-input')?.click()}
              >
                <CloudUploadOutlined style={{ fontSize: 20 }} />
              </IconButton>
            </>
          </Tooltip>
        }
      </Box>
      <ConfirmDialog
        open={confirmationModalOpen}
        confirmButtonText={'Yes'}
        handleClose={() => setConfirmationModalOpen(false)}
        handleCancel={() => setConfirmationModalOpen(false)}
        handleConfirm={onRemove}
        title={'Delete document'}
        body={`Are you sure you want to delete the document?`}
      />
    </>
  );
};

export const NewFileUploadModal = ({
  title,
  open,
  top,
  multiple,
  disabled,
  showUploadButton = false,
  showSimulateDelete = false,
  onClose,
  onUpload,
}: IFileUploadModalProps) => {
  const [items, setItems] = useState<NewFileUpload[]>([]);

  const classes = useStyles();

  const uploadAction = () => {
    onUpload(items);
    closeAction();
  };

  const closeAction = () => {
    onClose();
    setItems([]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    const newItems = acceptedFiles.map(file => ({
      name: file.name,
      file: file,
    }));
    setItems(prevItems => [...(multiple ? prevItems : []), ...newItems]);
  };

  const onRemove = (index: number) => {
    setItems(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onRename = (index: number, newName: string) => {
    setItems(prevItems => {
      const items = [...prevItems];
      items[index].name = newName;
      return items;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    maxFiles: multiple ? 0 : 1,
  });

  return (
    <StandardDialog
      title={title || 'UPLOAD FILES'}
      open={open}
      handleClose={closeAction}
      maxWidth="md"
      dialogActions={
        <Box flex="1" display="flex" justifyContent="flex-end">
          <Button style={{ marginRight: 16 }}
            testId="cancel"
            variant="outlined"
            color="primary"
            onClick={closeAction}
          >
            Cancel
          </Button>
          <Button
            testId="upload"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={disabled || !items.length}
            onClick={uploadAction}
          >
            Upload
          </Button>
        </Box>
      }
      dialogContent={
        <>
          {top && <Box className={classes.top}>{top}</Box>}
          <Box {...getRootProps()} className={classes.dragArea}>
            <input {...getInputProps()} />
            <CloudUpload className={classes.icon} />
            {isDragActive ? (
              <p>Drop the file(s) here...</p>
            ) : (
              <>
                <p>Drag and drop here</p>
                <p>or browse</p>
              </>
            )}
          </Box>
          <Box>
            {items.map((item, i) => (
              <NewFileUploadItem
                key={item.file.name}
                name={item.name}
                file={item.file}
                showSimulateDeleteButton={showSimulateDelete}
                showUploadButton={showUploadButton}
                onSaveName={newName => onRename(i, newName)}
                onRemove={() => onRemove(i)}
              />
            ))}
          </Box>
        </>
      }
    />
  );
};
