import { ConfirmDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanActionsStore } from '../hooks/useLoanActionsStore';

export const ResendFundingTemplateModal = observer(
  ({ open, handleClose }) => {
    const { loanActionsStore } = useLoanActionsStore();

    const confirm = () => {
      loanActionsStore.resendFundingTemplate();
      handleClose();
    };

    return (
      <ConfirmDialog
        open={open}
        confirmButtonText={'Yes'}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={confirm}
        title={'Resend Funding Template'}
        body={`Are you sure you want to resend the Funding Template?`}
      />
    );
  }
);
