import { Grid } from '@material-ui/core';
import {
  createSelectField,
  createTextField,
  createNumberFormatField,
  createDateField,
  createCurrencyField,
  createRadioField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { UNDERWRITING, yesNoOptionsBoolean } from '@roc/feature-utils';
import { FileUploadDownloadArea } from '../common/fileUploadDownloadArea';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { Document } from '@roc/feature-types';
import { useEffect } from 'react';
import { TERM_LEASE } from '../../../stores/documentForms/leaseInformation/leaseInformationFormStore';

interface LeaseInformationProps {
  loan: any;
  document: Document;
}

const LeaseInformation = observer((props: LeaseInformationProps) => {
  const { loan, document } = props;
  const disableDownload = (!document?.dropboxPath || document?.dropboxPath.trim().length === 0);
  const { documentStore } = useDocumentStore();
  const { leaseInformationFormStore } = documentStore.documentFormStore;

  useEffect(() => {
    const downloadExistingFile = !disableDownload ? (document) => documentStore.fetchDocumentFile(document) : null;
    leaseInformationFormStore.fetchLeaseInformation(loan, document, downloadExistingFile);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {createRadioField({
          store: leaseInformationFormStore,
          testId: 'vacant',
          fieldName: 'vacant',
          label: 'Vacant',
          options: yesNoOptionsBoolean,
          row: true,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store: leaseInformationFormStore,
          testId: 'tenantName',
          fieldName: 'tenantName',
          label: 'Tenant Name',
          disabled: leaseInformationFormStore.form.fields.vacant.value
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store: leaseInformationFormStore,
          testId: 'leaseType',
          fieldName: 'leaseType',
          label: 'Lease Type',
          options: leaseInformationFormStore.leaseTypes,
          disabled: leaseInformationFormStore.form.fields.vacant.value
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          label: 'Monthly Rent',
          fieldName: 'monthlyRent',
          testId: 'monthlyRent',
          store: leaseInformationFormStore,
          disabled: leaseInformationFormStore.form.fields.vacant.value
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createDateField({
          store: leaseInformationFormStore,
          testId: 'startDate',
          fieldName: 'startDate',
          label: 'Start Date',
          disabled: leaseInformationFormStore.form.fields.vacant.value
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          label: 'Market Rent',
          fieldName: 'marketRent',
          testId: 'marketRent',
          store: leaseInformationFormStore,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createDateField({
          store: leaseInformationFormStore,
          testId: 'endDate',
          fieldName: 'endDate',
          label: 'End Date',
          disabled: leaseInformationFormStore.form.fields.vacant.value || leaseInformationFormStore.form.fields.leaseType.value !== TERM_LEASE
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createNumberFormatField({
          store: leaseInformationFormStore,
          testId: 'unitNumber',
          fieldName: 'unitNumber',
          label: 'Unit Number',
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createRadioField({
          store: leaseInformationFormStore,
          testId: 'sectionEightFourTwo',
          fieldName: 'sectionEightFourTwo',
          label: 'Section 8/42',
          options: yesNoOptionsBoolean,
          row: true,
        })}
      </Grid>
      <Grid item xs={12}>
        <FileUploadDownloadArea
          file={leaseInformationFormStore.leaseInformationFile}
          onChange={file => leaseInformationFormStore.setLeaseInformationFile(file)}
          onUpload={(file) => documentStore.uploadDocument(file, document, UNDERWRITING)}
          onDownload={() => documentStore.downloadDocument(document)}
          disableDownload={disableDownload}
          infoMessage={disableDownload ? `${document.taskName} has not been uploaded` : `${document.taskName} was last uploaded at ${document.lastUpdateDate}`}
        />
      </Grid>
    </Grid>
  );
});

export default LeaseInformation;