import {
  Box,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import { isNil, sanitizeTestId } from '@roc/feature-utils';
import {
  DateField,
  MultiSelectField,
  TextField,
  useVirtualizedAutocomplete,
} from '@roc/ui';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Person } from '@material-ui/icons';
import { FieldLabel, VirtualizedAutocomplete } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  popper: {
    minWidth: '450px',
    zIndex: 9999,
  },
}));

export const TaskAssigneeField = ({
  label,
  value,
  onChange,
  assigneeOptions,
  disabled,
}) => {
  const classes = useStyles();
  const options = (assigneeOptions ?? []).map(assignee => ({
    ...assignee,
    label: assignee.assignedToLoan && !isNil(assignee.roleDisplayName) ? `${assignee.firstName} ${assignee.lastName} | ${assignee.roleDisplayName}` : `${assignee.firstName} ${assignee.lastName}`,
    value: '' + assignee.userId,
  }));

  const PopperComponent = useMemo(() => {
    return props => <Popper {...props} className={classes.popper} />;
  }, []);

  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <VirtualizedAutocomplete
        optionHeight={48}
        id={sanitizeTestId(label)}
        value={{
          label: (options || []).find(o => o.value === '' + value)?.label,
          value: '' + value,
        }}
        options={options}
        renderInput={params => (
          <TextField
            testId={sanitizeTestId(label)}
            variant="outlined"
            {...params}
            // InputProps={{
            //   ...params.InputProps,
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <Person />
            //     </InputAdornment>
            //   ),
            // }}
          />
        )}
        getOptionLabel={option => option?.label}
        getOptionSelected={(option, selectedOption) =>
          option.value === selectedOption.value
        }
        onChange={(event, selectedOption) => {
          onChange(event, selectedOption.value);
        }}
        fullWidth
        disabled={disabled}
        PopperComponent={PopperComponent}
        renderOption={(option, { selected }) => (
          <>
            <Grid container alignItems={'center'} wrap={'nowrap'} spacing={2}>
              <Grid item>
                <Typography>{`${option.firstName} ${option.lastName}`}</Typography>
              </Grid>
              <Grid item>
                <Chip
                  variant="default"
                  size="small"
                  label={option.role}
                  style={{
                    height: 18,
                    marginLeft: '4px',
                    backgroundColor: '#d3fbac',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      />
    </>
  );
};
