import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, ConfirmationButton, ConfirmDialog, Paper, SelectField, TextField } from '@roc/ui';
import { Box, Checkbox, Chip, Collapse, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined, OutlinedFlag, UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Layout } from '@roc/feature-app-core';
import SaveButtonWithPermissions from 'libs/ui/src/lib/components/shared/save-button-with-permissions';
import { InsuranceQuotesTable } from './insuranceQuotesTable';
import { InsuranceInformationReviewBaseStore } from '../stores';

const useStyles = makeStyles((theme: Theme) => ({
  titleRow: {
    height: '1.8em',
    borderLeft: '1px solid',
    borderLeftColor: '#e6f6fe',
    borderRight: '1px solid',
    borderRightColor: '#e6f6fe'
  },
  headerCell: {
    fontWeight: 'bold',
    padding: 0,
    textAlign: 'left',
  },
  headerCellCenter: {
    textAlign: 'center'
  },
  tableContainerWrapper: {
    padding: '8px',
    backgroundColor: '#F0F0F0',
  },
  tableContainer: {
    border: '1px #e0e0e0 solid',
    backgroundColor: '#FFF',
  },
  table: {
    margin: 0,
    padding: 0,
    '& td': {
      padding: '5px 0',
    },
    '& th:first-child, td[rowSpan], $addNewItemRow td, td:first-child': {
      paddingLeft: theme.spacing(2),
      paddingRight: '3px',
    },
    '& table tr:last-child td, & table td[rowSpan]': {
      borderBottom: 'none',
    },
    '& input': {
      padding: '12px',
      fontSize: '0.875rem'
    }
  },
  button: {
    fontSize: '16px',
    fontFamily: 'Open Sans,Lato,sans-serif',
    textTransform: 'capitalize',
    margin: theme.spacing(1, 0, 1, 0),
    width: '240px',
    color: '#00B2FF',
    backgroundColor: '#E2F6FF',
    borderColor: '#00B2FF'
  },
  flag: {
    fontSize: '25px'
  },
  title: {
    color: '#757575',
    fontSize: 20
  },
  reviewSectionContainer: {
    padding: '20px 0px',
  },
  iconWhite: {
    color: '#FFF'
  },
  successStyle: {
    color: theme.palette.success.dark
  },
  buttonError: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF'
  },
  chipError: {
    color: '#FFF',
    backgroundColor: theme.palette.error.main,
    borderRadius: '8px',
    borderColor: 'transparent',
    width: '55px',
    "&&:hover": {
      backgroundColor: theme.palette.error.main
    },
    "&&:focus": {
      backgroundColor: theme.palette.error.main
    }
  },
  chipSuccess: {
    color: theme.palette.success.dark,
    backgroundColor: '#FFF',
    borderRadius: '8px',
    borderColor: theme.palette.success.dark,
    width: '55px',
  },
  itemDisplayNameCell: {
    width: '20%',
    textAlign: 'left',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  dataCell: {
    width: '30%',
    textAlign: 'left',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  needsRevisionFlagCell: {
    width: '10%',
    textAlign: 'center',
  },
  sendForRevisionCell: {
    width: '10%',
    textAlign: 'center',
  },
  cell: {
    width: '20%',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  noBorderCell: {
    border: 0,
  },
}));

export const columns = [
  { name: 'Fields', mapping: 'itemDisplayName' },
  { name: 'Extracted Data', mapping: 'data' },
  { name: 'Flagged', mapping: 'needsRevisionFlag', className: 'headerCellCenter' },
  { name: 'Resolution?', mapping: 'sendForRevision', className: 'headerCellCenter' },
  { name: 'Reviewer Comment', mapping: 'reviewerComment', className: 'headerCellCenter' }
];

const ReviewTableItemValue = observer((props) => {
  const { item, column, store } = props;
  const { mapping } = column;
  const value = item[mapping];
  const classes = useStyles();

  switch (mapping) {
    case 'data':
      return <TextField
        testId="data"
        variant="outlined"
        standaloneLabel
        value={value}
        fullWidth
        onChange={e =>
          store.handleItemChange(mapping, item.uniqueId, item.sectionName, item.itemDisplayName, e.target.value)
        }
      />
    case 'reviewerComment':
      return <TextField
        testId="reviewerComment"
        variant="outlined"
        standaloneLabel
        value={value}
        multiline
        maxRows={4}
        fullWidth
        onChange={e =>
          store.handleItemChange(mapping, item.uniqueId, item.sectionName, item.itemDisplayName, e.target.value)
        }
      />
    case 'needsRevisionFlag':
      const className = !!value ? classes.chipError : classes.chipSuccess;
      return <Chip
        variant="outlined"
        size="small"
        icon={<OutlinedFlag className={!!value ? classes.iconWhite : classes.successStyle} />}
        label={!!value ? 'Yes' : 'No'}
        className={className}
        style={{
          fontWeight: 0,
        }}
        clickable={true}
        onClick={(e) => store.handleItemChange(mapping, item.uniqueId, item.sectionName, item.itemDisplayName, value)}
      />
    case 'sendForRevision':
      return <Checkbox
        checked={value}
        size='small'
        onChange={e => store.handleItemChange(mapping, item.uniqueId, item.sectionName, item.itemDisplayName, value)}
        color="secondary"
      />
    case 'itemDisplayName':
    default:
      return <Typography variant="body2">{value}</Typography>
  }
})

const ReviewTableSection = observer(({ sectionData, store, expanded }
  : { sectionData: any, store: InsuranceInformationReviewBaseStore, expanded: boolean }) => {
  const { sectionName, items } = sectionData;
  const [open, setOpen] = useState(expanded);
  const classes = useStyles();

  useEffect(() => {
    setOpen(expanded);
  }, [expanded])

  const getClassNameByColumn = (mapping) => {
    switch (mapping) {
      case 'itemDisplayName':
        return classes.itemDisplayNameCell;
      case 'data':
        return classes.dataCell;
      case 'needsRevisionFlag':
        return classes.needsRevisionFlagCell;
      case 'sendForRevision':
        return classes.sendForRevisionCell;
      default:
        return classes.cell;
    }
  }

  return (
    <Grid container>
      <Grid item container spacing={1} style={{ margin: '10px 25px' }} xs={12}>
        <Grid item>
          {open
            ? <IndeterminateCheckBoxOutlined onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
            : <AddBoxOutlined onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} />
          }
        </Grid>
        <Grid item>
          <Typography>{sectionName}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container className={classes.tableContainerWrapper} xs={12}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.titleRow}>
                    {columns.map(column => {
                      return (
                        <TableCell className={`${classes.headerCell} ${classes[column.className] ?? ''}`}> {column.name} </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item, index) => (
                    <TableRow>
                      {columns.map(column => {
                        return (
                          <TableCell
                            className={getClassNameByColumn(column.mapping)}
                            align="center"
                          >
                            <ReviewTableItemValue
                              item={item}
                              column={column}
                              index={index}
                              store={store}
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
});

const ReviewTable = observer(({ store, setResolutionConfirmOpen }
  : { store: InsuranceInformationReviewBaseStore, setResolutionConfirmOpen: any }) => {
  const [expanded, setExpanded] = useState(true);

  const classes = useStyles();

  return (
    <Paper style={{ border: '1.5px solid #E0E0E0FF' }}>
      <Grid container className={classes.reviewSectionContainer}>
        <Grid item container justifyContent="space-between" alignItems="center" style={{ padding: '0px 15px 5px 15px' }}>
          <Grid item xs={6}>
            <Typography variant="h5">Review Data Points</Typography>
          </Grid>
          <Grid item container spacing={1} xs={6} justifyContent='flex-end'>
            <Grid item>
              <Button testId="expandAll" onClick={() => setExpanded(true)}
                variant="outlined"
                style={{
                  letterSpacing: 'normal',
                  textTransform: 'none',
                }}>
                <Box height={'31px'} display="flex" alignItems="center">
                  <UnfoldMore />&nbsp;&nbsp;Expand All
                </Box>
              </Button>
            </Grid>
            <Grid item>
              <Button testId="collapseAll" onClick={() => setExpanded(false)}
                variant="outlined"
                style={{
                  letterSpacing: 'normal',
                  textTransform: 'none',
                }}>
                <Box height={'31px'} display="flex" alignItems="center">
                  <UnfoldLess />&nbsp;&nbsp;Collapse All
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='center'
          spacing={1}
          style={{ padding: '5px 11px', backgroundColor: '#F0F0F0', margin: '0' }}
        >
          <Grid item>
            <Typography variant="caption" style={{ color: 'red' }}>{`${store.numberOfResolutionsSelected} Item(s) selected`}</Typography>
          </Grid>
          <Grid item>
            <Button
              testId="requestResolution"
              onClick={() => setResolutionConfirmOpen(true)}
              variant='contained'
              className={classes.buttonError}
              disabled={!store.numberOfResolutionsSelected}
            >Request Resolution</Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!store.insuranceReviewData.length && (
            <TableRow>
              <TableCell colSpan={10} className={classes.noBorderCell}>
                <Box>
                  No insurance information have been added yet
                </Box>
              </TableCell>
            </TableRow>
          )}
          {store.insuranceReviewData.map(sectionData => {
            return (
              <ReviewTableSection sectionData={sectionData} store={store} expanded={expanded} />
            )
          })}
        </Grid>
      </Grid>
    </Paper>
  )
});

export type InsuranceInformationReviewFormProps = {
  store: InsuranceInformationReviewBaseStore;
  loanId: string;
  showTitle?: boolean;
};

export const InsuranceInformationReviewForm = observer((props: InsuranceInformationReviewFormProps) => {
  const { store, loanId, ...rest } = props;
  const [resolutionConfirmOpen, setResolutionConfirmOpen] = useState(false);
  const { insuranceInformationStore } = store;

  useEffect(() => {
    if (loanId) {
      store.getInsuranceQuotes(loanId);
      insuranceInformationStore.init(loanId, null);
    }
  }, [store, loanId])

  useEffect(() => {
    if (store.selectedQuote) {
      store.getInsuranceReviewData(store.selectedQuote);
    }
  }, [store, store.selectedQuote])

  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
  };

  return (
    <div style={{ backgroundColor: '#FFF', width: '100%' }}>
      <Layout>
        <Grid container spacing={2} direction='row' alignItems='flex-start'>
          <Grid item xs={6}>
            <SelectField
              standaloneLabel
              label={'Select an insurance / quote to review'}
              name={'selectedQuote'}
              value={store.selectedQuote}
              options={store.quotesOptions}
              onChange={value => store.setSelectedQuote(value)}
              variant="outlined"
              fullWidth
              shrink
              testId="selectedQuote"
              {...rest}
            />
          </Grid>
          <Grid item xs={12}>
            <InsuranceQuotesTable
              title={'Insurance / Quotes'}
              quotes={store.selectedQuotesData}
              emptyMessage={'No insurance information have been added yet'}
              showCollapseExpandAll={false}
              store={store.insuranceInformationStore}
            />
          </Grid>
          <Grid item xs={12}>
            <ReviewTable
              store={store}
              setResolutionConfirmOpen={setResolutionConfirmOpen}
            />
          </Grid>
          <Grid container item spacing={1} xs={12} justifyContent="flex-end" style={{ margin: '20px 11px 0 0' }}>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                testId="cancelButton"
                onClick={handleCloseButton}
                variant="outlined"
                color="primary"
                fullWidth
              >
                CLOSE
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <SaveButtonWithPermissions
                onClick={() => store.saveDataAndClose()}
                loanId={loanId}
                label="SAVE"
                variant="outlined"
                color="primary"
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <ConfirmationButton
                variant="outlined"
                onClick={() => store.setReviewDeclined()}
                testId="declineReview"
                color='primary'
                confirmDialogProps={{
                  title: 'Decline Review',
                  body: 'Are you sure you want to mark this review as declined?',
                }}
                fullWidth
              >
                REVIEW DECLINED
              </ConfirmationButton>
            </Grid> */}
            <Grid item xs={12} sm={6} md={2}>
              <ConfirmationButton
                variant="contained"
                onClick={() => store.setReviewComplete()}
                testId="confirmReview"
                color='primary'
                confirmDialogProps={{
                  title: 'Complete Review',
                  body: 'Are you sure you want to mark this review as completed?',
                }}
                fullWidth
              >
                REVIEW COMPLETE
              </ConfirmationButton>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
      <ConfirmDialog
        open={resolutionConfirmOpen}
        title={`Request Resolution(s)`}
        body={`Are you sure you want to request a resolution for the selected items?`}
        handleCancel={() => setResolutionConfirmOpen(false)}
        handleClose={() => setResolutionConfirmOpen(false)}
        handleConfirm={async () => {
          await store.requestResolutions();
          setResolutionConfirmOpen(false);
        }}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
      />
    </div>
  );
});