import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { IconButton } from '@roc/ui';
import { observer } from 'mobx-react';
import { Resolution } from '../../types/resolution';
import { ResolutionStore } from '../../stores';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  descriptionContainer: {
    display: 'inline-block',
    padding: '10px 0px',
    maxHeight: '150px',
    overflowY: 'auto'
  }
}))

export type ResolutionInfoProps = {
  resolution: Resolution,
  resolutionStore: ResolutionStore,
  showComment?: boolean,
};

export const ResolutionInfo = observer(
  (props: ResolutionInfoProps) => {
    const classes = useStyles();
    const { resolution, resolutionStore, showComment = false } = props;

    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <Typography
            component="span"
            style={{ display: 'inline-block', padding: '10px 0px' }}
          >
            <Typography component="span" color="textSecondary">
            </Typography>
            <strong>
              {resolution?.itemName || ''}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {(resolutionStore.canDownloadFile || resolutionStore.canDownloadFileFromInfoScreen) && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                type="submit"
                color="primary"
                testId="save"
                onClick={() => (resolutionStore.canDownloadFile ? resolutionStore.downloadDocument() :
                  resolutionStore.downloadDocumentByPath())}
              >
                <CloudDownload />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="span"
            className={classes.descriptionContainer}
          >
            <Typography component="span" color="textSecondary">
              Description:{' '}
            </Typography>
            <strong className={classes.description}>{resolution?.itemDescription}</strong>
          </Typography>
        </Grid>
        {showComment && (
          <Grid item xs={12}>
            <Typography
              component="span"
              style={{ display: 'inline-block', padding: '10px 0px' }}
            >
              <Typography component="span" color="textSecondary">
                Comment:{' '}
              </Typography>
              <strong>{resolution?.comment}</strong>
            </Typography>
          </Grid>
        )}
      </>
    );
  }
);
