import { Box, Grid } from '@material-ui/core';
import { isNil } from '@roc/feature-utils';
import { Button, DialogState, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentsTab } from '../editTask/editTaskBody/commentsTab';
import { CreateSuportTask } from './createSuportTask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    badge: {
      marginRight: '5px',
    },
  }),
);

export const EditSupportTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, supportTasksStore } = generalTasksStore;
  const store = editTaskStore;
  const classes = useStyles();

  useEffect(() => {
    if (editTaskStore.dialogState === DialogState.EDIT && !editTaskStore?.hasConversation) {
      editTaskStore.createTaskChatRoom()
    }
    if (editTaskStore.dialogState === DialogState.EDIT) {
      editTaskStore.reloadTaskById(generalTasksStore.editTaskStore.task.taskDetails.taskId);
    }
  }, [store.dialogState]);

  const getTitle = () => {
    if (store.dialogState === DialogState.EDIT) {
      return `Task #${editTaskStore?.task?.taskDetails?.taskId}`;
    } else {
      return `Please describe your tech bug below`;
    }
  };

  const dialogContent = (
    <Box my={1} className={classes.container}>
      <Grid container spacing={4} className={classes.container} style={{
        paddingTop: 0
      }}>
        {editTaskStore.dialogState === DialogState.ADD ? <CreateSuportTask /> : <CommentsTab />}
      </Grid>
    </Box>
  );

  return (
    <>

      <StandardDialog
        open={!isNil(store.dialogState)}
        title={getTitle()}
        maxWidth={editTaskStore.dialogState === DialogState.ADD ? "sm" : "lg"}
        handleClose={() => { store.closeDialog() }}
        dialogContent={dialogContent}
        dialogActions={(
          editTaskStore.dialogState === DialogState.ADD && (
            <>
              <Button
                style={{ marginRight: '8px' }}
                onClick={() => { store.closeDialog() }}
                color="primary"
                variant="outlined"
                testId="cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => supportTasksStore.saveSupportTask()}
                color="primary"
                variant="contained"
                testId="confirm"
              >
                Create
              </Button>
            </>)
        )}
      />
    </>

  );
});