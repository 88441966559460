import { Service } from '@roc/feature-app-core';
import { Task, TaskScope } from '../types/generalTasksTypes';

const url = {
  GET_ALL_TASKS_GRID: '/api/v1/tasks/generalTasks/getAllTasksGrid',
  GET_MY_TASKS_GRID: '/api/v1/tasks/generalTasks/getMyTasksGrid',
  GET_MY_LOANS_TASKS_GRID: '/api/v1/tasks/generalTasks/getMyLoansTasksGrid',
  GET_ALL_LOANS_TASKS_GRID: '/api/v1/tasks/generalTasks/getAllLoansTasksGrid',
  GET_MY_CRM_TASKS_GRID: '/api/v1/tasks/generalTasks/getCRMTasksGrid',
  GET_TASK_BY_ID: '/api/v1/tasks/generalTasks/getTaskById',
  SAVE_TASK: '/api/v1/tasks/generalTasks/saveTask',
  UPLOAD_ATTACHMENT: '/api/v1/tasks/generalTasks/uploadAttachment',
  REMOVE_ATTACHMENT: '/api/v1/tasks/generalTasks/removeAttachment',
  GET_ASSIGNEE_OPTIONS: '/api/v1/user/getUsersForGeneralTasksAssignees',
  DOCUMENT_DOWNLOAD: '/api/v1/document/downloadFile',
  SAVE_COMMENTS: '/api/v1/tasks/generalTasks/saveComments',
  GET_LOAN_INFO: '/api/v1/tasks/generalTasks/getLoanInformation',
  DOES_TASK_CHAT_ROOM_EXIST: '/api/v1/vendor/twilio/taskChatRoom/doesTaskChatRoomExist',
  CREATE_TASK_CHAT_ROOM: '/api/v1/vendor/twilio/taskChatRoom/createTaskChatRoom',
  CREATE_LINE_ITEM_TASK_CHAT_ROOM: '/api/v1/vendor/twilio/taskChatRoom/createLineItemTaskChatRoom',
  ESCALATE_TASK_TO_MANAGER: '/api/v1/tasks/generalTasks/escalateTaskToManager',
  GET_ALLOWED_ESCALATION_ROLES: '/api/v1/tasks/generalTasks/getAllowedEscalationRoles',
  MOVE_TASK_TO_ROC: '/api/v1/loan/task/moveTaskToRoc',
  MOVE_TASK_TO_CAPITAL_PROVIDER: '/api/v1/loan/task/moveTaskToCapitalProvider',
  MARK_TASK_AS_COMPLETED: '/api/v1/loan/task/markTaskAsCompleted',
  MARK_TASK_AS_WAITING_ON_EXTERNAL: '/api/v1/loan/task/setWaitingOnExternalFlagTrue',
  PUT_TASK_TO_TPO: '/api/v1/loan/task/putTaskToTpo',
  MOVE_TASK_TO_CMT_SPECIALIST: '/api/v1/loan/task/moveTaskToCMTSpecialist',
  IS_TAMARISK_APPRAISAL: '/api/v1/loan/appraisal/isTamariskAppraisal',
  UPDATE_TASK_BASED_ON_LOAN_TASK: '/api/v1/tasks/generalTasks/updateTaskBasedOnLoanTask',
  GET_UNREAD_COUNT_MESSAGES: '/api/v1/vendor/twilio/chatRooms/getTotalUserTasksConversationsUnreadCount',
  GET_WATCHERS_OPTIONS: '/api/v1/tasks/generalTasks/getUsersForGeneralTasksWatchers',
};

export class GeneralTasksService extends Service {
  getAllTasksGrid(
    loanId: number,
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_ALL_TASKS_GRID,
      {
        loanId,
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyTasksGrid(
    loanId: number,
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    type: string,
    taskScope: TaskScope
  ) {
    return this.get(
      url.GET_MY_TASKS_GRID,
      {
        loanId,
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        type,
        taskScope,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyLoansTasksGrid(
    loanId: number,
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    taskScope: TaskScope,
  ) {
    return this.get(
      url.GET_MY_LOANS_TASKS_GRID,
      {
        loanId,
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        taskScope
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getAllLoansTasksGrid(
    loanId: number,
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
  ) {
    return this.get(
      url.GET_ALL_LOANS_TASKS_GRID,
      {
        loanId,
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyCRMTasksGrid(
    loanId: number,
    status: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    taskScope: TaskScope
  ) {
    return this.get(
      url.GET_MY_CRM_TASKS_GRID,
      {
        loanId,
        status,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        taskScope,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getTaskById(taskId) {
    return this.get(url.GET_TASK_BY_ID, { taskId });
  }

  createTaskChatRoom(task: Task) {
    const body = {
      taskId: task.taskDetails.taskId,
      loanId: task?.attributes?.loanId,
      assigneeUserId: task.assignees[0]?.assigneeId,
      reporterUserId: task.taskDetails.ownerId,
      chatRoomName: task.taskDetails.title,
      propertyId: task.attributes.propertyId
    }
    return this.post(url.CREATE_TASK_CHAT_ROOM, body);
  }

  createLineItemTaskChatRoom(task: Task) {
    const body = {
      taskId: task.taskDetails.taskId,
      loanId: task?.attributes?.loanId,
      assigneeUserId: task.assignees[0]?.assigneeId,
      reporterUserId: task.taskDetails.ownerId,
      chatRoomName: task.taskDetails.title,
      propertyId: task.attributes.propertyId
    }
    return this.post(`${url.CREATE_LINE_ITEM_TASK_CHAT_ROOM}`, body);
  }

  doesTaskChatRoomExist(taskId) {
    return this.get(url.DOES_TASK_CHAT_ROOM_EXIST, { taskId });
  }

  saveTask(task: Task) {
    return this.post(url.SAVE_TASK, task);
  }

  getAssigneOptions(loanId, loanTaskId) {
    return this.get(url.GET_ASSIGNEE_OPTIONS, { loanId, loanTaskId });
  }

  getCurrentWatchersOptions(userIds) {
    return this.post(url.GET_WATCHERS_OPTIONS, userIds);
  }

  uploadAttachment(attachment, file: File) {
    const json = JSON.stringify(attachment);

    const formData = new FormData();
    formData.append(
      'attachment',
      new Blob([json], { type: 'application/JSON' })
    );
    formData.append('file', file);

    return this.post(url.UPLOAD_ATTACHMENT, formData, {
      'content-type': 'multipart/form-data',
    });
  }

  removeAttachment(attachmentId) {
    return this.delete(url.REMOVE_ATTACHMENT, { attachmentId });
  }

  downloadDocument(documentId) {
    return this.post(
      `${url.DOCUMENT_DOWNLOAD}?id=${documentId}`,
      { documentCollection: 'GENERAL_TASKS' },
      {},
      { responseType: 'arraybuffer' }
    );
  }

  saveComments(taskId, comments) {
    return this.post(`${url.SAVE_COMMENTS}?taskId=${taskId}`, comments);
  }

  getLoanInformation(loanIds) {
    const qs = loanIds.map(loanId => `loanId=${loanId}`).join('&');
    return this.get(
      `${url.GET_LOAN_INFO}?${qs}`,
      {},
      { disableGlobalLoading: true }
    );
  }

  escalateTaskToManager(taskId: number) {
    return this.patch(`${url.ESCALATE_TASK_TO_MANAGER}?taskId=${taskId}`, {});
  }

  moveTaskToRoc(taskId: number) {
    return this.post(`${url.MOVE_TASK_TO_ROC}?taskId=${taskId}`, {});
  }

  moveTaskToCapitalProvider(loanTaskId: number) {
    return this.post(`${url.MOVE_TASK_TO_CAPITAL_PROVIDER}?loanTaskId=${loanTaskId}`, {});
  }

  markTaskAsCompleted(taskId: number) {
    return this.post(`${url.MARK_TASK_AS_COMPLETED}?taskId=${taskId}`, {});
  }

  markTaskAsWaitingOnExternal(loanId: number, propertyId: number, taskType: string) {
    return this.put(`${url.MARK_TASK_AS_WAITING_ON_EXTERNAL}?loanId=${loanId}&propertyId=${propertyId}&taskType=${taskType}`, {});
  }

  putTaskToTpo(taskId: number) {
    return this.post(`${url.PUT_TASK_TO_TPO}?taskId=${taskId}`, {});
  }

  moveTaskToCMTSpecialist(taskId: number) {
    return this.post(`${url.MOVE_TASK_TO_CMT_SPECIALIST}?taskId=${taskId}`, {});
  }

  getAllowedEscalationRoles() {
    return this.get(url.GET_ALLOWED_ESCALATION_ROLES);
  }

  getIsTamariskAppraisal(loanId: number, propertyId: number) {
    return this.get(`${url.IS_TAMARISK_APPRAISAL}?loanId=${loanId}&propertyId=${propertyId}`, {});
  }

  updateTaskBasedOnLoanTask(taskId, loanTask) {
    return this.post(`${url.UPDATE_TASK_BASED_ON_LOAN_TASK}?taskId=${taskId}`, loanTask);
  }

  getUserTaskssUnreadCountMessages() {
    return this.get(url.GET_UNREAD_COUNT_MESSAGES);
  }
}
