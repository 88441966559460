import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useGeneralTasksStore } from './hooks/useGeneralTasksStore';
import { GeneralTasksTab } from './types/generalTasksTypes';
import { LoansTasksComponent } from './components/loansTasksComponent';

export const MyLoansTasks = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();

  useEffect(() => {
    generalTasksStore.setTab(GeneralTasksTab.MY_LOANS_TASKS);
  }, []);

  return (
    <Box px={0.25} py={2} width="100%" bgcolor={'#fff'}>
      <LoansTasksComponent />
    </Box>
  );
});
