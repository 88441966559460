export enum LoanAction {
  MARK_TITLE_COMPANY_CONTACTED = 'MARK_TITLE_COMPANY_CONTACTED',
  MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING = 'MARK_CLOSING_DOCUMENTS_SENT_FOR_SIGNING',
  APPROVE_ADDITIONAL_CLOSING = 'APPROVE_ADDITIONAL_CLOSING',
  APPROVE_INSURANCE = 'APPROVE_INSURANCE',
  REJECT_INSURANCE = 'REJECT_INSURANCE',
  REQUEST_FUNDING_TEMPLATE = 'REQUEST_FUNDING_TEMPLATE',
  UPLOAD_HUD = 'UPLOAD_HUD',
  MARK_FUNDED_WIRE_COMPLETED = 'MARK_FUNDED_WIRE_COMPLETED',
  REQUEST_CLOSING_WIRE = 'REQUEST_CLOSING_WIRE',
  GENERATE_CLOSING_DOCUMENTS = 'GENERATE_CLOSING_DOCUMENTS',
  UPDATE_FEDEX_TRACKING_NUMBER = 'UPDATE_FEDEX_TRACKING_NUMBER',
  CREATE_IN_SNAPDOCS = 'CREATE_IN_SNAPDOCS',
  UPLOAD_DOCS_TO_SNAPDOCS = 'UPLOAD_DOCS_TO_SNAPDOCS',
  APPROVE_ENTITY_INFORMATION = 'APPROVE_ENTITY_INFORMATION',
  APPROVE_TITLE_INFORMATION = 'APPROVE_TITLE_INFORMATION',
  UPLOAD_CLOSING_DOCUMENTS = 'UPLOAD_CLOSING_DOCUMENTS',
  UPLOAD_SIGNED_CLOSING_DOCUMENTS = 'UPLOAD_SIGNED_CLOSING_DOCUMENTS',
  RESEND_FUNDING_TEMPLATE = 'RESEND_FUNDING_TEMPLATE',
}
