import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import { FileUpload, FileUploadItem } from './fileUploadModal';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  dragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    minHeight: '120px',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 8,
  },
  inlineItemDragArea: {
    border: '2px dotted #427ED1',
    width: '100%',
    minHeight: '200px',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  errorDragArea: {
    border: '2px dotted #D10A0A',
    width: '100%',
    minHeight: '120px',
    backgroundColor: '#EAF4FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    marginBottom: 10,
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 8,
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
    width: '3vw',
    height: '3vw',
    color: '#4B89DA',
  },
  fileName: {
    marginLeft: 5,
    marginRight: 2,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  errorMeasage: {
    color: '#D10A0A',
  },
}));

interface IFileUploadAreaProps {
  items: FileUpload[];
  setItems: (items) => void;
  multiple?: boolean;
  fileType?: string;
  fileTypes?: string[];
  showError?: boolean;
  maxFiles?: number;
  isInlineItem?: boolean
}

export const FileUploadArea = observer(
  ({ items, setItems, multiple, fileType, showError, fileTypes, maxFiles, isInlineItem }: IFileUploadAreaProps) => {
    const classes = useStyles();
    const [error, setError] = useState<boolean>(false);
    const isCorrectFileType = (files: any[]) => {
      let isValid = true;
      if (fileType) {
        files.forEach(file => {
          if (file.type?.toLowerCase() !== fileType.toLowerCase()) {
            isValid = false;
            return;
          }
        })
      }

      if (fileTypes) {
        files.forEach(file => {
          const dotSplitFileName = file.name.split('.');
          if (!fileTypes.includes(dotSplitFileName[dotSplitFileName.length - 1])) {
            isValid = false;
            return;
          }
        });
      }

      return isValid;
    }

    const onDrop = (acceptedFiles: File[]) => {
      const newItems = acceptedFiles.map(file => ({
        name: file.name,
        file: file,
        type: file.name.split('.')[1]
      }));
      if (isCorrectFileType(newItems)) {
        setItems(prevItems => [...(multiple ? prevItems : []), ...newItems]);
        setError(false);
      } else {
        setError(true);
      }
    };

    const onRemove = (index: number) => {
      setItems(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles.splice(index, 1);
        return newFiles;
      });
    };

    const onRename = (index: number, newName: string) => {
      setItems(prevItems => {
        const items = [...prevItems];
        items[index].name = newName;
        return items;
      });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      multiple,
      maxFiles: multiple ? maxFiles ? maxFiles : 0 : 1,
    });

    return (
      <>
        {((multiple && !maxFiles) || (multiple && maxFiles && items.length < maxFiles) || (!multiple && items.length === 0)) &&
          <Box {...getRootProps()} className={error || showError ? classes.errorDragArea : isInlineItem ? classes.inlineItemDragArea : classes.dragArea}>
            <input {...getInputProps()} />
            <CloudUpload className={classes.icon} />
            {isDragActive ? (
              <p>Drop the file(s) here...</p>
            ) : (
              <>
                <p>Drop files here to upload</p>
                <p>or click here</p>
              </>
            )}
          </Box>
        }
        {error && <Typography variant="caption" className={classes.errorMeasage}>
          * The file you are trying to upload does not have the expected file type
        </Typography>}
        <Box>
          {items.map((item, i) => (
            <FileUploadItem
              key={item.file.name}
              name={item.name}
              file={item.file}
              disabled={item.disabled}
              onSaveName={newName => onRename(i, newName)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </Box>
      </>
    );
  }
);
