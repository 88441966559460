/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  createStyles,
  Link,
  makeStyles
} from "@material-ui/core";
import { observer } from "mobx-react";
import { InternalValuationReviewStore } from "../stores/internalValuationReviewStore";
import { useDocumentStore } from "@roc/feature-documents";

const useStyles = makeStyles({
  customLink: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: "0.9rem",
      padding: 10,
      border: "1px solid black",
    },
    head: {
      backgroundColor: "#f0f0f0",
      fontWeight: "bold",
      border: "1px solid black",
      textAlign: "center",
      fontSize: "0.7rem",
      padding: 10,
      letterSpacing: "1px"
    },
  })
)(TableCell);

interface ReportHistoryTableProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ReportHistoryTable = observer(({ internalValuationReviewStore }: ReportHistoryTableProps) => {
  const classes = useStyles();

  const { documentStore } = useDocumentStore();
  const historyRecords = internalValuationReviewStore.reportHistoryRecords;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width="30%">GENERATED DATE & TIME</StyledTableCell>
            <StyledTableCell width="30%">USER</StyledTableCell>
            <StyledTableCell width="30%">VIEW CHANGES</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(!historyRecords || historyRecords.length) === 0 ? (
            <TableRow key="noRecordsRow">
              <StyledTableCell colSpan={7} align="center">
                No records available
              </StyledTableCell>
            </TableRow>
          ) : (historyRecords.map((row, index) => (
            <TableRow key={`${index} ${row.dropboxPath}`}>
              <StyledTableCell align="center">{row.createdDate}</StyledTableCell>
              <StyledTableCell align="center">{row.createdByFullName}</StyledTableCell>
              <StyledTableCell align="center"><Link className={classes.customLink} onClick={() => documentStore.downloadHistoryDocument(row)}>{row.fileName}</Link></StyledTableCell>
            </TableRow>)))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
});
