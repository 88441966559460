import { action, computed, flow, makeObservable, observable } from 'mobx';
import { startOfToday, differenceInYears, parse, format, isValid } from 'date-fns';
import PropertyStore from './propertyStore';
import BorrowerFormStore from './borrowerFormStore';
import { ApiResponse, GridStore, UserService } from '@roc/feature-app-core';
import { LoanService } from '../../../loans/services/loanService';
import {
  ELMSURE,
  isEmptyObject,
  isNil,
  isNotBlank,
  LoanSubType,
  notLicensedinState,
  prequalificationStatusProperties,
  REQUEST_LOAN_TYPE_LOAN,
  Utility,
  WIMBA,
  Roles,
  REFINANCE,
  PURCHASE,
  LoanType,
  formatDate,
  generateUUID,
  getUniqueId,
  renovationDescriptionsByLoanSubtype,
  COMPLETED_STATE,
  LOAN_WITHOUT_LEAD_SOURCE_VALIDATION,
  segmentAnalytics,
  SegmentTrackerEvent,
  OTHER,
  LoanStatus,
  isFixAndFlip,
  getLoanPurpose,
  isGroundUp
} from '@roc/feature-utils';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import LoanInformationStore from './loanInformationStore';
import { Borrower, PropertyDSCR } from '@roc/feature-types';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { DraftLoan, Entity, Property } from '@roc/feature-types';
import EntityStore from '../common/entityStore';
import {
  getBridgeLoanTerms,
  getBorrowerEntity,
  getBorrowers,
  getBridgeProperties,
  getBorrowerEntityFromProposal,
  getBorrowerEntityForLoanApplication,
} from '../common/convert-draft-loan-helper';
import { canSubmitAppraisalWithTamarisk } from '../../utils/appraisalUtils';
import { borrowerDefaults } from '@roc/feature-utils';
import { transactionRoles } from '@roc/ui/formComponents';
import { BrokersStore } from '@roc/feature-brokers';
import {
  SUBMITTED_FROM_DEAL_ROOM,
  PROPOSAL_REQUEST_TYPE,
} from '@roc/feature-utils';
import { FixFlipGroundUpService } from '../../fixflipGroundUp/Services/fixflipGroundUpService';
import { LoanSubmissionStep } from '../../utils/constants';
import { requiredLicenseStates } from '@roc/feature-utils';
import { normalizeState } from '@roc/feature-utils';
import { getBoolean, getValues } from '@roc/ui/utils';
import { ProposalsStore } from '@roc/feature-proposals';
import { EditLoanSubmissionService } from '@roc/feature-edit-loans';
import { MarketingPromotionsStore } from '@roc/feature-sendgrid';

import { QuoteGeneratorDetailsStore, QuoteGeneratorService } from '@roc/feature-quote-generator';
import { LoanSubmissionService } from '../../services/loanSubmissionService';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';
import { getQuoteFromLoanSubmitJson } from '../common/mapLoanToQuoteHelper';
export class FixFlipStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  propertyStore: PropertyStore;
  brokersStore: BrokersStore;
  entityStore: EntityStore;
  borrowerFormStore: BorrowerFormStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanInformationStore: LoanInformationStore;
  proposalStore: ProposalsStore;
  marketingPromotionsStore: MarketingPromotionsStore;
  quoteGeneratorDetailsStore: QuoteGeneratorDetailsStore;
  private quoteGeneratorService: QuoteGeneratorService;
  private loanSubmissionService: LoanSubmissionService;
  public entity: Entity | Record<string, never>;
  public rawEntity: Entity | Record<string, never>;
  public selfBorrower: Borrower | Record<string, never>;
  public loanTerms;
  public properties: Property[];
  public loanType: string;
  public activeStep: number;
  public stepError: string;
  public stepErrors: string[];
  public stepWarnings: string[];
  public allErrors: string[];
  public allWarnings: string[];
  public loanSubtype: LoanSubType;
  public saved: boolean;
  public requestSaved: boolean;
  public savedLoanId: number;
  public isNewEntity: boolean;
  public hasFastTrack: boolean;
  public isFastTrackPaymentComplete: boolean;
  public selectedEntities: any[];
  private loanService: LoanService;
  private editLoanSubmissionService: EditLoanSubmissionService;
  public draftLoanInfo: DraftLoan;
  public isDealRoom: boolean;
  private dealRoomInfo;
  private loan;
  public resubmitAsStabilized: boolean;
  private user: UserService;
  private fixflipGroundUpService: FixFlipGroundUpService;
  public disableSubmitButton: boolean;
  public loanApplication: any;
  public isContinuingFromQuote: boolean;
  public borrowersErrors: string[];
  public borrowersWarnings: string[];
  public areBorrowersPreQualified: boolean;
  public rateAdjustmentsData: any;
  public editLoanJson: any;
  public maxLTC: number;
  public lenderFeesInfo: any;
  private oneToolService: OneToolService;
  public floorPoints: number;
  public maxPoints: number;

  constructor(globalStore, userStore, brokersStore) {
    this.globalStore = globalStore;
    this.user = new UserService();
    this.fixflipGroundUpService = new FixFlipGroundUpService();
    this.userStore = userStore;
    this.brokersStore = brokersStore;
    this.propertyStore = new PropertyStore(this.globalStore, x =>
      this.handleRenovationBudgeChanged(x)
    );
    this.entityStore = new EntityStore(this.globalStore, this);
    this.borrowerFormStore = new BorrowerFormStore(this.globalStore);
    this.loanInformationStore = new LoanInformationStore(
      this.globalStore,
      this
    );
    this.proposalStore = new ProposalsStore(this.globalStore, userStore);
    this.marketingPromotionsStore = new MarketingPromotionsStore(this.globalStore);
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      undefined,
      () => new BorrowerFormStore(this.globalStore)
    );
    this.loanService = new LoanService();
    this.editLoanSubmissionService = new EditLoanSubmissionService();
    this.quoteGeneratorService = new QuoteGeneratorService();
    this.quoteGeneratorDetailsStore = new QuoteGeneratorDetailsStore(
      globalStore,
      userStore,
      this.quoteGeneratorService,
      false,
    );
    this.loanSubmissionService = new LoanSubmissionService();
    this.oneToolService = new OneToolService();

    this.setDefaults();

    makeObservable(this, {
      entity: observable,
      selfBorrower: observable,
      loanTerms: observable,
      properties: observable,
      saved: observable,
      requestSaved: observable,
      resubmitAsStabilized: observable,
      savedLoanId: observable,
      activeStep: observable,
      stepError: observable,
      allErrors: observable,
      getRateFloor: observable,
      fetchBorrowerPointsOptions: observable,
      floorPoints: observable,
      maxPoints: observable,
      allWarnings: observable,
      stepErrors: observable,
      stepWarnings: observable,
      reset: action,
      loadStore: flow,
      setExistingEntity: action,
      setDefaultEntity: action,
      setEntity: action,
      setSelectedEntity: action,
      setIsNewEntity: action,
      moveToStep: action,
      goNextStep: flow,
      goPrevStep: action,
      setBorrowers: action,
      setNewBorrowers: action,
      setLoanTerms: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      propertiesRows: computed,
      canSubmitAppraisalWithTamarisk: computed,
      onLoanSave: flow,
      getDraftLoan: flow,
      getProposal: flow,
      onLoanSubmit: flow,
      log: flow,
      sendFastTrackPayment: flow,
      isRawEntityValue: action,
      setDraftLoanId: action,
      setRushedAppraisalProperties: action,
      draftLoanInfo: observable,
      isLoanRequest: computed,
      disableSubmitButton: observable,
      validateBorrowerErrors: flow,
      validateEntityErrors: flow,
      setLoanApplicationId: action,
      getLoanApplicationById: flow,
      loanApplication: observable,
      rejectLoanApplication: flow,
      validateBridgeSubmission: flow,
      validateBridgeSubmissionStep: flow,
      processStepCalculatedFields: action,
      isContinuingFromQuote: observable,
      borrowersErrors: observable,
      borrowersWarnings: observable,
      areBorrowersPreQualified: observable,
      getAreBorrowersPreQualifiedFromPrescreenValidations: flow,
      markProposalAsCompleted: flow,
      rateAdjustmentsData: observable,
      setBroker: action,
      editLoanJson: observable,
      getEditLoanJsonById: flow,
      onEditLoanSubmit: flow,
      isInternal: computed,
      checkStepErrors: flow,
      maxLTC: observable,
      getMaxLeverageFromMatrix: action,
      getProfitInfo: flow,
      getPropertiesInfoForProfit: action,
      getBorrowerAndEntityInfoForProfit: action,
      getLoanInfoForProfit: action,
      lenderFeesInfo: observable,
      fetchDefaultFees: flow,
      getLenderFeesForProfit: action,
      onLoanApplicationSave: flow,
      saveLoanQuoteForApplication: flow,
    });
  }

  private setDefaults() {
    this.loan = {};
    this.entity = {};
    this.rawEntity = {};
    this.loanTerms = {};
    this.properties = [];
    this.loanType = 'Residential Bridge';
    this.loanSubtype = LoanSubType.FIX_AND_FLIP;
    this.activeStep = 0;
    this.stepError = '';
    this.allErrors = [];
    this.stepErrors = [];
    this.stepWarnings = [];
    this.allWarnings = [];
    this.saved = false;
    this.requestSaved = false;
    this.resubmitAsStabilized = false;
    this.savedLoanId = null;
    this.hasFastTrack = false;
    this.isFastTrackPaymentComplete = false;
    this.isNewEntity = false;
    this.selectedEntities = [];
    this.draftLoanInfo = {
      borrowers: null,
      propertyAddress: null,
      loanType: null,
      loanSubType: null,
      amount: null,
      createdBy: null,
      createdDate: null,
      creationDate: null,
      draftLoanId: null,
      id: null,
      isActive: true,
      type: null,
      rejectRequestReason: null,
      status: null,
      loanApplicationId: null,
      borrowerLoanApplication: false,
      loanQuoteId: null,
    };
    this.isDealRoom = false;
    this.disableSubmitButton = false;
    this.loanApplication = {
      loanApplicationId: null,
    }
    this.isContinuingFromQuote = false;
    this.borrowersErrors = [];
    this.borrowersWarnings = [];
    this.areBorrowersPreQualified = true;
    this.rateAdjustmentsData = {};
    this.editLoanJson = {};
    this.maxLTC = 75;
    this.lenderFeesInfo = null;
    this.floorPoints = 1;
    this.maxPoints = 5;
  }

  setIsNewEntity = isNewEntity => {
    this.isNewEntity = isNewEntity;
  };

  private calculateBorrowerPercentages = () => {
    return this.selectBorrowersStore.borrowers.reduce(
      (total, current) => total + current.pctOwnership ?? 0,
      0
    );
  };

  public checkBorrowersErrors = () => {
    this.borrowersErrors = this.selectBorrowersStore.borrowerAccordionStore.validate();
    if (this.borrowersHaveEmail()) {
      this.borrowersErrors.push('Please add email address to every borrower.');
    }
  }

  public checkBorrowersWarnings = () => {
    this.borrowersWarnings = [];
    if (!isFixAndFlip(this.loanSubtype) && this.entityStore.hasEntityInfo && this.selectBorrowersStore.borrowers.length > 0 && this.calculateBorrowerPercentages() !== 100) {
      this.borrowersWarnings.push(
        'Please ensure that "Percentage of Ownership" adds to a total of 100%. Please adjust the ownership percentage slider accordingly.'
      );
    }
  }

  private checkAllErrors = () => {
    this.allErrors = [];

    const pointsError = this.checkPoints();
    if (pointsError)
      this.allErrors.push(pointsError);

    if (isEmptyObject(this.entity)) {
      this.allErrors.push('Please select an entity or create a new one.');
    }

    if (this.selectBorrowersStore.borrowers.length === 0) {
      this.allErrors.push('Please select a borrower or add a new one.');
    }

    this.checkBorrowersErrors();
    this.allErrors = [...this.allErrors, ...this.borrowersErrors];

    if (this.globalStore.lenderInfo?.showTPOLicenseValidation && this.properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
      !this.globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) &&
      this.loanInformationStore.licenseUpload.length == 0) {
      this.allErrors.push('Please upload the corresponding state license.');
    }

    if (this.properties.length === 0) {
      this.allErrors.push('Please add a new property.');
    }
    if (this.loanTerms.amount === 0) {
      this.allErrors.push('Total loan amount must be greater than 0.');
    }

    if (
      this.properties.some(p =>
        notLicensedinState.states.includes(p.state?.toUpperCase())
      )
    ) {
      this.allErrors.push(notLicensedinState.message);
    }
    if (
      (!this.loanInformationStore.otherTitleFormStore.form.meta.isValid && !isNil(this.editLoanJson.loan)) &&
      this.loanTerms.preferredTitle === 'Other'
    ) {
      this.allErrors.push(
        'Please fill out all the info for Preferred Title Company.'
      );
    }

    const allowLenderUnderwritingFee = this.globalStore.userFeatures
      ?.allowLenderUnderwritingFee;
    if (allowLenderUnderwritingFee && this.userStore.allowLoanPricing) {
      const error = this.checkLenderUnderwritingFeeValidations();
      if (error) {
        this.allErrors.push(error);
      }
    }

    this.allWarnings = [];

    this.checkBorrowersWarnings();
    this.allWarnings = [...this.allWarnings, ...this.borrowersWarnings];

    if (this.entity.operatingAgreementDate) {
      const diff = differenceInYears(
        startOfToday(),
        parse(this.entity.operatingAgreementDate, 'MM/dd/yyyy', new Date())
      );
      if (diff > 1) {
        this.allWarnings.push(
          'Certificate of Good Standing is needed within 90 days of closing. These can be ordered on the applicable county/state website'
        );
      }
    }
    if ((isNotBlank(this.entity.name) || !isNil(this.entity.ein)) && this.properties.some(p => p.state !== this.entity.state)) {
      this.allWarnings.push(
        'The borrower must obtain a foreign entity status in the state of the collateral. These can be ordered on the applicable county/state website'
      );
    }

    if (this.isMultifamilyBridge && this.isConstructionHoldbackAndLTFC()) {
      this.allWarnings.push(
        'The loan is lopsided (Initial loan amount < Construction Holdback) and over 93% of cost. The initial loan to cost is too high.'
      );
    }
  };

  *checkStepErrors(currentStep: LoanSubmissionStep, avoidStepCheck = false) {
    this.stepErrors = [];
    this.stepWarnings = [];
    this.checkAllErrors();
    switch (currentStep) {
      case LoanSubmissionStep.BORROWER_INFORMATION: {
        if (!avoidStepCheck && isFixAndFlip(this.loanSubtype)) {
          yield this.validateBridgeSubmissionStep(LoanSubmissionStep.BORROWER_INFORMATION);
          if (this.stepErrors.length) {
            return true;
          }
        }
        if (this.borrowersErrors.length > 0) {
          return true;
        }
        return false;
      }
      case LoanSubmissionStep.PROPERTY_INFORMATION: {
        if (!avoidStepCheck && isFixAndFlip(this.loanSubtype)) {
          yield this.validateBridgeSubmissionStep(LoanSubmissionStep.PROPERTY_INFORMATION);
          if (this.stepErrors.length) {
            return true;
          }
        }
        return false;
      }
      case LoanSubmissionStep.LOAN_DETAILS: {
        this.loanInformationStore.runFormValidation();
        if (!this.loanInformationStore.form.meta.isValid) {
          return true;
        }

        this.stepError = this.checkPoints();
        if (this.stepError) {
          return true;
        }

        if (!avoidStepCheck && isFixAndFlip(this.loanSubtype)) {
          yield this.validateBridgeSubmissionStep(LoanSubmissionStep.LOAN_DETAILS);
          if (this.stepErrors.length) {
            return true;
          }
        }

        const allowLenderUnderwritingFee = this.globalStore.userFeatures
          ?.allowLenderUnderwritingFee;
        if (allowLenderUnderwritingFee && this.userStore.allowLoanPricing) {
          this.stepError = this.checkLenderUnderwritingFeeValidations();
          return !!this.stepError;
        }

        else {
          this.stepError = '';
          return false;
        }
      }

      default: {
        this.stepError = '';
        return false;
      }
    }
  };

  checkLenderUnderwritingFeeValidations() {
    if (this.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
      return '';
    }

    if (
      this.loanTerms.amount < 500000 &&
      this.loanTerms.lenderUnderwritingFee < 595
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $595.';
    }
    if (
      this.loanTerms.amount >= 500000 &&
      this.loanTerms.amount < 1000000 &&
      this.loanTerms.lenderUnderwritingFee < 895
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $895.';
    }
    if (
      this.loanTerms.amount >= 1000000 &&
      this.loanTerms.amount < 2000000 &&
      this.loanTerms.lenderUnderwritingFee < 1295
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,295.';
    }
    if (
      this.loanTerms.amount >= 2000000 &&
      this.loanTerms.amount < 3000000 &&
      this.loanTerms.lenderUnderwritingFee < 1995
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,995.';
    }
    if (
      this.loanTerms.amount >= 3000000 &&
      this.loanTerms.amount < 4000000 &&
      this.loanTerms.lenderUnderwritingFee < 3495
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $3,495.';
    }
    if (
      this.loanTerms.amount >= 4000000 &&
      this.loanTerms.amount < 5000000 &&
      this.loanTerms.lenderUnderwritingFee < 4495
    ) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $4,495.';
    }
    return '';
  }

  checkPoints() {
    const isBrokerUser = this.userStore.userInformation?.roles?.includes(Roles.BROKER);
    const brokerPoints = this.loanTerms.brokerPoints;
    const lenderPoints = this.loanTerms.points;
    if (isBrokerUser && brokerPoints > 2) {
      return 'Broker points must not exceed 2%.'
    } else {
      return brokerPoints > lenderPoints
        ? 'Broker points cannot exceed total lender points.'
        : '';
    }
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    return {
      ...borrower,
      personalGuarantor:
        borrower.personalGuarantor ?? null,
    };
  }

  moveToStep = (step, currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.selectBorrowersStore.borrowerAccordionStore.saveBorrowersFromAccordion();
    }
    this.checkStepErrors(currentStep, true);
    this.activeStep = step;
  };

  goPrevStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    this.checkStepErrors(currentStep, true);
    this.activeStep--;
  };

  *goNextStep(currentStep: LoanSubmissionStep) {
    this.stepError = '';
    const value = yield this.checkStepErrors(currentStep);
    if (!value) {
      this.moveToStep(this.activeStep + 1, currentStep);
    }
    if (this.stepError) {
      this.globalStore.notificationStore.showErrorNotification({
        message: this.stepError,
      });
    }
  };

  *loadStore(editLoanId = null) {
    if (this.isContinuingFromQuote) {
    } else if (isNil(this.draftLoanInfo.draftLoanId) && isNil(this.loanApplication.loanApplicationId) && isNil(this.loan.loanId) && isNil(editLoanId)) {
      this.reset();
    } else if (!isNil(editLoanId)) {
      this.getEditLoanJsonById(editLoanId);
    } else if (!isNil(this.loanApplication.loanApplicationId)) {
      this.getLoanApplicationById();
    } else if (!isNil(this.loan.loanId)) {
      this.getProposal();
    } else {
      this.getDraftLoan();
    }

    //this.loanInformationStore.getLenderAttorneys();
    this.loanInformationStore.getWimbaStates();
    if (this.loanInformationStore.loanParticipantsStore.isFacoOriginator) {
      this.loanInformationStore.getLoanOriginators();
    } else {
      this.loanInformationStore.loanParticipantsStore.fetchLoanParticipants();
    }
    if (this.globalStore.userFeatures.isBroker) {
      yield this.brokersStore.selectBrokersStore.loadBrokerUser(
        this.userStore.userInformation.userId
      );
      yield this.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(
        this.brokersStore.selectBrokersStore.brokers[0].brokerId
      );
    }
  }

  reset() {
    this.setDefaults();
    this.entityStore.resetStore();
    this.borrowerFormStore.reset();
    this.propertyStore.reset();
    this.loanInformationStore.resetLoanInformation();
    this.brokersStore.reset();
    this.selectBorrowersStore.reset();
    this.quoteGeneratorDetailsStore.resetStore();
    if (this.globalStore.userFeatures.isBroker) {
      this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
    }
    this.loanInformationStore.loanParticipantsStore.reset();
  }

  setLoanType(loanSubType: LoanSubType) {
    this.loanSubtype = loanSubType;
    if (this.isContinuingFromQuote) {
      this.isContinuingFromQuote = false;
    } else {
      this.loanInformationStore.resetLoanInformation();
    }
  }

  setSelectedEntity(entity, isSelected) {
    if (isSelected) {
      this.selectedEntities = [...this.selectedEntities, entity];
    } else {
      const index = this.selectedEntities.findIndex(
        selectedEntity =>
          selectedEntity.borrowerEntityId === entity.borrowerEntityId
      );
      this.selectedEntities.splice(index, 1);
    }
  }

  setExistingEntity() {
    const selectedEntity = { ...this.selectedEntities[0] };
    if (!selectedEntity.name) return;

    let initialBorrowers = [];
    const _borrowers = [];
    for (const option of selectedEntity.borrowers) {
      const borrower = this.setBorrowerDefaults({
        ...option.borrower,
        borrowerId: option.borrower.borrowerId,
        pctOwnership: option.pctOwnership ?? 0,
        roleInTransaction: '',
        firstName: option.borrower.firstName,
        lastName: option.borrower.lastName,
        cellPhone: option.borrower.cellPhone,
        emailAddress: option.borrower.emailAddress,
        bankruptcyProvidedAtOrigination: option.borrower
          .bankruptcyProvidedAtOrigination
          ? 'Y'
          : 'N',
        foreclosureProvidedAtOrigination: option.borrower
          .foreclosureProvidedAtOrigination
          ? 'Y'
          : 'N',
      });
      _borrowers.push(borrower);
    }

    this.rawEntity = { ...selectedEntity };

    this.setEntity(selectedEntity, [...initialBorrowers, ..._borrowers]);
  }

  setDefaultEntity() {
    const _borrowers = [];
    this.entity = {
      name: '',
      type: 'Limited Liability Company',
      borrowers: _borrowers,
      loanApplicationUuid: generateUUID()
    };
    this.setBorrowers(_borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION, true);
  }

  setDefaultValues() {
    if (this.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
      this.loanInformationStore.setFixFlipProDefaults();
    }
  }

  setEntity(entity, borrowers = []) {
    this.entity = {
      borrowerEntityId: entity.borrowerEntityId,
      name: entity.name,
      ein: entity.ein,
      type: entity.type,
      operatingAgreementDate: entity.operatingAgreementDate,
      address: entity.address,
      streetNumber: entity.streetNumber,
      streetName: entity.streetName,
      city: entity.city,
      state: entity.state,
      zipCode: entity.zipCode,
      loanApplicationUuid: entity.loanApplicationUuid ?? generateUUID(),
      borrowers,
    };
    this.setBorrowers(borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION, true);
  }

  deleteEntity = () => {
    this.entity = {};
    this.rawEntity = {};
    this.setBorrowers([]);
    this.isNewEntity = false;
    this.selectedEntities = [];
  };

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }


  setIsLoanBeingEdit(isLoanBeingEdit) {
    this.selectBorrowersStore.setIsLoanBeingEdit(isLoanBeingEdit);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  addProperty = property => {
    this.properties.push(property);
    this.loanInformationStore.setDefaultPreferredTitle();
    this.checkStepErrors(LoanSubmissionStep.PROPERTY_INFORMATION);
  };

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
    this.loanInformationStore.setDefaultPreferredTitle();
  };

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
    if (!this.properties.length) {
      this.loanInformationStore.reset();
    }
    this.loanInformationStore.setDefaultPreferredTitle();
  };

  setLoanTerms = loanTerms => {
    this.loanTerms = { ...loanTerms };
  };

  setSaved = (value: boolean) => {
    this.saved = value;
  };

  setDraftLoanId = (value: number) => {
    this.draftLoanInfo.draftLoanId = value;
  };

  setProposalId = (value: number) => {
    this.loan = {
      loanId: value,
    };
  };

  isConstructionHoldbackAndLTFC = () => {
    return (
      this.loanTerms.constructionHoldback > this.loanTerms.initialLoanAmount &&
      this.getPropertiesLTFC() > 93
    );
  };

  borrowersHaveEmail = () => {
    return this.selectBorrowersStore.borrowers.length
      ? this.selectBorrowersStore.borrowers.findIndex(
        borrower => !borrower.emailAddress
      ) !== -1
      : false;
  };

  getPropertiesLTFCFromLoanInformationStore = () => {
    this.loanInformationStore.makeInitialCalculations();
    const futureCost =
      this.loanInformationStore.getTotalPurchasePrice() + this.loanInformationStore.getTotalRenovationBudget();
    return futureCost ? (this.loanTerms.amount / futureCost) * 100 : 0;
  }

  getPropertiesLTFC = () => {
    this.loanInformationStore.makeInitialCalculations();
    const futureCost =
      this.loanTerms.totalPurchasePrice + this.loanTerms.totalRenovationBudget;
    return futureCost ? (this.loanTerms.amount / futureCost) * 100 : 0;
  };

  *log(data, additionalInfo) {
    try {
      yield this.loanService.log(data, additionalInfo);
    } catch (error) {
      console.log(error);
    }
  }

  get dealRoomSaveProposalJson() {
    const {
      borrowerEntity,
      borrowers,
      properties,
      ...rest
    } = this.loanSubmitJson;
    return {
      ...this.dealRoomInfo,
      ...this.loanSubmitJson,
      status: this.dealRoomInfo.status,
      proposalStatus: this.dealRoomInfo.proposalStatus,
      dealName: this.dealRoomInfo.dealName,
      aggregatePurchasePrice: this.dealRoomInfo.aggregatePurchasePrice,
      calculatedLoanData: this.dealRoomInfo.calculatedLoanData,
      pointsOut: this.dealRoomInfo.pointsOut,
      projectDescription: this.dealRoomInfo.projectDescription,
      utility: Utility.PROPOSAL_INFORMATION,
      loanId: this.dealRoomInfo.loanId,
      borrowerEntityObj: {
        ...borrowerEntity,
      },
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...borrowers],
        },
      },
      propertiesMap: {
        rows: [...properties],
      },
    };
  }

  get dealRoomSubmitProposalJson() {
    return {
      ...this.dealRoomInfo,
      ...this.loanSubmitJson,
      status: this.dealRoomInfo.proposalStatus,
      dealName: this.dealRoomInfo.dealName,
      aggregatePurchasePrice: this.dealRoomInfo.aggregatePurchasePrice,
      calculatedLoanData: this.dealRoomInfo.calculatedLoanData,
      pointsOut: this.dealRoomInfo.pointsOut,
      projectDescription: this.dealRoomInfo.projectDescription,
      utility: Utility.LOAN_INFORMATION,
      loanId: this.dealRoomInfo.loanId,
      twilioCommunicationEnabled: true,
    };
  }

  get loanSaveJson() {
    const {
      borrowerEntity,
      borrowers,
      properties,
      ...rest
    } = this.loanSubmitJson;

    return {
      ...rest,
      borrowerEntityObj: {
        ...borrowerEntity,
      },
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...borrowers],
        },
      },
      propertiesMap: {
        rows: [...properties],
      },
    };
  }

  setInsuranceDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === ELMSURE || this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === null) {
      return { insuredThroughElmsure: true };
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany)
    }
  }

  setPreferredInsuranceBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === ELMSURE) {
      return ELMSURE;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setPreferredTitleBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return WIMBA;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setTitleDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return null;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore?.brokerVendorClosingSettings?.preferredTitleCompany)
    }
  }

  get loanSubmitJson() {
    const isElmsure = this.loanTerms.preferredInsurance === ELMSURE;
    const isWimba = this.loanTerms.preferredTitle === WIMBA;
    const loanInformationValues = this.loanInformationStore.getFormValues();
    let exceptionValues = {};
    if (loanInformationValues.exceptionLeverage) {
      exceptionValues = {
        exceptionLeverage: loanInformationValues.exceptionLeverage,
        exceptionLeverageReason: loanInformationValues.exceptionLeverageReason,
        exceptionInitialLoanAmount: loanInformationValues.exceptionInitialLoanAmount,
        exceptionInitialLoanToPurchase: loanInformationValues.exceptionInitialLoanToPurchase,
        exceptionConstructionHoldback: loanInformationValues.exceptionConstructionHoldback,
        exceptionConstructionHoldbackPercentage: loanInformationValues.exceptionConstructionHoldbackPercentage,
        exceptionMaxLeverageFromMatrix: this.getMaxLeverageFromMatrix(),
      };
    }
    return {
      loanType: this.loanType,
      broker: this.brokersStore.selectBrokersStore.brokers[0],
      loanSubtype: this.loanSubtype,
      loanPurpose: getLoanPurpose(this.properties),
      lenderParticipation: this.loanTerms.lenderParticipation,
      submitAppraisalWithTamarisk: this.globalStore.userFeatures
        ?.tamariskAppraisal
        ? true
        : this.loanTerms.submitAppraisalWithTamarisk,
      condoConversion: this.loanTerms.condoConversion === true || this.loanTerms.condoConversion === 'Y',
      pledgeOfShares: true,
      fastTrack: this.loanTerms.fastTrack === 'Y',
      fastTrackOptions: {
        ...this.loanTerms.fastTrackOptions,
        insuranceReview: !isElmsure,
        titleSearch: isWimba,
        insurance: isElmsure,
        titleAttorneySearch: !isWimba,
        properties: this.properties.length,
        propertiesAddresses: this.properties?.map(property => property.address).join(', ')
      },
      fullPrincipalInterest: this.loanTerms.fullPrincipalInterest,
      initialLoanAmount: this.loanTerms.initialLoanAmount,
      initialLoanToPurchase: this.loanTerms.initialLoanToPurchase,
      constructionHoldback: this.loanTerms.constructionHoldback,
      constructionHoldbackPercentage: this.loanTerms
        .constructionHoldbackPercentage,
      rate: this.loanTerms.rate,
      rocRate: this.loanTerms.rate,
      interestMethod: 'Actual/360',
      points: this.loanTerms.points,
      pointsIn: this.loanTerms.points,
      pointsOut: null,
      rocPointsIn: this.loanTerms.rocPointsIn,
      brokerPoints: this.loanTerms.brokerPoints,
      brokerPointsIn: this.loanTerms.brokerPoints,
      referralFee: this.loanTerms.referralFee,
      lenderOriginatorId: this.getLenderOriginatorId(
        this.loanTerms.loanOriginator
      ),
      brokerPointsOut: null,
      interestReserveMonths: this.loanTerms.interestReserveMonths,
      duration: this.loanTerms.duration,
      origLenderSubordination: this.loanTerms.origLenderSubordination,
      attorneyEmail: this.globalStore.userFeatures.isBroker ? this.loanInformationStore.getPreferredAttorney()
        ?.value : this.loanTerms.preferredClosingAttorney,
      lenderAttorneySelectedName: this.loanInformationStore.getPreferredAttorney()
        ?.label,
      preferredInsurance: this.globalStore.userFeatures.isBroker ? this.setPreferredInsuranceBasedOnBrokerSettings() : this.loanTerms.preferredInsurance,
      requestedClosingDate: this.loanTerms.requestedClosingDate,
      amount: this.loanTerms.amount,
      sellAs: this.loanTerms.sellAs,
      interestReserveFinanced: this.loanTerms.interestReserveFinanced === "Y",
      ltcCatchupDraw: this.loanTerms.ltcCatchupDraw === "Y",
      ltcCatchupDrawAmount: this.loanTerms.ltcCatchupDrawAmount,
      loanInsuranceData:
        this.globalStore.userFeatures.isBroker ? this.setInsuranceDataBasedOnBrokerSettings() :
          this.loanTerms.preferredInsurance === 'Elmsure'
            ? { insuredThroughElmsure: true }
            : {
              ...this.loanTerms.otherInsuranceCompany,
            },
      preferredTitle: this.globalStore.userFeatures.isBroker ? this.setPreferredTitleBasedOnBrokerSettings() : this.loanTerms.preferredTitle
        ? this.loanTerms.preferredTitle
        : null,
      titleCompany: this.globalStore.userFeatures.isBroker ? this.setTitleDataBasedOnBrokerSettings() :
        !this.loanTerms.preferredTitle
          ? null
          : this.loanTerms.preferredTitle !== WIMBA
            ? {
              ...this.loanTerms.otherTitleCompany,
            }
            : null,
      emailsForCreditBackgroundCheckRequests: this.borrowerCreditRequests?.join(
        ','
      ),
      loanSubmittedByMailId: this.userStore.userInformation?.username,
      isSubmitted: true,
      borrowerEntity: this.borrowerEntityObj,
      borrowers: [...this.borrowersRows],
      properties: [...this.propertiesRows],
      draftLoanId: !(this.loanTerms.fastTrack === 'Y')
        ? this.draftLoanInfo.draftLoanId
        : this.loanInformationStore.draftLoanId,
      underwritingFee: this.loanTerms.lenderUnderwritingFee,
      processingFee: this.loanTerms.lenderProcessingFee,
      externalLenderUnderwritingFee: this.loanTerms.externalLenderUnderwritingFee,
      externalLenderProcessingFee: this.loanTerms.externalLenderProcessingFee,
      submittedFrom: this.isDealRoom ? SUBMITTED_FROM_DEAL_ROOM : null,
      utility: Utility.LOAN_INFORMATION,
      freeAppraisalPromotion: this.loanTerms.freeAppraisalPromotion === 'Y',
      marketingPromotionId: this.loanTerms.marketingPromotionId,
      ...exceptionValues,
      projectDescription: loanInformationValues.projectDescription,
      referralFeesAmount: loanInformationValues.referralFeesAmount,
    };
  }

  getMaxLeverageFromMatrix = () => {
    let fico = this.borrowersRows.reduce((currentFicoInfo, borr) => {
      if (borr.medianScore > currentFicoInfo) {
        return borr.medianScore;
      }
      return currentFicoInfo;
    }, 0);

    if (!fico) {
      fico = this.borrowersRows.reduce((currentFicoInfo, borr) => {
        if (borr.ficoProvidedAtOrigination > currentFicoInfo) {
          return borr.ficoProvidedAtOrigination;
        }
        return currentFicoInfo;
      }, 0);
    }
    ;
    if (this.loanTerms?.amount < 1000000 && fico >= 660) {
      return this.maxLTC;
    }
    return null;
  }

  getLenderOriginatorId(selectedLenderOriginatorId) {
    return this.globalStore.userFeatures?.isLenderOriginator
      ? this.userStore.userInformation?.userId
      : selectedLenderOriginatorId;
  }

  get borrowerEntityObj() {
    return {
      borrowerEntityId: this.entity.borrowerEntityId,
      name: this.entity.name,
      ein: this.entity.ein,
      type: this.entity.type,
      operatingAgreementDate: this.entity.operatingAgreementDate,
      address: this.entity.address,
      city: this.entity.city,
      zipCode: this.entity.zipCode,
      streetName: this.entity.streetName,
      state: this.entity.state,
      streetNumber: this.entity.streetNumber,
      loanApplicationUuid: this.entity.loanApplicationUuid,
    };
  }

  get borrowersRows() {
    const defaultFormValues = this.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo
      .restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        ...borrower,
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: getValues(borrower.roleInTransaction),
        personalGuarantor: borrower.personalGuarantor,
        experience: borrower.experience ?? defaultFormValues.experience,
        pctOwnership: borrower.pctOwnership ?? defaultFormValues.pctOwnership,
        citizenshipStatus:
          borrower.citizenshipStatus || defaultFormValues.citizenshipStatus,
        hasSubstantialChanges:
          borrower.hasSubstantialChanges ??
          defaultFormValues.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        OwnershipNotKnown:
          borrower.ownershipNotKnown ?? defaultFormValues.ownershipNotKnown, // Compatiblity with eportal
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
        socialSecurityNumber: borrower.ssn,
        loanApplicationUuid: borrower.loanApplicationUuid,
        protectedBorrower: borrower?.protectedBorrower,
      };
    });
  }

  get propertiesRows() {
    return this.properties.map(property => ({
      ...property,
      propertyId:
        property.appraisalId || this.isDealRoom || !isNil(this.editLoanJson.loanId) || !isNil(this.loanApplication.loanApplicationId) ? property.propertyId : null,
      address: property.address,
      streetNumber: property.streetNumber,
      streetName: property.streetName,
      aptNumber: property.aptNumber,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      latitude: property.latitude,
      longitude: property.longitude,
      exitStrategy: property.exitStrategy,
      propertyOwnership: property.propertyOwnership,
      propertySourcing: property.propertySourcing,
      purchasePrice: property.purchasePrice,
      purchaseDate: property.purchaseDate, //TODO: Need formatting as mm/dd/yyyy ??
      totalRenovationBudget: property.totalRenovationBudget,
      renovationBudget: Math.max(
        0,
        property.totalRenovationBudget - property.sunkCost
      ),
      describeRenovation: property.describeRenovation,
      useCode: property.useCode,
      armsLength: getBoolean(property.armsLength),
      armsLengthComment: property.armsLengthComment,
      anyDebt: getBoolean(property.anyDebt),
      asIsValue: property.asIsValue,
      afterRepairValue: property.afterRepairValue,
      refiAmount: property.refiAmount,
      propertySourcingExplanation: property.propertySourcingExplanation,
      additionalComments: property.additionalComments,
      propertyAppraisalId: property.appraisalId,
      submitRushedAppraisal: property.submitRushedAppraisal,
      wholesaleAmount:
        property.wholesaleAmount &&
        property.purchasePrice &&
        property.purchasePrice - property.wholesaleAmount,
      wholesalerPurchase: property.wholesalerPurchase,
      sunkCost: property.sunkCost || 0,
      loanApplicationUuid: property.loanApplicationUuid,
      parcel: property.parcel,
      numberOfLots: property.numberOfLots,
      isOriginalAddress: property.isOriginalAddress,
      originalAddress: property.originalAddress,
      permitsInPlace: property.permitsInPlace
    }));
  }

  get borrowerCreditRequests() {
    return this.selectBorrowersStore.borrowers
      .filter(borrower => borrower.creditBackgroundCheck)
      .map(borrower => borrower.emailAddress);
  }

  get isMultifamilyBridge() {
    return this.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;
  }

  isRawEntityValue(field) {
    return this.rawEntity[field];
  }

  handleRenovationBudgeChanged = (value: number) => {
    if (!this.isMultifamilyBridge) return;

    const {
      purchasePrice,
    } = this.propertyStore.purchaseInformationStore.getFormValues();
    if (value > purchasePrice / 2) {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The Renovation budget should not be greater than 50% of the Purchase price.',
      });
    }
  };

  *rejectLoanApplication() {
    try {
      const data = {
        status: LoanStatus.APPLICATION_REJECTED,
        loanId: this.loanApplication.loanApplicationId
      }
      const response: ApiResponse = yield this.loanService.updateLoanApplicationStatus(
        data
      );
      if (response.data.data.success === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan Application status has been updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating loan application status',
      });
    }

  }
  *onLoanSave(type: string, showSuccessNotification = true) {
    try {
      if (this.isDealRoom) {
        this.loan = this.dealRoomSaveProposalJson;
        this.loan.marketingPromotionId = null;
        const data = this.loan;
        this.log(data, 'save bridge loan');
        const response: ApiResponse = yield this.loanService.saveDraftProposal(
          data
        );

        if (response.data?.data?.success === false) {
          const additionalDescription = '. Error while saving deal room';
          this.globalStore.notificationStore.showErrorNotification({
            message:
              response.data?.data?.responseMessage + additionalDescription,
          });
        } else {
          this.savedLoanId = response.data?.data?.loanId;
          this.globalStore.notificationStore.showSuccessNotification({
            message: 'Deal room has been saved successfully.',
          });
        }
      } else {
        this.loan = this.loanSaveJson;
        this.loan.marketingPromotionId = null;
        const loan = this.loan;
        const draftLoan = {
          content: JSON.stringify(loan),
          draftLoanId: this.draftLoanInfo.draftLoanId,
          createdBy: this.draftLoanInfo.createdBy,
          lenderOriginatorId: loan.lenderOriginatorId,
          creationDate: this.draftLoanInfo.creationDate
            ? format(
              new Date(this.draftLoanInfo.creationDate),
              'MM/dd/yyyy HH:mm:ss.SSS'
            )
            : null,
          isActive: this.draftLoanInfo.isActive,
          type: this.isDealRoom ? PROPOSAL_REQUEST_TYPE : type,
          rejectRequestReason: this.draftLoanInfo.rejectRequestReason,
          status: this.draftLoanInfo.status,
          brokerId: this.globalStore.userFeatures.isBroker
            ? this.brokersStore.selectBrokersStore.brokers[0].brokerId
            : null,
        };
        if (this.allErrors.length > 0 && type === REQUEST_LOAN_TYPE_LOAN) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Please correct the errors listed above',
          });
        } else {
          this.log(draftLoan, 'draft loan');
          const createDocuments = this.globalStore.userFeatures.uploadDuringSubmissionEnabled;
          const response: ApiResponse = yield this.loanService.saveDraftLoan(
            draftLoan,
            createDocuments
          );
          if (response.data) {
            this.draftLoanInfo.draftLoanId = response.data?.data?.id;
            if (showSuccessNotification) {
              this.globalStore.notificationStore.showSuccessNotification({
                message: 'Loan has been saved successfully.',
              });
            }
          }
          type == REQUEST_LOAN_TYPE_LOAN
            ? (this.requestSaved = true)
            : (this.requestSaved = false);
        }
      }

    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan.',
      });
    }
  }

  *getDraftLoan() {
    try {
      const response: ApiResponse = yield this.loanService.getDraftLoan(
        this.draftLoanInfo.draftLoanId
      );
      this.reset();

      const data = response?.data?.data;
      this.draftLoanInfo = data;
      const draftLoan = JSON.parse(data.content);
      this.mapDraftLoanToStore(draftLoan);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draft loan.',
      });
    }
  }

  *getProposal() {
    try {
      const response: ApiResponse = yield this.loanService.getProposalById(
        this.loan.loanId
      );
      this.reset();
      const data = response?.data?.data;
      this.dealRoomInfo = data;
      console.log('Deal room infooo ', this.dealRoomInfo);
      console.log('Dataa ', data);
      this.mapProposalToStore(data);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draft loan.',
      });
    }
  }

  mapDraftLoanToStore(draftLoan: any) {
    const { loanType, loanSubtype } = draftLoan;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntity(draftLoan);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(draftLoan, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

    this.properties = getBridgeProperties(draftLoan);
    this.loanTerms = getBridgeLoanTerms(draftLoan);

    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(
      this.loanTerms.otherInsuranceCompany
    );
    this.loanInformationStore.otherTitleFormStore.loadForm(
      this.loanTerms.otherTitleCompany
    );
    if (draftLoan?.broker) {
      const { broker } = draftLoan;
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }

    this.isDealRoom = draftLoan.submittedFrom === SUBMITTED_FROM_DEAL_ROOM;
    if (this.isDealRoom) {
      this.dealRoomInfo = draftLoan;
    }
  }

  mapProposalToStore(loan: any) {
    const { loanType, loanSubtype } = loan;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntityFromProposal(loan);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(loan, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

    this.properties = this.getProposalProperties(loan);
    this.loanTerms = getBridgeLoanTerms(loan);

    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(
      this.loanTerms.otherInsuranceCompany
    );
    this.loanInformationStore.otherTitleFormStore.loadForm(
      this.loanTerms.otherTitleCompany
    );

    this.isDealRoom = loan.submittedFrom === SUBMITTED_FROM_DEAL_ROOM;
    console.log('isDealRoom ', this.isDealRoom);
    if (this.isDealRoom) {
      this.dealRoomInfo = loan;
    }
  }

  getProposalProperties(proposalLoan) {
    let proposalProperties = getBridgeProperties(proposalLoan);
    proposalProperties = proposalProperties?.map(property => {
      if (property?.propertyOwnership !== PURCHASE && !property?.propertySourcing) {
        property.propertySourcing = null;
      }
      return property;
    });
    return proposalProperties;
  };

  *onLoanSubmit() {
    const isInternal = this.globalStore.lenderInfo?.isInternal;
    const isBackOfficeUser = this.userStore.isBackOfficeUser;
    const isBackOfficeProcessor = this.userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
    if (
      this.properties.some(p => p.totalRenovationBudget === 0) &&
      isFixAndFlip(this.loanSubtype)
    ) {
      this.saved = false;
      this.resubmitAsStabilized = true;
    } else {
      try {
        if (this.isDealRoom) {
          this.loan = this.dealRoomSubmitProposalJson;
        } else if (this.isBorrowerLoanApplication) {
          this.loan = {
            ...this.loanApplication,
            ...this.loanSubmitJson
          }
        }
        else {
          this.loan = this.loanSubmitJson;
        }
        this.disableSubmitButton = true;
        const data = this.loan;
        this.log(data, 'submit bridge loan');
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.BRIDGE_SUBMISSION,
          userName: this.userStore?.userInformation?.username
        });
        const response: ApiResponse = yield this.loanService.submitBridgeLoan(
          data
        );

        if (response.data?.data?.success === false) {
          const additionalDescription =
            '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
          this.globalStore.notificationStore.showErrorNotification({
            message:
              response.data?.data?.responseMessage + additionalDescription,
          });
          let errorMessage = response.data?.data?.responseMessage;
          this.sendOtherValidationEmailError(errorMessage);
        } else {
          this.savedLoanId = response.data?.data?.loanId;
          if (this.loanInformationStore.form.fields.fastTrack.value === 'Y') {
            const fastTrackInfo = yield this.loanService.getLoanDetails(
              this.savedLoanId.toString()
            );
            this.hasFastTrack = true;
            this.isFastTrackPaymentComplete =
              fastTrackInfo.data?.data?.fastrackPaymentComplete;
          }
          this.saved = true;
        }
      } catch (error) {
        this.saved = true;
        this.sendLoanSubmissionErrorEmail(
          error?.error?.response?.data?.error?.message ||
          error?.error?.message ||
          error
        );
      }
    }
  }



  *onEditLoanSubmit(onSuccessCallback = () => { }) {
    if (
      this.properties.some(p => p.totalRenovationBudget === 0) &&
      isFixAndFlip(this.loanSubtype)
    ) {
      this.saved = false;
      this.resubmitAsStabilized = true;
    } else {
      try {
        this.loan = this.loanSubmitJson;
        this.disableSubmitButton = true;
        const data = this.loan;

        const response: ApiResponse = yield this.editLoanSubmissionService.updateLoanSubmission(
          data, this.editLoanJson.loanId
        );

        if (response.data?.data?.success === false) {
          const additionalDescription =
            '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
          this.globalStore.notificationStore.showErrorNotification({
            message:
              response.data?.data?.responseMessage + additionalDescription,
          });
          this.saved = true;
        } else {
          this.savedLoanId = response.data?.data?.loanId;
          this.saved = true;
          onSuccessCallback();
        }
      } catch (error) {
        this.saved = true;
      }
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return canSubmitAppraisalWithTamarisk(
      this.loanSubtype,
      this.globalStore.userFeatures
    );
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.loanService.sendErrorEmail(
      'Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  *sendFastTrackPayment() {
    yield this.loanInformationStore.requestFastTrackPaymentEmail({
      loanId: this.loanInformationStore.draftLoanId,
      borrowers: [...this.borrowersRows],
    });
  }

  *getRateFloor(data) {
    const userInfo = yield this.user
      .getUserInformation()
      .then(result => result.data.data.user);
    let extraPayload = {};
    if (this.loanSubtype === LoanSubType.GROUND_UP && this.globalStore.lenderInfo?.isInternal) {
      const loanTerm = parseInt(this.loanTerms.duration) || 12;
      let biggestExperience = this.getHighestExperience();
      let biggestFicoInfo = this.getHighestFICOInfo();
      const { loanPurpose, sunkCost } = this.getPropertyValuesForRateAdjustments();
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: biggestExperience,
          fico: biggestFicoInfo.fico,
          ficoBorrowerId: !isNaN(biggestFicoInfo.borrowerId) ? biggestFicoInfo.borrowerId : null,
          transactionType: loanPurpose,
          completedRenovations: sunkCost,
          leverage: this.getPropertiesLTFCFromLoanInformationStore() || 0,
          loanTerm,
          totalRenoBudget: this.loanTerms.constructionHoldback,
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.fixflipGroundUpService.getRateFloor({
      ...data,
      userId: userInfo.userId,
      ...extraPayload,
    });
    this.rateAdjustmentsData = response.data;
    if (response.data && data?.enableFinancedIr && isGroundUp(this.loanSubtype) && isNil(this.editLoanJson.loanId)) {
      this.loanInformationStore.groundUpInitialCalculations(response.data);
    }
    return { data: response.data?.floorRate || 0 };
  }

  *fetchBorrowerPointsOptions() {
    try {
      const formValues = this.loanInformationStore.getFormValues();
      const { duration, points } = formValues;
      const lenderId = this.globalStore.lenderInfo.lenderId;
      const response = yield this.fixflipGroundUpService.calculateBorrowerPointsOptions(
        lenderId,
        duration,
        null,
      ).then(r => r.data);
      this.floorPoints = (response?.length || 0) > 0 ? Number(response[0].value) : 1;
      this.maxPoints = (response?.length || 0) > 0 ? Number(response[response.length-1].value) : 5;
      if(points < this.floorPoints) this.loanInformationStore.onFieldChange('points', this.floorPoints);
      if(points > this.maxPoints) this.loanInformationStore.onFieldChange('points', this.maxPoints);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while calculating values',
      });
    }
  }

  getHighestExperience() {
    const loanData = this.loanSubmitJson;
    let experience = loanData.borrowers.reduce((currentExp, borr) => {
      if (borr.experience > currentExp) {
        return borr.experience;
      }
      return currentExp;
    }, 0);

    if (!experience) {
      experience = loanData.borrowers.reduce((currentExp, borr) => {
        if (borr.experienceProvidedAtOrigination > currentExp) {
          return borr.experienceProvidedAtOrigination;
        }
        return currentExp;
      }, 0);
    }
    return experience;
  }

  getHighestFICOInfo() {
    const loanData = this.loanSubmitJson;
    const personalGuarantorBorrowers = loanData.borrowers.filter(borr => borr.personalGuarantor);
    let ficoInfo = personalGuarantorBorrowers.reduce((currentFicoInfo, borr) => {
      if (borr.medianScore > currentFicoInfo.fico) {
        return { fico: borr.medianScore, borrowerId: Number(borr.borrowerId) };
      }
      return currentFicoInfo;
    }, { fico: 0, borrowerId: 0 });

    if (!ficoInfo.fico) {
      ficoInfo = personalGuarantorBorrowers.reduce((currentFicoInfo, borr) => {
        if (borr.ficoProvidedAtOrigination > currentFicoInfo.fico) {
          return { fico: borr.ficoProvidedAtOrigination, borrowerId: Number(borr.borrowerId) };
        }
        return currentFicoInfo;
      }, { fico: 0, borrowerId: 0 });
    }
    return ficoInfo;
  }

  getPropertyValuesForRateAdjustments() {
    const loanData = this.loanSubmitJson;
    let loanPurpose = 'Purchase';
    let sunkCost = 0;

    loanData.properties.forEach(prop => {
      if (prop.propertyOwnership === 'Owned By Borrower') {
        loanPurpose = 'Refinance';
        sunkCost += prop.sunkCost;
      }
    });

    return {
      loanPurpose,
      sunkCost,
    }
  }

  setRushedAppraisalProperties(value) {
    this.properties = this.properties.map(prop => ({
      ...prop,
      submitRushedAppraisal: value,
    }));
  }

  get isLoanRequest() {
    return this.draftLoanInfo?.type === REQUEST_LOAN_TYPE_LOAN;
  }

  get isBorrowerLoanApplication() {
    return this.loanApplication.loanApplicationId != null;
  }

  *validateBorrowerErrors() {
    try {
      const response: ApiResponse = yield this.loanService.getValidateBorrowerLoanErrors({
        borrowerRows: [...this.borrowersRows],
        loanValues: {
          marketingPromotionId: this.loanTerms.marketingPromotionId,
          loanId: this.editLoanJson.loanId
        }
      });
      const validateErrors = response.data.data.errors;
      const validateWarnings = response.data.data.warnings;
      this.allErrors = [...this.allErrors, ...validateErrors];
      this.allWarnings = [...this.allWarnings, ...validateWarnings];
    } catch (error) {
      console.log(error);
    }
  }

  *validateEntityErrors() {
    try {
      const data = this.loanSubmitJson;
      if (!this.entity.borrowerEntityId) {
        return;
      }
      const response: ApiResponse = yield this.loanService.getValidateEntityLoanErrors(
        this.entity.borrowerEntityId
      );
      const validateErrors = response.data.data.errors;
      this.allErrors = [...this.allErrors, ...validateErrors];
    } catch (error) {
      console.log(error);
    }
  }

  setLoanApplicationId = (value: number) => {
    this.loanApplication.loanApplicationId = value;
  };

  *getLoanApplicationById() {
    try {
      const response: ApiResponse = yield this.loanService.getLoanApplicationById(
        this.loanApplication.loanApplicationId
      );
      this.reset();

      const data = response?.data?.data;
      this.loanApplication = data;
      this.mapLoanApplicationToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }

  mapLoanApplicationToStore(loanApplication: any) {
    const { loanType, loanSubtype, loanId } = loanApplication;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntityForLoanApplication(loanApplication);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(loanApplication, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));
    this.properties = getBridgeProperties(loanApplication);
    this.setLoanApplicationId(loanId);
  }

  *validateBridgeSubmission() {
    try {
      if (isFixAndFlip(this.loanSubtype)) {
        yield this.validateBridgeSubmissionStep(LoanSubmissionStep.SUMMARY);
      } else {
        const data = this.loanSubmitJson;
        const skipLeverageValidations = !isNil(this.editLoanJson.loanId);
        const response: ApiResponse = yield this.loanService.getValidateBridgeLoanErrors(
          { ...data, internalLender: this.globalStore.lenderInfo?.isInternal }, skipLeverageValidations
        );
        if (!response.data.data.isValid) {
          const validateErrors = response.data.data.errors;
          this.allErrors = [...this.allErrors, ...validateErrors];
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  *validateBridgeSubmissionStep(step: string) {
    try {
      this.stepErrors = [];
      const data = this.loanSubmitJson;
      const skipLeverageValidations = !isNil(this.editLoanJson.loanId);
      const response: ApiResponse = yield this.loanService.validateBridgeLoanStep(
        { ...data, internalLender: this.globalStore.lenderInfo?.isInternal },
        step,
        skipLeverageValidations
      );
      if (response.data?.data) {
        const data = response.data.data;
        const validateErrors = data.errors || [];
        this.stepErrors = validateErrors;

        const validateWarnings = data.warnings || [];
        this.stepWarnings = validateWarnings;

        if (data.calculatedFields) {
          this.processStepCalculatedFields(data.calculatedFields);
        } else {
          this.maxLTC = 75;
        }
      }
    } catch (error) {
      console.log(error);
      this.maxLTC = 75;
    }

    if (this.loanInformationStore.form.fields.initialLoanToPurchase.value > this.maxLTC) {
      this.loanInformationStore.changeInitialLoanToPurchase('initialLoanToPurchase', this.maxLTC);
    }
  };

  processStepCalculatedFields(calculatedFields) {
    const updatedFields: any = {};
    if (calculatedFields.interestAccrualMethod) {
      this.loanInformationStore.onFieldChange('interestAccrualMethod', calculatedFields.interestAccrualMethod);
      updatedFields.interestAccrualMethod = calculatedFields.interestAccrualMethod;
    }
    if (calculatedFields.interestReserveMonths) {
      this.loanInformationStore.onFieldChange('interestReserveMonths', calculatedFields.interestReserveMonths);
      updatedFields.interestReserveMonths = calculatedFields.interestReserveMonths;
    }
    if (calculatedFields.ltc) {
      this.maxLTC = calculatedFields.ltc;
    } else {
      this.maxLTC = 75;
    }
    this.loanTerms = { ...this.loanTerms, ...updatedFields };
  }

  loadQuoteDataToStore(quoteData: any) {
    this.loanType = LoanType.RESIDENTIAL_BRIDGE;
    this.loanSubtype = quoteData.loanSubtype;
    this.isContinuingFromQuote = true;

    const propertyMap: PropertyDSCR = {
      propertyId: getUniqueId().toString(),
      address: quoteData.propertyAddress,
      streetNumber: quoteData.streetNumber,
      streetName: quoteData.streetName,
      city: quoteData.city,
      state: quoteData.state,
      zipCode: quoteData.zipCode,
      latitude: quoteData.latitude,
      longitude: quoteData.longitude,
      propertyType: quoteData.propertyType,
      useCode: quoteData.propertyType,
      purchasePrice: quoteData.acquisitionPrice,
      purchaseDate: quoteData.purchaseDate ? formatDate(quoteData.purchaseDate, 'MM/dd/yyyy') : null,
      asIsValue: quoteData.asIsValue,
      totalRenovationBudget: quoteData.renoBudget,
      sunkCost: quoteData.completedCapitalImprovements,
      propertyOwnership:
        quoteData.loanPurpose === REFINANCE
          ? 'Owned By Borrower'
          : PURCHASE,
      afterRepairValue: quoteData.afterRepairValue,
      exitStrategy: quoteData.exitStrategy,
      monthlyRent: quoteData.monthlyRent,
      refiAmount: quoteData.payoffAmount,
      anyDebt: quoteData.payoffAmount && quoteData.payoffAmount > 0 ? 'Y' : 'N',
      renovationRemaining: (quoteData.renoBudget ? quoteData.renoBudget : 0)
        - (quoteData.completedCapitalImprovements ? quoteData.completedCapitalImprovements : 0),
      describeRenovation: (LoanSubType.GROUND_UP == quoteData.loanSubtype) ?
        renovationDescriptionsByLoanSubtype(LoanSubType.GROUND_UP) : quoteData.describeRenovation,
      monthlyMarketRentProvidedAtOrigination: quoteData.monthlyRent,
      annualTaxesProvidedAtOrigination: quoteData.annualTaxes,
      annualInsuranceProvidedAtOrigination: quoteData.annualInsurance,
      annualHOAProvidedAtOrigination: quoteData.annualHOADues,
    }
    this.properties = [propertyMap];

    this.loanTerms = {
      ...this.loanInformationStore.getFormValues(),
      fullPrincipalInterest: quoteData.fpi === 'Yes',
      initialLoanAmount: quoteData.initialLoanAmount,
      initialLoanToPurchase: quoteData.initialLTCPercentage,
      constructionHoldback: quoteData.constructionHoldback,
      constructionHoldbackPercentage: quoteData.constructionHoldbackPercentage,
      rate: quoteData.borrowerRate,
      points: (quoteData.borrowerPoints && Number(quoteData.borrowerPoints) >= 1) ? quoteData.borrowerPoints : 1,
      brokerPoints: quoteData.brokerPointsAtOrigination || this.loanTerms.brokerPoints,
      interestReserveMonths: quoteData.interestReserveMonths,
      duration: quoteData.loanTermsMonths,
      requestedClosingDate: quoteData.loanClosingDate,
      amount: quoteData.totalLoanAmount,
      lenderUnderwritingFee: this.isInternal ? (quoteData.underwritingFee || this.loanTerms.lenderUnderwritingFee) : 0,
      lenderProcessingFee: this.isInternal ? (quoteData.processingFee || this.loanTerms.lenderProcessingFee) : 0,
      externalLenderUnderwritingFee: this.isInternal ? 0 : (quoteData.underwritingFee || this.loanTerms.externalLenderUnderwritingFee),
      externalLenderProcessingFee: this.isInternal ? 0 : (quoteData.processingFee || this.loanTerms.externalLenderProcessingFee),
      loanSubType: quoteData.loanSubtype,
    };
    this.loanInformationStore.initForm(this.loanTerms);
  }

  *getAreBorrowersPreQualifiedFromPrescreenValidations() {
    try {
      this.areBorrowersPreQualified = true;

      if (!this.isDealRoom) {
        const response = yield this.loanService.getAreBorrowersPreQualifiedFromPrescreenValidations(this.loanSubmitJson);
        this.areBorrowersPreQualified = response?.data?.data;
        if (!this.areBorrowersPreQualified) {
          this.allWarnings.push('Your loan will be automatically assigned to our underwriting team once borrower credit and background are received.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  *markProposalAsCompleted(proposalId: number) {
    yield this.proposalStore.updateProposalStatus(proposalId, COMPLETED_STATE);
  }

  sendOtherValidationEmailError(errorMessage: any) {
    if (errorMessage !== LOAN_WITHOUT_LEAD_SOURCE_VALIDATION) {
      errorMessage += `. For ${this.globalStore.lenderInfo.companyName} with lender id ${this.globalStore.lenderInfo.lenderId}.`;
      this.sendLoanSubmissionErrorEmail(errorMessage);
    }
  }

  setBroker(broker) {
    if (!isNil(broker)) {
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker,
        entityName: broker?.company?.name,
        entityTaxNumber: broker?.company?.einNumber,
        brokerId: broker?.lenderId,
        companyId: broker?.company?.companyId,
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
  }

  *getEditLoanJsonById(editLoanId) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanSummary(
        editLoanId
      );
      this.reset();
      const data = JSON.parse(response?.data?.data);
      this.editLoanJson = data;
      this.mapEditLoanJsonToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }
  mapEditLoanJsonToStore(editLoanJson: any) {
    const { loanType, loanSubtype } = editLoanJson;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntityForLoanApplication(editLoanJson);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(editLoanJson, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setIsLoanBeingEdit(!isNil(this.editLoanJson.loanId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));
    this.properties = getBridgeProperties(editLoanJson);
    this.loanTerms = getBridgeLoanTerms(editLoanJson);
    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(this.loanTerms.otherInsuranceCompany);
    this.loanInformationStore.otherTitleFormStore.loadForm(this.loanTerms.otherTitleCompany);
    this.setBroker(editLoanJson?.broker);
  }

  get isInternal() {
    return this.globalStore.lenderInfo?.isInternal;
  }

  *getProfitInfo() {
    try {
      if (this.propertiesRows.length > 0) {


        const payload = {
          ...this.getPropertiesInfoForProfit(),
          ...this.getBorrowerAndEntityInfoForProfit(),
          ...this.getLoanInfoForProfit(),
          ...this.getLenderFeesForProfit(),
          loanSubtype: this.loanSubtype,
        }

        this.quoteGeneratorDetailsStore.loadForm(payload);
        yield this.quoteGeneratorDetailsStore.calculateQuote(this.loanTerms);
        this.quoteGeneratorDetailsStore.setCalculationStarted();
      }
    } catch (e) {
      console.log('error on getProfitInfo', e);
    }
  }

  getPropertiesInfoForProfit = () => {
    let acquisitionPrice = 0;
    let asIsValue = 0;
    let renoBudget = 0;
    let completedCapitalImprovements = 0;
    let afterRepairValue = 0;
    let firstPropertyInfo: any = {};

    if (this.propertiesRows.length > 0) {
      const firstProperty = this.propertiesRows[0];

      this.propertiesRows.forEach(prop => {
        acquisitionPrice += prop.purchasePrice;
        asIsValue += prop.purchasePrice;
        renoBudget += prop.renovationBudget;
        afterRepairValue += Number(prop.afterRepairValue);
        if (firstProperty.propertyOwnership === 'Refinance') {
          completedCapitalImprovements += prop.sunkCost;
        }
      });
      firstPropertyInfo = {
        ...firstProperty,
        loanPurpose: firstProperty.exitStrategy,
        propertyType: firstProperty.useCode,
        propertyAddress: firstProperty.address,
      };
    }

    return {
      ...firstPropertyInfo,
      acquisitionPrice,
      asIsValue,
      renoBudget,
      completedCapitalImprovements,
      afterRepairValue,
    };
  }

  getBorrowerAndEntityInfoForProfit = () => {
    let useBorrowerEntity = false;
    let borrowerEntity = '';
    let borrowerFirstName = '';
    let borrowerLastName = '';
    let estimatedFicoScore = 660;
    let experienceScore = 1;

    if (isNotBlank(this.entity.name)) {
      useBorrowerEntity = true;
      borrowerEntity = this.entity.name;
    }

    if (this.borrowersRows.length > 0) {
      // check first for PG
      let selectedBorrower = this.borrowersRows.find(borr => borr.personalGuarantor);

      // if no pg - first borrower in the list
      if (!selectedBorrower) {
        selectedBorrower = this.borrowersRows[0];
      }

      borrowerFirstName = selectedBorrower.firstName;
      borrowerLastName = selectedBorrower.lastName;
      estimatedFicoScore = selectedBorrower.medianScore || selectedBorrower.ficoProvidedAtOrigination || 660;
      experienceScore = selectedBorrower.experience || selectedBorrower.experienceProvidedAtOrigination || 1;
    }

    return {
      useBorrowerEntity,
      borrowerFirstName,
      borrowerLastName,
      borrowerEntity,
      estimatedFicoScore,
      experienceScore,
    };
  }

  getLoanInfoForProfit = () => {
    const loanInformationValues = this.loanInformationStore.getFormValues();

    return {
      initialLTCPercentage: loanInformationValues.initialLoanToPurchase,
      constructionHoldbackPercentage: loanInformationValues.constructionHoldbackPercentage,
      borrowerRate: loanInformationValues.rate,
      borrowerPoints: loanInformationValues.points,
      loanTermsMonths: this.loanTerms.duration,
      loanClosingDate: this.loanTerms.requestedClosingDate,
      exitLoanRate: 6.5,
      underwritingFee: this.loanTerms.lenderUnderwritingFee,
      processingFee: this.loanTerms.lenderProcessingFee,
      brokerPointsAtOrigination: this.loanTerms.brokerPoints,
      borrowerFloorRate: this.rateAdjustmentsData?.floorRate || 8,
      submissionDate: new Date(),
      isInternal: false,
    };
  }

  getLenderFeesForProfit = () => {
    let processingFee = this.loanTerms?.lenderProcessingFee || 0;
    let underwritingFee = this.loanTerms?.lenderUnderwritingFee || 0;

    if (this.lenderFeesInfo) {
      processingFee = this.lenderFeesInfo.processingFee;
      underwritingFee = this.lenderFeesInfo.underwritingFee;
    }

    return {
      processingFee,
      underwritingFee,
    }
  }

  *fetchDefaultFees() {
    try {
      if (!this.globalStore.userFeatures.isBroker && !this.isInternal && !this.lenderFeesInfo) {
        const response = yield this.loanSubmissionService.getMyLenderFeesInfo();
        this.lenderFeesInfo = response.data?.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the lender fees',
      });
    }
  }

  get getTotalLenderFees() {
    if (this.lenderFeesInfo) {
      const {
        adminFee = 0,
        appraisal = 0,
        commitmentFee = 0,
        constructionDrawFee = 0,
        custodianFee = 0,
        legalReviewFee = 0,
        processingFee = 0,
        servicingFee = 0,
        servicingSetupFee = 0,
        underwritingFee = 0,
        valuationReviewFee = 0,
        wireFee = 0,
      } = this.lenderFeesInfo;

      return (
        servicingFee +
        servicingSetupFee +
        appraisal +
        legalReviewFee +
        valuationReviewFee +
        processingFee +
        adminFee +
        underwritingFee +
        custodianFee +
        commitmentFee +
        wireFee +
        constructionDrawFee
      );
    }
    return 0;
  }

  validateProtectedBorrowers() {
    try {
      const borrowers = this.loanSubmitJson.borrowers;

      if (borrowers != null && borrowers.length > 0) {
        borrowers.map(borrower => {
          if (borrower?.protectedBorrower) {
            this.allWarnings.push('The Borrower ' + borrower.fullName + ' currently has loans associated with an active TPO');
          }
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  get loanApplicationJson() {
    const loanApplication = this.loanSaveJson;

    return {
      ...loanApplication,
      loanBorrowerInformation: {
        ...loanApplication.loanBorrowerInformation,
        borrowerEntity: {
          ...loanApplication.borrowerEntityObj
        },
      },
      loanClosingData: {},
      broker: isNil(loanApplication?.broker) || !isNil(loanApplication?.broker?.company) ? loanApplication?.broker : {
        lenderId: loanApplication?.broker?.brokerId,
        companyId: loanApplication?.broker?.companyId,
        lenderName: loanApplication?.broker?.entityName,
        company: {
          companyId: loanApplication?.broker?.companyId,
          name: loanApplication?.broker?.entityName,
          einNumber: loanApplication?.broker?.entityTaxNumber,
          phone: loanApplication?.broker?.cellPhone,
          email: loanApplication?.broker?.emailAddress,
          contacts: [
            {
              contactId: loanApplication?.broker?.contactId,
              firstName: loanApplication?.broker?.firstName,
              lastName: loanApplication?.broker?.lastName,
              emailAddress: loanApplication?.broker?.emailAddress,
              workPhone: loanApplication?.broker?.cellPhone,
            }
          ]
        }
      }
    };
  }

  *onLoanApplicationSave() {
    try {
      this.loan = this.loanApplicationJson;
      this.loan.marketingPromotionId = null;
      if (this.allErrors.length > 0) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(this.loan, 'loan request');
        const response: ApiResponse = yield this.loanService.submitLoanApplicationForBrokerOrLenderOriginator(
          this.loan
        );
        if (response.data) {
          this.savedLoanId = response.data?.data?.loanId;
        }
        this.requestSaved = true;
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan request.',
      });
    }
    this.saveLoanQuoteForApplication();
  }

  *saveLoanQuoteForApplication() {
    try {
      if (!isNil(this.savedLoanId)) {
        const quoteData = {
          ...getQuoteFromLoanSubmitJson(this.savedLoanId, this.loanSubmitJson),
          lenderId: this.globalStore.lenderInfo.lenderId,
        };
        const response = yield this.oneToolService.saveQuote(quoteData);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuoteForApplication because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving application as loan quote');
      console.log(error);
    }
  }

}
