import { GridColumnProps } from '@roc/client-portal-shared/components';
import { statusProperties } from '@roc/client-portal-shared/utils';
import { formatCurrency } from '@roc/ui/utils';
import { tooltips } from '@roc/feature-loans';
import { loanPriorityOptions } from '@roc/feature-loans';
import { tooltipHeaderTemplate } from '@roc/client-portal-shared/components';
import { DocumentStatus } from '@roc/feature-documents';

const usdAmount: any = params => {
  return params.value && formatCurrency(Number(params.value));
};

const noOpComparator = () => 0;

export const myPipelineLoansColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    maxWidth: 110,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'underwritingPendingDocuments',
    headerName: 'Underwriting Pending Documents',
    minWidth: 70,
    maxWidth: 320,
    cellRenderer: 'pendingDocumentsCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'closingPendingDocuments',
    headerName: 'Closing Pending Documents',
    minWidth: 70,
    maxWidth: 300,
    cellRenderer: 'pendingDocumentsCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 300,
    cellRenderer: 'dealNameCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: 'In Underwriting', value: 'IN_UNDERWRITING' },
        { label: 'Agreed', value: 'AGREED' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Pending Decision', value: 'PENDING_DECISION' },
        { label: 'Pending Term Sheet', value: 'PENDING_TERM_SHEET' },
        { label: 'Prescreen', value: 'PRESCREEN' },
        { label: 'Term Sheet Out', value: 'TERM_SHEET_OUT' },
        { label: 'Unknown', value: 'UNKNOWN' },
        { label: 'Pre-Submission', value: 'PENDING_SUBMISSION' },
        { label: 'Deal Room', value: 'DEAL_ROOM' },
      ],
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 125,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'fastrackPaymentComplete',
    headerName: 'Fast Tracked Loan',
    minWidth: 125,
    cellRenderer: 'fastTrackCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'appraisalReportUploaded',
    headerName: 'Appraisal Report Uploaded',
    minWidth: 125,
    cellRenderer: 'appraisalReportUploadedCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'interestEconomics',
    headerName: 'Interest Economics',
    minWidth: 200,
    comparator: noOpComparator,
    headerTooltip: tooltips.interestEconomics,
    headerComponentParams: {
      template: tooltipHeaderTemplate,
    },
  },
  {
    field: 'keyRatios',
    headerName: 'Key Ratios',
    minWidth: 200,
    comparator: noOpComparator,
    headerTooltip: tooltips.keyRatios,
    headerComponentParams: {
      template: tooltipHeaderTemplate,
    },
  },
  {
    field: 'submittedBy',
    headerName: 'Submitted By',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'coordinator',
    headerName: 'Processor',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'underwriter',
    headerName: 'Underwriter',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'priority',
    headerName: 'Priority',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: loanPriorityOptions,
    },
  },
  {
    field: 'lenderOriginatorName',
    headerName: 'Originator',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    }
  },
  {
    headerName: 'Action',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];

export const myPendingTasksColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 300,
    cellRenderer: 'dealNameCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 130,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'borrowerEntity',
    headerName: 'Borrower Entity',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 125,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'fastrackPaymentComplete',
    headerName: 'Fast Tracked Loan',
    minWidth: 125,
    cellRenderer: 'fastTrackCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'insuranceApprovalStatus',
    headerName: 'Insurance Status',
    minWidth: 125,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    filter: 'selectFilter',
    floatingFilter: true,
    floatingFilterComponent: 'selectFloatingFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      options: [
        { label: DocumentStatus.PENDING, value: DocumentStatus.PENDING },
        { label: DocumentStatus.MORE_INFO_NEEDED, value: DocumentStatus.MORE_INFO_NEEDED },
      ],
    },
  },
  {
    field: 'expectedClosingDate',
    headerName: 'Expected Closing Date',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    minWidth: 125,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'lenderOriginatorName',
    headerName: 'Originator',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    }
  },
];

export const myDraftLoansColumns: GridColumnProps[] = [
  {
    field: 'borrowers',
    headerName: 'Borrowers',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Subtype',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 130,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    }
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    minWidth: 125,
    comparator: noOpComparator,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Action',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
]

export const myQuoteLoansColumns: GridColumnProps[] = [
  {
    field: 'borrowers',
    headerName: 'Borrowers',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubtype',
    headerName: 'Loan Subtype',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    }
  },
  {
    field: 'loanAmount',
    headerName: 'Amount',
    valueFormatter: usdAmount,
    minWidth: 125,
    comparator: noOpComparator,
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Action',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
]

export const myRequestLoansColumns: GridColumnProps[] = [
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 400,
    cellRenderer: 'dealNameRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    minWidth: 125,
    comparator: noOpComparator,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    minWidth: 150,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'key',
    headerName: 'Action',
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true,
    equals: () => false,
  },
];


export const myPendingApprovalLoansColumns: GridColumnProps[] = [
  {
    field: 'borrowers',
    headerName: 'Borrowers',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Subtype',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 130,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'originatorName',
    headerName: 'Originator',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    }
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    minWidth: 125,
    comparator: noOpComparator,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 130,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
  },
];
export const myAgreedLoansColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 100,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 300,
    cellRenderer: 'dealNameCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanSubType',
    headerName: 'Loan Type',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'attorney',
    headerName: 'Attorney',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 125,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'pendingClosingConditions',
    headerName: 'Closing Conditions',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    cellRenderer: 'pendingClosingConditionsCellRenderer',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'expectedClosingDate',
    headerName: 'Expected Closing Date',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'expectedWireReleaseDate',
    headerName: 'Expected Wire Release Date',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'fastrackPaymentComplete',
    headerName: 'Fast Tracked Loan',
    minWidth: 125,
    cellRenderer: 'fastTrackCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'insuranceApprovedStatus',
    headerName: 'Insurance Approved Status',
    minWidth: 125,
    cellRenderer: 'insuranceApprovedCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'closingDocsStatus',
    headerName: 'Closing Docs Status',
    cellRenderer: 'closingDocsCellRenderer',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'hudStatus',
    headerName: 'HUD Status',
    cellRenderer: 'hudStatusCellRenderer',
    minWidth: 200,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'finalLoanApprovalStatus',
    headerName: 'Loan Approval Status',
    minWidth: 300,
    cellRenderer: 'loanApprovalCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'wireRequested',
    headerName: 'Wire Requested',
    minWidth: 300,
    cellRenderer: 'wireRequestedCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
];

export const myAppraisalToDosColumns: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    maxWidth: 110,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'section',
    headerName: 'Section',
    minWidth: 125,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 300,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'startDate',
    headerName: 'Requested Date',
    minWidth: 125,
    comparator: noOpComparator,
    filter: 'agDateColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'daysInQueue',
    headerName: 'Hours in queue',
    minWidth: 125,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    headerName: 'Action',
    minWidth: 80,
    cellRenderer: 'actionCellRenderer',
    comparator: noOpComparator,
    width: 80,
    pinned: 'right',
    lockPinned: true
  },
];
