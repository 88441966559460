/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { forwardRef } from "react";
import { Page1 } from "./page1";
import { Page2 } from "./page2";
import { Page3 } from "./page3";
import { Page4 } from "./page4";
import { Page5 } from "./page5";
import { Page6 } from "./page6";
import { Page7 } from "./page7";
import { PrintableArea } from "@roc/ui";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";

interface InternalValuationReportProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const InternalValuationReport = forwardRef<any, any>(({ internalValuationReviewStore }: InternalValuationReportProps, ref) => {
  return (<PrintableArea ref={ref}>
    <Page1 key={1} internalValuationReviewStore={internalValuationReviewStore} />
    <Page2 key={2} internalValuationReviewStore={internalValuationReviewStore} />
    <Page3 key={3} internalValuationReviewStore={internalValuationReviewStore} />
    <Page4 key={4} internalValuationReviewStore={internalValuationReviewStore} />
    <Page5 key={5} internalValuationReviewStore={internalValuationReviewStore} />
    <Page6 key={6} internalValuationReviewStore={internalValuationReviewStore} />
    <Page7 key={7} internalValuationReviewStore={internalValuationReviewStore} />
  </PrintableArea>)
});