import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout, Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { AppraisalReportForm } from 'libs/feature-documents/src/documents/components/documentForms/appraisalReport/appraisalReportForm';

export const getAppraisalReportRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanTaskId: any
) => {
  return {
    appraisalReport: {
      path: `${basePath}/appraisal-report`,
      url: `${baseUrl}/appraisal-report`,
      documentTitle: 'appraisal-report',
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white',
  },
}));

export const getAppraisalReportRoutes = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  loanTaskId: any,
) => {
  const config = getAppraisalReportRoutesConfig(basePath, baseUrl, loanId, loanTaskId);
  const classes = useStyles();

  return {
    appraisalReport: (
      <Route exact path={config.appraisalReport.path}>
        <Page routeDefinition={config.appraisalReport}>
          <Box pl={3} pr={3} style={{ background: 'white' }}>
            <Layout title="Appraisal Report" minHeight={100} containerClass={classes.container}>
              <EmbeddedFrameBoundaries>
                <AppraisalReportForm
                  open={true}
                  loanTaskId={loanTaskId}
                  handleClose={() => { window.parent.postMessage('saved-success', '*'); }}
                  internal={true}
                />
              </EmbeddedFrameBoundaries>
            </Layout>
          </Box>
        </Page>
      </Route>
    ),
  };
}