import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TitleDivider } from "../titleDivider";
import { observer } from "mobx-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { CompMap, MapLegend } from "../mapComponents";
import { ComparablesTable } from "../comparablesTable";
import { FieldSetBox } from "../fieldSetBox";

const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    backgroundColor: "#FFFFFF",
    padding: "2rem",
  },
  mapContainer: {
    height: "504px",
    width: "100%",
    position: "relative"
  }
}));

interface ComparableAnalysisTabProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ComparableAnalysisTab = observer(({ internalValuationReviewStore }: ComparableAnalysisTabProps) => {
  const classes = useStyles();

  const markers = internalValuationReviewStore.getPropertyCompsMarkers();


  const compsAvailable = markers && markers.length > 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      className={classes.tabContent}
    >
      <TitleDivider title="Comparable Analysis" />

      {compsAvailable && (<><Box className={classes.mapContainer}>
        <CompMap markers={markers} />
        <Box
          style={{
            position: "absolute",
            top: 15,
            right: 0,
          }}
        >
          <FieldSetBox title="Legend">
            <MapLegend markers={markers} liteMode />
          </FieldSetBox>
        </Box>
      </Box>
        <ComparablesTable internalValuationReviewStore={internalValuationReviewStore} />
      </>)}
      {!compsAvailable && (<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          height: "300px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          padding: "20px",
        }}
      >
        <Typography variant="h6">
          Comparable properties not available. Please go to the Comp Tool and save the comparable properties to continue.
        </Typography>
      </Box>)}

    </Box>
  );
});
