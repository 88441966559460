import { Box, Grid } from '@material-ui/core';
import { DateField, Button, FileUploadModal, FileUpload, IconButton } from '@roc/ui';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import moment from "moment";
import { Publish, FormatListBulleted } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useDocumentStore } from '@roc/feature-documents';

const useStyles = makeStyles(theme => ({
  stageItem: {
    display: "flex",
    padding: theme.spacing(1, 2),
  },
  stageButton: {
    marginRight: theme.spacing(2),
    color: "white",
    width: "50px",
    height: "50px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    justifyContent: "center",
    boxShadow: "0px 0px 1px rgba(0,0,0,0.2)",
    cursor: "pointer",
  },
  stageName: {
    fontWeight: "bold",
  },
  contentBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fileButtonUpload: {
    marginLeft: '10px',
  },

}));

export const StageItem = observer(({
  stage,
  stageNumber,
  currentStage,
  pastStage,
  onSelect,
  onDateChange,
  onUploadFile,
}) => {
  const classes = useStyles();
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const { documentStore } = useDocumentStore();

  const buttonColor = currentStage
    ? "#285391"
    : pastStage
      ? "#4b81ce"
      : "#7F7F7F";

  const _onUpload = async (uploads: FileUpload[]) => {
    onUploadFile(stage.stageName, uploads[0]);
  };

  return (
    <>
      <Box className={classes.stageItem}>
        <Box
          className={classes.stageButton}
          style={{ backgroundColor: buttonColor }}
          onClick={onSelect}
        >
          {stageNumber}
        </Box>
        <Box className={classes.contentBox} >
          <Grid container>
            <Grid item xs={12} md={12} lg={12} >
              <Box className={classes.stageName}>{stage.stageName}</Box>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <DateField
                testId="settlementDate"
                format="MM/dd/yyyy"
                standaloneLabel
                value={stage.stageDate ? new Date(stage.stageDate) : null}
                onChange={(date) => onDateChange(moment(date).format("MM/DD/YYYY"))}
                disabled={(!currentStage && !pastStage)}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2} >
              <Tooltip title="Upload File">
                <IconButton
                  className={classes.fileButtonUpload}
                  testId="uploadFile"
                  size="small"
                  color="primary"
                  disabled={false}
                  onClick={() => setIsFileUploadModalOpen(true)}
                >
                  <Publish fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <Tooltip title="Download File">
                <IconButton
                  testId="downloadFile"
                  size="small"
                  color="primary"
                  disabled={!stage.documentId}
                  onClick={() => {
                    const document = {
                      loanTaskId: stage.documentId,
                    };
                    documentStore.downloadDocumentOriginalNameById(document);
                  }}
                >
                  <FormatListBulleted fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <FileUploadModal
        title="Upload File"
        open={isFileUploadModalOpen}
        multiple={false}
        onClose={() => setIsFileUploadModalOpen(false)}
        onUpload={_onUpload}
      />
    </>
  );

});
