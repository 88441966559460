import { isMobileApp, isNil } from '@roc/feature-utils';
import { AddParticipantRequest, ChatRoomType, ConversationGroup, FetchConversationMessageRequest, ResetUnreadCountRequest, UpdateConversationNameRequest, UpdateConversationStarredStatusRequest } from '../types/communicationTypes';
import { ICommunicationService } from './iCommunicationService';

const url = {
  GET_TOKEN: '/api/v1/vendor/twilio/getTokenExternal',
  GET_ALL_TOKENS: '/api/v1/vendor/twilio/getAllTokensExternal',
  GET_CONVERSATION_MESSAGE: '/api/v1/vendor/twilio/conversation/fetchConversationMessages',
  GET_PARTICIPANTS_ALLOWED: '/api/v1/vendor/twilio/participant/getParticipantsAllowedByLoanId',
  GET_PARTICIPANTS_ALLOWED_BY_CHATROOM_ID: '/api/v1/vendor/twilio/participant/getParticipantsAllowedByChatroomId',
  GET_PARTICIPANTS_ALLOWED_BY_COMPANY_ID: '/api/v1/vendor/twilio/participant/getParticipantsAllowedByCompanyId',
  GET_APPRAISAL_CONVERSATIONS: '/api/v1/vendor/twilio/appraisalChatRooms/getAllAppraisalChatRoomsByAppraisalId',
  ADD_PARTICIPANT: '/api/v1/vendor/twilio/participant/addParticipantToConversation',
  REMOVE_PARTICIPANT: '/api/v1/vendor/twilio/participant/removeParticipantFromConversation',
  NEW_CONVERSATION: '/api/v1/vendor/twilio/loanChatRooms/createLoanChatRoom',
  NEW_USER_CONVERSATION: '/api/v1/vendor/twilio/loanChatRooms/createLoanChatRoomWithParticipants',
  GET_OR_CREATE_PRIVATE_CHAT_ROOM: '/api/v1/vendor/twilio/privateChatRooms/getOrCreatePrivateChatRoom',
  NEW_SUPPORT_CHAT_ROOM: '/api/v1/vendor/twilio/supportChatRoom/createSupportChatRooms',
  GET_LOAN_UNREAD_MESSAGES_COUNT: '/api/v1/vendor/twilio/chatRooms/getUserLoanConversationsUnreadCount',
  GET_TOTAL_UNREAD_MESSAGES_COUNT: '/api/v1/vendor/twilio/chatRooms/getTotalUserConversationsUnreadCount',
  GET_SUPPORT_UNREAD_MESSAGES_COUNT: '/api/v1/vendor/twilio/chatRooms/getUserSupportUnreadCount',
  RESET_UNREAD_COUNT: '/api/v1/vendor/twilio/chatRooms/resetChatRoomParticipantUnreadCount',
  UPDATE_CONVERSATION_NAME: '/api/v1/vendor/twilio/chatRooms/updateConversationName',
  UPDATE_CONVERSATION_STARRED_STATUS: '/api/v1/vendor/twilio/chatRooms/updateConversationStarredStatus',
  GET_ALL_MESSAGE_SNIPPETS: '/api/v1/vendor/twilio/loanChatRooms/getAllMessageSnippets',
  VERIFY_CHAT_ROOM_NAME: '/api/v1/vendor/twilio/loanChatRooms/verifyChatRoomName',
  GET_CONVERSATION_MESSAGE_DEFAULT_TAG: '/api/v1/vendor/twilio/chatRooms/getConversationMessageDefaultTag',
  GET_MY_CONVERSATIONS: '/api/v1/vendor/twilio/chatRooms/getMyConversations',
  GET_LOAN_UPDATES_LOGS: '/api/v1/vendor/twilio/chatRooms/getLoanUpdateLogsChatRoom',
  GET_MY_CONVERSATIONS_GROUPED: '/api/v1/vendor/twilio/chatRooms/getMyConversationsGrouped',
  GET_MY_CONVERSATION: '/api/v1/vendor/twilio/chatRooms/getMyConversation',
  LEAVE_CHAT_ROOM: '/api/v1/vendor/twilio/loanChatRooms/leaveLoanChatRoom',
  UNARCHIVE_CONVERSATION: '/api/v1/vendor/twilio/loanChatRooms/reopenChatRoom',
  GET_PARTICIPANTS_DETAILS: '/api/v1/vendor/twilio/participant/getParticipantsDetails',
  GET_PARTICIPANTS_CALENDLY_LINKS: '/api/v1/vendor/twilio/participant/getParticipantsCalendlyLinks',
  REPHRASE_MESSAGE: '/api/v1/vendor/twilio/loanChatRooms/rephraseMessage',
};

export class CommunicationService extends ICommunicationService {

  rephraseMessage(text: string) {
    return this.postWithTimeOut(
      url.REPHRASE_MESSAGE,
      {
        text
      },
      6000
    );
  }

  getToken(
    identity: string,
  ) {
    return this.get(
      url.GET_TOKEN,
      {
        identity,
      },
      {
        disableGlobalLoading: true
      }
    );
  }

  getAllTokens(
    userId: number,
  ) {
    return this.get(
      url.GET_ALL_TOKENS,
      {
        userId,
      },
      {
        disableGlobalLoading: true
      }
    );
  }

  getReplyToMessages(fetchConversationMessageRequest: FetchConversationMessageRequest) {
    return this.post(
      url.GET_CONVERSATION_MESSAGE,
      fetchConversationMessageRequest,
      {},
      {
        disableGlobalLoading: true
      }
    );
  }

  getAppraisalConversations(appraisalId: number, userId: number) {
    return this.get(
      url.GET_APPRAISAL_CONVERSATIONS,
      {
        appraisalId,
        userId
      },
    );
  }

  unarchiveConversation(chatRoomId: number) {
    return this.post(
      `${url.UNARCHIVE_CONVERSATION}?chatRoomId=${chatRoomId}`,
      null
    );
  }

  getParticipantsAllowedByLoanId(loanId, disableGlobalLoading = false) {
    return this.get(url.GET_PARTICIPANTS_ALLOWED,
      { loanId },
      {
        disableGlobalLoading
      });
  }

  addParticipant(addParticipantRequest: AddParticipantRequest) {
    return this.post(
      url.ADD_PARTICIPANT,
      addParticipantRequest,
    );
  }

  removeParticipant(conversationSid, participantSid) {
    return this.post(
      url.REMOVE_PARTICIPANT,
      {
        conversationSid,
        participantSid
      },
    );
  }

  getOrCreatePrivateChatRoom(userIds: number[]) {
    return this.post(
      url.GET_OR_CREATE_PRIVATE_CHAT_ROOM,
      {
        userIds
      },
    );
  }

  addNewConversation(conversationName, loanId) {
    return this.post(
      url.NEW_CONVERSATION,
      {
        name: conversationName,
        loanId
      },
    );
  }

  addNewUserConversation(loanId: number, userIds: number[], conversationName: string) {
    return this.post(
      url.NEW_USER_CONVERSATION,
      {
        loanId,
        userIds,
        name: conversationName
      },
    );
  }

  getUnreadCountByLoanId(loanId: string, userId: number) {
    return this.get(url.GET_LOAN_UNREAD_MESSAGES_COUNT, {
      loanId,
      userId
    }, {
      disableGlobalLoading: isMobileApp()
    });
  }

  getTotalUnreadCount(userId: number) {
    return this.get(url.GET_TOTAL_UNREAD_MESSAGES_COUNT, {
      userId,
    }, {
      disableGlobalLoading: true
    });
  }

  getConversationMessageDefaultTag(id: number, conversationInfoList: Array<any>) {
    return this.post(url.GET_CONVERSATION_MESSAGE_DEFAULT_TAG, {
      id,
      conversationInfoList,
    }, {}, {
      disableGlobalLoading: true
    });
  }

  resetUnreadCount(resetUnreadCountRequest: ResetUnreadCountRequest) {
    return this.post(url.RESET_UNREAD_COUNT, resetUnreadCountRequest, {},
      {
        disableGlobalLoading: true
      });
  }

  updateConversationName(updateConversationName: UpdateConversationNameRequest) {
    return this.post(url.UPDATE_CONVERSATION_NAME, updateConversationName, {});
  }

  updateConversationStarredStatus(updateConversationStarredStatus: UpdateConversationStarredStatusRequest) {
    return this.post(url.UPDATE_CONVERSATION_STARRED_STATUS, updateConversationStarredStatus, {},
      {
        disableGlobalLoading: true
      });
  }

  getAllMessageSnippets() {
    return this.get(url.GET_ALL_MESSAGE_SNIPPETS, {},
      {
        disableGlobalLoading: true
      });
  }

  verifyChatRoomName(name, loanId, userId) {
    return this.get(
      url.VERIFY_CHAT_ROOM_NAME,
      {
        name,
        loanId,
        userId,
      },
    );
  }

  getMyConversations(requestPayload: { userId: number; start: number; count: number; }) {
    return this.post(url.GET_MY_CONVERSATIONS, requestPayload, {}, {
      disableGlobalLoading: true
    });
  }

  getMyConversationsGrouped(requestPayload: {
    userId: number,
    start: number,
    count: number,
    conversationGroup?: ConversationGroup,
    query?: string
  }) {
    return this.post(url.GET_MY_CONVERSATIONS_GROUPED, requestPayload, {}, {
      disableGlobalLoading: true
    });
  }

  getMyConversation(requestPayload: { userId: number; conversationSid: string }) {
    return this.post(url.GET_MY_CONVERSATION, requestPayload, {}, {
      disableGlobalLoading: true
    });
  }

  createTechSupportChat(companyId) {
    const endpointUrl = `${url.NEW_SUPPORT_CHAT_ROOM}${isNil(companyId) ? '' : `?companyId=${companyId}`}`
    return this.post(endpointUrl, {});
  }

  getUnreadCountByUserId(companyId: number) {
    return this.get(url.GET_SUPPORT_UNREAD_MESSAGES_COUNT, { companyId }, {
      disableGlobalLoading: isMobileApp()
    });
  }

  leaveConversation(conversationSid, participantSid) {
    return this.post(
      url.LEAVE_CHAT_ROOM,
      {
        conversationSid,
        participantSid
      }
    );
  }

  getParticipantsAllowedByChatroomId(chatroomId, disableGlobalLoading = false) {
    return this.get(url.GET_PARTICIPANTS_ALLOWED_BY_CHATROOM_ID, { chatroomId }, { disableGlobalLoading });
  }

  getParticipantsAllowedByCompanyId(companyId, disableGlobalLoading = false) {
    return this.get(url.GET_PARTICIPANTS_ALLOWED_BY_COMPANY_ID, { companyId }, { disableGlobalLoading });
  }

  getParticipantsDetails(currentChatRoomId, disableGlobalLoading = false) {
    return this.get(
      url.GET_PARTICIPANTS_DETAILS,
      {
        chatRoomId: currentChatRoomId,
        responseAsMap: true
      },
      {
        disableGlobalLoading
      }
    );
  }

  getParticipantsCalendlyLinks() {
    return this.get(url.GET_PARTICIPANTS_CALENDLY_LINKS);
  }

  getLoanUpdateLogsChatRoom(loanId: number) {
    return this.get(url.GET_LOAN_UPDATES_LOGS, { loanId })
  }
}
