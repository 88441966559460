import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";

const form = {
  fields: {
    recipients: {
      value: '',
      error: null,
      rule: '',
      message: ''
    },
    liquidity_id: {
      value: '',
      error: null,
      rule: '',
      message: ''
    },
    arv: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required'
    },
    city: {
      value: '',
      error: null,
      rule: '',
      message: ''
    },
    state: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required'
    },
    zipcodes: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required'
    },
    property_type: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Required'
    },
    sqftage: {
      value: '',
      error: null,
      rule: '',
      message: ''
    }
  },
  meta: {
    isValid: false,
    error: null,
  },
}

export abstract class LiquidityReportBaseStore extends FormStore {
  globalStore: GlobalStore;

  abstract submitLiquidityReport();

  abstract submitLiquidityReportHelp(email, message);

  constructor(globalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;

    makeObservable(this, {
      submitLiquidityReport: flow,
      getDataForSubmit: action,
      submitLiquidityReportHelp: flow,
      resetStore: action,
    });
  }

  resetStore(){
    this.reset();
  }

  getDataForSubmit() {
    const data = this.getFormValues();
    return data;
  }
}
