/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DirtyableFormStore, GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";

const form = {
  fields: {
    borrowerValue: {
      value: '',
      error: null,
      rule: '',
    },
    asRepairedValue: {
      value: '',
      error: null,
      rule: '',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: '',
    },
    scopeOfWorkDescription: {
      value: '',
      error: null,
      rule: '',
    },
    valueReconciliationComments: {
      value: '',
      error: null,
      rule: '',
    },
    appraiser: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class ValuationFormStore extends DirtyableFormStore {
  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.reset();

    makeObservable(this, {
    })
  }
}
