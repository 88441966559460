import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Document } from '@roc/feature-types';
import { DocumentSection } from './documentSection';
import { FileUpload, Paper } from '@roc/ui';
import { LoanStore, RelationshipManagerInfo } from '@roc/feature-loans';
import DocumentForms from './components/documentForms/documentForms';
import { LoanDetailsLayout } from '@roc/feature-loans';
import { useDocumentStore } from './hooks/useDocumentStore';
import { LoanActionsPadding } from '../loanActions/components/loanActionsPadding';
import { Box } from '@material-ui/core';
import { ResendInsuranceQuote } from './components/overview/components/resendInsuranceQuote/resendInsuranceQuote';
import { RequestElmsureQuote } from './components/overview/components/requestElmsureQuote/requestElmsureQuote';
import { useBaseStore } from '@roc/feature-app-core';

const FILTERED_STATUS = ['Not Applicable'];
const CUSTOM_SORTED_DOCUMENT_SECTIONS = [43];

const applyFilters = (documents: Document[]) => {
  const filteredDocs = documents.filter(doc => !FILTERED_STATUS.includes(doc.status))
  if (documents.length > 0 && CUSTOM_SORTED_DOCUMENT_SECTIONS.includes(documents[0].sectionId)) return filteredDocs;
  return filteredDocs.sort((a, b) => a.taskName.localeCompare(b.taskName));
}

interface IDocuments {
  loanId: string;
  drawId?: string;
  loanProcess: string;
  section: string;
  extensionNumber?: string;
  loanStore: LoanStore;
}

export const Documents = observer(
  ({ loanId, drawId = null, loanProcess, section, extensionNumber = null, loanStore }: IDocuments) => {
    const sectionName = section.replace(/-/g, ' ').toUpperCase();
    const { documentStore } = useDocumentStore();
    const { globalStore } = useBaseStore();

    const displaySections = documentStore.displaySections[loanProcess];
    const documents = documentStore.documents[loanProcess];
    const displaySection = displaySections.find(
      displaySection => displaySection.key === sectionName
    );
    const sections = displaySection?.sections || [];

    const onSectionDocumentsUpload = (section, files: FileUpload[]) => {
      const { sectionId, sectionName } = section;

      documentStore.uploadSectionDocuments(
        files,
        loanId,
        sectionId,
        sectionName,
        loanProcess,
        null,
        drawId
      );
    };
    const onDocumentUpload = (file, doc) => {
      documentStore.uploadDocument(file, doc, loanProcess, null, drawId);
    };

    const processDocuments = useCallback(
      section => {
        const docs = applyFilters(documents[section.sectionName] || []);
        return docs.map((document: Document) =>
          documentStore.formatDocument(document, loanProcess)
        );
      },
      [documentStore, documents, loanProcess]
    );

    const isResendInsuranceEnable = globalStore.userFeatures?.resendInsuranceQuote;

    const renderDrawslayout = () => {
      return (
        <>
          {sections.map((section, index) => (
            <DocumentSection
              key={`${section.sectionName}-${index}`}
              entityId={loanId}
              drawId={drawId}
              section={section}
              loanProcess={loanProcess}
              documents={processDocuments(section)}
              onDocumentUpload={onDocumentUpload}
              onSectionDocumentsUpload={onSectionDocumentsUpload}
            />
          ))}
        </>
      );
    };
    const renderExtensionslayout = () => {
      return (
        <>
          {sections.map((section, index) => (
            <DocumentSection
              key={`${section.sectionName}-${index}`}
              entityId={loanId}
              drawId={drawId}
              section={section}
              loanProcess={loanProcess}
              documents={processDocuments(section)}
              onDocumentUpload={onDocumentUpload}
              onSectionDocumentsUpload={onSectionDocumentsUpload}
            />
          ))}
        </>
      );
    };
    const renderLayout = () => {
      return (
        //addFlag
        <LoanDetailsLayout title={sectionName} minHeight="auto">
          {sectionName === "INSURANCE" && isResendInsuranceEnable && !loanStore.loanDetails?.newInsuranceEnabled && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
              <Box mr={2} >
                <ResendInsuranceQuote loanStore={loanStore} />
              </Box>
              <Box>
                <RequestElmsureQuote loanStore={loanStore} />
              </Box>
            </div>
          )}

          {!documentStore.hideRelationShipManager &&
            <RelationshipManagerInfo />
          }

          {sections.map((section, index) => (
            <DocumentSection
              key={`${section.sectionName}-${index}`}
              entityId={loanId}
              drawId={drawId}
              section={section}
              loanProcess={loanProcess}
              documents={processDocuments(section)}
              onDocumentUpload={onDocumentUpload}
              onSectionDocumentsUpload={onSectionDocumentsUpload}
            />
          ))}
          <DocumentForms loanId={loanId} />
          <LoanActionsPadding />
        </LoanDetailsLayout>
      );
    };
    if (extensionNumber !== null && drawId === null) {
      return renderExtensionslayout();
    } else if (extensionNumber === null && drawId !== null) {
      return renderDrawslayout();
    } else {
      return renderLayout();
    }
  }
);
