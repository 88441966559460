import { ApiResponse, Service } from '@roc/client-portal-shared/services';
import { CategoryDB } from '@roc/feature-sow-shared/types';
import { encodeParams } from '@roc/feature-sow-v2';

const baseUrl = '/nportal/rest/pub/scopeOfWork';

const url = {
  GET_SCOPE_OF_WORK_V2_SHARE_URL: `${baseUrl}/getScopeOfWorkShareUrlV2`,
  GET_SCOPE_OF_WORK_V2_BY_ID_AND_LENDER_ID: `/nportal/rest/priv/scopeOfWork/getScopeOfWorkV2ByIdAndLenderId`,
  GET_SCOPE_OF_WORK_V2_BY_LOAN_AND_PROPERTY: `${baseUrl}/getScopeOfWorkByLoanIdAndPropertyIdV2`,
  GET_NEW_SCOPE_OF_WORK_V2: `${baseUrl}/getNewScopeOfWorkV2`,
  SAVE_SCOPE_OF_WORK_V2: `${baseUrl}/saveScopeOfWorkV2`,
  SUBMIT_SCOPE_OF_WORK_V2: `${baseUrl}/submitScopeOfWorkV2`,
  GET_SCOPE_OF_WORK_CATEGORIES: `${baseUrl}/getScopeOfWorkCategories`,
  EXPORT_SCOPE_OF_WORK: `${baseUrl}/exportScopeOfWorkV2`,
  SAVE_SCOPE_OF_WORK_DATA_CONTENT_V2: `${baseUrl}/saveDataContent`,
  MARK_AS_IN_REVIEW: `${baseUrl}/markAsInReview`,
  MARK_AS_IN_REVIEW_COMPLETE: `${baseUrl}/markAsInReviewComplete`,
  MARK_AS_IN_REVISION: `${baseUrl}/markAsInRevision`,
  MARK_AS_MORE_INFO_NEEDED: `${baseUrl}/markAsMoreInfoNeeded`,
  MARK_AS_APPROVED: `/nportal/rest/priv/scopeOfWork/approveSOW`,
  REMOVE_SCOPE_OF_WORK: `/nportal/rest/priv/scopeOfWork/removeScopeOfWork`,
  GET_UNASSIGNED_SCOPES_OF_WORK:
    '/nportal/rest/priv/scopeOfWork/getUnassignedScopesOfWorkGrid/',
  GET_SCOPES_OF_WORK: '/nportal/rest/priv/scopeOfWork/getAllScopesOfWorkGridByLender/',
  LINK_STANDALONE_SCOPE_OF_WORK:
    '/nportal/rest/priv/scopeOfWork/linkStandaloneScopeOfWorkExternalV2',
  LINK_NEW_SCOPE_OF_WORK: '/nportal/rest/priv/scopeOfWork/createScopeOfWorkExternalV2',
  COPY_SCOPE_OF_WORK: '/nportal/rest/priv/scopeOfWork/linkCopyScopeOfWorkExternalV2',
  GET_SPLITTED_CATEGORIES: '/nportal/rest/priv/scopeOfWork/getScopeOfWorkSplitedCategories',
  SAVE_PDF: `/nportal/rest/priv/scopeOfWork/savePdf`,
  SAVE_SOW_AUDIT_LOGS: `/nportal/rest/priv/scopeOfWorkAuditLogs/saveScopeOfWorkAuditLogs`,
  GET_SOW_AUDIT_LOG_MESSAGES: '/nportal/rest/priv/scopeOfWorkAuditLogs/getScopeOfWorkAuditLogsMessages',
  GET_SOW_ERROR_MESSAGES: '/nportal/rest/priv/scopeOfWork/getSowSubmissionErrors'
};

const addQueryParam = (params: string, key: string, value: string) => {
  return `${params ? `${params}&` : ''}${key}=${value || ''}`;
};

export class ScopeOfWorkInternalService extends Service {

  getSowSubmissionErrors(loanId: string, propertyId: any, data: any) {
    return this.post(`${url.GET_SOW_ERROR_MESSAGES}?loanId=${loanId}&propertyId=${propertyId}`, data)
  }

  savePdf(file, loanId, propertyId) {
    return this.post(`${url.SAVE_PDF}?loanId=${loanId}&propertyId=${propertyId}`, file)
  }

  saveSowAuditLogs(scopeOfWorkId: string, lenderId, data: any, isSubmit: boolean, userName: string) {
    const userNameParam = userName ? `&userName=${userName}` : '';
    return this.post(`${url.SAVE_SOW_AUDIT_LOGS}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}&isSubmit=${isSubmit}${userNameParam}`, data)
  }

  getSowAuditLogs(scopeOfWorkId: string, lenderId: any) {
    return this.get(`${url.GET_SOW_AUDIT_LOG_MESSAGES}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`)
  }

  getSplitedCategories(scopeOfWorkId: string, lenderId: string) {
    return this.get(`${url.GET_SPLITTED_CATEGORIES}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`)
  }

  getScopeOfWorkShareUrl(lenderId: string, scopeOfWorkId: string) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = encodeParams(params);
    return this.get(
      url.GET_SCOPE_OF_WORK_V2_SHARE_URL,
      { params },
      {
        responseType: 'text',
      }
    );
  }

  getScopeOfWork(lenderId: string, scopeOfWorkId: string) {
    return this.post(
      `${url.GET_SCOPE_OF_WORK_V2_BY_ID_AND_LENDER_ID}?lenderId=${lenderId}&scopeOfWorkId=${scopeOfWorkId}`,
      null
    );
  }

  getScopeOfWorkByLoanIdAndPropertyId(loanId: string, propertyId: string) {
    let params = addQueryParam('', 'loanId', loanId);
    params = addQueryParam(params, 'propertyId', propertyId);
    params = encodeParams(params);
    return this.post(
      `${url.GET_SCOPE_OF_WORK_V2_BY_LOAN_AND_PROPERTY}${params ? `?params=${params}` : ''
      }`,
      null
    );
  }

  getNewScopeOfWork() {
    return this.get(url.GET_NEW_SCOPE_OF_WORK_V2);
  }

  getCategories() {
    return this.get(url.GET_SCOPE_OF_WORK_CATEGORIES);
  }

  saveScopeOfWork(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SAVE_SCOPE_OF_WORK_V2}?params=${params}`,
      data,
      {},
      options
    );
  }


  saveScopeOfWorkDataContent(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SAVE_SCOPE_OF_WORK_DATA_CONTENT_V2}?params=${params}`,
      data,
      {},
      options
    );
  }


  exportScopeOfWork(data: any) {
    return this.post(
      `${url.EXPORT_SCOPE_OF_WORK}`,
      data,
      null,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  submitScopeOfWork(
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.SUBMIT_SCOPE_OF_WORK_V2}?params=${params}`,
      data,
      {},
      options
    );
  }

  markSOWAsInReviewComplete(
    reviewStatus: string,
    lenderId: string,
    scopeOfWorkId: string,
    username: string
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'reviewStatus', reviewStatus);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: false };
    return this.post(
      `${url.MARK_AS_IN_REVIEW_COMPLETE}?params=${params}`,
      {},
      options
    );
  };

  markSOWAsInRevision(
    reviewStatus: string,
    lenderId: string,
    scopeOfWorkId: string,
    username: string
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'reviewStatus', reviewStatus);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: false };
    return this.post(
      `${url.MARK_AS_IN_REVISION}?params=${params}`,
      {},
      options
    );
  };

  markSOWAsInReview(
    reviewStatus: string,
    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    reviewReason: string,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'reviewStatus', reviewStatus);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = addQueryParam(params, 'reviewReason', reviewReason);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.MARK_AS_IN_REVIEW}?params=${params}`,
      {},
      options
    );
  };

  markSOWAsApproved(
    scopeOfWorkId: string,
    silent: boolean = false
  ) {
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.MARK_AS_APPROVED}?sowId=${scopeOfWorkId}`,
      {},
      options
    );
  }

  markSOWAsMoreInfoNeeded(

    lenderId: string,
    scopeOfWorkId: string,
    username: string,
    data: any,
    silent: boolean = false
  ) {
    let params = addQueryParam('', 'lenderId', lenderId);
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId);
    params = addQueryParam(params, 'username', username);
    params = encodeParams(params);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${url.MARK_AS_MORE_INFO_NEEDED}?params=${params}`,
      data,
      {},
      options
    );
  }

  removeScopeOfWork(
    scopeOfWorkId: string,
    username: string,
  ) {
    return this.post(
      `${url.REMOVE_SCOPE_OF_WORK}?scopeOfWorkId=${scopeOfWorkId}&username=${username}`,
      {}
    );
  }


  async getScopesOfWork(
    page: any,
    pageSize: any,
    sortDir: string,
    sortBy: string,
    filters: any,
    dropdownFilters: any,
    loanId: number,
  ): Promise<ApiResponse> {
    let params = addQueryParam('', 'start', page);
    params = addQueryParam(params, 'count', pageSize);
    params = addQueryParam(params, 'sortDir', sortDir);
    params = addQueryParam(params, 'sortBy', sortBy);
    params = addQueryParam(params, 'filters', filters ? encodeURIComponent(JSON.stringify(filters)) : null);
    params = addQueryParam(params, 'dropdownFilters', dropdownFilters ? encodeURIComponent(JSON.stringify(dropdownFilters)) : null);

    const response: ApiResponse = await this.get(
      `${url.GET_SCOPES_OF_WORK}/${loanId}${params ? `?${params}` : ''}`
    );

    const responseData = response.data || {};
    const rows = responseData.rows || [];
    const totalCount = responseData.totalCount || 0;

    const apiResponse: ApiResponse = {
      data: {
        data: {
          rows,
          totalCount
        }
      },
      headers: response.headers
    };
    return apiResponse;
  }

  async getUnassignedScopesOfWork(
    page: any,
    pageSize: any,
    sortDir: string,
    sortBy: string,
    filters: any,
    dropdownFilters: any,
    loanId: number,
  ): Promise<ApiResponse> {
    let params = addQueryParam('', 'start', page);
    params = addQueryParam(params, 'count', pageSize);
    params = addQueryParam(params, 'sortDir', sortDir);
    params = addQueryParam(params, 'sortBy', sortBy);
    params = addQueryParam(params, 'filters', filters ? encodeURIComponent(JSON.stringify(filters)) : null);
    params = addQueryParam(params, 'dropdownFilters', dropdownFilters ? encodeURIComponent(JSON.stringify(dropdownFilters)) : null);

    const response: ApiResponse = await this.get(
      `${url.GET_UNASSIGNED_SCOPES_OF_WORK}/${loanId}${params ? `?${params}` : ''}`
    );

    const responseData = response.data || {};
    const rows = responseData.rows || [];
    const totalCount = responseData.totalCount || 0;

    const apiResponse: ApiResponse = {
      data: {
        data: {
          rows,
          totalCount
        }
      },
      headers: response.headers
    };
    return apiResponse;
  }

  linkStandaloneScopeOfWork(
    loanId: number,
    propertyId: number,
    scopeOfWorkId: number,
    username: string
  ) {
    let params = addQueryParam('', 'loanId', loanId.toString());
    params = addQueryParam(params, 'propertyId', propertyId.toString());
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId.toString());
    params = addQueryParam(params, 'username', username);

    return this.post(
      `${url.LINK_STANDALONE_SCOPE_OF_WORK}${params ? `?${params}` : ''}`,
      {
        loanId,
        propertyId,
        scopeOfWorkId,
      }
    );
  }


  linkNewScopeOfWork(loanId: number, propertyId: number, username: string) {
    let params = addQueryParam('', 'loanId', loanId.toString());
    params = addQueryParam(params, 'propertyId', propertyId.toString());
    params = addQueryParam(params, 'username', username);

    return this.post(
      `${url.LINK_NEW_SCOPE_OF_WORK}${params ? `?${params}` : ''}`,
      {
        loanId,
        propertyId,
      }
    );
  }

  linkCopyScopeOfWork(
    loanId: number,
    propertyId: number,
    scopeOfWorkId: number,
    username: string
  ) {
    let params = addQueryParam('', 'loanId', loanId.toString());
    params = addQueryParam(params, 'propertyId', propertyId.toString());
    params = addQueryParam(params, 'scopeOfWorkId', scopeOfWorkId.toString());
    params = addQueryParam(params, 'username', username);
    return this.post(
      `${url.COPY_SCOPE_OF_WORK}${params ? `?${params}` : ''}`,
      {
        loanId,
        propertyId,
        scopeOfWorkId,
      }
    );
  }

}
