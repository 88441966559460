import { useEffect, useState } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Tabs, Paper, GridColumnProps, Button, StandardDialog, } from '@roc/ui';
import { observer } from 'mobx-react';
import { ProtectiveAdvancesGrid } from '../protectiveAdvancesGrid';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { CounterPartyExpensesTab, PAYABLES_APPROVED_TABS } from '../../types/types';
import { usePayablesDashboardStore } from '../../hooks/usePayablesDashboardStore';
import { PayablesToolbar } from './payablesToolbar';
import { useDocumentStore } from '@roc/feature-documents';
import { CloudDownload, Check } from '@material-ui/icons';
import { MarkAsPaidModal } from './markAsPaidModal';
import { insertIf } from '@roc/feature-utils';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
}));

export const PayablesApprovedDashboard = observer(() => {
  const classes = useStyles();
  const { payablesApprovedStore } = usePayablesDashboardStore();
  const { tab } = payablesApprovedStore;
  const { documentStore } = useDocumentStore();
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const [payableRefNumber, setPayableRefNumber] = useState<string>('');
  const [actionSelectedRecord, setActionSelectedRecord] = useState(null);

  useEffect(() => {
    payablesApprovedStore.fetchEntityNames();
    return () => {
      payablesApprovedStore.resetApproved();
    };
  }, []);

  const handleTabChange = (_, i) => {
    payablesApprovedStore.setTab(PAYABLES_APPROVED_TABS[i].value)
  }

  const closeMarkAsPaidModal = () => {
    setPayableRefNumber('');
    setShowMarkAsPaidModal(false);
  };

  const openMarkAsPaidModal = () => {
    setShowMarkAsPaidModal(true);
  }

  const handleMarkAsPaidConfirm = async () => {
    await payablesApprovedStore.markAsPaid(actionSelectedRecord.recordId, payableRefNumber);
    payablesApprovedStore.counterPartyExpenseGridStore.resetAndFetchGridData();
    closeMarkAsPaidModal();
  }

  const actions = (record, handleMenuClose) => {

    return (
      [
        <MenuItem
          disabled={!record.documentId}
          onClick={() => {
            const document = {
              loanTaskId: record.documentId,
              originalFileName: record.originalFileName,
            };
            documentStore.downloadDocumentOriginalNameById(document);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CloudDownload fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download Invoice</ListItemText>
        </MenuItem>,
        ...insertIf(tab === CounterPartyExpensesTab.PAYABLES_APPROVED, [
          <MenuItem
            onClick={() => {
              setActionSelectedRecord(record);
              openMarkAsPaidModal()
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark as Paid</ListItemText>
          </MenuItem>
        ])
      ]
    );
  }


  const COLUMNS: GridColumnProps[] = [
    {
      field: 'loanId',
      headerName: 'Loan Id',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'entity',
      headerName: 'Vendor',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'invoice',
      headerName: 'Invoice #',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'feeType',
      headerName: 'Type',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'receivableStatus',
      headerName: 'Receivable Status',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 125,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'createdDate',
      headerName: 'Creation Date',
      minWidth: 125,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'leadId',
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  return (
    <Layout title="Approved Payables" containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={PAYABLES_APPROVED_TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(PAYABLES_APPROVED_TABS)}
          onTabChange={handleTabChange}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <ProtectiveAdvancesGrid
              counterPartyExpenseStore={payablesApprovedStore}
              columns={COLUMNS}
              toolbar={<PayablesToolbar counterPartyExpenseStore={payablesApprovedStore} />}
              selectModeEnabled={tab === CounterPartyExpensesTab.PAYABLES_APPROVED}
              actions={actions}
            />
          </Paper>
        </Box>
      </Box>
      <MarkAsPaidModal
        payableRefNumber={payableRefNumber}
        setPayableRefNumber={setPayableRefNumber}
        showMarkAsPaidModal={showMarkAsPaidModal}
        closeMarkAsPaidModal={closeMarkAsPaidModal}
        handleMarkAsPaidConfirm={handleMarkAsPaidConfirm}
      />
    </Layout>
  );
});
