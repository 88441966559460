import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { BorrowerSummaryCard } from '../cards/borrowerSummaryCard';
import { LoanOriginatorCard } from '../cards/loanOriginatorCard';
import { LoanKeyDates } from '../cards/loanKeyDates';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { LoanDetailsCardFixFlip } from './loanDetailsCardFixFlipV2';
import { LoanDetailsCardGroundUp } from './loanDetailsCardGroundUpV2';
import { useBaseStore } from '@roc/feature-app-core';
import { useSubmissionVsUnderwritingStore } from '../../hooks/useSubmissionVsUnderwritingStore';
import { useLoanStore } from '@roc/feature-loans';
import { LoanSummaryTodosTasksCard } from '../cards/loanSummaryTodosTasksCard';
import UpdateLogCard from '../cards/updateLogCard';
import { useRouteMatch } from 'react-router';

export const LoanSummaryBridgeV2 = observer(({ groundUp = false }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      marginTop: '16px',
      marginBottom: '16px',
    }
  }));
  const classes = useStyles();
  const { showOriginatorsCard, isLenderOriginator, allowLoanPricing, isTaskFrameworkEnabled } = useLoanFlags();
  const { globalStore } = useBaseStore();
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;
  const { submissionVsUnderwritingStore } = useSubmissionVsUnderwritingStore();
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const isServicerPortal = useRouteMatch('/servicer-portal');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoanKeyDates />
        </Grid>
        <Grid item xs={12}>
          {groundUp ? <LoanDetailsCardGroundUp /> : <LoanDetailsCardFixFlip />}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <LoanSummaryTodosTasksCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display={'flex'} flexDirection={'column'}>
                {!isServicerPortal && !isLenderOriginator && <Box flexGrow={1} style={{ marginBottom: 20 }}>
                  <LoanOriginatorCard readOnly={!allowLoanPricing} />
                </Box>}
                <Box flexGrow={1} pt={showOriginatorsCard ? 2 : 0}>
                  <BorrowerSummaryCard />
                </Box>
                {isTaskFrameworkEnabled && allowLoanPricing && <Box pt={2} flexGrow={1}>
                  <UpdateLogCard loanId={loanDetails.loanId}/>
                </Box>}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
