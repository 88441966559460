import { isNil } from '@roc/feature-utils';
import { EditableDrawer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { AppraisalTaskContent, EscalateButton, MarkTaskAsCompletedButton, MarkTaskAsWaitingOnExternalButton, MoveTaskToRocButton, PutTaskToTpo, TaskDialogContent, useEditAppraisalTask, useEditGeneralTask } from './commonEditTask';

export const EditGeneralTaskDrawer = observer(({ open, onClose }) => {
  const { store, classes, selectedTabIndex, getTitle, onTabChange } = useEditGeneralTask();

  return (
    <EditableDrawer
      title={getTitle()}
      maxWidth={1200}
      open={!isNil(store.dialogState) && open}
      state={store.dialogState}
      handleAdd={() => { 
        store.saveTaskWithMessage();
        onClose();
      }}
      handleEdit={() => { 
        store.saveTaskWithMessage();
        onClose();
      }}
      handleClose={() => {
        store.closeDialog();
        onClose();
      }}
      editButtonText="Save"
      addButtonText="Create"
      disabledButton={false}
      content={<TaskDialogContent selectedTabIndex={selectedTabIndex} onTabChange={onTabChange} classes={classes} showTabs={false}/>}
      additionalActions={[
        <EscalateButton />,
        <MoveTaskToRocButton />,
        <PutTaskToTpo />,
        <MarkTaskAsCompletedButton />,
      ]}
      removeContentBox
    />
  );
});

export const EditAppraisalTaskDrawer = observer(({ open, onClose }) => {
  const { store, classes, selectedTabIndex, getTitle, onTabChange, globalStore } = useEditAppraisalTask();

  return (
    <EditableDrawer
      title={getTitle()}
      maxWidth={1200}
      open={!isNil(store.dialogState) && open}
      state={store.dialogState}
      handleAdd={() => {
        store.saveTaskWithMessage();
        onClose();
      }}
      handleEdit={() => {
        store.saveTaskWithMessage();
        onClose();
      }}
      handleClose={() => {
        store.closeDialog();
        onClose();
      }}
      disabledButton={false}
      editButtonText="Save"
      content={<AppraisalTaskContent selectedTabIndex={selectedTabIndex} onTabChange={onTabChange} classes={classes} store={store} globalStore={globalStore} showTabs={false}/>}
      additionalActions={[
        <MoveTaskToRocButton />, 
        <PutTaskToTpo />, 
        <MarkTaskAsCompletedButton />
      ]}
      showSaveChangesButton={globalStore.isInternalPortal}
      removeContentBox
    />
  );
});

export const EditTaskDrawer = observer(({ open, onClose }) => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  return (
    editTaskStore.isAppraisalTaskType ?
      <EditAppraisalTaskDrawer open={open} onClose={onClose} /> :
      <EditGeneralTaskDrawer open={open} onClose={onClose}/>
  );
});
