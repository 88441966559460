import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { TamariskAppraisalDashboard } from '../components/tamariskAppraisalDashboard';

export const getTamariskAppraisalInternalRoutesConfig = (
  basePath: string,
  baseUrl: string,
) => {
  return {
    tamariskAppraisal: {
      path: `${basePath}/tamarisk-appraisal-dashboard`,
      url: `${baseUrl}/tamarisk-appraisal-dashboard`,
      documentTitle: 'tamarisk-appraisal-dashboard',
    }
  };
};

export const getTamariskAppraisalInternalRoutes = (
  basePath: string,
  baseUrl: string,
) => {
  const config = getTamariskAppraisalInternalRoutesConfig(basePath, baseUrl);

  return {
    tamariskAppraisalDashboard: (
      <Route exact path={config.tamariskAppraisal.path}>
        <Page routeDefinition={config.tamariskAppraisal}>
          <TamariskAppraisalDashboard />
        </Page>
      </Route>
    )
  };
}
