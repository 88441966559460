import { Box, Grid, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Layout, useBaseStore } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  fixFlipCover,
  groundUpCover,
  rentalPortfolioCover,
  singlePropertyCover,
  stabilizedBridgeCover,
} from '../assets';
import { useOneToolRoutes } from './hooks/useOneToolRoutes';
import {
  useLoanSubmissionRoutes,
  useRentalPortfolioStore,
  useSinglePropertyStore,
} from '@roc/feature-loans';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  loanTypePaper: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
    height: '100%',
  },
}));

export const ProductSelector = () => {
  const classes = useStyles();

  const { globalStore } = useBaseStore();
  const { oneToolRoutesConfig } = useOneToolRoutes();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const { singlePropertyStore } = useSinglePropertyStore();
  const { rentalPortfolioStore } = useRentalPortfolioStore();

  useEffect(() => {
    singlePropertyStore.reset();
    rentalPortfolioStore.reset();
  }, []);

  return (
    <Layout maxWidth={'lg'}>
      <Typography
        align="left"
        variant="h2"
        style={{
          color: 'gray',
          marginBottom: '3rem',
          textAlign: 'center',
        }}
      >
        Create a Quote / Submit a Loan
      </Typography>
      <Paper className={classes.paper}>
        <Box mb={4}>
          {/* <Typography align="left" variant="h3">
            Select a Product
          </Typography> */}
          <Box mx={'auto'} mb={4} mt={4}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid item>
                <ProductCard
                  name={'Fix & Flip'}
                  text={
                    <>
                      Short-term financing to purchase and/or renovate, then
                      sell or rent, non-owner occupied homes with 1-4 units.
                    </>
                  }
                  url={oneToolRoutesConfig.fixFlip().url}
                  image={fixFlipCover}
                />
              </Grid>
              <Grid item>
                <ProductCard
                  name={'Ground Up Construction'}
                  text={
                    <>Short-term financing to build new homes with 1-4 units.</>
                  }
                  url={oneToolRoutesConfig.groundUp().url}
                  image={groundUpCover}
                />
              </Grid>
              <Grid item>
                <ProductCard
                  name={'Stabilized Property'}
                  text={
                    <>
                      Short-term financing for recently renovated or constructed
                      properties that are currently, or soon to be, listed for
                      sale.
                    </>
                  }
                  url={oneToolRoutesConfig.stabilizedBridge().url}
                  image={stabilizedBridgeCover}
                />
              </Grid>
              {globalStore.lenderInfo?.showFixFlipProSubtype && (
                <Grid item>
                  <ProductCard
                    name={'Fix & Flip Pro'}
                    text={
                      <>
                        This program is for highly experienced flippers approved by sales management only.
                      </>
                    }
                    url={loanSubmissionRoutesConfig.submitloan.children.fixFlipPro.url}
                    image={fixFlipCover}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box mx={'auto'} mb={4} mt={4}>
            <Grid container direction="row" justifyContent="center" spacing={4}>
              <Grid item>
                <ProductCard
                  name={'Single Property Rental'}
                  text={
                    <>
                      Long-term financing to purchase or refinance a non-owner
                      occupied rental property with 1-4 units.
                    </>
                  }
                  url={
                    loanSubmissionRoutesConfig.submitloan.children
                      .singleProperty.url
                  }
                  image={singlePropertyCover}
                />
              </Grid>
              <Grid item>
                <ProductCard
                  name={'Rental Portfolio'}
                  text={
                    <>
                      Long-term financing to purchase or refinance multiple
                      non-owner occupied rental properties with 1-4 units.
                    </>
                  }
                  url={
                    loanSubmissionRoutesConfig.submitloan.children
                      .rentalPortfolio.url
                  }
                  image={rentalPortfolioCover}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
};

const ProductCard = observer(({ url, image, name, text }) => {
  const classes = useStyles();
  return (
    <Link to={url}>
      <Box
        style={{
          border: '1px solid #5E5E5E4D',
          borderRadius: '14px',
          width: '340px',
          height: '340px',
        }}
      >
        <Box
          height="70px"
          paddingLeft="20px"
          paddingRight="20px"
          display="flex"
          alignItems="center"
        >
          <Typography variant="h6" style={{ fontSize: '24px', color: 'black' }}>
            {name}
          </Typography>
        </Box>
        <Box style={{ height: '132.2px', objectFit: 'cover'}}>
          <img src={image} width={'100%'} style={{ objectFit: 'cover', height: '100%'}}/>
        </Box>
        <Box paddingLeft="20px" paddingRight="20px" marginTop="10px">
          <Typography
            variant="body2"
            style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
});
