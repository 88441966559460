import { Service } from "@roc/feature-app-core";

const url = {
  GET_COMP_TOOL_PROPERTIES_DATA:
    '/api/v1/property/compTool/getCompToolPropertiesData',
  GET_PROPERTY_BY_ID:
    '/nportal/rest/priv/property/getPropertyById/',
  SAVE_PROPERTY_COMPS:
    '/api/v1/property/propertyComp/savePropertyComps',
  GET_PROPERTY_COMPS:
    '/api/v1/property/propertyComp/getPropertyCompBySubjectPropertyId'
}


export class CompToolService extends Service {

  getCompToolPropertiesData(compToolRequest: any) {
    return this.post(url.GET_COMP_TOOL_PROPERTIES_DATA, compToolRequest);
  }

  getPropertyById(propertyId: number) {
    return this.get(url.GET_PROPERTY_BY_ID + propertyId);
  }

  savePropertyComps(propertyComps: any[], serviceMetadata?: any) {
    return this.post(url.SAVE_PROPERTY_COMPS, propertyComps, null, serviceMetadata);
  }

  getPropertyCompBySubjectPropertyId(subjectPropertyId) {
    return this.get(url.GET_PROPERTY_COMPS, { subjectPropertyId });
  }

}