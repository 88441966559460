import { isNil } from '@roc/feature-utils';
import { EditableDialog, EditableDrawer } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { AppraisalTaskContent, EscalateButton, MarkTaskAsCompletedButton, MarkTaskAsWaitingOnExternalButton, MoveTaskToRocButton, PutTaskToTpo, TaskDialogContent, useEditAppraisalTask, useEditGeneralTask } from './commonEditTask';

export const EditGeneralTaskDialog = observer(() => {
  const { store, classes, selectedTabIndex, getTitle, onTabChange } = useEditGeneralTask();

  return (
    <EditableDialog
      title={getTitle()}
      maxWidth="lg"
      open={!isNil(store.dialogState)}
      dialogState={store.dialogState}
      handleAdd={() => store.saveTaskWithMessage()}
      handleEdit={() => store.saveTaskWithMessage()}
      handleClose={() => store.closeDialog()}
      editButtonText="Save"
      addButtonText="Create"
      disabledButton={false}
      dialogContent={<TaskDialogContent selectedTabIndex={selectedTabIndex} onTabChange={onTabChange} classes={classes} />}
      additionalActions={[
        <EscalateButton />,
        <MoveTaskToRocButton />,
        <PutTaskToTpo />,
        <MarkTaskAsCompletedButton />,
      ]}
      removeContentBox
    />
  );
});

export const EditAppraisalTaskDialog = observer(() => {
  const { store, classes, selectedTabIndex, getTitle, onTabChange, globalStore } = useEditAppraisalTask();

  return (
    <EditableDialog
      title={getTitle()}
      maxWidth="lg"
      open={!isNil(store.dialogState)}
      dialogState={store.dialogState}
      handleAdd={() => store.saveTaskWithMessage()}
      handleEdit={() => store.saveTaskWithMessage()}
      handleClose={() => store.closeDialog()}
      disabledButton={false}
      editButtonText="Save"
      dialogContent={<AppraisalTaskContent selectedTabIndex={selectedTabIndex} onTabChange={onTabChange} classes={classes} store={store} globalStore={globalStore} />}
      additionalActions={[
        <MoveTaskToRocButton />, 
        <PutTaskToTpo />, 
        <MarkTaskAsCompletedButton />
      ]}
      showSaveChangesButton={globalStore.isInternalPortal}
      removeContentBox
    />
  );
});

export const EditTaskDialog = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore } = generalTasksStore;

  return (
    editTaskStore.isAppraisalTaskType ?
      <EditAppraisalTaskDialog /> :
      <EditGeneralTaskDialog />
  );
});
