export enum ReveneuRecognitionModules {
  DATA_MODULE = 'DATA_MODULE_1',
  MONTHLY_INTEREST_MODULE = 'MONTHLY_INTEREST_MODULE',
  MONTHLY_DQ_INTEREST_MANAGEMENT = 'MONTHLY_DQ_INTEREST_MANAGEMENT',
  MONTHLY_TRADE_MODULE = 'MONTHLY_TRADE_MODULE',
  DATA_MODULE_2 = 'DATA_MODULE_2',
  MONTHLY_POINTS_DATA_N_AMORTIZATION = 'M_PNTS_DATA_N_AMORTIZATION',
  MONTHLY_UW_DATA_N_AMORTIZATION = 'MONTHLY_UW_DATA_N_AMORTIZATION',
  MONTHLY_SERVICING_FEE_ON_RTL_LOANS_BPS = 'MONTHLY_SVC_FEE_RTL_BPS',
  MONTHLY_DEFAULT_INTEREST_N_SPECIAL_SERVICING_FEES = 'MONTHLY_DEF_INT_N_SSF',
  MONTHLY_LOAN_BALANCES_DATA = 'MONTHLY_LOAN_BALANCES_DATA',
  MONTHLY_MAPPING_OF_TERM_LOAN_SALE_ON_GAIN_LOSS = 'TERM_LOAN_SALE_GAIN_LOSS_MAP',
  MONTHLY_MAPPING_OF_LATE_PENALTIES = 'M_MAP_OF_LATE_PENALTIES',
  MONTHLY_EXTENSION_MAPPING_AND_AMORTIZATION = 'EXTENSION_MAPPING_AMORTIZATION',
  MONTHLY_PORTFOLIO_MANAGEMENT = 'MONTHLY_PORTFOLIO_MANAGEMENT',
};

export interface MonthFilter {
  label: string;
  value: string;
}