import { GlobalStore } from '@roc/client-portal-shared/stores';
import { isNil } from '@roc/client-portal-shared/utils';

import { CALLBACK_TYPE } from '@roc/feature-sow-shared/constants/scopeOfWorkV2Form';
import { ScopeOfWorkV2Service } from '../services/scopeOfWorkV2Service';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { makeObservable, override } from 'mobx';
import { getMultiPageScreenshotGrid } from '@roc/ui';

export class ScopeOfWorkV2Store extends ScopeOfWorkV2FormBaseStore {
  protected scopeOfWorkService: ScopeOfWorkV2Service;

  constructor(globalStore: GlobalStore, formFields?: any) {
    super(globalStore, formFields);
    this.scopeOfWorkService = new ScopeOfWorkV2Service();
    this.initialize();
    makeObservable(this, {
      handleSplitedCategories: override,
      savePdfOnDropbox: override
    })
  }

  initialize() {
    this.setGetScopeOfWorkShareUrl(this.getScopeOfWorkShareUrl);
    this.setGetPublicScopeOfWorkShareUrl(this.getPublicScopeOfWorkShareUrl);
    this.setGetNewScopeOfWork(this.getNewScopeOfWork);
    this.setGetScopeOfWork(this.getScopeOfWork);
    this.setGetScopeOfWorkByLoanIdAndPropertyId(
      this.getScopeOfWorkByLoanIdAndPropertyId
    );
    this.setGetScopeOfWorkCategories(this.getCategories);
    this.setSaveScopeOfWork(this.saveScopeOfWork);
    this.setSaveScopeOfWorkDataContent(this.saveScopeOfWorkDataContent);
    this.setSubmitScopeOfWork(this.submitScopeOfWork);
    this.setAfterCall(this.afterCall);
    this.setCopyScopeOfWorkShareUrl(this.copyScopeOfWorkShareUrl);
    this.setExportScopeOfWork(this.exportScopeOfWork);
    this.setMarkSOWAsInReview(this.markSOWAsInReview);
    this.setMarkSOWAsMoreInfoNeeded(this.markSOWAsMoreInfoNeeded);
  }

  *handleSplitedCategories() {
    const response = yield this.splitCategories(
      this.params.scopeOfWorkId,
      this.params.lenderId
    );
    this.splitedCategoriesObj = response;
  }

  *savePdfOnDropbox() {
    this.showScreenshotGrid();
    const pdf = yield getMultiPageScreenshotGrid('Scope of Work', this.scopeOfWorkScreenshotIds, []);
    this.hideScreenshotGrid()
    const response = yield this.scopeOfWorkService.savePdf(pdf, this.params.scopeOfWorkId, this.params.lenderId);
  }

  getSowSubmissionErrors = async (loanId: any, propertyId: any, data: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSowSubmissionErrors(loanId, propertyId, data)
    return response;
  }


  saveScopeOfWorkAuditLogs = async (scopeOfWorkId: any, lenderId: any, data: any, isSubmit: boolean, userName: string) => {
    const {
      data: response
    } = await this.scopeOfWorkService.saveSowAuditLogs(scopeOfWorkId, lenderId, data, isSubmit, this.username)
    return response;
  }

  getScopeOfWorkAuditLogs = async (scopeOfWorkId: any, lenderId: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSowAuditLogs(scopeOfWorkId, lenderId)
    return response;
  }


  splitCategories = async (scopeOfWorkId: string, propertyId: string) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSplittedCategories(scopeOfWorkId, propertyId)
    return response;
  }

  getScopeOfWorkShareUrl = async (params: ScopeOfWorkV2FormParams) => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkShareUrl(
      params.lenderId,
      params.scopeOfWorkId
    );
    return response;
  };

  getPublicScopeOfWorkShareUrl = async (params: ScopeOfWorkV2FormParams) => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkShareUrl(
      params.lenderId,
      null
    );
    return response;
  };

  getNewScopeOfWork = async () => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getNewScopeOfWork();
    return response;
  };

  getScopeOfWork = async (params: ScopeOfWorkV2FormParams) => {
    const { data: response } = await this.scopeOfWorkService.getScopeOfWork(
      params.lenderId,
      params.scopeOfWorkId
    );
    return response;
  };

  getScopeOfWorkByLoanIdAndPropertyId = async (
    params: ScopeOfWorkV2FormParams
  ) => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkByLoanIdAndPropertyId(
      params.loanId,
      params.propertyId
    );
    return response;
  };

  getCategories = async (params: ScopeOfWorkV2FormParams) => {
    const { data: response } = await this.scopeOfWorkService.getCategories();
    return response;
  };

  saveScopeOfWork = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    console.log('params', params);
    const { data: response } = await this.scopeOfWorkService.saveScopeOfWork(
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };



  saveScopeOfWorkDataContent = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    console.log('params', params);
    const { data: response } = await this.scopeOfWorkService.saveScopeOfWorkDataContent(
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };

  markSOWAsInReview = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.markSOWAsInReview(
      params.reviewStatus,
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };

  markSOWAsMoreInfoNeeded = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    console.log('params', params);
    const { data: response } = await this.scopeOfWorkService.markSOWAsMoreInfoNeeded(
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };


  exportScopeOfWork = async (
    data: any,
  ) => {
    const response = await this.scopeOfWorkService.exportScopeOfWork(
      data,
    );
    return response;
  };

  submitScopeOfWork = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.submitScopeOfWork(
      params.lenderId,
      params.scopeOfWorkId,
      params.username,
      data,
      silent
    );
    return response;
  };

  afterCall = (type: CALLBACK_TYPE, responseData: any, isInternal: boolean) => {
    if (isInternal) {
      this.afterCallInternal(type, responseData);
    } else {
      this.afterCallLocal(type, responseData);
    }
  };

  afterCallInternal = (type: CALLBACK_TYPE, responseData: any) => {
    let message = '';

    switch (type) {
      case CALLBACK_TYPE.GET_SHARE_URL:
        message = !responseData ? 'sow-get-share-url-error' : '';
        break;
      case CALLBACK_TYPE.GET:
        message = !responseData ? 'sow-get-error' : '';
        break;
      case CALLBACK_TYPE.SAVE:
        message = isNil(responseData)
          ? 'sow-save-error'
          : responseData
            ? 'sow-save-success'
            : 'sow-save-success|already';
        break;
      case CALLBACK_TYPE.SUBMIT:
        message = isNil(responseData)
          ? 'sow-submit-error'
          : responseData.success
            ? 'sow-submit-success'
            : responseData.responseMessage
              ? 'sow-submit-validation|' + responseData.responseMessage
              : 'sow-submit-success|already';
        break;
      default:
        break;
    }

    if (message) {
      window.parent.postMessage(message, '*');
    }
  };

  afterCallLocal = (type: CALLBACK_TYPE, responseData: any) => {
    switch (type) {
      case CALLBACK_TYPE.GET_SHARE_URL:
        if (!responseData) {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'An error occurred while getting the Scope of Work share URL',
          });
        }
        break;
      case CALLBACK_TYPE.GET:
        if (!responseData) {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'An error occurred while getting the Scope of Work information',
          });
        }
        break;
      case CALLBACK_TYPE.SAVE:
        this.handleSaveResponse(responseData);
        break;
      case CALLBACK_TYPE.SUBMIT:
        this.handleSubmitResponse(responseData);
        break;
      default:
        break;
    }
  };

  copyScopeOfWorkShareUrl = (
    scopeOfWorkShareUrl: string,
    isInternal: boolean
  ) => {
    if (isInternal) {
      window.parent.postMessage(`sow-copy|${this.scopeOfWorkShareUrl}`, '*');
    } else {
      navigator.clipboard.writeText(scopeOfWorkShareUrl);
    }
  };

  private handleSaveResponse(saved: boolean) {
    if (isNil(saved)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error when saving this Scope of Work',
      });
    } else {
      if (saved) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Scope of Work saved!',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'This Scope of Work is already submitted',
        });
      }
    }
  }

  private handleSubmitResponse(responseData: any) {
    if (isNil(responseData)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error when submitting this Scope of Work',
      });
    } else {
      if (responseData.success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Scope of Work submitted!',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: responseData.responseMessage ? responseData.responseMessage : 'This Scope of Work is already submitted',
        });
      }
    }
  }

  reset = () => {
    super.reset();
  };
}

export default ScopeOfWorkV2Store;
