import { LEAD_SOURCE_OPTIONS } from '../../leadDetails/leadSouceOptions';

export const leadSubStatusOptions = [
  { label: 'Bad/No Contact Info', value: 'Bad/No Contact Info' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Resi lead', value: 'Resi lead' },
  { label: 'Deal ready', value: 'Deal ready' },
  { label: 'Long Term potential', value: 'Long Term potential' },
  { label: 'Lost deal', value: 'Lost deal' },
  { label: 'Never', value: 'Never' },
  { label: 'New Brand', value: 'New Brand' },
  { label: 'Repeat', value: 'Repeat' },
  { label: 'Dupe', value: 'Dupe' },
  { label: 'First Attempt', value: 'First Attempt' },
  { label: 'Second Attempt', value: 'Second Attempt' },
  { label: 'Third Attempt', value: 'Third Attempt' },
];

export const leadNewSubStatusOptions = [
  { label: 'Never', value: 'Never' },
  { label: 'New Brand', value: 'New Brand' },
  { label: 'Repeat', value: 'Repeat' },
  { label: 'Dupe', value: 'Dupe' },
];

export const leadAttemptingToContactSubStatusOptions = [
  { label: 'First Attempt', value: 'First Attempt' },
  { label: 'Second Attempt', value: 'Second Attempt' },
  { label: 'Third Attempt', value: 'Third Attempt' },
];

export const leadFitSubStatusOptions = [
  { label: 'Deal ready', value: 'Deal ready' },
  { label: 'Long Term potential', value: 'Long Term potential' },
  { label: 'Lost deal', value: 'Lost deal' },
];

export const leadNoFitSubStatusOptions = [
  { label: 'Bad/No Contact Info', value: 'Bad/No Contact Info' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Resi lead', value: 'Resi lead' },
];

export const leadStatusOptions = [
  { label: 'New', value: 'New' },
  { label: 'Attempting to contact', value: 'Attempting to contact' },
  { label: 'No Fit', value: 'No Fit' },
  { label: 'Fit', value: 'Fit' },
];

export const tpoStatusOptions = [
  { label: 'Prospecting', value: 'Prospecting' },
  { label: 'Application in Progress', value: 'Application in Progress' },
  { label: 'Application Submitted', value: 'Application Submitted' },
  { label: 'Due Diligence', value: 'Due Diligence' },
  { label: 'Committee Decision', value: 'Committee Decision' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Declined', value: 'Declined' },
];

export const leadTypeOptions = [
  { label: 'Real Estate Investor', value: 'investor' },
  { label: 'Broker', value: 'broker' },
  { label: 'Private lender', value: 'private lender' },
  { label: 'Originator', value: 'originator' },
];

export const leadExperienceOptions = [
  {
    label: 'Looking for Personal Home Purchase',
    value: 'I am looking for a personal home purchase as a primary residence',
  },
  {
    label: 'Newbie but Interested',
    value: 'I am a newbie but very interested',
  },
  {
    label: 'Flipped homes or purchased Rental Properties',
    value: 'I have flipped homes or purchased rental properties',
  },
];

export const leadProfessionOptions = [
  { label: 'General Contractor', value: 'I am a general contractor' },
  { label: 'Real Estate Agent', value: 'I am a real estate agent' },
  { label: 'Neither', value: 'Neither' },
];

export const dataSourceGroupOptions = [
  { label: 'DS', value: 'DS' },
  { label: 'Conference', value: 'Conference' },
  { label: 'Vendor', value: 'Vendor' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Sales User Upload', value: 'Sales User Upload' },
  { label: 'Other', value: 'Other' },
];

export const leadSourceOptions = LEAD_SOURCE_OPTIONS.map(option => ({
  label: option,
  value: option,
}));

export enum FilterType {
  ADVANCED = 'advanced',
  SIMPLE = 'simple',
}

export const sortDirectionOptions = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];

export const activityTypeOptions = [
  { label: 'Inbound Call', value: 'MANUAL_INBOUND_CALL' },
  { label: 'Outbound Call', value: 'MANUAL_OUTBOUND_CALL' },
  { label: 'Outbound Email', value: 'MANUAL_OUTBOUND_EMAIL' },
  { label: 'Outbound Text', value: 'MANUAL_OUTBOUND_TEXT' },
];

export const activityTypeOptionsMapping = [
  { label: 'Inbound Call', value: 'MANUAL_INBOUND_CALL' },
  { label: 'Outbound Call', value: 'MANUAL_OUTBOUND_CALL' },
  { label: 'Outbound Email', value: 'MANUAL_OUTBOUND_EMAIL' },
  { label: 'Outbound Text', value: 'MANUAL_OUTBOUND_TEXT' },
  { label: 'Inbound Call', value: 'INBOUND_CALL' },
  { label: 'Outbound Call', value: 'OUTBOUND_CALL' },
  { label: 'Outbound Email', value: 'OUTBOUND_EMAIL' },
  { label: 'Outbound Text', value: 'OUTBOUND_TEXT' },
  { label: 'Auto Outbound Text', value: 'AUTO_OUTBOUND_TEXT' },
  { label: 'Inbound Text', value: 'INBOUND_TEXT' },
  {
    label: 'Outbound Phone Burner Call',
    value: 'MANUAL_OUTBOUND_PHONE_BURNER_CALL',
  },
  { label: 'Outbound Email By Marketing', value: 'OUTBOUND_MARKETING_EMAIL' },
  { label: 'Outbound Phone Burner Call', value: 'OUTBOUND_PHONE_BURNER_CALL' },
];

export const LEAD_OBJECT_TYPE = 'LEAD';
export const BORROWER_OBJECT_TYPE = 'BORROWER';
export const TPO_OBJECT_TYPE = 'TPO';
export const BROKER_OBJECT_TYPE = 'BROKER';
export const MIXED_OBJECT_TYPE = 'MIXED';

export const CRM_SALES_REMINDER = 'CRM_SALES_REMINDER';
export const BORROWER_WITHOUT_CREDIT_INQUIRY =
  'borrowers_with_outside_credit_inquiry';
export const STANDARD_DASHBOARD_TYPE = 'Standard';
export const CONVERT_TO_LENDER = 'convertToLender';
export const CONTACTED = 'contacted';
export const LEAD_NOT_INTERESTED = 'leadNotInterested';
export const UNABLE_TO_CONTACT = 'unableToContact';
export const CHANGE_STATUS = 'changeStatus';
export const ADD_VIEW_NOTES = 'addViewNotes';
export const ADD_ACTIVITY = 'addActivity';
export const ADD_TASK = 'addTask';
export const ADD_BROKER_AS_TPO = 'addBrokerAsTPO';
export const CHANGE_RELATIONSHIP_MANAGER = 'changeRelationshipManager';
export const CHANGE_OWNER = 'changeOwner';
export const CHANGE_LEAD_OWNER = 'changeLeadOwner';
export const OUTSIDE_CREDIT_INQUIRY = 'outsideCreditInquiry';
export const ADD_BROKER_RELATION = 'addBrokerRelation';
export const INCOMING_NOT_CONTACTED_LEADS = 'incoming_not_contacted_leads';
export const ALL_INCOMING_LEADS = 'all_incoming_leads';
export const RECENTLY_ASSIGNED_LEADS = 'recently_assigned_leads';

export const ALL_LEADS = 'all_leads';
export const FREE_APPRAISAL_AUDIENCE_LEADS = 'free_appraisal_audience_leads';
export const FREE_APPRAISAL_AUDICIENCE_UNASSIGNED_LEADS = 'free_appraisal_audience_unassigned_leads';
export const UNASSIGNED_LEADS = 'unassigned_leads';
export const BACKOFFICE_LEAD_DASHBOARDS = [INCOMING_NOT_CONTACTED_LEADS, ALL_INCOMING_LEADS, RECENTLY_ASSIGNED_LEADS];
export const DISPLAY_CREATED_LAST_UPDATED_DATE_DASHBOARDS = [ALL_LEADS, FREE_APPRAISAL_AUDIENCE_LEADS, FREE_APPRAISAL_AUDICIENCE_UNASSIGNED_LEADS, UNASSIGNED_LEADS];
export const BACKOFFICE_LEAD_DASHBOARDS_WITH_INCOMING_FIELD = [INCOMING_NOT_CONTACTED_LEADS, ALL_INCOMING_LEADS];
