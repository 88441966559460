import { action, computed, flow, makeObservable, observable } from 'mobx';
import { startOfToday, differenceInYears, parse, format } from 'date-fns';
import {
  ELMSURE,
  formatDate,
  generateUUID,
  getUniqueId,
  isEmptyObject,
  isNil,
  isNotBlank,
  LOAN_WITHOUT_LEAD_SOURCE_VALIDATION,
  LoanSubType,
  LoanType,
  notLicensedinState,
  PURCHASE,
  REFINANCE,
  REQUEST_LOAN_TYPE_LOAN,
  Utility,
  WIMBA,
  Roles,
  segmentAnalytics,
  SegmentTrackerEvent,
  OTHER,
  LoanStatus
} from '@roc/feature-utils';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import { ApiResponse, GlobalStore, UserService, UserStore } from '@roc/feature-app-core';
import { borrowerDefaults } from '@roc/feature-utils';
import { transactionRoles } from '@roc/ui/formComponents';
import PricerStoreStbBridge from './pricerStoreStbBridge';
import PricerSummaryStore from '../common/pricerSummaryStore';
import { BorrowerFormStore, canSubmitAppraisalWithTamarisk, LoanService } from '@roc/feature-loans';
import {
  getBridgeLoanTerms,
  getBorrowerEntity,
  getBorrowers,
  getBridgeProperties,
  getBorrowerEntityForLoanApplication,
  getStbBridgePropertiesFromExistingLoan,
} from '../common/convert-draft-loan-helper';

import { Borrower, DraftLoan, Entity, PropertyStbBridge } from '@roc/feature-types';
import { BrokersStore } from '@roc/feature-brokers';
import EntityStore from '../common/entityStore';
import LoanDetailsStore from '../common/loanDetailsStore';
import LoanInformationStore from './loanInformationStore';
import PropertyStore from '../fixFlip/propertyStore';
import PropertyTypesStore from '../rentalPortfolio/propertyTypesStore';
import primaryPropertyTemplate from '../templates/primaryPropertyTemplate';
import { StabilizedService } from '../../stabilizedBridge/components/Services/stabilizedService';
import { LoanSubmissionStep } from '../../utils/constants';
import { requiredLicenseStates } from '@roc/feature-utils';
import { normalizeState } from '@roc/feature-utils';
import { EditLoanSubmissionService } from '@roc/feature-edit-loans';
import { MarketingPromotionsStore } from '@roc/feature-sendgrid';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';
import { getQuoteFromLoanSubmitJson } from '../common/mapLoanToQuoteHelper';

export class StabilizedBridgeStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  propertyStore: PropertyStore;
  brokersStore: BrokersStore;
  entityStore: EntityStore;
  borrowerFormStore: BorrowerFormStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanInformationStore: LoanInformationStore;
  propertyTypesStore: PropertyTypesStore;
  pricerStore: PricerStoreStbBridge;
  pricerSummaryStore: PricerSummaryStore;
  public entity: Entity | Record<string, never>;
  public rawEntity: Entity | Record<string, never>;
  public selfBorrower: Borrower | Record<string, never>;
  public loanTerms;
  public properties: PropertyStbBridge[];
  public loanType: string;
  public activeStep: number;
  public stepError: string;
  public allErrors: string[];
  public allWarnings: string[];
  public loanSubtype: LoanSubType;
  public saved: boolean;
  public requestSaved: boolean;
  public savedLoanId: number;
  public isNewEntity: boolean;
  public hasFastTrack: boolean;
  public isFastTrackPaymentComplete: boolean;
  public selectedEntities: any[];
  private loanService: LoanService;
  private editLoanSubmissionService: EditLoanSubmissionService;
  public draftLoanInfo: DraftLoan;
  public isAnyPropertyPurchase: boolean;
  loanDetailsStore: LoanDetailsStore;
  public allCounties;
  private user: UserService;
  private stabilizedService: StabilizedService;
  public disableSubmitButton: boolean;
  public isContinuingFromQuote: boolean;
  public areBorrowersPreQualified: boolean;
  public borrowersErrors: string[];
  public borrowersWarnings: string[];
  public editLoanJson: any;
  marketingPromotionsStore: MarketingPromotionsStore;
  public loanApplication: any;
  private oneToolService: OneToolService;
  public floorPoints: number;
  public maxPoints: number;

  constructor(globalStore, userStore, brokersStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.brokersStore = brokersStore;
    this.user = new UserService();
    this.stabilizedService = new StabilizedService();
    this.editLoanSubmissionService = new EditLoanSubmissionService();
    this.propertyStore = new PropertyStore(this.globalStore, x =>
      this.handleRenovationBudgeChanged(x)
    );
    this.entityStore = new EntityStore(this.globalStore, this);
    this.borrowerFormStore = new BorrowerFormStore(this.globalStore);
    this.loanInformationStore = new LoanInformationStore(
      this.globalStore,
      this
    );
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      this.borrowerFormStore,
      undefined,
      undefined,
      () => this.handleBorrowersChange(),
      undefined,
      () => new BorrowerFormStore(this.globalStore)
    );
    this.loanService = new LoanService();
    this.editLoanSubmissionService = new EditLoanSubmissionService();
    this.propertyTypesStore = new PropertyTypesStore(globalStore);
    this.pricerStore = new PricerStoreStbBridge(globalStore, this);
    this.loanDetailsStore = new LoanDetailsStore(globalStore, this);
    this.marketingPromotionsStore = new MarketingPromotionsStore(globalStore);
    this.oneToolService = new OneToolService();
    this.setDefaults();

    makeObservable(this, {
      entity: observable,
      selfBorrower: observable,
      loanTerms: observable,
      properties: observable,
      saved: observable,
      requestSaved: observable,
      savedLoanId: observable,
      activeStep: observable,
      stepError: observable,
      allErrors: observable,
      allCounties: observable,
      getRateFloor: observable,
      fetchBorrowerPointsOptions: observable,
      floorPoints: observable,
      maxPoints: observable,
      reset: action,
      loadStore: flow,
      setExistingEntity: action,
      setDefaultEntity: action,
      setEntity: action,
      setSelectedEntity: action,
      setIsNewEntity: action,
      moveToStep: action,
      goNextStep: action,
      goPrevStep: action,
      setBorrowers: action,
      setNewBorrowers: action,
      setLoanTerms: action,
      addProperty: action,
      updateProperty: action,
      deleteProperty: action,
      propertiesRows: computed,
      canSubmitAppraisalWithTamarisk: computed,
      onLoanSave: flow,
      getDraftLoan: flow,
      onLoanSubmit: flow,
      log: flow,
      isRawEntityValue: action,
      setDraftLoanId: action,
      handleBorrowerInformationChange: action,
      isValidBankruptcyDate: action,
      isValidForeclosureDate: action,
      validateErrors: flow,
      sendFastTrackPayment: flow,
      draftLoanInfo: observable,
      isLoanRequest: computed,
      disableSubmitButton: observable,
      validateBorrowerErrors: flow,
      validateEntityErrors: flow,
      isContinuingFromQuote: observable,
      areBorrowersPreQualified: observable,
      getAreBorrowersPreQualifiedFromPrescreenValidations: flow,
      borrowersErrors: observable,
      borrowersWarnings: observable,
      setBroker: action,
      editLoanJson: observable,
      getEditLoanJsonById: flow,
      onEditLoanSubmit: flow,
      setLoanApplicationId: action,
      getLoanApplicationById: flow,
      loanApplication: observable,
      rejectLoanApplication: flow,
      onLoanApplicationSave: flow,
      saveLoanQuoteForApplication: flow,
    });
  }

  private setDefaults() {
    this.entity = {};
    this.rawEntity = {};
    this.loanTerms = {};
    this.properties = [];
    this.loanType = 'Residential Bridge';
    this.loanSubtype = LoanSubType.STABILIZED_BRIDGE;
    this.activeStep = 0;
    this.stepError = '';
    this.allErrors = [];
    this.allWarnings = [];
    this.saved = false;
    this.requestSaved = false;
    this.savedLoanId = null;
    this.isNewEntity = false;
    this.selectedEntities = [];
    this.draftLoanInfo = {
      borrowers: null,
      propertyAddress: null,
      loanType: null,
      loanSubType: null,
      amount: null,
      createdBy: null,
      createdDate: null,
      creationDate: null,
      draftLoanId: null,
      id: null,
      isActive: true,
      type: null,
      rejectRequestReason: null,
      status: null,
      loanApplicationId: null,
      borrowerLoanApplication: false,
      loanQuoteId: null,
    };
    this.loanApplication = {
      loanApplicationId: null,
    }
    this.allCounties = {};
    this.hasFastTrack = false;
    this.isFastTrackPaymentComplete = false;
    this.disableSubmitButton = false;
    this.isContinuingFromQuote = false;
    this.areBorrowersPreQualified = true;
    this.borrowersErrors = [];
    this.borrowersWarnings = [];
    this.editLoanJson = {};
    this.floorPoints = 1;
    this.maxPoints = 5;
  }

  findIsAnyPropertyPurchase() {
    this.isAnyPropertyPurchase = this.properties.some(property => property.loanPurpose === PURCHASE);
    if (!this.isAnyPropertyPurchase && isNil(this.editLoanJson.loanId)) {
      this.loanInformationStore.resetLoanPurpose();
    }
  }

  setIsNewEntity = isNewEntity => {
    this.isNewEntity = isNewEntity;
  };

  private calculateBorrowerPercentages = () => {
    return this.selectBorrowersStore.borrowers.reduce(
      (total, current) => total + current.pctOwnership ?? 0,
      0
    );
  };

  checkPoints() {
    const isBrokerUser = this.userStore.userInformation?.roles?.includes(Roles.BROKER);
    const brokerPoints = this.loanTerms.brokerPoints;
    const lenderPoints = this.loanTerms.points;

    if (isBrokerUser && brokerPoints > 2) {
      return 'Broker points must not exceed 2%.'
    } else {
      return brokerPoints > lenderPoints
        ? 'Broker points cannot exceed total lender points.'
        : '';
    }
  }

  private checkAllErrors = () => {
    this.allErrors = [];

    const pointsError = this.checkPoints();
    if (pointsError)
      this.allErrors.push(pointsError);

    if (isEmptyObject(this.entity)) {
      this.allErrors.push('Please select an entity or create a new one.');
    }
    if (this.selectBorrowersStore.borrowers.length === 0) {
      this.allErrors.push('Please select a borrower or add a new one.');
    }
    this.checkBorrowersErrors();
    this.allErrors = [...this.allErrors, ...this.borrowersErrors];
    if (this.properties.length === 0) {
      this.allErrors.push('Please add a new property.');
    }
    if (this.loanTerms.loanAmount === 0) {
      this.allErrors.push('Total loan amount must be greater than 0.');
    }
    if (
      this.properties.some(p =>
        notLicensedinState.states.includes(p.state?.toUpperCase())
      )
    ) {
      this.allErrors.push(notLicensedinState.message);
    }
    if ((!this.loanInformationStore.otherTitleFormStore.form.meta.isValid && !isNil(this.editLoanJson.loan)) && this.loanTerms.preferredTitle === 'Other') {
      this.allErrors.push('Please fill out all the info for Preferred Title Company.');
    }

    this.allWarnings = [];
    if (this.calculateBorrowerPercentages() !== 100) {
      this.allWarnings.push(
        'The total percent ownership for all borrowers should add up to 100. If you are not sure, you can submit the loan but provide this as soon as possible.'
      );
    }
    this.checkBorrowersWarnings();
    this.allWarnings = [...this.allWarnings, ...this.borrowersWarnings];
    if (this.entity.operatingAgreementDate) {
      const diff = differenceInYears(
        startOfToday(),
        parse(this.entity.operatingAgreementDate, 'MM/dd/yyyy', new Date())
      );
      if (diff > 1) {
        this.allWarnings.push(
          'Certificate of Good Standing is needed within 90 days of closing. These can be ordered on the applicable county/state website'
        );
      }
    }

    if (this.properties.some(p => p.state !== this.entity.state)) {
      this.allWarnings.push(
        'The borrower must obtain a foreign entity status in the state of the collateral. These can be ordered on the applicable county/state website'
      );
    }
    if (this.globalStore.lenderInfo?.showTPOLicenseValidation && this.properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
      !this.globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) &&
      this.loanInformationStore.licenseUpload.length == 0) {
      this.allErrors.push('Please upload the corresponding state license.');
    }
  };

  private checkStepErrors = (currentStep: LoanSubmissionStep) => {
    this.checkAllErrors();
    switch (currentStep) {
      case LoanSubmissionStep.LOAN_DETAILS: {
        this.loanInformationStore.runFormValidation();
        if (!this.loanInformationStore.form.meta.isValid) {
          return true;
        }
        this.stepError = this.checkPoints();
        if (this.stepError) {
          return true;
        }

        const allowLenderUnderwritingFee = this.globalStore.userFeatures?.allowLenderUnderwritingFee;
        if (allowLenderUnderwritingFee && this.userStore.allowLoanPricing) {
          this.stepError = this.checkLenderUnderwritingFeeValidations();
          return !!this.stepError;
        }

        else {
          this.stepError = '';
          return false;
        }
      }
      default: {
        this.stepError = '';
        return false;
      }
    }
  };

  checkLenderUnderwritingFeeValidations() {
    if (this.loanTerms.amount < 500000 && this.loanTerms.lenderUnderwritingFee < 595) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $595.';
    }
    if (this.loanTerms.amount >= 500000 && this.loanTerms.amount < 1000000 && this.loanTerms.lenderUnderwritingFee < 895) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $895.';
    }
    if (this.loanTerms.amount >= 1000000 && this.loanTerms.amount < 2000000 && this.loanTerms.lenderUnderwritingFee < 1295) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,295.';
    }
    if (this.loanTerms.amount >= 2000000 && this.loanTerms.amount < 3000000 && this.loanTerms.lenderUnderwritingFee < 1995) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $1,995.';
    }
    if (this.loanTerms.amount >= 3000000 && this.loanTerms.amount < 4000000 && this.loanTerms.lenderUnderwritingFee < 3495) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $3,495.';
    }
    if (this.loanTerms.amount >= 4000000 && this.loanTerms.amount < 5000000 && this.loanTerms.lenderUnderwritingFee < 4495) {
      return 'Lender Underwriting Fee for this loan must be greater than or equal to $4,495.';
    }
    return '';
  }

  private handleBorrowersChange() {
    this.prepareBorrowers();
  }

  private prepareBorrowers() {
    const existingBorrowers = this.selectBorrowersStore.existingBorrowers.map(
      borrower => this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowerDefaults(borrower)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  private setBorrowerDefaults(borrower: Borrower) {
    return {
      ...borrower,
      personalGuarantor:
        borrower.personalGuarantor ?? null,
    };
  }

  moveToStep = (step, currentStep: LoanSubmissionStep) => {
    if (currentStep === LoanSubmissionStep.BORROWER_INFORMATION) {
      this.selectBorrowersStore.borrowerAccordionStore.saveBorrowersFromAccordion();
    }
    this.checkStepErrors(currentStep);
    this.activeStep = step;
  };

  goPrevStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    this.checkStepErrors(currentStep);
    this.activeStep--;
  };

  goNextStep = (currentStep: LoanSubmissionStep) => {
    this.stepError = '';
    if (!this.checkStepErrors(currentStep)) {
      this.moveToStep(this.activeStep + 1, currentStep);
    }
    if (this.stepError) {
      this.globalStore.notificationStore.showErrorNotification({
        message: this.stepError,
      });
    }
  };

  *loadStore(editLoanId = null) {
    if (this.isContinuingFromQuote) {
    } else if (!isNil(editLoanId)) {
      this.getEditLoanJsonById(editLoanId);
    } else if (isNil(this.draftLoanInfo.draftLoanId) && isNil(this.loanApplication.loanApplicationId) && isNil(editLoanId)) {
      this.reset();
    } else if (!isNil(this.loanApplication.loanApplicationId)) {
      this.getLoanApplicationById();
    } else {
      this.getDraftLoan();
    }
    //this.loanInformationStore.getLenderAttorneys();
    this.loanInformationStore.getWimbaStates();

    if (this.loanInformationStore.loanParticipantsStore.isFacoOriginator) {
      this.loanInformationStore.getLoanOriginators();
    } else {
      this.loanInformationStore.loanParticipantsStore.fetchLoanParticipants();
    }
    if (this.globalStore.userFeatures.isBroker) {
      yield this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
      yield this.brokersStore.manageBrokersStore.getBrokerVendorClosingSettingsByBrokerId(
        this.brokersStore.selectBrokersStore.brokers[0].brokerId
      );
    }
  }

  reset() {
    this.setDefaults();
    this.entityStore.resetStore();
    this.borrowerFormStore.reset();
    this.pricerStore.reset();
    this.loanInformationStore.resetLoanInformation();
    this.brokersStore.reset();
    this.selectBorrowersStore.reset();
    if (this.globalStore.userFeatures.isBroker) {
      this.brokersStore.selectBrokersStore.loadBrokerUser(this.userStore.userInformation.userId);
    }
    this.loanInformationStore.loanParticipantsStore.reset();
  }

  setLoanType(loanSubType: LoanSubType) {
    this.loanSubtype = loanSubType;
    if (this.isContinuingFromQuote) {
      this.isContinuingFromQuote = false;
    } else {
      this.loanInformationStore.resetLoanInformation();
    }
  }

  setSelectedEntity(entity, isSelected) {
    if (isSelected) {
      this.selectedEntities = [...this.selectedEntities, entity];
    } else {
      const index = this.selectedEntities.findIndex(
        selectedEntity =>
          selectedEntity.borrowerEntityId === entity.borrowerEntityId
      );
      this.selectedEntities.splice(index, 1);
    }
  }

  setExistingEntity() {
    const selectedEntity = { ...this.selectedEntities[0] };
    if (!selectedEntity.name) return;

    let initialBorrowers = [];
    const _borrowers = [];
    for (const option of selectedEntity.borrowers) {
      const borrower = this.setBorrowerDefaults({
        ...option.borrower,
        borrowerId: option.borrower.borrowerId,
        pctOwnership: option.pctOwnership ?? 0,
        roleInTransaction: '',
        firstName: option.borrower.firstName,
        lastName: option.borrower.lastName,
        cellPhone: option.borrower.cellPhone,
        emailAddress: option.borrower.emailAddress,
      });
      _borrowers.push(borrower);
    }

    this.rawEntity = { ...selectedEntity };

    this.setEntity(selectedEntity, [...initialBorrowers, ..._borrowers]);
  }

  setDefaultEntity() {
    const _borrowers = [];
    this.entity = {
      name: '',
      type: 'Limited Liability Company',
      borrowers: _borrowers,
      loanApplicationUuid: generateUUID(),
    };
    this.setBorrowers(_borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  setEntity(entity, borrowers = []) {
    this.entity = {
      borrowerEntityId: entity.borrowerEntityId,
      name: entity.name,
      ein: entity.ein,
      type: entity.type,
      operatingAgreementDate: entity.operatingAgreementDate,
      address: entity.address,
      streetNumber: entity.streetNumber,
      streetName: entity.streetName,
      city: entity.city,
      state: entity.state,
      zipCode: entity.zipCode,
      loanApplicationUuid: entity.loanApplicationUuid ?? generateUUID(),
      borrowers,
    };
    this.setBorrowers(borrowers);
    this.checkStepErrors(LoanSubmissionStep.ENTITY_INFORMATION);
  }

  deleteEntity = () => {
    this.entity = {};
    this.rawEntity = {};
    this.setBorrowers([]);
    this.isNewEntity = false;
    this.selectedEntities = [];
  };

  setBorrowers(borrowers) {
    this.selectBorrowersStore.setExistingBorrowersFromExternal([...borrowers]);
  }

  setNewBorrowers(borrowers) {
    this.selectBorrowersStore.setNewBorrowersFromExternal([...borrowers]);
  }

  setIsLoanBeingEdit(isLoanBeingEdit) {
    this.selectBorrowersStore.setIsLoanBeingEdit(isLoanBeingEdit);
  }

  addProperty = property => {
    this.properties.push(property);
    this.loanInformationStore.setDefaultPreferredTitle();
    this.checkStepErrors(LoanSubmissionStep.PROPERTY_INFORMATION);
    this.findIsAnyPropertyPurchase();

  };

  updateProperty = updatedProperty => {
    this.properties = this.properties.map(property => {
      if (property.propertyId == updatedProperty.propertyId) {
        return updatedProperty;
      }
      return property;
    });
    this.loanInformationStore.setDefaultPreferredTitle();
    this.findIsAnyPropertyPurchase();
  };

  deleteProperty = (propertyId: string) => {
    this.properties = this.properties.filter(x => x.propertyId !== propertyId);
    if (!this.properties.length) {
      this.loanInformationStore.reset();
    }
    this.loanInformationStore.setDefaultPreferredTitle();
    this.findIsAnyPropertyPurchase();
  };

  setLoanTerms = loanTerms => {
    this.loanTerms = { ...loanTerms };
  };

  setSaved = (value: boolean) => {
    this.saved = value;
  };

  setDraftLoanId = (value: number) => {
    this.draftLoanInfo.draftLoanId = value;
  };


  getPropertiesLTFC = () => {
    this.loanInformationStore.makeInitialCalculations();
    const futureCost =
      this.loanTerms.totalPurchasePrice + this.loanTerms.totalRenovationBudget;
    return futureCost ? (this.loanTerms.amount / futureCost) * 100 : 0;
  };

  borrowersHaveEmail = () => {
    return this.selectBorrowersStore.borrowers.length
      ? this.selectBorrowersStore.borrowers.findIndex(
        borrower => !borrower.emailAddress
      ) !== -1
      : false;
  };

  public checkBorrowersErrors = () => {
    this.borrowersErrors = this.selectBorrowersStore.borrowerAccordionStore.validate();
    if (this.borrowersHaveEmail()) {
      this.borrowersErrors.push('Please add email address to every borrower.');
    }
  }

  public checkBorrowersWarnings = () => {
    this.borrowersWarnings = [];
    if (this.selectBorrowersStore.borrowers.length > 0 && this.calculateBorrowerPercentages() !== 100) {
      this.borrowersWarnings.push(
        'Please ensure that "Percentage of Ownership" adds to a total of 100%. Click the pencil icon to edit your borrower(s) and adjust the ownership percentage slider accordingly.'
      );
    }
  }

  *log(data, additionalInfo) {
    try {
      yield this.loanService.log(data, additionalInfo);
    } catch (error) {
      console.log(error);
    }
  }

  get loanSaveJson() {
    const {
      borrowerEntity,
      borrowers,
      properties,
      ...rest
    } = this.loanSubmitJson;

    return {
      ...rest,
      borrowerEntityObj: {
        ...borrowerEntity,
      },
      loanBorrowerInformation: {
        loanBorrowers: {
          rows: [...borrowers],
        },
      },
      propertiesMap: {
        rows: [...properties],
      },
    };
  }

  setInsuranceDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === ELMSURE || this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany === null) {
      return { insuredThroughElmsure: true };
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore.brokerVendorClosingSettings.preferredInsuranceCompany)
    }
  }

  setPreferredInsuranceBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === ELMSURE) {
      return ELMSURE;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredInsuranceCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setPreferredTitleBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return WIMBA;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return OTHER;
    }
  }

  setTitleDataBasedOnBrokerSettings = () => {
    if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === WIMBA) {
      return null;
    } else if (this.brokersStore.manageBrokersStore.brokerVendorClosingSettings?.preferredTitleCompany === null) {
      return null;
    } else {
      return JSON.parse(this.brokersStore.manageBrokersStore?.brokerVendorClosingSettings?.preferredTitleCompany)
    }
  }

  get loanSubmitJson() {
    const isElmsure = this.loanTerms.preferredInsurance === ELMSURE;
    const isWimba = this.loanTerms.preferredTitle === WIMBA;
    const loanInformationValues = this.loanInformationStore.getFormValues();
    return {
      loanType: this.loanType,
      broker: this.brokersStore.selectBrokersStore.brokers[0],
      loanSubtype: this.loanSubtype,
      loanPurpose: this.loanTerms.loanPurpose,
      lenderParticipation: this.loanTerms.lenderParticipation,
      submitAppraisalWithTamarisk: this.globalStore.userFeatures?.tamariskAppraisal ? true : this.loanTerms.submitAppraisalWithTamarisk,
      pledgeOfShares: true,
      fullPrincipalInterest: this.loanTerms.fullPrincipalInterest,
      loanAmount: this.loanTerms.loanAmount,
      loanToValue: this.loanTerms.loanToValue,
      rate: this.loanTerms.rate,
      rocRate: this.loanTerms.rate,
      interestMethod: 'Actual/360',
      points: this.loanTerms.points,
      pointsIn: this.loanTerms.points,
      pointsOut: null,
      rocPointsIn: this.loanTerms.rocPointsIn,
      brokerPoints: this.loanTerms.brokerPoints,
      brokerPointsIn: this.loanTerms.brokerPoints,
      referralFee: this.loanTerms.referralFee,
      lenderOriginatorId: this.getLenderOriginatorId(this.loanTerms.loanOriginator),
      brokerPointsOut: null,
      interestReserveMonths: this.loanTerms.interestReserveMonths,
      duration: this.loanTerms.duration,
      origLenderSubordination: this.loanTerms.origLenderSubordination,
      attorneyEmail: this.globalStore.userFeatures.isBroker ? this.loanInformationStore.getPreferredAttorney()
        ?.value : this.loanTerms.preferredClosingAttorney,
      lenderAttorneySelectedName: this.loanInformationStore.getPreferredAttorney()
        ?.label,
      preferredInsurance: this.globalStore.userFeatures.isBroker ? this.setPreferredInsuranceBasedOnBrokerSettings() : this.loanTerms.preferredInsurance,
      requestedClosingDate: this.loanTerms.requestedClosingDate,
      amount: this.loanTerms.loanAmount,
      initialLoanAmount: this.loanTerms.loanAmount,
      sellAs: this.loanTerms.sellAs,
      loanInsuranceData: this.globalStore.userFeatures.isBroker ? this.setInsuranceDataBasedOnBrokerSettings() :
        this.loanTerms.preferredInsurance === 'Elmsure'
          ? { insuredThroughElmsure: true, estimatedInsurance: this.loanInformationStore.calculateTotalAnnualInsurance() }
          : {
            ...this.loanTerms.otherInsuranceCompany, estimatedInsurance: this.loanInformationStore.calculateTotalAnnualInsurance()
          },
      preferredTitle: this.globalStore.userFeatures.isBroker ? this.setPreferredTitleBasedOnBrokerSettings() : this.loanTerms.preferredTitle
        ? this.loanTerms.preferredTitle
        : null,
      titleCompany: this.globalStore.userFeatures.isBroker ? this.setTitleDataBasedOnBrokerSettings() :
        !this.loanTerms.preferredTitle
          ? null
          : this.loanTerms.preferredTitle !== WIMBA
            ? {
              ...this.loanTerms.otherTitleCompany,
            }
            : null,
      emailsForCreditBackgroundCheckRequests: this.borrowerCreditRequests?.join(
        ','
      ),
      loanSubmittedByMailId: this.userStore.userInformation?.emailAddress,
      isSubmitted: true,
      borrowerEntity: this.borrowerEntityObj,
      borrowers: [...this.borrowersRows],
      properties: [...this.propertiesRows],

      utility: Utility.LOAN_INFORMATION,
      draftLoanId: !(this.loanTerms.fastTrack === 'Y') ? this.draftLoanInfo.draftLoanId :
        this.loanInformationStore.draftLoanId,
      underwritingFee: this.loanTerms.lenderUnderwritingFee,
      processingFee: this.loanTerms.lenderProcessingFee,
      externalLenderUnderwritingFee: this.loanTerms.externalLenderUnderwritingFee,
      externalLenderProcessingFee: this.loanTerms.externalLenderProcessingFee,
      fastTrack: this.loanTerms.fastTrack === 'Y',
      fastTrackOptions: {
        ...this.loanTerms.fastTrackOptions,
        insuranceReview: !isElmsure,
        titleSearch: isWimba,
        insurance: isElmsure,
        titleAttorneySearch: !isWimba,
        properties: this.properties.length,
        propertiesAddresses: this.properties?.map(property => property.address).join(', ')
      },
      freeAppraisalPromotion: this.loanTerms.freeAppraisalPromotion === 'Y',
      projectDescription: loanInformationValues.projectDescription,
      marketingPromotionId: this.loanTerms.marketingPromotionId
    };
  }

  getLenderOriginatorId(selectedLenderOriginatorId) {
    return this.globalStore.userFeatures?.isLenderOriginator ? this.userStore.userInformation?.userId : selectedLenderOriginatorId;
  }

  get borrowerEntityObj() {
    return {
      borrowerEntityId: this.entity.borrowerEntityId,
      name: this.entity.name,
      ein: this.entity.ein,
      type: this.entity.type,
      operatingAgreementDate: this.entity.operatingAgreementDate,
      address: this.entity.address,
      city: this.entity.city,
      zipCode: this.entity.zipCode,
      streetName: this.entity.streetName,
      state: this.entity.state,
      streetNumber: this.entity.streetNumber,
      loanApplicationUuid: this.entity.loanApplicationUuid
    };
  }

  get borrowersRows() {
    const defaultFormValues = this.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo.restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: this.getValues(borrower.roleInTransaction),
        personalGuarantor: borrower.personalGuarantor,
        experience: borrower.experience ?? defaultFormValues.experience,
        pctOwnership:
          borrower.pctOwnership ?? defaultFormValues.pctOwnership,
        citizenshipStatus:
          borrower.citizenshipStatus || defaultFormValues.citizenshipStatus,
        hasSubstantialChanges:
          borrower.hasSubstantialChanges ??
          defaultFormValues.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        OwnershipNotKnown:
          borrower.ownershipNotKnown ?? defaultFormValues.ownershipNotKnown, // Compatiblity with eportal
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
        ficoProvidedAtOrigination: defaultFormValues.ficoProvidedAtOrigination,
        loanApplicationUuid: borrower.loanApplicationUuid,
        promoCode: borrower?.promoCode,
        promoCodeDate: borrower?.promoCodeDate,
        protectedBorrower: borrower?.protectedBorrower,
      };
    });
  }

  shortDateAcquisitionDate(acquisitionDate) {
    return acquisitionDate ? format(new Date(acquisitionDate), 'MM/dd/yyyy') : null;
  }

  isoAcquisitionDate(acquisitionDate) {
    return acquisitionDate && new Date(acquisitionDate).toISOString();
  }

  checkBoolean(field) {
    if (field === 'Y') {
      return true;
    }
    if (field === 'N') {
      return false;
    }
    return field;
  }

  get propertiesRows() {
    return this.properties.map(property => ({
      ...property,
      acquisitionDate: this.isoAcquisitionDate(property.acquisitionDate),
      useCode: property.propertyType,
      noOfUnitLeased: property.unitsOccupied,
      unitsLeaseType: property.unitsOccupiedHave12MonthsLease,
      anyDebt: this.checkBoolean(property.anyDebt),
      occupancy: this.checkBoolean(property.occupancy),
      armsLength: this.checkBoolean(property.armsLength),
      exitStrategy: property.exitStrategy,
      nsfrLoanPropertyFields: {
        ...primaryPropertyTemplate.nsfrLoanPropertyFields,
        propertyValuation: property.propertyValuation,
        annualTaxes: property.annualTaxes,
        annualInsurance: property.annualInsurance,
        annualInsuranceFromSubmission: property.annualInsurance,
        annualHOA: property.annualHOA,
        acquisitionPrice: property.acquisitionPrice,
        acquisitionDate: this.shortDateAcquisitionDate(property.acquisitionDate),
        capitalImprovements: property.capitalImprovements,
        totalDebtPayoff: property.totalDebtPayoff,
        monthlyGrossRent: property.monthlyGrossRent,
        monthlyMarketRent: property.monthlyMarketRent,
        noOfUnitLeased: property.unitsOccupied,
        annualGrossRent:
          property.monthlyGrossRent && property.monthlyGrossRent * 12,
      },
      extraFields: {
        maximumAsIsValue: property.maximumAsIsValue,
        minimumAsIsValue: property.minimumAsIsValue,
        numberOfProperties: property.numberOfProperties,
        propertyManagement: property.propertyManagement,
        targetLTV: property.targetLTV,
        totalAnnualExpenses: property.totalAnnualExpenses,
        totalAnnualIncome: property.totalAnnualIncome,
        totalAnnualNOI: property.totalAnnualNOI,
        totalAnnualPropertyManagementFees:
          property.totalAnnualPropertyManagementFees,
        totalAnnualRepairsMaintenance:
          property.totalAnnualRepairsMaintenance,
        totalAnnualUtilities: property.totalAnnualUtilities,
      },
      propertyId:
        !isNil(this.editLoanJson.loanId) || !isNil(this.loanApplication.loanApplicationId) ? property.propertyId : null,
      loanApplicationUuid: property.loanApplicationUuid
    }));
  }

  get borrowerCreditRequests() {
    return this.selectBorrowersStore.borrowers
      .filter(borrower => borrower.creditBackgroundCheck)
      .map(borrower => borrower.emailAddress);
  }

  get isMultifamilyBridge() {
    return this.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS;
  }

  isRawEntityValue(field) {
    return this.rawEntity[field];
  }

  getBoolean = (value: string) => {
    return value === 'Y';
  };

  getValues = (options: any[]) => {
    const values = options ? options.map(option => option.value) : [];
    return values.join(',');
  };

  handleRenovationBudgeChanged = (value: number) => {
    if (!this.isMultifamilyBridge) return;

    const {
      purchasePrice,
    } = this.propertyStore.purchaseInformationStore.getFormValues();
    if (value > purchasePrice / 2) {
      this.globalStore.notificationStore.showWarningNotification({
        message:
          'The Renovation budget should not be greater than 50% of the Purchase price.',
      });
    }
  };

  *onLoanSave(type: string, showSuccessNotification = true) {
    try {
      let loan = this.loanSaveJson;
      loan.marketingPromotionId = null;
      const draftLoan = {
        content: JSON.stringify(loan),
        draftLoanId: this.draftLoanInfo.draftLoanId,
        createdBy: this.draftLoanInfo.createdBy,
        creationDate: this.draftLoanInfo.creationDate
          ? format(
            new Date(this.draftLoanInfo.creationDate),
            'MM/dd/yyyy HH:mm:ss.SSS'
          )
          : null,
        isActive: this.draftLoanInfo.isActive,
        type: type,
        rejectRequestReason: this.draftLoanInfo.rejectRequestReason,
        status: this.draftLoanInfo.status,
        brokerId: this.globalStore.userFeatures.isBroker ? this.brokersStore.selectBrokersStore.brokers[0].brokerId : null,
        lenderOriginatorId: loan.lenderOriginatorId,
      };
      if (this.allErrors.length > 0 && type === REQUEST_LOAN_TYPE_LOAN) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(draftLoan, 'draft loan');
        const createDocuments = this.globalStore.userFeatures.uploadDuringSubmissionEnabled;
        const response: ApiResponse = yield this.loanService.saveDraftLoan(
          draftLoan,
          createDocuments
        );
        if (response.data) {
          this.draftLoanInfo.draftLoanId = response.data?.data?.id;
          if (showSuccessNotification) {
            this.globalStore.notificationStore.showSuccessNotification({
              message: 'Loan has been saved successfully.',
            });
          }
        }
        type == REQUEST_LOAN_TYPE_LOAN
          ? (this.requestSaved = true)
          : (this.requestSaved = false);
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan.',
      });
    }
  }

  *getDraftLoan() {
    try {
      const response: ApiResponse = yield this.loanService.getDraftLoan(
        this.draftLoanInfo.draftLoanId
      );
      this.reset();
      const data = response?.data?.data;
      this.draftLoanInfo = data;
      const draftLoan = JSON.parse(data.content);
      this.mapDraftLoanToStore(draftLoan);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting draft loan.',
      });
    }
  }

  mapDraftLoanToStore(draftLoan: any) {
    const { loanType, loanSubtype } = draftLoan;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntity(draftLoan);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(draftLoan, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));

    this.properties = getBridgeProperties(draftLoan);
    this.loanTerms = getBridgeLoanTerms(draftLoan);

    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(
      this.loanTerms.otherInsuranceCompany
    );
    this.loanInformationStore.otherTitleFormStore.loadForm(
      this.loanTerms.otherTitleCompany
    );
    if (draftLoan.broker) {
      const { broker } = draftLoan;
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
  }

  *onLoanSubmit() {
    try {
      let data;
      if (this.isBorrowerLoanApplication) {
        data = {
          ...this.loanApplication,
          ...this.loanSubmitJson
        }
      }
      else {
        data = this.loanSubmitJson;
      }
      this.disableSubmitButton = true;
      const isInternal = this.globalStore.lenderInfo?.isInternal;
      this.log(data, 'submit bridge loan');
      console.log(data, 'submit bridge loan');
      console.log(data, '3');
      this.log(data, '3');
      console.log('3');
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEvent.STB_SUBMISSION,
        userName: this.userStore?.userInformation?.username
      });
      const response: ApiResponse = yield this.loanService.submitBridgeLoan(
        data
      );

      if (response.data?.data?.success === false) {
        const additionalDescription =
          '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
        let errorMessage = response.data?.data?.responseMessage;
        this.sendOtherValidationEmailError(errorMessage);
      } else {
        this.savedLoanId = response.data?.data?.loanId;
        if (this.loanInformationStore.form.fields.fastTrack.value === 'Y') {
          const fastTrackInfo = yield this.loanService.getLoanDetails(this.savedLoanId.toString());
          this.hasFastTrack = true;
          this.isFastTrackPaymentComplete = fastTrackInfo.data?.data?.fastrackPaymentComplete;
        }
        this.saved = true;
      }
    } catch (error) {
      this.saved = true;
      this.sendLoanSubmissionErrorEmail(error?.error?.response?.data?.error?.message || error?.error?.message || error);
    }
  }


  *onEditLoanSubmit(onSuccessCallback = () => { }) {
    try {
      const data = this.loanSubmitJson;
      this.disableSubmitButton = true;
      const response: ApiResponse = yield this.editLoanSubmissionService.updateLoanSubmission(
        data, this.editLoanJson.loanId
      );

      if (response.data?.data?.success === false) {
        const additionalDescription =
          '. You can save the loan, fix the errors and continue with the submission later. Please reach out to tech team on this.';
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data?.responseMessage + additionalDescription,
        });
        this.saved = true;
      } else {
        this.savedLoanId = response.data?.data?.loanId;
        this.saved = true;
        onSuccessCallback();
      }
    } catch (error) {
      this.saved = true;
    }
  }

  get canSubmitAppraisalWithTamarisk() {
    return canSubmitAppraisalWithTamarisk(
      this.loanSubtype,
      this.globalStore.userFeatures
    );
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.loanService.sendErrorEmail(
      'Bridge Loan Submission Failed',
      `Error occured while submitting ${this.loanType} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  handleBorrowerInformationChange() {
    this.prepareBorrowers();
  }

  *fetchStateCounties() {
    try {
      const response: ApiResponse = yield this.loanService.getStateCounties();
      this.allCounties = response.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting state counties.',
      });
    }
  }

  isValidBankruptcyDate(bankruptcyDate) {
    return (
      bankruptcyDate &&
      differenceInYears(startOfToday(), new Date(bankruptcyDate)) >= 3
    );
  }

  isValidForeclosureDate(foreclosureDate) {
    return (
      foreclosureDate &&
      differenceInYears(startOfToday(), new Date(foreclosureDate)) >= 3
    );
  }

  *validateErrors() {
    try {
      const data = this.loanSubmitJson;
      console.log(data);
      const skipLeverageValidations = !isNil(this.editLoanJson.loanId);
      const response: ApiResponse = yield this.loanService.getValidateBridgeLoanErrors(
        data,
        skipLeverageValidations
      );
      const validateErrors = response.data.data.errors;
      const validateWarnings = response.data.data.warnings;
      this.allErrors = [...this.allErrors, ...validateErrors];
      this.allWarnings = [...this.allWarnings, ...validateWarnings];
    } catch (error) {
      console.log(error);
    }
  }

  *validateEntityErrors() {
    try {
      const data = this.loanSubmitJson;
      if (!this.entity.borrowerEntityId) {
        return;
      }
      const response: ApiResponse = yield this.loanService.getValidateEntityLoanErrors(
        this.entity.borrowerEntityId
      );
      const validateErrors = response.data.data.errors;
      this.allErrors = [...this.allErrors, ...validateErrors];
    } catch (error) {
      console.log(error);
    }
  }

  *getRateFloor(data) {
    const userInfo = yield this.user.getUserInformation()
      .then(result => result.data.data.user);
    const response = yield this.stabilizedService.getRateFloor({ ...data, userId: userInfo.userId });
    return response;
  }

  *fetchBorrowerPointsOptions() {
    try {
      const formValues = this.loanInformationStore.getFormValues();
      const { duration, points } = formValues;
      const lenderId = this.globalStore.lenderInfo.lenderId;
      const response = yield this.stabilizedService.calculateBorrowerPointsOptions(
        lenderId,
        duration,
        null,
      ).then(r => r.data);
      this.floorPoints = (response?.length || 0) > 0 ? Number(response[0].value) : 1;
      this.maxPoints = (response?.length || 0) > 0 ? Number(response[response.length-1].value) : 5;
      if(points < this.floorPoints) this.loanInformationStore.onFieldChange('points', this.floorPoints);
      if(points > this.maxPoints) this.loanInformationStore.onFieldChange('points', this.maxPoints);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error occurred while calculating values',
      });
    }
  }

  *sendFastTrackPayment() {
    yield this.loanInformationStore.requestFastTrackPaymentEmail({
      loanId: this.loanInformationStore.draftLoanId,
      borrowers: [...this.borrowersRows],
    });
  }

  get isLoanRequest() {
    return this.draftLoanInfo?.type === REQUEST_LOAN_TYPE_LOAN;
  }

  get isBorrowerLoanApplication() {
    return this.loanApplication.loanApplicationId != null;
  }

  *validateBorrowerErrors() {
    try {
      if (this.borrowersRows.length > 0) {
        const response: ApiResponse = yield this.loanService.getValidateBorrowerLoanErrors({
          borrowerRows: [...this.borrowersRows],
          loanValues: {
            marketingPromotionId: this.loanTerms.marketingPromotionId,
            loanId: this.editLoanJson.loanId
          }
        });
        const validateErrors = response.data.data.errors;
        this.allErrors = [...this.allErrors, ...validateErrors];
      }
    } catch (error) {
      console.log(error);
    }
  }

  loadQuoteDataToStore(quoteData: any) {
    this.loanType = LoanType.RESIDENTIAL_BRIDGE;
    this.loanSubtype = quoteData.loanSubtype;
    this.isContinuingFromQuote = true;
    const isInternal = this.globalStore.lenderInfo?.isInternal;

    const propertyMap: PropertyStbBridge = {
      propertyId: getUniqueId().toString(),
      address: quoteData.propertyAddress,
      aptNumber: quoteData.aptNumber,
      streetNumber: quoteData.streetNumber,
      streetName: quoteData.streetName,
      city: quoteData.city,
      state: quoteData.state,
      zipCode: quoteData.zipCode,
      latitude: quoteData.latitude,
      longitude: quoteData.longitude,
      propertyType: quoteData.propertyType,
      acquisitionPrice: quoteData.acquisitionPrice,
      acquisitionDate: quoteData.purchaseDate ? formatDate(quoteData.purchaseDate, 'MM/dd/yyyy') : null,
      propertyValuation: quoteData.asIsValue,
      capitalImprovements: quoteData.completedCapitalImprovements,
      exitStrategy: quoteData.exitStrategy,
      monthlyGrossRent: quoteData.monthlyRent,
      monthlyMarketRent: quoteData.monthlyRent,
      annualTaxes: quoteData.annualTaxes,
      annualInsurance: quoteData.annualInsurance,
      annualHOA: quoteData.annualHOADues,
      totalDebtPayoff: quoteData.payoffAmount,
      loanPurpose: quoteData.loanPurpose,
      propertyOwnership: quoteData.loanPurpose === REFINANCE
        ? 'Owned By Borrower'
        : PURCHASE,
      anyDebt: quoteData.payoffAmount && quoteData.payoffAmount > 0 ? true : false,
      units: 1,
      occupancy: '',
      totalAnnualUtilities: 0,
      totalAnnualRepairsMaintenance: 0,
      totalAnnualPropertyManagementFees: 0,
      targetLTV: 0,
      totalAnnualNOI: 0,
      totalAnnualIncome: 0,
      totalAnnualExpenses: 0,
      propertyCountiesSelectedValues: [],
    }
    this.properties = [propertyMap];

    this.loanTerms = {
      ...this.loanInformationStore.getFormValues(),
      fullPrincipalInterest: quoteData.fpi === 'Yes',
      initialLoanAmount: quoteData.initialLoanAmount,
      initialLoanToPurchase: quoteData.initialLTCPercentage,
      constructionHoldback: quoteData.constructionHoldback,
      constructionHoldbackPercentage: quoteData.constructionHoldbackPercentage,
      rate: quoteData.borrowerRate,
      points: (quoteData.borrowerPoints && Number(quoteData.borrowerPoints) >= 1) ? quoteData.borrowerPoints : 1,
      brokerPoints: quoteData.brokerPointsAtOrigination || this.loanTerms.brokerPoints,
      interestReserveMonths: quoteData.interestReserveMonths,
      duration: quoteData.loanTermsMonths,
      requestedClosingDate: quoteData.loanClosingDate,
      amount: quoteData.totalLoanAmount,
      lenderUnderwritingFee: isInternal ? (quoteData.underwritingFee || this.loanTerms.lenderUnderwritingFee) : 0,
      lenderProcessingFee: isInternal ? (quoteData.processingFee || this.loanTerms.lenderProcessingFee) : 0,
      externalLenderUnderwritingFee: isInternal ? 0 : (quoteData.underwritingFee || this.loanTerms.externalLenderUnderwritingFee),
      externalLenderProcessingFee: isInternal ? 0 : (quoteData.processingFee || this.loanTerms.externalLenderProcessingFee),
    };
    this.loanInformationStore.initForm(this.loanTerms);
  }
  *getAreBorrowersPreQualifiedFromPrescreenValidations() {
    try {
      this.areBorrowersPreQualified = true;

      const response = yield this.loanService.getAreBorrowersPreQualifiedFromPrescreenValidations(this.loanSubmitJson);
      this.areBorrowersPreQualified = response?.data?.data;
      if (!this.areBorrowersPreQualified) {
        this.allWarnings.push('Your loan will be automatically assigned to our underwriting team once borrower credit and background are received.');
      }

    } catch (error) {
      console.log(error);
    }
  }

  sendOtherValidationEmailError(errorMessage: any) {
    if (errorMessage !== LOAN_WITHOUT_LEAD_SOURCE_VALIDATION) {
      errorMessage += `. For ${this.globalStore.lenderInfo.companyName} with lender id ${this.globalStore.lenderInfo.lenderId}.`;
      this.sendLoanSubmissionErrorEmail(errorMessage);
    }
  }

  setBroker(broker) {
    if (!isNil(broker)) {
      this.brokersStore.selectBrokersStore.brokers = this.brokersStore.selectBrokersStore.brokers.concat({
        ...broker,
        entityName: broker?.company?.name,
        entityTaxNumber: broker?.company?.einNumber,
        brokerId: broker?.lenderId,
        companyId: broker?.company?.companyId,
      });
      this.brokersStore.brokerFormStore.loadForm(broker);
    }
  }

  *getEditLoanJsonById(loanId) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanSummary(
        loanId
      );
      this.reset();
      const data = JSON.parse(response?.data?.data);
      this.editLoanJson = data;
      this.mapEditLoanJsonToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }

  mapEditLoanJsonToStore(editLoanJson: any) {
    const { loanType, loanSubtype } = editLoanJson;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntityForLoanApplication(editLoanJson);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(editLoanJson, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setIsLoanBeingEdit(!isNil(this.editLoanJson.loanId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));
    this.properties = getStbBridgePropertiesFromExistingLoan(editLoanJson);
    this.loanTerms = getBridgeLoanTerms(editLoanJson);
    this.loanInformationStore.initForm(this.loanTerms);
    this.loanInformationStore.otherInsuranceFormStore.loadForm(this.loanTerms.otherInsuranceCompany);
    this.loanInformationStore.otherTitleFormStore.loadForm(this.loanTerms.otherTitleCompany);
    this.findIsAnyPropertyPurchase();
    this.setBroker(editLoanJson?.broker);
  }

  validateProtectedBorrowers() {
    try {
      const borrowers = this.loanSubmitJson.borrowers;

      if (borrowers != null && borrowers.length > 0) {
        borrowers.map(borrower => {
          if (borrower?.protectedBorrower) {
            this.allWarnings.push('The Borrower ' + borrower.fullName + ' currently has loans associated with an active TPO');
          }
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  *rejectLoanApplication() {
    try {
      const data = {
        status: LoanStatus.APPLICATION_REJECTED,
        loanId: this.loanApplication.loanApplicationId
      }
      const response: ApiResponse = yield this.loanService.updateLoanApplicationStatus(
        data
      );
      if (response.data.data.success === true) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan Application status has been updated successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating loan application status',
      });
    }
  }

  setLoanApplicationId = (value: number) => {
    this.loanApplication.loanApplicationId = value;
  };

  *getLoanApplicationById() {
    try {
      const response: ApiResponse = yield this.loanService.getLoanApplicationById(
        this.loanApplication.loanApplicationId
      );
      this.reset();

      const data = response?.data?.data;
      this.loanApplication = data;
      this.mapLoanApplicationToStore(data);
    } catch (error) {
      console.log(error)
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting loan application.',
      });
    }
  }

  mapLoanApplicationToStore(loanApplication: any) {
    const { loanType, loanSubtype, loanId } = loanApplication;
    this.loanType = loanType;
    this.loanSubtype = loanSubtype;
    this.entity = getBorrowerEntityForLoanApplication(loanApplication);
    this.entityStore.setHasEntityInfo(
      isNotBlank(this.entity.name) || !isNil(this.entity.ein)
    );
    const borrowers = getBorrowers(loanApplication, transactionRoles);
    this.setBorrowers(borrowers.filter(b => b.borrowerId));
    this.setNewBorrowers(borrowers.filter(b => !b.borrowerId));
    this.properties = getBridgeProperties(loanApplication);
    this.setLoanApplicationId(loanId);
  }

  get loanApplicationJson() {
    const loanApplication = this.loanSaveJson;

    return {
      ...loanApplication,
      loanBorrowerInformation: {
        ...loanApplication.loanBorrowerInformation,
        borrowerEntity: {
          ...loanApplication.borrowerEntityObj
        },
      },
      loanClosingData: {},
      broker: isNil(loanApplication?.broker) || !isNil(loanApplication?.broker?.company) ? loanApplication?.broker : {
        lenderId: loanApplication?.broker?.brokerId,
        companyId: loanApplication?.broker?.companyId,
        lenderName: loanApplication?.broker?.entityName,
        company: {
          companyId: loanApplication?.broker?.companyId,
          name: loanApplication?.broker?.entityName,
          einNumber: loanApplication?.broker?.entityTaxNumber,
          phone: loanApplication?.broker?.cellPhone,
          email: loanApplication?.broker?.emailAddress,
          contacts: [
            {
              contactId: loanApplication?.broker?.contactId,
              firstName: loanApplication?.broker?.firstName,
              lastName: loanApplication?.broker?.lastName,
              emailAddress: loanApplication?.broker?.emailAddress,
              workPhone: loanApplication?.broker?.cellPhone,
            }
          ]
        }
      }
    };
  }

  *onLoanApplicationSave() {
    try {
      let loan = this.loanApplicationJson;
      loan.marketingPromotionId = null;
      if (this.allErrors.length > 0) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please correct the errors listed above',
        });
      } else {
        this.log(loan, 'loan request');
        const response: ApiResponse = yield this.loanService.submitSpecializedLoanApplicationForBrokerOrLenderOriginator(
          loan
        );
        if (response.data) {
          this.savedLoanId = response.data?.data?.loanId;
        }
        this.requestSaved = true;
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan request.',
      });
    }
    this.saveLoanQuoteForApplication();
  }

  *saveLoanQuoteForApplication() {
    try {
      if (!isNil(this.savedLoanId)) {
        const quoteData = {
          ...getQuoteFromLoanSubmitJson(this.savedLoanId, this.loanSubmitJson),
          lenderId: this.globalStore.lenderInfo.lenderId,
        };
        const response = yield this.oneToolService.saveQuote(quoteData);
        console.log('Got quote with id', response?.data?.data?.quoteId)
      }
      else {
        console.log('Skipping saveLoanQuoteForApplication because loanId is null');
      }
    }
    catch (error) {
      console.log('Error while saving application as loan quote');
      console.log(error);
    }
  }
}
