import { BorrowerBaseService } from './borrowerBaseService';

const url = {
  GET_BORROWER_DATA_COLLECTION: '/api/v1/loan/getBorrowerDataCollection',
  SAVE_BORROWER_DATA_COLLECTION: '/api/v1/loan/saveBorrowerDataCollection',
};
const type = {
  BORROWER_BACKGROUND_REVIEW: 'Borrower Background Review',
  BORROWER_CREDIT_REVIEW: 'Borrower Credit Review'
}

export class BorrowerService extends BorrowerBaseService {
  saveBorrowerDataCollection(borrowerId: number, loanId: number, data: any, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    return this.post(`${url.SAVE_BORROWER_DATA_COLLECTION}?borrowerId=${borrowerId}&loanId=${loanId}&type=${reviewType}`, data);
  }
  saveBorrowerDataCollectionAndCreateResolutions(borrowerId: number, loanId: number, data: any, reviewType = type.BORROWER_BACKGROUND_REVIEW) {
    throw new Error('Method not implemented.');
  }
  getBorrowerDataCollection(borrowerId: number, loanId: any, reviewType = type.BORROWER_CREDIT_REVIEW) {
    return this.get(url.GET_BORROWER_DATA_COLLECTION, { borrowerId, type: reviewType, loanId });
  }
  makeReviewCompleted(loanId: number, borrowerId: number, taskName: string) {
    throw new Error('Method not implemented.');
  }
  getDataCollectionUpdateHistoryByType(borrowerId: number, reviewType = type.BORROWER_CREDIT_REVIEW) {
    throw new Error('Method not implemented.');
  }
  getBorrowerLOE(borrowerId: number, section: string, lineItem: any) {
    throw new Error('Method not implemented.');
  }
  saveAutomatedEvents() { }
}