import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans';
import { observer } from 'mobx-react';
import { CustomCard } from '../customCard';
import { SelectField } from "@roc/ui";

const useStyles = makeStyles((theme) => ({
  selectField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d5d5d5 !important'
    },
  },
}));

export const LoanOriginatorCard = observer(({ readOnly = false }) => {
  const { loanStore } = useLoanStore();
  const classes = useStyles();
  const { loanDetails } = loanStore;

  useEffect(() => {
    loanStore.getLoanParticipants();
    loanStore.loanParticipantsStore.setParticipants(loanDetails);
  }, []);

  const { originatorOptions, selectedOriginator } = loanStore?.loanParticipantsStore;
  const { loanOriginator } = loanDetails;
  const handleOriginatorChange = (originatorId) => {
    const result = originatorOptions.find(({ value }) => value === originatorId);
    loanStore?.loanParticipantsStore.setSelectedOriginator(result.label, originatorId)
    loanStore?.saveLoanParticipants();
  }

  return (
    <CustomCard
      paperNoShadow
      paperStyle={{
        height: '100%',
      }}
      title="Originator"
    >
      <SelectField
        disabled={readOnly}
        required={false}
        standaloneLabel
        className={classes.selectField}
        label=""
        value={selectedOriginator?.originatorId ?? null}
        options={originatorOptions}
        // subLabel={originatorSubLabel}
        onChange={handleOriginatorChange}
        variant="outlined"
        fullWidth
        testId="loanOriginator"
      />
    </CustomCard>
  );
});
