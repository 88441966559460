import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { TodoCard } from '../components/cards/todoCard';
import {
  DocumentForms,
  useDocumentStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { flatten, LoanProcess, SERVICING } from '@roc/feature-utils';
import { Document } from '@roc/feature-types';
import { useEffect } from 'react';

const FILTERED_STATUS = ['Not Applicable'];
const CUSTOM_SORTED_DOCUMENT_SECTIONS = [43];

const applyFilters = (documents: Document[]) => {
  const filteredDocs = documents.filter(
    doc => !FILTERED_STATUS.includes(doc.status)
  );
  if (
    documents.length > 0 &&
    CUSTOM_SORTED_DOCUMENT_SECTIONS.includes(documents[0].sectionId)
  )
    return filteredDocs;
  return filteredDocs.sort((a, b) =>
    a.taskName.localeCompare(b.taskName)
  );
};

const processDocuments = (documentStore, section) => {
  const docs = applyFilters(
    documentStore.documents[SERVICING]?.[section.sectionName] || []
  );
  return docs.map((document: Document) =>
    documentStore.formatDocument(document, SERVICING)
  );
};

export const PostClosingTab = observer(() => {
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { loanDetails } = loanStore;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanId = loanDetails?.loanId;

  const servicingSections = flatten(
    documentStore.displaySections[SERVICING]?.map(item => item.sections) ?? []
  );

  const documents = flatten(
    servicingSections.map(section => processDocuments(documentStore, section))
  );

  const todos = documents.map(doc =>
    documentStore.loanTodosStore.mapDocumentToTodo(doc, LoanProcess.SERVICING)
  );

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, LoanProcess.SERVICING);
    }
  }, [loanId, currentForm]);

  return (
    <>
      <Grid item xs={12}>
        <TodoCard
          title={''}
          showSectionActions
          showFileName
          todos={todos}
          loanProcess={LoanProcess.SERVICING}
        />
        <DocumentForms loanId={loanId?.toString()} />
      </Grid>
    </>
  );
});
