import React, { ReactNode } from "react";
import {
  Box,
  Grid,
  InputLabel,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
} from "@material-ui/core";
import { formatPercentageField } from "../../utils/utils";
interface stylesProps {
  status?: string;
  removeRightBorder?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  badge: ({ status }: stylesProps) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: status === "increasing" ? "#00C41D" : "#B71C1C", // Dark Red
    color: "white",
    borderRadius: "20px",
    paddingLeft: "5px",
    paddingRight: "8px",
    paddingTop: "4px",
    paddingBottom: "5px",
    fontSize: "13px",
    width: "fit-content",
    height: "18px",
  }),
  icon: {
    fontSize: "13px",
    marginRight: "4px",
  },
  description: {
    fontSize: "12px",
  },
  labelBox: ({ removeRightBorder }: stylesProps) => ({
    borderTop: "1px solid #656565",
    borderRight: removeRightBorder ? "" : "1px solid #656565",
    padding: "5px",
  }),
  inputLabel: {
    textAlign: "right",
    fontSize: "10px",
    letterSpacing: 1,
  },
  value: {
    color: "black",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "left",
  },
}));
interface FieldLabelProps {
  value: string;
  removeRightBorder?: boolean;
}
const FieldLabel = ({ value, removeRightBorder }: FieldLabelProps) => {
  const classes = useStyles({ removeRightBorder });
  const formattedValue = value.toUpperCase();
  return (
    <Box className={classes.labelBox}>
      <InputLabel className={classes.inputLabel}>{formattedValue}</InputLabel>
    </Box>
  );
};
interface FieldValueProps {
  children: ReactNode;
}
const FieldValue: React.FC<FieldValueProps> = ({ children }) => {
  const classes = useStyles({});
  return <InputLabel className={classes.value}>{children}</InputLabel>;
};
interface TrendBadgeProps {
  percentage: number;
  description: string;
}
const UpRightArrowIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 18">
      <path
        d="M9.08337 14.2584L6.7417 11.9167L12.05 6.60003H7.92503V3.7417H17.2584V13.075H14.4084V8.95003L9.08337 14.2584Z"
        fill="white"
      />
    </SvgIcon>
  );
};
const DownRightArrowIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 18">
      <path
        d="M9.08337 3.7417L6.7417 6.08337L12.05 11.4H7.92503V14.2584H17.2584V4.92503H14.4084V9.05003L9.08337 3.7417Z"
        fill="white"
      />
    </SvgIcon>
  );
};
const TrendBadge = ({ percentage, description }: TrendBadgeProps) => {
  const status = percentage > 0 ? "increasing" : "decreasing";

  const classes = useStyles({ status });
  const Icon = status === "increasing" ? UpRightArrowIcon : DownRightArrowIcon;

  const absPercentage = formatPercentageField(Math.abs(percentage));
  return (
    <Box display="flex">
      <Box className={classes.badge}>
        <Icon />
        <Typography style={{ fontSize: "13px" }}>{absPercentage}</Typography>
      </Box>
      <Typography className={classes.description}>
        {"\u00A0\u00A0" + description}
      </Typography>
    </Box>
  );
};
interface ReportFieldProps {
  label: string;
  removeRightBorder?: boolean;
  value?: string;
  trend?: {
    percentage: number;
    description: string;
  };
  children?: ReactNode;
}
export const ReportField = ({
  label,
  removeRightBorder,
  value,
  trend,
  children,
}: ReportFieldProps) => {
  return (
    <Grid container direction="column" style={{ height: "100%" }}>
      <Grid item>
        <FieldLabel value={label} removeRightBorder={removeRightBorder} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        {!children && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            style={{ height: "100%", padding: "10px" }}
          >
            <Grid item>
              <FieldValue>{value}</FieldValue>
            </Grid>
            <Grid item>
              {trend && trend.percentage && (
                <TrendBadge
                  percentage={trend.percentage}
                  description={trend.description}
                />
              )}
            </Grid>
          </Grid>
        )}
        {children && children}
      </Grid>
    </Grid>
  );
};