import { Box, Grid, Typography } from '@material-ui/core';
import { formatDate } from '@roc/feature-utils';
import { Button, DialogState } from '@roc/ui';
import {
  createDateField,
  createHtmlField,
  createNumberFormatField,
  createSelectField,
  createTextField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../../hooks/useGeneralTasksStore';
import {
  appraisalTaskTypes,
  generalTaskPriorityOptions,
  generalTaskStatusOptions,
  lineItemsTaskTypes,
  taskTypeOptions,
} from '../../../utils/generalTasksConstants';
import { Attachment } from '../attachment';
import { RelatedTask } from '../relatedTask';
import { TaskAssigneeField } from '../taskAssigneeField';
import { TaskStatusField } from '../taskStatusField';
import { TaskPriorityField } from '../taskPriorityField';
import { TaskWatchField } from '../taskWatchField';
import { useUserStore } from '@roc/feature-app-core';

export const TaskDetails = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, supportTasksStore } = generalTasksStore;
  const {
    attachments,
    comments,
    attachmentStore,
    commentStore,
    dialogState,
    isReadOnly,
    isSubTask,
    task,
  } = editTaskStore;
  const store = editTaskStore;
  const isLoanTask = !!task.attributes.loanTaskId;

  return (
    <Grid container spacing={2}>
      {
        isSubTask &&
        <Grid item xs={12}>
          <Box pb={2}>
            <Typography variant="h6">Parent Task</Typography>
          </Box>
          <Box>
            <RelatedTask taskInfo={task?.parentTask} />
          </Box>
          <Box pt={2}>
            <Typography variant="h6">Task Details</Typography>
          </Box>
        </Grid>
      }
      <Grid item xs={12}>
        {createTextField({
          store,
          testId: 'title',
          fieldName: 'title',
          label: 'Title',
          disabled: isReadOnly,
        })}
      </Grid>

      <Grid item xs={12}>
        {createHtmlField({
          store,
          testId: 'description',
          fieldName: 'description',
          label: 'Description',
          multiline: true,
          minRows: 5,
          disabled: isReadOnly,
        })}
      </Grid>
      {DialogState.EDIT === dialogState && (
        <>
          {!isSubTask && !isLoanTask && !supportTasksStore.openModal && (
            <>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Typography variant="h6">Subtasks</Typography>
                </Box>
                {editTaskStore.task.subtasks.map(subtask => (
                  <Box pt={2}>
                    <RelatedTask taskInfo={subtask} />
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  testId="add-attachment"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    editTaskStore.openAddSubtask();
                  }}
                >
                  Add Subtask
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box pt={2}>
              <Typography variant="h6">Attachments</Typography>
            </Box>
            {attachments.map(attachment => (
              <Box pt={2}>
                <Attachment attachment={attachment} />
              </Box>
            ))}
            {/* {!attachments?.length && <Box pt={2}>No attachments</Box>} */}
          </Grid>
          <Grid item xs={12}>
            <Button
              testId="add-attachment"
              variant="outlined"
              color="primary"
              onClick={() => {
                attachmentStore.openAttachmentDialog();
              }}
            >
              Add Attachment
            </Button>
          </Grid>

        </>
      )}
    </Grid>
  );
});

export const AttributesPanel = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { editTaskStore, assigneeOptions, supportTasksStore, watcherOptions } = generalTasksStore;
  const isLoanView = !!generalTasksStore.loanId;
  const { isReadOnly, task, dialogState, isEscalationTask } = editTaskStore;
  const store = editTaskStore;
  const assigneeStartDate = task?.assignees?.[0]?.assigneeStartDate;

  return (
    <Grid container spacing={2}>
      {((supportTasksStore.openModal && DialogState.EDIT === dialogState) || !supportTasksStore.openModal) &&
        <Grid item xs={12}>
          <TaskStatusField
            label={'Status'}
            value={store.form.fields.status.value}
            onChange={(e, value) => store.onFieldChange('status', value)}
            options={generalTaskStatusOptions}
            disabled={store.isReadOnlyForAppraisalTasks}
          />
        </Grid>
      }
      <Grid item xs={12}>
        <TaskPriorityField
          label={'Priority'}
          value={store.form.fields.priority.value}
          onChange={(e, value) => store.onFieldChange('priority', value)}
          options={generalTaskPriorityOptions}
          disabled={isEscalationTask}
        />
      </Grid>
      {!supportTasksStore.openModal &&
        <Grid item xs={12}>
          <TaskAssigneeField
            label={'Assignee'}
            value={store.form.fields.assigneeId.value}
            onChange={(e, value) => store.onFieldChange('assigneeId', value)}
            assigneeOptions={assigneeOptions}
            //disabled={isReadOnly}
            disabled={false}
          />
        </Grid>
      }
      <Grid item xs={12}>
        {createSelectField({
          store,
          testId: 'taskType',
          fieldName: 'taskType',
          label: 'Category',
          disabled: (isReadOnly || isEscalationTask),
          options: [{ label: 'None', value: '' }, ...taskTypeOptions.filter(opt => {
            if (opt.legacy) return false;
            if (appraisalTaskTypes.includes(opt.value)) return false;
            if (!editTaskStore.task.attributes.loanTaskId && lineItemsTaskTypes.includes(opt.value)) return false;
            if (opt.value === 'ESCALATION') return isEscalationTask;
            return true;
          })]
        })}
      </Grid>
      {!supportTasksStore.openModal &&
        <Grid item xs={12}>
          {createDateField({
            store,
            testId: 'dueDate',
            fieldName: 'dueDate',
            label: 'Due Date',
          })}
        </Grid>
      }
      {!supportTasksStore.openModal &&
        <Grid item xs={12}>
          <TaskWatchField
            label={'Watchers'}
            value={store.form.fields.watchIds.value || []}
            onChange={(event, value) => store.onFieldChange('watchIds', value)}
            assigneeOptions={watcherOptions}
            disabled={false} />
        </Grid>
      }

      <Grid item xs={12}>
        {createNumberFormatField({
          store,
          testId: 'loanId',
          fieldName: 'loanId',
          label: 'Loan Id',
          disabled: isReadOnly || isLoanView,
        })}
      </Grid>
      {DialogState.EDIT === dialogState && !supportTasksStore.openModal &&
        <Grid item xs={12}>
          {createTextField({
            store,
            testId: 'ownerName',
            fieldName: 'ownerName',
            label: 'Reporter',
            disabled: true,
          })}
        </Grid>
      }

      {DialogState.EDIT === dialogState && (
        <>
          <Grid item xs={12}>
            <Typography>
              Created: {formatDate(task?.taskDetails?.createdDate)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Updated: {formatDate(task?.taskDetails?.lastUpdatedDate)}
            </Typography>
          </Grid>
          {assigneeStartDate && (
            <Grid item xs={12}>
              <Typography>
                Assigned Date: {formatDate(assigneeStartDate)}
              </Typography>
            </Grid>
          )}
          {task?.escalationDetails?.lastUpdatedDate && (
            <Grid item xs={12}>
              <Typography>
                Escalated Date: {formatDate(task?.escalationDetails?.lastUpdatedDate)}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
});
