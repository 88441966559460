import { Box } from '@material-ui/core';
import { GetApp, PhotoCamera, UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { LoanProcess } from '@roc/feature-utils';
import { Button, PDFPreview, PrintableArea, PrintableAreaRef, Tooltip } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { StatusFilterField } from './components/common/statusFilterField';
import { VirtualScroll } from './components/common/virtualScroll';
import { StatusFilterType } from './utils/loanDetailsConstants';
import { TodoStatus } from './utils/loanDetailsTypes';
import { useIframeMessaging } from './utils/todoUtils';
import { TaskTodoCardCompponent } from './components/cards/todoTaskCard';

export const UnderwritingInternal = observer(() => {
  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const [filterBy, setFilterBy] = useState(StatusFilterType.IN_REVIEW_OR_PENDING);
  const params = useParams<{ loanId: string }>();
  const loanId = parseInt(params.loanId);
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanProcess = LoanProcess.UNDERWRITING;
  const printableAreaRef = useRef<PrintableAreaRef>(null);
  const [expandAll, setExpandAll] = useState(0);
  const [collapseAll, setCollapseAll] = useState(0);

  const todos =
    documentStore.loanTodosStore.todosByLoanProcess[LoanProcess.UNDERWRITING] ??
    [];

  const loadTodos = () => {
    documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
  };

  useEffect(() => {
    userStore.getUserInfoAndEntitlements();
    loanStore.fetchLoanDetails(loanId);
    loadTodos();
  }, [loanId]);

  useIframeMessaging({
    reloadTodos() {
      loadTodos();
    },
  });

  const download = () => {
    printableAreaRef.current.downloadAsPdf(`Loan ${loanId} Documents.pdf`, {
      singlePage: true,
    });
  };

  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loadTodos();
    }
  }, [currentForm]);

  if (!globalStore.userFeatures) {
    return null;
  }

  const filteredTodos = todos.filter(t => {
    if (filterBy === TodoStatus.ALL) return t;
    if (filterBy === TodoStatus.PENDING) return t.status === filterBy || t.status === TodoStatus.MORE_INFO_NEEDED;
    return t.status === filterBy || filterBy.split(',').includes(t.status);
  });

  const todosWithSubtasks = filteredTodos;

  return (
    <VirtualScroll>
      <Box pb={2} display="flex" justifyContent="flex-end">
        <Box pr={2}>
          <Tooltip title="Expand all sections" placement='bottom'>
            <div>
              <Button testId="expandAll" onClick={() => {
                setExpandAll(x => x + 1);
              }} variant="outlined" style={{
                letterSpacing: 'normal',
                textTransform: 'none',
              }}>
                <Box height={'31px'} display="flex" alignItems="center">
                  <UnfoldMore />&nbsp;&nbsp;Expand All
                </Box>
              </Button>
            </div>
          </Tooltip>
        </Box>
        <Box pr={2}>
          <Tooltip title="Collapse all sections" placement='bottom'>
            <div>
              <Button testId="collapseAll" onClick={() => {
                setCollapseAll(x => x + 1);
              }} variant="outlined" style={{
                letterSpacing: 'normal',
                textTransform: 'none',
              }}>
                <Box height={'31px'} display="flex" alignItems="center">
                  <UnfoldLess />&nbsp;&nbsp;Collapse All
                </Box>
              </Button>
            </div>
          </Tooltip>
        </Box>
        <Box pr={2}>
          <Tooltip title="Take screenshot" placement='bottom'>
            <div>
              <Button testId="downloadAll" onClick={download} variant="outlined" style={{
                letterSpacing: 'normal',
                textTransform: 'none',
              }}>
                <Box height={'31px'} display="flex" alignItems="center">
                  <PhotoCamera />&nbsp;&nbsp;Take Screenshot
                </Box>
              </Button>
            </div>
          </Tooltip>
        </Box>
        <StatusFilterField
          value={filterBy}
          onChange={val => setFilterBy(val as TodoStatus)}
          version="v2"
        />
      </Box>
      <PrintableArea ref={printableAreaRef}>
        <TaskTodoCardCompponent
          loanProcess={LoanProcess.UNDERWRITING}
          todos={todosWithSubtasks}
          showSectionActions
          showDateColumn={true}
          showStatusColumn={true}
          showAssigneeColumn={true}
          showFileName
          expandAll={expandAll}
          collapseAll={collapseAll}
          showDownloadTodo
        />
      </PrintableArea>
      <DocumentForms loanId={loanId?.toString()} />
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </VirtualScroll >
  );
});
