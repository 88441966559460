import { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { LoanCommunicationStore } from '@roc/feature-communication';
import { observer } from 'mobx-react';
import { Badge } from "@material-ui/core";

interface ConversationsFABProps {
  showConversations: boolean;
  loanCommunicationStore: LoanCommunicationStore;
  onOpenConversations: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    fabContent: {
      display: "flex",
      alignItems: "center",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transition: "opacity 0.5s ease-in-out, width 1s ease-in-out",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    fab: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      transition: "width 1s ease-in-out, height 1s ease-in-out",
      height: "56px",
    },
    badge: {
      position: 'fixed',
      right: '30px',
      bottom: '70px',
      '& .MuiBadge-badge': {
        padding: '8px',
        fontSize: '1rem',
      },
    },
    circle: {
      transition: "fill 0.1s ease-in-out",
    },
    conversation: {
      color: "#fff",
      backgroundColor: "#006dff",
      transition: "all .7s",
      position: "fixed",
      bottom: "30px",
      right: "30px",
      "&:hover": {
        backgroundColor: "#0056c9",
      },
    },
    slideInText: {
      animation: "$slide 7s cubic-bezier(0, 0.5, 0, 0.7) forwards;",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    "@keyframes slide": {
      "0%": {
        transform: "translateX(100%)",
        opacity: 1,
      },
      "5%": {
        transform: "translateX(0)",
        opacity: 1,
      },
      "40%": {
        transform: "translateX(0)",
        opacity: 1,
      },
      "100%": {
        transform: "translateX(100%)",
        opacity: 0,
      },
    },
  })
);

export const FloatingActionButtons = observer(({ showConversations, loanCommunicationStore, onOpenConversations }: ConversationsFABProps) => {
  const classes = useStyles();

  const [circlePosition, setCirclePosition] = useState(1);
  const [showAnimation, setShowAnimation] = useState(true);
  const [currentText, setCurrentText] = useState<any>(null);
  const [buttonStyle, setButtonStyle] = useState<any>({});

  useEffect(() => {
    let currentPosition = 1;

    const circleInterval = setInterval(() => {
      setCirclePosition(currentPosition);
      currentPosition = currentPosition === 3 ? 1 : currentPosition + 1;
    }, 300);

    return () => clearInterval(circleInterval);
  }, []);

  useEffect(() => {
    if (currentText === '') {
      setButtonStyle({
        width: "56px",
      });
    }
    else {
      setButtonStyle({
        width: "auto",
      });
    }
  }, [currentText]);

  useEffect(() => {
    const cycleTexts = () => {
      const texts = [
        text2,
        <div
          className={classes.slideInText}
          style={{ color: "white", marginRight: 16, marginLeft: 8 }}
        >
          Have questions about your loan?
        </div>,
        text2,
        <div
          style={{ color: "white", marginRight: 8, marginLeft: 8 }}
        >
          Chat with us
        </div>,
      ];

      let currentIndex = 0;

      setCurrentText(texts[0]);
      setShowAnimation(true);

      const textInterval = setInterval(() => {
        if (currentIndex === 3) {
          setShowAnimation(false);
          setCurrentText(texts[currentIndex]);
          clearInterval(textInterval);
        } else {
          setShowAnimation(currentIndex % 2 === 0); // Only show animation for loading circles and text
          setCurrentText(texts[currentIndex]);
        }
        currentIndex = currentIndex + 1;
      }, 3000);

      return () => clearInterval(textInterval);
    };

    cycleTexts();
  }, [classes]);

  const text2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="25"
      viewBox="0 0 69 25"
      fill="none"
    >
      <circle
        cx="24.0811"
        cy="12.5"
        r="4"
        fill={circlePosition === 1 ? "white" : "#939393"}
        className={classes.circle}
      />
      <circle
        cx="35.0811"
        cy="12.5"
        r="3"
        fill={circlePosition === 2 ? "white" : "#939393"}
        className={classes.circle}
      />
      <circle
        cx="45.0811"
        cy="12.5"
        r="3"
        fill={circlePosition === 3 ? "white" : "#939393"}
        className={classes.circle}
      />
    </svg>
  );

  const chatIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3C6.5 3 2 6.58 2 11C2.05 13.15 3.06 15.17 4.75 16.5C4.75 17.1 4.33 18.67 2 21C4.37 20.89 6.64 20 8.47 18.5C9.61 18.83 10.81 19 12 19C17.5 19 22 15.42 22 11C22 6.58 17.5 3 12 3ZM12 17C7.58 17 4 14.31 4 11C4 7.69 7.58 5 12 5C16.42 5 20 7.69 20 11C20 14.31 16.42 17 12 17Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className={classes.root}>
      <Badge color="error" badgeContent={loanCommunicationStore.totalUnreadMessagesCount || 0} className={classes.badge}>
        <Fab
          variant="extended"
          className={`${classes.fab} ${classes.conversation}`}
          onClick={onOpenConversations}
          style={{
            ...buttonStyle,
            borderRadius: "100px",
            backgroundColor: "#4285F4",
          }}
        >
          <div className={classes.textContainer}>
            {showAnimation ? text2 : currentText}
          </div>
          {chatIcon}
        </Fab>
      </Badge>
    </div>
  );
});

export default FloatingActionButtons;