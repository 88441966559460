import { ApiResponse, GlobalStore, UserService } from '@roc/feature-app-core';
import { debounceRequest, GENERIC_ERROR_MESSAGE, isEmptyObject, isGroundUp, isNil, LoanSubType } from '@roc/feature-utils';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { InstaQuoteRanges } from '../../utils/types';
import { QuoteTypeStore } from '../quoteTypeStore';
import { FeeFormStore } from './feeFormStore';
import { LeverageFormStore } from './leverageFormStore';
import { LoanEconomicsFormStore } from './loanEconomicsFormStore';
import { feeOptions, MIN_FINANCED_IR_TOTAL_PURCHASE_PRICE } from '../../utils/constants';
import { FixFlipGroundUpService } from 'libs/feature-loans/src/loanSubmission/fixflipGroundUp/Services/fixflipGroundUpService';
import { sum } from '../../utils/utils';
export abstract class LoanEconomicsStore {
  protected globalStore: GlobalStore;
  protected oneToolSevice: OneToolService;
  private user: UserService;
  private fixflipGroundUpService: FixFlipGroundUpService;
  public rateAdjustmentsData: any;

  quoteTypeStore: QuoteTypeStore;

  feeStores: FeeFormStore[];
  loanEconomicsFormStore: LoanEconomicsFormStore;

  instaLeverageQuoteFormStore: LeverageFormStore;
  manualExceptionQuoteFormStore: LeverageFormStore;

  enableManualQuote: boolean;
  instaQuoteRanges: InstaQuoteRanges;
  interestReserveFinanced: boolean;
  showFinancedInterestReserve: boolean;
  userInputOnLeverage: boolean;

  instaInitialLoanAmount: number;
  validatedInitialLoanAmount: number;
  instaConstructionHoldback: number;
  manualInitialLoanAmount: number;
  manualConstructionHoldback: number;
  interestType: String;

  floorRate: number;
  floorPoints: number;
  maxPoints: number;

  outputs: any;

  defaultFees: any;

  abstract caclulateFields(
    properties: any[],
    leverage: any
  ): Record<string, number>;

  abstract mapCalculatedFields(): Record<string, number>;

  abstract calculateKeyRatios();

  abstract validateLeverageAmounts();

  fetchQuoteOutputsDebounced = debounceRequest(cancelToken =>
    this.fetchQuoteOutputs(cancelToken)
  );

  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.oneToolSevice = new OneToolService();
    this.user = new UserService();
    this.fixflipGroundUpService = new FixFlipGroundUpService();

    this.loanEconomicsFormStore = new LoanEconomicsFormStore(globalStore);
    this.instaLeverageQuoteFormStore = new LeverageFormStore(
      globalStore,
      this,
      true
    );
    this.manualExceptionQuoteFormStore = new LeverageFormStore(
      globalStore,
      this,
      false
    );
    this.userInputOnLeverage = false;

    makeObservable(this, {
      reset: action,
      feeStores: observable,
      enableManualQuote: observable,
      instaQuoteRanges: observable,
      floorRate: observable,
      floorPoints: observable,
      maxPoints: observable,
      instaInitialLoanAmount: observable,
      validatedInitialLoanAmount: observable,
      instaConstructionHoldback: observable,
      manualInitialLoanAmount: observable,
      manualConstructionHoldback: observable,
      outputs: observable,
      defaultFees: observable,
      getRateFloor: observable,
      rateAdjustmentsData: observable,
      interestType: observable,
      caclulateFields: action,
      mapCalculatedFields: action,
      calculateLeverageInfo: action,
      calculateKeyRatios: action,
      setEnableManualQuote: action,
      fecthInstaQuoteRanges: flow,
      fetchFloorRate: flow,
      fetchBorrowerPointsOptions: flow,
      fetchQuoteOutputs: flow,
      fetchDefaultFees: flow,
      calculatedFields: computed,
      loanLenderDetails: computed,
      getCurrentFeesOptions: action,
      loadLoanEconomics: action,
      getLoanEconomics: action,
      showFinancedInterestReserve: observable,
      interestReserveFinanced: observable,
      setAddFinancedInterestReserve: action,
      validateAllFees: action,
      validateLeverageAmounts: action,
      checkPropertyFormHash: action,
      validateLoanAmount: action,
      userInputOnLeverage: observable,
      setUserInputOnLeverage: action,
      isLTCAvailable: action,
      setInterestType: action,
      loadManualRangesIfNoCurrentValues: action,
      checkFinancedInterestReserveRules: action,
    });
  }

  reset() {
    this.enableManualQuote = false;
    this.instaQuoteRanges = null;
    this.showFinancedInterestReserve = false;
    this.interestReserveFinanced = false;
    this.floorRate = 10;
    this.floorPoints = 1;
    this.maxPoints = 5;
    this.feeStores = [];
    this.outputs = null;
    this.addNewFee();
    this.rateAdjustmentsData = {};
    this.instaLeverageQuoteFormStore.reset();
    this.manualExceptionQuoteFormStore.reset();
    this.userInputOnLeverage = false;
    this.instaInitialLoanAmount = 0;
    this.validatedInitialLoanAmount = 0;
    this.instaConstructionHoldback = 0;
    this.manualInitialLoanAmount = 0;
    this.manualConstructionHoldback = 0;
    this.interestType = '';
  }

  setEnableManualQuote(enableManualQuote) {
    this.enableManualQuote = enableManualQuote;
  }

  setAddFinancedInterestReserve(value) {
    this.interestReserveFinanced = value;
    //change fields to 0 so that they will be set by default with the new max value allowed
    this.instaLeverageQuoteFormStore.onFieldChange('loanToCost', 0);
    this.instaLeverageQuoteFormStore.onFieldChange('constructionReserve', 0);
    // we need to re fetch as interest financed adds
    // interest expense to the cost basis
    this.fecthInstaQuoteRanges();
  }


  *getRateFloor(data) {
    const userInfo = yield this.user.getUserInformation().then(result => result.data.data.user);
    const loanData = this.quoteTypeStore.getQuoteData();
    let extraPayload = {};
    if (isGroundUp(loanData?.loanSubtype) && this.globalStore.lenderInfo?.isInternal) {

      const loanTerm = parseInt(loanData.loanEconomics.loanTermMonths) || 12;
      let biggestExperience = this.getHighestExperience(loanData.borrowers);
      let biggestFicoInfo = this.getHighestFICOInfo(loanData.borrowers);
      extraPayload = {
        rateAdjustmentsRequestFields: {
          experience: biggestExperience,
          fico: biggestFicoInfo.fico,
          ficoBorrowerId: !isNaN(biggestFicoInfo.borrowerId) ? biggestFicoInfo.borrowerId : null,
          transactionType: loanData?.properties[0]?.loanPurpose,
          completedRenovations: loanData?.properties[0]?.constructionRenoSpendToDate || 0,
          leverage: data?.leverage || 0,
          loanTerm,
          totalRenoBudget: this.calculateTotalRenovationBudget(loanData.properties),
        },
        internalLender: this.globalStore.lenderInfo?.isInternal
      }
    }
    const response = yield this.fixflipGroundUpService.getRateFloor({
      ...data,
      userId: userInfo.userId,
      ...extraPayload,
    });
    this.rateAdjustmentsData = response.data;
    this.floorRate = response.data?.floorRate || 10.99;
    if (this.floorRate > this.loanEconomicsFormStore?.form?.fields?.interestRate?.value) {
      this.loanEconomicsFormStore.onFieldChange('interestRate', this.floorRate);
    }

    return { data: response.data?.floorRate || 0 };
  }

  private calculateTotalRenovationBudget(properties) {
    let total = 0;
    let renoBudget = 0;
    if (properties.length > 0) {
      properties.forEach(value => {
        console.log('property', value);
        if (value.loanPurpose === 'Purchase') {
          renoBudget = isNaN(Number(value.renovationBudget))
            ? 0
            : Number(value.renovationBudget);

          total += renoBudget;
        } else {
          renoBudget = isNaN(Number(value.renovationBudget))
            ? 0
            : Number(value.renovationBudget);

          total += renoBudget;
        }
      });
    }
    return total;
  }

  getHighestFICOInfo(borrowers) {
    let personalGuarantorBorrowers = borrowers.filter(borr => borr.personalGuarantor);
    if (personalGuarantorBorrowers === null || (personalGuarantorBorrowers && personalGuarantorBorrowers.length === 0)) {
      personalGuarantorBorrowers = borrowers; // if there are not PG just take the highest fico
    }
    let ficoInfo = personalGuarantorBorrowers.reduce((currentFicoInfo, borr) => {
      if (borr.ficoScore > currentFicoInfo.fico) {
        return { fico: borr.ficoScore, borrowerId: Number(borr.borrowerId) };
      }
      return currentFicoInfo;
    }, { fico: 0, borrowerId: 0 });

    return ficoInfo;
  }

  getHighestExperience(borrowers) {
    let experience = borrowers.reduce((currentExp, borr) => {
      if (borr.numberOfVerifiedInvestmentProperties > currentExp) {
        return borr.numberOfVerifiedInvestmentProperties;
      }
      return currentExp;
    }, 0);

    if (!experience) {
      experience = borrowers.reduce((currentExp, borr) => {
        if (borr.experience > currentExp) {
          return borr.experience;
        }
        return currentExp;
      }, 0);
    }
    return experience;
  }


  *fecthInstaQuoteRanges() {
    try {
      const quoteData = this.quoteTypeStore.getQuoteData();
      const response = yield this.oneToolSevice.calculateInstaQuoteRanges(
        {
          ...quoteData,
          interestReserveFinanced: this.interestReserveFinanced
        }
      );
      this.instaQuoteRanges = response.data.data;
      if (!this.instaQuoteRanges?.maxLoanToCost && !this.enableManualQuote) {
        this.setEnableManualQuote(true);
      }
      this.instaLeverageQuoteFormStore.loadRanges(this.instaQuoteRanges);
      this.loadManualRangesIfNoCurrentValues();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  loadManualRangesIfNoCurrentValues() {
    const manualQuoteleverageData = this.manualExceptionQuoteFormStore.getFormValues();

    if ((!manualQuoteleverageData.loanToCost && !manualQuoteleverageData.constructionReserve) || !this.enableManualQuote) {
      this.manualExceptionQuoteFormStore.loadRanges(this.instaQuoteRanges);
    }
  }

  *fetchFloorRate() {
    try {
      const quoteData = this.quoteTypeStore.getQuoteData();
      const lenderId = this.globalStore.lenderInfo.lenderId;
      const response = yield this.oneToolSevice.calculateFloorRate(
        quoteData,
        lenderId
      );
      this.floorRate = response.data.data;
      this.loanEconomicsFormStore.loadFloorRate(this.floorRate);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchBorrowerPointsOptions() {
    try {
      const quoteData = this.quoteTypeStore.getQuoteData();
      const { loanTermMonths } = quoteData.loanEconomics;
      const lenderId = this.globalStore.lenderInfo.lenderId;
      const response = yield this.oneToolSevice.calculateBorrowerPointsOptions(
        lenderId,
        loanTermMonths,
        null,
      ).then(r => r.data);
      this.floorPoints = (response?.length || 0) > 0 ? response[0].value : 1;
      this.maxPoints = (response?.length || 0) > 0 ? response[response.length-1].value : 5;
      this.loanEconomicsFormStore.loadPoints(this.floorPoints, this.maxPoints);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchQuoteOutputs(cancelToken?) {
    try {
      const quoteData = this.quoteTypeStore.getQuoteData();
      const { loanToCost, constructionReserve } = quoteData.loanEconomics;
      if (
        (this.quoteTypeStore.loanSubtype !== LoanSubType.STABILIZED_BRIDGE && (loanToCost !== 0 || constructionReserve !== 0)) ||
        (this.quoteTypeStore.loanSubtype === LoanSubType.STABILIZED_BRIDGE && loanToCost !== 0)) {
        const response = yield this.oneToolSevice.calculateQuote(
          quoteData,
          cancelToken
        );
        this.outputs = {
          ...response,
          interestReserveFinanced: this.interestReserveFinanced
        };
        this.loanEconomicsFormStore.onFieldChange("capitalProviderPoints", this.outputs?.bridgePricerResponseFields?.rocPoints);
        this.loanEconomicsFormStore.onFieldChange("lenderPoints", this.outputs?.bridgePricerResponseFields?.yourPoints);
      }
    } catch (e) {
      if (!e.isCancel) {
        this.globalStore.notificationStore.showErrorNotification({
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
  }

  *fetchDefaultFees() {
    try {
      const response = yield this.oneToolSevice.getDefaultFees();
      const fees = JSON.parse(response.data.data)?.rows;
      this.feeStores = fees
        .filter(fee => fee.tpoFeeAmount > 0 && !!feeOptions.find(opt => opt.value == fee.tpoFeeLabel))
        .map(fee => {
          const form = new FeeFormStore(this.globalStore, this);
          form.onFieldChange('usePercentage', false);
          form.onFieldChange('name', fee.tpoFeeLabel);
          form.onFieldChange('lenderAmount', fee.tpoFeeAmount);
          form.onFieldChange('capitalProviderAmount', fee.rocFeeAmount);
          return form;
        });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  getCurrentFeesOptions(currentFeeStore) {

    const availableFeeOptions = this.globalStore.lenderInfo?.isInternal ?
      feeOptions.filter(fee => !['Buy Down Fee', 'Commitment Fee', 'Admin Fee'].includes(fee.value)) : feeOptions;

    const newFeesOptions = availableFeeOptions.filter(feeOption => {
      const feeStore = this.feeStores.find(feeStore => {
        return feeStore.form.fields.name.value == feeOption.label;
      })
      return isNil(feeStore);
    });
    return currentFeeStore.form.fields.name.value != '' ? [...newFeesOptions, { label: currentFeeStore.form.fields.name.value, value: currentFeeStore.form.fields.name.value }] : newFeesOptions;
  }

  getRemainingFeesOptions(currentFeeStore) {
    const newFeesOptions = feeOptions.filter(feeOption => {
      const feeStore = this.feeStores.find(feeStore => {
        return feeStore.form.fields.name.value == feeOption.label;
      })
      return isNil(feeStore);
    });

    return currentFeeStore.form.fields.name.value != '' ? [...newFeesOptions, { label: currentFeeStore.form.fields.name.value, value: currentFeeStore.form.fields.name.value }] : newFeesOptions;
  }

  loadLoanEconomics(data) {
    if (!isNil(data)) {
      this.enableManualQuote = data.enableManualQuote;
      if (this.enableManualQuote) {
        this.manualExceptionQuoteFormStore.loadForm({
          loanToCost: data.manualQuoteLoantoCost,
          constructionReserve: data.manualQuoteConstructionReserve,
        });
      }
      this.instaLeverageQuoteFormStore.loadForm(data);
      this.userInputOnLeverage = this.enableManualQuote;
      this.instaQuoteRanges = {
        maxLoanToCost: data.maxLeverageFromMatrix
      } as any;

      this.loanEconomicsFormStore.loadForm(data);
      this.feeStores = data?.fees.map(fee => {
        const form = new FeeFormStore(this.globalStore, this);
        form.loadForm(fee);
        return form;
      });
    }
    else {
      this.reset();
    }
  }

  getLoanEconomics() {
    const instaQuoteleverageData = this.instaLeverageQuoteFormStore.getFormValues();
    const manualQuoteleverageData = this.manualExceptionQuoteFormStore.getFormValues();

    const isManualLeverageRequired = this.quoteTypeStore.isManualLeverageRequired(this.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount || 0);

    const leverageData = isManualLeverageRequired
      ? manualQuoteleverageData
      : instaQuoteleverageData;

    //
    this.calculateLeverageInfo(this.quoteTypeStore.propertiesStore.getProperties(), instaQuoteleverageData, manualQuoteleverageData);
    const loanEconomicsData = this.loanEconomicsFormStore.getFormValues();
    const fees = this.feeStores
      .filter(form => !form.isEmpty)
      .map(form => form.getFeeValues());
    return {
      ...this.mapCalculatedFields(),
      isManualLeverageRequired: isManualLeverageRequired,
      instaInitialLoanAmount: this.instaInitialLoanAmount,
      instaConstructionHoldback: this.instaConstructionHoldback,
      manualInitialLoanAmount: this.manualInitialLoanAmount,
      manualConstructionHoldback: this.manualConstructionHoldback,
      instaQuoteLoantoCost: instaQuoteleverageData.loanToCost,
      instaQuoteConstructionReserve: instaQuoteleverageData.constructionReserve,
      manualQuoteLoantoCost: manualQuoteleverageData.loanToCost,
      manualQuoteConstructionReserve: manualQuoteleverageData.constructionReserve,
      maxLeverageFromMatrix: this.instaQuoteRanges?.maxLoanToCost,
      enableManualQuote: this.enableManualQuote,
      interestReserveFinanced: this.interestReserveFinanced,
      fees,
      interestMethod: this.interestType,
      ...leverageData,
      ...loanEconomicsData,
    };
  }

  setInterestType() {
    console.log(this.quoteTypeStore.loanSubtype)
    if (this.quoteTypeStore.loanSubtype === LoanSubType.STABILIZED_BRIDGE) {
      this.interestType = 'Interest Only'
    } else {
      this.interestType = this.totalLoanAmount >= 100000 ? 'As disbursed' : 'Full Boat';
    }
  }

  get calculatedFields() {
    return this.quoteTypeStore.isManualLeverageRequired(this.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount || 0)
      ? this.manualExceptionQuoteFormStore.calculatedFields
      : this.instaLeverageQuoteFormStore.calculatedFields;
  }

  addNewFee() {
    if (this.feeStores.length < feeOptions.length - 1) {
      this.feeStores.push(new FeeFormStore(this.globalStore, this));
    }
  }


  removeFee(feeKey: string) {
    this.feeStores = this.feeStores.filter(fee => fee.form.meta.key !== feeKey);
  }

  get totalLoanAmount() {
    const isManualLeverageRequired = this.quoteTypeStore.isManualLeverageRequired(this.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount || 0);
    return isManualLeverageRequired ? this.manualExceptionQuoteFormStore?.calculatedFields?.totalLoanAmount : this.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount;
  }

  validateAllFees() {
    const brokerFormValues = this.quoteTypeStore.brokerStore.brokerFormStore.getFormValues()
    this.quoteTypeStore.brokerStore.brokerFormStore.runFormValidation()
    if (!this.quoteTypeStore.brokerStore.brokerFormStore.form.meta.isValid) {
      if (brokerFormValues.hasBroker !== null) {
        if (brokerFormValues.hasBroker === true) {
          if (brokerFormValues.brokerPoints === "") {
            this.globalStore.notificationStore.showErrorNotification({
              message: 'Broker points are required',
            });
            return false;
          }
          if (this.globalStore?.lenderInfo?.isInternal && !brokerFormValues.brokerType) {
            this.globalStore.notificationStore.showErrorNotification({
              message: 'Please fill out all the required fields',
            });

            return false
          }
        }
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Please fill out all the required fields',
        });
        return false;
      }
    }

    const loanEconomicsForm = this.quoteTypeStore.loanEconomicsStore.loanEconomicsFormStore.getFormValues();
    const isRetail = this.globalStore.lenderInfo?.isInternal;
    if (brokerFormValues.hasBroker &&
      (
        // wholesale case
        (!isRetail && (brokerFormValues.brokerPoints > ((loanEconomicsForm.lenderPoints || 0) + (loanEconomicsForm.capitalProviderPoints || 0)))) ||
        // retail case
        (isRetail && (brokerFormValues.brokerPoints > (loanEconomicsForm.capitalProviderPoints || 0)))
      )) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Broker points cannot exceed origination points',
      });

      return false;
    }

    const brokerPoints = brokerFormValues.brokerPoints ? +brokerFormValues.brokerPoints : 0;
    const totalPoints = loanEconomicsForm.totalPoints ? +loanEconomicsForm.totalPoints : 0;
    if ((brokerPoints + totalPoints) > 5) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Maximum total points allowable is 5',
      });

      return false;
    }

    if (this.globalStore?.lenderInfo?.isInternal) {
      let totalFees = 0;
      this.feeStores.forEach(feeStore => {
        totalFees += feeStore.form.fields.capitalProviderAmount.value;
      });
      if (this.totalLoanAmount < 500000 && totalFees < 595) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $595.',
        });
        return false;
      }
      if (this.totalLoanAmount >= 500000 && this.totalLoanAmount < 1000000 && totalFees < 895) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $895.',
        });
        return false;
      }
      if (this.totalLoanAmount >= 1000000 && this.totalLoanAmount < 2000000 && totalFees < 1295) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $1,295.',
        });
        return false;
      }
      if (this.totalLoanAmount >= 2000000 && this.totalLoanAmount < 3000000 && totalFees < 1995) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $1,995.',
        });
        return false;
      }
      if (this.totalLoanAmount >= 3000000 && this.totalLoanAmount < 4000000 && totalFees < 3495) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $3,495.',
        });
        return false;
      }
      if (this.totalLoanAmount >= 4000000 && this.totalLoanAmount < 5000000 && totalFees < 4495) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Total Fees for this loan must be greater than or equal to $4,495.',
        });
        return false;
      }
    }
    if (loanEconomicsForm.capitalProviderPoints <= 0) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Minimum points cannot be 0',
      });
      return false;
    }
    return true;
  }

  get loanLenderDetails() {
    const loanEconomicsFormStoreValues = this.loanEconomicsFormStore.getFormValues();
    let fees = {};
    this.feeStores.forEach(feeStore => {
      const fields = feeStore.form.fields;
      if (fields.name.value == 'Processing Fee') {
        fees = {
          ...fees,
          processingFee: fields.lenderAmount.value,
          rocProcessingFee: fields.capitalProviderAmount.value,
        }
      }
      else if (fields.name.value == 'Underwriting Fee') {
        fees = {
          ...fees,
          underwritingFee: fields.lenderAmount.value,
          rocUnderwritingFee: fields.capitalProviderAmount.value,
        }
      }
      else if (fields.name.value == 'Admin Fee') {
        fees = {
          ...fees,
          adminFee: fields.lenderAmount.value,
        }
      }
      else if (fields.name.value == 'Commitment Fee') {
        fees = {
          ...fees,
          commitmentFee: fields.lenderAmount.value,
        }
      }
      else if (fields.name.value == 'Buy Down Fee') {
        fees = {
          ...fees,
          lenderBuydownFee: fields.lenderAmount.value,
        }
      }
    })
    return {
      originationPointsTpo: loanEconomicsFormStoreValues.lenderPoints,
      originationPointsRoc: loanEconomicsFormStoreValues.capitalProviderPoints,
      ...fees,
    };
  }

  validateLoanAmount() {
    const errors = [];
    const totalLoanAmount = this.enableManualQuote ? this.manualExceptionQuoteFormStore?.calculatedFields?.totalLoanAmount : this.instaLeverageQuoteFormStore?.calculatedFields?.totalLoanAmount;

    if (totalLoanAmount < 50000) {
      errors.push('Unfortunately, due to our loan amount minimum of $50,000, we cannot offer terms here.')
    }

    return errors;
  }

  checkPropertyFormHash(propertyFormHash: string) {
    const instaQuoteTotalPropertyFormHash = this.instaLeverageQuoteFormStore.propertyFormHash;
    const manualQuoteTotalPropertyFormHash = this.manualExceptionQuoteFormStore.propertyFormHash;

    if (instaQuoteTotalPropertyFormHash !== propertyFormHash) {
      this.instaLeverageQuoteFormStore.setPropertyFormHash(propertyFormHash);
    }

    if (manualQuoteTotalPropertyFormHash !== propertyFormHash) {
      this.manualExceptionQuoteFormStore.setPropertyFormHash(propertyFormHash);
    }
  }

  setUserInputOnLeverage(value: boolean) {
    this.userInputOnLeverage = value;
  }

  calculateLeverageInfo(properties: any[], instaLeverage: any, manualLeverage: any) {
    //Leverage
    const totalPurchasePrice = sum(properties.map(p => p.purchasePrice));
    const totalProposedConstructionBudget = sum(
      properties.map(p => p.renovationBudget)
    );
    const totalAfterRepairValue = sum(
      properties.map(p => p.marketValueAfterCompletion)
    );

    const instaInitialAdvance = totalPurchasePrice * (instaLeverage.loanToCost / 100);
    const instaConstructionHoldback = totalProposedConstructionBudget * (instaLeverage.constructionReserve / 100);
    const instaTotalLoanAmount = instaInitialAdvance + instaConstructionHoldback;
    const instaAfterRepairLTV = (instaTotalLoanAmount / totalAfterRepairValue) * 100;

    const manualInitialAdvance = totalPurchasePrice * (manualLeverage.loanToCost / 100);
    const manualConstructionHoldback = totalProposedConstructionBudget * (manualLeverage.constructionReserve / 100);
    const manualTotalLoanAmount = manualInitialAdvance + manualConstructionHoldback;
    const manualAfterRepairLTV = (manualTotalLoanAmount / totalAfterRepairValue) * 100;


    this.manualInitialLoanAmount = this.validatedInitialLoanAmount ? this.validatedInitialLoanAmount : manualInitialAdvance;;
    this.manualConstructionHoldback = manualConstructionHoldback;

    this.instaInitialLoanAmount = this.validatedInitialLoanAmount ? this.validatedInitialLoanAmount : instaInitialAdvance;
    this.instaConstructionHoldback = instaConstructionHoldback;
  }

  checkFinancedInterestReserveRules(totalPurchasePrice: number) {
    const meetsMinPurchasePrice =
    totalPurchasePrice >= MIN_FINANCED_IR_TOTAL_PURCHASE_PRICE;
    this.showFinancedInterestReserve = meetsMinPurchasePrice;
    if (!this.showFinancedInterestReserve) {
      this.interestReserveFinanced = false;
    }
  }

  isLTCAvailable() {
    if (!this.instaQuoteRanges?.maxLoanToCost) {
      return false;
    }
    return true;
  }
}
