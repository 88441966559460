import { makeStyles, Theme } from '@material-ui/core/styles';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { SubmissionVsUnderwritingBaseStore } from '../common/store/submissionVsUnderwritingBaseStore';
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import LoanSummaryExpandableTable from '../common/loanSummaryExpandableTable';
import { insertIf, LoanType, LoanSubType, isNil, formatDate } from '@roc/feature-utils';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  differentValues: {
    fontWeight: 'bold',
    fontSize: '1rem',
  }
}));


interface SubmissionVsUnderwritingFormProps {
  store: SubmissionVsUnderwritingBaseStore;
  loanId: Number;
  isInternal: Boolean;
  loanDetails: any;
}


export const SubmissionVsUnderwritingValues = observer(({ store, loanId, isInternal, loanDetails }: SubmissionVsUnderwritingFormProps) => {
  const [data, setData] = useState([])
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanSummary } = loanSummaryStore;
  const isBridge = loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE;
  const isTerm = loanSummary?.loanType === LoanType.RESIDENTIAL_TERM;

  useEffect(() => {
    if (isInternal) {
      store.getSubmissionVsUnderwriting(loanId, null);
    } else {
      loanSummaryStore.fetchLoanSummary(loanId);
      store.getSubmissionVsUnderwriting(loanId, loanDetails);
    }
  }, []);

  const classes = useStyles();
  let style = isInternal ? { width: '100%', borderRadius: '0' } : {};
  const primaryProperty = store?.primaryProperty ?? loanSummaryStore?.loanSummary?.primaryProperty;
  const calculatedLoanData = store?.calculatedLoanData;
  const loanComparisonValues = store?.loanComparisonValues;


  const formatLtvRate = (ltv, rate) => {
    let response = '';

    if (ltv) {
      response = `${ltv}% LTV / ${rate ? `${rate}%` : '-'}`;
    } else if (rate) {
      response = `- / ${rate}%`
    }

    return response;
  }
  useEffect(() => {
    if (isBridge) {
      setData([
        {
          left: 'Borrower FICO',
          right:
          {
            column1: loanComparisonValues?.submittedBorrowerFICO
              ? loanComparisonValues?.submittedBorrowerFICO
              : '-',
            column2: calculatedLoanData?.maxBorrowerFICOScore
              ? calculatedLoanData?.maxBorrowerFICOScore
              : '-'
          }
        },
        {
          left: 'Experience Score',
          right:
          {
            column1: loanComparisonValues?.submittedBorrowerExperience
              ? loanComparisonValues?.submittedBorrowerExperience
              : '-',
            column2: calculatedLoanData?.maxExperienceScore
              ? calculatedLoanData?.maxExperienceScore
              : '-'
          }
        },
        {
          left: 'Loan Amount',
          right:
          {
            column1: loanComparisonValues?.submittedLoanAmount
              ? formatCurrency(loanComparisonValues?.submittedLoanAmount)
              : '-',
            column2: store?.amount
              ? formatCurrency(store?.amount)
              : '-'
          }
        },
        {
          left: 'Purchase price',
          right:
          {
            column1: loanComparisonValues?.submittedPurchasePrice
              ? formatCurrency(loanComparisonValues?.submittedPurchasePrice)
              : '-',
            column2: calculatedLoanData?.aggregatePurchasePrice ? formatCurrency(calculatedLoanData?.aggregatePurchasePrice) : '-'
          }
        },
        {
          left: 'Renovation Budget',
          right:
          {
            column1: loanComparisonValues?.submittedConstructionHoldback
              ? formatCurrency(loanComparisonValues?.submittedConstructionHoldback)
              : '-',
            column2: calculatedLoanData?.aggregateRenovationBudget ? formatCurrency(calculatedLoanData?.aggregateRenovationBudget) : '-'
          }
        },
        {
          left: 'As-is value',
          right:
          {
            column1: loanComparisonValues?.submittedAsIsValue
              ? formatCurrency(loanComparisonValues?.submittedAsIsValue)
              : '-',
            column2: calculatedLoanData?.aggregateAsIsValue ? formatCurrency(calculatedLoanData?.aggregateAsIsValue) : '-'
          }
        },
        {
          left: 'ARV',
          right:
          {
            column1: loanComparisonValues?.submittedLoanARV
              ? formatCurrency(loanComparisonValues?.submittedLoanARV)
              : '-',
            column2: calculatedLoanData?.aggregateAfterRepairValue ? formatCurrency(calculatedLoanData?.aggregateAfterRepairValue) : '-'
          }
        },
        {
          left: 'Sourcing',
          right:
          {
            column1: loanComparisonValues?.submittedSourcing
              ? loanComparisonValues?.submittedSourcing
              : '-',
            column2: calculatedLoanData?.sourcing || '-'
          }
        },
        {
          left: 'Wholesaler Fee',
          right:
          {
            column1: loanComparisonValues?.submittedWholesalerFee
              ? formatCurrency(loanComparisonValues?.submittedWholesalerFee)
              : '-',
            column2: calculatedLoanData?.aggregateWholesalerFee ? formatCurrency(calculatedLoanData?.aggregateWholesalerFee) : '-'
          }
        },
        {
          left: 'Seller Credit',
          right:
          {
            column1: loanComparisonValues?.submittedSellerCreditAmount
              ? formatCurrency(loanComparisonValues?.submittedSellerCreditAmount)
              : '-',
            column2: calculatedLoanData?.sellerCreditAmount ? formatCurrency(calculatedLoanData?.sellerCreditAmount) : '-'
          }
        },
      ])
    }
    if (isTerm) {
      setData([
        {
          left: 'Borrower FICO',
          right:
          {
            column1: loanComparisonValues?.submittedBorrowerFICO
              ? loanComparisonValues?.submittedBorrowerFICO
              : '-',
            column2: calculatedLoanData?.maxBorrowerFICOScore
              ? calculatedLoanData?.maxBorrowerFICOScore
              : '-'
          }
        },
        {
          left: 'Loan Amount',
          right:
          {
            column1: loanComparisonValues?.submittedLoanAmount
              ? formatCurrency(loanComparisonValues?.submittedLoanAmount)
              : '-',
            column2: store?.amount
              ? formatCurrency(store?.amount)
              : '-'
          }
        },
        {
          left: 'Purchase price',
          right:
          {
            column1: loanComparisonValues?.submittedPurchasePrice
              ? formatCurrency(loanComparisonValues?.submittedPurchasePrice)
              : '-',
            column2: calculatedLoanData?.aggregateAcquisitionPrice ? formatCurrency(calculatedLoanData?.aggregateAcquisitionPrice) : '-'
          }
        },
        {
          left: 'As-is value',
          right:
          {
            column1: loanComparisonValues?.submittedAsIsValue
              ? formatCurrency(loanComparisonValues?.submittedAsIsValue)
              : '-',
            column2: calculatedLoanData?.aggregateAppraisedAsIsValue ? formatCurrency(calculatedLoanData?.aggregateAppraisedAsIsValue) : '-'
          }
        },
        {
          left: 'Monthly Gross Rent',
          right:
          {
            column1: loanComparisonValues?.submittedMonthlyGrossRent
              ? formatCurrency(loanComparisonValues?.submittedMonthlyGrossRent)
              : '-',
            column2: calculatedLoanData?.aggregateMonthlyGrossRent ? formatCurrency(calculatedLoanData?.aggregateMonthlyGrossRent) : '-'
          }
        },
        {
          left: 'Monthly Market Rent',
          right:
          {
            column1: loanComparisonValues?.submittedMonthlyMarketRent
              ? formatCurrency(loanComparisonValues?.submittedMonthlyMarketRent)
              : '-',
            column2: calculatedLoanData?.aggregateMonthlyMarketRent ? formatCurrency(calculatedLoanData?.aggregateMonthlyMarketRent) : '-'
          }
        },
        {
          left: 'Annual Insurance',
          right:
          {
            column1: loanComparisonValues?.submittedAnnualInsurance
              ? formatCurrency(
                loanComparisonValues?.submittedAnnualInsurance
              )
              : '-',
            column2: calculatedLoanData?.aggregateAnnualInsurance ? formatCurrency(calculatedLoanData?.aggregateAnnualInsurance) : '-'
          }
        },
        {
          left: 'Annual Taxes',
          right:
          {
            column1: loanComparisonValues?.submittedAnnualTaxes
              ? formatCurrency(loanComparisonValues?.submittedAnnualTaxes)
              : '-',
            column2: calculatedLoanData?.aggregateAnnualTaxes ? formatCurrency(calculatedLoanData?.aggregateAnnualTaxes) : '-'
          }
        },
        {
          left: 'Annual HOA Fees',
          right:
          {
            column1: loanComparisonValues?.submittedHOAFees
              ? formatCurrency(loanComparisonValues?.submittedHOAFees)
              : '-',
            column2: calculatedLoanData?.aggregateAnnualHOAFees ? formatCurrency(calculatedLoanData?.aggregateAnnualHOAFees) : '-'
          }
        },
        {
          left: 'LTV / Interest Rate',
          right:
          {
            column1: loanComparisonValues?.submittedLoanLTV
              ? formatLtvRate(loanComparisonValues?.submittedLoanLTV, loanComparisonValues?.submittedRate)
              : '-',
            column2: calculatedLoanData?.aggregateLoanToAsIs ? formatLtvRate(store?.aggregateLoanToAsIs, store?.rate) : '-'
          }
        },
        ...insertIf(loanDetails?.loanSubType === LoanSubType.SINGLE_PROPERTY, [
          {
            left: 'Section 8',
            right:
            {
              column1: store?.loanComparisonValues?.section8 === null ? '-' : store?.loanComparisonValues?.section8 ? 'Yes' : 'No',
              column2: primaryProperty?.nsfrLoanPropertyFields?.section8 === null ? '-' : primaryProperty?.nsfrLoanPropertyFields?.section8 ? 'Yes' : 'No'
            }
          },
          {
            left: 'Rural',
            right:
            {
              column1: store?.loanComparisonValues?.rural === null ? '-' : store?.loanComparisonValues?.rural ? 'Yes' : 'No',
              column2: primaryProperty?.rural === null ? '-' : primaryProperty?.rural ? 'Yes' : 'No'
            }
          },
          {
            left: 'Purchase Date',
            right:
            {
              column1: store?.loanComparisonValues?.purchaseDate === null ? '-' : formatDate(store?.loanComparisonValues?.purchaseDate, 'MMMM d, yyyy'),
              column2: primaryProperty?.nsfrLoanPropertyFields?.acquisitionDate === null ? '-' : formatDate(primaryProperty?.nsfrLoanPropertyFields?.acquisitionDate, 'MMMM d, yyyy')
            }
          }
        ])
      ])
    }
  }, [loanSummary, loanComparisonValues, calculatedLoanData])

  return (
    <LoanSummaryExpandableTable hasTwoColumns={true} data={data} title="SUB/UW Values" column1Title="Submission" column2Title="Underwriting" />
  );
});
