const sum = (a, b) => parseFloat(a ?? 0) + parseFloat(b ?? 0);


export function subcategoryCost(subcategory) {
  const float = n => parseFloat(n ?? 0);
  return subcategory.itemized?.length > 0
    ? subcategory.itemized.map(d => d.cost).reduce(sum, 0)
    : float(subcategory.general.cost);
}

export function totalCost(categories: any[]) {
  return categories
    .map(category =>
      category.subCategories
        .map(subCategory => subcategoryCost(subCategory))
        .reduce(sum, 0)
    )
    .reduce(sum, 0);
}

export function subcategoryCapex(subcategory) {
  return subcategory.itemized?.length > 0
    ? subcategory.itemized.map(d => d.capex ? (d.capex * d.cost / 100) : 0).reduce(sum, 0)
    : subcategory.general.capex ? (subcategory.general.capex * subcategory.general.cost) / 100 : 0;
}

export function subcategoryRemaining(subcategory) {
  return subcategory.itemized?.length > 0
    ? subcategory.itemized.map(d => d.capex ? d.cost - ((d.capex * d.cost) / 100) : d.cost).reduce(sum, 0)
    : subcategory.general.capex ? subcategory.general.cost - ((subcategory.general.capex * subcategory.general.cost) / 100) : subcategory.general.cost;
}

export function totalCapex(categories: any[]) {
  return categories
    .map(category =>
      category.subCategories
        .map(subCategory => subcategoryCapex(subCategory))
        .reduce(sum, 0)
    )
    .reduce(sum, 0);
}

export function remainingReno(categories: any[]) {
  return categories
    .map(category =>
      category.subCategories
        .map(subCategory => subcategoryRemaining(subCategory))
        .reduce(sum, 0)
    )
    .reduce(sum, 0);
}

export function totalRemainingReno(categories: any[]) {
  return totalCapex(categories) + remainingReno(categories);
}