import { GlobalStore, GridStore } from "@roc/feature-app-core";
import { InsuranceAgentService } from "../services/insuranceAgentService";
import { ApiResponse } from "@roc/feature-app-core";
import { action, makeObservable, observable, flow } from "mobx";

export abstract class InsuranceAgentBaseStore {
  private globalStore: GlobalStore;
  protected insuranceAgentService: InsuranceAgentService;
  public insuranceAgentGridStore: GridStore;
  public addressInput: any;
  private addressObject: any;
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.insuranceAgentGridStore = new GridStore(() => this.fetchInsuranceAgents(), null, 10);
    makeObservable(this, {
      addressInput: observable,
      onAddressChange: action,
      searchInsuranceAgents: flow,
      insuranceAgentGridStore: observable
    });
  }

  async fetchInsuranceAgents() {
    try {

      const response: ApiResponse = await this.insuranceAgentService.getInsuranceAgentGrid(
        this.getZipCode(),
        this.insuranceAgentGridStore.gridData.meta.pageNumber,
        this.insuranceAgentGridStore.gridData.meta.pageSize,
        this.insuranceAgentGridStore.gridData.meta.sortDir,
        this.insuranceAgentGridStore.gridData.meta.sortBy,
        this.insuranceAgentGridStore.gridData.meta.filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error fetching insurance agents',
      });
    }
  }

  public onAddressChange(addressObject: any) {
    if (addressObject) {
      this.addressObject = addressObject;
      return;
    }
    this.addressObject = null;
  }

  public *searchInsuranceAgents() {
    const zipCode = this.getZipCode();
    if (zipCode) {
      yield this.insuranceAgentGridStore.resetAndFetchGridData();
      return;
    }
    this.globalStore.notificationStore.showErrorNotification({
      message: 'Please fill in the full address to search for an available agent.',
    });
  }

  private parseAddressValues(value: any) {
    const {
      address_components
    } = value;
    const state = address_components.find(item =>
      item.types.includes('administrative_area_level_1')
    )?.short_name;
    const zipCode = address_components.find(item =>
      item.types.includes('postal_code')
    )?.short_name;
    return {
      zipCode,
      state
    };
  }

  getZipCode() {
    if (this.addressObject) {
      return this.parseAddressValues(this.addressObject)?.zipCode;
    }
    return this.extractZipCodeRegex(this.addressInput);
  }

  extractZipCodeRegex(address) {
    const regex = /(?:(?:[A-Z]{2})(?:\s+))(\d{5})(?:.*)$/;
    const match = address.match(regex);
    return match ? match[1] : null;
  }
}