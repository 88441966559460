import { Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  DocumentName,
  DocumentStatus,
  INSURANCE_APPROVAL,
  LoanAction,
  LoanActionForms,
  useDocumentStore,
  useLoanActionsStore,
} from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import { CLOSING, formatDate } from '@roc/feature-utils';
import { FabActionsButton, FabActionsMenuItem } from '@roc/ui';

import { observer } from 'mobx-react';

export const InsuranceReviewerLoanActions = observer(props => {
  const { loanActionsStore } = useLoanActionsStore();
  const { documentStore } = useDocumentStore();
  const { loanStore } = useLoanStore();

  const setAction = action => {
    loanActionsStore.setCurrentAction(action);
  };

  const openInsuranceApprovalForm = () => {
    loanActionsStore.openInsuranceApprovalForm();
  };

  const document = loanActionsStore.findDocumentByName(CLOSING, DocumentName.INSURANCE_APPROVAL);
  const showInsuranceApprovalDate = !!document?.lastAcceptedDate && document?.status === DocumentStatus.ACCEPTED

  if (loanStore.loanDetails?.toDosFrameworkEnabled && (props.version !== 'v2' && props.version !== 'v3')) {
    return null;
  }

  return (
    <>
      {loanActionsStore?.showActions && (
        <FabActionsButton version={props.version}>
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Approve Insurance'}
            onClick={() => openInsuranceApprovalForm()}
            rightColumnValue={showInsuranceApprovalDate ? `Approved
            ${formatDate(document.lastAcceptedDate, 'MMMM d, yyyy')}` : ''}
          />
          <FabActionsMenuItem
            icon={<CheckCircleIcon />}
            label={'Reject Insurance'}
            onClick={() => setAction(LoanAction.REJECT_INSURANCE)}
            rightColumnValue={showInsuranceApprovalDate ? ' ' : ''}
          />
        </FabActionsButton>
      )}
      <LoanActionForms />
    </>
  );
});
