import { useState } from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, Divider, Menu, MenuItem, IconButton, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate, insertIf } from '@roc/feature-utils';
import { MoreVert, GetApp, FileCopy, ViewHeadline } from '@material-ui/icons';
import {
  AgGridColumnProps,
  Button,
  Paper,
  DataGrid as InvoiceGrid,
  FabActionsButton,
  FabActionsMenuItem,
  ConfirmDialog,
  StandardDialog
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForeclosureRequestStore } from '../hooks/useForeclosureRequestStore';
import { useLoanStore } from '@roc/feature-loans-shared';
import { ForeclosureInvoiceModal } from './foreclosureInvoiceModal';
import { formatCurrency } from '@roc/ui/utils';
import { LoanForeclosureDetailsDialog } from './loanForeclosureDetailsDialog';
import { useDocumentStore } from '@roc/feature-documents';
import { LEGAL_FEE } from '../utils/constans'
import { ForeclosureStageList } from './foreclosureStages/foreclosureStagesList';
import { LoanDqStageData } from '../types/loanDqStageData';
import { PayableAttestationDashboard } from '@roc/feature-special-servicing';
import { CheckCircle } from '@material-ui/icons';
import { LoanForeclosureBreakdownDialog } from './loanForeclosureBreakdown';
const noOpComparator = () => 0;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 32,
  },
  dealName: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  title: {
    marginBottom: 24,
  },
  paper: {
    padding: 24,
  },
  gridContainer: {
    paddingTop: 12,
  },
  paperTitle: {
    padding: 16,
  },
  attestationModalContent: {
    padding: 0,
    margin: 0,
  },
  dialog: {
    overflow: "hidden",
  },
}));

export const LoanForeclosureRequest = observer(({ loanId }: { loanId: string }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { foreclosureRequestStore } = useForeclosureRequestStore();
  const { currentForeclosure, foreclosureInvoiceFormStore } = foreclosureRequestStore;
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isBreakdownModalOpen, setIsBreakdownModalOpen] = useState(false);
  //const [confirmModalState, setConfirmModalState] = useState({ isOpen: false, title: "", body: "", onSubmit: () => { } });

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [record, setRecord] = useState<any>({});
  const menuOpen = Boolean(anchorEl);
  const [openUpdateForeclosureStages, setOpenUpdateForeclosureStages] = useState(false);
  const [openAttestModal, setOpenAttestModal] = useState(false);
  const payoffStatus = loanStore?.loanDetails?.loanPayoffData?.payoffStatus

  useEffect(() => {
    console.log("LoanForeclosureRequest useEffect called");
    foreclosureRequestStore.getActiveForeclosureDataByLoanId(loanId);
    foreclosureRequestStore.getInvoicesByLoanId(loanId);
  }, [loanId, foreclosureRequestStore]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const invoiceColumns: AgGridColumnProps[] = [
    {
      field: 'invoice',
      headerName: 'Invoice #',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 100,
      cellRenderer: 'currencyCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'receivedDate',
      headerName: 'Requested Date',
      minWidth: 100,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'documentStatus',
      headerName: 'Status',
      minWidth: 100,
      comparator: noOpComparator,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 100,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  const listItem = (label, value, divider: boolean = true) => {
    return (
      <>
        {divider && <Divider variant="middle" component="li" />}
        <ListItem>
          <ListItemText>
            <Box display="flex">
              <Box flexGrow={1}>{label}</Box>
              <span />
              <Box>{value}</Box>
            </Box>
          </ListItemText>
        </ListItem>
      </>
    );
  };

  const frameworkComponents = {
    dateCellRenderer: ({ value }) => {
      return value ? formatDate(value, 'MM/dd/yyyy') : '';
    },
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    actionCellRenderer: params => {
      return (
        <>
          <IconButton onClick={e => openMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>

        </>
      );
    },
  };

  const openBreakdownModal = () => {
    setIsBreakdownModalOpen(true);
  };

  const openForeClosureStagesModal = () => {
    setOpenUpdateForeclosureStages(true);
  };

  const handleAttestModal = (value: boolean) => {
    setOpenAttestModal(value);
  };

  const openDetailsModal = () => {
    foreclosureRequestStore.initForeclosureDetailsForm();
    setIsDetailsModalOpen(true);
  };

  const closeBreakdownModal = () => {
    setIsBreakdownModalOpen(false);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    setRecord(record)
    setAnchorEl(event.currentTarget);
  };

  const closeForeClosureStagesModal = () => {
    setOpenUpdateForeclosureStages(false);
  }

  const saveForeclosureStages = (stages: LoanDqStageData) => {

    foreclosureRequestStore.saveForeclosureStages(loanId, stages);
    closeForeClosureStagesModal();

  }

  return (
    <>
      <LoanForeclosureDetailsDialog
        open={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        store={foreclosureRequestStore}
      />
      <Grid container direction="row" className={classes.container} spacing={2}>
        <Grid item className={classes.dealName} xs={12}>
          <Typography variant="h6" color="textSecondary">
            {loanStore?.loanDetails.dealName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Foreclosure Request
          </Typography>
          <Grid direction="row" spacing={3} xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Paper>
                  <Grid item xs={12} md={12}>
                    <Grid container justifyContent="space-between" className={classes.paperTitle}>
                      <Grid item>
                        <Typography variant="h5">FORECLOSURE DETAILS</Typography>
                      </Grid>
                      <Grid item>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            testId="updateForeclosureDetails"
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={currentForeclosure == null}
                            onClick={() => openDetailsModal()}
                          >
                            EDIT
                          </Button>
                        </Box>
                      </Grid>
                      <Grid container direction="column">
                        <List>
                          {listItem('Stage', currentForeclosure?.stage || 'N/A', false)}
                          {listItem('Expected Settlement Date', currentForeclosure?.settlementDate || 'N/A')}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">INVOICES</Typography>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        testId="addInvoice"
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={currentForeclosure == null || (payoffStatus == "Approved")}
                        onClick={() => setIsFileUploadModalOpen(true)}
                      >
                        Add Expenses
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <InvoiceGrid
                columns={invoiceColumns}
                rows={foreclosureRequestStore.invoiceList}
                frameworkComponents={frameworkComponents}
                domLayout="autoHeight"
              />
              <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={menuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    const document = {
                      loanTaskId: record.documentId,
                      originalFileName: record.originalFileName,
                    };
                    documentStore.downloadDocumentOriginalNameById(document);
                    handleMenuClose();
                  }}
                  style={{ padding: '8px' }}
                >
                  <ListItemIcon>
                    <GetApp fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Download Document`}
                    style={{ cursor: 'pointer' }}
                  />
                </MenuItem>
              </Menu>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ForeclosureInvoiceModal
        loanId={loanId}
        open={isFileUploadModalOpen}
        store={foreclosureInvoiceFormStore}
        typeOfFee={LEGAL_FEE}
        onClose={() => setIsFileUploadModalOpen(false)}
        onUpload={() => foreclosureRequestStore.getInvoicesByLoanId(loanId)}
      />
      {[
        ...insertIf(currentForeclosure != null, [
          <FabActionsButton>
            <FabActionsMenuItem
              icon={<FileCopy />}
              label={'Request Loan Breakdown'}
              onClick={openBreakdownModal}
            />
            <FabActionsMenuItem
              icon={<ViewHeadline />}
              label={'Update Foreclosure Stages'}
              onClick={() => openForeClosureStagesModal()}
            />
            <FabActionsMenuItem
              icon={<CheckCircle />}
              label={'Attest Loan'}
              onClick={() => handleAttestModal(true)}
            />
          </FabActionsButton>
        ]),
      ]}
      <LoanForeclosureBreakdownDialog
        open={isBreakdownModalOpen}
        onClose={closeBreakdownModal}
      />
      <StandardDialog
        className={classes.dialog}
        classes={{
          root: classes.dialog,
          paper: classes.dialog
        }}
        dialogContentclasses={{ root: classes.dialog }}
        open={openUpdateForeclosureStages}
        title={"Foreclosure Stages"}
        maxWidth="lg"
        handleClose={closeForeClosureStagesModal}
        dialogContent={(
          <ForeclosureStageList
            loanId={loanId}
            store={foreclosureRequestStore}
            handleClose={closeForeClosureStagesModal}
            handleSave={saveForeclosureStages}
          />
        )}
      />
      <StandardDialog
        maxWidth="md"
        hideBackdrop={true}
        classes={{
          root: classes.attestationModalContent,
          paper: classes.attestationModalContent
        }}

        removeContentBox
        open={openAttestModal}
        handleClose={() => handleAttestModal(false)}
        dialogContentclasses={{ root: classes.attestationModalContent }}
        dialogContent={(
          <PayableAttestationDashboard
            loanId={loanId}
            type='Legal'
            handleClose={() => handleAttestModal(false)}
          />
        )}
      />
    </>
  );
});
