import {
  Box,
  Chip,
  darken,
  alpha,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { LoanProcess, UNDERWRITING, uniq } from '@roc/feature-utils';
import {
  Button,
  IconButton,
  InformationDialog,
  PrintableArea,
  PrintableAreaRef,
  SelectField,
  Tooltip,
  TooltipOnOverflow,
} from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CustomCard } from '../customCard';
import { TodoStatusChip, colorByStatus } from '../todoStatusChip';
import { TodoItem, TodoStatus } from '../../utils/loanDetailsTypes';
import { DownloadTodoButton, TodoActions, TodoTitleLink } from './todoActions';
import { useLoanStore } from '@roc/feature-loans';
import { todoStatusMapping } from '../../utils/loanDetailsConstants';
import {
  CloudDownload,
  GetApp,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  PhotoCamera,
} from '@material-ui/icons';
import { APPRAISAL_REPORT, APPRAISAL_REVIEW_REPORT, SCOPE_OF_WORK, SCOPE_OF_WORK_FORM, useDocumentStore } from '@roc/feature-documents';
import { StatusFilterField } from '../common/statusFilterField';
import { SectionActions } from './sectionActions';
import { isPendingTodoStatus } from '../../utils/todoUtils';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { Alert } from '@material-ui/lab';
import { APPRAISAL_REPORTS_LINE_ITEMS_TEXT, SCOPE_OF_WORK_LINE_ITEM_TEXT, SUBJECT_PROPERTY_SECTION } from '../../utils/todoConstants';

const CATEGORY_ROW_BG_BLUE_COLOR = '#ddf1f9';
const CATEGORY_ROW_BG_GRAY_COLOR = '#ededed';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  categoryRow: {
    backgroundColor: (props: any) => `${props.categoryRowBackground}`,
    '& th, & td': {
      ...theme.typography.h6,
      padding: 0,
    },
  },
  categoryRowExpandCollapseButton: {
    padding: 0,
    '& svg': {
      fontSize: 22,
    },
  },
  categoryRowOpen: {
    '& th, & td': {
      border: 0,
    },
  },
  headerRow: {
    '& th': {
      backgroundColor: 'unset',
      borderBottom: '1px solid #555',
    },
    '& th, & td': {
      fontWeight: 'bold',
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(0.5),
    },
  },
  headerRowSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 1,
  },
  todoRow: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1, 0.5),
      height: '20px',
      lineHeight: '20px',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      borderColor: '#e9e9e9',
      fontSize: 16,
    },
  },
  todoLastRow: {
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },
  collapse: {
    width: 30 + theme.spacing(2),
  },
  headerCell: {
    fontWeight: 'bold',
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(0.5),
  },
  categoryCell: {
    '& p': {
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  center: {
    textAlign: 'center',
  },
  overflowElipsis: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  todoTitle: {
    display: 'inline-block',
    flexShrink: 1,
  },
  filename: {
    maxWidth: '40%',
    paddingLeft: theme.spacing(2),
    color: '#86b8ff',
    display: 'inline-block',
  },
}));

interface Props {
  title?: string | React.ReactNode;
  todos: TodoItem[];
  loanProcess: LoanProcess;
  showFilter?: boolean;
  allowStatusChange?: boolean;
  showSectionActions?: boolean;
  showFileName?: boolean;
  showDownloadAll?: boolean;
  showDownloadTodo?: boolean;
}

export const TodoCard = observer(
  ({
    title,
    todos,
    loanProcess,
    showFilter = false,
    showSectionActions = false,
    allowStatusChange = false,
    showFileName = false,
    showDownloadAll = false,
    showDownloadTodo = false,
  }: Props) => {
    const printableAreaRef = useRef<PrintableAreaRef>(null);
    const [showOnlyPending, setShowOnlyPending] = useState(false);

    const filteredTodos = showOnlyPending
      ? todos.filter(todo => isPendingTodoStatus(todo.status))
      : todos;

    const download = () => {
      printableAreaRef.current.downloadAsPdf(
        `Loan ${todos[0]?.document?.loanId} To-dos.pdf`,
        {
          singlePage: true,
        }
      );
    };

    return (
      <CustomCard
        title={title}
        cardHeaderProps={{
          action: (
            <Box pt={1} display="flex">
              {showDownloadAll && (
                <Box pr={2}>
                  <Tooltip title="Take screenshot" placement="bottom">
                    <div>
                      <Button
                        testId="downloadAll"
                        onClick={download}
                        variant="outlined"
                        style={{
                          letterSpacing: 'normal',
                          textTransform: 'none',
                          fontWeight: 'normal',
                          fontSize: '16px',
                        }}
                      >
                        <Box height={'31px'} display="flex" alignItems="center">
                          <PhotoCamera />
                          &nbsp;&nbsp;Take Screenshot
                        </Box>
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
              )}
              {showFilter && (
                <StatusFilterField
                  value={showOnlyPending}
                  onChange={val => setShowOnlyPending(val as boolean)}
                />
              )}
            </Box>
          ),
        }}
        cardContentProps={{
          style: {
            overflow: 'auto',
          },
        }}
      >
        <PrintableArea ref={printableAreaRef}>
          <Box minWidth={1000}>
            <TodoCardCompponent
              todos={filteredTodos}
              loanProcess={loanProcess}
              allowStatusChange={allowStatusChange}
              showSectionActions={showSectionActions}
              showFileName={showFileName}
              showDownloadTodo={showDownloadTodo}
            />
          </Box>
        </PrintableArea>
      </CustomCard>
    );
  }
);

export const TitleWithTodoCount = observer(({ title = "To-dos", count }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box>{title}</Box>
      {count > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${count} - Pending`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.PENDING],
              borderColor: colorByStatus[TodoStatus.PENDING],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
});

export const TitleWithDetailedTodoCount = observer(({ title, pendingCount, moreInfoNeededCount, inReviewCount, acceptedCount, notApplicableCount }: {
  title: string;
  pendingCount?: number;
  moreInfoNeededCount?: number;
  inReviewCount?: number;
  acceptedCount?: number;
  notApplicableCount?: number;
}) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box>{title}</Box>
      {pendingCount > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${pendingCount} - Pending`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.PENDING],
              borderColor: colorByStatus[TodoStatus.PENDING],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
      {moreInfoNeededCount > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${moreInfoNeededCount} - More Info Needed`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.MORE_INFO_NEEDED],
              borderColor: colorByStatus[TodoStatus.MORE_INFO_NEEDED],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
      {inReviewCount > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${inReviewCount} - Being Reviewed`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.BEING_REVIEWED],
              borderColor: colorByStatus[TodoStatus.BEING_REVIEWED],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
      {acceptedCount > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${acceptedCount} - Accepted`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.ACCEPTED],
              borderColor: colorByStatus[TodoStatus.ACCEPTED],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
      {notApplicableCount > 0 ? (
        <Box ml={2}>
          <Chip
            label={`${notApplicableCount} - Not Applicable`}
            variant="outlined"
            style={{
              color: colorByStatus[TodoStatus.NOT_APPLICABLE],
              borderColor: colorByStatus[TodoStatus.NOT_APPLICABLE],
              fontWeight: 'bold',
              fontSize: 16,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
});

export const TodoCardCompponent = observer(
  ({
    todos,
    loanProcess,
    showSectionActions = false,
    allowStatusChange = false,
    stickyHeader = false,
    showFileName = false,
    showDownloadTodo = false,
    expandAll = 0,
    collapseAll = 0,
    isLoading = false,
    noRecordsMessageComponent = (
      <Alert severity="info">
        No Records.
      </Alert>
    ),
  }) => {
    const { globalStore } = useBaseStore();
    const classes = useStyles({
      categoryRowBackground: globalStore.isInternalPortal
        ? CATEGORY_ROW_BG_BLUE_COLOR
        : CATEGORY_ROW_BG_GRAY_COLOR,
    });
    const categories = uniq(todos.map(todo => todo.category)) as string[];

    return (
      <>
        {
          !isLoading && todos.length == 0 ?
            <Box mx={2} mb={1}>
              {
                noRecordsMessageComponent
              }
            </Box> :
            <Table className={classes.table}>
              <TableHead>
                <TableRow
                  className={clsx(
                    classes.headerRow,
                    stickyHeader && classes.headerRowSticky
                  )}
                >
                  <TableCell width="38px"></TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell width="180px">Status</TableCell>
                  <TableCell width="200px" className={classes.center}>
                    Last Update Date
                  </TableCell>
                  <TableCell width="100px" className={classes.center}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isLoading &&
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box textAlign={'center'}>
                        Loading...
                      </Box>
                    </TableCell>
                  </TableRow>
                }
                {!isLoading && categories.map(category => (
                  <CategoryRow
                    allowStatusChange={allowStatusChange}
                    key={category}
                    category={category}
                    todos={todos.filter(todo => todo.category === category)}
                    loanProcess={loanProcess}
                    showSectionActions={showSectionActions}
                    showFileName={showFileName}
                    showDownloadTodo={showDownloadTodo}
                    expandAll={expandAll}
                    collapseAll={collapseAll}
                  />
                ))}
              </TableBody>
            </Table>
        }
      </>
    );
  }
);

const CategoryRow = observer(
  ({
    category,
    todos,
    allowStatusChange,
    showSectionActions,
    loanProcess,
    showFileName,
    showDownloadTodo,
    expandAll,
    collapseAll,
  }: {
    category: string;
    todos: TodoItem[];
    allowStatusChange: boolean;
    showSectionActions: boolean;
    loanProcess: LoanProcess;
    showFileName: boolean;
    showDownloadTodo: boolean;
    expandAll: number;
    collapseAll: number;
  }) => {
    const { globalStore } = useBaseStore();
    const classes = useStyles({
      categoryRowBackground: globalStore.isInternalPortal
        ? CATEGORY_ROW_BG_BLUE_COLOR
        : CATEGORY_ROW_BG_GRAY_COLOR,
    });
    const [open, setOpen] = useState(true);

    useEffect(() => {
      if (expandAll) {
        setOpen(true);
      }
    }, [expandAll]);

    useEffect(() => {
      if (collapseAll) {
        setOpen(false);
      }
    }, [collapseAll]);

    return (
      <Fragment key={category}>
        <TableRow
          className={clsx(classes.categoryRow, open && classes.categoryRowOpen)}
        >
          <TableCell className={classes.categoryCell} width={42}>
            <Box
              display={'flex'}
              alignItems={'center'}
              px={1}
              py={1}
              ml={0}
              onClick={() => setOpen(!open)}
              style={{
                cursor: 'pointer',
              }}
            >
              <Box component={'span'}>
                <IconButton
                  testId={`expand-${category}`}
                  size="small"
                  onClick={() => setOpen(!open)}
                  className={classes.categoryRowExpandCollapseButton}
                >
                  {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
              </Box>
            </Box>
          </TableCell>
          <TableCell colSpan={3} className={classes.categoryCell}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent="space-between"
              px={0}
              py={1}
              ml={0}
              onClick={() => setOpen(!open)}
              style={{
                cursor: 'pointer',
              }}
            >
              <Typography>{category}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box textAlign="right">
              {showSectionActions && (
                <SectionActions
                  loanProcess={loanProcess}
                  sectionName={category}
                  sectionId={todos[0]?.document.sectionId}
                  loanId={todos[0]?.document.loanId}
                  drawId={todos[0]?.document.drawId}
                />
              )}
            </Box>
          </TableCell>
        </TableRow>

        {open && (
          <>
            {/* <TableRow>
              <TableCell className={classes.collapse}></TableCell>
              <TableCell className={classes.headerCell}>Title</TableCell>
              <TableCell className={clsx(classes.headerCell)}>
                Status
              </TableCell>
              <TableCell className={clsx(classes.headerCell, classes.center)}>
                Last Update Date
              </TableCell>
              <TableCell className={clsx(classes.headerCell, classes.center)}>
                Actions
              </TableCell>
            </TableRow> */}

            {todos.map((todo, i) => (
              <TodoRow
                allowStatusChange={allowStatusChange}
                key={todo.todoId}
                todo={todo}
                isLastRow={i == todos.length - 1}
                loanProcess={loanProcess}
                showFileName={showFileName}
                showDownloadTodo={showDownloadTodo}
              />
            ))}
          </>
        )}
      </Fragment>
    );
  }
);

const TodoRow = observer(
  ({
    todo,
    isLastRow,
    allowStatusChange,
    loanProcess,
    showFileName,
    showDownloadTodo,
  }: {
    todo: TodoItem;
    isLastRow: boolean;
    allowStatusChange: boolean;
    loanProcess: LoanProcess;
    showFileName: boolean;
    showDownloadTodo: boolean;
  }) => {
    const { loanStore } = useLoanStore();
    const { documentStore } = useDocumentStore();
    const { globalStore } = useBaseStore();
    const { userStore } = useUserStore();
    const classes = useStyles({
      categoryRowBackground: globalStore.isInternalPortal
        ? CATEGORY_ROW_BG_BLUE_COLOR
        : CATEGORY_ROW_BG_GRAY_COLOR,
    });
    const loanId = loanStore.loanDetails?.loanId;
    const tooltip = todo.document?.taskInformation;
    const filename = todo.document?.originalFileName;
    const title = documentStore.getDisplayDocumentName(todo.title);
    const info = todo.document?.documentNameSecondLine || null;
    const [avoidStatusChangeDialog, setAvoidStatusChangeDialog] = useState(false);
    const [avoidBodyDialog, setAvoidBodyDialog] = useState('');
    const isSubjectProperty = todo.category.includes(SUBJECT_PROPERTY_SECTION);
    const isRestrictedDocument = isSubjectProperty && [APPRAISAL_REPORT, APPRAISAL_REVIEW_REPORT, SCOPE_OF_WORK].includes(todo.title);

    return (
      <TableRow
        className={clsx(classes.todoRow, isLastRow ? classes.todoLastRow : '')}
      >
        <TableCell></TableCell>
        <TableCell>
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            <TooltipOnOverflow
              title={title}
              placement="bottom"
              spanClass={classes.todoTitle}
            >
              <Typography className={classes.overflowElipsis}>
                <TodoTitleLink
                  document={todo.document}
                  loanProcess={loanProcess}
                >
                  {title}
                  {info ? ` - ${info}` : null}
                </TodoTitleLink>
              </Typography>
            </TooltipOnOverflow>
            {!!tooltip && (
              <Tooltip
                title={<Box dangerouslySetInnerHTML={{ __html: tooltip }} />}
              />
            )}
            {showFileName && !!filename && (
              <TooltipOnOverflow
                title={filename}
                placement="bottom"
                spanClass={classes.filename}
              >
                <Typography className={classes.overflowElipsis}>
                  {filename}
                </Typography>
              </TooltipOnOverflow>
            )}
            {showDownloadTodo && (
              <DownloadTodoButton document={todo.document} />
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            <TodoStatusChip
              showDropdown={allowStatusChange}
              status={todo.status}
              onStatusChange={status => {
                if (isRestrictedDocument && !userStore.isTechUser) {
                  setAvoidStatusChangeDialog(true);
                  if (todo.title === SCOPE_OF_WORK) {
                    setAvoidBodyDialog(SCOPE_OF_WORK_LINE_ITEM_TEXT)
                  } else if (todo.title === APPRAISAL_REPORT || todo.title === APPRAISAL_REVIEW_REPORT) {
                    setAvoidBodyDialog(APPRAISAL_REPORTS_LINE_ITEMS_TEXT)
                  }
                } else {
                  documentStore.loanTodosStore.updateTodoStatus(
                    loanId,
                    loanProcess,
                    todo.document,
                    todoStatusMapping[status]
                  )
                }
              }
              }
            />
          </Box>
          <InformationDialog
            open={avoidStatusChangeDialog}
            body={avoidBodyDialog}
            handleClose={() => setAvoidStatusChangeDialog(false)}
          />
        </TableCell>
        <TableCell className={classes.center}>{todo.lastUpdateDate}</TableCell>
        <TableCell className={classes.center}>
          <Box m={-2}>
            {todo.document ? (
              <TodoActions document={todo.document} loanProcess={loanProcess} />
            ) : (
              <IconButton
                testId={`todo-${todo.todoId}-more`}
                onClick={() => { }}
              >
                <MoreHorizIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
    );
  }
);
