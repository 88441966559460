/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Grid,
  Box,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ItemDisplay } from "./itemDisplay";
import { NeighborhoodFormStore } from "../stores/neigborhoodFormStore";
import { observer } from "mobx-react";
import { highestAndBestUseOptions, locationOptions, neighborhoodZoningOptions } from "../utils/constants";
import { createRadioField, createSelectField, createTextField, trueFalseOptions } from "@roc/ui";
import { formatCurrencyField, formatNumberField } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) => ({
  saveButton: {
    width: "15rem",
  },
  fieldsContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  radioLabel: {
    fontSize: "0.75rem",
  },
  value: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
  },
}));

function getTrend(value) {
  let trend = "-";
  if (value) {
    if (value > 0) {
      trend = "Increasing";
    } else
      if (value === 0) {
        trend = "Stable";
      } else
        if (value < 0) {
          trend = "Decreasing";
        }
  }
  return trend
}

interface NeighborHoodFormProps {
  neighborhoodFormStore: NeighborhoodFormStore;
  readOnly: boolean;
}

export const NeighborhoodForm = observer(({ neighborhoodFormStore, readOnly }: NeighborHoodFormProps) => {
  const classes = useStyles();

  const flatValues: any = neighborhoodFormStore.getFlattenedValues();

  let comparableSales12MosRange = '-';
  if (flatValues.comparableSalePriceFrom && flatValues.comparableSalePriceTo) {
    comparableSales12MosRange = `${formatCurrencyField(flatValues.comparableSalePriceFrom)} - ${formatCurrencyField(flatValues.comparableSalePriceTo)}`;
  }

  let neighborhoodPriceRange = '-';

  if (flatValues.neighborhoodPriceFrom && flatValues.neighborhoodPriceTo) {
    neighborhoodPriceRange = `${formatCurrencyField(flatValues.neighborhoodPriceFrom)} - ${formatCurrencyField(flatValues.neighborhoodPriceTo)}`;
  }

  const propertyPriceTrend = getTrend(flatValues.yoyMedianPricePercentage);
  const reoTrend = getTrend(flatValues.yoyReoListingsPercentage);
  const marketingTimeTrend = getTrend(flatValues.yoyDomPercentage);
  const supplyTrend = getTrend(flatValues.yoyMonthsOfSupplyPercentage);
  const demandTrend = getTrend(flatValues.yoyWithin2WeeksPercentage);


  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box className={classes.fieldsContainer}>
          {createSelectField({
            store: neighborhoodFormStore,
            testId: 'location',
            fieldName: 'location',
            label: 'Location',
            options: locationOptions,
            standaloneLabel: false,
            disabled: readOnly
          })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.fieldsContainer}>
          {createRadioField({
            label: 'Neighborhood Zoning',
            fieldName: 'neighborhoodZoning',
            testId: 'neighborhoodZoning',
            store: neighborhoodFormStore,
            options: neighborhoodZoningOptions,
            row: true,
            standaloneLabel: false,
            disabled: readOnly
          })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.fieldsContainer}>
          {createRadioField({
            label: 'Highest & Best Use',
            fieldName: 'highestAndBestUse',
            testId: 'highestAndBestUse',
            store: neighborhoodFormStore,
            options: highestAndBestUseOptions,
            row: true,
            standaloneLabel: false,
            disabled: readOnly
          })}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.fieldsContainer}>
          {createRadioField({
            label: 'Any Adverse external factors within 0.25 miles of the subject property?',
            fieldName: 'hasAdverseFactors',
            testId: 'hasAdverseFactors',
            store: neighborhoodFormStore,
            options: trueFalseOptions,
            row: true,
            standaloneLabel: false,
            disabled: readOnly
          })}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-evenly" alignItems="flex-end" gridGap="2rem">
          <ItemDisplay
            label="Property Price Trend"
            value={propertyPriceTrend}
          />
          <ItemDisplay
            label="REO Trend"
            value={reoTrend}
          />
          <ItemDisplay
            label="Marketing Time Trend"
            value={marketingTimeTrend}
          />
          <ItemDisplay
            label="Supply"
            value={supplyTrend}
          />
          <ItemDisplay
            label="Demand"
            value={demandTrend}
          />
          <ItemDisplay
            label="Demand"
            value={demandTrend}
          />
          <ItemDisplay
            label="Comparable Sales 12Mos"
            value={formatNumberField(flatValues.compsPast12Mo)}
          />
          <ItemDisplay
            label="Comparable Sales 12MOS"
            value={comparableSales12MosRange}
          />
          <ItemDisplay
            label="Neighborhood Price Range"
            value={neighborhoodPriceRange}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          label: 'Adverse External Factor and Neighborhood Comments',
          fieldName: 'adverseFactorsComments',
          testId: 'adverseFactorsComments',
          store: neighborhoodFormStore,
          standaloneLabel: false,
          multiline: true,
          minRows: 4,
          disabled: readOnly
        })}
      </Grid>
    </Grid>
  );
});
