import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { GeneralLoanTasks } from '../generalLoanTasks';
import { GeneralTasks } from '../generalTasks';
import { MyGeneralTasks } from '../myGeneralTasks';
import { MyGeneralTasksGmailPopup } from '../myGeneralTasksGmailPopup';
import { CreateTaskDialogInternal } from '../components/editTask/createTaskDialogInternal';
import { EditTaskDialogInternal } from '../components/editTask/editTaskDialogInternal';
import { SingleTaskViewInternal } from '../singleTaskViewInternal';
import { MyLoansTasks } from '../myLoansTasks';

export const getGeneralTasksRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    tasksDashboard: {
      path: `${basePath}/tasks/all-tasks`,
      url: `${baseUrl}/tasks/all-tasks`,
      documentTitle: 'Tasks',
    },
    loanTasks: (loanId?) => ({
      path: `${basePath}/tasks/loan-tasks/:loanId`,
      url: `${baseUrl}/tasks/loan-tasks/${loanId}`,
      documentTitle: 'Tasks',
    }),
    myTasks: {
      path: `${basePath}/tasks/my-tasks`,
      url: `${baseUrl}/tasks/my-tasks`,
      documentTitle: 'Tasks',
    },
    myLoansTasks: {
      path: `${basePath}/tasks/my-loans-tasks`,
      url: `${baseUrl}/tasks/my-loans-tasks`,
      documentTitle: 'Loans Tasks',
    },
    myTasksGmailPopup: {
      path: `${basePath}/tasks/my-tasks-gmail`,
      url: `${baseUrl}/tasks/my-tasks-gmail`,
      documentTitle: 'Tasks',
    },
    createTaskModal: (loanId?) => ({
      path: `${basePath}/tasks/create-task-modal/:loanId`,
      url: `${baseUrl}/tasks/create-task-modal/${loanId}`,
      documentTitle: 'Tasks',
    }),
    editTaskModal: (taskId?) => ({
      path: `${basePath}/tasks/edit-task-modal/:taskId`,
      url: `${baseUrl}/tasks/edit-task-modal/${taskId}`,
      documentTitle: 'Tasks',
    }),
    singleTask: (taskId?) => ({
      path: `${basePath}/tasks/single-task-modal/:taskId`,
      url: `${baseUrl}/tasks/single-task-modal/${taskId}`,
      documentTitle: 'Tasks',
    }),
  };
};

export const getGeneralTasksRoutes = (basePath: string, baseUrl: string) => {
  const config = getGeneralTasksRoutesConfig(basePath, baseUrl);
  return {
    tasksDashboard: (
      <Route exact path={config.tasksDashboard.path}>
        <Page routeDefinition={config.tasksDashboard}>
          <GeneralTasks />
        </Page>
      </Route>
    ),
    loanTasks: (
      <Route exact path={config.loanTasks().path}>
        <Page routeDefinition={config.loanTasks()}>
          <GeneralLoanTasks />
        </Page>
      </Route>
    ),
    myTasks: (
      <Route exact path={config.myTasks.path}>
        <Page routeDefinition={config.myTasks}>
          <MyGeneralTasks />
        </Page>
      </Route>
    ),
    myLoansTasks: (
      <Route exact path={config.myLoansTasks.path}>
        <Page routeDefinition={config.myLoansTasks}>
          <MyLoansTasks />
        </Page>
      </Route>
    ),
    myTasksGmailPopup: (
      <Route exact path={config.myTasksGmailPopup.path}>
        <Page routeDefinition={config.myTasksGmailPopup}>
          <MyGeneralTasksGmailPopup />
        </Page>
      </Route>
    ),
    createTaskModal: (
      <Route exact path={config.createTaskModal().path}>
        <Page routeDefinition={config.createTaskModal()}>
          <CreateTaskDialogInternal />
        </Page>
      </Route>
    ),
    editTaskModal: (
      <Route exact path={config.editTaskModal().path}>
        <Page routeDefinition={config.editTaskModal()}>
          <EditTaskDialogInternal />
        </Page>
      </Route>
    ),
    singleTaskModal: (
      <Route exact path={config.singleTask().path}>
        <Page routeDefinition={config.singleTask()}>
          <SingleTaskViewInternal />
        </Page>
      </Route>
    ),
  };
};
