import {
    Box,
    Checkbox,
    Chip,
    Grid,
    InputAdornment,
    Popper,
    Typography,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
  import { isNil, sanitizeTestId } from '@roc/feature-utils';
  import {
    TextField,
    useVirtualizedAutocomplete,
  } from '@roc/ui';
  import clsx from 'clsx';
  import { useMemo } from 'react';
  import { Person } from '@material-ui/icons';
  import { FieldLabel, VirtualizedAutocomplete } from '@roc/ui';
  
  const useStyles = makeStyles(theme => ({
    popper: {
      minWidth: '450px',
      zIndex: 9999,
    },
    chipsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));
  
  export const TaskWatchField = ({
    label,
    value,
    onChange,
    assigneeOptions,
    disabled,
  }) => {
    const classes = useStyles();
    const options = (assigneeOptions ?? []).map(assignee => ({
      ...assignee,
      label: assignee.assignedToLoan && !isNil(assignee.roleDisplayName) ? `${assignee.firstName} ${assignee.lastName} | ${assignee.roleDisplayName}` : `${assignee.firstName} ${assignee.lastName}`,
      value: assignee.userId,
    }));
  
    const PopperComponent = useMemo(() => {
      return props => <Popper {...props} className={classes.popper} />;
    }, []);
  
    const handleChange = (event, selectedOptions) => {
      onChange(event, selectedOptions.map(option => option.value));
    };
  
    const handleDelete = (deletedValue) => {
      const updatedValue = value.filter(v => v !== deletedValue);
      onChange(null, updatedValue);
    };
  
    return (
      <>
        <FieldLabel>{label}</FieldLabel>
        <VirtualizedAutocomplete
          multiple
          optionHeight={48}
          id={sanitizeTestId(label)}
          value={(value || []).map(val => options.find(o => o.value === val))}
          options={options}
          renderInput={params => (
            <TextField
              testId={sanitizeTestId(label)}
              variant="outlined"
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Box className={classes.chipsContainer}>
                    {value.map(selectedValue => {
                      const selectedOption = options.find(o => o.value === selectedValue);
                      return (
                        <Chip
                          key={selectedOption.value}
                          label={selectedOption.label}
                          className={classes.chip}
                          onDelete={() => handleDelete(selectedOption.value)}
                        />
                      );
                    })}
                  </Box>
                ),
              }}
            />
          )}
          getOptionLabel={option => option?.label}
          getOptionSelected={(option, selectedOption) =>
            option.value === selectedOption.value
          }
          onChange={handleChange}
          fullWidth
          disabled={disabled}
          disableCloseOnSelect
          PopperComponent={PopperComponent}
          renderOption={(option, { selected }) => (
            <>
              <Grid container alignItems={'center'} wrap={'nowrap'} spacing={2}>
                <Grid item>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankSharp />}
                    checkedIcon={<CheckBoxSharp />}
                    checked={selected}
                  />
                </Grid>
                <Grid item>
                  <Typography>{`${option.firstName} ${option.lastName}`}</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    variant="default"
                    size="small"
                    label={option.role}
                    style={{
                      height: 18,
                      marginLeft: '4px',
                      backgroundColor: '#d3fbac',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        />
      </>
    );
  };  
  