import { LoanStatus } from "./utils";
import {
  LoanSubType,
  MIXED_USE,
  MULTIFAMILY_5_PLUS,
  SINGLE_FAMILY,
  CONDOMINIUM,
  LoanType,
  describedRennovationOptions,
} from './constants';

export const isResidentialBridgeLoan = (loanType: string) => {
  return loanType === LoanType.RESIDENTIAL_BRIDGE
}

export const isFundedLoan = (status: string) => {
  return (status === "FUNDED" || status === "PAID_OFF" || status === "PAID_OFF_BUYOUT")
}

export const isFundedOrAgreedLoan = (status: string) => {
  return (isFundedLoan(status) || status === "AGREED")
}

export const isAgreedLoan = (status: string) => {
  return status === "AGREED";
}

export const isPendingLoanStatus = (status: string) => {
  return (status === LoanStatus.PENDING)
}

export const isPreagreedLoanStatus = (status: string) => {
  return (status === LoanStatus.UNKNOWN || status === LoanStatus.PENDING || status === LoanStatus.TERM_SHEET_OUT ||
    status === LoanStatus.IN_UNDERWRITING || status === LoanStatus.PENDING_DECISION || status === LoanStatus.PENDING_TERM_SHEET ||
    status === LoanStatus.PRESCREEN)
}

export const propertyTypesByLoanSubtype = (type: LoanSubType) => {
  switch (type) {
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return [{ value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' }];
    case LoanSubType.SINGLE_PROPERTY:
    case LoanSubType.RENTAL_PORTFOLIO:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Warrantable Condominium' },
        { value: 'Townhome', label: 'Townhome' },
        {
          value: 'Planned Unit Development',
          label: 'Planned Unit Development',
        },
        { value: MULTIFAMILY_5_PLUS, label: '5+ Unit Multifamily' },
        { value: MIXED_USE, label: MIXED_USE },
      ];
    case LoanSubType.FIX_AND_FLIP:
    case LoanSubType.FIX_AND_FLIP_PRO:
    case LoanSubType.GROUND_UP:
    case LoanSubType.STABILIZED_BRIDGE:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Warrantable Condominium' },
      ]
    default:
      return [
        { value: SINGLE_FAMILY, label: SINGLE_FAMILY },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Triplex', label: 'Triplex' },
        { value: 'Quadruplex', label: 'Quadruplex' },
        { value: CONDOMINIUM, label: 'Condominium' },
      ];
  }
};


export const renovationDescriptionsByLoanSubtype = (type: LoanSubType) => {
  switch (type) {
    case LoanSubType.MULTIFAMILY_BRIDGE_5PLUS:
      return [
        {
          value: 'Cleanout',
          label: 'Cleanout',
        },
        {
          value: 'Light and cosmetic',
          label: 'Light and cosmetic',
        },
        {
          value: 'Gut rehab',
          label: 'Gut rehab',
        },
        {
          value: 'Moderate rehab',
          label: 'Moderate rehab',
        },
        {
          value: 'No Renovation planned',
          label: 'No Renovation planned',
        },
      ];
    case LoanSubType.FIX_AND_FLIP:
    case LoanSubType.FIX_AND_FLIP_PRO:
      return describedRennovationOptions;
    case LoanSubType.GROUND_UP:
      return [
        {
          value: 'New Construction',
          label: 'New Construction',
        }
      ]
  }
};
