import { Box, Divider, Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { Theme, createStyles, darken, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CopyText, StandardDialog, Toolbar, FilterButton, TestDataAttribute } from '@roc/ui';
import { useLoanSummaryStore } from '../hooks/useLoanSummaryStore';
import { useParams } from 'react-router';
import { LoanSubType, LoanType } from '@roc/feature-utils';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { useLoanCommissionStore } from '../hooks/useCommissionStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: '#fff'
    },
    paper: {
      marginBottom: theme.spacing(2),
    },
    card: {
      background: theme.palette.background.default,
      borderColor: darken(theme.palette.background.default, 0.1),
    },
  }),
);

const listItem = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={label}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const listItemBold = (label, value, icon = null) => {
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            <strong>{label}</strong>
          </Box>
          <TestDataAttribute id={label}>
            <Box textAlign="right"><strong>{value}</strong></Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const divider = <Divider variant="middle" component="li" />;

export const LoanSummaryCommissions = observer(() => {
  const classes = useStyles();
  const { loanSummaryStore } = useLoanSummaryStore();
  const { loanCommissionStore } = useLoanCommissionStore();
  const { loanId } = useParams<{ loanId: string }>();
  const { loanSummary } = loanSummaryStore;
  const { commissionData } = loanCommissionStore;

  useEffect(() => {
    loanSummaryStore.fetchLoanSummary(loanId);
  }, [loanSummaryStore, loanId]);

  useEffect(() => {
    if (loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE) {
      loanCommissionStore.getBridgeCommission({
        loanAmount: loanSummary?.amount,
        rocRate: loanSummary?.rocRate,
        rocPointsIn: loanSummary?.rocPointsIn,
        fees: loanSummary?.underwritingFee,
        rocPointsOut: loanSummary?.rocPointsOut,
        referralFee: loanSummary?.referralFee,
      });
    }
    if (loanSummary?.loanType === LoanType.RESIDENTIAL_TERM) {
      const retailFees = getTotalFees(loanSummary?.loanRetailFees);
      loanCommissionStore.getTermCommission({
        loanAmount: loanSummary?.amount,
        rocPoints: loanSummary?.loanRetailFees?.retailPoints,
        fees: retailFees ?? 0,
        lenderPremium: loanSummary?.lenderPremium,
        totalPremium: loanSummary?.totalPremium,
        referralFee: loanSummary?.referralFee,
      });
    }
  }, [loanSummaryStore.loanSummary, loanCommissionStore]);

  const sum = (a, b) => a + b;

  const getTotalFees = (fees) => {
    if (fees) {
      const retailCustomFees = fees.retailCustomFees
        ? JSON.parse(fees.retailCustomFees)
        : [];
      const customFeeTotal = retailCustomFees.map(customFee => parseFloat(customFee.retailValue || 0)).reduce(sum, 0);
      return customFeeTotal +
        parseFloat(fees.retailProcessingFee ?? 0) +
        parseFloat(fees.retailAdminFee ?? 0) +
        parseFloat(fees.retailUnderwritingFee ?? 0) +
        parseFloat(fees.retailCommitmentFee ?? 0) +
        parseFloat(fees.retailBuydownFee ?? 0);
    }
  }

  return (
    <Grid item xs={12} md={6}>
      {loanSummary?.loanType === LoanType.RESIDENTIAL_BRIDGE && (
        <Paper title="COMMISSIONS" className={classes.paper}>
          <Box>
            <List>
              {listItem('Loan Amount', formatCurrency(loanSummary?.amount ?? 0))}
              {divider}
              {listItem('Rate', formatPercentage(loanSummary?.rocRate ?? 0))}
              {divider}
              {listItem('Points', formatPercentage(loanSummary?.rocPointsIn ?? 0))}
              {divider}
              {listItem('Underwriting Fee', formatCurrency(loanSummary?.underwritingFee ?? 0))}
              {divider}
              {listItemBold('Your Commission %', formatPercentage(commissionData?.commissionPercentage ?? 0, 4))}
              {divider}
              {loanSummary?.loanSubtype !== LoanSubType.MULTIFAMILY_BRIDGE_5PLUS &&
                listItemBold('Your Commission Amount', formatCurrency(commissionData?.commissionAmount ?? 0))}
              {loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS && divider}
              {loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS &&
                listItemBold('Your Closing Commission Amount', formatCurrency(commissionData?.entryCommissionAmount ?? 0))}
              {(loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS && commissionData?.exitCommissionAmount > 0) && divider}
              {(loanSummary?.loanSubtype === LoanSubType.MULTIFAMILY_BRIDGE_5PLUS && commissionData?.exitCommissionAmount > 0) &&
                listItemBold('Your Payoff Commission Amount', formatCurrency(commissionData?.exitCommissionAmount ?? 0))}
            </List>
          </Box>
        </Paper>
      )}
      {loanSummary?.loanType === LoanType.RESIDENTIAL_TERM && (
        <Paper title="COMMISSIONS" className={classes.paper}>
          <Box>
            <List>
              {listItem('Loan Amount', formatCurrency(loanSummary?.amount ?? 0))}
              {divider}
              {listItem('Yield Spread Premium', formatPercentage(loanSummary?.lenderPremium ?? 0))}
              {divider}
              {listItem('Add-on Origination Points', formatPercentage(loanSummary?.loanRetailFees?.retailPoints ?? 0))}
              {divider}
              {listItem('Rate Buydown Points', formatPercentage(loanSummary?.rocPointsIn ?? 0))}
              {divider}
              {listItem('Total Fees', formatCurrency(getTotalFees(loanSummary?.loanRetailFees) ?? 0))}
              {divider}
              {listItemBold('Your Commission %', formatPercentage(commissionData?.commissionPercentage ?? 0, 4))}
              {divider}
              {listItemBold('Your Commission Amount', formatCurrency(commissionData?.commissionAmount ?? 0))}
            </List>
          </Box>
        </Paper>
      )}
    </Grid>
  );
});