import { Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { TaxCertificateComponent } from '../components/taxCertificateComponent';

export const getTaxRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanId: any,
  type: any = '',
) => {
  return {
    taxCertificateComponent: {
      path: `${basePath}/taxes/tax-certificate`,
      url: `${baseUrl}/taxes/tax-certificate`,
      documentTitle: 'Tax Certificate',
    },
  };
};

export const getTaxRoutes = (basePath: string, baseUrl: string, loanId: any) => {
  const config = getTaxRoutesConfig(basePath, baseUrl, loanId);

  return {
    taxCertificateComponent: (
      <Route exact path={config.taxCertificateComponent.path} >
        <Page routeDefinition={config.taxCertificateComponent}>
          <TaxCertificateComponent />
        </Page>
      </Route>
    ),
  };
};
