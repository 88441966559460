import { SelectField, ConfirmDialog, DateField } from "@roc/ui";
import { ICellEditor, ICellEditorParams } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useReceivablesDashboardStore } from '../../hooks/useReceivablesDasboardStore';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    borderRadius: '0',
    '& .MuiSelect-root': {
      paddingTop: '11px',
      paddingBottom: '11px',
      fontSize: '14px',
    },
  },
}));

export const StatusEditorReceivablesBills = forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const { value } = props;
  const { receivablesBillsStore } = useReceivablesDashboardStore();
  const [editableValue, setEditableValue] = useState(value);
  const [paymentDate, setPaymentDate] = useState(null);
  const [prevEditableValue, setPrevEditableValue] = useState(value);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editableValue;
    },
  }));

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const handleCancel = () => {
    setEditableValue(prevEditableValue);
    setPaymentDate(null);
    setOpenConfirmModal(false)
  }

  const getBody = () => {
    if (editableValue === 'Paid') {
      return (
        <Grid style={{ paddingTop: '25px', paddingBottom: '25px' }} item xs={12} md={6}>
          <Box>
            <Typography style={{ paddingBottom: '5px' }} variant="h6">Choose a payment date</Typography>
          </Box>
          <Box>
            <DateField
              inputVariant="outlined"
              standaloneLabel
              format='MM/dd/yyyy'
              fullWidth
              value={paymentDate}
              testId={'paymentDate'}
              onChange={date => setPaymentDate(date)}
            />
          </Box>
        </Grid>
      )
    } else {
      return <>Are you sure you want to reject this bill?</>
    }
  }

  return (
    <>
      {
        editableValue !== 'Paid' ? (
          <SelectField
            options={[
              { label: 'Billed', value: 'Billed' },
              { label: 'Paid', value: 'Paid' },
              { label: 'Rejected', value: 'Rejected' },
            ]}
            testId={'foundation'}
            value={editableValue}
            onChange={(value) => {
              setPrevEditableValue(editableValue);
              setEditableValue(value);
              setOpenConfirmModal(true);
            }}
            fullWidth
            variant="outlined"
            className={classes.selectField}
          />
        ) : (
          <SelectField
            options={[
              { label: 'Paid', value: 'Paid' },
            ]}
            testId={'foundation'}
            value={editableValue}
            onChange={(value) => {
              setEditableValue(value);
            }}
            fullWidth
            variant="outlined"
            className={classes.selectField}
          />
        )
      }
      <ConfirmDialog
        open={openConfirmModal}
        title={editableValue === 'Paid' ? 'Mark bill as paid' : 'Reject bill'}
        body={getBody}
        confirmButtonText={'Confirm'}
        handleCancel={handleCancel}
        handleClose={handleCancel}
        handleConfirm={async () => {
          await receivablesBillsStore.performBillAction(props?.data?.recordId, editableValue, paymentDate == null ? null : format(paymentDate, 'yyyy-MM-dd'));
          receivablesBillsStore.counterPartyExpenseGridStore.resetAndFetchGridData();
          setOpenConfirmModal(false);
        }}
      />
    </>


  );
});
