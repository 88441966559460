import { Snackbar, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button } from "@roc/ui";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useBaseStore } from "./hooks";
import { isElectronApp, isMobileApp } from "@roc/feature-utils";

export const RegisterWorker = observer(() => {

  const { workerStore, globalStore } = useBaseStore();
  const { showNewVersionBanner } = workerStore;

  const [seconds, setSeconds] = useState<number>();
  const [timer, setTimer] = useState<any>();

  const startTimer = () => {
    clearInterval(timer);
    let _seconds = 60;
    const _timer = setInterval(() => {
      if (_seconds > 0) {
        _seconds--;
        setSeconds(_seconds);
      } else {
        clearInterval(_timer);
        refreshPage();
      }
    }, 1000);
    setTimer(_timer);
  }

  useEffect(() => {
    if (window.Worker && !isMobileApp() && !isElectronApp()) {
      const myWorker = new Worker("worker.js");
      console.log('Main: WebWorker Initialized');

      myWorker.onmessage = function (event) {
        if (event.data.eventType === 'BUILD_TIME_CHANGED') {
          //const buildTimeFromServer = event.data.buildTimeFromServer;
          workerStore.setShowNewVersionBanner(true);
        }
        /* else if (event.data.eventType = 'ONLINE_STATUS_CHANGED') {
          const isOnline = event.data.isOnline;
          workerStore.setIsOnline(isOnline);
        } */
        else {
          console.log('Main: eventType not configured. Event Data: ' + event.data);
        }
      }

      if (!isMobileApp() && !isElectronApp()) {
        console.log('Main: Configure EventType: MONITOR_BUILD_TIME_CHANGES');
        myWorker.postMessage({
          eventType: 'MONITOR_BUILD_TIME_CHANGES',
          baseUrl: globalStore.routeBaseUrl
        });
      }

      /* if (isElectronApp()) {
        console.log('Main: Configure EventType: MONITOR_ONLINE_STATUS');
        myWorker.postMessage({
          eventType: 'MONITOR_ONLINE_STATUS',
          restApiBaseURL: (window as any).CHAT_UI_GLOBAL_DATA.restApiBaseURL
        });
      } */

    }
    else {
      console.log('Main: Your browser doesn\'t support web workers.');
    }
  }, []);

  useEffect(() => {
    if (showNewVersionBanner) {
      startTimer();
    }
  }, [showNewVersionBanner])

  const refreshPage = () => {
    clearInterval(timer);
    window.location.reload();
  };

  return (
    <Snackbar open={showNewVersionBanner}>
      <Alert severity="info" style={{
        border: '2px solid #285391',
        borderRadius: 8,
      }}>
        <AlertTitle><strong>New version available</strong></AlertTitle>
        <Typography gutterBottom>There is a newer version of the portal available and the page will be automatically refreshed in <span style={{
          fontSize: 18
        }}><strong>{seconds}</strong></span> seconds.</Typography>
        <Button testId="refresh" color="primary" size="small" variant="contained" onClick={refreshPage}>
          Refresh
        </Button>
      </Alert>
    </Snackbar>
  );
});