import React from 'react';
import { Box, Tab, Tabs as MuiTabs, Badge, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { TestDataAttribute } from '../testDataAttribute';
import { PORTAL_COLORS, sanitizeTestId } from '@roc/feature-utils';
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -10,
    top: -2,
    border: `2px solid #fff`,
    padding: '4px',
    fontSize: '13px',
    backgroundColor: PORTAL_COLORS.pendingDocuments,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
    height: 'auto',
    opacity: '1 !important',
  },
}))(Badge);

export type Tab = {
  label: string;
  right?: React.ReactNode;
  showBadge?: boolean;
  disabled?: boolean;
};

export type TabsProps = {
  className?: string;
  tabs: Tab[];
  selectedTab: number;
  onTabChange: (e: any, index: number) => void;
  right?: React.ReactNode;
  variant?: any;
  scrollButtons?: any;
  tabClassName?: string;
  tabSelectedClassName?: string;
};

const useToolbarStyles = makeStyles(() => {
  return {
    root: {
      width: '100%',
      background: '#fff',
      border: '1px solid #ddd',
    },
  };
});

const useTabsStyles = makeStyles(({ spacing, palette }: Theme) => {
  return {
    root: {
      width: '100%',
      padding: spacing(0, 1),
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& span': {
        maxWidth: 60,
        width: '100%',
        background: palette.primary.main,
      },
    },
    disabled: {
      color: palette.text.disabled,
    },
  };
});

const useTabItemStyles = makeStyles(({ spacing, breakpoints }: Theme) => {
  const tabsGutter = spacing(2);
  return {
    root: {
      textTransform: 'uppercase',
      padding: `0 ${tabsGutter}px`,
      minWidth: 0,
      [breakpoints.up('md')]: {
        minWidth: 0,
      },
      '&.MuiButtonBase-root.MuiTab-root': {
        opacity: '1 !important',
      },
    },
    selected: {
      zIndex: 1,
    },
    wrapper: {
      letterSpacing: 0.5,
    },
  };
});

const useLoanRequestTabItemStyles = makeStyles(({ spacing, breakpoints }: Theme) => {
  const tabsGutter = spacing(2);
  return {
    root: {
      textTransform: 'uppercase',
      padding: `0 ${tabsGutter}px`,
      minWidth: '165px !important',
      [breakpoints.up('md')]: {
        minWidth: 0,
      },
      '&.MuiButtonBase-root.MuiTab-root': {
        opacity: '1 !important',
      },
    },
    selected: {
      zIndex: 1,
    },
    wrapper: {
      fontWeight: 'bold',
      letterSpacing: 0.5,
    },
  };
});

export const Tabs = ({
  className,
  tabs,
  selectedTab,
  onTabChange,
  right,
  variant,
  scrollButtons,
  tabClassName,
  tabSelectedClassName,
}: TabsProps) => {
  const toolbarStyles = useToolbarStyles();
  const tabsStyles = useTabsStyles();
  const tabItemStyles = useTabItemStyles();
  const loanRequestTabItemStyles = useLoanRequestTabItemStyles();
  const testId = sanitizeTestId;

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx([toolbarStyles.root, className])}
    >
      <Box flexGrow={1} py={1}>
        <MuiTabs
          classes={tabsStyles}
          TabIndicatorProps={{ children: <span /> }}
          value={selectedTab}
          onChange={onTabChange}
          variant={variant}
          scrollButtons={scrollButtons}
        >
          {tabs.map(tab => (
            <Tab
              key={tab.label}
              classes={tab.label === 'LOAN REQUESTS' ? loanRequestTabItemStyles : tabItemStyles}
              className={clsx(tabClassName, tab.label == tabs[selectedTab].label ? tabSelectedClassName : '')}
              disableRipple
              disabled={tab.disabled}
              label={
                <TestDataAttribute id={testId(tab.label)}>
                  <Box className={clsx(tab.disabled && tabsStyles.disabled)}>
                    {tab.showBadge ?
                      <StyledBadge badgeContent={tab.showBadge}>
                        {tab.label}
                        {tab.right}
                      </StyledBadge>
                      :
                      <>
                        {tab.label}
                        {tab.right}
                      </>
                    }
                  </Box>
                </TestDataAttribute>
              }
            />
          ))}
        </MuiTabs>
      </Box>
      {right}
    </Box>
  );
};
