import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { createRadioField, createRadioGroupFieldWithParameters } from 'libs/client-portal-shared/src/app/components/formComponents/common/componentBuilder';
import { Card } from '@roc/ui';
import { yesNoRadioOptions } from '@roc/feature-sow-shared/constants/scopeOfWorkV2Form';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },
  gridStyle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
  radioGroupStyle: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));



export const HighValuePreferences = observer(({ companyProfileStore }) => {
  const { lenderDetailsStore } = companyProfileStore;
  const { lenderDetailsFormStore, salesforceLenderStore, globalStore } = lenderDetailsStore;
  const { portal, internalUser, isDecisionCommittee } = salesforceLenderStore;
  const store = lenderDetailsFormStore;
  const [editHighValueClient, setEditHighValueClient] = useState(false);
  const classes = useStyles();

  const handleSaveClick = async () => {
    lenderDetailsStore.saveHighValueClientValue();
    setEditHighValueClient(false);
  };

  const handleCancel = () => {
    setEditHighValueClient(false);
  };

  const isLead = portal === 'Internal'
    ? ((internalUser.roles.includes('decisionCommittee') || internalUser.roles.includes('salesManager') || internalUser.roles.includes('tech') || internalUser.roles.includes('techAdmin')) || isDecisionCommittee)
    : globalStore.userFeatures.backOfficeLead;

  const cardProps = isLead ? { onEdit: () => setEditHighValueClient(true) } : {};

  return (
    <Card title='HIGH VALUE CLIENT PREFERENCES' cardContentProps={{ style: { padding: 0 } }} className={classes.card} {...cardProps}>
      <Box p={4}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <Box flex={1} mr={2}>
            {createRadioField({
              store,
              testId: 'highValueClient',
              fieldName: 'highValueClient',
              label: 'Is this TPO a High Value Client?',
              options: yesNoRadioOptions,
              row: true,
              disabled: !editHighValueClient
            })}
          </Box>
        </Box>
        {editHighValueClient && (
          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              style={{ marginRight: 16 }}
              variant="outlined"
              onClick={handleCancel}
              color="default"
              className={classes.buttonStyle}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveClick}
              color="primary"
              variant="contained"
              className={classes.buttonStyle}
            >
              Save
            </Button>
          </Grid>
        )}
      </Box>
    </Card>
  );
});

export default HighValuePreferences;