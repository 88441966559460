import { GridColumnProps, LinkColumn } from '@roc/ui';
import { observer } from 'mobx-react';
import { useLoanStore } from '@roc/feature-loans';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import {
  filterProps,
  noOpComparator
} from '@roc/ui/utils';
import { InfiniteScrollGrid } from '@roc/feature-app-core';
import { formatPhoneNumber } from '@roc/ui/utils';
import { Roles } from '@roc/feature-utils';

export const foreclosureLoansColumns: GridColumnProps[] = [
  {
    field: 'dealid',
    headerName: 'Loan Id',
    minWidth: 100,
    maxWidth: 125,
    cellRenderer: 'loanIdCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'lenderType',
    headerName: 'Lender Type',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'delinquencyStatus',
    headerName: 'Status',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'loanType',
    headerName: 'Loan Type',
    minWidth: 175,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'dealName',
    headerName: 'Deal Name',
    minWidth: 375,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'borrowerName',
    headerName: 'Borrower Name',
    minWidth: 225,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'borrowerPhone',
    headerName: 'Borrower Phone',
    minWidth: 175,
    cellRenderer: 'phoneCellRenderer',
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'payString',
    headerName: 'Pay String',
    minWidth: 180,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'lenderName',
    headerName: 'Lender Name',
    minWidth: 225,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'servicerName',
    headerName: 'Servicer Name',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'servicerPhone',
    headerName: 'Servicer Phone',
    minWidth: 175,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'servicerUrl',
    headerName: 'Servicer URL',
    minWidth: 275,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'closingDate',
    headerName: 'Closing Date',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'nextDueDate',
    headerName: 'Next Due Date',
    minWidth: 150,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'foreclosureStartDate',
    headerName: 'Foreclosure Start Date',
    minWidth: 175,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'foreclosureEndDate',
    headerName: 'Foreclosure End Date',
    minWidth: 175,
    comparator: noOpComparator,
    ...filterProps(),
  },
];

export const DelinquentLoansDashboard = observer(() => {
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const { userStore } = loanStore;

  const frameworkComponents = {
    loanIdCellRenderer: params => {
      const loanId = params.node.data?.dealid;
      return LinkColumn({
        ...params,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      });
    },
    phoneCellRenderer: ({ value }) => formatPhoneNumber(value),
  };

  if(userStore?.userInfo?.role === Roles.BACK_OFFICE_LEAD) {
    foreclosureLoansColumns.push({
      field: 'primaryRelationshipManager',
      headerName: 'Primary RM',
      minWidth: 175,
      comparator: noOpComparator,
      ...filterProps(),
    });
  }

  return (
    <InfiniteScrollGrid
      columns={foreclosureLoansColumns}
      store={loanStore.delinquentLoansGridStore}
      frameworkComponents={frameworkComponents}
    />
  );
});
