import { action, flow, makeObservable, observable } from 'mobx';
import { CreateOrResetPasswordService } from '../services';
import { ApiResponse } from '@roc/client-portal-shared/services';
import { GlobalStore, FormStore, LoginStore } from '@roc/feature-app-core';

const form = {
  fields: {
    password: {
      value: '',
      error: null,
      rule: 'required',
    },
    confirmPassword: {
      value: '',
      error: null,
      rule: 'required',
    },
    passwordsMatch: {
      value: '',
      error: null,
      rule: 'same:password|same:confirmPassword',
      message: 'Password and confirm password match',
    },
    passwordMin: {
      value: '',
      error: null,
      rule: 'min:6',
      message: '6 characters minimum',
    },
    passwordSpecialOrNumber: {
      value: '',
      error: null,
      rule: [
        'regex:/[~`!@#$%^&*()_\\-+={[}\\]|\\\\:;"\'<,>.?\\/  ]/',
        'regex:/\\d/',
      ],
      message: 'One number and one special character',
    },
    passwordLowerUpper: {
      value: '',
      error: null,
      rule: ['regex:/[A-Z]/', 'regex:/[a-z]/'],
      message: 'One lowercase and one capital character',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class CreateOrResetPasswordStore extends FormStore {
  private globalStore: GlobalStore;
  public loginStore: LoginStore;
  private createOrResetPasswordService: CreateOrResetPasswordService;
  token: string = undefined;
  isTokenValid = undefined;
  isTokenNotExpired = undefined;
  successUrl = undefined;
  showSuccessResendNewLink: boolean = false;
  username: string;

  constructor(globalStore) {
    super({ ...form }, globalStore);
    this.globalStore = globalStore;
    this.loginStore = new LoginStore(this.globalStore);
    this.createOrResetPasswordService = new CreateOrResetPasswordService();
    makeObservable(this, {
      token: observable,
      isTokenValid: observable,
      isTokenNotExpired: observable,
      showSuccessResendNewLink: observable,
      setToken: action,
      setSuccessUrl: action,
      validateToken: flow,
      onFormSubmit: flow,
      resendNewLink: flow,
      username: observable,
    });
  }

  setToken(token) {
    this.token = token;
  }

  setSuccessUrl(successUrl) {
    this.successUrl = successUrl;
  }

  *validateToken() {
    try {
      const response: ApiResponse = yield this.createOrResetPasswordService.validateToken(
        this.token
      );
      this.isTokenNotExpired = response.data?.data ?? false;
      this.isTokenValid = true;
    } catch (error) {
      this.isTokenNotExpired = undefined;
      this.isTokenValid = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Invalid Token',
      });
    }
  }

  *resendNewLink() {
    try {
      const response: ApiResponse = yield this.createOrResetPasswordService.resendNewLink(
        this.token
      );
      this.showSuccessResendNewLink = true;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error resending new link',
      });
    }
  }

  *onFormSubmit() {
    const { password } = this.form.fields;
    try {
      const response = yield this.createOrResetPasswordService.updatePassword(
        this.token,
        password.value
      );
      if (response?.data?.data) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Password updated successfully!',
        });
        this.username = response.data.data;
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error updating password',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error updating password',
      });
    }
  }
}

export default CreateOrResetPasswordStore;
