import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TitleDivider } from "../titleDivider";
import { ValuationForm } from "../valuationForm";
import { observer } from "mobx-react";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";

const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    backgroundColor: "#FFFFFF",
    padding: "2rem",
  },
}));

interface ValuationTabProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ValuationTab = observer(({ internalValuationReviewStore }: ValuationTabProps) => {
  const classes = useStyles();

  const readOnly = internalValuationReviewStore.readOnly;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      className={classes.tabContent}
    >
      <TitleDivider title="Value Reconciliation" />
      <ValuationForm valuationFormStore={internalValuationReviewStore.valuationFormStore} readOnly={readOnly} />
    </Box>
  );
});
