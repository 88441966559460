import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { InsuranceInformationService } from '../services';
import { InsuranceInformationBaseStore } from './insuranceInformationBaseStore';
import { InsuranceQuoteFormStore } from './insuranceQuoteFormStore';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { FinalDocumentType } from '../util';
import ResendQuoteStore from './resendQuoteStore';

export class InsuranceInformationStore extends InsuranceInformationBaseStore {
  insuranceInformationService: InsuranceInformationService;

  constructor(
    globalStore: GlobalStore,
  ) {
    super(globalStore);
    this.insuranceInformationService = new InsuranceInformationService();
    this.insuranceQuoteFormStore = new InsuranceQuoteFormStore(false, this, globalStore);
    this.resendElmsureQuoteStore = new ResendQuoteStore(this.globalStore, this, false);
  }

  *getSelectedQuotesByLoanId() {
    try {
      const response = yield this.insuranceInformationService.getSelectedQuotesByLoanId(this.loanId);
      this.setFinalChoiceQuotes(JSON.parse(response?.data?.data));
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving selected insurance quotes.',
      });
    }
  }

  *getAvailableQuotesByLoanId() {
    try {
      const response = yield this.insuranceInformationService.getAvailableQuotesByLoanId(this.loanId);
      this.setQuotes(JSON.parse(response?.data?.data));
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving insurance quote details.',
      });
    }
  }

  *getPropertiesMissingQuotes() {
    try {
      const response = yield this.insuranceInformationService.getPropertiesMissingQuotes(this.loanId);
      this.propertiesMissingQuotes = JSON.parse(response?.data?.data) ?? [];
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while retrieving properties with quote details.',
      });
    }
  }

  *uploadInsuranceQuoteDocuments(documents) {
    try {
      const formData = new FormData();
      const loanDocumentId = this.loanDocumentId;
      formData.append('loanDocumentId', loanDocumentId.toString());
      formData.append('insuranceQuoteDetailId', this.currentQuote?.insuranceQuoteDetailId);
      documents.forEach(document => {
        const blob = document?.file.slice(0, document?.file.size, document?.file.type);
        const newFile = new File([blob], document?.name, { type: document?.file.type });
        formData.append('file', newFile);
      });
      const response = yield this.insuranceInformationService.uploadInsuranceQuoteDocuments(formData);
      this.getAvailableQuotesByLoanId();
      this.getSelectedQuotesByLoanId();
    } catch (error) {
      console.error(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while uploading the document(s).',
      });
    }
  }

  *saveInsuranceQuoteDetails(quote) {
    try {
      const quoteJson = {
        ...this.currentQuote,
        ...quote,
        loanId: this.loanId,
      };
      const data = new Blob([JSON.stringify(quoteJson)], { type: 'application/json' });
      const formData = new FormData();
      formData.append('loanDocumentId', this.loanDocumentId.toString());
      formData.append('data', data);
      if (this.isSaveInsuranceQuoteActive) {
        quote?.documents?.forEach(document => {
          const blob = document?.file.slice(0, document?.file.size, document?.file.type);
          const newFile = new File([blob], document?.name, { type: document?.file.type });
          formData.append('file', newFile);
        });
      }
      const response = yield this.insuranceInformationService.saveInsuranceQuoteDetail(formData);
      this.getAvailableQuotesByLoanId();
      this.getSelectedQuotesByLoanId();
      this.getPropertiesMissingQuotes();
      this.handleCloseSave();
    } catch (e) {
      const { error } = e;
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: error?.response?.data?.error?.message || GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *downloadInsuranceQuoteDocument(currentDocument) {
    try {
      const blob = yield this.insuranceInformationService.downloadInsuranceQuoteDocument(currentDocument?.insuranceQuoteDocumentId);
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        if (currentDocument?.name.includes('pdf')) {
          // Preview the PDF
          this.pdfTitle = currentDocument?.name;
          this.pdfData = reader.result; // The ArrayBuffer
          this.isPdfPreviewModalOpen = true;
        } else {
          // Download the file if not a PDF
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = currentDocument?.name;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };
    } catch (error) {
      console.error(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while downloading the document.',
      });
    }
  }

  *getLoanDetails(loanId: string) {
    try {
      const response: ApiResponse = yield this.insuranceInformationService.getLoanDetails(loanId);
      this.loanDetails = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  getPropertiesOptions() {
    this.propertiesOptions = this.loanDetails?.properties?.map(property => ({
      label: property?.address,
      value: property?.propertyId,
    })) ?? [];
    if (this.propertiesOptions.length > 1) {
      this.propertiesOptions.unshift({ label: 'All Properties', value: 'All' });
      this.insuranceQuoteFormStore.onFieldChange('properties', ['All']);
      return;
    }
    this.insuranceQuoteFormStore.onFieldChange('properties', this.propertiesOptions.map(o => o.value));
  }

  validateQuote(quote: any) {
    this.insuranceQuoteFormStore.runFormValidation();
    if ((quote?.documents.length == 0 && !quote?.insuranceQuoteDetailId) || !this.insuranceQuoteFormStore.form.meta.isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please fill out the required fields in the quote',
      });
      return false;
    }
    return true;
  }

  *requestQuoteFromElmsure() {
    try {
      yield this.insuranceInformationService.requestElmsureQuote(this.loanId, this.insuranceQuoteFormStore.getRequestElmsureQuoteValues());
      this.getAvailableQuotesByLoanId();
      this.getSelectedQuotesByLoanId();
      this.getPropertiesMissingQuotes();
      this.insuranceQuoteFormStore.reset();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'An error has occurred requesting a quote from elmsure',
      });
    }
  }

  *deleteQuoteDocument(quoteDocumentId: number) {
    try {
      yield this.insuranceInformationService.deleteQuoteDocument(quoteDocumentId);
      yield this.getAvailableQuotesByLoanId();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error deleting quote document',
      });
    }
  }

  deleteQuote(quoteId: number) { }
  approveInsurance() { }

  *resendElmsureQuote() {
    try {
      if (this.resendElmsureQuoteStore.runFormValidationWithMessage()) {
        yield this.insuranceInformationService.resendQuote(this.loanId, this.resendElmsureQuoteStore.getValue('emailAddress'));
        this.resendElmsureQuoteModal = false;
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Elmsure quote has been resent to the borrower'
        });
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error resending the elmsure quote to the borrower',
      });
    }
  }

  *getFinalDocuments() {
    try{
      return yield this.insuranceInformationService.getFinalDocuments(this.loanId);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting final documents',
      });
    }
  }

  *createFinalDocument(type: FinalDocumentType, insuranceQuoteDocumentIds: number[]) {
    try {
      return yield this.insuranceInformationService.createFinalDocument(this.loanDocumentId, type, insuranceQuoteDocumentIds);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error creating final document',
      });
    }
  }

  *downloadFinalDocument(type: FinalDocumentType) {
    try {
      const blob = yield this.insuranceInformationService.downloadFinalDocument(
        type,
        this.loanId
      );
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => {
        this.pdfTitle = type === FinalDocumentType.BINDER ? "Final Binder": type === FinalDocumentType.INVOICE ? "Final Invoice" : "Flood Certificate";
        this.pdfData = reader.result; // The ArrayBuffer
        this.isPdfPreviewModalOpen = true;
      };
    } catch (error) {
      console.error(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'We had an issue while downloading the document.',
      });
    }
  }
}
