import { Box, Grid, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { Layout, Page } from '@roc/feature-app-core';
import { Route } from 'react-router';
import { ResolutionForm } from '../components/utils/resolutionForm';
import { EmbeddedFrameBoundaries } from '@roc/ui';
import { Help } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useResolutionStore } from '../hooks/useResolutionStore';
import { useEffect } from 'react';

export const getResolutionRoutesConfig = (
  basePath: string,
  baseUrl: string,
  loanTaskId: string
) => {
  return {
    resolution: {
      path: `${basePath}/resolution-review`,
      url: `${baseUrl}/resolution-review`,
      documentTitle: 'Resolution',
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  tooltip: {
    fontSize: '18px',
    marginLeft: '3px',
    marginBottom: '10px'
  }
}));

export const getResolutionRoutes = (
  basePath: string,
  baseUrl: string,
  loanTaskId: string,
  readOnly?: string
) => {
  const config = getResolutionRoutesConfig(basePath, baseUrl, loanTaskId);
  return {
    resolution: (
      <Route exact path={config.resolution.path}>
        <Page routeDefinition={config.resolution}>
          <ResolutionItemInternal config={config} loanTaskId={loanTaskId} readOnly={readOnly === "true"} />
        </Page>
      </Route>
    ),
  };
};

export type ResolutionItemInternalProps = {
  config: any,
  loanTaskId: string,
  readOnly: boolean

};

export const ResolutionItemInternal = observer((
  props: ResolutionItemInternalProps
) => {
  const classes = useStyles();
  const { loanTaskId } = props
  const { resolutionStore } = useResolutionStore();
  const { tooltTipTitle } = resolutionStore;

  return (
    <Box pl={3} pr={3} style={{ background: 'white' }}>
      <Layout title={
        <div>
          Provide Resolution
          {resolutionStore.resolution?.objectType != 'Insurance Review' && <Tooltip
            placement="left-end"
            title={<p style={{ fontSize: '12px' }}> {tooltTipTitle} </p>}
          >
            <Help color="primary" className={classes.tooltip} />
          </Tooltip>}
        </div>
      } containerClass={classes.container}>
        <EmbeddedFrameBoundaries>
          <ResolutionForm
            loanTaskId={loanTaskId}
            handleClose={() => { window.parent.postMessage('saved-success', '*'); }}
            internal={true}
            readOnly={props?.readOnly}
          />
        </EmbeddedFrameBoundaries>
      </Layout>
    </Box>
  )
});
