import { IconButton, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { HelpOutline } from '@material-ui/icons';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { SupportTasksDialog } from './supportTasksDialog';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { useTechSupportCommunicationStore } from '@roc/feature-communication';


export const SupportIconButton = observer(() => {
  const { userStore } = useUserStore();
  const { globalStore } = useBaseStore();
  const { companyId } = userStore?.userInformation;
  const { techSupportCommunicationStore } = useTechSupportCommunicationStore();

  const { generalTasksStore } = useGeneralTasksStore();
  const { supportTasksStore } = generalTasksStore;

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={() => {
          supportTasksStore.setModalOpen(true);
          if (companyId) {
            techSupportCommunicationStore.createTechSupportChat(companyId)
          } else {
            techSupportCommunicationStore.createTechSupportChat(globalStore.selectedCompanyId)
          }
          supportTasksStore.fetchUnreadMessagesCount();
        }}
        style={{ padding: '0px' }}
      >
        <Typography variant="body2" style={{ marginRight: '5px' }}>
          Support
        </Typography>
        <HelpOutline fontSize='medium' color={'primary'} />
      </IconButton>
      <SupportTasksDialog />
    </>
  );
}
);
