import { FormStore, GlobalStore } from '@roc/feature-app-core';
import { Participant } from '@twilio/conversations';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import {
  AddParticipantRequest,
  ParticipantAttribute,
  ParticipantDetails,
  UserOption,
} from '../types/communicationTypes';
import { CommunicationService } from './../services/communicationService';
import { CommunicationStore } from './communicationStore';

const externalParticipantForm = {
  fields: {
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
      message: 'Email is required in correct format',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'First Name is required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
      message: 'Last Name is required',
    },
    participantType: {
      value: 'LoanChat',
      error: null,
      rule: 'required',
      message: 'Communicate via is required',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};
export class ManageParticipantsStore {
  globalStore: GlobalStore;
  communicationStore: CommunicationStore;
  open = false;
  openAddParticipant = false;
  externalParticipantStore: FormStore;
  communicationService: CommunicationService;
  internalParticipants = [];
  userIdParticipantDetailsMap: Record<number, ParticipantDetails> = {};
  userIdCalendlyLinkMap: Record<number, string> = {};
  selectedParticipant: UserOption[];

  constructor(globalStore, communicationStore, communicationService) {
    this.globalStore = globalStore;
    this.communicationStore = communicationStore;
    this.communicationService = communicationService;
    this.externalParticipantStore = new FormStore(
      externalParticipantForm,
      this.globalStore
    );
    makeObservable(this, {
      open: observable,
      openAddParticipant: observable,
      externalParticipantStore: observable,
      internalParticipants: observable,
      userIdParticipantDetailsMap: observable,
      userIdCalendlyLinkMap: observable,
      selectedParticipant: observable,
      reset: action,
      resetAddParticipantState: action,
      openDialog: action,
      closeDialog: action,
      openAddParticipantDialog: action,
      closeAddParticipantDialog: action,
      getParticipantsAllowedByLoanId: flow,
      getParticipantsAllowedByChatRoomId: flow,
      addExistingParticipant: flow,
      addNewParticipant: flow,
      addParticipant: flow,
      removeParticipant: flow,
      leaveConversation: flow,
      getParticipantsDetails: action,
      mergeAllowedAndExistingParticipants: action,
      convertParticipantToUserOption: action,
      getParticipantsCalendlyLinks: action,
    });
  }

  reset() {
    this.resetAddParticipantState();
    this.open = false;
    this.internalParticipants = [];
    this.userIdParticipantDetailsMap = {};
  }

  resetAddParticipantState() {
    this.externalParticipantStore.reset();
    this.openAddParticipant = false;
    this.selectedParticipant = null;
  }

  openDialog = () => {
    this.open = true;
  };

  closeDialog = () => {
    this.open = false;
  };

  openAddParticipantDialog = () => {
    this.openAddParticipant = true;
  };

  closeAddParticipantDialog = () => {
    this.openAddParticipant = false;
  };

  *getParticipantsAllowedByLoanId(loanId, disableGlobalLoading = false) {
    const allowedParticipants: UserOption[] = yield this.communicationStore.getParticipantsAllowedByLoanId(
      loanId,
      disableGlobalLoading
    );
    const currentParticipants = this.communicationStore.participants[
      this.communicationStore.currentConversationSid
    ];
    const activeParticipants = currentParticipants.filter(participant => {
      const { userId } = participant.attributes as ParticipantAttribute;
      const userInfo = this.userIdParticipantDetailsMap[userId];
      return userInfo && userInfo.active;
    });
    this.internalParticipants = this.mergeAllowedAndExistingParticipants(
      allowedParticipants,
      activeParticipants
    );
  }

  *getParticipantsAllowedByChatRoomId(
    chatRoomId,
    disableGlobalLoading = false
  ) {
    const allowedParticipants: UserOption[] = yield this.communicationStore.getParticipantsAllowedByChatRoomId(
      chatRoomId,
      disableGlobalLoading
    );
    const currentParticipants = this.communicationStore.participants[
      this.communicationStore.currentConversationSid
    ];
    const activeParticipants = currentParticipants.filter(participant => {
      const { userId } = participant.attributes as ParticipantAttribute;
      const userInfo = this.userIdParticipantDetailsMap[userId];
      return userInfo && userInfo.active;
    });
    this.internalParticipants = this.mergeAllowedAndExistingParticipants(
      allowedParticipants,
      activeParticipants
    );
  }

  mergeAllowedAndExistingParticipants = (
    allowedParticipants: UserOption[],
    existingParticipants: Participant[]
  ) => {
    const _existingChatRoomParticipants: UserOption[] = existingParticipants.map(
      p =>
        this.convertParticipantToUserOption(
          p.attributes as ParticipantAttribute
        )
    );

    const filteredParticipants = (_existingChatRoomParticipants ?? []).filter(
      option => {
        return !allowedParticipants.some(
          participant => participant.userId === option.userId
        );
      }
    );

    const mergedOptions = [...allowedParticipants, ...filteredParticipants];

    return mergedOptions;
  };

  convertParticipantToUserOption = (
    participant: ParticipantAttribute
  ): UserOption => {
    const { firstName, lastName, userId } = participant;
    return {
      firstName,
      lastName,
      userId,
      emailAddress: '',
      role: '',
      roleDisplayName: '',
      roleDisplayColor: '',
      active: true,
    };
  };

  *addExistingParticipant(loanId) {
    if (this.selectedParticipant?.length == 0) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please select a participant to add',
      });
      return;
    }
    this.addParticipant(
      this.communicationStore.currentChatRoomId,
      null,
      loanId,
      'Participant added successfully',
      this.selectedParticipant?.map(u => u.emailAddress)
    );
  }

  *addNewParticipant(loanId) {
    if (this.externalParticipantStore.runFormValidationWithMessage()) {
      const {
        firstName,
        lastName,
        emailAddress,
        participantType,
      } = this.externalParticipantStore.getFormValues();
      const request: AddParticipantRequest = {
        chatRoomId: this.communicationStore.currentChatRoomId,
        userName: emailAddress,
        userNames: this.selectedParticipant?.map(u => u.emailAddress),
        isSystemUser: false,
        newUser: {
          firstName,
          lastName,
          participantType,
        },
        loanId,
      };
      this._addParticipant(request);
    }
  }

  *addParticipant(
    chatRoomId: number,
    userName?: string,
    loanId?: number,
    successMessage?: string,
    userNames?: string[]
  ) {
    const request: AddParticipantRequest = {
      chatRoomId: chatRoomId,
      userName: userName,
      userNames: userNames,
      isSystemUser: false,
      loanId: loanId,
    };
    yield this._addParticipant(request, successMessage);
    yield this.getParticipantsDetails(chatRoomId);
  }

  private async _addParticipant(
    request: AddParticipantRequest,
    successMessage: string = 'Participant added successfully'
  ) {
    try {
      await this.communicationService.addParticipant(request);
      this.globalStore.notificationStore.showSuccessNotification({
        message: successMessage,
      });
      this.resetAddParticipantState();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message:
          e?.error?.response?.data?.error?.message ||
          'Unable to add participant',
      });
    }
  }

  *removeParticipant(currentConversationSid, participant: Participant, id) {
    try {
      const isCurrentUser = this.communicationStore.isCurrentUser(
        participant.identity
      );
      yield this.communicationService.removeParticipant(
        currentConversationSid,
        participant.sid
      );
      // if the current user himself got removed as participant, then remove the conversation
      if (isCurrentUser) {
        this.communicationStore.removeConversation(currentConversationSid);
      }
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Participant removed successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to remove participant',
      });
    }
  }

  *leaveConversation(participants, currentConversationSid) {
    try {
      const loggedInParticipant = this.communicationStore.getloggedInParticpant(
        participants[currentConversationSid]
      );
      yield this.communicationService.leaveConversation(
        currentConversationSid,
        loggedInParticipant.sid
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'You left the conversation successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to leave conversation',
      });
    }
  }

  async getParticipantsDetails(
    currentChatRoomId,
    disableGlobalLoading = false
  ) {
    try {
      const response = await this.communicationService.getParticipantsDetails(
        currentChatRoomId,
        disableGlobalLoading
      );
      this.userIdParticipantDetailsMap = response?.data?.data ?? {};
      return this.userIdParticipantDetailsMap;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to get participants details',
      });
    }
  }

  async getParticipantsCalendlyLinks() {
    try {
      const response = await this.communicationService.getParticipantsCalendlyLinks();
      this.userIdCalendlyLinkMap = response?.data?.data ?? {};
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Unable to get participants calendly links',
      });
    }
  }
}
