import { Badge, Box, Grid, withStyles } from '@material-ui/core';
import { StandardDialog, Tabs, } from '@roc/ui';
import { observer } from 'mobx-react';
import { useGeneralTasksStore } from '../../hooks/useGeneralTasksStore';
import { useState, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { SupportTasksComponent } from './supportTasksComponent';
import { SupportConversations } from './supportConversations';
import { PORTAL_COLORS } from '@roc/feature-utils';
import { useUserStore } from '@roc/feature-app-core';
import { useTechSupportCommunicationStore } from '@roc/feature-communication';

export const StyledBadge = withStyles(theme => ({
  badge: {
    right: -10,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '11px',
    backgroundColor: PORTAL_COLORS.pendingDocuments,
    color: 'white',
    fontWeight: 700,
    userSelect: 'none',
  },
}))(Badge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    badge: {
      marginRight: '5px',
    },
    dialog: {
      "& .MuiDialogTitle-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "3rem",
        }
      },
    }
  }),
);

export const SupportTasksDialog = observer(() => {
  const { userStore } = useUserStore();
  const { userId, companyId } = userStore?.userInformation;
  const { generalTasksStore } = useGeneralTasksStore();
  const { supportTasksStore } = generalTasksStore;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();
  const { techSupportCommunicationStore } = useTechSupportCommunicationStore();
  const { totalTechSupportUnreadMessagesCount } = techSupportCommunicationStore;
  const { unreadCountMessages } = supportTasksStore;
  const unreadConversationCount = totalTechSupportUnreadMessagesCount + unreadCountMessages;

  useEffect(() => {
    techSupportCommunicationStore.fetchUnreadMessagesCount(userId, companyId);
    supportTasksStore.fetchUnreadMessagesCount();
  }, []);

  const TABS = [
    {
      label: 'TASKS'
    },
    {
      label: '',
      right: (
        <Box >
          <StyledBadge className={classes.badge} badgeContent={unreadConversationCount} invisible={unreadConversationCount == 0}>
            {'Conversations'}
          </StyledBadge>

        </Box>
      ),
    },
  ];

  const onTabChange = (e, index) => {
    setSelectedTabIndex(index);
    supportTasksStore.fetchUnreadMessagesCount();
  };

  return (
    <StandardDialog
      className={classes.dialog}
      title={"Support"}
      fullScreen={true}
      open={supportTasksStore.openModal}
      handleClose={() => {
        supportTasksStore.setModalOpen(false)
      }}
      dialogContent={
        <Box my={1} className={classes.container}>
          <Grid container className={classes.container} style={{
            paddingTop: 0
          }}>
            <Grid item xs={12} md={12}>
              <Tabs
                tabs={TABS}
                selectedTab={selectedTabIndex}
                onTabChange={onTabChange}
              />
            </Grid>
            {selectedTabIndex === 0 && <SupportTasksComponent />}
            {selectedTabIndex === 1 && <SupportConversations />}
          </Grid>
        </Box>
      }
    />



  );
});
