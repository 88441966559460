import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { TaxCertificateGrid } from './taxCertificateGrid';
import { Button, StandardDialog } from '@roc/ui';
import { TaxCertificateToolbar } from './taxCertificateToolbar';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },

}));



export const TaxCertificateComponent = observer(() => {
  const classes = useStyles();


  return (
    <Layout title="Tax Certificate" containerClass={classes.container}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <TaxCertificateGrid
              toolbar={
                <TaxCertificateToolbar />}
            />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
