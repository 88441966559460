import { InsuranceInformation, InsuranceInformationBaseStore } from "@roc/feature-insurance-review";

export type InsuranceInformationInternalProps = {
  store: InsuranceInformationBaseStore,
  loanId: string,
  loanDocumentId: string,
};

export const InsuranceInformationInternal = (props: InsuranceInformationInternalProps) => {
  const { store, loanId, loanDocumentId } = props;
  return (
    <InsuranceInformation
      store={store}
      loanId={loanId}
      loanDocumentId={loanDocumentId}
    />
  )
}