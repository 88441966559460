import { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Mark,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Tooltip,
  TextField as MuiTextField,
  InputProps,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { isNotBlank, YES, NO } from '@roc/feature-utils';
import {
  NumberFormat,
  TextField,
  CurrencyField,
  SelectField,
  FieldLabel,
  DateField,
  AutocompleteAddressField,
  RadioField,
  PercentageField,
  Slider,
  MultiSelectField,
  HtmlFieldProps,
  HtmlField,
  MultiSelectFieldProps,
  NumberSliderWithInput,
} from '@roc/ui';
import { ColorPicker } from 'material-ui-color';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { formatPhoneNumber, Option } from '../utils';
import InputAdornment from '@material-ui/core/InputAdornment';

interface TextFieldProps {
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number' | 'email' | 'password';
  label: string;
  subLabel?: string;
  fieldName: string;
  testId: string;
  store: any;
  multiline?: boolean;
  disabled?: boolean;
  standaloneLabel?: boolean;
  tooltip?: string;
  placeholder?: string;
  inputProps?: any;
  InputProps?: InputProps;
  minRows?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isRequired?: boolean;
}

const useStyles = makeStyles({
  linkTextField: {
    textDecoration: 'underline',
    color: '#5c94de',
    cursor: 'pointer',
  },
});

type OmittedFields = 'value' | 'onChange' | 'required';

interface ColorSelectionFieldProps {
  fieldName: string;
  store: any;
  inputRef?: any;
}

interface PhoneFieldProps {
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number' | 'email' | 'password';
  label: string;
  subLabel?: string;
  fieldName: string;
  testId: string;
  store: any;
  multiline?: boolean;
  disabled?: boolean;
  standaloneLabel?: boolean;
  tooltip?: string;
  placeholder?: string;
  format?: '(###) ###-####' | '###-###-####';
  isRequired?: boolean;
}

interface CurrencyFieldProps {
  variant?: 'outlined' | 'standard';
  label: string;
  fieldName: string;
  testId: string;
  store: any;
  disabled?: boolean;
  tooltip?: string;
  placeholder?: string;
  groupSeparator?: string;
  helperText?: string;
  allowNegative?: boolean;
  onChange?: (name: string, value: any) => void
  onBlur?: () => void
  standaloneLabel?: boolean;
}

interface CheckboxFieldProps {
  label: string;
  fieldName: string;
  store: any;
  disabled?: boolean;
}

interface SelectFieldProps {
  options?: Array<{
    label: string;
    value: any;
  }>;
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number';
  label?: string;
  subLabel?: string;
  fieldName: string;
  testId: string;
  store: any;
  disabled?: boolean;
  tooltip?: string;
  placeholder?: string;
  standaloneLabel?: boolean;
  onChange?: (value: any) => void;
  isRequired?: boolean;
}

interface AutocompleteFieldProps {
  options?: Array<{
    label: string;
    value: any;
  }>;
  variant?: 'outlined' | 'standard';
  type?: 'text' | 'number';
  label: string;
  subLabel?: string;
  fieldName: string;
  inputFieldName?: string;
  store: any;
  disabled?: boolean;
  tooltip?: string;
  placeholder?: string;
  onChange: (value: any) => void;
  clearOnBlur?: boolean;
  standaloneLabel?: boolean;
  noOptionsText?: string;
  PopperComponent?: any;
}

interface SliderFieldProps {
  label: string;
  fieldName: string;
  store: any;
  valueLabelDisplay: 'on' | 'auto' | 'off';
  valueLabelFormat?: (value: number) => React.ReactNode;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  marks?: boolean | Mark[];
  defaultValue?: number;
  disabled?: boolean;
  tooltip?: string;
  tooltipData?: any[];
  isCustomLabelTooltip?: boolean;
  CustomTooltipComponent?: React.ComponentType;
  testId: string;
  onBlur?: () => void
}

interface NumberFormatFieldProps {
  store: any;
  testId: string;
  fieldName: string;
  label: string;
  format?: string;
  mask?: any;
  disabled?: boolean;
  standaloneLabel?: boolean;
  suffix?: string;
  placeholder?: string;
  thousandSeparator?: boolean;
  allowNegative?: boolean;
  onChange?: (value: any) => void;
  maxLimit?: number;
  showErrors?: boolean;
  isRequired?: boolean;
}

interface NumberFormatFieldWithAdornmentProps {
  store: any;
  testId: string;
  fieldName: string;
  label: string;
  format?: string;
  mask?: any;
  disabled?: boolean;
  standaloneLabel?: boolean;
  suffix?: string;
  placeholder?: string;
  thousandSeparator?: boolean;
  allowNegative?: boolean;
  onChange?: (name: string, value: number | string) => void;
  maxLimit?: number;
  adornment?: string;
  adornmentPosition?: 'start' | 'end';
  decimalScale?: number;
}

interface DateFieldProps {
  store: any;
  testId: string;
  fieldName: string;
  label: string;
  disabled?: boolean;
  format?: string;
  standaloneLabel?: boolean;
  tooltip?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  isRequired?: boolean;
  onChange?: (name: string, value: any) => void
}

interface AutocompleteAddressFieldProps {
  store: any;
  testId: string;
  fieldName: string;
  label?: string;
  disabled?: boolean;
  standaloneLabel?: boolean;
  tooltip?: string;
  showToolTip?: boolean;
  onChange: (event: object) => void;
  InputLabelProps?: any;
  InputProps?: any;
  compTool?: boolean;
}

interface RadioFieldProps {
  store: any;
  testId: string;
  fieldName: string;
  label: string;
  disabled?: boolean;
  row?: boolean;
  options: Array<{
    value: any;
    label: string;
  }>;
  needHtml?: boolean;
  standaloneLabel?: boolean;
  tooltip?: string;
  onChange?: (event: object) => void;
}

interface PercentageFieldProps {
  variant?: 'outlined' | 'standard';
  label: string;
  fieldName: string;
  testId: string;
  store: any;
  disabled?: boolean;
  standaloneLabel?: boolean;
  fullWidth?: boolean;
  tooltip?: string;
  decimalScale?: number;
  inputClassName?: string;
  allowNegative?: boolean;
  limitedPercentage?: boolean;
}

interface RadioGroupFieldProps {
  store: any;
  fieldName: string;
  label: string;
  disabled?: boolean;
  boolean?: boolean;
}

interface RadioGroupFieldWithParametersProps {
  store: any;
  fieldName: string;
  label: string;
  disabled?: boolean;
  options: Option[];
}

interface CreateHtmlFieldProps extends Omit<HtmlFieldProps, OmittedFields> {
  store: any;
  fieldName: string;
};

interface CreateMultiSelectFieldProps
  extends Omit<
    MultiSelectFieldProps<Option, any, any, any>,
    OmittedFields | 'renderInput'
  > {
  store: any;
  fieldName: string;
  renderInput?: any;
  helperText?: string;
}

export const createTextField = ({
  subLabel,
  variant = 'outlined',
  type = 'text',
  label,
  fieldName,
  testId,
  store,
  multiline,
  disabled,
  standaloneLabel = true,
  tooltip,
  placeholder,
  inputProps,
  InputProps,
  minRows,
  onKeyDown = () => { },
  onClick = () => { },
  isRequired = false,
}: TextFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule?.indexOf('required') > -1 || isRequired;

  return (
    <TextField
      type={type}
      testId={testId}
      disabled={disabled}
      variant={variant}
      standaloneLabel={standaloneLabel}
      label={label}
      subLabel={subLabel}
      value={field.value}
      onChange={e => store.onFieldChange(fieldName, e.target.value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      multiline={multiline}
      required={required}
      tooltip={tooltip}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onClick={onClick}
      inputProps={inputProps}
      InputProps={InputProps}
      minRows={minRows}
    />
  );
};

export const createPhoneField = ({
  label,
  fieldName,
  testId,
  store,
  disabled,
  format = '###-###-####',
  standaloneLabel = true,
  placeholder,
  isRequired = false,
}: PhoneFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1 || isRequired;

  return (
    <NumberFormat
      testId={testId}
      disabled={disabled}
      variant="outlined"
      standaloneLabel={standaloneLabel}
      customInput={TextField}
      format={format}
      mask="_"
      label={label}
      value={field.value}
      onChange={e => store.onFieldChange(fieldName, e.target.value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      required={required}
      placeholder={placeholder}
    />
  );
};

export const createPhoneFieldClick = ({
  label,
  fieldName,
  testId,
  store,
  disabled,
  format = '###-###-####',
  standaloneLabel = true,
  placeholder,
}: PhoneFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;
  const linkUrl = `rcapp://r/call?number=${field.value}`;
  const classes = useStyles();
  return (
    <div>
      {disabled ? (
        <>
          <FieldLabel>
            {label}
          </FieldLabel>
          <MuiTextField
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
              className: classes.linkTextField,
              onClick: () => window.open(linkUrl, '_blank', 'noopener noreferrer'),
            }}
            value={formatPhoneNumber(field.value) || ''}
          />
        </>
      ) : (
        <NumberFormat
          testId={testId}
          disabled={disabled}
          variant="outlined"
          standaloneLabel={standaloneLabel}
          customInput={TextField}
          format={format}
          mask="_"
          label={label}
          value={field.value}
          onChange={(e) => store.onFieldChange(fieldName, e.target.value)}
          error={isNotBlank(field.error)}
          helperText={field.error}
          fullWidth
          required={required}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export const createColorSelectionField = ({
  fieldName,
  store,
  inputRef,
}: ColorSelectionFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  return (
    <div ref={inputRef}>
      <ColorPicker
        hideTextfield
        value={field.value}
        deferred
        onChange={value => store.onFieldChange(fieldName, `#${value.hex}`)}
      />
    </div>
  );
};

export const createCurrencyField = ({
  variant = 'outlined',
  label,
  fieldName,
  testId,
  store,
  disabled,
  tooltip,
  placeholder,
  groupSeparator,
  helperText,
  allowNegative = false,
  standaloneLabel = true,
  onChange,
  onBlur,
}: CurrencyFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  return (
    <CurrencyField
      testId={testId}
      disabled={disabled}
      variant={variant}
      standaloneLabel={standaloneLabel}
      label={label}
      value={field.value}
      onChange={(name, value) => onChange ? onChange(fieldName, value) : store.onFieldChange(fieldName, value)}
      error={isNotBlank(field.error)}
      errorText={field.error || helperText}
      fullWidth
      required={required}
      tooltip={tooltip}
      placeholder={placeholder}
      groupSeparator={groupSeparator}
      allowNegative={allowNegative}
      onBlur={onBlur}
    />
  );
};

export const createCheckboxField = ({
  label,
  fieldName,
  store,
  disabled
}: CheckboxFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  return (
    <FormControlLabel
      control={
        <Checkbox
          required={required}
          checked={field.value}
          onChange={(e, checked) => store.onFieldChange(fieldName, checked)}
          color="secondary"
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export const createSelectField = ({
  options,
  variant = 'outlined',
  label,
  fieldName,
  testId,
  store,
  disabled,
  tooltip,
  subLabel,
  placeholder,
  standaloneLabel = true,
  onChange,
  isRequired = false
}: SelectFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1 || isRequired;

  return (
    <SelectField
      disabled={disabled}
      standaloneLabel={standaloneLabel}
      label={label}
      value={field.value}
      required={required}
      options={options}
      onChange={value => onChange ? onChange(value) : store.onFieldChange(fieldName, value)}
      error={isNotBlank(field.error)}
      errorText={field.error}
      variant={variant}
      fullWidth
      testId={testId}
      tooltip={tooltip}
      placeholder={placeholder}
      subLabel={subLabel}
    />
  );
};

export const createMultiSelectField = ({
  store,
  fieldName,
  standaloneLabel = true,
  helperText,
  ...fieldProps
}: CreateMultiSelectFieldProps) => {
  const { testId, disabled, options } = fieldProps;
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  const mapLabelsFromOptions = (value) => ({
    label: options.find(o => o.value === value)?.label,
    value
  })

  return (
    <MultiSelectField
      value={field.value?.map(mapLabelsFromOptions)}
      required={required}
      standaloneLabel={standaloneLabel}
      onChange={(e, selectedOptions) =>
        store.onFieldChange(fieldName, selectedOptions.map(o => o.value))
      }
      getOptionSelected={(option, selectedValue) => {
        return option.value == selectedValue?.value;
      }}
      renderInput={params => (
        <TextField
          {...params}
          testId={`${testId}-input`}
          variant="outlined"
          disabled={disabled}
          error={isNotBlank(field.error)}
          helperText={field.error || helperText}
        />
      )}
      {...fieldProps}
    />
  );
};

export const createAutocompleteField = ({
  options,
  variant = 'outlined',
  label,
  subLabel,
  fieldName,
  inputFieldName,
  store,
  disabled,
  placeholder,
  onChange,
  tooltip,
  clearOnBlur,
  standaloneLabel = true,
  noOptionsText,
  PopperComponent,
}: AutocompleteFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const inputField = formFields[inputFieldName];
  return (
    <>
      {standaloneLabel && label ? (
        <FieldLabel tooltip={tooltip}>
          {label}
        </FieldLabel>
      ) : null}
      {subLabel && (
        <FieldLabel style={{ fontSize: '.8rem', color: '#aaa' }}>
          {subLabel}
        </FieldLabel>
      )}
      <Autocomplete
        id={fieldName}
        disabled={disabled}
        value={field.value}
        inputValue={inputField?.value}
        onInputChange={(e, value) => inputFieldName ? store.onFieldChange(inputFieldName, value) : undefined}
        options={options}
        renderInput={(params) =>
          <MuiTextField
            variant={variant}
            label={standaloneLabel ? undefined : label}
            error={isNotBlank(field.error) || isNotBlank(inputField?.error)}
            helperText={field.error || inputField?.error}
            {...params} />}
        getOptionLabel={(option) => option?.label ?? ''}
        clearOnBlur={clearOnBlur}
        getOptionSelected={(option, value) => option.label === value.label}
        onChange={(event, value) => {
          onChange(value);
        }}
        fullWidth
        placeholder={placeholder}
        noOptionsText={noOptionsText}
        PopperComponent={PopperComponent}
      />
    </>
  );
};

export const createSliderField = ({
  label,
  fieldName,
  store,
  valueLabelDisplay,
  valueLabelFormat,
  className,
  min,
  max,
  step,
  marks,
  defaultValue,
  disabled,
  tooltip,
  tooltipData,
  isCustomLabelTooltip,
  CustomTooltipComponent,
  testId,
  onBlur,
}: SliderFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];

  const [currentTooltipText, setCurrentTooltipText] = useState(tooltip || '');

  const handleSliderChange = (e, value) => {
    if (tooltipData) {
      const foundItem = tooltipData.find((item) => item.value === value.toString());
      setCurrentTooltipText(foundItem ? foundItem.data : '');
    }
    store.onFieldChange(fieldName, value);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" style={{ height: '40px' }}>
        <FieldLabel>{label}</FieldLabel>
        {isCustomLabelTooltip && <CustomTooltipComponent />}
      </Box>
      <Box
        minHeight={56}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Tooltip title={currentTooltipText}>
          <Slider
            testId={testId}
            value={field.value}
            onChangeCommitted={handleSliderChange}
            valueLabelDisplay={valueLabelDisplay}
            valueLabelFormat={valueLabelFormat}
            className={className}
            min={min}
            max={max}
            step={step}
            marks={marks}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </Tooltip>
      </Box>
    </>
  );
};

export const createSliderInputField = ({
  label,
  fieldName,
  store,
  valueLabelDisplay,
  valueLabelFormat,
  className,
  min,
  max,
  step,
  marks,
  defaultValue,
  disabled,
  tooltip,
  tooltipData,
  isCustomLabelTooltip,
  CustomTooltipComponent,
  testId,
  onBlur,
}: SliderFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];

  const [currentTooltipText, setCurrentTooltipText] = useState(tooltip || '');

  const handleSliderChange = (e, value) => {
    if (tooltipData) {
      const foundItem = tooltipData.find((item) => item.value === value.toString());
      setCurrentTooltipText(foundItem ? foundItem.data : '');
    }
    store.onFieldChange(fieldName, value);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" style={{ height: '40px' }}>
        <FieldLabel>{label}</FieldLabel>
        {isCustomLabelTooltip && <CustomTooltipComponent />}
      </Box>
      <Box
        minHeight={56}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Tooltip title={currentTooltipText}>
          <NumberSliderWithInput
            testId={testId}
            value={field.value}
            onChangeCommitted={handleSliderChange}
            valueLabelDisplay={valueLabelDisplay}
            valueLabelFormat={valueLabelFormat}
            className={className}
            min={min}
            max={max}
            step={step}
            marks={marks}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </Tooltip>
      </Box>
    </>
  );
};

export const createNumberFormatField = ({
  store,
  label,
  fieldName,
  testId,
  format,
  mask = '_',
  disabled,
  suffix,
  standaloneLabel = true,
  placeholder,
  thousandSeparator,
  allowNegative,
  onChange,
  isRequired = false
}: NumberFormatFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1 || isRequired;

  return (
    <NumberFormat
      testId={testId}
      disabled={disabled}
      variant="outlined"
      standaloneLabel={standaloneLabel}
      customInput={TextField}
      format={format}
      mask={mask}
      label={label}
      value={field.value}
      onChange={e => onChange ? onChange(e.target.value) : store.onFieldChange(fieldName, e.target.value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      required={required}
      suffix={suffix}
      placeholder={placeholder}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
    />
  );
};

export const createNumberFormatFieldWithAdornment = ({
  store,
  label,
  fieldName,
  testId,
  format,
  mask = '_',
  disabled,
  suffix,
  standaloneLabel = true,
  placeholder,
  thousandSeparator,
  allowNegative,
  onChange,
  adornment,
  adornmentPosition = 'end',
  decimalScale
}: NumberFormatFieldWithAdornmentProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  let inputProps = {};

  if (adornment) {
    const adornmentValue = <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>;

    if (adornmentPosition === 'start') {
      inputProps = {
        startAdornment: adornmentValue
      };
    } else {
      inputProps = {
        endAdornment: adornmentValue
      };
    }
  }

  function handleChange(name, value) {
    if (onChange) {
      onChange(name, value);
    } else {
      store.onFieldChange(name, value);
    }
  }

  return (
    <NumberFormat
      testId={testId}
      disabled={disabled}
      variant="outlined"
      standaloneLabel={standaloneLabel}
      customInput={TextField}
      format={format}
      mask={mask}
      label={label}
      value={field.value}
      onValueChange={values => handleChange(fieldName, values.floatValue ? values.floatValue : values.value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      required={required}
      suffix={suffix}
      placeholder={placeholder}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      InputProps={inputProps}
      decimalScale={decimalScale}
    />
  );
};
const MAX_LIMIT = 10000;
export const createNumberFormatFieldNoDecimalsAndWithLimit = ({
  store,
  label,
  fieldName,
  testId,
  format,
  mask = '_',
  disabled,
  maxLimit = MAX_LIMIT,
  suffix,
  standaloneLabel = true,
  placeholder,
  thousandSeparator,
  allowNegative,
  onChange,
  showErrors = true,
  isRequired = false
}: NumberFormatFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1 || isRequired;
  const withValueLimit = ({ value }) => value <= maxLimit;

  return (
    <NumberFormat
      testId={testId}
      disabled={disabled}
      variant="outlined"
      standaloneLabel={standaloneLabel}
      customInput={TextField}
      format={format}
      mask={mask}
      label={label}
      value={field.value}
      onChange={e => onChange ? onChange(e.target.value) : store.onFieldChange(fieldName, e.target.value)}
      error={showErrors ? isNotBlank(field.error) : false}
      helperText={showErrors ? field.error : null}
      fullWidth
      required={required}
      suffix={suffix}
      placeholder={placeholder}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      decimalScale={0}
      isAllowed={withValueLimit}

    />
  );
};

export const createDateField = ({
  testId,
  disabled,
  fieldName,
  format = 'MM/dd/yyyy',
  label,
  store,
  standaloneLabel = true,
  disableFuture,
  disablePast,
  tooltip,
  onChange,
  isRequired = false
}: DateFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = (field.rule.indexOf('required') > -1 || isRequired);
  return (
    <DateField
      testId={testId}
      disabled={disabled}
      inputVariant="outlined"
      required={required}
      format={format}
      label={label}
      value={field.value}
      onChange={date => onChange ? onChange(fieldName, date) : store.onFieldChange(fieldName, date)}
      disableFuture={disableFuture}
      error={isNotBlank(field.error)}
      disablePast={disablePast}
      helperText={field.error}
      standaloneLabel={standaloneLabel}
      // tooltip={tooltip}
      fullWidth
    />
  );
};

export const createAutocompleteAddressField = ({
  store,
  testId,
  fieldName,
  label,
  disabled,
  standaloneLabel = true,
  tooltip,
  onChange,
  showToolTip,
  InputLabelProps,
  InputProps,
  compTool = false
}: AutocompleteAddressFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  const handleChange = event => {
    store.onFieldChange(fieldName, event.target.value);
    onChange(event);
  };

  return (
    <AutocompleteAddressField
      testId={testId}
      label={label}
      disabled={disabled}
      required={required}
      name={fieldName}
      value={field.value}
      error={isNotBlank(field.error)}
      errorText={field.error}
      standaloneLabel={standaloneLabel}
      onChange={event => handleChange(event)}
      tooltip={tooltip}
      fullWidth
      showToolTip={showToolTip}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      compTool={compTool}
    />
  );
};

export const createRadioField = ({
  store,
  fieldName,
  label,
  disabled,
  options,
  row,
  testId,
  standaloneLabel = true,
  needHtml = false,
  tooltip,
  onChange,
}: RadioFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  const handleChange = value => {
    if (onChange) {
      onChange(value);
      return;
    }
    const isBoolean = typeof options?.[0]?.value === 'boolean';
    store.onFieldChange(fieldName, isBoolean ? value === 'true' : value);
  };

  return (
    <RadioField
      label={label}
      value={field.value}
      options={options}
      onChange={handleChange}
      disabled={disabled}
      required={required}
      fullWidth
      row={row}
      testId={testId}
      standaloneLabel={standaloneLabel}
      needHtml={needHtml}
      error={isNotBlank(field.error)}
      errorText={field.error}
      tooltip={tooltip}
    />
  );
};

export const createPercentageField = ({
  store,
  variant = 'outlined',
  fieldName,
  standaloneLabel = true,
  fullWidth = true,
  label,
  disabled,
  testId,
  tooltip,
  decimalScale,
  inputClassName,
  allowNegative = false,
  limitedPercentage
}: PercentageFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  return (
    <PercentageField
      label={label}
      standaloneLabel={standaloneLabel}
      value={field.value}
      variant={variant}
      testId={testId}
      fullWidth={fullWidth}
      onChange={(name, value) => store.onFieldChange(fieldName, value)}
      disabled={disabled}
      error={isNotBlank(field.error)}
      errorText={field.error}
      tooltip={tooltip}
      decimalScale={decimalScale}
      inputClassname={inputClassName}
      allowNegative={allowNegative}
      limitedPercentage={limitedPercentage}
    />
  );
};

export const createRadioGroupField = ({
  store,
  fieldName,
  label,
  boolean,
  disabled,
}: RadioGroupFieldProps) => {
  const formFields = store.form.fields;
  const value = formFields[fieldName].value;

  return (
    <Grid container spacing={2} direction="row">
      <FormControl component="fieldset" disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          row
          name={fieldName}
          value={value}
          onChange={e => {
            const val = e.target.value;
            store.onFieldChange(fieldName, boolean ? val === 'Yes' : val);
          }}
        >
          <FormControlLabel
            value="Yes"
            control={<Radio />}
            label="Yes"
            id="Yes"
            checked={value === YES || value === true}
          />
          <FormControlLabel
            value="No"
            control={<Radio />}
            label="No"
            id="No"
            checked={value === NO || value === false}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export const createRadioGroupFieldWithParameters = ({
  store,
  fieldName,
  label,
  disabled = false,
  options,
}: RadioGroupFieldWithParametersProps) => {
  const formFields = store.form.fields;
  const value = formFields[fieldName].value;

  return (
    <Grid container spacing={2} direction="row">
      <FormControl component="fieldset" disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          row
          name={fieldName}
          value={value}
          onChange={e => {
            const val = e.target.value;
            store.onFieldChange(fieldName, val);
          }}
        >
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              checked={value === option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export const createStreetAddressField = ({
  subLabel,
  variant = 'outlined',
  type = 'text',
  label,
  fieldName,
  testId,
  store,
  multiline,
  disabled,
  standaloneLabel = true,
  tooltip,
  placeholder,
}: TextFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule.indexOf('required') > -1;

  return (
    <TextField
      type={type}
      testId={testId}
      disabled={disabled}
      variant={variant}
      standaloneLabel={standaloneLabel}
      label={label}
      subLabel={subLabel}
      value={field.value}
      onChange={e => store.handleStreetAddressChange(fieldName, e.target.value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      fullWidth
      multiline={multiline}
      required={required}
      tooltip={tooltip}
      placeholder={placeholder}
    />
  );
};


export const createHtmlField = ({
  store,
  fieldName,
  variant = 'outlined',
  standaloneLabel = true,
  ...htmlFieldProps
}: CreateHtmlFieldProps) => {
  const formFields = store.form.fields;
  const field = formFields[fieldName];
  const required = field.rule?.indexOf('required') > -1;

  return (
    <HtmlField
      value={field.value}
      onChange={value => store.onFieldChange(fieldName, value)}
      error={isNotBlank(field.error)}
      helperText={field.error}
      required={required}
      variant={variant}
      standaloneLabel={standaloneLabel}
      fullWidth
      {...htmlFieldProps}
    />
  );
};
