/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Box, Grid } from "@material-ui/core";
import { PropertyInformationFormStore } from "../stores/propertyInformationFormStore";
import { observer } from "mobx-react";
import { propertyTypeOptions } from "../utils/constants";
import { createCurrencyField, createNumberFormatField, createSelectField, createTextField } from "@roc/ui";
import { stateOptions } from "@roc/ui/formComponents";
import Tooltip from '@material-ui/core/Tooltip';

interface PropertyInformationFormProps {
  propertyInformationFormStore: PropertyInformationFormStore;
  readOnly: boolean;
}

export const PropertyInformationForm = observer(({ propertyInformationFormStore, readOnly }: PropertyInformationFormProps) => {

  const stateOptionsFiltered = stateOptions.slice(1);//remove select option

  const propertyTypeOptionsFiltered = [...propertyTypeOptions];

  const propertyInformation: any = propertyInformationFormStore.getFlattenedValues();

  const propertyType = propertyInformation.propertyType;
  if (propertyType && !propertyTypeOptionsFiltered.some(item => item.value === propertyType)) {
    propertyTypeOptionsFiltered.push({ label: propertyType, value: propertyType });
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {createTextField({
          label: 'Address',
          fieldName: 'address',
          testId: 'address',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          label: 'City',
          fieldName: 'city',
          testId: 'city',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store: propertyInformationFormStore,
          testId: 'state',
          fieldName: 'state',
          label: 'State',
          options: stateOptionsFiltered,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          label: 'County',
          fieldName: 'county',
          testId: 'county',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          label: 'Zip Code',
          fieldName: 'zipCode',
          testId: 'zipCode',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Beds',
          fieldName: 'beds',
          testId: 'beds',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Baths',
          fieldName: 'baths',
          testId: 'baths',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Parking',
          fieldName: 'parking',
          testId: 'parking',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Living Area',
          fieldName: 'livingArea',
          testId: 'livingArea',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        <TooltipWrapper title={propertyInformation.propertyType}>
          {createSelectField({
            store: propertyInformationFormStore,
            testId: 'propertyType',
            fieldName: 'propertyType',
            label: 'Property Type',
            options: propertyTypeOptionsFiltered,
            standaloneLabel: false,
            disabled: readOnly
          })}
        </TooltipWrapper>
      </Grid>
      <Grid item xs={6} md={3}>
        {createTextField({
          label: 'Subject Zoning',
          fieldName: 'subjectZoning',
          testId: 'subjectZoning',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Year Built',
          fieldName: 'yearBuilt',
          testId: 'yearBuilt',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={6} md={3}>
        {createNumberFormatField({
          label: 'Land Area',
          fieldName: 'landArea',
          testId: 'landArea',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          thousandSeparator: true,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          label: 'Hoa Fees',
          fieldName: 'hoaFees',
          testId: 'hoaFees',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          label: 'Parcel No.',
          fieldName: 'parcelNo',
          testId: 'parcelNo',
          store: propertyInformationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
    </Grid>
  );
});


const TooltipWrapper = ({ children, title }) => {
  return <Tooltip title={title} placement="top-start">
    <Box display="inline-block" width="100%">
      {children}
    </Box>
  </Tooltip>
}