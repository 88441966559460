export enum TamariskAppraisalDashboardTab {
  MY_TAMARISK_APPRAISAL = 'MY_APPRAISALS',
  ALL_TAMARISK_APPRAISAL = 'ALL_TAMARISK_APPRAISAL',
  SENT_TAMARISK_APPRAISAL = 'SENT_TAMARISK_APPRAISAL',
}

export enum TamariskAppraisalBulkAction {
  SEND = 'SEND',
  DOWNLOAD = 'DOWNLOAD',
}

export const SENT_TO_APPRAISER = 'Sent to Appraiser';

export type TamariskAppraisal = {
  orderId: string,
  propertyAddress: string,
  appraiserName?: string,
  noOfDays: number,
  propertyAppraisalId: number,
  tamariskSpecialist?: string,
  reportDate: string,
  lenderName?: string,
  taskName?: string,
  loanTaskId?: number | null,
  propertyId: number,
  loanId: number,
  taskId?: number | null,
  status?: string,
  [key: string]: any,
};
