import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { AutocompleteAddressField, Paper } from '@roc/ui';
import { observer } from 'mobx-react';
import { useStore } from '@roc/client-portal-shared/hooks';
import { DataGrid } from '@roc/ui';
import { GridColumnProps } from '@roc/ui';
import { Search } from '@material-ui/icons';

const columns: GridColumnProps[] = [
  {
    field: 'insuranceBrokerName',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerName: 'Name',
    minWidth: 300,
  },
  {
    field: 'insuranceBrokerEmail',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerName: 'Email',
    minWidth: 300,
  },
  {
    field: 'insuranceBrokerPhone',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerName: 'Phone',
    minWidth: 150,
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  note: {
    padding: '20px 0'
  },
  autocompleteContainer: {
    marginBottom: theme.spacing(2),
    height: '60px'
  },
  gridContainer: {
    height: '40vh'
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    height: '70px'
  },
  warning: {
    fontWeight: 'bold',
    color: 'red'
  }
}));

export const InsuranceAgentFinder = observer(() => {
  const classes = useStyles();
  const { insuranceAgentStore } = useStore();


  const onAddressChange = event => {
    insuranceAgentStore.addressInput = event?.target.value;
    insuranceAgentStore.onAddressChange(event?.target?.fullInfo);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    insuranceAgentStore.insuranceAgentGridStore.setFilterModel(filterModel);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    insuranceAgentStore.insuranceAgentGridStore.setSortModel(sortModel);
  };


  return (
    <Layout title="Find Insurance Agent">
      <Paper className={classes.paper}>
        <Typography className={classes.note}>
          If you are having trouble finding approved insurance for your loan, please search for an agent in the list below.
        </Typography>
        <Grid container spacing={2} className={classes.autocompleteContainer}>
          <Grid item xs={12} sm={10}>
            <AutocompleteAddressField
              label="Property Address"
              value={insuranceAgentStore.addressInput}
              name="address"
              onChange={onAddressChange}
              fullWidth
              testId="address"
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.searchButton}>
            <Button
              style={{ height: '100%' }}
              variant='contained'
              size='large'
              fullWidth
              color='primary'
              onClick={() => insuranceAgentStore.searchInsuranceAgents()}
              disabled={!insuranceAgentStore.addressInput}
              startIcon={<Search />}
            >Search</Button>
          </Grid>
        </Grid>
        <Box>
          <DataGrid
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            showFilters
            showPagination
            paginationData={insuranceAgentStore.insuranceAgentGridStore.gridData.meta}
            columns={columns}
            rows={insuranceAgentStore.insuranceAgentGridStore.gridData.data.rows}
            className={classes.gridContainer} />
        </Box>
      </Paper>
    </Layout>
  );
});