import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ColumnContainer, CondoConversion, FieldContainer, FieldContainerNoSpacing, MultipleLots, IsOriginalAddress, NumberOfLots, ParcelNumber, PermitsInPlace, OriginalAddress, FireDamage, SquareFootageAdded, IsSellerCredit, SellerCreditAmount, WholesalerRelationship, RehabDetails, DescribeRenovation } from '@roc/ui/formComponents';
import {
  PropertyAddressDetails,
  PropertyType,
  Unit,
  PropertyOwnership,
  PurchasePrice,
  ArmsLength,
  ArmsLengthDescribe,
  PropertySource,
  PropertySourceOther,
  WholesalerPropertyPaid,
  WholesalerPropertySource,
  WholesalerPropertySourceOther,
  AnyDebt,
  DebtAmount,
  PurchaseDate,
  RenovationBudget,
  RenovationDescription,
  RefinanceRenovationBudget,
  RefinanceInvestedCapitalImprovements,
  RefinanceRenovationAmountRemaining,
  AsIsValue,
  AfterRepairValue,
  ExitStrategy,
  AdditionalComments,
  SubmitRushAppraisal,
} from '@roc/ui/formComponents';
import { GridRowSelectedEvent } from '@roc/ui';
import { propertyTypesByLoanSubtype } from '../../utils/propertyTypes';
import { renovationDescriptionsByLoanSubtype } from '../../utils/renovationDescriptions';
import { CONDOMINIUM } from '@roc/feature-utils';
import { useFixFlipStore } from '../../hooks/useFixFlipStore';
import { ChooseExistingPropertyAppraisal } from './chooseExistingPropertyAppraisal';
import { LoanSubType, isFixAndFlip } from '@roc/feature-utils';
import { AnnualHoa, AnnualInsurance, AnnualTaxes, MonthlyMarketRent } from '../../components/propertyDSCRForm';
import { useBaseStore } from '@roc/feature-app-core';
import { Alert } from '@material-ui/lab';

export const AppraisalInformation = observer(() => {
  const {
    fixFlipStore: { propertyStore },
  } = useFixFlipStore();
  const { form } = propertyStore.appraisalInformationStore;

  useEffect(() => {
    if (!form.fields.chooseExistingAppraisal.value) {
      propertyStore.reset();
      propertyStore.appraisalInformationStore.onFieldChange(
        'chooseExistingAppraisal',
        false
      );
    }
  }, [form.fields.chooseExistingAppraisal.value]);

  const onRowSelected = (e: GridRowSelectedEvent) => {
    const { node } = e;

    if (node.isSelected()) {
      propertyStore.setAppraisalId(
        node.data?.appraisalData?.propertyAppraisalId
      );
      propertyStore.loadPropertyFromAppraisal(node.data);
    } else {
      propertyStore.reset();
    }
  };

  return (
    <>
      <FieldContainer>
        <ChooseExistingPropertyAppraisal
          store={propertyStore.appraisalInformationStore}
          onRowSelected={onRowSelected}
        />
      </FieldContainer>
    </>
  );
});

export const PropertyInformation = observer(({ store }) => {
  const { propertyStore, loanSubtype } = store;
  const { propertyInformationStore, appraisalId } = propertyStore;
  const ownershipValue = propertyInformationStore.form.fields.propertyOwnership.value;
  const multipleLots = propertyInformationStore.form.fields.multipleLots.value;
  const isOriginalAddress = propertyInformationStore.form.fields.isOriginalAddress.value;
  const propertyTypeOptions = propertyTypesByLoanSubtype(loanSubtype);
  propertyInformationStore.onFieldChange('loanSubType', loanSubtype);
  const disabledField = appraisalId || store.isDealRoom;

  return (
    <>
      <FieldContainer>
        <PropertyAddressDetails
          store={propertyInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {loanSubtype == LoanSubType.GROUND_UP &&
        <FieldContainer>
          <ParcelNumber
            store={propertyInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>}
      <FieldContainer>
        <PropertyType
          store={propertyInformationStore}
          propertyTypeOptions={propertyTypeOptions}
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <PropertyOwnership
          store={propertyInformationStore}
          disabled={disabledField}
          loanSubType={loanSubtype}
        />
      </FieldContainer>
      {loanSubtype == LoanSubType.GROUND_UP && <>
        {ownershipValue == 'Purchase' &&
          <FieldContainer>
            <MultipleLots
              store={propertyInformationStore}
              disabled={disabledField}
              loanSubType={loanSubtype}
            />
          </FieldContainer>}
        {ownershipValue == 'Owned By Borrower' && <FieldContainer>
          <IsOriginalAddress
            store={propertyInformationStore}
            disabled={disabledField}
            loanSubType={loanSubtype}
          />
        </FieldContainer>}
        {(isOriginalAddress === false && ownershipValue == 'Owned By Borrower') &&
          <FieldContainer>
            <OriginalAddress
              store={propertyInformationStore}
              required
              disabled={disabledField}
            />
          </FieldContainer>}
        {((multipleLots && ownershipValue == 'Purchase') || (isOriginalAddress === false && ownershipValue == 'Owned By Borrower')) &&
          <>
            <FieldContainer>
              <NumberOfLots
                store={propertyInformationStore}
                disabled={disabledField}
              />
            </FieldContainer>
            <FieldContainer>
              <Alert severity="info">Please make sure to click on "Add New Property" for each lot</Alert>
            </FieldContainer>
          </>
        }
      </>}
    </>
  );
});

export const PurchaseInformation = observer(({ store }) => {
  const { propertyStore, loanSubtype } = store;
  const { purchaseInformationStore, appraisalId } = propertyStore;
  const isWholesalerProperty =
    purchaseInformationStore.form.fields.propertySourcing.value ===
    'Wholesaler';
  const isArmsLengthNo =
    purchaseInformationStore.form.fields.armsLength.value === 'N';
  const isPropertySourceOther =
    purchaseInformationStore.form.fields.propertySourcing.value === 'Other';
  const isWholesalerPropertySourceOther =
    purchaseInformationStore.form.fields.wholesalerPurchase.value === 'Other';
  const isSellerCredit = purchaseInformationStore.form.fields.sellerCredit.value === true;
  const disabledField = appraisalId || store.isDealRoom;
  return (
    <>
      <FieldContainer>
        <PurchasePrice
          store={purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      <FieldContainer>
        <ArmsLength store={purchaseInformationStore} disabled={appraisalId} />
      </FieldContainer>
      {isArmsLengthNo && (
        <FieldContainer>
          <ArmsLengthDescribe
            store={purchaseInformationStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PropertySource
          store={purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {isPropertySourceOther && (
        <FieldContainer>
          <PropertySourceOther
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertyPaid
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && (
        <FieldContainer>
          <WholesalerPropertySource
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && isWholesalerPropertySourceOther && (
        <FieldContainer>
          <WholesalerPropertySourceOther
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isWholesalerProperty && isFixAndFlip(loanSubtype) && (
        <FieldContainer>
          <WholesalerRelationship
            store={purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      {isFixAndFlip(loanSubtype) &&
        <>
          <FieldContainer>
            <IsSellerCredit
              store={purchaseInformationStore}
              disabled={disabledField}
            />
          </FieldContainer>
          {isSellerCredit && (
            <FieldContainer>
              <SellerCreditAmount
                store={purchaseInformationStore}
                disabled={disabledField}
              />
            </FieldContainer>
          )}
        </>
      }
    </>
  );
});

export const Refinance_PurchaseInformation = observer(({ store }) => {
  const { propertyStore } = store;
  const { refinance_purchaseInformationStore, appraisalId } = propertyStore;
  const isAnyDebt =
    refinance_purchaseInformationStore.form.fields.anyDebt.value === 'Y';
  const disabledField = appraisalId || store.isDealRoom;
  return (
    <>
      <FieldContainer>
        <AnyDebt
          store={refinance_purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {isAnyDebt && (
        <FieldContainer>
          <DebtAmount
            store={refinance_purchaseInformationStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <PurchaseDate
          store={refinance_purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
      <FieldContainer>
        <PurchasePrice
          store={refinance_purchaseInformationStore}
          disabled={disabledField}
        />
      </FieldContainer>
    </>
  );
});

export const RenovationDetails = observer(({ store }) => {
  const { propertyStore, loanSubtype, loanInformationStore } = store;
  const { renovationDetailsStore, appraisalId } = propertyStore;
  const totalRenovationBudget =
    renovationDetailsStore.form.fields.totalRenovationBudget.value;
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );

  const disabledField = appraisalId || store.isDealRoom;

  useEffect(() => {
    renovationDetailsStore.onFieldChange("loanSubType", loanSubtype);
  }, [loanSubtype]);

  return (
    <>
      <FieldContainer>
        <RenovationBudget
          store={renovationDetailsStore}
          disabled={disabledField}
          onChange={() => renovationDetailsStore.handleRenovationBudgetChange(loanSubtype)}
        />
      </FieldContainer>
      {(totalRenovationBudget > 0 && !(LoanSubType.GROUND_UP == loanSubtype)) && (
        <FieldContainer>
          <DescribeRenovation
            store={renovationDetailsStore}
            disabled={disabledField}
            renovationDescriptionOptions={renovationDescriptionOptions}
          />
        </FieldContainer>
      )}
      {loanSubtype === LoanSubType.FIX_AND_FLIP_PRO && (
        <FieldContainer>
          <RehabDetails store={renovationDetailsStore} postRehabGradeFieldsDisabled={!renovationDetailsStore.form.fields.squareFootageAdded.value} />
        </FieldContainer>
      )}
      {loanSubtype === LoanSubType.GROUND_UP && (
        <FieldContainer>
          <PermitsInPlace store={renovationDetailsStore} />
        </FieldContainer>
      )}
    </>
  );
});

export const Refinance_RenovationDetails = observer(({ store }) => {
  const { propertyStore, loanSubtype } = store;
  const { refinance_renovationDetailsStore, appraisalId } = propertyStore;
  const amountRemaining =
    refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;
  const renovationDescriptionOptions = renovationDescriptionsByLoanSubtype(
    loanSubtype
  );
  refinance_renovationDetailsStore.onFieldChange('loanSubType', loanSubtype);
  const disabledField = appraisalId || store.isDealRoom;

  useEffect(() => {
    refinance_renovationDetailsStore.onFieldChange("loanSubType", loanSubtype);
  }, [loanSubtype]);

  return (
    <>
      <FieldContainer>
        <RefinanceRenovationBudget
          store={refinance_renovationDetailsStore}
          onChange={() =>
            refinance_renovationDetailsStore.handleRenovationBudgetChange(loanSubtype)
          }
          disabled={disabledField}
        />
      </FieldContainer>
      <FieldContainer>
        <RefinanceInvestedCapitalImprovements
          store={refinance_renovationDetailsStore}
          onChange={() =>
            refinance_renovationDetailsStore.handleRenovationSpentToDateChange(loanSubtype)
          }
          disabled={appraisalId}
        />
      </FieldContainer>
      <FieldContainer>
        <RefinanceRenovationAmountRemaining
          store={refinance_renovationDetailsStore}
        />
      </FieldContainer>
      {(amountRemaining && !(LoanSubType.GROUND_UP == loanSubtype)) && (
        <FieldContainer>
          <DescribeRenovation
            store={refinance_renovationDetailsStore}
            disabled={disabledField}
            renovationDescriptionOptions={renovationDescriptionOptions}
          />
        </FieldContainer>
      )}
      {loanSubtype === LoanSubType.FIX_AND_FLIP_PRO && (
        <FieldContainer>
          <RehabDetails store={refinance_renovationDetailsStore} postRehabGradeFieldsDisabled={!refinance_renovationDetailsStore.form.fields.squareFootageAdded.value} />
        </FieldContainer>
      )}
      {loanSubtype === LoanSubType.GROUND_UP && (
        <FieldContainer>
          <PermitsInPlace store={refinance_renovationDetailsStore} />
        </FieldContainer>
      )}
    </>
  );
});

export const OtherDetails = observer(({ store }) => {
  const { propertyStore, loanSubtype } = store;
  const { otherDetailsStore, appraisalId } = propertyStore;
  const disabledField = appraisalId || store.isDealRoom;
  const { globalStore } = useBaseStore();

  return (
    <>
      <FieldContainer>
        <AfterRepairValue store={otherDetailsStore} disabled={disabledField} />
      </FieldContainer>
      <FieldContainer>
        <ExitStrategy store={otherDetailsStore} disabled={disabledField} />
      </FieldContainer>
      {otherDetailsStore.form.fields.exitStrategy.value === 'Refinance' ? (
        <>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <MonthlyMarketRent store={otherDetailsStore} />
              <AnnualTaxes store={otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <AnnualInsurance store={otherDetailsStore} />
              <AnnualHoa store={otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
        </>
      ) : null}
    </>
  );
});

export const Refinance_OtherDetails = observer(({ store }) => {
  const { globalStore } = useBaseStore();
  const { propertyStore, loanSubtype } = store;
  const {
    refinance_renovationDetailsStore,
    refinance_otherDetailsStore,
    appraisalId,
  } = propertyStore;
  const amountRemaining =
    refinance_renovationDetailsStore.form.fields.renovationRemaining.value > 0;
  const disabledField = appraisalId || store.isDealRoom;

  return (
    <>
      <FieldContainer>
        <AsIsValue
          store={refinance_otherDetailsStore}
          disabled={disabledField}
        />
      </FieldContainer>
      {amountRemaining && (
        <FieldContainer>
          <AfterRepairValue
            store={refinance_otherDetailsStore}
            disabled={disabledField}
          />
        </FieldContainer>
      )}
      <FieldContainer>
        <ExitStrategy
          store={refinance_otherDetailsStore}
          disabled={appraisalId}
        />
      </FieldContainer>
      {refinance_otherDetailsStore.form.fields.exitStrategy.value === 'Refinance' ? (
        <>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <MonthlyMarketRent store={refinance_otherDetailsStore} />
              <AnnualTaxes store={refinance_otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
          <FieldContainerNoSpacing>
            <ColumnContainer mdSize={6}>
              <AnnualInsurance store={refinance_otherDetailsStore} />
              <AnnualHoa store={refinance_otherDetailsStore} />
            </ColumnContainer>
          </FieldContainerNoSpacing>
        </>
      ) : null}
      {!isFixAndFlip(loanSubtype) && (
        <FieldContainer>
          <AdditionalComments
            store={refinance_otherDetailsStore}
            disabled={appraisalId}
          />
        </FieldContainer>
      )}
    </>
  );
});
