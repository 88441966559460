import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    tierColumn: {
      textAlign: 'center',
    },
    sharedCellStyles: {
      fontSize: '0.8rem',
      padding: '4px',
      textAlign: 'center',
    },
    oddRow: {
      backgroundColor: '#F7F7F7',
    },
    headerCell: {
      fontWeight: 'bold',
      color: 'rgba(109, 109, 109, 1)',
    },
    borderRight: {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    borderTop: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    borderLeft: {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    noBorderTop: {
      borderTop: 'none',
    },
    noBorderBottom: {
      borderBottom: 'none',
    },
    noRightBorder: {
      borderRight: 'none',
    },
    noLeftBorder: {
      borderLeft: 'none',
    },
  })
);
export interface ITableMatrixData {
  COLUMNS_2: Record<string, any>;
  DATA_2: Record<string, any>;
  ROW_VALUE_KEYS: string[];
}

export const ExperienceMatrix = observer(
  ({ experience, data }: { experience?: number; data: ITableMatrixData }) => {
    const classes = useStyles();
    const dashedBorder = (tier: { experience: string }) => {
      return tier.experience
        ?.split(',')
        .map(x => x.trim())
        .includes(experience.toString())
        ? '2.5px dashed #34A853'
        : undefined;
    };
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table
              style={{
                position: 'relative',
              }}
              className={classes.borderTop}
            >
              {data.DATA_2.map((tier, i) => (
                <>
                  <TableHead
                    style={{
                      borderTop: dashedBorder(tier),
                      borderLeft: dashedBorder(tier),
                      borderRight: dashedBorder(tier),
                    }}
                  >
                    <TableRow>
                      {data.COLUMNS_2.map(col => (
                        <TableCell
                          className={`${classes.sharedCellStyles} ${classes.headerCell} ${classes.oddRow} ${classes.borderRight} ${classes.borderLeft}`}
                          style={{
                            color:
                              experience.toString() === tier.experience
                                ? '#222'
                                : 'rgba(109, 109, 109, 1)',
                          }}
                        >
                          {col.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    style={{
                      borderLeft: dashedBorder(tier),
                      borderRight: dashedBorder(tier),
                      borderBottom: dashedBorder(tier),
                    }}
                  >
                    <TableRow>
                      <TableCell
                        rowSpan={tier.rows.length + 1}
                        className={`${classes.sharedCellStyles} ${classes.tierColumn} ${classes.borderLeft}`}
                      >
                        {tier.experience}
                      </TableCell>
                    </TableRow>
                    {tier.rows.map((row, index) => (
                      <TableRow>
                        {data.ROW_VALUE_KEYS.map(key => {
                          return (
                            <TableCell
                              className={`${classes.sharedCellStyles} ${
                                classes.borderLeft
                              }
                              ${classes.borderRight}
                              ${
                                row[key]?.removeTopBorder
                                  ? classes.noBorderTop
                                  : ''
                              }
                              ${
                                row[key]?.removeBottomBorder
                                  ? classes.noBorderBottom
                                  : ''
                              }
                              ${
                                row[key]?.removeRightBorder
                                  ? classes.noRightBorder
                                  : ''
                              }
                              ${
                                row[key]?.removeLeftBorder
                                  ? classes.noLeftBorder
                                  : ''
                              }
                              `}
                            >
                              {row[key].value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ))}
            </Table>
          </Paper>
        </Grid>
      </Grid>
    );
  }
);

export default ExperienceMatrix;
