import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { Tabs } from "@roc/ui";
import { PropertiesSummary, EditPropertyFlood, OverrideFloodZone } from "./propertyDetails";
import { InsuranceInformationReviewForm, InsuranceInformationReviewFormProps } from "./insuranceInformationReviewForm";
import { useState } from "react";
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    display: 'block'
  },
  title: {
    margin: '0 0 20px 0'
  },
  tabs: {
    border: 'none !important'
  }
}));

export const InsuranceInformationReview = observer(({ ...props }: InsuranceInformationReviewFormProps) => {
  const [state, setState] = useState({
    tab: 0,
    uploadFloodCert: false,
    override: false
  });
  const classes = useStyles();

  const renderContentPropertyDetailsContent = () => {
    if (state.uploadFloodCert) return <EditPropertyFlood
      setUploadMode={(value) => setState(prev => ({ ...prev, uploadFloodCert: value }))} />
    if (state.override) return <OverrideFloodZone
      setOverrideMode={(value) => setState(prev => ({ ...prev, override: value }))} />
  }

  const renderContent = () => {

    if (state.uploadFloodCert || state.override) {
      return renderContentPropertyDetailsContent();
    }

    switch (state.tab) {
      case 0: return <InsuranceInformationReviewForm {...props} />;
      case 1: return <PropertiesSummary
        setUploadMode={(value) => setState(prev => ({ ...prev, uploadFloodCert: value }))}
        setOverrideMode={(value) => setState(prev => ({ ...prev, override: value }))} />;
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.title}>
        <Box mb={1}>
          <Typography variant="h4">{state.tab == 0 ? 'Insurance Information Review' : 'Property Review'}</Typography>
        </Box>
      </Grid>
      {!(state.uploadFloodCert || state.override) && <Grid item xs={12}>
        <Tabs
          tabs={[{ label: 'Insurance Review' }, { label: 'Property Details' }]}
          selectedTab={state.tab}
          onTabChange={(e, index) => setState(prev => ({ ...prev, tab: index }))}
          className={classes.tabs}
        />
      </Grid>}
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
});