import { observer } from 'mobx-react';
import { ExpandMore, HomeWorkOutlined } from '@material-ui/icons';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ItemDisplay } from '../../../components/itemDisplay';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { formatCurrency, getCompletePropertyAddress } from '@roc/ui/utils';
import { formatDate, insertIf } from '@roc/feature-utils';
import { ViewControls } from '../../../components/viewControls';
import { TitleDivider } from '../../../components/titleDivider';
import {
  getValueOrDefaultDash,
  getBooleanDisplayValue,
} from '../../../utils/utils';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { propertySourceValues } from '../../../utils/constants';
import { PropertyFormStore } from '../../../stores/properties/propertyFormStore';

export const PropertySupplementalInformationTable = observer(
  ({ store }: { store: PropertyFormStore }) => {
    const property = store.getFormValues();

    const table: DetailsTableField[] = [
      {
        label: 'Is the property being converted to condominiums?',
        value: getBooleanDisplayValue(property.condoConversion),
        colSpan: 1,
      },

      {
        label: 'Has the property suffered fire damage?',
        value: getBooleanDisplayValue(property.fireDamage),
        colSpan: 1,
      },
      {
        label: 'Is there a new address for this property?',
        value: getBooleanDisplayValue(property.hasNewAddress),
        colSpan: 1,
      },
      {
        label: 'Exit Strategy',
        value: property.exitStrategy,
        colSpan: 1,
      },
    ];

    return <DetailsTable fields={table} />;
  }
);
