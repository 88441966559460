import {
  Avatar, Box, Divider, Grid, List,
  ListItem,
  ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, Chip
} from '@material-ui/core';
import { lighten, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Delete, FiberManualRecord, FiberManualRecordOutlined, PersonAdd,
  PersonRounded,
  Warning
} from '@material-ui/icons';
import {
  Button,
  ConfirmationIconButton, StandardDialog
} from '@roc/ui';
import { Participant } from '@twilio/conversations';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CommunicationStore } from '../stores/communicationStore';
import { ParticipantAttribute, ParticipantDetails } from '../types/communicationTypes';
import { AddParticipantDialog } from './addParticipantDialog';
import { ChatRoomStatus } from "../types/communicationTypes";
import { DisplayNameWithOnlineStatus, WebSocketStore, useWebSocketStore } from '@roc/feature-app-core';
import { getRoleInfoForAuthor } from '../helper';

const useDialogContentStyles = makeStyles(theme => ({
  root: {
    '& .dialog-content-box': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const useStyles = makeStyles(theme => ({
  participantRowContainer: {
    border: '1px solid #eee',
    marginBottom: 8,
    borderRadius: 8,
    '&:hover': {
      background: lighten(theme.palette.primary.main, 0.9),
    },
  },
  chip: {
    color: 'black',
    marginLeft: '10px'
  },
  listItem: {
    '&.MuiListItemText-root': {
      flex: 'inherit',
      minWidth: 0,
      marginTop: '4px',
      marginBottom: '4px',
    },
  },
}));

export const ManageParticipantsDialog = observer(({ communicationStore, allowAddParticipants = true, allowAddInternalParticipants, allowRemoveParticipants, showEmailAddress, loanId }: {
  communicationStore: CommunicationStore,
  webSocketStore?: WebSocketStore,
  allowAddParticipants?: boolean,
  allowAddInternalParticipants: boolean,
  allowRemoveParticipants: boolean,
  showEmailAddress: boolean;
  loanId: number,
}) => {
  const {
    manageParticipantsStore,
    participants,
    currentConversationSid,
    currentConversation,
  } = communicationStore;
  const participantsCount = participants[currentConversationSid]?.length ?? 0;

  const removeParticipant = (participant) => {
    manageParticipantsStore.removeParticipant(currentConversationSid, participant, loanId);
  };

  const handleAddNewParticipant = () => {
    manageParticipantsStore.addNewParticipant(loanId);
  };

  const handleAddExistingParticipant = () => {
    manageParticipantsStore.addExistingParticipant(loanId);
  };

  useEffect(() => {
    manageParticipantsStore.reset();
  }, []);

  return (
    <StandardDialog
      title={
        <>
          Manage Participants
          <p style={{
            fontSize: 16,
            marginTop: 8
          }}>{communicationStore.getConversationDisplayName(currentConversation)}</p>
        </>
      }
      open={participantsCount > 0 && manageParticipantsStore.open}
      handleClose={() => manageParticipantsStore.closeDialog()}
      removeContentBox={true}
      dialogContent={
        <Box flexGrow={1}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">
                Participants ({participantsCount})
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {allowAddParticipants && communicationStore.conversationChatRoomStatus[currentConversationSid] !== ChatRoomStatus.ARCHIVED && (<Button
                testId="create-new-converstation"
                variant="outlined"
                color="default"
                startIcon={<PersonAdd />}
                onClick={manageParticipantsStore.openAddParticipantDialog}
              >
                Add Participant
              </Button>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
              <List>
                {participants[currentConversationSid]?.map(p => (
                  <ParticipantRow
                    key={p.identity}
                    communicationStore={communicationStore}
                    participant={p}
                    allowRemoveParticipants={allowRemoveParticipants}
                    onRemove={participant => removeParticipant(participant)}
                    roles={manageParticipantsStore?.userIdParticipantDetailsMap}
                  />
                ))}
              </List>
            </Grid>
            <AddParticipantDialog
              communicationStore={communicationStore}
              handleAddNewParticipant={handleAddNewParticipant}
              handleAddExistingParticipant={handleAddExistingParticipant}
              showEmailAddress={showEmailAddress}
              allowAddInternalParticipants={allowAddInternalParticipants}
              loanId={loanId}
            />
          </Grid>
        </Box>
      }
    />
  );
});

const ParticipantRow = observer(({ communicationStore, participant, allowRemoveParticipants, onRemove, roles }: {
  communicationStore: CommunicationStore,
  participant: Participant,
  allowRemoveParticipants: boolean,
  onRemove: (participant: Participant) => void,
  roles: Record<number, ParticipantDetails>;
}) => {
  const dialogContentclasses = useDialogContentStyles();
  const classes = useStyles();
  const theme = useTheme();
  const { isSystemUser, userId } = participant.attributes as ParticipantAttribute;
  const canRemove: boolean = !isSystemUser && allowRemoveParticipants;
  const displayName = communicationStore.getName(participant);
  const roleName = getRoleInfoForAuthor(userId.toString(), roles).role;
  const hasRole = getRoleInfoForAuthor(userId.toString(), roles).hasRole
  const roleColor = getRoleInfoForAuthor(userId.toString(), roles).roleColor;
  const isActive = getRoleInfoForAuthor(userId.toString(), roles).isActive;

  const isChatRoomActive = () => {
    return communicationStore.conversationChatRoomStatus[communicationStore.currentConversationSid] !== ChatRoomStatus.ARCHIVED;
  }
  return (
    <ListItem ContainerProps={{
      className: classes.participantRowContainer
    }}>
      <ListItemAvatar>
        <Avatar>
          <PersonRounded />
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <Box display="flex" alignItems="center">
          <DisplayNameWithOnlineStatus
            userId={userId}
            displayName={displayName}
            isActive={isActive} />
          {hasRole && isActive &&
            <Chip variant="default"
              size="small"
              label={roleName}
              className={classes.chip}
              style={{
                height: 18,
                backgroundColor: roleColor,
                color: roleColor ? theme.palette.getContrastText(roleColor) : 'black',
                fontWeight: 'bold'
              }} />
          }
        </Box>
      </ListItemText>
      <ListItemSecondaryAction>
        {
          canRemove && isChatRoomActive() &&
          <ConfirmationIconButton
            edge="end"
            testId="remove-participant"
            confirmDialogProps={{
              title: 'Remove Participant',
              dialogContentclasses: dialogContentclasses,
              body: (
                <>
                  <Warning
                    color="error"
                    fontSize="large"
                    style={{ marginRight: '16px' }}
                  />
                  <span>
                    {`Are you sure you want to remove the participant `}
                    <strong>{displayName}</strong>
                    {` from this conversation?`}
                  </span>
                </>
              ),
              confirmButtonText: 'Yes',
            }}
            onClick={() => onRemove(participant)}
          >
            <Delete />
          </ConfirmationIconButton>
        }
      </ListItemSecondaryAction>
    </ListItem>
  );
});
