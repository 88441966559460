import { Box, Button, Grid, Tab, Theme, Typography, Tabs, Tooltip, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";
import { displayColumns, fieldTypes, UW_APPRAISAL_REVIEW_STATUS } from "./appraisalReviewConstants";
import { useEffect, useState } from "react";
import { AppraisalAutomatedReviewSection } from "./appraisalAutomatedReviewSection";
import AppraisalReviewPhotos from "./appraisalReviewPhotos/appraisalReviewPhotosLinks";
import { EditableToogleButton } from "./appraisalReviewForm";
import { AppraisalReviewRevisionList } from "./appraisalReviewRevisionList";

export type SummaryData = {
  mapping: string,
  mappingToSave: string,
  title: string,
  value: any,
  options?: any,
  type: fieldTypes
  disabled: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: '#2199E0',
    borderColor: '#2199E0',
    backgroundColor: '#E2F6FF',
  },
  toogleButton: {
    '&[disabled]': {
      color: '#bbb',
      '& svg *': {
        fill: '#bbb',
      },
    },
  },
  addressWrapper: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    marginBottom: '30px',
    width: '100%',
  },
}));

const CancelButton = ({ onClick, className }) => (
  <Button
    onClick={onClick}
    className={className}
    variant="outlined"
    color="primary"
    fullWidth
  >
    CANCEL
  </Button>
)

interface Props {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
  onClose?: () => void;
  successCallback?: () => void;
}

export const UwAppraisalReviewReportForm = observer((props: Props) => {
  const classes = useStyles();
  const { store, propertyId, loanId } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    store.getPropertyInformmation(propertyId, loanId);
    store.getAutomatedReviewItems(propertyId, loanId);
    store.getAutomatedAppraisalReviewPhotos(propertyId, loanId);
    store.getAutomatedManualFlagByLoanId(propertyId, loanId);
  }, [store, loanId, propertyId]);

  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
    props.onClose?.();
  };

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUwChange = (status: string) => {
    store.updateUwAppraisalReviewStatus(loanId, propertyId, status, () => {
      window.parent.postMessage('saved-success', '*');
    });
  }

  return (
    <Box mt={3}>
      <Box mb={2} display={'flex'} alignItems={'center'}>
        <Typography variant="h4">{store.propertyInformation?.address || propertyId} - Appraisal Review</Typography>
        <EditableToogleButton
          checked={store.showAllItems}
          label="Show All"
          onClick={() => { store.setShowAllItems(!store.showAllItems) }}
        />
      </Box>
      <Box mb={2}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {!store.isNoResolutionStatus && <Tab label="Appraisal Revision" />}
          <Tab label="Appraisal Review" />
          <Tab label="Photos Review" />
        </Tabs>
      </Box>
      {selectedTab == 0 && !store.isNoResolutionStatus && (
        <Box>
          <AppraisalReviewRevisionList propertyId={props.propertyId} loanId={props.loanId} store={store} isUnderwritingReview />
        </Box>
      )}
      {selectedTab === (store.isNoResolutionStatus ? 0 : 1) && (<>

        <Box>
          {store.showAllItems ? (<>
            {store.automatedReviewSectionsToDisplay.map(section => (
              <Grid className={classes.addressWrapper}>
                <AppraisalAutomatedReviewSection section={section} store={store} columns={displayColumns} />
              </Grid>
            ))}
          </>) : (<>
            {(store.uwAutomatedReviewSectionsToDisplay?.length > 0) ? store.uwAutomatedReviewSectionsToDisplay.map(section => (
              <Grid className={classes.addressWrapper}>
                <AppraisalAutomatedReviewSection section={section} store={store} columns={displayColumns} />
              </Grid>
            ))
              : (
                <Grid className={classes.addressWrapper}>
                  <Typography variant="h6">No items to review yet</Typography>
                </Grid>
              )}
          </>)}

        </Box>
      </>)}
      {selectedTab == (store.isNoResolutionStatus ? 1 : 2) && (
        <Box>
          <AppraisalReviewPhotos propertyId={props.propertyId} loanId={props.loanId} store={store} />
        </Box>
      )}
      <Box mt={2}>
        <Grid container spacing={2} xs={12} justifyContent="flex-end">
          <Grid item xs={12} sm={4} md={3}>
            <CancelButton onClick={handleCloseButton} className={classes.button} />
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Button
              onClick={() => { handleUwChange(UW_APPRAISAL_REVIEW_STATUS.ACKNOWLEDGED) }}
              variant="contained"
              color="primary"
              fullWidth
            >
              ACKNOWLEDGE AND CLOSE
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
});