import { LoanSubmissionStep } from '@roc/feature-loans';
import { LoanSubType } from '@roc/feature-utils';

export const loanSubTypeOptions = [
  {
    label: 'Fix & Flip',
    value: LoanSubType.FIX_AND_FLIP,
  },
  {
    label: 'Ground Up',
    value: LoanSubType.GROUND_UP,
  },
  {
    label: 'Single Property Rental',
    value: LoanSubType.SINGLE_PROPERTY,
  },
];

export const borrowerLoanSubmissionSteps = {
  [LoanSubmissionStep.PROPERTY_INFORMATION]: 0,
  [LoanSubmissionStep.ENTITY_INFORMATION]: 1,
  [LoanSubmissionStep.BORROWER_INFORMATION]: 2,
  [LoanSubmissionStep.SUMMARY]: 3,
  [LoanSubmissionStep.SUCCESS]: 4,
}

export enum BorrowerInformationSteps {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  CO_BORROWER_INFORMATION = 'CO_BORROWER_INFORMATION',
  EDIT_PERSONAL_INFORMATION = 'EDIT_PERSONAL_INFORMATION',
  EDIT_CO_BORROWER_INFORMATION = 'EDIT_CO_BORROWER_INFORMATION',
  ENTITY_OWNERSHIP = 'ENTITY_OWNERSHIP',
  BORROWER_INFORMATION_COMPLETED = 'BORROWER_INFORMATION_COMPLETED',
}

export enum BorrowerEntityInformationSteps {
  ENTITY_INFORMATION = 'ENTITY_INFORMATION',
  ENTITY_OWNERSHIP = 'ENTITY_OWNERSHIP',
  EDIT_ENTITY_INFORMATION = 'EDIT_ENTITY_INFORMATION',
  EDIT_OWNERSHIP_INFORMATION = 'EDIT_OWNERSHIP_INFORMATION',
  ENTITY_INFORMATION_COMPLETED = 'ENTITY_INFORMATION_COMPLETED',
}

export enum PropertyInformationSteps {
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  EDIT_PROPERTY_INFORMATION = 'EDIT_PROPERTY_INFORMATION',
  PROPERTY_INFORMATION_COMPLETED = 'PROPERTY_INFORMATION_COMPLETED',
}
