import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { InsuranceDashboard } from '../components/insuranceDashboard';

export const getInsuranceDashboardRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    insuranceAgentDashboard: {
      path: `${basePath}/insurance-dashboard`,
      url: `${baseUrl}/insurance-dashboard`,
    },
  };
};

export const getInsuranceDashboardRoutes = (basePath: string, baseUrl: string) => {
  const config = getInsuranceDashboardRoutesConfig(basePath, baseUrl);

  return {
    insuranceDashboard: (
      <Route exact path={config.insuranceAgentDashboard.path}>
        <Page routeDefinition={config.insuranceAgentDashboard}>
          <InsuranceDashboard />
        </Page>
      </Route>
    ),
  };
};