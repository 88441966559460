import { GlobalStore } from '@roc/feature-app-core';
import { Document } from '@roc/feature-types';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { BorrowerService } from 'libs/feature-borrower-review/src/services/borrowerService';
import { action, flow, makeObservable, observable } from 'mobx';
import { BorrowerLoansService } from '../modules/borrowerLoans';
export class BorrowerLoanTasksStore {
  private globalStore: GlobalStore;
  private borrowerLoansService: BorrowerLoansService;
  tasks = [];
  idVerificationList = [];
  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.borrowerLoansService = new BorrowerLoansService();
    makeObservable(this, {
      tasks: observable,
      idVerificationList: observable,
      reset: action,
      fetchTasks: flow,
      uploadDocument: flow
    });
  }
  reset() {
    this.tasks = [];
    this.idVerificationList = [];
  }
  *fetchTasks(loanId: number) {
    const response = yield this.borrowerLoansService.getBorrowerLoanTasks(
      loanId
    );
    this.tasks = response.data?.data?.loanDocuments || [];
    const idVerificationListMapped = (
      response.data?.data?.idVerificationList || []
    ).map(id => ({
      ...id,
      documentName: 'Identity Verification',
      status: 'Pending',
    }));
    this.idVerificationList = idVerificationListMapped;
  }

  *uploadDocument(loanId: number, docRef: Document, file: File) {
    try {
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], file.name, { type: file.type });
      const formData = new FormData();
      formData.append('file', newFile);
      yield this.borrowerLoansService.uploadDocument(
        formData,
        docRef.loanTaskId
      );
      yield this.fetchTasks(loanId)
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
}
