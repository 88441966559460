import { action, makeObservable, observable, toJS } from 'mobx';
import * as Validator from 'validatorjs';
import { GlobalStore } from './globalStore';
import { errorMessages } from '@roc/feature-utils';

export const GENERIC_VALIDATION_NOTIFICATION = 'Please fix the validation errors.';

export class FormStore {
  private initialForm: any;
  private _globalStore: GlobalStore;
  private rules: any;
  private attributes;
  private customMessages;
  form: any;

  constructor(form: any, globalStore: GlobalStore) {
    this._globalStore = globalStore;
    const _form = JSON.stringify(form);
    this.initialForm = JSON.parse(_form);
    this.form = JSON.parse(_form);
    this.rules = this.getFlattenedValues('rule');
    this.attributes = this.getFlattenedValues('attribute');
    this.customMessages = this.getCustomMessages();
    makeObservable(this, {
      form: observable,
      onFieldChange: action,
      setError: action,
      loadForm: action,
      runFormValidation: action,
      runFormValidationWithMessage: action,
      reset: action,
      getFormValues: action,
      getValue: action,
    });
  }

  getFlattenedValues = (valueKey = 'value') => {
    const data = {};
    const form = toJS(this.form).fields;
    Object.keys(form)
      .filter(key => valueKey === 'value' || !!form[key][valueKey])
      .forEach(key => {
        data[key] = form[key][valueKey];
      });
    return data;
  };

  getCustomMessages = () => {
    const customMessages = { ...errorMessages };
    const form = toJS(this.form).fields;
    Object.keys(form)
      .filter(field => form[field].customMessages)
      .forEach(fieldName => {
        Object.keys(form[fieldName].customMessages).forEach(ruleName => {
          customMessages[`${ruleName}.${fieldName}`] =
            form[fieldName].customMessages[ruleName];
        });
      });
    return customMessages;
  };

  getFormValues: any = () => {
    const data = {};
    for (const key in this.form.fields) {
      data[key] = this.form.fields[key].value;
    }
    return data;
  };

  getValue: any = (field) => {
    return this.form.fields[field].value;
  };

  protected createFormValidator = () => {
    const values = this.getFlattenedValues('value');
    const validator = new Validator(values, this.rules, this.customMessages);
    validator.setAttributeNames(this.attributes);
    return validator;
  };

  onFieldChange(field, value) {
    this.form.fields[field].value = value;
    const validator = this.createFormValidator();
    this.form.meta.isValid = validator.passes();
    if (validator.errors.has(field) && this.form.fields[field].message) {
      this.form.fields[field].error = this.form.fields[field].message;
    } else {
      this.form.fields[field].error = validator.errors.first(field);
    }
  };

  runFormValidation = () => {
    for (const key in this.form.fields) {
      this.onFieldChange(key, this.form.fields[key].value);
    }
    const validator = this.createFormValidator();
    this.form.meta.isValid = validator.passes();
    if (!this.form.meta.isValid) {
      console.error(validator.errors);
    }
  };

  runFormValidationWithMessage = (customMessage = undefined) => {
    this.runFormValidation();
    if (this._globalStore && !this.form.meta.isValid) {
      this._globalStore.notificationStore.showWarningNotification({
        message: customMessage ? customMessage : GENERIC_VALIDATION_NOTIFICATION,
      });
      return false;
    }
    return true;
  };

  setError = errMsg => {
    this.form.meta.error = errMsg;
  };

  loadForm(obj: any) {
    Object.keys(this.form.fields).map(key => {
      if (obj.hasOwnProperty(key)) {
        this.form.fields[key].value = obj[key];
      }
    });
  };

  reset() {
    this.form = JSON.parse(JSON.stringify(this.initialForm));
  }


}

export default FormStore;
