/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Box } from "@material-ui/core";
import { TitleDivider } from "./titleDivider";
import { ItemDisplay } from "./itemDisplay";
import { PropertyStatusInformationStore } from "../stores/propertyStatusInformationStore";
import { observer } from "mobx-react";
import google from "../assets/google.png";
import map from "../assets/map.png";
import zillow from "../assets/zillow.png";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { formatCurrencyField, formatDateField } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) => ({
  imgLink: {
    width: "2.5rem",
    height: "2.5rem",
    cursor: "pointer"
  },
}));

interface PropertyStatusInformationProps {
  propertyStatusInformationStore: PropertyStatusInformationStore;
}

export const PropertyStatusInformation = observer(({ propertyStatusInformationStore }: PropertyStatusInformationProps) => {

  const classes = useStyles();
  const formValues: any = propertyStatusInformationStore.getFlattenedValues();

  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <TitleDivider title="Property Status Information" />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        <Box display="flex" gridGap="2rem">
          <ItemDisplay label="Property Status" value={formValues.propertyStatus || "-"} />
          <ItemDisplay label="Sale Type" value={formValues.saleType || "-"} />
          <ItemDisplay label="Original List Date" value={formatDateField(formValues.originalListDate)} />
          <ItemDisplay label="Original List Price" value={formatCurrencyField(formValues.originalListPrice)} />
          <ItemDisplay label="Last Sale Date" value={formatDateField(formValues.lastSaleDate)} />
          <ItemDisplay label="Last Sale Price" value={formatCurrencyField(formValues.lastSalePrice)} />
        </Box>
        <Box display="flex" gridGap="0.5rem">
          <img src={google} alt="Google Logo" className={classes.imgLink} onClick={() => handleClick(formValues.googleLink)} />
          <img src={map} alt="Google maps Logo" className={classes.imgLink} onClick={() => handleClick(formValues.googleMapsLink)} />
          <img src={zillow} alt="Zillow Logo" className={classes.imgLink} onClick={() => handleClick(formValues.zillowLink)} />
        </Box>
      </Box>
    </>
  );
});
