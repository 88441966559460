import { Box, Button, Grid, Tab, Theme, Typography, Tabs, Tooltip, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import { AppraisalReviewSummaryData } from "./appraisalReviewSummaryData";
import { AppraisalReviewStore } from "../stores/appraisalReviewStore";
import { fieldTypes } from "./appraisalReviewConstants";
import { ConfirmationButton } from "@roc/ui";
import { AppraisalAutomatedReview } from "./appraisalAutomatedReview";
import { AppraisalReviewAddItemToRevisionList } from "./appraisalReviewAddItemToRevisionList";
import { useEffect, useState } from "react";
import AppraisalReviewPhotos from "./appraisalReviewPhotos/appraisalReviewPhotosLinks";
import { ManualOrAutomatedChip } from "./manualOrAutomatedChip";
import { useUserStore } from "@roc/feature-app-core";
import SaveButtonWithPermissions from "libs/ui/src/lib/components/shared/save-button-with-permissions";
import { MANUAL_EDIT_APPRAISAL_REVIEW } from "./constants";
import { APPRAISAL_REPORT_VERSION_3 } from "@roc/feature-utils";
import { InternalRevisionTextBoxButton } from "./internalRevisionTextBoxButton";
import { AppraisalReviewRevisionList } from "./appraisalReviewRevisionList";

export type SummaryData = {
  mapping: string,
  mappingToSave: string,
  title: string,
  value: any,
  options?: any,
  type: fieldTypes
  disabled: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: '#2199E0',
    borderColor: '#2199E0',
    backgroundColor: '#E2F6FF',
  },
  toogleButton: {
    '&[disabled]': {
      color: '#bbb',
      '& svg *': {
        fill: '#bbb',
      },
    },
  },
}));

const CancelButton = ({ onClick, className }) => (
  <Button
    onClick={onClick}
    className={className}
    variant="outlined"
    color="primary"
    fullWidth
  >
    CANCEL
  </Button>
)

const SaveButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="outlined"
    color="primary"
    fullWidth
  >
    SAVE
  </Button>
);



export const EditableToogleButton = ({ onClick, checked, label }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onClick} color="primary" />}
      label={label}
      labelPlacement="start"
    />
  );
}

interface Props {
  loanId: number;
  propertyId: number;
  store: AppraisalReviewStore;
  onClose?: () => void;
  hideTitle?: boolean;
  successCallback?: () => void;
}

export const AppraisalReviewReportForm = observer((props: Props) => {
  const classes = useStyles();
  const { store, propertyId, loanId, hideTitle } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const { userStore } = useUserStore();

  useEffect(() => {
    store.getPropertyInformmation(propertyId, loanId);
    store.getAutomatedAppraisalReviewPhotos(propertyId, loanId);
    store.setSuccessCallback(props.successCallback);
    store.getAppraisalReviewItemNames();
  }, [store, loanId, propertyId]);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseButton = () => {
    window.parent.postMessage('close-modal', '*');
    props.onClose?.();
  };

  const handleConfirmationCompleteReviewAction = () => {
    store.approveReview(props.propertyId, props.loanId);
  };

  const handleConfirmationCreateAppraisal1004D = () => {
    store.requestAppraisal1004D(props.propertyId, props.loanId);
  };

  const saveAndClose = async () => {
    await store.saveDataAndClose(props.propertyId, props.loanId);
  }

  return (
    <Box mt={3}>
      {
        hideTitle ? null :
          <Box mb={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h4">Appraisal Review Report</Typography>
            <ManualOrAutomatedChip status={store.manualAutomatedStatus} />
            {userStore.canEditAppraisalReportConfig(store.reportVersion) && (
              <EditableToogleButton
                checked={store.editEnabled}
                label="Editable"
                onClick={() => { store.updateReportEditableFlag(propertyId) }}
              />
            )}
          </Box>
      }
      <Box>
        <AppraisalReviewSummaryData store={store} />
      </Box>
      <Box mt={2} mb={3}>
        <Grid container spacing={2} xs={12} justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={2}>
            <CancelButton onClick={handleCloseButton} className={classes.button} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <SaveButtonWithPermissions
              onClick={() => store.saveDataAndClose(props.propertyId, props.loanId)}
              loanId={props.loanId}
              label="SAVE"
              variant="outlined"
              color="primary"
              disabled={store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Appraisal Review" />
          <Tab label="Photos Review" />
        </Tabs>
      </Box>
      {selectedTab === 0 && (<>
        <Box>
          <AppraisalAutomatedReview propertyId={props.propertyId} loanId={props.loanId} store={store} />
        </Box>
        {<Box>
          <AppraisalReviewRevisionList propertyId={props.propertyId} loanId={props.loanId} store={store} />
        </Box>}
        <Box mt={2}>
          <Grid container spacing={2} xs={12} justifyContent="flex-end">
            <Grid container spacing={2} justifyContent="flex-end" style={{ paddingBottom: '10px' }}>
              <Grid item xs={12} sm={4} md={3}>
                <ConfirmationButton
                  variant="contained"
                  onClick={handleConfirmationCreateAppraisal1004D}
                  color="primary"
                  testId="confirmReview"
                  confirmDialogProps={{
                    title: 'Create Revision Request',
                    body: 'Are you sure you want to create a revision request?',
                  }}
                  fullWidth
                  disabled={store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW}
                >
                  Create Revision Request
                </ConfirmationButton>
              </Grid>
              {/* {store.taskFrameworkEnabled &&
                (<Grid item xs={12} sm={4} md={3}>
                  <AppraisalReviewAddItemToRevisionList propertyId={props.propertyId} loanId={props.loanId} store={store} />
                </Grid>
                )} */}
              <Grid item xs={12} sm={4} md={3}>
                <ConfirmationButton
                  variant="contained"
                  onClick={handleConfirmationCompleteReviewAction}
                  color="primary"
                  testId="confirmReview"
                  confirmDialogProps={{
                    title: 'Complete Review',
                    body: 'Are you sure you want to mark this review as completed?',
                  }}
                  fullWidth
                  disabled={store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW}
                >
                  REVIEW COMPLETE
                </ConfirmationButton>
              </Grid>
              {store.reportVersion === APPRAISAL_REPORT_VERSION_3 && (
                <Grid item xs={12} sm={4} md={3}>
                  <InternalRevisionTextBoxButton store={store} propertyId={propertyId} loanId={loanId} />
                </Grid>
              )}
            </Grid>
            {/* divider */}
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={3} md={2}>
                <CancelButton onClick={handleCloseButton} className={classes.button} />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <SaveButtonWithPermissions
                  onClick={() => store.saveDataAndClose(props.propertyId, props.loanId)}
                  loanId={props.loanId}
                  label="SAVE"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={store.manualAutomatedStatus === MANUAL_EDIT_APPRAISAL_REVIEW}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box></>)}

      {selectedTab == 1 && (
        <Box>
          <AppraisalReviewPhotos propertyId={props.propertyId} loanId={props.loanId} store={store} />
        </Box>
      )}
    </Box>
  )
});