import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button, StandardDialog } from '@roc/ui';
import { LoanStore, routeByLoanSubType } from '@roc/feature-loans';
import { useHistory } from 'react-router';
import { DraftLoan } from '@roc/feature-types';
import { LoanStatus, LoanSubType } from '@roc/client-portal-shared/utils';
import { useEditLoanSubmissionRoutes } from '../../hooks/useEditLoanSubmissionRoutes';




export interface EditSubmissionButtonProps {
  loanStore: LoanStore;
  loanId: any
  buttonStyle?: any
}

export const EditSubmissionButton = observer(({ loanStore, loanId, buttonStyle }: EditSubmissionButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { push } = useHistory();
  const { editLoansStore } = loanStore;
  const { editLoanSubmissionRoutesConfig } = useEditLoanSubmissionRoutes()

  const status = loanStore.loanDetails?.status;

  return (
    <>
      <StandardDialog
        open={dialogOpen}
        title="Warning"
        maxWidth="sm"
        handleClose={() => setDialogOpen(false)}
        dialogContent={
          <>
            <Typography align='justify'>
              Are you sure you want to continue?
              <br />
            </Typography>
          </>
        }
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              variant="outlined"
              color="primary"
              onClick={() => { setDialogOpen(false) }}
              testId="cancelButton"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(false);
                push(
                  editLoanSubmissionRoutesConfig[
                    routeByLoanSubType[loanStore?.loanDetails?.loanSubType]
                  ](loanId.toString()).url
                );
              }}
              testId="confirmButton"
            >
              Confirm
            </Button>
          </>
        }
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setDialogOpen(true);
        }}
        className={buttonStyle}
        color="primary"
        testId={`edit-loan-button`}
      >
        Edit Loan
      </Button>
    </>
  )
});