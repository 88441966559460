import React from 'react';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,

  Chip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { SubdirectoryArrowRight } from '@material-ui/icons';
import { Paper, GoogleMap, TestDataAttribute } from '@roc/ui';
import { formatCurrency as formatCurrencyUtil } from '@roc/ui/utils';
import { sanitizeTestId } from '@roc/feature-utils';

const testId = sanitizeTestId;
const listItem = (label, value, index = null, icon = null) => {

  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1}>{label}</Box>
          <TestDataAttribute id={testId(`${label}-${index}`)}>
            <Box>{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const BooleanChip = ({ value }) => {
  const color = value ? green : red;
  return (
    <Chip
      label={value ? 'Yes' : 'No'}
      style={{
        fontWeight: 0,
        backgroundColor: color[200],
        color: color[900],
      }}
    />
  );
};

const divider = <Divider variant="middle" component="li" />;

const useStyles = makeStyles(theme =>
  createStyles({
    mapStyles: {
      position: `relative`,
      width: `100%`,
      height: `95%`,
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  })
);

const formatCurrency = value => (value ? formatCurrencyUtil(value) : null);

export interface PropertiesProps {
  properties?: any[];
}

const Properties: React.FC<PropertiesProps> = ({ properties: properties }) => {
  const classes = useStyles();
  return (
    <>
      {properties.map((property, index) => (
        <Grid item xs={12} key={property.propertyId}>
          <Paper title={property.address} className={classes.paper} testId={testId(`property-title-${index}`)}>
            <Grid container direction="row" spacing={4}>
              {property.latitude && property.longitude ? (
                <Grid item xs={12} md={6} lg={4}>
                  <GoogleMap
                    mapStyles={classes.mapStyles}
                    latitude={property.latitude}
                    longitude={property.longitude}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={6} lg={4}>
                <List>
                  {listItem('Acquisition', property.propertyOwnership, index)}
                  {divider}
                  {listItem(
                    'Purchase Price',
                    formatCurrency(property.purchasePrice),
                    index
                  )}
                  {divider}
                  {listItem(
                    'Reno Budget',
                    formatCurrency(property.renovationBudget),
                    index
                  )}
                  {divider}
                  {listItem('Purchase Date', property.purchaseDate, index)}
                  {divider}
                  {listItem('Property sourcing', property.propertySourcing, index)}
                  {property.wholesalerPurchase && (
                    <>
                      {divider}
                      {listItem(
                        'Sourcing',
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {property.wholesalerPurchase}
                        </Typography>,
                        index,
                        <Box flexGrow={1}>
                          <SubdirectoryArrowRight fontSize="small" />
                        </Box>
                      )}
                    </>
                  )}
                  {property.propertySourcing === 'Other' && (
                    <>
                      {divider}
                      {listItem(
                        'Explanation',
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {property.propertySourcingExplanation}
                        </Typography>,
                        index,
                        <Box flexGrow={1}>
                          <SubdirectoryArrowRight fontSize="small" />
                        </Box>
                      )}
                    </>
                  )}
                  {property.wholesalerPurchase === 'Other' && (
                    <>
                      {divider}
                      {listItem(
                        'Explanation',
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {property.propertySourcingExplanation}
                        </Typography>,
                        index,
                        <Box flexGrow={1}>
                          <SubdirectoryArrowRight fontSize="small" />
                        </Box>
                      )}
                    </>
                  )}
                  {divider}
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <List>
                  {listItem('Exit Strategy', property.exitStrategy, index)}
                  {divider}
                  {listItem('Completed Renovations', formatCurrency(property.sunkCost), index)}
                  {divider}
                  {listItem(
                    'Refinance Amount',
                    formatCurrency(property.refiAmount),
                    index
                  )}
                  {divider}
                  {listItem('Special project type', property.describeRenovation, index)}
                  {divider}
                  {listItem(
                    'Arms Length',
                    <BooleanChip value={property.armsLength} />,
                    index
                  )}
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <List>
                  {listItem('Arms Comment', property.armsLengthComment, index)}
                  {divider}
                  {listItem(
                    'WholeSale',
                    formatCurrency(property.wholesaleAmount),
                    index
                  )}
                  {divider}
                  {listItem('Occupancy', property.occupancy, index)}
                  {divider}
                  {listItem(
                    'Monthly Rent',
                    formatCurrency(property.monthlyRent),
                    index
                  )}
                  {divider}
                  {listItem('As-is-Value', formatCurrency(property.asIsValue), index)}
                  {divider}
                  {listItem(
                    'Min Release',
                    formatCurrency(property.releaseAmount),
                    index
                  )}
                  {divider}
                  {listItem('Ins Exp Date', property.insuranceExpiryDate, index)}
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <List>
                  {listItem('ARV', formatCurrency(property.afterRepairValue), index)}
                  {divider}
                  {listItem(
                    'Full Net Proceeds',
                    <BooleanChip value={property.fullNetProceeds} />,
                    index
                  )}
                  {divider}
                  {listItem('Release Comment', property.releaseComment, index)}
                  {divider}
                  {listItem('Zillow', formatCurrency(property.zEstimate), index)}
                  {divider}
                  {listItem(
                    'Zillow Low',
                    formatCurrency(property.zEstimateLow),
                    index
                  )}
                  {divider}
                  {listItem(
                    'Zillow High',
                    formatCurrency(property.zEstimateHigh),
                    index
                  )}
                  {divider}
                  {listItem('Median', formatCurrency(property.zEstimateMedian), index)}
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <List>
                  {listItem('Additional Comments', property.additionalComments, index)}
                  {divider}
                  {listItem('Lien Pos', property.lienPosition, index)}
                  {divider}
                  {listItem('Sr Amt', property.lienAmount, index)}
                  {divider}
                  {listItem('Flood Zone', property.floodZone, index)}
                  {divider}
                  {listItem(
                    'Rural Census',
                    <BooleanChip value={property.ruralProperty} />,
                    index
                  )}
                  {divider}
                  {listItem('Units',property.units, index)}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </>
  );
};

export default Properties;