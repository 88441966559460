import { observer } from 'mobx-react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { Button, createCheckboxField } from '@roc/ui';
import { GetApp, MailOutline, Edit, PersonAdd } from '@material-ui/icons';
import { useState } from 'react';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { QuoteStep } from '../../../utils/constants';
import { BorrowerEmailDialog } from './borrowerEmailDialog';
import { DownloadQuoteDialog } from './downloadQuoteDialog';

interface SummaryActionsProps {
  store: QuoteTypeStore;
  showEditEconomics?: boolean;
  showAddBorrower?: boolean;
}

export const SummaryActions = observer(
  ({
    store,
    showEditEconomics = false,
    showAddBorrower = false,
  }: SummaryActionsProps) => {
    const [showEmailBorrowerDialog, setShowEmailBorrowerDialog] = useState(
      false
    );
    const [showDownloadQuoteDialog, setShowDownloadQuoteDialog] = useState(
      false
    );

    const handleEditEconomics = () => {
      store.goToStep(QuoteStep.LOAN_ECONOMICS);
    };

    const handleAddBorrower = () => {
      store.borrowersStore.addNewBorrower();
    };

    const isLeverageException = store.loanEconomicsStore?.enableManualQuote;

    return (
      <>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              startIcon={<GetApp />}
              testId="download-quote"
              onClick={() => setShowDownloadQuoteDialog(true)}
            >
              DOWNLOAD QUOTE
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              startIcon={<MailOutline />}
              testId="email-borrower"
              onClick={() => setShowEmailBorrowerDialog(true)}
            >
              EMAIL QUOTE
            </Button>
          </Grid>
          {showEditEconomics && (
            <Grid item>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                startIcon={<Edit />}
                testId="edit-economics"
                onClick={handleEditEconomics}
              >
                EDIT ECONOMICS
              </Button>
            </Grid>
          )}
          {showAddBorrower && (
            <Grid item>
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                startIcon={<PersonAdd />}
                testId="edit-economics"
                onClick={handleAddBorrower}
              >
                ADD BORROWER
              </Button>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={store.summaryStore.acknowledgeTerms}
                      onChange={(e, checked) =>
                        store.summaryStore.toggleAcknowledgeTerms()
                      }
                      color={'primary'}
                    />
                  }
                  label={
                    'By checking this box, I, as a third party representing the borrower, certify that the borrower mentioned above has communicated and approved the terms of the loan outlined on this page.'
                  }
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <BorrowerEmailDialog
          store={store}
          open={showEmailBorrowerDialog}
          onClose={() => setShowEmailBorrowerDialog(false)}
        />
        <DownloadQuoteDialog
          store={store}
          open={showDownloadQuoteDialog}
          onClose={() => setShowDownloadQuoteDialog(false)}
        />
      </>
    );
  }
);
