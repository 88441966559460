import { createData } from 'libs/feature-loan-summary/src/loanSummary/loanSummaryNportal/loanColumnsHelper/loanColumnsHelper';
import { formatPercentage, formatCurrency } from '@roc/ui/utils';
import { DateFormat, formatDate } from '@roc/feature-utils';

export const generalStatsColumns = [
  // row #1
  { fieldname: "Total Amount", mapvalue: "totalLoanAmount", specialtype: "currency" },
  { fieldname: "Loan Subtype", mapvalue: "loanSubtype", specialtype: "TextBox" },
  { fieldname: "Foreclosure Stage", mapvalue: "foreclosureStage", specialtype: "TextBox" },
  { fieldname: "Pledge", mapvalue: "pledgeOfShares", specialtype: "boolean" },

  // row #2
  { fieldname: "Funded Amount", mapvalue: "fundedLoanAmount", specialtype: "currency" },
  { fieldname: "Originator", mapvalue: "lender", specialtype: "TextBox" },
  { fieldname: "Foreclosure Start Date", mapvalue: "foreclosureStartDate", specialtype: "TextBox" },
  { fieldname: "LTV", mapvalue: "ltv", specialtype: "percentage" },

  // row #3
  { fieldname: "Total Exposure", mapvalue: "totalExposure", specialtype: "currency" },
  { fieldname: "Holding Entity", mapvalue: "investor", specialtype: "TextBox" },
  { fieldname: "Foreclosure Stage Date", mapvalue: "foreclosureStageDate", specialtype: "TextBox" },
  { fieldname: "FPI Flag", mapvalue: "fpiFlag", specialtype: "boolean" },

  // row #4
  { fieldname: "Construction Completion", mapvalue: "constructionCompletion", specialtype: "percentage" },
  { fieldname: "Attorney", mapvalue: "attorney", specialtype: "TextBox" },
  { fieldname: "Estimated Uncontested FC Timeline", mapvalue: "foreclosureTimeline", specialtype: "TextBox" },
  { fieldname: "Insurance Expiry Date", mapvalue: "insuranceExpiryDate", specialtype: "TextBox" },
];

export const protectiveAdvancesColumns = [
  { fieldname: "", mapvalue: "", specialtype: "TextBox" },
  { fieldname: "Payee", mapvalue: "payee", specialtype: "TextBox" },
  { fieldname: "Type", mapvalue: "feeType", specialtype: "TextBox" },
  { fieldname: "Received Date", mapvalue: "receivedDate", specialtype: "TextBox" },
  { fieldname: "Amount", mapvalue: "amount", specialtype: "currency" },
];

export const valuationReviewGridColumns = [
  {
    field: 'propertyAddress',
    headerName: 'Property Address',
    minWidth: 60,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'type',
    headerName: 'Valuation Type',
    cellRenderer: 'typeCellRenderer',
    minWidth: 150,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'asIsValue',
    headerName: 'As Is Value',
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: 'currencyCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'afterRepairValue',
    headerName: 'ARV',
    minWidth: 150,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    cellRenderer: 'currencyCellRenderer',
    floatingFilter: true,
    hide: false,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: 'dateCellRenderer',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'loanDqStatus',
    headerName: 'Loan DQ Status',
    minWidth: 150,
    maxWidth: 150,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: '',
    headerName: 'Actions',
    cellRenderer: 'actionsCellRenderer',
    minWidth: 110,
    maxWidth: 110,
  },
];

export const valuationHistoryColumns = [
  { fieldname: "", mapvalue: "", specialtype: "TextBox" },
  { fieldname: "Appraisal Date", mapvalue: "date", specialtype: "date" },
  { fieldname: "As Is Value", mapvalue: "asIsValue", specialtype: "formattedCurrency" },
  { fieldname: "After Repair Value", mapvalue: "afterRepairValue", specialtype: "formattedCurrency" },
];

export const realStateOwnedDetailsColumns = [
  { fieldname: "Deed Recorded", mapvalue: "test1", specialtype: "TextBox" },
  { fieldname: "Property Listed", mapvalue: "test2", specialtype: "TextBox" },
  { fieldname: "Property Listed Price", mapvalue: "test3", specialtype: "TextBox" },
  { fieldname: "Contact Price", mapvalue: "test4", specialtype: "TextBox" },
];

const formatMapValue = (field, mapValue) => {
  if (field.specialtype === "percentage") {
    return formatPercentage(mapValue, 2);
  } else if (field.specialtype === "currency") {
    return formatCurrency(mapValue, 2);
  } else if (field.specialtype === "formattedCurrency") {
    return mapValue ? formatCurrency(mapValue, 2) : 'N/A';
  } else if (field.specialtype === "boolean") {
    return mapValue ? 'Yes' : 'No';
  } else if (mapValue === 0) {
    return '0'
  }
  else if (field.specialtype === "date") {
    return formatDate(mapValue, DateFormat.MMDDYYYY);
  }
  else {
    return mapValue;
  }
};

export const transformDataV2 = (record: any, columns: any[]) => {

  const result = [];
  columns.forEach((field, index) => {
    let mapValue = record[field.mapvalue];
    const fieldName = field.fieldname;
    result.push(createData(fieldName, formatMapValue(field, mapValue)));
  });
  return result;
};

export const getTotalFromSection = (records: any[], key: string) => {
  let result = 0;
  records.forEach((record, index) => {
    let mapValue = record[key];
    result += mapValue ? mapValue : 0;
  });
  return formatCurrency(result, 2);
};

export const valuationTypeOptions = [
  {
    label: 'Internal',
    value: 'INTERNAL'
  },
  {
    label: 'Appraisal',
    value: 'FORECLOSURE_APPRAISAL'
  },
  {
    label: 'BPO',
    value: 'BPO'
  }
]


export const dqStatusOptions = [
  {
    label: 'Late',
    value: 'Late'
  },
  {
    label: 'DQ30+',
    value: '30+'
  },
  {
    label: 'DQ60+',
    value: '60+'
  },
  {
    label: 'DQ90+',
    value: '90+'
  },
  {
    label: 'Foreclosure',
    value: 'Foreclosure'
  },
  {
    label: 'REO',
    value: 'REO'
  },
]