import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { observer } from 'mobx-react';
import { Paper } from '@roc/ui';
import { WarningOutlined, ErrorOutline } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
    marginTop: '-18px',
  },
  paperV2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
    marginTop: '16px'
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  messageBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FEF0ED',
    color: '#642B20',
    borderRadius: 16
  },
  icon: {
    color: theme.palette.warning.light,
  },
  titleContainer: {
    paddingBottom: '0px !important'
  },
  inlineItemTypography: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21.98px',
    letterSpacing: '0.1px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  }
}));




export const BannerMessages = observer((props) => {
  const classes = useStyles();
  const { messages, isInlineItem } = props;
  const { newDesignForSummaryScreen } = props;
  let messageComponents = [];
  messageComponents = messages && messages.map(message => {
    const content: string = message.content;

    return (
      <Grid container className={classes.content} spacing={2}>
        <Grid item>
          {newDesignForSummaryScreen ? <ErrorOutline className={classes.icon} /> : <WarningOutlined className={classes.icon} />}
        </Grid>
        <Grid item xs={11} className={classes.titleContainer}>
          <Typography className={isInlineItem ? classes.inlineItemTypography : ''}>
            <strong>
              {message.title}
            </strong>
            <br />
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </Typography>
        </Grid>
      </Grid >
    );
  }

  );
  return (
    <>
      {messages?.length > 0 ? (
        <>
          {!newDesignForSummaryScreen && <Paper className={isInlineItem ? classes.paperV2 : classes.paper} title="">
            {messageComponents}
          </Paper>}
          {newDesignForSummaryScreen && <Box className={classes.messageBox}>{messageComponents}</Box>}
          <br />
        </>) : <Grid />
      }
    </>
  );
});
