import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DealEconomicsDialog } from './dealEconomics/dealEconomicsDialog';
import { RequestedClosingDateDialog } from './requestedClosingDate/requestedClosingDateDialog';
import { ExpectedClosingDateDialog } from './expectedClosingDate/expectedClosingDateDialog';
import { ACHFormDialog } from './achForm/achForm';
import { LoanPaymentsDialog } from './loanPayments/loanPayments/loanPaymentsDialog';
import LoanFastTrackPaymentDialog from './loanFastTrackPayment/loanFastTrackPayment/loanFastTrackPaymentDialog';
import { InsuranceProviderDialog } from './insuranceProvider/insuranceProviderDialog';
import { InsuranceApprovalDialog } from './insuranceApproval/insuranceApprovalDialog';
import { FundingTemplateDialog } from './fundingTemplate/fundingTemplateDialog';
import BorrowerSurveyDialog from './borrowerSurvey/borrowerSurveyDialog';
import BorrowerEntityDialog from './borrowerEntity/borrowerEntityDialog';
import LenderFeesDialog from './lenderFees/lenderFeesDialog';
import TitleAgentDialog from './titleAgent/titleAgentDialog';
import FundingPreferenceDialog from './fundingPreference/fundingPreferenceDialog';
import { ExpectedWireReleaseDateDialog } from './expectedWireReleaseDate/expectedWireReleaseDateDialog';
import BorrowerClosingAgentDialog from './borrowerClosingAgent/borrowerClosingAgentDialog';
import {
  ScopeOfWorkV2FormDialog,
  ScopeOfWorkV2LinkDialog,
} from '@roc/feature-sow-v2';
import TermSheetDialog from './termSheet/termSheet/termSheetDialog';
import NoteAllongeDialog from './noteAllonge/noteAllongeDialog';
import AssignmentAndConveyanceDialog from './assignmentAndConveyance/assignmentAndConveyanceDialog';
import { PointOfContactDialog, DrawReportDialog } from '@roc/feature-draws';

import {
  DEAL_ECONOMICS,
  REQUESTED_CLOSING_DATE,
  EXPECTED_CLOSING_DATE,
  DUE_DILIGENCE_PAYMENT,
  LOAN_FAST_TRACK_PAYMENT,
  TERM_SHEET,
  BORROWER_SURVEY_QUESTIONNAIRE,
  BORROWER_ENTITY,
  LENDER_FEES,
  TITLE_AGENT,
  INSURANCE_PROVIDER,
  FUNDING_PREFERENCE,
  EXPECTED_WIRE_RELEASE_DATE,
  INSURANCE_APPROVAL,
  LOAN_FUNDING_TEMPLATE,
  APPRAISAL_ORDER_DETAILS,
  BORROWER_CLOSING_AGENT,
  NOTE_ALLONGE,
  ASSIGNMENT_AND_CONVEYANCE,
  SCOPE_OF_WORK_FORM_V2,
  SCOPE_OF_WORK_LINK_V2,
  POINT_OF_CONTACT,
  RATE_LOCK_EXTENSION,
  TITLE_COMPANY_BANK_INFORMATION,
  RESOLUTION_ITEM_FORM,
  DRAW_REPORT,
  BORROWER_BACKGROUND_REVIEW,
  BORROWER_CREDIT_REVIEW,
  TRACK_RECORD_REVIEW,
  CREDIT_REPORT,
  TRIMERGE_CREDIT_REPORT,
  CLOSING_CONDITION,
  APPRAISAL_DETAILS,
  ACH_FORM,
  APPRAISAL_REPORT_ARV,
  APPRAISAL_REPORT_AS_IS,
  APPRAISAL_REPORT_AS_IS_RENT,
  APPRAISAL_REPORT,
  BORROWER_SETUP,
  TRACK_RECORD,
  APPRAISAL_REVIEW_REPORT,
  REFERRAL_INFORMATION,
  BANK_STATEMENT_PREFIX,
  LEASE_INFORMATION_UNIT_PREFIX,
  TAX_ESCROW,
  POST_DISASTER_INSPECTION,
  PERSONAL_IDENTIFICATION,
  CONSTRUCTION_FEASIBILITY_REVIEW,
  INTERNAL_VALUATION_REPORT,
  BANK_STATEMENT_MONTH_PREFIX,
  PERMITS,
  INSURANCE_INFORMATION,
  INSURANCE_INFORMATION_REVIEW,
} from '../../constants';
import { RateLockExtensionDialog } from './../documentForms/rateLockExtension/rateLockExtensionDialog';
import { useLoanStore } from '@roc/feature-loans';
import { useDocumentStore } from '../../hooks/useDocumentStore';
import { TitleCompanyBankInformationDialog } from './titleCompanyBankInformation/titleCompanyBankInformationDialog';
import { PlaidCopyUrlModal } from '@roc/feature-plaid';
import { DocumentStore } from '../../stores/documentStore';
import { useUserStore } from '@roc/feature-app-core';
import ResolutionItem from './resolutionItem/resolutionItem';
import { BorrowerBackgroundReviewDialog } from './borrowerBackgroundReview/borrowerBackgroundReviewDialog';
import { BorrowerCreditReviewDialog } from './borrowerCreditReview/borrowerCreditReviewDialog'
import { NewBorrowerBaseballCardDialog } from './borrowerBaseballCard/newBorrowerBaseballCardDialog';
import { CreditReport } from './creditReport/creditReport';
import { LoanClosingConditionModal } from './loanClosingCondition/loanClosingConditionDialog';
import { AppraisalOrderDetailsWithARRDialog } from './appraisalOrderAndCDADetails/loanPayments/appraisalOrderDetailsDialog';
import { AppraisalOrderDetailsDialog } from './appraisalOrderDetails/appraisalOrderDetailsDialog';
import { useStore } from '@roc/client-portal-shared/hooks';
import { AppraisalReportDialog } from './appraisalReport/appraisalReportDialog';
import { BorrowerSetupDialog } from './borrowerSetup/borrowerSetupDialog';
import EstimateFundingTemplateDialog from './fundingTemplate/estimateFundingTemplateNumbers';
import { TrackRecordLineItemDialog } from './borrowerBaseballCard/trackRecordLineItemDialog';
import { AppraisalReviewReportDialog } from './appraisalReviewReport/appraisalReviewReportDialog';
import { ReferrerInformationDialog } from './referralFee/referrerInformationDialog';
import BankStatementDialog from './bankStatement/bankStatementDialog';
import LeaseInformationDialog from './leaseInformation/leaseInformationDialog';
import TaxEscrowDialog from './taxEscrow/taxEscrowDialog';
import { LoanProcess } from '@roc/feature-utils';
import { BorrowerSetupToDoDialog } from '@roc/feature-borrowers';
import PostDisasterInspectionDialog from './postDisasterInspection/postDisasterInspectionDialog';
import { PersonalIdentificationDialog } from './personalIdentification/personalIdentificationDialog';
import { ConstructionFeasibilityToDoDialog } from 'libs/feature-construction-feasibility/src';
import { InternalValuationReportDialog } from './internalValuationReport/internalValuationReportDialog';
import { PermitsDialog } from './permits/permitsDialog';
import { InsuranceInformationDialog, InsuranceInformationReviewDialog } from '@roc/feature-insurance-review';

export type DocumentFormsProps = {
  loanId: string;
  onClose?: () => void;
};

export const DocumentForms = observer((props: DocumentFormsProps) => {
  const { loanId, onClose } = props;
  const { loanStore } = useLoanStore();
  const {
    documentStore: { documentFormStore, currentDocument },
  } = useDocumentStore();

  const { globalStore, insuranceInformationStore,
    insuranceInformationReviewStore } = useStore();

  const { documentStore } = useDocumentStore();
  const closeCurrentForm = () => {
    onClose && onClose();
    documentFormStore.closeCurrentForm();
  };

  const closeNoteAllongeAndAssignment = () => {
    onClose && onClose();
    documentFormStore.onCloseNoteAllongeAndAssignment();
  }

  const plaidShareBaseURL = window.location.href.split('lender-portal');
  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const currentForm = documentFormStore.currentForm;
  const isClosingConditionForm = currentForm && currentForm.includes('Closing Condition - ');

  useEffect(() => {
    return () => {
      documentFormStore.reset();
    };
  }, [documentFormStore]);
  return (
    <>
      {allowLoanPricing &&
        <DealEconomicsDialog
          open={documentFormStore.currentForm === DEAL_ECONOMICS}
          onClose={closeCurrentForm}
        />
      }
      <ExpectedClosingDateDialog
        open={documentFormStore.currentForm === EXPECTED_CLOSING_DATE}
        onClose={closeCurrentForm}
        loanId={loanId}
        store={documentFormStore.expectedClosingDateStore}
      />
      {globalStore.userFeatures?.allowACHForm && <ACHFormDialog
        open={documentFormStore.currentForm === ACH_FORM}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
      />}
      <RequestedClosingDateDialog
        open={documentFormStore.currentForm === REQUESTED_CLOSING_DATE}
        onClose={closeCurrentForm}
        loanId={loanId}
        store={documentFormStore.requestedClosingDateStore}
      />
      <ReferrerInformationDialog
        open={documentFormStore.currentForm === REFERRAL_INFORMATION}
        onClose={closeCurrentForm}
        loanId={loanId}
        brokerId={loanStore?.loanDetails?.broker?.lenderId}
      />
      <LoanPaymentsDialog
        open={documentFormStore.currentForm === DUE_DILIGENCE_PAYMENT}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <LoanFastTrackPaymentDialog
        open={documentFormStore.currentForm === LOAN_FAST_TRACK_PAYMENT}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <TermSheetDialog
        open={documentFormStore.currentForm === TERM_SHEET}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <BorrowerSurveyDialog
        open={documentFormStore.currentForm === BORROWER_SURVEY_QUESTIONNAIRE}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <BorrowerEntityDialog
        open={documentFormStore.currentForm === BORROWER_ENTITY}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      {allowLoanPricing &&
        <LenderFeesDialog
          open={documentFormStore.currentForm === LENDER_FEES}
          onClose={closeCurrentForm}
          loanId={loanId}
          document={currentDocument}
        />
      }
      <TitleAgentDialog
        open={documentFormStore.currentForm === TITLE_AGENT}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <InsuranceProviderDialog
        open={documentFormStore.currentForm === INSURANCE_PROVIDER}
        onClose={closeCurrentForm}
        document={currentDocument}
      />
      <FundingPreferenceDialog
        open={documentFormStore.currentForm === FUNDING_PREFERENCE}
        onClose={closeCurrentForm}
        loanId={loanId}
      />
      <ExpectedWireReleaseDateDialog
        open={documentFormStore.currentForm === EXPECTED_WIRE_RELEASE_DATE}
        onClose={closeCurrentForm}
        loanId={loanId}
        store={documentFormStore.expectedWireReleaseDateStore}
      />
      <InsuranceApprovalDialog
        open={documentFormStore.currentForm === INSURANCE_APPROVAL}
        onClose={closeCurrentForm}
        document={currentDocument}
      />
      <InsuranceInformationDialog
        open={documentFormStore.currentForm === INSURANCE_INFORMATION}
        onClose={closeCurrentForm}
        document={currentDocument}
        store={insuranceInformationStore}
        loanId={loanId}
      />
      <InsuranceInformationReviewDialog
        open={documentFormStore.currentForm === INSURANCE_INFORMATION_REVIEW}
        onClose={closeCurrentForm}
        document={currentDocument}
        store={insuranceInformationReviewStore}
        loanId={loanId}
      />
      <FundingTemplateDialog
        open={documentFormStore.currentForm === LOAN_FUNDING_TEMPLATE}
        onClose={closeCurrentForm}
      />
      <EstimateFundingTemplateDialog
        open={documentStore.openEstimateFundingTemplate}
        onClose={() => documentStore.setOpenEstimateFundingTemplate(false)}
      />
      <BorrowerClosingAgentDialog
        open={documentFormStore.currentForm === BORROWER_CLOSING_AGENT}
        onClose={closeCurrentForm}
        document={currentDocument}
      />
      <ScopeOfWorkV2LinkDialog
        open={documentFormStore.currentForm === SCOPE_OF_WORK_LINK_V2}
        onClose={closeCurrentForm}
        loanId={currentDocument?.loanId}
        propertyId={currentDocument?.collateralId}
        propertyAddress={currentDocument?.collateralAddress}
        store={documentFormStore.scopeOfWorkV2LinkStore}
      />
      <ScopeOfWorkV2FormDialog
        open={documentFormStore.currentForm === SCOPE_OF_WORK_FORM_V2}
        onClose={closeCurrentForm}
        store={documentFormStore.scopeOfWorkV2FormStore}
      />
      <NoteAllongeDialog
        open={documentFormStore.currentForm === NOTE_ALLONGE}
        onClose={closeNoteAllongeAndAssignment}
      />
      <AssignmentAndConveyanceDialog
        open={documentFormStore.currentForm === ASSIGNMENT_AND_CONVEYANCE}
        onClose={closeNoteAllongeAndAssignment}
      />
      <PointOfContactDialog
        open={documentFormStore.currentForm === POINT_OF_CONTACT}
        onClose={closeCurrentForm}
      />
      <RateLockExtensionDialog
        open={documentFormStore.currentForm === RATE_LOCK_EXTENSION}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <TitleCompanyBankInformationDialog
        open={documentFormStore.currentForm === TITLE_COMPANY_BANK_INFORMATION}
        onClose={closeCurrentForm}
      />
      <PlaidCopyUrlModal
        isOpen={documentStore.openSharePlaidLinkModal}
        url={plaidShareBaseURL[0] + 'borrower-portal/profile'}
        handleClose={() => { documentStore.openSharePlaidLinkModal = false }}
        handleCopy={() => {
          navigator.clipboard.writeText(plaidShareBaseURL[0] + 'borrower-portal/profile');
          documentStore.openSharePlaidLinkModal = false
        }}
      />
      <ResolutionItem
        open={documentFormStore.currentForm === RESOLUTION_ITEM_FORM}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
      />
      <DrawReportDialog
        open={documentFormStore.currentForm === DRAW_REPORT}
        onClose={closeCurrentForm}
      />
      <BorrowerBackgroundReviewDialog
        open={documentFormStore.currentForm === BORROWER_BACKGROUND_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
        loan={loanStore.loanDetails}
      />
      <BorrowerCreditReviewDialog
        open={documentFormStore.currentForm === BORROWER_CREDIT_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
        loan={loanStore.loanDetails}
      />
      <NewBorrowerBaseballCardDialog
        open={documentFormStore.currentForm === TRACK_RECORD_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <TrackRecordLineItemDialog
        open={documentFormStore.currentForm === TRACK_RECORD}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <CreditReport
        open={documentFormStore.currentForm === CREDIT_REPORT}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
      />
      <CreditReport
        open={documentFormStore.currentForm === TRIMERGE_CREDIT_REPORT}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
      />
      <LoanClosingConditionModal
        open={isClosingConditionForm}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        taskName={currentDocument?.taskName}
      />
      <AppraisalOrderDetailsWithARRDialog
        open={documentFormStore.currentForm === APPRAISAL_DETAILS}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <AppraisalOrderDetailsDialog
        open={documentFormStore.currentForm === APPRAISAL_ORDER_DETAILS}
        onClose={closeCurrentForm}
        loanId={currentDocument?.loanId}
        collateralId={currentDocument?.collateralId}
      />
      <AppraisalReportDialog
        open={documentFormStore.currentForm === APPRAISAL_REPORT_ARV}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        document={currentDocument}
      />
      <AppraisalReportDialog
        open={documentFormStore.currentForm === APPRAISAL_REPORT_AS_IS}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        document={currentDocument}
      />
      <AppraisalReportDialog
        open={documentFormStore.currentForm === APPRAISAL_REPORT_AS_IS_RENT}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        document={currentDocument}
      />
      <AppraisalReportDialog
        open={documentFormStore.currentForm === APPRAISAL_REPORT}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        document={currentDocument}
      />
      {/* <BorrowerSetupDialog
        open={documentFormStore.currentForm === BORROWER_SETUP}
        onClose={closeCurrentForm}
        loanTaskId={currentDocument?.loanTaskId}
        taskName={currentDocument?.taskName}
        borrowerId={currentDocument?.borrowerId}
        loanType={loanStore.loanDetails?.loanType}
        loanId={currentDocument?.loanId}
      /> */}
      <BorrowerSetupToDoDialog
        open={documentFormStore.currentForm === BORROWER_SETUP}
        borrowerId={currentDocument?.borrowerId}
        loan={loanStore.loanDetails}
        handleClose={closeCurrentForm}
        refreshLoan={() => {
          loanStore.refreshLoanDetails();
          documentStore.refetchDocuments();
          documentStore.loanTodosStore.fetchTodos(currentDocument?.loanId, LoanProcess.UNDERWRITING);
        }}
      />
      <AppraisalReviewReportDialog
        open={documentFormStore.currentForm === APPRAISAL_REVIEW_REPORT}
        onClose={closeCurrentForm}
        document={currentDocument}
      />
      <BankStatementDialog
        open={documentFormStore.currentForm && (documentFormStore.currentForm.includes(BANK_STATEMENT_PREFIX) || documentFormStore.currentForm.includes(BANK_STATEMENT_MONTH_PREFIX))}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <LeaseInformationDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm.includes(LEASE_INFORMATION_UNIT_PREFIX)}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <TaxEscrowDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm.includes(TAX_ESCROW)}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
        refreshLoan={() => {
          loanStore.refreshLoanDetails();
          documentStore.refetchDocuments();
          documentStore.loanTodosStore.fetchTodos(currentDocument?.loanId, LoanProcess.UNDERWRITING);
        }}
      />
      <PostDisasterInspectionDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm === POST_DISASTER_INSPECTION}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <PersonalIdentificationDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm === PERSONAL_IDENTIFICATION}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      <ConstructionFeasibilityToDoDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm === CONSTRUCTION_FEASIBILITY_REVIEW}
        onClose={closeCurrentForm}
        loanId={loanId}
        document={currentDocument}
      />
      <InternalValuationReportDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm === INTERNAL_VALUATION_REPORT}
        onClose={closeCurrentForm}
        document={currentDocument}
      />
      <PermitsDialog
        open={documentFormStore.currentForm && documentFormStore.currentForm === PERMITS}
        onClose={closeCurrentForm}
        loan={loanStore.loanDetails}
        document={currentDocument}
      />
      {/* Add additional forms in here */}
    </>
  );
});

export default DocumentForms;
