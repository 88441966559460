import { Box, Divider, Grid, makeStyles, Typography, Button, Tooltip, IconButton as Icon, useTheme } from '@material-ui/core';
import { CloudDownload, Help, HelpOutline, CheckCircleOutline, WarningOutlined } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, FileUpload, TextField, FileUploadArea, FieldLabel, NumberFormat } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useCreditReportStore } from '../../hooks/useCreditReportStore';
import { CreditReportExampleDialog } from './creditReportExampleDialog';
import { Alert } from '@material-ui/lab';
import { DocumentStatus } from '@roc/feature-documents';
const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre'
  },
  buttonsGrid: {
    marginTop: '7px',
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    marginTop: '24px'
  },
  inputField: {
    marginTop: '6px'
  },
  icon: {
    color: "transparent",
    "& path": {
      stroke: "currentColor",
      strokeWidth: 1.5,
      fill: "transparent"
    }
  }
}));

export const CreditReportForm = observer(
  ({ open, handleClose, loanTaskId: loanTaskId, internal }) => {
    const classes = useStyles();
    const { creditReportStore } = useCreditReportStore();
    const { creditReport } = creditReportStore;
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const [openExampleModal, setOpenExampleModal] = useState(false);
    const theme = useTheme();
    creditReportStore.creditReport.documentId = loanTaskId;
    const FILE_ID_REQUIRED = 'creditReportFileId';

    useEffect(() => {
      if (loanTaskId) {
        creditReportStore.setInternal(internal);
        creditReportStore.loadCreditReport(loanTaskId);
      }
    }, [loanTaskId]);

    const closeExampleModal = () => {
      setOpenExampleModal(false);
    }

    const handleCloseButton = () => {
      if (internal) {
        window.parent.postMessage('close-modal', '*');
      } else {
        handleClose();
      }
    }

    const handleCloseAfterSave = () => {
      if (internal) {
        window.parent.postMessage('saved-success', '*');
      } else {
        handleClose();
      }
    }

    const openCreditExampleModal = () => {
      if (internal) {
        window.parent.postMessage('open-credit-example-modal', '*');
      } else {
        setOpenExampleModal(true);
      }
    }

    const onSave = async () => {
      if (!creditReportStore.creditReport?.fileId) {
        creditReportStore.showErrorMessage('File Id is required.')
        return;
      }
      if (!creditReportStore.document.dropboxPath && !uploads[0]) {
        creditReportStore.showErrorMessage('File is required.')
        return;
      }
      if (!creditReportStore.creditReport?.fileId && !uploads[0]) {
        creditReportStore.showErrorMessage('File is required.')
        return;
      }
      if ((!creditReportStore.ssnAtLoad && !creditReport.ssn) && creditReportStore.creditReport?.fileId) {
        creditReportStore.showErrorMessage('SSN is required.')
        return;
      }
      if (uploads[0]) {
        await creditReportStore.uploadDocument(uploads[0]);
      }
      if (creditReportStore.creditReport?.fileId && (creditReportStore.document.dropboxPath || uploads[0])) {
        await creditReportStore.reissueCreditReport(handleCloseAfterSave);
      }
      handleCloseAfterSave()
    };

    return (
      <Grid container style={{ minHeight: '300px' }}>
        <CreditReportExampleDialog open={openExampleModal} onClose={closeExampleModal} />
        {creditReportStore.creditReport.errorResponseMessage && creditReportStore.document.status == DocumentStatus.MORE_INFO_NEEDED && <Grid item xs={11}>
          <Alert severity="error" style={{ marginTop: '-25px', marginBottom: '15px' }}>
            <Grid>{creditReportStore.creditReport.errorResponseMessage}</Grid>
          </Alert>
        </Grid>}
        <Grid item xs={11}>
          <Typography
            component="span"
            variant='subtitle2'
            style={{ display: 'inline', padding: '0px', margin: '0px' }}
            color="textSecondary"
          >
            Please upload a XACTUS360 Credit Report and enter the Report ID (as suggested in the
            {<span
              style={{ cursor: 'pointer', color: theme.palette.primary.main }}
              onClick={openCreditExampleModal}> image</span>}). No other Credit Report format will be accepted, so please make sure you provide a XACTUS360 Credit Report. Please reach out to your Relationship Manager if you have any questions.
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {creditReportStore.canDownloadFile && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                type="submit"
                color="primary"
                testId="save"
                onClick={() => creditReportStore.downloadDocument()}
              >
                <CloudDownload />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} className={classes.inputContainer}>
          {!creditReportStore.ssnAtLoad ? (
            <>
              <Box display="flex" alignItems="center">
                <FieldLabel>
                  Social Security Number *
                </FieldLabel>
                <Tooltip title='Please provide Social Security Number to speed up review'>
                  <span
                    style={{ marginLeft: '8px' }}
                  >
                    <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
                  </span>
                </Tooltip>
              </Box>
              <Box>
                <NumberFormat
                  customInput={TextField}
                  format="###-##-####"
                  mask="_"
                  variant="outlined"
                  testId="ssn"
                  value={creditReportStore.creditReport?.ssn}
                  onChange={(e) => {
                    creditReportStore.setBorrowerSsn(e.target.value)
                  }}

                  style={{ width: '100%' }}
                />
              </Box>
            </>
          ) : null}

          <Box display="flex" alignItems="center" style={{ marginTop: '8px' }}>
            <FieldLabel>
              Report Id *
            </FieldLabel>
            <Tooltip title='Open Credit Report Example'>
              <span
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                onClick={openCreditExampleModal}
              >
                <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
              </span>
            </Tooltip>
          </Box>
          <TextField
            type="text-area"
            variant="outlined"
            label=""
            value={creditReportStore.creditReport?.fileId || ''}
            onChange={e => creditReportStore.setCreditFileId(e.target.value)}
            fullWidth
            multiline={false}
            minRows={4}
            margin="normal"
            testId="fileNumber"
            className={classes.inputField}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={uploads}
            setItems={(items) => setUploads(items)}
            multiple={false} />
        </Grid>

        <Grid container spacing={1} className={classes.buttonsGrid}>
          <>
            <Grid item xs={2} sm={3}></Grid>
            <Grid item xs={4} sm={3}>
              <Button
                onClick={() => handleCloseButton()}
                variant="outlined"
                color="primary"
                fullWidth
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Button
                onClick={onSave}
                variant="contained"
                color="primary"
                fullWidth
              >
                SAVE
              </Button>
            </Grid>
            <Grid item xs={2} sm={3}></Grid>
          </>
        </Grid>
      </Grid>
    );
  }
);

export default CreditReportForm;