import { useEffect } from "react";
import { observer } from 'mobx-react';
import { NewBorrowerTrackRecord } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';
import { useBorrowerBaseballCardStore } from 'libs/feature-borrowers/src/borrowers/hooks/useBorrowerBaseballCardStore';

interface Props {
  borrowerId: number | string;
  loanId: number | undefined;
}
const BorrowerTrackRecord = observer(({ borrowerId, loanId }: Props) => {
  const { borrowerBaseballCardStore } = useBorrowerBaseballCardStore();
  const { userStore } = useUserStore();

  if (!borrowerId) {
    return null
  }

  borrowerBaseballCardStore.currentBorrower = borrowerId;
  borrowerBaseballCardStore.loanId = Number(loanId);


  return (
    <NewBorrowerTrackRecord store={borrowerBaseballCardStore} isInternal={userStore.isNportalUser} hideTitle={true} />
  );
})

export default BorrowerTrackRecord;
