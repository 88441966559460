import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper } from '@roc/ui';
import { observer } from 'mobx-react';

import { useRevenueRecognitionStore } from '../hooks/useRevenueRecognitionStore';
import { RevenueRecognitionGrid } from './revenueRecognitionGrid';
import { RevenueRecognitionToolbar } from './revenueRecognitionToolbar';
import { getRevenueRecognitionColumns } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    borderRadius: '0 !important',
    padding: theme.spacing(4),
    marginTop: '20px'
  },
}));



export const RevenueRecognitionDashboard = observer(() => {
  const classes = useStyles();
  const { revenueRecognitionStore } = useRevenueRecognitionStore();

  return (
    <Layout title="Revenue Recognition" containerClass={classes.container}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <RevenueRecognitionToolbar />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
