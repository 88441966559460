import { Grid } from '@material-ui/core';
import {
  createDateField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { UNDERWRITING } from '@roc/feature-utils';
import { FileUploadDownloadArea } from '../common/fileUploadDownloadArea';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { Document } from '@roc/feature-types';
import { useEffect } from 'react';

interface PostDisasterInspectionProps {
  loan: any;
  document: Document;
}

const PostDisasterInspection = observer((props: PostDisasterInspectionProps) => {
  const { loan, document } = props;
  const disableDownload = (!document?.dropboxPath || document?.dropboxPath.trim().length === 0);
  const { documentStore } = useDocumentStore();
  const { postDisasterInspectionFormStore } = documentStore.documentFormStore;

  useEffect(() => {
    const downloadExistingFile = !disableDownload ? (document) => documentStore.fetchDocumentFile(document) : null;
    postDisasterInspectionFormStore.getFemaPropertyInfo(loan, document, downloadExistingFile);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {createDateField({
          store: postDisasterInspectionFormStore,
          testId: 'femaIncidentEndDate',
          fieldName: 'femaIncidentEndDate',
          label: 'Incident End Date',
          disabled: true
        })}
      </Grid>
      <Grid item xs={12}>
        <FileUploadDownloadArea
          file={postDisasterInspectionFormStore.postDisasterInspectionFile}
          onChange={file => postDisasterInspectionFormStore.setPostDisasterInspectionFile(file)}
          onUpload={(file) => documentStore.uploadDocument(file, document, UNDERWRITING)}
          onDownload={() => documentStore.downloadDocument(document)}
          disableDownload={disableDownload}
          infoMessage={disableDownload ? `${document.taskName} has not been uploaded` : `${document.taskName} was last uploaded at ${document.lastUpdateDate}`}
        />
      </Grid>
    </Grid>
  );
});

export default PostDisasterInspection;