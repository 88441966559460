import { isNotBlank } from "@roc/feature-utils";
import { format, isValid } from "date-fns";
import { PreflightStep, QuoteStep, SquareFootageExpansion } from "libs/feature-one-tool/src/quote/utils/constants";

export const getQuoteFromLoanSubmitJson = (loanId, loanSubmitJson) => {
  const { loanSubtype, lenderOriginatorId, broker, borrowerEntity, borrowers, properties, brokerPointsIn } = loanSubmitJson;
  return {
    loanId,
    loanSubtype: loanSubtype,
    lenderOriginatorId: lenderOriginatorId,
    broker: getQuoteBroker(broker, brokerPointsIn),
    entity: getQuoteEntity(borrowerEntity),
    closingData: getQuoteClosingData(loanSubmitJson),
    borrowers: getQuoteBorrowers(borrowers),
    properties: getQuoteProperties(properties),
    uiState: {
      quoteStep: QuoteStep.BORROWER_INFORMATION,
      quoteNextStep: QuoteStep.PROPERTY_INFORMATION,
      preflightStep: PreflightStep.BROKER,
      preflightNextStep: PreflightStep.ENTITY,
      completedBorrowers: [],
      completedProperties: [],
    },
    quoteAccepted: false,
    loanEconomics: null,
    loanLenderDetails: null,
    loanComments: null,
    leverageExceptionComments: null,
    type: 'LOAN_REQUEST',
  };
}

export const getQuoteBroker = (broker, brokerPointsIn) => {
  const companyName = broker?.company?.name ?? broker?.entityName;
  const hasBroker = isNotBlank(companyName);
  const contact = broker?.company?.contacts[0] ?? null;
  return {
    brokerId: broker?.lenderId,
    brokerType: null,
    brokerPoints: brokerPointsIn,
    companyName: companyName,
    companyEin: broker?.company?.einNumber ?? broker?.entityTaxNumber,
    contactFirstName: contact?.firstName ?? broker?.firstName,
    contactLastName: contact?.lastName ?? broker?.lastName,
    contactEmail: contact?.emailAddress ?? broker?.emailAddress,
    contactPhoneNumber: contact?.workPhone ?? contact?.cellPhone ?? broker?.cellPhone,
    hasBroker: hasBroker,
  }
}

export const getQuoteEntity = (entity) => {
  const hasEntity = isNotBlank(entity?.name);
  return {
    entityId: entity?.borrowerEntityId,
    entityName: entity?.name,
    entityEin: entity?.ein,
    entityType: entity?.type,
    dateOfIncorporation: entity?.operatingAgreementDate ? entity?.operatingAgreementDate : null,
    address: {
      address: entity?.address,
      streetNumber: entity?.streetNumber,
      streetName: entity?.streetName,
      county: null,
      aptNumber: null,
      city: entity?.city,
      state: entity?.state,
      zip: entity?.zipcode,
    },
    hasEntity: hasEntity,
  };
}

export const getQuoteClosingData = (loanSubmitJson) => {
  const { attorneyEmail, preferredInsurance, requestedClosingDate, preferredTitle, loanInsuranceData, titleCompany } = loanSubmitJson
  return {
    fastTrack: false,
    fastTrackBorrowerPaymentLink: false,
    preferredClosingAttorney: attorneyEmail,
    preferredInsurance: preferredInsurance,
    requestedClosingDate: isValid(requestedClosingDate) ? format(requestedClosingDate, 'MM/dd/yyyy') : null,
    preferredTitle: preferredTitle,
    otherInsuranceInfo: getQuoteOtherInsuranceInfo(loanInsuranceData),
    otherTitleInfo: getQuoteOtherTitleInfo(titleCompany),
    lenderParticipation: 'Broker Loan',
    origLenderSubordination: 0,
  };
}

export const getQuoteOtherInsuranceInfo = (loanInsuranceData) => {
  return {
    name: loanInsuranceData?.name,
    contactName: loanInsuranceData?.contactName,
    cellPhone: loanInsuranceData?.phone,
    emailAddress: loanInsuranceData?.email,
    address: {
      address: loanInsuranceData?.address,
      streetNumber: loanInsuranceData?.streetNumber,
      streetName: loanInsuranceData?.streetName,
      county: null,
      aptNumber: null,
      city: loanInsuranceData?.city,
      state: loanInsuranceData?.state,
      zip: loanInsuranceData?.zipCode,
    },
  };
}

export const getQuoteOtherTitleInfo = (titleCompany) => {
  return {
    name: titleCompany?.name,
    contactName: titleCompany?.contactName,
    cellPhone: titleCompany?.phone,
    emailAddress: titleCompany?.email,
    address: {
      address: titleCompany?.address,
      streetNumber: titleCompany?.streetNumber,
      streetName: titleCompany?.streetName,
      county: null,
      aptNumber: null,
      city: titleCompany?.city,
      state: titleCompany?.state,
      zip: titleCompany?.zipCode,
    },
  };
}

export const getQuoteBorrowers = (borrowers) => {
  return borrowers.map(borrower => ({
    borrowerId: borrower?.borrowerId,
    firstName: borrower?.firstName,
    lastName: borrower?.lastName,
    emailAddress: borrower?.emailAddress,
    isRealEstateBrokerAgent: borrower?.broker,
    isGeneralContractor: borrower?.generalContractor,
    ficoScore: borrower?.ficoProvidedAtOrigination,
    numberOfVerifiedInvestmentProperties: borrower?.experienceProvidedAtOrigination ?? borrower?.experience,
    assets: borrower?.assets,
    liabilities: borrower?.liabilities,
    personalGuarantor: borrower?.personalGuarantor,
    creditBackgroundStatus: null,
    hasIdentification: null,
    creditPull: null,
    phoneNumber: borrower?.cellPhone,
    dateOfBirth: isValid(borrower?.dob) ? format(borrower?.dob, 'MM/dd/yyyy') : null,
    ssn: null,
    citizenshipStatus: borrower?.citizenshipStatus,
    address: {
      address: borrower?.fullAddress,
      streetNumber: borrower?.streetNumber,
      streetName: borrower?.streetName,
      county: null,
      aptNumber: borrower?.aptNumber,
      city: borrower?.city,
      state: borrower?.state,
      zip: borrower?.zipCode,
    },
    percentageOfOwnership: borrower?.pctOwnership,
  }));
}
const mapOutstandingLoanOnTheProperty = (outstandingLoanOnTheProperty) => {
  if (typeof outstandingLoanOnTheProperty === 'string') {
    return outstandingLoanOnTheProperty.toLocaleLowerCase() === 'y';
  } else {
    return outstandingLoanOnTheProperty;
  }
}
export const getQuoteProperties = (properties) => {
  return properties.map(property => ({
    propertyId: property?.propertyId,
    address: property?.address,
    streetNumber: property?.streetNumber,
    streetName: property?.streetName,
    aptNumber: property?.aptNumber,
    city: property?.city,
    state: property?.state,
    zipCode: property?.zipCode,
    latitude: property?.latitude,
    longitude: property?.longitude,
    propertyType: property?.useCode,
    loanPurpose: property?.propertyOwnership === 'Owned By Borrower' ? 'Refinance' : 'Purchase',
    purchaseDate: property?.purchaseDate ? property?.purchaseDate : property?.nsfrLoanPropertyFields?.acquisitionDate,
    propertySourcing: property?.propertySourcing,
    sellerCredit: property?.sellerCredit,
    sellerCreditAmount: property?.sellerCreditAmount,
    payoffAmount: property?.totalDebtPayoff || property?.refiAmount || property?.payoffAmount,
    subdividedIntoLots: property?.multipleLots,
    numberOfLots: property?.numberOfLots,
    permitsInPlace: property?.permitsInPlace,
    borrowerKnowOwnerOfRecord: !property?.armsLength,
    armsLengthComment: property?.armsLengthComment,
    outstandingLoanOnTheProperty: mapOutstandingLoanOnTheProperty(property?.outstandingLoanOnTheProperty || property?.anyDebt),
    loanEverBeenInDefault: false,
    ltcCatchupDraw: null,
    ltcCatchupDrawAmount: null,
    hasBeenRecentlyRenovated: null,
    soonToBeListedForSale: null,
    currentlyOccupied: property?.occupancy != "Vacant",
    annualTaxes: property?.nsfrLoanPropertyFields?.annualTaxes ?? property?.annualTaxesProvidedAtOrigination,
    monthlyMarketRent: property?.nsfrLoanPropertyFields?.monthlyMarketRent ?? property?.monthglyMarketRentProvidedAtOrigination,
    annualInsurance: property?.nsfrLoanPropertyFields?.annualInsurance ?? property?.annualInsuranceProvidedAtOrigination,
    holdingCosts: null,
    purchasePrice: property?.nsfrLoanPropertyFields?.acquisitionPrice ?? property?.purchasePrice,
    currentMarketValue: property?.nsfrLoanPropertyFields?.propertyValuation ?? property?.asIsValue,
    constructionRenoSpendToDate: property?.sunkCost,
    renovationBudget: property?.totalRenovationBudget,
    marketValueAfterCompletion: property?.afterRepairValue,
    wholesalerPropertyContractAmount: property?.wholesaleAmount,
    monthlyMarketRentProvidedAtOrigination: property?.monthlyMarketRentProvidedAtOrigination,
    annualHOAProvidedAtOrigination: property?.annualHOAProvidedAtOrigination,
    annualTaxesProvidedAtOrigination: property?.nsfrLoanPropertyFields?.annualTaxes ?? property?.annualTaxesProvidedAtOrigination,
    annualInsuranceProvidedAtOrigination: property?.nsfrLoanPropertyFields?.annualInsurance ?? property?.annualInsuranceProvidedAtOrigination,
    squareFootageAdded: property?.squareFootageAdded,
    squareFootageExpansion: property?.squareFootageAdded ? SquareFootageExpansion.VERTICAL_AND_HORIZONTAL : SquareFootageExpansion.NO,
    condoConversion: null,
    fireDamage: property?.fireDamage,
    hasNewAddress: null,
    exitStrategy: property?.exitStrategy,
    newAddress: null,
    isAppraisalAvailable: null,
    describeRenovation: property?.describeRenovation
  }));
}

export const getQuoteFromBorrowerLoanApplication = (loanId, loanSubtype, entity, borrowers, properties) => {
  return {
    loanId,
    loanSubtype: loanSubtype,
    originatorId: null,
    broker: null,
    entity: getQuoteEntity(entity),
    closingData: null,
    borrowers: getQuoteBorrowers(borrowers),
    properties: getQuoteProperties(properties),
    uiState: {
      quoteStep: QuoteStep.BORROWER_INFORMATION,
      quoteNextStep: QuoteStep.PROPERTY_INFORMATION,
      preflightStep: PreflightStep.BROKER,
      preflightNextStep: PreflightStep.ENTITY,
      completedBorrowers: [],
      completedProperties: [],
    },
    quoteAccepted: false,
    loanEconomics: null,
    loanLenderDetails: null,
    loanComments: null,
    leverageExceptionComments: null,
    type: 'LOAN_REQUEST',
  };
}