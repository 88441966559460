import { useState } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from '../common/tabPanel';

const LoanSummaryTabs = ({ tabs, tabsContent, classes }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<>
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="Loan Summary Tabs"
      className={classes.tabs}
      classes={{
        indicator: classes.indicator,
        scroller: classes.scroller,
      }}
    >
      {tabs.map((tab, i) => <Tab
        label={tab}
        className={`${classes.tab} ${classes.loanSummaryTabsOverride} ${value === i ? classes.activeTab : ""}`}
      />)}
    </Tabs>
    {tabsContent.map((tabContent, i) => <TabPanel value={value} index={i}>
      {tabContent}
    </TabPanel>)}
  </>)
}
export default LoanSummaryTabs;