import { Service } from '@roc/feature-app-core';
import { addQueryParam } from '@roc/feature-utils';

const baseUrl = '/api/v1/loan/scopeOfWork';
const sowBaseUrl = '/api/v1/draws/sow';


const url = {
  GET_SCOPE_OF_WORK_SHARE_URL: `${baseUrl}/getScopeOfWorkShareUrlV2`,
  GET_SCOPE_OF_WORK_SHARE_PUBLIC_URL: `${baseUrl}/getScopeOfWorkSharePublicUrl`,
  GET_NEW_SCOPE_OF_WORK: `${baseUrl}/getNewScopeOfWorkV2`,
  GET_SCOPE_OF_WORK: `${baseUrl}/getScopeOfWorkByIdV2`,
  SAVE_SCOPE_OF_WORK: `${baseUrl}/saveScopeOfWorkV2`,
  SUBMIT_SCOPE_OF_WORK: `${baseUrl}/submitScopeOfWorkV2`,
  GET_SCOPE_OF_WORK_CATEGORIES: `${baseUrl}/getScopeOfWorkCategories`,
  EXPORT_SCOPE_OF_WORK: `${baseUrl}/exportScopeOfWorkV2`,
  REMOVE_SCOPE_OF_WORK: `${baseUrl}/removeScopeOfWorkV2`,
  GET_SPLITTED_CATEGORIES: `${sowBaseUrl}/getSplitedCategoriesExternal`,
  SAVE_PDF: `${sowBaseUrl}/savePdf`,
  SAVE_SOW_AUDIT_LOGS: `${baseUrl}/saveScopeOfWorkAuditLogs`,
  GET_SOW_AUDIT_LOG_MESSAGES: `${baseUrl}/getScopeOfWorkAuditLogsMessages`,
  GET_SOW_ERROR_MESSAGES: `${baseUrl}/getSowSubmissionErrors`

};

export class ScopeOfWorkV2FormService extends Service {
  protected url: any;

  constructor() {
    super();
    this.url = url;
  }

  getSowSubmissionErrors(loanId: string, propertyId: any, data: any) {
    if (loanId && propertyId) {
      return this.post(`${url.GET_SOW_ERROR_MESSAGES}?loanId=${loanId}&propertyId=${propertyId}`, data)
    } else {
      return this.post(`${url.GET_SOW_ERROR_MESSAGES}`, data);
    }
  }

  saveSowAuditLogs(scopeOfWorkId: string, lenderId, data: any, isSubmit: boolean, userName: string) {
    const userNameParam = userName ? `&userName=${userName}` : '';
    return this.post(`${url.SAVE_SOW_AUDIT_LOGS}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}&isSubmit=${isSubmit}${userNameParam}`, data)
  }

  getSowAuditLogs(scopeOfWorkId: string, lenderId: any) {
    return this.get(`${url.GET_SOW_AUDIT_LOG_MESSAGES}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`)
  }

  savePdf(formData: FormData, scopeOfWorkId: string, lenderId: string) {
    const options = { disableGlobalLoading: true };
    return this.post(`${url.SAVE_PDF}?scopeOfWorkId=${scopeOfWorkId}&lenderId=${lenderId}`, formData, {}, options);
  }

  getSplitedCategories(scopeOfWorkId: string) {
    const options = { disableGlobalLoading: true };
    return this.get(`${url.GET_SPLITTED_CATEGORIES}?scopeOfWorkId=${scopeOfWorkId}`, {}, options);
  }

  getScopeOfWorkShareUrl(scopeOfWorkId: string) {
    const params = addQueryParam('', 'scopeOfWorkId', scopeOfWorkId);
    return this.get(
      `${this.url.GET_SCOPE_OF_WORK_SHARE_URL}${params ? `?${params}` : ''}`
    );
  }

  getScopeOfWorkSharePublicUrl() {
    return this.get(url.GET_SCOPE_OF_WORK_SHARE_PUBLIC_URL);
  }

  getNewScopeOfWork() {
    return this.get(this.url.GET_NEW_SCOPE_OF_WORK);
  }

  getScopeOfWorkById(scopeOfWorkId: string) {
    const params = addQueryParam('', 'scopeOfWorkId', scopeOfWorkId);
    return this.get(
      `${this.url.GET_SCOPE_OF_WORK}${params ? `?${params}` : ''}`
    );
  }

  saveScopeOfWork(scopeOfWorkId: string, data: any, silent = false) {
    const params = addQueryParam('', 'scopeOfWorkId', scopeOfWorkId);
    const options = { disableGlobalLoading: silent };
    return this.post(`${url.SAVE_SCOPE_OF_WORK}?${params}`, data, {}, options);
  }

  exportScopeOfWork(data: any) {
    return this.post(
      `${url.EXPORT_SCOPE_OF_WORK}`,
      data,
      null,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  removeScopeOfWork(scopeOfWorkId: any) {
    const params = addQueryParam('', 'scopeOfWorkId', scopeOfWorkId);
    return this.post(
      `${url.REMOVE_SCOPE_OF_WORK}${params ? `?${params}` : ''}`, {
      scopeOfWorkId
    });
  }

  submitScopeOfWork(scopeOfWorkId: string, data: any, silent = false) {
    const params = addQueryParam('', 'scopeOfWorkId', scopeOfWorkId);
    const options = { disableGlobalLoading: silent };
    return this.post(
      `${this.url.SUBMIT_SCOPE_OF_WORK}?${params}`,
      data,
      {},
      options
    );
  }

  getCategories() {
    return this.get(this.url.GET_SCOPE_OF_WORK_CATEGORIES);
  }
}
