import { FormStore, GlobalStore, GridStore } from "@roc/feature-app-core";
import { LoanValuationService } from "../services/loanValuationService";
import { ApiResponse } from "@roc/feature-app-core";
import { GENERIC_ERROR_MESSAGE } from "@roc/feature-utils";
import { makeObservable, flow, computed, observable } from "mobx";

const form = {
  fields: {
    propertyValuationId: {
      value: '',
      error: null,
      rule: '',
    },
    propertyId: {
      value: '',
      error: null,
      rule: 'required',
    },
    type: {
      value: 'INTERNAL',
      error: null,
      rule: 'required',
    },
    asIsValue: {
      value: '',
      error: null,
      rule: ['required_without:afterRepairValue'],
    },
    afterRepairValue: {
      value: '',
      error: null,
      rule: ['required_without:asIsValue'],
    },
    loanDqStatus: {
      value: '',
      error: null,
      rule: 'required',
    },
    date: {
      value: new Date(),
      error: null,
      rule: 'required',
    },
    comments: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};


export class LoanValuationFormStore extends FormStore {
  loanValuationService: LoanValuationService;
  valuationGridStore: GridStore;
  globalStore: GlobalStore;
  loanDetails: any;
  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.loanValuationService = new LoanValuationService();
    this.valuationGridStore = new GridStore(() => this.fetchValuationGrid(), null, 50);
    this.globalStore = globalStore;
    makeObservable(this, {
      loanDetails: observable,
      getPropertyAddressOptions: computed,
      getLoanDetails: flow,
      saveValuation: flow,
      saveValuationForm: flow
    });
  }


  async fetchValuationGrid() {
    try {
      if (this.loanDetails?.loanId) {
        const response: ApiResponse = await this.loanValuationService.getValuationGrid(
          this.loanDetails.loanId,
          this.valuationGridStore.gridData.meta.pageNumber,
          this.valuationGridStore.gridData.meta.pageSize,
          this.valuationGridStore.gridData.meta.sortDir,
          this.valuationGridStore.gridData.meta.sortBy,
          this.valuationGridStore.gridData.meta.filters
        );
        return response;
      }
      return null;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *getLoanDetails(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanValuationService.getLoanDetails(loanId);
      this.loanDetails = response.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  get getPropertyAddressOptions() {
    return this.loanDetails?.propertiesMap.rows.map(property => {
      return {
        label: property.address,
        value: property.propertyId
      }
    });
  }

  *saveValuationForm(callback: any) {
    try {
      if (this.runFormValidationWithMessage()) {
        const valuation = this.getFormValues();
        this.saveValuation(valuation, callback);
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Valuation saved'
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }

  *saveValuation(valuation: any, callback: any) {
    try {
      yield this.loanValuationService.saveValuation(valuation);
      if (callback) callback();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  }
}