import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import { Edit, Delete, Add, CloudDownload } from '@material-ui/icons';
import {
  SelectField,
  CurrencyField,
  AgGridReact,
  AgGridColumnProps,
  TextField,
  MultiSelectField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { RentalPortfolioStore } from '../../stores';
import { DUPLEX, QUADRUPLEX, SINGLE_FAMILY, TRIPLEX, isNotBlank } from '@roc/feature-utils';
import {
  REFINANCE,
  propertyStates,
  sfrPortfolioPropertyManagement,
  MULTIFAMILY_5_PLUS,
  notLicensedSfr,
} from '@roc/feature-utils';
import PropertyTypesModal, {
  PropertyTypesModalState,
} from './propertyTypesModal';
import {
  CapitalImprovements,
  CashOut,
  CurrentDebtAmount,
  CurrentDebtOnProperty,
  GridContainer,
  LoanPurpose,
  LoanRecourse,
  PurchaseDate,
  PurchasePrice,
  Rural,
  TargetLtv,
} from '../../components/formComponents';
import {
  Bankruptcy,
  ExperienceLevel,
  Foreclosure,
  QualifyingFicoScore,
  BankruptcyDate,
  ForeclosureDate,
} from '@roc/ui/formComponents';
import { formatCurrency } from '@roc/ui/utils';
import { CitizenshipStatus } from '../../../rentalPricer/components/rentalPricerFields';
import { useRentalPortfolioStore } from '../../hooks/useRentalPortfolioStore';
import { Alert } from '@material-ui/lab';
import { useUserStore } from '@roc/feature-app-core';
import PauseMultifamilySubmissions from '../../components/pausedMultifamilySubmissions';
import { Leases } from '../../../rentalPricer/components/rentalPricerFields';

const noOpComparator = () => 0;

const usdAmount: any = params => {
  return formatCurrency(Number(params.value ?? 0));
};

const propertyTypesColumns: AgGridColumnProps[] = [
  {
    field: 'propertyType',
    headerName: 'Property Type',
    maxWidth: 250,
    sortable: true,
    sort: 'desc',
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      if (valueA == valueB) return 0;
      return (valueA > valueB) ? 1 : -1;
    },
  },
  {
    field: 'numberOfProperties',
    headerName: 'Number Of Properties',
    minWidth: 200,
    comparator: noOpComparator,
  },
  {
    field: 'estimatedAsIsValue',
    headerName: 'Estimated As Is Value',
    minWidth: 200,
    valueFormatter: usdAmount,
    comparator: noOpComparator,
  },
  {
    field: 'propertyId',
    headerName: 'Actions',
    minWidth: 100,
    cellRendererSelector: function (params) {
      if (!params.node.rowPinned) {
        return {
          component: 'actionsCellRenderer'
        }
      }
    },
    comparator: noOpComparator,
  },
];

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    height: '100%',
    '& .ag-root-wrapper': {
      borderColor: '#ddd',
    },
  },
  alert: {
    backgroundColor: "#FDEFBF",
    color: theme.palette.text.primary
  }
}));

function LoanPricerRentalPortfolio() {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore, pricerSummaryStore } = rentalPortfolioStore;
  const { form } = pricerStore;

  return (
    <>
      <PauseMultifamilySubmissions open={pricerStore.isPauseMultifamilySubmissionsOpen} handleClose={() => pricerStore.setIsPauseMultifamilySubmissionsOpen(false)} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Loan Pricer</Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Portfolio's Properties</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid item container spacing={4} xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={6}>
          <PropertyStates />
        </Grid>
        {!!form.fields.propertyStates?.value?.length && (
          <Grid item xs={12} md={12} lg={6}>
            <County />
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={4} xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <PropertyTypes />
        </Grid>
      </Grid>
      <Grid item container spacing={4} xs={12} md={12} lg={12}>
        <Grid item xs={12} md={6} lg={6}>
          <LoanPurpose store={pricerStore} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <PurchasePrice store={pricerStore} label={'Total Purchase Price'} />
        </Grid>
        {form.fields.loanPurpose.value &&
          form.fields.loanPurpose.value === REFINANCE && (
            <Grid item xs={12} md={6} lg={6}>
              <PurchaseDate store={pricerStore} label={'Last Purchase Date'} />
            </Grid>
          )}
        {form.fields.loanPurpose.value &&
          form.fields.loanPurpose.value === REFINANCE && (
            <Grid item xs={12} md={6} lg={6}>
              <CapitalImprovements store={pricerStore} />
            </Grid>
          )}
        {form.fields.capitalImprovements.value !== '' &&
          form.fields.loanPurpose.value === REFINANCE && (
            <Grid item xs={12}>
              <CurrentDebtOnProperty
                store={pricerStore}
                label={'Any Debt on the portfolio?'}
              />
            </Grid>
          )}
        {form.fields.anyDebt.value === 'Y' &&
          form.fields.loanPurpose.value === REFINANCE && (
            <Grid item xs={12} md={6} lg={6}>
              <CurrentDebtAmount
                store={pricerStore}
                label={'Current Debt on the portfolio'}
              />
            </Grid>
          )}
        {form.fields.anyDebt.value === 'Y' &&
          form.fields.totalDebtPayoff.value >= 0 && (
            <Grid item xs={12}>
              <CashOut
                store={pricerSummaryStore}
              />
            </Grid>
          )}
        <Grid item xs={12} md={6} lg={6}>
          <LoanRecourse store={pricerStore} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Leases store={pricerStore} />
        </Grid>
      </Grid>
      <br />
      <PortfolioInformation />
      <br />
      <CashFlowInformation />
      <br />
      <TotalInformation />
      <br />
      <BorrowerInformation store={rentalPortfolioStore} />
      <br />
    </>
  );
}

const DownloadTape = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => rentalPortfolioStore.downloadTapeTemplate()}
      startIcon={<CloudDownload />}
    >
      DOWNLOAD TAPE TEMPLATE
    </Button>
  );
});

const getRowStyle = params => {
  if (params.node.rowPinned) {
    return { 'font-weight': 'bold' }
  }
}

const PropertyTypesActions = ({ onEdit, onDelete, ...rest }) => {
  return (
    <Grid>
      <IconButton onClick={() => onEdit(rest)}>
        <Edit fontSize="small" />
      </IconButton>
      <IconButton onClick={() => onDelete(rest)}>
        <Delete fontSize="small" />
      </IconButton>
    </Grid>
  );
};

const PropertyTypes = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const classes = useStyles();
  const [modalState, setModalState] = useState<PropertyTypesModalState>(null);
  const {
    propertyTypesStore,
    deleteProperty,
    properties,
    isSmallPortfolio,
    propertySummary
  } = rentalPortfolioStore;

  const closeModal = () => {
    setModalState(null);
    propertyTypesStore.reset();
  };

  const onEdit = property => {
    setModalState(PropertyTypesModalState.EDIT);
    propertyTypesStore.setPropertyId(property.propertyId);
    propertyTypesStore.loadProperty(property);
  };

  const onDelete = ({ propertyId }) => {
    deleteProperty(propertyId);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => setModalState(PropertyTypesModalState.ADD)}
          startIcon={<Add />}
        >
          Add Property Type
        </Button>
      </Grid>

      <Grid item xs={12} className={`ag-theme-alpine ${classes.grid}`}>
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            sortable: false,
            resizable: false,
            suppressMenu: true,
          }}
          columnDefs={propertyTypesColumns}
          rowData={[...properties]}
          domLayout="autoHeight"
          suppressCellSelection={true}
          frameworkComponents={{
            actionsCellRenderer: ({ data }) => (
              <PropertyTypesActions
                onEdit={onEdit}
                onDelete={onDelete}
                {...data}
              />
            ),
          }}
          getRowStyle={getRowStyle}
          getRowHeight={() => {
            return 48;
          }}
          pinnedBottomRowData={propertySummary}
        />
      </Grid>

      <PropertyTypesModal
        open={modalState != null}
        modalState={modalState}
        handleClose={closeModal}
        store={rentalPortfolioStore}
        handleAdd={() => {
          propertyTypesStore.setPropertyId(Date.now());
          if (propertyTypesStore.areValidPropertyDetails()) {
            rentalPortfolioStore.addProperty(propertyTypesStore.getProperty());
            closeModal();
          }
        }}
        handleEdit={() => {
          if (propertyTypesStore.areValidPropertyDetails()) {
            rentalPortfolioStore.updateProperty(
              propertyTypesStore.getProperty()
            );
            rentalPortfolioStore.checkSmallPortfolio();
            rentalPortfolioStore.updatePropertySummary();
            closeModal();
          }
        }}
      />
      {isSmallPortfolio ?
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Alert severity='warning' className={classes.alert}>
            Portfolios with 20 or fewer properties do not require a tape upload, so there will be a few extra questions in the Property Information step.
          </Alert>
        </Grid>
        : <></>
      }

    </Grid>
  );
});

const PropertyStates = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;

  const onPropertyStateChanged = value => {
    pricerStore.onPropertyStateChanged(value);
  };

  return (
    <>
      <SelectField
        standaloneLabel
        label={'Add Property States'}
        name={'propertyStates'}
        value={form.fields.propertyStates.value}
        error={isNotBlank(form.fields.propertyStates.error)}
        errorText={form.fields.propertyStates.error}
        options={propertyStates}
        onChange={value => onPropertyStateChanged(value)}
        variant="outlined"
        fullWidth
        shrink
        required
        testId="propertyStates"
      />
      <FormHelperText>{notLicensedSfr.message}</FormHelperText>
    </>
  );
});

const County = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form, counties } = pricerStore;

  return (
    <MultiSelectField
      standaloneLabel
      label={'Property Counties (Select All that apply)'}
      name={'propertyCounties'}
      options={counties}
      getOptionSelected={(option, selectedValue) => {
        return option.value == selectedValue.value;
      }}
      value={
        form.fields.propertyCounties.value
          ? form.fields.propertyCounties.value
          : []
      }
      onChange={(e, value) => {
        pricerStore.onFieldChange('propertyCounties', value);
      }}
      renderInput={params => (
        <TextField
          helperText={form.fields.propertyCounties.error}
          error={isNotBlank(form.fields.propertyCounties.error)}
          testId="propertyCounties"
          {...params}
          variant="outlined"
        />
      )}
      fullWidth
      required
      testId="propertyCounties"
    />
  );
});

const PortfolioInformation = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;

  const hasMixedUseProperties = false;

  return (
    <Grid container direction="row">
      <Grid container item xs={12} md={12}>
        <Typography variant="h5">{'Portfolio Information'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <TargetLtv store={pricerStore} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Lowest Property As Is Value in the Portfolio"
              value={form.fields.minimumAsIsValue.value}
              onChange={(name, value) =>
                pricerStore.onFieldChange('minimumAsIsValue', Number(value))
              }
              fullWidth
              testId="minimumAsIsValue"
              required
              error={isNotBlank(form.fields.minimumAsIsValue.error)}
              errorText={form.fields.minimumAsIsValue.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Highest Property As Is Value in the Portfolio"
              value={form.fields.maximumAsIsValue.value}
              onChange={(name, value) =>
                pricerStore.onFieldChange('maximumAsIsValue', Number(value))
              }
              fullWidth
              testId="maximumAsIsValue"
              required
              error={isNotBlank(form.fields.maximumAsIsValue.error)}
              errorText={form.fields.maximumAsIsValue.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              disabled={true}
              label="Total Estimated As Is Value"
              value={rentalPortfolioStore.totalEstimatedAsIsValue}
              onChange={(name, value) =>
                pricerStore.onFieldChange(
                  'totalEstimatedAsIsValue',
                  Number(value)
                )
              }
              fullWidth
              testId="totalEstimatedAsIsValue"
              required
              error={isNotBlank(form.fields.totalEstimatedAsIsValue.error)}
              errorText={form.fields.totalEstimatedAsIsValue.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <SelectField
              standaloneLabel
              label={'Property Management'}
              name={'propertyManagement'}
              value={form.fields.propertyManagement.value}
              errorText={form.fields.propertyManagement.error}
              error={isNotBlank(form.fields.propertyManagement.error)}
              options={sfrPortfolioPropertyManagement}
              onChange={value =>
                pricerStore.onFieldChange('propertyManagement', value)
              }
              variant="outlined"
              fullWidth
              shrink
              required
              testId="propertyManagement"
            />
          </Grid>
          {hasMixedUseProperties && (
            <Grid item xs={12} md={6} lg={6}>
              <CurrencyField
                variant="outlined"
                standaloneLabel
                label="Total Value of Mixed Use Properties in Portfolio"
                value={form.fields.totalMixUseEstimatedAsIsValue.value}
                onChange={(name, value) =>
                  pricerStore.onFieldChange(
                    'totalMixUseEstimatedAsIsValue',
                    Number(value)
                  )
                }
                fullWidth
                testId="totalMixUseEstimatedAsIsValue"
                required
                error={isNotBlank(
                  form.fields.totalMixUseEstimatedAsIsValue.error
                )}
                errorText={form.fields.totalMixUseEstimatedAsIsValue.error}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

const CashFlowInformation = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore, getPropertyTypes } = rentalPortfolioStore;
  const { form } = pricerStore;

  const isOneToFourProperty = getPropertyTypes().every(propertyType => {
    return (
      propertyType === SINGLE_FAMILY ||
      propertyType === DUPLEX ||
      propertyType === TRIPLEX ||
      propertyType === QUADRUPLEX
    );
  });


  return (
    <Grid container direction="row">
      <Grid container item xs={12} md={12}>
        <Typography variant="h5">{'Cash Flow Information'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Gross Monthly Rent"
              value={form.fields.totalGrossMonthlyRent.value}
              onChange={(name, value) => {
                pricerStore.handleIncomeChange(
                  'totalGrossMonthlyRent',
                  Number(value)
                );
              }}
              fullWidth
              testId="totalGrossMonthlyRent"
              required
              error={isNotBlank(form.fields.totalGrossMonthlyRent.error)}
              errorText={form.fields.totalGrossMonthlyRent.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Total Gross Annual Taxes"
              value={form.fields.totalGrossAnnualTaxes.value}
              onChange={(name, value) =>
                pricerStore.handleExpenseChange(
                  'totalGrossAnnualTaxes',
                  Number(value)
                )
              }
              fullWidth
              testId="totalGrossAnnualTaxes"
              required
              error={isNotBlank(form.fields.totalGrossAnnualTaxes.error)}
              errorText={form.fields.totalGrossAnnualTaxes.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Total Gross Annual Insurance"
              value={form.fields.totalGrossAnnualInsurance.value}
              onChange={(name, value) =>
                pricerStore.handleExpenseChange(
                  'totalGrossAnnualInsurance',
                  Number(value)
                )
              }
              fullWidth
              testId="totalGrossAnnualInsurance"
              required
              error={isNotBlank(form.fields.totalGrossAnnualInsurance.error)}
              errorText={form.fields.totalGrossAnnualInsurance.error}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyField
              variant="outlined"
              standaloneLabel
              label="Total Annual HOA Dues"
              value={form.fields.totalAnnualHOADues.value}
              onChange={(name, value) =>
                pricerStore.handleExpenseChange(
                  'totalAnnualHOADues',
                  Number(value)
                )
              }
              fullWidth
              testId="totalAnnualHOADues"
              required
              error={isNotBlank(form.fields.totalAnnualHOADues.error)}
              errorText={form.fields.totalAnnualHOADues.error}
            />
          </Grid>
          {!isOneToFourProperty &&
            <>
              <Grid item xs={12} md={6} lg={6}>
                <CurrencyField
                  variant="outlined"
                  standaloneLabel
                  label="Total Annual Utilities"
                  value={form.fields.totalAnnualUtilities.value}
                  onChange={(name, value) =>
                    pricerStore.handleExpenseChange(
                      'totalAnnualUtilities',
                      Number(value)
                    )
                  }
                  fullWidth
                  testId="totalAnnualUtilities"
                  required
                  error={isNotBlank(form.fields.totalAnnualUtilities.error)}
                  errorText={form.fields.totalAnnualUtilities.error}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CurrencyField
                  variant="outlined"
                  standaloneLabel
                  label="Total Annual Repairs &amp; Maintenance"
                  value={form.fields.totalAnnualRepairsMaintenance.value}
                  onChange={(name, value) =>
                    pricerStore.handleExpenseChange(
                      'totalAnnualRepairsMaintenance',
                      Number(value)
                    )
                  }
                  fullWidth
                  testId="totalAnnualRepairsMaintenance"
                  required
                  error={isNotBlank(
                    form.fields.totalAnnualRepairsMaintenance.error
                  )}
                  errorText={form.fields.totalAnnualRepairsMaintenance.error}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CurrencyField
                  variant="outlined"
                  standaloneLabel
                  label="Total Annual Property Management Fees"
                  value={form.fields.totalAnnualPropertyManagementFees.value}
                  onChange={(name, value) =>
                    pricerStore.handleExpenseChange(
                      'totalAnnualPropertyManagementFees',
                      Number(value)
                    )
                  }
                  fullWidth
                  testId="totalAnnualPropertyManagementFees"
                  required
                  error={isNotBlank(
                    form.fields.totalAnnualPropertyManagementFees.error
                  )}
                  errorText={form.fields.totalAnnualPropertyManagementFees.error}
                />
              </Grid>
            </>
          }
          {getPropertyTypes().includes(MULTIFAMILY_5_PLUS) && <MFExpenses />}
        </Grid>
      </Grid>
    </Grid>
  );
});

const MFExpenses = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { pricerStore } = rentalPortfolioStore;
  const { form } = pricerStore;

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Annual Administrative Expenses"
          value={form.fields.generalAdministrative.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange(
              'generalAdministrative',
              Number(value)
            )
          }
          fullWidth
          testId="generalAdministrative"
          required
          error={isNotBlank(form.fields.generalAdministrative.error)}
          errorText={form.fields.generalAdministrative.error}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Annual Payroll Expenses"
          value={form.fields.payroll.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange('payroll', Number(value))
          }
          fullWidth
          testId="payroll"
          required
          error={isNotBlank(form.fields.payroll.error)}
          errorText={form.fields.payroll.error}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Annual Marketing Expenses"
          value={form.fields.marketing.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange('marketing', Number(value))
          }
          fullWidth
          testId="marketing"
          required
          error={isNotBlank(form.fields.marketing.error)}
          errorText={form.fields.marketing.error}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          label="Annual Replacement Reserves"
          value={form.fields.replacementReserves.value}
          onChange={(name, value) =>
            pricerStore.handleExpenseChange(
              'replacementReserves',
              Number(value)
            )
          }
          fullWidth
          testId="replacementReserves"
          required
          error={isNotBlank(form.fields.replacementReserves.error)}
          errorText={form.fields.replacementReserves.error}
        />
      </Grid>
    </>
  );
});

const TotalInformation = observer(() => {
  const { rentalPortfolioStore } = useRentalPortfolioStore();
  const { form } = rentalPortfolioStore.pricerStore;

  function createData(
    name: string,
    calories: number,
    fat: number,
  ) {
    return { name, calories, fat };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0)
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={4}
      style={{
        width: '100%',
        border: 'solid 3px',
        margin: '32px 0px 32px 0px',
        borderColor: '#CECECE',
        borderRadius: '8px',
        padding: '3vw 5vw',
      }}
    >
      <GridContainer>
        <CurrencyField
          variant="outlined"
          disabled={true}
          standaloneLabel
          label="Total Annual Income"
          value={form.fields.totalAnnualIncome.value}
          fullWidth
          testId="totalAnnualIncome"
          required
        />
      </GridContainer>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          disabled={true}
          standaloneLabel
          label="Total Annual Expenses"
          value={form.fields.totalAnnualExpenses.value}
          fullWidth
          testId="totalAnnualExpenses"
          required
        />
      </GridContainer>
      <GridContainer>
        <CurrencyField
          variant="outlined"
          standaloneLabel
          disabled={true}
          label="Annual NOI"
          value={form.fields.totalAnnualNOI.value}
          fullWidth
          testId="totalAnnualNOI"
          required
          error={isNotBlank(form.fields.totalAnnualNOI.error)}
          errorText={form.fields.totalAnnualNOI.error}
        />
      </GridContainer>
    </Grid>
  );
});

const BorrowerInformation = observer(
  ({ store }: { store: RentalPortfolioStore }) => {
    const { pricerStore } = store;
    const { form } = pricerStore;

    return (
      <Grid container direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Borrower Information</Typography>
            <br />
          </Grid>
        </Grid>
        <Grid item container spacing={4} xs={12} md={12} lg={12}>
          <Grid item xs={12} md={6} lg={6}>
            <ExperienceLevel
              store={pricerStore}
              tooltip={
                'Number of Rental Properties Owned in the Last 24 Months'
              }
              onChange={() => store.handleBorrowerInformationChange()}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CitizenshipStatus store={store} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Bankruptcy
              store={pricerStore}
              onChange={() => store.handleBorrowerInformationChange()}
            />
          </Grid>
          {form.fields.bankruptcy.value === 'Y' && (
            <Grid item xs={6}>
              <BankruptcyDate
                store={pricerStore}
                onChange={() => store.handleBorrowerInformationChange()}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={6}>
            <Foreclosure store={pricerStore} />
          </Grid>
          {form.fields.foreclosure.value === 'Y' && (
            <Grid item xs={6}>
              <ForeclosureDate
                store={pricerStore}
                onChange={() => store.handleBorrowerInformationChange()}
              />
            </Grid>
          )}
          <Grid xs={12} md={12} lg={12}>
            <Grid item xs={12} md={6} lg={6}>
              <QualifyingFicoScore
                store={pricerStore}
                tooltip={
                  'If there are multiple guarantors, please select the lowest mid-score'
                }
                onChange={() => store.handleBorrowerInformationChange()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default observer(LoanPricerRentalPortfolio);
