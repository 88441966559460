import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  createStyles,
  Typography,
  makeStyles
} from "@material-ui/core";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { formatCurrencyField, formatDateField, formatNumberField, getStringOrDefault } from "../../utils/utils";
import { observer } from "mobx-react";


const useStyles = makeStyles(() => ({
  fontSize: {
    fontSize: "10px"
  },
  radioContainer: {
    "& .MuiFormControl-fullWidth": {
      width: "auto !important"
    }
  },
  fieldContainer: {
    "& input": {
      backgroundColor: "#FFFFFF !important",
      borderRadius: "12px"
    },
    "& .MuiOutlinedInput-multiline": {//for textarea
      backgroundColor: "#FFFFFF !important",
    }
  },
  subjectPropertyCell: {
    fontSize: "10px",
    backgroundColor: "#B8F7FA"
  }
}));

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      padding: 10,
      border: "1px solid black",
      textAlign: "center",
    },
    head: {
      fontWeight: "bold",
      border: "1px solid black",
      textAlign: "center",
      padding: 10,
      letterSpacing: "1px"
    },
  })
)(TableCell);

interface ComparablesTableProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ComparablesTable = observer(({ internalValuationReviewStore }: ComparablesTableProps) => {
  const classes = useStyles();

  const compForms = internalValuationReviewStore.propertyCompFormStores.map(store => store.getFlattenedValues());

  if (compForms.length === 0) {
    return (<Typography variant="h6">
      Comparable Properties Not Available
    </Typography>)
  }

  const compsColumnWidth = (100 - 10) / compForms.length;

  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow key="header-row">
            <StyledTableCell width="10%" className={classes.fontSize}></StyledTableCell>
            {compForms.map((_, i) => (<StyledTableCell width={`${compsColumnWidth}%`} key={`header-cell-${i}`} className={i === 0 ? classes.subjectPropertyCell : classes.fontSize} >{i === 0 ? "SUBJECT PROPERTY" : `COMP #${i}`}</StyledTableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((header, index) => (
            <TableRow key={`body-row-${header}`}>
              <StyledTableCell key={`body-row-cell-${header}`} className={classes.fontSize}>{header}</StyledTableCell>
              {compForms.map((row, i) => (
                <StyledTableCell key={`body-row-cell-${header}-${i}`} className={i === 0 ? classes.subjectPropertyCell : classes.fontSize}>{getCellValue(header, row)}</StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

const ADDRESS = "ADDRESS";
const CITY_STATE_ZIP = "CITY, STATE ZIP";
const MLS_NUMBER = "MLS #";
const PROXIMITY = "PROXIMITY";
const ORIGINAL_LIST_DATE = "ORIGINAL LIST DATE";
const ORIGINAL_LIST_PRICE = "ORIGINAL LIST PRICE";
const SALE_DATE = "SALE DATE";
const SALE_PRICE = "SALE PRICE";
const DOM = "DOM";
const PROPERTY_TYPE = "PROPERTY TYPE";
const SALE_TYPE = "SALE TYPE";
const LOT_SIZE = "LOT SIZE";
const YEAR_BUILT = "YEAR BUILT";
const BEDROOMS = "BEDROOMS";
const BATHROOMS = "BATHROOMS";
const PRICE_PER_SQ = "PRICE PER SQ";
const PARKING_TYPE = "PARKING TYPE";
const NO_OF_UNITS = "NO. OF UNITS";
const VIEW = "VIEW";
const COMPARABILITY = "COMPARABILITY";
const AMENITIES = "AMENITIES";
const POOL = "POOL";
const MLS_COMMENTS = "MLS COMMENTS";
const ANALYST_COMMENTS = "ANALYST COMMENTS";

const rows = [
  ADDRESS,
  CITY_STATE_ZIP,
  MLS_NUMBER,
  PROXIMITY,
  ORIGINAL_LIST_DATE,
  ORIGINAL_LIST_PRICE,
  SALE_DATE,
  SALE_PRICE,
  DOM,
  PROPERTY_TYPE,
  SALE_TYPE,
  LOT_SIZE,
  YEAR_BUILT,
  BEDROOMS,
  BATHROOMS,
  PRICE_PER_SQ,
  PARKING_TYPE,
  NO_OF_UNITS,
  VIEW,
  COMPARABILITY,
  AMENITIES,
  POOL,
  MLS_COMMENTS,
  ANALYST_COMMENTS
];

const limit = 600;

function getCellValue(row, comparable) {
  switch (row) {
    case ADDRESS:
      return getStringOrDefault(comparable.address);
    case CITY_STATE_ZIP:
      return `${comparable.city}, ${comparable.state} ${comparable.zipCode}`;
    case MLS_NUMBER:
      return getStringOrDefault(comparable.mlsNumber);
    case PROXIMITY: {
      const proximity = formatNumberField(comparable.proximity, 2, 2);
      if (proximity === '-') return proximity;

      return proximity + ' Miles';
    }
    case ORIGINAL_LIST_DATE:
      return formatDateField(comparable.originalListDate);
    case ORIGINAL_LIST_PRICE:
      return formatCurrencyField(comparable.originalListPrice);
    case SALE_DATE:
      return formatDateField(comparable.saleDate);
    case SALE_PRICE:
      return formatCurrencyField(comparable.salePrice);
    case DOM:
      return formatNumberField(comparable.domTrend);
    case PROPERTY_TYPE:
      return getStringOrDefault(comparable.propertyType);
    case SALE_TYPE:
      return getStringOrDefault(comparable.saleType);
    case LOT_SIZE:
      return formatNumberField(comparable.lotSize);
    case YEAR_BUILT:
      return getStringOrDefault(comparable.yearBuilt);
    case BEDROOMS:
      return formatNumberField(comparable.beds, 0, 2);
    case BATHROOMS:
      return formatNumberField(comparable.baths, 0, 2);
    case PRICE_PER_SQ:
      return formatCurrencyField(comparable.pricePerSqFt);
    case PARKING_TYPE:
      return getStringOrDefault(comparable.parkingType);
    case AMENITIES:
      return getStringOrDefault(comparable.amenities);
    case POOL:
      return comparable.hasPool ? "Yes" : "No";
    case NO_OF_UNITS:
      return getStringOrDefault(comparable.noOfUnits);
    case VIEW:
      return getStringOrDefault(comparable.view);
    case COMPARABILITY:
      return getStringOrDefault(comparable.comparability);
    case ANALYST_COMMENTS: {
      const analystComments = getStringOrDefault(comparable.analystComments);
      if (analystComments.length > limit) {
        return analystComments.slice(0, limit) + "...";
      }
      return analystComments;
    }
    case MLS_COMMENTS: {
      const mlsComments = getStringOrDefault(comparable.mlsComments);
      if (mlsComments.length > limit) {
        return mlsComments.slice(0, limit) + "...";
      }
      return mlsComments;
    }
  }
}
