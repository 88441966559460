import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { colors } from '../utils/todoConstants'
import { formatPercentage } from '@roc/ui/utils';
import {
  getValueOrDefaultDash,
} from 'libs/feature-one-tool/src/quote/utils/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`entity-tabpanel-${index}`}
      style={{ backgroundColor: "white" }}
      aria-labelledby={`entity-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "-20px",
    marginLeft: '- 8px',
  },
  tab: {
    color: colors.raisinBlack,
    fontWeight: "bold",
    borderTopLeftRadius: "8px",
    width: "calc(25% - 4px)",
    borderTopRightRadius: "8px",
    border: "1px solid #eee",
  },
  activeTab: {
    backgroundColor: colors.desertStorm,
    fontWeight: 'bold'
  },
  indicator: {
    backgroundColor: "transparent",
  },
  title: {
    fontWeight: 500,
    fontSize: "20px",
  },
  subtitle: {
    fontSize: "10px",
    fontWeight: 400,
    color: colors.dune,
  },
  entityTabBorder: {
    borderBottom: `solid 1px #eee`,
  }
}));

export default function EntityOverview({ entity, loanBorrowers }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="entity-tabs"
        className={classes.entityTabBorder}
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab
          label="ENTITY INFO"
          className={`${classes.tab} ${value === 0 ? classes.activeTab : ""}`}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.title}>{getValueOrDefaultDash(entity.ein ?? entity?.company?.einNumber)}</Typography>
            <Typography className={classes.subtitle}>
              ENTITY EIN{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.title}>{getValueOrDefaultDash(entity?.operatingAgreementDate)}</Typography>
            <Typography className={classes.subtitle}>DATE OF INCORPORATI0N </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.title}>{getValueOrDefaultDash(entity?.company?.address)}</Typography>
            <Typography className={classes.subtitle}>
              ADDRESS{" "}
            </Typography>
          </Grid>
          {loanBorrowers?.length > 0 && loanBorrowers.map((borrower) => <Grid key={borrower.borrowerId} item xs={6} md={3}>
            <Typography className={classes.title}>{formatPercentage(borrower?.pctOwnership)}</Typography>
            <Typography className={classes.subtitle}>
              {borrower?.fullName}
            </Typography>
          </Grid>)}
        </Grid>
      </TabPanel>
    </div>
  );
}
