import { Grid } from '@material-ui/core';
import {
  createSelectField,
  createTextField,
  createNumberFormatField,
  createDateField,
  createCurrencyField,
} from '@roc/ui/componentBuilder';
import { observer } from 'mobx-react';
import { FileUploadDownloadArea } from '../common/fileUploadDownloadArea';
import { Document } from '@roc/feature-types';
import { UNDERWRITING } from '@roc/feature-utils';
import { useDocumentStore } from '../../../hooks/useDocumentStore';
import { useEffect } from 'react';
import { OTHER } from '../../../stores/documentForms/bankStatement/bankStatementFormStore';

interface BankStatementProps {
  loan: any;
  document: Document;
}

const BankStatement = observer((props: BankStatementProps) => {
  const { loan, document } = props;
  const disableDownload = (!document?.dropboxPath || document?.dropboxPath.trim().length === 0);
  const { documentStore } = useDocumentStore();
  const { bankStatementFormStore } = documentStore.documentFormStore;

  useEffect(() => {
    const downloadExistingFile = !disableDownload ? (document) => documentStore.fetchDocumentFile(document) : null;
    bankStatementFormStore.fetchBankStatement(loan, document, downloadExistingFile);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {createDateField({
          store: bankStatementFormStore,
          testId: 'asOfDate',
          fieldName: 'asOfDate',
          label: 'As Of Date',
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createSelectField({
          store: bankStatementFormStore,
          testId: 'accountHolder',
          fieldName: 'accountHolder',
          label: 'Account Holder',
          options: bankStatementFormStore.holderList,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createCurrencyField({
          label: 'Amount',
          fieldName: 'amount',
          testId: 'amount',
          store: bankStatementFormStore,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createTextField({
          store: bankStatementFormStore,
          testId: 'otherAccountHolderName',
          fieldName: 'otherAccountHolderName',
          label: 'Specify Other',
          disabled: bankStatementFormStore.form.fields.accountHolder.value !== OTHER
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {createNumberFormatField({
          store: bankStatementFormStore,
          testId: 'lastFourDigits',
          fieldName: 'lastFourDigits',
          label: 'Last 4 Of Account #',
          format: '####',
        })}
      </Grid>
      <Grid item xs={12}>
        <FileUploadDownloadArea
          file={bankStatementFormStore.bankStatementFile}
          onChange={(file) => bankStatementFormStore.setBankStatementFile(file)}
          onUpload={(file) => documentStore.uploadDocument(file, document, UNDERWRITING)}
          onDownload={() => documentStore.downloadDocument(document)}
          disableDownload={disableDownload}
          infoMessage={disableDownload ? `${document?.taskName} has not been uploaded` : `${document?.taskName} was last uploaded at ${document.lastUpdateDate}`}
        />
      </Grid>
    </Grid>
  );
});

export default BankStatement;