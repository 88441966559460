import {
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  Page,
  useBaseStore,
  useMiscellaneousRoutes
} from '@roc/feature-app-core';
import { ConversationAttribute, MyChatContainer, SelectConversation, useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import {
  AllMessages,
  Documents,
  Overview,
  PendingDocuments,
  useDocumentCommunicationStore,
  useDocumentStore,
} from '@roc/feature-documents';
import {
  DrawDetails, DrawRequest, LoanDrawsDashboard, ReviseScopeOfWork
} from '@roc/feature-draws';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import {
  CLOSING,
  getDocumentSection,
  getDrawId, getExtensionId, LoanStatus, LoanType, SERVICING, UNDERWRITING
} from '@roc/feature-utils';
import { DrawerMenu, IconButton, NotFound, PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router';
import { useLoanStore } from '../hooks/useLoanStore';
import BorrowerEntityOverview from './components/borrowerEntityOverview';
import { LoanDetailsLayout } from './components/loanDetailsLayout';
import { LoanDetailsMenu } from './components/loanDetailsMenu';
import Properties from './components/properties';
import { ExtensionDetails } from './modules/extensionDetails';
import { LoanContacts } from './modules/loanContacts/loanContacts';
import { ExtensionsDashboard } from './modules/extensionsDashboard';
import PayoffRequest from './payoffs/payoffRequest';
import { PayoffRequestV2 } from './payoffs/payoffRequestV2';
import { EstimatedClosingCosts } from './components/estimatedClosingCosts';
import { BannerMessages } from '../components/bannerMessages';
import { LoanDetailsPaperLayout } from '@roc/feature-loans-shared';
import { LoanSummaryCommissions, LoanSummaryExtended, LoanSummaryDecisionTab } from '@roc/feature-loan-summary';
import { MenuOpen } from '@material-ui/icons';
import { EditLoanBorrowers, EditLoanProperties, EditLoanTerms } from '@roc/feature-edit-loans';
import { LoanForeclosureRequest } from './foreclosure/components/loanForeclosureRequest';
import { LoanExternalAgentDetails } from '@roc/feature-title-agent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%'
    },
    chatBox: {
      overflow: 'auto',
      [theme.breakpoints.down(769)]: {
        margin: '8px 0 0 0',
      },
    },
  })
);

interface LoanDetailsProps {
  hideEditBorrowers?: boolean;
  renderLoanSummary: (props) => React.ReactNode;
  isLoanSummary: boolean;
  onUserNewConversation: (inviteUserId: number) => Promise<void>;
  onOpenAddTask?: (assigneeId: number) => void;
  renderFastTrackPaymentReminder: () => React.ReactNode;
  renderLoanClosingCondition: () => React.ReactNode;
  renderDrawDetails: () => React.ReactNode;
  renderExtensionDetails: () => React.ReactNode;
  renderPayoffScreen: (servicer: any) => React.ReactNode;
  renderRepeatPropertyWarning: () => React.ReactNode;
}

export const LoanDetailsV1 = observer((props: LoanDetailsProps) => {
  const classes = useStyles();
  const {
    onOpenAddTask,
    renderLoanSummary,
    isLoanSummary,
    onUserNewConversation,
    renderFastTrackPaymentReminder,
    renderLoanClosingCondition,
    renderDrawDetails,
    renderExtensionDetails,
    renderPayoffScreen,
    renderRepeatPropertyWarning,
  } = props;
  const { globalStore } = useBaseStore();
  const { loanId } = useParams<{ loanId: string }>();
  const { pathname } = useLocation();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const { push } = useHistory();
  const {
    miscellaneousRoutes,
    miscellaneousRoutesConfig,
  } = useMiscellaneousRoutes();
  const { loanRoutesConfig } = useLoanRoutes();
  const twilioCommunicationEnabled = loanStore.loanDetails?.twilioCommunicationEnabled;
  const theme = useTheme();
  const isTabletSize = useMediaQuery(theme.breakpoints.down(769));
  const isBridgeLoan = loanStore.loanDetails?.loanType == LoanType.RESIDENTIAL_BRIDGE;
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;

  const {
    communicationRoutesConfig,
  } = useCommunicationRoutesConfig();

  const documentSection = getDocumentSection(pathname);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleDrawerMenu = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    setMenuVisible(!pathname.includes('/loan-summary'));
  }, [pathname]);

  return (
    <Box display="flex" className={classes.container}>
      {menuVisible &&
        <Box flexShrink={1}>
          <DrawerMenu
            open={drawerOpen}
            onOpen={toggleDrawerMenu}
            onClose={toggleDrawerMenu}
          >
            <LoanDetailsMenu data={loanStore.loanDetails} toggleMenu={toggleMenu} />
          </DrawerMenu>
        </Box>
      }
      {(isLoanSummary && !menuVisible) &&
        <Box display="flex" alignItems={'flex-start'}>
          <IconButton
            aria-label="close"
            testId="close"
            size="medium"
            onClick={toggleMenu}
          >
            <MenuOpen />
          </IconButton>
        </Box>
      }
      <Box flexGrow={1}>
        <Switch>
          <Route
            exact
            path={`${loanRoutesConfig.loans(loanId).path}/`}
            render={() => {
              return (
                <Redirect
                  to={loanRoutesConfig.loans(loanId).children.dashboard.url}
                />
              );
            }}
          />
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.dashboard.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.dashboard
              }
            >
              <LoanDetailsLayout title="LOAN SUMMARY">
                {renderRepeatPropertyWarning()}
                {renderFastTrackPaymentReminder()}
                {renderLoanClosingCondition()}
                {renderLoanSummary({ loanDetails: loanStore.loanDetails })}
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.pendingDocuments.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.pendingDocuments
              }
            >
              <PendingDocuments loanId={loanId} />
            </Page>
          </Route>
          {globalStore.userFeatures?.showLoanSummary && (
            <Route exact path={loanRoutesConfig.loans(loanId).children.loanSummary.path}>
              <Page routeDefinition={loanRoutesConfig.loans(loanId).children.loanSummary}>
                <LoanDetailsLayout
                  title="LOAN SUMMARY" >
                  <LoanSummaryExtended />
                </LoanDetailsLayout>
              </Page>
            </Route>)
          }
          {!twilioCommunicationEnabled && globalStore.userFeatures?.oldMessagesAccess && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.allMessages.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.allMessages
                }
              >
                <AllMessages />
              </Page>
            </Route>
          )}

          <Route path={communicationRoutesConfig.conversations(loanId).path}>
            <Page routeDefinition={communicationRoutesConfig.conversations(loanId)} >
              <LoanDetailsLayout
                heading=''
                height='100%'
                minHeight={isTabletSize ? 300 : 800}
                bodyContentBoxClass={classes.chatBox}>
                <MyChatContainer
                  id={Number(loanId)}
                  communicationStore={loanCommunicationStore}
                  renderAsModalInSmallerScreens={true}
                  allowManageParticipants={true}
                  allowRemoveParticipants={true}
                  allowAddInternalParticipants={true}
                  allowNewConversations={globalStore.userFeatures?.isBroker ? false : true}
                  allowJoinConversation={true}
                  onModalClose={() => push(loanRoutesConfig.loans(loanId).children.dashboard.url)}
                  onConversationSelect={(conversationId) => push(communicationRoutesConfig.conversations(loanId).children.conversation(conversationId).url)}
                  onUserNewConversation={onUserNewConversation}
                  onOpenAddTask={onOpenAddTask} />
                <Switch>
                  <Route exact path={communicationRoutesConfig.conversations().children.conversation().path}>
                    <SelectConversation communicationStore={loanCommunicationStore} />
                  </Route>
                </Switch>
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.commissions.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.commissions
              }
            >
              <LoanDetailsLayout title="COMMISSIONS">
                <LoanSummaryCommissions />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.decisionTab.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.decisionTab
              }
            >
              <LoanDetailsLayout title="DECISION COMMENTS">
                <LoanSummaryDecisionTab />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route
            exact
            path={
              loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, UNDERWRITING, documentSection).url
            }
          >
            <Page
              routeDefinition={loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, UNDERWRITING, documentSection)}
            >
              <Documents
                section={documentSection}
                loanProcess={UNDERWRITING}
                loanId={loanId}
                extensionNumber={null}
                loanStore={loanStore}
              />
            </Page>
          </Route>
          <Route
            exact
            path={
              loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, CLOSING, 'overview').url
            }
          >
            <Page
              routeDefinition={loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, CLOSING, 'overview')}
            >
              <Overview
                loanStore={loanStore}
                loanProcess={CLOSING}
                loanId={loanId}
                isResendInsuranceEnable={
                  globalStore.userFeatures?.resendInsuranceQuote
                }
              />
            </Page>
          </Route>
          <Route
            exact
            path={
              loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, CLOSING, documentSection).url
            }
          >
            <Page
              routeDefinition={loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, CLOSING, documentSection)}
            >
              <Documents
                section={documentSection}
                loanProcess={CLOSING}
                loanId={loanId}
                extensionNumber={null}
                loanStore={loanStore}
              />
            </Page>
          </Route>
          {loanStore.userStore.allowLoanPricing && isEstimatedClosingCostEnabled && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.estimatedClosingCosts.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.estimatedClosingCosts
                }
              >
                <EstimatedClosingCosts />
              </Page>
            </Route>
          )}

          <Route
            exact
            path={
              loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, SERVICING, documentSection).url
            }
          >
            <Page
              routeDefinition={loanRoutesConfig
                .loans(loanId)
                .children.documents(loanId, SERVICING, documentSection)}
            >
              <Documents
                section={documentSection}
                loanProcess={SERVICING}
                loanId={loanId}
                extensionNumber={null}
                loanStore={loanStore}
              />
            </Page>
          </Route>
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.properties.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.properties
              }
            >
              <LoanDetailsLayout
                title="PROPERTIES"
                onBack={() =>
                  push(loanRoutesConfig.loans(loanId).children.dashboard.url)
                }
              >
                <Properties properties={loanStore.loanDetails.properties} />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route
            exact
            path={loanRoutesConfig.loans(loanId).children.borrowerEntity.path}
          >
            <Page
              routeDefinition={
                loanRoutesConfig.loans(loanId).children.borrowerEntity
              }
            >
              <LoanDetailsLayout
                title="ENTITY"
                onBack={() =>
                  push(loanRoutesConfig.loans(loanId).children.dashboard.url)
                }
              >
                <BorrowerEntityOverview
                  hideEditBorrowers={props.hideEditBorrowers || !loanStore.allowEditBorrowers}
                  entity={loanStore.loanDetails.borrowerEntity}
                  borrowers={loanStore.loanDetails.borrowers}
                />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route exact path={loanRoutesConfig.loans(loanId).children.editLoanTerms.path}>
            <Page routeDefinition={loanRoutesConfig.loans(loanId).children.editLoanTerms}>
              <LoanDetailsLayout
                title=" "
                onBack={() => {
                  loanStore.editLoansStore.reset();
                  push(loanRoutesConfig.loans(loanId).children.dashboard.url)
                }}
              >
                <EditLoanTerms store={loanStore} refreshLoan={() => loanStore.refreshLoanDetails()} />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route exact path={loanRoutesConfig.loans(loanId).children.editBorrowers.path}>
            <Page routeDefinition={loanRoutesConfig.loans(loanId).children.editBorrowers}>
              <LoanDetailsLayout
                title=" "
                onBack={() => {
                  loanStore.editLoansStore.reset();
                  push(loanRoutesConfig.loans(loanId).children.dashboard.url)
                }}
              >
                <EditLoanBorrowers store={loanStore} refreshLoan={() => loanStore.refreshLoanDetails()} />
              </LoanDetailsLayout>
            </Page>
          </Route>
          <Route exact path={loanRoutesConfig.loans(loanId).children.editProperties.path}>
            <Page routeDefinition={loanRoutesConfig.loans(loanId).children.editProperties}>
              <LoanDetailsLayout
                title=" "
                onBack={() => {
                  loanStore.editLoansStore.reset();
                  push(loanRoutesConfig.loans(loanId).children.dashboard.url)
                }}
              >
                <EditLoanProperties store={loanStore} refreshLoan={() => loanStore.refreshLoanDetails()} />
              </LoanDetailsLayout>
            </Page>
          </Route>
          {loanStore.loanDetails.showDraws && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.draws.path}
            >
              <Page
                routeDefinition={loanRoutesConfig.loans(loanId).children.draws}
              >
                <LoanDrawsDashboard loanId={loanId} />
              </Page>
            </Route>
          )}
          {loanStore.loanDetails.showDraws && (
            <Route
              exact
              path={
                loanRoutesConfig.loans(loanId).children.reviseScopeOfWork.path
              }
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.reviseScopeOfWork
                }
              >
                <ReviseScopeOfWork loanId={loanId} />
              </Page>
            </Route>
          )}
          {renderDrawDetails()}
          {renderExtensionDetails()}
          {loanStore.loanDetails.showExtensions && (
            <Route
              exact
              path={
                loanRoutesConfig.loans(loanId).children.extensions.path
              }
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.extensions
                }
              >
                <ExtensionsDashboard loanId={loanId} />
              </Page>
            </Route>
          )}
          {loanStore.loanDetails.showPayoffs && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.payoffRequest.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.payoffRequest
                }
              >
                {renderPayoffScreen(
                  loanStore.loanDetails.servicer
                )}
              </Page>
            </Route>
          )}
          {loanStore.loanDetails.showDraws && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.drawRequest.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.drawRequest
                }
              >
                <DrawRequest />
              </Page>
            </Route>
          )}
          {loanStore.loanContactsGridStore.showContacts && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.loanContacts.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.loanContacts
                }
              >
                <LoanDetailsLayout
                  title="Contacts"
                  onBack={() =>
                    push(
                      loanRoutesConfig.loans(loanId).children.loanContacts.url
                    )
                  }
                >
                  <LoanContacts />
                </LoanDetailsLayout>
              </Page>
            </Route>
          )}
          {loanStore.loanDetails.showDelinquency && globalStore.userFeatures?.isClosingAttorney && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.foreclosureRequest.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.foreclosureRequest
                }
              >
                <LoanForeclosureRequest loanId={loanId} />
              </Page>
            </Route>
          )}

          {loanStore.loanDetails.showDelinquency && globalStore.userFeatures?.isExternalAgent && (
            <Route
              exact
              path={loanRoutesConfig.loans(loanId).children.foreclosureInvoice.path}
            >
              <Page
                routeDefinition={
                  loanRoutesConfig.loans(loanId).children.foreclosureInvoice
                }
              >
                <LoanExternalAgentDetails loanId={loanId} />
              </Page>
            </Route>
          )}

          {miscellaneousRoutes.notFound}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Box>
      <PDFPreview
        open={documentStore.documentPreview != null}
        title={documentStore.documentPreview?.title}
        data={documentStore.documentPreview?.data}
        headers={documentStore.documentPreview?.headers}
        onClose={() => documentStore.closeDocumentPreview()}
      />
    </Box>
  );
});
