import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '@roc/feature-sow-shared/stores/v2';
import { formatCurrency } from '@roc/ui/utils';
import { SOWTableCardPdf } from '../../../scopeOfWorkV2TableCard';
import { describedRennovationOptions } from '@roc/feature-utils';

const useStyles = makeStyles(theme =>
  createStyles({
    reviewAndSubmitContainer: {
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 1),
      },
    },
    box: {
      border: '2px #e0e0e0 solid',
      paddingLeft: '2px',
      marginTop: '5px',
    },
    boxProjectInfo: {
      border: '2px #e0e0e0 solid',
      paddingLeft: '2px',
      marginTop: '5px',
    },
    propertyLabel: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingLeft: theme.spacing(1),
    },
    propertyText: {
      fontSize: 14,
      paddingRight: theme.spacing(2),
    },
    propertyTitle: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(1),
    },
    subtitle: {
      paddingTop: theme.spacing(2),
    },
    boxTotalCost: {
      backgroundColor: '#fdefbf',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px'
    },
    projectInfoText: {
      fontSize: 14,
      paddingLeft: theme.spacing(1),
      marginRight: '5px',
    },
    totalRow: {
      backgroundColor: '#FDEFBF',
    },
    table: {
      fontSize: 14,
      minWidth: 590,
      border: '2px #e0e0e0 solid',
      '& td': {
        height: '40px',
        padding: 0,
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
    headerCell: {
      height: '41px',
      fontWeight: 'bold',
      marginRight: '3px',
      padding: 0,
    },
    headerCellProjectTotal: {
      height: '41px',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(1),
    },
    boxBudgetSummary: {
      border: '2px #e0e0e0 solid',
      padding: '4px',
    },
    budgetSummaryContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    info: {
      fontFamily: 'Oswald, sans-serif;',
      fontWeight: 'bold',
    },
    infoBottom: {
      fontFamily: 'Oswald, sans-serif;',
    },
  })
);

interface ReviewAndSubmitPDFProps {
  store: ScopeOfWorkV2FormBaseStore;
}

const PAGE_HEIGHT = 900;
const TOTALS_HEIGHT = 100;

const ReviewAndSubmitPDF = observer(({ store }: ReviewAndSubmitPDFProps) => {
  const { categoryStore } = store;
  const classes = useStyles();
  const [totalPages, setTotalPages]= useState(0);
  const [dividedCategories, setDividedCategories] = useState([]);
  const [totalsAdded, setTotalsAdded] = useState(false);
  const [ssIds, setSSIds] = useState([]);

  const initializeIds = (dividedCategories) => {
    let ids = ['projectInformation'];
    if(dividedCategories?.length > 0){
      dividedCategories?.map((_, idx) => {
      ids.push('page_'+idx);
    })
    }
    ids.push('totals')
    store.setScopeOfWorkScreenshotIds(ids);
    return ids;
  }

  const setDefaults = () =>{
    setTotalsAdded(false);
  }

  useEffect(() => {
    const newCategoryList = store.splitedCategoriesObj;
    setDefaults()
    setDividedCategories(newCategoryList);
    setTotalPages(totalPages);
    setSSIds(initializeIds(newCategoryList))
  }, [store.splitedCategoriesObj]);

  const columns = [
    {
      name: 'Line Item',
      width: '20%',
    },
    {
      name: 'Detail',
      width: '10%',
    },
    {
      name: 'Cost',
      width: '10%',
    },
    {
      name: 'COMPLETED RENOVATIONS',
      width: '20%',
      align: 'center'
    },
    {
      name: 'RENO BUDGET',
      width: '10%',
      align: 'center'
    },
  ];

  const TotalsTable = observer(({padding}) => {
    return (
      <Grid item xs={12} md={12} style={{padding: padding}} id='totals'>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width='25%' rowSpan={1}></TableCell>
              <TableCell align='center' className={classes.headerCell} width='25%'>
                COMPLETED RENOVATIONS
              </TableCell>
              <TableCell align='center' className={classes.headerCell} width='25%'>
                RENO BUDGET
              </TableCell>
              <TableCell align='center' className={classes.headerCell} width='25%'>
                TOTAL RENO BUDGET
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.totalRow}>
              <TableCell className={classes.headerCellProjectTotal} width='25%' rowSpan={1} style={{padding: 10}}>
                PROJECT TOTAL
              </TableCell>
              <TableCell align='center' width='20%'>
                <Typography variant="h5">
                  {formatCurrency(categoryStore.totalCapex)}
                </Typography>
              </TableCell>
              <TableCell align='center' width='25%'>
                <Typography variant="h5">
                  {formatCurrency(categoryStore.remainingReno)}
                </Typography>
              </TableCell>
              <TableCell align='center' width='25%'>
                <Typography variant="h5">
                  {formatCurrency(categoryStore.totalRemainingReno)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    )
  })

  const getTables = (idx, category, pageInfo, totalPages, indexOfSublist) => {
    if (idx === 0 && indexOfSublist === 0){
      return (
        <Box>
          <Box>
            <Typography className={classes.subtitle} color="primary" variant="h4">
              Scope of Work
            </Typography>
          </Box>
          <SOWTableCardPdf
            key={category.id}
            store={store}
            title={category.name}
            rows={category.subCategories}
            columns={columns}
            category={category}
            className={category?.className}
          />
        </Box>
      )
    }else if(idx == totalPages -1 && pageInfo.pageHeight + TOTALS_HEIGHT < PAGE_HEIGHT && pageInfo.data.length -1 == indexOfSublist){
      if(!totalsAdded){
        setTotalsAdded(true);
        ssIds.pop()
        store.setScopeOfWorkScreenshotIds(ssIds);
      }
      return (
        <Box>
          <SOWTableCardPdf
            key={category.id}
            store={store}
            title={category.name}
            rows={category.subCategories}
            columns={columns}
            category={category}
            className={category?.className}
          />
          <TotalsTable padding={0}/>
        </Box>
      )
    }else{
      return <SOWTableCardPdf
            key={category.id}
            store={store}
            title={category.name}
            rows={category.subCategories}
            columns={columns}
            category={category}
            className={category?.className}
          />
    }
  }

  return (
    <Box className={classes.reviewAndSubmitContainer} id='reviewAndSubmitContainerPDF'>
      <Box id="projectInformation" style={{padding: 25}}>
        <Grid container spacing={2} direction='row'>
          <Grid item xs = {6}>
            <Box>
              <Typography variant="h4">SCOPE OF WORK (PDF)</Typography>
              <Typography variant="body1">
                Please review the information below before you submit this Scope of Work
              </Typography>
            </Box>
          </Grid>
          <Grid item xs = {5} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '10px' }}>
            <Typography variant='body1' style={{fontSize: '18px'}}>
              Current Version: {store?.scopeOfWork?.currentVersion}
            </Typography>
          </Grid>
        </Grid>
         <Box>
            <Typography className={classes.subtitle} color="primary" variant="h4">
              General Information
            </Typography>
          </Box>
          <Box className={classes.boxBudgetSummary}>
            <Box pt={2} pb={2}>
              <Typography className={classes.propertyTitle} variant="h5">
                Budget Summary
              </Typography>
              <Grid container spacing={4} className={classes.budgetSummaryContainer}>
                <Grid item xs={12} md={4}>
                  <Typography variant='body1'>
                    Reno Budget
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {formatCurrency(categoryStore.remainingReno)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} >
                  <Typography variant='body1'>
                    Completed Renovations
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {formatCurrency(categoryStore.totalCapex)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant='body1'>
                    Total Reno Budget
                  </Typography>
                  <Divider />
                  <Typography variant='h5' className={classes.info}>
                    {formatCurrency(categoryStore.totalRemainingReno)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.box}>
            <Box pt={2} pb={2}>
              <Typography className={classes.propertyTitle} variant="h5">Property Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyLabel} variant="body1">Address</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyText} variant="body1">
                    {store.getAddress()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyLabel} variant="body1">
                    Do you already own the property?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyText} variant="body1">
                    {store.form.fields.alreadyOwn.value ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                {store.form.fields.describeRenovation.value &&
                  <>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography className={classes.propertyLabel} variant="body1">
                        Special Project Type
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography className={classes.propertyText} variant="body1">
                        {describedRennovationOptions.find(option => option.value === store.form.fields.describeRenovation.value)?.label}
                      </Typography>
                    </Grid>
                  </>}
                {store.form.fields.alreadyOwn.value && (
                  <>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography className={classes.propertyLabel} variant="body1">
                        Have you already made improvements to the property?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Typography className={classes.propertyText} variant="body1">
                        {store.form.fields.anyImprovements.value ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.propertyText} variant="body1">
                  {store.getAddress()}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.propertyLabel} variant="body1">
                  Do you already own the property?
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography className={classes.propertyText} variant="body1">
                  {store.form.fields.alreadyOwn.value ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              {store.form.fields.describeRenovation.value.length > 0 && (
                <>
                  <Grid item xs={12} md={6} lg={3}>
                    <Typography
                      className={classes.propertyLabel}
                      variant="body1"
                    >
                      Special project type
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Typography
                      className={classes.propertyText}
                      variant="body1"
                    >
                      {store.form.fields.describeRenovation.value}
                    </Typography>
                  </Grid>
                </>
              )}
              {store.form.fields.alreadyOwn.value && (
                <>
                  <Grid item xs={12} md={6} lg={3}>
                    <Typography
                      className={classes.propertyLabel}
                      variant="body1"
                    >
                      Have you already made improvements to the property?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Typography
                      className={classes.propertyText}
                      variant="body1"
                    >
                      {store.form.fields.anyImprovements.value ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
          <Box className={classes.boxProjectInfo}>
            <Box pt={2} pb={2}>
              <Typography className={classes.propertyTitle} variant="h5">
                Project Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <Typography className={classes.propertyLabel} variant="body1">
                    Provide a narrative description of the proposed work
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                  <Typography className={classes.projectInfoText} variant="body1">
                    {store.form.fields.projectDescription.value}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
      </Box>
      {dividedCategories?.length > 0 && dividedCategories.map((page, idx) => (
        <Box id={'page_'+idx} style={{padding: 25}}>
          {page.data.map((category, i) => getTables(idx, category, page, dividedCategories.length, i))}
        </Box>
      ))}

      {!totalsAdded && <TotalsTable padding={25}/>}

    </Box>
  );
});

export default ReviewAndSubmitPDF;
