import { Button, StandardDialog } from '@roc/ui';
import { NO_ACTION } from '@roc/feature-sow-shared/constants/scopeOfWorkForm';
import { observer } from 'mobx-react';
import { ScopeOfWorkV2FormBaseStore } from '../stores';
import { Typography } from '@material-ui/core';

interface Props {
  store: ScopeOfWorkV2FormBaseStore;
}

export const SubmitReviewCompleteDialog = observer((props: Props) => {
  const { store } = props;

  return (
    <StandardDialog
      open={store.isReviewCompleteOpen}
      title="Review Complete"
      maxWidth="md"
      handleClose={() => store.setOpenCompleteReviewDialog(false)}
      dialogContent={
        <Typography> Are you sure you want to finalize the review of this scope of work?</Typography>
      }
      dialogActions={
        <>
          <Button
            testId="submitReviewCancel"
            style={{ marginRight: '8px' }}
            onClick={() => store.setOpenCompleteReviewDialog(false)}
            color="primary"
            variant="outlined"
          >
            CANCEL
          </Button>
          <Button
            testId="submitReviewConfirm"
            onClick={() => store.handleMarkAsReviewComplete(NO_ACTION)}
            color="primary"
            variant="contained"
          >
            CONFIRM
          </Button>
        </>
      }
    />
  );
});

export default SubmitReviewCompleteDialog;