import React, { useMemo } from 'react';
import {
  MoreVert,
  Launch,
  GetApp,
  Publish,
  Search,
  History,
  Link,
  Cancel,
  FindInPage,
  FindInPageOutlined
} from '@material-ui/icons';
import { Document } from '@roc/feature-types';
import { DropdownMenu } from '@roc/ui';
import { DocumentName, DocumentStatus } from '../../../constants';
import { insertIf } from '@roc/feature-utils';
import { isFileAlreadyUploaded } from '../../../utils/documents';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';

export type DocumentActionsProps = {
  document: Document;
  onUpload?: (document: Document) => void;
  onDownload?: (document: Document) => void;
  onPreview?: (document: Document) => void;
  onOpenForm?: (document: Document) => void;
  onOpenHistory?: (document: Document) => void;
  onFillForm?: (document: Document) => void;
  onShareLink?: (document: Document) => void;
  onViewForm?: (document: Document) => void;
  onRequestMoreInformation?: (document: Document) => void;

};
export const DocumentActions = (props: DocumentActionsProps) => {
  const {
    document,
    onUpload,
    onDownload,
    onPreview,
    onOpenForm,
    onOpenHistory,
    onFillForm,
    onShareLink,
    onViewForm,
    onRequestMoreInformation
  } = props;

  const { globalStore } = useBaseStore();
  const { userStore } = useUserStore();

  const getDocumentActions = () => {
    const actionList = [];
    if (document.isForm) {
      if (document.taskName === DocumentName.DRAW_REPORT) {
        return [{
          icon: Launch,
          name: 'Open Form',
          action: () => onOpenForm(document),
          disabled: !(document.status === DocumentStatus.ACCEPTED),
        },
        ];
      }
      if (document.taskName.includes(DocumentName.CLOSING_CONDITION_SUFIX)) {
        return [{
          icon: Launch,
          name: 'Open Form',
          action: () => onOpenForm(document),
        },
        {
          icon: GetApp,
          name: 'Download Document',
          action: () => onDownload(document),
          disabled: !document.canDownloadFile
        }
        ];
      }
      if (document.canOpenForm) {
        actionList.push({
          icon: Launch,
          name: 'Open Form',
          action: () => onOpenForm(document),
        });
      }
      if (document.canRequestMoreInformation && userStore.isBackOfficeUser) {
        actionList.push({
          icon: FindInPageOutlined,
          name: 'Mark as More Info Needed',
          action: () => onRequestMoreInformation(document),
        });
      }
    } else {
      if (document.canUploadFile) {
        actionList.push({
          icon: Publish,
          name: 'Upload Document',
          action: () => onUpload(document),
        });
      }
      if (document.canDownloadFile) {
        actionList.push({
          icon: GetApp,
          name: 'Download Document',
          action: () => onDownload(document),
        });
        actionList.push({
          icon: Search,
          name: 'Preview Document',
          action: () => onPreview(document),
        });
        if (onOpenHistory) {
          actionList.push({
            icon: History,
            name: 'History',
            action: () => onOpenHistory(document),
          });
        }
      }
      if (document.taskName === DocumentName.DRAW_REQUEST_FORM) {
        return [{
          icon: Launch,
          name: 'Fill Form',
          action: () => onFillForm(document),
          disabled: !(document.status === DocumentStatus.MORE_INFO_NEEDED || document.status === DocumentStatus.PENDING),
        },

          // {
          //   icon: Link,
          //   name: 'Share Link',
          //   action: () => onShareLink(document),
          // }
        ];
      }
      if (document.taskName === DocumentName.PROPERTY_PHOTOS) {
        return [{
          icon: Link,
          name: 'Share Link',
          action: () => onShareLink(document),
        }];
      }
      if (document.taskName === DocumentName.PLAID_BANKS) {
        return [
          ...insertIf(isFileAlreadyUploaded(document), [
            {
              icon: GetApp,
              name: 'Download Document',
              action: () => onDownload(document),
            }
          ]),
          ...insertIf(globalStore.userFeatures.plaidRegistration, [{
            icon: Link,
            name: 'Share Link',
            action: () => onShareLink(document),
          }])];
      }
    }
    return actionList;
  };

  const documentActions = useMemo(getDocumentActions, [
    document,
    onUpload,
    onDownload,
    onOpenForm,
    onOpenHistory,
  ]);

  return documentActions.length ? (
    <DropdownMenu options={documentActions}>
      <MoreVert />
    </DropdownMenu>
  ) : null;
};

export default DocumentActions;
