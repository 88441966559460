import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import {
  Button,
  ConfirmDialog,
  GridColumnProps,
  Paper,
  StandardDialog,
  Tabs,
  TextField,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { filterProps, noOpComparator } from '@roc/ui/utils';
import { insertIf } from '@roc/feature-utils';
import { TamariskAppraisalDashboardToolbar } from './tamariskAppraisalDashboardToolbar';
import { useTamariskAppraisalStore } from '../hooks/useTamariskAppraisalStore';
import { TamariskAppraisalDashboardTab } from '../types/types';
import { TamariskAppraisalDashboardGrid } from './TamariskAppraisalDashboardGrid';

import { KeyboardArrowRight, Send, CloudDownload } from '@material-ui/icons';
import React, { useState } from 'react';
import { SingleTaskView } from '@roc/feature-general-tasks';

const TABS = [
  {
    value: TamariskAppraisalDashboardTab.MY_TAMARISK_APPRAISAL,
    label: 'My Appraisal Revision Pending',
  },
  {
    value: TamariskAppraisalDashboardTab.ALL_TAMARISK_APPRAISAL,
    label: 'Appraisal Revision Pending',
  },
  {
    value: TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL,
    label: 'Appraisal Sent To Appraiser',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },
}));

export const TamariskAppraisalDashboard = observer(() => {
  const classes = useStyles();
  const { tamariskAppraisalStore } = useTamariskAppraisalStore();
  const { tab } = tamariskAppraisalStore;
  const [orderIdToLink, setOrderIdToLink] = useState('');
  const [isSendToAppraiserDialogOpen, setSendToAppraiserDialogOpen] = useState(false
  );
  const [isRequestRecertificationModalOpen, setRequestRecertificationModalOpen] = useState(false);
  const [isConfirmLinkAppraisalOrderModalOpen, setConfirmLinkAppraisalOrderModalOpen] = useState(false);
  const [isLinkAppraisalOrderModalOpen, setLinkAppraisalOrderModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);

  const openLinkAppraisalOrderOpenModal = orderId => {
    setOrderIdToLink(orderId || '');
    setLinkAppraisalOrderModalOpen(true);
  };

  const openConfirmLinkAppraisalOrderModal = () => {
    setConfirmLinkAppraisalOrderModalOpen(true);
    setLinkAppraisalOrderModalOpen(false);
  };

  async function updateAppraisalReviewStatus(record) {
    setSendToAppraiserDialogOpen(false);
    console.log('updateAppraisalReviewStatus - here');
    await tamariskAppraisalStore.sendToAppraiser([record]);
    tamariskAppraisalStore.getCurrentGridStore().resetAndFetchGridData();
  }

  function requestAppraisalRecertification(propertyId, loanId) {
    setRequestRecertificationModalOpen(false);
    tamariskAppraisalStore.createAppraisalRecertification(loanId, propertyId);
  }

  function linkAppraisal(loanId, propertyId, orderId: string) {
    setConfirmLinkAppraisalOrderModalOpen(false);
    tamariskAppraisalStore.linkAppraisalToLoan(loanId, propertyId, orderId);
  }

  const actions = (record, handleMenuClose) => {
    const getActionVisibility = showAction => {
      return showAction || record?.isRoleAuthorizedToPushOrder;
    };

    const actionsRowMenu = [
      ...insertIf(
        tab !== TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL,
        [
          {
            icon: Send,
            text: 'Mark as Sent to Appraiser',
            onClickAction: () => setSendToAppraiserDialogOpen(true),
            showAction: true,
          },
        ]
      ),
      ...insertIf(
        tab !== TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL,
        [
          {
            icon: Send,
            text: 'Generate Recertification Order',
            onClickAction: () => setRequestRecertificationModalOpen(true),
            showAction: true,
          },
        ]
      ),
      {
        icon: CloudDownload,
        text: 'Download Revision',
        onClickAction: () =>
          tamariskAppraisalStore.downloadRevisionFile(
            record.propertyId,
            record.loanId,
            record?.loanTaskId
          ),
        showAction: true,
      },
      ...(record?.taskId
        ? [
          {
            icon: KeyboardArrowRight,
            text: 'Task',
            onClickAction: () => setTaskId(record.taskId),
            showAction: true,
          },
        ]
        : []),
      {
        icon: Send,
        text: 'Link Appraisal Order to Property',
        onClickAction: () => openLinkAppraisalOrderOpenModal(record.orderId),
        showAction: true,
      },
    ];
    return (
      <>
        {actionsRowMenu.map(
          item =>
            getActionVisibility(item.showAction) && (
              <MenuItem
                key={`menu_${item.text}`}
                onClick={() => {
                  item.onClickAction();
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  <item.icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
            )
        )}
        <ConfirmDialog
          open={isSendToAppraiserDialogOpen}
          handleClose={() => setSendToAppraiserDialogOpen(false)}
          handleCancel={() => setSendToAppraiserDialogOpen(false)}
          handleConfirm={() =>
            updateAppraisalReviewStatus(record)
          }
          title="Confirmation"
          body={`Are you sure you want to change the status to 'Sent to Appraiser'?`}
        />
        <ConfirmDialog
          open={isRequestRecertificationModalOpen}
          handleClose={() => setRequestRecertificationModalOpen(false)}
          handleCancel={() => setRequestRecertificationModalOpen(false)}
          handleConfirm={() =>
            requestAppraisalRecertification(record.propertyId, record.loanId)
          }
          title="Confirmation"
          body={`Are you sure you want to create an appraisal recertification order?`}
        />
        <StandardDialog
          open={isLinkAppraisalOrderModalOpen}
          handleClose={() => setLinkAppraisalOrderModalOpen(false)}
          title="Link Appraisal"
          dialogContent={
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  variant={'outlined'}
                  testId={'linkOrderId'}
                  standaloneLabel={true}
                  label={'Tamarisk Order Id'}
                  required
                  value={orderIdToLink}
                  onChange={e => setOrderIdToLink(e.target.value)}
                />
              </Grid>
            </Grid>
          }
          dialogActions={
            <Button
              testId={'linkOrderIdBtn'}
              onClick={openConfirmLinkAppraisalOrderModal}
              size="large"
              variant="contained"
              type="submit"
              color="primary"
            >
              Link
            </Button>
          }
        />
        <ConfirmDialog
          open={isConfirmLinkAppraisalOrderModalOpen}
          handleClose={() => setConfirmLinkAppraisalOrderModalOpen(false)}
          handleCancel={() => setConfirmLinkAppraisalOrderModalOpen(false)}
          handleConfirm={() =>
            linkAppraisal(record.loanId, record.propertyId, orderIdToLink)
          }
          title={'Confirmation'}
          body={'Are you sure want to link this order to the property?'}
        />
        {!!taskId && <SingleTaskView taskId={taskId} onClose={() => setTaskId(null)} />}
      </>
    );
  };

  const TAMARISK_APPRAISAL_COLUMNS: GridColumnProps[] = [
    {
      field: 'loanId',
      headerName: 'Loan Id',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'lenderName',
      headerName: 'TPO',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'propertyAddress',
      headerName: 'Property Address',
      minWidth: 200,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'taskName',
      headerName: 'Task Name',
      minWidth: 125,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'tamariskSpecialist',
      headerName: 'Tamarisk Specialist',
      minWidth: 175,
      comparator: noOpComparator,
      ...filterProps(),
    },
    ...insertIf(tab === TamariskAppraisalDashboardTab.SENT_TAMARISK_APPRAISAL, [
      {
        field: 'appraiserName',
        headerName: 'Appraiser Name',
        minWidth: 125,
        comparator: noOpComparator,
        ...filterProps(),
        editable: true,
        cellEditor: 'dropdownGridEditor',
      },
    ]),
    {
      field: 'reportDate',
      headerName: 'Request Date',
      width: 90,
      cellRenderer: 'dateCellRenderer',
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'noOfDays',
      headerName: 'Hours in Queue',
      width: 90,
      comparator: noOpComparator,
      ...filterProps(),
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 80,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
      width: 80,
      pinned: 'right',
      lockPinned: true,
    },
  ];

  return (
    <Layout
      title="Tamarisk Appraisal Dashboard"
      containerClass={classes.container}
    >
      <Box pb={2}>
        <Tabs
          selectedTab={TABS.findIndex(t => t.value === tab)}
          tabs={Object.values(TABS)}
          onTabChange={(e, i) => tamariskAppraisalStore.setTab(TABS[i].value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <TamariskAppraisalDashboardGrid
              tamariskAppraisalStore={tamariskAppraisalStore}
              columns={TAMARISK_APPRAISAL_COLUMNS}
              toolbar={
                <TamariskAppraisalDashboardToolbar
                  tamariskAppraisalStore={tamariskAppraisalStore}
                />
              }
              actions={actions}
            />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
