import { parseAddress } from '@roc/client-portal-shared/utils';
import { action, computed, flow, makeObservable, observable, values } from 'mobx';
import { GlobalStore } from '../../../stores';
import { FormStore } from '@roc/feature-app-core';
import { ExternalLenderStore } from '../../companyProfile/stores/externalLenderStore';
import { lenderStatus } from '../utils/constants';

const REQUIRED_MESSAGE = 'This field is required';

const form = {
  fields: {
    // Company Details
    companyName: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    einNumber: {
      value: '',
      error: null,
      rule: 'required|min:9',
      message: 'Should be a minimum of 9 digits',
    },
    entityType: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    marketProducts: {
      value: false,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    email: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    // Company Details > Address fields
    address: {
      value: '',
      error: null,
      rule: 'required',
      message: REQUIRED_MESSAGE,
    },
    streetNumber: {
      value: '',
      error: null,
      rule: '',
    },
    streetName: {
      value: '',
      error: null,
      rule: '',
    },
    city: {
      value: '',
      error: null,
      rule: '',
    },
    state: {
      value: '',
      error: null,
      rule: '',
    },
    zipCode: {
      value: '',
      error: null,
      rule: '',
    },
    // Products
    products: {
      value: '',
      error: null,
      rule: '',
    },
    operatingState: {
      value: '',
      error: null,
      rule: '',
    },
    website: {
      value: '',
      error: null,
      rule: '',
    },
    nmlsId: {
      value: '',
      error: null,
      rule: '',
    },
    companyDba: {
      value: '',
      error: null,
      rule: '',
    },
    fundingDba: {
      value: '',
      error: null,
      rule: '',
    },
    linkedinCompanyPage: {
      value: '',
      error: null,
      rule: '',
    },
    pledgeOfShares: {
      value: '',
      error: null,
      rule: '',
    },
    inherited: {
      value: '',
      error: null,
      rule: '',
    },
    lenderName: {
      value: '',
      error: null,
      rule: ''
    },
    primaryRelationshipManager: {
      value: null,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    primaryCommission: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    secondaryRelationshipManager: {
      value: null,
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    secondaryCommission: {
      value: '',
      error: null,
      rule: '',
      message: REQUIRED_MESSAGE,
    },
    bankName: {
      value: '',
      error: null,
      rule: '',
    },
    routingNumber: {
      value: '',
      error: null,
      rule: '',
    },
    accountName: {
      value: '',
      error: null,
      rule: '',
    },
    accountNumber: {
      value: '',
      error: null,
      rule: '',
    },
    preferredInsuranceReviewer: {
      value: '',
      error: null,
      rule: '',
    },
    phone: {
      value: '',
      error: null,
      rule: 'phone',
    },
    lenderTradeName: {
      value: '',
      error: null,
    },
    bankInfoStatus: {
      value: '',
      error: null,
    },
    newBankName: {
      value: '',
      error: null,
      rule: '',
    },
    newRoutingNumber: {
      value: '',
      error: null,
      rule: '',
    },
    newAccountName: {
      value: '',
      error: null,
      rule: '',
    },
    newAccountNumber: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskSendInvoiceEmail: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskDefaultOption: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskAppraisal: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskSendInvoiceEmailModal: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskDefaultOptionModal: {
      value: '',
      error: null,
      rule: '',
    },
    tamariskAppraisalModal: {
      value: '',
      error: null,
      rule: '',
    },
    desktopAppraisalPreference: {
      value: '',
      error: null,
      rule: '',
    },
    highValueClient: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class LenderDetailsFormStore extends FormStore {
  private globalStore: GlobalStore;
  public salesforceLenderStore: ExternalLenderStore;

  coordinates: { lat: number; lng: number };

  selectedProducts = {};
  showOtherProducts = false;
  selectedRoles = {};
  showOtherRoles = false;
  bankInfo;
  isReady = false;

  constructor(globalStore, salesforceLenderStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.salesforceLenderStore = salesforceLenderStore;

    makeObservable(this, {
      coordinates: observable,
      selectedProducts: observable,
      showOtherProducts: observable,
      selectedRoles: observable,
      showOtherRoles: observable,
      isReady: observable,
      toggleProduct: action,
      toggleOtherProducts: action,
      toggleRole: action,
      toggleOtherRoles: action,
      loadData: flow,
      getLenderBankInformation: flow,
      saveSalesforceLenderChanges: flow,
      isDuplicateLenderAlias: flow,
      loadDataToChangeRM: action,
      validateRMorPercentageChanged: action,
      updateCommissions: flow,
      setTamariskValuesFromModal: action,
      loadStepperModalWithValuesFromDB: action,
      saveHighValueClientTPO:flow
    });
  }

  setTamariskValuesFromModal(preferences) {
    this.onFieldChange('tamariskAppraisal', preferences.tamariskAppraisal);
    this.onFieldChange('tamariskDefaultOption', preferences.tamariskDefaultOption);
    this.onFieldChange('tamariskSendInvoiceEmail', preferences.tamariskSendInvoiceEmail);
  }

  toggleProduct = product => {
    this.selectedProducts[product] = !this.selectedProducts[product];
    const valuesStr = this.joinSemicolonSeparatedValues(this.selectedProducts);
    this.onFieldChange('products', valuesStr);
  };

  toggleOtherProducts = () => {
    if (this.showOtherProducts) {
      this.showOtherProducts = false;
    } else {
      this.showOtherProducts = true;
    }
  };

  toggleRole = role => {
    this.selectedRoles[role] = !this.selectedRoles[role];
    const valuesStr = this.joinSemicolonSeparatedValues(this.selectedRoles);
  };

  toggleOtherRoles = () => {
    if (this.showOtherRoles) {
      this.showOtherRoles = false;
    } else {
      this.showOtherRoles = true;
    }
  };

  *loadData() {
    try {
      yield this.salesforceLenderStore.fetchSalesforceLender();

      const lender = this.salesforceLenderStore.salesForceLender;
      this.loadForm(lender);
      this.onFieldChange('companyName', lender.company.name || '')
      this.onFieldChange('email', lender.company.email || '')
      this.onFieldChange('einNumber', lender.company.einNumber || '')
      this.onFieldChange('entityType', lender.company.entityType)
      this.onFieldChange('primaryRelationshipManager', { label: lender.primaryRelationshipManagerName, value: lender.primaryRelationshipManagerEmail || '' })
      this.onFieldChange('secondaryRelationshipManager', { label: lender.secondaryRelationshipManagerName, value: lender.secondaryRelationshipManagerEmail || '' })
      this.onFieldChange('lenderName', lender.lenderName || '')
      this.onFieldChange('lenderTradeName', lender.lenderTradeName || '')
      this.onFieldChange('preferredInsuranceReviewer', lender.preferredInsuranceReviewer)
      this.onFieldChange('address',
        [
          lender.company.streetNumber,
          lender.company.streetName,
          lender.company.city,
          lender.company.state,
          lender.company.zipCode,
        ]
          .filter(Boolean)
          .join(', ')
      );
      const streetNameWithNumber = lender?.company?.streetName
        ? lender.company.streetName.includes(lender.company.streetNumber)
          ? lender.company.streetName
          : `${lender.company.streetName} ${lender.company.streetNumber}`
        : lender.company.streetName || '';

      this.onFieldChange('streetName', streetNameWithNumber);
      this.onFieldChange('city', lender.company.city);
      this.onFieldChange('state', lender.company.state);
      this.onFieldChange('streetNumber', lender.company.streetNumber);
      this.onFieldChange('zipCode', lender.company.zipCode);
      this.onFieldChange('phone', lender.company.phone || '');
      this.form.fields.products.value = this.parseProducts(lender.products);

      this.selectedProducts = this.splitSemicolonSeparatedValues(
        lender.products
      );
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while retrieving company information.',
      });
    }

    this.isReady = true;
  }

  async loadDataToChangeRM(id) {
    try {
      await this.salesforceLenderStore.fetchLenderById(id);

      const lender = this.salesforceLenderStore.salesForceLender;
      this.loadForm(lender);
      this.onFieldChange('companyName', lender.company.name || '')
      this.onFieldChange('email', lender.company.email || '')
      this.onFieldChange('einNumber', lender.company.einNumber || '')
      this.onFieldChange('entityType', lender.company.entityType)
      this.onFieldChange('primaryRelationshipManager', { label: lender.primaryRelationshipManagerName, value: lender.primaryRelationshipManagerEmail || '' })
      this.onFieldChange('secondaryRelationshipManager', { label: lender.secondaryRelationshipManagerName, value: lender.secondaryRelationshipManagerEmail || '' })
      this.onFieldChange('lenderName', lender.lenderName || '')
      this.onFieldChange('lenderTradeName', lender.lenderTradeName || '')
      this.onFieldChange('preferredInsuranceReviewer', lender.preferredInsuranceReviewer)
      this.onFieldChange('address',
        [
          lender.company.streetNumber,
          lender.company.streetName,
          lender.company.city,
          lender.company.state,
          lender.company.zipCode,
        ]
          .filter(Boolean)
          .join(', ')
      );

      const streetNameWithNumber = lender?.company?.streetName
        ? lender.company.streetName.includes(lender.company.streetNumber)
          ? lender.company.streetName
          : `${lender.company.streetName} ${lender.company.streetNumber}`
        : lender.company.streetName || '';

      this.onFieldChange('streetName', streetNameWithNumber);
      this.onFieldChange('city', lender.company.city);
      this.onFieldChange('state', lender.company.state);
      this.onFieldChange('streetNumber', lender.company.streetNumber);
      this.onFieldChange('zipCode', lender.company.zipCode);
      this.form.fields.products.value = this.parseProducts(lender.products);

      this.selectedProducts = this.splitSemicolonSeparatedValues(
        lender.products
      );
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while retrieving company information.',
      });
    }

    this.isReady = true;
  }

  validateRMorPercentageChanged() {
    const values = this.getFormValues();
    const lender = this.salesforceLenderStore.salesForceLender;

    return (
      lender.primaryRelationshipManagerEmail !== values?.primaryRelationshipManager.value ||
      lender.primaryCommission !== values?.primaryCommission ||
      lender.secondaryRelationshipManagerEmail !== values?.secondaryRelationshipManager.value ||
      lender.secondaryCommission !== values?.secondaryCommission
    );
  }


  *isDuplicateLenderAlias() {
    const values = this.getFormValues();
    const lender = this.salesforceLenderStore.salesForceLender;
    lender.lenderName = values?.lenderName.trim();
    return yield this.salesforceLenderStore.isDuplicateLenderAlias(lender.lenderId, lender.lenderName);
  }

  *getLenderBankInformation() {
    this.resetBankInfoFields();
    const response = yield this.salesforceLenderStore.getBankInformationsForLender();
    if (!!response) {
      this.bankInfo = response;
      this.loadForm(this.bankInfo);
    }
  }

  *updateCommissions() {
    const lender = this.salesforceLenderStore.salesForceLender;
    const response = yield this.salesforceLenderStore.updateCommissions(lender.lenderId);
  }

  *saveSalesforceLenderChanges(isSubmitting) {
    const values = this.getFormValues();

    const lender = this.salesforceLenderStore.salesForceLender;
    const status = isSubmitting && (lender.status === lenderStatus.APPLICATION_IN_PROGRESS || lender.status === lenderStatus.PROSPECTING) ? 'Application Submitted' : lender.status;
    lender.company.address = values.address;
    lender.company.name = values.companyName;
    lender.company.email = values.email;
    lender.company.einNumber = values.einNumber;
    lender.company.entityType = values?.entityType;
    lender.company.streetName = values?.streetName;
    lender.company.streetNumber = values?.streetNumber;
    lender.company.city = values?.city;
    lender.company.state = values?.state;
    lender.company.zipCode = values?.zipCode;
    lender.products = this.getProducts(values?.products);
    lender.nmlsId = values.nmlsId;
    lender.companyDba = values.companyDba;
    lender.fundingDba = values.fundingDba;
    lender.website = values.website;
    lender.linkedinCompanyPage = values.linkedinCompanyPage;
    lender.pledgeOfShares = values.pledgeOfShares;
    lender.operatingState = values.operatingState;
    lender.lenderName = values?.lenderName;
    lender.lenderTradeName = values?.lenderTradeName;
    lender.primaryRelationshipManagerName = values?.primaryRelationshipManager.label;
    lender.primaryRelationshipManagerEmail = values?.primaryRelationshipManager.value;
    lender.primaryCommission = values?.primaryCommission;
    lender.secondaryRelationshipManagerName = values?.secondaryRelationshipManager.label;
    lender.secondaryRelationshipManagerEmail = values?.secondaryRelationshipManager.value;
    lender.secondaryCommission = values?.secondaryCommission;
    lender.inherited = values?.inherited;
    lender.preferredInsuranceReviewer = values.preferredInsuranceReviewer;
    lender.company.phone = values.phone;
    lender.highValueClient = values?.highValueClient;

    yield this.salesforceLenderStore.updateLenderFields({ ...lender, status });

    if (isSubmitting) {
      this.salesforceLenderStore.fetchSalesforceLender();
    }
    this.form.fields.products.value = this.parseProducts(lender.products);
  }

*saveHighValueClientTPO() {
    try {
      const values = this.getFormValues();
      const currentLender = this.salesforceLenderStore.salesForceLender;
      const newLender = { ...currentLender, highValueClient: values.highValueClient };

      console.log(newLender);
      yield this.salesforceLenderStore.updateLenderFields(newLender);
    }catch(e){
        console.error(e)
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving high value TPO value.',
        });
    }
  }

  private getProducts(values) {
    if (values && values.length > 0) {
      if (values[0].label) {
        return values.map(item => item.value).join(';');
      } else {
        return values;
      }
    }
  }

  private parseProducts(productsString) {
    if (productsString) {
      const productValues = productsString.split(';').map(value => ({
        label: value,
        value: value,
      }));
      return productValues;
    } else {
      return;
    }
  }

  private splitSemicolonSeparatedValues(valuesString) {
    // Splits strings like "A;B;C" into objects like { A:true, B:true, C:true } for easier checkbox handling
    return valuesString ? valuesString
      .split(';')
      .map(item => ({ [item]: true }))
      .reduce((a, b) => ({ ...a, ...b })) : {};
  }

  private joinSemicolonSeparatedValues(object) {
    // Joins objects like { A:true, B:false, C:true } into strings like "A;B;C"
    return Object.keys(object)
      .filter(item => object[item])
      .join(';');
  }

  saveAddressField(address: string, addressDetails, geometryDetails) {
    const parsedAddress = parseAddress(
      address,
      addressDetails,
      geometryDetails
    );

    this.onFieldChange('address', address);

    this.coordinates = {
      lat: parsedAddress.latitude,
      lng: parsedAddress.longitude,
    };

    this.onFieldChange('streetName', parsedAddress.street);
    this.onFieldChange('city', parsedAddress.city);
    this.onFieldChange('state', parsedAddress.state);
    this.onFieldChange('streetNumber', parsedAddress.street_number);
    this.onFieldChange('zipCode', parsedAddress.zip);
  }

  resetBankInfoFields() {
    this.onFieldChange('accountNumber', '');
    this.onFieldChange('accountName', '');
    this.onFieldChange('routingNumber', '');
    this.onFieldChange('bankName', '');
    this.onFieldChange('newAccountNumber', '');
    this.onFieldChange('newAccountName', '');
    this.onFieldChange('newRoutingNumber', '');
    this.onFieldChange('newBankName', '');
  }

  loadStepperModalWithValuesFromDB() {
    const values = this.getFormValues();
    this.onFieldChange('tamariskAppraisalModal', values.tamariskAppraisal);
    this.onFieldChange('tamariskDefaultOptionModal', values.tamariskDefaultOption);
    this.onFieldChange('tamariskSendInvoiceEmailModal', values.tamariskSendInvoiceEmail);
  }
}
