import { GlobalStore } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, observable } from "mobx";
import { getUniqueId } from "@roc/feature-utils";
import { InsuranceInformationBaseStore } from "./insuranceInformationBaseStore";
import { FloodZoneStore } from "./floodZoneStore";
import { QUOTE_STATUS } from "../utils";

export abstract class InsuranceInformationReviewBaseStore {
  globalStore: GlobalStore;
  insuranceInformationStore: InsuranceInformationBaseStore;
  floodZoneStore: FloodZoneStore;
  insuranceReviewData: any[];
  quotes: any[];
  quotesOptions: any[];
  selectedQuote: string;
  insuranceQuoteDataId: number;

  abstract getInsuranceQuotes(loanId);
  abstract getInsuranceReviewData(quoteId: string);
  abstract saveData();
  abstract createResolutions(data: any);
  abstract changeQuoteStatus(quoteId: any, status: string);

  constructor(globalStore, insuranceInformationStore) {
    this.globalStore = globalStore;
    this.insuranceInformationStore = insuranceInformationStore;
    this.floodZoneStore = new FloodZoneStore(globalStore);


    this.setDefaults();
    makeObservable(this, {
      quotes: observable,
      quotesOptions: observable,
      insuranceReviewData: observable,
      selectedQuote: observable,
      getInsuranceReviewData: flow,
      setSelectedQuote: action,
      handleItemChange: action,
      getInsuranceQuotes: flow,
      saveDataAndClose: flow,
      saveData: flow,
      numberOfResolutionsSelected: computed,
      selectedQuotesData: computed,
      setReviewComplete: flow,
      requestResolutions: flow,
      createResolutions: flow,
      changeQuoteStatus: flow
    });
  }

  setDefaults() {
    this.insuranceReviewData = [];
    this.quotes = [];
    this.quotesOptions = [];
    this.selectedQuote = null;
  }

  setSelectedQuote(value) {
    this.selectedQuote = value;
  }

  normalizeAutomatedReviewItems(data) {
    return data?.map(section => ({
      ...section,
      items: section.items?.map(item => (
        { ...item, uniqueId: getUniqueId() }
      ))
    })).sort((a, b) => a.sectionName?.localeCompare(b.sectionName));
  }

  handleItemChange(mapping, uniqueId, sectionName, itemDisplayName, value) {
    let newValue = value;
    switch (mapping) {
      case 'sendForRevision':
        newValue = !newValue;
        break;
      case 'needsRevisionFlag':
        newValue = !newValue;
        break;
    }

    this.insuranceReviewData.map(section => (section.items
      .filter(item => item.uniqueId === uniqueId)
      .map(item => item[mapping] = newValue)
    ));
  }

  get numberOfResolutionsSelected() {
    let counter = 0;
    this.insuranceReviewData.map(section => (
      section.items
        .filter(item => item.sendForRevision)
        .reduce((acc, item) => counter += 1, 0)
    ));
    return counter;
  }

  get selectedQuotesData() {
    return this.quotes.filter(q => q.insuranceQuoteDetailId == this.selectedQuote);
  }

  *saveDataAndClose() {
    try {
      yield this.saveData();
      window.parent.postMessage('saved-success', '*');
    } catch (e) {
      console.error('Error on saveDataAndClose', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Oops! Something went wrong while saving your info.',
      });
    }
  }


  getInsuranceReviewValue(sectionName: string, itemName: string) {
    return this.insuranceReviewData.find(section => section?.sectionName == sectionName)?.items?.find(item => item?.itemDisplayName == itemName)?.data;
  }

  getSelectedQuote() {
    return this.quotes?.find(quote => quote.insuranceQuoteDetailId == this.selectedQuote);
  }

  *setReviewComplete() {
    const selectedQuote = this.getSelectedQuote();
    this.fillOutQuoteWithReviewData(selectedQuote);
    this.insuranceInformationStore.insuranceQuoteFormStore.loadForm(selectedQuote);
    if (this.insuranceInformationStore.validateQuote(selectedQuote)) {
      yield this.saveData();
      yield this.changeQuoteStatus(selectedQuote.insuranceQuoteDetailId, QUOTE_STATUS.APPROVED);
      yield this.insuranceInformationStore.saveInsuranceQuoteDetails(selectedQuote);
      yield this.getInsuranceQuotes(this.insuranceInformationStore.loanId);
      this.setSelectedQuote(selectedQuote?.insuranceQuoteDetailId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: `${selectedQuote?.quoteShowId} has been approved`
      });
    }
  }

  *setReviewDeclined() {
    // const selectedQuote = this.quotes?.find(quote => quote.insuranceQuoteDetailId == this.selectedQuote);
    // yield this.saveData();
    // yield this.changeQuoteStatus(selectedQuote.insuranceQuoteDetailId, QUOTE_STATUS.MORE_INFO_NEEDED);
    // yield this.getInsuranceQuotes(this.insuranceInformationStore.loanId);
    // this.setSelectedQuote(selectedQuote?.insuranceQuoteDetailId);
    // this.globalStore.notificationStore.showSuccessNotification({
    //   message: `${selectedQuote?.quoteShowId} has been declined`
    // });
  }

  fillOutQuoteWithReviewData(selectedQuote: any) {
    const insuranceAgentName = this.getInsuranceReviewValue('Agency', 'Name');
    const insuranceAgentEmail = this.getInsuranceReviewValue('Agency', 'Email');
    const insuranceAgentPhone = this.getInsuranceReviewValue('Agency', 'Phone');
    const insuranceAgentAddress = this.getInsuranceReviewValue('Agency', 'Address');

    const carrier = this.getInsuranceReviewValue('Carrier', 'Name');
    const naic = this.getInsuranceReviewValue('Carrier', 'NAIC Number');

    const policy = this.getInsuranceReviewValue('Policy', 'Policy Number');
    const coverageEffectiveDate = this.getInsuranceReviewValue('Policy', 'Coverage Effective Date');
    const coverageExpirationDate = this.getInsuranceReviewValue('Policy', 'Coverage Expiration Date');

    const insurancePremium = this.getInsuranceReviewValue('Policy', 'Total Premium');

    if (insuranceAgentName) selectedQuote.insuranceAgentName = insuranceAgentName;
    if (insuranceAgentEmail) selectedQuote.insuranceAgentEmail = insuranceAgentEmail;
    if (insuranceAgentPhone) selectedQuote.insuranceAgentPhone = insuranceAgentPhone;
    if (insuranceAgentAddress) selectedQuote.insuranceAgentAddress = insuranceAgentAddress;

    if (carrier) selectedQuote.carrier = carrier;
    if (naic) selectedQuote.carrierNaic = naic;

    if (policy) selectedQuote.policyNumber = policy;
    if (coverageEffectiveDate) selectedQuote.insuranceEffectiveDate = coverageEffectiveDate;
    if (coverageExpirationDate) selectedQuote.insuranceExpiryDate = coverageExpirationDate;
    if (insurancePremium) {
      selectedQuote.insurancePremium = parseFloat(insurancePremium.replace(/[^0-9.]/g, ''));
      selectedQuote.remainingAmount = insurancePremium - selectedQuote.paidAlready;
    }
  }

  *requestResolutions() {
    yield this.createResolutions({ insuranceQuoteDetailId: this.getSelectedQuote().insuranceQuoteDetailId, sections: this.insuranceReviewData });
    yield this.saveDataAndClose();
  }
}