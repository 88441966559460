/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FormStore, GlobalStore } from "@roc/feature-app-core";
import { makeObservable } from "mobx";

const form = {
  fields: {
    address: {
      value: '',
      error: null,
      rule: '',
    },
    propertyStatus: {
      value: '-',
      error: null,
      rule: '',
    },
    saleType: {
      value: '-',
      error: null,
      rule: '',
    },
    originalListDate: {
      value: '-',
      error: null,
      rule: '',
    },
    originalListPrice: {
      value: '0',
      error: null,
      rule: '',
    },
    lastSaleDate: {
      value: '-',
      error: null,
      rule: '',
    },
    lastSalePrice: {
      value: '0',
      error: null,
      rule: '',
    },
    googleLink: {
      value: '',
      error: null,
      rule: '',
    },
    googleMapsLink: {
      value: '',
      error: null,
      rule: '',
    },
    zillowLink: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class PropertyStatusInformationStore extends FormStore {
  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.reset();

    makeObservable(this, {
    })
  }

  setDefaults() {
    this.reset();
  }
}
