import {
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLoanStore } from '@roc/feature-loans-shared';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { LoanPrescreenStatus, REASON_REQUIRED_STATUSES, isPreagreedLoanStatus, sanitizeTestId, statusProperties } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  Button,
  EntityCard,
  InfoTooltip,
  InformationButton,
  Paper,
  SubmitButton,
  TestDataAttribute,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { LoanParticipantsModal } from '@roc/feature-loan-participants';
import { LoanStatus } from '@roc/feature-utils';
import { Refresh, SubdirectoryArrowRight } from '@material-ui/icons';
import { UnderwriterCalendly } from '../calendly/underwriterCalendly';
import { BrokerContactsGrid } from './brokerContactsGrid';
import { noOpComparator } from '@roc/ui/utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LoanDataModal } from '@roc/feature-loans-data';
import { Warning as WarningIcon } from '@material-ui/icons';
import { statusReasonOptions } from '@roc/feature-loans';
import { useEffect } from 'react';

const divider = <Divider variant="middle" component="li" />;

const brokerColumns: AgGridColumnProps[] = [
  {
    field: 'name',
    headerName: 'Full Name',
    minWidth: 100,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    cellRenderer: 'brokerNameRenderer',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 250,
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  {
    field: 'phone',
    headerName: 'Cell Phone',
    minWidth: 150,
    cellRenderer: 'phoneCellRenderer',
    comparator: noOpComparator,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
]

type EditButtonProps = {
  buttonStyle?: any,
  data: any,
  loanStore: any,
  containerStyle?: any
}


const listItem = (label, value, icon = null, customTestId = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <ListItem>
      <ListItemText>
        <Box display="flex">
          {icon && <Box ml={1}>{icon}</Box>}
          <Box flexGrow={1} mr={1}>
            {label}
          </Box>
          <TestDataAttribute id={testId}>
            <Box textAlign="right">{value}</Box>
          </TestDataAttribute>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const listWithSubItem = (label, value, customTestId = null, button = null) => {
  const testId = sanitizeTestId(customTestId || label);
  return (
    <>
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box flexGrow={1} mr={1}>
              {label}
            </Box>
            {button}
          </Box>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Box display="flex">
            <Box textAlign="right">
              <Box flexGrow={1}>
                <SubdirectoryArrowRight fontSize="small" />
              </Box>
            </Box>
            <TestDataAttribute id={testId}>
              <Box flexGrow={1} mr={1}>
                {value}
              </Box>
            </TestDataAttribute>
          </Box>
        </ListItemText>
      </ListItem>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  card: {
    background: theme.palette.background.default,
    borderColor: darken(theme.palette.background.default, 0.1),
  },
}));

export const LoanSummaryBrokerSection = observer(({ loanData }) => {
  const classes = useStyles();
  const { broker } = loanData;

  return (
    <Paper title="BROKER" className={classes.paper}>
      <Box>
        <List>
          {listItem('Company Name', broker?.company?.name)}
          {divider}
          {listItem('Company Ein Number', broker?.company?.einNumber)}
          {divider}
          <Box mr={2} mb={2} sx={{ marginTop: '15px' }} display="flex" justifyContent="flex-end">
            <InformationButton
              size="small"
              variant="contained"
              color="secondary"
              disabled={broker?.company?.name == undefined}
              onClick={() => console.log('here', broker)}
              testId="brokerContactsInformation"
              informationDialogProps={{
                title: `${broker?.company?.name} Contacts`,
                body: (<LoanSummaryBrokerContacts broker={broker} />),
                closeButtonText: "Close"
              }}
            >
              Contacts
            </InformationButton>
          </Box>
        </List>
      </Box>
    </Paper>
  );
});




export const LoanSummaryBrokerContacts = observer(({ broker }) => {
  return (
    <BrokerContactsGrid columns={brokerColumns} brokerId={broker?.lenderId} />
  );
});

export const LoanSummaryBorrowerEntitySection = observer(({ loanData }) => {
  const classes = useStyles();
  const { borrowerEntity, borrowerNames } = loanData;
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const loanId = loanStore.loanDetails?.loanId;
  const { push } = useHistory();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TestDataAttribute id="entity">
            <EntityCard title="ENTITY" entity={borrowerEntity} />
          </TestDataAttribute>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper title="BORROWERS" style={{ boxShadow: 'none' }}>
            <Box>
              <List>
                {borrowerNames.map((name, i) => {
                  return (
                    <div key={name}>
                      {listItem(null, name, '', 'borrower')}
                      {divider}
                    </div>
                  );
                })}
                <br />
                <Grid>
                  <Grid item>
                    <Box mr={2} mb={2} display="flex" justifyContent="flex-end">
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          push(
                            loanRoutesConfig.loans(loanId.toString()).children
                              .borrowerEntity.url
                          )
                        }
                        testId="moreBorrowerInformation"
                      >
                        More information
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
});

export const LoanSummaryLoanParticipantsSection = observer(({ loanData, loanStore, disableEditButton }) => {
  const classes = useStyles();
  const { loanCoordinator, lenderOriginatorName, loanUnderwriter, loanSeniorUnderwriter, status,
    primarySupport, secondarySupport } = loanData;
  const { loanDetails } = loanStore;

  const calendlyPreferences = loanStore.calendlyPreferences;
  const prefill = calendlyPreferences.calendlyPrefill;
  const showCalendly = calendlyPreferences.showCalendly;

  useEffect(() => {
    loanStore.getCalendlyPreferences(loanDetails?.loanId);
  }, []);

  return (
    <Paper title="LOAN CONTACTS" className={classes.paper}>
      <Box>
        <List>
          {listWithSubItem('Senior Underwriter', loanSeniorUnderwriter?.name, 'seniorUnderwriter',
            <UnderwriterCalendly calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.loanSeniorUnderwriter?.userId]?.calendlyUrl} prefill={prefill} showCalendly={showCalendly} />)}
          {divider}
          {listWithSubItem('Underwriter', loanUnderwriter?.name, 'underwriter',
            <UnderwriterCalendly calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.loanUnderwriter?.userId]?.calendlyUrl} prefill={prefill} showCalendly={showCalendly} />
          )}
          {divider}
          {listWithSubItem('Coordinator', loanCoordinator?.name, 'loanCoordinator',
            <UnderwriterCalendly calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.loanCoordinator?.userId]?.calendlyUrl} prefill={prefill} showCalendly={showCalendly} />
          )}
          {divider}
          {listWithSubItem('Originator', lenderOriginatorName ? `${lenderOriginatorName}` : 'None', 'lenderOriginatorName')}
          {divider}
          {listWithSubItem('Primary Originator Support', primarySupport ? `${primarySupport}` : 'None', 'primarySupportName',
            <UnderwriterCalendly calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.primaryOriginationSupport?.userId]?.calendlyUrl} prefill={prefill} showCalendly={showCalendly} />
          )}
          {divider}
          {listWithSubItem('Secondary Originator Support', secondarySupport ? `${secondarySupport}` : 'None', 'secondarySupportName',
            <UnderwriterCalendly calendlyUrl={calendlyPreferences.calendlyUsers[loanDetails?.secondaryOriginationSupport?.userId]?.calendlyUrl} prefill={prefill} showCalendly={showCalendly} />
          )}
          {divider}
          <br />

          <Box mr={2} display="flex" justifyContent="flex-end">
            <SubmitButton
              hidden={true}
              disabled={status === LoanStatus.PAID_OFF || disableEditButton}
              size="small"
              variant="contained"
              color="secondary"
              testId="editLoanLenderParticipants"
              onCancelClick={() => loanStore.loanParticipantsStore.setParticipants(loanData)}
              onClick={() => loanStore.saveLoanParticipants()}
              submitDialogProps={{
                title: 'Loan Contacts',
                body:
                  (loanStore.getLoanParticipants(),
                    loanStore.loanParticipantsStore.setParticipants(loanData),
                    (
                      <LoanParticipantsModal data={loanData} store={loanStore.loanParticipantsStore} />
                    )),
                okButtonText: 'Submit',
              }}
            >
              Edit
            </SubmitButton>
          </Box>
        </List>
      </Box>
    </Paper>
  )
});

export const LoanPrescreenBanner = observer(({ loanStore, reloadLoan }) => {
  const { loanPrescreenData } = loanStore;
  const { loanDetails } = loanStore;
  const classes = useStyles();

  const getWarning = (status: LoanPrescreenStatus, loanStatus: LoanStatus, fullySubmitted: boolean) => {
    const loanIsPendingStatus = LoanStatus.PENDING === loanStatus;
    if (!fullySubmitted) {
      return (
        <Grid item xs={12}>
          <Alert
            style={{
              backgroundColor: '#00000014',
              color: '#000',
            }}
            icon={false}
          >
            <AlertTitle>
              <Grid container direction='row' alignItems='center' spacing={1}>
                <Grid item>
                  <b>Thank you for your submission. Please click refresh to get the latest status on the loan.</b>
                </Grid>
                <Grid item>
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={() => reloadLoan()}
                    testId='refreshLoan'
                  >
                    <span>Refresh</span>
                    <Refresh fontSize='small' />
                  </Button>
                </Grid>
              </Grid>
            </AlertTitle>
          </Alert>
        </Grid>
      )
    }
    else {
      switch (status) {
        case LoanPrescreenStatus.UNASSIGNED:
          const linkHref = `/${location.pathname.split('/')[1]}/loans/${loanDetails.loanId}/underwriting-documents/background-&-credit-review`;
          return (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                style={{
                  backgroundColor: '#f9f9e8',
                  color: '#000',
                }}
                icon={<WarningIcon style={{ color: '#ffa726' }} />}
              >
                <AlertTitle>
                  <b>Thank you! Your loan will be automatically assigned to our underwriting team once borrower credit and background are received.</b>
                </AlertTitle>
                To check status, please go to <Link href={linkHref}>Background & Credit Review section</Link> and select Borrower Setup.
              </Alert>
            </Grid>
          );
        case LoanPrescreenStatus.DEAL_ROOM:
        default:
          return (
            <Grid item xs={12}>
              <Alert
                severity="info"
              >
                <AlertTitle>
                  <b>Thank you for your loan submission! A member of our underwriting team will conduct a
                    <InfoTooltip
                      arrow
                      placement='top'
                      text='pre-screen'
                      title={
                        <div>
                          <p>A pre-screen is a brief review of the file (the borrower, collateral, exit</p>
                          <p>strategy, overall loan structure, etc.) conducted by the underwriting team</p>
                          <p>to provide you with initial feedback and/or areas of interest or concern.</p>
                        </div>
                      }
                    />
                    and provide initial feedback shortly.
                  </b>
                </AlertTitle>
              </Alert>
            </Grid>
          );
      };
    }
  };

  if ((loanDetails?.status === LoanStatus.PENDING || loanDetails?.status === LoanStatus.PENDING_SUBMISSION)) {
    return (getWarning(loanPrescreenData?.prescreenStatus, loanDetails?.status, loanDetails?.fullySubmitted));
  } else {
    return <></>;
  }
});

export const LoanStatusChangeReasonBanner = observer(({ loan }) => {
  const { status, statusChangeReason } = loan;
  const showStatusChangeReason = REASON_REQUIRED_STATUSES.includes(status);

  if (!showStatusChangeReason) {
    return <></>;
  }

  let statusChangeReasonLabel = statusChangeReason;

  if (showStatusChangeReason) {
    const option = statusReasonOptions.find(opt => opt.value === statusChangeReason);

    if (option) {
      statusChangeReasonLabel = option.label;
    }
  }

  return (
    <Grid item xs={12}>
      <Alert
        severity="warning"
        icon={<WarningIcon />}
      >
        <AlertTitle>
          <b>{statusProperties[status]?.name || 'Status'} Reason</b>
        </AlertTitle>
        {statusChangeReasonLabel}
      </Alert>
    </Grid>
  );
})

export const EditLoanButton = observer(({ loanStore, data, buttonStyle, containerStyle }: EditButtonProps) => {
  return (
    <Box className={containerStyle} mr={2} display="flex" justifyContent="flex-end">
      <SubmitButton
        hidden={true}
        size="small"
        variant="contained"
        className={buttonStyle}
        color="primary"
        testId="editLoanData"
        disabled={!isPreagreedLoanStatus(data.status)}
        onClick={() => loanStore.loansDataFormStore.saveData(() => loanStore.refreshLoanDetails())}
        submitDialogProps={{
          title: 'Edit Fields',
          body:
            (
              loanStore.loansDataFormStore.setValues(data),
              (
                <LoanDataModal data={data} store={loanStore.loansDataFormStore} />
              )),
          okButtonText: 'Submit',
          submitValidation: () => {
            loanStore.loansDataFormStore.validateForm(data);
            return loanStore.loansDataFormStore.isFormValid;
          },
        }}
      >
        Edit Fees
      </SubmitButton>
    </Box>
  )
});