import { useBaseStore } from '@roc/feature-app-core';
import { getDashboardRoutes, getDashboardRoutesConfig } from '../routes/dashboardRoutes';
import { getExternalAgentRoutes } from '../routes/externalAgentRoutes';
import { getLoanRoutesConfig } from '@roc/feature-loans-routes-config';


export const useExternalAgentRoutes = () => {
  const { globalStore } = useBaseStore();
  const { routeBasePath, routeBaseUrl } = globalStore;
  return {
    titleAgentRoutes: getExternalAgentRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    dashboardRoutes: getDashboardRoutes(
      routeBasePath,
      routeBaseUrl
    ),
    dashboardRoutesConfig: getDashboardRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
    titleAgentLoanRoutesConfig: getLoanRoutesConfig(
      routeBasePath,
      routeBaseUrl
    ),
  };
};
