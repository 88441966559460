import React from "react";
import { Box, Card, Grid } from "@material-ui/core";
import SubjectPropertyInformationCardHeader from "./subjectPropertyInformationCardHeader";
import { PicturesCarousel } from "./picturesCarousel";
import { PropertyInformationForm } from "./propertyInformationForm";
import { PropertyStatusInformation } from "./propertyStatusInformation";
import { observer } from "mobx-react";
import { InternalValuationReviewStore } from "../stores/internalValuationReviewStore";

interface SubjectPropertyInformationCardProps {
  internalValuationReviewStore: InternalValuationReviewStore;
  generateReport: () => void;
}

export const SubjectPropertyInformationCard = observer(({ internalValuationReviewStore, generateReport }: SubjectPropertyInformationCardProps) => {

  const { propertyInformationFormStore, propertyStatusInformationStore } = internalValuationReviewStore.subjectPropertyInformationStore;

  const readOnly = internalValuationReviewStore.readOnly;

  return (
    <Card style={{ border: "1px solid #BABEC5", borderRadius: "14px" }}>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubjectPropertyInformationCardHeader internalValuationReviewStore={internalValuationReviewStore} generateReport={generateReport} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <PicturesCarousel propertyInformationFormStore={propertyInformationFormStore} />
              </Grid>
              <Grid item xs={7}>
                <PropertyInformationForm propertyInformationFormStore={propertyInformationFormStore} readOnly={readOnly} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PropertyStatusInformation propertyStatusInformationStore={propertyStatusInformationStore} />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
});
