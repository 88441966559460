import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, PDFPreview } from '@roc/ui';
import { observer } from 'mobx-react';
import { EditTaskDialog } from './editTask/editTaskDialog';
import { LoansTaskListView } from './loansTaskListView';
import { LoansTasksToolbarFilters } from './loansTasksToolbarFilters';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
  }
}))

export const LoansTasksComponent = observer(() => {
  const classes = useStyles();
  const { generalTasksStore } = useGeneralTasksStore();
  const { loanId } = generalTasksStore;
  const { documentStore } = useDocumentStore();

  const onClose = () => {
    generalTasksStore.setLoanId(null);
    generalTasksStore.applyFilters();
  }

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box pb={2}>
          <Paper className={classes.paper}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <LoansTasksToolbarFilters />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box flex={1}>
          <Paper className={classes.paper}>
            <LoansTaskListView />
          </Paper>
        </Box>
      </Box>
      <EditTaskDialog />
      {loanId &&
        <>
          <DocumentForms
            loanId={loanId?.toString()}
            onClose={() => onClose()}
          />
          <PDFPreview
            open={documentStore.documentPreview != null}
            title={documentStore.documentPreview?.title}
            data={documentStore.documentPreview?.data}
            headers={documentStore.documentPreview?.headers}
            onClose={() => {
              onClose();
              documentStore.closeDocumentPreview();
            }}
          />
        </>
      }
    </>
  );
});
