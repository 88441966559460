import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu
} from '@material-ui/core';
import { Cancel, CloudDownload, Launch, Message, MoreVert, Publish } from '@material-ui/icons';
import { DocumentName, DocumentStatus, useDocumentStore } from '@roc/feature-documents';
import { useScopeOfWorkV2Store } from '@roc/feature-sow-shared/hooks';
import { DRAWS, formatDate, isEmptyString } from '@roc/feature-utils';
import {
  AgGridColumnProps,
  ConfirmDialog,
  DataGrid,
  MenuItem,
  StandardDialog,
  StatusChip,
  StatusType
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import CommentDialog from '../../common/commentDialog';
import { useDrawsStore } from '../../hooks';

const noOpComparator = () => 0;

const revisionColumns: AgGridColumnProps[] = [
  {
    field: 'taskName',
    headerName: 'Document Name',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'collateralAddress',
    headerName: 'Property Address',
    minWidth: 100,
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'lastUpdateDate',
    headerName: 'Update Date',
    minWidth: 100,
    cellRenderer: 'dateCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    cellRenderer: 'statusCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'taskInformation',
    headerName: 'Rejection Reason',
    minWidth: 100,
    cellRenderer: 'rejectionCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    cellRenderer: 'actionsCellRenderer',
    comparator: noOpComparator,
    sortable: false,
  },
];

export const SOWRevisionGrid = observer(
  ({ loanId, revisionList }: { loanId: string, revisionList: any[] }) => {
    const { drawStore, scopeOfWorkRevisionStore } = useDrawsStore();
    const { documentStore } = useDocumentStore();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [revisionMenuOpen, setRevisionMenuOpen] = useState(false);
    const [isOpenRejectionModal, setIsOpenRejectionModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const inputRef = useRef(null);
    const onUpload = useCallback(
      doc => {
        inputRef.current.click();
      },
      [inputRef]
    );

    const revisionFrameworkComponents = {
      dateCellRenderer: ({ value }) => formatDate(value, 'MM/dd/yyyy') ?? null,
      statusCellRenderer: ({ value }) =>
        value ? (
          <StatusChip
            statusType={StatusType.DOCUMENT}
            label={value?.toUpperCase()}
            size="small"
          />
        ) : null,
      actionsCellRenderer: params => {
        return (
          <IconButton onClick={e => openRevisionMenu(e, params.node.data)}>
            <MoreVert color="primary" />
          </IconButton>
        );
      },
      rejectionCellRenderer: ({ value }) => {
        const disabled = isEmptyString(value);
        return (
          <IconButton
            disabled={disabled}
            onClick={() => {
              setRejectionReason(value);
              setIsOpenRejectionModal(true);
            }}>
            <Message color={disabled ? 'disabled' : 'primary'} />
          </IconButton>
        );
      },
    };

    const openRevisionMenu = (event: React.MouseEvent<HTMLElement>, record: any) => {
      setSelectedDocument(record);
      setAnchorEl(event.currentTarget);
      setRevisionMenuOpen(true);
    };

    const handleRevisionMenuClose = () => {
      setSelectedDocument(null);
      setAnchorEl(null);
      setRevisionMenuOpen(false);
    };

    const cancelRevision = () => {
      drawStore.cancelRevision(selectedDocument);
      setIsConfirmOpen(false);
      handleRevisionMenuClose();
    };

    if (!revisionList) {
      return <></>;
    };

    return (
      <>
        <input
          accept="*"
          type="file"
          onChange={(event) => {
            documentStore.uploadDocument(event.target.files[0], selectedDocument, DRAWS, () => drawStore.fetchSOWDocuments(loanId));
          }}
          hidden
          ref={inputRef}
        />
        <ConfirmDialog
          open={isConfirmOpen}
          title={`Cancel SOW Revision`}
          body={`Are you sure you want to cancel the SOW revision?\n\nThis Operation cannot be reverted.`}
          handleCancel={() => setIsConfirmOpen(false)}
          handleClose={() => setIsConfirmOpen(false)}
          handleConfirm={cancelRevision}
          confirmButtonText={'Confirm'}
        />
        <DataGrid
          columns={revisionColumns}
          rows={revisionList}
          frameworkComponents={revisionFrameworkComponents}
          domLayout="autoHeight"
        />
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={revisionMenuOpen}
          onClose={handleRevisionMenuClose}
        >
          {selectedDocument?.taskName?.startsWith(DocumentName.REVISED_SOW_FORM + ' - ') &&
            <div>
              <MenuItem
                testId={`view-revision-document`}
                onClick={() => scopeOfWorkRevisionStore.fetchSOWRevision(selectedDocument)}
                disabled={selectedDocument?.status !== DocumentStatus.PENDING}
              >
                <ListItemIcon>
                  <Launch fontSize="small" />
                </ListItemIcon>
                <ListItemText>Open Form</ListItemText>
              </MenuItem>
            </div>
          }
          {selectedDocument?.taskName?.startsWith(DocumentName.REVISED_SOW + ' - ') &&
            <div>
              <MenuItem
                testId={`download-revision-document`}
                disabled={!selectedDocument.dropboxPath}
                onClick={() => documentStore.downloadDocument(selectedDocument)}
              >
                <ListItemIcon>
                  <CloudDownload fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download Document</ListItemText>
              </MenuItem>
              <MenuItem
                testId={`upload-revision-document`}
                onClick={onUpload}
                disabled={selectedDocument?.status !== DocumentStatus.PENDING}
              >
                <ListItemIcon>
                  <Publish fontSize="small" />
                </ListItemIcon>
                <ListItemText>Upload Document</ListItemText>
              </MenuItem>
            </div>
          }
          {(selectedDocument?.status === DocumentStatus.PENDING) &&
            <div>
              <MenuItem
                testId={`cancel-revision-request`}
                onClick={() => setIsConfirmOpen(true)}
              >
                <ListItemIcon>
                  <Cancel fontSize="small" />
                </ListItemIcon>
                <ListItemText>Cancel Revision</ListItemText>
              </MenuItem>
            </div>
          }
        </Menu>
        <StandardDialog
          open={isOpenRejectionModal}
          title="Rejection Reason"
          maxWidth="sm"
          handleClose={() => {
            setIsOpenRejectionModal(false);
            setRejectionReason('');
          }}
          dialogContent={
            <CommentDialog
              onClose={() => {
                setIsOpenRejectionModal(false);
                setRejectionReason('');
              }}
              subTitle={"Reviewer's Comment"}
              comment={rejectionReason}
            />
          }
        />
      </>
    );
  }
);