import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { DocumentForms, useDocumentStore } from '@roc/feature-documents';
import { useLoanStore } from '@roc/feature-loans';
import {
  CLOSING,
  flatten,
  LoanProcess,
  LoanStatus,
} from '@roc/feature-utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CustomCard } from '../customCard';
import { TaskTodoCardCompponent } from './todoTaskCard';
import { isPendingTodoStatus } from '../../utils/todoUtils';
import { Button } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { useHistory } from 'react-router';
import { colors } from '../../utils/todoConstants';
import { useBaseStore } from '@roc/feature-app-core';
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: '4px 10px',
    fontSize: '15px',
    fontWeight: 700,
    marginRight: '10px',
  },
  borderLinearProgress: {
    height: '18px',
    borderRadius: 20,
    [`&.MuiLinearProgress-root`]: {
      backgroundColor: colors.brightGray,
    },
    [`& .MuiLinearProgress-bar`]: {
      borderRadius: 20,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const LoanSummaryTodosTasksCard = observer(() => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();
  const { documentStore } = useDocumentStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { push } = useHistory();
  const { loanDetails } = loanStore;
  const { loanTodosStore } = documentStore;
  const currentForm = documentStore.documentFormStore.currentForm;
  const loanId = loanDetails.loanId;

  const showUnderwritingTodos = [
    LoanStatus.PENDING_SUBMISSION,
    LoanStatus.PENDING,
    LoanStatus.IN_UNDERWRITING,
  ].includes(loanDetails?.status);

  const showClosingTodos = [
    LoanStatus.PENDING_DECISION,
    LoanStatus.AGREED,
  ].includes(loanDetails?.status);

  useEffect(() => {
    if (showUnderwritingTodos) {
      documentStore.loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    } else if (showClosingTodos) {
      documentStore.fetchProcessDocuments(loanId, CLOSING);
    }
  }, [loanId]);

  const todos =
    documentStore.loanTodosStore.todosByLoanProcess[LoanProcess.UNDERWRITING] ?? [];
  const { globalStore } = useBaseStore();


  useEffect(() => {
    if (!currentForm && todos.length > 0) {
      loanTodosStore.fetchTodos(loanId, LoanProcess.UNDERWRITING);
    }
  }, [loanId, currentForm]);

  let pendingTodos = [];
  let closingDocumentsCount = 0;

  if (showUnderwritingTodos) {
    pendingTodos =
      documentStore.loanTodosStore.todosByLoanProcess[
      LoanProcess.UNDERWRITING
      ] ?? [];
  } else if (showClosingTodos) {
    let documents = documentStore.closingDocuments;
    closingDocumentsCount = flatten(Object.values(documents)).length;
    pendingTodos = flatten(Object.values(documents)).map(doc =>
      documentStore.loanTodosStore.mapDocumentToTodo(doc, LoanProcess.CLOSING)
    );
  }

  pendingTodos = pendingTodos.filter(t => isPendingTodoStatus(t.status) || t.document?.unreadCount > 0);

  const pendingSubtasksTodos = pendingTodos;

  const hideTodosCard =
    [
      LoanStatus.PAID_OFF,
      LoanStatus.PAID_OFF_BUYOUT,
      LoanStatus.DECLINED,
      LoanStatus.RETRACTED,
      LoanStatus.ON_HOLD,
    ].includes(loanDetails?.status);

  const isTodosEmpty = pendingTodos.length == 0


  return (
    <>
      {!hideTodosCard && (
        <CustomCard paperNoShadow paperStyle={{ padding: '0px 8px 24px 8px' }} title={'To-Dos'}
          cardHeaderProps={{
            action: (
              <Box pt={1}>
                <Button className={classes.button} testId={'seeAllTodos'} unsetTextTransfrom unsetLetterSpacing onClick={() => {
                  if (showUnderwritingTodos) {
                    push(loanRoutesConfig.loans(loanId).children.underwriting.url)
                  } else {
                    push(loanRoutesConfig.loans(loanId).children.closing.url)
                  }
                }}>
                  VIEW ALL
                </Button>
              </Box>
            ),
          }}
          cardContentProps={{
            style: {
              overflow: 'auto'
            }
          }}>
          <Box style={{
            padding: '4px 0 10px 0px'
          }}>
            <Typography style={{ marginLeft: '4px' }}>{`${pendingTodos.length} To-Do`}</Typography>
            <LinearProgress
              variant="determinate"
              value={showUnderwritingTodos ?
                (pendingTodos.length / loanTodosStore.todosByLoanProcess[LoanProcess.UNDERWRITING]?.length * 100) :
                (pendingTodos.length / closingDocumentsCount * 100)}
              className={classes.borderLinearProgress} />
          </Box>
          <Box
            style={{
              position: 'relative',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {isTodosEmpty ? (
              <Box pt={3}>
                <Alert severity="success">
                  You don't have any pending to-dos.
                </Alert>
              </Box>
            ) : (
            <TaskTodoCardCompponent
              todos={pendingSubtasksTodos}
              loanProcess={
                showUnderwritingTodos
                  ? LoanProcess.UNDERWRITING
                  : LoanProcess.CLOSING
              }
              showSectionActions={globalStore.isInternalPortal}
              showFileName={globalStore.isInternalPortal}
              showActions={true}
              isSummaryView={true}
            />
            )}
          </Box>
        </CustomCard>
      )}
      <DocumentForms loanId={loanDetails.loanId} />
    </>
  );
});
