import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  CurrencyField,
  DateField,
  SelectField,
  RadioField,
  yesNoOptions,
  SubmitButton,
  TextField,
  NumberFormat,
  Slider,
  FileUpload,
  FileUploadArea,
  createTextField
} from '@roc/ui';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { isNotBlank, OTHER, CASHOUT_REFINACE, PURCHASE, RATE_TERM_REFINANCE, REFINANCE, requiredLicenseStates, Roles, isNil, roleOptions } from '@roc/client-portal-shared/utils';
import { format, isValid } from 'date-fns';
import { Add } from '@material-ui/icons';
import CompanyForm from '../../components/companyForm';
import { InputAdornment } from '@material-ui/core';
import {
  LoanOriginator,
  PreferredClosingAttorney,
  PreferredInsurance,
  PreferredTitleCompany,
  RequestedClosingDate,
  SubmitAppraisalWithTamarisk,
  FastTrackLoan,
  FacoParticipants,
  LenderParticipants,
  AppraisalPromotion
} from '../../components/formComponents';
import { useStore } from '@roc/client-portal-shared/hooks';
import { GridContainer } from '../../components/formComponents';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useUserStore } from '@roc/feature-app-core';
import { getMultipartFile } from '../../utils/fileUtils';
import { StateLicenseUpload } from '../../components/formComponents';
import { normalizeState } from '@roc/client-portal-shared/utils';
import { useLoanCommissionStore } from 'libs/feature-loan-summary/src/loanSummary/hooks/useCommissionStore';

import { nonReferralFeeStates } from '@roc/client-portal-shared/utils';

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(5.5),
      width: '95%',
    },
    totalAmountCard: {
      marginTop: theme.spacing(4),
    },
    button: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);
const loanPurposePurchase = [
  { value: PURCHASE, label: 'Purchase' },
];

const loanPurposeRefinance = [
  { value: RATE_TERM_REFINANCE, label: 'Rate Term Refinance' },
  { value: CASHOUT_REFINACE, label: 'Cash-Out Refinance' },
];

const percentageMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const posibleDurations = [
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '18 months',
    value: 18,
  },
];

const accrualMethods = [
  {
    value: 'Full Boat',
    label: 'Full Boat',
  },
  {
    value: 'As disbursed',
    label: 'As disbursed',
  },
];

const interestReserveOptions = [
  {
    value: 0,
    label: 'None',
  },
  {
    value: 1,
    label: '1 month',
  },
  {
    value: 2,
    label: '2 months',
  },
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 4,
    label: '4 months',
  },
  {
    value: 5,
    label: '5 months',
  },
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 7,
    label: '7 months',
  },
  {
    value: 8,
    label: '8 months',
  },
  {
    value: 9,
    label: '9 months',
  },
  {
    value: 10,
    label: '10 months',
  },
  {
    value: 11,
    label: '11 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 13,
    label: '13 months',
  },
  {
    value: 14,
    label: '14 months',
  },
  {
    value: 15,
    label: '15 months',
  },
  {
    value: 16,
    label: '16 months',
  },
  {
    value: 17,
    label: '17 months',
  },
  {
    value: 18,
    label: '18 months',
  },
];

const rateOptions = [
  {
    value: 9.99,
    label: '9.99%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 10.25,
    label: '10.25%',
  },
  {
    value: 10.5,
    label: '10.5%',
  },
  {
    value: 10.75,
    label: '10.75%',
  },
  {
    value: 10.99,
    label: '10.99%',
  },
  {
    value: 11.0,
    label: '11%',
  },
  {
    value: 11.25,
    label: '11.25%',
  },
  {
    value: 11.5,
    label: '11.5%',
  },
  {
    value: 11.75,
    label: '11.75%',
  },
  {
    value: 11.99,
    label: '11.99%',
  },
  {
    value: 12.0,
    label: '12%',
  },
  {
    value: 12.25,
    label: '12.25%',
  },
  {
    value: 12.5,
    label: '12.5%',
  },
  {
    value: 12.75,
    label: '12.75%',
  },
  {
    value: 12.99,
    label: '12.99%',
  },
  {
    value: 13.0,
    label: '13%',
  },
  {
    value: 13.25,
    label: '13.25%',
  },
  {
    value: 13.5,
    label: '13.5%',
  },
  {
    value: 13.75,
    label: '13.75%',
  },
  {
    value: 13.99,
    label: '13.99%',
  },
  {
    value: 14.0,
    label: '14%',
  },
  {
    value: 14.25,
    label: '14.25%',
  },
  {
    value: 14.5,
    label: '14.5%',
  },
  {
    value: 14.75,
    label: '14.75%',
  },
  {
    value: 14.99,
    label: '14.99%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 15.25,
    label: '15.25%',
  },
  {
    value: 15.5,
    label: '15.5%',
  },
  {
    value: 15.75,
    label: '15.75%',
  },
  {
    value: 15.99,
    label: '15.99%',
  },
  {
    value: 16.0,
    label: '16%',
  },
];

const pointFormatOptions = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

const participationOptions = [
  {
    value: 'Through life of loan',
    label: 'Through life of loan',
  },
  {
    value: 'With subsequent buy-out',
    label: 'With subsequent buy-out',
  },
  {
    value: 'No Participation',
    label: 'No Participation',
  },
];

const participationPercetangeOptions = [
  {
    value: 0.0,
    label: '0%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 20.0,
    label: '20%',
  },
  {
    value: 25.0,
    label: '25%',
  },
  {
    value: 30.0,
    label: '30%',
  },
  {
    value: 35.0,
    label: '35%',
  },
  {
    value: 40.0,
    label: '40%',
  },
  {
    value: 45.0,
    label: '45%',
  },
  {
    value: 49.0,
    label: '49%',
  },
];

const pointOptions = [
  {
    value: 0.0,
    label: '0',
  },
  {
    value: 0.25,
    label: '0.25',
  },
  {
    value: 0.5,
    label: '0.5',
  },
  {
    value: 0.75,
    label: '0.75',
  },
  {
    value: 1.0,
    label: '1',
  },
  {
    value: 1.25,
    label: '1.25',
  },
  {
    value: 1.5,
    label: '1.5',
  },
  {
    value: 1.75,
    label: '1.75',
  },
  {
    value: 2.0,
    label: '2',
  },
  {
    value: 2.25,
    label: '2.25',
  },
  {
    value: 2.5,
    label: '2.5',
  },
  {
    value: 2.75,
    label: '2.75',
  },
  {
    value: 3.0,
    label: '3',
  },
  {
    value: 3.25,
    label: '3.25',
  },
  {
    value: 3.5,
    label: '3.5',
  },
  {
    value: 3.75,
    label: '3.75',
  },
  {
    value: 4.0,
    label: '4',
  },
  {
    value: 4.25,
    label: '4.25',
  },
  {
    value: 4.5,
    label: '4.5',
  },
  {
    value: 4.75,
    label: '4.75',
  },
  {
    value: 5.0,
    label: '5',
  },
  {
    value: 5.25,
    label: '5.25',
  },
  {
    value: 5.5,
    label: '5.5',
  },
  {
    value: 5.75,
    label: '5.75',
  },
  {
    value: 6.0,
    label: '6',
  },
];

const LoanAmount = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      testId="loanAmount"
      standaloneLabel
      required
      fullWidth
      label="Loan Amount"
      variant="outlined"
      value={formFields.loanAmount.value}
      errorText={formFields.loanAmount.error}
      error={isNotBlank(formFields.loanAmount.error)}
      decimalScale={0}
      onChange={(name, value) =>
        store.changeLoanAmount('loanAmount', Number(value))
      }
    />
  );
});

const LoanToValue = observer(({ store }) => {
  const formFields = store.form.fields;
  const classes = useStyles();
  return (
    <Slider
      testId="initialLoanToPurchaseSlider"
      standaloneLabel
      label="Loan to Value"
      className={classes.slider}
      value={formFields.loanToValue.value}
      valueLabelDisplay="on"
      valueLabelFormat={value => <div>{value}%</div>}
      onChangeCommitted={(event, value) =>
        store.changeLoanToValue('loanToValue', value)
      }
      marks={percentageMarks}
      step={1}
      min={0}
      max={100}
    />
  );
});

const InterestRate = observer(({ store, options }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Rate'}
      name={'rate'}
      value={formFields.rate.value}
      errorText={formFields.rate.error}
      error={isNotBlank(formFields.rate.error)}
      options={options}
      onChange={value => store.onChange('rate', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="rate"
    />
  );
});

const Points = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <>
      <RadioField
        standaloneLabel
        label="Points"
        value={formFields.pointsFormat.value}
        errorText={formFields.pointsFormat.error}
        error={isNotBlank(formFields.pointsFormat.error)}
        name={'pointsFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('pointsFormat', value);
          store.changePointsPercentage(
            'points',
            formFields.points.value,
            'pointsNumber'
          );
        }}
        fullWidth
        row
        required
        testId="pointsFormat"
      />
      {formFields.pointsFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="pointsPercentage"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.points.value}
          onChange={e =>
            store.changePointsPercentage(
              'points',
              e.target.value,
              'pointsNumber'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.points.error)}
          helperText={formFields.points.error}
          standaloneLabel
          fullWidth
          isAllowed={(values) => {
            const {floatValue} = values;
            return floatValue >= (store?.StabilizedBridgeStore?.floorPoints ?? 1) && floatValue <= (store?.StabilizedBridgeStore?.maxPoints ?? 5);
          }}
        />
      )}
      {formFields.pointsFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.pointsNumber.value}
          errorText={formFields.points.error}
          error={isNotBlank(formFields.points.error)}
          onChange={(name, value) =>
            store.changePointsAmount('pointsNumber', value, 'points')
          }
          fullWidth
          required
          testId="pointsNumber"
        />
      )}
    </>
  );
});

const BrokerPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  const {globalStore} = useStore();

  return (
    <>
      <RadioField
        standaloneLabel
        label={globalStore?.lenderInfo?.isInternal ? "On Hud Broker Fee" : "Broker Points"}
        value={formFields.brokerPointsFormat.value}
        errorText={formFields.brokerPointsFormat.error}
        error={isNotBlank(formFields.brokerPointsFormat.error)}
        name={'brokerPointsFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('brokerPointsFormat', value);
          store.changePointsPercentage(
            'brokerPoints',
            formFields.brokerPoints.value,
            'brokerPointsNumber'
          );
        }}
        fullWidth
        row
        required
        testId="brokerPointsFormat"
      />
      {formFields.brokerPointsFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="brokerPointsPercentage"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.brokerPoints.value}
          onChange={e =>
            store.changePointsPercentage(
              'brokerPoints',
              e.target.value,
              'brokerPointsNumber'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.brokerPoints.error)}
          helperText={formFields.brokerPoints.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.brokerPointsFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.brokerPointsNumber.value}
          errorText={formFields.brokerPoints.error}
          error={isNotBlank(formFields.brokerPoints.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'brokerPointsNumber',
              value,
              'brokerPoints'
            )
          }
          fullWidth
          required
          testId="brokerPointsNumber"
        />
      )}
    </>
  );
});

const ReferralFees = observer(({ store }) => {
  const formFields = store.form.fields;

  return (
    <>
      <RadioField
        standaloneLabel
        label={"Off Hud Broker Fee"}
        value={formFields.referralFeesFormat.value}
        errorText={formFields.referralFeesFormat.error}
        error={isNotBlank(formFields.referralFeesFormat.error)}
        name={'referralFeesFormat'}
        options={pointFormatOptions}
        onChange={value => {
          store.onChange('referralFeesFormat', value);
          store.changePointsPercentage(
            'referralFee',
            formFields.referralFee.value,
            'referralFeesAmount'
          );
        }}
        fullWidth
        row
        required
        testId="referralFeesFormat"
      />
      {formFields.referralFeesFormat.value === pointFormatOptions[0].value && (
        <NumberFormat
          testId="referralFee"
          required
          customInput={TextField}
          variant="outlined"
          value={formFields.referralFee.value}
          onChange={e =>
            store.changePointsPercentage(
              'referralFee',
              e.target.value,
              'referralFeesAmount'
            )
          }
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={isNotBlank(formFields.referralFee.error)}
          helperText={formFields.referralFee.error}
          fullWidth
          standaloneLabel
        />
      )}
      {formFields.referralFeesFormat.value === pointFormatOptions[1].value && (
        <CurrencyField
          variant="outlined"
          standaloneLabel
          value={formFields.referralFeesAmount.value}
          errorText={formFields.referralFee.error}
          error={isNotBlank(formFields.referralFee.error)}
          onChange={(name, value) =>
            store.changePointsAmount(
              'referralFeesAmount',
              value,
              'referralFee'
            )
          }
          fullWidth
          required
          testId="referralFeesNumber"
        />
      )}
    </>
  );
});

const LenderUnderwritingFee = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Underwriting Fee"
      value={formFields.lenderUnderwritingFee.value}
      errorText={formFields.lenderUnderwritingFee.error}
      error={isNotBlank(formFields.lenderUnderwritingFee.error)}
      onChange={(name, value) => store.onChange('lenderUnderwritingFee', Number(value))}
      variant="outlined"
      fullWidth
      required
      testId="lenderUnderwritingFee"
    />
  );
});

const LenderProcessingFee = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <CurrencyField
      standaloneLabel
      label="Lender Processing Fee"
      value={formFields.lenderProcessingFee.value}
      errorText={formFields.lenderProcessingFee.error}
      error={isNotBlank(formFields.lenderProcessingFee.error)}
      onChange={(name, value) => store.onChange('lenderProcessingFee', Number(value))}
      variant="outlined"
      fullWidth
      testId="lenderProcessingFee"
    />
  );
});

export const RocPoints = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Capital Provider Points'}
      name={'rocPointsIn'}
      value={formFields.rocPointsIn.value}
      errorText={formFields.rocPointsIn.error}
      error={isNotBlank(formFields.rocPointsIn.error)}
      options={pointOptions}
      onChange={value => store.onChange('rocPointsIn', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="rocPointsIn"
    />
  );
});

const TermDuration = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Term (Duration)'}
      name={'termDuration'}
      value={formFields.duration.value}
      errorText={formFields.duration.error}
      error={isNotBlank(formFields.duration.error)}
      options={posibleDurations}
      onChange={value => {
        store.onChange('duration', value);
        store.StabilizedBridgeStore.fetchBorrowerPointsOptions();
      }}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="termDuration"
    />
  );
});

const InterestAccrualMethod = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Accrual Method'}
      name={'interestAccrualMethod'}
      value={formFields.interestAccrualMethod.value}
      errorText={formFields.interestAccrualMethod.error}
      error={isNotBlank(formFields.interestAccrualMethod.error)}
      options={accrualMethods}
      onChange={value => store.onChange('interestAccrualMethod', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestAccrualMethod"
      disabled
    />
  );
});

const InterestReserve = observer(({ store }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Interest Reserve'}
      name={'interestReserveMonths'}
      value={formFields.interestReserveMonths.value}
      errorText={formFields.interestReserveMonths.error}
      error={isNotBlank(formFields.interestReserveMonths.error)}
      options={interestReserveOptions}
      onChange={value => store.onChange('interestReserveMonths', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="interestReserveMonths"
    />
  );
});

const Participation = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Participation'}
      name={'participation'}
      value={formFields.participation.value}
      errorText={formFields.participation.error}
      error={isNotBlank(formFields.participation.error)}
      options={participationOptions}
      onChange={value => store.onChange('participation', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="participation"
      {...rest}
    />
  );
});

const ParticipationPercentage = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Participation Percentage'}
      name={'origLenderSubordination'}
      value={formFields.origLenderSubordination.value}
      errorText={formFields.origLenderSubordination.error}
      error={isNotBlank(formFields.origLenderSubordination.error)}
      options={participationPercetangeOptions}
      onChange={value => store.onChange('origLenderSubordination', value)}
      variant="outlined"
      fullWidth
      shrink
      required
      testId="origLenderSubordination"
      {...rest}
    />
  );
});

export const LoanPurpose = observer(({ store, disabled }) => {
  const formFields = store.form.fields;
  return (
    <SelectField
      standaloneLabel
      label={'Loan Purpose'}
      name={'loanPurpose'}
      value={disabled ? formFields.loanPurpose.value = 'Purchase' : formFields.loanPurpose.value}
      errorText={formFields.loanPurpose.error}
      error={isNotBlank(formFields.loanPurpose.error)}
      options={disabled ? loanPurposePurchase : loanPurposeRefinance}
      onChange={value => store.onChange('loanPurpose', value)}
      variant="outlined"
      fullWidth
      shrink
      testId="loanPurpose"
      disabled={disabled}
      required
    />
  );
});

function LoanInformation({ ...props }) {
  const { stabilizedBridgeStore, globalStore, lenderDetailsStore } = useStore();
  const {
    loanInformationStore,
    loanTerms,
    canSubmitAppraisalWithTamarisk,
    isAnyPropertyPurchase,
    brokersStore
  } = stabilizedBridgeStore;
  const { form } = loanInformationStore;
  const classes = useStyles();
  const { isFacoOriginator } = loanInformationStore.loanParticipantsStore;
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const { loanCommissionStore } = useLoanCommissionStore();
  const [interestRateOptions, setInterestRateOptions] = useState([]);

  useEffect(() => {
    if (allowLoanPricing) {
      getInterestRateOptions();
    }
    stabilizedBridgeStore.loanInformationStore.makeInitialCalculations();
    loanInformationStore.getLenderAttorneys();
    setUploads(loanInformationStore.licenseUpload);
    loanInformationStore.resetBrokerPointsBasedOnHud(brokersStore.onTheHud);
  }, []);

  useEffect(() => {
    if (uploads.length > 0 && uploads !== loanInformationStore.licenseUpload) {
      loanInformationStore.setLicenseUpload(uploads);
      lenderDetailsStore.lenderDocumentsStore.uploadDocument(`State License - ${stabilizedBridgeStore.properties[0].state}`, getMultipartFile(uploads[0]));
    }
  }, [uploads]);

  useEffect(() => {
    const formFields = form?.fields || {};
    if(!isBrokerUser && !isBorrowerUser) {
      loanTerms.commission = loanCommissionStore?.getBridgeCommission({
        loanAmount: formFields.loanAmount.value,
        rocRate: formFields.rate.value,
        rocPointsIn: formFields.points.value,
        fees: formFields.lenderUnderwritingFee.value + formFields.lenderProcessingFee.value,
        rocPointsOut: 0,
        referralFee: formFields?.referralFee.value,
      });
    }
  }, [form.fields.loanAmount.value, form.fields.rate.value, form.fields.points.value, form.fields.lenderUnderwritingFee.value, form.fields.lenderProcessingFee.value, form.fields.referralFee.value]);

  const getInterestRateOptions = async () => {
    const actualDate = new Date();
    try {
      const response = await stabilizedBridgeStore.getRateFloor(
        {
          "submissionDate": actualDate,
          "lenderName": "",
          "propertyState": "",
          "loanSubtype": ""
        });
      filterRateOptions(response);
      stabilizedBridgeStore.fetchBorrowerPointsOptions();
    } catch (err) {
      console.log(err);
    }
  }

  const filterRateOptions = (rate) => {
    const floorRate = rate.data;
    const options = rateOptions.filter((o) => {
      return o.value >= parseFloat(floorRate);
    })

    const lowest = options.find((o =>
      o.value === parseFloat(floorRate)
    ));

    if (!lowest) {
      options.push({
        label: floorRate + "%",
        value: floorRate
      });
    }

    options.sort((a, b) => a.value - b.value);
    setInterestRateOptions(options);
  }

  const { userStore } = useUserStore();
  const allowLoanPricing = userStore.allowLoanPricing;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const isBrokerUser = userStore.userInformation?.roles?.includes(Roles.BROKER);
  const isBorrowerUser = userStore.userInformation?.roles?.includes(roleOptions.BORROWER.value);

  return (
    <>
      <Typography variant="h4">Loan Details</Typography>
      <br />
      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <LoanPurpose store={loanInformationStore} disabled={isAnyPropertyPurchase} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h4">Loan Economics</Typography>
      <br />
      <Typography variant="body1">
        Let's do some math here with the loan terms and pricing
      </Typography>
      <br />

      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <LoanAmount store={loanInformationStore} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <LoanToValue store={loanInformationStore} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid container spacing={10}>
        {isBrokerUser ? (
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Pricing</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <BrokerPoints store={loanInformationStore} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          allowLoanPricing &&
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Pricing</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <InterestRate
                  store={loanInformationStore}
                  options={
                    interestRateOptions
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Points store={loanInformationStore} />
              </Grid>
              {brokersStore.onTheHud ?
                (<Grid item xs={12}>
                  <BrokerPoints store={loanInformationStore} />
                </Grid>) :
                (<Grid item xs={12}>
                  <ReferralFees store={loanInformationStore} />
                </Grid>)}
              {globalStore.userFeatures?.allowLenderUnderwritingFee &&
                <Grid item xs={12}>
                  <LenderUnderwritingFee store={loanInformationStore} />
                </Grid>
              }
              {globalStore.userFeatures?.allowLenderUnderwritingFee &&
                <Grid item xs={12}>
                  <LenderProcessingFee store={loanInformationStore} />
                </Grid>
              }
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <Typography variant="h4">Interest Mechanics</Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TermDuration store={loanInformationStore} />
            </Grid>
            <Grid item xs={12}>
              <InterestAccrualMethod store={loanInformationStore} />
            </Grid>
            <Grid item xs={12}>
              <InterestReserve store={loanInformationStore} />
            </Grid>
            {!isBrokerUser && (
              <Grid item xs={12}>
                {createTextField({
                  store: loanInformationStore,
                  testId: 'projectDescription',
                  fieldName: 'projectDescription',
                  label: 'Project Description',
                  multiline: true,
                  inputProps: { maxLength: 500, rows: 3 }
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
        {isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
          <Grid item xs={12} md={4}>
            <Card variant="outlined" className={classes.totalAmountCard}>
              <CardContent>
                <Typography align="center" variant="h6" color="primary">
                  Your Commission %
                </Typography>
                <br />
                <Typography align="center" variant="h4">
                  {loanCommissionStore?.commissionData?.commissionPercentage
                    ? formatPercentage(loanCommissionStore.commissionData.commissionPercentage, 4)
                    : '%'}
                </Typography>
                <br />
                <Typography align="center" variant="h6" color="primary">
                  Your Commission Amount
                </Typography>
                <br />
                <Typography align="center" variant="h4">
                  {loanCommissionStore?.commissionData?.commissionAmount
                    ? formatCurrency(loanCommissionStore.commissionData.commissionAmount)
                    : '$'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <br />
      <br />
      {allowLoanPricing &&
        <Grid container spacing={10}>
          <Grid item xs={12} md={5}>
            <Typography variant="h4">Vendor and Closing Options</Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              {(allowLoanPricing && globalStore.userFeatures?.allowParticipation) &&
                <Grid item xs={12}>
                  <Participation
                    store={loanInformationStore}
                  />
                </Grid>
              }
              {(allowLoanPricing && globalStore.userFeatures?.allowParticipation) &&
                <Grid item xs={12}>
                  <ParticipationPercentage
                    store={loanInformationStore}
                  />
                </Grid>
              }
              {(canSubmitAppraisalWithTamarisk &&
                !globalStore.userFeatures?.tamariskAppraisal) && (
                  <Grid item xs={12}>
                    <SubmitAppraisalWithTamarisk store={loanInformationStore} />
                  </Grid>
                )}
              <Grid item xs={12}>
                <PreferredClosingAttorney store={loanInformationStore} />
              </Grid>
              <Grid item xs={12}>
                <RequestedClosingDate store={loanInformationStore} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="h4" color="primary">
              &nbsp;
            </Typography>
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <PreferredInsurance store={loanInformationStore} />
              </Grid>
              {loanTerms.preferredInsurance === OTHER && (
                <Grid item xs={12}>
                  <SubmitButton
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    testId="addOtherInsurance"
                    onClick={() =>
                      loanInformationStore.setOtherInsuranceCompany()
                    }
                    submitDialogProps={{
                      title: 'Insurance',
                      body: (
                        <CompanyForm
                          store={loanInformationStore.otherInsuranceFormStore}
                        />
                      ),
                      okButtonText: 'Confirm',
                      submitValidation: () => {
                        loanInformationStore.otherInsuranceFormStore.runFormValidation();
                        return loanInformationStore.otherInsuranceFormStore.form
                          .meta.isValid;
                      },
                    }}
                  >
                    ADD INFO
                  </SubmitButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <PreferredTitleCompany store={loanInformationStore} />
              </Grid>
              {loanTerms.preferredTitle === OTHER && (
                <Grid item xs={12}>
                  <SubmitButton
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    startIcon={<Add />}
                    testId="addOtherTitle"
                    onClick={() => loanInformationStore.setOtherTitleCompany()}
                    submitDialogProps={{
                      title: 'Title',
                      body: (
                        <CompanyForm
                          store={loanInformationStore.otherTitleFormStore}
                        />
                      ),
                      okButtonText: 'Confirm',
                      submitValidation: () => {
                        loanInformationStore.otherTitleFormStore.runFormValidation();
                        return loanInformationStore.otherTitleFormStore.form.meta
                          .isValid;
                      },
                    }}
                  >
                    ADD INFO
                  </SubmitButton>
                </Grid>
              )}
              {globalStore.userFeatures.fastTrack && isNil(stabilizedBridgeStore.editLoanJson.loanId) &&
                (loanInformationStore.form.fields.preferredTitle.value ||
                  loanInformationStore.form.fields.preferredInsurance.value) && (
                  <Grid item xs={12}>
                    <FastTrackLoan
                      store={loanInformationStore}
                      sendPaymentEmail={() => stabilizedBridgeStore.sendFastTrackPayment()}
                      propertiesQuantity={stabilizedBridgeStore.properties.length}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      }
      <Grid container spacing={10}>
        {isFacoOriginator ? (
          <FacoParticipants xs={12} md={5} store={loanInformationStore} />
        ) : (
          <LenderParticipants
            xs={12}
            md={5}
            store={loanInformationStore}
            originatorFieldName="loanOriginator"
          />
        )}
      </Grid>
      {globalStore.userFeatures?.showFreeAppraisalPromotion &&
        <Grid container spacing={10}>
          <Grid item xs={12} md={5}>
            <Typography variant="h4" style={{ marginBottom: '8px' }}>Promotions</Typography>
            <AppraisalPromotion
              store={loanInformationStore} />
          </Grid>
        </Grid>
      }
      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          {globalStore.lenderInfo?.showTPOLicenseValidation && stabilizedBridgeStore.properties.some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
            !globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) && (
              <StateLicenseUpload store={loanInformationStore} setUploads={setUploads} property={stabilizedBridgeStore.properties[0]} />
            )}
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
}

export default observer(LoanInformation);
