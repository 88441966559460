import { makeObservable, override } from 'mobx';
import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { ScopeOfWorkV2FormBaseStore } from './scopeOfWorkV2FormBaseStore';
import { ScopeOfWorkV2FormService } from '../../../services';
import { ScopeOfWorkV2FormParams } from '@roc/feature-sow-shared/types';
import { CALLBACK_TYPE } from '@roc/feature-sow-shared/constants/scopeOfWorkV2Form';
import { SegmentTrackerEvent, isNil, segmentAnalytics } from '@roc/feature-utils';
import { getMultiPageScreenshotGrid } from '@roc/ui';

export class ScopeOfWorkV2FormStore extends ScopeOfWorkV2FormBaseStore {
  protected scopeOfWorkService: ScopeOfWorkV2FormService;

  private onSaveScopeOfWork: (saved: boolean) => void;
  private onSubmitScopeOfWork: (submitted: boolean) => void;

  onResetSuccesfull: () => void;
  constructor(globalStore: GlobalStore, userStore?: UserStore) {
    super(globalStore, undefined, userStore);
    this.scopeOfWorkService = new ScopeOfWorkV2FormService();
    makeObservable(this, {
      reset: override,
      savePdfOnDropbox: override
    });
    this.initialize();
  }

  initialize() {
    this.setGetScopeOfWorkShareUrl(this.getScopeOfWorkShareUrl);
    this.setGetPublicScopeOfWorkShareUrl(this.getPublicScopeOfWorkShareUrl);
    this.setGetNewScopeOfWork(this.getNewScopeOfWork);
    this.setGetScopeOfWork(this.getScopeOfWork);
    this.setGetScopeOfWorkCategories(this.getCategories);
    this.setSaveScopeOfWork(this.saveScopeOfWork);
    this.setSaveScopeOfWorkDataContent(this.saveScopeOfWorkDataContent);
    this.setSubmitScopeOfWork(this.submitScopeOfWork);
    this.setAfterCall(this.afterCall);
    this.setCopyScopeOfWorkShareUrl(this.copyScopeOfWorkShareUrl);
    this.setExportScopeOfWork(this.exportScopeOfWork);
    this.setMarkSOWAsInReview(this.markSOWAsInReview);
    this.setMarkSOWAsMoreInfoNeeded(this.markSOWAsMoreInfoNeeded);
    this.setRemoveScopeOfWork(this.removeScopeOfWork);
  }

  *savePdfOnDropbox() {
    if (!this.params?.scopeOfWorkId) return;

    this.showScreenshotGrid();
    const pdf = yield getMultiPageScreenshotGrid('Scope of Work', this.scopeOfWorkScreenshotIds, []);
    this.hideScreenshotGrid()
    const response = yield this.scopeOfWorkService.savePdf(pdf, this.params.scopeOfWorkId, this.params.lenderId);
  }

  getSowSubmissionErrors = async(loanId: any, propertyId: any, data: any) => {
    const {
      data: response
    } = await this.scopeOfWorkService.getSowSubmissionErrors(loanId, propertyId, data)
    return response;
  }

  saveScopeOfWorkAuditLogs = async(scopeOfWorkId:any, lenderId: any, data:any, isSubmit: boolean, userName: string) =>{
    const {
      data: response
    } = await this.scopeOfWorkService.saveSowAuditLogs(scopeOfWorkId, lenderId, data, isSubmit, this.username)
    return response;
  }

  getScopeOfWorkAuditLogs = async(scopeOfWorkId:any, lenderId: any) =>{
    const {
      data: response
    } = (await this.scopeOfWorkService.getSowAuditLogs(scopeOfWorkId, lenderId));
    return response;
  }


  splitCategories = async (scopeOfWorkId: string, lenderId: string) => {
    if (!scopeOfWorkId) return;
    const {
      data: response
    } = await this.scopeOfWorkService.getSplitedCategories(scopeOfWorkId)
    return response;
  }

  setOnSaveScopeOfWork(onSaveScopeOfWork) {
    this.onSaveScopeOfWork = onSaveScopeOfWork;
  }

  setOnSubmitScopeOfWork(onSubmitScopeOfWork) {
    this.onSubmitScopeOfWork = onSubmitScopeOfWork;
  }

  getScopeOfWorkShareUrl = async (params: ScopeOfWorkV2FormParams) => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkShareUrl(
      params.scopeOfWorkId
    );
    return response.data;
  };

  getPublicScopeOfWorkShareUrl = async () => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getScopeOfWorkSharePublicUrl();
    return response.data;
  };

  getNewScopeOfWork = async () => {
    const {
      data: response,
    } = await this.scopeOfWorkService.getNewScopeOfWork();
    return JSON.parse(response.data);
  };

  getScopeOfWork = async (params: ScopeOfWorkV2FormParams) => {
    const { data: response } = await this.scopeOfWorkService.getScopeOfWorkById(
      params.scopeOfWorkId
    );
    return JSON.parse(response.data);
  };

  getCategories = async () => {
    const { data: response } = await this.scopeOfWorkService.getCategories();
    return JSON.parse(response.data);
  };

  saveScopeOfWork = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.saveScopeOfWork(
      params.scopeOfWorkId,
      data,
      silent
    );
    return response.data;
  };

  exportScopeOfWork = async (
    data: any,
  ) => {
    const response = await this.scopeOfWorkService.exportScopeOfWork(
      data,
    );
    return response;
  };

  removeScopeOfWork = async (
    data: any,
  ) => {
    const response = await this.scopeOfWorkService.removeScopeOfWork(
      data,
    );
    return response;
  };

  submitScopeOfWork = async (
    params: ScopeOfWorkV2FormParams,
    data: any,
    silent: boolean
  ) => {
    const { data: response } = await this.scopeOfWorkService.submitScopeOfWork(
      params.scopeOfWorkId,
      data,
      silent
    );
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEvent.SOW_SUBMISSION,
      userName: this.userStore?.userInformation?.username,
    });
    return response.data;
  };

  afterCall = (type: CALLBACK_TYPE, responseData: any) => {
    switch (type) {
      case CALLBACK_TYPE.GET_SHARE_URL:
        if (!responseData) {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'An error occurred while getting the Scope of Work share URL',
          });
        }
        break;
      case CALLBACK_TYPE.GET:
        if (!responseData) {
          this.globalStore.notificationStore.showErrorNotification({
            message:
              'An error occurred while getting the Scope of Work information',
          });
        }
        break;
      case CALLBACK_TYPE.SAVE:
        this.handleSaveResponse(responseData);
        break;
      case CALLBACK_TYPE.SUBMIT:
        this.handleSubmitResponse(responseData);
        break;
      default:
        break;
    }
  };

  copyScopeOfWorkShareUrl = (scopeOfWorkShareUrl: string) => {
    navigator.clipboard.writeText(scopeOfWorkShareUrl);
  };

  private handleSaveResponse(saved: boolean) {
    if (isNil(saved)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error when saving this Scope of Work',
      });
    } else {
      if (saved) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Scope of Work saved!',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'This Scope of Work is already submitted',
        });
      }
    }
    if (!this.onSaveScopeOfWork) return;
    this.onSaveScopeOfWork(saved);
  }

  private handleSubmitResponse(responseData: any) {
    if (isNil(responseData)) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error when submitting this Scope of Work',
      });
    } else {
      if (responseData.success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Scope of Work submitted!',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: responseData.responseMessage ? responseData.responseMessage : 'This Scope of Work is already submitted',
        });
      }
    }
    if (!this.onSubmitScopeOfWork || !responseData.success) return;
    this.onSubmitScopeOfWork(responseData);
  }

  reset() {
    super.reset();
  }

}

export default ScopeOfWorkV2FormStore;
