import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BorrowerForm } from './borrowerForm';
import { PersonIcon } from './personIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.4%',
  },
  body: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
  label: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '175%',
    letterSpacing: '0.15px',
  },
}));

export const CoBorrowerInformation = observer(({ store, loanTypeStore, rental = false }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <PersonIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.subTitle}>
            CO-BORROWER INFORMATION
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.body}>
          Please fill out each co-borrower's information.
        </Typography>
      </Grid>
      {store.coBorrowers.map((borrower, index) => (
        <Grid
          item
          xs={12}
          container
          direction="row"
          spacing={2}
          key={`coBorrower${index + 1}`}
        >
          <Grid item xs={6}>
            <Typography variant="h5" className={classes.label}>
              Co-Borrower {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              testId="remove-borrower"
              color="primary"
              variant='outlined'
              onClick={() =>
                loanTypeStore.handleRemoveBorrower(index)
              }
            >
              Remove Co-Borrower
            </Button>
          </Grid>
          <Grid item xs={12}>
            <BorrowerForm
              store={store.coBorrowersFormStores[index]}
              rental={rental}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box pt={2}>
          <Button
            testId="add-borrower"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => loanTypeStore.handleAddCoBorrower()}
          >
            Add another co-borrower
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
});
