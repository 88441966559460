import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from '@roc/client-portal-shared/hooks';
import { isNotBlank } from '@roc/client-portal-shared/utils';
import { AutocompleteAddressField, NumberFormat, MultiSelectField } from '@roc/ui';
import { observer } from 'mobx-react';
import MailIcon from '@material-ui/icons/Mail';
import {
  createCheckboxField,
  createPercentageField,
  createPhoneField,
  createSelectField,
  createTextField,
} from 'libs/ui/src/lib/components/componentBuilder';
import { NextBackButtons } from 'libs/client-portal-shared/src/app/modules/lenderDetails/components/lenderDetailsFormComponents';
import { LenderRMUsers } from 'libs/client-portal-shared/src/app/modules/companyProfile/lenderRelationshipManagers/lendersRMUsers';
import { useEffect } from 'react';
import { residentialProductsOptions } from '@roc/client-portal-shared/modules/lenderDetails';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
}));

const entityTypes = [
  {
    value: 'Corp',
    label: 'Corp',
  },
  {
    value: 'LLC',
    label: 'LLC',
  },
];

const preferredInsuranceOptions = [
  {
    value: 'CMM',
    label: 'CMM',
  },
  {
    value: 'Albert Risk',
    label: 'Albert Risk',
  },
  {
    value: 'Advocate',
    label: 'Advocate',
  },
  {
    value: 'Internal',
    label: 'Internal',
  },
];

interface CompanyDetailsFormProps {
  disabled?: boolean;
  companyProfileStore: any;
}


export const CompanyDetailsForm = observer(({ disabled = false, companyProfileStore }: CompanyDetailsFormProps) => {
  const { lenderDetailsStore, salesforceLenderStore } = companyProfileStore;
  const { userStore } = useStore();
  const { lenderDetailsFormStore } = lenderDetailsStore;
  const userRole = userStore?.userInformation?.roles[0];
  const userEmail = userStore?.userInformation.emailAddress;
  const BACK_OFFICE_LEAD_ROLE = 'backOfficeLead';
  const BACK_OFFICE_ORIGINATOR = 'backOfficeOriginator';
  const handleAddressChange = event => {
    const { value, fullInfo } = event?.target || {};
    const { address_components, geometry } = fullInfo || {};
    lenderDetailsFormStore.saveAddressField(
      value,
      address_components,
      geometry
    );
  };

  const isRelationshipManagersDisabled = (userRole, backOfficeLeadRole) => {
    let pRM = lenderDetailsFormStore.form.fields.primaryRelationshipManager.value.value;
    if (userRole === BACK_OFFICE_ORIGINATOR && pRM === userEmail) {
      return false;
    }
    return userRole !== backOfficeLeadRole;
  }

  const handleRelationshipManagerChange = (field, value) => {
    lenderDetailsFormStore.onFieldChange(field, value)
  }

  useEffect(() => {
    salesforceLenderStore.getRelationshipManagersUsers();
  }, []);

  function createOptionsArray(options) {
    const values = options && options?.length > 0 ? options.map(option => ({
      label: option,
      value: option,
    })) : [];
    return values;
  }

  const formFields = lenderDetailsFormStore.form.fields;

  return (
    <Grid container justifyContent={'flex-start'} spacing={2}>
      <Grid item xs={6}>
        {createTextField({
          label: 'Company Name',
          testId: 'companyName',
          fieldName: 'companyName',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          testId: 'lenderName',
          label: 'Alias',
          fieldName: 'lenderName',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Trade Name',
          testId: 'lenderTradeName',
          fieldName: 'lenderTradeName',
          store: lenderDetailsFormStore,
          disabled: disabled
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Company DBA',
          testId: 'companyDba',
          fieldName: 'companyDba',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Funding DBA',
          testId: 'fundingDba',
          fieldName: 'fundingDba',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createSelectField({
          options: entityTypes,
          label: 'Entity Type',
          testId: 'entityType',
          fieldName: 'entityType',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        <NumberFormat
          customInput={TextField}
          format="##-#######"
          mask="_"
          required={(formFields.einNumber.rule || '').includes('required')}
          variant="outlined"
          label="Tax Id /  EIN #"
          testId="einNumber"
          value={formFields.einNumber.value}
          onChange={e =>
            lenderDetailsFormStore.onFieldChange('einNumber', e.target.value)
          }
          helperText={formFields.einNumber.error}
          error={isNotBlank(formFields.einNumber.error)}
          standaloneLabel
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Email',
          testId: 'companyEmail',
          fieldName: 'email',
          type: 'email',
          store: lenderDetailsFormStore,
          disabled: disabled,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          },
        })}
      </Grid>
      <Grid item xs={6}>
        {createPhoneField({
          label: 'Phone',
          testId: 'phone',
          fieldName: 'phone',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Website',
          testId: 'website',
          fieldName: 'website',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={12}>
        <AutocompleteAddressField
          label="Address"
          required={true}
          value={formFields.address.value}
          name="address"
          error={isNotBlank(formFields.address.error)}
          errorText={formFields.address.error}
          onChange={handleAddressChange}
          fullWidth
          testId="address"
          disabled={disabled}
          readOnly={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'LinkedIn Company Page',
          testId: 'linkedinCompanyPage',
          fieldName: 'linkedinCompanyPage',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Operating State',
          testId: 'operatingState',
          fieldName: 'operatingState',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createTextField({
          label: 'Nmls Id',
          testId: 'nmlsId',
          fieldName: 'nmlsId',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        <MultiSelectField
          standaloneLabel
          label="What kind of products do you offer?"
          name={'residentialProducts'}
          disabled={disabled}
          options={createOptionsArray(residentialProductsOptions)}
          getOptionSelected={(option, selectedValue) => {
            return option.value == selectedValue.value;
          }}
          value={
            lenderDetailsFormStore.form.fields.products.value?.length
              ? lenderDetailsFormStore.form.fields.products.value
              : []
          }
          onChange={(e, value) => {
            lenderDetailsFormStore.onFieldChange('products', value);
          }}
          renderInput={params => (
            <TextField {...params} variant="outlined" disabled={disabled} />
          )}
          fullWidth
          testId="products"
        />
      </Grid>
      <Grid item xs={6}>
        <LenderRMUsers
          label={'Primary Relationship Manager'}
          value={
            lenderDetailsFormStore.form.fields.primaryRelationshipManager?.value
          }
          onChange={value =>
            handleRelationshipManagerChange('primaryRelationshipManager', value)
          }
          disabled={
            disabled ||
            isRelationshipManagersDisabled(userRole, BACK_OFFICE_LEAD_ROLE)
          }
          companyProfileStore={companyProfileStore}
        />
      </Grid>
      <Grid item xs={6}>
        {createPercentageField({
          testId: 'primaryCommission',
          label: 'Primary Commission %',
          fieldName: 'primaryCommission',
          store: lenderDetailsFormStore,
          disabled:
            disabled ||
            isRelationshipManagersDisabled(userRole, BACK_OFFICE_LEAD_ROLE),
        })}
      </Grid>
      <Grid item xs={6}>
        <LenderRMUsers
          label={'Secondary Relationship Manager'}
          value={
            lenderDetailsFormStore.form.fields.secondaryRelationshipManager
              ?.value
          }
          onChange={value =>
            handleRelationshipManagerChange(
              'secondaryRelationshipManager',
              value
            )
          }
          disabled={
            disabled ||
            isRelationshipManagersDisabled(userRole, BACK_OFFICE_LEAD_ROLE)
          }
          companyProfileStore={companyProfileStore}
        />
      </Grid>
      <Grid item xs={6}>
        {createPercentageField({
          testId: 'secondaryCommission',
          label: 'Secondary Commission %',
          fieldName: 'secondaryCommission',
          store: lenderDetailsFormStore,
          disabled:
            disabled ||
            isRelationshipManagersDisabled(userRole, BACK_OFFICE_LEAD_ROLE),
        })}
      </Grid>
      <Grid item xs={6}>
        {createSelectField({
          options: preferredInsuranceOptions,
          label: 'Preferred Insurance Reviewer',
          testId: 'preferredInsuranceReviewer',
          fieldName: 'preferredInsuranceReviewer',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={6}>
        {createCheckboxField({
          label: 'Pledge of Shares',
          fieldName: 'pledgeOfShares',
          store: lenderDetailsFormStore,
          disabled: disabled,
        })}
      </Grid>
      <Grid item xs={6}>
        {createCheckboxField({
          label: 'Inherited',
          fieldName: 'inherited',
          store: lenderDetailsFormStore,
          disabled:
            disabled ||
            isRelationshipManagersDisabled(userRole, BACK_OFFICE_LEAD_ROLE),
        })}
      </Grid>
    </Grid>
  );
});
