import { darken, lighten } from '@material-ui/core';
import {
  DescribedRennovation,
  PURCHASE,
  REFINANCE,
  TOWNHOME,
} from '@roc/feature-utils';

const DANGER_COLOR = '#ad4b4b';
const DISABLED_COLOR = '#8B8B8B';
const BEIGE_COLOR = '#E3EAF2';

export const deleteIconButtonStyle = {
  color: DANGER_COLOR,
  borderColor: DANGER_COLOR,
  '&:hover': {
    color: darken(DANGER_COLOR, 0.1),
    borderColor: darken(DANGER_COLOR, 0.1),
  },
  '&.Mui-disabled': {
    color: lighten(DISABLED_COLOR, 0.5),
    borderColor: lighten(DISABLED_COLOR, 0.5),
  },
};

export const beigeButtonColorStyle = {
  backgroundColor: BEIGE_COLOR,
  boxShadow: '0 2px 1px rgba(0, 0, 0, 0.14)',
  border: `solid 1px ${BEIGE_COLOR}`,
};
export const deleteButtonStyle = {
  color: DANGER_COLOR,
  borderColor: DANGER_COLOR,
  backgroundColor: lighten(DANGER_COLOR, 0.999),
  '&:hover': {
    color: darken(DANGER_COLOR, 0.1),
    borderColor: darken(DANGER_COLOR, 0.1),
    backgroundColor: lighten(DANGER_COLOR, 0.99),
  },
  '&.Mui-disabled': {
    color: lighten(DISABLED_COLOR, 0.5),
    backgroundColor: lighten(DISABLED_COLOR, 1),
    borderColor: lighten(DISABLED_COLOR, 0.5),
  },
};

export const FORM_MAX_WIDTH = '736px';

export const verifiedInvestmentPropertiesOptions = [
  { label: 'No verified properties', value: 1 },
  {
    label: '1 - 2 verified properties',
    value: 2,
  },
  {
    label: '3 - 4 verified properties',
    value: 3,
  },
  {
    label: '5 - 9 verified properties',
    value: 4,
  },
  {
    label: '10 + verified properties',
    value: 5,
  },
];

export const propertyTypeOptions = [
  { label: 'Single Family', value: 'Single Family' },
  {
    label: 'Duplex',
    value: 'Duplex',
  },
  {
    label: 'Triplex',
    value: 'Triplex',
  },
  {
    label: 'Quadruplex',
    value: 'Quadruplex',
  },
  {
    label: 'Warrantable Condominium',
    value: 'Condominium',
  },
];

export const propertyTypeOptionsFnFGU = [
  { label: 'Single Family', value: 'Single Family' },
  {
    label: 'Duplex',
    value: 'Duplex',
  },
  {
    label: 'Triplex',
    value: 'Triplex',
  },
  {
    label: 'Quadruplex',
    value: 'Quadruplex',
  },
  {
    label: 'Warrantable Condominium',
    value: 'Condominium',
  },
  {
    label: TOWNHOME,
    value: TOWNHOME,
  },
];

export const propertySourceValues = {
  bankruptcySale: 'Bankruptcy Sale',
  entityToEntity: 'Entity to Entity',
  estateSale: 'Estate Sale',
  foreclosureAuction: 'Foreclosure Auction',
  inheritance: 'Inheritance',
  multipleListingService: 'Multiple Listing Service',
  onlineAuction: 'Online Auction',
  preForeclosure: 'Pre-Foreclosure',
  privateSale: 'Private Sale',
  realEstateOwned: 'Real Estate Owned',
  sheriffsSale: "Sheriff's Sale",
  shortSale: 'Short Sale',
  wholesaler: 'Wholesaler',
  other: 'Other',
};

export enum PropertySource {
  WHOLESALER = 'Wholesaler',
  OTHER = 'Other',
}

export const propertySourceOptions = [
  {
    label: propertySourceValues.bankruptcySale,
    value: propertySourceValues.bankruptcySale,
  },
  {
    label: propertySourceValues.entityToEntity,
    value: propertySourceValues.entityToEntity,
  },
  {
    label: propertySourceValues.estateSale,
    value: propertySourceValues.estateSale,
  },
  {
    label: propertySourceValues.foreclosureAuction,
    value: propertySourceValues.foreclosureAuction,
  },
  {
    label: propertySourceValues.inheritance,
    value: propertySourceValues.inheritance,
  },
  {
    label: propertySourceValues.multipleListingService,
    value: propertySourceValues.multipleListingService,
  },
  {
    label: propertySourceValues.onlineAuction,
    value: propertySourceValues.onlineAuction,
  },
  {
    label: propertySourceValues.preForeclosure,
    value: propertySourceValues.preForeclosure,
  },
  {
    label: propertySourceValues.privateSale,
    value: propertySourceValues.privateSale,
  },
  {
    label: propertySourceValues.realEstateOwned,
    value: propertySourceValues.realEstateOwned,
  },
  {
    label: propertySourceValues.sheriffsSale,
    value: propertySourceValues.sheriffsSale,
  },
  {
    label: propertySourceValues.shortSale,
    value: propertySourceValues.shortSale,
  },
  {
    label: propertySourceValues.wholesaler,
    value: propertySourceValues.wholesaler,
  },
  {
    label: propertySourceValues.other,
    value: propertySourceValues.other,
  },
];

export const describedRennovationOptions = [
  { label: 'No', value: DescribedRennovation.NO },
  {
    label: 'Expanding the square footage (Horizontally)',
    value: DescribedRennovation.HORIZONTAL,
  },
  {
    label: 'Expanding the square footage (Vertically)',
    value: DescribedRennovation.VERTICAL,
  },
  {
    label: 'Expanding the square footage (Horizontally & Vertically)',
    value: DescribedRennovation.VERTICAL_AND_HORIZONTAL,
  },
  { label: 'Changing the unit count', value: DescribedRennovation.UNIT_COUNT },
  {
    label: 'Converting to Condominiums',
    value: DescribedRennovation.CONDO_CONVERSION,
  },
  {
    label: 'Adding or converting to an ADU (Accessory Dwelling Unit)',
    value: DescribedRennovation.ADU_CONVERSION,
  },
  { label: 'Repairing fire damage', value: DescribedRennovation.FIRE_DAMAGE },
];

export enum SquareFootageExpansion {
  NO = 'NO',
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL_AND_HORIZONTAL = 'VERTICAL_AND_HORIZONTAL',
}

export const squareFootageExpansionOptions = [
  { label: 'No', value: SquareFootageExpansion.NO },
  { label: 'Yes, Vertical Expansion', value: SquareFootageExpansion.VERTICAL },
  {
    label: 'Yes, Horizontal Expansion',
    value: SquareFootageExpansion.HORIZONTAL,
  },
  {
    label: 'Yes, Vertical & Horizontal Expansion',
    value: SquareFootageExpansion.VERTICAL_AND_HORIZONTAL,
  },
];

export const loanTermLengthsOptions = [
  { label: '12 Months', value: 12 },
  { label: '18 Months', value: 18 },
];

export const feeOptions = [
  { label: 'Select a Fee', value: '' },
  { label: 'Buy Down Fee', value: 'Buy Down Fee' },
  { label: 'Admin Fee', value: 'Admin Fee' },
  { label: 'Commitment Fee', value: 'Commitment Fee' },
  { label: 'Processing Fee', value: 'Processing Fee' },
  { label: 'Underwriting Fee', value: 'Underwriting Fee' },
];

export const creditBackgroundReportsStatuses = {
  noCreditBackground: 'noCreditBackground',
  creditReportExpired: 'creditReportExpired',
  creditBackgroundSent: 'creditBackgroundSent',
  xactusCreditBackgroundOk: 'xactusCreditBackgroundOk',
  creditBackgroundOk: 'creditBackgroundOk',
};

export enum QuoteStep {
  BORROWER_INFORMATION = 'BORROWER_INFORMATION',
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  LEVERAGE = 'LEVERAGE',
  LOAN_ECONOMICS = 'LOAN_ECONOMICS',
  SUMMARY = 'SUMMARY',
  PREFLIGHT = 'PREFLIGHT',
  SUCCESS = 'SUCCESS',
}

export const quoteSteps = [
  QuoteStep.BORROWER_INFORMATION,
  QuoteStep.PROPERTY_INFORMATION,
  QuoteStep.LEVERAGE,
  QuoteStep.LOAN_ECONOMICS,
  QuoteStep.SUMMARY,
  QuoteStep.PREFLIGHT,
  QuoteStep.SUCCESS,
];

export enum PreflightStep {
  BROKER = 'BROKER',
  ENTITY = 'ENTITY',
  BORROWER = 'BORROWER',
  PROPERTY = 'PROPERTY',
  CLOSING = 'CLOSING',
  SUBMISSION = 'SUBMISSION',
}

export const preflightSteps = [
  PreflightStep.BROKER,
  PreflightStep.ENTITY,
  PreflightStep.BORROWER,
  PreflightStep.PROPERTY,
  PreflightStep.CLOSING,
  PreflightStep.SUBMISSION,
];

export const propertyOwnershipOptions = [
  { label: 'Yes', value: REFINANCE },
  { label: 'No', value: PURCHASE },
];

export const LEVERAGE_EXCEPTION_COLOR = '#FC5E5E';

export enum CreditStatus {
  CREDIT_AND_BACKGROUND_OK_XACTUS,
  CREDIT_AND_BACKGROUND_OK,
  CREDIT_OLDER_THAN_180_DAYS,
  CREDIT_AND_BACKGROUND_CHECK_SENT,
  NO_CREDIT_AND_BACKGROUND,
}

export enum IDVerificationStatus {
  ID_ON_FILE,
  NO_ID_ON_FILE,
}

export enum CreditPull {
  BORROWER_AUTHORIZATION_FORM = 'BORROWER_AUTHORIZATION_FORM',
  XACTUS_360 = 'XACTUS_360',
}

export const fixAndFlipExperienceMatrixData = {
  COLUMNS_2: [
    {
      headerName: 'Tier',
    },
    {
      headerName: 'Renovation Type',
    },
    {
      headerName: 'FICO',
    },
    {
      headerName: 'Max LTC',
    },
    {
      headerName: 'Max LTFC',
    },
    {
      headerName: 'Max LTARV',
    },
    {
      headerName: 'Exit Test',
    },
  ],
  DATA_2: [
    {
      experience: '5',
      rows: [
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltfc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: { value: '', removeTopBorder: false, removeBottomBorder: true },
        },
        {
          renovation: { value: 'Moderate (>25% & <=50% of Purchase)' },
          fico: {
            value: '>= 660',
            hasLeftBorder: true,
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltc: {
            value: '90%',
            hasLeftBorder: true,
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltfc: { value: '-', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '75%',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          exit: {
            value: '>=0% ROI or 1.1 DSCR',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
        },
        {
          renovation: { value: 'Heavy (>50% & <100% of Purchase)' },
          fico: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltarv: {
            value: '',
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          exit: { value: '' },
        },
        {
          renovation: {
            value:
              'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
            hasBorder: false,
          },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: { value: '85%', hasBorder: false },
          ltfc: { value: '90%', hasBorder: false },
          ltarv: { value: '70%', hasBorder: false },
          exit: { value: '>=10% ROI', hasBorder: false },
        },
      ],
    },
    {
      experience: '4',
      rows: [
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltc: { value: '90%', removeBottomBorder: true },
          ltfc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: { value: '', removeTopBorder: false, removeBottomBorder: true },
        },
        {
          renovation: { value: 'Moderate (>25% & <=50% of Purchase)' },
          fico: {
            value: '>= 660',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltc: { value: '', hasBorder: false },
          ltfc: { value: '-', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '75%',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          exit: {
            value: '>=0% ROI or 1.1 DSCR',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
        },
        {
          renovation: { value: 'Heavy (>50% & <100% of Purchase)' },
          fico: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltc: { value: '85%', removeBottomBorder: true },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltarv: {
            value: '',
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          exit: { value: '', removeTopBorder: true, removeBottomBorder: false },
        },
        {
          renovation: {
            value:
              'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
          },
          fico: { value: '>= 680' },
          ltc: { value: '' },
          ltfc: { value: '90%' },
          ltarv: { value: '70%' },
          exit: { value: '>=10% ROI' },
        },
      ],
    },
    {
      experience: '3',
      rows: [
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltc: {
            value: '85%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          ltfc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: { value: '', removeTopBorder: false, removeBottomBorder: true },
        },
        {
          renovation: { value: 'Moderate (>25% & <=50% of Purchase)' },
          fico: {
            value: '>= 660',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltc: { value: '', removeTopBorder: false, removeBottomBorder: false },
          ltfc: { value: '-', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '75%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: {
            value: '>=10% ROI or 1.1 DSCR',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          renovation: { value: 'Heavy (>50% & <100% of Purchase)' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: {
            value: '80%',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exit: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
        {
          renovation: {
            value:
              'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
          },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: { value: '', removeTopBorder: false, removeBottomBorder: false },
          ltfc: {
            value: '85%',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltarv: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exit: {
            value: '>=20% ROI',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
      ],
    },
    {
      experience: '2',
      rows: [
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltc: {
            value: '80%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          ltfc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: { value: '', removeTopBorder: false, removeBottomBorder: true },
        },
        {
          renovation: { value: 'Moderate (>25% & <=50% of Purchase)' },
          fico: {
            value: '>= 680',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          ltc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltfc: { value: '-', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '70%',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          exit: {
            value: '>=20% ROI or 1.1 DSCR',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
        },
        {
          renovation: { value: 'Heavy (>50% & <100% of Purchase)' },
          fico: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltc: {
            value: '75%',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          exit: { value: '', removeTopBorder: true, removeBottomBorder: false },
        },
        {
          renovation: {
            value:
              'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
          },
          fico: {
            value: '-',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: {
            value: '-',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltarv: {
            value: '-',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exit: {
            value: '-',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
      ],
    },
    {
      experience: '1',
      rows: [
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: {
            value: '>= 660',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: {
            value: '75%',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltfc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltarv: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: {
            value: '>=30% ROI or 1.1 DSCR',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          renovation: { value: 'Light (<25% of Purchase)' },
          fico: {
            value: '>= 720',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltc: {
            value: '80%',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exit: { value: '', removeTopBorder: true, removeBottomBorder: false },
        },
        {
          renovation: { value: 'Moderate (>25% & <=50% of Purchase)' },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltc: { value: '', removeTopBorder: false, removeBottomBorder: true },
          ltfc: { value: '-', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exit: { value: '', removeTopBorder: false, removeBottomBorder: true },
        },
        {
          renovation: { value: 'Heavy (>50% & <100% of Purchase)' },
          fico: {
            value: '-',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          ltc: { value: '-', removeTopBorder: false, removeBottomBorder: true },
          ltfc: { value: '', removeTopBorder: true, removeBottomBorder: true },
          ltarv: {
            value: '-',
            removeTopBorder: true,
            removeBottomBorder: true,
          },
          exit: { value: '-', removeTopBorder: true, removeBottomBorder: true },
        },
        {
          renovation: {
            value:
              'Extensive (>=100% of Purchase) Addition, Expansion, Conversion, ADU',
          },
          fico: { value: '', removeTopBorder: true, removeBottomBorder: false },
          ltc: { value: '', removeTopBorder: false, removeBottomBorder: false },
          ltfc: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          ltarv: {
            value: '',
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          exit: { value: '', removeTopBorder: true, removeBottomBorder: false },
        },
      ],
    },
  ],
  ROW_VALUE_KEYS: ['renovation', 'fico', 'ltc', 'ltfc', 'ltarv', 'exit'],
};
export const groundUpExperienceMatrixData = {
  COLUMNS_2: [
    {
      headerName: 'Tier',
    },
    {
      headerName: 'Permitted/Not Permitted',
    },
    {
      headerName: 'FICO',
    },
    {
      headerName: 'Max LTC',
    },
    {
      headerName: 'Max LTV',
    },
    {
      headerName: 'Max LTFC',
    },
    {
      headerName: 'Max LTFC w/Financed IR',
    },
    {
      headerName: 'Max LTARV',
    },
    {
      headerName: 'Exit Test',
    },
  ],
  DATA_2: [
    {
      experience: '5',
      rows: [
        {
          permitNoPermit: { value: 'Permitted Project' },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTC: { value: '75%' },
          maxLTV: { value: '75%' },
          maxLTFC: {
            value: '85%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTFCwFIR: {
            value: '90%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTARV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '>=10% ROI',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          permitNoPermit: { value: 'Non-Permitted Project' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          maxLTC: { value: '60%' },
          maxLTV: { value: '60%' },
          maxLTFC: { value: '' },
          maxLTFCwFIR: { value: '' },
          maxLTARV: { value: '' },
          exitTest: { value: '' },
        },
      ],
    },
    {
      experience: '4',
      rows: [
        {
          permitNoPermit: { value: 'Permitted Project' },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTC: { value: '75%' },
          maxLTV: { value: '75%' },
          maxLTFC: {
            value: '85%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTFCwFIR: {
            value: '90%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTARV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '>=10% ROI',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          permitNoPermit: { value: 'Non-Permitted Project' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          maxLTC: { value: '60%' },
          maxLTV: { value: '60%' },
          maxLTFC: { value: '' },
          maxLTFCwFIR: { value: '' },
          maxLTARV: { value: '' },
          exitTest: { value: '' },
        },
      ],
    },
    {
      experience: '3',
      rows: [
        {
          permitNoPermit: { value: 'Permitted Project' },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTC: { value: '75%' },
          maxLTV: { value: '75%' },
          maxLTFC: {
            value: '85%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTFCwFIR: {
            value: '90%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          maxLTARV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '>=20% ROI',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          permitNoPermit: { value: 'Non-Permitted Project' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          maxLTC: { value: '60%' },
          maxLTV: { value: '60%' },
          maxLTFC: { value: '' },
          maxLTFCwFIR: { value: '' },
          maxLTARV: { value: '' },
          exitTest: { value: '' },
        },
      ],
    },
    {
      experience: '2',
      rows: [
        {
          permitNoPermit: { value: 'Permitted Project' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
            removeRightBorder: true,
          },
          maxLTC: {
            value: '',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTV: {
            value: '',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTFC: {
            value: 'N/A',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTFCwFIR: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
          maxLTARV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '',
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
        },
        {
          permitNoPermit: { value: 'Non-Permitted Project' },
          fico: {
            value: '',
            removeLeftBorder: true,
            removeRightBorder: true,
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          maxLTC: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTFC: {
            value: 'N/A',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTFCwFIR: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTARV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          exitTest: { value: '', removeLeftBorder: true },
        },
      ],
    },
    {
      experience: '1',
      rows: [
        {
          permitNoPermit: { value: 'Permitted Project' },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: true,
            removeRightBorder: true,
          },
          maxLTC: {
            value: '',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTV: {
            value: '',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTFC: {
            value: 'N/A',
            removeRightBorder: true,
            removeBottomBorder: true,
            removeLeftBorder: true,
          },
          maxLTFCwFIR: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
          maxLTARV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '',
            removeLeftBorder: true,
            removeBottomBorder: true,
          },
        },
        {
          permitNoPermit: { value: 'Non-Permitted Project' },
          fico: {
            value: '',
            removeLeftBorder: true,
            removeRightBorder: true,
            removeTopBorder: true,
            removeBottomBorder: false,
          },
          maxLTC: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTFC: {
            value: 'N/A',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTFCwFIR: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          maxLTARV: {
            value: '',
            removeRightBorder: true,
            removeLeftBorder: true,
          },
          exitTest: { value: '', removeLeftBorder: true },
        },
      ],
    },
  ],
  ROW_VALUE_KEYS: [
    'permitNoPermit',
    'fico',
    'maxLTC',
    'maxLTV',
    'maxLTFC',
    'maxLTFCwFIR',
    'maxLTARV',
    'exitTest',
  ],
};

export const stabilizedBridgeExperienceMatrixData = {
  COLUMNS_2: [
    {
      headerName: 'Experience Tier',
    },
    {
      headerName: 'Property Condition',
    },
    {
      headerName: 'FICO',
    },
    {
      headerName: 'Seasoning',
    },
    {
      headerName: 'Max LTFC',
    },
    {
      headerName: 'Max LTV',
    },
    {
      headerName: 'Exit Test',
    },
  ],
  DATA_2: [
    {
      experience: '3, 4, 5',
      rows: [
        {
          propertyCondition: {
            value: 'C1 or C2',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          fico: {
            value: '>= 660',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          seasoning: { value: '<= 180 Days' },
          maxLTFC: { value: '90%' },
          maxLTV: {
            value: '70/75%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: 'N/A',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          propertyCondition: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          seasoning: { value: '> 180 Days' },
          maxLTFC: { value: 'N/A' },
          maxLTV: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exitTest: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
        {
          propertyCondition: {
            value: 'C3 or C4',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          seasoning: { value: '<= 180 Days' },
          maxLTFC: { value: '85%' },
          maxLTV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '1.1 Exit DSCR',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          propertyCondition: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          seasoning: { value: '> 180 Days' },
          maxLTFC: { value: 'N/A' },
          maxLTV: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exitTest: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
      ],
    },
    {
      experience: '2',
      rows: [
        {
          propertyCondition: {
            value: 'C1 or C2',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          seasoning: { value: '<= 180 Days' },
          maxLTFC: { value: '80%' },
          maxLTV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: 'N/A',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          propertyCondition: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          seasoning: { value: '> 180 Days' },
          maxLTFC: { value: 'N/A' },
          maxLTV: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exitTest: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
        {
          propertyCondition: {
            value: 'C3 or C4',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          fico: { value: '', removeTopBorder: false, removeBottomBorder: true },
          seasoning: { value: '<= 180 Days' },
          maxLTFC: { value: '75%' },
          maxLTV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: '1.1 Exit DSCR',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          propertyCondition: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          seasoning: { value: '> 180 Days' },
          maxLTFC: { value: 'N/A' },
          maxLTV: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exitTest: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
        },
      ],
    },
    {
      experience: '1',
      rows: [
        {
          propertyCondition: {
            value: 'C1 or C2',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          fico: {
            value: '>= 680',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          seasoning: { value: '<= 180 Days' },
          maxLTFC: { value: '80%' },
          maxLTV: {
            value: '70%',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
          exitTest: {
            value: 'N/A',
            removeTopBorder: false,
            removeBottomBorder: true,
          },
        },
        {
          propertyCondition: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          fico: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          seasoning: { value: '> 180 Days' },
          maxLTFC: { value: 'N/A' },
          maxLTV: {
            value: '',
            removeTopBorder: false,
            removeBottomBorder: false,
          },
          exitTest: { value: '' },
        },
      ],
    },
  ],
  ROW_VALUE_KEYS: [
    'propertyCondition',
    'fico',
    'seasoning',
    'maxLTFC',
    'maxLTV',
    'exitTest',
  ],
};

export const participationOptions = [
  {
    value: 'Broker Loan',
    label: 'No Participation',
  },
  {
    value: 'Participate through life of loan',
    label: 'Through life of loan',
  },
  {
    value: 'Participate with subsequent buy-out',
    label: 'With subsequent buy-out',
  },
];
export const participationPercetangeOptions = [
  {
    value: 0.0,
    label: '0%',
  },
  {
    value: 10.0,
    label: '10%',
  },
  {
    value: 15.0,
    label: '15%',
  },
  {
    value: 20.0,
    label: '20%',
  },
  {
    value: 25.0,
    label: '25%',
  },
  {
    value: 30.0,
    label: '30%',
  },
  {
    value: 35.0,
    label: '35%',
  },
  {
    value: 40.0,
    label: '40%',
  },
  {
    value: 45.0,
    label: '45%',
  },
  {
    value: 49.0,
    label: '49%',
  },
];

export const NO_INSTA_QUOTE_ALLOWED =
  'We are unable to provide an insta-leverage Quote at this time. Please submit the loan at your requested terms and be sure to provide any and all additional information to support credit decision. Final terms are subject to underwriting and lender discretion.';

export const GROUND_UP_MANUAL_QUOTE_BULLET_POINTS = [
  'Multi-property loans',
  '<680 fico',
  '>$1,000,000 loan amount',
  'Experience tier 1 or 2',
];

export const MIN_FINANCED_IR_TOTAL_PURCHASE_PRICE = 100000;
