import React, { useState } from 'react';
import { ComparableProperty } from '../../types/propertyTypes';
import { Box, Card, CardContent, CardHeader, CardMedia, Checkbox, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import placeHolderImage from "../../images/Placeholder.svg"
import { formatDate, formatPrice } from '../../utils/formatter';
import { ReactComponent as GreenArrow } from '../../images/greenArrow.svg'
import { ReactComponent as RedArrow } from '../../images/redArrow.svg'
import { segmentAnalytics, SegmentTrackerEventCompTool } from '@roc/feature-utils';
import { useStore } from '@roc/client-portal-shared/hooks';
import SaveIcon from '@material-ui/icons/Save';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.primary.light}`,
      width: '100%',
      height: '400px'
    },
    image: {
      width: '100%',
      height: 'auto',
    },
    cardText: {
      fontSize: '17px'
    },
    cardBody: {
      fontSize: '13px'
    },
    cardMedia: {
      height: '220px',
      margin: '0px 10px 0px 10px'
    },
    divider: {
      color: '#DBDBDB',
      fontSize: '18px'
    }
  })
);

export interface CheckBoxesProps {
  properties: ComparableProperty[];
}

export const CheckBoxes: React.FC<CheckBoxesProps> = observer(({
  properties
}) => {
  const classes = useStyles();
  const { compToolStore } = useStore();

  const { subjectProperty, isInternal, internalUsername, userStore } = compToolStore;

  const getArrowSymbol = (key, property) => {
    if (subjectProperty.data[key] && property.data[key]) {
      if (subjectProperty.data[key] > property.data[key]) {
        return <RedArrow />;
      } else if (subjectProperty.data[key] < property.data[key]) {
        return <GreenArrow />;
      }
    }
    return null;
  }


  const getPropertyImage = (property) => {
    const image = property.data.images.find(image => image.recommended_preview) ? property.data.images.find(image => image.recommended_preview).url : property.data.images[0]?.url

    return image || placeHolderImage
  }

  const openDialog = (property) => {
    compToolStore.setSelectedProperty(property);
    compToolStore.setOpenPropertyDialog(true)
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEventCompTool.COMP_TOOL_COMP_PROPERTY_CLICKED,
      userName: isInternal ? internalUsername : userStore.userInformation?.username,
      subjectAddress: subjectProperty.data.address,
      propertyAddress: property.data.address
    });
  }

  const [imageLoaded, setImageLoaded] = useState(false);

  const onError = (event) => {
    if (!imageLoaded && event.target.src !== placeHolderImage) {
      event.target.src = placeHolderImage;
    }
  };

  const onLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {
        properties.map(property =>
          <Grid item xs={12} sm={compToolStore.propertiesMapExtended ? 3 : 6} style={{ padding: '10px 9px' }}>
            <Card className={classes.card}>
              <CardHeader
                title={property.data?.address?.split(',')[0].trim()}
                titleTypographyProps={{
                  style: { fontSize: '24px', fontWeight: 500 }
                }}
                action={
                  <Box display="flex" flexDirection="row" gridGap="1rem" alignItems="center">
                    {compToolStore.savedPropertyComps?.some(p => p.comparePropertyId === property.property_id) && <SaveIcon color="primary" />}
                    <Checkbox
                      checked={compToolStore.selectedCompareProperties?.some(p => p.property_id === property.property_id)}
                      onClick={() => { compToolStore.addSelectCompareProperties(property) }}
                      color='primary'
                    />
                  </Box>
                }
              />
              <div style={{ position: "relative" }}>
                <CardMedia onClick={() => openDialog(property)}
                  className={classes.cardMedia}
                  image={getPropertyImage(property)}
                  onError={onError}
                  onLoad={onLoad}
                  title="House Image"

                />
                <div style={{
                  position: "absolute", bottom: 10, left: 20,
                  color: '#000000',
                  backgroundColor: 'white',
                  display: 'inline',
                  padding: '5px',
                  borderRadius: '4px'
                }}>
                  <Typography gutterBottom style={{ fontSize: '32px', margin: '0px' }}>
                    {formatPrice(property.data.price, property.data.rent, compToolStore.rentalComps)}{compToolStore.rentalComps && <strong><span style={{ fontSize: '16px' }}>/mo</span></strong>}
                  </Typography>
                </div>
              </div>
              <CardContent onClick={() => openDialog(property)}>
                <Grid container spacing={1}>
                  <Grid item xs={3} style={{ padding: '5px 0px' }}>
                    <Typography align="left" className={classes.cardText}>
                      <strong>{`${property.data.bedrooms ? property.data.bedrooms : '-'}`}</strong> <span style={{ fontSize: '14px' }}>bd</span>{getArrowSymbol('bedrooms', property)} <span className={classes.divider}>|</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ padding: '5px 0px' }}>
                    <Typography align="left" className={classes.cardText}>
                      <strong>{`${property.data.full_bathrooms ? property.data.full_bathrooms : '-'}`}</strong> <span style={{ fontSize: '14px' }}>ba</span>{getArrowSymbol('full_bathrooms', property)} <span className={classes.divider}>|</span>
                    </Typography>

                  </Grid>
                  <Grid item xs={3} style={{ padding: '5px 0px' }}>
                    <Typography align="left" className={classes.cardText}>
                      <strong>{`${property.data.half_bathrooms ? property.data.half_bathrooms : '-'}`}</strong> <span style={{ fontSize: '14px' }}>hba</span>{getArrowSymbol('half_bathrooms', property)} <span className={classes.divider}>|</span>
                    </Typography>

                  </Grid>
                  <Grid item xs={3} style={{ padding: '5px 0px' }}>
                    <Typography align="right" className={classes.cardText}>
                      <strong>{`${property.data.area ? property.data.area : '-'}`}</strong> <span style={{ fontSize: '12px' }}>sqft</span>{getArrowSymbol('area', property)}
                    </Typography>
                  </Grid>
                  {compToolStore.rentalComps ?
                    (<Grid item xs={6}>
                      <Typography align="left" className={classes.cardBody}>
                        Rent Date <strong>{formatDate(property.data.rent_date)}</strong>
                      </Typography>
                    </Grid>) : (<Grid item xs={6}>
                      <Typography align="left" className={classes.cardBody}>
                        Last sold on <strong>{formatDate(property.data.sale_date)}</strong>
                      </Typography>
                    </Grid>)}

                  <Grid item xs={3}>
                    <Typography align="center" className={classes.cardBody}>
                      Built <strong>{`${property.data.year_built ? property.data.year_built : '-'}`}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="right" className={classes.cardBody}>
                      <strong>{`${property.similarity.mi_distance ? property.similarity.mi_distance.toFixed(2) : '-'}`} mi</strong> away
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="right" className={classes.cardBody}>
                      <strong>{`${property.data.days_on_market ? property.data.days_on_market : '-'}`}</strong> DOM
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
      }
    </>
  );
});
