import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { TestDataAttributesConfigProvider, PreviewBackdrop, InvalidCertificate } from '@roc/ui';
import { observer } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import * as ReactDOM from 'react-dom';
import { Route, Router, Switch, useHistory } from 'react-router-dom';
import { BaseStore, Loader, MaintenanceWindowWorker, ThemeCongifurationContextProvider, WrongCompanyBanner } from '@roc/feature-app-core';
import { StoreProvider } from '@roc/feature-app-core';
import {
  AppConfigurationsProvider,
  ThemeProvider,
} from '@roc/feature-app-core';
import {
  useBaseStore,
  Notification,
  RegisterWorker
} from '@roc/feature-app-core';
import AppCssBaseline, { AppCssBaselineProps } from './styles/AppCssBaseline';
import { useEffect } from 'react';
import './styles/styled-scrollbar.scss';
import { ThemeConfiguration } from '@roc/feature-types';

const isProduction = process.env.NODE_ENV === 'production';
const testDataAttributeConfig = {
  suffix: 'testid',
  enable: !isProduction,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    background: theme.palette.success.dark,
  },
  error: {
    background: theme.palette.error.dark,
  },
  warning: {
    background: theme.palette.warning.dark,
  },
  info: {
    background: theme.palette.info.dark,
  },
}));

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, []);
  return null;
};

const AppContainer = observer(({ children }) => {
  const { globalStore } = useBaseStore();
  const { isBorrowerPortal } = globalStore;
  const classes = useStyles();

  useEffect(() => {
    globalStore.validateHostCertificate()
  }, []);

  useEffect(() => {
    // add styled scrollbar to entire site
    document.body.classList.add('styled-scrollbar-all-child');
    // remove loading from index.html
    const el = document.getElementById('lds-ellipsis-container');
    if (el) el.remove();
  }, []);

  return (
    globalStore.isCertificateValid === false ?
      <InvalidCertificate />
      : <TestDataAttributesConfigProvider value={testDataAttributeConfig}>
        <SnackbarProvider
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantInfo: classes.info,
            variantWarning: classes.warning,
          }}
          anchorOrigin={{
            horizontal: isBorrowerPortal ? 'center' : 'left',
            vertical: isBorrowerPortal ? 'top' : 'bottom'
          }}
        >
          {globalStore.isPreviewMode ? (
            <PreviewBackdrop />
          ) : null}
          {children}
          <Notification />
          <Loader />
        </SnackbarProvider>
      </TestDataAttributesConfigProvider>
  );
});

export interface RenderAppProps {
  store: BaseStore,
  App: any,
  appCssBaselineProps?: AppCssBaselineProps,
  ignoreApplyAppCssBaseline?: boolean,
  RouterComponent?: any,
  themeConfiguration?: ThemeConfiguration,
  ThemeProviderComponent?: typeof ThemeProvider,
  overridePrimaryColor?: string,
  AppContainerComponent?: any,
  AppCssBaselineComponent?: any
}

export const RenderApp = ({
  store,
  App,
  appCssBaselineProps,
  ignoreApplyAppCssBaseline = false,
  RouterComponent = Router,
  themeConfiguration,
  ThemeProviderComponent = ThemeProvider,
  overridePrimaryColor,
  AppContainerComponent = AppContainer,
  AppCssBaselineComponent = AppCssBaseline
}: RenderAppProps) => {
  ReactDOM.render(
    <StoreProvider value={store}>
      <ThemeCongifurationContextProvider value={themeConfiguration}>
        <AppConfigurationsProvider overridePrimaryColor={overridePrimaryColor}>
          <ThemeProviderComponent>
            {
              !ignoreApplyAppCssBaseline && (<AppCssBaselineComponent {...appCssBaselineProps} />)
            }
            <RouterComponent history={store.globalStore.history}>
              <RegisterWorker />
              <MaintenanceWindowWorker />
              <WrongCompanyBanner />
              <ScrollToTop />
              <Switch>
                <Route
                  path={'/'}
                  render={() => (
                    <AppContainerComponent>
                      <App />
                    </AppContainerComponent>
                  )}
                />
              </Switch>
            </RouterComponent>
          </ThemeProviderComponent>
        </AppConfigurationsProvider>
      </ThemeCongifurationContextProvider>
    </StoreProvider>,
    document.getElementById('root')
  );
};
