import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { Layout } from '@roc/client-portal-shared/components';
import { Paper, StandardDialog, TextField } from '@roc/ui';
import { observer } from "mobx-react";
import { Arv, City, LiquidityId, PropertyTypes, Recipients, SqtFootage, State, Zipcode } from './liquidityReportFields';
import { LiquidityReportBaseStore } from '../stores';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}))

interface LiquidityReportProps {
  store: LiquidityReportBaseStore;
  openModal?: () => any
}

export const LiquidityReport = observer(({store}) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const closeModal = () => {
    setModalOpen(false);
  }

  const openModal = () => {
    setModalOpen(true);
    setMessage("");
  }

  const submitHelpRequest = () => {
    store.submitLiquidityReportHelp(message)
  }

  return (
    <Layout title='Liquidity Report' maxWidth={'xl'}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} direction='row'>
          <Grid item xs={12} md={6}>
            <ReportDetailsSection store={store} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LiquidityDetailsSection store={store} openModal={openModal} />
          </Grid>
        </Grid>
        <Grid container spacing={6} direction='row'>
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => store.submitLiquidityReport()}>
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent='flex-end'>
            <Grid item xs={12} md={6}>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                fullWidth
                onClick={openModal}>
                Help/Report Bug
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <StandardDialog
        title={'Help/Report Bug'}
        open={modalOpen}
        handleClose={closeModal}
        dialogContent={
          <>
            <TextField
              label={"Message"}
              fullWidth={true}
              standaloneLabel={true}
              testId={"message"}
              variant="outlined"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </>
        }
        dialogActions={
          <>
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => setModalOpen(false)}
              color="primary"
              variant="outlined"
            >
              CANCEL
            </Button>
            <Button
              onClick={submitHelpRequest}
              color="primary"
              variant="outlined"
            >
              SUBMIT
            </Button>
          </>
        }
      />
    </Layout>
  )
})

export const ReportDetailsSection = observer(({ store }: LiquidityReportProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Report Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Recipients store={store} />
      </Grid>
      <Grid item xs={12}>
        <LiquidityId store={store} />
      </Grid>
    </Grid>
  )
})

export const LiquidityDetailsSection = observer(({ store, openModal }: LiquidityReportProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Liquidity Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Arv store={store} />
      </Grid>
      <Grid item xs={12}>
        <SqtFootage store={store} />
      </Grid>
      <Grid item xs={12}>
        <City store={store} />
      </Grid>
      <Grid item xs={12}>
        <State store={store} />
      </Grid>
      <Grid item xs={12}>
        <Zipcode store={store} />
      </Grid>
      <Grid item xs={12}>
        <PropertyTypes store={store} />
      </Grid>
    </Grid>
  )
})