import { flow, action, observable, makeObservable, computed } from 'mobx';
import { ApiResponse } from '@roc/feature-app-core';
import { GlobalStore } from '@roc/feature-app-core';
import { DocumentBaseStore, DocumentStore } from '@roc/feature-documents';
import { LoanStore } from '@roc/feature-loans';
import { FormStore } from '@roc/feature-app-core';
import { DealEconomicsService } from '../../services/documentForms/dealEconomicsService';
import { DealEconomics } from '../../types/documentForms/dealEconomics';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import { isNil } from '@roc/feature-utils';

const getCurrencyValues = value => {
  let multiplier = 1;
  if (value < 0) {
    multiplier = -1;
  }
  return {
    value: !isNil(value) ? formatCurrency(multiplier * value) : '',
    isNegative: multiplier === -1,
  };
};

const dealEconomicsForm = {
  fields: {
    purchasePrice: {
      value: '',
      error: null,
      rule: '',
    },
    sellersCreditAmount: {
      value: '',
      error: null,
      rule: '',
    },
    value: {
      value: '',
      error: null,
      rule: '',
    },
    loanAmount: {
      value: '',
      error: null,
      rule: '',
    },
    afterRepairLTV: {
      value: '',
      error: null,
      rule: '',
    },
    renovationBudget: {
      value: '',
      error: null,
      rule: '',
    },
    interestRate: {
      value: '',
      error: null,
      rule: '',
    },
    points: {
      value: '',
      error: null,
      rule: '',
    },
    fullPrincipalInterest: {
      value: '',
      error: null,
      rule: '',
    },
    rent: {
      value: '',
      error: null,
      rule: '',
    },
    timeToFlip: {
      value: '',
      error: null,
      rule:  '',
    },
    mortgageTaxPercent: {
      value: '',
      error: null,
      rule: '',
    },
    transferTaxPercent: {
      value: '',
      error: null,
      rule: '',
    },
    mansionTaxPercent: {
      value: '',
      error: null,
      rule: '',
    },
    titleInsurancePercent: {
      value: '',
      error: null,
      rule: '',
    },
    brokerFeePercent: {
      value: '',
      error: null,
      rule: '',
    },
    propertyTaxAndMaintenancePercent: {
      value: '',
      error: null,
      rule: '',
    },
    noi: {
      value: '',
      error: null,
      rule: '',
    },
    exitLoanRate: {
      value: '',
      error: null,
      rule: '',
    },
    purchaseDate: {
      value: '',
      error: null,
      rule: '',
    },
    useNewDealEconomicsCalculations: {
      value: '',
      error: null,
      rule: '',
    },
    payoffAmount: {
      value: '',
      error: null,
      rule: '',
    },
    loanClosingDate: {
      value: '',
      error: null,
      rule: '',
    },
    annualTaxes: {
      value: '',
      error: null,
      rule: '',
    },
    annualHOADues: {
      value: '',
      error: null,
      rule: '',
    },
    annualInsurance: {
      value: '',
      error: null,
      rule: '',
    },
    exitStrategy: {
      value: '',
      error: null,
      rule: '',
    },
    originationBrokerPoints: {
      value: '',
      error: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class DealEconomicsStore extends FormStore {
  loanStore: LoanStore;
  private documentStore: DocumentBaseStore;
  private globalStore: GlobalStore;
  private dealEconomicsService: DealEconomicsService;
  dealEconomics: DealEconomics;
  isDownloading: boolean;
  isDisabled: boolean;
  loanTerm: number;

  constructor(
    loanStore: LoanStore,
    documentStore: DocumentStore,
    globalStore: GlobalStore
  ) {
    super({ ...dealEconomicsForm }, globalStore);
    this.loanStore = loanStore;
    this.documentStore = documentStore;
    this.globalStore = globalStore;
    this.dealEconomicsService = new DealEconomicsService();
    this.dealEconomics = null;
    makeObservable(this, {
      fetchDealEconomics: flow,
      calculateDealEconomics: flow,
      dealEconomics: observable,
      resetStore: action,
      acquisitionCosts: computed,
      saleCosts: computed,
      interimExpenses: computed,
      borrowerProfitLoss: computed,
      totalCosts: computed,
      flipEconomics: computed,
      rentEconomics: computed,
      setIsDisabled: action,
      setIsDownloading: action,
      isDisabled: observable,
      isDownloading: observable,
    });
  }

  *fetchDealEconomics() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const {
        data,
      }: ApiResponse = yield this.dealEconomicsService.getDealEconomics(loanId);

      const response = JSON.parse(data.data);

      if (!response.success) {
        throw response.data;
      }

      this.dealEconomics = response.data;
      this.initForm();
      this.loanTerm = response.data.loanTerm;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting deal economics.',
      });
    }
  }

  initForm() {
    this.loadForm(this.dealEconomics);
  }

  *calculateDealEconomics() {
    try {
      const { loanId } = this.loanStore.loanDetails;
      const body = {
        ...this.dealEconomics,
        ...this.getFormValues(),
      };

      if((this.form.fields.timeToFlip.value || 0) <= (this.loanTerm || 0)) {
        this.form.meta.isValid = true;
        const {
          data,
        }: ApiResponse = yield this.dealEconomicsService.saveDealEconomics(
          loanId,
          body
        );

        const response = JSON.parse(data.data);

        this.dealEconomics = response.data;
        this.initForm();

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Deal Economics form data calculated successfully.',
        });
      } else {
        this.form.meta.isValid = false;
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Time to flip cannot be more than loan term',
        });
      }
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error on calculating Deal Economics form data.',
      });
    }
  }

  resetStore() {
    this.dealEconomics = null;
    this.reset();
  }

  get acquisitionCosts() {
    return [
      {
        label: 'Purchase Price',
        ...getCurrencyValues(this.dealEconomics?.purchasePrice),
      },
      {
        label: 'Legal Fees',
        ...getCurrencyValues(this.dealEconomics?.legalFees),
      },
      {
        label: 'Mortgage Recording Tax',
        ...getCurrencyValues(this.dealEconomics?.mortgageRecordingTax),
      },
      {
        label: 'Filing Fees',
        ...getCurrencyValues(this.dealEconomics?.filingFees),
      },
      {
        label: 'Appraisal',
        ...getCurrencyValues(this.dealEconomics?.appraisal),
      },
      {
        label: 'PL Legal Fee',
        ...getCurrencyValues(this.dealEconomics?.pllegalFee),
      },
      {
        label: 'Title Insurance',
        ...getCurrencyValues(this.dealEconomics?.titleInsurance),
      },
      {
        label: 'Origination Points',
        ...getCurrencyValues(this.dealEconomics?.originationPoints),
      },
      {
        label: 'Total',
        ...getCurrencyValues(this.dealEconomics?.totalAcquisitionCosts),
        isTotal: true,
      },
    ];
  }

  get saleCosts() {
    return [
      {
        label: 'Broker Fee',
        ...getCurrencyValues(this.dealEconomics?.brokerFee),
      },
      {
        label: 'Transfer Tax',
        ...getCurrencyValues(this.dealEconomics?.transferTax),
      },
      {
        label: 'Mansion Tax',
        ...getCurrencyValues(this.dealEconomics?.mansionTax),
      },
      {
        label: 'Legal',
        ...getCurrencyValues(this.dealEconomics?.legalFees),
      },
      {
        label: 'Filing Fees',
        ...getCurrencyValues(this.dealEconomics?.filingFees),
      },
      {
        label: 'Total',
        ...getCurrencyValues(this.dealEconomics?.totalSaleCosts),
        isTotal: true,
      },
    ];
  }

  get interimExpenses() {
    return [
      {
        label: 'Renovation',
        ...getCurrencyValues(this.dealEconomics?.renovationBudget),
      },
      {
        label: 'Interest',
        ...getCurrencyValues(this.dealEconomics?.interestAmount),
      },
      {
        label: 'Property Tax & Maintenance',
        ...getCurrencyValues(
          this.dealEconomics?.propertyTaxAndMaintenanceAmount
        ),
      },
      {
        label: 'Total',
        ...getCurrencyValues(this.dealEconomics?.totalInterimExpenses),
        isTotal: true,
      },
    ];
  }

  get borrowerProfitLoss() {
    return [
      {
        label: 'Sales Price',
        ...getCurrencyValues(this.dealEconomics?.salesPrice),
      },
      {
        label: 'Costs',
        ...getCurrencyValues(this.dealEconomics?.totalCosts),
      },
      {
        label: 'Total',
        ...getCurrencyValues(this.dealEconomics?.borrowerProfitOrLoss),
        isTotal: true,
      },
    ];
  }

  get totalCosts() {
    return [
      {
        label: 'Purchase Price',
        ...getCurrencyValues(this.dealEconomics?.purchasePrice),
      },
      {
        label: 'Renovation',
        ...getCurrencyValues(this.dealEconomics?.renovationBudget),
      },
      {
        label: 'Interest & Origination Points',
        ...getCurrencyValues(this.dealEconomics?.interestAndOriginationPoints),
      },
      {
        label: 'Property Tax & Maintenance',
        ...getCurrencyValues(
          this.dealEconomics?.propertyTaxAndMaintenanceAmount
        ),
      },
      {
        label: 'Taxes and Other Costs',
        ...getCurrencyValues(this.dealEconomics?.taxesAndOtherCosts),
      },
      {
        label: 'Total',
        ...getCurrencyValues(this.dealEconomics?.totalCosts),
        isTotal: true,
      },
    ];
  }

  get flipEconomics() {
    return [
      {
        label: 'Total Profit on Flip',
        ...getCurrencyValues(this.dealEconomics?.totalProfitOnFlip),
      },
      {
        label: 'Borrower Cash in Deal At Purchase',
        ...getCurrencyValues(this.dealEconomics?.borrowerCashInDealAtPurchase),
      },
      {
        label: 'Borrower Cash in Deal (months)',
        ...getCurrencyValues(this.dealEconomics?.borrowerCashInDeal),
      },
      {
        label: 'Borrower ROI on Cash in Deal (months)',
        value: formatPercentage(this.dealEconomics?.borrowerROIOnCashInDeal),
      },
    ];
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  get rentEconomics() {
    return [
      {
        label: 'Appraiser Opex',
        value: formatPercentage(
          this.dealEconomics?.appraiserOperationalExpense
        ),
      },
      {
        label: 'Monthly Payment',
        ...getCurrencyValues(this.dealEconomics?.monthlyPayment),
      },
      {
        label: 'DSCR',
        value: this.dealEconomics?.dscr,
      },
    ];
  }

  get newRentEconomics() {
    return [
      {
        label: 'Annual PITIA',
        ...getCurrencyValues(this.dealEconomics?.annualPITIA),
      },
      {
        label: 'Monthly Principal & Interest Payment',
        ...getCurrencyValues(this.dealEconomics?.monthlyPAndIP),
      },
      {
        label: 'DSCR',
        value: this.dealEconomics?.dscr,
      },
    ];
  }
}

export default DealEconomicsStore;
