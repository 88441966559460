export const locationOptions = [
  { label: 'Rural', value: "rural" },
  { label: 'Suburban', value: "suburban" },
  { label: 'Urban', value: "urban" },
];

export const getLabelForLocationValue = (value) => {
  const foundItem = locationOptions.find(item => item.value === value);
  return foundItem ? foundItem.label : value;
}

export const neighborhoodZoningOptions = [
  { label: 'Commercial', value: "commercial" },
  { label: 'Residential', value: "residential" },
];

export const getLabelForNeighborhoodZoningValue = (value) => {
  const foundItem = neighborhoodZoningOptions.find(item => item.value === value);
  return foundItem ? foundItem.label : value;
}

export const highestAndBestUseOptions = [
  { label: 'Commercial', value: "commercial" },
  { label: 'Residential', value: "residential" },
];

export const getLabelForHighestAndBestUseValue = (value) => {
  const foundItem = highestAndBestUseOptions.find(item => item.value === value);
  return foundItem ? foundItem.label : value;
}

export const propertyTypeOptions = [
  { label: 'Single Family', value: "Single Family" },
  { label: 'Duplex', value: "Duplex" },
  { label: 'Triplex', value: "Triplex" },
  { label: 'Quadruplex', value: "Quadruplex" },
  { label: 'Condominium', value: "Condominium" },
  { label: '5Plus Unit Multifamily', value: "5Plus Unit Multifamily" },
  { label: 'Townhome', value: "Townhome" },
  { label: 'Vacant Residential Land', value: "Vacant Residential Land" },
  { label: 'Mixed Use', value: "Mixed Use" },
  { label: 'Other', value: "Other" },
];

export const getLabelForPropertyTypeValue = (value) => {
  const foundItem = propertyTypeOptions.find(item => item.value === value);
  return foundItem ? foundItem.label : value;
}

export const REQUIRED_ERROR_MESSAGE = 'This field is required.';

export const comparabilityOptions = [
  { label: 'Inferior', value: "Inferior" },
  { label: 'Similar', value: "Similar" },
  { label: 'Superior', value: "Superior" },
];

export const viewOptions = [
  ...comparabilityOptions
];