import { ACH_FORM } from './documentForms';

export enum DocumentName {
  DEAL_ECONOMICS = 'Deal Economics',
  REQUESTED_CLOSING_DATE = 'Requested Closing Date',
  EXPECTED_CLOSING_DATE = 'Expected Closing Date',
  DUE_DILIGENCE_PAYMENT = 'Due Diligence Payment',
  APPRAISAL_ORDER_DETAILS = 'Appraisal Order Details',
  APPRAISAL_DETAILS = 'Appraisal Details',
  LOAN_FAST_TRACK_PAYMENT = 'Loan Fast Track Payment',
  TERM_SHEET = 'Term Sheet',
  BORROWER_SURVEY_QUESTIONNAIRE = 'Borrower Survey Questionnaire',
  BORROWER_ENTITY = 'Borrower Entity Information',
  LENDER_FEES = 'Lender Fees',
  TITLE_AGENT = 'Title Provider Information',
  INSURANCE_PROVIDER = 'Insurance Provider Information',
  EXPECTED_WIRE_RELEASE_DATE = 'Expected Wire Release Date',
  INSURANCE_APPROVAL = 'Insurance Approval',
  INSURANCE_INFORMATION = 'Insurance Information',
  INSURANCE_INFORMATION_REVIEW = 'Insurance Information Review',
  LOAN_FUNDING_TEMPLATE = 'Loan Funding Template',
  APPRAISAL = 'Appraisal',
  APPRAISAL_REPORT = 'Appraisal Report',
  APPRAISAL_REPORT_AS_IS_RENT = 'Appraisal Report (As-Is w/ Rent Schedule)',
  APPRAISAL_REPORT_ARV = 'Appraisal Report (ARV)',
  APPRAISAL_REPORT_AS_IS = 'Appraisal Report (As is)',
  APPRAISAL_RESOLUTIONS = 'Appraisal Resolutions',
  RENT_SCHEDULE = 'Rent Schedule',
  BORROWER_CLOSING_AGENT = 'Borrower Closing Agent Information',
  SCOPE_OF_WORK = 'Scope of Work',
  DIGITAL_SCOPE_OF_WORK = 'Digital SOW',
  DEAL_SUMMARY = 'Deal Summary',
  LOAN_TERMS = 'Loan Terms',
  PLAID_BANKS = 'Bank Information via Plaid',
  NOTE_ALLONGE = 'Note Allonge',
  ASSIGNMENT_AND_CONVEYANCE = 'Assignment and Conveyance',
  FUNDING_PREFERENCE = 'Loan Closing Data',
  RATE_LOCK_EXTENSION = 'Rate Lock Extension',
  POINT_OF_CONTACT = 'Point of Contact',
  PROPERTY_PHOTOS = 'Property Photos',
  DRAW_REQUEST_FORM = 'Draw Request Form',
  REVISED_SOW = 'Revised SOW',
  REVISED_SOW_FORM = 'Revised SOW Form',
  HUD = 'HUD',
  ADDITIONAL_CLOSING_REVIEW = 'Additional Closing Review',
  TITLE_COMPANY_BANK_INFORMATION = 'Title Company Bank Information',
  DRAW_REPORT = 'Draw Report',
  BORROWER_BACKGROUND_REVIEW = 'Borrower Background Review',
  BORROWER_CREDIT_REVIEW = 'Borrower Credit Review',
  TRACK_RECORD_REVIEW = 'Borrower Track Record Review',
  TRACK_RECORD = 'Track Record',
  FINAL_PAYOFF_REPORT = 'Final Payoff Report',
  CREDIT_REPORT = 'Credit Report',
  TRIMERGE_CREDIT_REPORT = 'Trimerge Credit Report',
  CLOSING_CONDITION_SUFIX = 'Closing Condition - ',
  ACH_FORM = 'ACH Consent Form',
  BORROWER_SETUP = 'Borrower Setup',
  APPRAISAL_REVIEW_REPORT = 'Appraisal Review Report',
  REFERRAL_INFORMATION = 'Referral Information',
  BORROWER_PAST_LOAN_DOCUMENTS = 'Borrower Past Loan Documents',
  PERSONAL_IDENTIFICATION = 'Personal Identification',
  CONSTRUCTION_FEASIBILITY_REVIEW = 'Construction Feasibility Review',
  APPRAISAL_LICENSE = 'Appraisal Licence',
  APPRAISAL_E_AND_O_POLICY = 'Appraisal E&O Policy',
  INTERNAL_VALUATION_REPORT = 'Internal Valuation Report',
  PERMITS = 'Architect Letter Or Permits'
}

export enum DocumentStatus {
  PENDING = 'Pending',
  BEING_REVIEWED = 'Being Reviewed',
  MORE_INFO_NEEDED = 'More Info Needed',
  ACCEPTED = 'Accepted',
  NOT_APPLICABLE = 'Not Applicable',
  REPORTS_DELIVERED = 'Report(s) Delivered',
  CANCELED = 'Canceled',
}

export enum DocumentSection {
  INSURANCE = 10,
}

export enum DocumentSectionNames {
  GENERATED_CLOSING_DOCUMENTS = 'Generated Closing Documents',
  BOARDING = 'Boarding',
  SCANNED_CLOSING_DOCS = 'Scanned Closing Docs',
  RECORDED_CLOSING_DOCS = 'Recorded Closing Docs',
  PHYSICAL_ORIGINAL_RECORDED_CLOSING_DOCS = 'Physical Original Recorded Closing Docs',
  E_FILE_ONLY_RECORDED_CLOSING_DOCS = 'E-file Only Recorded Closing Docs',
  POST_CLOSING = 'Post Closing',
  DELINQUENCY_MANAGEMENT = 'Delinquency Management',
  APPRAISAL_AND_SOW = 'Appraisal And Sow',
  OTHER = 'Other',
  CLOSING_DOCUMENTS = 'Closing Documents',
  SIGNED_CLOSING_DOCUMENTS = 'Signed Closing Documents',
  INSURANCE = 'Insurance',
  TRAILING_DOCUMENTS = 'Trailing Documents',
}

export enum DocumentFilter {
  ALL_DOCUMENTS = 'All Documents',
  PENDING_DOCUMENTS = 'Pending Documents',
}

export const DONT_DELETE_EXCLUDE_DOCUMENTS = [DocumentName.APPRAISAL_LICENSE, DocumentName.APPRAISAL_E_AND_O_POLICY]

export const QUOTE_LOANS = ['Fix and Flip (1-4)', 'Groundup (1-4)', 'Stabilized Bridge'];

export const restrictedAppraisalDocuments: Array<String> = [
  DocumentName.APPRAISAL,
  DocumentName.APPRAISAL_REPORT,
  DocumentName.APPRAISAL_REPORT_AS_IS_RENT,
  DocumentName.APPRAISAL_REPORT_ARV,
  DocumentName.APPRAISAL_REPORT_AS_IS,
  DocumentName.APPRAISAL_RESOLUTIONS,
  DocumentName.RENT_SCHEDULE
];
