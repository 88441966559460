import {
  Badge,
  Box,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import {
  AssignmentLate,
  FileCopy,
  ListAlt,
  MenuOpen,
  Message, People, ViewList, AttachMoney, Feedback
} from '@material-ui/icons';
import { useBaseStore, useHeaderHeight, useUserStore } from '@roc/feature-app-core';
import { FilterType, useCommunicationRoutesConfig, useLoanCommunicationStore } from '@roc/feature-communication';
import { useDocumentStore } from '@roc/feature-documents';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { CLOSING, formatSectionName, PORTAL_COLORS, Roles, SERVICING, UNDERWRITING } from '@roc/feature-utils';
import { CopyText, ExpandableMenu, IconButton, Tooltip } from '@roc/ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useCallback, useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useLoanStore } from '../../hooks/useLoanStore';

const useStyles = headerHeight =>
  makeStyles((theme: Theme) =>
    createStyles({
      scrollBarContainer: {
        flex: 1,
        marginTop: headerHeight,
        paddingBottom: theme.spacing(1),
      },
      listItem: {
        padding: theme.spacing(0.5, 2, 0.5, 2),
        justifyContent: 'space-between',
        '& .MuiTypography-body1': {
          fontSize: 14,
        },
      },
      listItemChild: {
        paddingLeft: theme.spacing(4),
      },
      listItemActive: {
        fontWeight: theme.typography.fontWeightBold,
        background: lighten(theme.palette.primary.main, 0.9),
        '&:hover': {
          background: lighten(theme.palette.primary.main, 0.9),
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: theme.spacing(0.5),
          height: '100%',
          background: theme.palette.primary.dark,
        },
      },
      firstBadgeContainer: {
        marginRight: theme.spacing(1),
      },
      badge: {
        '& .MuiBadge-root': {
          display: 'flex',
        },
        '& .MuiBadge-badge': {
          position: 'relative',
          top: 0,
          right: 0,
          transform: 'initial',
          transformOrigin: 'initial',
          color: theme.palette.common.white,
          fontWeight: 700,
        },
      },
      pendingDocumentsBadge: {
        '& .MuiBadge-badge': {
          backgroundColor: PORTAL_COLORS.pendingDocuments,
        },
      },
      unreadMessagesBadge: {
        '& .MuiBadge-badge': {
          backgroundColor: PORTAL_COLORS.unreadMessages,
        },
      },
    })
  );

export const ListItem = ({
  Icon = null,
  text,
  url,
  pendingDocs = null,
  unreadMessages = null,
  isLevel1 = true,
}) => {
  const headerHeight = useHeaderHeight();
  const classes = useStyles(headerHeight)();
  const { push } = useHistory();
  const { pathname } = useLocation();


  const getListItemClasses = useCallback(
    (currentRoute: string) => {
      const cls = [classes.listItem];
      if (!isLevel1) {
        cls.push(classes.listItemChild);
      }
      if (currentRoute && pathname.indexOf(currentRoute) !== -1) {
        cls.push(classes.listItemActive);
      }
      return clsx(cls);
    },
    [classes, pathname]
  );

  const handleClick = useCallback((event, url: string) => {
    event.stopPropagation();
    if (url) push(url);
  }, []);

  return (
    <MuiListItem
      button
      className={getListItemClasses(url)}
      onClick={event => handleClick(event, url)}
    >
      <Box display="flex">
        {Icon && (
          <ListItemIcon style={{ alignItems: 'center' }}>
            <Icon fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText primary={text} />
      </Box>
      <Box display="flex" flexDirection="row">
        {Boolean(unreadMessages) && (
          <Box className={pendingDocs ? classes.firstBadgeContainer : null}>
            <Tooltip title={`${unreadMessages} unread messages`} arrow>
              <Badge
                className={clsx([classes.badge, classes.unreadMessagesBadge])}
                badgeContent={unreadMessages}
              />
            </Tooltip>
          </Box>
        )}
        {Boolean(pendingDocs) && (
          <Box>
            <Tooltip title={`${pendingDocs} pending documents`} arrow>
              <Badge
                className={clsx([classes.badge, classes.pendingDocumentsBadge])}
                badgeContent={pendingDocs}
              />
            </Tooltip>
          </Box>
        )}
      </Box>
    </MuiListItem>
  );
};

const useLoanIdBoxStyles = makeStyles(theme => ({
  dealNameHolder: {
    height: 85,
    position: 'relative',
    flexDirection: 'column',
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  copyButton: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    '& svg': {
      fontSize: theme.spacing(2),
    },
  },
  toggleMenuButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    '& svg': {
      fontSize: theme.spacing(3),
    },
    padding: '1px'
  },
}));

export const LoanIdBox = props => {
  const { loanId, toggleMenu } = props;
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [isLoanSummary, setIsLoanSummary] = useState(false);
  const { pathname } = useLocation();

  const classes = useLoanIdBoxStyles();

  const copyLoanId = event => {
    event.preventDefault();
    navigator.clipboard.writeText(loanId);
  };

  const onMouseEnter = () => {
    setShowCopyButton(true);
  };

  const onMouseLeave = () => {
    setShowCopyButton(false);
  };

  useEffect(() => {
    setIsLoanSummary(pathname.includes('/loan-summary'))
  }, [pathname]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      className={classes.dealNameHolder}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Typography variant="h4" color="textPrimary" style={{ fontWeight: 300 }}>
        <span>LOAN #</span>
        <span>
          <CopyText>
            {loanId}
          </CopyText>
        </span>
      </Typography>
      {isLoanSummary &&
        <IconButton
          aria-label="close"
          testId="close"
          size="medium"
          onClick={toggleMenu}
          className={classes.toggleMenuButton}
        >
          <MenuOpen />
        </IconButton>
      }
      {showCopyButton && (
        <IconButton
          aria-label="copy"
          testId="copy"
          size="small"
          onClick={copyLoanId}
          className={classes.copyButton}
        >
          <Tooltip title="Copy Loan ID" arrow placement="right">
            <FileCopy />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
};

export const LoanDetailsMenu = observer(({ data, toggleMenu }) => {
  const headerHeight = useHeaderHeight();
  const classes = useStyles(headerHeight)();
  const { loanId } = useParams<{ loanId: string }>();
  const { showExtensions, showPayoffs, showDraws, showDelinquency } = data;
  const { documentStore } = useDocumentStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { communicationRoutesConfig } = useCommunicationRoutesConfig();
  const { globalStore } = useBaseStore();
  const { push } = useHistory();
  const { loanStore } = useLoanStore();
  const { loanCommunicationStore } = useLoanCommunicationStore();
  const twilioCommunicationEnabled =
    loanStore.loanDetails?.twilioCommunicationEnabled;

  const isServicerPayoffApplicable = ['SLS', 'Keybank', 'Fay Servicing', 'Planet Home Lending', 'Planet Portfolio'].includes(loanStore.loanDetails.servicer);
  const isBridge = (loanStore.loanDetails.loanType === 'Residential Bridge');
  const underwritingSections = documentStore.displaySections[UNDERWRITING];
  const closingSections = documentStore.displaySections[CLOSING];
  const servicingSections = documentStore.displaySections[SERVICING] || [];

  const showAllDocuments = !globalStore.userFeatures?.isServicer && !globalStore.userFeatures.isExternalAgent;
  const showUnderwriting = !globalStore.userFeatures?.isServicer && !globalStore.userFeatures.isExternalAgent;
  const showClosing = !globalStore.userFeatures?.isServicer && !globalStore.userFeatures.isExternalAgent;
  const showOverview = !globalStore.userFeatures?.isServicer && !globalStore.userFeatures.isExternalAgent;

  const shouldShowDraws = showDraws && !globalStore.userFeatures.isClosingAttorney && !globalStore.userFeatures.isInsuranceReviewer && !globalStore.userFeatures.isClosingReviewer && !globalStore.userFeatures?.isServicer && !globalStore.userFeatures.isExternalAgent;
  const shouldShowServicing = documentStore.showServicing && !globalStore.userFeatures.isClosingReviewer && !globalStore.userFeatures.isExternalAgent;
  const shouldShowDelinquency = (showDelinquency && globalStore.userFeatures.showForeclosureRequest) || globalStore.userFeatures.isExternalAgent;
  const { userStore } = useUserStore();
  const showContacts = loanStore.loanContactsGridStore.showContacts;
  const isAlbertRisk = globalStore.userFeatures.isInsuranceReviewerAlbertRisk;
  const allowLoanPricing = userStore.allowLoanPricing;
  const isEstimatedClosingCostEnabled = globalStore.userFeatures.isEstimatedClosingCostEnabled;
  const isInternal = globalStore.lenderInfo?.isInternal;
  const isBackOfficeProcessor = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_PROCESSOR);
  const isBackOfficeUser = userStore.isBackOfficeUser;
  const showDecisionComments = globalStore.userFeatures.showDecisionComments;
  const isBackOfficeOriginator = userStore.userInformation?.roles?.includes(Roles.BACK_OFFICE_ORIGINATOR);

  const getTotalPendingDocuments = () => {
    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue.pendingDocuments;
    const totalUnderwritingPendingDocuments = underwritingSections.reduce(
      reducer,
      0
    );
    const totalClosingPendingDocuments = closingSections.reduce(reducer, 0);
    const totalServicingPendingDocuments = servicingSections.reduce(reducer, 0);

    return (
      totalUnderwritingPendingDocuments +
      totalClosingPendingDocuments +
      totalServicingPendingDocuments
    );
  };
  const [showConversations, setShowConversations] = useState(false);
  useEffect(() => {
    const loadConversations = () => {
      loanCommunicationStore.reset();
      loanCommunicationStore.setFilter({ type: FilterType.LOAN_ID, value: loanId });
      loanCommunicationStore.loadMyConversations(1, 100, (totalCount) => {
        if (totalCount > 0) {
          setShowConversations(true);
        }
      }, () => null);
    };

    if (globalStore.userFeatures?.isBroker) {
      loadConversations();
    } else if (userStore.allowLoanPricing) {
      setShowConversations(true);
    }
  }, []);


  return (
    <PerfectScrollbar className={classes.scrollBarContainer}>
      <LoanIdBox loanId={loanId} toggleMenu={toggleMenu} />
      <>
        <ExpandableMenu
          title="Loan Summary"
          defaultOpen
          handleClick={isOpen => {
            if (isOpen && showOverview) {
              push(loanRoutesConfig.loans(loanId).children.dashboard.url);
            }
          }}
        >
          {showOverview ? <ListItem
            Icon={ViewList}
            text="Overview"
            url={loanRoutesConfig.loans(loanId).children.dashboard.url}
          /> : null}
          {globalStore.userFeatures?.showLoanSummary ? <ListItem
            Icon={ListAlt}
            text="Loan Summary"
            url={loanRoutesConfig.loans(loanId).children.loanSummary.url}
          /> : null}
          {
            showAllDocuments &&
            <ListItem
              Icon={AssignmentLate}
              pendingDocs={getTotalPendingDocuments()}
              text="Document Checklist"
              url={loanRoutesConfig.loans(loanId).children.pendingDocuments.url}
            />
          }
          {
            showConversations &&
            <ListItem
              Icon={Message}
              unreadMessages={loanCommunicationStore.totalUnreadMessagesCount}
              text="Conversations"
              url={communicationRoutesConfig.conversations(loanId).url}
            />
          }
          {
            !twilioCommunicationEnabled && globalStore.userFeatures?.oldMessagesAccess && (
              <ListItem
                Icon={Message}
                unreadMessages={documentStore.totalUnreadComments}
                text="All Messages"
                url={loanRoutesConfig.loans(loanId).children.allMessages.url}
              />
            )
          }
          {
            showContacts && (
              <ListItem
                Icon={People}
                text="Contacts"
                url={loanRoutesConfig.loans(loanId).children.loanContacts.url}
              />
            )
          }
          {
            isBackOfficeUser && !isBackOfficeProcessor && isInternal && (
              <ListItem
                Icon={AttachMoney}
                text="Commissions"
                url={loanRoutesConfig.loans(loanId).children.commissions.url}
              />
            )
          }
          {
            showDecisionComments && isBackOfficeOriginator && (
              <ListItem
                Icon={Feedback}
                text="Decision Comments"
                url={loanRoutesConfig.loans(loanId).children.decisionTab.url}
              />
            )
          }
        </ExpandableMenu >
        {shouldShowDraws && (
          <ExpandableMenu title="Draws" defaultOpen>
            <ListItem
              isLevel1={false}
              text="Draws Dashboard"
              url={loanRoutesConfig.loans(loanId).children.draws.url}
            />
            <ListItem
              isLevel1={false}
              text="Request Draw"
              url={loanRoutesConfig.loans(loanId).children.drawRequest.url}
            />
            <ListItem
              isLevel1={false}
              text="Revise Scope of Work"
              url={
                loanRoutesConfig.loans(loanId).children.reviseScopeOfWork.url
              }
            />
          </ExpandableMenu>
        )}
        {
          shouldShowServicing && (
            <ExpandableMenu title="Servicing" defaultOpen>
              {showExtensions && isBridge && !globalStore.userFeatures?.isServicer && (
                <ListItem
                  isLevel1={false}
                  text="Extensions"
                  url={loanRoutesConfig.loans(loanId).children.extensions.url}
                />
              )}
              {showPayoffs && isServicerPayoffApplicable && !globalStore.userFeatures.isInsuranceReviewer &&
                !globalStore.userFeatures.isClosingReviewer && (
                  <ListItem
                    isLevel1={false}
                    text="Request Payoff"
                    url={loanRoutesConfig.loans(loanId).children.payoffRequest.url}
                  />
                )}

              {!globalStore.userFeatures.isInsuranceReviewer &&
                !globalStore.userFeatures.isClosingAttorney && !globalStore.userFeatures.isClosingReviewer && (
                  servicingSections.map(({ name, pendingDocuments, unreadComments }) => (
                    <ListItem
                      isLevel1={false}
                      key={name}
                      text={name}
                      pendingDocs={pendingDocuments}
                      unreadMessages={unreadComments}
                      url={formatSectionName(
                        loanRoutesConfig
                          .loans(loanId)
                          .children.documents(loanId, SERVICING, name).url
                      )}
                    />

                  )
                  ))}
            </ExpandableMenu>
          )
        }
        {
          showUnderwriting && (
            <ExpandableMenu title="Underwriting" defaultOpen={!showDraws}>
              <div>
                {underwritingSections.map(
                  ({ name, pendingDocuments, unreadComments }) => (
                    <ListItem
                      isLevel1={false}
                      key={name}
                      text={name}
                      pendingDocs={pendingDocuments}
                      unreadMessages={unreadComments}
                      url={formatSectionName(
                        loanRoutesConfig
                          .loans(loanId)
                          .children.documents(loanId, UNDERWRITING, name).url
                      )}
                    />
                  )
                )}
              </div>
            </ExpandableMenu>
          )
        }
        {
          showClosing && (
            <ExpandableMenu
              title="Closing"
              defaultOpen={!showDraws}
              handleClick={isOpen => {
                if (isOpen) {
                  push(
                    loanRoutesConfig
                      .loans(loanId)
                      .children.documents(loanId, CLOSING, 'overview').url
                  );
                }
              }}
            >
              <div>
                {closingSections.map(
                  ({ name, pendingDocuments, unreadComments }) => (
                    <ListItem
                      isLevel1={false}
                      key={name}
                      text={name}
                      pendingDocs={pendingDocuments}
                      unreadMessages={unreadComments}
                      url={formatSectionName(
                        loanRoutesConfig
                          .loans(loanId)
                          .children.documents(loanId, CLOSING, name).url
                      )}
                    />
                  )
                )}
              </div>
              {allowLoanPricing && isEstimatedClosingCostEnabled && (
                <ListItem
                  isLevel1={false}
                  text="Estimated Closing Costs"
                  url={
                    loanRoutesConfig.loans(loanId).children.estimatedClosingCosts.url
                  }
                />
              )}
            </ExpandableMenu>
          )
        }
        {
          shouldShowDelinquency && (

            <ExpandableMenu title="Delinquency" defaultOpen>
              {!globalStore.userFeatures.isExternalAgent && (
                <ListItem
                  isLevel1={false}
                  text="Foreclosure Request"
                  url={loanRoutesConfig.loans(loanId).children.foreclosureRequest.url}
                />
              )}
              {globalStore.userFeatures.isExternalAgent && (
                <ListItem
                  isLevel1={false}
                  text="Foreclosure Invoice"
                  url={loanRoutesConfig.loans(loanId).children.foreclosureInvoice.url}
                />
              )}
            </ExpandableMenu>

          )
        }
      </>
    </PerfectScrollbar >
  );
});
