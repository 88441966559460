import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TitleDivider } from "../titleDivider";
import { TitleHistoryTable } from "../titleHistoryTable";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    backgroundColor: "#FFFFFF",
    padding: "2rem",
  },
  saveButton: {
    width: "15rem",
  },
}));

interface TitleHistoryTabProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const TitleHistoryTab = observer(({ internalValuationReviewStore }: TitleHistoryTabProps) => {
  const classes = useStyles();

  const propertyActivities = internalValuationReviewStore.initialData.propertyActivities;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="1rem"
      className={classes.tabContent}
    >
      <TitleDivider title="Title History" />
      <TitleHistoryTable propertyActivities={propertyActivities} />
    </Box>
  );
});
