import { ProcessEntitlements } from '@roc/feature-app-core';

export const shouldBeAbleToSubmitSpecificForm = (
  isSubmissionAllowed: boolean,
  isSubmissionNotAllowedOnly: string[],
  isSubmissionAllowedOnly: string[],
  taskName: string
) => {
  if (isSubmissionAllowed) {
    return !(
      isSubmissionNotAllowedOnly &&
      isSubmissionNotAllowedOnly.includes(taskName)
    );
  } else {
    return !!(
      isSubmissionAllowedOnly && isSubmissionAllowedOnly.includes(taskName)
    );
  }
};

export const canEditForm = (
  entitlements: ProcessEntitlements,
  taskName: string
) => {
  return shouldBeAbleToSubmitSpecificForm(
    entitlements?.isSubmissionAllowed,
    entitlements?.isSubmissionNotAllowedOnly,
    entitlements?.isSubmissionAllowedOnly,
    taskName
  );
};
