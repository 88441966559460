import { Route } from 'react-router';
import { Page } from '@roc/feature-app-core';
import { UnderwritingInternal } from '../underwritingInternal';
import { ClosingInternal } from '../closingInternal';
import { LoanSystemNotificationInternal } from '../loanSystemNotificationInternal';

const LOANS_HOME_BASE_PATH = 'loans';
const LOAN_BASE_PATH = ':loanId';

export const getLoanDetailsRoutesConfig = (
  basePath: string,
  baseUrl: string
) => {
  return {
    loans: (loanId?: string) => ({
      path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}`,
      url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}`,
      children: {
        closing: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/closing`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/closing`,
          documentTitle: `${loanId} - Closing`,
        },
        underwriting: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/underwriting`,
          url: `${baseUrl}/${LOANS_HOME_BASE_PATH}/${loanId}/underwriting`,
          documentTitle: `${loanId} - Underwriting`,
        },
        loanSystemNotificationConversation: {
          path: `${basePath}/${LOANS_HOME_BASE_PATH}/${LOAN_BASE_PATH}/loan-system-notifications`,
          url: `${basePath}/${LOANS_HOME_BASE_PATH}/${loanId}/loan-system-notifications`,
          documentTitle: `${loanId} - Loan System Notifications`,
        },
      },
    }),

  };
};

export const getLoanDetailsRoutes = (basePath: string, baseUrl: string) => {
  const config = getLoanDetailsRoutesConfig(basePath, baseUrl);
  return {
    underwriting: (
      <Route exact path={config.loans().children.underwriting.path}>
        <Page routeDefinition={config.loans().children.underwriting}>
          <UnderwritingInternal />
        </Page>
      </Route>
    ),
    closing: (
      <Route exact path={config.loans().children.closing.path}>
        <Page routeDefinition={config.loans().children.closing}>
          <ClosingInternal />
        </Page>
      </Route>
    ),
    loanSystemNotificationConversation: (
      <Route exact path={config.loans().children.loanSystemNotificationConversation.path}>
        <Page routeDefinition={config.loans().children.loanSystemNotificationConversation}>
          <LoanSystemNotificationInternal />
        </Page>
      </Route>
    ),  };
};
