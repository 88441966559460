import { observer } from "mobx-react";
import { useDocumentStore } from "../../../hooks/useDocumentStore";
import { useEffect } from "react";
import { Button, StandardDialog } from "@roc/ui";
import SaveButtonWithPermissions from "libs/ui/src/lib/components/shared/save-button-with-permissions";
import { PermitForm } from "./permitsForm";
import { Document } from "@roc/feature-types";

type Props = {
  open?: boolean;
  onClose?: () => void;
  loan: any;
  document: Document;
};

export const PermitsDialog = observer ((props: Props)=> {
  const { open, onClose, loan, document } = props;
  const { documentStore } = useDocumentStore();
  const { documentFormStore } = documentStore;
  const { permitFormStore } = documentFormStore;

  useEffect(() => {
    if (open) {
      const {loanId, collateralId} = document;
      permitFormStore.reset();
      permitFormStore.getPermitDocumentType(loanId,collateralId);
    }
  }, [open]);

  const sumbit = async () => {
    const {loanId, collateralId} = document;
    await permitFormStore.savePermitDocumentType(loanId, collateralId);
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      title="Architect letter or Permits"
      maxWidth="sm"
      handleClose={onClose}
      dialogContent={<PermitForm document={document} />}
      dialogActions={
        <>
          <Button
            style={{ marginRight: '8px' }}
            onClick={onClose}
            color="primary"
            variant="contained"
            testId="cancel"
          >
            Cancel
          </Button>
          <SaveButtonWithPermissions
            variant="contained"
            color="primary"
            onClick={sumbit}
            testId="next"
            label="Submit"
            loanId={loan?.loanId}
          />
        </>
      }
    />
  );
})