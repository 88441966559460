import { GlobalStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { BorrowerTrackRecordVerificationStore } from '../../borrowerDetailsVerification';
import { BorrowerTrackRecordService } from '../services/borrowerTrackRecordService';
import { DocumentStore } from '@roc/feature-documents';
import { downloadDocument, GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { BorrowerDetailsVerificationService, PrivateBorrowerDetailsVerificationService } from '@roc/feature-credit-background-check';

export class BaseBorrowerTrackRecordStore {
  protected globalStore: GlobalStore;
  protected borrowerTrackRecordService: BorrowerTrackRecordService;
  borrowerTrackRecordVerificationStore: BorrowerTrackRecordVerificationStore;
  document: any;
  internal: boolean;
  documentStore: DocumentStore;

  constructor(globalStore, documentStore: DocumentStore) {
    this.globalStore = globalStore;
    this.documentStore = documentStore;
    this.borrowerTrackRecordService = new BorrowerTrackRecordService();
    this.document = null;
    this.internal = false;

    makeObservable(this, {
      document: observable,
      getDocument: flow,
      setDocument: action,
      downloadDocument: flow,
      downloadDocumentInternal: flow,
    });
  }

  *getDocument(loanTaskId) {
    const response = yield this.borrowerTrackRecordService.getDocumentDetails(loanTaskId);

    this.setDocument(response.data);
  }

  setDocument(document: any) {
    this.document = document;
  }

  *downloadDocument() {
    if (this.internal) {
      this.downloadDocumentInternal();
    } else {
      yield this.documentStore.downloadDocument(this.document);
    }
  }

  *downloadDocumentInternal() {
    try {
      const response = yield this.borrowerTrackRecordService.downloadDocumentInternal(this.document);
      downloadDocument(
        response?.data,
        response?.headers,
        'download',
        this.document.originalFileName
      );
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      });
    }
  };

  get canDownloadFile() {
    return this.document && !!this.document.dropboxPath;
  };
}


export class BorrowerTrackRecordStore extends BaseBorrowerTrackRecordStore {
  constructor(globalStore, documentStore: DocumentStore) {
    super(globalStore, documentStore);
    this.borrowerTrackRecordVerificationStore = new BorrowerTrackRecordVerificationStore(
      this.globalStore,
      new BorrowerDetailsVerificationService()
    );
  }
}

export class PrivateBorrowerTrackRecordStrore extends BaseBorrowerTrackRecordStore {
  constructor(globalStore, documentStore: DocumentStore) {
    super(globalStore, documentStore);
    this.borrowerTrackRecordVerificationStore = new BorrowerTrackRecordVerificationStore(
      this.globalStore,
      new PrivateBorrowerDetailsVerificationService()
    );
  }
}