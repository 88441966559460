import { useHistory, useLocation } from 'react-router';
import { LoanDetailsTab } from '../utils/loanDetailsTypes';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tabs } from '@roc/ui';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { Box } from '@material-ui/core';
import { SelectField } from '@roc/ui';
import { useLoanStore } from '@roc/feature-loans';
import { colors } from '../utils/todoConstants'
import {
  insertIf,
  LoanStatus,
  LoanSubType,
  LoanType,
} from '@roc/feature-utils';
import { useLoanFlags } from '../hooks/useLoanFlags';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    border: '0 !important',
    padding: theme.spacing(0, 2),
    '@media (max-width: 992px)': {
      overflowX: 'auto',
      scrollbarColor: '#eee #eee'
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    }
  },
  select: {
    '& .MuiSelect-root': {
      padding: '8px 32px 8px 8px'
    }
  },
  tab: {
    color: colors.raisinBlack,
    borderRadius: '10px 10px 0px 0px',
    border: "1px solid #eee",
    borderBottom: 'none',
    fontWeight: 500,
    margin: "1px",
    width: '152px'
  },
  activeTab: {
    backgroundColor: colors.desertStorm,
    fontWeight: 700
  },
}));
type LoanDetailsTabs = {
  overrideShowTpoView?: boolean,
  setOverrideShowTpoView?: any
  showCrmLoanSummaryView?: boolean
}
export const LoanDetailsTabs = ({ overrideShowTpoView, setOverrideShowTpoView, showCrmLoanSummaryView }: LoanDetailsTabs) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { loanRoutesConfig } = useLoanRoutes();
  const { loanStore } = useLoanStore();
  const {
    showDecisionCommentsTab,
    showDelinquency,
    hideDrawsTab,
    hideExtensionsTab,
    hidePayoffsTab,
    hideLoanSumaryTab,
    hideUnderwritingTab,
    hideClosingTab,
    showContactsTab,
    isExternalAgent,
    showPostClosing,
    isClosingAttorney,
  } = useLoanFlags();
  const loanId = loanStore.loanDetails?.loanId;
  const loanDetails = loanStore.loanDetails;
  const isBridgeLoan = loanDetails?.loanType === LoanType.RESIDENTIAL_BRIDGE;
  const isBridgeLoanExceptStabilizedBridge =
    isBridgeLoan && loanDetails?.loanSubType !== LoanSubType.STABILIZED_BRIDGE;

  const TABS = [
    ...insertIf(!hideLoanSumaryTab, [
      {
        label: 'LOAN SUMMARY',
        value: LoanDetailsTab.LOAN_SUMMARY,
        url: loanRoutesConfig.loans(loanId).children.dashboard.url,
      },
    ]),
    ...insertIf(showDecisionCommentsTab, [
      {
        label: 'DECISION COMMENTS',
        value: LoanDetailsTab.DECISION_COMMENTS,
        url: loanRoutesConfig.loans(loanId).children.decisionTab.url,
      },
    ]),
    ...insertIf(!hideUnderwritingTab, [
      {
        label: 'UNDERWRITING',
        value: LoanDetailsTab.UNDERWRITING,
        url: loanRoutesConfig.loans(loanId).children.underwriting.url,
      },
    ]),
    ...insertIf(!hideClosingTab, [
      {
        label: 'CLOSING',
        value: LoanDetailsTab.CLOSING,
        url: loanRoutesConfig.loans(loanId).children.closing.url,
      },
    ]),
    ...insertIf(isBridgeLoanExceptStabilizedBridge && !hideDrawsTab, [
      {
        label: 'DRAWS',
        value: LoanDetailsTab.DRAWS,
        url: loanRoutesConfig.loans(loanId).children.draws.url,
        disabled: !loanDetails?.showDraws,
        childRoutes: [
          loanRoutesConfig.loans(loanId).children.drawRequest.url,
          loanRoutesConfig.loans(loanId).children.reviseScopeOfWork.url,
        ],
      },
    ]),
    ...insertIf((isBridgeLoan && !hideExtensionsTab) || (isBridgeLoan && isClosingAttorney), [
      {
        label: 'EXTENSIONS',
        value: LoanDetailsTab.EXTENSIONS,
        url: loanRoutesConfig.loans(loanId).children.extensions.url,
        disabled: !loanDetails?.showExtensions,
        childRoutes: [
          loanRoutesConfig.loans(loanId).children.extensionDetails().url,
        ],
      },
    ]),
    ...insertIf((isBridgeLoan && !hidePayoffsTab) || (isBridgeLoan && isClosingAttorney), [
      {
        label: 'PAYOFFS',
        value: LoanDetailsTab.PAYOFFS,
        url: loanRoutesConfig.loans(loanId).children.payoffs.url,
        disabled: !loanDetails?.showPayoffs,
        chilldRoutes: [
          loanRoutesConfig.loans(loanId).children.payoffRequest.url,
        ],
      },
    ]),
    ...insertIf(showPostClosing, [
      {
        label: 'POST CLOSING',
        value: LoanDetailsTab.POST_CLOSING,
        url: loanRoutesConfig.loans(loanId).children.postClosing.url,
      },
    ]),
    ...insertIf(showDelinquency, [
      {
        label: 'DELINQUENCY',
        value: isExternalAgent ? LoanDetailsTab.FORECLOSURE_INVOICE : LoanDetailsTab.FORECLOSURE_REQUEST,
        url: isExternalAgent ? loanRoutesConfig.loans(loanId).children.foreclosureInvoice.url : loanRoutesConfig.loans(loanId).children.foreclosureRequest.url,
      },
    ]),
    ...insertIf(showContactsTab, [
      {
        label: 'CONTACTS',
        value: LoanDetailsTab.CONTACTS,
        url: loanRoutesConfig.loans(loanId).children.loanContacts.url,
      },
    ])
  ];

  const onTabChange = (e, index) => {
    const tab = TABS[index];
    push(tab.url);
  };

  const tabIndex = TABS.findIndex(tab => {
    return (
      pathname.indexOf(tab.url) !== -1 ||
      tab.childRoutes?.some?.(url => pathname.indexOf(url) !== -1)
    );
  });

  return (
    <div style={{
      display: 'flex',
      background: 'white',
      marginLeft: '-24px'
    }}>
      <Tabs
        tabs={TABS}
        tabClassName={classes.tab}
        tabSelectedClassName={classes.activeTab}
        className={classes.tabs}
        selectedTab={tabIndex === -1 ? 0 : tabIndex}
        onTabChange={onTabChange}
      />
      {
        tabIndex === 0 && showCrmLoanSummaryView && <CrmViewField value={overrideShowTpoView} onChange={() => setOverrideShowTpoView(!overrideShowTpoView)} />
      }
    </div>
  );
};
const CrmViewField = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <Box textAlign="right" p={1} pb={.5}>
      <SelectField
        value={value}
        options={[
          { label: 'TPO View ', value: false },
          { label: 'Sales User View', value: true },
        ]}
        onChange={onChange}
        variant={'outlined'}
        testId={'enableCrmView'}
        className={classes.select}
      />
    </Box>
  );
};